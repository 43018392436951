/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />

// Induction Plan Profile
class InductionPlanProfile {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanProfileField"];

        let inductionPlanProfileSaveButton = document.getElementById("inductionPlanProfileSave");
        if (inductionPlanProfileSaveButton !== null)
            inductionPlanProfileSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let planPK = 0;

        let profileFormElement = <HTMLDivElement>document.getElementById("inductionPlanProfileForm");
        let inputs = document.getElementsByClassName("inductionPlanProfileField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Teacher Induction Committee Participants
class TeacherInductionCommitteeParticipants {

    validationClasses: string[];

    private _core: Core;
    private planPK: number;

    constructor() {
        let that = this;
        this._core = new Core();
        this.bindDeleteButtons();
        this.validationClasses = ["steeringCommitteeField"];

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        if (steeringCommitteeTable != null) {
            that.planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        }

        let exportToWordButton = <HTMLButtonElement>document.getElementById("inductionExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let exportToExcelButton = <HTMLButtonElement>document.getElementById("inductionExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let saveInductionCommitteeMembers = <HTMLButtonElement>document.getElementById("saveInductionCommitteeMembers");
        if (saveInductionCommitteeMembers !== null)
            saveInductionCommitteeMembers.addEventListener("click", (e: Event) => this.save("save"));

        let inductionPlanSteeringCommitteeDeleteCancel = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteCancel");
        if (inductionPlanSteeringCommitteeDeleteCancel !== null)
            inductionPlanSteeringCommitteeDeleteCancel.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel());

        let inductionPlanSteeringCommitteeDeleteConfirm = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteConfirm");
        if (inductionPlanSteeringCommitteeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeDeleteConfirm.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        let steeringCommitteeAddRowsButton = document.getElementById("inductionSteeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e: Event) => this.addNewSteeringCommitteeRow());

        let steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("change", (e: Event) => this.checkForPlural());

        let steeringCommitteeExportExcel = document.getElementById("exportExcelCommitteeMembers");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        this.initializeRequiredFieldsCustom();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        let allSaveElements = [];

        let planPK = 0;

        let inputs = document.getElementsByClassName("steeringCommitteeField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeTable.dataset.planpk);

            if (ele instanceof HTMLSelectElement) {
                let element = <HTMLSelectElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                let lookupCodeFK = null;
                let textValue = null;
                if (ele.dataset.propertycode == "inductionPlanCommitteeAppointedBy") {
                    lookupCodeFK = parseInt(element.value);
                } else if (ele.dataset.propertycode == "inductionPlanCommitteeRole") {
                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    textValue = ele.value;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: textValue,
                        LookupCodeFK: lookupCodeFK,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            } else {
                let element = <HTMLInputElement>ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {
        let core = this._core;
        Core.showLoader();

        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let planPK = 0;
        planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        window.open("/ExportExcel/InductionSteeringCommitteeDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeInductionSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }

    deleteRowConfirmCancel() {
        let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.hide();
    }

    deleteRowConfirmConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;

        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("steeringCommittee");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();

        let committeeFields = document.getElementsByClassName("steeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = <HTMLInputElement>comm;
            if (inputElement.dataset.row === row && "planpropertypk" in inputElement.dataset && inputElement.dataset.planpropertypk !== "0") {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }

        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = <HTMLDivElement>thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);

                    let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();

                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        } else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    addNewSteeringCommitteeRow() {
        let that = this;
        let core = this._core;
        let newRow: number = 0;
        let _that = this;
        let numberOfRowsElement = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber");
        let numberOfRows = parseInt(numberOfRowsElement.value);

        if (numberOfRows !== null && numberOfRows > 0) {

            let communicationFields = document.getElementsByClassName("steeringCommitteeField");
            for (let comm of communicationFields) {
                let commElement = <HTMLInputElement>comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddSteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteButtons();
                        numberOfRowsElement.value = "";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planPK=" + that.planPK);
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
    }

    checkForPlural(): void {
        let word = <HTMLSpanElement>document.getElementById("rowWord");
        let num = <HTMLInputElement>document.getElementById("federalPreparingAddRow")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

    initializeRequiredFieldsCustom() {
        let steeringCommitteeTableRows = document.querySelectorAll("tr.steeringCommitteeRow[data-row]");

        for (let row of steeringCommitteeTableRows) {

            let fields = row.getElementsByClassName("steeringCommitteeField") as HTMLCollectionOf<HTMLElement>;

            for (let element of fields) {

                if (element instanceof HTMLSelectElement) {
                    if(element.selectedIndex > 0) {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                } else if (element instanceof HTMLInputElement) {
                    if(element.value != "") {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }
}

// Educator Induction Plan
class EducatorInductionPlan {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanEducatorInductionPlan"];

        this.checkPreCount();

        let educatorInductionPlanSaveButton = document.getElementById("saveEducatorInductionPlan");
        if (educatorInductionPlanSaveButton !== null)
            educatorInductionPlanSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let educatorInductionPlanExportToWordButton = document.getElementById("exportToWordEducatorInductionPlan");
        if (educatorInductionPlanExportToWordButton !== null)
            educatorInductionPlanExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let educatorInductionPlanExportToExcelButton = document.getElementById("exportToExcelEducatorInductionPlan");
        if (educatorInductionPlanExportToExcelButton !== null)
            educatorInductionPlanExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        const preCounts = document.getElementsByClassName("precount");
        for (const preCount of preCounts) {
            preCount.addEventListener("change", (e: Event) => this.checkPreCount());
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanEducatorInductionPlanForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let inputs = document.getElementsByClassName("inductionPlanEducatorInductionPlan");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let textInputs = document.getElementsByClassName("inductionPlanEducatorInductionPlanText");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    checkPreCount() {
        const preCount = <HTMLInputElement>document.querySelector(".precount:checked");
        if (preCount !== null) {
            const preKCountsContainer = <HTMLDivElement>document.getElementById("preKCountsContainer");
            const inductionPlanEducatorPreCountList = <HTMLTextAreaElement>document.getElementById("inductionPlanEducatorPreCountListId");

            if (preCount.dataset.lookupcode === "no") {
                preKCountsContainer.classList.add("hide");
                this._core.forceElementOptional(inductionPlanEducatorPreCountList);
            } else {
                preKCountsContainer.classList.remove("hide");
                this._core.forceElementRequired(inductionPlanEducatorPreCountList);
            }
        }
    }
}

// Mentors
class Mentors{

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["indctionPlanMentorRadioField", "inductionPlanMentorField"];

        let mentorsSaveButton = document.getElementById("saveMentors");
        if (mentorsSaveButton !== null)
            mentorsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let mentorsExportToWordButton = document.getElementById("exportToWordMentors");
        if (mentorsExportToWordButton !== null)
            mentorsExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let mentorsExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (mentorsExportToExcelButton !== null)
            mentorsExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        this.setMentorsOther();

        let otherRadio = document.querySelectorAll('input[name="inductionplanmentorsinductionPlanMentorOther"]');
        if (otherRadio !== null) {
            for (let radioElement of otherRadio) {
                radioElement.addEventListener("change", (e: Event) => this.setMentorsOther());
            }
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanMentorsForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanMentorField");
        let inputs = document.getElementsByClassName("indctionPlanMentorRadioField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveMentors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setMentorsOther() {
        let otherRadioChecked = <HTMLInputElement>document.querySelector('input[name="inductionplanmentorsinductionPlanMentorOther"]:checked');
        let otherExplanation = <HTMLTextAreaElement>document.getElementById("inductionPlanMentorOtherText");

        //Show explanation and make explanation required if user picks "No"
        if (otherRadioChecked !== null && otherRadioChecked.dataset.lookuplabel !== null && otherRadioChecked.dataset.lookuplabel === "Yes" && otherExplanation !== null) {
            this._core.forceElementRequired(otherExplanation);
        } else {
            this._core.forceElementOptional(otherExplanation);
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

// Needs Assessment
class NeedsAssessment {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanNeedsAssessmentRadioField", "inductionPlanNeedsAssessmentField"];

        let needsAssessmentSaveButton = document.getElementById("saveNeedsAssessment");
        if (needsAssessmentSaveButton !== null)
            needsAssessmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let needsAssessmentExportToWordButton = document.getElementById("exportToWordMentors");
        if (needsAssessmentExportToWordButton !== null)
            needsAssessmentExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let needsAssessmentExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (needsAssessmentExportToExcelButton !== null)
            needsAssessmentExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        this._core.leftnav(this);

        this.setNeedsAssessmentOther();

        let otherRadio = document.querySelectorAll('input[name="inductionPlanNeedsOther"]');
        if (otherRadio !== null) {
            for (let radioElement of otherRadio) {
                radioElement.addEventListener("change", (e: Event) => this.setNeedsAssessmentOther());
            }
        }

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanNeedsAssessmentForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanNeedsAssessmentField");
        let inputs = document.getElementsByClassName("inductionPlanNeedsAssessmentRadioField");

        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveNeedsAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setNeedsAssessmentOther() {
        let otherRadioChecked = <HTMLInputElement>document.querySelector('input[name="inductionPlanNeedsOther"]:checked');
        let otherExplanation = <HTMLTextAreaElement>document.getElementById("inductionPlanNeedsOtherSpecify");

        //Show explanation and make explanation required if user picks "Yes"
        if (otherRadioChecked !== null && otherRadioChecked.dataset.lookuplabel !== null && otherRadioChecked.dataset.lookuplabel === "Yes" && otherExplanation !== null) {
            this._core.forceElementRequired(otherExplanation);
        } else {
            this._core.forceElementOptional(otherExplanation);
        }
    }

    exportToWord() {

    }

    exportToExcel() {

    }
}

// Educator Induction Plan Topic Areas
class EducatorInductionPlanTopicAreas {

    validationClasses: string[];
    hashClasses: string[];
    deleteFileModal: Modal;
    planPropertyFileToDeletePK: number;

    private _core: Core;
    constructor() {
        let that = this;
        this._core = new Core();

        this.validationClasses = ["multiSelectTopicAreasTimeline", "multiSelectTopicAreasTopicAreas", "inductionPlanFileUpload"];
        this.hashClasses = ["addDanielsonFrameworkToListItem", "inductionPlanTopicAreasTimeline"];

        this.deleteFileModal = new Modal('deleteFileModal', null);

        let fileUploaders = document.getElementsByClassName('fileUpload') as HTMLCollectionOf<HTMLInputElement>;
        for (let upload of fileUploaders) {
            if ("propertyFk" in upload.dataset) {
                 upload.addEventListener('change', (e: Event) => that.uploadFile(parseInt(upload.dataset.propertyFk)));
            } 
        }

        document.addEventListener("click", function (event) {
            let target = <HTMLElement>event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("deleteFile") && "planPropertyFilePk" in target.dataset) {
                that.deleteFileModal.show();
                that.deleteFileModal.callingId = target.id;
                that.planPropertyFileToDeletePK = parseInt(target.dataset.planPropertyFilePk);
            }
        });

        let deleteFileConfirm = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirm != null) {
            deleteFileConfirm.addEventListener("click", () => that.deleteFileConfirm());
        }

        let deleteFileCancel = document.getElementById("deleteFileCancel");
        if (deleteFileCancel != null) {
            deleteFileCancel.addEventListener("click", () => that.deleteFileModal.hide());
        }

        let educatorInductionPlanTopicAreasSaveButton = document.getElementById("saveEducatorInductionPlanTopicAreas");
        if (educatorInductionPlanTopicAreasSaveButton !== null)
            educatorInductionPlanTopicAreasSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        let reportTopicAreasButton = document.getElementById("inductionReportSelectedTopics");
        if (reportTopicAreasButton !== null)
            reportTopicAreasButton.addEventListener("click", (e: Event) => this.reportTopicAreas());

        let topicAreaExportToWordButton = document.getElementById("exportToWordTopicAreas");
        if (topicAreaExportToWordButton !== null)
            topicAreaExportToWordButton.addEventListener("click", (e: Event) => this.exportToWord());

        let topicAreaExportToExcelButton = document.getElementById("exportToExcelTopicAreas");
        if (topicAreaExportToExcelButton !== null)
            topicAreaExportToExcelButton.addEventListener("click", (e: Event) => this.exportToExcel());

        let allAddDanielsonButtons = document.getElementsByClassName("addThisDanielson");
        for (let addDanielsonButton of allAddDanielsonButtons)
            addDanielsonButton.addEventListener("click", (e: Event) => this.addDanielson(e));

        this._core.leftnav(this);

        this.initializeRequiredFields();

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this.bindRemoveDanielsonButtons();

        let hidden = this._core.createHash(this.hashClasses);  // using hashclasses instead of validation classes due to danielson framework

        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let checkboxes = document.getElementsByClassName("inductionPlanTopicAreasCheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForReadonly(e));
    }

    checkForReadonly(e: Event) {
        let checkbox = <HTMLInputElement>e.target;

        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;

            return false;
        }
    }

    createHash() {
        //using custom hashclasses for this page.
        let hash = this._core.createHash(this.hashClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    addDanielson(e: Event) {
        let core = this._core;
        let _that = this;
        let lookupCodePK = parseInt((<HTMLButtonElement>e.target).dataset.lookupcodepk);
        let danielsonSelection = <HTMLSelectElement>document.querySelector(`.danielsonFrameworkList[data-lookupcodepk="${lookupCodePK}"]`);
        let propertyPK = danielsonSelection.dataset.propertypk;
        let danielsonLookupCodePK = danielsonSelection.value;

        if (danielsonLookupCodePK === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please select a Danielson Framework to add", "warning", 3000, null);
        } else {
            let isNotInList: boolean = true;
            let allListElements = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
            for (let listElement of allListElements) {
                let element = <HTMLDivElement>listElement;
                if (element.dataset.danielsonlookupcodepk === danielsonLookupCodePK.toString()) {
                    isNotInList = false;
                }
            }

            if (isNotInList) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddDanielsonFrameworkToList');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $(".addDanielsonFrameworkToList[data-lookupcodepk='" + lookupCodePK + "']").append(xhr.responseText);
                        _that.bindRemoveDanielsonButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("lookupCodePK=" + lookupCodePK + "&danielsonLookupPK=" + danielsonLookupCodePK + "&propertyFK=" + propertyPK);
            }
        }
    }

    removeDanielson(e: Event) {
        e.stopPropagation();
        let element: HTMLButtonElement;
        let go = true;
        element = <HTMLButtonElement>e.target;
        let lookupCodePK = element.dataset.lookupcodepk;
        let danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;

        let allLookups = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
        for (let lookup of allLookups) {
            let thisElement = <HTMLDivElement>lookup;

            if (thisElement.dataset.danielsonlookupcodepk === danielsonLookupCodePK) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }

    bindRemoveDanielsonButtons() {
        let allDeleteButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (let deleteButton of allDeleteButtons) {
            deleteButton.removeEventListener("click", (e: Event) => this.removeDanielson(e));
            deleteButton.addEventListener("click", (e: Event) => this.removeDanielson(e));
        }
    }

    reportTopicAreas() {
        let _that = this;
        let successes: boolean[] = [];
        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");

        for (let topicArea of allTopicAreas) {
            let topicAreaElement = <HTMLInputElement>topicArea;
            let topLevelAccordionElement = <HTMLDivElement>document.getElementById("inductionPlanTopicAreasAccordionList");
            let accordionTrigger = <HTMLButtonElement>topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            let accordionPanel = <HTMLDivElement>topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);

            if (topicAreaElement.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");

                    let timeline = <HTMLElement>accordionPanel.querySelector(".multiSelectTopicAreasTimeline");
                    if (timeline != null) {
                        this._core.forceElementRequired(timeline);
                    }
                }
            } else {
                let selects = accordionPanel.querySelectorAll("select");
                for (let select of selects) {
                    let selectElement = <HTMLSelectElement>select;
                    selectElement.value = "0";
                }

                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                }
                

                //TODO: Remove the danielson elements from the list.

            }
        }

        Core.createHTMLAlert("alertMessageDiv", "Topic Area(s) successfully added", 'success', 3000, null);

        this._core.initializeRequiredFields(this.validationClasses);
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];
        let hadDataElements = [];
        let allDanielsonElements = [];
        let allTopicAreaElements = [];

        let formElement = document.getElementById("inductionPlanEducatorInductionPlanTopicAreasForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let timelines = document.getElementsByClassName("inductionPlanTopicAreasTimeline");

        for (let ele of timelines) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = 0
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let lookupCodePK = element.dataset.lookupcodepk;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.checked || hadValue) {
                    let saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.dataset.timelinelookupcodepk),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        ButtonRow: parseInt(lookupCodePK),
                        DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let danielsonList = document.getElementsByClassName("addDanielsonFrameworkToListItem");

        for (let danielson of danielsonList) {
            let danielsonElement = <HTMLDivElement>danielson;

            let planPropertyPK = 0;
            let propertyPK = parseInt(danielsonElement.dataset.propertypk);
            let danielsonLookupCodePK = danielsonElement.dataset.danielsonlookupcodepk;
            let lookupCodePK = danielsonElement.dataset.lookupcodepk;
            if (danielsonElement.dataset.planpropertypk !== "" && danielsonElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(danielsonElement.dataset.planpropertypk);
            }

            let saveItem: IPlanPropertyExtended = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: "",
                LookupCodeFK: parseInt(danielsonLookupCodePK),
                RowNbr: 0,
                IsDeletedInd: false,
                ButtonRow: parseInt(lookupCodePK),
                DataPointPlanPropertyPK: parseInt(danielsonElement.dataset.datapointplanpropertypk)
            };

            allDanielsonElements.push(saveItem);
        }

        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        for (let topic of allTopicAreas) {
            let topicElement = <HTMLInputElement>topic;
            let propertyPK = 0;
            let topicLookupCodePK = 0;

            if ("lookupcodepk" in topicElement.dataset) {
                topicLookupCodePK = parseInt(topicElement.dataset.lookupcodepk);
            }

            if ("propertyPK" in topicElement.dataset) {
                propertyPK = parseInt(topicElement.dataset.propertypk);
            }

            if (!topicElement.checked && "hadvalue" in topicElement.dataset && topicElement.dataset.hadvalue === "true") {

                let planPropertyPK = 0;
                if (topicElement.dataset.planpropertypk !== "" && topicElement.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(topicElement.dataset.planpropertypk);

                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        RowNbr: 0,
                        LookupCodeFK: topicLookupCodePK,
                        IsDeletedInd: true,
                    };
                    hadDataElements.push(saveItem);
                }
            } else if (topicElement.checked) {
                let planPropertyPK = 0;
                if (topicElement.dataset.planpropertypk) {
                    planPropertyPK = parseInt(topicElement.dataset.planpropertypk);
                }

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    RowNbr: 0,
                    LookupCodeFK: topicLookupCodePK,
                    IsDeletedInd: false,
                };
                allTopicAreaElements.push(saveItem);
            }
        }

        let saveData = {
            "Timeline": allSaveElements,
            "Danielson": allDanielsonElements,
            "RemovedTopicAreas": hadDataElements,
            "TopicAreas": allTopicAreaElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveTopicAreas', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allDanielsonElements.length === 0 && hadDataElements.length === 0 && allTopicAreaElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    initializeRequiredFields() {
        let hiddenTimelines = document.querySelectorAll(".hide-accordion .multiSelectTopicAreasTimeline") as NodeListOf<HTMLElement>;

        for (let hiddenTimeline of hiddenTimelines) {
            this._core.forceElementOptional(hiddenTimeline);
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    exportToWord() {

    }

    exportToExcel() {

    }

    uploadFile(propertyFK: number) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = <HTMLFormElement>document.querySelector(`.uploadForm[data-property-fk='${propertyFK}']`);
        const formData = new FormData(uploadForm);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/UploadFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {                        
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        
                        const formFile = document.querySelector(`.fileUpload[data-property-fk='${propertyFK}']`) as HTMLInputElement;
                        formFile.value = '';             

                        const planPropertyFile = response.payload;

                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/Base/GetFileUploadPartialView?planPropertyFilePK=${planPropertyFile.planPropertyFilePK}`);                                               
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-property-fk='${propertyFK}']`) as HTMLDivElement;
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            } 
                        };
                        xhrForPartialView.send();  
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }      
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    deleteFileConfirm() {
        let that = this;
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileContainer[data-plan-property-file-pk='${that.planPropertyFileToDeletePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            that.deleteFileModal.hide();
        };
        xhr.send(JSON.stringify([that.planPropertyFileToDeletePK]));
    }
}

// Evaluation and Monitoring
class EvaluationAndMonitoring {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["evaluationAndMonitoringField"];

        let evaluationAndMonitoringSaveButton = document.getElementById("saveEvaluationAndMonitoring");
        if (evaluationAndMonitoringSaveButton !== null)
            evaluationAndMonitoringSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanEvaluationAndMonitoringForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("evaluationAndMonitoringField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEvaluationAndMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Documentation of Participation and Completion
class DocumentationOfParticipationAndCompletion {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["documentationOfParticipationField", "documentationOfParticipationRadioField"];

        let documentationOfParticipationAndCompletionSaveButton = document.getElementById("saveDocumentationOfParticipationAndCompletion");
        if (documentationOfParticipationAndCompletionSaveButton !== null)
            documentationOfParticipationAndCompletionSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");

        this.setConfirmFirstYearTeachers();

        let firstYearTeachersRadio = document.querySelectorAll('input[name="inductionPlanConfirmFirstYear"]');
        if (firstYearTeachersRadio !== null) {
            for (let radioElement of firstYearTeachersRadio) {
                radioElement.addEventListener("change", (e: Event) => this.setConfirmFirstYearTeachers());
            }
        }

        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    setConfirmFirstYearTeachers() {
        let confirmTeachersRadioChecked = <HTMLInputElement>document.querySelector('input[name="inductionPlanConfirmFirstYear"]:checked');
        let confirmTeachersExplanation = <HTMLTextAreaElement>document.getElementById("confirmTeachersExplanation");

        //Show explanation and make explanation required if user picks "No"
        if (confirmTeachersRadioChecked !== null && confirmTeachersRadioChecked.dataset.lookuplabel !== null && confirmTeachersRadioChecked.dataset.lookuplabel === "No" && confirmTeachersExplanation !== null) {
            this._core.forceElementRequired(confirmTeachersExplanation);
        } else {
            this._core.forceElementOptional(confirmTeachersExplanation);
        }
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanDocumentationOfParticipationAndCompletionForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("documentationOfParticipationField");
        let radioInputs = document.getElementsByClassName("documentationOfParticipationRadioField");

        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveDocumentationOfParticipationAndCompletion', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// Educator Induction Plan Statement Of Assurance
class EducatorInductionPlanStatementOfAssurance {

    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanSignatureAndAssuranceField"];

        let EducatorInductionPlanStatementOfAssuranceSaveButton = document.getElementById("saveEducatorInductionPlanStatementOfAssurance");
        if (EducatorInductionPlanStatementOfAssuranceSaveButton !== null)
            EducatorInductionPlanStatementOfAssuranceSaveButton.addEventListener("click", (e: Event) => this.save("save"));


        let fileUploader = document.getElementById("inductionPlanUploadMinutesFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));
        }

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }

        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = <HTMLFormElement>document.getElementById("inductionPlanUploadMinutes");
        let formData = new FormData(uploadMinutesForm);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.getElementById("inductionPlanUploadMinutesFile");
                        formFile.value = "";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let allSaveElements = [];

        let formElement = document.getElementById("inductionPlanEducatorInductionPlanStatementOfAssuranceForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("inductionPlanSignatureAndAssuranceField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlanStatementOfAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

//Curriculum Summary Checklist and Submission
class InductionPlanSummaryChecklistAndSubmission {

    private _core: Core;
    constructor() {
        this._core = new Core();

        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));
        this._core.leftnav(this);

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;

        //Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

//V3 
// InductionPlanV3SteeringCommittee
class InductionPlanV3SteeringCommittee {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanV3SteeringCommitteeField", "inductionPlanV3SteeringCommitteeSelectField"];

        const inductionPlanV3SteeringCommitteeSaveButton = document.getElementById("inductionPlanV3SteeringCommitteeSave");
        if (inductionPlanV3SteeringCommitteeSaveButton !== null)
            inductionPlanV3SteeringCommitteeSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const inductionPlanSteeringCommitteeDeleteCancel = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteCancel");
        if (inductionPlanSteeringCommitteeDeleteCancel !== null)
            inductionPlanSteeringCommitteeDeleteCancel.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel(e));

        const inductionPlanSteeringCommitteeDeleteConfirm = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeDeleteConfirm");
        if (inductionPlanSteeringCommitteeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeDeleteConfirm.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        const inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel");
        if (inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel !== null)
            inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel.addEventListener("click", (e: Event) => this.deleteRowConfirmCancel(e));

        const inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm = <HTMLButtonElement>document.getElementById("inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm");
        if (inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm.addEventListener("click", (e: Event) => this.deleteRowConfirmConfirm(e));

        const steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("input", (e: Event) => this.checkForPlural());

        const exportToExcelButton = document.getElementById("inductionExportToExcel");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        const steeringCommitteeAddRowsButton = document.getElementById("inductionSteeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e: Event) => this.addNewSteeringCommitteeRow());

        this.bindDeleteButtons();
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3SteeringCommitteeForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("inductionPlanV3SteeringCommitteeField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const selectInputs = document.getElementsByClassName("inductionPlanV3SteeringCommitteeSelectField");
        for (const ele of selectInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3SteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    checkForPlural(): void {
        const word = <HTMLSpanElement>document.getElementById("rowWord");
        const num = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber")
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }

        word.textContent = newWord;
    }

    exportToExcel() {
        const formElement = <HTMLButtonElement>document.getElementById("inductionPlanV3SteeringCommitteeForm");
        const planFK = formElement.dataset.planfk;

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/InductionPlanV3SteeringCommitteeDataExport?planFK=${planFK}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "SteeringCommittee.xlsx";

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = <HTMLAnchorElement>document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                } else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            }
            xhr.send();
        });
    }

    addNewSteeringCommitteeRow() {
        const that = this;
        const core = this._core;
        let newRow: number = 0;
        const _that = this;
        const numberOfRowsElement = <HTMLInputElement>document.getElementById("steeringCommitteeAddRowsNumber");
        const numberOfRows = parseInt(numberOfRowsElement.value);

        const form = <HTMLDivElement>document.getElementById("inductionPlanV3SteeringCommitteeForm");
        const planFK = form.dataset.planfk;

        if (numberOfRows !== null && numberOfRows > 0) {

            let communicationFields = document.getElementsByClassName("inductionPlanSteeringCommitteeRow");
            for (const comm of communicationFields) {
                const commElement = <HTMLTableRowElement>comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddV3SteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteButtons();
                        numberOfRowsElement.value = "";
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planPK=" + planFK);
            }
        } else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
    }

    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeInductionSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteRowConfirm(e));
        }
    }

    showDeleteRowConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const row = buttonElement.dataset.row;
        const hasData = buttonElement.dataset.hasdata;

        if (parseInt(row) <= 3) {
            const modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", null);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", "#inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm", "row", row);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", "#inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm", "hasdata", hasData);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", "#inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel", "row", row);
            modal.show();
        } else {
            const modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "row", row);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "hasdata", hasData);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteCancel", "row", row);
            modal.show();
        }
    }

    deleteRowConfirmCancel(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const row = button.dataset.row;

        if (parseInt(row) <= 3) {
            const modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", null);
            modal.hide();
        } else {
            const modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
            modal.hide();
        }
    }

    deleteRowConfirmConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const row = buttonElement.dataset.row;
        const hasDataText = buttonElement.dataset.hasdata;
        let hasData: boolean = false;
        if (hasDataText === "true")
            hasData = true;

        const planProps = [];
        Core.showLoader();

        let modal: Modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        if (parseInt(row) <= 3) {
            modal = new Modal("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", null);
        }
                
        
        if (!hasData) {
            const theRow = <HTMLTableRowElement>document.querySelector(`.inductionPlanSteeringCommitteeRow[data-row='${row}']`);
            theRow.remove();

            modal.hide();

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
        } else {
            const thisRow = document.querySelectorAll(`.steeringCommitteeField[data-row='${row}']`);

            for (const row of thisRow) {
                const ele = <HTMLElement>row;

                planProps.push(ele.dataset.planpropertypk);
            }

            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const allTheRows = document.getElementsByClassName("inductionPlanSteeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = <HTMLDivElement>thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }

                    Core.hideLoader();
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const allElements = document.querySelectorAll(classesToValidate);

        const rows = [];
        for (const ele of allElements) {
            const element = <HTMLElement>ele;
            const row = element.dataset.row;
            if (rows.indexOf(row) === -1)
                rows.push(row);
        }

        for (const row of rows) {
            const nameElement = <HTMLInputElement>document.querySelector(`.name[data-row='${row}']`);
            const titleElement = <HTMLInputElement>document.querySelector(`.title[data-row='${row}']`);
            const roleElement = <HTMLInputElement>document.querySelector(`.role[data-row='${row}']`);
            const appointedByElement = <HTMLInputElement>document.querySelector(`.appointedBy[data-row='${row}']`);

            if (nameElement.value !== "" || titleElement.value !== "" || roleElement.value !== "0" || appointedByElement.value !== "0") {
                if (nameElement.value === "") {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                }

                if (titleElement.value === "") {
                    titleElement.classList.add("missing-field");
                    titleElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(titleElement);
                    showMessage = true;
                    totalErrors++;
                }

                if (roleElement.value === "0") {
                    roleElement.classList.add("missing-field");
                    roleElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(roleElement);
                    showMessage = true;
                    totalErrors++;
                }

                if (appointedByElement.value === "0") {
                    appointedByElement.classList.add("missing-field");
                    appointedByElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(appointedByElement);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }

        const message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {
            const firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    const accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");

                        const accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// InductionPlanV3NeedsAssessment
class InductionPlanV3NeedsAssessment {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.checkForOthers();

        this.validationClasses = ["inductionPlanV3NeedsAssessmentField", "inductionPlanV3NeedsAssessmentRadioField"];

        const inductionPlanV3NeedsAssessmentSaveButton = document.getElementById("inductionPlanV3NeedsAssessmentSave");
        if (inductionPlanV3NeedsAssessmentSaveButton !== null)
            inductionPlanV3NeedsAssessmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const inductionPlanV3NeedsOthers = document.getElementsByClassName("inductionPlanV3NeedsOther");
        for (const inductionPlanV3NeedsOther of inductionPlanV3NeedsOthers)
            inductionPlanV3NeedsOther.addEventListener("click", (e: Event) => this.checkForOther(e.target as HTMLInputElement));

    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3NeedsAssessmentForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("inductionPlanV3NeedsAssessmentField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("inductionPlanV3NeedsAssessmentRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3NeedsAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    checkForOthers() {
        const inductionPlanV3NeedsOthers = document.getElementsByClassName("inductionPlanV3NeedsOther");
        for (const inductionPlanV3NeedsOther of inductionPlanV3NeedsOthers)
            this.checkForOther(inductionPlanV3NeedsOther as HTMLInputElement);
    }

    checkForOther(radio: HTMLInputElement) {
        if (radio.checked) {
            var lookupLabel = radio.dataset.lookuplabel.toLowerCase();

            const otherElement = <HTMLTextAreaElement>document.getElementById("inductionPlanV3NeedsOtherSpecify");
            if (lookupLabel === "yes") {
                this._core.forceElementRequired(otherElement);
            } else {
                this._core.forceElementOptional(otherElement);
            }
        }
    }
}

// InductionPlanV3Mentors
class InductionPlanV3Mentors {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();
        this.checkForOthers();

        this.validationClasses = ["inductionPlanV3MentorsField", "inductionPlanV3MentorsRadioField"];

        const inductionPlanV3MentorsSaveButton = document.getElementById("inductionPlanV3MentorsSave");
        if (inductionPlanV3MentorsSaveButton !== null)
            inductionPlanV3MentorsSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const inductionPlanV3MentorOthers = document.getElementsByClassName("inductionPlanV3MentorOther");
        for (const inductionPlanV3MentorOther of inductionPlanV3MentorOthers)
            inductionPlanV3MentorOther.addEventListener("click", (e: Event) => this.checkForOther(e.target as HTMLInputElement));
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3MentorsForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("inductionPlanV3MentorsField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("inductionPlanV3MentorsRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3Mentors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    checkForOthers() {
        const inductionPlanV3MentorOthers = document.getElementsByClassName("inductionPlanV3MentorOther");
        for (const inductionPlanV3MentorOther of inductionPlanV3MentorOthers)
            this.checkForOther(inductionPlanV3MentorOther as HTMLInputElement);
    }

    checkForOther(radio: HTMLInputElement) {
        if (radio.checked) {
            var lookupLabel = radio.dataset.lookuplabel.toLowerCase();

            const otherElement = <HTMLTextAreaElement>document.getElementById("inductionPlanV3MentorOtherText");
            if (lookupLabel === "yes") {
                this._core.forceElementRequired(otherElement);
            } else {
                this._core.forceElementOptional(otherElement);
            }
        }
    }
}

// InductionPlanV3PlanTopicAreas
class InductionPlanV3PlanTopicAreas {
    validationClasses: string[];

    private _suppressToast: boolean;

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanV3PlanTopicAreasField", "inductionPlanV3PlanTopicAreasFileField", "inductionPlanV3PlanTopicAreasCheckboxField"];

        const inductionPlanV3PlanTopicAreasSaveButton = document.getElementById("inductionPlanV3PlanTopicAreasSave");
        if (inductionPlanV3PlanTopicAreasSaveButton !== null)
            inductionPlanV3PlanTopicAreasSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.checkForEducatorEffectivenessCharterSchool();

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const uploadInductionPlan = document.getElementById("inductionPlanV3PlanTopicAreasInductionPlan");
        if (uploadInductionPlan !== null)
            uploadInductionPlan.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        const reportTopicAreasButton = document.getElementById("inductionReportSelectedTopics");
        if (reportTopicAreasButton !== null)
            reportTopicAreasButton.addEventListener("click", (e: Event) => this.reportTopicAreas());

        const inductionPlanTopicAreasDeleteConfirm = document.getElementById("inductionPlanTopicAreasDeleteConfirm");
        if (inductionPlanTopicAreasDeleteConfirm !== null)
            inductionPlanTopicAreasDeleteConfirm.addEventListener("click", (e: Event) => this.confirmDeleteTopic(e));

        const inductionPlanTopicAreasDeleteCancel = document.getElementById("inductionPlanTopicAreasDeleteCancel");
        if (inductionPlanTopicAreasDeleteCancel !== null)
            inductionPlanTopicAreasDeleteCancel.addEventListener("click", (e: Event) => this.cancelDeleteTopic());

        const inductionPlanDeleteDanielsonDeleteConfirm = document.getElementById("inductionPlanDeleteDanielsonDeleteConfirm");
        if (inductionPlanDeleteDanielsonDeleteConfirm !== null)
            inductionPlanDeleteDanielsonDeleteConfirm.addEventListener("click", (e: Event) => this.confirmDeleteDanielson(e));

        const inductionPlanDeleteDanielsonDeleteCancel = document.getElementById("inductionPlanDeleteDanielsonDeleteCancel");
        if (inductionPlanDeleteDanielsonDeleteCancel !== null)
            inductionPlanDeleteDanielsonDeleteCancel.addEventListener("click", (e: Event) => this.cancelDeleteDanielson());

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        const inductionReportCustomTopic = document.getElementById("inductionReportCustomTopic");
        if (inductionReportCustomTopic !== null)
            inductionReportCustomTopic.addEventListener("click", (e: Event) => this.reportCustomTopic());
        this._suppressToast = false;
        this.checkForRequiredCheckboxes();

        this.bindEducatorEffectivenessCharterSchool();
        this.bindAddDanielsons();
        this.bindDeleteTopics();
        this.bindRemoveDanielsonButtons();
        this.bindDeleteFiles();
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allFileElements = [];
        const charterSchool = [];

        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3PlanTopicAreasForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const checkbox = <HTMLInputElement>document.querySelector(".inductionPlanV3PlanTopicAreasCheckboxField");
        if (checkbox !== null) {
            let planPropertyPK = 0;
            const element = checkbox;
            const rowNumber = "0";
            const propertyPK = parseInt(element.dataset.propertypk);
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
            }

            let textValue = "off";
            if (element.checked)
                textValue = "on";

            const saveItem = {
                "PlanPropertyPK": planPropertyPK,
                "PlanFK": planPK,
                "PropertyFK": propertyPK,
                "TextValue": textValue,
                "RowNbr": parseInt(rowNumber),
                "IsDeletedInd": false
            };

            charterSchool.push(saveItem);
        }

        const files = document.getElementsByClassName("inductionPlanV3PlanTopicAreasFileField");
        for (const ele of files) {
            const element = <HTMLElement>ele;
            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            const fileUploadPK = element.dataset.fileuploadpk;
            const propertyPK = parseInt(element.dataset.propertypk);
            const saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }

        const topicOptions = [];

        const selectedItems = document.querySelectorAll(`.inductionPlanTopicAreasCheckbox:checked`);
        for (const si of selectedItems) {
            const element = <HTMLInputElement>si;
            const lookupCodePK = element.dataset.lookupcodepk;
            const dataPointPlanPropertyPK = element.dataset.planpropertypk;

            const innerTopic = [];
            const innerOF = [];
            let topLevelElement: IPlanProperty = null;

            const topLevel = <HTMLDivElement>document.querySelector(`.InductionPlanTopicAreaParent[data-lookupcodepk='${lookupCodePK}']`);
            if (topLevel !== null) {
                const propertyPK = topLevel.dataset.propertypk;
                const planPropertyPK = topLevel.dataset.planpropertypk;
                const lookupCodePK = topLevel.dataset.lookupcodepk;

                const element: IPlanProperty = {
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PlanFK: planPK,
                    PropertyFK: parseInt(propertyPK),
                    TextValue: null,
                    LookupCodeFK: parseInt(lookupCodePK),
                    RowNbr: null,
                    IsDeletedInd: false
                };

                topLevelElement = element;
            }

            const allOFTextArea = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.textarea[data-datapointlookupcodepk='${lookupCodePK}']`);
            for (const thisOFTA of allOFTextArea) {
                const ofElement = <HTMLTextAreaElement>thisOFTA;

                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;

                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };

                innerTopic.push(saveItem);
            }


            const allOFInput = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.input[data-datapointlookupcodepk='${lookupCodePK}']`);
            for (const thisOFTA of allOFInput) {
                const ofElement = <HTMLInputElement>thisOFTA;

                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;

                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };

                innerTopic.push(saveItem);
            }

            //Observation Framework
            const allOFs = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk='${lookupCodePK}']`);
            for (const thisOF of allOFs) {
                const element = <HTMLDivElement>thisOF;
                const danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;
                const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
                const planPropertyPK = element.dataset.planpropertypk;
                const propertyPK = element.dataset.propertypk;

                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "DanielsonLookupCodePK": danielsonLookupCodePK,
                    "PlanPropertyPK": planPropertyPK,
                    "PropertyPK": propertyPK
                };

                innerOF.push(saveItem);
            }

            const fullTopic = {
                "TopLevelPlanProperty": topLevelElement,
                "TopicData": innerTopic,
                "FrameworkData": innerOF,
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "DataPointLookupCodePK": lookupCodePK
            };

            topicOptions.push(fullTopic);
        }

        const otherItems = document.querySelectorAll(`.Accordion-trigger[data-lookupcode='inductionPlanV3TopicsOther']`);
        for (const oi of otherItems) {
            const element = <HTMLInputElement>oi;
            const lookupCodePK = element.dataset.lookupcodepk;
            const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;

            const innerTopic = [];
            const innerOF = [];
            let topLevelElement: IPlanProperty = null;

            const topLevel = <HTMLDivElement>document.querySelector(`.InductionPlanTopicAreaParent[data-lookupcodepk='${lookupCodePK}'][data-planpropertypk='${dataPointPlanPropertyPK}']`);
            if (topLevel !== null) {
                const propertyPK = topLevel.dataset.propertypk;
                const planPropertyPK = topLevel.dataset.planpropertypk;
                const lookupCodePK = topLevel.dataset.lookupcodepk;
                const title = topLevel.dataset.title;

                const element: IPlanProperty = {
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PlanFK: planPK,
                    PropertyFK: parseInt(propertyPK),
                    TextValue: title,
                    LookupCodeFK: parseInt(lookupCodePK),
                    RowNbr: null,
                    IsDeletedInd: false
                };

                topLevelElement = element;
            }

            const allOFTextArea = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.textarea[data-datapointlookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const thisOFTA of allOFTextArea) {
                const ofElement = <HTMLTextAreaElement>thisOFTA;

                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;

                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };

                innerTopic.push(saveItem);
            }

            const allOFInput = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.input[data-datapointlookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const thisOFTA of allOFInput) {
                const ofElement = <HTMLInputElement>thisOFTA;

                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;

                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };

                innerTopic.push(saveItem);
            }

            //Observation Framework
            const allOFs = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const thisOF of allOFs) {
                const element = <HTMLDivElement>thisOF;
                const danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;
                const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
                const planPropertyPK = element.dataset.planpropertypk;
                const propertyPK = element.dataset.propertypk;

                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "DanielsonLookupCodePK": danielsonLookupCodePK,
                    "PlanPropertyPK": planPropertyPK,
                    "PropertyPK": propertyPK
                };

                innerOF.push(saveItem);
            }

            const fullTopic = {
                "TopLevelPlanProperty": topLevelElement,
                "TopicData": innerTopic,
                "FrameworkData": innerOF,
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "DataPointLookupCodePK": lookupCodePK
            };

            topicOptions.push(fullTopic);
        }
        
        const finalData = {
            "PageLevel": {
                "CharterSchool": charterSchool,
                "Files": allFileElements,
            },
            "Frameworks": topicOptions,
            "PlanFK": planPK
        };

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3PlanTopicAreas', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(finalData));
    }

    reportTopicAreas() {
        const _that = this;
        const successes: boolean[] = [];
        const allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");

        for (let topicArea of allTopicAreas) {
            const topicAreaElement = <HTMLInputElement>topicArea;
            const topLevelAccordionElement = <HTMLDivElement>document.getElementById("inductionPlanTopicAreasAccordionList");
            const accordionTrigger = <HTMLButtonElement>topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            const accordionPanel = <HTMLDivElement>topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);

            if (topicAreaElement.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");
                }

                topicAreaElement.disabled = true;
            } else {
                const selects = accordionPanel.querySelectorAll("select");
                for (let select of selects) {
                    let selectElement = <HTMLSelectElement>select;
                    selectElement.value = "0";
                }

                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                }
            }
        }

        if (!this._suppressToast)
            Core.createHTMLAlert("alertMessageDiv", "Topic Area(s) successfully added", 'success', 3000, null);

        this._core.initializeRequiredFields(this.validationClasses);
    }

    addDanielson(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const lookupCodePK = parseInt(buttonElement.dataset.lookupcodepk);
        const danielsonSelection = <HTMLSelectElement>document.querySelector(`.danielsonFrameworkList[data-lookupcodepk="${lookupCodePK}"][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const propertyPK = danielsonSelection.dataset.propertypk;
        const danielsonLookupCodePK = danielsonSelection.value;
        const that = this;

        if (danielsonLookupCodePK === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please select an Observation and Practice Framework to add", "warning", 3000, null);
        } else {
            let isNotInList: boolean = true;
            const allListElements = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const listElement of allListElements) {
                const element = <HTMLDivElement>listElement;
                if (element.dataset.danielsonlookupcodepk === danielsonLookupCodePK.toString()) {
                    isNotInList = false;
                }
            }

            if (isNotInList) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddDanielsonFrameworkToListV3');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        danielsonSelection.selectedIndex = 0;

                        const newDiv = <HTMLDivElement>document.createElement("div");
                        newDiv.classList.add("medium-6");
                        newDiv.classList.add("column");
                        newDiv.classList.add("end");
                        newDiv.innerHTML = xhr.responseText;

                        const danielsonList = document.querySelector(`.addDanielsonFrameworkToList[data-lookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .row`);
                        danielsonList.append(newDiv);

                        that.bindRemoveDanielsonButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Observation and Practice Framework Added", 'success', 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send(`lookupCodePK=${lookupCodePK}&danielsonLookupPK=${danielsonLookupCodePK}&propertyFK=${propertyPK}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}`);
            } else {
                Core.createHTMLAlert("alertMessageDiv", `Please choose an Observation and Practice Framework that is not already selected.`, 'warning', 3000, null);
            }
        }
    }

    bindRemoveDanielsonButtons() {
        const allDeleteButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (const deleteButton of allDeleteButtons) 
            deleteButton.addEventListener("click", (e: Event) => this.checkRemoveDanielson(e));
    }

    checkRemoveDanielson(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const planPropertyPK = button.dataset.planpropertypk;
        const lookupCodePK = button.dataset.lookupcodepk;
        const danielsonLookupCodePK = button.dataset.danielsonlookupcodepk;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        
        if (planPropertyPK && planPropertyPK !== "0")
            this.showRemoveDanielson(e);
        else {
            const element = document.querySelector(`.addDanielsonFrameworkToListItem[data-lookupcodepk='${lookupCodePK}'][data-danielsonlookupcodepk='${danielsonLookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            element.remove();
        }
    }

    showRemoveDanielson(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const planPropertyPK = button.dataset.planpropertypk;

        const modal: Modal = new Modal("deleteDanielsonModal", null);
        modal.addAttributeToElement("deleteDanielsonModal", "#inductionPlanDeleteDanielsonDeleteConfirm", "planpropertypk", planPropertyPK);

        modal.show();
    }
        
    cancelDeleteDanielson() {
        const modal: Modal = new Modal("deleteDanielsonModal", null);
        modal.hide();
    }

    async confirmDeleteDanielson(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const planPropertyPK = button.dataset.planpropertypk;

        const response = await fetch(`/InductionPlanReport/DeleteDanielson/${planPropertyPK}`, { credentials: 'include' })
        if (response.ok) {
            const element = document.querySelector(`.addDanielsonFrameworkToListItem[data-planpropertypk='${planPropertyPK}']`);
            element.remove();
        }
    }

    showDeleteTopic(deleteTopicButton: HTMLButtonElement) {
        const dataPointPlanPropertyPK = deleteTopicButton.dataset.datapointplanpropertypk;
        const dataPointLookupCodePK = deleteTopicButton.dataset.datapointlookupcodepk;
        const isCustom = deleteTopicButton.dataset.iscustom;
        const title = deleteTopicButton.dataset.title;

        const modal: Modal = new Modal("deleteTopicModal", null);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "datapointlookupcodepk", dataPointLookupCodePK);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "iscustom", isCustom);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "title", title);
        modal.show();
    }

    async confirmDeleteTopic(e: Event) {
        const deleteButton = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
        const dataPointLookupCodePK = deleteButton.dataset.datapointlookupcodepk;
        const isCustom = deleteButton.dataset.iscustom;
        const title = deleteButton.dataset.title;
        const form = document.getElementById("inductionPlanV3PlanTopicAreasForm");
        const planFK = form.dataset.planfk;
        const modal: Modal = new Modal("deleteTopicModal", null);

        const data = {
            "PlanFK": planFK,
            "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
            "DataPointLookupCodePK": dataPointLookupCodePK,
            "Title": title
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(`/InductionPlanReport/DeleteV3TopicArea`, settings)
        if (response.ok) {
            const json = await response.json();

            if (json.success) {
                const allTextAreas = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.textarea[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);
                const allInputs = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.input[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);

                for (const textArea of allTextAreas) {
                    const element = <HTMLTextAreaElement>textArea;

                    element.value = "";
                    element.dataset.datapointplanpropertypk = "0";
                    element.dataset.planpropertypk = "0";
                }

                for (const input of allInputs) {
                    const element = <HTMLInputElement>input;

                    element.value = "";
                    element.dataset.datapointplanpropertypk = "0";
                    element.dataset.planpropertypk = "0";
                }

                const addDanielsonFrameworkToList = document.querySelector(`.addDanielsonFrameworkToList[data-lookupcodepk='${dataPointLookupCodePK}'] .row`);
                addDanielsonFrameworkToList.innerHTML = "";

                let parentElement = document.querySelector(`.InductionPlanTopicAreaParent[data-lookupcodepk='${dataPointLookupCodePK}']`);
                parentElement = parentElement.parentElement as HTMLDivElement;
                parentElement.classList.add("open");

                if (isCustom === "false") {
                    const checkbox = <HTMLInputElement>document.querySelector(`.inductionPlanTopicAreasLookup[data-lookupcodepk='${dataPointLookupCodePK}']`);
                    checkbox.disabled = false;
                    checkbox.click();
                }

                const topLevelAccordionElement = <HTMLDivElement>document.getElementById("inductionPlanTopicAreasAccordionList");
                const accordionTrigger = <HTMLButtonElement>topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${dataPointLookupCodePK}"]`);
                const accordionPanel = <HTMLDivElement>topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${dataPointLookupCodePK}"]`);

                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                }
                
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The topic has been removed successfully.", 'success', 3000, null);
            } else {
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this topic. Please try again.", 'error', 3000, null);
            }
        }
    }

    cancelDeleteTopic() {
        const modal: Modal = new Modal("deleteTopicModal", null);
        modal.hide();
    }

    uploadFile(upload: HTMLInputElement) {
        Core.showLoader();
        const formName = upload.dataset.formname;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = upload.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`.inductionPlanV3PlanTopicAreasFileUploadField[data-formname='${formName}']`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList`)

                        const uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        const fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");

                        const newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");

                        const fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("inductionPlanV3PlanTopicAreasFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.inductionPlanV3PlanTopicAreasFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }

    bindAddDanielsons() {
        const allAddDanielsonButtons = document.getElementsByClassName("addThisDanielson");
        for (const addDanielsonButton of allAddDanielsonButtons)
            addDanielsonButton.addEventListener("click", (e: Event) => this.addDanielson(e));
    }

    bindDeleteTopics() {
        const deleteTopics = document.getElementsByClassName("deleteTopic");
        for (const deleteTopic of deleteTopics)
            deleteTopic.addEventListener("click", (e: Event) => this.showDeleteTopic(e.target as HTMLButtonElement));
    }

    async reportCustomTopic() {
        const topicNameElement = <HTMLInputElement>document.getElementById("inductionPlanV3PlanTopicAreasNameofAdditionalTraining");
        const topicName = topicNameElement.value;
        const form = <HTMLDivElement>document.getElementById("inductionPlanV3PlanTopicAreasForm");
        const planFK = form.dataset.planfk;

        const data = {
            "PlanFK": planFK,
            "TopicName": topicName
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(`/InductionPlanReport/CreateCustomTopic`, settings);
        if (response.ok) {
            const result = await response.text();

            const div = document.createElement("div");
            div.innerHTML = result;

            const accordionList = <HTMLDivElement>document.getElementById("inductionPlanTopicAreasAccordionList");
            accordionList.append(div);

            this.bindAddDanielsons();
            this.bindDeleteTopics();

            new CustomAccordion(null);
        }
    }

    checkForRequiredCheckboxes() {
        const allCheckboxes = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        const lookupsRequired = [
            "inductionPlanV3TopicsCommonGround",
            "inductionPlanV3TopicsEducatorEffectiveness",
            "inductionPlanV3TopicsProfessionalEthics",
            "inductionPlanV3TopicsStudentLearning",
            "inductionPlanV3TopicsTeacherCompetency"
        ];

        let atLeastOne: boolean = false;

        for (const checkbox of allCheckboxes) {
            const element = <HTMLInputElement>checkbox;
            const lookupCode = element.dataset.lookupcode;

            if (lookupsRequired.indexOf(lookupCode) !== -1) {
                if (!element.checked) {
                    element.checked = true;
                    atLeastOne = true;
                }
            }
        }

        this._suppressToast = true;
        if (atLeastOne) {
            const reportButton = <HTMLButtonElement>document.getElementById("inductionReportSelectedTopics");
            reportButton.click();
        }
        this._suppressToast = false;
    }

    bindEducatorEffectivenessCharterSchool() {
        const checkboxes = document.getElementsByClassName("educatorEffectivenessCharterSchool");
        for (const checkbox of checkboxes)
            checkbox.addEventListener("click", (e: Event) => this.checkForEducatorEffectivenessCharterSchool());
    }

    checkForEducatorEffectivenessCharterSchool() {
        const checkboxes = document.getElementsByClassName("educatorEffectivenessCharterSchool");
        for (const checkbox of checkboxes) {
            const element = <HTMLInputElement>checkbox;
            const dataPointLookupCodePK = element.dataset.datapointlookupcodepk;
            const allElements = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasAccordionData[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);
            const addButton = <HTMLButtonElement>document.querySelector(`.inductionPlanV3PlanTopicAreasAccordionDataButton[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);

            if (element.checked) {
                for (const innerElement of allElements) {
                    const innerEle = <HTMLInputElement>innerElement;

                    this._core.forceElementOptional(innerEle);
                    innerEle.readOnly = true;
                    innerEle.disabled = true;

                    addButton.disabled = true;
                }
            } else {
                for (const innerElement of allElements) {
                    const innerEle = <HTMLInputElement>innerElement;

                    this._core.forceElementRequired(innerEle);
                    innerEle.readOnly = false;
                    innerEle.disabled = false;

                    addButton.disabled = false;
                }
            }
        }
    }

    doCustomValidation(allClasses: string[]) {
        const showMessage = this.customClientSideValidation(allClasses);
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            const message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            const successMessage = <HTMLElement>document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const lookupsRequired = [
            "inductionPlanV3TopicsCommonGround",
            "inductionPlanV3TopicsEducatorEffectiveness",
            "inductionPlanV3TopicsProfessionalEthics",
            "inductionPlanV3TopicsStudentLearning",
            "inductionPlanV3TopicsTeacherCompetency"
        ];

        const allAccordions = document.querySelectorAll(`.InductionPlanTopicAreaParent`);
        for (const accord of allAccordions) {
            const element = <HTMLDivElement>accord;
            const lookupCode = element.dataset.lookupcode;
            const lookupCodePK = element.dataset.lookupcodepk;
            const dataPointPlanPropertyPK = element.dataset.planpropertypk;

            if (lookupsRequired.indexOf(lookupCode) !== -1) {
                const allElements = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasAccordionData[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                let keepGoing: boolean = true;
                if (lookupCode === "inductionPlanV3TopicsEducatorEffectiveness") {
                    const charterCheck = <HTMLInputElement>document.querySelector(`.educatorEffectivenessCharterSchool[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    if (charterCheck.checked)
                        keepGoing = false;
                }
                if (keepGoing) {
                    for (const innerEle of allElements) {
                        const innerElement = <HTMLElement>innerEle;

                        if (innerElement.classList.contains("input-with-add-button")) {
                            //This is the danielson framework element. Handle this differently than the others.
                            const danielsonListElements = document.querySelectorAll(`.addDanielsonFrameworkToList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .addDanielsonFrameworkToListItem`);
                            if (danielsonListElements.length === 0) {
                                innerElement.classList.add("missing-field");
                                innerElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(innerElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            const inputElement = innerElement as HTMLInputElement;

                            if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            } else {
                //get parent accordion element. If it doesnt have a dataPointPlanPropertyPK value (ie. == 0), just ignore it completely, otherwise, it must be completed.
                if (dataPointPlanPropertyPK !== "0") {
                    const allElements = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasAccordionData[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`); 
                    for (const innerEle of allElements) {
                        const innerElement = <HTMLElement>innerEle;

                        if (innerElement.classList.contains("input-with-add-button")) {
                            //This is the danielson framework element. Handle this differently than the others.
                            const danielsonListElements = document.querySelectorAll(`.addDanielsonFrameworkToList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .addDanielsonFrameworkToListItem`);
                            if (danielsonListElements.length === 0) {
                                innerElement.classList.add("missing-field");
                                innerElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(innerElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            const inputElement = innerElement as HTMLInputElement;

                            if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        
        const message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        const goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            const that = this;

            const firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    const accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");

                        const accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// InductionPlanV3Evaluation
class InductionPlanV3Evaluation {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanV3EvaluationField", "inductionPlanV3EvaluationFileUploadField"];

        const inductionPlanV3EvaluationSaveButton = document.getElementById("inductionPlanV3EvaluationSave");
        if (inductionPlanV3EvaluationSaveButton !== null)
            inductionPlanV3EvaluationSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const fileUploader = document.getElementById("inductionPlanV3PlanTopicAreasInductionProgramEvaluationExample");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));

        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());

        this.bindShowDeleteFile();
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const allFileElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3EvaluationForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("inductionPlanV3EvaluationField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const files = document.getElementsByClassName("inductionPlanV3EvaluationFileField");
        for (const ele of files) {
            let element = <HTMLElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = element.dataset.fileuploadpk;
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }

        const data = {
            "FileData": allFileElements,
            "SaveData": allSaveElements,
            "PlanFK": planPK
        };

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3Evaluation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of " + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of " + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }

    uploadFile(e: HTMLInputElement) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        const formFile = <HTMLInputElement>document.querySelector(`#inductionPlanV3PlanTopicAreasInductionProgramEvaluationExample`);
                        formFile.value = "";

                        const fileList = document.querySelector(`.uploadFileList`)

                        const currentFileList = document.querySelectorAll(".inductionPlanV3EvaluationFileField");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        const uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        const fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        const newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        const fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("inductionPlanV3EvaluationFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    bindShowDeleteFile() {
        const files = document.getElementsByClassName("deleteFile");
        for (const file of files)
            file.addEventListener("click", (e: Event) => this.showDeleteFile(e));
    }

    showDeleteFile(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.inductionPlanV3EvaluationFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`#uploadedFiles .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById("uploadedFiles");

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                const modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();
            } else {
                const modal: Modal = new Modal("deleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        const modal: Modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}

// InductionPlanV3Documentation
class InductionPlanV3Documentation {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.checkForOtherRequired();

        this.validationClasses = ["inductionPlanV3DocumentationField", "inductionPlanV3DocumentationRadioField"];

        const inductionPlanV3DocumentationSaveButton = document.getElementById("inductionPlanV3DocumentationSave");
        if (inductionPlanV3DocumentationSaveButton !== null)
            inductionPlanV3DocumentationSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const requiresOthers = document.getElementsByClassName("requiresOther");
        for (const requiresOther of requiresOthers)
            requiresOther.addEventListener("change", (e: Event) => this.checkForOther(e.target as HTMLInputElement));
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3DocumentationForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("inductionPlanV3DocumentationField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("inductionPlanV3DocumentationRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3Documentation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    checkForOther(element: HTMLInputElement) {
        const otherElementContainer = <HTMLDivElement>document.getElementById("otherRow");
        const otherElement = <HTMLTextAreaElement>document.getElementById("inductionPlanV3DocumentationFirstYearTeachersNo");

        if (element.checked) {
            const lookupCode = element.dataset.lookupcode;
            if (lookupCode === "no") {
                otherElementContainer.classList.remove("hide");
                this._core.forceElementRequired(otherElement);
            } else {
                otherElementContainer.classList.add("hide");
                this._core.forceElementOptional(otherElement);
            }
        }
    }

    checkForOtherRequired() {
        const requiresOthers = document.getElementsByClassName("requiresOther");
        for (const requiresOther of requiresOthers)
            this.checkForOther(requiresOther as HTMLInputElement);
    }
}

// InductionPlanV3SignaturesQA
class InductionPlanV3SignaturesQA {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["inductionPlanV3SignaturesQAField", "inductionPlanV3SignaturesQARadioField", "inductionPlanV3SignaturesQACheckboxField"];

        const inductionPlanV3SignaturesQASaveButton = document.getElementById("inductionPlanV3SignaturesQASave");
        if (inductionPlanV3SignaturesQASaveButton !== null)
            inductionPlanV3SignaturesQASaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3SignaturesQAForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("inductionPlanV3SignaturesQAField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const radios = document.getElementsByClassName("inductionPlanV3SignaturesQARadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }

        const checkboxes = document.getElementsByClassName("inductionPlanV3SignaturesQACheckboxField");
        for (const ele of checkboxes) {
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPK = 0;
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            const saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };

            allSaveElements.push(saveItem);
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3SignaturesQA', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// InductionPlanV3Summary
class InductionPlanV3Summary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        const backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        const submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        const newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        const element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            const planFK = parseInt(element.dataset.planfk);
            const core = this._core;
            Core.showLoader();
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
