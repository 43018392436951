class Core {
    Workflows: Workflow[] = [];

    constructor() {
        let that = this;
        that.initializeDeleteChallenge();
        that.initializeDeleteStrength();
        that.initializeWorkflows();

        let completePlanPageButton = document.getElementById("updatePlanPageComplete");
        if (completePlanPageButton !== null)
            completePlanPageButton.addEventListener("click", (e: Event) => this.completePlanPage(e));

        Tab.Init();
    }

    static a11yClick(e: KeyboardEvent): boolean {
        if (e.type === "keypress") {
            let code = e.which;
            if (code === 32 || code === 13) {
                return true;
            }
        }
        
        return false;
    }

    activateMenu(controls: Array<string>, section: string): void {
        //Clear the menu active classes
        let readyMenu = document.getElementById("readySection");
        if (readyMenu !== null) {
            let readyMenus = readyMenu.querySelectorAll("li");

            for (let link of readyMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        let setMenu = document.getElementById("setSection");
        if (setMenu !== null) {
            let setMenus = setMenu.querySelectorAll("li");

            for (let link of setMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        let goMenu = document.getElementById("goSection");
        if (goMenu) {
            let goMenus = goMenu.querySelectorAll("li");

            for (let link of goMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        for (let link of controls) {
            let parentId = link + "Parent";
            let element = document.getElementById(parentId);
            if(element !== null)
                element.classList.add("active");
        }

        //Deal with the open/closed state of the sections
        let leftBar = document.getElementById("leftBarAccordionGroup");
        let topLevels = leftBar.getElementsByClassName("Accordion-trigger");
        for (let top of topLevels) {
            if (top.classList.contains("open")) {
                top.classList.remove("open");
            }
        }

        let innerTopLevels = leftBar.getElementsByClassName("Accordion-panel");
        for (let inner of innerTopLevels) {
            if (inner.classList.contains("open")) {
                inner.classList.remove("open");
            }
        }

        let sectionName = section + "Section";
        let accordionName = section + "Accordion";

        let sectionElement = document.getElementById(sectionName);
        if(sectionElement !== null)
            sectionElement.classList.add("open");

        let accordionElement = document.getElementById(accordionName);
        if(accordionElement !== null)
            accordionElement.classList.add("open");
    }

    activateMenuSpecialEducationIU(controls: Array<string>, section: string): void {
        //Clear the menu active classes
        let profileMenu = document.getElementById("profileandPlanEssentialsSection");
        if (profileMenu !== null) {
            let profileMenus = profileMenu.querySelectorAll("li");

            for (let link of profileMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        let useOfStateFundsMenu = document.getElementById("useofStateFundsSection");
        if (useOfStateFundsMenu !== null) {
            let useOfStateFundsMenus = useOfStateFundsMenu.querySelectorAll("li");

            for (let link of useOfStateFundsMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        let useOfFederalFundsMenu = document.getElementById("useofFederalFundsSection");
        if (useOfFederalFundsMenu) {
            let useOfFederalFundsMenus = useOfFederalFundsMenu.querySelectorAll("li");

            for (let link of useOfFederalFundsMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        let profilesSectionMenu = document.getElementById("profilesSection");
        if (profilesSectionMenu) {
            let profilesSectionMenus = profilesSectionMenu.querySelectorAll("li");

            for (let link of profilesSectionMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        let technicalAssistanceMenu = document.getElementById("technicalAssistanceSection");
        if (technicalAssistanceMenu) {
            let technicalAssistanceMenus = technicalAssistanceMenu.querySelectorAll("li");

            for (let link of technicalAssistanceMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }

        for (let link of controls) {
            let parentId = link + "Parent";
            let element = document.getElementById(parentId);
            if (element !== null)
                element.classList.add("active");
        }

        //Deal with the open/closed state of the sections
        let leftBar = document.getElementById("leftBarAccordionGroup");
        let topLevels = leftBar.getElementsByClassName("Accordion-trigger");
        for (let top of topLevels) {
            if (top.classList.contains("open")) {
                top.classList.remove("open");
            }
        }

        let innerTopLevels = leftBar.getElementsByClassName("Accordion-panel");
        for (let inner of innerTopLevels) {
            if (inner.classList.contains("open")) {
                inner.classList.remove("open");
            }
        }

        let sectionName = section + "Section";
        let accordionName = section + "Accordion";

        let sectionElement = document.getElementById(sectionName);
        if (sectionElement !== null)
            sectionElement.classList.add("open");

        let accordionElement = document.getElementById(accordionName);
        if (accordionElement !== null)
            accordionElement.classList.add("open");
    }

    insertAfter(el, referenceNode) {
        referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
    }

    pageReload(fromSave: boolean = null, planFK: number = null) {
        if (fromSave !== null && fromSave === true) {
            let url = window.location.href;
            var alertDiv = document.getElementById("alertDiv");
            alertDiv.setAttribute("data-saved", "from-save");
            if (url.indexOf("fromSave") === -1) {

                if (window.location.search.length > 0) {
                    url += "&fromSave=" + fromSave.toString();
                } else {
                    url += "?fromSave=" + fromSave.toString();
                }

                window.location.href = url;
            } else {
                window.location.reload();
            }
        } else {
            window.location.reload();
        }
    }

    static createHTMLAlert(id, message, type, timeout, callback) {
        let that = this;
        var alertDiv = document.getElementById("alertDiv");
        //alertDiv.setAttribute("data-saved", type);
        alertDiv.style.top = "10px";
        var alertContainerDiv = document.getElementById("alertContainerDiv");
        var alertBox = document.getElementById(id);
        alertBox.innerHTML = message;
        alertBox.style.display = "block";
        alertDiv.style.display = "block";

        Core.createLiveAlert(message);

        //TODO: Make these classes instead of inline. Add and remove as needed.
        switch (type) {
            case 'alert':
            case 'notification':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#FFF';
                alertContainerDiv.style.borderColor = '#CCC';
                break;
            case 'warning':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#fadd84';
                alertContainerDiv.style.borderColor = '#FFC237';
                break;
            case 'error':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#f87676';
                alertContainerDiv.style.borderColor = 'darkred';
                alertBox.style.fontWeight = 'bold';

                break;
            case 'information':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#57B7E2';
                alertContainerDiv.style.borderColor = '#0B90C4';
                break;
            case 'success':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#acdeb2';
                alertContainerDiv.style.borderColor = '#50C24E';
                break;
            default:
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#FFF';
                alertContainerDiv.style.borderColor = '#CCC';
                break;
        }

        if (timeout) {

            return setTimeout(function () {
                alertBox.style.display = "none";
                alertDiv.style.display = "none";
                alertBox.innerHTML = "";
                alertDiv.style.top = "0";
                if (callback && typeof (callback) === 'function') {
                    callback();
                }
            }, timeout);
        }
        else {
            if (callback && typeof (callback) === 'function') {
                callback();
            }

            return false;
        }
    }

    static createLiveAlert(message) {
        // set the message in the html containing the aria-live attribute
        // this will iniate the screen reader to read the message
        $('#alertDivHidden').html(message);

        // this clears out whatever is in the html for the aria-live attribute
        // the screen reader will only read new html inside of it, so you should really clear it out after using it
        setTimeout(function () {
            $('#alertDivHidden').html("");
        }, 2000);
    }

    doValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    } else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");

                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked: boolean = false;
                                    let isAlreadyValidated: boolean = false;

                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = <HTMLInputElement>radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }

                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }

                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            } else {
                                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox")
                                {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                } else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            } else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if(!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }   
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    initializeRequiredFields(allClasses: string[], refresh: boolean = false, allowDuplicates = false) {

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        if (refresh) {
            let allElements = document.querySelectorAll(classesToValidate);

            for (let element of allElements) {
                let htmlElement = <HTMLElement>element;

                htmlElement.removeAttribute("aria-required");
                let label = Core.findLabelForInput(htmlElement);

                if (label !== null) {
                    label.classList.remove("isRequired");
                    let asterisk = label.querySelector(".required-label") as HTMLElement;
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                        alreadyExists = true;
                    } 
                }

                if (!alreadyExists || allowDuplicates) {

                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        let label = Core.findLabelForInput(htmlElement);

                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }

    forceElementRequired(element: HTMLElement) {
        element.dataset.percent = "1.00";
        element.setAttribute("aria-required", "true");
        element.dataset.forcerequired = "true";
        let label = Core.findLabelForInput(element);

        if (label !== null && !label.classList.contains("isRequired")) {
            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
            label.classList.add("isRequired");
        }
    }

    forceElementOptional(element: HTMLElement) {
        element.dataset.percent = "0.00";
        element.setAttribute("aria-required", "false");
        element.dataset.forcerequired = "false";
        let label = Core.findLabelForInput(element);

        if (label !== null) {
            label.classList.remove("isRequired");
            let requiredLabel = label.querySelector(".required-label");
            if (requiredLabel !== null) {
                requiredLabel.parentNode.removeChild(requiredLabel);
            }
        }
    }

    static trapFocus(element: HTMLElement) {
        var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), img:not([disabled])');
        var firstFocusableEl = <HTMLElement>focusableEls[0];
        var lastFocusableEl = <HTMLElement>focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;

        element.addEventListener('keydown', function (e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }

        });
    }

    //Returns label for element (either labelled by or label for). Returns null if no label found
    static findLabelForInput(element: HTMLElement) {

        if (element.hasAttribute("data-label") && element.getAttribute("data-label") !== "") {
            let dataLabel = element.getAttribute("data-label");
            let labelForEl = document.querySelector(`#${dataLabel}`);

            if (labelForEl !== null) {
                return labelForEl;
            }
        }

        if (element.hasAttribute("aria-labelledby") && element.getAttribute("aria-labelledby") !== "") {
            let labelledBy = element.getAttribute("aria-labelledby");
            let labelledByEl = document.querySelector(`#${labelledBy}`);

            if (labelledByEl !== null) {
                return labelledByEl;
            }    
        }

        if (element.hasAttribute("id") && element.getAttribute("id") !== "") {
            let label = document.querySelector(`label[for=${element.id}]`);

            if (label !== null) {
                return label;
            }
        }

        if (element.parentNode instanceof HTMLTableCellElement) {
            //Testing the cases where label is a <th> in the table.
            let parentElement = element.parentElement;
            let index = Array.prototype.indexOf.call(parentElement.parentNode.children, parentElement)
            //let correspondingTable = element.closest("table");
            let correspondingTable = Core.findClosest(element, "table")

            if (correspondingTable !== null) {
                let correspondingTh = correspondingTable.querySelector(`th:nth-child(${index + 1})`);
                if (correspondingTh !== null) {
                    return correspondingTh;
                }
            } 
        } 

        return null;
    }

    //https://stackoverflow.com/a/24107550/158722
    static findClosest(el, selector) {
        let matchesFn;

        // find vendor prefix
        ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
            if (typeof document.body[fn] == 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        })

        let parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    }

    //Puts exclamation mark on labels for invalid fields
    static createErrorLabelForInput(element: HTMLElement) {
        let label = Core.findLabelForInput(element);
        if (label !== null && !label.classList.contains("hasBeenValidated")) {
            label.classList.add("hasBeenValidated");
            label.innerHTML = "<span class='missing-field-label'><i class='fas fa-exclamation-triangle' aria-hidden='true'></i></span> " + label.innerHTML;
        }
    }

    static removeErrorLabelForInput(element: HTMLElement) {
        let label = Core.findLabelForInput(element) as HTMLElement;
        if (label !== null && label.classList.contains("hasBeenValidated")) {
            label.classList.remove("hasBeenValidated");
            label.removeChild(label.querySelector(".missing-field-label"));
        }
    }

    static showLoader() {
        let loaderElement = document.getElementById("loader");
        if (loaderElement !== null) {
            let imageElement = document.getElementById("loaderImage");
            if (imageElement !== null) {
                imageElement.focus();
                Core.trapFocus(imageElement);
            }

            loaderElement.classList.add("open");
        }
    }

    static hideLoader() {
        let loaderElement = document.getElementById("loader");
        if (loaderElement !== null) {
            loaderElement.classList.remove("open");
        }
    }

    static showLongLoader() {
        let loaderElement = document.getElementById("longLoader");
        if (loaderElement !== null) {
            let imageElement = document.getElementById("loaderImage");
            if (imageElement !== null) {
                imageElement.focus();
                Core.trapFocus(imageElement);
            }

            loaderElement.classList.add("open");
        }
    }

    static hideLongLoader() {
        let loaderElement = document.getElementById("longLoader");
        if (loaderElement !== null) {
            loaderElement.classList.remove("open");
        }
    }

    activateSubMenu(menuId: string) {
        let allMenus = document.getElementsByClassName("subMenuElement");
        for (let menu of allMenus) {
            if (menu.classList.contains("active")) {
                menu.classList.remove("active");
            }
        }

        let newActive = document.getElementById(menuId);
        if(newActive !== null)
            newActive.classList.add("active");
    }

    createHash(allClasses: string[]) {
        let hash = "0";
        let value;
        let newHash;
        for (let thisClass of allClasses) {
            let allElements = document.getElementsByClassName(thisClass);

            for (let element of allElements) {
                if (element instanceof HTMLInputElement) {
                    let inputElement = <HTMLInputElement>element;

                    if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && inputElement.checked) {
                        value = 1;
                        hash = hash + value;
                    }
                    if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox" && !inputElement.checked) {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox" && inputElement.checked) {
                        value = 1;
                        hash = hash + value;
                    }
                    else if (inputElement.value === "") {
                        value = 0
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = this.hashCode(value);
                        hash = hash + newHash;
                    }
                } else if (element instanceof HTMLSelectElement) {
                    let selectElement = <HTMLSelectElement>element;
                    if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                        value = selectElement.options[selectElement.selectedIndex].value
                        newHash = this.hashCode(value);
                        hash = hash + newHash;
                    }
                } else if (element instanceof HTMLTextAreaElement) {
                    let textAreaElement = <HTMLTextAreaElement>element;

                    if (textAreaElement.classList.contains("mce") && textAreaElement.hasAttribute("id") && tinymce.get(textAreaElement.getAttribute("id")) != null) {

                        let mceValue = tinymce.get(textAreaElement.getAttribute("id")).getContent();

                        if (mceValue === "") {
                            value = 0
                            hash = hash + value;
                        }
                        else {
                            value = mceValue;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }

                    } else {

                        if (textAreaElement.value === "") {
                            value = 0
                            hash = hash + value;
                        }
                        else {
                            value = textAreaElement.value;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                }
                    //putting in special check for danielson frameworks
                else if (element instanceof HTMLDivElement) {
                    let divElement = <HTMLDivElement>element;
                    if (divElement.hasAttribute("data-danielsonlookupcodepk")) {
                        if (divElement.dataset.danielsonlookupcodepk === "") {
                            value = 0
                            hash = hash + value;
                        }
                        else {
                            value = divElement.dataset.danielsonlookupcodepk;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                    else if (divElement.hasAttribute("data-lookupcodepk")) {
                        if (divElement.dataset.lookupcodepk === "") {
                            value = 0
                            hash = hash + value;
                        }
                        else {
                            value = divElement.dataset.lookupcodepk;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                    else {
                        if (divElement.dataset.planpropertypk === "" || !divElement.dataset.planpropertypk) {
                            value = 0
                            hash = hash + value;
                        }
                        else {
                            value = divElement.dataset.planpropertypk;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                }
            }
        }
        return hash
    }

    hashCode(s) {
        let h;
        for (let i = 0; i < s.length; i++)
            h = (Math.imul(31, h) + s.charCodeAt(i) | 0).toString();

        return h;
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    continue(e: Event) {
        let newPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    checkSave(myClass: any) {
        var newHash = myClass.createHash();
        let hiddenInputElement = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInputElement !== null) {
            let hiddenInput = hiddenInputElement.value;
            if (newHash !== hiddenInput) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    leftnav(page: any) {
        let that = this;
        var listItems = document.getElementsByClassName("navLink");
        let isSave = (typeof page.save === 'function');
        for (let li of listItems) {
            let element = <HTMLElement>document.getElementById(li.id);
            let dataRedirectUrl = element.getAttribute("data-redirect-url");

            if (isSave === true) {
                li.addEventListener("click", (e: Event) => {                    
                    if(that.Workflows.length > 0) {
                        Workflow.SaveAllWorkflows(that.Workflows)
                        .then((resolve) => {
                            page.save(dataRedirectUrl);
                        });
                    } else {
                        page.save(dataRedirectUrl);
                    }
                });
            }
            else {
                li.addEventListener("click", (e: Event) => {
                    let promiseArray: Promise<string>[] = [];
                    
                    if(that.Workflows.length > 0) {
                        Workflow.SaveAllWorkflows(that.Workflows)
                        .then((values) => {
                            window.location.href = dataRedirectUrl;
                        });
                    } else {
                        window.location.href = dataRedirectUrl;
                    }
                });
            }
        }

    }

    tabLinkSave(page: any) {
        var tablinks = document.getElementsByClassName("tablinks") as HTMLCollectionOf<HTMLElement>;
        let isSave = (typeof page.save === 'function');
        for (let tab of tablinks) {
            let dataRedirectUrl = tab.getAttribute("data-redirect-url");

            if (isSave === true) {
                tab.addEventListener("click", (e: Event) => page.save(dataRedirectUrl));
            } else {
                tab.addEventListener("click", (e: Event) => window.location.href = dataRedirectUrl);
            }
        }
    }

    initializeDeleteChallenge() {
        let core = this;
        let modal = new Modal("deleteChallengeModal", null);

        //Use event delegation to add event listener to document, not delete buttons. This is so we don't have to re-add event listeners when rows are added dynamically.
        document.addEventListener("click", function (event) {
            let button = <HTMLElement>event.target;
            if (button.classList.contains("deleteChallenge") && "container" in button.dataset) {
                if ("challengeplanpropertypk" in button.dataset && parseInt(button.dataset.challengeplanpropertypk) > 0) {
                    modal.show();
                    if (button.id) {
                        modal.callingId = button.id;
                    }
                    modal.addAttributeToElement(modal.id, "#deleteChallengeConfirm", "challengeplanpropertypk", button.dataset.challengeplanpropertypk);
                    modal.addAttributeToElement(modal.id, "#deleteChallengeConfirm", "container", button.dataset.container);
                } else {
                    let container = document.getElementById(button.dataset.container);
                    if (container != null && "removecontainer" in button.dataset && button.dataset.removecontainer == 'true') {
                        let nextFocusable = Core.getNextFocusableElement(button);
                        container.parentNode.removeChild(container);
                        nextFocusable.focus();
                        Core.createHTMLAlert("alertMessageDiv", "Challenge row removed", "success", 3000, null);
                    } else if(container != null && container.querySelector('textarea') != null) {
                        let challenge = container.querySelector('textarea');
                        challenge.value = '';
                        challenge.dataset.planpropertypk = '0';
                        Core.createHTMLAlert("alertMessageDiv", "Challenge row cleared", "success", 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Error clearing challenge row", "success", 3000, null);
                    }
                }
            }
        });

        let deleteChallengeConfirmButton = document.getElementById("deleteChallengeConfirm");
        if (deleteChallengeConfirmButton != null) {
            deleteChallengeConfirmButton.addEventListener("click", function () {
                modal.hide();
                if ("challengeplanpropertypk" in deleteChallengeConfirmButton.dataset && parseInt(deleteChallengeConfirmButton.dataset.challengeplanpropertypk) > 0 && "container" in deleteChallengeConfirmButton.dataset) {
                    //Make call to delete challenge
                    Core.showLoader();
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/Base/DeleteChallenge', true);
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.onload = function () {
                        if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                            
                            Core.createHTMLAlert("alertMessageDiv", "Challenge successfully deleted", 'success', 3000, null);

                            let container = document.getElementById(deleteChallengeConfirmButton.dataset.container);

                            if (container != null) {
                                let deleteChallengeButton = container.querySelector(`.deleteChallenge[data-challengeplanpropertypk='${deleteChallengeConfirmButton.dataset.challengeplanpropertypk}']`) as HTMLButtonElement;
                                if (deleteChallengeButton != null) {
                                    deleteChallengeButton.dataset.challengeplanpropertypk = '0';
                                }

                                if (deleteChallengeButton != null && "removecontainer" in deleteChallengeButton.dataset && deleteChallengeButton.dataset.removecontainer == "true") {
                                    let nextFocusable = Core.getNextFocusableElement(deleteChallengeButton);
                                    container.parentNode.removeChild(container);
                                    nextFocusable.focus();
                                } else {
                                    let challenge = container.querySelector(`[data-planpropertypk='${deleteChallengeConfirmButton.dataset.challengeplanpropertypk}']`) as HTMLTextAreaElement;
                                    if (challenge != null) {
                                        challenge.value = '';
                                        challenge.dataset.planpropertypk = '0';
                                        deleteChallengeButton.focus();
                                    }
                                }
                            }
                        }
                        else {
                            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting challenge", "error", 3000, null);
                        }
                        Core.hideLoader();
                    };
                    xhr.send(deleteChallengeConfirmButton.dataset.challengeplanpropertypk);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Error: challenge could not be removed", "error", 3000, null);
                }
            });
        }

        let deleteChallengeCancelButton = document.getElementById("deleteChallengeCancel");
        if (deleteChallengeCancelButton != null) {
            deleteChallengeCancelButton.addEventListener("click", function () {
                modal.hide();
            });
        }
    }

    initializeDeleteStrength() {
        let core = this;
        let deleteStrengthButtons = document.getElementsByClassName("deleteStrength") as HTMLCollectionOf<HTMLButtonElement>;
        let modal = new Modal("deleteStrengthModal", null);

        document.addEventListener("click", function (event) {
            let button = <HTMLElement>event.target;
            if (button.classList.contains("deleteStrength") && "container" in button.dataset) {
                if ("strengthplanpropertypk" in button.dataset && parseInt(button.dataset.strengthplanpropertypk) > 0) {
                    modal.show();
                    if (button.id) {
                        modal.callingId = button.id;
                    }
                    modal.addAttributeToElement(modal.id, "#deleteStrengthConfirm", "strengthplanpropertypk", button.dataset.strengthplanpropertypk);
                    modal.addAttributeToElement(modal.id, "#deleteStrengthConfirm", "container", button.dataset.container);
                } else {
                    let container = document.getElementById(button.dataset.container);
                    if (container != null && "removecontainer" in button.dataset && button.dataset.removecontainer == 'true') {
                        let nextFocusable = Core.getNextFocusableElement(button);
                        container.parentNode.removeChild(container);
                        Core.createHTMLAlert("alertMessageDiv", "Strength row removed", "success", 3000, null);
                        nextFocusable.focus();
                    } else if (container != null && container.querySelector('textarea') != null) {
                        let strength = container.querySelector('textarea');
                        strength.value = '';
                        strength.dataset.planpropertypk = '0';
                        Core.createHTMLAlert("alertMessageDiv", "Strength row cleared", "success", 3000, null);
                    } else {
                        Core.createHTMLAlert("alertMessageDiv", "Error clearing strength row", "success", 3000, null);
                    }
                }
            }
        });

        let deleteStrengthConfirmButton = document.getElementById("deleteStrengthConfirm");
        if (deleteStrengthConfirmButton != null) {
            deleteStrengthConfirmButton.addEventListener("click", function () {
                modal.hide();
                if ("strengthplanpropertypk" in deleteStrengthConfirmButton.dataset && parseInt(deleteStrengthConfirmButton.dataset.strengthplanpropertypk) > 0 && "container" in deleteStrengthConfirmButton.dataset) {
                    //Make call to delete strength
                    Core.showLoader();
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/Base/DeleteStrength', true);
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.onload = function () {
                        if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {

                            Core.createHTMLAlert("alertMessageDiv", "Strength successfully deleted", 'success', 3000, null);

                            let container = document.getElementById(deleteStrengthConfirmButton.dataset.container);

                            if (container != null) {
                                let deleteStrengthButton = document.querySelector(`.deleteStrength[data-strengthplanpropertypk='${deleteStrengthConfirmButton.dataset.strengthplanpropertypk}']`) as HTMLButtonElement;
                                if (deleteStrengthButton != null) {
                                    deleteStrengthButton.dataset.strengthplanpropertypk = '0';
                                }

                                if (deleteStrengthButton != null && "removecontainer" in deleteStrengthButton.dataset && deleteStrengthButton.dataset.removecontainer == "true") {
                                    let nextFocusable = Core.getNextFocusableElement(deleteStrengthButton);
                                    container.parentNode.removeChild(container);
                                    nextFocusable.focus();
                                } else {
                                    let strength = container.querySelector(`[data-planpropertypk='${deleteStrengthConfirmButton.dataset.strengthplanpropertypk}']`) as HTMLTextAreaElement;
                                    if (strength != null) {
                                        strength.value = '';
                                        strength.dataset.planpropertypk = '0';
                                        if (deleteStrengthButton != null) {
                                            deleteStrengthButton.focus();
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting strength", "error", 3000, null);
                        }
                        Core.hideLoader();
                    };
                    xhr.send(deleteStrengthConfirmButton.dataset.strengthplanpropertypk);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "Error: strength could not be removed", "error", 3000, null);
                }
            });
        }

        let deleteStrengthCancelButton = document.getElementById("deleteStrengthCancel");
        if (deleteStrengthCancelButton != null) {
            deleteStrengthCancelButton.addEventListener("click", function () {
                modal.hide();
            });
        }
    }

    bindChangeLEA() {
        let leaChange = document.getElementById("changeLEA");

        if (leaChange !== null) {
            leaChange.addEventListener("change", this.changeLEA.bind(this), false);
        }
    }

    private changeLEA() {
        let core = this;
        let LEAElement = <HTMLSelectElement>document.getElementById("changeLEA");

        let institutionPKValue = LEAElement.value;
        if (institutionPKValue === "") {
            return false;
        }

        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/SwitchLEA', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let newURL = Core.removeURLParameter(window.location.href, "PageNumber");
                Core.createHTMLAlert("alertMessageDiv", "LEA changed, reloading new information", 'success', 3000, function(){
                    window.location.href = newURL
                });
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an error switching LEA. Please try again later", 'error', 3000, null);
            }
        };

        xhr.send(institutionPKValue);
    }

    exportDocx(e: Event, action: string, fileName: string) {
        let core = this;
        let button = <HTMLButtonElement>e.target;
        if (button.dataset.planfk) {
            Core.showLoader();

            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportDocx/${action}?planFK=${button.dataset.planfk}`, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = "blob";
            xhr.onload = function () {

                Core.hideLoader();

                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `${fileName}.docx`;

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = <HTMLAnchorElement>document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
                }
            }
            xhr.send();
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an error exporting to Word. Please try again later.", 'warning', 3000, null);
        }
    }

    //Adapted from https://stackoverflow.com/questions/7208161
    //Returns the next focusable element, given an element.
    static getNextFocusableElement(element: HTMLElement) : HTMLElement {
        //add all elements we want to include in our selection
        var focussableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), input[type=number]:not([disabled]), textarea:not([disabled]), select:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

        var focussable = Array.prototype.filter.call(document.querySelectorAll(focussableElements),
            function (el) {
                //check for visibility while always include the current activeElement 
                return el.offsetWidth > 0 || el.offsetHeight > 0 || el === element
            });
        var index = focussable.indexOf(element);
        if (index > -1) {
            var nextElement = focussable[index + 1] || focussable[0];
            return nextElement as HTMLElement;
        }
    }

    static refreshProfile(formName: string) {
        Core.showLoader();
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/GetICDataForProfile', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {

                let zipCodeElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='Zip']");
                let stateElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='State']");
                let cityElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='City']");
                let addressLine2Element = <HTMLInputElement>document.querySelector(".profileICData[data-field='Address2']");
                let addressLine1Element = <HTMLInputElement>document.querySelector(".profileICData[data-field='Address1']");
                let aunElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='AUN']");
                let leaNameElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='Name']");
                let leaPhoneElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='LEAPhone']");
                let leaPhoneExtElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='LEAPhoneExt']");
                let leaTypeElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='LEAType']");
                               
                

                let instDetails = JSON.parse(xhr.response);

                if (leaNameElement)
                    leaNameElement.value = instDetails.instDetails.instName;
                if (aunElement)
                    aunElement.value = instDetails.instDetails.auNumber;
                if (addressLine1Element)
                    addressLine1Element.value = instDetails.instDetails.addressLine1;
                if (addressLine2Element)
                    addressLine2Element.value = instDetails.instDetails.addressLine2;
                if (cityElement)
                    cityElement.value = instDetails.instDetails.city;
                if (stateElement)
                    stateElement.value = instDetails.instDetails.state;
                if (zipCodeElement)
                    zipCodeElement.value = instDetails.instDetails.zipCode;
                if (leaPhoneElement)
                    leaPhoneElement.value = instDetails.instDetails.phoneNumber;
                if (leaPhoneExtElement)
                    leaPhoneExtElement.value = instDetails.instDetails.phoneNumberExtn;
                if (leaTypeElement)
                    leaTypeElement.value = instDetails.instDetails.instCategory;


                let superEmailElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperEmail']");
                let superPhoneExtElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperPhoneExt']");
                let superPhoneElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperPhone']");
                let superNameElement = <HTMLInputElement>document.querySelector(".profileICData[data-field='SuperName']");


                if (superEmailElement)
                    superEmailElement.value = instDetails.superintendentInfo.email;
                if (superPhoneElement)
                    superPhoneElement.value = instDetails.superintendentInfo.phoneNumber;
                if (superNameElement)
                    superNameElement.value = instDetails.superintendentInfo.adminName;

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Profile Data has been refreshed, please save the changes.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }

    static PlanSearch(pageNumber: number, url: string) {
        let query = this.GetPlanQueryModel(pageNumber);

        let params = "";
        for (var key in query) {
            if(query[key] !== null && query[key] !== "") {
                if (params != "") {
                    params += "&";
                }
                params += key + "=" + encodeURIComponent(query[key]);
            }
        }

        window.location.href = url + "?" + params;
    }

    static GetPlanQueryModel(pageNumber: number) {
        let query: IPlanQueryModel = {
            LEAName: "",
            PageNumber: pageNumber,
            PhaseCodeFK: null,
            PlanTypeGroupCode: "",
            PlanTypePK: null,
            SchoolName: "",
            StatusPKs: "",
            Year: null,
            AUNumber: ""
        };

        let yearElement = <HTMLSelectElement>document.getElementById("planYear");
        let leaNameElement = <HTMLInputElement>document.getElementById("leaName");
        let statusElement = <HTMLDivElement>document.getElementById("planStatus");
        let typeElement = <HTMLSelectElement>document.getElementById("planType");
        let phaseElement = <HTMLSelectElement>document.getElementById("planPhase");
        let categoryElement = <HTMLSelectElement>document.getElementById("planTypeGroup");
        let schoolNameElement = <HTMLSelectElement>document.getElementById("schoolName");
        let auNumberElement = <HTMLInputElement>document.getElementById("auNumber");

        if (yearElement != null && yearElement.selectedIndex > 0 && parseInt(yearElement.value)) {
            query.Year = parseInt(yearElement.value);
        }

        if (leaNameElement != null && leaNameElement.value != "") {
            query.LEAName = leaNameElement.value;
        }

        if (schoolNameElement != null && schoolNameElement.value != "") {
            query.SchoolName = schoolNameElement.value;
        }

        if (categoryElement != null && categoryElement.selectedIndex > 0) {
            query.PlanTypeGroupCode = categoryElement.value;
        }

        if (statusElement != null) {
            let string = "";
            let selection = statusElement.querySelectorAll("ul li.selected");
            if (selection.length > 0) {
                for (let s of selection) {
                    let sequenceLength = selection.length;
                    let sElement = <HTMLLIElement>s;
                    let nextElement = sElement.querySelector("input");
                    let inputElement = <HTMLElement>nextElement;
                    string = string + inputElement.dataset.statusfk + ",";
                }
            }
            query.StatusPKs = string;
        }

        if (typeElement != null && typeElement.selectedIndex > 0 && parseInt(typeElement.value)) {
            query.PlanTypePK = parseInt(typeElement.value);
        }

        if (phaseElement != null && phaseElement.selectedIndex > 0 && parseInt(phaseElement.value)) {
            query.PhaseCodeFK = parseInt(phaseElement.value);
        }

        if (auNumberElement != null && auNumberElement.value != "") {
            query.AUNumber = auNumberElement.value;
        }

        return query;
    }

    static removeURLParameter(url: string, parameter: string) {
        var urlparts = url.split('?');   
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            for (var i = pars.length; i-- > 0;) {    
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    updatePlanDueDate(e: Event) {
        let button = <HTMLButtonElement>e.currentTarget;
        let datePickerId = button.dataset.datepickerid;
        let planPK = parseInt(button.dataset.planpk);

        let saveObject: IPlanAdminSaveDueDate;

            let datepicker = document.getElementById(datePickerId);
            //let datepicker = document.querySelector(".adminDatePicker[data-planpkcompletiondate='" + elementPlanPK + "']");
            let datePickerElement = <HTMLInputElement>datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.

            saveObject = {
                PlanPK: planPK,
                DueDate: dueDate
            };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/AdminSavePlanDueDate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    bindDeleteOwner() {
        const deleteownerConfirmButton = document.getElementById("ownerDeleteConfirm");
        if (deleteownerConfirmButton !== null)
            deleteownerConfirmButton.addEventListener("click", (e: Event) => this.deleteOwnerConfirm(e));

        const deleteOwnerCancelButton = document.getElementById("ownerDeleteCancel");
        if (deleteOwnerCancelButton !== null)
            deleteOwnerCancelButton.addEventListener("click", (e: Event) => this.deleteOwnerCancel());

        const allDeletes = document.getElementsByClassName("deleteOwner");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showOwnerDelete(e));
    }

    showOwnerDelete(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let ownerpk = buttonElement.dataset.ownerpk;
        let institutionpk = buttonElement.dataset.institutionpk;
        let plantypefk = buttonElement.dataset.plantypefk;

        let modal: Modal = new Modal("deleteOwnerModal", null);
        modal.addAttributeToElement("deleteOwnerModal", "#ownerDeleteConfirm", "ownerpk", ownerpk);
        modal.addAttributeToElement("deleteOwnerModal", "#ownerDeleteConfirm", "institutionpk", institutionpk);
        modal.addAttributeToElement("deleteOwnerModal", "#ownerDeleteConfirm", "plantypefk", plantypefk);
        modal.show();
    }

    deleteOwnerCancel() {
        let modal: Modal = new Modal("deleteOwnerModal", null);
        modal.hide();
    }

    deleteOwnerConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let ownerpk = parseInt(buttonElement.dataset.ownerpk);
        let institutionpk = parseInt(buttonElement.dataset.institutionpk);
        let plantypefk = parseInt(buttonElement.dataset.plantypefk);
        if (ownerpk != 0) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Base/DeleteOwner', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();

                    //let element = document.getElementById("planOwner");
                    //element.remove();

                    let modal: Modal = new Modal("deleteOwnerModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The data has been removed.  Please refresh the page if you want to see new data.", 'success', 3000, null);
                } else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("userFK=" + ownerpk + "&frppInstitutionFk=" + institutionpk + "&planTypeFK=" + plantypefk);
        }
    }

    updateLockoutException(e: Event) {
        let that = this;
        let checkmark = <HTMLInputElement>e.currentTarget;
        let planPK = parseInt(checkmark.dataset.planpk);
        let planLockoutException = parseInt(checkmark.dataset.lockoutexceptionpk);

        let saveObject: ISavePlanLockoutException;

        if (planLockoutException === 0) {
            saveObject = {
                PlanLockoutExceptionPK: planLockoutException,
                PlanTypeLockoutFK: 0,//update this in the save method
                PlanFK: planPK,
                ModifiedByUserName: "",
                ModifiedDate: new Date(),
                IsDeletedInd: false
            };
        }

        if (planLockoutException > 0 && checkmark.checked) {
            saveObject = {
                PlanLockoutExceptionPK: planLockoutException,
                PlanTypeLockoutFK: 0,//update this in the save method
                PlanFK: planPK,
                ModifiedByUserName: "",
                ModifiedDate: new Date(),
                IsDeletedInd: false
            };
        }

        if (planLockoutException > 0 && !checkmark.checked) {
            saveObject = {
                PlanLockoutExceptionPK: planLockoutException,
                PlanTypeLockoutFK: 0,//update this in the save method
                PlanFK: planPK,
                ModifiedByUserName: "",
                ModifiedDate: new Date(),
                IsDeletedInd: true
            };
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePlanLockoutException', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, that.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }

    static autocomplete(inp, arr) {

        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/

        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists(e);

            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y: any;
            if (x) y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });

        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    completePlanPage(e: Event) {
        let that = this;
        let button = <HTMLButtonElement>e.currentTarget;
        let planpk = button.dataset.planpk;
        let pagecode = button.dataset.pagecode;

        let xhr = new XMLHttpRequest();

        xhr.open('POST', '/Admin/AdminCompletePlanPage', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, that.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planPK=" + planpk + "&pageCode=" + pagecode);
    }

    initializeWorkflows()
    {
        let that = this;
        that.Workflows = [];
        let allActions = document.querySelectorAll("[data-action][data-workflow-instance-fk]") as NodeListOf<HTMLElement>;
        if(allActions != null && allActions.length > 0) {
            let distinctWorkflows = [... new Set([...allActions].map(el => parseInt(el.dataset.workflowInstanceFk)))];
            distinctWorkflows.forEach(workflowInstancePK => {
                that.Workflows.push(new Workflow(workflowInstancePK));
            });
        }
    }

    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------

    async serverSideValidation(serverSideMethodName: string, planFK: number, pageCode: string) {
        const response = await fetch(`${serverSideMethodName}/${planFK}/${pageCode}/false`, { credentials: 'include' })
        if (response.ok) {

        }
    }

    newValidation(allClasses: string[], showMessageOverride?: boolean) {
        const showMessage = showMessageOverride === undefined ? this.newClientSideValidation(allClasses) : showMessageOverride;
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            const message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            const successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    newClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            const htmlElement = <HTMLElement>element;
            if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                if (!element.classList.contains("missing-field")) {
                    if (element instanceof HTMLInputElement) {
                        const inputElement = <HTMLInputElement>element;

                        //Only validate once for radio buttons
                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                            if (inputElement.hasAttribute("name")) {
                                const radioName = inputElement.getAttribute("name");

                                const radioButtons = document.getElementsByName(radioName);
                                let alreadyValidated: boolean = false;
                                let isMissing: boolean = false;

                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                    let radioElement = <HTMLInputElement>radioButtons[i];
                                    if (radioElement.classList.contains("missing-field")) {
                                        alreadyValidated = true;
                                    }

                                    if (radioElement.dataset.ismissing && radioElement.dataset.ismissing === "true") {
                                        isMissing = true;
                                    }
                                }

                                if (!alreadyValidated && isMissing) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if (inputElement.dataset.ismissing && inputElement.dataset.ismissing === "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                            if (!inputElement.dataset.ismissing && inputElement.dataset.ismissing === "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (inputElement.dataset.ismissing && inputElement.dataset.ismissing === "true") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLSelectElement) {
                        const selectElement = <HTMLSelectElement>element;
                        if (selectElement.dataset.ismissing && selectElement.dataset.ismissing === "true") {
                            selectElement.classList.add("missing-field");
                            selectElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        const textAreaElement = <HTMLTextAreaElement>element;
                        if (textAreaElement.dataset.ismissing && textAreaElement.dataset.ismissing === "true") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                        const multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                        let hasSelection = false;

                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }

                        if (!hasSelection) {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }

                    } else if (htmlElement.classList.contains("multiSelect")) {
                        const multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                        let hasSelection = false;

                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }

                        if (!hasSelection) {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
        }

        const message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        const goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {
            const firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    const accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");

                        const accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    getTextInputs(planFK, className): IPlanProperty[] {
        const inputElements: IPlanProperty[] = [];
        const textInputs = document.getElementsByClassName(className);
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                const saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                inputElements.push(saveItem);
            }
        }

        return inputElements;
    }

    getRadioInputs(planFK, className): IPlanProperty[] {
        const allRadios: IPlanProperty[] = [];
        const radios = document.getElementsByClassName(className);
        for (const ele of radios) {
            let planPropertyPK = 0;

            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    const saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                    };

                    allRadios.push(saveItem);
                }
            }
        }

        return allRadios;
    }

    getCheckboxInputs(planFK, className): IPlanProperty[] {
        const allCheckboxes: IPlanProperty[] = [];
        const checkboxes = document.getElementsByClassName(className);
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            const element = <HTMLInputElement>ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);

            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";

            const saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allCheckboxes.push(saveItem);
        }

        return allCheckboxes;
    }

    getFiles(planFK, className): IPlanPropertyFile[] {
        const allFiles: IPlanPropertyFile[] = [];
        const files = document.getElementsByClassName(className);
        for (let ele of files) {
            const element = <HTMLInputElement>ele;
            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            const fileUploadPK = parseInt(element.dataset.fileuploadpk);
            const propertyPK = parseInt(element.dataset.propertypk);

            const saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planFK
            };

            allFiles.push(saveItem);
        }

        return allFiles;
    }
}