var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//import "core-js/stable";
//import "regenerator-runtime/runtime";
//Don't add any javascript here, ONLY imports of polyfills, etc. The core-js and regen MUST be the first two on the list.
class Core {
    constructor() {
        this.Workflows = [];
        let that = this;
        that.initializeDeleteChallenge();
        that.initializeDeleteStrength();
        that.initializeWorkflows();
        let completePlanPageButton = document.getElementById("updatePlanPageComplete");
        if (completePlanPageButton !== null)
            completePlanPageButton.addEventListener("click", (e) => this.completePlanPage(e));
        Tab.Init();
    }
    static a11yClick(e) {
        if (e.type === "keypress") {
            let code = e.which;
            if (code === 32 || code === 13) {
                return true;
            }
        }
        return false;
    }
    activateMenu(controls, section) {
        //Clear the menu active classes
        let readyMenu = document.getElementById("readySection");
        if (readyMenu !== null) {
            let readyMenus = readyMenu.querySelectorAll("li");
            for (let link of readyMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        let setMenu = document.getElementById("setSection");
        if (setMenu !== null) {
            let setMenus = setMenu.querySelectorAll("li");
            for (let link of setMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        let goMenu = document.getElementById("goSection");
        if (goMenu) {
            let goMenus = goMenu.querySelectorAll("li");
            for (let link of goMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        for (let link of controls) {
            let parentId = link + "Parent";
            let element = document.getElementById(parentId);
            if (element !== null)
                element.classList.add("active");
        }
        //Deal with the open/closed state of the sections
        let leftBar = document.getElementById("leftBarAccordionGroup");
        let topLevels = leftBar.getElementsByClassName("Accordion-trigger");
        for (let top of topLevels) {
            if (top.classList.contains("open")) {
                top.classList.remove("open");
            }
        }
        let innerTopLevels = leftBar.getElementsByClassName("Accordion-panel");
        for (let inner of innerTopLevels) {
            if (inner.classList.contains("open")) {
                inner.classList.remove("open");
            }
        }
        let sectionName = section + "Section";
        let accordionName = section + "Accordion";
        let sectionElement = document.getElementById(sectionName);
        if (sectionElement !== null)
            sectionElement.classList.add("open");
        let accordionElement = document.getElementById(accordionName);
        if (accordionElement !== null)
            accordionElement.classList.add("open");
    }
    activateMenuSpecialEducationIU(controls, section) {
        //Clear the menu active classes
        let profileMenu = document.getElementById("profileandPlanEssentialsSection");
        if (profileMenu !== null) {
            let profileMenus = profileMenu.querySelectorAll("li");
            for (let link of profileMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        let useOfStateFundsMenu = document.getElementById("useofStateFundsSection");
        if (useOfStateFundsMenu !== null) {
            let useOfStateFundsMenus = useOfStateFundsMenu.querySelectorAll("li");
            for (let link of useOfStateFundsMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        let useOfFederalFundsMenu = document.getElementById("useofFederalFundsSection");
        if (useOfFederalFundsMenu) {
            let useOfFederalFundsMenus = useOfFederalFundsMenu.querySelectorAll("li");
            for (let link of useOfFederalFundsMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        let profilesSectionMenu = document.getElementById("profilesSection");
        if (profilesSectionMenu) {
            let profilesSectionMenus = profilesSectionMenu.querySelectorAll("li");
            for (let link of profilesSectionMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        let technicalAssistanceMenu = document.getElementById("technicalAssistanceSection");
        if (technicalAssistanceMenu) {
            let technicalAssistanceMenus = technicalAssistanceMenu.querySelectorAll("li");
            for (let link of technicalAssistanceMenus) {
                if (link.classList.contains("active")) {
                    link.classList.remove("active");
                }
            }
        }
        for (let link of controls) {
            let parentId = link + "Parent";
            let element = document.getElementById(parentId);
            if (element !== null)
                element.classList.add("active");
        }
        //Deal with the open/closed state of the sections
        let leftBar = document.getElementById("leftBarAccordionGroup");
        let topLevels = leftBar.getElementsByClassName("Accordion-trigger");
        for (let top of topLevels) {
            if (top.classList.contains("open")) {
                top.classList.remove("open");
            }
        }
        let innerTopLevels = leftBar.getElementsByClassName("Accordion-panel");
        for (let inner of innerTopLevels) {
            if (inner.classList.contains("open")) {
                inner.classList.remove("open");
            }
        }
        let sectionName = section + "Section";
        let accordionName = section + "Accordion";
        let sectionElement = document.getElementById(sectionName);
        if (sectionElement !== null)
            sectionElement.classList.add("open");
        let accordionElement = document.getElementById(accordionName);
        if (accordionElement !== null)
            accordionElement.classList.add("open");
    }
    insertAfter(el, referenceNode) {
        referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
    }
    pageReload(fromSave = null, planFK = null) {
        if (fromSave !== null && fromSave === true) {
            let url = window.location.href;
            var alertDiv = document.getElementById("alertDiv");
            alertDiv.setAttribute("data-saved", "from-save");
            if (url.indexOf("fromSave") === -1) {
                if (window.location.search.length > 0) {
                    url += "&fromSave=" + fromSave.toString();
                }
                else {
                    url += "?fromSave=" + fromSave.toString();
                }
                window.location.href = url;
            }
            else {
                window.location.reload();
            }
        }
        else {
            window.location.reload();
        }
    }
    static createHTMLAlert(id, message, type, timeout, callback) {
        let that = this;
        var alertDiv = document.getElementById("alertDiv");
        //alertDiv.setAttribute("data-saved", type);
        alertDiv.style.top = "10px";
        var alertContainerDiv = document.getElementById("alertContainerDiv");
        var alertBox = document.getElementById(id);
        alertBox.innerHTML = message;
        alertBox.style.display = "block";
        alertDiv.style.display = "block";
        Core.createLiveAlert(message);
        //TODO: Make these classes instead of inline. Add and remove as needed.
        switch (type) {
            case 'alert':
            case 'notification':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#FFF';
                alertContainerDiv.style.borderColor = '#CCC';
                break;
            case 'warning':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#fadd84';
                alertContainerDiv.style.borderColor = '#FFC237';
                break;
            case 'error':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#f87676';
                alertContainerDiv.style.borderColor = 'darkred';
                alertBox.style.fontWeight = 'bold';
                break;
            case 'information':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#57B7E2';
                alertContainerDiv.style.borderColor = '#0B90C4';
                break;
            case 'success':
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#acdeb2';
                alertContainerDiv.style.borderColor = '#50C24E';
                break;
            default:
                alertContainerDiv.style.color = '#222';
                alertContainerDiv.style.backgroundColor = '#FFF';
                alertContainerDiv.style.borderColor = '#CCC';
                break;
        }
        if (timeout) {
            return setTimeout(function () {
                alertBox.style.display = "none";
                alertDiv.style.display = "none";
                alertBox.innerHTML = "";
                alertDiv.style.top = "0";
                if (callback && typeof (callback) === 'function') {
                    callback();
                }
            }, timeout);
        }
        else {
            if (callback && typeof (callback) === 'function') {
                callback();
            }
            return false;
        }
    }
    static createLiveAlert(message) {
        // set the message in the html containing the aria-live attribute
        // this will iniate the screen reader to read the message
        $('#alertDivHidden').html(message);
        // this clears out whatever is in the html for the aria-live attribute
        // the screen reader will only read new html inside of it, so you should really clear it out after using it
        setTimeout(function () {
            $('#alertDivHidden').html("");
        }, 2000);
    }
    doValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox") {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                }
                                else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    initializeRequiredFields(allClasses, refresh = false, allowDuplicates = false) {
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        if (refresh) {
            let allElements = document.querySelectorAll(classesToValidate);
            for (let element of allElements) {
                let htmlElement = element;
                htmlElement.removeAttribute("aria-required");
                let label = Core.findLabelForInput(htmlElement);
                if (label !== null) {
                    label.classList.remove("isRequired");
                    let asterisk = label.querySelector(".required-label");
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                        alreadyExists = true;
                    }
                }
                if (!alreadyExists || allowDuplicates) {
                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        let label = Core.findLabelForInput(htmlElement);
                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
    forceElementRequired(element) {
        element.dataset.percent = "1.00";
        element.setAttribute("aria-required", "true");
        element.dataset.forcerequired = "true";
        let label = Core.findLabelForInput(element);
        if (label !== null && !label.classList.contains("isRequired")) {
            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
            label.classList.add("isRequired");
        }
    }
    forceElementOptional(element) {
        element.dataset.percent = "0.00";
        element.setAttribute("aria-required", "false");
        element.dataset.forcerequired = "false";
        let label = Core.findLabelForInput(element);
        if (label !== null) {
            label.classList.remove("isRequired");
            let requiredLabel = label.querySelector(".required-label");
            if (requiredLabel !== null) {
                requiredLabel.parentNode.removeChild(requiredLabel);
            }
        }
    }
    static trapFocus(element) {
        var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), img:not([disabled])');
        var firstFocusableEl = focusableEls[0];
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;
        element.addEventListener('keydown', function (e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
            if (!isTabPressed) {
                return;
            }
            if (e.shiftKey) /* shift + tab */ {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            }
            else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    }
    //Returns label for element (either labelled by or label for). Returns null if no label found
    static findLabelForInput(element) {
        if (element.hasAttribute("data-label") && element.getAttribute("data-label") !== "") {
            let dataLabel = element.getAttribute("data-label");
            let labelForEl = document.querySelector(`#${dataLabel}`);
            if (labelForEl !== null) {
                return labelForEl;
            }
        }
        if (element.hasAttribute("aria-labelledby") && element.getAttribute("aria-labelledby") !== "") {
            let labelledBy = element.getAttribute("aria-labelledby");
            let labelledByEl = document.querySelector(`#${labelledBy}`);
            if (labelledByEl !== null) {
                return labelledByEl;
            }
        }
        if (element.hasAttribute("id") && element.getAttribute("id") !== "") {
            let label = document.querySelector(`label[for=${element.id}]`);
            if (label !== null) {
                return label;
            }
        }
        if (element.parentNode instanceof HTMLTableCellElement) {
            //Testing the cases where label is a <th> in the table.
            let parentElement = element.parentElement;
            let index = Array.prototype.indexOf.call(parentElement.parentNode.children, parentElement);
            //let correspondingTable = element.closest("table");
            let correspondingTable = Core.findClosest(element, "table");
            if (correspondingTable !== null) {
                let correspondingTh = correspondingTable.querySelector(`th:nth-child(${index + 1})`);
                if (correspondingTh !== null) {
                    return correspondingTh;
                }
            }
        }
        return null;
    }
    //https://stackoverflow.com/a/24107550/158722
    static findClosest(el, selector) {
        let matchesFn;
        // find vendor prefix
        ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
            if (typeof document.body[fn] == 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });
        let parent;
        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && parent[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }
        return null;
    }
    //Puts exclamation mark on labels for invalid fields
    static createErrorLabelForInput(element) {
        let label = Core.findLabelForInput(element);
        if (label !== null && !label.classList.contains("hasBeenValidated")) {
            label.classList.add("hasBeenValidated");
            label.innerHTML = "<span class='missing-field-label'><i class='fas fa-exclamation-triangle' aria-hidden='true'></i></span> " + label.innerHTML;
        }
    }
    static removeErrorLabelForInput(element) {
        let label = Core.findLabelForInput(element);
        if (label !== null && label.classList.contains("hasBeenValidated")) {
            label.classList.remove("hasBeenValidated");
            label.removeChild(label.querySelector(".missing-field-label"));
        }
    }
    static showLoader() {
        let loaderElement = document.getElementById("loader");
        if (loaderElement !== null) {
            let imageElement = document.getElementById("loaderImage");
            if (imageElement !== null) {
                imageElement.focus();
                Core.trapFocus(imageElement);
            }
            loaderElement.classList.add("open");
        }
    }
    static hideLoader() {
        let loaderElement = document.getElementById("loader");
        if (loaderElement !== null) {
            loaderElement.classList.remove("open");
        }
    }
    static showLongLoader() {
        let loaderElement = document.getElementById("longLoader");
        if (loaderElement !== null) {
            let imageElement = document.getElementById("loaderImage");
            if (imageElement !== null) {
                imageElement.focus();
                Core.trapFocus(imageElement);
            }
            loaderElement.classList.add("open");
        }
    }
    static hideLongLoader() {
        let loaderElement = document.getElementById("longLoader");
        if (loaderElement !== null) {
            loaderElement.classList.remove("open");
        }
    }
    activateSubMenu(menuId) {
        let allMenus = document.getElementsByClassName("subMenuElement");
        for (let menu of allMenus) {
            if (menu.classList.contains("active")) {
                menu.classList.remove("active");
            }
        }
        let newActive = document.getElementById(menuId);
        if (newActive !== null)
            newActive.classList.add("active");
    }
    createHash(allClasses) {
        let hash = "0";
        let value;
        let newHash;
        for (let thisClass of allClasses) {
            let allElements = document.getElementsByClassName(thisClass);
            for (let element of allElements) {
                if (element instanceof HTMLInputElement) {
                    let inputElement = element;
                    if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && inputElement.checked) {
                        value = 1;
                        hash = hash + value;
                    }
                    if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox" && !inputElement.checked) {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox" && inputElement.checked) {
                        value = 1;
                        hash = hash + value;
                    }
                    else if (inputElement.value === "") {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = this.hashCode(value);
                        hash = hash + newHash;
                    }
                }
                else if (element instanceof HTMLSelectElement) {
                    let selectElement = element;
                    if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                        value = selectElement.options[selectElement.selectedIndex].value;
                        newHash = this.hashCode(value);
                        hash = hash + newHash;
                    }
                }
                else if (element instanceof HTMLTextAreaElement) {
                    let textAreaElement = element;
                    if (textAreaElement.classList.contains("mce") && textAreaElement.hasAttribute("id") && tinymce.get(textAreaElement.getAttribute("id")) != null) {
                        let mceValue = tinymce.get(textAreaElement.getAttribute("id")).getContent();
                        if (mceValue === "") {
                            value = 0;
                            hash = hash + value;
                        }
                        else {
                            value = mceValue;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                    else {
                        if (textAreaElement.value === "") {
                            value = 0;
                            hash = hash + value;
                        }
                        else {
                            value = textAreaElement.value;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                }
                //putting in special check for danielson frameworks
                else if (element instanceof HTMLDivElement) {
                    let divElement = element;
                    if (divElement.hasAttribute("data-danielsonlookupcodepk")) {
                        if (divElement.dataset.danielsonlookupcodepk === "") {
                            value = 0;
                            hash = hash + value;
                        }
                        else {
                            value = divElement.dataset.danielsonlookupcodepk;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                    else if (divElement.hasAttribute("data-lookupcodepk")) {
                        if (divElement.dataset.lookupcodepk === "") {
                            value = 0;
                            hash = hash + value;
                        }
                        else {
                            value = divElement.dataset.lookupcodepk;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                    else {
                        if (divElement.dataset.planpropertypk === "" || !divElement.dataset.planpropertypk) {
                            value = 0;
                            hash = hash + value;
                        }
                        else {
                            value = divElement.dataset.planpropertypk;
                            newHash = this.hashCode(value);
                            hash = hash + newHash;
                        }
                    }
                }
            }
        }
        return hash;
    }
    hashCode(s) {
        let h;
        for (let i = 0; i < s.length; i++)
            h = (Math.imul(31, h) + s.charCodeAt(i) | 0).toString();
        return h;
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    continue(e) {
        let newPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    checkSave(myClass) {
        var newHash = myClass.createHash();
        let hiddenInputElement = document.getElementById("hashValue");
        if (hiddenInputElement !== null) {
            let hiddenInput = hiddenInputElement.value;
            if (newHash !== hiddenInput) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    leftnav(page) {
        let that = this;
        var listItems = document.getElementsByClassName("navLink");
        let isSave = (typeof page.save === 'function');
        for (let li of listItems) {
            let element = document.getElementById(li.id);
            let dataRedirectUrl = element.getAttribute("data-redirect-url");
            if (isSave === true) {
                li.addEventListener("click", (e) => {
                    if (that.Workflows.length > 0) {
                        Workflow.SaveAllWorkflows(that.Workflows)
                            .then((resolve) => {
                            page.save(dataRedirectUrl);
                        });
                    }
                    else {
                        page.save(dataRedirectUrl);
                    }
                });
            }
            else {
                li.addEventListener("click", (e) => {
                    let promiseArray = [];
                    if (that.Workflows.length > 0) {
                        Workflow.SaveAllWorkflows(that.Workflows)
                            .then((values) => {
                            window.location.href = dataRedirectUrl;
                        });
                    }
                    else {
                        window.location.href = dataRedirectUrl;
                    }
                });
            }
        }
    }
    tabLinkSave(page) {
        var tablinks = document.getElementsByClassName("tablinks");
        let isSave = (typeof page.save === 'function');
        for (let tab of tablinks) {
            let dataRedirectUrl = tab.getAttribute("data-redirect-url");
            if (isSave === true) {
                tab.addEventListener("click", (e) => page.save(dataRedirectUrl));
            }
            else {
                tab.addEventListener("click", (e) => window.location.href = dataRedirectUrl);
            }
        }
    }
    initializeDeleteChallenge() {
        let core = this;
        let modal = new Modal("deleteChallengeModal", null);
        //Use event delegation to add event listener to document, not delete buttons. This is so we don't have to re-add event listeners when rows are added dynamically.
        document.addEventListener("click", function (event) {
            let button = event.target;
            if (button.classList.contains("deleteChallenge") && "container" in button.dataset) {
                if ("challengeplanpropertypk" in button.dataset && parseInt(button.dataset.challengeplanpropertypk) > 0) {
                    modal.show();
                    if (button.id) {
                        modal.callingId = button.id;
                    }
                    modal.addAttributeToElement(modal.id, "#deleteChallengeConfirm", "challengeplanpropertypk", button.dataset.challengeplanpropertypk);
                    modal.addAttributeToElement(modal.id, "#deleteChallengeConfirm", "container", button.dataset.container);
                }
                else {
                    let container = document.getElementById(button.dataset.container);
                    if (container != null && "removecontainer" in button.dataset && button.dataset.removecontainer == 'true') {
                        let nextFocusable = Core.getNextFocusableElement(button);
                        container.parentNode.removeChild(container);
                        nextFocusable.focus();
                        Core.createHTMLAlert("alertMessageDiv", "Challenge row removed", "success", 3000, null);
                    }
                    else if (container != null && container.querySelector('textarea') != null) {
                        let challenge = container.querySelector('textarea');
                        challenge.value = '';
                        challenge.dataset.planpropertypk = '0';
                        Core.createHTMLAlert("alertMessageDiv", "Challenge row cleared", "success", 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Error clearing challenge row", "success", 3000, null);
                    }
                }
            }
        });
        let deleteChallengeConfirmButton = document.getElementById("deleteChallengeConfirm");
        if (deleteChallengeConfirmButton != null) {
            deleteChallengeConfirmButton.addEventListener("click", function () {
                modal.hide();
                if ("challengeplanpropertypk" in deleteChallengeConfirmButton.dataset && parseInt(deleteChallengeConfirmButton.dataset.challengeplanpropertypk) > 0 && "container" in deleteChallengeConfirmButton.dataset) {
                    //Make call to delete challenge
                    Core.showLoader();
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/Base/DeleteChallenge', true);
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.onload = function () {
                        if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                            Core.createHTMLAlert("alertMessageDiv", "Challenge successfully deleted", 'success', 3000, null);
                            let container = document.getElementById(deleteChallengeConfirmButton.dataset.container);
                            if (container != null) {
                                let deleteChallengeButton = container.querySelector(`.deleteChallenge[data-challengeplanpropertypk='${deleteChallengeConfirmButton.dataset.challengeplanpropertypk}']`);
                                if (deleteChallengeButton != null) {
                                    deleteChallengeButton.dataset.challengeplanpropertypk = '0';
                                }
                                if (deleteChallengeButton != null && "removecontainer" in deleteChallengeButton.dataset && deleteChallengeButton.dataset.removecontainer == "true") {
                                    let nextFocusable = Core.getNextFocusableElement(deleteChallengeButton);
                                    container.parentNode.removeChild(container);
                                    nextFocusable.focus();
                                }
                                else {
                                    let challenge = container.querySelector(`[data-planpropertypk='${deleteChallengeConfirmButton.dataset.challengeplanpropertypk}']`);
                                    if (challenge != null) {
                                        challenge.value = '';
                                        challenge.dataset.planpropertypk = '0';
                                        deleteChallengeButton.focus();
                                    }
                                }
                            }
                        }
                        else {
                            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting challenge", "error", 3000, null);
                        }
                        Core.hideLoader();
                    };
                    xhr.send(deleteChallengeConfirmButton.dataset.challengeplanpropertypk);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Error: challenge could not be removed", "error", 3000, null);
                }
            });
        }
        let deleteChallengeCancelButton = document.getElementById("deleteChallengeCancel");
        if (deleteChallengeCancelButton != null) {
            deleteChallengeCancelButton.addEventListener("click", function () {
                modal.hide();
            });
        }
    }
    initializeDeleteStrength() {
        let core = this;
        let deleteStrengthButtons = document.getElementsByClassName("deleteStrength");
        let modal = new Modal("deleteStrengthModal", null);
        document.addEventListener("click", function (event) {
            let button = event.target;
            if (button.classList.contains("deleteStrength") && "container" in button.dataset) {
                if ("strengthplanpropertypk" in button.dataset && parseInt(button.dataset.strengthplanpropertypk) > 0) {
                    modal.show();
                    if (button.id) {
                        modal.callingId = button.id;
                    }
                    modal.addAttributeToElement(modal.id, "#deleteStrengthConfirm", "strengthplanpropertypk", button.dataset.strengthplanpropertypk);
                    modal.addAttributeToElement(modal.id, "#deleteStrengthConfirm", "container", button.dataset.container);
                }
                else {
                    let container = document.getElementById(button.dataset.container);
                    if (container != null && "removecontainer" in button.dataset && button.dataset.removecontainer == 'true') {
                        let nextFocusable = Core.getNextFocusableElement(button);
                        container.parentNode.removeChild(container);
                        Core.createHTMLAlert("alertMessageDiv", "Strength row removed", "success", 3000, null);
                        nextFocusable.focus();
                    }
                    else if (container != null && container.querySelector('textarea') != null) {
                        let strength = container.querySelector('textarea');
                        strength.value = '';
                        strength.dataset.planpropertypk = '0';
                        Core.createHTMLAlert("alertMessageDiv", "Strength row cleared", "success", 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Error clearing strength row", "success", 3000, null);
                    }
                }
            }
        });
        let deleteStrengthConfirmButton = document.getElementById("deleteStrengthConfirm");
        if (deleteStrengthConfirmButton != null) {
            deleteStrengthConfirmButton.addEventListener("click", function () {
                modal.hide();
                if ("strengthplanpropertypk" in deleteStrengthConfirmButton.dataset && parseInt(deleteStrengthConfirmButton.dataset.strengthplanpropertypk) > 0 && "container" in deleteStrengthConfirmButton.dataset) {
                    //Make call to delete strength
                    Core.showLoader();
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/Base/DeleteStrength', true);
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.onload = function () {
                        if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                            Core.createHTMLAlert("alertMessageDiv", "Strength successfully deleted", 'success', 3000, null);
                            let container = document.getElementById(deleteStrengthConfirmButton.dataset.container);
                            if (container != null) {
                                let deleteStrengthButton = document.querySelector(`.deleteStrength[data-strengthplanpropertypk='${deleteStrengthConfirmButton.dataset.strengthplanpropertypk}']`);
                                if (deleteStrengthButton != null) {
                                    deleteStrengthButton.dataset.strengthplanpropertypk = '0';
                                }
                                if (deleteStrengthButton != null && "removecontainer" in deleteStrengthButton.dataset && deleteStrengthButton.dataset.removecontainer == "true") {
                                    let nextFocusable = Core.getNextFocusableElement(deleteStrengthButton);
                                    container.parentNode.removeChild(container);
                                    nextFocusable.focus();
                                }
                                else {
                                    let strength = container.querySelector(`[data-planpropertypk='${deleteStrengthConfirmButton.dataset.strengthplanpropertypk}']`);
                                    if (strength != null) {
                                        strength.value = '';
                                        strength.dataset.planpropertypk = '0';
                                        if (deleteStrengthButton != null) {
                                            deleteStrengthButton.focus();
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting strength", "error", 3000, null);
                        }
                        Core.hideLoader();
                    };
                    xhr.send(deleteStrengthConfirmButton.dataset.strengthplanpropertypk);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Error: strength could not be removed", "error", 3000, null);
                }
            });
        }
        let deleteStrengthCancelButton = document.getElementById("deleteStrengthCancel");
        if (deleteStrengthCancelButton != null) {
            deleteStrengthCancelButton.addEventListener("click", function () {
                modal.hide();
            });
        }
    }
    bindChangeLEA() {
        let leaChange = document.getElementById("changeLEA");
        if (leaChange !== null) {
            leaChange.addEventListener("change", this.changeLEA.bind(this), false);
        }
    }
    changeLEA() {
        let core = this;
        let LEAElement = document.getElementById("changeLEA");
        let institutionPKValue = LEAElement.value;
        if (institutionPKValue === "") {
            return false;
        }
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/SwitchLEA', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let newURL = Core.removeURLParameter(window.location.href, "PageNumber");
                Core.createHTMLAlert("alertMessageDiv", "LEA changed, reloading new information", 'success', 3000, function () {
                    window.location.href = newURL;
                });
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an error switching LEA. Please try again later", 'error', 3000, null);
            }
        };
        xhr.send(institutionPKValue);
    }
    exportDocx(e, action, fileName) {
        let core = this;
        let button = e.target;
        if (button.dataset.planfk) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportDocx/${action}?planFK=${button.dataset.planfk}`, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = "blob";
            xhr.onload = function () {
                Core.hideLoader();
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `${fileName}.docx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send();
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an error exporting to Word. Please try again later.", 'warning', 3000, null);
        }
    }
    //Adapted from https://stackoverflow.com/questions/7208161
    //Returns the next focusable element, given an element.
    static getNextFocusableElement(element) {
        //add all elements we want to include in our selection
        var focussableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), input[type=number]:not([disabled]), textarea:not([disabled]), select:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
        var focussable = Array.prototype.filter.call(document.querySelectorAll(focussableElements), function (el) {
            //check for visibility while always include the current activeElement 
            return el.offsetWidth > 0 || el.offsetHeight > 0 || el === element;
        });
        var index = focussable.indexOf(element);
        if (index > -1) {
            var nextElement = focussable[index + 1] || focussable[0];
            return nextElement;
        }
    }
    static refreshProfile(formName) {
        Core.showLoader();
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/GetICDataForProfile', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let zipCodeElement = document.querySelector(".profileICData[data-field='Zip']");
                let stateElement = document.querySelector(".profileICData[data-field='State']");
                let cityElement = document.querySelector(".profileICData[data-field='City']");
                let addressLine2Element = document.querySelector(".profileICData[data-field='Address2']");
                let addressLine1Element = document.querySelector(".profileICData[data-field='Address1']");
                let aunElement = document.querySelector(".profileICData[data-field='AUN']");
                let leaNameElement = document.querySelector(".profileICData[data-field='Name']");
                let leaPhoneElement = document.querySelector(".profileICData[data-field='LEAPhone']");
                let leaPhoneExtElement = document.querySelector(".profileICData[data-field='LEAPhoneExt']");
                let leaTypeElement = document.querySelector(".profileICData[data-field='LEAType']");
                let instDetails = JSON.parse(xhr.response);
                if (leaNameElement)
                    leaNameElement.value = instDetails.instDetails.instName;
                if (aunElement)
                    aunElement.value = instDetails.instDetails.auNumber;
                if (addressLine1Element)
                    addressLine1Element.value = instDetails.instDetails.addressLine1;
                if (addressLine2Element)
                    addressLine2Element.value = instDetails.instDetails.addressLine2;
                if (cityElement)
                    cityElement.value = instDetails.instDetails.city;
                if (stateElement)
                    stateElement.value = instDetails.instDetails.state;
                if (zipCodeElement)
                    zipCodeElement.value = instDetails.instDetails.zipCode;
                if (leaPhoneElement)
                    leaPhoneElement.value = instDetails.instDetails.phoneNumber;
                if (leaPhoneExtElement)
                    leaPhoneExtElement.value = instDetails.instDetails.phoneNumberExtn;
                if (leaTypeElement)
                    leaTypeElement.value = instDetails.instDetails.instCategory;
                let superEmailElement = document.querySelector(".profileICData[data-field='SuperEmail']");
                let superPhoneExtElement = document.querySelector(".profileICData[data-field='SuperPhoneExt']");
                let superPhoneElement = document.querySelector(".profileICData[data-field='SuperPhone']");
                let superNameElement = document.querySelector(".profileICData[data-field='SuperName']");
                if (superEmailElement)
                    superEmailElement.value = instDetails.superintendentInfo.email;
                if (superPhoneElement)
                    superPhoneElement.value = instDetails.superintendentInfo.phoneNumber;
                if (superNameElement)
                    superNameElement.value = instDetails.superintendentInfo.adminName;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Profile Data has been refreshed, please save the changes.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    static PlanSearch(pageNumber, url) {
        let query = this.GetPlanQueryModel(pageNumber);
        let params = "";
        for (var key in query) {
            if (query[key] !== null && query[key] !== "") {
                if (params != "") {
                    params += "&";
                }
                params += key + "=" + encodeURIComponent(query[key]);
            }
        }
        window.location.href = url + "?" + params;
    }
    static GetPlanQueryModel(pageNumber) {
        let query = {
            LEAName: "",
            PageNumber: pageNumber,
            PhaseCodeFK: null,
            PlanTypeGroupCode: "",
            PlanTypePK: null,
            SchoolName: "",
            StatusPKs: "",
            Year: null,
            AUNumber: ""
        };
        let yearElement = document.getElementById("planYear");
        let leaNameElement = document.getElementById("leaName");
        let statusElement = document.getElementById("planStatus");
        let typeElement = document.getElementById("planType");
        let phaseElement = document.getElementById("planPhase");
        let categoryElement = document.getElementById("planTypeGroup");
        let schoolNameElement = document.getElementById("schoolName");
        let auNumberElement = document.getElementById("auNumber");
        if (yearElement != null && yearElement.selectedIndex > 0 && parseInt(yearElement.value)) {
            query.Year = parseInt(yearElement.value);
        }
        if (leaNameElement != null && leaNameElement.value != "") {
            query.LEAName = leaNameElement.value;
        }
        if (schoolNameElement != null && schoolNameElement.value != "") {
            query.SchoolName = schoolNameElement.value;
        }
        if (categoryElement != null && categoryElement.selectedIndex > 0) {
            query.PlanTypeGroupCode = categoryElement.value;
        }
        if (statusElement != null) {
            let string = "";
            let selection = statusElement.querySelectorAll("ul li.selected");
            if (selection.length > 0) {
                for (let s of selection) {
                    let sequenceLength = selection.length;
                    let sElement = s;
                    let nextElement = sElement.querySelector("input");
                    let inputElement = nextElement;
                    string = string + inputElement.dataset.statusfk + ",";
                }
            }
            query.StatusPKs = string;
        }
        if (typeElement != null && typeElement.selectedIndex > 0 && parseInt(typeElement.value)) {
            query.PlanTypePK = parseInt(typeElement.value);
        }
        if (phaseElement != null && phaseElement.selectedIndex > 0 && parseInt(phaseElement.value)) {
            query.PhaseCodeFK = parseInt(phaseElement.value);
        }
        if (auNumberElement != null && auNumberElement.value != "") {
            query.AUNumber = auNumberElement.value;
        }
        return query;
    }
    static removeURLParameter(url, parameter) {
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);
            for (var i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }
    updatePlanDueDate(e) {
        let button = e.currentTarget;
        let datePickerId = button.dataset.datepickerid;
        let planPK = parseInt(button.dataset.planpk);
        let saveObject;
        let datepicker = document.getElementById(datePickerId);
        //let datepicker = document.querySelector(".adminDatePicker[data-planpkcompletiondate='" + elementPlanPK + "']");
        let datePickerElement = datepicker;
        let date = datePickerElement.value;
        let utc = new Date(date);
        var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        saveObject = {
            PlanPK: planPK,
            DueDate: dueDate
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/AdminSavePlanDueDate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    bindDeleteOwner() {
        const deleteownerConfirmButton = document.getElementById("ownerDeleteConfirm");
        if (deleteownerConfirmButton !== null)
            deleteownerConfirmButton.addEventListener("click", (e) => this.deleteOwnerConfirm(e));
        const deleteOwnerCancelButton = document.getElementById("ownerDeleteCancel");
        if (deleteOwnerCancelButton !== null)
            deleteOwnerCancelButton.addEventListener("click", (e) => this.deleteOwnerCancel());
        const allDeletes = document.getElementsByClassName("deleteOwner");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showOwnerDelete(e));
    }
    showOwnerDelete(e) {
        let buttonElement = e.target;
        let ownerpk = buttonElement.dataset.ownerpk;
        let institutionpk = buttonElement.dataset.institutionpk;
        let plantypefk = buttonElement.dataset.plantypefk;
        let modal = new Modal("deleteOwnerModal", null);
        modal.addAttributeToElement("deleteOwnerModal", "#ownerDeleteConfirm", "ownerpk", ownerpk);
        modal.addAttributeToElement("deleteOwnerModal", "#ownerDeleteConfirm", "institutionpk", institutionpk);
        modal.addAttributeToElement("deleteOwnerModal", "#ownerDeleteConfirm", "plantypefk", plantypefk);
        modal.show();
    }
    deleteOwnerCancel() {
        let modal = new Modal("deleteOwnerModal", null);
        modal.hide();
    }
    deleteOwnerConfirm(e) {
        let buttonElement = e.target;
        let ownerpk = parseInt(buttonElement.dataset.ownerpk);
        let institutionpk = parseInt(buttonElement.dataset.institutionpk);
        let plantypefk = parseInt(buttonElement.dataset.plantypefk);
        if (ownerpk != 0) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Base/DeleteOwner', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    //let element = document.getElementById("planOwner");
                    //element.remove();
                    let modal = new Modal("deleteOwnerModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The data has been removed.  Please refresh the page if you want to see new data.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("userFK=" + ownerpk + "&frppInstitutionFk=" + institutionpk + "&planTypeFK=" + plantypefk);
        }
    }
    updateLockoutException(e) {
        let that = this;
        let checkmark = e.currentTarget;
        let planPK = parseInt(checkmark.dataset.planpk);
        let planLockoutException = parseInt(checkmark.dataset.lockoutexceptionpk);
        let saveObject;
        if (planLockoutException === 0) {
            saveObject = {
                PlanLockoutExceptionPK: planLockoutException,
                PlanTypeLockoutFK: 0, //update this in the save method
                PlanFK: planPK,
                ModifiedByUserName: "",
                ModifiedDate: new Date(),
                IsDeletedInd: false
            };
        }
        if (planLockoutException > 0 && checkmark.checked) {
            saveObject = {
                PlanLockoutExceptionPK: planLockoutException,
                PlanTypeLockoutFK: 0, //update this in the save method
                PlanFK: planPK,
                ModifiedByUserName: "",
                ModifiedDate: new Date(),
                IsDeletedInd: false
            };
        }
        if (planLockoutException > 0 && !checkmark.checked) {
            saveObject = {
                PlanLockoutExceptionPK: planLockoutException,
                PlanTypeLockoutFK: 0, //update this in the save method
                PlanFK: planPK,
                ModifiedByUserName: "",
                ModifiedDate: new Date(),
                IsDeletedInd: true
            };
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePlanLockoutException', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, that.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    static autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists(e);
            if (!val) {
                return false;
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y;
            if (x)
                y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x)
                return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length)
                currentFocus = 0;
            if (currentFocus < 0)
                currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    completePlanPage(e) {
        let that = this;
        let button = e.currentTarget;
        let planpk = button.dataset.planpk;
        let pagecode = button.dataset.pagecode;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/AdminCompletePlanPage', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, that.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planPK=" + planpk + "&pageCode=" + pagecode);
    }
    initializeWorkflows() {
        let that = this;
        that.Workflows = [];
        let allActions = document.querySelectorAll("[data-action][data-workflow-instance-fk]");
        if (allActions != null && allActions.length > 0) {
            let distinctWorkflows = [...new Set([...allActions].map(el => parseInt(el.dataset.workflowInstanceFk)))];
            distinctWorkflows.forEach(workflowInstancePK => {
                that.Workflows.push(new Workflow(workflowInstancePK));
            });
        }
    }
    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------------
    serverSideValidation(serverSideMethodName, planFK, pageCode) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`${serverSideMethodName}/${planFK}/${pageCode}/false`, { credentials: 'include' });
            if (response.ok) {
            }
        });
    }
    newValidation(allClasses, showMessageOverride) {
        const showMessage = showMessageOverride === undefined ? this.newClientSideValidation(allClasses) : showMessageOverride;
        const messageContainerColumn = document.getElementById("validationColumn");
        const messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        const validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            const message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            const successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    newClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            const htmlElement = element;
            if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                if (!element.classList.contains("missing-field")) {
                    if (element instanceof HTMLInputElement) {
                        const inputElement = element;
                        //Only validate once for radio buttons
                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                            if (inputElement.hasAttribute("name")) {
                                const radioName = inputElement.getAttribute("name");
                                const radioButtons = document.getElementsByName(radioName);
                                let alreadyValidated = false;
                                let isMissing = false;
                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                    let radioElement = radioButtons[i];
                                    if (radioElement.classList.contains("missing-field")) {
                                        alreadyValidated = true;
                                    }
                                    if (radioElement.dataset.ismissing && radioElement.dataset.ismissing === "true") {
                                        isMissing = true;
                                    }
                                }
                                if (!alreadyValidated && isMissing) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if (inputElement.dataset.ismissing && inputElement.dataset.ismissing === "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                            if (!inputElement.dataset.ismissing && inputElement.dataset.ismissing === "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (inputElement.dataset.ismissing && inputElement.dataset.ismissing === "true") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLSelectElement) {
                        const selectElement = element;
                        if (selectElement.dataset.ismissing && selectElement.dataset.ismissing === "true") {
                            selectElement.classList.add("missing-field");
                            selectElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        const textAreaElement = element;
                        if (textAreaElement.dataset.ismissing && textAreaElement.dataset.ismissing === "true") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                        const multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                        let hasSelection = false;
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }
                        if (!hasSelection) {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (htmlElement.classList.contains("multiSelect")) {
                        const multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                        let hasSelection = false;
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }
                        if (!hasSelection) {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
        }
        const message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        const goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            const firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    const accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");
                        const accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    getTextInputs(planFK, className) {
        const inputElements = [];
        const textInputs = document.getElementsByClassName(className);
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                inputElements.push(saveItem);
            }
        }
        return inputElements;
    }
    getRadioInputs(planFK, className) {
        const allRadios = [];
        const radios = document.getElementsByClassName(className);
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                    };
                    allRadios.push(saveItem);
                }
            }
        }
        return allRadios;
    }
    getCheckboxInputs(planFK, className) {
        const allCheckboxes = [];
        const checkboxes = document.getElementsByClassName(className);
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            const saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allCheckboxes.push(saveItem);
        }
        return allCheckboxes;
    }
    getFiles(planFK, className) {
        const allFiles = [];
        const files = document.getElementsByClassName(className);
        for (let ele of files) {
            const element = ele;
            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            const fileUploadPK = parseInt(element.dataset.fileuploadpk);
            const propertyPK = parseInt(element.dataset.propertypk);
            const saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planFK
            };
            allFiles.push(saveItem);
        }
        return allFiles;
    }
}
/// <reference path="core.ts" />
class Home {
    constructor() {
        let comprensive = document.getElementById("comprehensivePlans");
        if (comprensive !== null)
            comprensive.addEventListener("click", (e) => this.comprehensivePlans());
        let hideWelcomeMessage = document.getElementById("dontShowAgain");
        if (hideWelcomeMessage !== null)
            hideWelcomeMessage.addEventListener("click", (e) => this.hideMessage());
        let school = document.getElementById("schoolPlans");
        if (school !== null)
            school.addEventListener("click", (e) => this.schoolPlans());
        let stateReports = document.getElementById("stateReports");
        if (stateReports !== null)
            stateReports.addEventListener("click", (e) => this.stateReports());
    }
    comprehensivePlans() {
        window.location.href = "/Home/MyPlans";
    }
    schoolPlans() {
        window.location.href = "/Home/SchoolPlans";
    }
    stateReports() {
        window.location.href = "/Reports/StateRequiredReports";
    }
    hideMessage() {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/Home/HideWelcomeMessage');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let close = document.getElementById("closeModalHomeDisclaimer");
                close.click();
            }
            else {
                alert('Request failed.  Returned status of ' + xhr.status);
            }
        };
        xhr.send();
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/ready/interfaces.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
//Profile and Plan Essentials
class ProfileAndPlanEssentials {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["ProfiePlanEssentials"];
        let saveButtonElement = document.getElementById("profileAndPlanSave");
        if (saveButtonElement !== null)
            saveButtonElement.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let executiveSaveButton = document.getElementById("ExecutiveSave");
        if (executiveSaveButton !== null)
            executiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        let controls = new Array("leftBarprofileplanessentials");
        this._core.activateMenu(controls, "ready");
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("profilePlanEssentialsData"));
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("profilePlanEssentialsData");
        let planPK = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("ProfiePlanEssentials");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let processed = JSON.stringify(allSaveElements[0]);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveProfilePlanEssentials', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPK));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("profilePlanEssentialsData");
        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveProfilePlanEssentials', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Steering Committee
class SteeringCommittee {
    constructor() {
        this._core = new Core();
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        this.planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        this.templateFK = parseInt(steeringCommitteeTable.dataset.templatefk);
        this.validationClasses = ["steeringCommitteeField"];
        let steeringCommitteeAddRows = document.getElementById("steeringCommitteeAddRows");
        if (steeringCommitteeAddRows !== null)
            steeringCommitteeAddRows.addEventListener("click", (e) => this.steeringCommitteeAddRows());
        let steeringCommitteeSave = document.getElementById("saveCommitteeMembers");
        if (steeringCommitteeSave !== null)
            steeringCommitteeSave.addEventListener("click", (e) => this.save("save"));
        let steeringCommitteeExportExcel = document.getElementById("exportExcelCommitteeMembers");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e) => this.exportExcel());
        let wordPluralizer = document.getElementById("steeringCommitteeAddRowsNumber");
        if (wordPluralizer !== null)
            wordPluralizer.addEventListener("change", (e) => this.checkForPlural());
        var rows = document.getElementsByClassName("removeSteeringCommitteeRow");
        for (let row of rows) {
            row.addEventListener("click", (e) => this.removeSteeringCommitteeRow(e));
        }
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        let IsCompleteElement = document.getElementById("isComplete");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportWord = document.getElementById("exportSteeringCommitteeWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "SteeringCommitteeDataExport", "steeringcommittee"));
        }
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-rownumber]");
        let allSaveElements = [];
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let planPK = 0;
        for (let row of steeringCommitteeTableRows) {
            let rowNumber = parseInt(row.dataset.rownumber);
            let elements = row.querySelectorAll(".steeringCommitteeField");
            for (let element of elements) {
                let planPropertyPK = 0;
                planPK = parseInt(steeringCommitteeTable.dataset.planpk);
                if (element instanceof HTMLSelectElement) {
                    let htmlElement = element;
                    let hadValue = false;
                    let planPropertyPK = 0;
                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }
                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    if (element.value != null || hadValue) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false
                        };
                        allSaveElements.push(saveItem);
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    let htmlElement = element;
                    let hadValue = false;
                    if (htmlElement.dataset.planpropertypk !== "" && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (element.value !== "" || hadValue) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false
                        };
                        allSaveElements.push(saveItem);
                    }
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    steeringCommitteeAddRows() {
        let core = this._core;
        let value = document.getElementById("steeringCommitteeAddRowsNumber");
        let _that = this;
        if (value.value === "") {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
        else {
            let rows = parseInt(value.value);
            let row = 0;
            let table = document.getElementById("steeringCommittee");
            let trs = table.rows;
            for (let tr of trs) {
                if (parseInt(tr.getAttribute("data-rownumber")) > row)
                    row = parseInt(tr.getAttribute("data-rownumber"));
            }
            row++;
            for (let i = 0; i < rows; i++) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Ready/SteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        value.value = "";
                        let rows = document.getElementsByClassName("removeSteeringCommitteeRow");
                        let row = rows[rows.length - 1];
                        row.addEventListener("click", (e) => _that.removeSteeringCommitteeRow(e));
                        Core.createHTMLAlert("alertMessageDiv", "New Steering Committee Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("templateFK=" + _that.templateFK + "&row=" + row);
                row++;
            }
        }
    }
    removeSteeringCommitteeRow(e) {
        let core = this._core;
        let button = e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        let rowNumber = parent.dataset.rownumber;
        let hasData = button.dataset.hasdata;
        let planPK = 0;
        let allRemoveElements = [];
        if (hasData !== "") {
            planPK = parseInt(document.getElementById("steeringCommittee").dataset.planpk);
            let elements = parent.querySelectorAll(".steeringCommitteeField");
            for (let element of elements) {
                let planPropertyPK = 0;
                let hadValue = false;
                if (element instanceof HTMLSelectElement) {
                    let htmlElement = element;
                    if (htmlElement.dataset.planpropertypk !== "" && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }
                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    if (htmlElement.selectedIndex > 0 || hadValue) {
                        let removeItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: parseInt(element.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: true
                        };
                        allRemoveElements.push(removeItem);
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    let htmlElement = element;
                    if (htmlElement.dataset.planpropertypk !== "" && htmlElement.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (htmlElement.value !== "" || hadValue) {
                        let removeItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: parseInt(element.dataset.propertypk),
                            TextValue: htmlElement.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: true
                        };
                        allRemoveElements.push(removeItem);
                    }
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Ready/SaveSteeringCommittee', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    //window.location.reload();
                    Core.createHTMLAlert("alertMessageDiv", "Steering Committee Row Successfully Removed", 'success', 3000, core.pageReload);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(allRemoveElements));
        }
        parent.parentElement.removeChild(parent);
        Core.createHTMLAlert("alertMessageDiv", "Steering Committee Row Successfully Removed", 'success', 3000, null);
    }
    checkForPlural() {
        let word = document.getElementById("rowWord");
        let num = document.getElementById("steeringCommitteeAddRowsNumber");
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }
        word.textContent = newWord;
    }
    exportExcel() {
        let core = this._core;
        Core.showLoader();
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let planPK = 0;
        planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        window.open("/ExportExcel/SteeringCommitteeDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    initializeRequiredFieldsCustom() {
        //If any field in a row is filled out, force rest of row required (except email)
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-rownumber]");
        for (let row of steeringCommitteeTableRows) {
            let elements = row.getElementsByClassName("steeringCommitteeField");
            for (let element of elements) {
                if (element instanceof HTMLSelectElement) {
                    let htmlElement = element;
                    if (element.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode != "steeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    let htmlElement = element;
                    if (element.value != "") {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode != "steeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
}
var tinymce;
//LEAProfile
class LEAProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["leaProfileField"];
        let saveButton = document.getElementById("saveLEAProfile");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this.save("continue"));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this.save("back"));
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportWord = document.getElementById("exportLEAProfileWord");
        if (exportWord !== null) {
            let fileName = "export";
            let heading = document.querySelector("h1");
            if (heading != null) {
                fileName = heading.innerText;
            }
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "LEAProfileDataExport", fileName));
        }
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let htmlElement = document.getElementById("leaProfileDescription");
        let planPropertyPK = htmlElement.dataset.planpropertypk;
        let planPK = htmlElement.dataset.planpk;
        let propertyPK = htmlElement.dataset.propertypk;
        let value = tinymce.get('leaProfileDescription').getContent();
        var allSaveElements = [];
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (value !== "") {
            let saveItem = {
                PlanPropertyPK: parseInt(planPropertyPK),
                PlanFK: parseInt(planPK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPK));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//MissionAndVision
class MissionAndVision {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["missionAndVisionData"];
        let saveButton = document.getElementById("missionAndVisionSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this.save("continue"));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this.save("back"));
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportWord = document.getElementById("exportMissionVisionWord");
        if (exportWord !== null) {
            let fileName = "export";
            let heading = document.querySelector("h1");
            if (heading != null) {
                fileName = heading.innerText;
            }
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "MissionVisionDataExport", fileName));
        }
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("missionAndVisionForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("missionAndVisionData");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let processed = JSON.stringify(allSaveElements[0]);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveMissionAndVision', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//EducationalValues
class EducationalValues {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["educationalValuesDataElement"];
        let saveButton = document.getElementById("educationalValuesSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this.save("continue"));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this.save("back"));
        let otherOptional = document.getElementById("educationValuesOtherOptionalCheckbox");
        if (otherOptional !== null)
            otherOptional.addEventListener("click", (e) => this.noPrograms("educationValuesOtherOptional", "educationValuesOtherOptionalCheckbox"));
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportWord = document.getElementById("exportEducationalValuesWord");
        if (exportWord !== null) {
            let fileName = "export";
            let heading = document.querySelector("h1");
            if (heading != null) {
                fileName = heading.innerText;
            }
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "EducationalValuesDataExport", fileName));
        }
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    noPrograms(textarea, checkboxName) {
        let textArea = document.getElementById(textarea);
        let checkbox = document.getElementById(checkboxName);
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
        else {
            if (checkbox.checked) {
                textArea.value = " ";
                textArea.readOnly = true;
            }
            else {
                textArea.readOnly = false;
                textArea.value = "";
            }
        }
    }
    checkForCheckbox() {
        let textArea = document.getElementById("educationValuesOtherOptional");
        let checkbox = document.getElementById("educationValuesOtherOptionalCheckbox");
        if (checkbox.checked) {
            textArea.readOnly = true;
        }
    }
    save(referrer) {
        let core = this._core;
        if (this._core.checkSave(this) === false && referrer !== "save") {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("educationalValuesForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("educationalValuesDataElement");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("educationalValuesCheckElement");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let processed = JSON.stringify(allSaveElements[0]);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Ready/SaveEducationalValues', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
/// <reference path="core.ts" / >
class PlanSectionSet {
    constructor() {
        let that = this;
        this.deleteDataSourceConfirmModal = new Modal("deleteDataSourceModal", null);
        let deleteDataSourceCancelButton = document.getElementById("deleteDataSourceCancel");
        if (deleteDataSourceCancelButton != null) {
            deleteDataSourceCancelButton.addEventListener("click", () => {
                that.deleteDataSourceConfirmModal.hide();
            });
        }
        let deleteDataSourceConfirmButton = document.getElementById("deleteDataSourceConfirm");
        if (deleteDataSourceConfirmButton != null) {
            deleteDataSourceConfirmButton.addEventListener("click", () => {
                if ("buttonid" in deleteDataSourceConfirmButton.dataset) {
                    that.deleteDataSourceConfirm(deleteDataSourceConfirmButton.dataset.buttonid);
                }
            });
        }
        document.addEventListener("click", (e) => {
            let target = e.target;
            if (target.classList.contains("deleteDataSourceComments")) {
                let deleteButton = target;
                that.deleteDataSource(deleteButton);
            }
        });
    }
    showDeleteDataSource() {
        var programContainers = document.querySelectorAll(".performance-data-table, #futureReadyPAIndexGradeLevelAndStudentGroupStrengths, #futureReadyPAIndexGradeLevelAndStudentGroupChallenges");
        for (let container of programContainers) {
            let dataSources = container.querySelectorAll(".rowContainer");
            let deleteButtons = container.querySelectorAll(".deleteDataSourceComments");
            if (dataSources.length > 1) {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = deleteButton;
                    deleteButtonElement.classList.remove("hide");
                }
            }
            else {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = deleteButton;
                    deleteButtonElement.classList.add("hide");
                }
            }
        }
    }
    deleteDataSource(deleteButton) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }
        if (hasExistingData) {
            that.deleteDataSourceConfirmModal.callingId = deleteButton.id;
            that.deleteDataSourceConfirmModal.show();
            that.deleteDataSourceConfirmModal.addAttributeToElement(that.deleteDataSourceConfirmModal.id, "#deleteDataSourceConfirm", "buttonid", deleteButton.id);
        }
        else {
            let container = Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }
    deleteDataSourceConfirm(buttonId) {
        let that = this;
        let deleteButton = document.getElementById(buttonId);
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        that.deleteDataSourceConfirmModal.hide();
        Core.showLoader();
        let allRemoveElements = [];
        //Get all data source elements with this row and button row
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                allRemoveElements.push(parseInt(htmlElement.dataset.planpropertypk));
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeleteDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                dataSourceRow.parentNode.removeChild(dataSourceRow);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row successfully deleted", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this data source", 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allRemoveElements));
    }
    setUpDeleteDataSourceConfirmModal(buttonId) {
        let that = this;
        let deleteButton = document.getElementById(buttonId);
        that.deleteDataSourceConfirmModal.callingId = deleteButton.id;
        that.deleteDataSourceConfirmModal.show();
        that.deleteDataSourceConfirmModal.addAttributeToElement(that.deleteDataSourceConfirmModal.id, "#deleteDataSourceConfirm", "buttonid", deleteButton.id);
    }
}
//FutureReadyPAIndex
class FutureReadyPAIndex {
    constructor() {
        let that = this;
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["futureReadyPAIndexField", "futureReadyPAIndexFieldMulti"];
        let statewide = document.getElementById("futureReadyPAIndexMeasureASS2030GAddRow");
        if (statewide !== null)
            statewide.addEventListener("click", (e) => this.addStrengthAllStudent());
        let improvement = document.getElementById("futureReadyPAIndexMeasureSIOSITAddRow");
        if (improvement !== null)
            improvement.addEventListener("click", (e) => this.addStrengthStatewideImprovement());
        let challenge = document.getElementById("futureReadyPAIndexChallengeAddRow");
        if (challenge !== null)
            challenge.addEventListener("click", (e) => this.addChallenge());
        let schoollevelstrength = document.getElementById("futureReadyPAIndexSchoolLevelStrengthsAddRow");
        if (schoollevelstrength !== null)
            schoollevelstrength.addEventListener("click", (e) => this.addSchoolLevelStrength());
        let schoollevelchallenge = document.getElementById("futureReadyPAIndexSchoolLevelChallengesAddRow");
        if (schoollevelchallenge !== null)
            schoollevelchallenge.addEventListener("click", (e) => this.addSchoolLevelChallenge());
        let gradelevelstudentgroupstrength = document.getElementById("futureReadyPAIndexGradeLevelAndStudentGroupStrengthsAddRow");
        if (gradelevelstudentgroupstrength !== null)
            gradelevelstudentgroupstrength.addEventListener("click", (e) => this.addGradeLevelStudentGroupStrength());
        let gradelevelstudentgroupchallenge = document.getElementById("futureReadyPAIndexGradeLevelAndStudentGroupChallengesAddRow");
        if (gradelevelstudentgroupchallenge !== null)
            gradelevelstudentgroupchallenge.addEventListener("click", (e) => this.addGradeLevelStudentGroupChallenge());
        let saveButton = document.getElementById("futureReadyPAIndexSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        document.addEventListener("click", (e) => {
            let target = e.target;
            if (target.classList.contains("deleteStrengthComments")) {
                let gradelevelstudentgroupstrengthButton = target;
                that.deleteGradeLevelStudentGroupStrength(gradelevelstudentgroupstrengthButton);
            }
            else if (target.classList.contains("deleteChallengeComments")) {
                let gradelevelstudentgroupchallengeButton = target;
                that.deleteGradeLevelStudentGroupChallenge(gradelevelstudentgroupchallengeButton);
            }
        });
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let reminderCloseButton = document.getElementById("futureReadyPAIndexReportReminderClose");
        if (reminderCloseButton !== null) {
            reminderCloseButton.addEventListener("click", (e) => this.closeReminderModal());
        }
        const downloadFRPAIFile = document.getElementById("downloadFRPAIFile");
        if (downloadFRPAIFile !== null)
            downloadFRPAIFile.addEventListener("click", (e) => this.getFRPAIFile(e));
    }
    closeReminderModal() {
        let close = document.getElementById("closeModalFRPAIndexReportsReminder");
        close.click();
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("futureReadyPAIndexField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allCheckElements = document.getElementsByClassName("futureReadyPAIndexCheckboxField");
        for (let ele of allCheckElements) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const buttonRow = parseInt(element.dataset.buttonrow);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                ButtonRow: buttonRow
            };
            allSaveElements.push(saveItem);
        }
        const allMultis = [];
        const multiCheckboxes = document.getElementsByClassName("futureReadyPAIndexFieldMulti");
        for (const multi of multiCheckboxes) {
            //get the data for this dropdown
            const multiElement = multi;
            const parentPropertyPK = multiElement.dataset.propertypk;
            const parentPlanPropertyPK = multiElement.dataset.planpropertypk;
            const thisRow = multiElement.dataset.row;
            const thisMulti = {
                "PropertyPK": parentPropertyPK,
                "PlanPropertyPK": parentPlanPropertyPK,
                "RowNbr": thisRow,
                "Checkboxes": []
            };
            //get all checks for this checkbox
            const allTheseCheckboxes = document.querySelectorAll(`.futureReadyPAIndexFieldMultiCheckbox[data-row='${thisRow}'][data-parentpropertypk='${parentPropertyPK}']`);
            const theseChecks = [];
            for (const thisCheckbox of allTheseCheckboxes) {
                const checkElement = thisCheckbox;
                const checkLookupCodePK = checkElement.dataset.lookupcodepk;
                const checkPlanPropertyPK = checkElement.dataset.planpropertypk;
                const checkPropertyPK = checkElement.dataset.parentpropertypk;
                if ((checkPlanPropertyPK !== "" && checkPlanPropertyPK !== "0") || checkElement.checked) {
                    let textValue = "off";
                    if (checkElement.checked)
                        textValue = "on";
                    const thisCheck = {
                        "PropertyPK": checkPropertyPK,
                        "PlanPropertyPK": checkPlanPropertyPK,
                        "LookupCodePK": checkLookupCodePK,
                        "DataPointPlanPropertyPK": parentPlanPropertyPK,
                        "TextValue": textValue
                    };
                    theseChecks.push(thisCheck);
                }
            }
            thisMulti.Checkboxes = theseChecks;
            allMultis.push(thisMulti);
        }
        const saveData = {
            "SaveData": allSaveElements,
            "MultiDropdown": allMultis,
            "PlanFK": planPk
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveFutureReadyPAIndex', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    addStrengthAllStudent() {
        let core = this._core;
        let table = document.getElementById("futureReadyPAIndexMeasureASS2030G");
        let newRow = 0;
        let strengthList = document.getElementsByClassName("frStrengthMeasureASS2030G");
        let _that = this;
        for (let strength of strengthList) {
            let element = strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexStrength');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=ASS2030G");
    }
    addStrengthStatewideImprovement() {
        let core = this._core;
        let table = document.getElementById("futureReadyPAIndexMeasureSIOSIT");
        let newRow = 0;
        let strengthList = document.getElementsByClassName("frStrengthMeasureSIOSIT");
        let _that = this;
        for (let strength of strengthList) {
            let element = strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexStrength');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Statewide Strength Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=SIOSIT");
    }
    addChallenge() {
        let core = this._core;
        let table = document.getElementById("futureReadyPAIndexChallenge");
        let newRow = 0;
        let challengeList = document.getElementsByClassName("frChallenge");
        let _that = this;
        for (let challenge of challengeList) {
            let element = challenge;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexChallenge');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "");
    }
    addSchoolLevelStrength() {
        let core = this._core;
        let table = document.getElementById("futureReadyPAIndexSchoolLevelStrengths");
        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;
        let newRow = 0;
        let strengthList = document.getElementsByClassName("frSchoolLevelStrengths");
        let _that = this;
        for (let strength of strengthList) {
            let element = strength;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexSchoolLevel');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Strengths&planFK=" + planPk);
    }
    addSchoolLevelChallenge() {
        let core = this._core;
        let table = document.getElementById("futureReadyPAIndexSchoolLevelChallenges");
        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;
        let newRow = 0;
        let challengeList = document.getElementsByClassName("frSchoolLevelChallenges");
        let _that = this;
        for (let challenge of challengeList) {
            let element = challenge;
            if (newRow < parseInt(element.dataset.row)) {
                newRow = parseInt(element.dataset.row);
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexSchoolLevel');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Challenges&planFK=" + planPk);
    }
    addGradeLevelStudentGroupStrength() {
        let core = this._core;
        let newRow = 0;
        let _that = this;
        let gradeLevelStudentGroupFields = document.getElementsByClassName("frGradeLevelAndStudentGroupStrengths");
        for (let glsg of gradeLevelStudentGroupFields) {
            let glsgElement = glsg;
            if (newRow < parseInt(glsgElement.dataset.row))
                newRow = parseInt(glsgElement.dataset.row);
        }
        newRow++;
        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexGradeLevelAndStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#futureReadyPAIndexGradeLevelAndStudentGroupStrengths > div > div").append(xhr.responseText);
                $('.multiSelectESSA').MultiSelect({
                    ShowAllCheckbox: false
                });
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Strength Row Added", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Strengths&planFK=" + parseInt(planPk));
    }
    addGradeLevelStudentGroupChallenge() {
        let core = this._core;
        let newRow = 0;
        let _that = this;
        let gradeLevelStudentGroupFields = document.getElementsByClassName("frGradeLevelAndStudentGroupChallenges");
        for (let glsg of gradeLevelStudentGroupFields) {
            let glsgElement = glsg;
            if (newRow < parseInt(glsgElement.dataset.row))
                newRow = parseInt(glsgElement.dataset.row);
        }
        let planElement = document.getElementById("futureReadyPAIndexForm");
        let planPk = planElement.dataset.planpk;
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/FutureReadyPAIndexGradeLevelAndStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#futureReadyPAIndexGradeLevelAndStudentGroupChallenges > div > div").append(xhr.responseText);
                $('.multiSelectESSA').MultiSelect({
                    ShowAllCheckbox: false
                });
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&label=Challenges&planFK=" + parseInt(planPk));
    }
    deleteGradeLevelStudentGroupStrength(deleteButton) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }
        if (hasExistingData) {
            that._planSectionSet.setUpDeleteDataSourceConfirmModal(deleteButton.id);
        }
        else {
            let container = Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that._planSectionSet.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }
    deleteGradeLevelStudentGroupChallenge(deleteButton) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }
        if (hasExistingData) {
            that._planSectionSet.setUpDeleteDataSourceConfirmModal(deleteButton.id);
        }
        else {
            let container = Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that._planSectionSet.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }
    getFRPAIFile(e) {
        const element = e.target;
    }
}
//Future Ready PA Academics
class FutureReadyPAAcademics {
    constructor() {
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["futureReadyPAAcademicsField", "futureReadyPAAcademicsCheckboxField"];
        let elaButton = document.getElementById("futureReadyPAAcademicsAddDataSourceELA");
        if (elaButton !== null)
            elaButton.addEventListener("click", (e) => this.addDataSource("futureReadyPAAcademicsELA", "ELA", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));
        let mathButton = document.getElementById("futureReadyPAAcademicsAddDataSourceMath");
        if (mathButton !== null)
            mathButton.addEventListener("click", (e) => this.addDataSource("futureReadyPAAcademicsMath", "Mathematics", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));
        let steeButton = document.getElementById("futureReadyPAAcademicsAddDataSourceSTEE");
        if (steeButton !== null)
            steeButton.addEventListener("click", (e) => this.addDataSource("futureReadyPAAcademicsSTEE", "STEE", "futureReadyPAAcademicsField", "spdfuturereadypaacademics"));
        let saveButton = document.getElementById("futureReadyPAAcademicsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this.grouping();
        this.initializeRequiredFieldsCustom();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const futureReadyPAAcademicsGroupings = document.getElementsByClassName("futureReadyPAAcademicsGrouping");
        for (const futureReadyPAAcademicsGrouping of futureReadyPAAcademicsGroupings)
            futureReadyPAAcademicsGrouping.addEventListener("change", (e) => this.groupingChange(e.target));
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("futureReadyPAAcademicsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("futureReadyPAAcademicsField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxInputs = document.getElementsByClassName("futureReadyPAAcademicsCheckboxField");
        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let textValue = "off";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            if (element.checked || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveFutureReadyPAAcademics', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addDataSource(tableName, pageSection, className, pageCode) {
        let core = this._core;
        let table = document.getElementById(tableName);
        let inputBoxes = table.querySelectorAll("textarea");
        let rowCount = 0;
        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }
        rowCount++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/StudentPerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }
    grouping() {
        const futureReadyPAAcademicsGroupings = document.getElementsByClassName("futureReadyPAAcademicsGrouping");
        for (const futureReadyPAAcademicsGrouping of futureReadyPAAcademicsGroupings)
            this.groupingChange(futureReadyPAAcademicsGrouping);
    }
    groupingChange(checkElement) {
        const group = checkElement.dataset.group;
        const allElementsInGroup = document.querySelectorAll(`.futureReadyPAAcademicsGroupField[data-group='${group}']`);
        for (const element of allElementsInGroup) {
            const ele = element;
            if (checkElement.checked) {
                this.makeElementOptional(ele);
            }
            else {
                this.makeElementRequired(ele);
            }
        }
    }
    makeElementOptional(inputElement) {
        inputElement.dataset.percent = "0.00";
        inputElement.setAttribute("aria-required", "false");
        inputElement.readOnly = true;
        inputElement.disabled = true;
    }
    makeElementRequired(inputElement) {
        inputElement.dataset.percent = "1.00";
        inputElement.setAttribute("aria-required", "true");
        inputElement.readOnly = false;
        inputElement.disabled = false;
        let label = Core.findLabelForInput(inputElement);
        if (label !== null && !label.classList.contains("isRequired")) {
            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
            label.classList.add("isRequired");
        }
    }
    initializeRequiredFieldsCustom() {
        //If any field in a row is filled out, force rest of row required 
        let accordions = document.querySelectorAll(".Accordion-panel:not(.summary-dark)");
        for (let accordion of accordions) {
            let accordionRows = accordion.querySelectorAll("tr.rowContainer");
            for (let accordionRow of accordionRows) {
                let elements = accordionRow.getElementsByClassName("futureReadyPAAcademicsField");
                for (let element of elements) {
                    if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = element;
                        if (element.value != "" && !element.disabled) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
}
//Related Academics
class RelatedAcademics {
    constructor() {
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["relatedAcademicsField", "relatedAcademicsFileField"];
        let careerReadinessButton = document.getElementById("relatedAcademicsAddDataSourceCareerReadiness");
        if (careerReadinessButton !== null)
            careerReadinessButton.addEventListener("click", (e) => this.addDataSource("relatedAcademicsCareerReadiness", "Career", "relatedAcademicsField", "spdrelatedacademics"));
        let careerAndTechButton = document.getElementById("relatedAcademicsAddDataSourceCareerAndTech");
        if (careerAndTechButton !== null)
            careerAndTechButton.addEventListener("click", (e) => this.addDataSource("relatedAcademicsCareerAndTech", "CTEP", "relatedAcademicsField", "spdrelatedacademics"));
        let artsAndHumanitiesButton = document.getElementById("relatedAcademicsAddDataSourceArtsAndHumanities");
        if (artsAndHumanitiesButton !== null)
            artsAndHumanitiesButton.addEventListener("click", (e) => this.addDataSource("relatedAcademicsArtsAndHumanities", "Arts", "relatedAcademicsField", "spdrelatedacademics"));
        let environmentAndEcologyButton = document.getElementById("relatedAcademicsAddDataSourceEnvironmentAndEcology");
        if (environmentAndEcologyButton !== null)
            environmentAndEcologyButton.addEventListener("click", (e) => this.addDataSource("relatedAcademicsEnvironmentAndEcology", "EAE", "relatedAcademicsField", "spdrelatedacademics"));
        let familyAndConsumerButton = document.getElementById("relatedAcademicsAddDataSourceFamilyAndConsumer");
        if (familyAndConsumerButton !== null)
            familyAndConsumerButton.addEventListener("click", (e) => this.addDataSource("relatedAcademicsFamilyAndConsumer", "FCS", "relatedAcademicsField", "spdrelatedacademics"));
        let healthAndSafetyButton = document.getElementById("relatedAcademicsAddDataSourceHealthAndSafety");
        if (healthAndSafetyButton !== null)
            healthAndSafetyButton.addEventListener("click", (e) => this.addDataSource("relatedAcademicsHealthAndSafety", "HSPE", "relatedAcademicsField", "spdrelatedacademics"));
        let socialStudiesButton = document.getElementById("relatedAcademicsAddDataSourceSocialStudies");
        if (socialStudiesButton !== null)
            socialStudiesButton.addEventListener("click", (e) => this.addDataSource("relatedAcademicsSocialStudies", "SS", "relatedAcademicsField", "spdrelatedacademics"));
        let saveButton = document.getElementById("relatedAcademicsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let noCTEPCheckbox = document.getElementById("relatedAcademicsCareerandTechnicalEducationCTEProgramsOmit");
        if (noCTEPCheckbox !== null)
            noCTEPCheckbox.addEventListener("click", (e) => this.noPrograms("relatedAcademicsCareerAndTech", "relatedAcademicsCareerandTechnicalEducationCTEProgramsOmit"));
        let noArtsAndHumanitiesCheckbox = document.getElementById("relatedAcademicsArtsandHumanitiesOmit");
        if (noArtsAndHumanitiesCheckbox !== null)
            noArtsAndHumanitiesCheckbox.addEventListener("click", (e) => this.noPrograms("relatedAcademicsArtsAndHumanities", "relatedAcademicsArtsandHumanitiesOmit"));
        let noEnvironmentAndEcologyCheckbox = document.getElementById("relatedAcademicsEnvironmentandEcologyOmit");
        if (noEnvironmentAndEcologyCheckbox !== null)
            noEnvironmentAndEcologyCheckbox.addEventListener("click", (e) => this.noPrograms("relatedAcademicsEnvironmentAndEcology", "relatedAcademicsEnvironmentandEcologyOmit"));
        let noFamilyAndConsumerCheckbox = document.getElementById("relatedAcademicsFamilyandConsumerSciencesOmit");
        if (noFamilyAndConsumerCheckbox !== null)
            noFamilyAndConsumerCheckbox.addEventListener("click", (e) => this.noPrograms("relatedAcademicsFamilyAndConsumer", "relatedAcademicsFamilyandConsumerSciencesOmit"));
        let noHealthAndSafetyCheckbox = document.getElementById("relatedAcademicsHealthSafetyandPhysicalEducationOmit");
        if (noHealthAndSafetyCheckbox !== null)
            noHealthAndSafetyCheckbox.addEventListener("click", (e) => this.noPrograms("relatedAcademicsHealthAndSafety", "relatedAcademicsHealthSafetyandPhysicalEducationOmit"));
        let noSocialStudiesCheckbox = document.getElementById("relatedAcademicsSocialStudiesCivicsandGovernmentEconomicsGeographyHistoryOmit");
        if (noSocialStudiesCheckbox !== null)
            noSocialStudiesCheckbox.addEventListener("click", (e) => this.noPrograms("relatedAcademicsSocialStudies", "relatedAcademicsSocialStudiesCivicsandGovernmentEconomicsGeographyHistoryOmit"));
        let allPDEApprovedCheckboxes = document.getElementsByClassName("checkIfPDEApprovedProgramCheckbox");
        for (let allPDEApprovedCheckbox of allPDEApprovedCheckboxes)
            allPDEApprovedCheckbox.addEventListener("click", (e) => this.pdeApprovedProgram(e));
        const relatedAcademicsArticulationAgreementsNotAvailableCheck = document.getElementById("relatedAcademicsArticulationAgreementsNotAvailable");
        if (relatedAcademicsArticulationAgreementsNotAvailableCheck !== null) {
            relatedAcademicsArticulationAgreementsNotAvailableCheck.addEventListener("change", (e) => this.articulationAgreementNotAvailable(e.target));
            this.articulationAgreementNotAvailable(relatedAcademicsArticulationAgreementsNotAvailableCheck);
        }
        let fromSaveElement = document.getElementById("fromSave");
        this.initializeRequiredFieldsCustom();
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.bindUploads();
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        const addArticulationAgreementButton = document.getElementById("relatedAcademicsAddArticulationAgreement");
        if (addArticulationAgreementButton !== null)
            addArticulationAgreementButton.addEventListener("click", (e) => this.addArticulationAgreement());
        let deleteArticulationAgreementConfirmButton = document.getElementById("relatedAcademicsDeleteArticulationAgreementConfirm");
        if (deleteArticulationAgreementConfirmButton !== null)
            deleteArticulationAgreementConfirmButton.addEventListener("click", (e) => this.deleteArticulationAgreementConfirm(e));
        let deleteArticulationAgreementCancelButton = document.getElementById("relatedAcademicsDeleteArticulationAgreementCancel");
        if (deleteArticulationAgreementCancelButton !== null)
            deleteArticulationAgreementCancelButton.addEventListener("click", (e) => this.deleteArticulationAgreementCancel());
        this.bindDeleteArticulationAgreements();
    }
    getCore() {
        return this._core;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("relatedAcademicsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("relatedAcademicsField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("relatedAcademicsCheckField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.dataset.row && htmlElement.dataset.row !== "0") {
                rowNumber = parseInt(htmlElement.dataset.row);
            }
            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        const allFileElements = [];
        const files = document.getElementsByClassName("relatedAcademicsFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: parseInt(planPk)
            };
            allFileElements.push(saveItem);
        }
        const allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPk
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveRelatedAcademics', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    initializeRequiredFieldsCustom() {
        //If any field in a row is filled out, force rest of row required 
        let accordions = document.querySelectorAll(".Accordion-panel:not(.summary-dark)");
        for (let accordion of accordions) {
            let accordionRows = accordion.querySelectorAll("tr.rowContainer");
            for (let accordionRow of accordionRows) {
                let elements = accordionRow.getElementsByClassName("relatedAcademicsField");
                for (let element of elements) {
                    if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = element;
                        if (element.value != "" && !element.disabled) {
                            for (let otherElement of elements) {
                                if (otherElement.dataset.percent == "1.00") {
                                    otherElement.dataset.forcerequired = "true";
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    addDataSource(tableName, pageSection, className, pageCode) {
        let core = this._core;
        let table = document.getElementById(tableName);
        let inputBoxes = table.querySelectorAll("textarea");
        let rowCount = 0;
        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }
        rowCount++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/StudentPerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }
    noPrograms(tableName, checkboxName) {
        let table = document.getElementById(tableName);
        let inputBoxes = table.querySelectorAll("textarea");
        let checkbox = document.getElementById(checkboxName);
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
        else {
            for (let input of inputBoxes) {
                if (checkbox.checked) {
                    input.value = " ";
                    input.readOnly = true;
                }
                else {
                    input.readOnly = false;
                    input.value = "";
                }
            }
        }
    }
    pdeApprovedProgram(e) {
        let checkboxElement = e.target;
        if (checkboxElement.readOnly) {
            if (checkboxElement.checked)
                checkboxElement.checked = false;
            else
                checkboxElement.checked = true;
            return false;
        }
    }
    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("relatedAcademicsOmitCheckField");
        for (let element of allElements) {
            let htmlElement = element;
            let tableName = element.getAttribute("data-tablename");
            let table = document.getElementById(tableName);
            let inputBoxes = table.querySelectorAll("textarea");
            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }
    bindUploads() {
        const uploadArticulationAgreements = document.querySelectorAll(`.relatedAcademicsArticulationAgreementsUploadArticulationAgreement`);
        for (const uploadArticulationAgreement of uploadArticulationAgreements)
            uploadArticulationAgreement.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let row = e.dataset.row;
        const that = this;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);
                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-row='${row}']`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("relatedAcademicsFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    element.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    addArticulationAgreement() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let formElement = document.getElementById("relatedAcademicsForm");
            let planFK = formElement.dataset.planpk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll(".relatedAcademicsArticulationAgreement");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            let response = yield fetch(`/Set/RelatedAcademicsAddArticulationAgreement/${planFK}/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const container = document.createElement("div");
                container.classList.add("relatedAcademicsArticulationAgreement");
                container.classList.add("articulation-agreement");
                container.dataset.row = newRow.toString();
                container.innerHTML = output;
                const articulationAgreementList = document.getElementById("articulationAgreementList");
                articulationAgreementList.appendChild(container);
                that.bindUploads();
                that.bindDeleteFiles();
                that.bindDeleteArticulationAgreements();
                Core.hideLoader();
            }
            else {
                Core.hideLoader();
            }
        });
    }
    bindDeleteArticulationAgreements() {
        const allDeletes = document.getElementsByClassName("deleteArticulationAgreement");
        for (const deleteButton of allDeletes)
            deleteButton.addEventListener("click", (e) => this.showDeleteArticulationAgreement(e));
    }
    showDeleteArticulationAgreement(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteArticulationAgreementModal", null);
        modal.addAttributeToElement("deleteArticulationAgreementModal", "#relatedAcademicsDeleteArticulationAgreementConfirm", "row", row);
        modal.show();
    }
    deleteArticulationAgreementConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            let buttonElement = e.target;
            let row = buttonElement.dataset.row;
            const planPKs = [];
            const filePKs = [];
            const allPlanData = document.querySelectorAll(`.relatedAcademicsArticulationAgreementField[data-row='${row}']`);
            const allFileData = document.querySelectorAll(`.uploadFileArticulationAgreementColumn[data-row='${row}']`);
            for (const planData of allPlanData) {
                const ele = planData;
                planPKs.push(ele.dataset.planpropertypk);
            }
            for (const fileData of allFileData) {
                const ele = fileData;
                filePKs.push(ele.dataset.planpropertyfilepk);
            }
            const dataModel = {
                "PlanPropertyPKs": planPKs,
                "PlanPropertyFilePKs": filePKs
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            const response = yield fetch(`/Set/DeleteArticulationAgreement`, settings);
            if (response.ok) {
                const output = yield response.json();
                if (output === true) {
                    const articulationAgreement = document.querySelector(`.relatedAcademicsArticulationAgreement[data-row='${row}']`);
                    articulationAgreement.remove();
                    Core.hideLoader();
                    let modal = new Modal("deleteArticulationAgreementModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "Articulation Agreement successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    let modal = new Modal("deleteArticulationAgreementModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the articulation agreement. Please try again.", 'error', 3000, null);
                }
            }
        });
    }
    deleteArticulationAgreementCancel() {
        let modal = new Modal("deleteArticulationAgreementModal", null);
        modal.hide();
    }
    articulationAgreementNotAvailable(e) {
        const checkBox = e;
        if (!checkBox.checked) {
            const allInputs = document.getElementsByClassName("relatedAcademicsArticulationAgreementField");
            for (const input of allInputs) {
                const ele = input;
                this._core.forceElementRequired(ele);
                ele.readOnly = false;
                ele.disabled = false;
            }
            const allFiles = document.getElementsByClassName("relatedAcademicsArticulationAgreementsUploadArticulationAgreement");
            for (const file of allFiles) {
                const ele = file;
                this._core.forceElementRequired(ele);
                ele.readOnly = false;
                ele.disabled = false;
            }
        }
        else {
            const allInputs = document.getElementsByClassName("relatedAcademicsArticulationAgreementField");
            for (const input of allInputs) {
                const ele = input;
                this._core.forceElementOptional(ele);
                ele.readOnly = true;
                ele.disabled = true;
            }
            const allFiles = document.getElementsByClassName("relatedAcademicsArticulationAgreementsUploadArticulationAgreement");
            for (const file of allFiles) {
                const ele = file;
                this._core.forceElementOptional(ele);
                ele.readOnly = true;
                ele.disabled = true;
            }
        }
    }
    doValidationCustom() {
    }
}
//Equity Considerations
class EquityConsiderations {
    constructor() {
        this._core = new Core();
        this._planSectionSet = new PlanSectionSet();
        this.validationClasses = ["equityConsiderationsField"];
        let studentEnglishLearnersButton = document.getElementById("equityConsiderationsAddStudentGroupEnglishLearners");
        if (studentEnglishLearnersButton !== null)
            studentEnglishLearnersButton.addEventListener("click", (e) => this.addDataSource("equityConsiderationsEnglishLearners", "EL", "equityConsiderationsField", "spdequityconsiderations"));
        let studentWithDisabilitiesButton = document.getElementById("equityConsiderationsAddStudentGroupStudentsWithDisabilities");
        if (studentWithDisabilitiesButton !== null)
            studentWithDisabilitiesButton.addEventListener("click", (e) => this.addDataSource("equityConsiderationsStudentsWithDisabilities", "DIS", "equityConsiderationsField", "spdequityconsiderations"));
        let studentsConsideredEconomicallyDisadvantagedButton = document.getElementById("equityConsiderationsAddStudentGroupEconomicallyDisadvantaged");
        if (studentsConsideredEconomicallyDisadvantagedButton !== null)
            studentsConsideredEconomicallyDisadvantagedButton.addEventListener("click", (e) => this.addDataSource("equityConsiderationsEconomicallyDisadvantaged", "ED", "equityConsiderationsField", "spdequityconsiderations"));
        let groupsByRace = document.getElementById("equityConsiderationsAddStudentGroupGroupsByRace");
        if (groupsByRace !== null)
            groupsByRace.addEventListener("click", (e) => this.addStudentGroup("equityConsiderationsGroupsByRace", "equityConsiderationsDropdownField"));
        let saveButton = document.getElementById("equityConsiderationsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let noEnglishLearnersCheckbox = document.getElementById("equityConsiderationsEnglishLearnersOmit");
        if (noEnglishLearnersCheckbox !== null)
            noEnglishLearnersCheckbox.addEventListener("click", (e) => this.noPrograms("equityConsiderationsEnglishLearners", "equityConsiderationsEnglishLearnersOmit"));
        let noStudentWithDisabilitiesCheckbox = document.getElementById("equityConsiderationsStudentswithDisabilitiesOmit");
        if (noStudentWithDisabilitiesCheckbox !== null)
            noStudentWithDisabilitiesCheckbox.addEventListener("click", (e) => this.noPrograms("equityConsiderationsStudentsWithDisabilities", "equityConsiderationsStudentswithDisabilitiesOmit"));
        let noStudentsConsideredEconomicallyDisadvantagedCheckbox = document.getElementById("equityConsiderationsStudentsConsideredEconomicallyDisadvantagedOmit");
        if (noStudentsConsideredEconomicallyDisadvantagedCheckbox !== null)
            noStudentsConsideredEconomicallyDisadvantagedCheckbox.addEventListener("click", (e) => this.noPrograms("equityConsiderationsEconomicallyDisadvantaged", "equityConsiderationsStudentsConsideredEconomicallyDisadvantagedOmit"));
        let noStudentsByRaceEthnicityCheckbox = document.getElementById("equityConsiderationsStudentGroupsbyRaceEthnicityOmit");
        if (noStudentsByRaceEthnicityCheckbox !== null)
            noStudentsByRaceEthnicityCheckbox.addEventListener("click", (e) => this.noPrograms("equityConsiderationsGroupsByRace", "equityConsiderationsStudentGroupsbyRaceEthnicityOmit"));
        this.initializeRequiredFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("equityConsideraionsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("equityConsiderationsField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("equityConsiderationsDropdownField");
        let rowCount = 0;
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.selectedIndex > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: htmlElement.value,
                    RowNbr: rowCount,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
                rowCount++;
            }
        }
        allElements = document.getElementsByClassName("equityConsiderationsCheckField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveEquityConsiderations', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    initializeRequiredFieldsCustom() {
        //If any field in a row is filled out, force rest of row required 
        let accordions = document.querySelectorAll(".Accordion-panel:not(.summary-dark)");
        for (let accordion of accordions) {
            let accordionRows = accordion.querySelectorAll("tr.rowContainer");
            for (let accordionRow of accordionRows) {
                let elements = accordionRow.getElementsByClassName("equityConsiderationsField");
                for (let element of elements) {
                    if (element instanceof HTMLSelectElement) {
                        let htmlElement = element;
                        if (element.selectedIndex > 0) {
                            for (let otherElement of elements) {
                                if (otherElement.dataset.percent == "1.00") {
                                    otherElement.dataset.forcerequired = "true";
                                }
                            }
                            break;
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = element;
                        if (element.value != "" && !element.disabled) {
                            for (let otherElement of elements) {
                                if (otherElement.dataset.percent == "1.00") {
                                    otherElement.dataset.forcerequired = "true";
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    addDataSource(tableName, pageSection, className, pageCode) {
        let core = this._core;
        let table = document.getElementById(tableName);
        let inputBoxes = table.querySelectorAll("textarea");
        let rowCount = 0;
        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }
        rowCount++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/EquityConsiderationsStudentGroupText', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("pageSection=" + pageSection + "&rowNumber=" + rowCount + "&className=" + className + "&pageCode=" + pageCode);
    }
    addStudentGroup(tableName, classNameBase) {
        let core = this._core;
        let table = document.getElementById(tableName);
        let inputBoxes = table.querySelectorAll("textarea");
        let rowCount = 0;
        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }
        rowCount++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/EquityConsiderationsStudentGroup', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Student Group Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount);
    }
    noPrograms(tableName, checkboxName) {
        let table = document.getElementById(tableName);
        let inputBoxes = table.querySelectorAll("textarea");
        let checkbox = document.getElementById(checkboxName);
        for (let input of inputBoxes) {
            if (checkbox.readOnly) {
                if (checkbox.checked)
                    checkbox.checked = false;
                else
                    checkbox.checked = true;
                return false;
            }
            else {
                if (checkbox.checked) {
                    input.value = " ";
                    input.readOnly = true;
                }
                else {
                    input.readOnly = false;
                    input.value = "";
                }
            }
        }
    }
    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("equityConsiderationsOmitCheckField");
        for (let element of allElements) {
            let htmlElement = element;
            let tableName = element.getAttribute("data-tablename");
            let table = document.getElementById(tableName);
            let inputBoxes = table.querySelectorAll("textarea");
            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }
}
//Supplemental LEA Plans
class SupplementalLEAPlans {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["supplementalLEAField"];
        let addAPlanButton = document.getElementById("supplementalLEAPlansAddPlan");
        if (addAPlanButton !== null)
            addAPlanButton.addEventListener("click", (e) => this.addPlan("supplementalLEAPlans", "supplementalLEAPlan"));
        let saveButton = document.getElementById("supplementalLEAPlansSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("supplementalLEAForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("supplementalLEAField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let toDelete = (htmlElement.value === "");
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveSupplementalLEAPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addPlan(tableName, classNameBase) {
        let core = this._core;
        let table = document.getElementById(tableName);
        let inputBoxes = table.querySelectorAll("textarea");
        let rowCount = 0;
        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }
        rowCount++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SupplementalLEAPlansAddPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount);
    }
}
//Designated Schools
class DesignatedSchools {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["designatedSchoolsDesignatedSchoolField", "designatedSchoolsChallengeField"];
        let addChallengeButton = document.getElementById("designatedSchoolsAddChallenge");
        if (addChallengeButton !== null)
            addChallengeButton.addEventListener("click", (e) => this.addChallenge("designatedSchoolsChallenges"));
        let saveButton = document.getElementById("designatedSchoolsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addDesignatedSchool = document.getElementById("addDesignatedSchool");
        if (addDesignatedSchool !== null)
            addDesignatedSchool.addEventListener("click", (e) => this.addDesignatedSchool());
        this.bindDeleteDesignatedSchoolRows();
        this.bindDeleteChallengeRows();
        this.bindDeletes();
        const deleteDesignatedSchoolRowConfirmButton = document.getElementById("designatedSchoolDeleteRowConfirm");
        if (deleteDesignatedSchoolRowConfirmButton !== null)
            deleteDesignatedSchoolRowConfirmButton.addEventListener("click", (e) => this.deleteDesignatedSchoolRowConfirm(e));
        const deleteDesignatedSchoolRowCancelButton = document.getElementById("designatedSchoolDeleteRowCancel");
        if (deleteDesignatedSchoolRowCancelButton !== null)
            deleteDesignatedSchoolRowCancelButton.addEventListener("click", (e) => this.deleteDesignatedSchoolRowCancel());
        const designatedSchoolChallengeDeleteRowConfirm = document.getElementById("designatedSchoolChallengeDeleteRowConfirm");
        if (designatedSchoolChallengeDeleteRowConfirm !== null)
            designatedSchoolChallengeDeleteRowConfirm.addEventListener("click", (e) => this.deleteDesignatedSchoolChallengeConfirm(e));
        const designatedSchoolChallengeDeleteRowCancel = document.getElementById("designatedSchoolChallengeDeleteRowCancel");
        if (designatedSchoolChallengeDeleteRowCancel !== null)
            designatedSchoolChallengeDeleteRowCancel.addEventListener("click", (e) => this.deleteDesignatedSchoolChallengeCancel());
        const designatedSchoolAccordionDeleteConfirmButton = document.getElementById("designatedSchoolAccordionDeleteConfirm");
        if (designatedSchoolAccordionDeleteConfirmButton !== null)
            designatedSchoolAccordionDeleteConfirmButton.addEventListener("click", (e) => this.deleteDesignatedSchoolAccordionConfirm(e));
        const designatedSchoolAccordionDeleteCancelButton = document.getElementById("designatedSchoolAccordionDeleteCancel");
        if (designatedSchoolAccordionDeleteCancelButton !== null)
            designatedSchoolAccordionDeleteCancelButton.addEventListener("click", (e) => this.deleteDesignatedSchoolAccordionCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("designatedSchoolsForm");
        let planPk = planElement.dataset.planpk;
        let allAccordionElements = document.getElementsByClassName("designatedSchoolsDesignatedSchoolField");
        for (let element of allAccordionElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            const dataPointPlanPropertyFK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let toDelete = (htmlElement.value === "");
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: toDelete,
                    DataPointPlanPropertyPK: dataPointPlanPropertyFK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        const allOtherSaveElements = [];
        const allOtherElements = document.getElementsByClassName("designatedSchoolsChallengeField");
        for (let ele of allOtherElements) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allOtherSaveElements.push(saveItem);
            }
        }
        var data = {
            "AccordionElementData": allSaveElements,
            "ElementData": allOtherSaveElements,
            "PlanFK": planPk
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveDesignatedSchools', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                core.doValidation(this.validationClasses);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }
    addChallenge(tableName) {
        const inputBoxes = document.querySelectorAll(".designatedSchoolsChallenge");
        let rowCount = 0;
        for (const input of inputBoxes)
            if (parseInt(input.getAttribute("data-row")) > rowCount)
                rowCount = parseInt(input.getAttribute("data-row"));
        rowCount++;
        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DesignatedSchoolsChallengesAddChallenge', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                const tableElement = document.querySelector("#designatedSchoolsChallenges tbody");
                const newTRElement = document.createElement("tr");
                newTRElement.dataset.row = rowCount.toString();
                newTRElement.innerHTML = xhr.responseText;
                tableElement.append(newTRElement);
                that.bindDeleteChallengeRows();
                Core.createHTMLAlert("alertMessageDiv", "New Challenge Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount);
    }
    addDesignatedSchool() {
        return __awaiter(this, void 0, void 0, function* () {
            const designatedSchoolsSchoolNameAndBranchNew = document.getElementById("designatedSchoolsSchoolNameAndBranchNew");
            const designatedSchoolsDesignationTypeNew = document.getElementById("designatedSchoolsDesignationTypeNew");
            const schoolName = designatedSchoolsSchoolNameAndBranchNew.value;
            const designationType = designatedSchoolsDesignationTypeNew.value;
            if (schoolName === "0" || designationType === "0") {
                Core.createHTMLAlert("alertMessageDiv", "Please choose a school and designation to add.", 'warning', 3000, null);
            }
            else {
                //set the values in the dropdowns back to -1 so they can't double click
                designatedSchoolsSchoolNameAndBranchNew.value = "0";
                designatedSchoolsDesignationTypeNew.value = "0";
                const formElement = document.getElementById("designatedSchoolsForm");
                const planFK = formElement.dataset.planpk;
                const dataModel = {
                    "PlanFK": planFK,
                    "DesignationTypeLookupFK": designationType,
                    "SchoolName": schoolName
                };
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };
                const response = yield fetch(`/Set/AddDesignatedSchool`, settings);
                if (response.ok) {
                    const output = yield response.text();
                    const newAccordion = document.createElement("div");
                    newAccordion.innerHTML = output;
                    const newAccordionIdElement = newAccordion.querySelector(".Accordion-trigger");
                    const newAccordionId = newAccordionIdElement.id;
                    const container = document.querySelector("#designatedSchoolsListing");
                    container.append(newAccordion);
                    new CustomAccordion(newAccordionId);
                    this.bindDeleteDesignatedSchoolRows();
                    Core.createHTMLAlert("alertMessageDiv", "Designated School Added", 'success', 3000, null);
                }
            }
        });
    }
    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteDesignatedSchoolAccordion");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e) => this.showDeleteDesignatedSchoolAccordion(e));
    }
    showDeleteDesignatedSchoolAccordion(e) {
        const buttonElement = e.target;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;
        const modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
        modal.addAttributeToElement("deleteDesignatedSchoolAccordionModal", "#designatedSchoolAccordionDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyFK);
        modal.show();
    }
    deleteDesignatedSchoolAccordionCancel() {
        const modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
        modal.hide();
    }
    deleteDesignatedSchoolAccordionConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const buttonElement = e.target;
            const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;
            Core.showLoader();
            const response = yield fetch(`/Set/DeleteDesignatedSchoolAccordion/${dataPointPlanPropertyFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                if (output === "true") {
                    const container = document.querySelector(`.Accordion-trigger[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                    container.parentElement.remove();
                    const container2 = document.querySelector(`.Accordion-panel[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                    container2.remove();
                    Core.createHTMLAlert("alertMessageDiv", `The record was successfully removed.`, "success", 3000, null);
                    Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
                    Core.hideLoader();
                }
                const modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
                modal.hide();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
                Core.hideLoader();
                const modal = new Modal("deleteDesignatedSchoolAccordionModal", null);
                modal.hide();
            }
        });
    }
    bindDeleteDesignatedSchoolRows() {
        const deleteRows = document.getElementsByClassName("deleteDesignatedSchoolRow");
        for (const deleteRow of deleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteDesignatedSchoolRow(e));
    }
    showDeleteDesignatedSchoolRow(e) {
        const buttonElement = e.target;
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;
        const modal = new Modal("deleteDesignatedSchoolRowModal", null);
        modal.addAttributeToElement("deleteDesignatedSchoolRowModal", "#designatedSchoolDeleteRowConfirm", "row", row);
        modal.addAttributeToElement("deleteDesignatedSchoolRowModal", "#designatedSchoolDeleteRowConfirm", "datapointplanpropertypk", dataPointPlanPropertyFK);
        modal.show();
    }
    deleteDesignatedSchoolRowConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const buttonElement = e.target;
            const planPropertyPKs = [];
            const row = buttonElement.dataset.row;
            const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;
            const allPlanProps = document.querySelectorAll(`.designatedSchoolsDesignatedSchoolField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${row}']`);
            for (const prop of allPlanProps) {
                const ele = prop;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "0")
                    planPropertyPKs.push(ele.dataset.planpropertypk);
            }
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planPropertyPKs)
            };
            const response = yield fetch(`/Set/DeleteDesignatedSchoolRow`, settings);
            if (response.ok) {
                const output = yield response.text();
                if (output.toLowerCase().indexOf("true") >= 0) {
                    const trToRemove = document.querySelector(`.designatedSchoolData[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'] tr[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                    trToRemove.remove();
                    this.addDesignatedSchoolRow(dataPointPlanPropertyFK);
                    Core.createHTMLAlert("alertMessageDiv", "The Designated School data was successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the record. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                const modal = new Modal("deleteDesignatedSchoolRowModal", null);
                modal.hide();
            }
            else {
                const modal = new Modal("deleteDesignatedSchoolRowModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the record. Please try again.", 'error', 3000, null);
            }
        });
    }
    deleteDesignatedSchoolRowCancel() {
        const modal = new Modal("deleteDesignatedSchoolRowModal", null);
        modal.hide();
    }
    addDesignatedSchoolRow(dataPointPlanPropertyPK) {
        return __awaiter(this, void 0, void 0, function* () {
            const formElement = document.getElementById("designatedSchoolsForm");
            const planFK = formElement.dataset.planpk;
            let row = 0;
            const allRows = document.querySelectorAll(`#designatedSchoolData tr[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const thisRow of allRows) {
                const trEle = thisRow;
                const thisRowVal = parseInt(trEle.dataset.row);
                if (row < thisRowVal)
                    row = thisRowVal;
            }
            row++;
            const response = yield fetch(`/Set/AddDesignatedSchoolRow/${planFK}/${dataPointPlanPropertyPK}/${row}`, { "credentials": "include" });
            if (response.ok) {
                const output = yield response.text();
                const table = document.querySelector(`.designatedSchoolData[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody`);
                const newTR = document.createElement("tr");
                newTR.dataset.row = row.toString();
                newTR.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                newTR.innerHTML = output;
                table.append(newTR);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new record.", 'error', 3000, null);
            }
        });
    }
    bindDeleteChallengeRows() {
        const deleteDesignatedChallenges = document.getElementsByClassName("deleteDesignatedChallenge");
        for (const deleteDesignatedChallenge of deleteDesignatedChallenges)
            deleteDesignatedChallenge.addEventListener("click", (e) => this.showDeleteChallenge(e));
    }
    showDeleteChallenge(e) {
        const button = e.target;
        const row = button.dataset.row;
        const element = document.querySelector(`.designatedSchoolsChallenge[data-row='${row}']`);
        const planPropertyPK = element.dataset.planpropertypk;
        if (element.value !== "" || planPropertyPK !== "0") {
            const modal = new Modal("deleteDesignatedSchoolChallengeRowModal", null);
            modal.addAttributeToElement("deleteDesignatedSchoolChallengeRowModal", "#designatedSchoolChallengeDeleteRowConfirm", "planpropertypk", planPropertyPK);
            modal.addAttributeToElement("deleteDesignatedSchoolChallengeRowModal", "#designatedSchoolChallengeDeleteRowConfirm", "row", row);
            modal.show();
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There is no data to delete.", 'success', 3000, null);
        }
    }
    deleteDesignatedSchoolChallengeCancel() {
        const modal = new Modal("deleteDesignatedSchoolChallengeRowModal", null);
        modal.hide();
    }
    deleteDesignatedSchoolChallengeConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const planPropertyPK = button.dataset.planpropertypk;
            const row = button.dataset.row;
            const form = document.getElementById("designatedSchoolsForm");
            const planFK = form.dataset.planpk;
            const modal = new Modal("deleteDesignatedSchoolChallengeRowModal", null);
            const response = yield fetch(`/Set/DeleteDesignatedSchoolChallenge/${planFK}/${planPropertyPK}/${row}`, { "credentials": "include" });
            if (response.ok) {
                const output = yield response.json();
                if (output.success) {
                    const element = document.querySelector(`#designatedSchoolsChallenges tr[data-row='${row}']`);
                    element.remove();
                    modal.hide();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this challenge. Please try again.", 'error', 3000, null);
                    modal.hide();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this challenge. Please try again.", 'error', 3000, null);
                modal.hide();
            }
        });
    }
}
//ConditionsForLeadership
class ConditionsForLeadership {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["conditionsForLeadershipField", "conditionsForLeadershipSummaryField"];
        let saveButton = document.getElementById("conditionsForLeadershipSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planElement = document.getElementById("conditionsForLeadershipForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("conditionsForLeadershipField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.checked) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("conditionsForLeadershipSummaryField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveConditionsForLeadership', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//SummaryOfStrengths
class SummaryOfStrengths {
    constructor() {
        this._core = new Core();
        this.validationClasses = [
            "summaryOfStrengthsField",
            "summaryOfChallengesField",
            "summaryOfStrengthsReflectionsField"
        ];
        this.uncheckChallengeForConsiderationModal = new Modal("uncheckChallengeForConsiderationModal", null);
        this.uncheckStrengthForConsiderationModal = new Modal("uncheckStrengthForConsiderationModal", null);
        let that = this;
        let uncheckChallengeConfirmButton = document.getElementById("uncheckChallengeForConsiderationConfirm");
        if (uncheckChallengeConfirmButton !== null) {
            uncheckChallengeConfirmButton.addEventListener("click", function () {
                if ("challengecheckplanpropertypk" in uncheckChallengeConfirmButton.dataset && parseInt(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk) > 0) {
                    that.uncheckChallengeForConsideration(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk);
                }
                else {
                    that.uncheckChallengeForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this challenge for consideration", "error", 3000, null);
                }
            });
        }
        let exportWord = document.getElementById("exportSummaryOfStrengthsWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "SummaryOfStrengthsAndChallengesDataExport", "summarystrengthschallenges"));
        }
        let uncheckChallengeCancelButton = document.getElementById("uncheckChallengeForConsiderationCancel");
        if (uncheckChallengeCancelButton !== null) {
            uncheckChallengeCancelButton.addEventListener("click", function () {
                that.uncheckChallengeForConsiderationModal.hide();
                if (uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk) {
                    let checkbox = document.querySelector(`[data-planpropertypk='${uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk}']`);
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }
        let uncheckStrengthConfirmButton = document.getElementById("uncheckStrengthForConsiderationConfirm");
        if (uncheckStrengthConfirmButton !== null) {
            uncheckStrengthConfirmButton.addEventListener("click", function () {
                if ("strengthcheckplanpropertypk" in uncheckStrengthConfirmButton.dataset && parseInt(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk) > 0) {
                    that.uncheckStrengthForConsideration(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk);
                }
                else {
                    that.uncheckStrengthForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this strength for consideration", "error", 3000, null);
                }
            });
        }
        let uncheckStrengthCancelButton = document.getElementById("uncheckStrengthForConsiderationCancel");
        if (uncheckStrengthCancelButton !== null) {
            uncheckStrengthCancelButton.addEventListener("click", function () {
                that.uncheckStrengthForConsiderationModal.hide();
                if (uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk) {
                    let checkbox = document.querySelector(`[data-planpropertypk='${uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk}']`);
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }
        let saveButton = document.getElementById("summaryOfStrengthsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customValidation();
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let checkboxes = document.getElementsByClassName("checkbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e) => this.checkForReadonly(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    checkForReadonly(e) {
        let checkbox = e.target;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
        else {
            if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "summaryOfStrengthsStrengthCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckStrengthForConsiderationModal.show();
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationConfirm", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationCancel", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);
                    if (checkbox.id) {
                        this.uncheckStrengthForConsiderationModal.callingId = checkbox.id;
                    }
                }
            }
            else if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "summaryOfStrengthsChallengeCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckChallengeForConsiderationModal.show();
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationConfirm", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationCancel", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);
                    if (checkbox.id) {
                        this.uncheckChallengeForConsiderationModal.callingId = checkbox.id;
                    }
                }
            }
        }
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allStrengthElements = [];
        let allChallengeElements = [];
        let reflectionElement;
        let planElement = document.getElementById("summaryOfStrengthsForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("summaryOfStrengthsField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let val = "";
            if (htmlElement.checked) {
                val = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(htmlElement.dataset.datapointplanpropertypk),
                ButtonRow: 0
            };
            allStrengthElements.push(saveItem);
        }
        allElements = document.getElementsByClassName("summaryOfChallengesField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let val = "";
            if (htmlElement.checked) {
                val = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(htmlElement.dataset.datapointplanpropertypk),
                ButtonRow: 0
            };
            allChallengeElements.push(saveItem);
        }
        let reflectionElements = document.getElementsByClassName("summaryOfStrengthsReflectionsField");
        for (let element of reflectionElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };
            reflectionElement = saveItem;
        }
        let allSaveElements = {
            "Strengths": allStrengthElements,
            "Challenges": allChallengeElements,
            "Reflections": reflectionElement,
            "PlanFK": planPk
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveSummaryOfStrengths', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allChallengeElements.length === 0 && allStrengthElements.length === 0 && allSaveElements.Reflections.TextValue === "") {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                this.customValidation();
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    customValidation() {
        let errorCount = 0;
        //Check to see if at least two challenges have been checked for consideration
        let challengeCheckboxes = document.getElementsByClassName("summaryOfChallengesField");
        let challengeCheckedCount = 0;
        for (let check of challengeCheckboxes) {
            if (check.checked) {
                challengeCheckedCount++;
            }
        }
        if (challengeCheckedCount < 2) {
            errorCount++;
            let checkboxErrorMessage = document.getElementById("summaryChallengesErrorContainer");
            if (checkboxErrorMessage !== null) {
                checkboxErrorMessage.classList.add("show");
            }
        }
        //Check to see if at least one strength has been checked for consideration
        let strengthCheckboxes = document.getElementsByClassName("summaryOfStrengthsField");
        let strengthCheckedCount = 0;
        for (let check of strengthCheckboxes) {
            if (check.checked) {
                strengthCheckedCount++;
            }
        }
        if (strengthCheckedCount === 0) {
            errorCount++;
            let strengthCheckboxErrorMessage = document.getElementById("summaryStrengthsErrorContainer");
            if (strengthCheckboxErrorMessage !== null) {
                strengthCheckboxErrorMessage.classList.add("show");
            }
        }
        if (errorCount > 0) {
            //Set text for error message
            let message = document.getElementById("validationMessage");
            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            }
            else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");
            if (goToError !== null) {
                let firstFocusableEl = document.querySelector(".custom-error.show");
                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                }
                else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
            this._core.doValidation(null, true);
        }
        else {
            this._core.doValidation(null, false);
        }
    }
    uncheckChallengeForConsideration(planPropertyPK) {
        let that = this;
        that.uncheckChallengeForConsiderationModal.hide();
        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`);
        if (checkbox != null && "datapointplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Set/UncheckChallengeForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Challenge successfully unchecked for consideration", 'success', 3000, null);
                    //Uncheck checkbox and reset values
                    checkbox.checked = false;
                    checkbox.dataset.initiallychecked = "false";
                    checkbox.dataset.planpropertypk = "0";
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.datapointplanpropertypk);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
        }
    }
    uncheckStrengthForConsideration(planPropertyPK) {
        let that = this;
        that.uncheckStrengthForConsiderationModal.hide();
        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`);
        if (checkbox != null && "datapointplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Set/UncheckStrengthForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Strength successfully unchecked for consideration", 'success', 3000, null);
                    //Uncheck checkbox and reset values
                    if (checkbox != null) {
                        checkbox.checked = false;
                        checkbox.dataset.initiallychecked = "false";
                        checkbox.dataset.planpropertypk = "0";
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.datapointplanpropertypk);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
        }
    }
}
class IUPerformanceData {
    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = ["multiSelectServices", "performanceDataField", "performanceDataOtherField", "performanceDataStrengthChallenge"];
        this.hashClasses = ["performanceDataField", "performanceDataOtherField", "performanceDataStrengthChallenge"];
        this.deleteDataSourceConfirmModal = new Modal("deleteDataSourceModal", null);
        this.deleteOtherServiceConfirmModal = new Modal("deleteServiceModal", null);
        let deleteDataSourceCancelButton = document.getElementById("deleteDataSourceCancel");
        if (deleteDataSourceCancelButton != null) {
            deleteDataSourceCancelButton.addEventListener("click", () => {
                that.deleteDataSourceConfirmModal.hide();
            });
        }
        let deleteServiceCancelButton = document.getElementById("deleteServiceCancel");
        if (deleteServiceCancelButton != null) {
            deleteServiceCancelButton.addEventListener("click", () => {
                that.deleteOtherServiceConfirmModal.hide();
            });
        }
        let deleteDataSourceConfirmButton = document.getElementById("deleteDataSourceConfirm");
        if (deleteDataSourceConfirmButton != null) {
            deleteDataSourceConfirmButton.addEventListener("click", () => {
                if ("buttonid" in deleteDataSourceConfirmButton.dataset) {
                    that.deleteDataSourceConfirm(deleteDataSourceConfirmButton.dataset.buttonid);
                }
            });
        }
        let deleteServiceConfirmButton = document.getElementById("deleteServiceConfirm");
        if (deleteServiceConfirmButton != null) {
            deleteServiceConfirmButton.addEventListener("click", () => {
                if ("servicepk" in deleteServiceConfirmButton.dataset && "buttonid" in deleteServiceConfirmButton.dataset) {
                    that.deleteServiceConfirm(parseInt(deleteServiceConfirmButton.dataset.servicepk), deleteServiceConfirmButton.dataset.buttonid);
                }
            });
        }
        let planElement = document.getElementById("iuPerformanceDataForm");
        this.planFK = parseInt(planElement.dataset.planpk);
        this.templateFK = parseInt(planElement.dataset.templatefk);
        let saveButton = document.getElementById("iuPerformanceDataSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this.customInitializeRequiredFields();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.hashClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let reportServicesButton = document.getElementById("performanceDataSelectedServices");
        if (reportServicesButton !== null) {
            reportServicesButton.addEventListener("click", (e) => this.reportServices());
        }
        document.addEventListener("click", (e) => {
            let target = e.target;
            if (target.classList.contains("addDataSource") && "row" in target.dataset && "serviceLookupPk" in target.dataset) {
                that.addProgram(parseInt(target.dataset.row), parseInt(target.dataset.serviceLookupPk));
            }
            else if (target.classList.contains("addDataSourceOtherService") && "row" in target.dataset) {
                that.addOtherServiceProgram(parseInt(target.dataset.row));
            }
            else if (target.classList.contains("deleteDataSource")) {
                let deleteButton = target;
                that.deleteDataSource(deleteButton);
            }
            else if (target.classList.contains("deleteService")) {
                let deleteServiceButton = target;
                that.deleteService(deleteServiceButton);
            }
        });
        document.addEventListener("change", (e) => {
            let target = e.target;
            if (target.classList.contains("serviceProgram")) {
                let element = target;
                let container = Core.findClosest(element, ".rowContainer");
                let otherElementContainer = container.querySelector(".serviceProgramOtherContainer");
                let otherElement = container.querySelector(".serviceProgramOther");
                if (element.options[element.selectedIndex].dataset.lookupcode === "performanceProgramOther") {
                    otherElementContainer.classList.remove("hide");
                    that._core.forceElementRequired(otherElement);
                }
                else {
                    otherElementContainer.classList.add("hide");
                    that._core.forceElementOptional(otherElement);
                }
            }
        });
        let addOtherServiceButton = document.getElementById("addOtherService");
        if (addOtherServiceButton != null) {
            addOtherServiceButton.addEventListener("click", () => {
                that.addOtherService();
            });
        }
        that.showDeleteDataSource();
        let exportToExcelButton = document.getElementById("exportExcelPerformanceData");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                that.exportToExcel();
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.hashClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let that = this;
        let core = that._core;
        if (referrer !== "save" && this._core.checkSave(that) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allServiceData = [];
        let removeServicesData = [];
        let allServiceElements = document.querySelectorAll(".performanceDataService, .otherService");
        for (let element of allServiceElements) {
            let serviceElement = element;
            let propertyPK = 0;
            let lookupCodeFK = null;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let buttonRow = 0;
            let rowNbr = 0;
            let textValue = null;
            if (serviceElement.classList.contains("performanceDataService") && "lookupcodepk" in serviceElement.dataset) {
                lookupCodeFK = parseInt(serviceElement.dataset.lookupcodepk);
            }
            if ("propertypk" in serviceElement.dataset) {
                propertyPK = parseInt(serviceElement.dataset.propertypk);
            }
            if ("planpropertypk" in serviceElement.dataset) {
                planPropertyPK = parseInt(serviceElement.dataset.planpropertypk);
            }
            if (serviceElement.classList.contains("otherService")) {
                textValue = serviceElement.value;
            }
            let serviceSaveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: that.planFK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                RowNbr: rowNbr,
                LookupCodeFK: lookupCodeFK,
                IsDeletedInd: false,
                ButtonRow: buttonRow,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK
            };
            if (serviceElement.checked || serviceElement.classList.contains("otherService")) {
                let allDataSourceData = [];
                let equityConsiderationsSaveItem = null;
                let equityConsiderationsElement = null;
                if (serviceElement.classList.contains("otherService")) {
                    equityConsiderationsElement = document.querySelector(`.performanceDataOtherField.serviceProgramEquityConsiderations[data-buttonrow='${serviceElement.dataset.row}']`);
                }
                else {
                    equityConsiderationsElement = document.querySelector(`.performanceDataField.serviceProgramEquityConsiderations[data-service-lookup-pk='${serviceElement.dataset.lookupcodepk}']`);
                }
                if (equityConsiderationsElement != null) {
                    let equityPropertyPK = 0;
                    let equityLookupCodeFK = null;
                    let equityPlanPropertyPK = 0;
                    let equityDataPointPlanPropertyPK = 0;
                    let equityButtonRow = 0;
                    let equityRowNbr = 0;
                    let equityTextValue = equityConsiderationsElement.value;
                    if ("propertypk" in equityConsiderationsElement.dataset) {
                        equityPropertyPK = parseInt(equityConsiderationsElement.dataset.propertypk);
                    }
                    if ("planpropertypk" in equityConsiderationsElement.dataset) {
                        equityPlanPropertyPK = parseInt(equityConsiderationsElement.dataset.planpropertypk);
                    }
                    if ("row" in equityConsiderationsElement.dataset) {
                        equityRowNbr = parseInt(equityConsiderationsElement.dataset.row);
                    }
                    if ("buttonrow" in equityConsiderationsElement.dataset) {
                        equityButtonRow = parseInt(equityConsiderationsElement.dataset.buttonrow);
                    }
                    if ("pointplanpropertypk" in equityConsiderationsElement.dataset) {
                        equityDataPointPlanPropertyPK = parseInt(equityConsiderationsElement.dataset.pointplanpropertypk);
                    }
                    equityConsiderationsSaveItem = {
                        PlanPropertyPK: equityPlanPropertyPK,
                        PlanFK: that.planFK,
                        PropertyFK: equityPropertyPK,
                        TextValue: equityTextValue,
                        RowNbr: equityRowNbr,
                        LookupCodeFK: equityLookupCodeFK,
                        IsDeletedInd: false,
                        ButtonRow: equityButtonRow,
                        DataPointPlanPropertyPK: equityDataPointPlanPropertyPK
                    };
                }
                //Get data source elements for this service
                let dataSourceRows;
                if (serviceElement.classList.contains("otherService")) {
                    dataSourceRows = document.querySelectorAll(`.otherProgramContainer[data-buttonrow='${serviceElement.dataset.row}']`);
                }
                else {
                    dataSourceRows = document.querySelectorAll(`.programContainer[data-service-lookup-pk='${serviceElement.dataset.lookupcodepk}']`);
                }
                for (let row of dataSourceRows) {
                    let dataSourceElements = row.querySelectorAll('.dataSourceField');
                    let dataSourceSaveItem = null;
                    let measureOfQualitySaveItem = null;
                    let measureOfImpactSaveItem = null;
                    for (let programElement of dataSourceElements) {
                        let programHTMLElement;
                        let propertyPK = 0;
                        let lookupCodeFK = null;
                        let planPropertyPK = 0;
                        let dataPointPlanPropertyPK = 0;
                        let buttonRow = 0;
                        let rowNbr = 0;
                        let textValue = null;
                        if (programElement.classList.contains("serviceProgramDataSource")) {
                            programHTMLElement = programElement;
                            textValue = programHTMLElement.value;
                        }
                        else if (programElement.classList.contains("serviceProgramMeasureQuality")) {
                            programHTMLElement = programElement;
                            textValue = programHTMLElement.value;
                        }
                        else if (programElement.classList.contains("serviceProgramMeasureImpact")) {
                            programHTMLElement = programElement;
                            textValue = programHTMLElement.value;
                        }
                        if ("propertypk" in programHTMLElement.dataset) {
                            propertyPK = parseInt(programHTMLElement.dataset.propertypk);
                        }
                        if ("planpropertypk" in programHTMLElement.dataset) {
                            planPropertyPK = parseInt(programHTMLElement.dataset.planpropertypk);
                        }
                        if ("row" in programHTMLElement.dataset) {
                            rowNbr = parseInt(programHTMLElement.dataset.row);
                        }
                        if ("buttonrow" in programHTMLElement.dataset) {
                            buttonRow = parseInt(programHTMLElement.dataset.buttonrow);
                        }
                        if ("pointplanpropertypk" in programHTMLElement.dataset) {
                            dataPointPlanPropertyPK = parseInt(programHTMLElement.dataset.pointplanpropertypk);
                        }
                        let programData = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: that.planFK,
                            PropertyFK: propertyPK,
                            TextValue: textValue,
                            RowNbr: rowNbr,
                            LookupCodeFK: lookupCodeFK,
                            IsDeletedInd: false,
                            ButtonRow: buttonRow,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK
                        };
                        if (programHTMLElement.classList.contains("serviceProgram") || programData.LookupCodeFK != null || programData.PlanPropertyPK != 0 || programData.TextValue != "") {
                            if (programHTMLElement.classList.contains("serviceProgramDataSource")) {
                                dataSourceSaveItem = programData;
                            }
                            else if (programHTMLElement.classList.contains("serviceProgramMeasureQuality")) {
                                measureOfQualitySaveItem = programData;
                            }
                            else if (programHTMLElement.classList.contains("serviceProgramMeasureImpact")) {
                                measureOfImpactSaveItem = programData;
                            }
                        }
                    }
                    let dataSourceData = {
                        DataSource: dataSourceSaveItem,
                        MeasureOfImpact: measureOfImpactSaveItem,
                        MeasureOfQuality: measureOfQualitySaveItem
                    };
                    allDataSourceData.push(dataSourceData);
                }
                let serviceSaveData = {
                    DataSources: allDataSourceData,
                    Service: serviceSaveItem,
                    EquityConsiderations: equityConsiderationsSaveItem
                };
                allServiceData.push(serviceSaveData);
            }
            else if ("hadvalue" in serviceElement.dataset) {
                removeServicesData.push(serviceSaveItem);
            }
        }
        let otherData = [];
        let allOtherElements = document.querySelectorAll(".performanceDataStrengthChallenge");
        for (let element of allOtherElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: that.planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                otherData.push(saveItem);
            }
        }
        let saveData = {
            PlanFK: that.planFK,
            RemoveServices: removeServicesData,
            Services: allServiceData,
            OtherData: otherData
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveIUPerformanceData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, that.planFK);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(saveData));
    }
    reportServices() {
        let _that = this;
        let successes = [];
        let allServices = document.getElementsByClassName("performanceDataService");
        for (let service of allServices) {
            let topLevelAccordionElement = document.getElementById("performanceDataAccordionList");
            let accordionTrigger = topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${service.dataset.lookupcodepk}"]`);
            let accordionPanel = topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${service.dataset.lookupcodepk}"]`);
            if (service.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");
                    var elementsToMakeRequired = accordionPanel.querySelectorAll(`.performanceDataField[data-isrequired='true']`);
                    for (let element of elementsToMakeRequired) {
                        let htmlElement = element;
                        _that._core.forceElementRequired(htmlElement);
                    }
                }
            }
            else {
                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                    var elementsToMakeOptional = accordionPanel.querySelectorAll(`.performanceDataField[data-isrequired='true']`);
                    for (let element of elementsToMakeOptional) {
                        let htmlElement = element;
                        _that._core.forceElementOptional(htmlElement);
                    }
                }
            }
        }
        Core.createHTMLAlert("alertMessageDiv", "Service(s) and/or Program(s) successfully added", 'success', 3000, null);
        this._core.initializeRequiredFields(this.validationClasses);
    }
    addProgram(row, serviceLookupPK = null) {
        let that = this;
        //Get the current max program row number
        let rowNbr = 0;
        let programContainers = document.querySelectorAll(`.programContainer[data-buttonrow='${row}']`);
        for (let programContainer of programContainers) {
            let element = programContainer;
            if ("row" in element.dataset && parseInt(element.dataset.row) > rowNbr) {
                rowNbr = parseInt(element.dataset.row);
            }
        }
        rowNbr++;
        let xhr = new XMLHttpRequest();
        let serviceProgramContainer = document.querySelector(`.serviceProgramContainer[data-row='${row}']`);
        xhr.open('POST', '/Set/PerformanceDataNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(serviceProgramContainer).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
                var insertedProgram = serviceProgramContainer.querySelector(`.rowContainer[data-row='${rowNbr}']`);
                if (insertedProgram != null) {
                    var elementsToMakeRequired = insertedProgram.querySelectorAll(`.performanceDataField[data-isrequired='true']`);
                    for (let element of elementsToMakeRequired) {
                        let htmlElement = element;
                        that._core.forceElementRequired(htmlElement);
                    }
                }
                that.showDeleteDataSource();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error adding a new data source row. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`templateFK=${that.templateFK}&rowNumber=${rowNbr}&buttonRow=${row}&isOtherService=false&serviceLookupPK=${serviceLookupPK}`);
    }
    addOtherServiceProgram(row) {
        let that = this;
        //Get the current max program row number
        let rowNbr = 0;
        let programContainers = document.querySelectorAll(`.otherProgramContainer[data-buttonrow='${row}']`);
        for (let programContainer of programContainers) {
            let element = programContainer;
            if ("row" in element.dataset && parseInt(element.dataset.row) > rowNbr) {
                rowNbr = parseInt(element.dataset.row);
            }
        }
        rowNbr++;
        let xhr = new XMLHttpRequest();
        let serviceProgramContainer = document.querySelector(`.serviceProgramOtherContainer[data-row='${row}']`);
        xhr.open('POST', '/Set/PerformanceDataNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(serviceProgramContainer).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
                var insertedProgram = serviceProgramContainer.querySelector(`.rowContainer[data-row='${rowNbr}']`);
                if (insertedProgram != null) {
                    var elementsToMakeRequired = insertedProgram.querySelectorAll(`.performanceDataOtherField[data-isrequired='true']`);
                    for (let element of elementsToMakeRequired) {
                        let htmlElement = element;
                        that._core.forceElementRequired(htmlElement);
                    }
                }
                that.showDeleteDataSource();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error adding a new data source row. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`templateFK=${that.templateFK}&rowNumber=${rowNbr}&buttonRow=${row}&isOtherService=true&serviceLookupPK=null`);
    }
    addOtherService() {
        let that = this;
        //Get the current max program row number
        let rowNbr = 0;
        let otherServiceContainers = document.querySelectorAll(`.serviceProgramOtherContainer`);
        for (let programContainer of otherServiceContainers) {
            let element = programContainer;
            if ("row" in element.dataset && parseInt(element.dataset.row) > rowNbr) {
                rowNbr = parseInt(element.dataset.row);
            }
        }
        let newOtherServiceName = document.getElementById("nameOtherSerivce");
        rowNbr++;
        let xhr = new XMLHttpRequest();
        let container = document.getElementById("performanceDataOtherAccordionList");
        if (newOtherServiceName.value != "") {
            xhr.open('POST', '/Set/PerformanceDataNewOtherService', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(container).append(xhr.responseText);
                    Core.createHTMLAlert("alertMessageDiv", "New Program/Service Added", 'success', 3000, null);
                    new CustomAccordion(null);
                    newOtherServiceName.value = "";
                    var newAccordion = document.getElementById(`serviceContent${rowNbr}`);
                    if (newAccordion != null) {
                        var elementsToMakeRequired = newAccordion.querySelectorAll(`.performanceDataOtherField[data-isrequired='true']`);
                        for (let element of elementsToMakeRequired) {
                            let htmlElement = element;
                            that._core.forceElementRequired(htmlElement);
                        }
                    }
                    that.showDeleteDataSource();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an error adding a new program/service. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send(`templateFK=${that.templateFK}&serviceName=${newOtherServiceName.value}&rowNumber=${rowNbr}`);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify a name for the new program/service", 'error', 3000, null);
        }
    }
    customInitializeRequiredFields() {
        let that = this;
        //Only make fields from services that have been selected required
        var elementsToMakeRequired = document.querySelectorAll(`.Accordion-panel:not(.hide-accordion) .performanceDataField[data-isrequired='true'], .performanceDataOtherField[data-isrequired='true']`);
        for (let element of elementsToMakeRequired) {
            let htmlElement = element;
            that._core.forceElementRequired(htmlElement);
        }
        let serviceProgramElements = document.getElementsByClassName("serviceProgram");
        for (let el of serviceProgramElements) {
            let container = Core.findClosest(el, ".rowContainer");
            let otherElementContainer = container.querySelector(".serviceProgramOtherContainer");
            let otherElement = container.querySelector(".serviceProgramOther");
            if (el.options[el.selectedIndex].dataset.lookupcode === "performanceProgramOther") {
                otherElementContainer.classList.remove("hide");
                that._core.forceElementRequired(otherElement);
            }
            else {
                otherElementContainer.classList.add("hide");
                that._core.forceElementOptional(otherElement);
            }
        }
        that._core.initializeRequiredFields(that.validationClasses);
    }
    showDeleteDataSource() {
        var programContainers = document.querySelectorAll(".serviceProgramOtherContainer, .serviceProgramContainer");
        for (let container of programContainers) {
            let dataSources = container.querySelectorAll(".rowContainer");
            let deleteButtons = container.querySelectorAll(".deleteDataSource");
            if (dataSources.length > 1) {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = deleteButton;
                    deleteButtonElement.classList.remove("hide");
                }
            }
            else {
                for (let deleteButton of deleteButtons) {
                    let deleteButtonElement = deleteButton;
                    deleteButtonElement.classList.add("hide");
                }
            }
        }
    }
    deleteDataSource(deleteButton) {
        let that = this;
        let hasExistingData = false;
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                hasExistingData = true;
                break;
            }
        }
        if (hasExistingData) {
            that.deleteDataSourceConfirmModal.callingId = deleteButton.id;
            that.deleteDataSourceConfirmModal.show();
            that.deleteDataSourceConfirmModal.addAttributeToElement(that.deleteDataSourceConfirmModal.id, "#deleteDataSourceConfirm", "buttonid", deleteButton.id);
        }
        else {
            let container = Core.findClosest(deleteButton, ".rowContainer");
            if (container != null) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                container.parentNode.removeChild(container);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row removed", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();
            }
        }
    }
    deleteDataSourceConfirm(buttonId) {
        let that = this;
        let deleteButton = document.getElementById(buttonId);
        let dataSourceRow = Core.findClosest(deleteButton, ".rowContainer");
        that.deleteDataSourceConfirmModal.hide();
        Core.showLoader();
        let allRemoveElements = [];
        //Get all data source elements with this row and button row
        let dataSourceElements = dataSourceRow.querySelectorAll('.dataSourceField');
        for (let element of dataSourceElements) {
            let htmlElement = element;
            if ("planpropertypk" in htmlElement.dataset && parseInt(htmlElement.dataset.planpropertypk) > 0) {
                allRemoveElements.push(parseInt(htmlElement.dataset.planpropertypk));
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeletePerformanceDataDataSource', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let nextFocusable = Core.getNextFocusableElement(deleteButton);
                dataSourceRow.parentNode.removeChild(dataSourceRow);
                Core.createHTMLAlert("alertMessageDiv", "Data Source row successfully deleted", 'success', 3000, null);
                that.showDeleteDataSource();
                nextFocusable.focus();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this data source", 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allRemoveElements));
    }
    deleteService(deleteServiceButton) {
        let that = this;
        let servicePlanPropertyPK = parseInt(deleteServiceButton.dataset.servicepk);
        if (servicePlanPropertyPK > 0) {
            that.deleteOtherServiceConfirmModal.callingId = deleteServiceButton.id;
            that.deleteOtherServiceConfirmModal.show();
            that.deleteOtherServiceConfirmModal.addAttributeToElement(that.deleteOtherServiceConfirmModal.id, "#deleteServiceConfirm", "servicepk", deleteServiceButton.dataset.servicepk);
            that.deleteOtherServiceConfirmModal.addAttributeToElement(that.deleteOtherServiceConfirmModal.id, "#deleteServiceConfirm", "buttonid", deleteServiceButton.id);
        }
        else {
            let accordion = Core.findClosest(deleteServiceButton, ".Accordion");
            if (accordion != null) {
                let otherServicesContainer = document.getElementById("performanceDataOtherAccordionList");
                //Get next focusable accordion
                let accordionTriggers = otherServicesContainer.querySelectorAll(".Accordion-trigger");
                if (accordionTriggers.length > 1) {
                    let arr = Array.prototype.slice.call(accordionTriggers);
                    let thisAccordionTrigger = accordion.querySelector(".Accordion-trigger");
                    if (thisAccordionTrigger != null) {
                        let index = arr.indexOf(thisAccordionTrigger);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                }
                else {
                    //Focus on create other service
                    let create = document.getElementById("nameOtherSerivce");
                    if (create != null) {
                        create.focus();
                    }
                }
                accordion.parentNode.removeChild(accordion);
                Core.createHTMLAlert("alertMessageDiv", "Other program/service removed", 'success', 3000, null);
            }
        }
    }
    deleteServiceConfirm(servicePK, buttonId) {
        let that = this;
        that.deleteOtherServiceConfirmModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/DeletePerformanceDataService', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let deleteButton = document.getElementById(buttonId);
                if (deleteButton != null) {
                    let accordion = Core.findClosest(deleteButton, ".Accordion");
                    if (accordion != null) {
                        let otherServicesContainer = document.getElementById("performanceDataOtherAccordionList");
                        //Get next focusable accordion
                        let accordionTriggers = otherServicesContainer.querySelectorAll(".Accordion-trigger");
                        if (accordionTriggers.length > 1) {
                            let arr = Array.prototype.slice.call(accordionTriggers);
                            let thisAccordionTrigger = accordion.querySelector(".Accordion-trigger");
                            if (thisAccordionTrigger != null) {
                                let index = arr.indexOf(thisAccordionTrigger);
                                if (index > -1) {
                                    let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                                    nextFocusable.focus();
                                }
                            }
                        }
                        else {
                            //Focus on create other service
                            let create = document.getElementById("nameOtherSerivce");
                            if (create != null) {
                                create.focus();
                            }
                        }
                        accordion.parentNode.removeChild(accordion);
                        Core.createHTMLAlert("alertMessageDiv", "Other program/service successfully deleted", 'success', 3000, null);
                    }
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this program/service", 'error', 3000, null);
            }
        };
        xhr.send("servicePK=" + servicePK);
    }
    exportToExcel() {
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/ExportExcel/PerformanceDataExport?planFK=${that.planFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "Performance Data.xlsx";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Excel. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send();
    }
}
class IUStateSystemSupport {
    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = ["stateSystemSupportField"];
        let planElement = document.getElementById("iuStateSystemSupportForm");
        this.planFK = parseInt(planElement.dataset.planpk);
        this.templateFK = parseInt(planElement.dataset.templatefk);
        let saveButton = document.getElementById("iuStateSystemSupportSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let addDataButton = document.getElementsByClassName("addDataSource");
        for (let button of addDataButton) {
            button.addEventListener("click", () => that.addDataSource(button.dataset.section));
        }
        let omitCheckbox = document.getElementsByClassName("stateSystemSupportOmitCheckField");
        for (let checkbox of omitCheckbox) {
            checkbox.addEventListener("click", () => that.noPrograms(checkbox.dataset.section));
        }
        that.checkForCheckboxes();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let that = this;
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allElements = document.getElementsByClassName("stateSystemSupportField");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== "" || planPropertyPK > 0) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: that.planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkElements = document.getElementsByClassName("stateSystemSupportCheckField");
        for (let element of checkElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let isChecked = "off";
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.checked) {
                isChecked = "on";
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: that.planFK,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: isChecked,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/SaveIUStateSystemOfSupports', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, that.planFK);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "") {
                        window.location.href = refreshPage;
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addDataSource(sectionCode) {
        let that = this;
        let core = this._core;
        let table = document.querySelector(`.supportTableContainer[data-section='${sectionCode}']`);
        let inputBoxes = table.querySelectorAll("textarea");
        let rowCount = 0;
        for (let input of inputBoxes) {
            if (parseInt(input.getAttribute("data-row")) > rowCount) {
                rowCount++;
            }
        }
        rowCount++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Set/StateSystemOfSupportsNewRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Data Source Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`sectionCode=${sectionCode}&templateFK=${that.templateFK}&rowNumber=${rowCount}`);
    }
    noPrograms(sectionCode) {
        let table = document.querySelector(`.supportTableContainer[data-section='${sectionCode}']`);
        let inputBoxes = table.querySelectorAll("textarea");
        let checkbox = document.querySelector(`.stateSystemSupportOmitCheckField[data-section='${sectionCode}']`);
        for (let input of inputBoxes) {
            if (checkbox.readOnly) {
                if (checkbox.checked)
                    checkbox.checked = false;
                else
                    checkbox.checked = true;
                return false;
            }
            else {
                if (checkbox.checked) {
                    input.value = " ";
                    input.readOnly = true;
                }
                else {
                    input.readOnly = false;
                    input.value = "";
                }
            }
        }
    }
    checkForCheckboxes() {
        let allElements = document.getElementsByClassName("stateSystemSupportOmitCheckField");
        for (let element of allElements) {
            let htmlElement = element;
            let sectionCode = element.getAttribute("data-section");
            let table = document.querySelector(`.supportTableContainer[data-section='${sectionCode}']`);
            let inputBoxes = table.querySelectorAll("textarea");
            if (htmlElement.checked) {
                for (let input of inputBoxes) {
                    input.readOnly = true;
                }
            }
        }
    }
}
/// <reference path="core.ts" />
//AnalyzingStrengthsAndChallenges
class AnalyzingStrengthsAndChallenges {
    constructor() {
        this._core = new Core();
        this.validationClasses = [
            "analyzingStrengthsAndChallengesChallenges",
            "analyzingStrengthsAndChallengesPriority",
            "analyzingStrengthsAndChallengesStrengths",
            "analyzingStrengthsAndChallengesChallenges"
        ];
        this.uncheckChallengeForPriorityModal = new Modal("uncheckChallengeForPriorityModal", null);
        let that = this;
        let uncheckChallengeConfirmButton = document.getElementById("uncheckChallengeForPriorityConfirm");
        if (uncheckChallengeConfirmButton !== null) {
            uncheckChallengeConfirmButton.addEventListener("click", function () {
                if ("prioritycheckplanpropertypk" in uncheckChallengeConfirmButton.dataset && parseInt(uncheckChallengeConfirmButton.dataset.prioritycheckplanpropertypk) > 0) {
                    that.uncheckChallengeForPriority(uncheckChallengeConfirmButton.dataset.prioritycheckplanpropertypk);
                }
                else {
                    that.uncheckChallengeForPriorityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this challenge for priority", "error", 3000, null);
                }
            });
        }
        let uncheckChallengeCancelButton = document.getElementById("uncheckChallengeForPriorityCancel");
        if (uncheckChallengeCancelButton !== null) {
            uncheckChallengeCancelButton.addEventListener("click", function () {
                that.uncheckChallengeForPriorityModal.hide();
                if ("prioritycheckplanpropertypk" in uncheckChallengeCancelButton.dataset) {
                    let checkbox = document.querySelector(`[data-planpropertypk='${uncheckChallengeCancelButton.dataset.prioritycheckplanpropertypk}']`);
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }
        let checkboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityStatement");
        for (let check of checkboxes) {
            check.addEventListener("change", (e) => this.addPriorityChallengeRow(e));
        }
        let saveButton = document.getElementById("analyzingStrengthsAndChallengesSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customValidation();
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportWord = document.getElementById("exportAnalyzingStrengthsChallengesWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "AnalyzingStrengthsAndChallengesDataExport", "analyzingstrengthschallenges"));
        }
        that.limitPriorityChallengesCheck();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allChallengeElements = [];
        let allChallengeCheckElements = [];
        let allStrengthElements = [];
        let allPriorityElements = [];
        let planFK = 0;
        let planFKElement = document.getElementById("analyzingForm");
        planFK = parseInt(planFKElement.dataset.planpk);
        //Get all the textboxes for challenges
        let challengeElements = document.getElementsByClassName("analyzingStrengthsAndChallengesChallenges");
        for (let element of challengeElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            let thisData = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
            allChallengeElements.push(thisData);
        }
        //Get all the checkboxes for challenges
        let challengeCheckElements = document.getElementsByClassName("analyzingStrengthsAndChallengesChallengesCheckbox");
        for (let element of challengeCheckElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            let checkValue = "";
            if (htmlElement.checked)
                checkValue = "on";
            let thisData = {
                Row: parseInt(htmlElement.dataset.row),
                Value: checkValue,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
            allChallengeCheckElements.push(thisData);
        }
        //Get all the textboxes for Strengths
        let strengthElements = document.getElementsByClassName("analyzingStrengthsAndChallengesStrengths");
        for (let element of strengthElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            let thisData = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
            allStrengthElements.push(thisData);
        }
        //Get all the textboxes for Priority Statements
        let priorityElements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriority");
        for (let element of priorityElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            let thisData = {
                Row: parseInt(htmlElement.dataset.row),
                Value: htmlElement.value,
                PropertyPK: parseInt(htmlElement.dataset.propertypk),
                PlanProperyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
            allPriorityElements.push(thisData);
        }
        let allSaveData = {
            Challenges: allChallengeElements,
            ChallengesCheckboxes: allChallengeCheckElements,
            Strengths: allStrengthElements,
            PriorityChallenges: allPriorityElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveAnalyzeStrengthsAndChallengesData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveData.Challenges.length === 0 && allSaveData.ChallengesCheckboxes.length === 0 && allSaveData.PriorityChallenges.length === 0 && allSaveData.Strengths.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                this.customValidation();
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveData));
        }
    }
    customValidation() {
        let errorCount = 0;
        //Check to see if at least two challenges have been checked for priority
        let priorityCheckboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesChallengesCheckbox");
        let checkedCount = 0;
        for (let check of priorityCheckboxes) {
            if (check.checked) {
                checkedCount++;
            }
        }
        if (checkedCount < 2) {
            errorCount++;
            let checkboxErrorMessage = document.getElementById("priorityChallengesErrorContainer");
            if (checkboxErrorMessage !== null) {
                checkboxErrorMessage.classList.add("show");
            }
        }
        //Check to see if all priority statements have values
        let priorityStatements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriority");
        let allPriorityCompleted = true;
        for (let statement of priorityStatements) {
            if (statement.value.length == 0) {
                errorCount++;
                allPriorityCompleted = false;
                statement.classList.add("missing-field");
            }
        }
        if (!allPriorityCompleted) {
            let priorityErrorMessage = document.getElementById("priorityStatementsErrorContainer");
            if (priorityErrorMessage != null) {
                priorityErrorMessage.classList.add("show");
            }
        }
        if (errorCount > 0) {
            //Set text for error message
            let message = document.getElementById("validationMessage");
            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            }
            else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");
            if (goToError !== null) {
                let firstFocusableEl = document.querySelector(".custom-error.show");
                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                }
                else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
            this._core.doValidation(null, true);
        }
        else {
            this._core.doValidation(null, false);
        }
    }
    addPriorityChallengeRow(e) {
        let core = this._core;
        let that = this;
        var element = e.target;
        let planFK = 0;
        let planFKElement = document.getElementById("analyzingForm");
        planFK = parseInt(planFKElement.dataset.planpk);
        if (element.readOnly) {
            if (element.checked)
                element.checked = false;
            else
                element.checked = true;
            return false;
        }
        else {
            if (element.checked) {
                let dataPointPropertyPK = element.dataset.propertypk; //The checkbox Property PK becomes the datapoint
                let dataPointPlanPropertyPK = element.dataset.planpropertypk; //The checkbox Plan Property PK becomes the datapoint
                let discussionPointDataPointPropertyPK = element.dataset.datapointpropertypk;
                let discussionPointDataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
                let currentRow = element.dataset.row;
                let allElements = document.getElementsByClassName("readOnlyChallenges");
                let discussionPointElement;
                for (let discussionElement of allElements) {
                    let htmlElement = discussionElement;
                    if (htmlElement.dataset.row === currentRow && htmlElement.dataset.planpropertypk === discussionPointDataPointPlanPropertyPK) {
                        discussionPointElement = htmlElement;
                    }
                }
                if (discussionPointElement.value !== "") {
                    that.limitPriorityChallengesCheck();
                    let discussionPointPlanPropertyPK = 0;
                    if (discussionPointElement.dataset.planpropertypk !== "")
                        discussionPointPlanPropertyPK = parseInt(discussionPointElement.dataset.planpropertypk);
                    let discussionPointPropertyPK = 0;
                    if (discussionPointElement.dataset.propertypk !== "")
                        discussionPointPropertyPK = parseInt(discussionPointElement.dataset.propertypk);
                    let priorityPlanPropertyPK = 0;
                    if (element.dataset.planpropertypk !== "")
                        priorityPlanPropertyPK = parseInt(element.dataset.planpropertypk);
                    let allDiscussionPointReadOnlyElements = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityDiscussionPointReadOnly");
                    let discussionPointReadOnlyElement;
                    for (let discussionReadOnlyElement of allDiscussionPointReadOnlyElements) {
                        let htmldiscussionPointReadOnlyElement = discussionReadOnlyElement;
                        if (htmldiscussionPointReadOnlyElement.dataset.row === currentRow && htmldiscussionPointReadOnlyElement.dataset.planpropertypk === discussionPointDataPointPlanPropertyPK) {
                            discussionPointReadOnlyElement = htmldiscussionPointReadOnlyElement;
                        }
                    }
                    if (discussionPointReadOnlyElement != null) {
                        discussionPointReadOnlyElement.value = discussionPointElement.value;
                    }
                    else {
                        let newRowItem = {
                            DiscussionPointPlanPropertyPK: discussionPointPlanPropertyPK,
                            DiscussionPointPropertyPK: discussionPointPropertyPK,
                            DiscussionPointTextValue: discussionPointElement.value,
                            PriorityPlanPropertyPK: priorityPlanPropertyPK,
                            PriorityPropertyPK: parseInt(element.dataset.propertypk),
                            PriorityValue: "on",
                            RowNumber: parseInt(currentRow),
                            PlanFK: planFK
                        };
                        let table = document.getElementById("analyzingStrengthsAndChallengesTable");
                        let xhr = new XMLHttpRequest();
                        xhr.open('POST', '/Go/PriorityChallengesRow', true);
                        xhr.setRequestHeader('Content-Type', 'application/json');
                        xhr.onload = function () {
                            if (xhr.status === 200) {
                                let element = table.querySelector("tbody");
                                $(element).append(xhr.responseText);
                                Core.createHTMLAlert("alertMessageDiv", "New Priority Challenge Row Added", 'success', 3000, null);
                                //Reinitialize required fields
                                core.initializeRequiredFields(that.validationClasses);
                            }
                            else {
                                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                            }
                        };
                        xhr.send(JSON.stringify(newRowItem));
                    }
                }
                else {
                    element.checked = false;
                }
            }
            else {
                //If the checkbox was initially checked and is now unchecked, show confirmation modal to delete hierarchy (challenge > checked for priority, checked for priority > priority statement)
                if ("planpropertypk" in element.dataset && parseInt(element.dataset.planpropertypk) > 0 && "initiallychecked" in element.dataset && element.dataset.initiallychecked == "true") {
                    //recheck the checkbox just in case they close the modal (we don't want this to actually be unchecked until call to delete checkbox and hierarchy)
                    element.checked = true;
                    this.uncheckChallengeForPriorityModal.show();
                    this.uncheckChallengeForPriorityModal.addAttributeToElement(this.uncheckChallengeForPriorityModal.id, "#uncheckChallengeForPriorityConfirm", "prioritycheckplanpropertypk", element.dataset.planpropertypk);
                    this.uncheckChallengeForPriorityModal.addAttributeToElement(this.uncheckChallengeForPriorityModal.id, "#uncheckChallengeForPriorityCancel", "prioritycheckplanpropertypk", element.dataset.planpropertypk);
                    if (element.id) {
                        this.uncheckChallengeForPriorityModal.callingId = element.id;
                    }
                }
                else {
                    //remove priority statement from DOM
                    if ("row" in element.dataset) {
                        let priorityStatement = document.querySelector(`.analyzingStrengthsAndChallengesPriorityContainer[data-row='${element.dataset.row}']`);
                        if (priorityStatement != null) {
                            priorityStatement.parentNode.removeChild(priorityStatement);
                        }
                    }
                }
                that.limitPriorityChallengesCheck();
            }
        }
    }
    limitPriorityChallengesCheck() {
        const max = 4;
        var numberChecked = 0;
        let checkboxes = document.getElementsByClassName("analyzingStrengthsAndChallengesPriorityStatement");
        for (let check of checkboxes) {
            var elementCheckbox = check;
            if (elementCheckbox.checked) {
                numberChecked++;
            }
        }
        for (let check of checkboxes) {
            var elementCheckbox = check;
            if (!elementCheckbox.checked && numberChecked >= max) {
                elementCheckbox.setAttribute("disabled", "disabled");
                elementCheckbox.classList.add("disabled");
                elementCheckbox.style.cursor = "cursor";
            }
            else {
                elementCheckbox.removeAttribute("disabled");
                elementCheckbox.classList.remove("disabled");
                elementCheckbox.style.cursor = "pointer";
            }
        }
    }
    //Make call to delete hierarchy (challenge > checked for priority, checked for priority > priority statement)
    uncheckChallengeForPriority(priorityCheckPlanpropertyPK) {
        let that = this;
        that.uncheckChallengeForPriorityModal.hide();
        let checkbox = document.querySelector(`[data-planpropertypk='${priorityCheckPlanpropertyPK}']`);
        if (checkbox != null) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/UncheckChallengeForPriority', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Challenge successfully unchecked for priority", 'success', 3000, null);
                    //Uncheck checkbox and reset values
                    checkbox.checked = false;
                    checkbox.dataset.initiallychecked = "false";
                    checkbox.dataset.planpropertypk = "0";
                    that.limitPriorityChallengesCheck();
                    //remove priority statement from DOM
                    if ("row" in checkbox.dataset) {
                        let priorityStatement = document.querySelector(`.analyzingStrengthsAndChallengesPriorityContainer[data-row='${checkbox.dataset.row}']`);
                        if (priorityStatement != null) {
                            priorityStatement.parentNode.removeChild(priorityStatement);
                        }
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for priority. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(priorityCheckPlanpropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for priority. Please try again later.", "error", 3000, null);
        }
    }
}
//GoalSetting
class GoalSetting {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["goalSettingField", "goalSettingLookupField"];
        this.deleteGoalModal = new Modal("deleteGoalModal", null);
        let saveButton = document.getElementById("goalSettingSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let checkboxes = document.querySelectorAll(".goalSettingCheckbox");
        for (let check of checkboxes) {
            check.addEventListener("change", (e) => this.omitQuarterlyTargets(check));
            this.omitQuarterlyTargets(check);
        }
        let goalStatements = document.querySelectorAll(".goalSettingField[data-propertycode='goalSettingMeasurableGoalStatement']");
        for (let statement of goalStatements) {
            let targetYear3 = document.querySelector(`.goalSettingField[data-propertycode='goalSettingTargetYear3'][data-buttonrow='${statement.dataset.buttonrow}'][data-row='${statement.dataset.row}'`);
            if (targetYear3 != null) {
                statement.addEventListener("focusout", function () {
                    targetYear3.value = statement.value;
                });
            }
        }
        let that = this;
        let deleteGoalButtons = document.querySelectorAll(".deleteGoal");
        for (let button of deleteGoalButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.show();
                that.deleteGoalModal.addAttributeToElement(that.deleteGoalModal.id, "#deleteGoalConfirm", "goalplanpropertypk", button.dataset.goalplanpropertypk);
                if (button.id) {
                    that.deleteGoalModal.callingId = button.id;
                }
            });
        }
        let deleteGoalConfirmButton = document.getElementById("deleteGoalConfirm");
        if (deleteGoalConfirmButton !== null) {
            deleteGoalConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("goalplanpropertypk" in deleteGoalConfirmButton.dataset && parseInt(deleteGoalConfirmButton.dataset.goalplanpropertypk) > 0) {
                    that.deleteGoal(deleteGoalConfirmButton.dataset.goalplanpropertypk);
                }
                else {
                    that.deleteGoalModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal", "error", 3000, null);
                }
            });
        }
        let deleteGoalCancelButton = document.getElementById("deleteGoalCancel");
        if (deleteGoalCancelButton !== null) {
            deleteGoalCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.hide();
            });
        }
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportWord = document.getElementById("exportGoalSettingWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "GoalSettingDataExport", "goalsetting"));
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    omitQuarterlyTargets(element) {
        let core = this._core;
        if (element.readOnly && !("omitset" in element.dataset)) {
            if (element.checked) {
                element.checked = false;
            }
            else {
                element.checked = true;
            }
            return false;
        }
        else {
            let quarterlyTargets = document.querySelectorAll(`.goalSettingField[data-quarterlytarget='true'][data-row='${element.dataset.row}'][data-buttonrow='${element.dataset.buttonrow}']`);
            for (let target of quarterlyTargets) {
                element.dataset.omitset = "true";
                if (element.checked) {
                    //Make optional
                    core.forceElementOptional(target);
                    target.readOnly = true;
                    target.value = '';
                }
                else {
                    //Make required (if first)
                    if (("index" in target.dataset) && target.dataset.index == "0") {
                        core.forceElementRequired(target);
                    }
                    else {
                        core.forceElementOptional(target);
                    }
                    target.readOnly = false;
                }
            }
        }
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("goalSettingForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let pageFK = parseInt(formElement.dataset.pagefk);
        let goals = [];
        let goalStatements = document.querySelectorAll(".goalSettingField[data-propertycode='goalSettingMeasurableGoalStatement']");
        for (let goal of goalStatements) {
            let goalElement = goal;
            let goalStatementPropertyPK = 0;
            let goalStatement = {
                PlanPropertyPK: parseInt(goalElement.dataset.planpropertypk),
                PlanFK: planFK,
                PropertyFK: parseInt(goalElement.dataset.propertypk),
                TextValue: goalElement.value,
                LookupCodeFK: null,
                RowNbr: parseInt(goalElement.dataset.row),
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(goalElement.dataset.datapointplanpropertypk),
                ButtonRow: parseInt(goalElement.dataset.buttonrow)
            };
            //Get fields associated with goal statement
            let goalDetails = [];
            let goalDetailsElements = document.querySelectorAll(`.goalSettingField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement']), .goalSettingLookupField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement'])`);
            for (let detail of goalDetailsElements) {
                if (detail.classList.contains("goalSettingLookupField")) {
                    let htmlElement = detail;
                    let planPropertyPK = 0;
                    let dataPointPlanPropertyPK = 0;
                    let rowNumber = parseInt(htmlElement.dataset.row);
                    let buttonRow = parseInt(htmlElement.dataset.buttonrow);
                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                    if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                        dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                    if (htmlElement.value !== "" || planPropertyPK > 0) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: "",
                            LookupCodeFK: parseInt(htmlElement.value),
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                            ButtonRow: buttonRow
                        };
                        goalDetails.push(saveItem);
                    }
                }
                else {
                    let htmlElement = detail;
                    let planPropertyPK = 0;
                    let doNotInclude = false;
                    let rowNumber = parseInt(htmlElement.dataset.row);
                    let buttonRow = parseInt(htmlElement.dataset.buttonrow);
                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                    let value = htmlElement.value;
                    if (htmlElement.classList.contains("goalSettingCheckbox")) {
                        if (htmlElement.checked) {
                            value = "on";
                        }
                        else {
                            value = "off";
                            if (planPropertyPK === 0) {
                                doNotInclude = true;
                            }
                        }
                    }
                    if ((value || planPropertyPK > 0) && !doNotInclude) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: goalStatement.PlanPropertyPK,
                            ButtonRow: buttonRow
                        };
                        goalDetails.push(saveItem);
                    }
                }
            }
            let goalSettingSaveData = {
                GoalDetails: goalDetails,
                GoalStatement: goalStatement
            };
            goals.push(goalSettingSaveData);
        }
        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "MeasurableGoals": goals
        });
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveGoalSetting', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (goals.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(saveData);
        }
    }
    deleteGoal(goalPlanPropertyPK) {
        let that = this;
        that.deleteGoalModal.hide();
        let goal = document.querySelector(`[data-planpropertypk='${goalPlanPropertyPK}']`);
        if (goal != null && "row" in goal.dataset) {
            let container = document.getElementById(goal.dataset.container);
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/DeleteGoal', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Goal successfully deleted", 'success', 3000, null);
                    //reset fields
                    let goalFields = document.querySelectorAll(`.goalSettingField[data-row='${goal.dataset.row}'], .goalSettingLookupField[data-row='${goal.dataset.row}']`);
                    for (let field of goalFields) {
                        let htmlElement = field;
                        //Lookup
                        if (field instanceof HTMLSelectElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.selectedIndex = 0;
                        }
                        else if (field.classList.contains("goalSettingCheckbox")) {
                            //checkbox
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.checked = false;
                            that.omitQuarterlyTargets(htmlField);
                        }
                        else if (field instanceof HTMLInputElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }
                        else if (field instanceof HTMLTextAreaElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }
                        //remove delete button for the goal
                        let deleteButton = document.querySelector(`.deleteGoal[data-goalplanpropertypk='${goalPlanPropertyPK}']`);
                        if (deleteButton != null) {
                            deleteButton.parentNode.removeChild(deleteButton);
                        }
                    }
                    let firstField = goalFields[0];
                    firstField.focus();
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted goal", "success", 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(goalPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
        }
    }
}
//ActionPlan
class ActionPlan {
    constructor() {
        let that = this;
        this._core = new Core();
        this.deleteActionPlanModal = new Modal("deleteActionPlanModal", null);
        this.deleteActionStepModal = new Modal("deleteActionStepModal", null);
        this.deleteSystemicChallengeModal = new Modal("deleteSystemicChallengeModal", null);
        this.validationClasses = [
            "actionPlanField",
            "actionPlanSelectedMeasurableGoals",
            "actionPlanPDStepField",
            "actionPlanComStepField",
            "actionPlanDesignatedSchoolField",
            "actionPlanCheckboxField",
            "actionPlanDesignatedSchoolSelectField"
        ];
        let addEvidenceBasedStrategyButton = document.getElementById("addMoreEvidenceBasedStrategies");
        if (addEvidenceBasedStrategyButton !== null) {
            addEvidenceBasedStrategyButton.addEventListener("click", (e) => this.addEvidenceBasedStrategies("actionPlanEvidenceBasedStrategy"));
        }
        let numberTextBox = document.getElementById("addMoreEvidenceBasedStrategiesNumber");
        if (numberTextBox !== null) {
            numberTextBox.addEventListener("change", (e) => this.checkForPlural());
        }
        let saveButton = document.getElementById("actionPlanSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let measurableGoalsCheckboxes = document.getElementsByClassName("actionPlanSelectedMeasurableGoals");
        for (let measurableGoalsCheckbox of measurableGoalsCheckboxes)
            measurableGoalsCheckbox.addEventListener("click", (e) => this.checkForDisabled(e));
        this.bindCreateActionPlans();
        this.bindAddAnActionStep();
        this.bindMeasurableGoals();
        let deleteActionPlanButtons = document.querySelectorAll(".deleteActionPlan");
        for (let button of deleteActionPlanButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionPlanModal.show();
                that.deleteActionPlanModal.addAttributeToElement(that.deleteActionPlanModal.id, "#deleteActionPlanConfirm", "actionplanplanpropertypk", button.dataset.actionplanplanpropertypk);
                if (button.id) {
                    that.deleteActionPlanModal.callingId = button.id;
                }
            });
        }
        let deleteActionPlanConfirmButton = document.getElementById("deleteActionPlanConfirm");
        if (deleteActionPlanConfirmButton !== null) {
            deleteActionPlanConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("actionplanplanpropertypk" in deleteActionPlanConfirmButton.dataset && parseInt(deleteActionPlanConfirmButton.dataset.actionplanplanpropertypk) > 0) {
                    that.deleteActionPlan(deleteActionPlanConfirmButton.dataset.actionplanplanpropertypk);
                }
                else {
                    that.deleteActionPlanModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action plan", "error", 3000, null);
                }
            });
        }
        let deleteActionPlanCancelButton = document.getElementById("deleteActionPlanCancel");
        if (deleteActionPlanCancelButton !== null) {
            deleteActionPlanCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionPlanModal.hide();
            });
        }
        let deleteActionStepButtons = document.querySelectorAll(".deleteActionStep");
        for (let button of deleteActionStepButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionStepModal.show();
                that.deleteActionStepModal.addAttributeToElement(that.deleteActionStepModal.id, "#deleteActionStepConfirm", "actionstepplanpropertypk", button.dataset.actionstepplanpropertypk);
                if (button.id) {
                    that.deleteActionStepModal.callingId = button.id;
                }
            });
        }
        let deleteActionStepConfirmButton = document.getElementById("deleteActionStepConfirm");
        if (deleteActionStepConfirmButton !== null) {
            deleteActionStepConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("actionstepplanpropertypk" in deleteActionStepConfirmButton.dataset && parseInt(deleteActionStepConfirmButton.dataset.actionstepplanpropertypk) > 0) {
                    that.deleteActionStep(deleteActionStepConfirmButton.dataset.actionstepplanpropertypk);
                }
                else {
                    that.deleteActionStepModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action step", "error", 3000, null);
                }
            });
        }
        let deleteActionStepCancelButton = document.getElementById("deleteActionStepCancel");
        if (deleteActionStepCancelButton !== null) {
            deleteActionStepCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActionStepModal.hide();
            });
        }
        this.checkForCSISchools();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidation(this.validationClasses);
        }
        this.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportWord = document.getElementById("exportActionPlanWord");
        if (exportWord !== null) {
            exportWord.addEventListener("click", (e) => this._core.exportDocx(e, "ActionPlanDataExport", "actionplan"));
        }
        //use event delegation for up/down buttons that are possibly added dynamically
        document.addEventListener("click", function (event) {
            let target = event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("up")) {
                that.moveActionStep(event, true);
            }
        });
        document.addEventListener("click", function (event) {
            let target = event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("down")) {
                that.moveActionStep(event, false);
            }
        });
        this.recalculateUpDown();
        const addDesignatedSchoolSupportButtons = document.getElementsByClassName("addDesignatedSchoolSupport");
        for (const addDesignatedSchoolSupportButton of addDesignatedSchoolSupportButtons)
            addDesignatedSchoolSupportButton.addEventListener("click", (e) => this.addDesignatedSchoolSupport(e));
        const actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools = document.getElementById("actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools");
        if (actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools !== null)
            actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools.addEventListener("change", (e) => this.checkForCSISchools());
        this.bindDeleteSystemicLEAChallenge();
        const deleteSystemicLEAChallengeConfirm = document.getElementById("deleteSystemicLEAChallengeConfirm");
        if (deleteSystemicLEAChallengeConfirm !== null)
            deleteSystemicLEAChallengeConfirm.addEventListener("click", (e) => this.deleteLEASystemicChallengeConfirm(e.target));
        const deleteSystemicLEAChallengeCancel = document.getElementById("deleteSystemicLEAChallengeCancel");
        if (deleteSystemicLEAChallengeCancel !== null)
            deleteSystemicLEAChallengeCancel.addEventListener("click", (e) => this.deleteLEASystemicChallengeCancel());
    }
    initializeRequiredFields(allClasses) {
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
    doValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk and datapointplanpropertypk)
                var otherElements = document.querySelectorAll(`[data-datapointplanpropertypk='${htmlElement.dataset.datapointplanpropertypk}'][data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox") {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                }
                                else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    checkForDisabled(e) {
        let checkbox = e.target;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let allActionPlans = [];
        let allActionSteps = [];
        let allPdSteps = [];
        let allComSteps = [];
        let allGoals = [];
        let allOthers = [];
        let actionStepOthers = [];
        let allElements = document.getElementsByClassName("actionPlanField");
        for (let element of allElements) {
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let buttonRow = 0;
            let htmlElement;
            let saveItem;
            if (element.classList.contains("actionPlanSelectedMeasurableGoals")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.checked) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("actionPlanPDStepField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("actionPlanComStepField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let toDelete = (htmlElement.value === "");
                let value = htmlElement.value;
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            if (htmlElement.classList.contains("evidenceBasedStrategyName") && saveItem !== undefined)
                allActionPlans.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanActionStepField") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanPDStepField") && saveItem !== undefined)
                allPdSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanComStepField") && saveItem !== undefined)
                allComSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanSelectedMeasurableGoals") && saveItem !== undefined)
                allGoals.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                actionStepOthers.push(saveItem);
            else if (saveItem !== undefined)
                allOthers.push(saveItem);
        }
        let allActionPlanCheckboxElements = document.getElementsByClassName("actionPlanCheckboxField");
        for (let element of allActionPlanCheckboxElements) {
            const ele = element;
            let textValue = "off";
            if (ele.checked)
                textValue = "on";
            const planPropertyPK = ele.dataset.planpropertypk;
            const rowNumber = 0;
            const dataPointPlanPropertyPK = 0;
            const buttonRow = -1;
            const saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: parseInt(ele.dataset.propertypk),
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                ButtonRow: buttonRow
            };
            allOthers.push(saveItem);
        }
        const allDesignatedSchoolsSaveElements = [];
        const allDesignatedSchoolElements = document.getElementsByClassName("actionPlanDesignatedSchoolField");
        for (const element of allDesignatedSchoolElements) {
            const ele = element;
            const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;
            const propertyPK = ele.dataset.propertypk;
            const row = ele.dataset.row;
            const planPropertyPK = ele.dataset.planpropertypk;
            const textValue = ele.value;
            if (textValue !== '' || parseInt(planPropertyPK) > 0) {
                const saveItem = {
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                    IsDeletedInd: false,
                    LookupCodeFK: null,
                    PlanFK: planFK,
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PropertyFK: parseInt(propertyPK),
                    RowNbr: parseInt(row),
                    TextValue: textValue
                };
                allDesignatedSchoolsSaveElements.push(saveItem);
            }
        }
        const allDesignatedSchoolSelectElements = document.getElementsByClassName("actionPlanDesignatedSchoolSelectField");
        for (const element of allDesignatedSchoolSelectElements) {
            const ele = element;
            const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;
            const propertyPK = ele.dataset.propertypk;
            const row = ele.dataset.row;
            const planPropertyPK = ele.dataset.planpropertypk;
            const lookupCodeFK = parseInt(ele.value);
            if (lookupCodeFK > 0 || parseInt(planPropertyPK) > 0) {
                const saveItem = {
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                    IsDeletedInd: false,
                    LookupCodeFK: lookupCodeFK,
                    PlanFK: planFK,
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PropertyFK: parseInt(propertyPK),
                    RowNbr: parseInt(row),
                    TextValue: ""
                };
                allDesignatedSchoolsSaveElements.push(saveItem);
            }
        }
        let saveElement = {
            "PlanFK": planFK,
            "ActionPlans": allActionPlans,
            "ActionSteps": allActionSteps,
            "PDSteps": allPdSteps,
            "ComSteps": allComSteps,
            "GoalSteps": allGoals,
            "ActionStepOther": actionStepOthers,
            "Others": allOthers,
            "DesignatedSchoolData": allDesignatedSchoolsSaveElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveActionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    //Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLongLoader();
                    //Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader(); //Core.hideLoader();
                }
                else {
                    Core.hideLongLoader(); //Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionPlans.length === 0 && saveElement.ActionStepOther.length === 0 && saveElement.ActionSteps.length === 0 && saveElement.ComSteps.length === 0 && saveElement.GoalSteps.length === 0 && saveElement.Others.length === 0 && saveElement.PDSteps.length === 0 && saveElement.DesignatedSchoolData.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader(); //Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }
    addActionStep(buttonRow) {
        let that = this;
        let core = this._core;
        let newRow = 0;
        let formElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let actionStepFields = document.getElementsByClassName("actionPlanActionStepField");
        for (let action of actionStepFields) {
            let actionElement = action;
            if (buttonRow === parseInt(actionElement.dataset.buttonrow)) {
                if (newRow < parseInt(actionElement.dataset.row)) {
                    newRow = parseInt(actionElement.dataset.row);
                }
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddActionStep', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".actionStepContainer[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Action Step Added", 'success', 3000, null);
                that.recalculateUpDown();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&newRow=" + newRow + "&buttonRow=" + buttonRow);
    }
    addEvidenceBasedStrategies(classNameBase) {
        let core = this._core;
        let addEvidenceBasedStrategyNumber = document.getElementById("addMoreEvidenceBasedStrategiesNumber");
        let planFKElement = document.getElementById("actionPlanForm");
        let planFK = parseInt(planFKElement.dataset.planfk);
        let _that = this;
        if (addEvidenceBasedStrategyNumber.value === "") {
        }
        else {
            let value = parseInt(addEvidenceBasedStrategyNumber.value);
            let table = document.getElementById("evidenceBasedStrategy");
            let findRows = document.getElementsByClassName("addAnActionStep");
            let rowCount = 0;
            for (let row of findRows) {
                let element = row;
                if (rowCount < parseInt(element.dataset.buttonrow)) {
                    rowCount = parseInt(element.dataset.buttonrow);
                }
            }
            for (let i = 1; i <= value; i++) {
                rowCount++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/EvidenceBasedStrategy');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#evidenceBasedStrategy > tbody").append("<tr><td>" + xhr.responseText + "</td></tr>");
                        $('.multiSelectMeasurableGoals').MultiSelect();
                        _that.bindCreateActionPlans();
                        _that.bindAddAnActionStep();
                        new CustomAccordion("actionPlanActionPlanAccordion" + rowCount.toString());
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New Evidence-Based Strategy Row Added", 'success', 3000, null);
                        _that.recalculateUpDown();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount + "&planFK=" + planFK);
            }
        }
    }
    bindAddAnActionStep() {
        let addActionSteps = document.getElementsByClassName("addAnActionStep");
        for (let action of addActionSteps) {
            let addActionStep = action;
            if (addActionStep.dataset.listener === "true") {
            }
            else {
                let buttonRow = parseInt(addActionStep.dataset.buttonrow);
                addActionStep.addEventListener("click", (e) => this.addActionStep(buttonRow));
                addActionStep.dataset.listener = "true";
            }
        }
    }
    bindCreateActionPlans() {
        let actionPlanButtons = document.getElementsByClassName("createActionPlan");
        for (let button of actionPlanButtons) {
            let but = button;
            if (but.dataset.listener === "true") {
            }
            else {
                but.addEventListener("click", (e) => this.addActionPlan(e.target));
                but.dataset.listener = "true";
            }
        }
    }
    addActionPlan(button) {
        let core = this._core;
        //Make sure there is a title in the Evidence Based-Strategy name
        let _that = this;
        let dontDoIt = false;
        let row = button.dataset.row;
        let textElement = document.getElementsByClassName("evidenceBasedStrategyName");
        let hasName = true;
        for (let tElement of textElement) {
            let properElement = tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                    hasName = false;
                }
            }
        }
        let selectedSequence = this.itemsSelected(row);
        if (selectedSequence.length === 0) {
            dontDoIt = true;
        }
        if (!dontDoIt) {
            let rowNumber = button.dataset.row;
            let textBoxId = "actionPlanEvidenceBasedStrategyName" + rowNumber.toString();
            let evidenceNameElement = document.getElementById(textBoxId);
            let newAccordionTitle = "Action Plan for: " + evidenceNameElement.value;
            let accordionParent = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var thisElement;
            for (let accordion of accordionParent) {
                let acc = accordion;
                if (acc.dataset.row == rowNumber) {
                    thisElement = acc;
                }
            }
            let theSpan = thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            evidenceNameElement.readOnly = true;
            //Now show the accordion.
            let actionPlanElements = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var actionPlanElement;
            for (let ap of actionPlanElements) {
                let ape = ap;
                if (ape.dataset.row === rowNumber) {
                    actionPlanElement = ape;
                }
            }
            //Get the measurable goals list to display inside the accordion
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/MeasurableGoalsList', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let element = document.getElementById("actionPlanActionPlanSection" + rowNumber);
                    let divElement = element;
                    let measurableGoals = divElement.querySelector(".actionPlanMeasurableGoalsReaderList");
                    let measurableGoalsElement = measurableGoals;
                    $(measurableGoalsElement).append(xhr.responseText);
                    let allMeasurableGoals = divElement.querySelectorAll(".measurableGoalModal");
                    for (let goal of allMeasurableGoals) {
                        let goalElement = goal;
                        let modalValue = goalElement.dataset.measurablegoal;
                        goal.addEventListener("click", (e) => _that.showGoalModal(modalValue));
                    }
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Plan Row Added", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(selectedSequence));
            actionPlanElement.classList.remove("hide-action-plan");
            actionPlanElement.classList.add("open");
            button.classList.add("hide-action-plan");
            _that.recalculateUpDown();
        }
        else {
            if (!hasName) {
                Core.createHTMLAlert("alertMessageDiv", "A name for the evidence-based strategy must be provided", 'error', 3000, null);
            }
            else if (selectedSequence.length === 0) {
                Core.createHTMLAlert("alertMessageDiv", "At least one measurable goal must be selected", 'error', 3000, null);
            }
        }
    }
    bindMeasurableGoals() {
        let allMeasurableGoals = document.querySelectorAll(".measurableGoalModal");
        for (let goal of allMeasurableGoals) {
            let goalElement = goal;
            let modalValue = goalElement.dataset.measurablegoal;
            goal.addEventListener("click", (e) => this.showGoalModal(modalValue));
        }
    }
    showGoalModal(modalText) {
        let element = document.getElementById("actionPlanMeasurableGoalsDescription");
        element.innerHTML = modalText;
        let modal = new Modal('ActionPlanMeasurableGoalDescriptionModal', null);
        modal.show();
    }
    itemsSelected(row) {
        let selectElements = document.getElementsByClassName("multiSelectMeasurableGoals");
        let elementSequence = [];
        for (let selectElement of selectElements) {
            let element = selectElement;
            if (element.dataset.row === row) {
                let selection = element.querySelectorAll("ul li.selected");
                if (selection.length > 0) {
                    for (let s of selection) {
                        let sElement = s;
                        let nextElement = sElement.querySelector("input");
                        let inputElement = nextElement;
                        elementSequence.push(inputElement.dataset.planpropertypk);
                    }
                }
            }
        }
        return elementSequence;
    }
    checkForPlural() {
        let word = document.getElementById("strategyWord");
        let num = document.getElementById("addMoreEvidenceBasedStrategiesNumber");
        let newWord = "strategies";
        if (parseInt(num.value) === 1) {
            newWord = "strategy";
        }
        word.textContent = newWord;
    }
    deleteActionPlan(actionPlanPlanPropertyPK) {
        let that = this;
        that.deleteActionPlanModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteActionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Action Plan successfully deleted", 'success', 3000, null);
                //Get next focusable evidence based strategy name
                let strategyNames = document.querySelectorAll(".evidenceBasedStrategyName");
                if (strategyNames.length > 1) {
                    let arr = Array.prototype.slice.call(strategyNames);
                    let thisStrategyName = document.querySelector(`.evidenceBasedStrategyName[data-planpropertypk='${actionPlanPlanPropertyPK}']`);
                    if (thisStrategyName != null) {
                        let index = arr.indexOf(thisStrategyName);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                }
                else {
                    //Focus on add button if no strategies left
                    let addButton = document.getElementById("addMoreEvidenceBasedStrategies");
                    if (addButton != null) {
                        addButton.focus();
                    }
                }
                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractionplanplanpropertypk='${actionPlanPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action plan. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(actionPlanPlanPropertyPK);
    }
    deleteActionStep(actionStepPlanPropertyPK) {
        let that = this;
        that.deleteActionStepModal.hide();
        let actionStepDeleteButton = document.querySelector(`.deleteActionStep[data-actionstepplanpropertypk='${actionStepPlanPropertyPK}']`);
        if (actionStepDeleteButton != null && "container" in actionStepDeleteButton.dataset) {
            let container = document.getElementById(actionStepDeleteButton.dataset.container);
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/DeleteActionStep', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Action Step successfully deleted", 'success', 3000, null);
                    //remove from DOM
                    if (container != null) {
                        let nextFocusable = Core.getNextFocusableElement(actionStepDeleteButton);
                        container.parentNode.removeChild(container);
                        that.recalculateUpDown();
                        that._core.initializeRequiredFields(that.validationClasses, true);
                        nextFocusable.focus();
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action step. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(actionStepPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this action step. Please try again later.", "error", 3000, null);
        }
    }
    recalculateUpDown() {
        let actionStepContainers = document.getElementsByClassName("actionStepContainer");
        for (let container of actionStepContainers) {
            let actionSteps = container.querySelectorAll(".actionStepsRow");
            if (actionSteps != null) {
                let i = 1;
                for (let actionStep of actionSteps) {
                    let upArrow = actionStep.querySelector("button.up");
                    let downArrow = actionStep.querySelector("button.down");
                    if (upArrow != null && downArrow != null && upArrow.dataset.canedit == "true" && downArrow.dataset.canedit == "true") {
                        //Reset the state up the up and down arrows
                        upArrow.classList.remove("hide");
                        upArrow.disabled = false;
                        downArrow.classList.remove("hide");
                        downArrow.disabled = false;
                        if (actionSteps.length == 1) {
                            //Only one action step so hide the up/down arrows
                            upArrow.classList.add("hide");
                            downArrow.classList.add("hide");
                        }
                        else {
                            if (i === 1) {
                                upArrow.disabled = true;
                            }
                            if (i === actionSteps.length) {
                                downArrow.disabled = true;
                            }
                        }
                    }
                    i++;
                }
            }
        }
    }
    moveActionStep(e, up) {
        let that = this;
        e.preventDefault();
        let button = e.target;
        if (button != null) {
            let actionStepContainer = Core.findClosest(button, ".action-steps-row");
            if (actionStepContainer != null) {
                let sibling;
                if (up) {
                    sibling = actionStepContainer.previousElementSibling;
                    actionStepContainer.parentNode.insertBefore(actionStepContainer, sibling);
                }
                else {
                    sibling = actionStepContainer.nextElementSibling;
                    actionStepContainer.parentNode.insertBefore(sibling, actionStepContainer);
                }
                let siblingElement = sibling;
                let thisRow = actionStepContainer.dataset.overriderow;
                let siblingRow = siblingElement.dataset.overriderow;
                //swap override row number for the two
                actionStepContainer.dataset.overriderow = siblingRow;
                let thisActionStepFields = actionStepContainer.querySelectorAll(`[data-overriderow='${thisRow}']`);
                for (let field of thisActionStepFields) {
                    let htmlField = field;
                    htmlField.dataset.overriderow = siblingRow;
                }
                siblingElement.dataset.overriderow = thisRow;
                let siblingActionStepFields = siblingElement.querySelectorAll(`[data-overriderow='${siblingRow}']`);
                for (let field of siblingActionStepFields) {
                    let htmlField = field;
                    htmlField.dataset.overriderow = thisRow;
                }
                this.recalculateUpDown();
                if (button.disabled) {
                    if (up) {
                        let downButton = actionStepContainer.querySelector("button.up-down.down");
                        if (downButton != null && !downButton.disabled) {
                            downButton.focus();
                        }
                    }
                    else {
                        let upButton = actionStepContainer.querySelector("button.up-down.up");
                        if (upButton != null && !upButton.disabled) {
                            upButton.focus();
                        }
                    }
                }
                else {
                    button.focus();
                }
                Core.createHTMLAlert("alertMessageDiv", "Action Step Has Been Reordered", "success", 3000, null);
            }
        }
        this._core.initializeRequiredFields(this.validationClasses, true);
    }
    addDesignatedSchoolSupport(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const button = e.target;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const formElement = document.getElementById("actionPlanForm");
            const planFK = formElement.dataset.planfk;
            const allRows = document.querySelectorAll(`.actionPlanDesignatedSchoolSupportRecord[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            let maxRow = 0;
            for (const theRow of allRows) {
                const ele = theRow;
                const row = parseInt(ele.dataset.row);
                if (!isNaN(row)) {
                    if (row > maxRow)
                        maxRow = row;
                }
            }
            maxRow++;
            const response = yield fetch(`/Go/AddActionPlanDesignatedSchoolsSupport/${planFK}/${dataPointPlanPropertyPK}/${maxRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const newDiv = document.createElement("div");
                newDiv.classList.add("actionPlanDesignatedSchoolSupportRecord");
                newDiv.classList.add("technical-support-row");
                newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                newDiv.dataset.row = maxRow.toString();
                newDiv.innerHTML = output;
                const container = document.querySelector(`.designatedSchoolsSystemicLEAChallenge[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                container.append(newDiv);
                this.bindDeleteSystemicLEAChallenge();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", `New support successfully added.`, 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${response.status}'`, 'error', 3000, null);
            }
        });
    }
    checkForCSISchools() {
        const actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools = document.getElementById("actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools");
        if (actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools !== null) {
            const fundingSources = document.getElementById("fundingSources");
            if (actionPlanDesignatedSchoolsLEADoesNotHaveCSISchools.checked) {
                this._core.forceElementOptional(fundingSources);
                fundingSources.readOnly = true;
                fundingSources.disabled = true;
            }
            else {
                this._core.forceElementRequired(fundingSources);
                fundingSources.readOnly = false;
                fundingSources.disabled = false;
            }
        }
    }
    bindDeleteSystemicLEAChallenge() {
        const deleteTechnicalSupportButtons = document.getElementsByClassName("deleteTechnicalSupport");
        for (const deleteTechnicalSupportButton of deleteTechnicalSupportButtons)
            deleteTechnicalSupportButton.addEventListener("click", (e) => this.showDeleteLEASystemicChallenge(e));
    }
    showDeleteLEASystemicChallenge(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const row = button.dataset.row;
        this.deleteSystemicChallengeModal.addAttributeToElement("deleteSystemicChallengeModal", "#deleteSystemicLEAChallengeConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        this.deleteSystemicChallengeModal.addAttributeToElement("deleteSystemicChallengeModal", "#deleteSystemicLEAChallengeConfirm", "row", row);
        this.deleteSystemicChallengeModal.show();
    }
    deleteLEASystemicChallengeConfirm(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const row = button.dataset.row;
            const allPlanPropElements = document.querySelectorAll(`.actionPlanDesignatedSchoolField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
            const allPlanProps = [];
            for (const planProp of allPlanPropElements) {
                const ele = planProp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "0")
                    allPlanProps.push(ele.dataset.planpropertypk);
            }
            const allSelectPlanPropElements = document.querySelectorAll(`.actionPlanDesignatedSchoolSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
            for (const planProp of allSelectPlanPropElements) {
                const ele = planProp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "0")
                    allPlanProps.push(ele.dataset.planpropertypk);
            }
            const actionPlanForm = document.getElementById("actionPlanForm");
            const planFK = actionPlanForm.dataset.planfk;
            if (allPlanProps.length > 0) {
                const deleteData = {
                    "PlanProperties": allPlanProps,
                    "PlanFK": planFK,
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "Row": row
                };
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(deleteData)
                };
                const response = yield fetch("/Go/DeleteActionPlanSystemicChallengeRow", settings);
                if (response.ok) {
                    const output = yield response.json();
                    if (output.success === true) {
                        const container = document.querySelector(`.actionPlanDesignatedSchoolSupportRecord[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        container.remove();
                    }
                    this.deleteSystemicChallengeModal.hide();
                }
            }
            else {
                const allInputs = document.querySelectorAll(`.actionPlanDesignatedSchoolField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                const allSelects = document.querySelectorAll(`.actionPlanDesignatedSchoolSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}'] select`);
                for (const input of allInputs) {
                    const ele = input;
                    ele.value = "";
                }
                for (const select of allSelects) {
                    const ele = select;
                    ele.selectedIndex = 0;
                }
                this.deleteSystemicChallengeModal.hide();
            }
        });
    }
    deleteLEASystemicChallengeCancel() {
        this.deleteSystemicChallengeModal.hide();
    }
}
// SchoolPlanExpenditureTables
class SchoolPlanExpenditureTables {
    constructor() {
        this._core = new Core();
        this.sumAllAmounts();
        this.sumAllSchoolwideAmounts();
        //this.allNoFunding();
        this.validationClasses = ["schoolPlanExpenditureTablesField", "schoolPlanExpenditureTablesCheckboxField", "schoolPlanExpenditureTablesSelectField", "schoolPlanExpenditureTablesMultiSelectField"];
        let schoolPlanExpenditureTablesSaveButton = document.getElementById("schoolPlanExpenditureTablesSave");
        if (schoolPlanExpenditureTablesSaveButton !== null)
            schoolPlanExpenditureTablesSaveButton.addEventListener("click", (e) => this.save("save"));
        let isReviewElement = document.getElementById("isReview");
        let isLockdowElement = document.getElementById("isLockdown");
        if (isReviewElement !== null && isReviewElement.value === "false" && isLockdowElement !== null && isLockdowElement.value === "false") {
            this.allNoFunding();
        }
        this.initializeRequiredFieldsCustom();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const schoolPlanExpenditureTablesSchoolImprovementAddRow = document.getElementById("schoolPlanExpenditureTablesSchoolImprovementAddRow");
        if (schoolPlanExpenditureTablesSchoolImprovementAddRow !== null)
            schoolPlanExpenditureTablesSchoolImprovementAddRow.addEventListener("click", (e) => this.addSchoolImprovementRow());
        const schoolPlanExpenditureTablesSchoolwideAddRow = document.getElementById("schoolPlanExpenditureTablesSchoolwideTitle1AddRow");
        if (schoolPlanExpenditureTablesSchoolwideAddRow !== null)
            schoolPlanExpenditureTablesSchoolwideAddRow.addEventListener("click", (e) => this.addSchoolwideRow());
        this.bindSumAllAmounts();
        this.bindDeleteButtons();
        const deleteExpenditureRowConfirm = document.getElementById("deleteExpenditureRowConfirm");
        if (deleteExpenditureRowConfirm !== null)
            deleteExpenditureRowConfirm.addEventListener("click", (e) => this.deleteExpenditureConfirm(e));
        const deleteExpenditureRowCancel = document.getElementById("deleteExpenditureRowCancel");
        if (deleteExpenditureRowCancel !== null)
            deleteExpenditureRowCancel.addEventListener("click", (e) => this.deleteExpenditureCancel());
        const noFundingCheckboxes = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolDoesNot");
        for (const noFundingCheckbox of noFundingCheckboxes)
            noFundingCheckbox.addEventListener("change", (e) => this.noFunding(e.target));
        const schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel = document.getElementById("schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel");
        if (schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel !== null)
            schoolPlanExpenditureTablesSchoolwideTitle1ExportToExcel.addEventListener("click", (e) => this.exportTitle1ToExcel());
        const schoolPlanExpenditureTablesSchoolImprovementExportToExcel = document.getElementById("schoolPlanExpenditureTablesSchoolImprovementExportToExcel");
        if (schoolPlanExpenditureTablesSchoolImprovementExportToExcel !== null)
            schoolPlanExpenditureTablesSchoolImprovementExportToExcel.addEventListener("click", (e) => this.exportSchoolImprovementToExcel());
        const closeComingSoon = document.getElementById("closeComingSoon");
        if (closeComingSoon !== null)
            closeComingSoon.addEventListener("click", (e) => this.closeComingSoon());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const allMultiSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("schoolPlanExpenditureTablesForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("schoolPlanExpenditureTablesField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const selects = document.getElementsByClassName("schoolPlanExpenditureTablesSelectField");
        for (const ele of selects) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("schoolPlanExpenditureTablesCheckboxField");
        for (const ele of checkboxes) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const multiCheckboxes = document.getElementsByClassName("schoolPlanExpenditureTablesMultiCheckboxField");
        for (const ele of multiCheckboxes) {
            let planPropertyPK = 0;
            const element = ele;
            const dropDownPropertyPK = element.parentElement.parentElement.parentElement.parentElement.dataset.propertypk;
            const buttonRowNumber = element.parentElement.parentElement.parentElement.parentElement.dataset.row;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            const multiDropdownDataPointPlanPropertyPK = element.dataset.multidropdowndatapointplanpropertypk;
            const actionPlanDataPointPlanPropertyPK = element.dataset.actionplandatapointplanpropertypk;
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    ButtonRowNbr: parseInt(buttonRowNumber),
                    IsDeletedInd: false,
                    ActionPlanDataPointPlanPropertyPK: actionPlanDataPointPlanPropertyPK,
                    MultiDropdownDataPointPlanPropertyPK: multiDropdownDataPointPlanPropertyPK,
                    DropdownPropertyPK: dropDownPropertyPK
                };
                allMultiSaveElements.push(saveItem);
            }
        }
        const saveData = {
            "SaveElements": allSaveElements,
            "MultiSaveElements": allMultiSaveElements,
            "PlanFK": planPK
        };
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveSchoolPlanExpenditureTables', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allMultiSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    addSchoolImprovementRow() {
        return __awaiter(this, void 0, void 0, function* () {
            const formElement = document.getElementById("schoolPlanExpenditureTablesForm");
            const planFK = formElement.dataset.planfk;
            let newRow = 0;
            let allRows = document.querySelectorAll(".schoolImprovementExpenditureTable .expenditureTableRow");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const response = yield fetch(`/Go/AddSchoolImprovementExpenditureTableRow/${planFK}/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const tableHead = document.querySelector(`.schoolImprovementExpenditureTable tbody`);
                const newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.classList.add("expenditureTableRow");
                newTR.innerHTML = output;
                tableHead.appendChild(newTR);
                $('.multiSelectActionPlans').MultiSelect();
                Core.createHTMLAlert("alertMessageDiv", `A new Expenditure Row has been added.`, 'success', 3000, null);
                this.bindDeleteButtons();
                this.bindSumAllAmounts();
            }
        });
    }
    addSchoolwideRow() {
        return __awaiter(this, void 0, void 0, function* () {
            const formElement = document.getElementById("schoolPlanExpenditureTablesForm");
            const planFK = formElement.dataset.planfk;
            let newRow = 0;
            let allRows = document.querySelectorAll(".schoolwideTitle1ExpenditureTable .expenditureTableSchoolwideRow");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const response = yield fetch(`/Go/AddSchoolwideExpenditureTableRow/${planFK}/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const tableHead = document.querySelector(`.schoolwideTitle1ExpenditureTable tbody`);
                const newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.classList.add("expenditureTableSchoolwideRow");
                newTR.innerHTML = output;
                tableHead.appendChild(newTR);
                $('.multiSelectActionPlans').MultiSelect();
                Core.createHTMLAlert("alertMessageDiv", `A new Expenditure Row has been added.`, 'success', 3000, null);
                this.bindDeleteButtons();
                this.bindSumAllAmounts();
            }
        });
    }
    sumAllAmounts() {
        const schoolPlanExpenditureTablesAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesAmount");
        let totalValue = 0;
        for (const schoolPlanExpenditureTablesAmountField of schoolPlanExpenditureTablesAmountFields) {
            const element = schoolPlanExpenditureTablesAmountField;
            const thisValue = parseFloat(element.value);
            if (!isNaN(thisValue))
                totalValue += thisValue;
        }
        const totalElement = document.getElementById("schoolPlanExpenditureTablesTotalExpenditures");
        totalElement.value = totalValue.toString();
    }
    sumAllSchoolwideAmounts() {
        const schoolPlanExpenditureTablesAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolwideAmount");
        let totalValue = 0;
        for (const schoolPlanExpenditureTablesAmountField of schoolPlanExpenditureTablesAmountFields) {
            const element = schoolPlanExpenditureTablesAmountField;
            const thisValue = parseFloat(element.value);
            if (!isNaN(thisValue))
                totalValue += thisValue;
        }
        const totalElement = document.getElementById("schoolPlanExpenditureTablesSchoolwideTotalExpenditures");
        totalElement.value = totalValue.toString();
    }
    bindSumAllAmounts() {
        let schoolPlanExpenditureTablesAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesAmount");
        for (let schoolPlanExpenditureTablesAmountField of schoolPlanExpenditureTablesAmountFields)
            schoolPlanExpenditureTablesAmountField.addEventListener("input", (e) => this.sumAllAmounts());
        let schoolPlanExpenditureTablesSchoolwideAmountFields = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolwideAmount");
        for (let schoolPlanExpenditureTablesSchoolwideAmountField of schoolPlanExpenditureTablesSchoolwideAmountFields)
            schoolPlanExpenditureTablesSchoolwideAmountField.addEventListener("input", (e) => this.sumAllSchoolwideAmounts());
    }
    bindDeleteButtons() {
        let deleteSchoolImprovementExpenditureRows = document.getElementsByClassName("deleteSchoolImprovementExpenditureRow");
        for (let deleteSchoolImprovementExpenditureRow of deleteSchoolImprovementExpenditureRows)
            deleteSchoolImprovementExpenditureRow.addEventListener("click", (e) => this.showDeleteExpenditureRow(e));
    }
    showDeleteExpenditureRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let table = buttonElement.dataset.table;
        let modal = new Modal("deleteExpenditureRowModal", null);
        modal.addAttributeToElement("deleteExpenditureRowModal", "#deleteExpenditureRowConfirm", "row", row);
        modal.addAttributeToElement("deleteExpenditureRowModal", "#deleteExpenditureRowConfirm", "table", table);
        modal.show();
    }
    deleteExpenditureConfirm(e) {
        let that = this;
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let table = buttonElement.dataset.table;
        const rowData = document.querySelectorAll(`.${table}[data-row='${row}'] .expenditureTableField`);
        const rowMultiData = document.querySelectorAll(`.${table}[data-row='${row}'] .schoolPlanExpenditureTablesMultiSelectField`);
        const allPlanProperties = [];
        const allPlanPropertiesMulti = [];
        for (const data of rowData) {
            var ele = data;
            if (ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                allPlanProperties.push(parseInt(ele.dataset.planpropertypk));
        }
        for (const multiData of rowMultiData) {
            var divele = multiData;
            if (divele.dataset.planpropertypk !== "" && divele.dataset.planpropertypk !== "0")
                allPlanPropertiesMulti.push(parseInt(divele.dataset.planpropertypk));
        }
        const allData = {
            "RowData": allPlanProperties,
            "MultiRowData": allPlanPropertiesMulti
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteExpenditureRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`tr.${table}[data-row='${row}']`);
                    element.remove();
                    that.sumAllSchoolwideAmounts();
                    that.sumAllAmounts();
                    Core.createHTMLAlert("alertMessageDiv", "Expenditure row successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this row. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteExpenditureRowModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteExpenditureRowModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allData));
    }
    deleteExpenditureCancel() {
        let modal = new Modal("deleteExpenditureRowModal", null);
        modal.hide();
    }
    allNoFunding() {
        const noFundingCheckboxes = document.getElementsByClassName("schoolPlanExpenditureTablesSchoolDoesNot");
        for (const noFundingCheckbox of noFundingCheckboxes)
            this.noFunding(noFundingCheckbox);
    }
    noFunding(checkbox) {
        const table = checkbox.dataset.table;
        const allRows = document.querySelectorAll(`tr.${table}`);
        const allAddButtons = document.querySelectorAll(`.addSchoolImprovementRow[data-table='${table}']`);
        for (const row of allRows) {
            const allFields = row.querySelectorAll(".expenditureTableField");
            const allMultiFields = row.querySelectorAll("input[type='checkbox']");
            for (const field of allFields) {
                if (checkbox.checked) {
                    field.readOnly = true;
                    field.disabled = true;
                }
                else {
                    field.readOnly = false;
                    field.disabled = false;
                }
            }
            for (const multiField of allMultiFields) {
                if (checkbox.checked) {
                    multiField.readOnly = true;
                    multiField.disabled = true;
                }
                else {
                    multiField.readOnly = false;
                    multiField.disabled = false;
                }
            }
        }
        for (const button of allAddButtons) {
            if (checkbox.checked) {
                button.disabled = true;
            }
            else {
                button.disabled = false;
            }
        }
    }
    exportTitle1ToExcel() {
        Core.showLoader();
        let planElement = document.getElementById("schoolPlanExpenditureTablesForm");
        let planFK = planElement.dataset.planfk;
        window.open(`/ExportExcel/SchoolPlanExpenditureTablesSchoolwideTitle1Export/${planFK}`);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    exportSchoolImprovementToExcel() {
        Core.showLoader();
        let planElement = document.getElementById("schoolPlanExpenditureTablesForm");
        let planFK = planElement.dataset.planfk;
        window.open(`/ExportExcel/SchoolPlanExpenditureTablesSchoolImprovementExcelExport/${planFK}`);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    closeComingSoon() {
        const modal = new Modal("comingSoonModal", null);
        modal.hide();
    }
    initializeRequiredFieldsCustom() {
        let that = this;
        //If any field in a row is filled out, force rest of row required
        /*let expenditureTables = document.getElementsByClassName("expenditureTable");*/
        let schoolImprovementExpenditureTable = document.getElementById("schoolImprovementExpenditureTable");
        let schoolImprovementExpenditureTableCheckbox = document.getElementById("schoolPlanExpenditureTablesSchooldoesnotreceiveSchoolImprovementSetAsideGrant");
        let schoolwideTitle1ExpenditureTable = document.getElementById("schoolwideTitle1ExpenditureTable");
        let schoolwideTitle1ExpenditureTableCheckbox = document.getElementById("schoolPlanExpenditureTablesSchooldoesnotreceiveSchoolwideTitle1funding");
        if (!schoolImprovementExpenditureTableCheckbox.checked) {
            let expenditureTableRows = schoolImprovementExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of expenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated");
                for (let element of elements) {
                    if (element instanceof HTMLSelectElement) {
                        let htmlElement = element;
                        if (element.selectedIndex > 0) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                    else if (element instanceof HTMLInputElement) {
                        let htmlElement = element;
                        if (element.value != "" || element.checked) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                    else if (element.hasAttribute("data-multiselectvalidate")) {
                        //See if any options have been checked in multiselect
                        let multiselectCheckboxes = element.getElementsByTagName("input");
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                for (let otherElement of elements) {
                                    otherElement.dataset.forcerequired = "true";
                                }
                                break;
                            }
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = element;
                        if (element.value != "") {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                }
            }
        }
        else {
            let expenditureTableRows = schoolImprovementExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of expenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated");
                for (let element of elements) {
                    this._core.forceElementOptional(element);
                }
            }
            let schoolPlanExpenditureTablesTotalExpenditures = document.getElementById("schoolPlanExpenditureTablesTotalExpenditures");
            this._core.forceElementOptional(schoolPlanExpenditureTablesTotalExpenditures);
        }
        if (!schoolwideTitle1ExpenditureTableCheckbox.checked) {
            let Title1ExpenditureTableRows = schoolwideTitle1ExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of Title1ExpenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated");
                for (let element of elements) {
                    if (element instanceof HTMLSelectElement) {
                        let htmlElement = element;
                        if (element.selectedIndex > 0) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                    else if (element instanceof HTMLInputElement) {
                        let htmlElement = element;
                        if (element.value != "" || element.checked) {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                    else if (element.hasAttribute("data-multiselectvalidate")) {
                        //See if any options have been checked in multiselect
                        let multiselectCheckboxes = element.getElementsByTagName("input");
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                for (let otherElement of elements) {
                                    otherElement.dataset.forcerequired = "true";
                                }
                                break;
                            }
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        let htmlElement = element;
                        if (element.value != "") {
                            for (let otherElement of elements) {
                                otherElement.dataset.forcerequired = "true";
                            }
                            break;
                        }
                    }
                }
            }
        }
        else {
            let Title1ExpenditureTableRows = schoolwideTitle1ExpenditureTable.querySelectorAll("tr[data-row]");
            for (let row of Title1ExpenditureTableRows) {
                let elements = row.getElementsByClassName("fieldToBeValidated");
                for (let element of elements) {
                    this._core.forceElementOptional(element);
                }
            }
            let schoolPlanExpenditureTablesSchoolwideTotalExpenditures = document.getElementById("schoolPlanExpenditureTablesSchoolwideTotalExpenditures");
            this._core.forceElementOptional(schoolPlanExpenditureTablesSchoolwideTotalExpenditures);
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
}
//ProfessionalDevelopment
class ProfessionalDevelopment {
    constructor() {
        this._core = new Core();
        this.deleteActivityModal = new Modal("deleteProfessionalDevelopmentActivityModal", null);
        this.validationClasses = [
            "professionalDevelopmentField",
            "addDanielsonFrameworkToListItem",
            "professionalDevelopmentLearningFormatStateRequired",
            "professionalDevelopmentFrequencyField",
            "professionalDevelopmentAddActionStep",
            "professionalDevelopmentSystemicChallenge"
        ];
        this.checkForDesignatedSchools();
        let saveButton = document.getElementById("professionalDevelopmentSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        let professionalDevelopmentTableExportExcel = document.getElementById("professionalDevelopmentTableExportExcel");
        if (professionalDevelopmentTableExportExcel !== null)
            professionalDevelopmentTableExportExcel.addEventListener("click", (e) => this.tableExportExcel());
        let professionalDevelopmentExportExcel = document.getElementById("professionalDevelopmentExportExcel");
        if (professionalDevelopmentExportExcel !== null)
            professionalDevelopmentExportExcel.addEventListener("click", (e) => this.exportExcel());
        this._core.leftnav(this);
        let addMoreRowsButton = document.getElementById("addProfessionalDevelopmentRows");
        if (addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e) => this.addMoreProfessionalDevelopmentRows());
        let that = this;
        let deleteActivityButtons = document.querySelectorAll(".deleteProfessionalDevelopmentActivity");
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteProfessionalDevelopmentActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);
                if (button.id) {
                    that.deleteActivityModal.callingId = button.id;
                }
            });
        }
        let deleteActivityConfirmButton = document.getElementById("deleteProfessionalDevelopmentActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteProfessionalDevelopmentActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                }
                else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this professional development activity", "error", 3000, null);
                }
            });
        }
        let deleteActivityCancelButton = document.getElementById("deleteProfessionalDevelopmentActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }
        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();
        this.bindAddLEASystemicChallenge();
        this.bindTechnicalAssistanceStepDeleteButtons();
        this.bindNotPartOfThis();
        this.notPartOfThis();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportStepsWord = document.getElementById("exportActionStepsWord");
        if (exportStepsWord !== null) {
            exportStepsWord.addEventListener("click", (e) => this._core.exportDocx(e, "ProfessionalDevelopmentActionStepsDataExport", "actionsteps"));
        }
        let exportActivitiesWord = document.getElementById("exportActivitiesWord");
        if (exportActivitiesWord !== null) {
            exportActivitiesWord.addEventListener("click", (e) => this._core.exportDocx(e, "ProfessionalDevelopmentDataExport", "professionaldevelopment"));
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];
        const technicalAssistanceSteps = [];
        let allElements = document.getElementsByClassName("professionalDevelopmentField");
        for (let element of allElements) {
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let buttonRow = 0;
            let htmlElement;
            let saveItem;
            if (element.classList.contains("actionStepList")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let lookupCode = 0;
                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                }
                else {
                    lookupCode = null;
                }
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let lookupCode = 0;
                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                }
                else {
                    lookupCode = null;
                }
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let toDelete = (htmlElement.value === "");
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else if (element.classList.contains("professionalDevelopmentTASACheckboxField")) {
                let value = "off";
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.checked)
                    value = "on";
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let toDelete = (htmlElement.value === "");
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== undefined)
                allPDActivities.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== undefined)
                allLearningFormats.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                learningFormatOthers.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== undefined)
                learningFormatStateRequired.push(saveItem);
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== undefined)
                learningFormatFrameworks.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== undefined)
                learningFormatFrequency.push(saveItem);
            else if (htmlElement.classList.contains("technicalAssistanceStep") && saveItem !== undefined)
                technicalAssistanceSteps.push(saveItem);
            else if (saveItem !== undefined)
                allOthers.push(saveItem);
        }
        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers,
            "TechnicalAssistanceSteps": technicalAssistanceSteps
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveProfessionalDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.LearningFormatDanielsonFrameworks.length === 0 && saveElement.LearningFormatFrequency.length === 0 && saveElement.LearningFormatOther.length === 0 && saveElement.LearningFormats.length === 0 && saveElement.LearningFormatStateRequired.length === 0 && saveElement.Others.length === 0 && saveElement.PDActivities.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }
    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAdd");
        for (let actionStepButton of actionStepButtons) {
            let element = actionStepButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addActionStepToActivity(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAdd");
        for (let danielsonButton of danielsonButtons) {
            let element = danielsonButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addDanielsonFrameworkToList(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivity");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.createNewPDActivity(e.target));
            element.addEventListener("click", (e) => this.createNewPDActivity(e.target));
            element.dataset.listener = "true";
            //}
        }
    }
    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStep");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.addEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
            //}
        }
    }
    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeDanielsonFrameworkList(e));
            element.addEventListener("click", (e) => this.removeDanielsonFrameworkList(e));
            element.dataset.listener = "true";
            //}
        }
    }
    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormat");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addLearningFormat(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeLearningFormat(e));
            element.addEventListener("click", (e) => this.removeLearningFormat(e));
            element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteActivityButtons();
    }
    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }
    addActionStepToActivity(button) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddActionStep");
        let actionStepPlanPropertyPK;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }
        let isNotInList = true;
        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }
        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added to this activity", 'error', 3000, null);
        }
    }
    removeItemFromActionStepList(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let planPropertyPK = element.dataset.planpropertypk;
        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = actionStep;
            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Action step removed from activity", 'success', 3000, null);
            }
        }
    }
    addDanielsonFrameworkToList(button) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddDanielsonFramework");
        let lookupCodePK;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === buttonrow && selectElement.dataset.row === rowNumber) {
                lookupCodePK = parseInt(selectElement.value);
            }
        }
        let isNotInList = true;
        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let listElement of allListElements) {
            let element = listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }
        //See if user has selected a danielson framework or if it's still set to default value
        let userHasSelected = true;
        if (isNaN(lookupCodePK)) {
            userHasSelected = false;
            Core.createHTMLAlert("alertMessageDiv", "A Danielson Framework must first be selected", 'error', 3000, null);
            return;
        }
        if (isNotInList && userHasSelected) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddDanielsonFrameworkToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToList[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "This Danielson Framework item has already been added", 'error', 3000, null);
        }
    }
    removeDanielsonFrameworkList(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let buttonrow = element.dataset.buttonrow;
        let lookupCodePK = element.dataset.lookupcodepk;
        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let lookup of allLookups) {
            let thisElement = lookup;
            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Danielson Framework Removed", 'success', 3000, null);
            }
        }
    }
    addLearningFormat(button) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivity");
        let _that = this;
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddLearningFormat');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormats[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                _that._core.initializeRequiredFields(_that.validationClasses);
                Core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }
    removeLearningFormat(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let buttonrow = element.dataset.buttonrow;
        let nextFocusable = Core.getNextFocusableElement(element);
        let count = 0;
        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormat");
        for (let learningFormat of allLearningFormats) {
            let thisElement = learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                nextFocusable.focus();
            }
        }
        this.showHideDeleteActivityButtons();
        Core.createHTMLAlert("alertMessageDiv", "Learning Format Removed", 'success', 3000, null);
    }
    createNewPDActivity(button) {
        let _that = this;
        let core = this._core;
        let dontDoIt = false;
        let hasName = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let tElement of textElement) {
            let properElement = tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
                else {
                    hasName = true;
                }
            }
        }
        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "professionalDevelopmentActivity" + rowNumber.toString();
            let professionalDevelopmentNameElement = document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordion");
            var thisElement;
            for (let accordion of accordionParent) {
                let acc = accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }
            let theSpan = thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;
            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordion");
            var professionalDevelopmentElement;
            for (let ap of professionalDevelopmentElements) {
                let pde = ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }
            this.bindNotPartOfThis();
            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
            _that._core.initializeRequiredFields(_that.validationClasses);
            Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Added", 'success', 3000, null);
        }
        else if (!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }
    addMoreProfessionalDevelopmentRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = document.getElementById("addProfessionalDevelopmentRowsNumber");
        let howMany = parseInt(howManyElement.value);
        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;
        let activityElements = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let activity of activityElements) {
            let activityElement = activity;
            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }
        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContent").append(xhr.responseText);
                        new CustomAccordion("professionalDevelopmentAccordion" + currentRow.toString());
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        _that.bindAddLEASystemicChallenge();
                        _that.bindTechnicalAssistanceStepDeleteButtons();
                        _that._core.initializeRequiredFields(_that.validationClasses);
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }
    tableExportExcel() {
        let core = this._core;
        Core.showLoader();
        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentTableDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    exportExcel() {
        let core = this._core;
        Core.showLoader();
        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    deleteProfessionalDevelopmentActivity(activityPlanPropertyPK) {
        let that = this;
        that.deleteActivityModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteProfessionalDevelopmentActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Professional development activity successfully deleted", 'success', 3000, null);
                //Get next focusable activity name
                let activityNames = document.querySelectorAll(".professionalDevelopmentActivity");
                if (activityNames.length > 1) {
                    let arr = Array.prototype.slice.call(activityNames);
                    let thisActivityName = document.querySelector(`.professionalDevelopmentActivity[data-planpropertypk='${activityPlanPropertyPK}']`);
                    if (thisActivityName != null) {
                        let index = arr.indexOf(thisActivityName);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                }
                else {
                    //Focus on add button if no strategies left
                    let addButton = document.getElementById("addProfessionalDevelopmentRows");
                    if (addButton != null) {
                        addButton.focus();
                    }
                }
                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }
    bindAddLEASystemicChallenge() {
        const allAdds = document.getElementsByClassName("professionalDevelopmentSystemicChallengeAdd");
        for (const add of allAdds)
            add.addEventListener("click", (e) => this.addLEASystemicChallenge(e));
    }
    addLEASystemicChallenge(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const buttonRow = button.dataset.buttonrow;
            const formElement = document.getElementById("professionalDevelopmentForm");
            const planFK = formElement.dataset.formfk;
            const dropdown = document.querySelector(`.professionalDevelopmentSystemicChallenge[data-buttonrow='${buttonRow}']`);
            if (dropdown.value !== "-1") {
                const selectedPlanPropertyPK = parseInt(dropdown.value);
                let isNotInList = true;
                let allListElements = document.getElementsByClassName("technicalAssistanceStep");
                for (let listElement of allListElements) {
                    let element = listElement;
                    if (parseInt(element.dataset.planpropertypk) === selectedPlanPropertyPK && element.dataset.buttonrow === buttonRow) {
                        isNotInList = false;
                    }
                }
                if (isNotInList) {
                    const response = yield fetch(`/Go/AddLEASystemicChallenge/${planFK}/${selectedPlanPropertyPK}/${buttonRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const newDiv = document.createElement("div");
                        newDiv.innerHTML = yield response.text();
                        const container = document.querySelector(`.addTechnicalAssistanceStepToList[data-row='${buttonRow}']`);
                        container.append(newDiv);
                        this.bindTechnicalAssistanceStepDeleteButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Technical Assistance/Support Activity added.", 'success', 3000, null);
                    }
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", "You can only add each Technical Assistance/Support Activity one time.", "warning", 3000, null);
            }
            else
                Core.createHTMLAlert("alertMessageDiv", "You must choose a Technical Assistance/Support Activity to add to the list.", "warning", 3000, null);
        });
    }
    bindTechnicalAssistanceStepDeleteButtons() {
        const allDeletes = document.getElementsByClassName("deleteTechnicalAssistanceStep");
        for (const del of allDeletes)
            del.addEventListener("click", (e) => this.deleteTechnicalAssistanceStepItem(e));
    }
    deleteTechnicalAssistanceStepItem(e) {
        const buttonElement = e.target;
        const planPropertyPK = buttonElement.dataset.planpropertypk;
        const buttonRow = buttonElement.dataset.buttonrow;
        const elementToDelete = document.querySelector(`.professionalDevelopmentField[data-planpropertypk='${planPropertyPK}'][data-buttonrow='${buttonRow}']`);
        elementToDelete.remove();
    }
    checkForDesignatedSchools() {
        const selects = document.getElementsByClassName("professionalDevelopmentSystemicChallenge");
        for (const sel of selects) {
            const select = sel;
            if (select.options.length > 1) {
                this._core.forceElementRequired(select);
            }
        }
    }
    bindNotPartOfThis() {
        const professionalDevelopmentSystemicChallengeNotPartOfThis = document.getElementsByClassName("professionalDevelopmentSystemicChallengeNotPartOfThis");
        for (const element of professionalDevelopmentSystemicChallengeNotPartOfThis)
            element.addEventListener("change", (e) => this.notPartOfThis());
    }
    notPartOfThis() {
        const professionalDevelopmentSystemicChallengeNotPartOfThis = document.getElementsByClassName("professionalDevelopmentSystemicChallengeNotPartOfThis");
        for (const element of professionalDevelopmentSystemicChallengeNotPartOfThis) {
            const ele = element;
            const row = ele.dataset.row;
            const technicalAssistanceElement = document.querySelector(`.professionalDevelopmentSystemicChallenge[data-row='${row}']`);
            const professionalDevelopmentSystemicChallengeAdd = document.querySelector(`.professionalDevelopmentSystemicChallengeAdd[data-buttonrow='${row}']`);
            if (technicalAssistanceElement) {
                if (ele.checked) {
                    this._core.forceElementOptional(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = true;
                    professionalDevelopmentSystemicChallengeAdd.disabled = true;
                }
                else {
                    this._core.forceElementRequired(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = false;
                    professionalDevelopmentSystemicChallengeAdd.disabled = false;
                }
            }
        }
    }
}
//Communications
class Communications {
    constructor() {
        this._core = new Core();
        this.deleteActivityModal = new Modal("deleteCommunicationsActivityModal", null);
        this.validationClasses = [
            "communicationsField",
            "communicationsFrequencyField",
            "actionStepList",
            "communicationsField",
            "communicationSystemicChallenge"
        ];
        this.checkForDesignatedSchools();
        let saveButton = document.getElementById("communicationsSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        let communicationsTableExportExcel = document.getElementById("communicationsTableExportExcel");
        if (communicationsTableExportExcel !== null)
            communicationsTableExportExcel.addEventListener("click", (e) => this.tableExportExcel());
        let communicationsExportExcel = document.getElementById("communicationsExportExcel");
        if (communicationsExportExcel !== null)
            communicationsExportExcel.addEventListener("click", (e) => this.exportExcel());
        this._core.leftnav(this);
        let addMoreRowsButton = document.getElementById("addCommunicationsRows");
        if (addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e) => this.addMoreCommunicationsRows());
        let numberTextBox = document.getElementById("addCommunicationsRowsNumber");
        if (numberTextBox !== null)
            numberTextBox.addEventListener("change", (e) => this.checkForPlural());
        let that = this;
        let deleteActivityButtons = document.querySelectorAll(".deleteCommunicationsActivity");
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteCommunicationsActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);
                if (button.id) {
                    that.deleteActivityModal.callingId = button.id;
                }
            });
        }
        let deleteActivityConfirmButton = document.getElementById("deleteCommunicationsActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteCommunicationsActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                }
                else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this communications activity", "error", 3000, null);
                }
            });
        }
        let deleteActivityCancelButton = document.getElementById("deleteCommunicationsActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }
        this.bindCreateCommunicationActivityButtons();
        this.bindCommunicationsActionStepAddButtons();
        this.bindCommunicationsActionStepRemoveButtons();
        this.bindAddCommunicationButtons();
        this.bindRemoveCommunicationButtons();
        this.bindAddLEASystemicChallenge();
        this.bindTechnicalAssistanceStepDeleteButtons();
        this.bindNotPartOfThis();
        this.notPartOfThis();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportStepsWord = document.getElementById("exportActionStepsWord");
        if (exportStepsWord !== null) {
            exportStepsWord.addEventListener("click", (e) => this._core.exportDocx(e, "CommunicationsActionStepsDataExport", "actionsteps"));
        }
        let exportActivitiesWord = document.getElementById("exportActivitiesWord");
        if (exportActivitiesWord !== null) {
            exportActivitiesWord.addEventListener("click", (e) => this._core.exportDocx(e, "CommunicationsDataExport", "communications"));
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("communicationsForm");
        const planFK = parseInt(formElement.dataset.planfk);
        const allCommActivities = [];
        const allCommunications = [];
        const allActionSteps = [];
        const allOthers = [];
        const communicationOthers = [];
        const communicationFrequency = [];
        const technicalAssistanceSteps = [];
        const allElements = document.getElementsByClassName("communicationsField");
        for (let element of allElements) {
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let buttonRow = 0;
            let htmlElement;
            let saveItem;
            if (element.classList.contains("actionStepList")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else if (element.classList.contains("communicationsCommunicationActivity")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("communicationsFrequencyField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("communicationsTASACheckboxField")) {
                let value = "off";
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.checked)
                    value = "on";
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            if (htmlElement.classList.contains("communicationsActivity") && saveItem !== null && saveItem !== undefined)
                allCommActivities.push(saveItem);
            else if (htmlElement.classList.contains("communicationsCommunicationActivity") && saveItem !== null && saveItem !== undefined)
                allCommunications.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== null && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== null && saveItem !== undefined)
                communicationOthers.push(saveItem);
            else if (htmlElement.classList.contains("communicationsFrequencyField") && saveItem !== null && saveItem !== undefined)
                communicationFrequency.push(saveItem);
            else if (htmlElement.classList.contains("technicalAssistanceStep") && saveItem !== undefined)
                technicalAssistanceSteps.push(saveItem);
            else if (saveItem !== null && saveItem !== undefined)
                allOthers.push(saveItem);
        }
        let saveElement = {
            "CommActivities": allCommActivities,
            "Communications": allCommunications,
            "ActionSteps": allActionSteps,
            "CommunicationsOther": communicationOthers,
            "CommunicationFrequency": communicationFrequency,
            "Others": allOthers,
            "TechnicalAssistanceSteps": technicalAssistanceSteps
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/SaveCommunications', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.CommActivities.length === 0 && saveElement.CommunicationFrequency.length === 0 && saveElement.Communications.length === 0 && saveElement.CommunicationsOther.length === 0 && saveElement.Others.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }
    bindCommunicationsActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("communicationsActionStepAdd");
        for (let actionStepButton of actionStepButtons) {
            let element = actionStepButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addActionStepToActivity(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    bindCreateCommunicationActivityButtons() {
        let activityButtons = document.getElementsByClassName("createCommunicationsActivity");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.createNewCommActivity(e.target));
            element.addEventListener("click", (e) => this.createNewCommActivity(e.target));
            element.dataset.listener = "true";
            //}
        }
    }
    bindCommunicationsActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deleteCommAddedActionStep");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.addEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
            //}
        }
    }
    bindAddCommunicationButtons() {
        let activityButtons = document.getElementsByClassName("communicationsAddCommunication");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addCommunication(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    bindRemoveCommunicationButtons() {
        let activityButtons = document.getElementsByClassName("communicationsDeleteCommunicationRow");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeCommunication(e));
            element.addEventListener("click", (e) => this.removeCommunication(e));
            element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteCommunicationsButtons();
    }
    showHideDeleteCommunicationsButtons() {
        let activityButtons = document.getElementsByClassName("communicationsDeleteCommunicationRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }
    addActionStepToActivity(button) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("communicationsForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("communicationsAddActionStep");
        let actionStepPlanPropertyPK;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }
        let isNotInList = true;
        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }
        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddCommActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindCommunicationsActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added", 'error', 3000, null);
        }
    }
    removeItemFromActionStepList(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let planPropertyPK = element.dataset.planpropertypk;
        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = actionStep;
            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Action step removed from communications activity", 'success', 3000, null);
            }
        }
    }
    addCommunication(button) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow = 0;
        let communicationList = document.getElementsByClassName("communicationsCommunicationActivity");
        let _that = this;
        let formElement = document.getElementById("communicationsForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of communicationList) {
            let element = activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/AddCommunication');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".communications[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindRemoveCommunicationButtons();
                Core.createHTMLAlert("alertMessageDiv", "New Communications Activity Row Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }
    removeCommunication(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let buttonrow = element.dataset.buttonrow;
        let allCommunications = document.getElementsByClassName("communicationsCommunication");
        for (let communication of allCommunications) {
            let thisElement = communication;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteCommunicationsButtons();
        Core.createHTMLAlert("alertMessageDiv", "Communication Removed", 'success', 3000, null);
    }
    createNewCommActivity(button) {
        let _that = this;
        let core = this._core;
        let dontDoIt = false;
        let hasName = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("communicationsActivity");
        for (let tElement of textElement) {
            let properElement = tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
                else {
                    hasName = true;
                }
            }
        }
        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "communicationsActivity" + rowNumber.toString();
            let communicationsNameElement = document.getElementById(textBoxId);
            let newAccordionTitle = "" + communicationsNameElement.value;
            let accordionParent = document.getElementsByClassName("communicationsAccordion");
            var thisElement;
            for (let accordion of accordionParent) {
                let acc = accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }
            let theSpan = thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            communicationsNameElement.readOnly = true;
            //Now show the accordion.
            let communicationsElements = document.getElementsByClassName("communicationsAccordion");
            let communicationsElement;
            for (let ap of communicationsElements) {
                let comm = ap;
                if (comm.dataset.buttonrow === rowNumber) {
                    communicationsElement = comm;
                }
            }
            communicationsElement.classList.remove("hide-communications");
            communicationsElement.classList.add("open");
            button.classList.add("hide-communications");
            Core.createHTMLAlert("alertMessageDiv", "New Communications Activity Added", 'success', 3000, null);
        }
        else if (!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }
    addMoreCommunicationsRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = document.getElementById("addCommunicationsRowsNumber");
        let howMany = parseInt(howManyElement.value);
        let planElement = document.getElementById("communicationsForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;
        let activityElements = document.getElementsByClassName("communicationsActivity");
        for (let activity of activityElements) {
            let activityElement = activity;
            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }
        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Go/AddNewCommunicationsActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#communicationsContent").append(xhr.responseText);
                        new CustomAccordion("communicationsAccordion" + currentRow.toString());
                        _that.bindAddCommunicationButtons();
                        _that.bindCommunicationsActionStepAddButtons();
                        _that.bindCreateCommunicationActivityButtons();
                        _that.bindRemoveCommunicationButtons();
                        _that.bindAddLEASystemicChallenge();
                        _that.bindTechnicalAssistanceStepDeleteButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Communication Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }
    checkForPlural() {
        let word = document.getElementById("pluralWord");
        let num = document.getElementById("addCommunicationsRowsNumber");
        let newWord = "activities";
        if (parseInt(num.value) === 1) {
            newWord = "activity";
        }
        word.textContent = newWord;
    }
    tableExportExcel() {
        let core = this._core;
        Core.showLoader();
        let planElement = document.getElementById("communicationsForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/CommunicationsTableDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    exportExcel() {
        let core = this._core;
        Core.showLoader();
        let planElement = document.getElementById("communicationsForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/CommunicationsDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    deleteCommunicationsActivity(activityPlanPropertyPK) {
        let that = this;
        that.deleteActivityModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteCommunicationsActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Communications activity successfully deleted", 'success', 3000, null);
                //Get next focusable activity name
                let activityNames = document.querySelectorAll(".communicationsActivity");
                if (activityNames.length > 1) {
                    let arr = Array.prototype.slice.call(activityNames);
                    let thisActivityName = document.querySelector(`.communicationsActivity[data-planpropertypk='${activityPlanPropertyPK}']`);
                    if (thisActivityName != null) {
                        let index = arr.indexOf(thisActivityName);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                }
                else {
                    //Focus on add button if no strategies left
                    let addButton = document.getElementById("addCommunicationsRows");
                    if (addButton != null) {
                        addButton.focus();
                    }
                }
                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }
    bindAddLEASystemicChallenge() {
        const allAdds = document.getElementsByClassName("communicationSystemicChallengeAdd");
        for (const add of allAdds)
            add.addEventListener("click", (e) => this.addCommunicationLEASystemicChallenge(e));
    }
    addCommunicationLEASystemicChallenge(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const buttonRow = button.dataset.buttonrow;
            const formElement = document.getElementById("communicationsForm");
            const planFK = formElement.dataset.formfk;
            const dropdown = document.querySelector(`.communicationSystemicChallenge[data-buttonrow='${buttonRow}']`);
            if (dropdown.value !== "-1") {
                const selectedPlanPropertyPK = parseInt(dropdown.value);
                let isNotInList = true;
                let allListElements = document.getElementsByClassName("technicalAssistanceStep");
                for (let listElement of allListElements) {
                    let element = listElement;
                    if (parseInt(element.dataset.planpropertypk) === selectedPlanPropertyPK && element.dataset.buttonrow === buttonRow) {
                        isNotInList = false;
                    }
                }
                if (isNotInList) {
                    const response = yield fetch(`/Go/AddCommunicationLEASystemicChallenge/${planFK}/${selectedPlanPropertyPK}/${buttonRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const newDiv = document.createElement("div");
                        newDiv.innerHTML = yield response.text();
                        const container = document.querySelector(`.addTechnicalAssistanceStepToList[data-row='${buttonRow}']`);
                        container.append(newDiv);
                        this.bindTechnicalAssistanceStepDeleteButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Technical Assistance/Support Activity added.", 'success', 3000, null);
                    }
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", "You can only add each Technical Assistance/Support Activity one time.", "warning", 3000, null);
            }
            else
                Core.createHTMLAlert("alertMessageDiv", "You must choose a Technical Assistance/Support Activity to add to the list.", "warning", 3000, null);
        });
    }
    bindTechnicalAssistanceStepDeleteButtons() {
        const allDeletes = document.getElementsByClassName("deleteTechnicalAssistanceStep");
        for (const del of allDeletes)
            del.addEventListener("click", (e) => this.deleteTechnicalAssistanceStepItem(e));
    }
    deleteTechnicalAssistanceStepItem(e) {
        const buttonElement = e.target;
        const planPropertyPK = buttonElement.dataset.planpropertypk;
        const buttonRow = buttonElement.dataset.buttonrow;
        const elementToDelete = document.querySelector(`.communicationsField[data-planpropertypk='${planPropertyPK}'][data-buttonrow='${buttonRow}']`);
        elementToDelete.remove();
    }
    checkForDesignatedSchools() {
        const selects = document.getElementsByClassName("communicationSystemicChallenge");
        for (const sel of selects) {
            const select = sel;
            if (select.options.length > 1) {
                this._core.forceElementRequired(select);
            }
        }
    }
    bindNotPartOfThis() {
        const communicationsSystemicChallengeNotPartOfThis = document.getElementsByClassName("communicationsSystemicChallengeNotPartOfThis");
        for (const element of communicationsSystemicChallengeNotPartOfThis)
            element.addEventListener("change", (e) => this.notPartOfThis());
    }
    notPartOfThis() {
        const communicationsSystemicChallengeNotPartOfThis = document.getElementsByClassName("communicationsSystemicChallengeNotPartOfThis");
        for (const element of communicationsSystemicChallengeNotPartOfThis) {
            const ele = element;
            const row = ele.dataset.row;
            const technicalAssistanceElement = document.querySelector(`.communicationSystemicChallenge[data-row='${row}']`);
            const communicationsSystemicChallengeAdd = document.querySelector(`.communicationSystemicChallengeAdd[data-buttonrow='${row}']`);
            if (technicalAssistanceElement) {
                if (ele.checked) {
                    this._core.forceElementOptional(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = true;
                    communicationsSystemicChallengeAdd.disabled = true;
                }
                else {
                    this._core.forceElementRequired(technicalAssistanceElement);
                    technicalAssistanceElement.disabled = false;
                    communicationsSystemicChallengeAdd.disabled = false;
                }
            }
        }
    }
}
///<reference path="core.ts" />
//Lea Level User
class LeaLevelUser {
    constructor() {
        let core = new Core();
        //let saveButton = document.getElementById("LEALevelUserSave");
        //if (saveButton !== null)
        //    saveButton.addEventListener("click", (e: Event) => this.save());
        this.bindDeleteButtons();
        this.bindDeleteAllAccessButtons();
        this.bindSaveUserAccessButtons();
        let addNewRowButtons = document.getElementsByClassName("addAnotherPlan");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e) => this.addAnotherPlan(e));
        }
        core.activateSubMenu("LeaLevelUser");
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.removeLeaLevelUserRow(e));
        }
    }
    bindDeleteAllAccessButtons() {
        let deleteAllAccessButtons = document.getElementsByClassName("removeUserAccess");
        for (let deleteAllAccessButton of deleteAllAccessButtons) {
            deleteAllAccessButton.addEventListener("click", (e) => this.removeUserAccess(e));
        }
    }
    bindSaveUserAccessButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e) => this.saveUserAccess(e));
        }
    }
    //save() {
    //    let core = new Core();
    //    Core.showLoader();
    //    let allSaveElements = [];
    //    let rows = document.querySelectorAll("tr[data-rownumber]");
    //    for (let row of rows) {
    //        let allElements = row.getElementsByClassName("adminCheckbox");
    //        let selectElements = row.getElementsByClassName("adminSelect");
    //        let selectElementValue = 0;
    //        let isSchool = "true";
    //        for (let selectElement of selectElements) {
    //            let element = <HTMLSelectElement>selectElement;
    //            selectElementValue = parseInt(element.value);
    //            isSchool = element.options[element.selectedIndex].getAttribute('data-isschool')
    //        }
    //        for (let element of allElements) {
    //            let htmlElement = <HTMLInputElement>element;
    //            let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
    //            let institutionFK: number = 0;
    //            let accessTypeCodeFK: number = 0;
    //            if (isSchool === "False" || isSchool === "false") {
    //                institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
    //                accessTypeCodeFK = selectElementValue;
    //            }
    //            else {
    //                institutionFK = selectElementValue;
    //                accessTypeCodeFK = 8;
    //            }
    //            if (htmlElement.checked) {
    //                let saveItem: IEdDirUserAccessCreate = {
    //                    EdDirUserFK: edDirUserPK,
    //                    AccessCodeFK: parseInt(htmlElement.value),
    //                    InstitutionFK: institutionFK,
    //                    AccessTypeCodeFK: accessTypeCodeFK,
    //                    UserEdDirUserPK: ""
    //                };
    //                allSaveElements.push(saveItem);
    //            }
    //        }
    //    }
    //    let xhr = new XMLHttpRequest();
    //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
    //    xhr.setRequestHeader('Content-Type', 'application/json');
    //    xhr.onload = function () {
    //        if (xhr.status === 200) {
    //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
    //        }
    //        else {
    //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
    //        }
    //    };
    //        xhr.send(JSON.stringify(allSaveElements));
    //}
    removeLeaLevelUserRow(e) {
        let core = new Core();
        let saveObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        //parent.parentElement.removeChild(parent); // remove the row from the dom
        let edDirUserFK = button.dataset.eddiruserpk; // start to do a normal save
        let selectedValue = button.dataset.selectedvalue;
        let buttonInstitutionFK = button.dataset.institutionfk;
        let rowId = button.dataset.rowid;
        //let rowValue = 0;
        //if (selectedValue !== "none") {
        //    rowValue = parseInt(selectedValue);
        //}
        //if (rowValue !== 0 && rowValue < 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for LEA level plans
        //}
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll(`tr[data-rownumber="${rowId}"]`);
        let numberOfLEARows = 0;
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt(row.dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                if (htmlElement.dataset.originallychecked === "True") {
                    let deleteItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allDeleteElements.push(deleteItem);
                }
            }
        }
        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
        //for (let row of rows) {
        //    let allElements = row.getElementsByClassName("adminCheckbox");
        //    let selectElements = row.getElementsByClassName("adminSelect");
        //    let selectElementValue = 0;
        //    for (let selectElement of selectElements) {
        //        let element = <HTMLSelectElement>selectElement;
        //        selectElementValue = parseInt(element.value);
        //    }
        //    for (let element of allElements) {
        //        let htmlElement = <HTMLInputElement>element;
        //        let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
        //        let institutionFK: number = 0;
        //        let accessTypeCodeFK: number = 0;
        //        let isSchool = true;
        //        if (selectElementValue !== 0 && selectElementValue < 8) {
        //            isSchool = false;
        //        }
        //        if (isSchool === false) {
        //            institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
        //            accessTypeCodeFK = selectElementValue;
        //            numberOfLEARows++;
        //        }
        //        else {
        //            institutionFK = selectElementValue;
        //            accessTypeCodeFK = 8;
        //        }
        //        if (htmlElement.checked && selectElementValue !== rowValue) {
        //            let saveItem: IEdDirUserAccessCreate = {
        //                EdDirUserFK: edDirUserPK,
        //                AccessCodeFK: parseInt(htmlElement.value),
        //                InstitutionFK: institutionFK,
        //                AccessTypeCodeFK: accessTypeCodeFK,
        //                UserEdDirUserPK: ""
        //            };
        //            allSaveElements.push(saveItem);
        //        }
        //    }
        //}
        //if (rowValue !== 0 && rowValue > 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for school level plans
        //}
        //if (allSaveElements.length === 0) {
        //    let instfk = 0
        //    if (rowValue !== 0 && rowValue <= 11) {
        //        instfk = parseInt(buttonInstitutionFK);
        //    }
        //    else {
        //        instfk = rowValue;
        //    }
        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: instfk,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };
        //    allSaveElements.push(saveItem);
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue > 11) {  // if you clicked on a school row delete it
        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: rowValue,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };
        //    allDeleteElements.push(saveItem);
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allDeleteElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue <= 11 && numberOfLEARows === 0) {  // if you clicked on an LEA row when there are more than 1 rows left but only one of them is and LEA row
        //    this.deleteLastLEARow(parseInt(edDirUserFK), parseInt(buttonInstitutionFK));
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else {
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
    }
    deleteLastLEARow(EdDirUserFK, InstitutionFK) {
        let core = new Core();
        let allDeleteElements = [];
        let saveItem = {
            EdDirUserFK: EdDirUserFK,
            AccessCodeFK: 0,
            InstitutionFK: InstitutionFK,
            AccessTypeCodeFK: 0,
            UserEdDirUserPK: ""
        };
        allDeleteElements.push(saveItem);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }
    addAnotherPlan(e) {
        let core = new Core();
        let that = this;
        let button = e.currentTarget;
        let userPK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let rowCount = 0;
        for (let row of rows) {
            rowCount++;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/LeaLevelUserDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount + "&userPK=" + userPK);
    }
    removeUserAccess(e) {
        let core = new Core();
        let saveObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt(row.dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                if (htmlElement.dataset.originallychecked === "True") {
                    let deleteItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allDeleteElements.push(deleteItem);
                }
            }
        }
        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
        //for (let row of rows) {
        //    let allElements = row.getElementsByClassName("adminCheckbox");
        //    let selectElements = row.getElementsByClassName("adminSelect");
        //    let selectElementValue = 0;
        //    for (let selectElement of selectElements) {
        //        let element = <HTMLSelectElement>selectElement;
        //        selectElementValue = parseInt(element.value);
        //    }
        //    for (let element of allElements) {
        //        let htmlElement = <HTMLInputElement>element;
        //        let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
        //        let institutionFK: number = 0;
        //        let accessTypeCodeFK: number = 0;
        //        let isSchool = true;
        //        if (selectElementValue !== 0 && selectElementValue < 8) {
        //            isSchool = false;
        //        }
        //        if (isSchool === false) {
        //            institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
        //            accessTypeCodeFK = selectElementValue;
        //        }
        //        else {
        //            institutionFK = selectElementValue;
        //            accessTypeCodeFK = 8;
        //        }
        //        if (htmlElement.checked) {
        //            let saveItem: IEdDirUserAccessCreate = {
        //                EdDirUserFK: edDirUserPK,
        //                AccessCodeFK: parseInt(htmlElement.value),
        //                InstitutionFK: institutionFK,
        //                AccessTypeCodeFK: accessTypeCodeFK,
        //                UserEdDirUserPK: ""
        //            };
        //            allSaveElements.push(saveItem);
        //            htmlElement.checked = false;
        //        }
        //    }
        //}
        //let xhr = new XMLHttpRequest();
        //xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //xhr.setRequestHeader('Content-Type', 'application/json');
        //xhr.onload = function () {
        //    if (xhr.status === 200) {
        //        Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //    }
        //    else {
        //        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //    }
        //};
        //xhr.send(JSON.stringify(allSaveElements));
    }
    saveUserAccess(e) {
        let core = new Core();
        //making this save more like the bureauUserAccess Save
        let saveObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt(row.dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                // only push those elements that are different
                if (htmlElement.checked && htmlElement.dataset.originallychecked === "False") {
                    let saveItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allSaveElements.push(saveItem);
                }
                if (htmlElement.checked === false && htmlElement.dataset.originallychecked === "True") {
                    let deleteItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allDeleteElements.push(deleteItem);
                }
            }
        }
        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}
//School Plans
class SchoolPlansAdmin {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("SchoolPlanSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save());
        this.bindDeleteButtons();
        this.bindIndSaveButtons();
        core.activateSubMenu("SchoolPlans");
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.removeSchoolPlansRow(e));
        }
    }
    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveIndSchoolAccess");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e) => this.saveSchoolAccess(e));
        }
    }
    save() {
        let core = new Core();
        Core.showLoader();
        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = 0;
        for (let selectElement of selectElements) {
            let element = selectElement;
            let elementInstitutionFK = element.dataset.institutionfk;
            let elementPlanPK = element.dataset.planpk;
            //let datepicker = document.querySelector(".adminDatePicker[data-institutionfkcompletiondate='" + elementInstitutionFK + "']");
            //let datePickerElement = <HTMLInputElement>datepicker;
            //let date = datePickerElement.value;
            //let utc = new Date(date);
            //var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            selectElementValue = parseInt(element.value);
            if (selectElementValue > -1) {
                let saveItem = {
                    PlanPK: parseInt(elementPlanPK),
                    PlanTypeFK: selectElementValue
                };
                allSelectElements.push(saveItem);
            }
        }
        let rows = document.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = parseInt(htmlElement.dataset.institutionfk);
                if (htmlElement.checked) {
                    let saveItem = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: 8,
                        UserEdDirUserPK: ""
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        saveObject = {
            EdDirUserList: allSaveElements,
            PlanAdminSaveList: allSelectElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSchoolPlans', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    saveSchoolAccess(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let selectid = button.dataset.selectid;
        let elementInstitutionFK = button.dataset.institutionfk;
        let tableid = button.dataset.tableid;
        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = -1;
        //for (let selectElement of selectElements) {
        let element = document.getElementById(selectid);
        let elementPlanPK = 0;
        if (element !== null) {
            elementPlanPK = parseInt(element.dataset.planpk);
            selectElementValue = parseInt(element.value);
            //let elementPlanPK = element.dataset.planpk;
            //let elementInstitutionFK = element.dataset.institutionfk;
            //let datepicker = document.querySelector(".adminDatePicker[data-institutionfkcompletiondate='" + elementInstitutionFK + "']");
            //let datePickerElement = <HTMLInputElement>datepicker;
            //let date = datePickerElement.value;
            //let utc = new Date(date);
            //var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);// this converts it back to the day it should be.
            //selectElementValue = parseInt(element.value);
            if (selectElementValue > -1 && elementPlanPK !== 0) {
                let saveItem = {
                    PlanPK: elementPlanPK,
                    PlanTypeFK: selectElementValue
                };
                allSelectElements.push(saveItem);
            }
        }
        //}
        let table = document.getElementById(tableid);
        if (table !== null) {
            let rows = table.querySelectorAll("tr[data-rownumber]");
            for (let row of rows) {
                let allElements = row.getElementsByClassName("adminCheckbox");
                for (let element of allElements) {
                    let htmlElement = element;
                    let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                    let institutionFK = parseInt(htmlElement.dataset.institutionfk);
                    if (htmlElement.checked) {
                        let saveItem = {
                            EdDirUserFK: edDirUserPK,
                            AccessCodeFK: parseInt(htmlElement.value),
                            InstitutionFK: institutionFK,
                            AccessTypeCodeFK: 8,
                            UserEdDirUserPK: ""
                        };
                        allSaveElements.push(saveItem);
                    }
                }
            }
        }
        saveObject = {
            EdDirUserList: allSaveElements,
            PlanAdminSaveList: allSelectElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSchoolPlans', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    removeSchoolPlansRow(e) {
        let core = new Core();
        let button = e.currentTarget;
        let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let frppInstitutionFk = parseInt(button.dataset.institutionfk);
        let parent = button.parentElement.parentElement; //To the TR
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteEdDirUserAccessByInstitution', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userFK=" + edDirUserFK + "&frppInstitutionFk=" + frppInstitutionFk);
    }
}
//District Configuration
class DistrictConfiguration {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("districtConfiguration");
        let fileUploader = document.getElementById("uploadNewLogo");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.srcElement));
        }
        let saveButton = document.getElementById("adminDistrictConfigurationSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save(e));
    }
    save(e) {
        let saveButton = e.srcElement;
        let adminLogo = document.getElementById("adminLogo");
        let frcppInstitutionFK = saveButton.dataset.frcppinstitutionpk;
        let fileFK = adminLogo.dataset.filefk;
        let backgroundColor = "";
        let textColor = "";
        //We'll need to add in values from the color pickers here to make sure there is something to save before making sure it's saved.
        if (fileFK !== null) {
            console.log(`Save ${saveButton.dataset.frcppinstitutionpk} + ${fileFK}`);
            let core = this._core;
            Core.showLoader();
            let formData = {
                FileFK: parseInt(fileFK),
                FRCPPInstitutionFK: parseInt(frcppInstitutionFK),
                ReportHeaderBackgroundColor: backgroundColor,
                ReportHeaderTextColor: textColor
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/SaveDistrictConfiguration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status == 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The district configuration settings have been updated", "success", 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(formData));
        }
        else {
            console.log("No new file uploaded. Nothing to save here.");
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadLogoForm = document.getElementById("uploadLogo");
        let formData = new FormData(uploadLogoForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded!", 'success', 3000, null);
                        let adminLogo = document.getElementById("adminLogo");
                        adminLogo.innerHTML = '';
                        let image = document.createElement("img");
                        image.src = `${res.fileBytes}`;
                        image.height = image.width = 192;
                        image.alt = "District Logo";
                        adminLogo.dataset.filefk = res.fileFK;
                        adminLogo.classList.remove("empty");
                        adminLogo.appendChild(image);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}
//Language Editor
class LanguageEditor {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("languageEditor");
    }
}
//Resources
class Resources {
    constructor() {
        this.allDeleteElements = [];
        this._core = new Core();
        this._core.activateSubMenu("Resources");
        this.bindAll();
        let resourceSelect = document.getElementById("resourceType");
        if (resourceSelect !== null) {
            resourceSelect.addEventListener("change", (e) => this.showResourceType(e));
        }
    }
    bindAll() {
        let searchButton = document.getElementById("ResourceSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e) => this.search(e));
        let clearButton = document.getElementById("ResourceSearchClear");
        if (clearButton !== null)
            clearButton.addEventListener("click", (e) => this.clearSearch(e));
        let saveButton = document.getElementById("ResourcesSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save());
        let newResourceSaveButton = document.getElementById("newResourceSave");
        if (newResourceSaveButton !== null)
            newResourceSaveButton.addEventListener("click", (e) => this.saveNewResource());
        let resourceCheckboxes = document.getElementsByClassName("actionPlanSelectedMeasurableGoals");
        for (let resourceCheckbox of resourceCheckboxes) {
            //let element = <HTMLInputElement>resourceCheckbox;
            resourceCheckbox.addEventListener("change", (e) => this.setDeleteElement(e));
        }
        let editResourceButtons = document.getElementsByClassName("editResource");
        for (let editResourceButton of editResourceButtons) {
            editResourceButton.addEventListener("click", (e) => this.showEditResourceModal(editResourceButton));
        }
        let editResourceSaveButton = document.getElementById("editResourceSave");
        if (editResourceSaveButton !== null)
            editResourceSaveButton.addEventListener("click", (e) => this.editResourceSave(editResourceSaveButton));
        let editResourceCancelButton = document.getElementById("editResourceCancel");
        if (editResourceCancelButton !== null)
            editResourceCancelButton.addEventListener("click", (e) => this.editResourceCancel());
        let deleteResourceButtons = document.getElementsByClassName("deleteResourceButton");
        for (let deleteResourceButton of deleteResourceButtons) {
            deleteResourceButton.addEventListener("click", (e) => this.deleteResource(e));
        }
    }
    showEditResourceModal(editResourceButton) {
        let resourceName = editResourceButton.dataset.resourcename;
        let resourceURL = editResourceButton.dataset.resourceurl;
        let resourceFile = editResourceButton.dataset.resourcefile;
        let resourcepk = editResourceButton.dataset.resourcepk;
        let modal = new Modal("editResourceModal", null);
        let editResourceSaveButton = document.getElementById("editResourceSave");
        editResourceSaveButton.dataset.resourcename = resourceName;
        editResourceSaveButton.dataset.resourceurl = resourceURL;
        editResourceSaveButton.dataset.resourcefile = resourceFile;
        editResourceSaveButton.dataset.resourcepk = resourcepk;
        modal.show();
        let resourceNameInput = document.getElementById("ResourceNameEditInput");
        //let resourceFileInput = <HTMLInputElement>document.getElementById("resourceFileEditInput");
        let resourceURLInput = document.getElementById("resourceURLEditInput");
        resourceNameInput.value = resourceName;
        let file = document.getElementById("editFileUploadDiv");
        let link = document.getElementById("editURLDiv");
        if (resourceURL !== undefined && resourceURL !== "") {
            resourceURLInput.value = resourceURL;
            editResourceSaveButton.dataset.type = "link";
            link.classList.remove("is-hidden");
            file.classList.add("is-hidden");
        }
        if (resourceFile !== undefined && resourceFile !== "") {
            //resourceFileInput.value = resourceFile;
            editResourceSaveButton.dataset.type = "file";
            file.classList.remove("is-hidden");
            link.classList.add("is-hidden");
        }
    }
    editResourceSave(editResourceSaveButton) {
        let core = this._core;
        let that = this;
        let saveButton = editResourceSaveButton;
        let type = saveButton.dataset.type;
        let resourceNameElement = document.getElementById("ResourceNameEditInput");
        let resourceName = resourceNameElement.value;
        let resourceURLElement = document.getElementById("resourceURLEditInput");
        let resourceURL = "";
        if (resourceURLElement !== null) {
            resourceURL = resourceURLElement.value;
        }
        let uploadForm = document.getElementById("resourceEditFileUpload");
        let formData = new FormData(uploadForm);
        let resource = {
            ResourcePK: parseInt(saveButton.dataset.resourcepk),
            ResourceName: resourceName,
            ResourceURL: null,
            ResourceCategoryCodeFK: null,
            FileUploadFK: null,
            IsDeletedInd: false
        };
        //you cant get the files.length from the uploadForm, so we need this
        let upload = document.getElementById("resourceEditFileUpload");
        if (type === "file" && upload[0].files.length > 0) {
            that.uploadResourceFileForExistingResource(formData, resource);
        }
        else if (type === "file" && upload[0].files.length === 0) {
            resource.FileUploadFK = parseInt(saveButton.dataset.resourcefile);
            that.updateResource(resource);
        }
        else if (type === "link") {
            resource.ResourceURL = resourceURL;
            that.updateResource(resource);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "Save failed.", 'error', 3000, null);
        }
    }
    editResourceCancel() {
        let modal = new Modal("editResourceModal", null);
        modal.hide();
    }
    showResourceType(e) {
        let element = e.srcElement;
        let file = document.getElementById("fileUploadDiv");
        let link = document.getElementById("URLDiv");
        let linkInput = document.getElementById("resourceLinkInput");
        let fileInput = document.getElementById("resourceFileInput");
        if (element.value === "file") {
            file.classList.remove("is-hidden");
            link.classList.add("is-hidden");
            if (linkInput !== null) {
                linkInput.value = "";
            }
        }
        else if (element.value === "link") {
            file.classList.add("is-hidden");
            link.classList.remove("is-hidden");
            fileInput.value = "";
        }
        else {
            file.classList.add("is-hidden");
            link.classList.add("is-hidden");
            fileInput.value = "";
            linkInput.value = "";
        }
    }
    setDeleteElement(e) {
        let element = e.srcElement;
        if (element.checked === false && parseInt(element.dataset.sequencenbr) !== 0) {
            let saveItem = {
                ResourceFK: parseInt(element.dataset.resourcefk),
                PageFK: parseInt(element.dataset.pagefk),
                SequenceNbr: parseInt(element.dataset.sequencenbr),
            };
            this.allDeleteElements.push(saveItem);
        }
    }
    clearSearch(e) {
        Core.createHTMLAlert("alertMessageDiv", "ReloadingPage", 'success', 3000, this._core.pageReload());
    }
    searchName(searchvalue) {
        let searchString = searchvalue.toUpperCase().split(" ");
        let searchRows = document.getElementsByClassName("rowForSearch");
        let resourceName = document.getElementById("ResourceNameInput");
        for (let searchRow of searchRows) {
            let count = 0;
            let element = searchRow;
            let searchRowValue = element.dataset.resourcename.toUpperCase();
            for (let search of searchString) {
                if (searchRowValue.includes(search)) {
                    count++;
                }
            }
            if (count === 0) {
                element.style.display = "none";
            }
            if (count !== 0) {
                element.style.display = "block";
            }
        }
        resourceName.value = searchvalue;
    }
    search(e) {
        let core = this._core;
        let that = this;
        let resourceName = document.getElementById("ResourceNameInput");
        let resourcePage = document.getElementById("ResourcePageSearchDropDown");
        let resourcePageValue = 0;
        let resourceNameValue = "";
        if (resourcePage !== null) {
            resourcePageValue = parseInt(resourcePage.value);
        }
        if (resourceName !== null) {
            resourceNameValue = resourceName.value;
        }
        if ((resourceName !== null || resourceName.value !== "") && (resourcePage !== null && resourcePageValue === 0)) {
            that.searchName(resourceNameValue);
        }
        else {
            if (resourcePage !== null) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Admin/SearchResoursesByPage', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        let document = window.document.body;
                        document.innerHTML = xhr.responseText;
                        that.bindAll();
                        that.allDeleteElements = [];
                        $('.multiSelectResources').MultiSelect();
                        if (resourceNameValue !== "") {
                            that.searchName(resourceNameValue);
                        }
                        Core.createHTMLAlert("alertMessageDiv", "New data loaded", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("resourcePageValue=" + resourcePageValue);
            }
        }
    }
    uploadNewResourceFile(formData) {
        let core = this._core;
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/UploadResourceFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded!", 'success', 3000, null);
                        that.saveNewFileResource(res.resourceFileFK);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                        return false;
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.  Please make sure you have a file chosen.", 'error', 3000, null);
                return false;
            }
        };
        xhr.send(formData);
    }
    uploadResourceFileForExistingResource(formData, resource) {
        let core = this._core;
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/UploadResourceFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded!", 'success', 3000, null);
                        resource.FileUploadFK = res.resourceFileFK;
                        that.updateResource(resource);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                        return false;
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                return false;
            }
        };
        xhr.send(formData);
    }
    saveNewResource() {
        let core = this._core;
        let that = this;
        let resourceName = document.getElementById("resourceNameInput");
        let resourceNameValue = resourceName.value;
        if (resourceNameValue === "" || resourceNameValue === null) {
            Core.createHTMLAlert("alertMessageDiv", "Please enter a Resource Name", 'error', 3000, null);
            return false;
        }
        let resourceType = document.getElementById("resourceType");
        let resourceTypeValue = resourceType.value;
        if (resourceTypeValue === "file") {
            let uploadForm = document.getElementById("resourceFileUpload");
            let formData = new FormData(uploadForm);
            //you cant get the files.length from the uploadForm, so we need this
            let upload = document.getElementById("resourceFileUpload");
            if (upload[0].files.length > 0) {
                that.uploadNewResourceFile(formData);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Please upload a file first.", 'error', 3000, null);
                return false;
            }
        }
        if (resourceTypeValue === "link") {
            let resourceURL = document.getElementById("resourceURLInput");
            let resourceURLValue = resourceURL.value;
            if (resourceURLValue !== null && resourceURLValue !== "") {
                that.saveNewLinkResource();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a URL for the link. ", 'error', 3000, null);
                return false;
            }
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "Please choose a resource Type", 'error', 3000, null);
        }
    }
    saveNewLinkResource() {
        let core = this._core;
        let that = this;
        Core.showLoader();
        let saveObject;
        let allSaveElements = [];
        let resourceURL = document.getElementById("resourceURLInput");
        let resourceURLValue = resourceURL.value;
        let resourceName = document.getElementById("resourceNameInput");
        let resourceNameValue = resourceName.value;
        let resource = {
            ResourcePK: 0,
            ResourceName: resourceNameValue,
            ResourceURL: resourceURLValue,
            ResourceCategoryCodeFK: null,
            FileUploadFK: null,
            IsDeletedInd: false
        };
        let selectElements = document.getElementsByClassName("multiSelectResourcesNew");
        for (let selectElement of selectElements) {
            let element = selectElement;
            let selection = element.querySelectorAll("ul li.selected");
            if (selection.length > 0) {
                for (let s of selection) {
                    let sequenceLength = selection.length;
                    let sElement = s;
                    let nextElement = sElement.querySelector("input");
                    let inputElement = nextElement;
                    let saveItem = {
                        ResourceFK: 0,
                        PageFK: parseInt(inputElement.dataset.pagefk),
                        SequenceNbr: 1,
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        saveObject = {
            Resource: resource,
            SavePageList: allSaveElements,
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/CreateNewResource', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    updateResource(resource) {
        let core = this._core;
        Core.showLoader();
        let saveObject;
        let allSaveElements = [];
        saveObject = {
            Resource: resource,
            SavePageList: allSaveElements,
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/CreateNewResource', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    saveNewFileResource(resourceFileFK) {
        let core = this._core;
        Core.showLoader();
        let saveObject;
        let allSaveElements = [];
        let resourceName = document.getElementById("resourceNameInput");
        let resourceNameValue = resourceName.value;
        let resource = {
            ResourcePK: 0,
            ResourceName: resourceNameValue,
            ResourceURL: null,
            ResourceCategoryCodeFK: null,
            FileUploadFK: resourceFileFK,
            IsDeletedInd: false
        };
        let selectElements = document.getElementsByClassName("multiSelectResourcesNew");
        for (let selectElement of selectElements) {
            let element = selectElement;
            let selection = element.querySelectorAll("ul li.selected");
            if (selection.length > 0) {
                for (let s of selection) {
                    let sequenceLength = selection.length;
                    let sElement = s;
                    let nextElement = sElement.querySelector("input");
                    let inputElement = nextElement;
                    let saveItem = {
                        ResourceFK: 0,
                        PageFK: parseInt(inputElement.dataset.pagefk),
                        SequenceNbr: 1,
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        saveObject = {
            Resource: resource,
            SavePageList: allSaveElements,
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/CreateNewResource', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    save() {
        let core = this._core;
        Core.showLoader();
        let saveObject;
        let allSaveElements = [];
        let selectElements = document.getElementsByClassName("multiSelectResourcesRow");
        for (let selectElement of selectElements) {
            let element = selectElement;
            let selection = element.querySelectorAll("ul li.selected");
            if (selection.length > 0) {
                for (let s of selection) {
                    let sequenceLength = selection.length;
                    let sElement = s;
                    let nextElement = sElement.querySelector("input");
                    let inputElement = nextElement;
                    let sequenceNbr = parseInt(inputElement.dataset.sequencenbr);
                    if (sequenceNbr === 0) {
                        sequenceNbr = 1;
                    }
                    let saveItem = {
                        ResourceFK: parseInt(inputElement.dataset.resourcefk),
                        PageFK: parseInt(inputElement.dataset.pagefk),
                        SequenceNbr: parseInt(inputElement.dataset.resourcefk),
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        saveObject = {
            SaveList: allSaveElements,
            DeleteList: this.allDeleteElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveResourcePage', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    deleteResource(e) {
        let core = this._core;
        let button = e.srcElement;
        let resourcePK = parseInt(button.dataset.resourcepk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteResourceByResourcePK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("resourcePK=" + resourcePK);
    }
}
////EditLEA
//class EditLEA {
//    private _core: Core;
//    constructor() {
//        this._core = new Core();
//        this._core.activateSubMenu("EditLEA");
//    }
//}
class CommunicateWithLEA {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CommunicateWithLEA");
        let comprehensivePlansTab = document.querySelector(".tablinks.comprehensivePlans");
        if (comprehensivePlansTab != null) {
            comprehensivePlansTab.addEventListener("click", (e) => this.tabLinks("CommunicateWithLEA"));
        }
        let schoolPlansTab = document.querySelector(".tablinks.schoolPlans");
        if (schoolPlansTab != null) {
            schoolPlansTab.addEventListener("click", (e) => this.tabLinks("SchoolCommunicate"));
        }
        let stateReportsTab = document.querySelector(".tablinks.stateReports");
        if (stateReportsTab != null) {
            stateReportsTab.addEventListener("click", (e) => this.tabLinks("StateReportsCommunicate"));
        }
        let leaClearButton = document.getElementById("clearSearch");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e) => this.clearLEASearchFilter());
        let leaSearchButton = document.getElementById("runSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => Core.PlanSearch(1, '/Admin/CommunicateWithLEA'));
        let leaPlanName = document.getElementById("leaName");
        if (leaPlanName !== null)
            leaPlanName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
                Core.PlanSearch(1, '/Admin/CommunicateWithLEA');
            } });
        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            //let element = <HTMLInputElement>resourceCheckbox;
            emailButton.addEventListener("click", (e) => this.sendEmail(e));
        }
        this._core.bindChangeLEA();
    }
    sendEmail(e) {
        Core.showLoader();
        let planfkList = [];
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        for (let checkbox of checkboxes) {
            let element = checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.planfk;
                var planfk = parseInt(value);
                planfkList.push(planfk);
            }
        }
        if (planfkList.length > 0) {
            //make call to controller to get email string
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/GetEmailString', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var resp = JSON.parse(xhr.response);
                    if (resp.success === true) {
                        Core.hideLoader();
                        if (resp.emailAddress.length === 0) {
                            Core.createHTMLAlert("alertMessageDiv", "No Email Addresses found", "error", 3000, null);
                        }
                        else {
                            window.location.href = `mailto:?bcc=${resp.emailAddress}`;
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.Returned status of '" + xhr.status, "error", 3000, null);
                        Core.hideLoader();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planfkList));
        }
        else {
            alert("Please choose someone to email to.");
        }
    }
    clearLEASearchFilter() {
        window.location.href = "/Admin/CommunicateWithLEA";
    }
    tabLinks(link) {
        let url = "/Admin/" + link;
        window.location.href = url;
    }
}
class SchoolCommunicate {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CommunicateWithLEA");
        let comprehensivePlansTab = document.querySelector(".tablinks.comprehensivePlans");
        if (comprehensivePlansTab != null) {
            comprehensivePlansTab.addEventListener("click", (e) => this.tabLinks("CommunicateWithLEA"));
        }
        let schoolPlansTab = document.querySelector(".tablinks.schoolPlans");
        if (schoolPlansTab != null) {
            schoolPlansTab.addEventListener("click", (e) => this.tabLinks("SchoolCommunicate"));
        }
        let stateReportsTab = document.querySelector(".tablinks.stateReports");
        if (stateReportsTab != null) {
            stateReportsTab.addEventListener("click", (e) => this.tabLinks("StateReportsCommunicate"));
        }
        let schoolClearButton = document.getElementById("clearSearch");
        schoolClearButton.addEventListener("click", (e) => this.clearSchoolSearchFilter());
        let schoolSearchButton = document.getElementById("runSearch");
        schoolSearchButton.addEventListener("click", (e) => Core.PlanSearch(1, '/Admin/SchoolCommunicate'));
        let schoolPlanName = document.getElementById("schoolName");
        schoolPlanName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
            Core.PlanSearch(1, '/Admin/SchoolCommunicate');
        } });
        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            emailButton.addEventListener("click", (e) => this.sendEmail(e));
        }
        this._core.bindChangeLEA();
    }
    sendEmail(e) {
        Core.showLoader();
        let planfkList = [];
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        for (let checkbox of checkboxes) {
            let element = checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.planfk;
                var planfk = parseInt(value);
                planfkList.push(planfk);
            }
        }
        if (planfkList.length > 0) {
            //make call to controller to get email string
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/GetEmailString', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var resp = JSON.parse(xhr.response);
                    if (resp.success === true) {
                        Core.hideLoader();
                        if (resp.emailAddress.length === 0) {
                            Core.createHTMLAlert("alertMessageDiv", "No Email Addresses found", "error", 3000, null);
                        }
                        else {
                            window.location.href = `mailto:?bcc=${resp.emailAddress}`;
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.Returned status of '" + xhr.status, "error", 3000, null);
                        Core.hideLoader();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planfkList));
        }
        else {
            alert("Please choose someone to email to.");
        }
    }
    tabLinks(link) {
        let url = "/Admin/" + link;
        window.location.href = url;
    }
    redirect(e, url) {
        let element = e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;
        window.location.href = newURL;
    }
    clearSchoolSearchFilter() {
        window.location.href = "/Admin/SchoolCommunicate";
    }
}
class StateReportsCommunicate {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CommunicateWithLEA");
        let comprehensivePlansTab = document.querySelector(".tablinks.comprehensivePlans");
        if (comprehensivePlansTab != null) {
            comprehensivePlansTab.addEventListener("click", (e) => this.tabLinks("CommunicateWithLEA"));
        }
        let schoolPlansTab = document.querySelector(".tablinks.schoolPlans");
        if (schoolPlansTab != null) {
            schoolPlansTab.addEventListener("click", (e) => this.tabLinks("SchoolCommunicate"));
        }
        let stateReportsTab = document.querySelector(".tablinks.stateReports");
        if (stateReportsTab != null) {
            stateReportsTab.addEventListener("click", (e) => this.tabLinks("StateReportsCommunicate"));
        }
        let stateClearButton = document.getElementById("clearSearch");
        stateClearButton.addEventListener("click", (e) => this.clearStateSearchFilter());
        let stateSearchButton = document.getElementById("runSearch");
        stateSearchButton.addEventListener("click", (e) => Core.PlanSearch(1, '/Admin/StateReportsCommunicate'));
        let schoolPlanName = document.getElementById("leaName");
        schoolPlanName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
            Core.PlanSearch(1, '/Admin/StateReportsCommunicate');
        } });
        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            emailButton.addEventListener("click", (e) => this.sendEmail(e));
        }
        let selectAll = document.getElementById("selectAllSchool");
        if (selectAll !== null) {
            selectAll.addEventListener("change", (e) => this.selectAllSchools(e.srcElement));
        }
        this._core.bindChangeLEA();
    }
    clearStateSearchFilter() {
        window.location.href = "/Admin/StateReportsCommunicate";
    }
    sendEmail(e) {
        Core.showLoader();
        let planfkList = [];
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        for (let checkbox of checkboxes) {
            let element = checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.planfk;
                var planfk = parseInt(value);
                planfkList.push(planfk);
            }
        }
        if (planfkList.length > 0) {
            //make call to controller to get email string
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/GetEmailString', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var resp = JSON.parse(xhr.response);
                    if (resp.success === true) {
                        Core.hideLoader();
                        if (resp.emailAddress.length === 0) {
                            Core.createHTMLAlert("alertMessageDiv", "No Email Addresses found", "error", 3000, null);
                        }
                        else {
                            window.location.href = `mailto:?bcc=${resp.emailAddress}`;
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.Returned status of '" + xhr.status, "error", 3000, null);
                        Core.hideLoader();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planfkList));
        }
        else {
            alert("Please choose someone to email to.");
        }
    }
    tabLinks(link) {
        let url = "/Admin/" + link;
        window.location.href = url;
    }
    redirect(e, url) {
        let element = e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;
        window.location.href = newURL;
    }
    selectAllSchools(selectAllCheckbox) {
        let allCheckboxes = document.getElementsByClassName("emailCheckBox");
        let allChecked = false;
        if (selectAllCheckbox.checked) {
            allChecked = true;
        }
        if (allChecked) {
            for (let checkbox of allCheckboxes) {
                let element = checkbox;
                element.checked = true;
            }
        }
        else {
            for (let checkbox of allCheckboxes) {
                let element = checkbox;
                element.checked = false;
            }
        }
    }
}
//HomePage
class HomePage {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("HomePage");
        this.bindDeleteButtons();
        this.bindIndSaveButtons();
        let addNewRowButtons = document.getElementsByClassName("addAnotherPost");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e) => this.addAnotherPost(e));
        }
        this._core.activateSubMenu("SchoolPlans");
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.removeNewsUpdateRow(e));
        }
    }
    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("savenewsupdates");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e) => this.saveNewsUpdate(e));
        }
    }
    saveNewsUpdate(e) {
        let core = this._core;
        //Core.showLoader();
        let button = e.currentTarget;
        let newsUpdatePK = parseInt(button.dataset.newsupdatepk);
        let titleElement = document.getElementById("title_" + newsUpdatePK);
        let titleElementValue = titleElement.value;
        let startDatePicker = document.getElementById("startDate_" + newsUpdatePK);
        let startDatePickerValue = startDatePicker.value;
        let startUTC = new Date(startDatePickerValue);
        let startDate = new Date(startUTC.getTime() + startUTC.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let endDatePicker = document.getElementById("endDate_" + newsUpdatePK);
        let endDatePickerValue = endDatePicker.value;
        let endUTC = new Date(endDatePickerValue);
        let endDate = new Date(endUTC.getTime() + endUTC.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let checkElement = document.getElementById("show_" + newsUpdatePK);
        let showOnHomePage = false;
        if (checkElement.checked) {
            showOnHomePage = true;
        }
        let saveObject = {
            NewsUpdatePK: newsUpdatePK,
            NewsTitle: titleElementValue,
            NewsDesc: " ",
            DisplayStartDate: startDate,
            DisplayEndDate: endDate,
            ShowOnHomePageInd: showOnHomePage,
            IsDeletedInd: false
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveNewsUpdate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    addAnotherPost(e) {
        let core = new Core();
        let that = this;
        let button = e.currentTarget;
        button.disabled = true;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/AddAnotherPost', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Post Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindIndSaveButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send();
    }
    removeNewsUpdateRow(e) {
        let core = new Core();
        let button = e.currentTarget;
        let newsUpdatePK = parseInt(button.dataset.newsupdatepk);
        let parent = button.parentElement.parentElement; //To the TR
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteNewsUpdateByNewsUpdatePK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newsUpdatePK=" + newsUpdatePK);
    }
}
//Read Only
class ReadOnly {
    constructor() {
        let core = new Core();
        this.bindDeleteButtons();
        this.bindDeleteAllAccessButtons();
        this.bindSaveUserAccessButtons();
        this.bindAutocompletes();
        let leaClearButton = document.getElementById("readOnlySearchClear");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e) => this.clearLEASearchFilter());
        let leaSearchButton = document.getElementById("readOnlySearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => this.leaSearch());
        let addNewRowButtons = document.getElementsByClassName("addAnotherPlan");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e) => this.addAnotherPlan(e));
        }
        core.activateSubMenu("readOnly");
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.removeReadOnlyRow(e));
        }
    }
    bindAutocompletes() {
        let hidden = document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray, row, tableid);
        }
    }
    bindDeleteAllAccessButtons() {
        let deleteAllAccessButtons = document.getElementsByClassName("removeUserAccess");
        for (let deleteAllAccessButton of deleteAllAccessButtons) {
            deleteAllAccessButton.addEventListener("click", (e) => this.removeUserAccess(e));
        }
    }
    bindSaveUserAccessButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e) => this.saveUserAccess(e));
        }
    }
    clearLEASearchFilter() {
        let nameElement = document.getElementById("readOnlySearchClear");
        nameElement.value = "";
        //TODO: Turn this into a partial view, instead of a reload.
        window.location.href = "/Admin/ReadOnly";
    }
    leaSearch() {
        let nameElement = document.getElementById("institutionNameSearch");
        let name = nameElement.value;
        let url = "/Admin/ReadOnly/";
        if (name !== "")
            url += "?schoolName=" + name;
        window.location.href = url;
    }
    removeLeaLevelUserRow(e) {
        let core = new Core();
        let saveObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = e.currentTarget;
        let parent = button.parentElement.parentElement; //To the TR
        //parent.parentElement.removeChild(parent); // remove the row from the dom
        let edDirUserFK = button.dataset.eddiruserpk; // start to do a normal save
        let selectedValue = button.dataset.selectedvalue;
        let buttonInstitutionFK = button.dataset.institutionfk;
        let rowId = button.dataset.rowid;
        //let rowValue = 0;
        //if (selectedValue !== "none") {
        //    rowValue = parseInt(selectedValue);
        //}
        //if (rowValue !== 0 && rowValue < 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for LEA level plans
        //}
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll(`tr[data-rownumber="${rowId}"]`);
        let numberOfLEARows = 0;
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let selectElementValue = 0;
            let isSchool = "True";
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                //let isSchool = true;
                //if (selectElementValue !== 0 && selectElementValue < 8) {
                //    isSchool = false;
                //}
                if (isSchool === "False" || isSchool === "false") {
                    institutionFK = parseInt(row.dataset.leainstitution);
                    accessTypeCodeFK = selectElementValue;
                }
                else {
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                if (htmlElement.dataset.wasSelected === "True") {
                    let deleteItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allDeleteElements.push(deleteItem);
                }
            }
        }
        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveLEAUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
        //for (let row of rows) {
        //    let allElements = row.getElementsByClassName("adminCheckbox");
        //    let selectElements = row.getElementsByClassName("adminSelect");
        //    let selectElementValue = 0;
        //    for (let selectElement of selectElements) {
        //        let element = <HTMLSelectElement>selectElement;
        //        selectElementValue = parseInt(element.value);
        //    }
        //    for (let element of allElements) {
        //        let htmlElement = <HTMLInputElement>element;
        //        let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
        //        let institutionFK: number = 0;
        //        let accessTypeCodeFK: number = 0;
        //        let isSchool = true;
        //        if (selectElementValue !== 0 && selectElementValue < 8) {
        //            isSchool = false;
        //        }
        //        if (isSchool === false) {
        //            institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
        //            accessTypeCodeFK = selectElementValue;
        //            numberOfLEARows++;
        //        }
        //        else {
        //            institutionFK = selectElementValue;
        //            accessTypeCodeFK = 8;
        //        }
        //        if (htmlElement.checked && selectElementValue !== rowValue) {
        //            let saveItem: IEdDirUserAccessCreate = {
        //                EdDirUserFK: edDirUserPK,
        //                AccessCodeFK: parseInt(htmlElement.value),
        //                InstitutionFK: institutionFK,
        //                AccessTypeCodeFK: accessTypeCodeFK,
        //                UserEdDirUserPK: ""
        //            };
        //            allSaveElements.push(saveItem);
        //        }
        //    }
        //}
        //if (rowValue !== 0 && rowValue > 11) {
        //    parent.parentElement.removeChild(parent); // remove the row from the dom for school level plans
        //}
        //if (allSaveElements.length === 0) {
        //    let instfk = 0
        //    if (rowValue !== 0 && rowValue <= 11) {
        //        instfk = parseInt(buttonInstitutionFK);
        //    }
        //    else {
        //        instfk = rowValue;
        //    }
        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: instfk,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };
        //    allSaveElements.push(saveItem);
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue > 11) {  // if you clicked on a school row delete it
        //    let saveItem: IEdDirUserAccessCreate = {
        //        EdDirUserFK: parseInt(edDirUserFK),
        //        AccessCodeFK: 0,
        //        InstitutionFK: rowValue,
        //        AccessTypeCodeFK: 0,
        //        UserEdDirUserPK: ""
        //    };
        //    allDeleteElements.push(saveItem);
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allDeleteElements));
        //}
        //else if (allSaveElements.length > 0 && rowValue !== 0 && rowValue <= 11 && numberOfLEARows === 0) {  // if you clicked on an LEA row when there are more than 1 rows left but only one of them is and LEA row
        //    this.deleteLastLEARow(parseInt(edDirUserFK), parseInt(buttonInstitutionFK));
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
        //else {
        //    let xhr = new XMLHttpRequest();
        //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
        //    xhr.setRequestHeader('Content-Type', 'application/json');
        //    xhr.onload = function () {
        //        if (xhr.status === 200) {
        //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
        //        }
        //        else {
        //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
        //        }
        //    };
        //    xhr.send(JSON.stringify(allSaveElements));
        //}
    }
    deleteLastLEARow(EdDirUserFK, InstitutionFK) {
        let core = new Core();
        let allDeleteElements = [];
        let saveItem = {
            EdDirUserFK: EdDirUserFK,
            AccessCodeFK: 0,
            InstitutionFK: InstitutionFK,
            AccessTypeCodeFK: 0,
            UserEdDirUserPK: ""
        };
        allDeleteElements.push(saveItem);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteAllEdDirUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }
    addAnotherPlan(e) {
        let core = new Core();
        let that = this;
        let button = e.currentTarget;
        let userPK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let instInput = document.getElementById("institutionNameSearch");
        let rowCount = 0;
        for (let row of rows) {
            rowCount++;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/ReadOnlyUserDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindAutocompletes();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount + "&userPK=" + userPK + "&tableId=" + tableName);
    }
    removeUserAccess(e) {
        let core = new Core();
        let allSaveElements = [];
        let button = e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0; // this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0; // this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }
            for (let autcompleteElement of autcompleteElements) {
                let element = autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") { //no search done
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") { //institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") { //search has been done, and its an admin dropdown value
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else { //picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else { // its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                if (htmlElement.checked) {
                    let saveItem = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK,
                        UserEdDirUserPK: ""
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteAllReadOnlyViewerUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }
    removeReadOnlyRow(e) {
        let core = new Core();
        let button = e.currentTarget;
        let institutionfk = button.dataset.institutionfk;
        let userpk = button.dataset.eddiruserpk;
        let parent = button.parentElement.parentElement; //To the TR
        /*parent.parentElement.removeChild(parent);*/ // remove the row from the dom 
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteReadOnlyViewerUserAccessRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Row removed, Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userToDelete=" + userpk + "&frppInstitutionFk=" + institutionfk);
    }
    //saveUserAccess(e: Event) {
    //    let core = new Core();
    //    let allSaveElements = [];
    //    let button = <HTMLButtonElement>e.currentTarget;
    //    let edDirUserFK = button.dataset.usereddiruser;
    //    let tableName = button.dataset.tableid;
    //    let table = (<HTMLTableElement>document.getElementById(tableName));
    //    let rows = table.querySelectorAll("tr[data-rownumber]");
    //    let instInput = <HTMLInputElement>document.getElementById("institutionNameSearch");
    //    let instSearchPk = parseInt(instInput.getAttribute('data-searchinstpk'));
    //    for (let row of rows) {
    //        let allElements = row.getElementsByClassName("adminCheckbox");
    //        let selectElements = row.getElementsByClassName("adminSelect");
    //        let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
    //        let selectElementValue = 0;// this is the plan dropdown value.  Ie the accesstype
    //        let selectElementInstitutionValue = 0;// this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
    //        let isSchool = "False";
    //        let isAdmin = "False";
    //        let inputElements = row.getElementsByClassName("adminInputInstitution");
    //        let inputElementValue = 0;
    //        for (let inputElement of inputElements) {
    //            let element = <HTMLInputElement>inputElement;
    //            inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
    //        }
    //        //for (let selectElementInstitution of selectElementInstitutions) {
    //        //    let element = <HTMLSelectElement>selectElementInstitution;
    //        //    selectElementInstitutionValue = parseInt(element.value);
    //        //    isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
    //        //}
    //        for (let selectElement of selectElements) {
    //            let element = <HTMLSelectElement>selectElement;
    //            selectElementValue = parseInt(element.value);
    //            isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
    //            isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
    //        }
    //        for (let element of allElements) {
    //            let htmlElement = <HTMLInputElement>element;
    //            let edDirUserPK: number = parseInt((<HTMLTableRowElement>row).dataset.usereddiruserpk);
    //            let institutionFK: number = 0;
    //            let accessTypeCodeFK: number = 0;
    //            if (isSchool === "False" || isSchool === "false") {
    //                if (instInput.value === "" && inputElementValue === 0) {//no search done
    //                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
    //                }
    //                else if (instInput.value === "" && inputElementValue !== 0) {//institution is one not from the selected district
    //                    institutionFK = inputElementValue;
    //                }
    //                else if (instInput.value !== "" && isAdmin === "True") {//search has been done and value has been picked, and its an admin dropdown value
    //                    institutionFK = parseInt((<HTMLTableRowElement>row).dataset.leainstitution);
    //                }
    //                else {//picking a non admin LEA level plantype for the searched for LEA
    //                    institutionFK = instSearchPk;
    //                }
    //                accessTypeCodeFK = selectElementValue;
    //            }
    //            else {// its a school
    //                institutionFK = selectElementValue;
    //                accessTypeCodeFK = 8;
    //                if (inputElementValue !== 0) {
    //                    institutionFK = inputElementValue;
    //                }
    //            }
    //            if (htmlElement.checked) {
    //                let saveItem: IEdDirUserAccessCreate = {
    //                    EdDirUserFK: edDirUserPK,
    //                    AccessCodeFK: parseInt(htmlElement.value),
    //                    InstitutionFK: institutionFK,
    //                    AccessTypeCodeFK: accessTypeCodeFK,
    //                    UserEdDirUserPK: ""
    //                };
    //                allSaveElements.push(saveItem);
    //            }
    //        }
    //    }
    //    let xhr = new XMLHttpRequest();
    //    xhr.open('POST', '/Admin/SaveEdDirUserAccess', true);
    //    xhr.setRequestHeader('Content-Type', 'application/json');
    //    xhr.onload = function () {
    //        if (xhr.status === 200) {
    //            Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
    //        }
    //        else {
    //            Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
    //        }
    //    };
    //    xhr.send(JSON.stringify(allSaveElements));
    //}
    saveUserAccess(e) {
        let core = new Core();
        let allSaveElements = [];
        let button = e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0; // this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0; // this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }
            for (let autcompleteElement of autcompleteElements) {
                let element = autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") { //no search done
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") { //institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") { //search has been done, and its an admin dropdown value
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else { //picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else { // its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                if (htmlElement.checked) {
                    let saveItem = {
                        EdDirUserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        InstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK,
                        UserEdDirUserPK: ""
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveReadOnlyUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }
    autocomplete(inp, arr, row, tableid) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists(e);
            //if (!val) { return false; }
            if (!val) {
                that.resetDropdown(row, tableid);
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y;
            if (x)
                y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x)
                return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length)
                currentFocus = 0;
            if (currentFocus < 0)
                currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    getListOfSchools(e, row, tableid) {
        let core = new Core();
        let dropdown = e.srcElement;
        let parent = dropdown.parentElement;
        let parentid = parent.id;
        let name = encodeURIComponent(dropdown.textContent);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/GetSchoolList', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                let res = JSON.parse(xhr.responseText);
                if (res.success === true) {
                    let tableName = tableid;
                    let table = document.getElementById(tableName);
                    //let selects = table.getElementsByClassName("adminSelect");
                    let selects = table.getElementsByTagName("select");
                    let autoCompletes = table.getElementsByClassName("institutionAutocomplete");
                    let thisSelect = selects[0];
                    let thisAutoComplete;
                    for (let select of selects) {
                        if (select.id === ("'select'" + row)) {
                            thisSelect = select;
                        }
                    }
                    for (let autoComplete of autoCompletes) {
                        if (autoComplete.id === ("'institutionNameSearch'" + row)) {
                            thisAutoComplete = autoComplete;
                        }
                    }
                    let mintoRemove = 0;
                    let maxToRemove = 0;
                    for (let option of thisSelect.options) {
                        let length = thisSelect.options.length;
                        let thisOption = option;
                        let data = thisOption.dataset;
                        data = JSON.parse(JSON.stringify(data));
                        let index = thisOption.index;
                        let isOriginal = data.isoriginal;
                        if (isOriginal === "False") {
                            maxToRemove = length - 1;
                            mintoRemove = index;
                            break;
                        }
                    }
                    if (maxToRemove > 0) {
                        while (mintoRemove < (maxToRemove + 1)) {
                            thisSelect.options.remove(maxToRemove); //want to remove the highest one first
                            maxToRemove--;
                        }
                    }
                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        let t = document.createTextNode(inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                    thisAutoComplete.setAttribute("data-searchinstpk", res.searchedInstPK);
                    let options = thisSelect.options;
                    for (let option of options) {
                        if (option.dataset.isadmin == "True") {
                            option.disabled = true;
                        }
                        else {
                            option.disabled = false;
                        }
                    }
                    thisSelect.selectedIndex = -1;
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("name=" + name);
    }
    resetDropdown(row, tableid) {
        let tableName = tableid;
        let table = document.getElementById(tableName);
        let selects = table.getElementsByTagName("select");
        let thisSelect = selects[0];
        for (let select of selects) {
            if (select.id === ("'select'" + row)) {
                thisSelect = select;
            }
        }
        let mintoRemove = 0;
        let maxToRemove = 0;
        for (let option of thisSelect.options) {
            let length = thisSelect.options.length;
            let thisOption = option;
            let data = thisOption.dataset;
            data = JSON.parse(JSON.stringify(data));
            let index = thisOption.index;
            let isOriginal = data.isoriginal;
            if (isOriginal === "False") {
                maxToRemove = length - 1;
                mintoRemove = index;
                break;
            }
        }
        if (maxToRemove > 0) {
            while (mintoRemove < (maxToRemove + 1)) {
                thisSelect.options.remove(maxToRemove); //want to remove the highest one first
                maxToRemove--;
            }
        }
        let options = thisSelect.options;
        for (let option of options) {
            if (option.dataset.isadmin == "True") {
                option.disabled = false;
            }
            else {
                option.disabled = true;
            }
        }
        thisSelect.selectedIndex = -1;
        return false;
    }
}
class Impersonation {
    constructor() {
        let core = new Core();
        this.bindLEAAutocompletes();
        this.bindUserAutocompletes();
        let leaSearchButton = document.getElementById("impersonationLEASearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => this.leaSearch());
        let aUNSearchButton = document.getElementById("impersonationLEAAUNSearchSearch");
        if (aUNSearchButton !== null)
            aUNSearchButton.addEventListener("click", (e) => this.aUNSearch());
        let UserSearchButton = document.getElementById("impersonationUserSearchSearch");
        if (UserSearchButton !== null)
            UserSearchButton.addEventListener("click", (e) => this.userSearch());
        core.activateSubMenu("Impersonation");
    }
    bindLEAAutocompletes() {
        let hidden = document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            Core.autocomplete(autocomplete, thisarray);
        }
    }
    bindUserAutocompletes() {
        let hidden = document.getElementById("hiddenUserlist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("userAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            Core.autocomplete(autocomplete, thisarray);
        }
    }
    leaSearch() {
        let nameElement = document.getElementById("institutionNameSearch");
        let name = document.getElementsByTagName("input")[0].value;
        let url = "/Admin/Impersonation/";
        if (name !== "")
            url += "?districtName=" + encodeURIComponent(name);
        window.location.href = url;
    }
    aUNSearch() {
        let nameElement = document.getElementById("institutionAUN");
        let AUN = document.getElementsByTagName("input")[3].value;
        let aUNNumber = parseInt(AUN);
        let branch = document.getElementsByTagName("input")[4].value;
        if (AUN === "" || branch === "") {
            alert("Both AUN and Branch need a value");
        }
        let url = "/Admin/Impersonation/";
        if (AUN !== "")
            url += "?districtAUN=" + encodeURIComponent(aUNNumber) + "&branch=" + branch;
        window.location.href = url;
    }
    userSearch() {
        let nameElement = document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let AUN = document.getElementsByTagName("input")[3].value;
        let branch = document.getElementsByTagName("input")[4].value;
        let aUNNumber = parseInt(AUN);
        let userName = document.getElementsByTagName("input")[5].value;
        if (leaName !== "" && AUN !== "") {
            alert("please choose Name or AUN");
            return false;
        }
        let url = "/Admin/ImpersonateUser/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;
            window.location.href = url;
        }
        else {
            url = "/Admin/ImpersonateUserByAUN/";
            url += "?aUN=" + aUNNumber + "&branch=" + branch + "&userName=" + userName;
            window.location.href = url;
        }
    }
}
//School Plans
class PDEUserSchoolPlansAdmin {
    constructor() {
        let core = new Core();
        //let saveButton = document.getElementById("SchoolPlanSave");
        //if (saveButton !== null)
        //    saveButton.addEventListener("click", (e: Event) => this.save());
        this.bindIndSaveButtons();
        let schoolSearchButton = document.getElementById("schoolSearchSearch");
        if (schoolSearchButton !== null)
            schoolSearchButton.addEventListener("click", (e) => this.schoolSearch());
        core.activateSubMenu("PDEUserSchoolPlans");
    }
    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveIndSchoolAccess");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e) => this.saveSchoolAccess(e));
        }
    }
    schoolSearch() {
        let nameElement = document.getElementById("institutionNameSearch");
        let name = document.getElementsByTagName("input")[0].value;
        let url = "/Admin/PDEUSerSchoolPlans/";
        if (name !== "")
            url += "?schoolName=" + name;
        window.location.href = url;
    }
    saveSchoolAccess(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        let selectid = button.dataset.selectid;
        let elementInstitutionFK = button.dataset.institutionfk;
        let saveObject;
        let selectElementValue = -1;
        //for (let selectElement of selectElements) {
        let element = document.getElementById(selectid);
        let elementPlanPK = 0;
        if (element !== null) {
            elementPlanPK = parseInt(element.dataset.planpk);
            selectElementValue = parseInt(element.value);
            let datepicker = document.querySelector(".adminDatePicker[data-planpkcompletiondate='" + elementPlanPK + "']");
            let datePickerElement = datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
            //selectElementValue = parseInt(element.value);
            if (selectElementValue > -1 && elementPlanPK !== 0) {
                saveObject = {
                    PlanPK: elementPlanPK,
                    PlanTypeFK: selectElementValue,
                    DueDate: dueDate
                };
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePDEUserPlanRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}
//Update All Due Dates
class PDEUserUpdateAllDueDates {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save(e));
        core.activateSubMenu("PDEUserUpdateAllDueDates");
    }
    save(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let selectid = button.dataset.selectid;
        //let elementInstitutionFK = button.dataset.institutionfk;
        //let tableid = button.dataset.tableid;
        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = -1;
        /*let selectedPhaseCodeValue = -1;*/
        let selectedAcademicYearValue = -1;
        //for (let selectElement of selectElements) {
        let element = document.getElementById(selectid);
        //let elementPlanPK = 0;
        if (element !== null) {
            //elementPlanPK = parseInt(element.dataset.planpk);
            selectElementValue = parseInt(element.value);
            //let elementPlanPK = element.dataset.planpk;
            //let elementInstitutionFK = element.dataset.institutionfk;
            let datepicker = document.getElementById("datePicker");
            let datePickerElement = datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
            //selectElementValue = parseInt(element.value);
            //let selectedPhaseCode = document.getElementById("updateDueDatesSelectPhase");
            //let selectedPhaseCodeElement = <HTMLSelectElement>selectedPhaseCode;
            //if (selectedPhaseCodeElement !== null) {
            //    selectedPhaseCodeValue = parseInt(selectedPhaseCodeElement.value);
            //}
            let selectedAcademicYear = document.getElementById("updateDueDatesSelectAcademicYear");
            let selectedAcademicYearElement = selectedAcademicYear;
            if (selectedAcademicYearElement !== null) {
                selectedAcademicYearValue = parseInt(selectedAcademicYearElement.value);
            }
            if (selectElementValue > -1 && selectedAcademicYearValue > -1) {
                saveObject = {
                    PlanTypeFK: selectElementValue,
                    AcademicYearFK: selectedAcademicYearValue,
                    DueDate: dueDate
                };
                //allSelectElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePDEUserAllDueDates', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}
//PDEBureau Access
class PDEBureauAccess {
    constructor() {
        let core = new Core();
        this.bindDeleteButtons();
        this.bindDeleteAllAccessButtons();
        this.bindSaveUserAccessButtons();
        this.bindAutocompletes();
        let addNewRowButtons = document.getElementsByClassName("addAnotherPlan");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e) => this.addAnotherPlan(e));
        }
        core.activateSubMenu("pdebureauaccess");
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.removeReadOnlyRow(e));
        }
    }
    bindAutocompletes() {
        let hidden = document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray, row, tableid);
        }
    }
    bindDeleteAllAccessButtons() {
        let deleteAllAccessButtons = document.getElementsByClassName("removeUserAccess");
        for (let deleteAllAccessButton of deleteAllAccessButtons) {
            deleteAllAccessButton.addEventListener("click", (e) => this.removeUserAccess(e));
        }
    }
    bindSaveUserAccessButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e) => this.saveUserAccess(e));
        }
    }
    addAnotherPlan(e) {
        let core = new Core();
        let that = this;
        let button = e.currentTarget;
        let userPK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let instInput = document.getElementById("institutionNameSearch");
        let rowCount = 0;
        for (let row of rows) {
            rowCount++;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/PDEBureauAccessDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Plan Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindAutocompletes();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + rowCount + "&userPK=" + userPK + "&tableId=" + tableName);
    }
    removeUserAccess(e) {
        let core = new Core();
        let allDeleteElements = [];
        let button = e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0; // this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0; // this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }
            for (let autcompleteElement of autcompleteElements) {
                let element = autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let wasChecked = htmlElement.dataset.waschecked;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") { //no search done
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") { //institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") { //search has been done, and its an admin dropdown value
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else { //picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else { // its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                if (htmlElement.checked || wasChecked == "true") {
                    let saveItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allDeleteElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeletePDEBureauAccessRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }
    removeReadOnlyRow(e) {
        let core = new Core();
        let allDeleteElements = [];
        let button = e.currentTarget;
        let institutionfk = button.dataset.institutionfk;
        let userpk = button.dataset.eddiruserpk;
        let accessTypeCode = button.dataset.accesstypecodefk;
        let parent = button.parentElement.parentElement; //To the TR
        /*parent.parentElement.removeChild(parent);*/ // remove the row from the dom 
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        //create 2 items to be deleted
        let saveItem2 = {
            UserFK: parseInt(userpk),
            AccessCodeFK: 2,
            FRCPPInstitutionFK: parseInt(institutionfk),
            AccessTypeCodeFK: parseInt(accessTypeCode)
        };
        allDeleteElements.push(saveItem2);
        let saveItem3 = {
            UserFK: parseInt(userpk),
            AccessCodeFK: 3,
            FRCPPInstitutionFK: parseInt(institutionfk),
            AccessTypeCodeFK: parseInt(accessTypeCode)
        };
        allDeleteElements.push(saveItem3);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeletePDEBureauAccessRow', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Row removed, Save Successful", 'success', 3000, null);
                parent.parentElement.removeChild(parent);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allDeleteElements));
    }
    saveUserAccess(e) {
        let core = new Core();
        let saveObject;
        let allSaveElements = [];
        let allDeleteElements = [];
        let button = e.currentTarget;
        let edDirUserFK = button.dataset.usereddiruser;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let allElements = row.getElementsByClassName("adminCheckbox");
            let selectElements = row.getElementsByClassName("adminSelect");
            let autcompleteElements = row.getElementsByClassName("institutionAutocomplete");
            let selectElementInstitutions = row.getElementsByClassName("adminSelectInstitution");
            let searchInstPK = 0;
            let selectElementValue = 0; // this is the plan dropdown value.  Ie the accesstype
            let selectElementInstitutionValue = 0; // this is the institution dropdown value.  If this has a value then its institutionpk, otherwise its PDE's institutionpk
            let isSchool = "False";
            let isAdmin = "False";
            let wasSelected = "False";
            let isCATS = "False";
            let inputElements = row.getElementsByClassName("adminInputInstitution");
            let inputElementValue = 0;
            for (let inputElement of inputElements) {
                let element = inputElement;
                inputElementValue = parseInt(element.getAttribute('data-institutionfk'));
            }
            for (let autcompleteElement of autcompleteElements) {
                let element = autcompleteElement;
                searchInstPK = parseInt(element.getAttribute('data-searchinstpk'));
            }
            for (let selectElement of selectElements) {
                let element = selectElement;
                selectElementValue = parseInt(element.value);
                isSchool = element.options[element.selectedIndex].getAttribute('data-isschool');
                isAdmin = element.options[element.selectedIndex].getAttribute('data-isadmin');
                isCATS = element.options[element.selectedIndex].getAttribute('data-iscats');
                wasSelected = element.options[element.selectedIndex].getAttribute('data-wasselected');
            }
            for (let element of allElements) {
                let htmlElement = element;
                let edDirUserPK = parseInt(row.dataset.usereddiruserpk);
                let institutionFK = 0;
                let accessTypeCodeFK = 0;
                let wasChecked = htmlElement.dataset.waschecked;
                if (isSchool === "False" || isSchool === "false") {
                    if (searchInstPK === 0 && wasSelected === "False") { //no search done
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else if (searchInstPK === 0 && wasSelected === "True") { //institution is one not from the selected district
                        institutionFK = inputElementValue;
                    }
                    else if (searchInstPK !== 0 && isAdmin === "True") { //search has been done, and its an admin dropdown value
                        institutionFK = parseInt(row.dataset.leainstitution);
                    }
                    else { //picking a non admin LEA level plantype for the searched for LEA
                        institutionFK = searchInstPK;
                    }
                    accessTypeCodeFK = selectElementValue;
                }
                else if ((isSchool === "True" || isSchool === "true") && (isCATS === "True" || isCATS === "true")) { //its a cats plan
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 223;
                }
                else { // its a school
                    institutionFK = selectElementValue;
                    accessTypeCodeFK = 8;
                }
                if (htmlElement.checked) {
                    let saveItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allSaveElements.push(saveItem);
                }
                if ((htmlElement.checked == false) && wasChecked == "true") {
                    let deleteItem = {
                        UserFK: edDirUserPK,
                        AccessCodeFK: parseInt(htmlElement.value),
                        FRCPPInstitutionFK: institutionFK,
                        AccessTypeCodeFK: accessTypeCodeFK
                    };
                    allDeleteElements.push(deleteItem);
                }
            }
        }
        saveObject = {
            CreateList: allSaveElements,
            DeleteList: allDeleteElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePDEBureauUserAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    autocomplete(inp, arr, row, tableid) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists(e);
            //if (!val) { return false; }
            if (!val) {
                that.resetDropdown(row, tableid);
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y;
            if (x)
                y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x)
                return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length)
                currentFocus = 0;
            if (currentFocus < 0)
                currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    getListOfSchools(e, row, tableid) {
        let core = new Core();
        let dropdown = e.srcElement;
        let parent = dropdown.parentElement;
        let parentid = parent.id;
        let name = encodeURIComponent(dropdown.textContent);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/GetSchoolList', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                let res = JSON.parse(xhr.responseText);
                if (res.success === true) {
                    let tableName = tableid;
                    let table = document.getElementById(tableName);
                    //let selects = table.getElementsByClassName("adminSelect");
                    let selects = table.getElementsByTagName("select");
                    let autoCompletes = table.getElementsByClassName("institutionAutocomplete");
                    let thisSelect = selects[0];
                    let thisAutoComplete;
                    for (let select of selects) {
                        if (select.id === ("'select'" + row)) {
                            thisSelect = select;
                        }
                    }
                    for (let autoComplete of autoCompletes) {
                        if (autoComplete.id === ("'institutionNameSearch'" + row)) {
                            thisAutoComplete = autoComplete;
                        }
                    }
                    let mintoRemove = 0;
                    let maxToRemove = 0;
                    for (let option of thisSelect.options) {
                        let length = thisSelect.options.length;
                        let thisOption = option;
                        let data = thisOption.dataset;
                        data = JSON.parse(JSON.stringify(data));
                        let index = thisOption.index;
                        let isOriginal = data.isoriginal;
                        if (isOriginal === "False") {
                            maxToRemove = length - 1;
                            mintoRemove = index;
                            break;
                        }
                    }
                    if (maxToRemove > 0) {
                        while (mintoRemove < (maxToRemove + 1)) {
                            thisSelect.options.remove(maxToRemove); //want to remove the highest one first
                            maxToRemove--;
                        }
                    }
                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        let t = document.createTextNode(inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                    //now add the cats options
                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        x.setAttribute("data-isCATS", "True");
                        let t = document.createTextNode("CATS-" + inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                    thisAutoComplete.setAttribute("data-searchinstpk", res.searchedInstPK);
                    let options = thisSelect.options;
                    for (let option of options) {
                        if (option.dataset.isadmin == "True") {
                            option.disabled = true;
                        }
                        else {
                            option.disabled = false;
                        }
                    }
                    thisSelect.selectedIndex = -1;
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("name=" + name);
    }
    resetDropdown(row, tableid) {
        let tableName = tableid;
        let table = document.getElementById(tableName);
        let selects = table.getElementsByTagName("select");
        let thisSelect = selects[0];
        for (let select of selects) {
            if (select.id === ("'select'" + row)) {
                thisSelect = select;
            }
        }
        let mintoRemove = 0;
        let maxToRemove = 0;
        for (let option of thisSelect.options) {
            let length = thisSelect.options.length;
            let thisOption = option;
            let data = thisOption.dataset;
            data = JSON.parse(JSON.stringify(data));
            let index = thisOption.index;
            let isOriginal = data.isoriginal;
            if (isOriginal === "False") {
                maxToRemove = length - 1;
                mintoRemove = index;
                break;
            }
        }
        if (maxToRemove > 0) {
            while (mintoRemove < (maxToRemove + 1)) {
                thisSelect.options.remove(maxToRemove); //want to remove the highest one first
                maxToRemove--;
            }
        }
        let options = thisSelect.options;
        for (let option of options) {
            if (option.dataset.isadmin == "True") {
                option.disabled = false;
            }
            else {
                option.disabled = true;
            }
        }
        thisSelect.selectedIndex = -1;
        return false;
    }
}
class ViewPermissions {
    constructor() {
        let core = new Core();
        this.bindLEAAutocompletes();
        this.bindUserAutocompletes();
        let leaSearchButton = document.getElementById("impersonationLEASearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => this.leaSearch());
        let UserSearchButton = document.getElementById("impersonationUserSearchSearch");
        if (UserSearchButton !== null)
            UserSearchButton.addEventListener("click", (e) => this.userSearch());
        core.activateSubMenu("Impersonation");
    }
    bindLEAAutocompletes() {
        let hidden = document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray);
        }
    }
    bindUserAutocompletes() {
        let hidden = document.getElementById("hiddenUserlist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("userAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray);
        }
    }
    leaSearch() {
        let nameElement = document.getElementById("institutionNameSearch");
        let name = document.getElementsByTagName("input")[0].value;
        let url = "/Admin/ViewPermissions/";
        if (name !== "")
            url += "?districtName=" + encodeURIComponent(name);
        window.location.href = url;
    }
    userSearch() {
        let nameElement = document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let userName = document.getElementsByTagName("input")[3].value;
        let userNAme = document.getElementById("userNameSearch");
        let userNAmeValue = userNAme.value;
        let url = "/Admin/ViewPermissions/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;
            window.location.href = url;
        }
    }
    autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists(e);
            if (!val) {
                return false;
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y;
            if (x)
                y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x)
                return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length)
                currentFocus = 0;
            if (currentFocus < 0)
                currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
}
class DeletePermissions {
    constructor() {
        let core = new Core();
        this.bindLEAAutocompletes();
        this.bindUserAutocompletes();
        let leaSearchButton = document.getElementById("impersonationLEASearchSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => this.leaSearch());
        let UserSearchButton = document.getElementById("impersonationUserSearchSearch");
        if (UserSearchButton !== null)
            UserSearchButton.addEventListener("click", (e) => this.userSearch());
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.deleteRow(e));
        core.activateSubMenu("DeletePermissions");
    }
    bindLEAAutocompletes() {
        let hidden = document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray);
        }
    }
    bindUserAutocompletes() {
        let hidden = document.getElementById("hiddenUserlist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("userAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray);
        }
    }
    leaSearch() {
        let nameElement = document.getElementById("institutionNameSearch");
        let name = document.getElementsByTagName("input")[0].value;
        let url = "/Admin/DeletePermissions/";
        if (name !== "")
            url += "?districtName=" + encodeURIComponent(name);
        window.location.href = url;
    }
    userSearch() {
        let nameElement = document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let userName = document.getElementsByTagName("input")[3].value;
        let userNAme = document.getElementById("userNameSearch");
        let userNAmeValue = userNAme.value;
        let url = "/Admin/DeletePermissions/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;
            window.location.href = url;
        }
    }
    autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists(e);
            if (!val) {
                return false;
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y;
            if (x)
                y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x)
                return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length)
                currentFocus = 0;
            if (currentFocus < 0)
                currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    deleteRow(e) {
        let buttonElement = e.srcElement;
        let userpk = parseInt(buttonElement.dataset.eddiruserpk);
        let institutionfk = parseInt(buttonElement.dataset.institutionfk);
        let accesstypecodefk = parseInt(buttonElement.dataset.accesstypecodefk);
        if (userpk != 0) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/DeleteUserAccess', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    //let element = document.getElementById("planOwner");
                    //element.remove();
                    //let modal: Modal = new Modal("deleteOwnerModal", null);
                    //modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The data has been removed.  Please refresh the page if you want to see new data.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("userFK=" + userpk + "&frppInstitutionFK=" + institutionfk + "&accessTypeCodeFK=" + accesstypecodefk);
        }
    }
}
//SpecialEducationAssignLEA
class SpecialEducationAssignLEA {
    constructor() {
        this.allDeleteElements = [];
        this._core = new Core();
        this._core.activateSubMenu("SpecialEducationAssignLEA");
        this.bindAll();
    }
    bindAll() {
        let searchButton = document.getElementById("LEASearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e) => this.search(e));
        let clearButton = document.getElementById("LEASearchClear");
        if (clearButton !== null)
            clearButton.addEventListener("click", (e) => this.clearSearch(e));
        let resourceCheckboxes = document.getElementsByClassName("actionPlanSelectedMeasurableGoals");
        for (let resourceCheckbox of resourceCheckboxes) {
            //let element = <HTMLInputElement>resourceCheckbox;
            resourceCheckbox.addEventListener("change", (e) => this.setDeleteElement(e));
        }
        let saveButtons = document.getElementsByClassName("saveLEA");
        for (let saveButton of saveButtons) {
            //let element = <HTMLInputElement>resourceCheckbox;
            saveButton.addEventListener("click", (e) => this.saveLEA(e));
        }
        let editResourceCancelButton = document.getElementById("editResourceCancel");
        if (editResourceCancelButton !== null)
            editResourceCancelButton.addEventListener("click", (e) => this.editResourceCancel());
        let deleteLEAConfirmButton = document.getElementById("deleteLEAConfirm");
        if (deleteLEAConfirmButton !== null)
            deleteLEAConfirmButton.addEventListener("click", (e) => this.deleteLEAConfirm(deleteLEAConfirmButton));
        let deleteLEACancelButton = document.getElementById("deleteLEACancel");
        if (deleteLEACancelButton !== null)
            deleteLEACancelButton.addEventListener("click", (e) => this.deleteLEACancel());
        let deleteLEAButtons = document.getElementsByClassName("deleteLEAButton");
        for (let deleteLEAButton of deleteLEAButtons) {
            deleteLEAButton.addEventListener("click", (e) => this.deleteLEA(e));
        }
    }
    deleteLEA(e) {
        let button = e.srcElement;
        let originallearningnetwork = button.dataset.originallearningnetwork;
        let inst = button.dataset.inst;
        if ((originallearningnetwork && parseInt(originallearningnetwork) > 0) && (inst && parseInt(inst) > 0)) {
            let modal = new Modal("deleteLEAModal", null);
            let deleteConfirmButton = document.getElementById("deleteLEAConfirm");
            deleteConfirmButton.dataset.originallearningnetwork = originallearningnetwork;
            deleteConfirmButton.dataset.inst = inst;
            modal.show();
        }
    }
    deleteLEAConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let originallearningnetwork = parseInt(confirmButton.dataset.originallearningnetwork);
        let inst = parseInt(confirmButton.dataset.inst);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationFRCPPInstutionDelete', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Delete Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("originalNetwork=" + originallearningnetwork + "&frcppInstitution=" + inst);
    }
    deleteLEACancel() {
        let modal = new Modal("deleteLEAModal", null);
        modal.hide();
    }
    editResourceCancel() {
        let modal = new Modal("editResourceModal", null);
        modal.hide();
    }
    setDeleteElement(e) {
        let element = e.srcElement;
        if (element.checked === false && parseInt(element.dataset.sequencenbr) !== 0) {
            let saveItem = {
                ResourceFK: parseInt(element.dataset.resourcefk),
                PageFK: parseInt(element.dataset.pagefk),
                SequenceNbr: parseInt(element.dataset.sequencenbr),
            };
            this.allDeleteElements.push(saveItem);
        }
    }
    clearSearch(e) {
        Core.createHTMLAlert("alertMessageDiv", "ReloadingPage", 'success', 3000, this._core.pageReload());
    }
    searchName(searchvalue) {
        let searchString = searchvalue.toUpperCase().split(" ");
        let searchRows = document.getElementsByClassName("rowForSearch");
        let iUName = document.getElementById("IUNameInput");
        for (let searchRow of searchRows) {
            let count = 0;
            let element = searchRow;
            let searchRowValue = element.dataset.iuname.toUpperCase();
            for (let search of searchString) {
                if (searchRowValue.includes(search)) {
                    count++;
                }
            }
            if (count === 0) {
                element.style.display = "none";
            }
            if (count !== 0) {
                element.style.display = "block";
            }
        }
        iUName.value = searchvalue;
    }
    search(e) {
        let core = this._core;
        let that = this;
        let iUName = document.getElementById("IUNameInput");
        let iU = document.getElementById("IUSearchDropDown");
        let iUValue = 0;
        let iUNameValue = "";
        if (iU !== null) {
            iUValue = parseInt(iU.value);
        }
        if (iUName !== null) {
            iUNameValue = iUName.value;
        }
        if ((iUName !== null || iUName.value !== "") && (iU !== null && iUValue === 0)) {
            that.searchName(iUNameValue);
        }
        else {
            if (iU !== null) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Admin/SearchByLEA', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        let document = window.document.body;
                        document.innerHTML = xhr.responseText;
                        that.bindAll();
                        that.allDeleteElements = [];
                        if (iUNameValue !== "") {
                            that.searchName(iUNameValue);
                        }
                        Core.createHTMLAlert("alertMessageDiv", "New data loaded", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("iUValue=" + iUValue);
            }
        }
    }
    saveLEA(e) {
        let core = this._core;
        let button = e.srcElement;
        let originallearningnetwork = parseInt(button.dataset.originallearningnetwork);
        let inst = parseInt(button.dataset.inst);
        let thisSelect = (button.dataset.thisselect);
        let select = document.getElementById(thisSelect);
        let selectElement = select;
        let newLearningNetwork = parseInt(selectElement.value);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationFRCPPInstutionAssign', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("originalNetwork=" + originallearningnetwork + "&newNetwork=" + newLearningNetwork + "&frcppInstitution=" + inst);
    }
}
//SpecialEducationAddNewLEA
class SpecialEducationAddNewLEA {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("SpecialEducationAddNewLEA");
        this.bindLEAAutocompletes();
        let UserSaveButton = document.getElementById("AddNewLEASave");
        if (UserSaveButton !== null)
            UserSaveButton.addEventListener("click", (e) => this.saveLEA(e));
    }
    bindLEAAutocompletes() {
        let hidden = document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray);
        }
    }
    userSearch() {
        let nameElement = document.getElementById("impersonationUserSearchSearch");
        let leaName = document.getElementsByTagName("input")[0].value;
        let userName = document.getElementsByTagName("input")[3].value;
        let userNAme = document.getElementById("userNameSearch");
        let userNAmeValue = userNAme.value;
        let url = "/Admin/ImpersonateUser/";
        if (leaName !== "" && userName !== "") {
            url += "?districtName=" + encodeURIComponent(leaName) + "&userName=" + userName;
            window.location.href = url;
        }
    }
    autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists(e);
            if (!val) {
                return false;
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        //that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y;
            if (x)
                y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x)
                return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length)
                currentFocus = 0;
            if (currentFocus < 0)
                currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    saveLEA(e) {
        let core = this._core;
        let institutionNameSearch = document.getElementById("institutionNameSearch");
        let instName = institutionNameSearch.value;
        let select = document.getElementById("IUSearchDropDown");
        let selectElement = select;
        let newLearningNetwork = parseInt(selectElement.value);
        if (instName === "" || newLearningNetwork === 0) {
            Core.createHTMLAlert("alertMessageDiv", "Please enter both values", 'error', 3000, null);
            return false;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationNewLEA', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newNetwork=" + newLearningNetwork + "&institution=" + instName);
    }
}
//UpdatePageInformation
class UpdatePageInformation {
    constructor() {
        this._core = new Core();
        this.bindAll();
    }
    bindAll() {
        let leadInSaveButton = document.getElementById("leadInSave");
        if (leadInSaveButton !== null)
            leadInSaveButton.addEventListener("click", (e) => this.savePlanSection(e));
        //let clearButton = document.getElementById("LEASearchClear");
        //if (clearButton !== null)
        //    clearButton.addEventListener("click", (e: Event) => this.clearSearch(e));
        let pageInstructionSaveButton = document.getElementById("pageInstructionSave");
        if (pageInstructionSaveButton !== null)
            pageInstructionSaveButton.addEventListener("click", (e) => this.savePageInstructions(e));
        let pageSectionSaveButtons = document.getElementsByClassName("savePageSection");
        for (let pageSectionSaveButton of pageSectionSaveButtons) {
            pageSectionSaveButton.addEventListener("click", (e) => this.savePageSection(e));
        }
        let pageSectionLabelSaveButtons = document.getElementsByClassName("pageSectionLabelSave");
        for (let pageSectionLabelSaveButton of pageSectionLabelSaveButtons) {
            pageSectionLabelSaveButton.addEventListener("click", (e) => this.savePageSectionLabel(e));
        }
        let propertyLabelSaveButtons = document.getElementsByClassName("propertyLabelSave");
        for (let propertyLabelSaveButton of propertyLabelSaveButtons) {
            propertyLabelSaveButton.addEventListener("click", (e) => this.savePropertyLabel(e));
        }
        let propertyDescriptionSaveButtons = document.getElementsByClassName("propertyDescriptionSave");
        for (let propertyDescriptionSaveButton of propertyDescriptionSaveButtons) {
            propertyDescriptionSaveButton.addEventListener("click", (e) => this.savePropertyDescription(e));
        }
        let editResourceCancelButton = document.getElementById("editResourceCancel");
        if (editResourceCancelButton !== null)
            editResourceCancelButton.addEventListener("click", (e) => this.editResourceCancel());
    }
    deleteLEA(e) {
        let button = e.srcElement;
        let originallearningnetwork = button.dataset.originallearningnetwork;
        let inst = button.dataset.inst;
        if ((originallearningnetwork && parseInt(originallearningnetwork) > 0) && (inst && parseInt(inst) > 0)) {
            let modal = new Modal("deleteLEAModal", null);
            let deleteConfirmButton = document.getElementById("deleteLEAConfirm");
            deleteConfirmButton.dataset.originallearningnetwork = originallearningnetwork;
            deleteConfirmButton.dataset.inst = inst;
            modal.show();
        }
    }
    deleteLEAConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let originallearningnetwork = parseInt(confirmButton.dataset.originallearningnetwork);
        let inst = parseInt(confirmButton.dataset.inst);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSpecialEducationFRCPPInstutionDelete', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Delete Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("originalNetwork=" + originallearningnetwork + "&frcppInstitution=" + inst);
    }
    deleteLEACancel() {
        let modal = new Modal("deleteLEAModal", null);
        modal.hide();
    }
    editResourceCancel() {
        let modal = new Modal("editResourceModal", null);
        modal.hide();
    }
    savePlanSection(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = document.getElementById("leadInInput");
        let value = inputElement.value;
        let saveObject;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePlanSection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    savePageInstructions(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = document.getElementById("pageInstructionInput");
        let value = inputElement.value;
        let saveObject;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePageInstructions', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    savePageSection(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = document.getElementById("pageSectionInput_" + PKid);
        let value = inputElement.value;
        let saveObject;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePageSection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    savePageSectionLabel(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = document.getElementById("pageSectionLabelInput_" + PKid);
        let value = inputElement.value;
        let saveObject;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePageSection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    savePropertyLabel(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = document.getElementById("propertyLabelInput_" + PKid);
        let value = inputElement.value;
        let saveObject;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePropertyLabel', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    savePropertyDescription(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        //let edDirUserFK = parseInt(button.dataset.eddiruserpk);
        let PKid = button.dataset.pk;
        let inputElement = document.getElementById("propertyDescriptionInput_" + PKid);
        let value = inputElement.value;
        let saveObject;
        saveObject = {
            PK: parseInt(PKid),
            Value: value
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePropertyDescription', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}
//Update All Archive Dates
class UpdateAllArchiveDates {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save(e));
        core.activateSubMenu("UpdateAllArchiveDates");
    }
    save(e) {
        let core = new Core();
        let runSave = true;
        let button = e.currentTarget;
        let selectid = button.dataset.selectid;
        let allSaveElements = [];
        let allSelectElements = [];
        let saveObject;
        let selectElements = document.getElementsByClassName("adminSelect");
        let selectElementValue = -1;
        let startYear = document.getElementById("planYear");
        let startYearFK = parseInt(startYear.value);
        let selectedPhaseCodeValue = -1;
        let element = document.getElementById(selectid);
        if (element !== null) {
            selectElementValue = parseInt(element.value);
            let datepicker = document.getElementById("datePicker");
            let datePickerElement = datepicker;
            let date = datePickerElement.value;
            let utc = new Date(date);
            var archiveDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
            let selectedPhaseCode = document.getElementById("updateArchiveDatesSelectPhase");
            let selectedPhaseCodeElement = selectedPhaseCode;
            if (selectedPhaseCodeElement !== null) {
                selectedPhaseCodeValue = parseInt(selectedPhaseCodeElement.value);
            }
            if (selectElementValue > -1 && selectedPhaseCodeValue > -1 && date !== "" && startYear.value !== "") {
                saveObject = {
                    PlanTypeFK: selectElementValue,
                    PhaseCodeFK: selectedPhaseCodeValue,
                    StartYearFK: startYearFK,
                    ArchiveDate: archiveDate
                };
            }
        }
        if (typeof saveObject === "undefined" || saveObject === null) {
            Core.createHTMLAlert("alertMessageDiv", "Please fill out all fields", 'error', 3000, null);
        }
        else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/SaveAllArchiveDates', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(saveObject));
        }
    }
}
class CATSEmail {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CATSEmail");
        //let leaClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        //if (leaClearButton !== null)
        //    leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());
        let leaSearchButton = document.getElementById("catsEmailSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => this.doSearch(e));
        let emailButtons = document.getElementsByClassName("emailButton");
        for (let emailButton of emailButtons) {
            //let element = <HTMLInputElement>resourceCheckbox;
            emailButton.addEventListener("click", (e) => this.sendEmail(e));
        }
        const instructionalRadioButtons = document.getElementsByClassName("catsSystemProgramDataTOPRadioField");
        for (const instructionalRadioButton of instructionalRadioButtons)
            instructionalRadioButton.addEventListener("change", (e) => this.programAreaOrCluster(e.srcElement));
        const programAreaOrClusterRadio = document.querySelector(".catsSystemProgramDataTOPRadioField:checked");
        if (programAreaOrClusterRadio !== null)
            this.programAreaOrCluster(programAreaOrClusterRadio);
        const clusterProgramAreaSelects = document.getElementsByClassName("catsSystemProgramDataClusterPASelectField");
        for (const clusterProgramAreaSelect of clusterProgramAreaSelects) {
            clusterProgramAreaSelect.addEventListener("change", (e) => this.narrowCIPs(e.srcElement));
            this.narrowCIPs(clusterProgramAreaSelect, false, true);
        }
        let selectAll = document.getElementById("selectAllSchool");
        if (selectAll !== null) {
            selectAll.addEventListener("change", (e) => this.selectAllSchools(e.srcElement));
        }
        this._core.bindChangeLEA();
    }
    programAreaOrCluster(radioElement) {
        if (radioElement && radioElement.checked) {
            const whichOne = radioElement.dataset.lookuplabel;
            const clusterElement = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cluster']");
            const programAreaElement = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='programarea']");
            const cipSelect = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");
            if (whichOne.toLowerCase().indexOf("cluster") >= 0) {
                clusterElement.disabled = false;
                programAreaElement.disabled = true;
            }
            else if (whichOne.toLowerCase().indexOf("program") >= 0) {
                clusterElement.disabled = true;
                programAreaElement.disabled = false;
            }
            cipSelect.disabled = true;
        }
    }
    narrowCIPs(selectElement, resetIndex = true, firstRun = false) {
        const typeOfProgram = selectElement.dataset.typeofprogram;
        const cipSelect = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");
        if (resetIndex)
            cipSelect.selectedIndex = 0;
        const cluster = document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='cluster']");
        const programArea = document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='programarea']");
        if (cluster.selectedIndex >= 0 || programArea.selectedIndex >= 0)
            firstRun = false; //Override this if there is a value selected.
        const allCips = document.querySelectorAll(".catsSystemProgramDataSelectField[data-typeofprogram='cip'] option");
        for (let cip of allCips) {
            let ele = cip;
            if (ele.classList.contains("hide"))
                ele.classList.remove("hide");
        }
        if (typeOfProgram === "cluster") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            }
            else {
                cipSelect.disabled = false;
                const clusterPK = selectElement.value;
                for (let i = 0; i < cipSelect.length; i++) {
                    const option = cipSelect[i];
                    if (option.dataset.cluster !== clusterPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        }
        else if (typeOfProgram === "programarea") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            }
            else {
                cipSelect.disabled = false;
                const clusterPK = selectElement.value;
                for (let i = 0; i < cipSelect.length; i++) {
                    const option = cipSelect[i];
                    if (option.dataset.cluster !== clusterPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        }
        if (!firstRun)
            cipSelect.disabled = false;
    }
    doSearch(e) {
        let typeOfProgramElement = document.querySelector(".catsSystemProgramDataRadioField[data-save='typeofprogram']:checked");
        let typeValue = 0;
        if (typeOfProgramElement !== null)
            typeValue = parseInt(typeOfProgramElement.value);
        let cluster = document.getElementById("CareerCluster");
        let clusterValue = 0;
        if (cluster !== null)
            clusterValue = parseInt(cluster.value);
        let programArea = document.getElementById("ProgramArea");
        let programAreaValue = 0;
        if (programArea !== null)
            programAreaValue = parseInt(programArea.value);
        let cip = document.getElementById("CipCode");
        let cipValue = 0;
        if (cip !== null)
            cipValue = parseInt(cip.value);
        let status = document.getElementById("Status");
        let statusValue = 0;
        if (status !== null)
            statusValue = parseInt(status.value);
        let noTeacher = document.getElementById("noTeacherCheckbox");
        let noTeacherValue = false;
        if (noTeacher.checked)
            noTeacherValue = true;
        let noScope = document.getElementById("noScopeCheckbox");
        let noScopeValue = false;
        if (noScope.checked)
            noScopeValue = true;
        let noStudentCert = document.getElementById("noStudentCertCheckbox");
        let noStudentCertValue = false;
        if (noStudentCert.checked)
            noStudentCertValue = true;
        let noTeacherCert = document.getElementById("noTeacherIndCertCheckbox");
        let noTeacherCertValue = false;
        if (noTeacherCert.checked)
            noTeacherCertValue = true;
        let button = document.getElementsByClassName("button page-nav-button active")[0];
        let pageNumber = 1;
        if (button != null)
            pageNumber = parseInt(button.dataset.pagenumber);
        let url = "/Admin/CatsEmail/";
        url += "?cipCodeFK=" + cipValue + "&typeOfProgram=" + typeValue + "&cluster=" + clusterValue + "&programArea=" + programAreaValue + "&statusPK=" + statusValue + "&noTeacher=" + noTeacherValue + "&noStudentIndCert=" + noStudentCertValue + "&noScope=" + noScopeValue + "&noTeacherIndCert=" + noTeacherCertValue + "&pageNumber=" + pageNumber;
        window.location.href = url;
    }
    selectAllSchools(selectAllCheckbox) {
        let allCheckboxes = document.getElementsByClassName("emailCheckBox");
        let allChecked = false;
        if (selectAllCheckbox.checked) {
            allChecked = true;
        }
        if (allChecked) {
            for (let checkbox of allCheckboxes) {
                let element = checkbox;
                element.checked = true;
            }
        }
        else {
            for (let checkbox of allCheckboxes) {
                let element = checkbox;
                element.checked = false;
            }
        }
    }
    sendEmail(e) {
        let button = e.srcElement;
        let planPK = button.dataset.planpk;
        let href = "mailto:?bcc=";
        let checkboxes = document.getElementsByClassName("emailCheckBox");
        let counter = 0;
        for (let checkbox of checkboxes) {
            let element = checkbox;
            let value = "";
            if (element.checked === true) {
                value = element.dataset.email;
                if (counter > 0) {
                    value = "" + value;
                }
                counter++;
            }
            if (value !== "") {
                href = href + value;
            }
        }
        if (href !== "mailto:?bcc=") {
            document.location.href = href;
        }
        else {
            alert("Please choose someone to email to.");
        }
    }
    static pagingSearch(pageToGoTo) {
        let typeOfProgramElement = document.querySelector(".catsSystemProgramDataRadioField[data-save='typeofprogram']:checked");
        let typeValue = 0;
        if (typeOfProgramElement !== null)
            typeValue = parseInt(typeOfProgramElement.value);
        let cluster = document.getElementById("CareerCluster");
        let clusterValue = 0;
        if (cluster !== null)
            clusterValue = parseInt(cluster.value);
        let programArea = document.getElementById("ProgramArea");
        let programAreaValue = 0;
        if (programArea !== null)
            programAreaValue = parseInt(programArea.value);
        let cip = document.getElementById("CipCode");
        let cipValue = 0;
        if (cip !== null)
            cipValue = parseInt(cip.value);
        let status = document.getElementById("Status");
        let statusValue = 0;
        if (status !== null)
            statusValue = parseInt(status.value);
        let noTeacher = document.getElementById("noTeacherCheckbox");
        let noTeacherValue = false;
        if (noTeacher.checked)
            noTeacherValue = true;
        let noScope = document.getElementById("noScopeCheckbox");
        let noScopeValue = false;
        if (noScope.checked)
            noScopeValue = true;
        let noStudentCert = document.getElementById("noStudentCertCheckbox");
        let noStudentCertValue = false;
        if (noStudentCert.checked)
            noStudentCertValue = true;
        let noTeacherCert = document.getElementById("noTeacherIndCertCheckbox");
        let noTeacherCertValue = false;
        if (noTeacherCert.checked)
            noTeacherCertValue = true;
        let url = "/Admin/CatsEmail/";
        url += "?cipCodeFK=" + cipValue + "&typeOfProgram=" + typeValue + "&cluster=" + clusterValue + "&programArea=" + programAreaValue + "&statusPK=" + statusValue + "&noTeacher=" + noTeacherValue + "&noStudentIndCert=" + noStudentCertValue + "&noScope=" + noScopeValue + "&noTeacherIndCert=" + noTeacherCertValue + "&pageNumber=" + pageToGoTo;
        window.location.href = url;
    }
}
class DeleteCATSProgram {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("DeleteCATSProgram");
        let deleteButtons = document.getElementsByClassName("deleteProgram");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.showDeleteProgram(e));
        }
        let deleteProgramConfirmButton = document.getElementById("deleteProgramConfirm");
        if (deleteProgramConfirmButton !== null)
            deleteProgramConfirmButton.addEventListener("click", (e) => this.deleteProgramConfirm(e));
        let deleteProgramCancelButton = document.getElementById("deleteProgramCancel");
        if (deleteProgramCancelButton !== null)
            deleteProgramCancelButton.addEventListener("click", (e) => this.deleteProgramCancel());
    }
    showDeleteProgram(e) {
        let buttonElement = e.srcElement;
        let programPK = buttonElement.dataset.catsprogrampk;
        let modal = new Modal("deleteProgramModal", null);
        modal.addAttributeToElement("deleteProgramModal", "#deleteProgramConfirm", "catsprogrampk", programPK);
        modal.show();
    }
    deleteProgramConfirm(e) {
        let buttonElement = e.srcElement;
        let catsProgramPK = buttonElement.dataset.catsprogrampk;
        let tr = document.getElementById("tr_" + catsProgramPK);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteProgramByCATSProgramPK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    tr.remove();
                    Core.createHTMLAlert("alertMessageDiv", "Program successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the program. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteProgramModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteProgramModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("cATSProgramPK=" + catsProgramPK);
    }
    deleteProgramCancel() {
        let modal = new Modal("deleteProgramModal", null);
        modal.hide();
    }
}
//CATSAnnualTargets
class CATSAnnualTargets {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CATSAnnualTargets");
        this.bindIndSaveButtons();
    }
    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveAnnualTargets");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e) => this.saveAnnualTargets(e));
        }
    }
    saveAnnualTargets(e) {
        let core = this._core;
        //Core.showLoader();
        let button = e.currentTarget;
        let yearPK = parseInt(button.dataset.yearpk);
        let allSaveElements = [];
        let row = document.getElementById("tr_" + yearPK);
        let allElements = row.getElementsByClassName("annualTargetInput");
        let today = new Date();
        for (let element of allElements) {
            let htmlElement = element;
            let catsAnnualStateTargetPK = parseInt(htmlElement.dataset.statetargetpk);
            let indicator = htmlElement.dataset.indicator;
            let percent = parseFloat(htmlElement.value);
            let saveObject = {
                CATSAnnualStateTargetPK: catsAnnualStateTargetPK,
                PerformanceIndicatorCode: indicator,
                AnnualStateTargetPercent: percent,
                AcademicYearFK: yearPK,
                ModifiedByUserName: "",
                ModifiedDate: today,
                IsActiveInd: true
            };
            allSaveElements.push(saveObject);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveAnnualTargets', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }
}
class ViewDeletedPrograms {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("ViewDeletedPrograms");
    }
}
//CATS Lockout
class CATSLockout {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("CATSLockout");
        this.bindClearExceptions();
        const saveMainPlanButton = document.getElementById("saveMainPlan");
        if (saveMainPlanButton !== null)
            saveMainPlanButton.addEventListener("click", (e) => this.savelockout(e));
        const saveProgramButton = document.getElementById("saveProgram");
        if (saveProgramButton !== null)
            saveProgramButton.addEventListener("click", (e) => this.saveProgramlockout(e));
    }
    bindClearExceptions() {
        const clearConfirmButton = document.getElementById("clearConfirm");
        if (clearConfirmButton !== null)
            clearConfirmButton.addEventListener("click", (e) => this.ClearExceptionsConfirm(e));
        const clearCancelButton = document.getElementById("clearCancel");
        if (clearCancelButton !== null)
            clearCancelButton.addEventListener("click", (e) => this.ClearExceptionsCancel());
        const clearExceptionsButton = document.getElementById("clearExceptionsButton");
        if (clearExceptionsButton !== null)
            clearExceptionsButton.addEventListener("click", (e) => this.showClearExceptions(e));
        const clearProgramExceptionsButton = document.getElementById("clearProgramExceptionsButton");
        if (clearProgramExceptionsButton !== null)
            clearProgramExceptionsButton.addEventListener("click", (e) => this.showClearExceptions(e));
    }
    savelockout(e) {
        let core = this._core;
        //Core.showLoader();
        let button = e.currentTarget;
        let lockoutPK = parseInt(button.dataset.lockoutpk);
        let plantypePK = parseInt(button.dataset.plantypepk);
        let startDatePicker = document.getElementById("startDate_" + lockoutPK);
        let startDatePickerValue = startDatePicker.value;
        let startUTC = new Date(startDatePickerValue);
        let startDate = new Date(startUTC.getTime() + startUTC.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let endDatePicker = document.getElementById("endDate_" + lockoutPK);
        let endDatePickerValue = endDatePicker.value;
        let endUTC = new Date(endDatePickerValue);
        let endDate = new Date(endUTC.getTime() + endUTC.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let saveObject = {
            PlanTypeLockoutPK: lockoutPK,
            PlanTypeFK: plantypePK,
            LockoutStartDate: startDate,
            LockoutEndDate: endDate,
            ModifiedByUserName: "",
            ModifiedDate: startDate, //fix this when it gets to the controller
            IsDeletedInd: false
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCATSLockout', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    saveProgramlockout(e) {
        let core = this._core;
        //Core.showLoader();
        let button = e.currentTarget;
        let lockoutPK = parseInt(button.dataset.lockoutpk);
        //let plantypePK = parseInt(button.dataset.plantypepk);
        let startDatePicker = document.getElementById("startDate_" + lockoutPK);
        let startDatePickerValue = startDatePicker.value;
        let startUTC = new Date(startDatePickerValue);
        let startDate = new Date(startUTC.getTime() + startUTC.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let endDatePicker = document.getElementById("endDate_" + lockoutPK);
        let endDatePickerValue = endDatePicker.value;
        let endUTC = new Date(endDatePickerValue);
        let endDate = new Date(endUTC.getTime() + endUTC.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let saveObject = {
            PlanTypeLockoutPK: lockoutPK,
            PlanTypeFK: 291,
            LockoutStartDate: startDate,
            LockoutEndDate: endDate,
            ModifiedByUserName: "",
            ModifiedDate: startDate, //fix this when it gets to the controller
            IsDeletedInd: false
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCATSLockout', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    showClearExceptions(e) {
        let buttonElement = e.currentTarget;
        let checkboxID = buttonElement.dataset.lockoutid;
        let checkbox = document.getElementById(checkboxID);
        if (checkbox.checked) {
            let modal = new Modal("clearExceptionsModal", null);
            modal.show();
            modal.addAttributeToElement("clearExceptionsModal", "#clearConfirm", "lockoutid", checkboxID);
        }
        else {
            alert("If you want to clear all exceptions check the checkbox.");
        }
    }
    ClearExceptionsCancel() {
        let modal = new Modal("clearExceptionsModal", null);
        modal.hide();
    }
    ClearExceptionsConfirm(e) {
        let button = e.currentTarget;
        let checkboxID = button.dataset.lockoutid;
        let checkbox = document.getElementById(checkboxID);
        let plantypelockoutfk = parseInt(checkbox.dataset.plantypelockout);
        if (checkbox.checked) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Admin/ClearAllExceptionsByPlanTypeLockoutFK', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let modal = new Modal("clearExceptionsModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "All Exceptions have been cleared.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("planTypeLockoutFK=" + plantypelockoutfk);
        }
    }
}
class UpdatePlanTypeFRCPPInstitution {
    constructor() {
        this._core = new Core();
        //this._core.activateSubMenu("DeleteCATSProgram");
        let deleteButtons = document.getElementsByClassName("deletePlanTypeFRCPPInstitution");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.showDeletePlanTypeFRCPPInstitution(e));
        }
        let deleteProgramConfirmButton = document.getElementById("deletePlanTypeFRCPPInstitutionConfirm");
        if (deleteProgramConfirmButton !== null)
            deleteProgramConfirmButton.addEventListener("click", (e) => this.deletePlanTypeFRCPPInstitutionConfirm(e));
        let deleteProgramCancelButton = document.getElementById("deletePlanTypeFRCPPInstitutionCancel");
        if (deleteProgramCancelButton !== null)
            deleteProgramCancelButton.addEventListener("click", (e) => this.deletePlanTypeFRCPPInstitutionCancel());
        let searchButton = document.getElementById("SearchButton");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e) => this.search());
        let saveButton = document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save(e));
    }
    search() {
        let selectElement = document.getElementById("institutionNameSearch");
        let value = parseInt(selectElement.value);
        let url = "/Admin/UpdatePlanTypeFRCPPInstitution/";
        if (value !== 0)
            url += "?fRCPPInstitution=" + value;
        window.location.href = url;
    }
    save(e) {
        let that = this;
        let lea = document.getElementById("hiddenLea");
        let leaValue = parseInt(lea.value);
        let ptfi = document.getElementById("planTypeSearch");
        let ptfiValue = parseInt(ptfi.value);
        let saveObject = {
            PlanTypeFK: ptfiValue,
            FRCPPInstitutionFK: leaValue,
            PlanTypeFRCPPInstitutionRelationTypeCodeFK: 1
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SavePlanTypeFRCPPInstitution', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, that._core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    showDeletePlanTypeFRCPPInstitution(e) {
        let buttonElement = e.srcElement;
        let planTypeFK = buttonElement.dataset.plantypefk;
        let fRCPPInstitutionFK = buttonElement.dataset.frcppinstitutionfk;
        let modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
        modal.addAttributeToElement("deletePlanTypeFRCPPInstitutionModal", "#deletePlanTypeFRCPPInstitutionConfirm", "plantypefk", planTypeFK);
        modal.addAttributeToElement("deletePlanTypeFRCPPInstitutionModal", "#deletePlanTypeFRCPPInstitutionConfirm", "frcppinstitutionfk", fRCPPInstitutionFK);
        modal.show();
    }
    deletePlanTypeFRCPPInstitutionConfirm(e) {
        let that = this;
        let buttonElement = e.srcElement;
        let planTypeFK = parseInt(buttonElement.dataset.plantypefk);
        let fRCPPInstitutionFK = parseInt(buttonElement.dataset.frcppinstitutionfk);
        //let tr = document.getElementById("tr_" + planTypeFK)
        let saveObject = {
            PlanTypeFK: planTypeFK,
            FRCPPInstitutionFK: fRCPPInstitutionFK,
            PlanTypeFRCPPInstitutionRelationTypeCodeFK: 1
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeletePlanTypeFRCPPInstitution', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    //tr.remove();
                    Core.createHTMLAlert("alertMessageDiv", "Program successfully removed.", 'success', 3000, that._core.pageReload(true, null));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the program. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    deletePlanTypeFRCPPInstitutionCancel() {
        let modal = new Modal("deletePlanTypeFRCPPInstitutionModal", null);
        modal.hide();
    }
}
//Create New Plans No Copying Data
class CreateNewPlansNoCopy {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save(e));
        core.activateSubMenu("CreateNewPlansNoCopy");
    }
    save(e) {
        let core = new Core();
        let saveObject;
        let plantype = document.getElementById("createPlanTypeSelect");
        let planTypeFK = parseInt(plantype.value);
        let phase = document.getElementById("createPhaseSelect");
        let phaseFK = parseInt(phase.value);
        let startYear = document.getElementById("startYear");
        let startYearFK = parseInt(startYear.value);
        let endYear = document.getElementById("endYear");
        let endYearFk = parseInt(endYear.value);
        let instCtgyCode = document.getElementById("createCategorySelect");
        let instCtgyCodeFk = instCtgyCode.value;
        let datepicker = document.getElementById("datePicker");
        let datePickerElement = datepicker;
        let date = datePickerElement.value;
        let utc = new Date(date);
        var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        if (planTypeFK > -1) {
            saveObject = {
                PlanTypeFK: planTypeFK,
                PhaseCodeFK: phaseFK,
                StartAcademicYearFK: startYearFK,
                EndAcademicYearFK: endYearFk,
                InstCtgyCode: instCtgyCodeFk,
                DueDate: dueDate
            };
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCreateNewPlansNoCopy', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            let res = JSON.parse(xhr.responseText);
            if (res.success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Total Inst:" + res.savesToBeDone + " Total Saves:" + res.completedSaves, 'success', 10000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}
//Create New Plans for CATS
class CreateNewCATSPlans {
    constructor() {
        let core = new Core();
        let saveButton = document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save(e));
        core.activateSubMenu("CreateNewCATSPlans");
    }
    save(e) {
        let core = new Core();
        let programYear = document.getElementById("programYear");
        let programYearAcademicYearFK = parseInt(programYear.value);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveNewCATSPlans', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            let res = JSON.parse(xhr.responseText);
            if (res.success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Total Inst:" + res.savesToBeDone + " Total Saves:" + res.completedSaves, 'success', 10000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("programYearAcademicYearFK=" + programYearAcademicYearFK);
    }
}
class PlanAccessDetailReport {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("PlanAccessDetailReport");
        let saveButton = document.getElementById("reportButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.runReport(e));
        this._core.bindChangeLEA();
    }
    runReport(e) {
        let button = e.currentTarget;
        let leaFRCPPInstitutionFK = parseInt(button.dataset.leafrcppinstitutionfk);
        let url = "/Reports/PlanAccessDetails" + "?leaFRCPPInstitutionFK=" + leaFRCPPInstitutionFK;
        window.location.href = url;
    }
}
class SchoolExpenditureReport {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("SchoolExpenditureReport");
        let saveButton = document.getElementById("reportButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.runReport(e));
        this._core.bindChangeLEA();
    }
    runReport(e) {
        let academicYearElement = document.getElementById("academicYearFK");
        let academicYearElementValue = parseInt(academicYearElement.value);
        let url = "/Reports/LEASchoolPlanExpenditureTablesExport" + "?academicYearFK=" + academicYearElementValue;
        window.location.href = url;
    }
}
class PlanContactReport {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("PlanContactReport");
        let runReportButton = document.getElementById("runReport");
        if (runReportButton !== null)
            runReportButton.addEventListener("click", (e) => this.runReport(e));
    }
    runReport(e) {
        let academicYearElement = document.getElementById("startAcademicYear");
        let planTypes = document.getElementById("planTypes");
        var hasAcademicYear = false;
        var hasPlanTypes = false;
        let url = "/Admin/PlanContactReportExport/?";
        if (academicYearElement != null && academicYearElement.selectedIndex > 0 && parseInt(academicYearElement.value)) {
            url = url + "startAcademicYearFK" + "=" + parseInt(academicYearElement.value) + "&";
            hasAcademicYear = true;
        }
        if (planTypes != null && planTypes.selectedIndex > 0 && planTypes.value) {
            url = url + "planTypePK" + "=" + parseInt(planTypes.value) + "&";
            hasPlanTypes = true;
        }
        if (hasAcademicYear && hasPlanTypes) {
            window.location.href = url;
        }
        else {
            alert("Please choose a start year and a plan type.");
        }
    }
}
//School Designation
class UpdateSchoolDesignation {
    constructor() {
        let core = new Core();
        this.bindIndSaveButtons();
        let schoolSearchButton = document.getElementById("schoolSearchSearch");
        if (schoolSearchButton !== null)
            schoolSearchButton.addEventListener("click", (e) => this.schoolSearch());
        core.activateSubMenu("UpdateSchoolDesignation");
    }
    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveIndSchoolAccess");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e) => this.saveSchoolDesignation(e));
        }
    }
    schoolSearch() {
        let nameElement = document.getElementById("institutionNameSearch");
        let name = document.getElementsByTagName("input")[0].value;
        let url = "/Admin/UpdateSchoolDesignation/";
        if (name !== "")
            url += "?schoolName=" + name;
        window.location.href = url;
    }
    saveSchoolDesignation(e) {
        let core = new Core();
        //Core.showLoader();
        let button = e.currentTarget;
        let selectid = button.dataset.selectid;
        let elementInstitutionFK = parseInt(button.dataset.institutionfk);
        let saveObject;
        let selectElementValue = -1;
        let element = document.getElementById(selectid);
        if (element !== null) {
            selectElementValue = parseInt(element.value);
            if (selectElementValue > -1) {
                saveObject = {
                    InstitutionFK: elementInstitutionFK,
                    DesignationTypeFK: selectElementValue
                };
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveSchoolDesignation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}
//HomePage
class UpcomingDueDate {
    constructor() {
        this._core = new Core();
        this._core.activateSubMenu("UpcomingDueDate");
        this.bindDeleteButtons();
        this.bindIndSaveButtons();
        let addNewRowButtons = document.getElementsByClassName("addAnotherPost");
        for (let addNewRowButton of addNewRowButtons) {
            addNewRowButton.addEventListener("click", (e) => this.addAnotherPost(e));
        }
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deleteRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.removeRow(e));
        }
    }
    bindIndSaveButtons() {
        let indSaveButtons = document.getElementsByClassName("saveupcomingduedate");
        for (let indSaveButton of indSaveButtons) {
            indSaveButton.addEventListener("click", (e) => this.saveUpcomingDueDate(e));
        }
    }
    saveUpcomingDueDate(e) {
        let core = this._core;
        //Core.showLoader();
        let button = e.currentTarget;
        let upcomingDueDatePK = parseInt(button.dataset.upcomingduedatepk);
        let titleElement = document.getElementById("title_" + upcomingDueDatePK);
        let titleElementValue = titleElement.value;
        let dueDateDatePicker = document.getElementById("dueDate_" + upcomingDueDatePK);
        let dueDatePickerValue = dueDateDatePicker.value;
        let dueDateUTC = new Date(dueDatePickerValue);
        let dueDate = new Date(dueDateUTC.getTime() + dueDateUTC.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let saveObject = {
            UpcomingDueDatePK: upcomingDueDatePK,
            DueDateTitle: titleElementValue,
            DueDate: dueDate,
            IsDeletedInd: false
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveUpcomingDueDate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    addAnotherPost(e) {
        let core = new Core();
        let that = this;
        let button = e.currentTarget;
        button.disabled = true;
        let tableName = button.dataset.tableid;
        let table = document.getElementById(tableName);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/AddUpcomingDueDate', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Due Date Row Added", 'success', 3000, null);
                that.bindDeleteButtons();
                that.bindIndSaveButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send();
    }
    removeRow(e) {
        let core = new Core();
        let button = e.currentTarget;
        let upcomingDueDatePK = parseInt(button.dataset.upcomingduedatepk);
        let parent = button.parentElement.parentElement; //To the TR
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/DeleteUpcomingDueDateByUpcomingDueDatePK', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Row Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("upcomingDueDatePK=" + upcomingDueDatePK);
    }
}
//Create New Plan
class CreateNewPlan {
    constructor() {
        let core = new Core();
        this.bindAutocompletes();
        let saveButton = document.getElementById("saveButton");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save(e));
        core.activateSubMenu("createnewplan");
    }
    bindAutocompletes() {
        let hidden = document.getElementById("hiddenlealist");
        let hiddenValue = hidden.value;
        let thisarray = new Array();
        thisarray = hiddenValue.split(',');
        let autocompletes = document.getElementsByClassName("institutionAutocomplete");
        for (let autocomplete of autocompletes) {
            let ac = autocomplete;
            let row = ac.dataset.rownumber;
            let tableid = ac.dataset.tableid;
            this.autocomplete(autocomplete, thisarray, row, tableid);
        }
    }
    save(e) {
        let core = new Core();
        let saveObject;
        let plantype = document.getElementById("plantypeSelect");
        let planTypeFK = parseInt(plantype.value);
        let lea = document.getElementById("institutionNameSearch");
        let leaString = lea.value;
        let school = document.getElementById("schoolSelect");
        let schoolFK = parseInt(school.value);
        let startYear = document.getElementById("selectStartAcademicYear");
        let startYearFK = parseInt(startYear.value);
        let endYear = document.getElementById("selectEndAcademicYear");
        let endYearFk = parseInt(endYear.value);
        let datepicker = document.getElementById("datePicker");
        let datePickerElement = datepicker;
        let date = datePickerElement.value;
        let utc = new Date(date);
        var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        if (planTypeFK > -1 && startYearFK > -1 && endYearFk > -1) {
            saveObject = {
                PlanTypeFK: planTypeFK,
                LEAName: leaString,
                SchoolFK: schoolFK,
                StartAcademicYearFK: startYearFK,
                EndAcademicYearFK: endYearFk,
                DueDate: dueDate
            };
        }
        else {
            alert("You need to pick a PlanType and a start and end year!");
            return false;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/SaveCreateNewPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Plan Created", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    autocomplete(inp, arr, row, tableid) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        let that = this;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists(e);
            //if (!val) { return false; }
            if (!val) {
                that.resetDropdown(row, tableid);
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists(e);
                        that.getListOfSchools(e, row, tableid);
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            let that = this;
            let x = document.getElementById(this.id + "autocomplete-list");
            let y;
            if (x)
                y = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(y);
            }
            else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (y) {
                        y[currentFocus].click();
                    }
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x)
                return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length)
                currentFocus = 0;
            if (currentFocus < 0)
                currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    getListOfSchools(e, row, tableid) {
        let core = new Core();
        let dropdown = e.target;
        let parent = dropdown.parentElement;
        let parentid = parent.id;
        let name = encodeURIComponent(dropdown.textContent);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/GetSchoolList', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
                let res = JSON.parse(xhr.responseText);
                if (res.success === true) {
                    let thisSelect = document.getElementById("schoolSelect");
                    let mintoRemove = 0;
                    let maxToRemove = 0;
                    for (let option of thisSelect.options) {
                        let length = thisSelect.options.length;
                        let thisOption = option;
                        let data = thisOption.dataset;
                        data = JSON.parse(JSON.stringify(data));
                        let index = thisOption.index;
                        let isOriginal = data.isoriginal;
                        if (isOriginal === "False") {
                            maxToRemove = length - 1;
                            mintoRemove = index;
                            break;
                        }
                    }
                    if (maxToRemove > 0) {
                        while (mintoRemove < (maxToRemove + 1)) {
                            thisSelect.options.remove(maxToRemove); //want to remove the highest one first
                            maxToRemove--;
                        }
                    }
                    for (let inst of res.relatedInstList) {
                        let x = document.createElement("option");
                        x.setAttribute("value", inst.relatedInstitutionPK);
                        x.setAttribute("data-isadmin", "False");
                        x.setAttribute("data-isschool", "True");
                        x.setAttribute("data-wasselected", "False");
                        x.setAttribute("data-isoriginal", "False");
                        let t = document.createTextNode(inst.relatedInstitutionName);
                        x.appendChild(t);
                        thisSelect.appendChild(x);
                    }
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("name=" + name);
    }
    resetDropdown(row, tableid) {
        let tableName = tableid;
        let table = document.getElementById(tableName);
        let selects = table.getElementsByTagName("select");
        let thisSelect = selects[0];
        for (let select of selects) {
            if (select.id === ("'select'" + row)) {
                thisSelect = select;
            }
        }
        let mintoRemove = 0;
        let maxToRemove = 0;
        for (let option of thisSelect.options) {
            let length = thisSelect.options.length;
            let thisOption = option;
            let data = thisOption.dataset;
            data = JSON.parse(JSON.stringify(data));
            let index = thisOption.index;
            let isOriginal = data.isoriginal;
            if (isOriginal === "False") {
                maxToRemove = length - 1;
                mintoRemove = index;
                break;
            }
        }
        if (maxToRemove > 0) {
            while (mintoRemove < (maxToRemove + 1)) {
                thisSelect.options.remove(maxToRemove); //want to remove the highest one first
                maxToRemove--;
            }
        }
        let options = thisSelect.options;
        for (let option of options) {
            if (option.dataset.isadmin == "True") {
                option.disabled = false;
            }
            else {
                option.disabled = true;
            }
        }
        thisSelect.selectedIndex = -1;
        return false;
    }
}
class MyPlans {
    constructor() {
        let core = new Core();
        this.bindLinkButtons();
        let comprehensivePlansTab = document.querySelector(".tablinks.comprehensivePlans");
        if (!comprehensivePlansTab.classList.contains("active") && !comprehensivePlansTab.classList.contains("inactive")) {
            comprehensivePlansTab.addEventListener("click", (e) => this.tabLinks("MyPlans"));
        }
        let schoolPlansTab = document.querySelector(".tablinks.schoolPlans");
        if (!schoolPlansTab.classList.contains("active") && !schoolPlansTab.classList.contains("inactive")) {
            schoolPlansTab.addEventListener("click", (e) => this.tabLinks("SchoolPlans"));
        }
        let myFoldersTab = document.querySelector(".tablinks.myFolders");
        if (!myFoldersTab.classList.contains("active") && !myFoldersTab.classList.contains("inactive")) {
            myFoldersTab.addEventListener("click", (e) => this.tabLinks("MyFolders"));
        }
        let leaClearButton = document.getElementById("clearSearch");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e) => this.clearLEASearchFilter());
        let leaSearchButton = document.getElementById("runSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => Core.PlanSearch(1, "/Home/MyPlans"));
        let leaPlanName = document.getElementById("leaName");
        if (leaPlanName !== null)
            leaPlanName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
                Core.PlanSearch(1, "/Home/MyPlans");
            } });
        let plans = document.getElementsByClassName("plan-box");
        for (let plan of plans) {
            let percent = plan.dataset.percentage;
            let pie = plan.querySelector(".pieChart");
            let stylePercent = percent;
            if (stylePercent === "100")
                stylePercent = "101"; //Hacks
            let oneHundred = "100";
            if (pie != null) {
                pie.style.strokeDasharray = `${stylePercent} ${oneHundred}`;
            }
        }
        let myPlansRenameButtons = document.getElementsByClassName("myPlansRenameFolder");
        for (let renameButton of myPlansRenameButtons) {
            let renameButtonElement = renameButton;
            renameButtonElement.addEventListener("click", (e) => this.showRenameModal(e));
        }
        let myPlansCancelRenameButtons = document.getElementsByClassName("myPlansRenameFolderCancel");
        for (let renameCancelButton of myPlansCancelRenameButtons) {
            let renameCancelButtonElement = renameCancelButton;
            renameCancelButtonElement.addEventListener("click", (e) => this.closeRenameModal());
        }
        let myPlansConfirmRenameButtons = document.getElementsByClassName("myPlansRenameFolderConfirm");
        for (let renameConfirmButton of myPlansConfirmRenameButtons) {
            let renameConfirmButtonElement = renameConfirmButton;
            renameConfirmButtonElement.addEventListener("click", (e) => this.renameFolder(e));
        }
        let myPlansDeleteButtons = document.getElementsByClassName("myPlansDeleteFolder");
        for (let deleteButton of myPlansDeleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteModal(parseInt(e.srcElement.dataset.folderpk)));
        }
        let myPlansCancelDeleteButtons = document.getElementsByClassName("myPlansDeleteFolderCancel");
        for (let deleteCancelButton of myPlansCancelDeleteButtons) {
            let deleteCancelButtonElement = deleteCancelButton;
            deleteCancelButtonElement.addEventListener("click", (e) => this.closeDeleteModal());
        }
        let myPlansConfirmDeleteButtons = document.getElementsByClassName("myPlansDeleteFolderConfirm");
        for (let deleteConfirmButton of myPlansConfirmDeleteButtons) {
            let deleteConfirmButtonElement = deleteConfirmButton;
            deleteConfirmButtonElement.addEventListener("click", (e) => this.deleteFolder(e));
        }
        let showFolderContentsButtons = document.getElementsByClassName("showFolderContents");
        for (let showFolderContentsButton of showFolderContentsButtons) {
            let showFolderContentsButtonElement = showFolderContentsButton;
            ;
            showFolderContentsButtonElement.addEventListener("click", (e) => this.showFolderContents(e));
        }
        let myPlansDeleteFolderPlanFromConfirmButton = document.getElementById("myPlansDeleteFolderPlanFromConfirm");
        if (myPlansDeleteFolderPlanFromConfirmButton !== null)
            myPlansDeleteFolderPlanFromConfirmButton.addEventListener("click", (e) => this.confirmDeletePlanFromFolder(e));
        let myPlansDeleteFolderPlanFromCancelButton = document.getElementById("myPlansDeletePlanFromFolderCancel");
        if (myPlansDeleteFolderPlanFromCancelButton !== null)
            myPlansDeleteFolderPlanFromCancelButton.addEventListener("click", (e) => this.cancelDeletePlanFromFolder(e));
        let individualSaveButtons = document.getElementsByClassName("saveIndDueDates");
        for (let individualSaveButton of individualSaveButtons) {
            individualSaveButton.addEventListener("click", (e) => this.updateMyPlanDueDate(e));
        }
        let schoolPlanSaveToLibraryButton = document.getElementById("schoolPlanSaveToLibrary");
        if (schoolPlanSaveToLibraryButton !== null)
            schoolPlanSaveToLibraryButton.addEventListener("click", (e) => this.showSaveToLibrary());
        let myPlansSchoolPlanCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
        for (let myPlansSchoolPlanCheckbox of myPlansSchoolPlanCheckboxes) {
            let myPlanSchoolPlanCheckboxElement = myPlansSchoolPlanCheckbox;
            myPlanSchoolPlanCheckboxElement.addEventListener("change", (e) => this.schoolPlanCheck(e));
        }
        let createAndSaveButton = document.getElementById("createAndSaveButton");
        createAndSaveButton.addEventListener("click", (e) => this.createAndSave(e));
        let saveToThisFolderButtons = document.getElementsByClassName("saveToThisFolder");
        for (let saveToThisFolderButton of saveToThisFolderButtons) {
            let saveToThisFolderButtonElement = saveToThisFolderButton;
            saveToThisFolderButtonElement.addEventListener("click", (e) => this.saveToThisFolder(e));
        }
        core.bindChangeLEA();
        core.bindDeleteOwner();
    }
    bindFolderContentButtons() {
        let myFolderContentsRenameEditButton = document.getElementById("myFolderContentsRenameEdit");
        if (myFolderContentsRenameEditButton !== null) {
            myFolderContentsRenameEditButton.addEventListener("click", (e) => this.showEditMyFolderContentsRenameFolderName());
        }
        let myFolderContentsRenameSaveButton = document.getElementById("myFolderContentsRenameSave");
        if (myFolderContentsRenameSaveButton !== null) {
            myFolderContentsRenameSaveButton.addEventListener("click", (e) => this.saveMyFolderContentsRenameFolderName(e));
        }
        let myFolderContentsRenameCancelButton = document.getElementById("myFolderContentsRenameCancel");
        if (myFolderContentsRenameCancelButton !== null) {
            myFolderContentsRenameCancelButton.addEventListener("click", (e) => this.cancelMyFolderContentsRenameFolderName());
        }
    }
    updateMyPlanDueDate(e) {
        let button = e.currentTarget;
        let datePickerId = button.dataset.datepickerid;
        let planPK = parseInt(button.dataset.planpk);
        let saveObject;
        let datepicker = document.getElementById(datePickerId);
        //let datepicker = document.querySelector(".adminDatePicker[data-planpkcompletiondate='" + elementPlanPK + "']");
        let datePickerElement = datepicker;
        let date = datePickerElement.value;
        let utc = new Date(date);
        var dueDate = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000); // this converts it back to the day it should be.
        let reportValue = "reportPKList_" + button.dataset.planpk;
        let reportInput = document.getElementById(reportValue);
        let reportString = null;
        if (reportInput !== null) {
            reportString = reportInput.value;
            reportString = reportString.slice(0, reportString.length - 1); //take the last ; out of the list
        }
        saveObject = {
            PlanPK: planPK,
            DueDate: dueDate,
            ReportString: reportString
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Admin/AdminSaveMyPlanDueDate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    schoolPlanCheck(e) {
        let libraryClass = document.getElementsByClassName("library-button");
        for (let lib of libraryClass)
            if (e.srcElement.checked)
                lib.classList.add("show");
    }
    showSaveToLibrary() {
        let selectedValues = [];
        let allCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
        for (let checkbox of allCheckboxes)
            if (checkbox.checked)
                selectedValues.push(checkbox.dataset.planfk);
        let modal = new Modal("addToFolder", null);
        modal.addAttributeToElement("addToFolder", "#createAndSaveButton", "selectedvalues", selectedValues.join(","));
        modal.addAttributeToElement("addToFolder", ".saveToThisFolder", "selectedvalues", selectedValues.join(","));
        modal.show();
    }
    saveToThisFolder(e) {
        let folderPK = e.srcElement.dataset.folderpk;
        let listOfPlans = e.srcElement.dataset.selectedvalues;
        let saveData = {
            "FolderPK": folderPK,
            "PlanList": listOfPlans
        };
        let core = new Core();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/SavePlansToFolder', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Plans added to the existing folder!", 'success', 3000, null);
                let modal = new Modal("addToFolder", null);
                modal.hide();
                let allCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
                for (let checkbox of allCheckboxes) {
                    let checkboxElement = checkbox;
                    checkboxElement.checked = false;
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveData));
    }
    createAndSave(e) {
        let textValueElement = document.getElementById("newFolderName");
        let textValue = textValueElement.value;
        let core = new Core();
        let plansToSave = e.srcElement.dataset.selectedvalues;
        if (textValue === "") {
            Core.createHTMLAlert("alertMessageDiv", "You must enter a value in the folder name to create a new folder.", "warning", 3000, null);
        }
        else {
            let saveData = {
                "FolderName": textValue,
                "PlansToSave": plansToSave
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Home/CreateFolderAndAddPlans', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New folder created and plans added!", 'success', 3000, core.pageReload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
                }
                let modal = new Modal("addToFolder", null);
                modal.hide();
            };
            xhr.send(JSON.stringify(saveData));
        }
    }
    showEditMyFolderContentsRenameFolderName() {
        let editButtonSpan = document.getElementById("editButtonSpan");
        let saveAndCancelButtonsSpan = document.getElementById("saveAndCancelButtonsSpan");
        let editFolderNameSpan = document.getElementById("folderContentsEditfolderNameSpan");
        let displayFolderNameSpan = document.getElementById("folderContentsFolderNameSpan");
        editButtonSpan.classList.remove("show");
        displayFolderNameSpan.classList.remove("show");
        saveAndCancelButtonsSpan.classList.add("show");
        editFolderNameSpan.classList.add("show");
    }
    saveMyFolderContentsRenameFolderName(e) {
        let buttonElement = e.srcElement;
        let folderPK = buttonElement.dataset.folderpk;
        let myFolderNameTextValueElement = document.getElementById("folderContentsEditfolderNameValue");
        let currentValue = myFolderNameTextValueElement.dataset.currentvalue;
        let newValue = myFolderNameTextValueElement.value;
        let core = new Core();
        if (currentValue !== newValue) {
            let saveData = {
                "FolderPK": folderPK,
                "NewValue": newValue
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Home/RenameFolder', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let valueElement = document.getElementById("folderContentsFolderNameSpan");
                    valueElement.textContent = newValue;
                    Core.createHTMLAlert("alertMessageDiv", "The folder was successfully renamed.", 'success', 3000, null);
                    let editButtonSpan = document.getElementById("editButtonSpan");
                    let saveAndCancelButtonsSpan = document.getElementById("saveAndCancelButtonsSpan");
                    let editFolderNameSpan = document.getElementById("folderContentsEditfolderNameSpan");
                    let displayFolderNameSpan = document.getElementById("folderContentsFolderNameSpan");
                    editButtonSpan.classList.add("show");
                    displayFolderNameSpan.classList.add("show");
                    saveAndCancelButtonsSpan.classList.remove("show");
                    editFolderNameSpan.classList.remove("show");
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(saveData));
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", `You need to enter a different name for the folder to rename it.`, 'warning', 3000, null);
        }
    }
    cancelMyFolderContentsRenameFolderName() {
        let editButtonSpan = document.getElementById("editButtonSpan");
        let saveAndCancelButtonsSpan = document.getElementById("saveAndCancelButtonsSpan");
        let editFolderNameSpan = document.getElementById("folderContentsEditfolderNameSpan");
        let displayFolderNameSpan = document.getElementById("folderContentsFolderNameSpan");
        editButtonSpan.classList.add("show");
        displayFolderNameSpan.classList.add("show");
        saveAndCancelButtonsSpan.classList.remove("show");
        editFolderNameSpan.classList.remove("show");
    }
    bindLinkButtons() {
        let rsgElements = document.getElementsByClassName("readySetGo");
        for (let rsg of rsgElements) {
            let element = rsg;
            var planId = element.dataset.planpk;
            rsg.addEventListener("click", (e) => this.redirect(e, "/Ready/ProfilePlanEssentials/"));
        }
        let monitoringElements = document.getElementsByClassName("planMonitoring");
        for (let monitor of monitoringElements) {
            let monitorElement = monitor;
            var planId = monitorElement.dataset.planpk;
            if (!monitorElement.classList.contains("not-available")) {
                monitor.addEventListener("click", (e) => this.redirect(e, "/PlanMonitoring/"));
            }
        }
        let communicationElements = document.getElementsByClassName("planCommunications");
        for (let communication of communicationElements) {
            let communicationElement = communication;
            var planId = communicationElement.dataset.planpk;
            if (!communicationElement.classList.contains("not-available")) {
                communication.addEventListener("click", (e) => this.redirect(e, "/PlanCommunications/PlanCommunications/"));
            }
        }
    }
    confirmDeletePlanFromFolder(e) {
        let parentContainerElement = document.getElementById("myFoldersFolderContents");
        let buttonElement = e.srcElement;
        let folderPK = parentContainerElement.dataset.folderpk;
        let planPK = buttonElement.dataset.planpk;
        let core = new Core();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/DeletePlanFromFolder', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                //Remove from dom.
                let allPlans = document.getElementsByClassName("folderContentPlan");
                for (let plan of allPlans) {
                    let planElement = plan;
                    if (planElement.dataset.planpk === planPK) {
                        planElement.parentNode.removeChild(planElement);
                    }
                }
                let modalId = "myPlansDeletePlanFromFolder";
                let modal = new Modal(modalId, null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The plan was successfully removed from this folder.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error removing plan from folder. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`folderPK=${folderPK}&planPK=${planPK}`);
    }
    cancelDeletePlanFromFolder(e) {
        let modalId = "myPlansDeletePlanFromFolder";
        let modal = new Modal(modalId, null);
        modal.hide();
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deletePlanFromFolder");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.deletePlanFromFolder(e));
        }
    }
    deletePlanFromFolder(e) {
        let buttonElement = e.srcElement;
        let modalId = "myPlansDeletePlanFromFolder";
        let modal = new Modal(modalId, null);
        let planPK = buttonElement.dataset.planpk;
        modal.addAttributeToElement(modalId, "#myPlansDeleteFolderPlanFromConfirm", "planpk", planPK.toString());
        modal.show();
    }
    showFolderContents(e) {
        let thisButton = e.srcElement;
        let folderPK = thisButton.dataset.folderpk;
        let folderListingDivElement = document.getElementById("myFoldersFolderListing");
        let folderContentDivElement = document.getElementById("myFoldersFolderContents");
        let _that = this;
        let core = new Core();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/ShowFolderContents', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.getElementById("myFoldersFolderContents");
                $(element).append(xhr.responseText);
                element.dataset.folderpk = folderPK;
                Core.hideLoader();
                folderListingDivElement.classList.remove("show");
                folderContentDivElement.classList.add("show");
                _that.bindDeleteButtons();
                _that.bindLinkButtons();
                _that.bindFolderContentButtons();
                let plans = document.getElementsByClassName("plan-box");
                for (let plan of plans) {
                    let percent = plan.dataset.percentage;
                    let pie = plan.querySelector(".pieChart");
                    let stylePercent = percent;
                    if (stylePercent === "100")
                        stylePercent = "101"; //Hacks
                    let oneHundred = "100";
                    if (pie != null) {
                        pie.style.strokeDasharray = `${stylePercent} ${oneHundred}`;
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };
        xhr.send("folderPK=" + folderPK);
    }
    showRenameModal(e) {
        let folderPK = e.srcElement.dataset.folderpk;
        let currentValue = e.srcElement.dataset.currentvalue;
        let modalId = "myPlansRename";
        let modal = new Modal(modalId, null);
        modal.addAttributeToElement(modalId, ".myPlansRenameFolderConfirm", "folderpk", folderPK);
        modal.addAttributeToElement(modalId, ".myPlansRenameFolderName", "value", currentValue, true);
        modal.addAttributeToElement(modalId, ".myPlansRenameFolderName", "oldvalue", currentValue);
        modal.show();
    }
    closeRenameModal() {
        let close = document.getElementById("closeModalmyPlansRename");
        close.click();
    }
    renameFolder(e) {
        let core = new Core();
        let folderPK = parseInt(e.srcElement.dataset.folderpk);
        let textboxId = e.srcElement.dataset.textboxid;
        let textbox = document.getElementById(textboxId);
        let newValue = textbox.value;
        let oldValue = textbox.dataset.oldvalue;
        let saveData = {
            "FolderPK": folderPK,
            "NewValue": newValue
        };
        if (newValue === oldValue) {
            Core.createHTMLAlert("alertMessageDiv", "You must change the current value in order to rename the folder.", "warning", 3000, null);
        }
        else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Home/RenameFolder', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(saveData));
        }
    }
    showDeleteModal(folderPK) {
        let modalId = "myPlansDelete";
        let modal = new Modal(modalId, null);
        modal.addAttributeToElement(modalId, ".myPlansDeleteFolderConfirm", "folderpk", folderPK.toString());
        modal.show();
    }
    closeDeleteModal() {
        let close = document.getElementById("closeModalmyPlansDelete");
        close.click();
    }
    deleteFolder(e) {
        let core = new Core();
        let folderPK = parseInt(e.srcElement.dataset.folderpk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/DeleteFolder', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload());
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };
        xhr.send("folderPK=" + folderPK);
    }
    clearLEASearchFilter() {
        //TODO: Turn this into a partial view, instead of a reload.
        window.location.href = "/Home/MyPlans";
    }
    tabLinks(link) {
        let url = "/Home/" + link;
        window.location.href = url;
    }
    redirect(e, url) {
        let element = e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;
        window.location.href = newURL;
    }
}
class SchoolPlans {
    constructor() {
        let core = new Core();
        let rsgElements = document.getElementsByClassName("readySetGo");
        for (let rsg of rsgElements) {
            let element = rsg;
            var planId = element.dataset.planpk;
            rsg.addEventListener("click", (e) => this.redirect(e, "/Ready/ProfilePlanEssentials/"));
        }
        let comprehensivePlansTab = document.querySelector(".tablinks.comprehensivePlans");
        if (!comprehensivePlansTab.classList.contains("active") && !comprehensivePlansTab.classList.contains("inactive")) {
            comprehensivePlansTab.addEventListener("click", (e) => this.tabLinks("MyPlans"));
        }
        let schoolPlansTab = document.querySelector(".tablinks.schoolPlans");
        if (!schoolPlansTab.classList.contains("active") && !schoolPlansTab.classList.contains("inactive")) {
            schoolPlansTab.addEventListener("click", (e) => this.tabLinks("SchoolPlans"));
        }
        let myFoldersTab = document.querySelector(".tablinks.myFolders");
        if (!myFoldersTab.classList.contains("active") && !myFoldersTab.classList.contains("inactive")) {
            myFoldersTab.addEventListener("click", (e) => this.tabLinks("MyFolders"));
        }
        let schoolClearButton = document.getElementById("clearSearch");
        schoolClearButton.addEventListener("click", (e) => this.clearSchoolSearchFilter());
        let schoolSearchButton = document.getElementById("runSearch");
        schoolSearchButton.addEventListener("click", (e) => Core.PlanSearch(1, "/Home/SchoolPlans"));
        let schoolPlanName = document.getElementById("schoolName");
        schoolPlanName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
            Core.PlanSearch(1, "/Home/SchoolPlans");
        } });
        let schoolPlanLEAName = document.getElementById("leaName");
        if (schoolPlanLEAName != null) {
            schoolPlanLEAName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
                Core.PlanSearch(1, "/Home/SchoolPlans");
            } });
        }
        let plans = document.getElementsByClassName("plan-box");
        for (let plan of plans) {
            let percent = plan.dataset.percentage;
            let pie = plan.querySelector(".pieChart");
            let stylePercent = percent;
            if (stylePercent === "100")
                stylePercent = "101"; //Hacks
            let oneHundred = "100";
            if (pie != null) {
                pie.style.strokeDasharray = `${stylePercent} ${oneHundred}`;
            }
        }
        let schoolPlanSaveToLibraryButton = document.getElementById("schoolPlanSaveToLibrary");
        if (schoolPlanSaveToLibraryButton !== null)
            schoolPlanSaveToLibraryButton.addEventListener("click", (e) => this.showSaveToLibrary());
        let myPlansSchoolPlanCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
        for (let myPlansSchoolPlanCheckbox of myPlansSchoolPlanCheckboxes) {
            let myPlanSchoolPlanCheckboxElement = myPlansSchoolPlanCheckbox;
            myPlanSchoolPlanCheckboxElement.addEventListener("change", (e) => this.schoolPlanCheck(e));
        }
        let createAndSaveButton = document.getElementById("createAndSaveButton");
        createAndSaveButton.addEventListener("click", (e) => this.createAndSave(e));
        let saveToThisFolderButtons = document.getElementsByClassName("saveToThisFolder");
        for (let saveToThisFolderButton of saveToThisFolderButtons) {
            let saveToThisFolderButtonElement = saveToThisFolderButton;
            saveToThisFolderButtonElement.addEventListener("click", (e) => this.saveToThisFolder(e));
        }
        let monitoringElements = document.getElementsByClassName("planMonitoring");
        for (let monitor of monitoringElements) {
            let monitorElement = monitor;
            var planId = monitorElement.dataset.planpk;
            if (!monitorElement.classList.contains("not-available")) {
                monitor.addEventListener("click", (e) => this.redirect(e, "/PlanMonitoring/"));
            }
        }
        let communicationElements = document.getElementsByClassName("planCommunications");
        for (let communication of communicationElements) {
            let communicationElement = communication;
            var planId = communicationElement.dataset.planpk;
            if (!communicationElement.classList.contains("not-available")) {
                communication.addEventListener("click", (e) => this.redirect(e, "/PlanCommunications/PlanCommunications/"));
            }
        }
        let individualSaveButtons = document.getElementsByClassName("saveIndDueDates");
        for (let individualSaveButton of individualSaveButtons) {
            individualSaveButton.addEventListener("click", (e) => core.updatePlanDueDate(e));
        }
        core.bindChangeLEA();
        core.bindDeleteOwner();
    }
    schoolPlanCheck(e) {
        let libraryClass = document.getElementsByClassName("library-button");
        for (let lib of libraryClass)
            if (e.srcElement.checked)
                lib.classList.add("show");
    }
    showSaveToLibrary() {
        let selectedValues = [];
        let allCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
        for (let checkbox of allCheckboxes)
            if (checkbox.checked)
                selectedValues.push(checkbox.dataset.planfk);
        let modal = new Modal("addToFolder", null);
        modal.addAttributeToElement("addToFolder", "#createAndSaveButton", "selectedvalues", selectedValues.join(","));
        modal.addAttributeToElement("addToFolder", ".saveToThisFolder", "selectedvalues", selectedValues.join(","));
        modal.show();
    }
    saveToThisFolder(e) {
        let folderPK = e.srcElement.dataset.folderpk;
        let listOfPlans = e.srcElement.dataset.selectedvalues;
        let saveData = {
            "FolderPK": folderPK,
            "PlanList": listOfPlans
        };
        let core = new Core();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Home/SavePlansToFolder', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Plans added to the existing folder!", 'success', 3000, null);
                let modal = new Modal("addToFolder", null);
                modal.hide();
                let allCheckboxes = document.getElementsByClassName("myFoldersCheckbox");
                for (let checkbox of allCheckboxes) {
                    let checkboxElement = checkbox;
                    checkboxElement.checked = false;
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveData));
    }
    createAndSave(e) {
        let textValueElement = document.getElementById("newFolderName");
        let textValue = textValueElement.value;
        let core = new Core();
        let plansToSave = e.srcElement.dataset.selectedvalues;
        if (textValue === "") {
            Core.createHTMLAlert("alertMessageDiv", "You must enter a value in the folder name to create a new folder.", "warning", 3000, null);
        }
        else {
            let saveData = {
                "FolderName": textValue,
                "PlansToSave": plansToSave
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Home/CreateFolderAndAddPlans', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New folder created and plans added!", 'success', 3000, core.pageReload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${xhr.status}, with message ${xhr.statusText}`, 'error', 3000, null);
                }
                let modal = new Modal("addToFolder", null);
                modal.hide();
            };
            xhr.send(JSON.stringify(saveData));
        }
    }
    tabLinks(link) {
        let url = "/Home/" + link;
        window.location.href = url;
    }
    redirect(e, url) {
        let element = e.srcElement;
        let planPK = element.dataset.planpk;
        let newURL = url + planPK;
        window.location.href = newURL;
    }
    clearSchoolSearchFilter() {
        //TODO: Turn this into a partial view, instead of a reload.
        window.location.href = "/Home/SchoolPlans";
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
class PlanCommunications {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["planCommunicationsField"];
        let saveButton = document.getElementById("savePlanCommunications");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        let printButton = document.getElementById("printPlanCommunications");
        if (printButton !== null)
            printButton.addEventListener("click", (e) => this.print());
        let addCommunicationPlanButton = document.getElementById("addCommunicationPlan");
        if (addCommunicationPlanButton !== null)
            addCommunicationPlanButton.addEventListener("click", (e) => this.addPlanCommunication());
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.leftnav(this);
        this.bindDeleteButtons();
        this.bindModalButtons();
        this._core.activateSubMenu("subNavPlanCommunications");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    print() {
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formData = document.getElementById("planCommunicationsData");
        let planPK = parseInt(formData.dataset.planpk);
        let allElements = document.getElementsByClassName("planCommunicationsField");
        for (let ele of allElements) {
            let element = ele;
            let planPropertyPK = parseInt(element.dataset.planpropertypk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let value = element.value;
            let row = parseInt(element.dataset.row);
            if (element.value !== "") {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: row,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanCommunications/SavePlanCommunications', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("deletePlanCommunication");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.showDeletePlanCommunicationNotification(e));
        }
    }
    bindModalButtons() {
        let planCommunicationDeletePlanCommunicationCancelButton = document.getElementById("planCommunicationDeletePlanCommunicationCancel");
        if (planCommunicationDeletePlanCommunicationCancelButton !== null) {
            planCommunicationDeletePlanCommunicationCancelButton.addEventListener("click", (e) => this.cancelDeletePlanCommunicationNotification());
        }
        let planCommunicationDeletePlanCommunicationConfirmButton = document.getElementById("planCommunicationDeletePlanCommunicationConfirm");
        if (planCommunicationDeletePlanCommunicationConfirmButton !== null) {
            planCommunicationDeletePlanCommunicationConfirmButton.addEventListener("click", (e) => this.deletePlanCommunication());
        }
    }
    showDeletePlanCommunicationNotification(e) {
        let modal = new Modal("planCommunicationDeletePlanCommunication", null);
        let buttonElement = e.srcElement;
        let row = buttonElement.dataset.row;
        modal.addAttributeToElement("planCommunicationDeletePlanCommunication", "#planCommunicationDeletePlanCommunicationConfirm", "row", row);
        modal.show();
    }
    cancelDeletePlanCommunicationNotification() {
        let modal = new Modal("planCommunicationDeletePlanCommunication", null);
        modal.hide();
    }
    deletePlanCommunication() {
        let deleteButtonElement = document.getElementById("planCommunicationDeletePlanCommunicationConfirm");
        let row = deleteButtonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("planCommunicationsData");
        let planPK = parseInt(formData.dataset.planpk);
        Core.showLoader();
        let communicationFields = document.getElementsByClassName("planCommunicationsField");
        for (let comm of communicationFields) {
            let inputElement = comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanCommunications/DeleteCommunicationPlanRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let allTheRows = document.getElementsByClassName("planCommunicationRow");
                for (let thisRow of allTheRows) {
                    let thisRowElement = thisRow;
                    if (thisRowElement.dataset.row === row) {
                        thisRowElement.parentNode.removeChild(thisRowElement);
                    }
                }
                Core.createHTMLAlert("alertMessageDiv", "This communication plan has been removed.", 'success', 3000, core.pageReload(false, planPK));
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    addPlanCommunication() {
        let core = this._core;
        let newRow = 0;
        let _that = this;
        let communicationFields = document.getElementsByClassName("planCommunicationsField");
        for (let comm of communicationFields) {
            let commElement = comm;
            if (newRow < parseInt(commElement.dataset.row))
                newRow = parseInt(commElement.dataset.row);
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanCommunications/AddCommunicationPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#planCommunications > div > div").append(xhr.responseText);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Plan Communication added", 'success', 3000, null);
                _that.bindDeleteButtons();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newRow=" + newRow);
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// Chapter 16 Profile
class Chapter16Profile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["chapter16ProfileField"];
        let chapter16ProfileSaveButton = document.getElementById("chapter16ProfileSave");
        if (chapter16ProfileSaveButton !== null)
            chapter16ProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("chapter16ProfileForm");
        let inputs = document.getElementsByClassName("chapter16ProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let value = element.value;
            if (element.classList.contains("omitCheckbox")) {
                if (element.checked) {
                    value = "on";
                }
                else {
                    value = "off";
                }
            }
            if (value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false,
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter16Report/SaveChapter16Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Gifted Education Assurance
class GiftedEducationAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["giftedEducationAssuranceField"];
        let chapter16SaveButton = document.getElementById("chapter16GiftedEducationAssuranceSave");
        if (chapter16SaveButton !== null)
            chapter16SaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fileUploader = document.getElementById("chapter16DocumentsToSupportGiftedServices");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const allSaveElements = [];
        const allFileElements = [];
        let planPK = 0;
        let formElement = document.getElementById("giftedEducationAssuranceForm");
        planPK = parseInt(formElement.dataset.planfk);
        let inputs = document.getElementsByClassName("giftedEducationAssuranceField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const files = document.getElementsByClassName("giftedEducationAssuranceFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const saveData = {
            "PlanFK": planPK,
            "Elements": allSaveElements,
            "Files": []
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter16Report/SaveGiftedEducationAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = document.getElementById(e.dataset.formname);
        let formData = new FormData(uploadMinutesForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter16Report/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("chapter16DocumentsToSupportGiftedServices");
                        formFile.value = "";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter16Report/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.giftedEducationAssuranceFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                const modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                const modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// Assurance and Signature
class Chapter16SignaturesAndQualityAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["chapter16SignaturesAndQualityAssuranceField"];
        let chapter16SignaturesAndQualityAssuranceSaveButton = document.getElementById("chapter16SignaturesAndQualityAssuranceSave");
        if (chapter16SignaturesAndQualityAssuranceSaveButton !== null)
            chapter16SignaturesAndQualityAssuranceSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fileUploader = document.getElementById("chapter16signaturesminutes");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        }
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = document.getElementById("uploadMinutes");
        let formData = new FormData(uploadMinutesForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter16Report/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("chapter16signaturesminutes");
                        formFile.value = "";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("chapter16SignaturesAndQualityAssuranceForm");
        let inputs = document.getElementsByClassName("chapter16SignaturesAndQualityAssuranceField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter16Report/SaveSignaturesAndQualityAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Curriculum Summary Checklist and Submission
class Chapter16SummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Chapter16Report/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// Chapter 12 Profile
class Chapter12Profile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["chapter12ProfileField"];
        let chapter12ProfileSaveButton = document.getElementById("chapter12ProfileSave");
        if (chapter12ProfileSaveButton !== null)
            chapter12ProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("chapter12ProfileForm");
        let inputs = document.getElementsByClassName("chapter12ProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter12Report/SaveChapter12Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Student Services Assurances
class StudentServicesAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["studentServicesAssuranceField"];
        let studentServicesSaveButton = document.getElementById("chapter12StudentServicesAssuranceSave");
        if (studentServicesSaveButton !== null)
            studentServicesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let checkboxes = document.getElementsByClassName("studentServicesAssuranceCheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e) => this.checkForReadonly(e));
    }
    checkForReadonly(e) {
        let checkbox = e.srcElement;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let formElement = document.getElementById("studentServicesAssuranceForm");
        planPK = parseInt(formElement.dataset.planfk);
        let inputs = document.getElementsByClassName("studentServicesAssuranceField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let value;
            if (element.classList.contains("studentServicesAssuranceNonCheckbox")) {
                value = element.value;
            }
            else {
                if (element.checked === true)
                    value = "on";
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter12Report/SaveStudentServicesAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Assurance and Signature
class Chapter12AssuranceAndSignature {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["chapter12AssuranceAndSignatureField"];
        let chapter12AssuranceAndSignatureSaveButton = document.getElementById("chapter12AssuranceAndSignatureSave");
        if (chapter12AssuranceAndSignatureSaveButton !== null)
            chapter12AssuranceAndSignatureSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("chapter12AssuranceAndSignatureForm");
        let inputs = document.getElementsByClassName("chapter12AssuranceAndSignatureField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Chapter12Report/SaveAssuranceAndSignature', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Curriculum Summary Checklist and Submission
class Chapter12SummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Chapter12Report/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// Profile and Plan Essentials
class ProfessionalDevelopmentProfileAndPlanEssentials {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["act48ProfileField"];
        let act48ProfileSaveButton = document.getElementById("act48ProfileSave");
        if (act48ProfileSaveButton !== null)
            act48ProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        const profileFormElement = document.getElementById("act48ProfileForm");
        planPK = parseInt(profileFormElement.dataset.planfk);
        const templateCode = profileFormElement.dataset.templatecode;
        const inputs = document.getElementsByClassName("act48ProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false,
                };
                allSaveElements.push(saveItem);
            }
        }
        const allData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK,
            "TemplateCode": templateCode
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveAct48Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) { //This is still the valid check, as long as allSaveElements has data in it, we can save, if not, no need.
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
}
//Steering Committee
class ProfessionalDevelopmentSteeringCommittee {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["steeringCommitteeField"];
        this.bindDeleteRowButtons();
        let numberTextBox = document.getElementById("steeringCommitteeAddRowsNumber");
        if (numberTextBox !== null)
            numberTextBox.addEventListener("change", (e) => this.checkForPlural());
        let deleteSteeringCommitteeConfirmButton = document.getElementById("pdReportSteeringCommitteeDeleteConfirm");
        if (deleteSteeringCommitteeConfirmButton !== null)
            deleteSteeringCommitteeConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteSteeringCommitteeCancelButton = document.getElementById("pdReportSteeringCommitteeDeleteCancel");
        if (deleteSteeringCommitteeCancelButton !== null)
            deleteSteeringCommitteeCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        let steeringCommitteeAddRowsButton = document.getElementById("steeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e) => this.addNewSteeringCommitteeRow());
        let steeringCommitteeSave = document.getElementById("saveCommitteeMembers");
        if (steeringCommitteeSave !== null)
            steeringCommitteeSave.addEventListener("click", (e) => this.save("save"));
        let steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("change", (e) => this.checkForPlural());
        let steeringCommitteeExportExcel = document.getElementById("exportExcelCommitteeMembers");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e) => this.exportExcel());
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        let allSaveElements = [];
        let planPK = 0;
        let inputs = document.getElementsByClassName("steeringCommitteeField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeTable.dataset.planpk);
            if (ele instanceof HTMLSelectElement) {
                let element = ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                let lookupCodeFK = null;
                let textValue = null;
                if (ele.dataset.propertycode == "pdAct48ReportSteeringAppointedBy") {
                    lookupCodeFK = parseInt(element.value);
                }
                else if (ele.dataset.propertycode == "pdAct48ReportSteeringCommitteeRole") {
                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    textValue = ele.value;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: textValue,
                        LookupCodeFK: lookupCodeFK,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
            else {
                let element = ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("profDevAct48DeleteSteeringCommitteeRow", "#pdReportSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("steeringCommittee");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();
        let committeeFields = document.getElementsByClassName("steeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }
        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);
                    let modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        }
        else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }
                    let modal = new Modal("profDevAct48DeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    addNewSteeringCommitteeRow() {
        let core = this._core;
        let newRow = 0;
        let _that = this;
        let formData = document.getElementById("steeringCommittee");
        let planFK = parseInt(formData.dataset.planpk);
        let numberOfRowsElement = document.getElementById("steeringCommitteeAddRowsNumber");
        let numberOfRows = parseInt(numberOfRowsElement.value);
        if (numberOfRows !== null && numberOfRows > 0) {
            let communicationFields = document.getElementsByClassName("steeringCommitteeField");
            for (let comm of communicationFields) {
                let commElement = comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }
            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddSteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteRowButtons();
                        numberOfRowsElement.value = "";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planFK=" + planFK);
            }
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
    }
    checkForPlural() {
        let word = document.getElementById("rowWord");
        let num = document.getElementById("steeringCommitteeAddRowsNumber");
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }
        word.textContent = newWord;
    }
    exportExcel() {
        let core = this._core;
        Core.showLoader();
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let planPK = 0;
        planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        window.open("/ExportExcel/PDSteeringCommitteeDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    initializeRequiredFieldsCustom() {
        let steeringCommitteeTableRows = document.querySelectorAll("tr.steeringCommitteeRow[data-row]");
        for (let row of steeringCommitteeTableRows) {
            let fields = row.getElementsByClassName("steeringCommitteeField");
            for (let element of fields) {
                if (element instanceof HTMLSelectElement) {
                    if (element.selectedIndex > 0) {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    if (element.value != "") {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
}
//Professional Education Plan
class ProfessionalDevelopmentProfessionalEducationPlan {
    constructor() {
        this._core = new Core();
        this.structuredLiteracyImplementation();
        this.validationClasses = ["professionalDevelopmentEdPlanField", "professionalDevelopmentEdPlanTextField", "professionalDevelopmentEdPlanSelectField"];
        let saveButton = document.getElementById("saveProfessionalEducationPlan");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        let exportToWordButton = document.getElementById("professionalEdPlanExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let exportToExcelButton = document.getElementById("professionalEdPlanExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const professionalDevelopmentImplementationFields = document.getElementsByClassName("professionalDevelopmentImplementation");
        for (const professionalDevelopmentImplementationField of professionalDevelopmentImplementationFields)
            professionalDevelopmentImplementationField.addEventListener("change", (e) => this.structuredLiteracyImplementation());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let inputs = document.getElementsByClassName("professionalDevelopmentEdPlanField");
        let professionalEdPlanElement = document.getElementById("professionalEducationPlan");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(professionalEdPlanElement.dataset.planfk);
            let element = ele;
            if (element.checked) {
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: 0,
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const selects = document.getElementsByClassName("professionalDevelopmentEdPlanSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(professionalEdPlanElement.dataset.planfk);
            let element = ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: 0,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let texts = document.getElementsByClassName("professionalDevelopmentEdPlanTextField");
        for (let ele of texts) {
            let planPropertyPK = 0;
            planPK = parseInt(professionalEdPlanElement.dataset.planfk);
            let element = ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalEducationPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    exportToWord() {
        console.log("Export to Word");
    }
    exportToExcel() {
        console.log("Export to Excel");
    }
    structuredLiteracyImplementation() {
        const professionalDevelopmentImplementationFields = document.getElementsByClassName("professionalDevelopmentImplementation");
        const noDisplayDiv = document.getElementById("structuredImplementationNo");
        const noReqElement = document.getElementById("pdAct48V2PDPlanAssuranceDescribeyourreadingcurriculumandincludegradelevels");
        for (const professionalDevelopmentImplementationField of professionalDevelopmentImplementationFields) {
            const element = professionalDevelopmentImplementationField;
            if (element.checked) {
                if (element.dataset.lookupcode === "structuredLiteracyImplemenationNo") {
                    noDisplayDiv.classList.remove("hide");
                    this._core.forceElementRequired(noReqElement);
                }
                else {
                    noDisplayDiv.classList.add("hide");
                    this._core.forceElementOptional(noReqElement);
                }
            }
        }
    }
}
//Action Plans Steps from Comprehensive Plan
class ProfessionalDevelopmentActionPlansStepsFromComprehensivePlan {
    constructor() {
        this._core = new Core();
        let exportToWordButton = document.getElementById("act48professionalDevelopmentExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let exportToExcelButton = document.getElementById("act48professionalDevelopmentExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        let tableExportExcel = document.getElementById("professionalDevelopmentActionStepTableExportExcel");
        if (tableExportExcel !== null)
            tableExportExcel.addEventListener("click", (e) => this.tableExportExcel());
        this._core.leftnav(this);
    }
    getCore() {
        return this._core;
    }
    exportToWord() {
    }
    exportToExcel() {
        let core = this._core;
        Core.showLoader();
        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    tableExportExcel() {
        let core = this._core;
        Core.showLoader();
        let planElement = document.getElementById("professionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/ProfessionalDevelopmentActionStepTableDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
}
//Other Professional Development Activities
class OtherProfessionalDevelopmentActivies {
    constructor() {
        this._core = new Core();
        this.deleteActivityModal = new Modal("deleteProfessionalDevelopmentActivityModal", null);
        this.validationClasses = [
            "professionalDevelopmentField",
            "addDanielsonFrameworkToListItem",
            "professionalDevelopmentLearningFormatStateRequired",
            "professionalDevelopmentFrequencyField"
        ];
        this.hashClasses = [
            "professionalDevelopmentField",
        ];
        let saveButton = document.getElementById("act48OtherProfessionalDevelopmentSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let addMoreRowsButton = document.getElementById("addAct48OtherProfessionalDevelopmentRows");
        if (addMoreRowsButton !== null)
            addMoreRowsButton.addEventListener("click", (e) => this.addMoreProfessionalDevelopmentRows());
        let exportToWordButton = document.getElementById("act48OtherProfessionalDevelopmentExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let exportToExcelButton = document.getElementById("act48OtherProfessionalDevelopmentExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        this.customInitializeRequiredFields();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let that = this;
        let deleteActivityButtons = document.querySelectorAll(".deleteProfessionalDevelopmentActivity");
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteProfessionalDevelopmentActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);
            });
        }
        let deleteActivityConfirmButton = document.getElementById("deleteProfessionalDevelopmentActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteProfessionalDevelopmentActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                }
                else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this professional development activity", "error", 3000, null);
                }
            });
        }
        let deleteActivityCancelButton = document.getElementById("deleteProfessionalDevelopmentActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }
        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();
        let hidden = this._core.createHash(this.hashClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.hashClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //let allSaveElements = [];
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];
        let omit;
        let allElements = document.getElementsByClassName("professionalDevelopmentField");
        for (let element of allElements) {
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let buttonRow = 0;
            let htmlElement;
            let saveItem;
            if (element.classList.contains("actionStepList")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let lookupCode = 0;
                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                }
                else {
                    lookupCode = null;
                }
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let lookupCode = 0;
                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                }
                else {
                    lookupCode = null;
                }
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let value = htmlElement.value;
                if (value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== undefined)
                allPDActivities.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== undefined)
                allLearningFormats.push(saveItem);
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                learningFormatOthers.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== undefined)
                learningFormatStateRequired.push(saveItem);
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== undefined)
                learningFormatFrameworks.push(saveItem);
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== undefined)
                learningFormatFrequency.push(saveItem);
            else if (saveItem !== undefined && !htmlElement.classList.contains("omitCheckbox"))
                allOthers.push(saveItem);
        }
        //Get omit property
        let omitSave = null;
        let omitCheckbox = document.getElementById("omitPageCheckbox");
        if (omitCheckbox) {
            let rowNumber = parseInt(omitCheckbox.dataset.row);
            let planPropertyPK = 0;
            if (omitCheckbox.dataset.planpropertypk && omitCheckbox.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(omitCheckbox.dataset.planpropertypk);
            }
            let value = "";
            if (omitCheckbox.checked) {
                value = "on";
            }
            else {
                value = "off";
            }
            omitSave = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: parseInt(omitCheckbox.dataset.propertypk),
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
            };
        }
        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers,
            "OmitPage": omitSave,
            "PlanFK": planFK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionSteps.length === 0 && saveElement.LearningFormatDanielsonFrameworks.length === 0 && saveElement.LearningFormatFrequency.length === 0 && saveElement.LearningFormatOther.length === 0 && saveElement.LearningFormats.length === 0 && saveElement.LearningFormatStateRequired.length === 0 && saveElement.Others.length === 0 && saveElement.PDActivities.length === 0 && saveElement.OmitPage === null) {
            if (refreshPage && refreshPage !== "")
                window.location.href = refreshPage;
            else
                Core.hideLoader();
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }
    customInitializeRequiredFields() {
        let activities = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let activity of activities) {
            if ("buttonrow" in activity.dataset) {
                let requiredChildren = document.querySelectorAll(`.professionalDevelopmentField[data-buttonrow='${activity.dataset.buttonrow}'][data-percent='1.00']`);
                for (let child of requiredChildren) {
                    let childElement = child;
                    if (activity.value.length > 0) {
                        this._core.forceElementRequired(childElement);
                    }
                    else {
                        this._core.forceElementOptional(childElement);
                    }
                }
            }
        }
    }
    exportToWord() {
    }
    exportToExcel() {
        let core = this._core;
        Core.showLoader();
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        window.open("/ExportExcel/OtherProfessionalDevelopmentDataExport?id=" + planFK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeLearningFormat(e));
            element.addEventListener("click", (e) => this.removeLearningFormat(e));
            element.dataset.listener = "true";
            //}
        }
        this.showHideDeleteActivityButtons();
    }
    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRow");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }
    removeLearningFormat(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let buttonrow = element.dataset.buttonrow;
        let count = 0;
        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormat");
        for (let learningFormat of allLearningFormats) {
            let thisElement = learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteActivityButtons();
        Core.createHTMLAlert("alertMessageDiv", "Learning Format Removed", 'success', 3000, null);
    }
    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeDanielsonFrameworkList(e));
            element.addEventListener("click", (e) => this.removeDanielsonFrameworkList(e));
            element.dataset.listener = "true";
            //}
        }
    }
    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStep");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            //if (element.dataset.listener !== "true") {
            element.removeEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.addEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
            //}
        }
    }
    removeItemFromActionStepList(e) {
        e.stopPropagation();
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let planPropertyPK = element.dataset.planpropertypk;
        let allActionSteps = document.getElementsByClassName("actionStepList");
        for (let actionStep of allActionSteps) {
            let thisElement = actionStep;
            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }
    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormat");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addLearningFormat(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    addLearningFormat(button) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivity");
        let _that = this;
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/AddLearningFormat');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormats[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                Core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
                _that.customInitializeRequiredFields();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }
    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivity");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            element.removeEventListener("click", (e) => this.createNewPDActivity(e.target));
            element.addEventListener("click", (e) => this.createNewPDActivity(e.target));
            element.dataset.listener = "true";
        }
    }
    createNewPDActivity(button) {
        let _that = this;
        let core = this._core;
        let dontDoIt = false;
        let hasName = false;
        let row = button.dataset.buttonrow;
        let textElement = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let tElement of textElement) {
            let properElement = tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
                else {
                    hasName = true;
                }
            }
        }
        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = "professionalDevelopmentActivity" + rowNumber.toString();
            let professionalDevelopmentNameElement = document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordion");
            var thisElement;
            for (let accordion of accordionParent) {
                let acc = accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }
            let theSpan = thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;
            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordion");
            var professionalDevelopmentElement;
            for (let ap of professionalDevelopmentElements) {
                let pde = ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }
            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
            Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Added", 'success', 3000, null);
            this.customInitializeRequiredFields();
        }
        else if (!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }
    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAdd");
        for (let actionStepButton of actionStepButtons) {
            let element = actionStepButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addActionStepToActivity(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    addActionStepToActivity(button) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddActionStep");
        let actionStepPlanPropertyPK;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }
        let isNotInList = true;
        let allListElements = document.getElementsByClassName("actionStepList");
        for (let listElement of allListElements) {
            let element = listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }
        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToList[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added to this activity", 'error', 3000, null);
        }
    }
    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAdd");
        for (let danielsonButton of danielsonButtons) {
            let element = danielsonButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addDanielsonFrameworkToList(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    addDanielsonFrameworkToList(button) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddDanielsonFramework");
        let lookupCodePK;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === buttonrow && selectElement.dataset.row === rowNumber) {
                lookupCodePK = parseInt(selectElement.value);
            }
        }
        let isNotInList = true;
        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let listElement of allListElements) {
            let element = listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }
        //See if user has selected a danielson framework or if it's still set to default value
        let userHasSelected = true;
        if (isNaN(lookupCodePK)) {
            userHasSelected = false;
            Core.createHTMLAlert("alertMessageDiv", "A Danielson Framework must first be selected", 'error', 3000, null);
        }
        if (isNotInList && userHasSelected) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddDanielsonFrameworkToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToList[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "This Danielson Framework item has already been added", 'error', 3000, null);
        }
    }
    removeDanielsonFrameworkList(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let buttonrow = element.dataset.buttonrow;
        let lookupCodePK = element.dataset.lookupcodepk;
        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let lookup of allLookups) {
            let thisElement = lookup;
            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Danielson Framework Removed", 'success', 3000, null);
            }
        }
    }
    addMoreProfessionalDevelopmentRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = "1";
        let howMany = parseInt(howManyElement);
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;
        let activityElements = document.getElementsByClassName("professionalDevelopmentActivity");
        for (let activity of activityElements) {
            let activityElement = activity;
            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }
        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContent").append(xhr.responseText);
                        new CustomAccordion("professionalDevelopmentAccordion" + currentRow.toString());
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }
    deleteProfessionalDevelopmentActivity(activityPlanPropertyPK) {
        let that = this;
        that.deleteActivityModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteProfessionalDevelopmentActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Professional development activity successfully deleted", 'success', 3000, null);
                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }
}
//Evaluation and Review
class EvaluationAndReview {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["evaluationAndReviewField"];
        let evaluationAndReviewSaveButton = document.getElementById("act48EvaluationAndReviewSave");
        if (evaluationAndReviewSaveButton !== null)
            evaluationAndReviewSaveButton.addEventListener("click", (e) => this.save("save"));
        let evaluationAndReviewExportToWordButton = document.getElementById("act48EvaluationAndReviewExportToWord");
        if (evaluationAndReviewExportToWordButton !== null)
            evaluationAndReviewExportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let evaluationAndReviewExportToExcelButton = document.getElementById("act48EvaluationAndReviewExportToExcel");
        if (evaluationAndReviewExportToExcelButton !== null)
            evaluationAndReviewExportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let formElement = document.getElementById("evaluationAndReviewForm");
        planPK = parseInt(formElement.dataset.planfk);
        let inputs = document.getElementsByClassName("evaluationAndReviewField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveEvaluationAndReview', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    exportToWord() {
    }
    exportToExcel() {
    }
}
//Professional Education Plan Assurances
class ProfessionalEducationPlanAssurances {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["professionalEducationPlanAssurancesField"];
        let professionalEducationPlanSaveButton = document.getElementById("act48ProfessionalEducationPlanAssurancesSave");
        if (professionalEducationPlanSaveButton !== null)
            professionalEducationPlanSaveButton.addEventListener("click", (e) => this.save("save"));
        let professionalEducationPlanExportToWordButton = document.getElementById("act48ProfessionalEducationPlanAssurancesExportToWord");
        if (professionalEducationPlanExportToWordButton !== null)
            professionalEducationPlanExportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let fileUploader = document.getElementById("uploadMinutesFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        }
        let professionalEducationExportToExcelButton = document.getElementById("act48ProfessionalEducationPlanAssurancesExportToExcel");
        if (professionalEducationExportToExcelButton !== null)
            professionalEducationExportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = document.getElementById("uploadMinutes");
        let formData = new FormData(uploadMinutesForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("uploadMinutesFile");
                        formFile.value = "";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("professionalEducationPlanAssurancesForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const templateCode = formElement.dataset.templatecode;
        let inputs = document.getElementsByClassName("professionalEducationPlanAssurancesField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        var saveData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK,
            "TemplateCode": templateCode
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SaveProfessionalEducationPlanAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    exportToWord() {
    }
    exportToExcel() {
    }
}
//Curriculum Summary Checklist and Submission
class ProfessionalDevelopmentSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
//V2 report
// PdAct48V2PDActivities
class PDAct48V2PDActivities {
    constructor() {
        this._core = new Core();
        this.deleteActivityModal = new Modal("deleteProfessionalDevelopmentActivityModal", null);
        this.validationClasses = ["pdAct48V2PDActiviesField", "professionalDevelopmentField", "professionalDevelopmentFieldV2"];
        let pdAct48V2PDActiviesSaveButton = document.getElementById("pdAct48V2PDActivitiesSave");
        if (pdAct48V2PDActiviesSaveButton !== null)
            pdAct48V2PDActiviesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addAct48OtherProfessionalDevelopmentV2Rows = document.getElementById("addAct48OtherProfessionalDevelopmentV2Rows");
        if (addAct48OtherProfessionalDevelopmentV2Rows !== null)
            addAct48OtherProfessionalDevelopmentV2Rows.addEventListener("click", (e) => this.addOtherProfessionalDevelopmentRow());
        const that = this;
        const deleteActivityButtons = document.querySelectorAll(".deleteProfessionalDevelopmentActivityV2");
        for (let button of deleteActivityButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.show();
                that.deleteActivityModal.addAttributeToElement(that.deleteActivityModal.id, "#deleteProfessionalDevelopmentActivityConfirm", "activityplanpropertypk", button.dataset.activityplanpropertypk);
            });
        }
        const deleteActivityConfirmButton = document.getElementById("deleteProfessionalDevelopmentActivityConfirm");
        if (deleteActivityConfirmButton !== null) {
            deleteActivityConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if ("activityplanpropertypk" in deleteActivityConfirmButton.dataset && parseInt(deleteActivityConfirmButton.dataset.activityplanpropertypk) > 0) {
                    that.deleteProfessionalDevelopmentActivity(deleteActivityConfirmButton.dataset.activityplanpropertypk);
                }
                else {
                    that.deleteActivityModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this professional development activity", "error", 3000, null);
                }
            });
        }
        const deleteActivityCancelButton = document.getElementById("deleteProfessionalDevelopmentActivityCancel");
        if (deleteActivityCancelButton !== null) {
            deleteActivityCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteActivityModal.hide();
            });
        }
        this.bindCreatePDActivityButtons();
        this.bindPDActionStepAddButtons();
        this.bindDanielsonAddButtons();
        this.bindAddLearningFormatButtons();
        this.bindPDActionStepRemoveButtons();
        this.bindDanielsonListRemove();
        this.bindRemoveLearningFormatButtons();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formElement.dataset.planfk);
        //This save needs to be split into 2 parts: 1 - The comp plan Prof Dev stuff (the top half of the page). 2 - the "other prof dev" stuff (bottom half). Since they get
        //saved in different places, we need to make sure all the data is separate.
        let goSave = false;
        // Part 1.
        let allPDActivities = [];
        let allLearningFormats = [];
        let allActionSteps = [];
        let allOthers = [];
        let learningFormatOthers = [];
        let learningFormatFrameworks = [];
        let learningFormatFrequency = [];
        let learningFormatStateRequired = [];
        let allElements = document.getElementsByClassName("professionalDevelopmentField");
        for (let element of allElements) {
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let buttonRow = 0;
            let htmlElement;
            let saveItem;
            if (element.classList.contains("actionStepList")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else if (element.classList.contains("professionalDevelopmentLearningFormatActivity")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let lookupCode = 0;
                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                }
                else {
                    lookupCode = null;
                }
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("professionalDevelopmentLearningFormatStateRequired")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let lookupCode = 0;
                if (htmlElement.value !== "") {
                    lookupCode = parseInt(htmlElement.value);
                }
                else {
                    lookupCode = null;
                }
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: lookupCode,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("professionalDevelopmentFrequencyField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let toDelete = (htmlElement.value === "");
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("addDanielsonFrameworkToListItem")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: "",
                    LookupCodeFK: parseInt(htmlElement.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else if (element.classList.contains("occupationalCTC")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let value = "";
                if (htmlElement.checked) {
                    value = "on";
                }
                else {
                    value = "off";
                }
                saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: 0,
                    ButtonRow: buttonRow
                };
            }
            else {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let toDelete = (htmlElement.value === "");
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            if (htmlElement.classList.contains("professionalDevelopmentActivity") && saveItem !== undefined) {
                allPDActivities.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatActivity") && saveItem !== undefined) {
                allLearningFormats.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("actionStepList") && saveItem !== undefined) {
                allActionSteps.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined) {
                learningFormatOthers.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("professionalDevelopmentLearningFormatStateRequired") && saveItem !== undefined) {
                learningFormatStateRequired.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("addDanielsonFrameworkToListItem") && saveItem !== undefined) {
                learningFormatFrameworks.push(saveItem);
                goSave = true;
            }
            else if (htmlElement.classList.contains("professionalDevelopmentFrequencyField") && saveItem !== undefined) {
                learningFormatFrequency.push(saveItem);
                goSave = true;
            }
            else {
                if (saveItem !== undefined && !htmlElement.classList.contains("omitCheckbox")) {
                    allOthers.push(saveItem);
                    goSave = true;
                }
            }
        }
        let saveElement = {
            "PDActivities": allPDActivities,
            "LearningFormats": allLearningFormats,
            "ActionSteps": allActionSteps,
            "LearningFormatOther": learningFormatOthers,
            "LearningFormatDanielsonFrameworks": learningFormatFrameworks,
            "LearningFormatStateRequired": learningFormatStateRequired,
            "LearningFormatFrequency": learningFormatFrequency,
            "Others": allOthers
        };
        // Part 2.
        ////Get omit property
        let omitSave = null;
        const omitCheckbox = document.getElementById("pdAct48V2PDActivitiesOtherOmit");
        if (omitCheckbox) {
            let rowNumber = parseInt(omitCheckbox.dataset.row);
            let planPropertyPK = 0;
            if (omitCheckbox.dataset.planpropertypk && omitCheckbox.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(omitCheckbox.dataset.planpropertypk);
            }
            let value = "";
            if (omitCheckbox.checked) {
                value = "on";
            }
            else {
                value = "off";
            }
            omitSave = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planFK,
                PropertyFK: parseInt(omitCheckbox.dataset.propertypk),
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false,
            };
        }
        const totalActivities = [];
        const allActivities = document.querySelectorAll(".professionalDevelopmentActivityV2");
        for (const activity of allActivities) {
            const titleElement = activity;
            let datapointPlanPropertyPK = 0;
            if (titleElement && titleElement.dataset.planpropertypk && titleElement.dataset.planpropertypk !== "")
                datapointPlanPropertyPK = parseInt(titleElement.dataset.planpropertypk);
            const allActivityItems = document.querySelectorAll(`.professionalDevelopmentActivityFieldV2[data-buttonrow='${titleElement.dataset.buttonrow}']`);
            const activities = [];
            let buttonRow = 0;
            const totalLearningData = [];
            for (const inside of allActivityItems) {
                let element = null;
                if (inside.classList.contains("isSelect"))
                    element = inside;
                else
                    element = inside;
                const planPropertyPK = element.dataset.planpropertypk;
                const propertyPK = parseInt(element.dataset.propertypk);
                let textValue = "";
                let lookupCodePK = null;
                if (inside.classList.contains("isSelect")) {
                    textValue = "";
                    lookupCodePK = parseInt(element.value);
                }
                else {
                    textValue = element.value;
                }
                const rowNumber = parseInt(element.dataset.row);
                buttonRow = parseInt(element.dataset.buttonrow);
                const activityItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: lookupCodePK,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: datapointPlanPropertyPK,
                    ButtonRow: buttonRow
                };
                activities.push(activityItem);
            }
            const learningFormatContainer = document.querySelectorAll(`.professionalDevelopmentLearningFormatV2[data-buttonrow='${titleElement.dataset.buttonrow}']`);
            for (const learning of learningFormatContainer) {
                const learnRow = learning.dataset.row;
                const allLearnings = learning.querySelectorAll(`.professionalDevelopmentLearningV2[data-row='${learnRow}']`);
                const allLearningData = [];
                for (const learn of allLearnings) {
                    let learnElement = null;
                    if (learn.classList.contains("isSelect"))
                        learnElement = learn;
                    else
                        learnElement = learn;
                    const planPropertyPK = learnElement.dataset.planpropertypk;
                    const propertyPK = parseInt(learnElement.dataset.propertypk);
                    let textValue = "";
                    let lookupCodePK = null;
                    if (learn.classList.contains("isSelect")) {
                        textValue = "";
                        lookupCodePK = parseInt(learnElement.value);
                    }
                    else {
                        textValue = learnElement.value;
                    }
                    const learnRowNumber = parseInt(learnElement.dataset.row);
                    const learnButtonRow = parseInt(learnElement.dataset.buttonrow);
                    const learnItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: propertyPK,
                        TextValue: textValue,
                        LookupCodeFK: lookupCodePK,
                        RowNbr: learnRowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: datapointPlanPropertyPK,
                        ButtonRow: learnButtonRow,
                    };
                    allLearningData.push(learnItem);
                }
                const learnDanielsons = [];
                const allDanielson = document.querySelectorAll(`.addDanielsonFrameworkToListItemV2[data-buttonrow='${titleElement.dataset.buttonrow}'][data-row='${learnRow}']`);
                for (const danielson of allDanielson) {
                    const danielsonElement = danielson;
                    let danielsonPlanPropertyPK = 0;
                    if (danielsonElement.dataset.planpropertypk && danielsonElement.dataset.planpropertypk !== "")
                        danielsonPlanPropertyPK = parseInt(danielsonElement.dataset.planpropertypk);
                    const danielsonPropertyPK = parseInt(danielsonElement.dataset.propertypk);
                    const danielsonLookupCodePK = parseInt(danielsonElement.dataset.lookupcodepk);
                    const danielsonItem = {
                        PlanPropertyPK: danielsonPlanPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: danielsonPropertyPK,
                        TextValue: "",
                        LookupCodeFK: danielsonLookupCodePK,
                        RowNbr: learnRow,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: datapointPlanPropertyPK,
                        ButtonRow: buttonRow,
                    };
                    learnDanielsons.push(danielsonItem);
                }
                totalLearningData.push({
                    "LearningFormats": allLearningData,
                    "Danielson": learnDanielsons,
                    "DataPointPlanPropertyPK": datapointPlanPropertyPK
                });
            }
            const fullActivity = {
                "Activity": activities,
                "LearningFormat": totalLearningData,
            };
            totalActivities.push(fullActivity);
        }
        var saveData = {
            "CompPlanSaveData": saveElement,
            "OtherSaveData": totalActivities,
            "PlanFK": planFK,
            "OmitData": omitSave
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/SavePDAct48V2PDActivities', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(saveData));
    }
    addOtherProfessionalDevelopmentRow() {
        let core = this._core;
        let _that = this;
        let howManyElement = "1";
        let howMany = parseInt(howManyElement);
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;
        let activityElements = document.getElementsByClassName("professionalDevelopmentActivityV2");
        for (let activity of activityElements) {
            let activityElement = activity;
            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }
        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddNewProfessionalDevelopmentActivityV2');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContentV2").append(xhr.responseText);
                        new CustomAccordion(`professionalDevelopmentAccordion${currentRow.toString()}V2`);
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }
    bindRemoveLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRowV2");
        //let count = 0;
        for (let activityButton of activityButtons) {
            let element = activityButton;
            element.removeEventListener("click", (e) => this.removeLearningFormat(e));
            element.addEventListener("click", (e) => this.removeLearningFormat(e));
            element.dataset.listener = "true";
        }
        this.showHideDeleteActivityButtons();
    }
    showHideDeleteActivityButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentDeleteLearningFormatRowV2");
        let count = 0;
        for (let activityButton of activityButtons) {
            count++;
        }
        if (count > 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:visible;");
                }
            }
        }
        if (count === 1) {
            for (let activityButton of activityButtons) {
                let element = activityButton;
                for (let activityButton of activityButtons) {
                    let element = activityButton;
                    element.setAttribute("style", "visibility:hidden;");
                }
            }
        }
    }
    removeLearningFormat(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let buttonrow = element.dataset.buttonrow;
        let count = 0;
        let allLearningFormats = document.getElementsByClassName("professionalDevelopmentLearningFormatV2");
        for (let learningFormat of allLearningFormats) {
            let thisElement = learningFormat;
            if (thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
        this.showHideDeleteActivityButtons();
        Core.createHTMLAlert("alertMessageDiv", "Learning Format Removed", 'success', 3000, null);
    }
    bindDanielsonListRemove() {
        let activityButtons = document.getElementsByClassName("deletePDDanielsonFrameworkV2");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            element.removeEventListener("click", (e) => this.removeDanielsonFrameworkList(e));
            element.addEventListener("click", (e) => this.removeDanielsonFrameworkList(e));
            element.dataset.listener = "true";
        }
    }
    bindPDActionStepRemoveButtons() {
        let activityButtons = document.getElementsByClassName("deletePDAddedActionStepV2");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            element.removeEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.addEventListener("click", (e) => this.removeItemFromActionStepList(e));
            element.dataset.listener = "true";
        }
    }
    removeItemFromActionStepList(e) {
        e.stopPropagation();
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let planPropertyPK = element.dataset.planpropertypk;
        let allActionSteps = document.getElementsByClassName("actionStepListV2");
        for (let actionStep of allActionSteps) {
            let thisElement = actionStep;
            if (thisElement.dataset.planpropertypk === planPropertyPK && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }
    bindAddLearningFormatButtons() {
        let activityButtons = document.getElementsByClassName("professionalDevelopmentAddLearningFormatV2");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addLearningFormat(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    addLearningFormat(button) {
        let core = this._core;
        let buttonRow = parseInt(button.dataset.buttonrow);
        let newRow = 0;
        let learningFormatList = document.getElementsByClassName("professionalDevelopmentLearningFormatActivityV2");
        let _that = this;
        let formElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = formElement.dataset.planfk;
        for (let activity of learningFormatList) {
            let element = activity;
            if (buttonRow === parseInt(element.dataset.buttonrow)) {
                if (newRow < parseInt(element.dataset.row)) {
                    newRow = parseInt(element.dataset.row);
                }
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ProfessionalDevelopmentReport/AddLearningFormatV2');
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".learningFormatsV2[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                _that.bindDanielsonAddButtons();
                _that.bindRemoveLearningFormatButtons();
                Core.createHTMLAlert("alertMessageDiv", "New Learning Format Row Added", 'success', 3000, null);
                //                _that.customInitializeRequiredFields();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("rowNumber=" + newRow + "&buttonRow=" + buttonRow + "&planFK=" + planFK);
    }
    bindCreatePDActivityButtons() {
        let activityButtons = document.getElementsByClassName("createProfessionalDevelopmentActivityV2");
        for (let activityButton of activityButtons) {
            let element = activityButton;
            element.removeEventListener("click", (e) => this.createNewPDActivity(e.target));
            element.addEventListener("click", (e) => this.createNewPDActivity(e.target));
            element.dataset.listener = "true";
        }
    }
    createNewPDActivity(button) {
        let dontDoIt = false;
        let hasName = false;
        const row = button.dataset.buttonrow;
        const textElement = document.getElementsByClassName("professionalDevelopmentActivityV2");
        for (let tElement of textElement) {
            let properElement = tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                }
                else {
                    hasName = true;
                }
            }
        }
        if (!dontDoIt) {
            let rowNumber = button.dataset.buttonrow;
            let textBoxId = `professionalDevelopmentActivity${rowNumber.toString()}V2`;
            let professionalDevelopmentNameElement = document.getElementById(textBoxId);
            let newAccordionTitle = "" + professionalDevelopmentNameElement.value;
            let accordionParent = document.getElementsByClassName("professionalDevelopmentAccordionV2");
            var thisElement;
            for (let accordion of accordionParent) {
                let acc = accordion;
                if (acc.dataset.buttonrow == rowNumber) {
                    thisElement = acc;
                }
            }
            let theSpan = thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            professionalDevelopmentNameElement.readOnly = true;
            //Now show the accordion.
            let professionalDevelopmentElements = document.getElementsByClassName("professionalDevelopmentAccordionV2");
            var professionalDevelopmentElement;
            for (let ap of professionalDevelopmentElements) {
                let pde = ap;
                if (pde.dataset.buttonrow === rowNumber) {
                    professionalDevelopmentElement = pde;
                }
            }
            professionalDevelopmentElement.classList.remove("hide-professional-development");
            professionalDevelopmentElement.classList.add("open");
            button.classList.add("hide-professional-development");
            Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Added", 'success', 3000, null);
            //            this.customInitializeRequiredFields();
        }
        else if (!hasName) {
            Core.createHTMLAlert("alertMessageDiv", "A name for the activity must be provided", 'error', 3000, null);
        }
    }
    bindPDActionStepAddButtons() {
        let actionStepButtons = document.getElementsByClassName("professionalDevelopmentActionStepAddV2");
        for (let actionStepButton of actionStepButtons) {
            let element = actionStepButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addActionStepToActivity(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    addActionStepToActivity(button) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.buttonrow;
        let formName = document.getElementById("professionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddActionStepV2");
        let actionStepPlanPropertyPK;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === rowNumber) {
                actionStepPlanPropertyPK = parseInt(selectElement.value);
            }
        }
        let isNotInList = true;
        let allListElements = document.getElementsByClassName("actionStepListV2");
        for (let listElement of allListElements) {
            let element = listElement;
            if (parseInt(element.dataset.planpropertypk) === actionStepPlanPropertyPK && element.dataset.buttonrow === rowNumber) {
                isNotInList = false;
            }
        }
        if (isNotInList) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Go/AddPDActionStepToList');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addActionStepToListV2[data-row='" + rowNumber + "']").append(xhr.responseText);
                    _that.bindPDActionStepRemoveButtons();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Step Row Added", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&planFK=" + planFK + "&actionStepPlanPropertyPK=" + actionStepPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "This action step has already been added to this activity", 'error', 3000, null);
        }
    }
    bindDanielsonAddButtons() {
        let danielsonButtons = document.getElementsByClassName("professionalDevelopmentDanielsonFrameworkAddV2");
        for (let danielsonButton of danielsonButtons) {
            let element = danielsonButton;
            if (element.dataset.listener !== "true") {
                element.addEventListener("click", (e) => this.addDanielsonFrameworkToList(e.target));
                element.dataset.listener = "true";
            }
        }
    }
    addDanielsonFrameworkToList(button) {
        let core = this._core;
        let _that = this;
        let rowNumber = button.dataset.row;
        let buttonrow = button.dataset.buttonrow;
        let propertyfk = button.dataset.propertypk;
        let formName = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = parseInt(formName.dataset.planfk);
        let selectElements = document.getElementsByClassName("professionalDevelopmentAddDanielsonFrameworkV2");
        let lookupCodePK;
        for (let selectElement of selectElements) {
            if (selectElement.dataset.buttonrow === buttonrow && selectElement.dataset.row === rowNumber) {
                lookupCodePK = parseInt(selectElement.value);
            }
        }
        let isNotInList = true;
        let allListElements = document.getElementsByClassName("addDanielsonFrameworkToListItemV2");
        for (let listElement of allListElements) {
            let element = listElement;
            if (element.dataset.lookupcodepk === lookupCodePK.toString() && element.dataset.buttonrow === buttonrow && element.dataset.row === rowNumber) {
                isNotInList = false;
            }
        }
        //See if user has selected a danielson framework or if it's still set to default value
        let userHasSelected = true;
        if (isNaN(lookupCodePK)) {
            userHasSelected = false;
            Core.createHTMLAlert("alertMessageDiv", "A Danielson Framework must first be selected", 'error', 3000, null);
        }
        if (isNotInList && userHasSelected) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/ProfessionalDevelopmentReport/AddDanielsonFrameworkToListV2');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $(".addDanielsonFrameworkToListV2[data-row='" + rowNumber + "'][data-buttonrow='" + buttonrow + "']").append(xhr.responseText);
                    _that.bindDanielsonListRemove();
                    Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Row Added", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("rowNumber=" + rowNumber + "&buttonRowNumber=" + buttonrow + "&planFK=" + planFK + "&lookupCodePK=" + lookupCodePK + "&propertyfk=" + propertyfk);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "This Danielson Framework item has already been added", 'error', 3000, null);
        }
    }
    removeDanielsonFrameworkList(e) {
        e.stopPropagation();
        let core = this._core;
        let element;
        let go = true;
        element = e.target;
        let rowNumber = element.dataset.row;
        let buttonrow = element.dataset.buttonrow;
        let lookupCodePK = element.dataset.lookupcodepk;
        let allLookups = document.getElementsByClassName("addDanielsonFrameworkToListItemV2");
        for (let lookup of allLookups) {
            let thisElement = lookup;
            if (thisElement.dataset.lookupcodepk === lookupCodePK && thisElement.dataset.buttonrow === buttonrow && thisElement.dataset.row === rowNumber) {
                thisElement.parentNode.removeChild(thisElement);
                Core.createHTMLAlert("alertMessageDiv", "Danielson Framework Removed", 'success', 3000, null);
            }
        }
    }
    addMoreProfessionalDevelopmentRows() {
        let core = this._core;
        let _that = this;
        let howManyElement = "1";
        let howMany = parseInt(howManyElement);
        let planElement = document.getElementById("otherProfessionalDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let currentRow = 0;
        let activityElements = document.getElementsByClassName("professionalDevelopmentActivityV2");
        for (let activity of activityElements) {
            let activityElement = activity;
            if (parseInt(activityElement.dataset.buttonrow) > currentRow) {
                currentRow = parseInt(activityElement.dataset.buttonrow);
            }
        }
        if (howMany && howMany > 0) {
            for (let i = 1; i <= howMany; i++) {
                currentRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ProfessionalDevelopmentReport/AddNewProfessionalDevelopmentActivity');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#professionalDevelopmentContentV2").append(xhr.responseText);
                        new CustomAccordion(`professionalDevelopmentAccordion${currentRow.toString()}V2`);
                        _that.bindDanielsonAddButtons();
                        _that.bindAddLearningFormatButtons();
                        _that.bindPDActionStepAddButtons();
                        _that.bindCreatePDActivityButtons();
                        _that.bindRemoveLearningFormatButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Professional Development Activity Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("rowNumber=" + currentRow + "&planFK=" + planFK);
            }
        }
    }
    deleteProfessionalDevelopmentActivity(activityPlanPropertyPK) {
        let that = this;
        that.deleteActivityModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Go/DeleteProfessionalDevelopmentActivity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Professional development activity successfully deleted", 'success', 3000, null);
                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractivityplanplanpropertypk='${activityPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
            }
            Core.hideLoader();
        };
        xhr.send(activityPlanPropertyPK);
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// Induction Plan Profile
class InductionPlanProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanProfileField"];
        let inductionPlanProfileSaveButton = document.getElementById("inductionPlanProfileSave");
        if (inductionPlanProfileSaveButton !== null)
            inductionPlanProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("inductionPlanProfileForm");
        let inputs = document.getElementsByClassName("inductionPlanProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Teacher Induction Committee Participants
class TeacherInductionCommitteeParticipants {
    constructor() {
        let that = this;
        this._core = new Core();
        this.bindDeleteButtons();
        this.validationClasses = ["steeringCommitteeField"];
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        if (steeringCommitteeTable != null) {
            that.planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        }
        let exportToWordButton = document.getElementById("inductionExportToWord");
        if (exportToWordButton !== null)
            exportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let exportToExcelButton = document.getElementById("inductionExportToExcel");
        if (exportToExcelButton !== null)
            exportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        let saveInductionCommitteeMembers = document.getElementById("saveInductionCommitteeMembers");
        if (saveInductionCommitteeMembers !== null)
            saveInductionCommitteeMembers.addEventListener("click", (e) => this.save("save"));
        let inductionPlanSteeringCommitteeDeleteCancel = document.getElementById("inductionPlanSteeringCommitteeDeleteCancel");
        if (inductionPlanSteeringCommitteeDeleteCancel !== null)
            inductionPlanSteeringCommitteeDeleteCancel.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        let inductionPlanSteeringCommitteeDeleteConfirm = document.getElementById("inductionPlanSteeringCommitteeDeleteConfirm");
        if (inductionPlanSteeringCommitteeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeDeleteConfirm.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let steeringCommitteeAddRowsButton = document.getElementById("inductionSteeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e) => this.addNewSteeringCommitteeRow());
        let steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("change", (e) => this.checkForPlural());
        let steeringCommitteeExportExcel = document.getElementById("exportExcelCommitteeMembers");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e) => this.exportToExcel());
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        let allSaveElements = [];
        let planPK = 0;
        let inputs = document.getElementsByClassName("steeringCommitteeField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeTable.dataset.planpk);
            if (ele instanceof HTMLSelectElement) {
                let element = ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                let lookupCodeFK = null;
                let textValue = null;
                if (ele.dataset.propertycode == "inductionPlanCommitteeAppointedBy") {
                    lookupCodeFK = parseInt(element.value);
                }
                else if (ele.dataset.propertycode == "inductionPlanCommitteeRole") {
                    //Note that even though this is a select, the value is being stored as TextValue NOT LookupCodeFK
                    textValue = ele.value;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: textValue,
                        LookupCodeFK: lookupCodeFK,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
            else {
                let element = ele;
                let rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    exportToWord() {
    }
    exportToExcel() {
        let core = this._core;
        Core.showLoader();
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let planPK = 0;
        planPK = parseInt(steeringCommitteeTable.dataset.planpk);
        window.open("/ExportExcel/InductionSteeringCommitteeDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeInductionSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("steeringCommittee");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();
        let committeeFields = document.getElementsByClassName("steeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = comm;
            if (inputElement.dataset.row === row && "planpropertypk" in inputElement.dataset && inputElement.dataset.planpropertypk !== "0") {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }
        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);
                    let modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        }
        else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }
                    let modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    addNewSteeringCommitteeRow() {
        let that = this;
        let core = this._core;
        let newRow = 0;
        let _that = this;
        let numberOfRowsElement = document.getElementById("steeringCommitteeAddRowsNumber");
        let numberOfRows = parseInt(numberOfRowsElement.value);
        if (numberOfRows !== null && numberOfRows > 0) {
            let communicationFields = document.getElementsByClassName("steeringCommitteeField");
            for (let comm of communicationFields) {
                let commElement = comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }
            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddSteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteButtons();
                        numberOfRowsElement.value = "";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planPK=" + that.planPK);
            }
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
    }
    checkForPlural() {
        let word = document.getElementById("rowWord");
        let num = document.getElementById("federalPreparingAddRow");
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }
        word.textContent = newWord;
    }
    initializeRequiredFieldsCustom() {
        let steeringCommitteeTableRows = document.querySelectorAll("tr.steeringCommitteeRow[data-row]");
        for (let row of steeringCommitteeTableRows) {
            let fields = row.getElementsByClassName("steeringCommitteeField");
            for (let element of fields) {
                if (element instanceof HTMLSelectElement) {
                    if (element.selectedIndex > 0) {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    if (element.value != "") {
                        for (let otherElement of fields) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
}
// Educator Induction Plan
class EducatorInductionPlan {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanEducatorInductionPlan"];
        this.checkPreCount();
        let educatorInductionPlanSaveButton = document.getElementById("saveEducatorInductionPlan");
        if (educatorInductionPlanSaveButton !== null)
            educatorInductionPlanSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorInductionPlanExportToWordButton = document.getElementById("exportToWordEducatorInductionPlan");
        if (educatorInductionPlanExportToWordButton !== null)
            educatorInductionPlanExportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let educatorInductionPlanExportToExcelButton = document.getElementById("exportToExcelEducatorInductionPlan");
        if (educatorInductionPlanExportToExcelButton !== null)
            educatorInductionPlanExportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        const preCounts = document.getElementsByClassName("precount");
        for (const preCount of preCounts) {
            preCount.addEventListener("change", (e) => this.checkPreCount());
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("inductionPlanEducatorInductionPlanForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let inputs = document.getElementsByClassName("inductionPlanEducatorInductionPlan");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let textInputs = document.getElementsByClassName("inductionPlanEducatorInductionPlanText");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    exportToWord() {
    }
    exportToExcel() {
    }
    checkPreCount() {
        const preCount = document.querySelector(".precount:checked");
        if (preCount !== null) {
            const preKCountsContainer = document.getElementById("preKCountsContainer");
            const inductionPlanEducatorPreCountList = document.getElementById("inductionPlanEducatorPreCountListId");
            if (preCount.dataset.lookupcode === "no") {
                preKCountsContainer.classList.add("hide");
                this._core.forceElementOptional(inductionPlanEducatorPreCountList);
            }
            else {
                preKCountsContainer.classList.remove("hide");
                this._core.forceElementRequired(inductionPlanEducatorPreCountList);
            }
        }
    }
}
// Mentors
class Mentors {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["indctionPlanMentorRadioField", "inductionPlanMentorField"];
        let mentorsSaveButton = document.getElementById("saveMentors");
        if (mentorsSaveButton !== null)
            mentorsSaveButton.addEventListener("click", (e) => this.save("save"));
        let mentorsExportToWordButton = document.getElementById("exportToWordMentors");
        if (mentorsExportToWordButton !== null)
            mentorsExportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let mentorsExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (mentorsExportToExcelButton !== null)
            mentorsExportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        this._core.leftnav(this);
        this.setMentorsOther();
        let otherRadio = document.querySelectorAll('input[name="inductionplanmentorsinductionPlanMentorOther"]');
        if (otherRadio !== null) {
            for (let radioElement of otherRadio) {
                radioElement.addEventListener("change", (e) => this.setMentorsOther());
            }
        }
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("inductionPlanMentorsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("inductionPlanMentorField");
        let inputs = document.getElementsByClassName("indctionPlanMentorRadioField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveMentors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    setMentorsOther() {
        let otherRadioChecked = document.querySelector('input[name="inductionplanmentorsinductionPlanMentorOther"]:checked');
        let otherExplanation = document.getElementById("inductionPlanMentorOtherText");
        //Show explanation and make explanation required if user picks "No"
        if (otherRadioChecked !== null && otherRadioChecked.dataset.lookuplabel !== null && otherRadioChecked.dataset.lookuplabel === "Yes" && otherExplanation !== null) {
            this._core.forceElementRequired(otherExplanation);
        }
        else {
            this._core.forceElementOptional(otherExplanation);
        }
    }
    exportToWord() {
    }
    exportToExcel() {
    }
}
// Needs Assessment
class NeedsAssessment {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanNeedsAssessmentRadioField", "inductionPlanNeedsAssessmentField"];
        let needsAssessmentSaveButton = document.getElementById("saveNeedsAssessment");
        if (needsAssessmentSaveButton !== null)
            needsAssessmentSaveButton.addEventListener("click", (e) => this.save("save"));
        let needsAssessmentExportToWordButton = document.getElementById("exportToWordMentors");
        if (needsAssessmentExportToWordButton !== null)
            needsAssessmentExportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let needsAssessmentExportToExcelButton = document.getElementById("exportToExcelMentors");
        if (needsAssessmentExportToExcelButton !== null)
            needsAssessmentExportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        this._core.leftnav(this);
        this.setNeedsAssessmentOther();
        let otherRadio = document.querySelectorAll('input[name="inductionPlanNeedsOther"]');
        if (otherRadio !== null) {
            for (let radioElement of otherRadio) {
                radioElement.addEventListener("change", (e) => this.setNeedsAssessmentOther());
            }
        }
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("inductionPlanNeedsAssessmentForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("inductionPlanNeedsAssessmentField");
        let inputs = document.getElementsByClassName("inductionPlanNeedsAssessmentRadioField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveNeedsAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    setNeedsAssessmentOther() {
        let otherRadioChecked = document.querySelector('input[name="inductionPlanNeedsOther"]:checked');
        let otherExplanation = document.getElementById("inductionPlanNeedsOtherSpecify");
        //Show explanation and make explanation required if user picks "Yes"
        if (otherRadioChecked !== null && otherRadioChecked.dataset.lookuplabel !== null && otherRadioChecked.dataset.lookuplabel === "Yes" && otherExplanation !== null) {
            this._core.forceElementRequired(otherExplanation);
        }
        else {
            this._core.forceElementOptional(otherExplanation);
        }
    }
    exportToWord() {
    }
    exportToExcel() {
    }
}
// Educator Induction Plan Topic Areas
class EducatorInductionPlanTopicAreas {
    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = ["multiSelectTopicAreasTimeline", "multiSelectTopicAreasTopicAreas", "inductionPlanFileUpload"];
        this.hashClasses = ["addDanielsonFrameworkToListItem", "inductionPlanTopicAreasTimeline"];
        this.deleteFileModal = new Modal('deleteFileModal', null);
        let fileUploaders = document.getElementsByClassName('fileUpload');
        for (let upload of fileUploaders) {
            if ("propertyFk" in upload.dataset) {
                upload.addEventListener('change', (e) => that.uploadFile(parseInt(upload.dataset.propertyFk)));
            }
        }
        document.addEventListener("click", function (event) {
            let target = event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("deleteFile") && "planPropertyFilePk" in target.dataset) {
                that.deleteFileModal.show();
                that.deleteFileModal.callingId = target.id;
                that.planPropertyFileToDeletePK = parseInt(target.dataset.planPropertyFilePk);
            }
        });
        let deleteFileConfirm = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirm != null) {
            deleteFileConfirm.addEventListener("click", () => that.deleteFileConfirm());
        }
        let deleteFileCancel = document.getElementById("deleteFileCancel");
        if (deleteFileCancel != null) {
            deleteFileCancel.addEventListener("click", () => that.deleteFileModal.hide());
        }
        let educatorInductionPlanTopicAreasSaveButton = document.getElementById("saveEducatorInductionPlanTopicAreas");
        if (educatorInductionPlanTopicAreasSaveButton !== null)
            educatorInductionPlanTopicAreasSaveButton.addEventListener("click", (e) => this.save("save"));
        let reportTopicAreasButton = document.getElementById("inductionReportSelectedTopics");
        if (reportTopicAreasButton !== null)
            reportTopicAreasButton.addEventListener("click", (e) => this.reportTopicAreas());
        let topicAreaExportToWordButton = document.getElementById("exportToWordTopicAreas");
        if (topicAreaExportToWordButton !== null)
            topicAreaExportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let topicAreaExportToExcelButton = document.getElementById("exportToExcelTopicAreas");
        if (topicAreaExportToExcelButton !== null)
            topicAreaExportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        let allAddDanielsonButtons = document.getElementsByClassName("addThisDanielson");
        for (let addDanielsonButton of allAddDanielsonButtons)
            addDanielsonButton.addEventListener("click", (e) => this.addDanielson(e));
        this._core.leftnav(this);
        this.initializeRequiredFields();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this.bindRemoveDanielsonButtons();
        let hidden = this._core.createHash(this.hashClasses); // using hashclasses instead of validation classes due to danielson framework
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let checkboxes = document.getElementsByClassName("inductionPlanTopicAreasCheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e) => this.checkForReadonly(e));
    }
    checkForReadonly(e) {
        let checkbox = e.target;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    createHash() {
        //using custom hashclasses for this page.
        let hash = this._core.createHash(this.hashClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    addDanielson(e) {
        let core = this._core;
        let _that = this;
        let lookupCodePK = parseInt(e.target.dataset.lookupcodepk);
        let danielsonSelection = document.querySelector(`.danielsonFrameworkList[data-lookupcodepk="${lookupCodePK}"]`);
        let propertyPK = danielsonSelection.dataset.propertypk;
        let danielsonLookupCodePK = danielsonSelection.value;
        if (danielsonLookupCodePK === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please select a Danielson Framework to add", "warning", 3000, null);
        }
        else {
            let isNotInList = true;
            let allListElements = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
            for (let listElement of allListElements) {
                let element = listElement;
                if (element.dataset.danielsonlookupcodepk === danielsonLookupCodePK.toString()) {
                    isNotInList = false;
                }
            }
            if (isNotInList) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddDanielsonFrameworkToList');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $(".addDanielsonFrameworkToList[data-lookupcodepk='" + lookupCodePK + "']").append(xhr.responseText);
                        _that.bindRemoveDanielsonButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Danielson Framework Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("lookupCodePK=" + lookupCodePK + "&danielsonLookupPK=" + danielsonLookupCodePK + "&propertyFK=" + propertyPK);
            }
        }
    }
    removeDanielson(e) {
        e.stopPropagation();
        let element;
        let go = true;
        element = e.target;
        let lookupCodePK = element.dataset.lookupcodepk;
        let danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;
        let allLookups = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"]`);
        for (let lookup of allLookups) {
            let thisElement = lookup;
            if (thisElement.dataset.danielsonlookupcodepk === danielsonLookupCodePK) {
                thisElement.parentNode.removeChild(thisElement);
            }
        }
    }
    bindRemoveDanielsonButtons() {
        let allDeleteButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (let deleteButton of allDeleteButtons) {
            deleteButton.removeEventListener("click", (e) => this.removeDanielson(e));
            deleteButton.addEventListener("click", (e) => this.removeDanielson(e));
        }
    }
    reportTopicAreas() {
        let _that = this;
        let successes = [];
        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        for (let topicArea of allTopicAreas) {
            let topicAreaElement = topicArea;
            let topLevelAccordionElement = document.getElementById("inductionPlanTopicAreasAccordionList");
            let accordionTrigger = topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            let accordionPanel = topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            if (topicAreaElement.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");
                    let timeline = accordionPanel.querySelector(".multiSelectTopicAreasTimeline");
                    if (timeline != null) {
                        this._core.forceElementRequired(timeline);
                    }
                }
            }
            else {
                let selects = accordionPanel.querySelectorAll("select");
                for (let select of selects) {
                    let selectElement = select;
                    selectElement.value = "0";
                }
                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                }
                //TODO: Remove the danielson elements from the list.
            }
        }
        Core.createHTMLAlert("alertMessageDiv", "Topic Area(s) successfully added", 'success', 3000, null);
        this._core.initializeRequiredFields(this.validationClasses);
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let hadDataElements = [];
        let allDanielsonElements = [];
        let allTopicAreaElements = [];
        let formElement = document.getElementById("inductionPlanEducatorInductionPlanTopicAreasForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let timelines = document.getElementsByClassName("inductionPlanTopicAreasTimeline");
        for (let ele of timelines) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let lookupCodePK = element.dataset.lookupcodepk;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.checked || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.dataset.timelinelookupcodepk),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        ButtonRow: parseInt(lookupCodePK),
                        DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let danielsonList = document.getElementsByClassName("addDanielsonFrameworkToListItem");
        for (let danielson of danielsonList) {
            let danielsonElement = danielson;
            let planPropertyPK = 0;
            let propertyPK = parseInt(danielsonElement.dataset.propertypk);
            let danielsonLookupCodePK = danielsonElement.dataset.danielsonlookupcodepk;
            let lookupCodePK = danielsonElement.dataset.lookupcodepk;
            if (danielsonElement.dataset.planpropertypk !== "" && danielsonElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(danielsonElement.dataset.planpropertypk);
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: "",
                LookupCodeFK: parseInt(danielsonLookupCodePK),
                RowNbr: 0,
                IsDeletedInd: false,
                ButtonRow: parseInt(lookupCodePK),
                DataPointPlanPropertyPK: parseInt(danielsonElement.dataset.datapointplanpropertypk)
            };
            allDanielsonElements.push(saveItem);
        }
        let allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        for (let topic of allTopicAreas) {
            let topicElement = topic;
            let propertyPK = 0;
            let topicLookupCodePK = 0;
            if ("lookupcodepk" in topicElement.dataset) {
                topicLookupCodePK = parseInt(topicElement.dataset.lookupcodepk);
            }
            if ("propertyPK" in topicElement.dataset) {
                propertyPK = parseInt(topicElement.dataset.propertypk);
            }
            if (!topicElement.checked && "hadvalue" in topicElement.dataset && topicElement.dataset.hadvalue === "true") {
                let planPropertyPK = 0;
                if (topicElement.dataset.planpropertypk !== "" && topicElement.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(topicElement.dataset.planpropertypk);
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        RowNbr: 0,
                        LookupCodeFK: topicLookupCodePK,
                        IsDeletedInd: true,
                    };
                    hadDataElements.push(saveItem);
                }
            }
            else if (topicElement.checked) {
                let planPropertyPK = 0;
                if (topicElement.dataset.planpropertypk) {
                    planPropertyPK = parseInt(topicElement.dataset.planpropertypk);
                }
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    RowNbr: 0,
                    LookupCodeFK: topicLookupCodePK,
                    IsDeletedInd: false,
                };
                allTopicAreaElements.push(saveItem);
            }
        }
        let saveData = {
            "Timeline": allSaveElements,
            "Danielson": allDanielsonElements,
            "RemovedTopicAreas": hadDataElements,
            "TopicAreas": allTopicAreaElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveTopicAreas', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allDanielsonElements.length === 0 && hadDataElements.length === 0 && allTopicAreaElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    initializeRequiredFields() {
        let hiddenTimelines = document.querySelectorAll(".hide-accordion .multiSelectTopicAreasTimeline");
        for (let hiddenTimeline of hiddenTimelines) {
            this._core.forceElementOptional(hiddenTimeline);
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    exportToWord() {
    }
    exportToExcel() {
    }
    uploadFile(propertyFK) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = document.querySelector(`.uploadForm[data-property-fk='${propertyFK}']`);
        const formData = new FormData(uploadForm);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/UploadFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        const formFile = document.querySelector(`.fileUpload[data-property-fk='${propertyFK}']`);
                        formFile.value = '';
                        const planPropertyFile = response.payload;
                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/Base/GetFileUploadPartialView?planPropertyFilePK=${planPropertyFile.planPropertyFilePK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-property-fk='${propertyFK}']`);
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            }
                        };
                        xhrForPartialView.send();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    deleteFileConfirm() {
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileContainer[data-plan-property-file-pk='${that.planPropertyFileToDeletePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            that.deleteFileModal.hide();
        };
        xhr.send(JSON.stringify([that.planPropertyFileToDeletePK]));
    }
}
// Evaluation and Monitoring
class EvaluationAndMonitoring {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["evaluationAndMonitoringField"];
        let evaluationAndMonitoringSaveButton = document.getElementById("saveEvaluationAndMonitoring");
        if (evaluationAndMonitoringSaveButton !== null)
            evaluationAndMonitoringSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("inductionPlanEvaluationAndMonitoringForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("evaluationAndMonitoringField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEvaluationAndMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Documentation of Participation and Completion
class DocumentationOfParticipationAndCompletion {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["documentationOfParticipationField", "documentationOfParticipationRadioField"];
        let documentationOfParticipationAndCompletionSaveButton = document.getElementById("saveDocumentationOfParticipationAndCompletion");
        if (documentationOfParticipationAndCompletionSaveButton !== null)
            documentationOfParticipationAndCompletionSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        this.setConfirmFirstYearTeachers();
        let firstYearTeachersRadio = document.querySelectorAll('input[name="inductionPlanConfirmFirstYear"]');
        if (firstYearTeachersRadio !== null) {
            for (let radioElement of firstYearTeachersRadio) {
                radioElement.addEventListener("change", (e) => this.setConfirmFirstYearTeachers());
            }
        }
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    setConfirmFirstYearTeachers() {
        let confirmTeachersRadioChecked = document.querySelector('input[name="inductionPlanConfirmFirstYear"]:checked');
        let confirmTeachersExplanation = document.getElementById("confirmTeachersExplanation");
        //Show explanation and make explanation required if user picks "No"
        if (confirmTeachersRadioChecked !== null && confirmTeachersRadioChecked.dataset.lookuplabel !== null && confirmTeachersRadioChecked.dataset.lookuplabel === "No" && confirmTeachersExplanation !== null) {
            this._core.forceElementRequired(confirmTeachersExplanation);
        }
        else {
            this._core.forceElementOptional(confirmTeachersExplanation);
        }
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("inductionPlanDocumentationOfParticipationAndCompletionForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("documentationOfParticipationField");
        let radioInputs = document.getElementsByClassName("documentationOfParticipationRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveDocumentationOfParticipationAndCompletion', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Educator Induction Plan Statement Of Assurance
class EducatorInductionPlanStatementOfAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanSignatureAndAssuranceField"];
        let EducatorInductionPlanStatementOfAssuranceSaveButton = document.getElementById("saveEducatorInductionPlanStatementOfAssurance");
        if (EducatorInductionPlanStatementOfAssuranceSaveButton !== null)
            EducatorInductionPlanStatementOfAssuranceSaveButton.addEventListener("click", (e) => this.save("save"));
        let fileUploader = document.getElementById("inductionPlanUploadMinutesFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = document.getElementById("inductionPlanUploadMinutes");
        let formData = new FormData(uploadMinutesForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("inductionPlanUploadMinutesFile");
                        formFile.value = "";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("inductionPlanEducatorInductionPlanStatementOfAssuranceForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("inductionPlanSignatureAndAssuranceField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: 0,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveEducatorInductionPlanStatementOfAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Curriculum Summary Checklist and Submission
class InductionPlanSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        this._core.leftnav(this);
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
//V3 
// InductionPlanV3SteeringCommittee
class InductionPlanV3SteeringCommittee {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanV3SteeringCommitteeField", "inductionPlanV3SteeringCommitteeSelectField"];
        const inductionPlanV3SteeringCommitteeSaveButton = document.getElementById("inductionPlanV3SteeringCommitteeSave");
        if (inductionPlanV3SteeringCommitteeSaveButton !== null)
            inductionPlanV3SteeringCommitteeSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const inductionPlanSteeringCommitteeDeleteCancel = document.getElementById("inductionPlanSteeringCommitteeDeleteCancel");
        if (inductionPlanSteeringCommitteeDeleteCancel !== null)
            inductionPlanSteeringCommitteeDeleteCancel.addEventListener("click", (e) => this.deleteRowConfirmCancel(e));
        const inductionPlanSteeringCommitteeDeleteConfirm = document.getElementById("inductionPlanSteeringCommitteeDeleteConfirm");
        if (inductionPlanSteeringCommitteeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeDeleteConfirm.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        const inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel = document.getElementById("inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel");
        if (inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel !== null)
            inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel.addEventListener("click", (e) => this.deleteRowConfirmCancel(e));
        const inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm = document.getElementById("inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm");
        if (inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm !== null)
            inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        const steeringCommitteeAddRowsNumberInput = document.getElementById("steeringCommitteeAddRowsNumber");
        if (steeringCommitteeAddRowsNumberInput !== null)
            steeringCommitteeAddRowsNumberInput.addEventListener("input", (e) => this.checkForPlural());
        const exportToExcelButton = document.getElementById("inductionExportToExcel");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        const steeringCommitteeAddRowsButton = document.getElementById("inductionSteeringCommitteeAddRows");
        if (steeringCommitteeAddRowsButton !== null)
            steeringCommitteeAddRowsButton.addEventListener("click", (e) => this.addNewSteeringCommitteeRow());
        this.bindDeleteButtons();
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3SteeringCommitteeForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("inductionPlanV3SteeringCommitteeField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const selectInputs = document.getElementsByClassName("inductionPlanV3SteeringCommitteeSelectField");
        for (const ele of selectInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3SteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkForPlural() {
        const word = document.getElementById("rowWord");
        const num = document.getElementById("steeringCommitteeAddRowsNumber");
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }
        word.textContent = newWord;
    }
    exportToExcel() {
        const formElement = document.getElementById("inductionPlanV3SteeringCommitteeForm");
        const planFK = formElement.dataset.planfk;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/InductionPlanV3SteeringCommitteeDataExport?planFK=${planFK}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "SteeringCommittee.xlsx";
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send();
        });
    }
    addNewSteeringCommitteeRow() {
        const that = this;
        const core = this._core;
        let newRow = 0;
        const _that = this;
        const numberOfRowsElement = document.getElementById("steeringCommitteeAddRowsNumber");
        const numberOfRows = parseInt(numberOfRowsElement.value);
        const form = document.getElementById("inductionPlanV3SteeringCommitteeForm");
        const planFK = form.dataset.planfk;
        if (numberOfRows !== null && numberOfRows > 0) {
            let communicationFields = document.getElementsByClassName("inductionPlanSteeringCommitteeRow");
            for (const comm of communicationFields) {
                const commElement = comm;
                if (newRow < parseInt(commElement.dataset.row))
                    newRow = parseInt(commElement.dataset.row);
            }
            for (let i = 0; i < numberOfRows; i++) {
                newRow++;
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddV3SteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "New steering committee row added", 'success', 3000, null);
                        _that.bindDeleteButtons();
                        numberOfRowsElement.value = "";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send("newRow=" + newRow + "&planPK=" + planFK);
            }
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeInductionSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        const buttonElement = e.target;
        const row = buttonElement.dataset.row;
        const hasData = buttonElement.dataset.hasdata;
        if (parseInt(row) <= 3) {
            const modal = new Modal("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", null);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", "#inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm", "row", row);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", "#inductionPlanSteeringCommitteeRowOneTwoThreeDeleteConfirm", "hasdata", hasData);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", "#inductionPlanSteeringCommitteeRowOneTwoThreeDeleteCancel", "row", row);
            modal.show();
        }
        else {
            const modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "row", row);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteConfirm", "hasdata", hasData);
            modal.addAttributeToElement("inductionPlanDeleteSteeringCommitteeRow", "#inductionPlanSteeringCommitteeDeleteCancel", "row", row);
            modal.show();
        }
    }
    deleteRowConfirmCancel(e) {
        const button = e.target;
        const row = button.dataset.row;
        if (parseInt(row) <= 3) {
            const modal = new Modal("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", null);
            modal.hide();
        }
        else {
            const modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
            modal.hide();
        }
    }
    deleteRowConfirmConfirm(e) {
        const buttonElement = e.target;
        const row = buttonElement.dataset.row;
        const hasDataText = buttonElement.dataset.hasdata;
        let hasData = false;
        if (hasDataText === "true")
            hasData = true;
        const planProps = [];
        Core.showLoader();
        let modal = new Modal("inductionPlanDeleteSteeringCommitteeRow", null);
        if (parseInt(row) <= 3) {
            modal = new Modal("inductionPlanDeleteSteeringCommitteeRowOneTwoThree", null);
        }
        if (!hasData) {
            const theRow = document.querySelector(`.inductionPlanSteeringCommitteeRow[data-row='${row}']`);
            theRow.remove();
            modal.hide();
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
        }
        else {
            const thisRow = document.querySelectorAll(`.steeringCommitteeField[data-row='${row}']`);
            for (const row of thisRow) {
                const ele = row;
                planProps.push(ele.dataset.planpropertypk);
            }
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const allTheRows = document.getElementsByClassName("inductionPlanSteeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }
                    Core.hideLoader();
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const allElements = document.querySelectorAll(classesToValidate);
        const rows = [];
        for (const ele of allElements) {
            const element = ele;
            const row = element.dataset.row;
            if (rows.indexOf(row) === -1)
                rows.push(row);
        }
        for (const row of rows) {
            const nameElement = document.querySelector(`.name[data-row='${row}']`);
            const titleElement = document.querySelector(`.title[data-row='${row}']`);
            const roleElement = document.querySelector(`.role[data-row='${row}']`);
            const appointedByElement = document.querySelector(`.appointedBy[data-row='${row}']`);
            if (nameElement.value !== "" || titleElement.value !== "" || roleElement.value !== "0" || appointedByElement.value !== "0") {
                if (nameElement.value === "") {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                }
                if (titleElement.value === "") {
                    titleElement.classList.add("missing-field");
                    titleElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(titleElement);
                    showMessage = true;
                    totalErrors++;
                }
                if (roleElement.value === "0") {
                    roleElement.classList.add("missing-field");
                    roleElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(roleElement);
                    showMessage = true;
                    totalErrors++;
                }
                if (appointedByElement.value === "0") {
                    appointedByElement.classList.add("missing-field");
                    appointedByElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(appointedByElement);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        const message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            const firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    const accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");
                        const accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// InductionPlanV3NeedsAssessment
class InductionPlanV3NeedsAssessment {
    constructor() {
        this._core = new Core();
        this.checkForOthers();
        this.validationClasses = ["inductionPlanV3NeedsAssessmentField", "inductionPlanV3NeedsAssessmentRadioField"];
        const inductionPlanV3NeedsAssessmentSaveButton = document.getElementById("inductionPlanV3NeedsAssessmentSave");
        if (inductionPlanV3NeedsAssessmentSaveButton !== null)
            inductionPlanV3NeedsAssessmentSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const inductionPlanV3NeedsOthers = document.getElementsByClassName("inductionPlanV3NeedsOther");
        for (const inductionPlanV3NeedsOther of inductionPlanV3NeedsOthers)
            inductionPlanV3NeedsOther.addEventListener("click", (e) => this.checkForOther(e.target));
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3NeedsAssessmentForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("inductionPlanV3NeedsAssessmentField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("inductionPlanV3NeedsAssessmentRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3NeedsAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkForOthers() {
        const inductionPlanV3NeedsOthers = document.getElementsByClassName("inductionPlanV3NeedsOther");
        for (const inductionPlanV3NeedsOther of inductionPlanV3NeedsOthers)
            this.checkForOther(inductionPlanV3NeedsOther);
    }
    checkForOther(radio) {
        if (radio.checked) {
            var lookupLabel = radio.dataset.lookuplabel.toLowerCase();
            const otherElement = document.getElementById("inductionPlanV3NeedsOtherSpecify");
            if (lookupLabel === "yes") {
                this._core.forceElementRequired(otherElement);
            }
            else {
                this._core.forceElementOptional(otherElement);
            }
        }
    }
}
// InductionPlanV3Mentors
class InductionPlanV3Mentors {
    constructor() {
        this._core = new Core();
        this.checkForOthers();
        this.validationClasses = ["inductionPlanV3MentorsField", "inductionPlanV3MentorsRadioField"];
        const inductionPlanV3MentorsSaveButton = document.getElementById("inductionPlanV3MentorsSave");
        if (inductionPlanV3MentorsSaveButton !== null)
            inductionPlanV3MentorsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const inductionPlanV3MentorOthers = document.getElementsByClassName("inductionPlanV3MentorOther");
        for (const inductionPlanV3MentorOther of inductionPlanV3MentorOthers)
            inductionPlanV3MentorOther.addEventListener("click", (e) => this.checkForOther(e.target));
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3MentorsForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("inductionPlanV3MentorsField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("inductionPlanV3MentorsRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3Mentors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkForOthers() {
        const inductionPlanV3MentorOthers = document.getElementsByClassName("inductionPlanV3MentorOther");
        for (const inductionPlanV3MentorOther of inductionPlanV3MentorOthers)
            this.checkForOther(inductionPlanV3MentorOther);
    }
    checkForOther(radio) {
        if (radio.checked) {
            var lookupLabel = radio.dataset.lookuplabel.toLowerCase();
            const otherElement = document.getElementById("inductionPlanV3MentorOtherText");
            if (lookupLabel === "yes") {
                this._core.forceElementRequired(otherElement);
            }
            else {
                this._core.forceElementOptional(otherElement);
            }
        }
    }
}
// InductionPlanV3PlanTopicAreas
class InductionPlanV3PlanTopicAreas {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanV3PlanTopicAreasField", "inductionPlanV3PlanTopicAreasFileField", "inductionPlanV3PlanTopicAreasCheckboxField"];
        const inductionPlanV3PlanTopicAreasSaveButton = document.getElementById("inductionPlanV3PlanTopicAreasSave");
        if (inductionPlanV3PlanTopicAreasSaveButton !== null)
            inductionPlanV3PlanTopicAreasSaveButton.addEventListener("click", (e) => this.save("save"));
        this.checkForEducatorEffectivenessCharterSchool();
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const uploadInductionPlan = document.getElementById("inductionPlanV3PlanTopicAreasInductionPlan");
        if (uploadInductionPlan !== null)
            uploadInductionPlan.addEventListener("change", (e) => this.uploadFile(e.target));
        const reportTopicAreasButton = document.getElementById("inductionReportSelectedTopics");
        if (reportTopicAreasButton !== null)
            reportTopicAreasButton.addEventListener("click", (e) => this.reportTopicAreas());
        const inductionPlanTopicAreasDeleteConfirm = document.getElementById("inductionPlanTopicAreasDeleteConfirm");
        if (inductionPlanTopicAreasDeleteConfirm !== null)
            inductionPlanTopicAreasDeleteConfirm.addEventListener("click", (e) => this.confirmDeleteTopic(e));
        const inductionPlanTopicAreasDeleteCancel = document.getElementById("inductionPlanTopicAreasDeleteCancel");
        if (inductionPlanTopicAreasDeleteCancel !== null)
            inductionPlanTopicAreasDeleteCancel.addEventListener("click", (e) => this.cancelDeleteTopic());
        const inductionPlanDeleteDanielsonDeleteConfirm = document.getElementById("inductionPlanDeleteDanielsonDeleteConfirm");
        if (inductionPlanDeleteDanielsonDeleteConfirm !== null)
            inductionPlanDeleteDanielsonDeleteConfirm.addEventListener("click", (e) => this.confirmDeleteDanielson(e));
        const inductionPlanDeleteDanielsonDeleteCancel = document.getElementById("inductionPlanDeleteDanielsonDeleteCancel");
        if (inductionPlanDeleteDanielsonDeleteCancel !== null)
            inductionPlanDeleteDanielsonDeleteCancel.addEventListener("click", (e) => this.cancelDeleteDanielson());
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        const inductionReportCustomTopic = document.getElementById("inductionReportCustomTopic");
        if (inductionReportCustomTopic !== null)
            inductionReportCustomTopic.addEventListener("click", (e) => this.reportCustomTopic());
        this._suppressToast = false;
        this.checkForRequiredCheckboxes();
        this.bindEducatorEffectivenessCharterSchool();
        this.bindAddDanielsons();
        this.bindDeleteTopics();
        this.bindRemoveDanielsonButtons();
        this.bindDeleteFiles();
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allFileElements = [];
        const charterSchool = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3PlanTopicAreasForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const checkbox = document.querySelector(".inductionPlanV3PlanTopicAreasCheckboxField");
        if (checkbox !== null) {
            let planPropertyPK = 0;
            const element = checkbox;
            const rowNumber = "0";
            const propertyPK = parseInt(element.dataset.propertypk);
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
            }
            let textValue = "off";
            if (element.checked)
                textValue = "on";
            const saveItem = {
                "PlanPropertyPK": planPropertyPK,
                "PlanFK": planPK,
                "PropertyFK": propertyPK,
                "TextValue": textValue,
                "RowNbr": parseInt(rowNumber),
                "IsDeletedInd": false
            };
            charterSchool.push(saveItem);
        }
        const files = document.getElementsByClassName("inductionPlanV3PlanTopicAreasFileField");
        for (const ele of files) {
            const element = ele;
            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            const fileUploadPK = element.dataset.fileuploadpk;
            const propertyPK = parseInt(element.dataset.propertypk);
            const saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const topicOptions = [];
        const selectedItems = document.querySelectorAll(`.inductionPlanTopicAreasCheckbox:checked`);
        for (const si of selectedItems) {
            const element = si;
            const lookupCodePK = element.dataset.lookupcodepk;
            const dataPointPlanPropertyPK = element.dataset.planpropertypk;
            const innerTopic = [];
            const innerOF = [];
            let topLevelElement = null;
            const topLevel = document.querySelector(`.InductionPlanTopicAreaParent[data-lookupcodepk='${lookupCodePK}']`);
            if (topLevel !== null) {
                const propertyPK = topLevel.dataset.propertypk;
                const planPropertyPK = topLevel.dataset.planpropertypk;
                const lookupCodePK = topLevel.dataset.lookupcodepk;
                const element = {
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PlanFK: planPK,
                    PropertyFK: parseInt(propertyPK),
                    TextValue: null,
                    LookupCodeFK: parseInt(lookupCodePK),
                    RowNbr: null,
                    IsDeletedInd: false
                };
                topLevelElement = element;
            }
            const allOFTextArea = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.textarea[data-datapointlookupcodepk='${lookupCodePK}']`);
            for (const thisOFTA of allOFTextArea) {
                const ofElement = thisOFTA;
                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;
                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };
                innerTopic.push(saveItem);
            }
            const allOFInput = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.input[data-datapointlookupcodepk='${lookupCodePK}']`);
            for (const thisOFTA of allOFInput) {
                const ofElement = thisOFTA;
                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;
                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };
                innerTopic.push(saveItem);
            }
            //Observation Framework
            const allOFs = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk='${lookupCodePK}']`);
            for (const thisOF of allOFs) {
                const element = thisOF;
                const danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;
                const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
                const planPropertyPK = element.dataset.planpropertypk;
                const propertyPK = element.dataset.propertypk;
                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "DanielsonLookupCodePK": danielsonLookupCodePK,
                    "PlanPropertyPK": planPropertyPK,
                    "PropertyPK": propertyPK
                };
                innerOF.push(saveItem);
            }
            const fullTopic = {
                "TopLevelPlanProperty": topLevelElement,
                "TopicData": innerTopic,
                "FrameworkData": innerOF,
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "DataPointLookupCodePK": lookupCodePK
            };
            topicOptions.push(fullTopic);
        }
        const otherItems = document.querySelectorAll(`.Accordion-trigger[data-lookupcode='inductionPlanV3TopicsOther']`);
        for (const oi of otherItems) {
            const element = oi;
            const lookupCodePK = element.dataset.lookupcodepk;
            const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
            const innerTopic = [];
            const innerOF = [];
            let topLevelElement = null;
            const topLevel = document.querySelector(`.InductionPlanTopicAreaParent[data-lookupcodepk='${lookupCodePK}'][data-planpropertypk='${dataPointPlanPropertyPK}']`);
            if (topLevel !== null) {
                const propertyPK = topLevel.dataset.propertypk;
                const planPropertyPK = topLevel.dataset.planpropertypk;
                const lookupCodePK = topLevel.dataset.lookupcodepk;
                const title = topLevel.dataset.title;
                const element = {
                    PlanPropertyPK: parseInt(planPropertyPK),
                    PlanFK: planPK,
                    PropertyFK: parseInt(propertyPK),
                    TextValue: title,
                    LookupCodeFK: parseInt(lookupCodePK),
                    RowNbr: null,
                    IsDeletedInd: false
                };
                topLevelElement = element;
            }
            const allOFTextArea = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.textarea[data-datapointlookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const thisOFTA of allOFTextArea) {
                const ofElement = thisOFTA;
                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;
                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };
                innerTopic.push(saveItem);
            }
            const allOFInput = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.input[data-datapointlookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const thisOFTA of allOFInput) {
                const ofElement = thisOFTA;
                const textValue = ofElement.value;
                const dataPointPlanPropertyPK = ofElement.dataset.datapointplanpropertypk;
                const propertyPK = ofElement.dataset.propertypk;
                const planPropertyPK = ofElement.dataset.planpropertypk;
                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "TextValue": textValue,
                    "PropertyPK": propertyPK,
                    "PlanPropertyPK": planPropertyPK
                };
                innerTopic.push(saveItem);
            }
            //Observation Framework
            const allOFs = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const thisOF of allOFs) {
                const element = thisOF;
                const danielsonLookupCodePK = element.dataset.danielsonlookupcodepk;
                const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
                const planPropertyPK = element.dataset.planpropertypk;
                const propertyPK = element.dataset.propertypk;
                const saveItem = {
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "DanielsonLookupCodePK": danielsonLookupCodePK,
                    "PlanPropertyPK": planPropertyPK,
                    "PropertyPK": propertyPK
                };
                innerOF.push(saveItem);
            }
            const fullTopic = {
                "TopLevelPlanProperty": topLevelElement,
                "TopicData": innerTopic,
                "FrameworkData": innerOF,
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "DataPointLookupCodePK": lookupCodePK
            };
            topicOptions.push(fullTopic);
        }
        const finalData = {
            "PageLevel": {
                "CharterSchool": charterSchool,
                "Files": allFileElements,
            },
            "Frameworks": topicOptions,
            "PlanFK": planPK
        };
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3PlanTopicAreas', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(finalData));
    }
    reportTopicAreas() {
        const _that = this;
        const successes = [];
        const allTopicAreas = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        for (let topicArea of allTopicAreas) {
            const topicAreaElement = topicArea;
            const topLevelAccordionElement = document.getElementById("inductionPlanTopicAreasAccordionList");
            const accordionTrigger = topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            const accordionPanel = topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${topicAreaElement.dataset.lookupcodepk}"]`);
            if (topicAreaElement.checked) {
                if (accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.remove("hide-accordion");
                    accordionPanel.classList.remove("hide-accordion");
                }
                topicAreaElement.disabled = true;
            }
            else {
                const selects = accordionPanel.querySelectorAll("select");
                for (let select of selects) {
                    let selectElement = select;
                    selectElement.value = "0";
                }
                if (!accordionTrigger.classList.contains("hide-accordion")) {
                    accordionTrigger.classList.add("hide-accordion");
                    accordionPanel.classList.add("hide-accordion");
                }
            }
        }
        if (!this._suppressToast)
            Core.createHTMLAlert("alertMessageDiv", "Topic Area(s) successfully added", 'success', 3000, null);
        this._core.initializeRequiredFields(this.validationClasses);
    }
    addDanielson(e) {
        const buttonElement = e.target;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const lookupCodePK = parseInt(buttonElement.dataset.lookupcodepk);
        const danielsonSelection = document.querySelector(`.danielsonFrameworkList[data-lookupcodepk="${lookupCodePK}"][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const propertyPK = danielsonSelection.dataset.propertypk;
        const danielsonLookupCodePK = danielsonSelection.value;
        const that = this;
        if (danielsonLookupCodePK === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please select an Observation and Practice Framework to add", "warning", 3000, null);
        }
        else {
            let isNotInList = true;
            const allListElements = document.querySelectorAll(`.addDanielsonFrameworkToListItem[data-lookupcodepk="${lookupCodePK}"][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const listElement of allListElements) {
                const element = listElement;
                if (element.dataset.danielsonlookupcodepk === danielsonLookupCodePK.toString()) {
                    isNotInList = false;
                }
            }
            if (isNotInList) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/InductionPlanReport/AddDanielsonFrameworkToListV3');
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        danielsonSelection.selectedIndex = 0;
                        const newDiv = document.createElement("div");
                        newDiv.classList.add("medium-6");
                        newDiv.classList.add("column");
                        newDiv.classList.add("end");
                        newDiv.innerHTML = xhr.responseText;
                        const danielsonList = document.querySelector(`.addDanielsonFrameworkToList[data-lookupcodepk='${lookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .row`);
                        danielsonList.append(newDiv);
                        that.bindRemoveDanielsonButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Observation and Practice Framework Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send(`lookupCodePK=${lookupCodePK}&danielsonLookupPK=${danielsonLookupCodePK}&propertyFK=${propertyPK}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}`);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Please choose an Observation and Practice Framework that is not already selected.`, 'warning', 3000, null);
            }
        }
    }
    bindRemoveDanielsonButtons() {
        const allDeleteButtons = document.getElementsByClassName("deletePDDanielsonFramework");
        for (const deleteButton of allDeleteButtons)
            deleteButton.addEventListener("click", (e) => this.checkRemoveDanielson(e));
    }
    checkRemoveDanielson(e) {
        const button = e.target;
        const planPropertyPK = button.dataset.planpropertypk;
        const lookupCodePK = button.dataset.lookupcodepk;
        const danielsonLookupCodePK = button.dataset.danielsonlookupcodepk;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        if (planPropertyPK && planPropertyPK !== "0")
            this.showRemoveDanielson(e);
        else {
            const element = document.querySelector(`.addDanielsonFrameworkToListItem[data-lookupcodepk='${lookupCodePK}'][data-danielsonlookupcodepk='${danielsonLookupCodePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            element.remove();
        }
    }
    showRemoveDanielson(e) {
        const button = e.target;
        const planPropertyPK = button.dataset.planpropertypk;
        const modal = new Modal("deleteDanielsonModal", null);
        modal.addAttributeToElement("deleteDanielsonModal", "#inductionPlanDeleteDanielsonDeleteConfirm", "planpropertypk", planPropertyPK);
        modal.show();
    }
    cancelDeleteDanielson() {
        const modal = new Modal("deleteDanielsonModal", null);
        modal.hide();
    }
    confirmDeleteDanielson(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const planPropertyPK = button.dataset.planpropertypk;
            const response = yield fetch(`/InductionPlanReport/DeleteDanielson/${planPropertyPK}`, { credentials: 'include' });
            if (response.ok) {
                const element = document.querySelector(`.addDanielsonFrameworkToListItem[data-planpropertypk='${planPropertyPK}']`);
                element.remove();
            }
        });
    }
    showDeleteTopic(deleteTopicButton) {
        const dataPointPlanPropertyPK = deleteTopicButton.dataset.datapointplanpropertypk;
        const dataPointLookupCodePK = deleteTopicButton.dataset.datapointlookupcodepk;
        const isCustom = deleteTopicButton.dataset.iscustom;
        const title = deleteTopicButton.dataset.title;
        const modal = new Modal("deleteTopicModal", null);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "datapointlookupcodepk", dataPointLookupCodePK);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "iscustom", isCustom);
        modal.addAttributeToElement("deleteTopicModal", "#inductionPlanTopicAreasDeleteConfirm", "title", title);
        modal.show();
    }
    confirmDeleteTopic(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const deleteButton = e.target;
            const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
            const dataPointLookupCodePK = deleteButton.dataset.datapointlookupcodepk;
            const isCustom = deleteButton.dataset.iscustom;
            const title = deleteButton.dataset.title;
            const form = document.getElementById("inductionPlanV3PlanTopicAreasForm");
            const planFK = form.dataset.planfk;
            const modal = new Modal("deleteTopicModal", null);
            const data = {
                "PlanFK": planFK,
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "DataPointLookupCodePK": dataPointLookupCodePK,
                "Title": title
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            };
            const response = yield fetch(`/InductionPlanReport/DeleteV3TopicArea`, settings);
            if (response.ok) {
                const json = yield response.json();
                if (json.success) {
                    const allTextAreas = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.textarea[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);
                    const allInputs = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasField.input[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);
                    for (const textArea of allTextAreas) {
                        const element = textArea;
                        element.value = "";
                        element.dataset.datapointplanpropertypk = "0";
                        element.dataset.planpropertypk = "0";
                    }
                    for (const input of allInputs) {
                        const element = input;
                        element.value = "";
                        element.dataset.datapointplanpropertypk = "0";
                        element.dataset.planpropertypk = "0";
                    }
                    const addDanielsonFrameworkToList = document.querySelector(`.addDanielsonFrameworkToList[data-lookupcodepk='${dataPointLookupCodePK}'] .row`);
                    addDanielsonFrameworkToList.innerHTML = "";
                    let parentElement = document.querySelector(`.InductionPlanTopicAreaParent[data-lookupcodepk='${dataPointLookupCodePK}']`);
                    parentElement = parentElement.parentElement;
                    parentElement.classList.add("open");
                    if (isCustom === "false") {
                        const checkbox = document.querySelector(`.inductionPlanTopicAreasLookup[data-lookupcodepk='${dataPointLookupCodePK}']`);
                        checkbox.disabled = false;
                        checkbox.click();
                    }
                    const topLevelAccordionElement = document.getElementById("inductionPlanTopicAreasAccordionList");
                    const accordionTrigger = topLevelAccordionElement.querySelector(`.Accordion-trigger[data-lookupcodepk="${dataPointLookupCodePK}"]`);
                    const accordionPanel = topLevelAccordionElement.querySelector(`.Accordion-panel[data-lookupcodepk="${dataPointLookupCodePK}"]`);
                    if (!accordionTrigger.classList.contains("hide-accordion")) {
                        accordionTrigger.classList.add("hide-accordion");
                        accordionPanel.classList.add("hide-accordion");
                    }
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The topic has been removed successfully.", 'success', 3000, null);
                }
                else {
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this topic. Please try again.", 'error', 3000, null);
                }
            }
        });
    }
    cancelDeleteTopic() {
        const modal = new Modal("deleteTopicModal", null);
        modal.hide();
    }
    uploadFile(upload) {
        Core.showLoader();
        const formName = upload.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = upload.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.inductionPlanV3PlanTopicAreasFileUploadField[data-formname='${formName}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList`);
                        const uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        const fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        const newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        const fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("inductionPlanV3PlanTopicAreasFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.inductionPlanV3PlanTopicAreasFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindAddDanielsons() {
        const allAddDanielsonButtons = document.getElementsByClassName("addThisDanielson");
        for (const addDanielsonButton of allAddDanielsonButtons)
            addDanielsonButton.addEventListener("click", (e) => this.addDanielson(e));
    }
    bindDeleteTopics() {
        const deleteTopics = document.getElementsByClassName("deleteTopic");
        for (const deleteTopic of deleteTopics)
            deleteTopic.addEventListener("click", (e) => this.showDeleteTopic(e.target));
    }
    reportCustomTopic() {
        return __awaiter(this, void 0, void 0, function* () {
            const topicNameElement = document.getElementById("inductionPlanV3PlanTopicAreasNameofAdditionalTraining");
            const topicName = topicNameElement.value;
            const form = document.getElementById("inductionPlanV3PlanTopicAreasForm");
            const planFK = form.dataset.planfk;
            const data = {
                "PlanFK": planFK,
                "TopicName": topicName
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            };
            const response = yield fetch(`/InductionPlanReport/CreateCustomTopic`, settings);
            if (response.ok) {
                const result = yield response.text();
                const div = document.createElement("div");
                div.innerHTML = result;
                const accordionList = document.getElementById("inductionPlanTopicAreasAccordionList");
                accordionList.append(div);
                this.bindAddDanielsons();
                this.bindDeleteTopics();
                new CustomAccordion(null);
            }
        });
    }
    checkForRequiredCheckboxes() {
        const allCheckboxes = document.getElementsByClassName("inductionPlanTopicAreasLookup");
        const lookupsRequired = [
            "inductionPlanV3TopicsCommonGround",
            "inductionPlanV3TopicsEducatorEffectiveness",
            "inductionPlanV3TopicsProfessionalEthics",
            "inductionPlanV3TopicsStudentLearning",
            "inductionPlanV3TopicsTeacherCompetency"
        ];
        let atLeastOne = false;
        for (const checkbox of allCheckboxes) {
            const element = checkbox;
            const lookupCode = element.dataset.lookupcode;
            if (lookupsRequired.indexOf(lookupCode) !== -1) {
                if (!element.checked) {
                    element.checked = true;
                    atLeastOne = true;
                }
            }
        }
        this._suppressToast = true;
        if (atLeastOne) {
            const reportButton = document.getElementById("inductionReportSelectedTopics");
            reportButton.click();
        }
        this._suppressToast = false;
    }
    bindEducatorEffectivenessCharterSchool() {
        const checkboxes = document.getElementsByClassName("educatorEffectivenessCharterSchool");
        for (const checkbox of checkboxes)
            checkbox.addEventListener("click", (e) => this.checkForEducatorEffectivenessCharterSchool());
    }
    checkForEducatorEffectivenessCharterSchool() {
        const checkboxes = document.getElementsByClassName("educatorEffectivenessCharterSchool");
        for (const checkbox of checkboxes) {
            const element = checkbox;
            const dataPointLookupCodePK = element.dataset.datapointlookupcodepk;
            const allElements = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasAccordionData[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);
            const addButton = document.querySelector(`.inductionPlanV3PlanTopicAreasAccordionDataButton[data-datapointlookupcodepk='${dataPointLookupCodePK}']`);
            if (element.checked) {
                for (const innerElement of allElements) {
                    const innerEle = innerElement;
                    this._core.forceElementOptional(innerEle);
                    innerEle.readOnly = true;
                    innerEle.disabled = true;
                    addButton.disabled = true;
                }
            }
            else {
                for (const innerElement of allElements) {
                    const innerEle = innerElement;
                    this._core.forceElementRequired(innerEle);
                    innerEle.readOnly = false;
                    innerEle.disabled = false;
                    addButton.disabled = false;
                }
            }
        }
    }
    doCustomValidation(allClasses) {
        const showMessage = this.customClientSideValidation(allClasses);
        const messageContainerColumn = document.getElementById("validationColumn");
        const messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        const validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            const message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            const successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const lookupsRequired = [
            "inductionPlanV3TopicsCommonGround",
            "inductionPlanV3TopicsEducatorEffectiveness",
            "inductionPlanV3TopicsProfessionalEthics",
            "inductionPlanV3TopicsStudentLearning",
            "inductionPlanV3TopicsTeacherCompetency"
        ];
        const allAccordions = document.querySelectorAll(`.InductionPlanTopicAreaParent`);
        for (const accord of allAccordions) {
            const element = accord;
            const lookupCode = element.dataset.lookupcode;
            const lookupCodePK = element.dataset.lookupcodepk;
            const dataPointPlanPropertyPK = element.dataset.planpropertypk;
            if (lookupsRequired.indexOf(lookupCode) !== -1) {
                const allElements = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasAccordionData[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                let keepGoing = true;
                if (lookupCode === "inductionPlanV3TopicsEducatorEffectiveness") {
                    const charterCheck = document.querySelector(`.educatorEffectivenessCharterSchool[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    if (charterCheck.checked)
                        keepGoing = false;
                }
                if (keepGoing) {
                    for (const innerEle of allElements) {
                        const innerElement = innerEle;
                        if (innerElement.classList.contains("input-with-add-button")) {
                            //This is the danielson framework element. Handle this differently than the others.
                            const danielsonListElements = document.querySelectorAll(`.addDanielsonFrameworkToList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .addDanielsonFrameworkToListItem`);
                            if (danielsonListElements.length === 0) {
                                innerElement.classList.add("missing-field");
                                innerElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(innerElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            const inputElement = innerElement;
                            if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
            else {
                //get parent accordion element. If it doesnt have a dataPointPlanPropertyPK value (ie. == 0), just ignore it completely, otherwise, it must be completed.
                if (dataPointPlanPropertyPK !== "0") {
                    const allElements = document.querySelectorAll(`.inductionPlanV3PlanTopicAreasAccordionData[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    for (const innerEle of allElements) {
                        const innerElement = innerEle;
                        if (innerElement.classList.contains("input-with-add-button")) {
                            //This is the danielson framework element. Handle this differently than the others.
                            const danielsonListElements = document.querySelectorAll(`.addDanielsonFrameworkToList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .addDanielsonFrameworkToListItem`);
                            if (danielsonListElements.length === 0) {
                                innerElement.classList.add("missing-field");
                                innerElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(innerElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            const inputElement = innerElement;
                            if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        const message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        const goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            const that = this;
            const firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    const accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");
                        const accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// InductionPlanV3Evaluation
class InductionPlanV3Evaluation {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanV3EvaluationField", "inductionPlanV3EvaluationFileUploadField"];
        const inductionPlanV3EvaluationSaveButton = document.getElementById("inductionPlanV3EvaluationSave");
        if (inductionPlanV3EvaluationSaveButton !== null)
            inductionPlanV3EvaluationSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const fileUploader = document.getElementById("inductionPlanV3PlanTopicAreasInductionProgramEvaluationExample");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindShowDeleteFile();
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const allFileElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3EvaluationForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("inductionPlanV3EvaluationField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const files = document.getElementsByClassName("inductionPlanV3EvaluationFileField");
        for (const ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = element.dataset.fileuploadpk;
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const data = {
            "FileData": allFileElements,
            "SaveData": allSaveElements,
            "PlanFK": planPK
        };
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3Evaluation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of " + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of " + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`#inductionPlanV3PlanTopicAreasInductionProgramEvaluationExample`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList`);
                        const currentFileList = document.querySelectorAll(".inductionPlanV3EvaluationFileField");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        const uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        const fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        const newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        const fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("inductionPlanV3EvaluationFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindShowDeleteFile() {
        const files = document.getElementsByClassName("deleteFile");
        for (const file of files)
            file.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.inductionPlanV3EvaluationFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`#uploadedFiles .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById("uploadedFiles");
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                const modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                const modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// InductionPlanV3Documentation
class InductionPlanV3Documentation {
    constructor() {
        this._core = new Core();
        this.checkForOtherRequired();
        this.validationClasses = ["inductionPlanV3DocumentationField", "inductionPlanV3DocumentationRadioField"];
        const inductionPlanV3DocumentationSaveButton = document.getElementById("inductionPlanV3DocumentationSave");
        if (inductionPlanV3DocumentationSaveButton !== null)
            inductionPlanV3DocumentationSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const requiresOthers = document.getElementsByClassName("requiresOther");
        for (const requiresOther of requiresOthers)
            requiresOther.addEventListener("change", (e) => this.checkForOther(e.target));
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3DocumentationForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("inductionPlanV3DocumentationField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("inductionPlanV3DocumentationRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3Documentation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkForOther(element) {
        const otherElementContainer = document.getElementById("otherRow");
        const otherElement = document.getElementById("inductionPlanV3DocumentationFirstYearTeachersNo");
        if (element.checked) {
            const lookupCode = element.dataset.lookupcode;
            if (lookupCode === "no") {
                otherElementContainer.classList.remove("hide");
                this._core.forceElementRequired(otherElement);
            }
            else {
                otherElementContainer.classList.add("hide");
                this._core.forceElementOptional(otherElement);
            }
        }
    }
    checkForOtherRequired() {
        const requiresOthers = document.getElementsByClassName("requiresOther");
        for (const requiresOther of requiresOthers)
            this.checkForOther(requiresOther);
    }
}
// InductionPlanV3SignaturesQA
class InductionPlanV3SignaturesQA {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["inductionPlanV3SignaturesQAField", "inductionPlanV3SignaturesQARadioField", "inductionPlanV3SignaturesQACheckboxField"];
        const inductionPlanV3SignaturesQASaveButton = document.getElementById("inductionPlanV3SignaturesQASave");
        if (inductionPlanV3SignaturesQASaveButton !== null)
            inductionPlanV3SignaturesQASaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("inductionPlanV3SignaturesQAForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("inductionPlanV3SignaturesQAField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("inductionPlanV3SignaturesQARadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const checkboxes = document.getElementsByClassName("inductionPlanV3SignaturesQACheckboxField");
        for (const ele of checkboxes) {
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPK = 0;
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            const saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/InductionPlanReport/SaveInductionPlanV3SignaturesQA', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// InductionPlanV3Summary
class InductionPlanV3Summary {
    constructor() {
        this._core = new Core();
        const backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        const submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        const newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        const element = e.target;
        if (!element.classList.contains("disabled")) {
            const planFK = parseInt(element.dataset.planfk);
            const core = this._core;
            Core.showLoader();
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/InductionPlanReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// Profile Essentials
class CurriculumProfileEssentials {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["curriculumReportsProfileEssentialsField"];
        let curriculumProfileSaveButton = document.getElementById("curriculumReportsProfileSave");
        if (curriculumProfileSaveButton !== null)
            curriculumProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("curriculumReportsProfileEssentialsForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("curriculumReportsProfileEssentialsForm");
        let inputs = document.getElementsByClassName("curriculumReportsProfileEssentialsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveProfileEssentials', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Academic Standards and Planning
class CurriculumAcademicStandardsAndPlanning {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["curriculumReportsAcademicStandardsField"];
        let curriculumStandardsSaveButton = document.getElementById("curriculumReportsAcademicStandardsAndPlanningSave");
        if (curriculumStandardsSaveButton !== null)
            curriculumStandardsSaveButton.addEventListener("click", (e) => this.save("save"));
        let curriculumStandardsExportToWordButton = document.getElementById("curriculumReportsAcademicStandardsAndPlanningExportToWord");
        if (curriculumStandardsExportToWordButton !== null)
            curriculumStandardsExportToWordButton.addEventListener("click", (e) => this.exportToWord());
        let curriculumStandardsExportToExcelButton = document.getElementById("curriculumReportsAcademicStandardsAndPlanningExportToExcel");
        if (curriculumStandardsExportToExcelButton !== null)
            curriculumStandardsExportToExcelButton.addEventListener("click", (e) => this.exportToExcel());
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let checkboxes = document.getElementsByClassName("curriculumReportsAcademicStandardsCheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e) => this.checkForReadonly(e));
    }
    checkForReadonly(e) {
        let checkbox = e.srcElement;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    exportToWord() {
    }
    exportToExcel() {
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let standardsFormElement = document.getElementById("curriculumReportsAcademicStandardsAndPlanningForm");
        let inputs = document.getElementsByClassName("curriculumReportsAcademicStandardsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(standardsFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked) {
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
            else {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.dataset.lookupcodepk),
                    RowNbr: rowNumber,
                    IsDeletedInd: true
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAcademicStandardsAndPlanning', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                //TODO: come back to this when we have client-side validation for checkboxes
                //core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Assurances Standards Alignment
class CurriculumAssurancesStandardsAlignment {
    constructor() {
        this._core = new Core();
        this.validationClasses = [
            "assurancesStandardsAlignmentRadioField",
            "assurancesStandardsAlignmentField",
            "assurancesStandardsAlignmentFileField"
        ];
        let curriculumStandardsAlignmentSaveButton = document.getElementById("curriculumReportsAssurancesStandardsAlignmentSave");
        if (curriculumStandardsAlignmentSaveButton !== null)
            curriculumStandardsAlignmentSaveButton.addEventListener("click", (e) => this.save("save"));
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);
        let standardsAlignmentRadioButtons = document.querySelectorAll(".assurancesStandardsAlignmentRadioField:not([data-propertycode='curriculumReportsStandardsLEAStandardized']):not([data-propertycode='curriculumReportsStandardsIUStandardized'])");
        for (let standardsAlignmentRadioButton of standardsAlignmentRadioButtons) {
            standardsAlignmentRadioButton.addEventListener("change", (e) => this.noReasonShowOrHide(e.srcElement));
            this.noReasonShowOrHide(standardsAlignmentRadioButton);
        }
        let standardizedFormatRadioButtons = document.querySelectorAll(".assurancesStandardsAlignmentRadioField[data-propertycode='curriculumReportsStandardsLEAStandardized'], .assurancesStandardsAlignmentRadioField[data-propertycode='curriculumReportsStandardsIUStandardized']");
        for (let standardizedFormatRadioButton of standardizedFormatRadioButtons) {
            standardizedFormatRadioButton.addEventListener("change", (e) => this.standardizedFormatShowOrHide(e.srcElement));
            this.standardizedFormatShowOrHide(standardizedFormatRadioButton);
        }
        let fileUploader = document.getElementById("uploadSampleFile");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.srcElement));
        }
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e) => this.showDeleteFileConfirmation(deleteButton));
            }
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = document.getElementById("uploadSample");
        let formData = new FormData(uploadSampleForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("uploadSampleFile");
                        formFile.value = "";
                        formFile.dataset.hasUploaded = "true";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFileConfirmation(deleteButton) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    noReasonShowOrHide(e) {
        let thisRadio = e;
        if (thisRadio.checked) {
            let name = thisRadio.name;
            let trToShowOrHide = document.querySelector(`tr[data-name='${name}']`);
            if (trToShowOrHide !== null) {
                let hiddenField = trToShowOrHide.querySelector(".assurancesStandardsAlignmentField");
                if (thisRadio.dataset.lookupcode === "yes") {
                    trToShowOrHide.classList.add("hide");
                    if (hiddenField != null) {
                        this._core.forceElementOptional(hiddenField);
                    }
                }
                else {
                    trToShowOrHide.classList.remove("hide");
                    if (hiddenField != null) {
                        this._core.forceElementRequired(hiddenField);
                    }
                }
            }
        }
    }
    standardizedFormatShowOrHide(e) {
        let thisRadio = e;
        if (thisRadio.checked) {
            let elementToShowOrHide = document.querySelector(`div[data-name='curriculumReportsStandardsLocallyDevelopedSample'], div[data-name='curriculumReportsStandardsLocallyDevelopedSampleIU']`);
            if (elementToShowOrHide !== null) {
                let hiddenField = document.getElementById("uploadSampleFile");
                if (thisRadio.dataset.lookupcode === "no") {
                    elementToShowOrHide.classList.add("hide");
                    if (hiddenField != null) {
                        this._core.forceElementOptional(hiddenField);
                    }
                }
                else {
                    elementToShowOrHide.classList.remove("hide");
                    if (hiddenField != null) {
                        this._core.forceElementRequired(hiddenField);
                    }
                }
            }
        }
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("curriculumReportsAssurancesStandardsAlignmentForm");
        let inputs = document.getElementsByClassName("assurancesStandardsAlignmentField");
        let radios = document.getElementsByClassName("assurancesStandardsAlignmentRadioField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssurancesStandardsAlignment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// AssurancesCurriculumAndInstruction
class CurriculumAssurancesCurriculumAndInstruction {
    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = [
            "curriculumReportsAssurancesCurriculumAndInstructionField",
            "curriculumReportsAssurancesCurriculumAndInstructionRadioField",
            "curriculumReportsAssurancesCurriculumAndInstructionMultiSelectField",
            "curriculumReportsAssurancesCurriculumAndInstructionSelectField"
        ];
        let curriculumCurriculumAndInstructionSaveButton = document.getElementById("curriculumReportsAssurancesCurriculumAndInstructionSave");
        if (curriculumCurriculumAndInstructionSaveButton !== null)
            curriculumCurriculumAndInstructionSaveButton.addEventListener("click", (e) => this.save("save"));
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);
        let instructionalRadioButtons = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionRadioField");
        for (let instructionalRadioButton of instructionalRadioButtons) {
            instructionalRadioButton.addEventListener("change", (e) => this.noReasonShowOrHide(e.srcElement));
            this.noReasonShowOrHide(instructionalRadioButton);
        }
        let sloCheckbox = document.getElementById("omitSLOCheckbox");
        let fieldToToggle = document.querySelector(".sloInform[data-required]");
        if (sloCheckbox != null && fieldToToggle != null) {
            if (sloCheckbox.checked) {
                that._core.forceElementOptional(fieldToToggle);
            }
            else {
                that._core.forceElementRequired(fieldToToggle);
                fieldToToggle.readOnly = false;
            }
            sloCheckbox.addEventListener("change", () => {
                if (sloCheckbox.checked) {
                    that._core.forceElementOptional(fieldToToggle);
                    fieldToToggle.readOnly = true;
                }
                else {
                    that._core.forceElementRequired(fieldToToggle);
                    fieldToToggle.readOnly = false;
                }
            });
        }
        let charterAct13OmitCheckbox = document.getElementById("curriculumReportsInstructionCharterOmitId");
        let act13FieldsToToggle = document.querySelectorAll(".act13Field[data-required]");
        if (charterAct13OmitCheckbox != null && act13FieldsToToggle != null) {
            if (charterAct13OmitCheckbox.checked) {
                for (let act13FieldToToggle of act13FieldsToToggle) {
                    let act13FieldToToggleEle = act13FieldToToggle;
                    that._core.forceElementOptional(act13FieldToToggleEle);
                    if (act13FieldToToggleEle instanceof HTMLInputElement) {
                        let Act13FieldToToggleEle = act13FieldToToggleEle;
                        Act13FieldToToggleEle.readOnly = true;
                    }
                    else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                        let Act13FieldToToggleSelectElement = act13FieldToToggleEle;
                        Act13FieldToToggleSelectElement.disabled = true;
                    }
                    else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                        let Act13FieldToToggleTextAreaElement = act13FieldToToggleEle;
                        Act13FieldToToggleTextAreaElement.readOnly = true;
                    }
                }
            }
            else {
                for (let act13FieldToToggle of act13FieldsToToggle) {
                    let act13FieldToToggleEle = act13FieldToToggle;
                    that._core.forceElementRequired(act13FieldToToggleEle);
                    if (act13FieldToToggleEle instanceof HTMLInputElement) {
                        let Act13FieldToToggleEle = act13FieldToToggleEle;
                        Act13FieldToToggleEle.readOnly = false;
                    }
                    else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                        let Act13FieldToToggleSelectElement = act13FieldToToggleEle;
                        Act13FieldToToggleSelectElement.disabled = false;
                    }
                    else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                        let Act13FieldToToggleTextAreaElement = act13FieldToToggleEle;
                        Act13FieldToToggleTextAreaElement.readOnly = false;
                    }
                }
            }
            charterAct13OmitCheckbox.addEventListener("change", () => {
                if (charterAct13OmitCheckbox.checked) {
                    for (let act13FieldToToggle of act13FieldsToToggle) {
                        let act13FieldToToggleEle = act13FieldToToggle;
                        that._core.forceElementOptional(act13FieldToToggleEle);
                        if (act13FieldToToggleEle instanceof HTMLInputElement) {
                            let Act13FieldToToggleEle = act13FieldToToggleEle;
                            Act13FieldToToggleEle.readOnly = true;
                        }
                        else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                            let Act13FieldToToggleSelectElement = act13FieldToToggleEle;
                            Act13FieldToToggleSelectElement.disabled = true;
                        }
                        else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                            let Act13FieldToToggleTextAreaElement = act13FieldToToggleEle;
                            Act13FieldToToggleTextAreaElement.readOnly = true;
                        }
                    }
                }
                else {
                    for (let act13FieldToToggle of act13FieldsToToggle) {
                        let act13FieldToToggleEle = act13FieldToToggle;
                        that._core.forceElementRequired(act13FieldToToggleEle);
                        if (act13FieldToToggleEle instanceof HTMLInputElement) {
                            let Act13FieldToToggleEle = act13FieldToToggleEle;
                            Act13FieldToToggleEle.readOnly = false;
                        }
                        else if (act13FieldToToggleEle instanceof HTMLSelectElement) {
                            let Act13FieldToToggleSelectElement = act13FieldToToggleEle;
                            Act13FieldToToggleSelectElement.disabled = false;
                        }
                        else if (act13FieldToToggleEle instanceof HTMLTextAreaElement) {
                            let Act13FieldToToggleTextAreaElement = act13FieldToToggleEle;
                            Act13FieldToToggleTextAreaElement.readOnly = false;
                        }
                    }
                }
            });
        }
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let checkboxes = document.getElementsByClassName("commonCIACheckbox");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e) => this.checkForReadonly(e));
        let morecheckboxes = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionCheckboxField");
        for (let morecheckbox of morecheckboxes)
            morecheckbox.addEventListener("click", (e) => this.checkForReadonly(e));
        let ciaNotIUExplainCheckboxElement = document.getElementById("curriculumReportsInstructionNoIUId");
        if (ciaNotIUExplainCheckboxElement !== null)
            ciaNotIUExplainCheckboxElement.addEventListener("change", (e) => this.IUExplainShowHide(e));
        let percentageOfEducatorsEvaluatedAct13TextBoxes = document.querySelectorAll('input.curriculumReportsAssurancesCurriculumAndInstructionField.number-box.act13Field');
        let totalInput = document.querySelector('input.number-box-total');
        this.totalPercentageOfEducatorsEvaluatedAct13(percentageOfEducatorsEvaluatedAct13TextBoxes, totalInput);
        for (let percentageOfEducatorsEvaluatedAct13TextBox of percentageOfEducatorsEvaluatedAct13TextBoxes) {
            percentageOfEducatorsEvaluatedAct13TextBox.addEventListener("input", (e) => this.totalPercentageOfEducatorsEvaluatedAct13(percentageOfEducatorsEvaluatedAct13TextBoxes, totalInput));
        }
    }
    checkForReadonly(e) {
        let checkbox = e.srcElement;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    noReasonShowOrHide(e) {
        let thisRadio = e;
        if (thisRadio.checked) {
            let name = thisRadio.name;
            let trToShowOrHide = document.querySelector(`tr[data-name='${name}']`);
            if (trToShowOrHide !== null) {
                let hiddenField = trToShowOrHide.querySelector(".curriculumReportsAssurancesCurriculumAndInstructionField");
                if (thisRadio.dataset.lookupcode === "yes") {
                    trToShowOrHide.classList.add("hide");
                    if (hiddenField != null) {
                        this._core.forceElementOptional(hiddenField);
                    }
                }
                else {
                    trToShowOrHide.classList.remove("hide");
                    if (hiddenField != null) {
                        this._core.forceElementRequired(hiddenField);
                    }
                }
            }
        }
    }
    totalPercentageOfEducatorsEvaluatedAct13(inputsInColumn, totalInput) {
        let total = 0;
        for (const input of inputsInColumn) {
            if (input.value && !isNaN(parseInt(input.value)))
                total += parseInt(input.value);
        }
        totalInput.value = total.toString();
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("curriculumReportsAssurancesCurriculumAndInstructionForm");
        let inputs = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionField");
        let radios = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionRadioField");
        let multis = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionMultiSelectField");
        let selects = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionSelectField");
        let checkboxInputs = document.getElementsByClassName("curriculumReportsAssurancesCurriculumAndInstructionCheckboxField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let value = element.value;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.id == "omitSLOCheckbox") {
                if (element.checked) {
                    value = "on";
                }
                else {
                    value = "off";
                }
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        for (let parent of multis) {
            let parentElement = parent;
            let parentPropertyPK = parentElement.dataset.propertypk;
            let innerElements = parentElement.querySelectorAll(`input[data-propertypk='${parentPropertyPK}']`);
            for (let ele of innerElements) {
                let planPropertyPK = 0;
                let element = ele;
                let rowNumber = 0;
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.checked) {
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (element.value !== "" || hadValue) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: "",
                            LookupCodeFK: parseInt(element.dataset.lookupcodepk),
                            RowNbr: rowNumber,
                            IsDeletedInd: false
                        };
                        allSaveElements.push(saveItem);
                    }
                }
                else {
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: "",
                            LookupCodeFK: parseInt(element.dataset.lookupcodepk),
                            RowNbr: rowNumber,
                            IsDeletedInd: true
                        };
                        allSaveElements.push(saveItem);
                    }
                }
            }
        }
        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssurancesCurriculumAndInstruction', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    IUExplainShowHide(e) {
        let element = e.srcElement;
        let divElement = document.getElementById("ciaNotIUExplain");
        let textElement = document.getElementById("curriculumReportsInstructionNoIUExplainId");
        if (element.checked) {
            divElement.classList.remove("hide");
            this._core.forceElementRequired(textElement);
        }
        else {
            divElement.classList.add("hide");
            this._core.forceElementOptional(textElement);
        }
    }
}
// Assessment
class CurriculumAssessment {
    constructor() {
        this._core = new Core();
        this.deleteAssessmentModal = new Modal("deleteAssessmentModal", null);
        this.validationClasses = [
            "curriculumReportsAssessmentField",
            "curriculumReportsAssessmentRadioField",
            "curriculumReportsAssessmentSelectField",
            "curriculumReportsAssessmentFileUploadField"
        ];
        let curriculumAssessmentSaveButton = document.getElementById("curriculumReportsAssessmentSave");
        if (curriculumAssessmentSaveButton !== null)
            curriculumAssessmentSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let addAnAssessmentButton = document.getElementById("addAnAssessment");
        if (addAnAssessmentButton !== null)
            addAnAssessmentButton.addEventListener("click", (e) => this.addAnAssessment(e));
        let fileUploader3rdGrade = document.getElementById("earlycurriculumReportsAssessmentGrade3Document");
        if (fileUploader3rdGrade !== null) {
            fileUploader3rdGrade.addEventListener("change", (e) => this.uploadFile(e.srcElement, "UploadGrade3Document", "earlycurriculumReportsAssessmentGrade3Document"));
        }
        let fileUploader7thGrade = document.getElementById("earlycurriculumReportsAssessmentGrade7Document");
        if (fileUploader7thGrade !== null) {
            fileUploader7thGrade.addEventListener("change", (e) => this.uploadFile(e.srcElement, "UploadGrade7Document", "earlycurriculumReportsAssessmentGrade7Document"));
        }
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e) => this.showDeleteFileConfirmation(deleteButton));
            }
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let that = this;
        document.addEventListener("click", function (e) {
            let buttonElement = event.target;
            if (buttonElement.classList.contains("deleteAssessment") && buttonElement instanceof HTMLButtonElement) {
                e.preventDefault();
                let button = buttonElement;
                if ("hasdata" in button.dataset && button.dataset.hasdata == "True") {
                    that.deleteAssessmentModal.show();
                    that.deleteAssessmentModal.addAttributeToElement(that.deleteAssessmentModal.id, "#deleteAssessmentConfirm", "row", button.dataset.row);
                }
                else {
                    //remove from DOM
                    let container = document.getElementById(button.dataset.container);
                    if ("container" in button.dataset && document.getElementById(button.dataset.container) != null) {
                        let container = document.getElementById(button.dataset.container);
                        container.parentNode.removeChild(container);
                        Core.createHTMLAlert("alertMessageDiv", "Successfully removed assessment row", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an error removing assessment row", 'error', 3000, null);
                    }
                }
            }
            ;
        });
        let deleteAssessmentConfirmButton = document.getElementById("deleteAssessmentConfirm");
        if (deleteAssessmentConfirmButton !== null) {
            deleteAssessmentConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if (deleteAssessmentConfirmButton.dataset.row) {
                    that.deleteAssessment(deleteAssessmentConfirmButton.dataset.row);
                }
                else {
                    that.deleteAssessmentModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this assessment", "error", 3000, null);
                }
            });
        }
        let deleteAssessmentCancelButton = document.getElementById("deleteAssessmentCancel");
        if (deleteAssessmentCancelButton !== null) {
            deleteAssessmentCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteAssessmentModal.hide();
            });
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(e, formElementId, fileElementId) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = document.getElementById(formElementId);
        let formData = new FormData(uploadSampleForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById(fileElementId);
                        formFile.value = "";
                        formFile.dataset.hasuploaded = "true";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFileConfirmation(deleteButton) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    addAnAssessment(e) {
        let core = this._core;
        let newRow = 0;
        let _that = this;
        let profileFormElement = document.getElementById("curriculumReportsAssessmentForm");
        let planPK = parseInt(profileFormElement.dataset.planfk);
        let assessmentFields = document.getElementsByClassName("curriculumReportsAssessmentFieldRow");
        for (let assmt of assessmentFields) {
            let assmtElement = assmt;
            if (newRow < parseInt(assmtElement.dataset.row))
                newRow = parseInt(assmtElement.dataset.row);
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/AddAnAssessment', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $("#assessmentAssessmentList").append(xhr.responseText);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Assessment Added", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("newRow=" + newRow + "&planFK=" + planPK);
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("curriculumReportsAssessmentForm");
        let inputs = document.getElementsByClassName("curriculumReportsAssessmentField");
        let radios = document.getElementsByClassName("curriculumReportsAssessmentRadioField");
        let selects = document.getElementsByClassName("curriculumReportsAssessmentSelectField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssessment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    deleteAssessment(rowNumber) {
        let that = this;
        that.deleteAssessmentModal.hide();
        let planPK = 0;
        let formElement = document.getElementById("curriculumReportsAssessmentForm");
        if (formElement != null) {
            planPK = parseInt(formElement.dataset.planfk);
        }
        let deleteAssessmentButton = document.querySelector(`.deleteAssessment[data-row='${rowNumber}']`);
        if (deleteAssessmentButton != null && "container" in deleteAssessmentButton.dataset && document.getElementById(deleteAssessmentButton.dataset.container) != null) {
            let toRemove = [];
            let assessmentInputFields = document.querySelectorAll(`.assessment-list-item[data-row='${rowNumber}'] .curriculumReportsAssessmentField[data-row='${rowNumber}']`);
            let assessmentRadioFields = document.querySelectorAll(`.assessment-list-item[data-row='${rowNumber}'] .curriculumReportsAssessmentRadioField[data-row='${rowNumber}']`);
            let assessmentSelectFields = document.querySelectorAll(`.assessment-list-item[data-row='${rowNumber}'] .curriculumReportsAssessmentSelectField[data-row='${rowNumber}']`);
            for (let ele of assessmentInputFields) {
                let planPropertyPK = 0;
                let element = ele;
                let rowNumber = "0";
                if (element.dataset.row !== null)
                    rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                }
                let removeItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: true
                };
                toRemove.push(removeItem);
            }
            for (let ele of assessmentRadioFields) {
                let planPropertyPK = 0;
                let element = ele;
                let rowNumber = "0";
                if (element.dataset.row !== null)
                    rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                if (element.checked) {
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                    }
                    let removeItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: true
                    };
                    toRemove.push(removeItem);
                }
            }
            for (let ele of assessmentSelectFields) {
                let planPropertyPK = 0;
                let element = ele;
                let rowNumber = "0";
                if (element.dataset.row !== null)
                    rowNumber = element.dataset.row;
                let propertyPK = parseInt(element.dataset.propertypk);
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                }
                let removeItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: true
                };
                toRemove.push(removeItem);
            }
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveAssessment', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Assessment successfully deleted", 'success', 3000, null);
                    //remove from DOM
                    let container = document.getElementById(deleteAssessmentButton.dataset.container);
                    container.parentNode.removeChild(container);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this activity. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(JSON.stringify(toRemove));
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting assessment. Please try again later.", "error", 3000, null);
        }
    }
}
// Signature and Submit
class CurriculumSignatureAndSubmit {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["curriculumReportsSignatureAndSubmitField"];
        let curriculumSignatureAndSubmitSaveButton = document.getElementById("curriculumReportsSignatureAndSubmitSave");
        if (curriculumSignatureAndSubmitSaveButton !== null)
            curriculumSignatureAndSubmitSaveButton.addEventListener("click", (e) => this.save("save"));
        //let continueButton = document.getElementById("continueButton");
        //if (continueButton !== null)
        //    continueButton.addEventListener("click", (e: Event) => this._core.continue(e));
        //let backButton = document.getElementById("backButton");
        //if (backButton !== null)
        //    backButton.addEventListener("click", (e: Event) => this._core.back(e));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("curriculumReportsSignatureAndSubmitForm");
        let inputs = document.getElementsByClassName("curriculumReportsSignatureAndSubmitField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveSignatureAndSubmit', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Curriculum Summary Checklist and Submission
class CurriculumSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CurriculumInstructionAssessmentReports/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// CurriculumReportsV3StandardsAssess
class CurriculumReportsV3StandardsAssess {
    constructor() {
        this._core = new Core();
        this.iuOriginalPercent();
        this.checkForCTCorIU();
        this.checkIUNotOffering();
        this.validationClasses = ["curriculumReportsV3StandardsAssessField", "curriculumReportsV3StandardsAssessRadioField", "curriculumReportsV3StandardsAssessCheckboxField", "curriculumReportsV3StandardsAssessSelectField"];
        let curriculumReportsV3StandardsAssessSaveButton = document.getElementById("curriculumReportsV3StandardsAssessSave");
        if (curriculumReportsV3StandardsAssessSaveButton !== null)
            curriculumReportsV3StandardsAssessSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const studentPopulationNumbersFields = document.getElementsByClassName("studentPopulationNumbers");
        for (const studentPopulationNumbersField of studentPopulationNumbersFields)
            studentPopulationNumbersField.addEventListener("input", (e) => this.sumTotalPopulationNumbers());
        const curriculumReportsV3TaughtInEntityFields = document.getElementsByClassName("curriculumReportsV3TaughtInEntity");
        for (const curriculumReportsV3TaughtInEntityField of curriculumReportsV3TaughtInEntityFields)
            curriculumReportsV3TaughtInEntityField.addEventListener("change", (e) => this.checkForTaughtInEntity());
        this.checkForTaughtInEntity();
        const curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist = document.getElementById("curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist");
        if (curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist !== null)
            curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist.addEventListener("change", (e) => this.checkForCTCorIU());
        const curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms = document.getElementById("curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms");
        if (curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms !== null)
            curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms.addEventListener("change", (e) => this.checkIUNotOffering());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("curriculumReportsV3StandardsAssessForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("curriculumReportsV3StandardsAssessField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const selects = document.getElementsByClassName("curriculumReportsV3StandardsAssessSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("curriculumReportsV3StandardsAssessCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        const radios = document.getElementsByClassName("curriculumReportsV3StandardsAssessRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveCurriculumReportsV3StandardsAssess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    sumTotalPopulationNumbers() {
        const totalPopulationField = document.getElementById("curriculumReportsV3StandardsAssessStudentPopulationTotal");
        let totalSum = 0;
        const studentPopulationNumbersFields = document.getElementsByClassName("studentPopulationNumbers");
        for (const studentPopulationNumbersField of studentPopulationNumbersFields) {
            const parsedValue = parseInt(studentPopulationNumbersField.value);
            if (!isNaN(parsedValue))
                totalSum += parsedValue;
        }
        totalPopulationField.value = totalSum.toString();
    }
    checkForTaughtInEntity() {
        const curriculumReportsV3TaughtInEntityFields = document.getElementsByClassName("curriculumReportsV3TaughtInEntity");
        for (const curriculumReportsV3TaughtInEntityField of curriculumReportsV3TaughtInEntityFields) {
            const element = curriculumReportsV3TaughtInEntityField;
            if (element.checked) {
                const entity = element.dataset.entity;
                const value = element.dataset.lookupcode;
                const numberElement = document.querySelector(`.studentPopulationNumbers[data-entity='${entity}']`);
                if (value === "no") {
                    numberElement.readOnly = true;
                    numberElement.value = "0";
                }
                else {
                    numberElement.readOnly = false;
                }
            }
        }
        this.sumTotalPopulationNumbers();
    }
    checkForCTCorIU() {
        const curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist = document.getElementById("curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist");
        if (curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist !== null) {
            const ctcContainerElement = document.getElementById("curriculumReportsV3StandardsAssessmentCTC");
            const ctcElement = document.getElementById("curriculumReportsV3StandardsAssessThisParttimeCTCoffersthefollowingcorecontent");
            const selectedElement = curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist[curriculumReportsV3StandardsAssessIdentifyyourschoolentitytypefromthedropdownlist.selectedIndex];
            const iuContainerElement = document.getElementById("iuOnly");
            const iuTextElements = document.getElementsByClassName("curriculumReportIUOnlyText");
            const iuRadioElements = document.getElementsByClassName("curriculumReportIUOnlyRadio");
            const reportTypeElement = document.getElementById("curriculumReportsV3StandardsAssessForm");
            let reportType = "";
            if (reportTypeElement !== null) {
                reportType = reportTypeElement.dataset.reporttemplate;
            }
            if (selectedElement.dataset.lookupcode === "curriculumSchoolEntityPartTimeCTC") { //Part-Time CTC
                if (reportType !== "curriculumreportsiuv3") {
                    ctcContainerElement.classList.remove("hide");
                    this._core.forceElementRequired(ctcElement);
                    if (iuContainerElement !== null) {
                        iuContainerElement.classList.add("hide");
                        for (const iuText of iuTextElements) {
                            const ele = iuText;
                            this._core.forceElementOptional(ele);
                        }
                        for (const iuRadio of iuRadioElements) {
                            const ele = iuRadio;
                            this._core.forceElementOptional(ele);
                        }
                    }
                }
            }
            else if (selectedElement.dataset.lookupcode === "curriculumSchoolEntityIntermediateUnit") { //IU
                ctcContainerElement.classList.add("hide");
                this._core.forceElementOptional(ctcElement);
                if (iuContainerElement !== null) {
                    iuContainerElement.classList.remove("hide");
                    for (const iuTextT of iuTextElements) {
                        const eleT = iuTextT;
                        if (eleT.dataset.originalpercent === "1.0")
                            this._core.forceElementRequired(eleT);
                    }
                    for (const iuRadioR of iuRadioElements) {
                        const eleR = iuRadioR;
                        if (eleR.dataset.originalpercent === "1.0")
                            this._core.forceElementRequired(eleR);
                    }
                }
            }
            else {
                ctcContainerElement.classList.add("hide");
                this._core.forceElementOptional(ctcElement);
                if (iuContainerElement !== null) {
                    iuContainerElement.classList.add("hide");
                    for (const iuTextTT of iuTextElements) {
                        const eleTT = iuTextTT;
                        this._core.forceElementOptional(eleTT);
                    }
                    for (const iuRadioRR of iuRadioElements) {
                        const eleRR = iuRadioRR;
                        this._core.forceElementOptional(eleRR);
                    }
                }
            }
        }
    }
    iuOriginalPercent() {
        const iuTextElements = document.getElementsByClassName("curriculumReportIUOnlyText");
        const iuRadioElements = document.getElementsByClassName("curriculumReportIUOnlyRadio");
        for (const text of iuTextElements) {
            const ele = text;
            ele.dataset.originalpercent = ele.dataset.percent;
        }
        for (const radio of iuRadioElements) {
            const ele = radio;
            ele.dataset.originalpercent = ele.dataset.percent;
        }
    }
    checkIUNotOffering() {
        const curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms = document.getElementById("curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms");
        if (curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms !== null) {
            const ele = curriculumReportsV3StandardsAssessThisIUdoesNOToffercenterbasedprograms;
            const iuTextElements = document.getElementsByClassName("curriculumReportIUOnlyText");
            const iuRadioElements = document.getElementsByClassName("curriculumReportIUOnlyRadio");
            if (ele.checked) {
                for (const textE of iuTextElements) {
                    const eleT = textE;
                    eleT.value = "";
                    eleT.readOnly = true;
                    this._core.forceElementOptional(eleT);
                }
                for (const radioE of iuRadioElements) {
                    const eleR = radioE;
                    eleR.checked = false;
                    eleR.disabled = true;
                    this._core.forceElementOptional(eleR);
                }
            }
            else {
                for (const textEle of iuTextElements) {
                    const eleTT = textEle;
                    eleTT.readOnly = false;
                    if (eleTT.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(eleTT);
                }
                for (const radioEle of iuRadioElements) {
                    const eleRR = radioEle;
                    eleRR.disabled = false;
                    if (eleRR.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(eleRR);
                }
            }
        }
    }
}
// CurriculumReportsV3StandardsAlign
class CurriculumReportsV3StandardsAlign {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["curriculumReportsV3StandardsAlignField", "curriculumReportsV3StandardsAlignRadioField", "curriculumReportsV3StandardsAlignCheckboxField", "curriculumReportsV3StandardsAlignFileField"];
        let curriculumReportsV3StandardsAlignSaveButton = document.getElementById("curriculumReportsV3StandardsAlignSave");
        if (curriculumReportsV3StandardsAlignSaveButton !== null)
            curriculumReportsV3StandardsAlignSaveButton.addEventListener("click", (e) => this.save("save"));
        this.checkAllAlignmentQuestions();
        this.checkCTC();
        const curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply = document.getElementById("curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply");
        if (curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply !== null) {
            curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply.addEventListener("change", (e) => this.contentDoesNotApply(e.srcElement));
            this.contentDoesNotApply(curriculumReportsV3StandardsAlignSecondaryGradeLevelcontentdoesnotapply);
        }
        const curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply = document.getElementById("curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply");
        if (curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply !== null) {
            curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply.addEventListener("change", (e) => this.contentDoesNotApply(e.srcElement));
            this.contentDoesNotApply(curriculumReportsV3StandardsAlignElementaryGradeLevelcontentdoesnotapply);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const curriculumReportsV3AlignmentQuestions = document.getElementsByClassName("curriculumReportsV3AlignmentQuestions");
        for (const curriculumReportsV3AlignmentQuestion of curriculumReportsV3AlignmentQuestions)
            curriculumReportsV3AlignmentQuestion.addEventListener("change", (e) => this.checkAlignmentQuestions(e.srcElement));
        const fileUploads = document.querySelectorAll(`.curriculumReportsV3StandardsAlignFileField[type='file']`);
        for (const fileUpload of fileUploads)
            fileUpload.addEventListener("change", (e) => this.uploadFile(e.srcElement, fileUpload.dataset.filetype));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("curriculumReportsV3StandardsAlignForm");
        let planPK = parseInt(formElement.dataset.newplanfk);
        let textInputs = document.getElementsByClassName("curriculumReportsV3StandardsAlignField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("curriculumReportsV3StandardsAlignRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const checkboxes = document.getElementsByClassName("curriculumReportsV3StandardsAlignCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("curriculumReportsV3StandardsAlignFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveCurriculumReportsV3StandardsAlign', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData.ElementData.length == 0 && allData.FileData.length == 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    checkAllAlignmentQuestions() {
        const curriculumReportsV3AlignmentQuestions = document.getElementsByClassName("curriculumReportsV3AlignmentQuestions");
        for (const curriculumReportsV3AlignmentQuestion of curriculumReportsV3AlignmentQuestions)
            this.checkAlignmentQuestions(curriculumReportsV3AlignmentQuestion);
    }
    checkAlignmentQuestions(srcElement) {
        const questionNumber = srcElement.dataset.question;
        const textElement = document.querySelector(`.curriculumReportsV3AlignmentQuestionsNoResponse[data-question='${questionNumber}']`);
        const textElementContainer = document.querySelector(`.curriculumReportsV3AlignmentQuestionsNoResponseContainer[data-question='${questionNumber}']`);
        if (srcElement.checked) {
            if (srcElement.dataset.lookupcode === "no") {
                this._core.forceElementRequired(textElement);
                textElementContainer.classList.remove("hide");
                if (srcElement.dataset.question === "5") {
                    const allCheckboxes = document.getElementsByClassName("curriculumReportsV3StandardsAlignCheckboxField");
                    for (const checkbox of allCheckboxes) {
                        const checkEle = checkbox;
                        this._core.forceElementOptional(checkEle);
                    }
                    const allFiles = document.getElementsByClassName("curriculumReportsV3StandardsAlignFileField");
                    for (const file of allFiles) {
                        const fileEle = file;
                        this._core.forceElementOptional(fileEle);
                    }
                    const elementaryAndSecondaryEle = document.getElementById("elementaryAndSecondary");
                    elementaryAndSecondaryEle.classList.add("hide");
                }
            }
            else {
                this._core.forceElementOptional(textElement);
                textElementContainer.classList.add("hide");
                if (srcElement.dataset.question === "5") {
                    const allCheckboxes = document.getElementsByClassName("curriculumReportsV3StandardsAlignCheckboxField");
                    for (const checkbox of allCheckboxes) {
                        const checkEle = checkbox;
                        this._core.forceElementOptional(checkEle);
                    }
                    const allFiles = document.getElementsByClassName("curriculumReportsV3StandardsAlignFileField");
                    for (const file of allFiles) {
                        const fileEle = file;
                        this._core.forceElementRequired(fileEle);
                    }
                    const elementaryAndSecondaryEle = document.getElementById("elementaryAndSecondary");
                    elementaryAndSecondaryEle.classList.remove("hide");
                }
            }
        }
    }
    checkCTC() {
        const excludeCTCElement = document.getElementById("excludeCTC");
        if (excludeCTCElement.value === "true") {
            const excludes = document.getElementsByClassName("ctcExclude");
            for (const exclude of excludes) {
                const ele = exclude;
                this._core.forceElementOptional(ele);
                ele.disabled = true;
            }
        }
    }
    uploadFile(e, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.curriculumReportsV3StandardsAlignFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList[data-filetype='${fileType}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("curriculumReportsV3StandardsAlignFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.srcElement;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.curriculumReportsV3StandardsAlignFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    contentDoesNotApply(element) {
        const fileType = element.dataset.filetype;
        const container = document.querySelector(`.fileUploaderContainer[data-filetype='${fileType}']`);
        const fileUploader = document.querySelector(`.curriculumReportsV3StandardsAlignFileField[data-filetype='${fileType}'][type='file']`);
        if (element.checked) {
            container.classList.add("hide");
            this._core.forceElementOptional(fileUploader);
        }
        else {
            container.classList.remove("hide");
            this._core.forceElementRequired(fileUploader);
        }
    }
    initializeRequiredFieldsCustom(allClasses) {
        this._core.initializeRequiredFields(allClasses);
    }
}
// CurriculumReportsV3EdEffectiveness
class CurriculumReportsV3EdEffectiveness {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["curriculumReportsV3EdEffectivenessField", "curriculumReportsV3EdEffectivenessCheckboxField", "curriculumReportsV3EdEffectivenessRadioField", "curriculumReportsV3EdEffectivenessSelectField"];
        this.addOriginalPercent();
        this.checkForAct13NotUsed();
        this.totalAllCategoryValues();
        let curriculumReportsV3EdEffectivenessSaveButton = document.getElementById("curriculumReportsV3EdEffectivenessSave");
        if (curriculumReportsV3EdEffectivenessSaveButton !== null)
            curriculumReportsV3EdEffectivenessSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const curriculumReportsV3EdEffectivenessAct13NotUsedChecks = document.getElementsByClassName("curriculumReportsV3EdEffectivenessCheckboxField");
        for (const curriculumReportsV3EdEffectivenessAct13NotUsedCheck of curriculumReportsV3EdEffectivenessAct13NotUsedChecks)
            curriculumReportsV3EdEffectivenessAct13NotUsedCheck.addEventListener("click", (e) => this.checkForAct13NotUsed());
        const curriculumReportsV3EdEffectivenessAct13DataFields = document.getElementsByClassName("curriculumReportsV3EdEffectivenessAct13DataField");
        for (const curriculumReportsV3EdEffectivenessAct13DataField of curriculumReportsV3EdEffectivenessAct13DataFields) {
            curriculumReportsV3EdEffectivenessAct13DataField.addEventListener("input", (e) => this.checkForNumericError(e.srcElement));
            this.checkForNumericError(curriculumReportsV3EdEffectivenessAct13DataField);
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("curriculumReportsV3EdEffectivenessForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("curriculumReportsV3EdEffectivenessField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("curriculumReportsV3EdEffectivenessRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const checkboxes = document.getElementsByClassName("curriculumReportsV3EdEffectivenessCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        const selects = document.getElementsByClassName("curriculumReportsV3EdEffectivenessSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CurriculumInstructionAssessmentReports/SaveCurriculumReportsV3EdEffectiveness', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkForAct13NotUsed() {
        const curriculumReportsV3EdEffectivenessAct13NotUsedCheck = document.getElementById("curriculumReportsV3EdEffectivenessAct13NotUsed");
        if (curriculumReportsV3EdEffectivenessAct13NotUsedCheck !== null) {
            const divElement = document.getElementById("act13");
            const allRadios = document.getElementsByClassName("curriculumReportsV3EdEffectivenessRadioField");
            const allTexts = document.getElementsByClassName("curriculumReportsV3EdEffectivenessField");
            const allSelects = document.getElementsByClassName("curriculumReportsV3EdEffectivenessSelectField");
            if (curriculumReportsV3EdEffectivenessAct13NotUsedCheck.checked) {
                divElement.classList.add("hide");
                for (const radio of allRadios) {
                    this._core.forceElementOptional(radio);
                }
                for (const text of allTexts) {
                    this._core.forceElementOptional(text);
                }
                for (const select of allSelects) {
                    this._core.forceElementOptional(select);
                }
            }
            else {
                divElement.classList.remove("hide");
                for (const radio of allRadios) {
                    const ele = radio;
                    if (ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }
                for (const text of allTexts) {
                    const ele = text;
                    if (ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }
                for (const select of allSelects) {
                    const ele = select;
                    if (ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }
            }
        }
    }
    addOriginalPercent() {
        const allRadios = document.getElementsByClassName("curriculumReportsV3EdEffectivenessRadioField");
        const allTexts = document.getElementsByClassName("curriculumReportsV3EdEffectivenessField");
        const allSelects = document.getElementsByClassName("curriculumReportsV3EdEffectivenessSelectField");
        for (const radio of allRadios) {
            const ele = radio;
            const originalPercent = ele.dataset.percent;
            ele.dataset.originalpercent = originalPercent;
        }
        for (const text of allTexts) {
            const ele = text;
            const originalPercent = ele.dataset.percent;
            ele.dataset.originalpercent = originalPercent;
        }
        for (const select of allSelects) {
            const ele = select;
            const originalPercent = ele.dataset.percent;
            ele.dataset.originalpercent = originalPercent;
        }
    }
    checkForNumericError(element) {
        const category = element.dataset.category;
        if (element.value !== "") {
            const value = parseFloat(element.value);
            const errorEle = document.querySelector(`.act13-error[data-category='${category}']`);
            if (isNaN(value) || (value < 0 || value > 100)) {
                errorEle.classList.remove("hide");
            }
            else {
                errorEle.classList.add("hide");
            }
            this.totalAllCategoryValues();
        }
    }
    totalAllCategoryValues() {
        const allValueElements = document.getElementsByClassName("curriculumReportsV3EdEffectivenessAct13DataField");
        let total = 0.0;
        for (const valueElement of allValueElements) {
            const ele = valueElement;
            const val = parseFloat(ele.value);
            if (!isNaN(val))
                total += val;
            total = Math.round((total + Number.EPSILON) * 100) / 100;
        }
        const curriculumReportsV3EdEffectivenessAct13Total = document.getElementById("curriculumReportsV3EdEffectivenessAct13Total");
        if (total > 0 || curriculumReportsV3EdEffectivenessAct13Total.value !== "") {
            curriculumReportsV3EdEffectivenessAct13Total.value = total.toString();
            const errorEle = document.querySelector(`.act13-error[data-category='total']`);
            if (total != 100.00)
                errorEle.classList.remove("hide");
            else
                errorEle.classList.add("hide");
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
//Approvals and Signatures
class ApprovalsAndSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["approvalsAndSignaturesField", "approvalsAndSignaturesFileField"];
        let approvalsAndSignaturesSaveButton = document.getElementById("saveApprovalsAndSignatures");
        if (approvalsAndSignaturesSaveButton !== null)
            approvalsAndSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        let fileUploader = document.getElementById("approvalsAndSignaturesBoardApproval");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.srcElement));
        }
        this._core.leftnav(this);
        this.setOptional();
        this.setRequired();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let copyButton = document.getElementById("copyToSchoolImprovementPlan");
        if (copyButton !== null) {
            copyButton.addEventListener("click", (e) => this.copyPlan(e.srcElement));
        }
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e) => this.showDeleteFileConfirmation(deleteButton));
            }
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let that = this;
        let exports = document.getElementsByClassName("fullReport");
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLoader();
                //this needs to get reworked if we add any other reports to the dropdowns-- see outcomebasedreports
                let core = that._core;
                let url = "/ExportDocx/FullReportCompPlanExportExport";
                let planfk = parseInt(exportOption.dataset.planfk);
                let templatefk = parseInt(exportOption.dataset.templatefk);
                //let reportTypeCode = exportOption.dataset.reporttypecode;
                //if (typeof(reportTypeCode) !== "undefined" && reportTypeCode !== "") {
                //    url = `/ExportDocx/FullReport${reportTypeCode}Export`;
                //}
                let xhr = new XMLHttpRequest();
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = "blob";
                xhr.onload = function () {
                    Core.hideLoader();
                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = "planexport";
                        let planDetails = document.querySelector(`.planContainer[data-planfk='${planfk}'] .planTypeDetails`);
                        if (planDetails != null) {
                            filename = planDetails.innerHTML;
                        }
                        filename = filename + ".docx";
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        }
                        else {
                            var a = document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
                    }
                };
                xhr.send(`planFK=${planfk}&templateFK=${templatefk}`);
                //Get all components for given template
                //let xhr = new XMLHttpRequest();
                //xhr.open('POST', '/OutcomeBasedReports/FullReportCompPlanExportExport', true);
                //xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                //xhr.responseType = 'blob';
                //xhr.onload = function () {
                //    Core.hideLoader();
                //    if (xhr.status === 200) {
                //        let blob = this.response;
                //        let filename = exportOption.textContent;
                //        filename = filename + ".pdf";
                //        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                //            window.navigator.msSaveBlob(blob, filename);
                //        } else {
                //            var a = <HTMLAnchorElement>document.createElement("a");
                //            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                //            document.body.appendChild(a);
                //            a.style.display = "none";
                //            a.href = blobUrl;
                //            a.download = filename;
                //            a.click();
                //        }
                //    } else {
                //        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                //    }
                //}
                //xhr.send("planFK=" + exportOption.dataset.planfk + "&templateFK=" + exportOption.dataset.templatefk);
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadMinutesForm = document.getElementById("uploadMinutes");
        let formData = new FormData(uploadMinutesForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("approvalsAndSignaturesBoardApproval");
                        formFile.value = "";
                        formFile.dataset.hasuploaded = "true";
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFileConfirmation(deleteButton) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let formElement = document.getElementById("approvalsAndSignaturesForm");
        let inputs = document.getElementsByClassName("approvalsAndSignaturesField");
        let numberRequired = 0;
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.dataset.percent === "1.00") {
                numberRequired++;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/SaveApprovalsAndSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }
    setOptional() {
        let superintendentSignatureOptional = document.querySelector('input[name="hdnSuperintendentSignatureOptional"]');
        let fileUploadOptional = document.querySelector('input[name="hndFileUploadOptional"]');
        let dateOfApprovalOptional = document.querySelector('input[name="hndDateOfApprovalOptional"]');
        let approvalsAndSignaturesChiefAdministatorField = document.querySelector('input[name="approvalsAndSignaturesChiefAdministator"]');
        let approvalsAndSignaturesChiefAdministatorDateField = document.querySelector('input[name="approvalsAndSignaturesChiefAdministatorDate"]');
        let fileField = document.querySelector('input[name="file"]');
        let approvalsAndSignaturesBoardApprovalDateField = document.querySelector('input[name="approvalsAndSignaturesBoardApprovalDate"]');
        //For Title I Schoolwide Plans  (incuding Single Entity)-  do not need Superintendent signature  and Affirmation statement requirement (does not apply to them).
        if (superintendentSignatureOptional !== null && superintendentSignatureOptional.value == "true") {
            if (approvalsAndSignaturesChiefAdministatorField !== null) {
                this._core.forceElementOptional(approvalsAndSignaturesChiefAdministatorField);
            }
            if (approvalsAndSignaturesChiefAdministatorDateField !== null) {
                this._core.forceElementOptional(approvalsAndSignaturesChiefAdministatorDateField);
            }
        }
        if (fileUploadOptional !== null && fileUploadOptional.value == "true" && fileField !== null) {
            this._core.forceElementOptional(fileField);
        }
        if (dateOfApprovalOptional !== null && dateOfApprovalOptional.value == "true" && approvalsAndSignaturesBoardApprovalDateField !== null) {
            this._core.forceElementOptional(approvalsAndSignaturesBoardApprovalDateField);
        }
    }
    setRequired() {
        let schoolImprovementFacilitatorSignatureRequired = document.querySelector('input[name="hdnSchoolImprovementFacilitatorSignatureRequired"]');
        let schoolSignaturesSchoolImprovementFacilitatorField = document.querySelector('input[name="schoolSignaturesSchoolImprovementFacilitator"]');
        let schoolSignaturesSchoolImprovementFacilitatorDateField = document.querySelector('input[name="schoolSignaturesSchoolImprovementFacilitatorDate"]');
        //For CSI and ATSI Title 1 Plans  (incuding Single Entity)-  need School Improvement Facilitator signature
        if (schoolImprovementFacilitatorSignatureRequired !== null && schoolImprovementFacilitatorSignatureRequired.value == "true") {
            if (schoolSignaturesSchoolImprovementFacilitatorField !== null) {
                this._core.forceElementRequired(schoolSignaturesSchoolImprovementFacilitatorField);
            }
            if (schoolSignaturesSchoolImprovementFacilitatorDateField !== null) {
                this._core.forceElementRequired(schoolSignaturesSchoolImprovementFacilitatorDateField);
            }
        }
    }
    copyPlan(e) {
        let core = this._core;
        Core.showLoader();
        let planPK = 0;
        let formElement = document.getElementById("approvalsAndSignaturesForm");
        planPK = parseInt(formElement.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ApprovalsAndSignatures/CopyPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Successfully copied information to new School Improvement Plan.", 'success', 3000, null);
                        e.classList.add("disabled");
                        e.setAttribute("disabled", "disabled");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The plan failed to copy. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The plan failed to copy. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send("planPK=" + planPK);
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
//SummaryChecklistAndSubmissionCompPlan
class SummaryChecklistAndSubmissionCompPlan {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        this._core.leftnav(this);
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let planFK = 0;
        let element = e.srcElement;
        planFK = parseInt(element.dataset.planfk);
        let core = this._core;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SummaryAndSubmission/SubmitPlan', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Your plan has been submitted for review!", 'success', 3000, window.location.reload());
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting plan. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK);
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="interfaces/common/JQuery.d.ts" />
class OutcomeBasedReports {
    constructor() {
        let that = this;
        that._core = new Core();
        let categoryElement = document.getElementById("planTypeGroup");
        if (categoryElement != null) {
            categoryElement.addEventListener("change", that.populatePlanTypeList);
        }
        that.populatePlanTypeList();
        that.addEventListenersForPlans();
        let selectAll = document.querySelector(".selectAll");
        if (selectAll !== null) {
            selectAll.addEventListener("change", (e) => that.customizeReportSelectAll(e.target));
        }
        let onePageReportModals = document.getElementsByClassName("onePageReportContainer");
        if (onePageReportModals.length) {
            for (let onePageReportModal of onePageReportModals) {
                let format = onePageReportModal.dataset.format;
                let onePageReportButtons = onePageReportModal.getElementsByClassName("onePageCreateReport");
                for (let onePageReportButton of onePageReportButtons) {
                    let functionName = "createOnePageReport" + format.toUpperCase();
                    onePageReportButton.addEventListener("click", (e) => that[functionName](onePageReportModal));
                }
            }
        }
        let createCustomReportButton = document.getElementById("createCustomReport");
        if (createCustomReportButton != null) {
            createCustomReportButton.addEventListener("click", function () {
                switch (that.formatCode) {
                    case "pdf":
                        that.createCustomReportPDF();
                        break;
                    case "docx":
                        that.createCustomReportDOCX();
                        break;
                }
            });
        }
        let clearButton = document.getElementById("clearSearch");
        if (clearButton !== null)
            clearButton.addEventListener("click", (e) => this.clearSearchFilter());
        let searchButton = document.getElementById("runSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e) => this.search(1));
        let leaName = document.getElementById("leaName");
        if (leaName !== null)
            leaName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
                this.search(1);
            } });
        let schoolName = document.getElementById("schoolName");
        if (schoolName !== null)
            schoolName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
                this.search(1);
            } });
        this._core.bindChangeLEA();
        window.addEventListener('popstate', function (e) {
            let query = e.state;
            let yearElement = document.getElementById("planYear");
            let leaNameElement = document.getElementById("leaName");
            let statusElement = document.getElementById("planStatus");
            let typeElement = document.getElementById("planType");
            let phaseElement = document.getElementById("planPhase");
            let categoryElement = document.getElementById("planTypeGroup");
            let schoolNameElement = document.getElementById("schoolName");
            if (yearElement != null) {
                if (query != null && query.Year != null) {
                    yearElement.value = query.Year.toString();
                }
                else {
                    yearElement.selectedIndex = 0;
                }
            }
            if (leaNameElement != null) {
                if (query != null && query.LEAName != null) {
                    leaNameElement.value = query.LEAName;
                }
                else {
                    leaNameElement.value = "";
                }
            }
            if (schoolNameElement != null) {
                if (query != null && query.SchoolName != null) {
                    schoolNameElement.value = query.SchoolName;
                }
                else {
                    schoolNameElement.value = "";
                }
            }
            if (categoryElement != null) {
                if (query != null && query.PlanTypeGroupCode != null) {
                    categoryElement.value = query.PlanTypeGroupCode;
                }
                else {
                    categoryElement.selectedIndex = 0;
                }
            }
            if (statusElement != null) {
                if (query != null && query.StatusPKs != null) {
                    statusElement.value = query.StatusPKs.toString();
                }
                else {
                    statusElement.selectedIndex = 0;
                }
            }
            if (typeElement != null) {
                if (query != null && query.PlanTypePK != null) {
                    typeElement.value = query.PlanTypePK.toString();
                }
                else {
                    typeElement.selectedIndex = 0;
                }
            }
            if (phaseElement != null) {
                if (query != null && query.PhaseCodeFK != null) {
                    phaseElement.value = query.PhaseCodeFK.toString();
                }
                else {
                    phaseElement.selectedIndex = 0;
                }
            }
            if (query != null) {
                that.search(query.PageNumber, query, true);
            }
            else {
                let container = document.getElementById("plansList");
                if (container != null) {
                    container.innerHTML = "";
                }
            }
        });
    }
    customizeReportSelectAll(selectAllCheckbox) {
        let modal = Core.findClosest(selectAllCheckbox, ".customizePlanReportContainer");
        let allCheckboxes = modal.getElementsByClassName("customizeItem");
        let allChecked = true;
        for (let checkbox of allCheckboxes) {
            let element = checkbox;
            if (!element.checked)
                allChecked = false;
        }
        let word = modal.querySelector(".selectAllWord");
        if (!allChecked) {
            for (let checkbox of allCheckboxes) {
                let element = checkbox;
                element.checked = true;
            }
            word.textContent = "Remove";
        }
        else {
            for (let checkbox of allCheckboxes) {
                let element = checkbox;
                element.checked = false;
            }
            word.textContent = "Select";
        }
    }
    checkForCheckboxWord(checkbox) {
        let container = Core.findClosest(checkbox, ".customizePlanReportContainer");
        let allCheckboxes = container.getElementsByClassName("customizeItem");
        let selectAll = container.querySelector(".selectAll");
        let allChecked = true;
        for (let checkbox of allCheckboxes) {
            let element = checkbox;
            if (!element.checked)
                allChecked = false;
        }
        let word = container.querySelector(".selectAllWord");
        if (allChecked) {
            word.textContent = "Remove";
            selectAll.checked = true;
        }
        else {
            word.textContent = "Select";
            selectAll.checked = false;
        }
    }
    showCustomizeReport(exportOption) {
        this.reportTypeCode = exportOption.dataset.reporttypecode;
        let that = this;
        Core.showLoader();
        //Reset Export Info
        that.planFK = exportOption.dataset.planfk;
        that.templateFK = exportOption.dataset.templatefk;
        that.formatCodeFK = exportOption.dataset.exportFormatCodeFk;
        that.formatCode = exportOption.dataset.exportFormatCode;
        let modalId = exportOption.dataset.modal;
        let modalElement = document.getElementById("customizeReport");
        that.currentModal = new Modal("customizeReport", null);
        let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
        if (planDetails != null) {
            let modalElementHeading = modalElement.querySelector("h1");
            if (modalElementHeading != null) {
                modalElementHeading.innerHTML = planDetails.innerHTML;
            }
        }
        //Make call to populate modal
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/GetExportModalComponentsView', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let componentContainer = document.getElementById("exportComponentsContainer");
                componentContainer.innerHTML = xhr.responseText;
                let allCheckboxes = modalElement.getElementsByClassName("customizeItem");
                for (let checkbox of allCheckboxes) {
                    let element = checkbox;
                    element.checked = false;
                    element.addEventListener("change", (e) => that.checkForCheckboxWord(e.target));
                }
                let word = modalElement.querySelector(".selectAllWord");
                word.textContent = "Select";
                let selectAll = modalElement.querySelector(".selectAll");
                selectAll.checked = false;
                Core.hideLoader();
                that.currentModal.show();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "This action could not be performed. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send("templateFK=" + that.templateFK + "&exportFormatCodeFK=" + that.formatCodeFK);
    }
    showOnePageReport(exportOption) {
        let that = this;
        //Reset Export Info
        that.planFK = exportOption.dataset.planfk;
        that.templateFK = exportOption.dataset.templatefk;
        let modalId = exportOption.dataset.modal;
        let modalElement = document.getElementById(modalId);
        that.currentModal = new Modal(modalId, null);
        let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
        if (planDetails != null) {
            let modalElementHeading = modalElement.querySelector("h1");
            if (modalElementHeading != null) {
                modalElementHeading.innerHTML = planDetails.innerHTML;
            }
        }
        that.currentModal.show();
    }
    createCustomReportDOCX() {
        let that = this;
        let core = that._core;
        if (this.currentModal !== undefined) {
            this.currentModal.hide();
        }
        Core.showLoader();
        let modalContainer = document.getElementById("customizeReport");
        let allCheckboxes = modalContainer.getElementsByClassName("customizeItem");
        let componentsToBuild = [];
        let headerComponent = document.getElementById("headerComponent");
        if (headerComponent != null && headerComponent.value) {
            componentsToBuild.push(headerComponent.value);
        }
        for (let checkbox of allCheckboxes) {
            let element = checkbox;
            if (element.checked && "component" in element.dataset) {
                componentsToBuild.push(element.dataset.component);
            }
        }
        let request = {
            Components: componentsToBuild,
            PlanFK: parseInt(that.planFK)
        };
        let url = "/ExportDocx/CustomReportDataExport";
        if (that.reportTypeCode !== "") {
            url = `/ExportDocx/CustomReport${that.reportTypeCode}Export`;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".docx";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(request));
    }
    createFullReportDOCX() {
        let that = this;
        let core = that._core;
        Core.showLoader();
        let url = "/ExportDocx/FullReportDataExport";
        if (that.reportTypeCode !== "") {
            url = `/ExportDocx/FullReport${that.reportTypeCode}Export`;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".docx";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${that.planFK}&templateFK=${that.templateFK}`);
    }
    createCustomReportPDF() {
        let that = this;
        let core = that._core;
        if (this.currentModal !== undefined) {
            this.currentModal.hide();
        }
        Core.showLoader();
        let modalContainer = document.getElementById("customizeReport");
        let allCheckboxes = modalContainer.getElementsByClassName("customizeItem");
        let componentsToBuild = [];
        let headerComponent = document.getElementById("headerComponent");
        if (headerComponent != null && headerComponent.value) {
            componentsToBuild.push(headerComponent.value);
        }
        for (let checkbox of allCheckboxes) {
            let element = checkbox;
            if (element.checked && "component" in element.dataset) {
                componentsToBuild.push(element.dataset.component);
            }
        }
        let request = {
            Components: componentsToBuild,
            PlanFK: parseInt(that.planFK)
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/CustomReportDataExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(request));
    }
    createFullReportPDF() {
        let that = this;
        Core.showLoader();
        //Get all components for given template
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/FullReportDataExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + that.planFK + "&templateFK=" + that.templateFK);
    }
    createFullReportDOCXWithFiles() {
        let that = this;
        let core = that._core;
        Core.showLongLoader();
        let url = "";
        if (that.reportTypeCode !== "") {
            url = `/ExportDocx/FullReportExportWithFiles`;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLongLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${that.planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".zip";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${that.planFK}&templateFK=${that.templateFK}`);
    }
    createFullReportPDFWithFiles() {
        //Todo: Implement if needed.
    }
    createOnePageReportPDF(modalContainer) {
        let that = this;
        let button = modalContainer.querySelector(".onePageCreateReport");
        let baseUrl = button.dataset.url;
        let report = button.dataset.report;
        that.currentModal.hide();
        Core.showLoader();
        let radios = modalContainer.querySelectorAll("[name='layoutSelection']");
        let whichLayout = "";
        for (let radio of radios) {
            let ele = radio;
            if (ele.checked)
                whichLayout = ele.value;
        }
        this.buildSinglePageReportPDF(that.planFK, that.templateFK, baseUrl, whichLayout, report);
    }
    createOnePageReportDOCX(modalContainer) {
        let that = this;
        let button = modalContainer.querySelector(".onePageCreateReport");
        let report = button.dataset.report;
        that.currentModal.hide();
        Core.showLoader();
        let radios = modalContainer.querySelectorAll("[name='layoutSelection']");
        let whichLayout = "";
        for (let radio of radios) {
            let ele = radio;
            if (ele.checked)
                whichLayout = ele.value;
        }
        this.buildSinglePageReportDOCX(that.planFK, that.templateFK, whichLayout);
    }
    buildComponent(planFK, templateFK, component) {
        var xhr = new XMLHttpRequest();
        return new Promise(function (resolve, reject) {
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status >= 300) {
                        reject("Error, status code = " + xhr.status);
                    }
                    else {
                        resolve(xhr.responseText);
                    }
                }
            };
            xhr.open('POST', `/OutcomeBasedReports/${component}`, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send(`planFK=${planFK}&templateFK=templateFK`);
        });
    }
    buildSinglePageReportPDF(planFK, templateFK, baseUrl, whichLayout, report) {
        let that = this;
        const getReportStuff = () => __awaiter(this, void 0, void 0, function* () {
            let componentsToBuild = [];
            const componentToBuild = yield that.buildSinglePageComponent(planFK, templateFK, whichLayout, report);
            componentsToBuild.push(componentToBuild);
            return componentsToBuild;
        });
        const allTheReports = getReportStuff();
        let htmlReport = "";
        htmlReport += "<!DOCTYPE html>";
        htmlReport += "<html lang=\"en-us\">";
        htmlReport += "<head>";
        htmlReport += "<meta charset=\"utf-8\" />";
        htmlReport += "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\" />";
        htmlReport += "<title>Outcome based Report - Future Ready Comprehensive Planning Portal</title>";
        htmlReport += `<link rel=\"stylesheet\" href=\"${baseUrl}/css/foundation.css\" />`;
        htmlReport += `<link rel=\"stylesheet\" href=\"${baseUrl}/css/site.css\" />`;
        htmlReport += "<link rel=\"stylesheet\" href=\"https://use.typekit.net/hrl7phx.css\" />";
        htmlReport += "<script src='https://kit.fontawesome.com/968075e8cc.js' crossorigin=\"anonymous\"> </script>";
        htmlReport += `<script src=\"${baseUrl}/js/vendor.js\" type=\"text/javascript\"></script>`;
        htmlReport += `<script src=\"${baseUrl}/js/site.js\" type=\"text/javascript\"></script>`;
        htmlReport += "</head>";
        htmlReport += "<body>";
        let landscape = false;
        if (whichLayout.includes("Landscape"))
            landscape = true;
        allTheReports.then(reports => {
            for (let report of reports) {
                if (report)
                    htmlReport += report;
            }
            htmlReport += "</body>";
            htmlReport += "</html>";
            let saveElement = {
                Html: htmlReport,
                Landscape: landscape
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/OutcomeBasedReports/ExportReport', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let blob = this.response;
                    let filename = `${whichLayout}.pdf`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue during export. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(saveElement));
        }).catch(e => {
            console.error(e);
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue generating report", 'error', 3000, null);
        });
    }
    buildSinglePageReportDOCX(planFK, templateFK, whichLayout) {
        let that = this;
        let core = that._core;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ExportDocx/SinglePage/' + whichLayout, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "planexport";
                let planDetails = document.querySelector(`.planContainer[data-planfk='${planFK}'] .planTypeDetails`);
                if (planDetails != null) {
                    filename = planDetails.innerHTML;
                }
                filename = filename + ".docx";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue during export to Word. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&templateFK=" + templateFK);
    }
    buildSinglePageComponent(planFK, templateFK, whichLayout, report) {
        var xhr = new XMLHttpRequest();
        return new Promise(function (resolve, reject) {
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status >= 300) {
                        reject("Error, status code = " + xhr.status);
                    }
                    else {
                        resolve(xhr.responseText);
                    }
                }
            };
            xhr.open('POST', `/OutcomeBasedReports/OnePageReport`, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send(`planFK=${planFK}&templateFK=${templateFK}&layout=${whichLayout}&report=${report}`);
        });
    }
    search(pageNumber, searchQuery = null, fromHistory = false) {
        let that = this;
        let query = searchQuery;
        if (query === null) {
            query = Core.GetPlanQueryModel(pageNumber);
        }
        let params = "";
        for (var key in query) {
            if (params != "") {
                params += "&";
            }
            params += key + "=" + encodeURIComponent(query[key]);
        }
        let queryString = "?" + params;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/RefreshPlansList', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            let container = document.getElementById("plansList");
            if (container != null) {
                container.innerHTML = xhr.responseText;
                //Add to history so back button works
                if (!fromHistory) {
                    history.pushState(query, '', queryString);
                }
                that.addEventListenersForPlans();
            }
        };
        xhr.send(JSON.stringify(query));
    }
    clearSearchFilter() {
        let yearElement = document.getElementById("planYear");
        let leaNameElement = document.getElementById("leaName");
        let statusElement = document.getElementById("planStatus");
        let typeElement = document.getElementById("planType");
        let phaseElement = document.getElementById("planPhase");
        let categoryElement = document.getElementById("planTypeGroup");
        let schoolNameElement = document.getElementById("schoolName");
        if (yearElement != null) {
            yearElement.selectedIndex = 0;
        }
        if (leaNameElement != null) {
            leaNameElement.value = "";
        }
        if (statusElement != null) {
            statusElement.selectedIndex = 0;
        }
        if (typeElement != null) {
            typeElement.selectedIndex = 0;
        }
        if (phaseElement != null) {
            phaseElement.selectedIndex = 0;
        }
        if (categoryElement != null) {
            categoryElement.selectedIndex = 0;
        }
        if (schoolNameElement != null) {
            schoolNameElement.value = "";
        }
        this.populatePlanTypeList();
        let container = document.getElementById("plansList");
        if (container != null) {
            container.innerHTML = "";
        }
        history.pushState(null, '', '/OutcomeBasedReports');
        document.title = "Plan Reports and Print - Future Ready Comprehensive Planning Portal";
    }
    populatePlanTypeList() {
        let typeElement = document.getElementById("planType");
        let categoryElement = document.getElementById("planTypeGroup");
        let type = typeElement == null ? "" : typeElement.value;
        let category = categoryElement == null ? "" : categoryElement.value;
        typeElement.disabled = true;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/GetPlanTypeList', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let planTypeList = document.getElementById("planTypeContainer");
                if (planTypeList != null) {
                    planTypeList.innerHTML = xhr.responseText;
                    typeElement.disabled = false;
                }
            }
            else {
                typeElement.disabled = false;
            }
        };
        xhr.send("planTypeGroupCode=" + category + "&selectedPlanType=" + type);
    }
    addEventListenersForPlans() {
        let that = this;
        let completeReports = document.getElementsByClassName("completeReport");
        for (let completeReport of completeReports) {
            completeReport.addEventListener("click", function () {
                //Reset Export Info
                that.planFK = completeReport.dataset.planfk;
                that.templateFK = completeReport.dataset.templatefk;
                that.formatCodeFK = completeReport.dataset.exportFormatCodeFk;
                that.formatCode = completeReport.dataset.exportFormatCode;
                that.reportTypeCode = completeReport.dataset.reporttypecode;
                switch (that.formatCode) {
                    case "pdf":
                        that.createFullReportPDF();
                        break;
                    case "docx":
                        that.createFullReportDOCX();
                        break;
                }
            });
        }
        const completeReportWithFiles = document.getElementsByClassName("completeReportWithFiles");
        for (const report of completeReportWithFiles) {
            report.addEventListener("click", function () {
                //Reset Export Info
                that.planFK = report.dataset.planfk;
                that.templateFK = report.dataset.templatefk;
                that.formatCodeFK = report.dataset.exportFormatCodeFk;
                that.formatCode = report.dataset.exportFormatCode;
                that.reportTypeCode = report.dataset.reporttypecode;
                switch (that.formatCode) {
                    case "pdf":
                        that.createFullReportPDFWithFiles();
                        break;
                    case "docx":
                        that.createFullReportDOCXWithFiles();
                        break;
                }
            });
        }
        let customizeReports = document.getElementsByClassName("customizeReport");
        for (let customizeReport of customizeReports) {
            customizeReport.addEventListener("click", (e) => that.showCustomizeReport(e.target));
        }
        let onePageReports = document.getElementsByClassName("onePageReport");
        for (let onePageReport of onePageReports) {
            onePageReport.addEventListener("click", (e) => that.showOnePageReport(e.target));
        }
        let selectToExport = document.getElementsByClassName("selectExportCheckbox");
        for (let selectCheckbox of selectToExport) {
            selectCheckbox.addEventListener("change", that.updateSelectToExport);
        }
        let exportSelectAll = document.getElementById("exportSelectAll");
        if (exportSelectAll != null) {
            let exportSelectAllElement = exportSelectAll;
            exportSelectAll.addEventListener("change", function (e) {
                let exportCheckboxes = document.getElementsByClassName("selectExportCheckbox");
                for (let checkbox of exportCheckboxes) {
                    if (exportSelectAllElement.checked) {
                        checkbox.checked = true;
                    }
                    else {
                        checkbox.checked = false;
                    }
                }
                that.updateSelectToExport();
            });
        }
        let bulkExport = document.getElementById("bulkExport");
        if (bulkExport != null) {
            bulkExport.addEventListener("click", function () {
                that.bulkExport();
            });
        }
        let searchResultsMessage = document.getElementById("searchResultsMessage");
        if (searchResultsMessage != null) {
            document.title = searchResultsMessage.textContent + " - Plan Reports and Print - Future Ready Comprehensive Planning Portal";
            searchResultsMessage.focus();
        }
        //Reinitialize Foundation dropdowns
        $(document).foundation();
    }
    updateSelectToExport() {
        let that = this;
        let exportSelectAll = document.getElementById("exportSelectAll");
        let allCheckboxes = document.getElementsByClassName("selectExportCheckbox");
        let allChecked = true;
        let atLeastOneChecked = false;
        for (let checkbox of allCheckboxes) {
            let element = checkbox;
            if (!element.checked) {
                allChecked = false;
            }
            else {
                atLeastOneChecked = true;
            }
        }
        let bulkExport = document.getElementById("bulkExport");
        if (bulkExport != null) {
            bulkExport.disabled = !atLeastOneChecked;
        }
        let word = document.getElementById("exportSelectAllWord");
        if (allChecked) {
            word.textContent = "Deselect";
            exportSelectAll.checked = true;
        }
        else {
            word.textContent = "Select";
            exportSelectAll.checked = false;
        }
    }
    bulkExport() {
        let that = this;
        Core.showLoader();
        let planFKs = [];
        let exportCheckboxes = document.getElementsByClassName("selectExportCheckbox");
        for (let checkbox of exportCheckboxes) {
            if (checkbox.checked) {
                planFKs.push(parseInt(checkbox.dataset.planfk));
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/BulkExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let messageContainer = document.getElementById("bulkExportMessageContainer");
                let message = document.getElementById("bulkExportMessage");
                if (messageContainer != null) {
                    messageContainer.classList.remove("hide");
                }
                if (message != null) {
                    message.innerHTML = "Successfully queued export. " + jsonResponse.email + " will receive an email with a download link once the export is complete.";
                    message.focus();
                }
                for (let checkbox of exportCheckboxes) {
                    checkbox.checked = false;
                    that.updateSelectToExport();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error exporting plans. Please try again later.", 'error', 3000, null);
            }
        };
        if (planFKs.length === 0) {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "No plans were selected for export", 'error', 3000, null);
        }
        else {
            xhr.send(JSON.stringify(planFKs));
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
class Reports {
    constructor() {
        this._core = new Core();
        let leaClearButton = document.getElementById("clearSearch");
        if (leaClearButton !== null)
            leaClearButton.addEventListener("click", (e) => this.clearLEASearchFilter());
        let leaSearchButton = document.getElementById("runSearch");
        if (leaSearchButton !== null)
            leaSearchButton.addEventListener("click", (e) => this.planSearch(1));
        let leaPlanName = document.getElementById("leaName");
        if (leaPlanName !== null)
            leaPlanName.addEventListener("keypress", (e) => { if (e.keyCode === 13) {
                this.planSearch(1);
            } });
        let individualSaveButtons = document.getElementsByClassName("saveIndDueDates");
        for (let individualSaveButton of individualSaveButtons) {
            individualSaveButton.addEventListener("click", (e) => this._core.updatePlanDueDate(e));
        }
        let lockoutexceptions = document.getElementsByClassName("lockoutException");
        for (let lockoutexception of lockoutexceptions) {
            lockoutexception.addEventListener("click", (e) => this._core.updateLockoutException(e));
        }
        this._core.bindChangeLEA();
        this._core.bindDeleteOwner();
    }
    planSearch(pageNumber) {
        let query = Core.GetPlanQueryModel(pageNumber);
        let planTypeGroupCodeInput = document.getElementById("planTypeGroup");
        if (planTypeGroupCodeInput != null) {
            query.PlanTypeGroupCode = planTypeGroupCodeInput.value;
        }
        let params = "";
        for (var key in query) {
            if (params != "") {
                params += "&";
            }
            params += key + "=" + encodeURIComponent(query[key]);
        }
        window.location.href = "/Reports/StateRequiredReportsGroup/?" + params;
    }
    clearLEASearchFilter() {
        let planTypeGroupCodeInput = document.getElementById("planTypeGroup");
        let url = "/Reports/StateRequiredReportsGroup/?";
        if (planTypeGroupCodeInput !== null) {
            url += "PlanTypeGroupCode=" + planTypeGroupCodeInput.value;
        }
        window.location.href = url;
    }
}
class Reporting {
    constructor() {
        this._core = new Core();
        //let leaClearButton = <HTMLButtonElement>document.getElementById("clearSearch");
        //if (leaClearButton !== null)
        //    leaClearButton.addEventListener("click", (e: Event) => this.clearLEASearchFilter());
        //let leaSearchButton = <HTMLButtonElement>document.getElementById("runSearch");
        //if (leaSearchButton !== null)
        //    leaSearchButton.addEventListener("click", (e: Event) => this.planSearch(1));
        this._core.bindChangeLEA();
    }
}
class FRCPPReport {
    constructor() {
        this._core = new Core();
        let runReportButton = document.getElementById("runReport");
        if (runReportButton !== null)
            runReportButton.addEventListener("click", (e) => this.runReport(e));
    }
    runReport(e) {
        //add more elements as we add more options for selectors in reports  these must match exactly what is in the reportParamater table
        let academicYearElement = document.getElementById("academicYearFK");
        let showOnlyNotApproved = document.getElementById("showOnlyNotApproved");
        let showOnlyApproved = document.getElementById("showOnlyApproved");
        let cipElement = document.getElementById("cipCodeFK");
        let planTypes = document.getElementById("planTypes");
        let button = e.currentTarget;
        let reportCode = button.dataset.reportcode;
        let url = "/Reports/" + reportCode + "/?";
        if (academicYearElement != null && academicYearElement.selectedIndex > 0 && parseInt(academicYearElement.value)) {
            url = url + academicYearElement.id + "=" + parseInt(academicYearElement.value) + "&";
        }
        if (showOnlyNotApproved != null) {
            var ischecked = "false";
            if (showOnlyNotApproved.checked)
                ischecked = "true";
            url = url + showOnlyNotApproved.id + "=" + ischecked + "&";
        }
        if (showOnlyApproved != null) {
            var ischecked = "false";
            if (showOnlyApproved.checked)
                ischecked = "true";
            url = url + showOnlyApproved.id + "=" + ischecked + "&";
        }
        if (cipElement != null && cipElement.selectedIndex > 0 && cipElement.value) {
            url = url + cipElement.id + "=" + cipElement.value + "&";
        }
        if (planTypes != null && planTypes.selectedIndex > 0 && planTypes.value) {
            url = url + planTypes.id + "=" + parseInt(planTypes.value) + "&";
        }
        window.location.href = url;
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
class PlanMonitoringPage {
    constructor(validationClasses) {
        let that = this;
        that.validationClasses = validationClasses;
        this._core = new Core();
        this._core.activateSubMenu("subnavPlanMonitoring");
        this._core.leftnav(this);
        this._core.tabLinkSave(this);
        this.deleteFileModal = new Modal('deleteFileModal', null);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fileUploaders = document.getElementsByClassName('fileUpload');
        for (let upload of fileUploaders) {
            if ("propertyFk" in upload.dataset && "planFk" in upload.dataset) {
                upload.addEventListener('change', (e) => this.uploadFile(parseInt(upload.dataset.propertyFk), parseInt(upload.dataset.planFk)));
            }
        }
        document.addEventListener("click", function (event) {
            let target = event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("deleteFile") && "monitoringFilePk" in target.dataset) {
                that.deleteFileModal.show();
                that.deleteFileModal.callingId = target.id;
                that.planMonitoringFileToDeletePK = parseInt(target.dataset.monitoringFilePk);
            }
        });
        let deleteFileConfirm = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirm != null) {
            deleteFileConfirm.addEventListener("click", () => that.deleteFileConfirm());
        }
        let deleteFileCancel = document.getElementById("deleteFileCancel");
        if (deleteFileCancel != null) {
            deleteFileCancel.addEventListener("click", () => that.deleteFileModal.hide());
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(propertyFK, planFK) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = document.querySelector(`.uploadForm[data-property-fk='${propertyFK}']`);
        const formData = new FormData(uploadForm);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/UploadMonitoringFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        const formFile = document.querySelector(`.fileUpload[data-property-fk='${propertyFK}']`);
                        formFile.value = '';
                        const fileUpload = response.planMonitoringFile;
                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/PlanMonitoring/GetPlanMonitoringFileUploadPartialView?planMonitoringFilePK=${fileUpload.planMonitoringFilePK}&planFK=${planFK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-property-fk='${propertyFK}']`);
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            }
                        };
                        xhrForPartialView.send();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    deleteFileConfirm() {
        let that = this;
        const core = this._core;
        Core.showLoader();
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/DeleteMonitoringFile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully deleted.', 'success', 3000, null);
                        const containerElement = document.querySelector(`.uploadFileContainer[data-monitoring-file-pk='${that.planMonitoringFileToDeletePK}']`);
                        if (containerElement !== null) {
                            let fileInput = document.querySelector(`.fileUpload[data-property-fk='${containerElement.dataset.propertyFk}']`);
                            if (fileInput != null) {
                                that.deleteFileModal.callingId = fileInput.id;
                            }
                            containerElement.parentElement.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error deleting file.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file removal failed. Please try again.', 'error', 3000, null);
            }
            that.deleteFileModal.hide();
        };
        xhr.send(that.planMonitoringFileToDeletePK.toString());
    }
}
class MeasurableGoalsProgress extends PlanMonitoringPage {
    constructor() {
        super(["planMonitoringField"]);
        let that = this;
        let saveMeasurableGoalsProgressButton = document.getElementById("saveMeasurableGoalsProgress");
        if (saveMeasurableGoalsProgressButton !== null) {
            saveMeasurableGoalsProgressButton.addEventListener("click", (e) => that.save("save"));
        }
        let exportMeasurableGoalsProgressWord = document.getElementById("exportMeasurableGoalsProgressWord");
        if (exportMeasurableGoalsProgressWord !== null) {
            exportMeasurableGoalsProgressWord.addEventListener("click", (e) => that.getCore().exportDocx(e, "MeasurableGoalsProgressDataExport", "measurablegoalsprogress"));
        }
        let exportQuarterlyReportWord = document.getElementById("exportQuarterlyReportWord");
        if (exportQuarterlyReportWord !== null) {
            exportQuarterlyReportWord.addEventListener("click", (e) => that.getCore().exportDocx(e, "SchoolImprovementQuarterlyReportDataExport", "schoolimprovementquarterlyreport"));
        }
    }
    save(referrer) {
        let core = this.getCore();
        if (referrer !== "save" && core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let planFK = 0;
        let planMonitoringFK = 0;
        let pageFK = 0;
        let formElement = document.getElementById("measurableGoalsProgressData");
        planFK = parseInt(formElement.dataset.planfk);
        planMonitoringFK = parseInt(formElement.dataset.planmonitoringfk);
        pageFK = parseInt(formElement.dataset.pagefk);
        let targetSaveElements = [];
        let targets = document.getElementsByClassName("goalSettingTarget");
        for (let target of targets) {
            if ("targetplanpropertyfk" in target.dataset && "goaltargetpk" in target.dataset) {
                let goalTargetPlanPropertyFK = parseInt(target.dataset.targetplanpropertyfk);
                let planMonitoringGoalTargetPK = parseInt(target.dataset.goaltargetpk);
                let actualPerformanceValueText = "";
                let performance = document.querySelector(`.actualPerformanceText[data-targetplanpropertyfk='${goalTargetPlanPropertyFK}']`);
                if (performance != null) {
                    actualPerformanceValueText = performance.value;
                }
                let summaryNotesValueText = "";
                let summary = document.querySelector(`.summaryNotes[data-targetplanpropertyfk='${goalTargetPlanPropertyFK}']`);
                if (summary != null) {
                    summaryNotesValueText = summary.value;
                }
                let thisData = {
                    ActualPerformanceValueText: actualPerformanceValueText,
                    GoalTargetPlanPropertyFK: goalTargetPlanPropertyFK,
                    PlanMonitoringFK: planMonitoringFK,
                    PlanMonitoringGoalTargetPK: planMonitoringGoalTargetPK,
                    SummaryNotesValueText: summaryNotesValueText
                };
                targetSaveElements.push(thisData);
            }
        }
        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "PlanMonitoringFK": planMonitoringFK,
            "GoalTargets": targetSaveElements
        });
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/SaveMeasurableGoalsProgress', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(saveData);
    }
}
class ActionPlanReview extends PlanMonitoringPage {
    constructor() {
        super(["planMonitoringField"]);
        let that = this;
        let saveActionPlanReviewButton = document.getElementById("saveActionPlanReview");
        if (saveActionPlanReviewButton !== null) {
            saveActionPlanReviewButton.addEventListener("click", (e) => that.save("save"));
        }
        let levelOfCompletion = document.getElementsByClassName("levelOfCompletion");
        for (let levelDropdown of levelOfCompletion) {
            levelDropdown.addEventListener("change", (e) => that.setBarriersToCompletion(levelDropdown));
        }
        let proposedChanges = document.getElementsByClassName("notesProposedChanges");
        for (let change of proposedChanges) {
            change.addEventListener("input", (e) => that.setProposedChanges(e));
        }
        let exportActionPlanReviewWord = document.getElementById("exportActionPlanReviewWord");
        if (exportActionPlanReviewWord !== null) {
            exportActionPlanReviewWord.addEventListener("click", (e) => this.getCore().exportDocx(e, "ActionPlanReviewDataExport", "actionplanreview"));
        }
        let barriersToCompletionMoreInfo = document.querySelectorAll(".barriersMoreInfo");
        for (let infoButton of barriersToCompletionMoreInfo) {
            infoButton.addEventListener("click", function () {
                let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                slideout.show();
            });
            infoButton.addEventListener("keypress", (e) => {
                if (Core.a11yClick(e)) {
                    let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                    slideout.show();
                }
            });
        }
    }
    //When the user selects "Behind" for the completion level of an action step, enable barriers of completion and make it required.
    setBarriersToCompletion(dropdown) {
        let that = this;
        let selectedOption = dropdown.options[dropdown.selectedIndex];
        if (selectedOption != null && "lookupcode" in selectedOption.dataset && "actionplanstepplanpropertyfk" in dropdown.dataset) {
            let barrierContainer = document.querySelector(`.barriersToCompletionContainer[data-actionplanstepplanpropertyfk='${dropdown.dataset.actionplanstepplanpropertyfk}']`);
            let barrier = document.querySelector(`.barriersToCompletion[data-actionplanstepplanpropertyfk='${dropdown.dataset.actionplanstepplanpropertyfk}']`);
            if (barrierContainer != null && barrier != null) {
                if (selectedOption.dataset.lookupcode == "levelOfCompletionBehind") {
                    barrierContainer.classList.remove("hide");
                    that.getCore().forceElementRequired(barrier);
                }
                else {
                    barrierContainer.classList.add("hide");
                    that.getCore().forceElementOptional(barrier);
                }
                let barriersToCompletionMoreInfo = document.querySelectorAll(".barriersMoreInfo");
                for (let infoButton of barriersToCompletionMoreInfo) {
                    infoButton.addEventListener("click", function () {
                        let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                        slideout.show();
                    });
                    infoButton.addEventListener("keypress", (e) => {
                        if (Core.a11yClick(e)) {
                            let slideout = new Slideout("barriersToCompletionSlideout", infoButton.id);
                            slideout.show();
                        }
                    });
                }
            }
        }
    }
    //When user makes a change to the "Notes on Proposed Changes" for an action plan, make the corresponding change in the "Summary To Proposed Changes"
    setProposedChanges(e) {
        let change = e.target;
        if ("actionplanplanpropertyfk" in change.dataset && "propertyfk" in change.dataset) {
            let summaryChange = document.querySelector(`.notesProposedChangesSummary[data-actionplanplanpropertyfk='${change.dataset.actionplanplanpropertyfk}'][data-propertyfk='${change.dataset.propertyfk}']`);
            if (summaryChange != null) {
                summaryChange.value = change.value;
            }
        }
    }
    save(referrer) {
        let core = this.getCore();
        if (referrer !== "save" && core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let planFK = 0;
        let planMonitoringFK = 0;
        let pageFK = 0;
        let formElement = document.getElementById("actionPlanReviewData");
        planFK = parseInt(formElement.dataset.planfk);
        planMonitoringFK = parseInt(formElement.dataset.planmonitoringfk);
        pageFK = parseInt(formElement.dataset.pagefk);
        let actionPlans = [];
        //Get all action plans
        let actionPlanHiddenInputs = document.getElementsByClassName("actionPlan");
        for (let actionPlan of actionPlanHiddenInputs) {
            if ("actionplanplanpropertyfk" in actionPlan.dataset && "planmonitoringactionplanfk" in actionPlan.dataset) {
                let actionPlanSaveElement = {
                    ActionPlanPlanPropertyFK: parseInt(actionPlan.dataset.actionplanplanpropertyfk),
                    PlanMonitoringActionPlanPK: parseInt(actionPlan.dataset.planmonitoringactionplanfk),
                    PlanMonitoringFK: planMonitoringFK
                };
                //Get the action steps
                let actionPlanStepSaveElements = [];
                let actionPlanSteps = document.querySelectorAll(`.actionPlanStep[data-actionplanplanpropertyfk='${actionPlanSaveElement.ActionPlanPlanPropertyFK}']`);
                for (let actionPlanStep of actionPlanSteps) {
                    let actionPlanStepElement = actionPlanStep;
                    if ("actionplanstepplanpropertyfk" in actionPlanStepElement.dataset && "actionplanplanpropertyfk" in actionPlanStepElement.dataset && "planmonitoringactionplanstepfk" in actionPlanStepElement.dataset) {
                        //level of completion
                        let levelOfCompletion = null;
                        let levelOfCompletionElement = actionPlanStepElement.querySelector(`.levelOfCompletion[data-actionplanplanpropertyfk='${actionPlanStepElement.dataset.actionplanplanpropertyfk}'][data-actionplanstepplanpropertyfk='${actionPlanStepElement.dataset.actionplanstepplanpropertyfk}']`);
                        if (levelOfCompletionElement != null && parseInt(levelOfCompletionElement.value)) {
                            levelOfCompletion = parseInt(levelOfCompletionElement.value);
                        }
                        //revise action plan
                        let reviseActionPlan = null;
                        let reviseActionPlanElement = actionPlanStepElement.querySelector(`.reviseActionPlan[data-actionplanplanpropertyfk='${actionPlanStepElement.dataset.actionplanplanpropertyfk}'][data-actionplanstepplanpropertyfk='${actionPlanStepElement.dataset.actionplanstepplanpropertyfk}']`);
                        if (reviseActionPlanElement != null && parseInt(reviseActionPlanElement.value)) {
                            reviseActionPlan = parseInt(reviseActionPlanElement.value);
                        }
                        //barriers to completion
                        let barriersToCompletion = "";
                        let barriersToCompletionElement = actionPlanStepElement.querySelector(`.barriersToCompletion[data-actionplanplanpropertyfk='${actionPlanStepElement.dataset.actionplanplanpropertyfk}'][data-actionplanstepplanpropertyfk='${actionPlanStepElement.dataset.actionplanstepplanpropertyfk}']`);
                        if (barriersToCompletionElement != null) {
                            barriersToCompletion = barriersToCompletionElement.value;
                        }
                        let actionPlanStepSaveElement = {
                            ActionPlanStepPlanPropertyFK: parseInt(actionPlanStepElement.dataset.actionplanstepplanpropertyfk),
                            BarriersToCompletionValueText: barriersToCompletion,
                            LevelOfCompletionLookupCodeFK: levelOfCompletion,
                            PlanMonitoringActionPlanFK: actionPlanSaveElement.PlanMonitoringActionPlanPK,
                            PlanMonitoringActionPlanStepPK: parseInt(actionPlanStepElement.dataset.planmonitoringactionplanstepfk),
                            ReviseActionPlanLookupCodeFK: reviseActionPlan
                        };
                        actionPlanStepSaveElements.push(actionPlanStepSaveElement);
                    }
                }
                //Get the summary stuff
                let actionPlanSummarySaveElements = [];
                //Proposed Changes
                let proposedChanges = document.querySelectorAll(`.notesProposedChanges[data-actionplanplanpropertyfk='${actionPlan.dataset.actionplanplanpropertyfk}']`);
                for (let change of proposedChanges) {
                    let changeElement = change;
                    let propertyFK = parseInt(changeElement.dataset.propertyfk);
                    let notesOnProposedChangesValueText = null;
                    let levelOfCompletionLookupCodeFK = null;
                    let reviseActionPlanLookupCodeFK = null;
                    if (changeElement.value != "") {
                        notesOnProposedChangesValueText = changeElement.value;
                    }
                    let levelOfCompletionElement = document.querySelector(`.levelOfCompletionSummary[data-actionplanplanpropertyfk='${actionPlan.dataset.actionplanplanpropertyfk}'][data-propertyfk='${propertyFK}']`);
                    if (levelOfCompletionElement != null && levelOfCompletionElement.selectedIndex > 0) {
                        levelOfCompletionLookupCodeFK = levelOfCompletionElement.value;
                    }
                    let reviseActionPlanElement = document.querySelector(`.reviseActionPlanSummary[data-actionplanplanpropertyfk='${actionPlan.dataset.actionplanplanpropertyfk}'][data-propertyfk='${propertyFK}']`);
                    if (reviseActionPlanElement != null && reviseActionPlanElement.selectedIndex > 0) {
                        reviseActionPlanLookupCodeFK = reviseActionPlanElement.value;
                    }
                    let summarySaveElement = {
                        LevelOfCompletionLookupCodeFK: levelOfCompletionLookupCodeFK,
                        NotesOnProposedChangesValueText: notesOnProposedChangesValueText,
                        PlanMonitoringActionPlanFK: parseInt(actionPlan.dataset.planmonitoringactionplanfk),
                        PropertyFK: propertyFK,
                        ReviseActionPlanLookupCodeFK: reviseActionPlanLookupCodeFK
                    };
                    actionPlanSummarySaveElements.push(summarySaveElement);
                }
                let actionPlanSaveData = {
                    ActionPlan: actionPlanSaveElement,
                    ActionPlanSteps: actionPlanStepSaveElements,
                    Summary: actionPlanSummarySaveElements
                };
                actionPlans.push(actionPlanSaveData);
            }
        }
        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "PlanMonitoringFK": planMonitoringFK,
            "ActionPlans": actionPlans
        });
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/SaveActionPlanReview', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(saveData);
    }
}
class PlanMonitoringArticulationAgreement {
    constructor() {
        let that = this;
        var planMonitoringHdn = document.querySelector("[name='planMonitoringFK']");
        if (planMonitoringHdn) {
            that.planMonitoringFK = parseInt(planMonitoringHdn.value);
        }
        var templateHdn = document.querySelector("[name='templateFK']");
        if (templateHdn) {
            that.templateFK = parseInt(templateHdn.value);
        }
        var planHdn = document.querySelector("[name='planFK']");
        if (planHdn) {
            that.planFK = parseInt(planHdn.value);
        }
        var canEditHdn = document.querySelector("[name='canEdit']");
        if (planHdn) {
            that.canEdit = canEditHdn.value === "True";
        }
        that._core = new Core();
        that._core.activateSubMenu("subnavPlanMonitoring");
        that._core.leftnav(that);
        that._core.tabLinkSave(that);
        that.deletePlanMonitoringFileModal = new Modal('deletePlanMonitoringFileModal', null);
        that.deleteArticulationAgreementFileModal = new Modal('deleteArticulationAgreementFileModal', null);
        that.deleteArticulationConfirmModal = new Modal("deleteArticulationAgreementConfirmModal", null);
        that.validationClasses = ["planMonitoringField"];
        that.initializeRequiredFields();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            that._core.doValidation(that.validationClasses);
        }
        let saveButton = document.getElementById("savePage");
        if (saveButton !== null) {
            saveButton.addEventListener("click", (e) => that.save("save"));
        }
        let hidden = that._core.createHash(that.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteArticulationAgreementFileConfirm = document.getElementById("deleteArticulationAgreementFileConfirm");
        if (deleteArticulationAgreementFileConfirm != null) {
            deleteArticulationAgreementFileConfirm.addEventListener("click", () => that.deleteArticulationAgreementFileConfirm());
        }
        let deleteArticulationAgreementFileCancel = document.getElementById("deleteArticulationAgreementFileCancel");
        if (deleteArticulationAgreementFileCancel != null) {
            deleteArticulationAgreementFileCancel.addEventListener("click", () => that.deleteArticulationAgreementFileModal.hide());
        }
        let deletePlanMonitoringFileConfirm = document.getElementById("deleteFileConfirm");
        if (deletePlanMonitoringFileConfirm != null) {
            deletePlanMonitoringFileConfirm.addEventListener("click", () => that.deletePlanMonitoringFileConfirm());
        }
        let deletePlanMonitoringFileCancel = document.getElementById("deleteFileCancel");
        if (deletePlanMonitoringFileCancel != null) {
            deletePlanMonitoringFileCancel.addEventListener("click", () => that.deletePlanMonitoringFileModal.hide());
        }
        //Event listener for deleting an articulation agreement
        let deleteArticulationConfirmButton = document.getElementById("deleteArticulationConfirm");
        if (deleteArticulationConfirmButton != null) {
            deleteArticulationConfirmButton.addEventListener("click", () => {
                Core.showLoader();
                that.deleteArticulationConfirmModal.hide();
                that.deleteArticulationAgreement(that.articulationAgreementToDeletePK)
                    .then((response) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        //Event listener for cancelling the delete of a support
        let deleteArticulationCancelButton = document.getElementById("deleteArticulationCancel");
        if (deleteArticulationCancelButton != null) {
            deleteArticulationCancelButton.addEventListener("click", () => {
                that.deleteArticulationConfirmModal.hide();
                var returnFocusTo = document.querySelector(`.deletePlanMonitoringArticulationAgreement[data-articulation-pk='${that.articulationAgreementToDeletePK}']`);
                if (returnFocusTo != null) {
                    returnFocusTo.focus();
                }
            });
        }
        let addArticulationAgreement = document.getElementById("planMonitoringAddArticulationAgreement");
        if (addArticulationAgreement != null) {
            addArticulationAgreement.addEventListener("click", () => {
                Core.showLoader();
                that.addArticulationAgreement()
                    .then((response) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    that._core.initializeRequiredFields(that.validationClasses);
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        document.addEventListener("click", (e) => {
            let target = e.target;
            //Deleting an articulation agreement
            if (target.classList.contains("deletePlanMonitoringArticulationAgreement") && "articulationPk" in target.dataset) {
                that.deleteArticulationConfirmModal.show();
                that.articulationAgreementToDeletePK = parseInt(target.dataset.articulationPk);
            }
            else if (target.tagName == "BUTTON" && target.classList.contains("deleteArticulationAgreementFile") && "articulationFilePk" in target.dataset) {
                that.deleteArticulationAgreementFileModal.show();
                that.deleteArticulationAgreementFileModal.callingId = target.id;
                that.planMonitoringArticulationAgreementFileUploadToDeletePK = parseInt(target.dataset.articulationFilePk);
            }
            else if (target.tagName == "BUTTON" && target.classList.contains("deleteFile") && "monitoringFilePk" in target.dataset) {
                that.deletePlanMonitoringFileModal.show();
                that.deletePlanMonitoringFileModal.callingId = target.id;
                that.planMonitoringFileToDeletePK = parseInt(target.dataset.monitoringFilePk);
            }
        });
        document.addEventListener("change", (e) => {
            let target = e.target;
            //Uploading an articulation agreement file
            if (target.classList.contains("articulationAgreementFileUpload") && "articulationFk" in target.dataset && "planFk" in target.dataset) {
                that.uploadArticulationAgreementFile(parseInt(target.dataset.articulationFk), parseInt(target.dataset.planFk));
            }
            else if (target.classList.contains("fileUpload") && "propertyFk" in target.dataset) {
                that.uploadPlanMonitoringFile(parseInt(target.dataset.propertyFk));
            }
        });
        let hasArticulationsCheckbox = document.getElementById("noArticulationAgreements");
        if (hasArticulationsCheckbox != null) {
            hasArticulationsCheckbox.addEventListener("change", () => {
                that.initializeRequiredFields.apply(that);
            });
        }
    }
    initializeRequiredFields() {
        let that = this;
        let hasArticulationsCheckbox = document.getElementById("noArticulationAgreements");
        let formattedAllClasses = [];
        that.validationClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + that.validationClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);
        if (hasArticulationsCheckbox != null) {
            let hasArticulations = !hasArticulationsCheckbox.checked;
            for (let element of allElements) {
                if (hasArticulations) {
                    that._core.forceElementRequired(element);
                }
                else {
                    that._core.forceElementOptional(element);
                }
                if (that.canEdit) {
                    if (element instanceof HTMLSelectElement) {
                        element.disabled = !hasArticulations;
                    }
                    else if (element instanceof HTMLInputElement && element.id != "noArticulationAgreements") {
                        element.disabled = !hasArticulations;
                    }
                }
            }
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let that = this;
        let core = that._core;
        if (referrer !== "save" && that._core.checkSave(that) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let articulations = [];
        var articulationContainers = document.querySelectorAll(".newArticulationAgreementContainer[data-articulation-pk]");
        for (let articulation of articulationContainers) {
            let partneringInstitutionValue = "";
            let partneringInstitutionInput = document.querySelector(`.newArticulationPartneringInstitution[data-articulation-pk='${articulation.dataset.articulationPk}']`);
            if (partneringInstitutionInput != null) {
                partneringInstitutionValue = partneringInstitutionInput.value;
            }
            let agreementTypeValue = null;
            let agreementTypeSelect = document.querySelector(`.newArticulationAgreementType[data-articulation-pk='${articulation.dataset.articulationPk}']`);
            if (agreementTypeSelect != null && parseInt(agreementTypeSelect.value)) {
                agreementTypeValue = parseInt(agreementTypeSelect.value);
            }
            let programCourseValue = "";
            let programCourseInput = document.querySelector(`.newArticulationProgramCourseAreaName[data-articulation-pk='${articulation.dataset.articulationPk}']`);
            if (programCourseInput != null) {
                programCourseValue = programCourseInput.value;
            }
            let articulationSaveData = {
                PartneringInstitutionName: partneringInstitutionValue,
                AgreementTypeLookupCodeFK: agreementTypeValue,
                ProgramCourseAreaName: programCourseValue,
                PlanMonitoringArticulationAgreementPK: parseInt(articulation.dataset.articulationPk),
                PlanMonitoringFK: that.planMonitoringFK
            };
            articulations.push(articulationSaveData);
        }
        let isMonitoringArticulationAgreements = true;
        let isMonitoringCheckbox = document.getElementById("noArticulationAgreements");
        if (isMonitoringCheckbox != null && isMonitoringCheckbox.checked) {
            isMonitoringArticulationAgreements = false;
        }
        let saveData = JSON.stringify({
            "ArticulationAgreements": articulations,
            "IsMonitoringArticulationAgreements": isMonitoringArticulationAgreements,
            "PlanMonitoringFK": that.planMonitoringFK
        });
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/SaveArticulationAgreement', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, that.planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(saveData);
    }
    deleteArticulationAgreementFileConfirm() {
        let that = this;
        const core = this._core;
        Core.showLoader();
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/DeleteArticulationAgreementFile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully deleted.', 'success', 3000, null);
                        const containerElement = document.querySelector(`.uploadFileContainer[data-articulation-file-pk='${that.planMonitoringArticulationAgreementFileUploadToDeletePK}']`);
                        if (containerElement !== null) {
                            let nextFocusable = Core.getNextFocusableElement(containerElement);
                            containerElement.parentElement.removeChild(containerElement);
                            if (nextFocusable != null) {
                                nextFocusable.focus();
                            }
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error deleting file.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file removal failed. Please try again.', 'error', 3000, null);
            }
            that.deleteArticulationAgreementFileModal.hide();
        };
        xhr.send(that.planMonitoringArticulationAgreementFileUploadToDeletePK.toString());
    }
    addArticulationAgreement() {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/PlanMonitoring/CreateNewArticulationAgreement', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("newUpdatedArticulationAgreementContainer");
                    if (container != null) {
                        $(container).append(xhr.responseText);
                        resolve("Successfully added the new articulation agreement.");
                        that.initializeRequiredFields();
                    }
                    else {
                        reject("There was an unexpected error adding the new articulation agreement.");
                    }
                }
                else {
                    reject("There was an unexpected error adding the new articulation agreement");
                }
            };
            xhr.send("planMonitoringFK=" + that.planMonitoringFK + "&templateFK=" + that.templateFK + '&planFK=' + that.planFK);
        });
    }
    deleteArticulationAgreement(articulationPK) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/PlanMonitoring/DeleteArticulationAgreement', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let container = document.querySelector(`.newArticulationAgreementContainer[data-articulation-pk='${articulationPK}']`);
                    if (container != null) {
                        let deleteButton = document.querySelector(`.deletePlanMonitoringArticulationAgreement[data-articulation-pk='${articulationPK}']`);
                        let nextFocusable = Core.getNextFocusableElement(deleteButton);
                        container.parentNode.removeChild(container);
                        nextFocusable.focus();
                        resolve("Successfully deleted articulation agreement");
                    }
                    else {
                        reject("There was an unexpected error removing this articulation agreement");
                    }
                }
                else {
                    reject("There was an unexpected error deleting this articulation agreement");
                }
            };
            xhr.send("articulationPK=" + articulationPK);
        });
    }
    uploadArticulationAgreementFile(articulationPK, planPK) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = document.querySelector(`.uploadArticulationFileForm[data-articulation-fk='${articulationPK}']`);
        const formData = new FormData(uploadForm);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/UploadArticulationAgreementFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        const formFile = document.querySelector(`.articulationAgreementFileUpload[data-articulation-fk='${articulationPK}']`);
                        formFile.value = '';
                        const fileUpload = response.articulationFile;
                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/PlanMonitoring/GetPlanMonitoringArticulationAgreementFileUploadPartialView?planMonitoringArticulationAgreementFileUploadPK=${fileUpload.planMonitoringArticulationAgreementFileUploadPK}&planFK=${planPK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-articulation-fk='${articulationPK}']`);
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            }
                        };
                        xhrForPartialView.send();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    uploadPlanMonitoringFile(propertyFK) {
        const core = this._core;
        Core.showLoader();
        const uploadForm = document.querySelector(`.uploadForm[data-property-fk='${propertyFK}']`);
        const formData = new FormData(uploadForm);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/UploadMonitoringFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        const formFile = document.querySelector(`.fileUpload[data-property-fk='${propertyFK}']`);
                        formFile.value = '';
                        const fileUpload = response.planMonitoringFile;
                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/PlanMonitoring/GetPlanMonitoringFileUploadPartialView/${fileUpload.planMonitoringFilePK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.uploadedFilesContainer[data-property-fk='${propertyFK}']`);
                                $(fileUploadDiv).append(xhrForPartialView.response);
                            }
                        };
                        xhrForPartialView.send();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    deletePlanMonitoringFileConfirm() {
        let that = this;
        const core = this._core;
        Core.showLoader();
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/PlanMonitoring/DeleteMonitoringFile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully deleted.', 'success', 3000, null);
                        const containerElement = document.querySelector(`.uploadFileContainer[data-monitoring-file-pk='${that.planMonitoringFileToDeletePK}']`);
                        if (containerElement !== null) {
                            let fileInput = document.querySelector(`.fileUpload[data-property-fk='${containerElement.dataset.propertyFk}']`);
                            if (fileInput != null) {
                                that.deletePlanMonitoringFileModal.callingId = fileInput.id;
                            }
                            containerElement.parentElement.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error deleting file.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file removal failed. Please try again.', 'error', 3000, null);
            }
            that.deletePlanMonitoringFileModal.hide();
        };
        xhr.send(that.planMonitoringFileToDeletePK.toString());
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
//Summary
class CovidReportSummary {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["covidSummaryField"];
        this._core.leftnav(this);
        let textAreaControlStr = document.getElementsByClassName("covidSummaryStrength");
        for (let textArea of textAreaControlStr)
            textArea.addEventListener("input", (e) => this.checkTheStrengthCheckbox(e, "covidSummaryStrength"));
        let textAreaControlCha = document.getElementsByClassName("covidSummaryChallenge");
        for (let textArea of textAreaControlCha)
            textArea.addEventListener("input", (e) => this.checkTheStrengthCheckbox(e, "covidSummaryChallenge"));
        let saveButton = document.getElementById("listOfStrengthsAndChallengesSave");
        saveButton.addEventListener("click", (e) => this.save("save"));
    }
    checkTheStrengthCheckbox(e, className) {
        let textElement = e.srcElement;
        let fullClassName = `${className}Checkbox`;
        let row = parseInt(textElement.dataset.row);
        let rowCheckbox = document.querySelector(`.${fullClassName}[data-row='${row}']`);
        if (textElement.value !== "") {
            rowCheckbox.checked = true;
        }
        else {
            rowCheckbox.checked = false;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allStrengthElements = [];
        let allStrengthCheckboxElements = [];
        let allChallengeElements = [];
        let allChallengeCheckboxElements = [];
        let planElement = document.getElementById("covidListOfStrengthsAndChallengesForm");
        let planPk = planElement.dataset.planpk;
        let allElements = document.getElementsByClassName("covidSummaryStrength");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== '') {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allStrengthElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("covidSummaryStrengthCheckbox");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let val = "";
            if (htmlElement.checked) {
                val = "on";
            }
            if (htmlElement.checked) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allStrengthCheckboxElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("covidSummaryChallenge");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.value !== '') {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: htmlElement.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allChallengeElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("covidSummaryChallengeCheckbox");
        for (let element of allElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let val = "";
            if (htmlElement.checked) {
                val = "on";
            }
            if (htmlElement.checked) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: parseInt(planPk),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allChallengeCheckboxElements.push(saveItem);
            }
        }
        let reflectionElement;
        let reflectionElements = document.getElementsByClassName("summaryOfStrengthsReflectionsField");
        for (let element of reflectionElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planPk),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };
            reflectionElement = saveItem;
        }
        let allSaveElements = {
            "Strengths": allStrengthElements,
            "StrengthCheckboxes": allStrengthCheckboxElements,
            "Challenges": allChallengeElements,
            "ChallengeCheckboxes": allChallengeCheckboxElements,
            "PlanFK": planPk,
            "Reflections": reflectionElement
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CovidReopeningReport/SaveListOfSummaryAndStrengths', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planPk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allChallengeElements.length === 0 && allStrengthElements.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
                this.customValidation();
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    //TODO:
    customValidation() {
        let errorCount = 0;
        //Check to see if at least two challenges have been checked for consideration
        let challengeCheckboxes = document.getElementsByClassName("summaryOfChallengesField");
        let challengeCheckedCount = 0;
        for (let check of challengeCheckboxes) {
            if (check.checked) {
                challengeCheckedCount++;
            }
        }
        if (challengeCheckedCount < 2) {
            errorCount++;
            let checkboxErrorMessage = document.getElementById("summaryChallengesErrorContainer");
            if (checkboxErrorMessage !== null) {
                checkboxErrorMessage.classList.add("show");
            }
        }
        //Check to see if at least one strength has been checked for consideration
        let strengthCheckboxes = document.getElementsByClassName("summaryOfStrengthsField");
        let strengthCheckedCount = 0;
        for (let check of strengthCheckboxes) {
            if (check.checked) {
                strengthCheckedCount++;
            }
        }
        if (strengthCheckedCount === 0) {
            errorCount++;
            let strengthCheckboxErrorMessage = document.getElementById("summaryStrengthsErrorContainer");
            if (strengthCheckboxErrorMessage !== null) {
                strengthCheckboxErrorMessage.classList.add("show");
            }
        }
        if (errorCount > 0) {
            //Set text for error message
            let message = document.getElementById("validationMessage");
            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            }
            else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");
            if (goToError !== null) {
                let firstFocusableEl = document.querySelector(".custom-error.show");
                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                }
                else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
            this._core.doValidation(null, true);
        }
        else {
            this._core.doValidation(null, false);
        }
    }
}
//GoalSetting
class CovidGoalSetting {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["covidGoalSettingField", "covidGoalSettingLookupField"];
        this.deleteGoalModal = new Modal("deleteGoalModal", null);
        let saveButton = document.getElementById("covidGoalSettingSave");
        if (saveButton !== null)
            saveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let that = this;
        let deleteGoalButtons = document.querySelectorAll(".deleteGoal");
        for (let button of deleteGoalButtons) {
            button.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.show();
                that.deleteGoalModal.addAttributeToElement(that.deleteGoalModal.id, "#deleteGoalConfirm", "goalplanpropertypk", button.dataset.goalplanpropertypk);
            });
        }
        let deleteGoalConfirmButton = document.getElementById("deleteGoalConfirm");
        if (deleteGoalConfirmButton !== null) {
            deleteGoalConfirmButton.addEventListener("click", function (e) {
                e.preventDefault();
                if (deleteGoalConfirmButton.dataset.goalplanpropertypk && parseInt(deleteGoalConfirmButton.dataset.goalplanpropertypk)) {
                    that.deleteGoal(deleteGoalConfirmButton.dataset.goalplanpropertypk);
                }
                else {
                    that.deleteGoalModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal", "error", 3000, null);
                }
            });
        }
        let deleteGoalCancelButton = document.getElementById("deleteGoalCancel");
        if (deleteGoalCancelButton !== null) {
            deleteGoalCancelButton.addEventListener("click", function (e) {
                e.preventDefault();
                that.deleteGoalModal.hide();
            });
        }
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this._core.activateSubMenu("subNavReadySetGo");
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("covidGoalSettingForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let pageFK = parseInt(formElement.dataset.pagefk);
        let goals = [];
        let counter = 0;
        let goalStatements = document.querySelectorAll(".covidGoalSettingField[data-propertycode='goalSettingMeasurableGoalStatement']");
        for (let goal of goalStatements) {
            let goalElement = goal;
            let goalStatementPropertyPK = 0;
            let goalStatement = {
                PlanPropertyPK: parseInt(goalElement.dataset.planpropertypk),
                PlanFK: planFK,
                PropertyFK: parseInt(goalElement.dataset.propertypk),
                TextValue: goalElement.value,
                LookupCodeFK: null,
                RowNbr: parseInt(goalElement.dataset.row),
                IsDeletedInd: false,
                DataPointPlanPropertyPK: parseInt(goalElement.dataset.datapointplanpropertypk),
                ButtonRow: parseInt(goalElement.dataset.buttonrow)
            };
            console.log("PlanPropertyPK: " + goalElement.dataset.planpropertypk);
            console.log("PlanPropertyPK: " + parseInt(goalElement.dataset.planpropertypk));
            console.log("PlanFK: " + planFK);
            console.log("PropertyFK: " + goalElement.dataset.propertypk);
            console.log("PropertyFK: " + parseInt(goalElement.dataset.propertypk));
            console.log("TextValue: " + goalElement.value);
            console.log("RowNbr: " + goalElement.dataset.row);
            console.log("RowNbr: " + parseInt(goalElement.dataset.row));
            console.log("DataPointPlanPropertyPK: " + goalElement.dataset.datapointplanpropertypk);
            console.log("DataPointPlanPropertyPK: " + parseInt(goalElement.dataset.datapointplanpropertypk));
            console.log("ButtonRow: " + goalElement.dataset.buttonrow);
            console.log("ButtonRow: " + parseInt(goalElement.dataset.buttonrow));
            //Get fields associated with goal statement
            let goalDetails = [];
            let goalDetailsElements = document.querySelectorAll(`.covidGoalSettingField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement']), .covidGoalSettingLookupField[data-row='${goalElement.dataset.row}']:not([data-propertycode='goalSettingMeasurableGoalStatement'])`);
            for (let detail of goalDetailsElements) {
                counter = counter + 1;
                console.log(counter);
                if (detail.classList.contains("covidGoalSettingLookupField")) {
                    console.log(counter);
                    let htmlElement = detail;
                    let planPropertyPK = 0;
                    let dataPointPlanPropertyPK = 0;
                    let rowNumber = parseInt(htmlElement.dataset.row);
                    let buttonRow = parseInt(htmlElement.dataset.buttonrow);
                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                    if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                        dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                    console.log('here' + counter);
                    console.log("saveItemBefore: " + counter);
                    if (htmlElement.value !== "" || planPropertyPK > 0) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: "",
                            LookupCodeFK: parseInt(htmlElement.value),
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                            ButtonRow: buttonRow
                        };
                        goalDetails.push(saveItem);
                    }
                }
                else {
                    let htmlElement = detail;
                    let planPropertyPK = 0;
                    let doNotInclude = false;
                    let rowNumber = parseInt(htmlElement.dataset.row);
                    let buttonRow = parseInt(htmlElement.dataset.buttonrow);
                    if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                        planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                    let value = htmlElement.value;
                    if (htmlElement.classList.contains("goalSettingCheckbox")) {
                        if (htmlElement.checked) {
                            value = "on";
                        }
                        else {
                            value = "off";
                            if (planPropertyPK === 0) {
                                doNotInclude = true;
                            }
                        }
                    }
                    if ((value || planPropertyPK > 0) && !doNotInclude) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planFK,
                            PropertyFK: parseInt(htmlElement.dataset.propertypk),
                            TextValue: value,
                            LookupCodeFK: null,
                            RowNbr: rowNumber,
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: goalStatement.PlanPropertyPK,
                            ButtonRow: buttonRow
                        };
                        console.log("PlanPropertyPK: " + planPropertyPK);
                        console.log("PlanFK: " + planFK);
                        console.log("PropertyFK: " + htmlElement.dataset.propertypk);
                        console.log("PropertyFK: " + parseInt(htmlElement.dataset.propertypk));
                        console.log("TextValue: " + value);
                        console.log("RowNbr: " + rowNumber);
                        console.log("DataPointPlanPropertyPK: " + goalStatement.PlanPropertyPK);
                        console.log("ButtonRow: " + buttonRow);
                        console.log("saveItem: " + counter);
                        goalDetails.push(saveItem);
                    }
                }
            }
            let goalSettingSaveData = {
                GoalDetails: goalDetails,
                GoalStatement: goalStatement
            };
            goals.push(goalSettingSaveData);
        }
        let saveData = JSON.stringify({
            "PlanFK": planFK,
            "PageFK": pageFK,
            "MeasurableGoals": goals
        });
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CovidReopeningReport/SaveGoalSetting', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (goals.length === 0) {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            console.log('hereToSave');
            xhr.send(saveData);
        }
    }
    deleteGoal(goalPlanPropertyPK) {
        let that = this;
        that.deleteGoalModal.hide();
        let goal = document.querySelector(`[data-planpropertypk='${goalPlanPropertyPK}']`);
        if (goal != null && goal.dataset.row) {
            let container = document.getElementById(goal.dataset.container);
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CovidReopeningReport/DeleteGoal', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Goal successfully deleted", 'success', 3000, null);
                    //reset fields
                    let goalFields = document.querySelectorAll(`.covidGoalSettingField[data-row='${goal.dataset.row}'], .covidGoalSettingLookupField[data-row='${goal.dataset.row}']`);
                    for (let field of goalFields) {
                        let htmlElement = field;
                        //Lookup
                        if (field instanceof HTMLSelectElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.selectedIndex = 0;
                        }
                        else if (field instanceof HTMLInputElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }
                        else if (field instanceof HTMLTextAreaElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }
                        //remove delete button for the goal
                        let deleteButton = document.querySelector(`.deleteGoal[data-goalplanpropertypk='${goalPlanPropertyPK}']`);
                        if (deleteButton != null) {
                            deleteButton.parentNode.removeChild(deleteButton);
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted goal", "success", 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(goalPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
        }
    }
}
/// <reference path="../core.ts" />
class LazyAccordion {
    constructor(accordionId = null) {
        if (accordionId === null) {
            const allLazyAccordions = document.getElementsByClassName("lazyAccordionTrigger");
            for (const accordion of allLazyAccordions) {
                const accordionButton = accordion;
                if (!accordionButton.hasAttribute("data-hasclick")) {
                    accordion.setAttribute("data-hasclick", "true");
                    accordion.addEventListener("click", (e) => this.toggle(accordion.id));
                }
            }
        }
        else {
            const thisLazyAccordion = document.getElementById(accordionId);
            if (thisLazyAccordion !== null) {
                if (!thisLazyAccordion.hasAttribute("data-hasclick")) {
                    thisLazyAccordion.setAttribute("data-hasclick", "true");
                    thisLazyAccordion.addEventListener("click", (e) => this.toggle(thisLazyAccordion.id));
                }
            }
        }
    }
    toggle(accordionId) {
        const accordion = document.getElementById(accordionId);
        let isOpen;
        const contentId = accordion.getAttribute("aria-controls");
        const contentElement = document.getElementById(contentId);
        if (contentElement.classList.contains("open")) {
            isOpen = true;
        }
        if (isOpen === true) {
            this.hide(accordion, contentElement);
        }
        else {
            if (accordion.dataset.loaded === "true") {
                this.show(accordion, contentElement);
            }
            else {
                this.loadContent(accordion, contentElement);
            }
        }
    }
    show(accordion, contentElement) {
        accordion.classList.add("open");
        accordion.setAttribute("aria-expanded", "true");
        accordion.setAttribute("data-loaded", "true");
        contentElement.classList.add("open");
    }
    hide(accordion, contentElement) {
        accordion.classList.remove("open");
        accordion.setAttribute("aria-expanded", "false");
        contentElement.classList.remove("open");
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/lazyAccordion.ts" />
// Special Education Profile
class SpecialEducationProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationProfileField"];
        let specialEducationProfileSaveButton = document.getElementById("specialEducationProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let profileAndPlanSpecialEducationTotalStudentEnrollmentElement = document.getElementById("profileAndPlanSpecialEducationTotalNumberOfStudents");
        if (profileAndPlanSpecialEducationTotalStudentEnrollmentElement !== null)
            profileAndPlanSpecialEducationTotalStudentEnrollmentElement.addEventListener("change", (e) => this.calculateSpecialEducationPercent());
        let profileAndPlanSpecialEducationTotalEnrollmentElement = document.getElementById("profileAndPlanSpecialEducationTotalStudentEnrollment");
        if (profileAndPlanSpecialEducationTotalEnrollmentElement !== null)
            profileAndPlanSpecialEducationTotalEnrollmentElement.addEventListener("change", (e) => this.calculateSpecialEducationPercent());
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("specialEducationProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("specialEducationProfileForm");
        let inputs = document.getElementsByClassName("specialEducationProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("specialEducationProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    calculateSpecialEducationPercent() {
        let totalNumberOfStudentsElement = document.getElementById("profileAndPlanSpecialEducationTotalNumberOfStudents");
        let totalEnrollmentElement = document.getElementById("profileAndPlanSpecialEducationTotalStudentEnrollment");
        let percentElement = document.getElementById("profileAndPlanSpecialEducationPercentOfSpecialEducation");
        let totalNumberOfStudents = 0;
        let totalEnrollment = 0;
        let percentOfStudents = 0.0;
        if (totalNumberOfStudentsElement !== null && totalNumberOfStudentsElement.value !== "")
            totalNumberOfStudents = parseInt(totalNumberOfStudentsElement.value);
        if (totalEnrollmentElement !== null && totalEnrollmentElement.value !== "")
            totalEnrollment = parseInt(totalEnrollmentElement.value);
        let num = (totalNumberOfStudents / totalEnrollment) * 100;
        percentElement.value = parseFloat(num.toFixed(1)).toString();
    }
}
// Special Education Steering Committee
class SpecialEducationSteeringCommittee {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationSteeringCommitteeField"];
        let specialEducationSteeringCommitteeSaveButton = document.getElementById("specialEducationSteeringCommitteeSave");
        if (specialEducationSteeringCommitteeSaveButton !== null)
            specialEducationSteeringCommitteeSaveButton.addEventListener("click", (e) => this.save("save"));
        let steeringCommitteeAddRows = document.getElementById("specialEducationSteeringCommitteeAddRows");
        if (steeringCommitteeAddRows !== null)
            steeringCommitteeAddRows.addEventListener("click", (e) => this.steeringCommitteeAddRows(e));
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteSteeringCommitteeConfirmButton = document.getElementById("specialEducationSteeringCommitteeDeleteConfirm");
        if (deleteSteeringCommitteeConfirmButton !== null)
            deleteSteeringCommitteeConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteSteeringCommitteeCancelButton = document.getElementById("specialEducationSteeringCommitteeDeleteCancel");
        if (deleteSteeringCommitteeCancelButton !== null)
            deleteSteeringCommitteeCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        this.bindDeleteRowButtons();
        let wordPluralizer = document.getElementById("specialEducationSteeringCommitteeAddRowsNumber");
        if (wordPluralizer !== null)
            wordPluralizer.addEventListener("change", (e) => this.checkForPlural());
        let steeringCommitteeExportToExcelButton = document.getElementById("specialEducationSteeringCommitteeExportToExcel");
        if (steeringCommitteeExportToExcelButton !== null) {
            steeringCommitteeExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSteeringCommitteeRow");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null);
        modal.addAttributeToElement("specialEducationDeleteSteeringCommitteeRow", "#specialEducationSteeringCommitteeDeleteConfirm", "row", row);
        modal.show();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let steeringCommitteeFormElement = document.getElementById("specialEducationSteeringCommitteeForm");
        let inputs = document.getElementsByClassName("specialEducationSteeringCommitteeField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(steeringCommitteeFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSteeringCommittee', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    steeringCommitteeAddRows(e) {
        let target = e.target;
        let core = this._core;
        let value = document.getElementById("specialEducationSteeringCommitteeAddRowsNumber");
        let _that = this;
        let planFK = target.dataset.planfk;
        if (value.value === "") {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
        else {
            let rows = parseInt(value.value);
            let row = 0;
            let table = document.getElementById("steeringCommittee");
            let trs = table.rows;
            for (let tr of trs) {
                if (parseInt(tr.getAttribute("data-row")) > row)
                    row = parseInt(tr.getAttribute("data-row"));
            }
            row++;
            for (let i = 0; i < rows; i++) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/SteeringCommitteeRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        $("#steeringCommittee tbody").append(xhr.responseText);
                        value.value = "";
                        let rows = document.getElementsByClassName("removeSteeringCommitteeRow");
                        let row = rows[rows.length - 1];
                        _that.bindDeleteRowButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Steering Committee Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send(`row=${row}&planFK=${planFK}`);
                row++;
            }
        }
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("specialEducationSteeringCommitteeForm");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();
        let committeeFields = document.getElementsByClassName("specialEducationSteeringCommitteeField");
        for (let comm of committeeFields) {
            let inputElement = comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-- Select One --")
                    allBlank = false;
            }
        }
        if (allBlank) {
            let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);
                    let modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        }
        else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteSteeringCommitteeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let allTheRows = document.getElementsByClassName("steeringCommitteeRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }
                    let modal = new Modal("specialEducationDeleteSteeringCommitteeRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The steering committee row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    checkForPlural() {
        let word = document.getElementById("rowWord");
        let num = document.getElementById("specialEducationSteeringCommitteeAddRowsNumber");
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }
        word.textContent = newWord;
    }
    initializeRequiredFieldsCustom() {
        //If any field in a row is filled out, force rest of row required (except email)
        let steeringCommitteeTable = document.getElementById("steeringCommittee");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        for (let row of steeringCommitteeTableRows) {
            let elements = row.getElementsByClassName("specialEducationSteeringCommitteeField");
            for (let element of elements) {
                if (element instanceof HTMLSelectElement) {
                    if (element.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode !== "specialEducationSteeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    if (element.value !== "") {
                        for (let otherElement of elements) {
                            if (otherElement.dataset.propertycode !== "specialEducationSteeringCommitteeEmail") {
                                otherElement.dataset.forcerequired = "true";
                            }
                        }
                        break;
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationSteeringCommitteeForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationSteeringCommitteeExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationSteeringCommittee.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}
// Special Education School District Areas Of Improvement And Planning - Indicators
class SpecialEducationIndicators {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIndicatorsField"];
        let specialEducationIndicatorsSaveButton = document.getElementById("specialEducationIndicatorsSave");
        if (specialEducationIndicatorsSaveButton !== null)
            specialEducationIndicatorsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation();
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let specialEducationIndicatorAddIndicatorTopButton = document.getElementById("specialEducationIndicatorAddIndicatorTop");
        if (specialEducationIndicatorAddIndicatorTopButton !== null)
            specialEducationIndicatorAddIndicatorTopButton.addEventListener("click", (e) => this.addNewIndicator());
        let specialEducationIndicatorNotFlagged = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        for (let specialEducationIndicatorNotFlaggedEle of specialEducationIndicatorNotFlagged)
            specialEducationIndicatorNotFlaggedEle.addEventListener("click", (e) => this.notFlagged(e));
        let deleteIndicatorConfirmButton = document.getElementById("deleteIndicatorConfirm");
        if (deleteIndicatorConfirmButton !== null)
            deleteIndicatorConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteIndicatorCancelButton = document.getElementById("deleteIndicatorCancel");
        if (deleteIndicatorCancelButton !== null)
            deleteIndicatorCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        this.bindAddImprovementAndPlanningActivityButtons();
        this.bindCheckboxes();
        this.notFlaggedLoading();
        this.bindDeleteButtons();
    }
    notFlagged(e) {
        let checkboxElement = e.target;
        this.notFlaggedWork(checkboxElement);
    }
    notFlaggedLoading() {
        let allCheckboxes = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        for (let checkbox of allCheckboxes) {
            let checkboxElement = checkbox;
            this.notFlaggedWork(checkboxElement);
        }
    }
    notFlaggedWork(checkboxElement) {
        let pageSectionFK = checkboxElement.dataset.pagesectionfk;
        let textElements = document.querySelectorAll(`table[data-pagesectionfk='${pageSectionFK}'] textarea`);
        let button = document.querySelector(`button[data-pagesectionfk='${pageSectionFK}']`);
        const pageSectionPK = checkboxElement.dataset.pagesectionpk;
        const accordion = document.querySelector(`.Accordion-trigger[data-pagesectionpk='${pageSectionFK}']`);
        if (checkboxElement.checked) {
            for (let textbox of textElements) {
                let textboxElement = textbox;
                textboxElement.value = "";
                textboxElement.readOnly = true;
                this._core.forceElementOptional(textboxElement);
            }
            accordion.classList.add("summary-dark");
            button.disabled = true;
        }
        else {
            for (let textbox of textElements) {
                let textboxElement = textbox;
                textboxElement.readOnly = false;
                this._core.forceElementRequired(textboxElement);
            }
            accordion.classList.remove("summary-dark");
            button.disabled = false;
        }
    }
    bindAddImprovementAndPlanningActivityButtons() {
        let allButtons = document.getElementsByClassName("indicatorsAddImprovementPlanningActivity");
        for (let button of allButtons)
            button.addEventListener("click", (e) => this.addNewImprovementAndPlanningActivity(e));
    }
    addNewIndicator() {
        let core = this._core;
        let indicatorSelection = document.getElementById("specialEducationIndicatorsOtherIndicatorsSelection");
        let pageSectionCode = indicatorSelection[indicatorSelection.selectedIndex].dataset.pagesectioncode;
        let pageSectionFK = 0;
        let planFK = 0;
        if (indicatorSelection !== null) {
            pageSectionFK = parseInt(indicatorSelection.value);
            planFK = parseInt(indicatorSelection.dataset.planfk);
        }
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIndicator', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector("#indicators");
                var div = document.createElement("div");
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                let newAccordion = `indicatorsAccordion${pageSectionCode}`;
                new CustomAccordion(newAccordion);
                indicatorSelection.remove(indicatorSelection.options.selectedIndex);
                that.bindAddImprovementAndPlanningActivityButtons();
                that.bindCheckboxes();
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&indicatorPageSectionFK=" + pageSectionFK);
    }
    addNewImprovementAndPlanningActivity(e) {
        let core = this._core;
        let button = e.target;
        let pageSectionFK = button.dataset.pagesectionfk;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCode = "";
        let improvementPlanningActivities = document.querySelectorAll(`.performance-data-table[data-pagesectionfk='${pageSectionFK}'] tbody .specialEducationIndicatorsField`);
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
            propertyCode = improvementElement.dataset.propertycode;
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIndicatorImprovementPlanningActivity', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`.performance-data-table[data-pagesectionfk='${pageSectionFK}'] tbody`);
                var tr = document.createElement("tr");
                tr.dataset.row = newRow.toString();
                tr.dataset.newrow = "true";
                tr.dataset.pagesectionfk = pageSectionFK;
                tr.innerHTML = xhr.responseText;
                element.appendChild(tr);
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCode=${propertyCode}`);
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationIndicatorsFormElement = document.getElementById("specialEducationIndicatorsForm");
        let inputs = document.getElementsByClassName("specialEducationIndicatorsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIndicatorsFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxInputs = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIndicatorsFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIndicators', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindCheckboxes() {
        let allCheckboxes = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        for (let check of allCheckboxes) {
            check.addEventListener("change", (e) => this.disabledCheckbox(e));
        }
    }
    disabledCheckbox(e) {
        let checkbox = e.target;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    initializeRequiredFieldsCustom(allClasses, refresh = false) {
        let checkboxes = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        for (let checkbox of checkboxes) {
            let checkboxElement = checkbox;
            let sectionFK = checkboxElement.dataset.pagesectionfk;
            let fields = document.querySelectorAll(`tr[data-pagesectionfk='${sectionFK}'] .specialEducationIndicatorsField`);
            if (checkboxElement.checked) {
                for (let element of fields) {
                    let ele = element;
                    this._core.forceElementOptional(ele);
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    doCustomValidation() {
        let checkboxElements = document.getElementsByClassName("specialEducationIndicatorsCheckboxField");
        let checkboxElement = document.createElement("input");
        let isValid = true;
        for (let checkbox of checkboxElements) {
            checkboxElement = checkbox;
            let isCheckValid = false;
            if (checkboxElement.checked)
                isCheckValid = true;
            if (!isCheckValid) {
                let fields = document.getElementsByClassName("specialEducationIndicatorsField");
                for (let field of fields) {
                    let element = field;
                    if (element.dataset.percent === "1.00")
                        if (element.value === "")
                            isValid = false;
                }
            }
        }
        let showMessage = !isValid;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
        this._core.doValidation(["specialEducationIndicatorsField", "specialEducationIndicatorsCheckboxField"]);
    }
    bindDeleteButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationIndicatorsField");
        for (let deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.deleteRow(e));
    }
    deleteRow(e) {
        let buttonElement = e.target;
        let pageSectionFK = buttonElement.dataset.pagesectionfk;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        let modal = new Modal("deleteIndicatorModal", null);
        modal.addAttributeToElement("deleteIndicatorModal", "#deleteIndicatorConfirm", "row", row);
        modal.addAttributeToElement("deleteIndicatorModal", "#deleteIndicatorConfirm", "pagesectionfk", pageSectionFK);
        modal.addAttributeToElement("deleteIndicatorModal", "#deleteIndicatorConfirm", "newrow", newRow);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("deleteIndicatorModal", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        let pageSectionFK = buttonElement.dataset.pagesectionfk;
        let core = this._core;
        Core.showLoader();
        if (newRow === "true") {
            let thisRowElement = document.querySelector(`.performance-data-table tr[data-row='${row}'][data-pagesectionfk='${pageSectionFK}']`);
            thisRowElement.remove();
            let modal = new Modal("deleteIndicatorModal", null);
            modal.hide();
            Core.createHTMLAlert("alertMessageDiv", "The Improvement and Planning Activity has been removed.", 'success', 3000, null);
            Core.hideLoader();
        }
        else {
            let planProps = [];
            let thisRowElement = document.querySelector(`.performance-data-table tr[data-row='${row}'][data-pagesectionfk='${pageSectionFK}']`);
            let rowElements = thisRowElement.querySelectorAll("td textarea.specialEducationIndicatorsField");
            for (let row of rowElements) {
                let rowElement = row;
                let planProp = rowElement.dataset.planpropertypk;
                if (planProp && parseInt(rowElement.dataset.planpropertypk) > 0) {
                    planProps.push(parseInt(rowElement.dataset.planpropertypk));
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteIndicatorRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    if (xhr.responseText === "true") {
                        thisRowElement.remove();
                        Core.createHTMLAlert("alertMessageDiv", "The Improvement and Planning Activity has been removed.", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this activity, please try again.", 'error', 3000, null);
                    }
                    Core.hideLoader();
                    let modal = new Modal("deleteIndicatorModal", null);
                    modal.hide();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
}
// Special Education School District Areas Of Improvement And Planning - Cyclical Monitoring
class SpecialEducationCyclicalMonitoring {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationCyclicalMonitoringField", "specialEducationCyclicalMonitoringCheckboxField"];
        let specialEducationCyclicalMonitoringFormSaveButton = document.getElementById("specialEducationCyclicalMonitoringSave");
        if (specialEducationCyclicalMonitoringFormSaveButton !== null)
            specialEducationCyclicalMonitoringFormSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation();
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let specialEducationCyclicalMonitoringAddCorrectiveActionButton = document.getElementById("specialEducationCyclicalMonitoringAddCorrectiveAction");
        if (specialEducationCyclicalMonitoringAddCorrectiveActionButton !== null)
            specialEducationCyclicalMonitoringAddCorrectiveActionButton.addEventListener("click", (e) => this.addCorrectiveAction(e));
        let specialEducationCyclicalMonitoringCompletedCorrectiveActionButton = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        for (let element of specialEducationCyclicalMonitoringCompletedCorrectiveActionButton) {
            element.addEventListener("change", (e) => this.completedCorrectiveAction());
        }
        this.completedCorrectiveAction();
        this.bindCheckboxes();
        let deleteFileConfirmButton = document.getElementById("specialEducationDeleteDistrictMonitoringConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteFileCancelButton = document.getElementById("specialEducationDeleteDistrictMonitoringCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        this.bindDeleteButtons();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationCyclicalMonitoringFormElement = document.getElementById("specialEducationCyclicalMonitoringForm");
        let inputs = document.getElementsByClassName("specialEducationCyclicalMonitoringField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationCyclicalMonitoringFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxInputs = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        for (let ele of checkboxInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationCyclicalMonitoringFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                if (element.checked) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "on",
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
                else {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "off",
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationCyclicalMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    completedCorrectiveAction() {
        let checkboxElements = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements)
            checkboxElement = checkbox;
        let textElements = document.getElementsByClassName("specialEducationCyclicalMonitoringField");
        let button = document.getElementById("specialEducationCyclicalMonitoringAddCorrectiveAction");
        if (checkboxElement.checked) {
            for (let textbox of textElements) {
                let textboxElement = textbox;
                textboxElement.value = "";
                textboxElement.readOnly = true;
                button.disabled = true;
                this._core.forceElementOptional(textboxElement);
            }
        }
        else {
            for (let textbox of textElements) {
                let textboxElement = textbox;
                textboxElement.readOnly = false;
                button.disabled = false;
                this._core.forceElementRequired(textboxElement);
            }
        }
    }
    addCorrectiveAction(e) {
        let core = this._core;
        let button = e.target;
        let pageSectionFK = button.dataset.pagesectionfk;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationCyclicalMonitoringField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationCyclicalMonitoringCorrectiveAction', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`.performance-data-table[data-pagesectionfk='${pageSectionFK}'] tbody`);
                var tr = document.createElement("tr");
                tr.innerHTML = xhr.responseText;
                tr.classList.add("specialEducationDistrictMonitoringRow");
                tr.dataset.row = newRow.toString();
                tr.dataset.newrow = "true";
                element.appendChild(tr);
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&rowNumber=" + newRow);
    }
    initializeRequiredFieldsCustom(allClasses, refresh = false) {
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let checkboxElements = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements) {
            checkboxElement = checkbox;
        }
        let classesToValidate = formattedAllClasses.join(",");
        if (!checkboxElement.checked) {
            if (refresh) {
                let allElements = document.querySelectorAll(classesToValidate);
                for (let element of allElements) {
                    let htmlElement = element;
                    htmlElement.removeAttribute("aria-required");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null) {
                        label.classList.remove("isRequired");
                        let asterisk = label.querySelector(".required-label");
                        if (asterisk != null) {
                            asterisk.parentNode.removeChild(asterisk);
                        }
                    }
                }
            }
        }
        let allElements = document.querySelectorAll(classesToValidate);
        if (checkboxElement.checked) {
            //
        }
        else {
            for (let element of allElements) {
                let alreadyExists = false;
                let htmlElement = element;
                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                    //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                    var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                    for (let otherElement of otherElements) {
                        let otherHtmlElement = otherElement;
                        if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                            alreadyExists = true;
                        }
                    }
                    if (!alreadyExists) {
                        if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                            htmlElement.setAttribute("aria-required", "true");
                            let label = Core.findLabelForInput(htmlElement);
                            if (label !== null && !label.classList.contains("isRequired")) {
                                label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                                label.classList.add("isRequired");
                            }
                        }
                    }
                }
            }
        }
    }
    doCustomValidation() {
        let checkboxElements = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements) {
            checkboxElement = checkbox;
        }
        let isCheckValid = false;
        if (checkboxElement.checked)
            isCheckValid = true;
        let isValid = true;
        if (isCheckValid) {
            let fields = document.getElementsByClassName("specialEducationTargetedMonitoringField");
            for (let field of fields) {
                let element = field;
                if (element.dataset.percent === "1.00")
                    if (element.value === "")
                        isValid = false;
            }
        }
        let showMessage = !isValid;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    bindCheckboxes() {
        let allCheckboxes = document.getElementsByClassName("specialEducationCyclicalMonitoringCheckboxField");
        for (let check of allCheckboxes) {
            check.addEventListener("change", (e) => this.disabledCheckbox(e));
        }
    }
    disabledCheckbox(e) {
        let checkbox = e.target;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    bindDeleteButtons() {
        let buttons = document.getElementsByClassName("removeDistrictMonitoringField");
        for (let button of buttons)
            button.addEventListener("click", (e) => this.deleteIdentificationMethod(e));
    }
    deleteIdentificationMethod(e) {
        let button = e.target;
        let row = button.dataset.row;
        let newRow = button.dataset.newrow;
        let modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDistrictMonitoringDeleteRowModal", "#specialEducationDeleteDistrictMonitoringConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDistrictMonitoringDeleteRowModal", "#specialEducationDeleteDistrictMonitoringConfirm", "newrow", newRow);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        Core.showLoader();
        if (buttonElement.dataset.newrow === "true") {
            let trElement = document.querySelector(`.specialEducationDistrictMonitoringRow[data-row='${row}']`);
            trElement.remove();
            let modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
            modal.hide();
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
        }
        else {
            let rowToDelete = document.querySelector(`.specialEducationDistrictMonitoringRow[data-row='${row}']`);
            let rowElements = rowToDelete.querySelectorAll(`textarea.specialEducationCyclicalMonitoringField[data-row='${row}']`);
            for (let ele of rowElements) {
                let element = ele;
                let planProp = element.dataset.planpropertypk;
                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDistrictMonitoringRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    rowToDelete.remove();
                    Core.hideLoader();
                    let modal = new Modal("specialEducationDistrictMonitoringDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
}
// Special Education Idenfication Model
class SpecialEducationIdentificationMethod {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIdentificationMethodSelectField", "specialEducationIdentificationMethodBuildingField", "specialEducationIdentificationMethodSchoolTable"];
        let specialEducationIdentificationMethodFormSaveButton = document.getElementById("specialEducationIdentificationMethodSave");
        if (specialEducationIdentificationMethodFormSaveButton !== null)
            specialEducationIdentificationMethodFormSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let identificationDropdown = document.getElementById("specialEducationIdentificationspecialEducationIdentificationIdentify");
        if (identificationDropdown !== null)
            identificationDropdown.addEventListener("change", (e) => this.changeIdentification(e.target));
        this.changeIdentification(identificationDropdown);
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation();
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteFileConfirmButton = document.getElementById("specialEducationDeleteIdentificationMethodDeleteConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteFileCancelButton = document.getElementById("specialEducationDeleteIdentificationMethodDeleteCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        let specialEducationIdentificationAddSchoolButton = document.getElementById("specialEducationIdentificationAddSchool");
        if (specialEducationIdentificationAddSchoolButton !== null)
            specialEducationIdentificationAddSchoolButton.addEventListener("click", (e) => this.addSchool(e));
        let specialEducationIdentificationMethodBuildingElement = document.getElementById("specialEducationIdentificationBuildingName");
        if (specialEducationIdentificationMethodBuildingElement !== null)
            specialEducationIdentificationMethodBuildingElement.addEventListener("change", (e) => this.changeBuilding(e));
        this.bindCheckboxes();
        this.bindDeleteButtons();
        const identificationMethodExportToExcelButton = document.getElementById("specialEducationIdentificationMethodExportToExcel");
        if (identificationMethodExportToExcelButton !== null) {
            identificationMethodExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationIdentificationMethodFormElement = document.getElementById("specialEducationIdentificationMethodForm");
        let selects = document.getElementsByClassName("specialEducationIdentificationMethodSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIdentificationMethodFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("specialEducationIdentificationMethodCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIdentificationMethodFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        planPK = parseInt(specialEducationIdentificationMethodFormElement.dataset.planfk);
        let tableData = document.getElementsByClassName("specialEducationIndentificationMethodRow");
        for (let ele of tableData) {
            let tableEle = ele;
            let pdeApprovedElement = tableEle.querySelector(".specialEducationIndentificationMethodRowCellPDEApproved");
            let rtiElement = tableEle.querySelector(".specialEducationIndentificationMethodRowCellRTI");
            let branchElement = tableEle.querySelector(".specialEducationIndentificationMethodRowCellBranch");
            let aunElement = tableEle.querySelector(".specialEducationIndentificationMethodRowCellAUN");
            let buildingPKElement = tableEle.querySelector(".specialEducationIndentificationMethodRowCellPK");
            let buildingNameElement = tableEle.querySelector(".specialEducationIndentificationMethodRowCellName");
            let allElements = [];
            allElements.push(buildingPKElement);
            allElements.push(buildingNameElement);
            allElements.push(aunElement);
            allElements.push(branchElement);
            allElements.push(pdeApprovedElement);
            let row = null;
            if (tableEle.dataset.row)
                row = parseInt(tableEle.dataset.row);
            for (let element of allElements) {
                let building = element;
                let planPropertyPK = 0;
                let propertyPK = 0;
                let lookupCodePK = null;
                let textValue = null;
                if (building.dataset.propertypk)
                    propertyPK = parseInt(building.dataset.propertypk);
                if (building.dataset.textvalue)
                    textValue = building.dataset.textvalue;
                let hadValue = false;
                if (building.dataset.planpropertypk && building.dataset.planpropertypk !== "" && building.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(building.dataset.planpropertypk);
                    hadValue = true;
                }
                if (hadValue || textValue !== "") {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        IsDeletedInd: false,
                        LookupCodeFK: lookupCodePK,
                        RowNbr: row,
                        TextValue: textValue
                    };
                    allSaveElements.push(saveItem);
                }
            }
            let rtiSpans = rtiElement.querySelectorAll("span");
            for (let rti of rtiSpans) {
                let rtiSpan = rti;
                let propertyElement = document.querySelector(".multiSelectIdentificationRTI");
                let planPropertyPK = 0;
                let propertyPK = 0;
                let lookupCodePK = null;
                let textValue = null;
                if (propertyElement.dataset.propertypk)
                    propertyPK = parseInt(propertyElement.dataset.propertypk);
                if (rtiSpan.dataset.lookupcodepk)
                    lookupCodePK = parseInt(rtiSpan.dataset.lookupcodepk);
                let hadValue = false;
                if (rtiSpan.dataset.planpropertypk && rtiSpan.dataset.planpropertypk !== "" && rtiSpan.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(rtiSpan.dataset.planpropertypk);
                    hadValue = true;
                }
                if (hadValue || lookupCodePK > 0) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        IsDeletedInd: false,
                        LookupCodeFK: lookupCodePK,
                        RowNbr: row,
                        TextValue: textValue
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIdentificationMethod', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addSchool(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationIndentificationMethodRow");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let buildingNameElement = document.getElementById("specialEducationIdentificationBuildingName");
        let aunElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationAUN");
        let branchElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationBranchNumber");
        let pdeApprovedElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationPDEApproved");
        let buildingSelection = buildingNameElement[buildingNameElement.selectedIndex];
        let buildingName = buildingNameElement.value;
        let aun = aunElement.value;
        let branch = branchElement.value;
        let buildingPK = 0;
        let rtiList = [];
        if (buildingSelection.dataset.institutionpk && buildingSelection.dataset.institutionpk !== null && buildingSelection.dataset.institutionpk !== "")
            buildingPK = parseInt(buildingSelection.dataset.institutionpk);
        let rtis = document.getElementsByClassName("specialEducationIdentificationMethodMultiselect");
        for (let rti of rtis) {
            let rtiElement = rti;
            if (rtiElement.checked) {
                let add = {
                    LookupCodePK: parseInt(rtiElement.dataset.lookupcodepk),
                    LookupCodeLabel: rtiElement.dataset.lookupcodelabel
                };
                rtiList.push(add);
            }
        }
        let data = {
            RowNumber: newRow,
            PlanFK: parseInt(planFK),
            BuildingName: buildingName,
            AUN: aun,
            Branch: branch,
            BuildingPK: buildingPK,
            PDEApproved: pdeApprovedElement.checked,
            RTI: rtiList
        };
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIdentificationSchool', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`#specialEducationIdentificationAddedBuildings tbody`);
                var tr = document.createElement("tr");
                tr.innerHTML = xhr.responseText;
                tr.classList.add("specialEducationIndentificationMethodRow");
                tr.dataset.row = newRow.toString();
                element.appendChild(tr);
                buildingNameElement.selectedIndex = 0;
                aunElement.value = "";
                branchElement.value = "";
                pdeApprovedElement.checked = false;
                let multis = document.getElementsByClassName("multiSelectIdentificationRTI");
                for (let multi of multis) {
                    let multiElement = multi;
                    let checkboxes = multiElement.querySelectorAll("input");
                    for (let check of checkboxes) {
                        let checkElement = check;
                        if (checkElement.checked)
                            checkElement.click();
                    }
                }
                let addedBuildingsMissing = document.getElementById("addedBuildingsMissing");
                addedBuildingsMissing.classList.add("hide");
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(data));
    }
    changeBuilding(e) {
        let buildingSelectElement = e.target;
        let selection = buildingSelectElement[buildingSelectElement.selectedIndex];
        let aun = selection.dataset.aun;
        let branch = selection.dataset.branch;
        let intitutionpk = selection.dataset.institutionpk;
        let building = selection.value;
        let specialEducationIdentificationspecialEducationIdentificationAUNElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationAUN");
        if (specialEducationIdentificationspecialEducationIdentificationAUNElement !== null)
            specialEducationIdentificationspecialEducationIdentificationAUNElement.value = aun;
        let specialEducationIdentificationspecialEducationIdentificationBranchNumberElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationBranchNumber");
        if (specialEducationIdentificationspecialEducationIdentificationBranchNumberElement !== null)
            specialEducationIdentificationspecialEducationIdentificationBranchNumberElement.value = branch;
    }
    initializeRequiredFieldsCustom(allClasses, refresh = false) {
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        if (refresh) {
            let allElements = document.querySelectorAll(classesToValidate);
            for (let element of allElements) {
                let htmlElement = element;
                htmlElement.removeAttribute("aria-required");
                let label = Core.findLabelForInput(htmlElement);
                if (label !== null) {
                    label.classList.remove("isRequired");
                    let asterisk = label.querySelector(".required-label");
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }
        let isValid = true;
        let selectElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationIdentify");
        let selectedOption = selectElement[selectElement.selectedIndex];
        let selectedText = selectedOption.text;
        let buildings = document.getElementsByClassName("specialEducationIndentificationMethodRowCellPK");
        if (buildings.length === 0 && selectedText.toLowerCase().indexOf("discrepancy") < 0) {
            isValid = false;
            totalErrors++;
            let buildingNameElement = document.getElementById("specialEducationIdentificationBuildingName");
            let rtiElement = document.getElementById("multi-select-plugin");
            if (!(buildingNameElement.hasAttribute("aria-required") && buildingNameElement.getAttribute("aria-required") === "true")) {
                buildingNameElement.setAttribute("aria-required", "true");
                buildingNameElement.dataset.forcerequired = "true";
                buildingNameElement.classList.add("missing-field");
                buildingNameElement.setAttribute("aria-invalid", "true");
                let label = Core.findLabelForInput(buildingNameElement);
                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }
            if (!(rtiElement.hasAttribute("aria-required") && rtiElement.getAttribute("aria-required") === "true")) {
                rtiElement.setAttribute("aria-required", "true");
                let label = Core.findLabelForInput(rtiElement);
                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }
            let errorMessage = document.getElementById("addedBuildingsMissing");
            if (errorMessage !== null)
                errorMessage.classList.remove("hide");
        }
        if (!isValid) {
            let message = document.getElementById("validationMessage");
            if (totalErrors === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            }
            else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");
            if (goToError !== null) {
                let firstFocusableEl = document.querySelector(".missing-field");
                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        if (firstFocusableEl.classList.contains("mce")) {
                            tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                        }
                        else {
                            firstFocusableEl.focus();
                        }
                    });
                }
                else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
        }
    }
    doCustomValidation() {
        let isValid = true;
        let selectElements = document.getElementsByClassName("specialEducationIdentificationMethodSelectField");
        let selectElement = document.createElement("select");
        for (let selectBox of selectElements) {
            selectElement = selectBox;
        }
        if (selectElement.dataset.planpropertypk === "")
            isValid = false;
        let selectedOption = selectElement[selectElement.selectedIndex];
        let selectedText = selectedOption.text;
        let buildings = document.getElementsByClassName("specialEducationIndentificationMethodRowCellPK");
        if (buildings.length === 0 && selectedText.toLowerCase().indexOf("discrepancy") < 0)
            isValid = false;
        else {
            for (let building of buildings) {
                let buildingElement = building;
                if (buildingElement.dataset.planpropertypk === "")
                    isValid = false;
            }
        }
        //this._core.clientSideValidation(["specialEducationIdentificationMethodSchoolTable"]);
        let showMessage = !isValid;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    bindCheckboxes() {
        let allCheckboxes = document.getElementsByClassName("specialEducationIdentificationMethodCheckboxField");
        for (let check of allCheckboxes) {
            check.addEventListener("change", (e) => this.disabledCheckbox(e));
        }
        let allMultiCheckboxes = document.getElementsByClassName("specialEducationIdentificationMethodMultiselect");
        for (let check of allMultiCheckboxes) {
            check.addEventListener("change", (e) => this.disabledCheckbox(e));
        }
    }
    disabledCheckbox(e) {
        let checkbox = e.target;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
    }
    bindDeleteButtons() {
        let buttons = document.getElementsByClassName("removeIdentificationMethodButton");
        for (let button of buttons)
            button.addEventListener("click", (e) => this.deleteIdentificationMethod(e));
    }
    deleteIdentificationMethod(e) {
        let button = e.target;
        let row = button.dataset.row;
        let newRow = button.dataset.newrow;
        let modal = new Modal("deleteIdentificationMethodRowModal", null);
        modal.addAttributeToElement("deleteIdentificationMethodRowModal", "#specialEducationDeleteIdentificationMethodDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteIdentificationMethodRowModal", "#specialEducationDeleteIdentificationMethodDeleteConfirm", "newrow", newRow);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("deleteIdentificationMethodRowModal", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        Core.showLoader();
        if (buttonElement.dataset.newrow === "true") {
            let trElement = document.querySelector(`.specialEducationIndentificationMethodRow[data-row='${row}']`);
            trElement.remove();
            let modal = new Modal("deleteIdentificationMethodRowModal", null);
            modal.hide();
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The identification method row has been removed.", 'success', 3000, null);
        }
        else {
            let rowToDeleteTr = document.querySelector(`.specialEducationIndentificationMethodRow[data-row='${row}']`);
            let rowElements = rowToDeleteTr.querySelectorAll(`td[data-row='${row}']`);
            for (let ele of rowElements) {
                let tdElement = ele;
                if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellRTI")) {
                    let spans = tdElement.querySelectorAll("span.specialEducationIdentificationMethodRTI");
                    for (let span of spans) {
                        let spanEle = span;
                        let planProp = parseInt(spanEle.dataset.planpropertypk);
                        if (planProp > 0) {
                            planProps.push(planProp);
                        }
                    }
                }
                else if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellName")) {
                    let input = tdElement.querySelector("input.specialEducationIndentificationMethodRowCellPK");
                    let planProp = parseInt(input.dataset.planpropertypk);
                    if (planProp > 0) {
                        planProps.push(planProp);
                    }
                    let planPropName = parseInt(tdElement.dataset.planpropertypk);
                    if (planPropName > 0) {
                        planProps.push(planPropName);
                    }
                }
                else {
                    let planProp = parseInt(tdElement.dataset.planpropertypk);
                    if (planProp > 0) {
                        planProps.push(planProp);
                    }
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteIdentificationMethodRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    rowToDeleteTr.remove();
                    Core.hideLoader();
                    let modal = new Modal("deleteIdentificationMethodRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The identification method row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    changeIdentification(selectElement) {
        let userSelectionModelTitleElement = document.getElementById("userSelectionModelTitle");
        let selectedOption = selectElement[selectElement.options.selectedIndex];
        let addBuildingButton = document.getElementById("specialEducationIdentificationAddSchool");
        let specialEducationIdentificationBuildingNameElement = document.getElementById("specialEducationIdentificationBuildingName");
        let specialEducationIdentificationspecialEducationIdentificationAUNElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationAUN");
        let specialEducationIdentificationspecialEducationIdentificationBranchNumberElement = document.getElementById("specialEducationIdentificationspecialEducationIdentificationBranchNumber");
        let multiSelectIdentificationRTIElement = document.querySelector(".multiSelectIdentificationRTI");
        if (selectedOption.text.indexOf("Discrepancy") >= 0) {
            userSelectionModelTitleElement.textContent = "Discrepancy Model";
            addBuildingButton.disabled = true;
            this._core.forceElementOptional(multiSelectIdentificationRTIElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementOptional(specialEducationIdentificationBuildingNameElement);
        }
        else if (selectedOption.text.indexOf("RTI") >= 0) {
            userSelectionModelTitleElement.textContent = "RTI";
            addBuildingButton.disabled = false;
            this._core.forceElementRequired(multiSelectIdentificationRTIElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementRequired(specialEducationIdentificationBuildingNameElement);
        }
        else if (selectedOption.text.indexOf("Both") >= 0) {
            userSelectionModelTitleElement.textContent = "Both Models";
            addBuildingButton.disabled = false;
            this._core.forceElementRequired(multiSelectIdentificationRTIElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementRequired(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementRequired(specialEducationIdentificationBuildingNameElement);
        }
        else { //They choose "Select One"
            userSelectionModelTitleElement.textContent = "";
            addBuildingButton.disabled = true;
            this._core.forceElementOptional(multiSelectIdentificationRTIElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationBranchNumberElement);
            this._core.forceElementOptional(specialEducationIdentificationspecialEducationIdentificationAUNElement);
            this._core.forceElementOptional(specialEducationIdentificationBuildingNameElement);
        }
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIdentificationMethodForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIdentificationMethodExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationSupportServices.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}
// Special Education Data Review - Placement
class SpecialEducationDataReviewPlacement {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationDataReviewSelectField", "specialEducationDataReviewField"];
        let specialEducationDataReviewFormSaveButton = document.getElementById("specialEducationDataReviewSave");
        if (specialEducationDataReviewFormSaveButton !== null)
            specialEducationDataReviewFormSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteDataReviewRowConfirmButton = document.getElementById("deleteDataReviewRowConfirm");
        if (deleteDataReviewRowConfirmButton !== null)
            deleteDataReviewRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteDataReviewRowCancelButton = document.getElementById("deleteDataReviewRowCancel");
        if (deleteDataReviewRowCancelButton !== null)
            deleteDataReviewRowCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        this.bindDeleteRowButtons();
        let specialEducationDataReviewAddTrendButton = document.getElementById("specialEducationDataReviewAddTrend");
        if (specialEducationDataReviewAddTrendButton !== null)
            specialEducationDataReviewAddTrendButton.addEventListener("click", (e) => this.addDataReviewPlacementTrend(e));
        let specialEducationDataReviewSignificantDisproportionalityDropdowns = document.getElementsByClassName("specialEducationDataReviewSignificantDisproportionalityDropdown");
        for (let specialEducationDataReviewSignificantDisproportionalityDropdown of specialEducationDataReviewSignificantDisproportionalityDropdowns)
            specialEducationDataReviewSignificantDisproportionalityDropdown.addEventListener("change", (e) => this.setSignificantDisproportionalityRequirements(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationDataReviewFormElement = document.getElementById("specialEducationDataReviewPlacementForm");
        let inputs = document.getElementsByClassName("specialEducationDataReviewField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("specialEducationDataReviewSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationDataReviewPlacement', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addDataReviewPlacementTrend(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCodeTrends = "specialEducationDataReviewPlacementTrends";
        let propertyCodeImprovement = "specialEducationDataReviewPlacementImprovement";
        let pageCode = "specialeducationdatareviewplacement";
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationDataReviewField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationDataReviewCommonTrend', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`#identifyTrends`);
                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("data-review-identify-trends");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCodeTrends=${propertyCodeTrends}&propertyCodeImprovement=${propertyCodeImprovement}&pageCode=${pageCode}`);
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationDataReviewField");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "newrow", newRow);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        if (newRow.toLowerCase() === "true") {
            let thisRow = document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            thisRow.remove();
            let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
            modal.hide();
        }
        else {
            let planProps = [];
            let core = this._core;
            let thisRow = document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            let textElements = thisRow.querySelectorAll(`textarea.specialEducationDataReviewField[data-row='${row}']`);
            for (let text of textElements) {
                let textElement = text;
                let planProp = textElement.dataset.planpropertypk;
                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDataReviewRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    thisRow.remove();
                    let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    setSignificantDisproportionalityRequirements(e) {
        let dropdownElement = e.target;
        let elementContent = dropdownElement[dropdownElement.selectedIndex].textContent;
        if (elementContent.indexOf("Not Flagged") >= 0) {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");
            for (let textElement of allElements) {
                let element = textElement;
                if (element.classList.contains("trending"))
                    this._core.forceElementRequired(element);
                else
                    this._core.forceElementOptional(element);
            }
            let topLabels = document.querySelectorAll(".tabcontent .fake-label");
            for (let labelElement of topLabels) {
                if (!labelElement.classList.contains("trending")) {
                    let span = labelElement.querySelector("span");
                    span.remove();
                }
                else if (labelElement.innerHTML.indexOf("required-label") < 0)
                    labelElement.innerHTML = labelElement.innerHTML + " <span class='required-label'>*</span>";
            }
        }
        else {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");
            for (let textElement of allElements) {
                let element = textElement;
                this._core.forceElementRequired(element);
                let topLabels = document.querySelectorAll(".tabcontent .fake-label");
                for (let labelElement of topLabels) {
                    let label = labelElement;
                    if (!label.querySelector("span")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    }
                }
            }
        }
    }
}
// Special Education Data Review - Least Restrictive Environment
class SpecialEducationDataReviewDiscipline {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationDataReviewSelectField", "specialEducationDataReviewField"];
        let specialEducationDataReviewFormSaveButton = document.getElementById("specialEducationDataReviewSave");
        if (specialEducationDataReviewFormSaveButton !== null)
            specialEducationDataReviewFormSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let specialEducationDataReviewAddTrendButton = document.getElementById("specialEducationDataReviewAddTrend");
        if (specialEducationDataReviewAddTrendButton !== null)
            specialEducationDataReviewAddTrendButton.addEventListener("click", (e) => this.addDataReviewDisciplineTrend(e));
        let deleteDataReviewRowConfirmButton = document.getElementById("deleteDataReviewRowConfirm");
        if (deleteDataReviewRowConfirmButton !== null)
            deleteDataReviewRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteDataReviewRowCancelButton = document.getElementById("deleteDataReviewRowCancel");
        if (deleteDataReviewRowCancelButton !== null)
            deleteDataReviewRowCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        this.bindDeleteRowButtons();
        let specialEducationDataReviewSignificantDisproportionalityDropdowns = document.getElementsByClassName("specialEducationDataReviewSignificantDisproportionalityDropdown");
        for (let specialEducationDataReviewSignificantDisproportionalityDropdown of specialEducationDataReviewSignificantDisproportionalityDropdowns)
            specialEducationDataReviewSignificantDisproportionalityDropdown.addEventListener("change", (e) => this.setSignificantDisproportionalityRequirements(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationDataReviewFormElement = document.getElementById("specialEducationDisciplineForm");
        let inputs = document.getElementsByClassName("specialEducationDataReviewField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("specialEducationDataReviewSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationDataReviewDiscipline', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addDataReviewDisciplineTrend(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCodeTrends = "specialEducationDataReviewDisciplineIdentifyTrends";
        let propertyCodeImprovement = "specialEducationDataReviewDisciplineImprovement";
        let pageCode = "specialeducationdatareviewdisciplin";
        let that = this;
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationDataReviewField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationDataReviewCommonTrend', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`#identifyTrends`);
                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("data-review-identify-trends");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCodeTrends=${propertyCodeTrends}&propertyCodeImprovement=${propertyCodeImprovement}&pageCode=${pageCode}`);
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationDataReviewField");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "newrow", newRow);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        if (newRow.toLowerCase() === "true") {
            let thisRow = document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            thisRow.remove();
            let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
            modal.hide();
        }
        else {
            let planProps = [];
            let core = this._core;
            let thisRow = document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            let textElements = thisRow.querySelectorAll(`textarea.specialEducationDataReviewField[data-row='${row}']`);
            for (let text of textElements) {
                let textElement = text;
                let planProp = textElement.dataset.planpropertypk;
                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDataReviewRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    thisRow.remove();
                    let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    setSignificantDisproportionalityRequirements(e) {
        let dropdownElement = e.target;
        let elementContent = dropdownElement[dropdownElement.selectedIndex].textContent;
        if (elementContent.indexOf("Not Flagged") >= 0) {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");
            for (let textElement of allElements) {
                let element = textElement;
                if (element.classList.contains("trending"))
                    this._core.forceElementRequired(element);
                else
                    this._core.forceElementOptional(element);
            }
            let topLabels = document.querySelectorAll(".tabcontent .fake-label");
            for (let labelElement of topLabels) {
                if (!labelElement.classList.contains("trending")) {
                    let span = labelElement.querySelector("span");
                    span.remove();
                }
                else if (labelElement.innerHTML.indexOf("required-label") < 0)
                    labelElement.innerHTML = labelElement.innerHTML + " <span class='required-label'>*</span>";
            }
        }
        else {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");
            for (let textElement of allElements) {
                let element = textElement;
                this._core.forceElementRequired(element);
                let topLabels = document.querySelectorAll(".tabcontent .fake-label");
                for (let labelElement of topLabels) {
                    let label = labelElement;
                    if (!label.querySelector("span")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    }
                }
            }
        }
    }
}
// Special Education Data Review - Identification
class SpecialEducationDataReviewIdentification {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationDataReviewSelectField", "specialEducationDataReviewField"];
        let specialEducationDataReviewFormSaveButton = document.getElementById("specialEducationDataReviewSave");
        if (specialEducationDataReviewFormSaveButton !== null)
            specialEducationDataReviewFormSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let specialEducationDataReviewAddTrendButton = document.getElementById("specialEducationDataReviewAddTrend");
        if (specialEducationDataReviewAddTrendButton !== null)
            specialEducationDataReviewAddTrendButton.addEventListener("click", (e) => this.addDataReviewIdentificationTrend(e));
        let deleteDataReviewRowConfirmButton = document.getElementById("deleteDataReviewRowConfirm");
        if (deleteDataReviewRowConfirmButton !== null)
            deleteDataReviewRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteDataReviewRowCancelButton = document.getElementById("deleteDataReviewRowCancel");
        if (deleteDataReviewRowCancelButton !== null)
            deleteDataReviewRowCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        this.bindDeleteRowButtons();
        let specialEducationDataReviewSignificantDisproportionalityDropdowns = document.getElementsByClassName("specialEducationDataReviewSignificantDisproportionalityDropdown");
        for (let specialEducationDataReviewSignificantDisproportionalityDropdown of specialEducationDataReviewSignificantDisproportionalityDropdowns)
            specialEducationDataReviewSignificantDisproportionalityDropdown.addEventListener("change", (e) => this.setSignificantDisproportionalityRequirements(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationDataReviewFormElement = document.getElementById("specialEducationDataReviewIdentificationForm");
        let inputs = document.getElementsByClassName("specialEducationDataReviewField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("specialEducationDataReviewSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationDataReviewFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationDataReviewIdentification', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addDataReviewIdentificationTrend(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let propertyCodeTrends = "specialEducationDataReviewIdentificationIdentifyTrends";
        let propertyCodeImprovement = "specialEducationDataReviewIdentificationImprovement";
        let pageCode = "specialeducationdatareviewident";
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationDataReviewField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationDataReviewCommonTrend', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`#identifyTrends`);
                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("data-review-identify-trends");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCodeTrends=${propertyCodeTrends}&propertyCodeImprovement=${propertyCodeImprovement}&pageCode=${pageCode}`);
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationDataReviewField");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "row", row);
        modal.addAttributeToElement("specialEducationDataReviewDeleteRowModal", "#deleteDataReviewRowConfirm", "newrow", newRow);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let newRow = buttonElement.dataset.newrow;
        if (newRow.toLowerCase() === "true") {
            let thisRow = document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            thisRow.remove();
            let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
            modal.hide();
        }
        else {
            let planProps = [];
            let core = this._core;
            let thisRow = document.querySelector(`.data-review-identify-trends[data-row='${row}']`);
            let textElements = thisRow.querySelectorAll(`textarea.specialEducationDataReviewField[data-row='${row}']`);
            for (let text of textElements) {
                let textElement = text;
                let planProp = textElement.dataset.planpropertypk;
                if (planProp && parseInt(planProp) > 0) {
                    planProps.push(planProp);
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteDataReviewRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    thisRow.remove();
                    let modal = new Modal("specialEducationDataReviewDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    setSignificantDisproportionalityRequirements(e) {
        let dropdownElement = e.target;
        let elementContent = dropdownElement[dropdownElement.selectedIndex].textContent;
        if (elementContent.indexOf("Not Flagged") >= 0) {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");
            for (let textElement of allElements) {
                let element = textElement;
                if (element.classList.contains("trending"))
                    this._core.forceElementRequired(element);
                else
                    this._core.forceElementOptional(element);
            }
            let topLabels = document.querySelectorAll(".tabcontent .fake-label");
            for (let labelElement of topLabels) {
                if (!labelElement.classList.contains("trending")) {
                    let span = labelElement.querySelector("span");
                    span.remove();
                }
                else if (labelElement.innerHTML.indexOf("required-label") < 0)
                    labelElement.innerHTML = labelElement.innerHTML + " <span class='required-label'>*</span>";
            }
        }
        else {
            let allElements = document.querySelectorAll(".data-review-identify-trends .specialEducationDataReviewField");
            for (let textElement of allElements) {
                let element = textElement;
                this._core.forceElementRequired(element);
                let topLabels = document.querySelectorAll(".tabcontent .fake-label");
                for (let labelElement of topLabels) {
                    let label = labelElement;
                    if (!label.querySelector("span")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    }
                }
            }
        }
    }
}
// Special Education Non-Resident Students Oversight
class SpecialEducationNonResidentStudentsOversight {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationNonResidentStudentsOversightField", "specialEducationNonResidentStudentsOversightSelectField", "specialEducationNonResidentStudentsOversightRadioField"];
        let specialEducationNonResidentStudentsOversightFormSaveButton = document.getElementById("specialEducationNonResidentStudentsOversightSave");
        if (specialEducationNonResidentStudentsOversightFormSaveButton !== null)
            specialEducationNonResidentStudentsOversightFormSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let radioButtonHostDistrict = document.getElementsByName("hostDistrict");
        for (let radioButtonHostDistrictElement of radioButtonHostDistrict)
            radioButtonHostDistrictElement.addEventListener("change", (e) => this.hostDistrict());
        this.hostDistrict();
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let specialEducationNonResidentStudentsOversightAddAFacilityButton = document.getElementById("specialEducationNonResidentStudentsOversightAddAFacility");
        if (specialEducationNonResidentStudentsOversightAddAFacilityButton !== null)
            specialEducationNonResidentStudentsOversightAddAFacilityButton.addEventListener("click", (e) => this.addFacility(e));
        this.bindDeleteRowButtons();
        this.bindOtherDropdowns();
        let deleteFacilityRowConfirmButton = document.getElementById("deleteOversightFacilityConfirm");
        if (deleteFacilityRowConfirmButton !== null)
            deleteFacilityRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteFacilityRowCancelButton = document.getElementById("deleteOversightFacilityCancel");
        if (deleteFacilityRowCancelButton !== null)
            deleteFacilityRowCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        let changeCheckbox = document.getElementById("changeCheckbox");
        if (changeCheckbox !== null)
            changeCheckbox.addEventListener("click", (e) => this.change(changeCheckbox));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationNonResidentStudentsOversightFormElement = document.getElementById("specialEducationNonResidentStudentsOversightForm");
        let inputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightField");
        for (let ele of inputs) {
            if (!ele.classList.contains("specialEducationNonResidentStudentsOversightFakeField")) { //This is a special case. We don't want this in the save.
                let planPropertyPK = 0;
                planPK = parseInt(specialEducationNonResidentStudentsOversightFormElement.dataset.planfk);
                let element = ele;
                let rowNumber = parseInt(element.dataset.row);
                let propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let checkInputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationNonResidentStudentsOversightFormElement.dataset.planfk);
            let element = ele;
            let row = element.dataset.row;
            let rowNumber = 0;
            if (row)
                rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationNonResidentStudentsOversightFormElement.dataset.planfk);
            let element = ele;
            let row = element.dataset.row;
            let rowNumber = 0;
            if (row)
                rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("specialEducationNonResidentStudentsOversightRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const hiddenElement = document.getElementById("isChange");
        if (hiddenElement != null && hiddenElement.value === "true") {
            const hiddenRow = document.getElementById("changeRowValue");
            const rowNumber = hiddenRow.value;
            let saveItem = {
                PlanPropertyPK: 0,
                PlanFK: planPK,
                PropertyFK: 1266,
                TextValue: "",
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationNonResidentStudentsOversight', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    hostDistrict() {
        let elements = document.getElementsByName("hostDistrict");
        let allFacilityElements = document.getElementsByClassName("specialEducationNonResidentStudentsOversightFacility");
        let yesChecked = false;
        for (let ele of elements) {
            let element = ele;
            if (element.checked && element.dataset.lookuplabel === "Yes")
                yesChecked = true;
        }
        let facilityTableElement = document.getElementById("facilityTable");
        let specialEducationNonResidentStudentsOversightProceduresElement = document.getElementById("specialEducationNonResidentStudentsOversightProcedures");
        let specialEducationNonResidentStudentsOversightEducationalElement = document.getElementById("specialEducationNonResidentStudentsOversightEducational");
        if (yesChecked) {
            for (let ele of allFacilityElements) {
                let element = ele;
                this._core.forceElementRequired(element);
            }
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightProceduresElement);
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightEducationalElement);
            facilityTableElement.classList.remove("hide");
        }
        else {
            for (let ele of allFacilityElements) {
                let element = ele;
                this._core.forceElementOptional(element);
            }
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightProceduresElement);
            this._core.forceElementRequired(specialEducationNonResidentStudentsOversightEducationalElement);
            facilityTableElement.classList.add("hide");
            let facilityErrorElement = document.getElementById("incompleteFacilityTableError");
            if (facilityErrorElement)
                facilityErrorElement.remove();
        }
        let facilitySelects = document.querySelectorAll(`.specialEducationNonResidentStudentsOversightFacilityWithOther`);
        for (let facilitySelect of facilitySelects) {
            let ele = facilitySelect;
            let selectedOption = ele[ele.selectedIndex];
            let row = ele.dataset.row;
            let otherElement = document.querySelector(`.specialEducationNonResidentStudentsOversightFacilityActualOther[data-row='${row}']`);
            if (selectedOption.text.indexOf("Other") > 0) {
                this._core.forceElementRequired(otherElement);
            }
            else {
                this._core.forceElementOptional(otherElement);
            }
        }
    }
    changeFacilityType(e) {
        let row = e.target.dataset.row;
        let selectElement = document.querySelector(`.specialEducationNonResidentStudentsOversightFacilityWithOther[data-row='${row}']`);
        let selectedOption = selectElement[selectElement.selectedIndex];
        let hiddenOther = document.querySelector(`.facilityTypeOther[data-row='${row}']`);
        let hiddenOtherText = document.querySelector(`.specialEducationNonResidentStudentsOversightFacilityActualOther[data-row='${row}']`);
        if (selectedOption.text === "Other") {
            if (hiddenOther !== null)
                hiddenOther.classList.remove("hide");
            if (hiddenOtherText !== null)
                this._core.forceElementRequired(hiddenOtherText);
        }
        else {
            if (hiddenOther !== null)
                hiddenOther.classList.add("hide");
            if (hiddenOtherText !== null)
                this._core.forceElementOptional(hiddenOtherText);
        }
    }
    addFacility(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationNonResidentStudentsOversightFacility");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationNonResidentStudentsOversightFacility', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`#facilities`);
                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("facility");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                that.bindDeleteRowButtons();
                that.bindOtherDropdowns();
                let facilitiesMissingElement = document.getElementById("facilitiesMissing");
                facilitiesMissingElement.classList.remove("hide");
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}`);
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationNonResidentStudentsOversightFacility");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.addAttributeToElement("specialEducationFacilityDeleteRowModel", "#deleteOversightFacilityConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let thisRow = document.querySelector(`.facility[data-row='${row}']`);
        let textElements = thisRow.querySelectorAll(`.specialEducationNonResidentStudentsOversightFacility[data-row='${row}']`);
        for (let ele of textElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteNonResidentOversightFacilityRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                thisRow.remove();
                let modal = new Modal("specialEducationFacilityDeleteRowModel", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindOtherDropdowns() {
        let specialEducationNonResidentStudentsOversightFacilityTypeSelects = document.getElementsByClassName("specialEducationNonResidentStudentsOversightFacilityWithOther");
        for (let specialEducationNonResidentStudentsOversightFacilityTypeSelect of specialEducationNonResidentStudentsOversightFacilityTypeSelects)
            specialEducationNonResidentStudentsOversightFacilityTypeSelect.addEventListener("change", (e) => this.changeFacilityType(e));
    }
    initializeRequiredFieldsCustom(validationClasses) {
        let yesChecked = false;
        let hostDistrict = document.getElementsByClassName("specialEducationNonResidentStudentsOversightRadioField");
        for (let host of hostDistrict) {
            let ele = host;
            if (ele.checked) {
                if (ele.dataset.lookuplabel === "yes")
                    yesChecked = true;
            }
        }
        if (yesChecked) {
            let facilities = document.querySelectorAll(`.special-education-facilities .facility`);
            let facilitiesMissingElement = document.getElementById("facilitiesMissing");
            if (facilities.length === 0) {
                facilitiesMissingElement.classList.remove("hide");
                var fakeElementDiv = document.createElement("div");
                fakeElementDiv.classList.add("medium-6");
                fakeElementDiv.classList.add("column");
                fakeElementDiv.classList.add("end");
                fakeElementDiv.id = "incompleteFacilityTableError";
                var fakeElement = document.createElement("input");
                fakeElement.type = "text";
                fakeElement.style.opacity = "0";
                fakeElement.style.filter = "alpha(opacity=0)";
                fakeElement.style.width = "5";
                fakeElement.style.height = "0";
                fakeElement.dataset.percent = "1.0";
                fakeElement.classList.add("specialEducationNonResidentStudentsOversightField");
                fakeElement.classList.add("specialEducationNonResidentStudentsOversightFakeField");
                this._core.forceElementRequired(fakeElement);
                fakeElementDiv.appendChild(fakeElement);
                facilitiesMissingElement.appendChild(fakeElementDiv);
            }
            else {
                facilitiesMissingElement.classList.remove("show");
                let divElement = document.getElementById("incompleteFacilityTableError");
                if (divElement)
                    divElement.remove();
            }
        }
        this._core.initializeRequiredFields(validationClasses);
    }
    change(checkElement) {
        //set the value to true so when we do a save it counts change checkbox
        const hiddenElement = document.getElementById("isChange");
        if (hiddenElement !== null) {
            hiddenElement.value = "true";
        }
        const allTextAreaElements = document.getElementsByClassName("changeTextArea");
        for (const element of allTextAreaElements) {
            const ele = element;
            if (checkElement.checked) {
                ele.readOnly = false;
                ele.disabled = false;
            }
            else {
                ele.readOnly = true;
                ele.disabled = true;
            }
        }
        const allInputElements = document.getElementsByClassName("changeInput");
        for (const element of allInputElements) {
            const ele = element;
            if (checkElement.checked) {
                ele.readOnly = false;
                ele.disabled = false;
                ele.classList.remove("disabled");
            }
            else {
                ele.readOnly = true;
                ele.disabled = true;
                ele.classList.add("disabled");
            }
        }
        const allSelectElements = document.getElementsByClassName("changeSelect");
        for (const element of allSelectElements) {
            const ele = element;
            if (checkElement.checked) {
                ele.disabled = false;
            }
            else {
                ele.disabled = true;
            }
        }
    }
}
// Special Education Incarcerated Students Oversight
class SpecialEducationIncarceratedStudentsOversight {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIncarceratedStudentsOversightField", "specialEducationIncarceratedStudentsOversightSelectField", "specialEducationIncarceratedStudentsOversightRadioField"];
        let specialEducationIncarceratedStudentsOversightSaveButton = document.getElementById("specialEducationIncarceratedStudentsOversightSave");
        if (specialEducationIncarceratedStudentsOversightSaveButton !== null)
            specialEducationIncarceratedStudentsOversightSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let radioButtonHostDistrict = document.getElementsByName("hostDistrict");
        for (let radioButtonHostDistrictElement of radioButtonHostDistrict)
            radioButtonHostDistrictElement.addEventListener("change", (e) => this.adultCorrectional());
        this.adultCorrectional();
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let specialEducationIncareratedStudentsOversightAddAFacilityButton = document.getElementById("specialEducationIncarceratedStudentsOversightAddAFacility");
        if (specialEducationIncareratedStudentsOversightAddAFacilityButton !== null)
            specialEducationIncareratedStudentsOversightAddAFacilityButton.addEventListener("click", (e) => this.addFacility(e));
        this.bindDeleteRowButtons();
        let deleteFacilityRowConfirmButton = document.getElementById("deleteOversightFacilityConfirm");
        if (deleteFacilityRowConfirmButton !== null)
            deleteFacilityRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteFacilityRowCancelButton = document.getElementById("deleteOversightFacilityCancel");
        if (deleteFacilityRowCancelButton !== null)
            deleteFacilityRowCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationIncarceratedStudentsOversightFormElement = document.getElementById("specialEducationIncarceratedStudentsOversightForm");
        let inputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIncarceratedStudentsOversightFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIncarceratedStudentsOversightFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIncarceratedStudentsOversightFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                if (parseInt(element.value) > 0) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let radioInputs = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIncarceratedStudentsOversight', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    adultCorrectional() {
        let elements = document.getElementsByName("hostDistrict");
        let allFacilityElements = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightFacility");
        let yesChecked = false;
        for (let ele of elements) {
            let element = ele;
            if (element.checked && element.dataset.lookuplabel === "Yes")
                yesChecked = true;
        }
        let facilityTableElement = document.getElementById("facilityTable");
        if (yesChecked) {
            for (let ele of allFacilityElements) {
                let element = ele;
                this._core.forceElementRequired(element);
            }
            facilityTableElement.classList.remove("hide");
        }
        else {
            for (let ele of allFacilityElements) {
                let element = ele;
                this._core.forceElementOptional(element);
            }
            facilityTableElement.classList.add("hide");
        }
        this.changeRequirements();
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSpecialEducationIncarceratedStudentsOversightFacility");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let thisRow = document.querySelector(`.facility[data-row='${row}']`);
        let textElements = thisRow.querySelectorAll(`specialEducationIncarceratedStudentsOversightFacility[data-row='${row}']`);
        for (let ele of textElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteIncarceratedOversightFacilityRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                thisRow.remove();
                let modal = new Modal("specialEducationFacilityDeleteRowModel", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    addFacility(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationIncarceratedStudentsOversightFacility");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationIncarceratedStudentsOversightFacility', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`#facilities`);
                var div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("facility");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}`);
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("specialEducationFacilityDeleteRowModel", null);
        modal.addAttributeToElement("specialEducationFacilityDeleteRowModel", "#deleteOversightFacilityConfirm", "row", row);
        modal.show();
    }
    initializeRequiredFieldsCustom(validationClasses) {
        this.changeRequirements();
        this._core.initializeRequiredFields(this.validationClasses);
    }
    changeRequirements() {
        let textElement = document.getElementById("specialEducationIncarceratedStudentsDistrict");
        let selectedElement = document.querySelector("input.specialEducationIncarceratedStudentsOversightRadioField:checked");
        if (selectedElement && selectedElement.dataset.lookuplabel.toLowerCase() === "no") {
            this._core.forceElementOptional(textElement);
            textElement.readOnly = true;
            textElement.disabled = true;
        }
        else {
            this._core.forceElementRequired(textElement);
            textElement.readOnly = false;
            textElement.disabled = false;
        }
    }
}
// Special Education Least Restrictive Environment
class SpecialEducationLeastRestrictiveEnvironment {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationLeastRestrictiveEnvironmentField", "specialEducationLeastRestrictiveEnvironmentSelectField"];
        let specialEducationLeastRestrictiveEnvironmentSaveButton = document.getElementById("specialEducationLeastRestrictiveEnvironmentSave");
        if (specialEducationLeastRestrictiveEnvironmentSaveButton !== null)
            specialEducationLeastRestrictiveEnvironmentSaveButton.addEventListener("click", (e) => this.save("save"));
        let specialEducationLeastRestrictiveEnvironmentAddAPlacementButton = document.getElementById("specialEducationLeastRestrictiveEnvironmentAddAPlacement");
        if (specialEducationLeastRestrictiveEnvironmentAddAPlacementButton !== null)
            specialEducationLeastRestrictiveEnvironmentAddAPlacementButton.addEventListener("click", (e) => this.addAPlacement(e));
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteFileConfirmButton = document.getElementById("deleteLeastRestrictiveEnvironmentConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteLeastRestrictiveEnvironmentCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        let noOutOfDistrictPlacementsCheckbox = document.getElementById("specialEducationLeastRestrictivespecialEducationLeastRestrictiveNoOutOfDistrictPlacements");
        if (noOutOfDistrictPlacementsCheckbox !== null)
            noOutOfDistrictPlacementsCheckbox.addEventListener("change", (e) => this.changeOutOfDistrict());
        this.changeOutOfDistrict();
        this.bindDeleteButtons();
        this.bindDropdowns();
        this.showOther();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationLeastRestrictiveEnvironmentFormElement = document.getElementById("specialEducationLeastRestrictiveEnvironmentForm");
        let inputs = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationLeastRestrictiveEnvironmentFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationLeastRestrictiveEnvironmentFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let value = "off";
            if (element.checked)
                value = "on";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let selectInputs = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationLeastRestrictiveEnvironmentFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            rowNumber = parseInt(element.dataset.row);
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationLeastRestrictiveEnvironment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addAPlacement(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let newRow = 0;
        let improvementPlanningActivities = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentField");
        for (let improvement of improvementPlanningActivities) {
            let improvementElement = improvement;
            if (newRow < parseInt(improvementElement.dataset.row))
                newRow = parseInt(improvementElement.dataset.row);
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddSpecialEducationLeastRestrictiveEnvironmentPlacement', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.getElementById("placementList");
                let div = document.createElement("div");
                div.classList.add("row");
                div.classList.add("least-restrictive");
                div.dataset.row = newRow.toString();
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                that.bindDeleteButtons();
                that.bindDropdowns();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&rowNumber=" + newRow);
    }
    bindDeleteButtons() {
        let buttons = document.getElementsByClassName("specialEducationLeastRestrictiveRemove");
        for (let button of buttons)
            button.addEventListener("click", (e) => this.deleteIdentificationMethod(e));
    }
    deleteIdentificationMethod(e) {
        let button = e.target;
        let row = button.dataset.row;
        let newRow = button.dataset.newrow;
        let modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
        modal.addAttributeToElement("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", "#deleteLeastRestrictiveEnvironmentConfirm", "row", row);
        modal.addAttributeToElement("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", "#deleteLeastRestrictiveEnvironmentConfirm", "newrow", newRow);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        Core.showLoader();
        if (buttonElement.dataset.newrow === "true") {
            let divElement = document.querySelector(`.least-restrictive[data-row='${row}']`);
            divElement.remove();
            let modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
            modal.hide();
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
        }
        else {
            let rowToDelete = document.querySelector(`.least-restrictive[data-row='${row}']`);
            let rowElements = rowToDelete.querySelectorAll(`input.specialEducationLeastRestrictiveEnvironmentField[data-row='${row}']`);
            for (let ele of rowElements) {
                let tdElement = ele;
                if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellRTI")) {
                    let spans = tdElement.querySelectorAll("span.specialEducationIdentificationMethodRTI");
                    for (let span of spans) {
                        let spanEle = span;
                        let planProp = parseInt(spanEle.dataset.planpropertypk);
                        if (planProp > 0) {
                            planProps.push(planProp);
                        }
                    }
                }
                else if (tdElement.classList.contains("specialEducationIndentificationMethodRowCellName")) {
                    let input = tdElement.querySelector("input.specialEducationIndentificationMethodRowCellPK");
                    let planProp = parseInt(input.dataset.planpropertypk);
                    if (planProp > 0) {
                        planProps.push(planProp);
                    }
                    let planPropName = parseInt(tdElement.dataset.planpropertypk);
                    if (planPropName > 0) {
                        planProps.push(planPropName);
                    }
                }
                else {
                    let planProp = parseInt(tdElement.dataset.planpropertypk);
                    if (planProp > 0) {
                        planProps.push(planProp);
                    }
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteLeastRestrictiveEnvironmentRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    rowToDelete.remove();
                    Core.hideLoader();
                    let modal = new Modal("specialEducationLeastRestrictiveEnvironmentDeleteRowModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    changeFacilityType(e) {
        let selectElement = e.target;
        let selectedOption = selectElement[selectElement.selectedIndex];
        let row = selectElement.dataset.row;
        let hiddenOther = document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOther[data-row='${row}']`);
        let hiddenOtherText = document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOtherText[data-row='${row}']`);
        if (selectedOption.text === "Other") {
            if (hiddenOther !== null)
                hiddenOther.classList.remove("hide");
            if (hiddenOtherText !== null)
                this._core.forceElementRequired(hiddenOtherText);
        }
        else {
            if (hiddenOther !== null)
                hiddenOther.classList.add("hide");
            if (hiddenOtherText !== null)
                this._core.forceElementOptional(hiddenOtherText);
        }
    }
    bindDropdowns() {
        let specialEducationLeastRestrictiveEnvironmentFacilityTypes = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentFacilityType");
        for (let specialEducationLeastRestrictiveEnvironmentFacilityType of specialEducationLeastRestrictiveEnvironmentFacilityTypes)
            specialEducationLeastRestrictiveEnvironmentFacilityType.addEventListener("change", (e) => this.changeFacilityType(e));
    }
    changeOutOfDistrict() {
        let noOutOfDistrictPlacementsCheckbox = document.getElementById("specialEducationLeastRestrictivespecialEducationLeastRestrictiveNoOutOfDistrictPlacements");
        if (noOutOfDistrictPlacementsCheckbox !== null && noOutOfDistrictPlacementsCheckbox.checked) {
            let rows = document.querySelectorAll(".least-restrictive");
            for (let row of rows) {
                let div = row;
                let rowNumber = div.dataset.row;
                let allInputElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] input`);
                for (let thisElement of allInputElements) {
                    let input = thisElement;
                    input.readOnly = true;
                    this._core.forceElementOptional(input);
                }
                let allSelectElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] select`);
                for (let thisElement of allSelectElements) {
                    let input = thisElement;
                    input.disabled = true;
                    this._core.forceElementOptional(input);
                }
            }
        }
        else {
            let rows = document.querySelectorAll(".least-restrictive");
            let firstRow = true;
            for (let row of rows) {
                let div = row;
                let rowNumber = div.dataset.row;
                let allInputElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] input`);
                for (let thisElement of allInputElements) {
                    let input = thisElement;
                    input.readOnly = false;
                    if (firstRow)
                        if (!input.classList.contains("specialEducationLeastRestrictiveEnvironmentFacilityTypeOtherText"))
                            this._core.forceElementRequired(input);
                }
                let allSelectElements = document.querySelectorAll(`.least-restrictive[data-row='${rowNumber}'] select`);
                for (let thisElement of allSelectElements) {
                    let input = thisElement;
                    input.disabled = false;
                    if (firstRow)
                        this._core.forceElementRequired(input);
                }
                firstRow = false;
            }
        }
    }
    initializeRequiredFieldsCustom(validationClasses) {
        this.changeOutOfDistrict();
        const allFacilityNames = document.querySelectorAll(`.specialEducationLeastRestrictiveEnvironmentFacilityName`);
        for (const facility of allFacilityNames) {
            const ele = facility;
            if (ele.value !== "") {
                const row = ele.dataset.row;
                const allElements = document.querySelectorAll(`.specialEducationLeastRestrictiveEnvironmentFacilityData[data-type='facility'][data-row='${row}']`);
                for (const innerElement of allElements) {
                    const thisEle = innerElement;
                    this._core.forceElementRequired(thisEle);
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    showOther() {
        let selects = document.getElementsByClassName("specialEducationLeastRestrictiveEnvironmentFacilityType");
        for (var select of selects) {
            let element = select;
            let selectedOption = element[element.selectedIndex];
            let row = element.dataset.row;
            let hiddenOther = document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOther[data-row='${row}']`);
            let hiddenOtherText = document.querySelector(`.specialEducationLeastRestrictiveEnvironmentFacilityTypeOtherText[data-row='${row}']`);
            if (selectedOption.text === "Other") {
                if (hiddenOther !== null)
                    hiddenOther.classList.remove("hide");
                if (hiddenOtherText !== null)
                    this._core.forceElementRequired(hiddenOtherText);
            }
            else {
                if (hiddenOther !== null)
                    hiddenOther.classList.add("hide");
                if (hiddenOtherText !== null)
                    this._core.forceElementOptional(hiddenOtherText);
            }
        }
    }
}
// Special Education Behavior Support Services
class SpecialEducationBehaviorSupportServices {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationBehaviorSupportServicesField", "specialEducationBehaviorSupportServicesFileField", "specialEducationBehaviorSupportServicesRadioField"];
        let specialEducationBehaviorSupportServicesSaveButton = document.getElementById("specialEducationBehaviorSupportServicesSave");
        if (specialEducationBehaviorSupportServicesSaveButton !== null)
            specialEducationBehaviorSupportServicesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fileUploader = document.getElementById("uploadMinutes");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteButtons();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationBehaviorSupportServicesFormElement = document.getElementById("specialEducationBehaviorSupportServicesForm");
        let inputs = document.getElementsByClassName("specialEducationBehaviorSupportServicesField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationBehaviorSupportServicesFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("specialEducationBehaviorSupportServicesRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    if (element.checked) {
                        let saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: "",
                            LookupCodeFK: parseInt(element.value),
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false
                        };
                        allSaveElements.push(saveItem);
                    }
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationBehaviorSupportServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = document.getElementById("uploadMinutes");
        let formData = new FormData(uploadSampleForm);
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("uploadMinutes");
                        formFile.value = "";
                        formFile.dataset.hasUploaded = "true";
                        that.bindDeleteButtons();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");
        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// Special Education Intensive Interagency
class SpecialEducationIntensiveInteragency {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIntensiveInteragencyField"];
        let specialEducationIntensiveInteragencySaveButton = document.getElementById("specialEducationIntensiveInteragencySave");
        if (specialEducationIntensiveInteragencySaveButton !== null)
            specialEducationIntensiveInteragencySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationIntensiveInteragencyFormElement = document.getElementById("specialEducationIntensiveInteragencyForm");
        let inputs = document.getElementsByClassName("specialEducationIntensiveInteragencyField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationIntensiveInteragencyFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationIntensiveInteragency', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Special Education Education Program (Caseload FTE)
class SpecialEducationEducationProgramCaseloadFTE {
    constructor() {
        let that = this;
        this._core = new Core();
        let form = document.getElementById("specialEducationEducationProgramCaseloadFTEForm");
        if (form != null) {
            that.planFK = parseInt(form.dataset.planfk);
            that.templateFK = parseInt(form.dataset.templatefk);
        }
        let hdnAllowExceedsFTE = document.getElementById("hdnAllowExceedsFTE");
        if (hdnAllowExceedsFTE != null && hdnAllowExceedsFTE.value === "True") {
            that.allowExceedsFTE = true;
        }
        else {
            that.allowExceedsFTE = false;
        }
        new SpecialEducationProgramCaseloadFTELazyAccordion();
        that.deleteSupportConfirmModal = new Modal("deleteSupportConfirmModal", null);
        that.deleteFTEConfirmModal = new Modal("deleteFTEConfirmModal", null);
        this.validationClasses = [
            "fteID",
            "classroomLocationFTE",
            "buildingName",
            "supportTypeGroup",
            "supportType",
            "supportLevel",
            "caseLoad",
            "identifyClassroom",
            "classroomLocationSupport",
            "ageRangeFrom",
            "ageRangeEnd",
            "ageRangeJustification",
            "exitingViaTransferOfEntity"
        ];
        let specialEducationEducationProgramCaseloadFTEFormSaveButton = document.getElementById("specialEducationEducationProgramCaseloadFTESave");
        if (specialEducationEducationProgramCaseloadFTEFormSaveButton !== null) {
            specialEducationEducationProgramCaseloadFTEFormSaveButton.addEventListener("click", (e) => this.save("save"));
        }
        this._core.leftnav(this);
        this._core.tabLinkSave(this);
        this._core.initializeRequiredFields(this.validationClasses);
        that.initializeFTEHashes();
        that.handleClearDisabled();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        //Event Listener for creating new FTE
        let createNewFTE = document.getElementById("createNewFTE");
        if (createNewFTE != null) {
            createNewFTE.addEventListener("click", () => {
                Core.showLoader();
                that.createNewFTE()
                    .then((response) => {
                    Core.hideLoader();
                    that._core.initializeRequiredFields(that.validationClasses);
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        //Event listener for deleting a support
        let deleteSupportConfirmButton = document.getElementById("deleteSupportConfirm");
        if (deleteSupportConfirmButton != null) {
            deleteSupportConfirmButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
                if ("supportPk" in deleteSupportConfirmButton.dataset && "ftePk" in deleteSupportConfirmButton.dataset) {
                    Core.showLoader();
                    that.deleteSupport(parseInt(deleteSupportConfirmButton.dataset.supportPk))
                        .then((response) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                        that.handleFTESaveDisabled(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        that.checkSupportCount(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        that.refreshFTEPercentage(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this support. Please try again later.", 'error', 3000, null);
                }
            });
        }
        //Event listener for confirming deletion of FTE
        let deleteFTEConfirmButton = document.getElementById("deleteFTEConfirm");
        if (deleteFTEConfirmButton != null) {
            deleteFTEConfirmButton.addEventListener("click", () => {
                if ("ftePk" in deleteFTEConfirmButton.dataset) {
                    that.deleteFTE(parseInt(deleteFTEConfirmButton.dataset.ftePk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE. Please try again later.", 'error', 3000, null);
                }
            });
        }
        //Event listener for cancelling the delete of a support
        let deleteSupportCancelButton = document.getElementById("deleteSupportCancel");
        if (deleteSupportCancelButton != null) {
            deleteSupportCancelButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
            });
        }
        //Event listener for cancelling the delete of an FTE
        let deleteFTECancelButton = document.getElementById("deleteFTECancel");
        if (deleteFTECancelButton != null) {
            deleteFTECancelButton.addEventListener("click", () => {
                that.deleteFTEConfirmModal.hide();
            });
        }
        //Event listener for clicking the search button
        let searchButton = document.getElementById("searchButton");
        if (searchButton !== null) {
            //1. Save page (will only save FTE that have changed)
            //2. Run Search
            searchButton.addEventListener("click", () => {
                Core.showLoader();
                that.promiseSave()
                    .then(() => {
                    return that.search();
                })
                    .then(() => {
                    that.initializeFTEHashes();
                    that._core.initializeRequiredFields(that.validationClasses);
                    //reinitialize accordions
                    new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        //Event listener for searching when hitting enter
        let fteIDSearch = document.getElementById("searchFTEId");
        if (fteIDSearch !== null) {
            fteIDSearch.addEventListener("keypress", (e) => {
                if (e.keyCode === 13) {
                    Core.showLoader();
                    that.promiseSave()
                        .then(() => {
                        return that.search();
                    })
                        .then(() => {
                        that._core.initializeRequiredFields(that.validationClasses);
                        that.initializeFTEHashes();
                        //reinitialize accordions
                        new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                        Core.hideLoader();
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
            });
            fteIDSearch.addEventListener("input", () => {
                that.handleClearDisabled();
            });
        }
        //Clears the search fields
        let clearButton = document.getElementById("searchClear");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                that.clearSearch();
            });
        }
        //When changing building filter, determine whether clear button should be enabled
        let buildingSearch = document.getElementById("searchBuilding");
        let supportTypeSearch = document.getElementById("searchSupportType");
        if (buildingSearch != null) {
            buildingSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }
        //When changing support type filter, determine whether clear button should be enabled
        if (supportTypeSearch != null) {
            supportTypeSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }
        //Event listener for expanding all accordions
        let expandAll = document.getElementById("expandAll");
        if (expandAll != null) {
            expandAll.addEventListener("click", () => {
                that.expandAllFTE();
            });
        }
        //Event listener for collapsing all accordions
        let collapseAll = document.getElementById("collapseAll");
        if (expandAll != null) {
            collapseAll.addEventListener("click", () => {
                that.collapseAllFTE();
            });
        }
        //When user has searched, focus on search result message
        let searchResultsMessage = document.getElementById("searchResultsMessage");
        if (searchResultsMessage != null) {
            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
            searchResultsMessage.focus();
        }
        //Event listener for exporting all data to excel
        let exportToExcelButton = document.getElementById("exportExcelFTE");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        //Event listener for exporting data to excel based on search criteria
        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
        if (exportToExcelFilteredButton != null) {
            exportToExcelFilteredButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel(true)
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        document.addEventListener("click", (e) => {
            let target = e.target;
            //Adding a support
            if (target.classList.contains("addSupportButton") && "ftePk" in target.dataset) {
                Core.showLoader();
                that.createNewSupport(parseInt(target.dataset.ftePk))
                    .then((response) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    that.checkSupportCount(parseInt(target.dataset.ftePk));
                    that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk));
                })
                    .then(() => {
                    that._core.initializeRequiredFields(that.validationClasses);
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            }
            //Deleting a support
            else if (target.classList.contains("deleteSupport") && "supportPk" in target.dataset && "ftePk" in target.dataset) {
                that.deleteSupportConfirmModal.show();
                deleteSupportConfirmButton.dataset.supportPk = target.dataset.supportPk;
                deleteSupportConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteSupportConfirmModal.callingId = target.id;
            }
            //Deleting an FTE
            else if (target.classList.contains("deleteFTE") && "ftePk" in target.dataset) {
                that.deleteFTEConfirmModal.show();
                deleteFTEConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteFTEConfirmModal.callingId = target.id;
            }
            //Saving an FTE
            else if (target.classList.contains("saveFTEButton") && "ftePk" in target.dataset) {
                Core.showLoader();
                that.promiseSave(parseInt(target.dataset.ftePk))
                    .then((response) => {
                    //Recalculate hash and handle disabling the save button for the FTE
                    that.fteHashes[parseInt(target.dataset.ftePk)] = that.calculateFTEHash(parseInt(target.dataset.ftePk));
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            }
            //Marking FTE complete if userAdmin role
            else if (target.classList.contains("markCompleteButton") && "ftePk" in target.dataset) {
                //Core.showLoader();
                that.markFTEComplete(parseInt(target.dataset.ftePk))
                    .then((response) => {
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                    .catch((error) => {
                    //Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            }
        });
        document.addEventListener("change", (e) => {
            let target = e.target;
            //Handle Support Type Group changing
            if (target.classList.contains("supportTypeGroup") && "supportPk" in target.dataset && "ftePk" in target.dataset) {
                //1. Refresh the support type list
                //2. Refresh the support level list (1 needs to happen first)
                //3. Refresh FTE percent (1 and 2 need to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.refreshSupportTypeSubList(parseInt(target.dataset.supportPk))
                    .then(() => {
                    return that.refreshSupportLevels(parseInt(target.dataset.supportPk));
                })
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                })
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Support Type changing
            else if (target.classList.contains("supportType") && "supportPk" in target.dataset) {
                //1. Refresh the support level list
                //2. Refresh FTE percent (1 needs to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.refreshSupportLevels(parseInt(target.dataset.supportPk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                })
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Support Level changing
            else if (target.classList.contains("supportLevel") && "supportPk" in target.dataset) {
                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Classroom Location for FTE changing
            else if (target.classList.contains("classroomLocationFTE") && "ftePk" in target.dataset) {
                that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //Handle Full-time/Part-time changing
            else if (target.classList.contains("fullTime") && "ftePk" in target.dataset) {
                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Classroom Location for Support changing
            else if (target.classList.contains("classroomLocationSupport") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && !(target instanceof HTMLInputElement) && !(target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            }
        });
        document.addEventListener("input", (e) => {
            //On a slight delay so that ajax call isn't made until finished typing
            let target = e.target;
            //Handle Caseload changing
            if (target.classList.contains("caseLoad") && "supportPk" in target.dataset) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                if (typeof (that.typingTimer) !== 'undefined') {
                    clearTimeout(that.typingTimer);
                }
                that.typingTimer = setTimeout(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                        .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                        .catch((error) => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }, 500);
            }
            //Handle age Range From for Support changing
            else if (target.classList.contains("ageRangeFrom") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //Handle age Range End for Support changing
            else if (target.classList.contains("ageRangeEnd") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            }
        });
        //This is called when clicking the back/forward buttons in the browser. This is using the History API
        window.addEventListener('popstate', (e) => {
            let query = e.state;
            let fteID = document.getElementById("searchFTEId");
            let building = document.getElementById("searchBuilding");
            let supportType = document.getElementById("searchSupportType");
            if (fteID != null) {
                if (query != null && query.FTEIdentifierName != null) {
                    fteID.value = query.FTEIdentifierName;
                }
                else {
                    fteID.value = "";
                }
            }
            if (building != null) {
                if (query != null && query.FRCPPInstitutionFK != null) {
                    building.value = query.FRCPPInstitutionFK.toString();
                }
                else {
                    building.selectedIndex = 0;
                }
            }
            if (supportType != null) {
                if (query != null && query.SupportTypeCodePK != null) {
                    supportType.value = query.SupportTypeCodePK.toString();
                }
                else {
                    supportType.selectedIndex = 0;
                }
            }
            that.handleClearDisabled();
            Core.showLoader();
            that.promiseSave()
                .then(() => {
                return that.search(true);
            })
                .then(() => {
                that.initializeFTEHashes();
                //reinitialize accordions
                new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                Core.hideLoader();
            })
                .catch((error) => {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
            });
        });
        SpecialEducationEducationProgramCaseloadFTE.fullPageValidation(true);
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    //Save method that returns a promise. If ftePK is passed, only the specified FTE will be saved. Otherwise, all FTE on page will be saved (if there are changes)
    promiseSave(ftePK = null, forceSave = false) {
        let that = this;
        let core = this._core;
        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData = [];
            let fteToSave;
            if (ftePK !== null) {
                fteToSave = document.querySelectorAll(`.fteContainer[data-fte-pk='${ftePK}']`);
            }
            else {
                //Get all the FTE on the page
                fteToSave = document.querySelectorAll(".fteContainer");
            }
            for (let fte of fteToSave) {
                let fteSupportsData = [];
                if ("ftePk" in fte.dataset && that.fteHasChanged(parseInt(fte.dataset.ftePk))) {
                    shouldSave = true;
                    let ftePK = fte.dataset.ftePk;
                    //Get FTEId
                    let educationProgramFTEId = null;
                    let fteID = fte.querySelector(`.fteID[data-fte-pk='${ftePK}']`);
                    if (fteID != null && fteID.value != "") {
                        educationProgramFTEId = fteID.value;
                    }
                    //Get Classroom Location Lookup
                    let classroomLocationLookupCodeFK = null;
                    let classroomLocationFTE = fte.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`);
                    if (classroomLocationFTE != null && classroomLocationFTE.value != "") {
                        classroomLocationLookupCodeFK = parseInt(classroomLocationFTE.value);
                    }
                    //Get Full Time Lookup
                    let fullTimeLookupCodeFK = null;
                    let fullTimeFTE = fte.querySelector(`.fullTime[data-fte-pk='${ftePK}']`);
                    if (fullTimeFTE != null && fullTimeFTE.value != "") {
                        fullTimeLookupCodeFK = parseInt(fullTimeFTE.value);
                    }
                    //Get supports
                    let supports = [];
                    let supportsForFTE = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
                    for (let support of supportsForFTE) {
                        let supportElement = support;
                        if ("supportPk" in supportElement.dataset) {
                            //exitingViaTransferOfEntity
                            let exitingViaTransferOfEntity = null;
                            let exitingViaTransferOfEntityEle = supportElement.querySelector(`.exitingViaTransferOfEntity[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (exitingViaTransferOfEntityEle !== null) {
                                if (exitingViaTransferOfEntityEle.checked) {
                                    exitingViaTransferOfEntity = true;
                                }
                                else {
                                    exitingViaTransferOfEntity = false;
                                }
                            }
                            //buildingName
                            let frcppInstitutionFK = null;
                            let buildingName = supportElement.querySelector(`.buildingName[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (buildingName != null && buildingName.value != "") {
                                frcppInstitutionFK = parseInt(buildingName.value);
                            }
                            //supportTypeGroup
                            let supportTypeGroupCodeFK = null;
                            let supportTypeGroup = supportElement.querySelector(`.supportTypeGroup[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportTypeGroup != null && supportTypeGroup.value != "") {
                                supportTypeGroupCodeFK = parseInt(supportTypeGroup.value);
                            }
                            //supportType
                            let supportTypeCodeFK = null;
                            let supportType = supportElement.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportType != null && supportType.value != "") {
                                supportTypeCodeFK = parseInt(supportType.value);
                            }
                            //supportLevel
                            let supportLevelCodeFK = null;
                            let supportLevel = supportElement.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportLevel != null && supportLevel.value != "") {
                                supportLevelCodeFK = parseInt(supportLevel.value);
                            }
                            //caseLoad
                            let caseLoadCount = null;
                            let caseLoad = supportElement.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (caseLoad != null && caseLoad.value != "") {
                                caseLoadCount = parseInt(caseLoad.value);
                            }
                            //identifyclassroom
                            let classroomLookupCodeFK = null;
                            let identifyclassroom = supportElement.querySelector(`.identifyClassroom[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (identifyclassroom != null && identifyclassroom.value != "") {
                                classroomLookupCodeFK = parseInt(identifyclassroom.value);
                            }
                            //classroomLocationSupport
                            let supportClassroomLocationLookupCodeFK = null;
                            let classroomLocationSupport = supportElement.querySelector(`.classroomLocationSupport[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (classroomLocationSupport != null && classroomLocationSupport.value != "") {
                                supportClassroomLocationLookupCodeFK = parseInt(classroomLocationSupport.value);
                            }
                            //ageRangeFrom
                            let ageRangeFromNbr = null;
                            let ageRangeFrom = supportElement.querySelector(`.ageRangeFrom[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeFrom != null && ageRangeFrom.value != "") {
                                ageRangeFromNbr = parseInt(ageRangeFrom.value);
                            }
                            //ageRangeEnd
                            let ageRangeToNbr = null;
                            let ageRangeEnd = supportElement.querySelector(`.ageRangeEnd[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeEnd != null && ageRangeEnd.value != "") {
                                ageRangeToNbr = parseInt(ageRangeEnd.value);
                            }
                            //ageRangeJustification
                            let ageRangeJustificationText = "";
                            let ageRangeJustification = supportElement.querySelector(`.ageRangeJustification[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeJustification != null && ageRangeJustification.value != "") {
                                ageRangeJustificationText = ageRangeJustification.value;
                            }
                            let fteSupportSaveData = {
                                AgeRangeFromNbr: ageRangeFromNbr,
                                AgeRangeJustification: ageRangeJustificationText,
                                AgeRangeToNbr: ageRangeToNbr,
                                CaseLoadCount: caseLoadCount,
                                ClassroomLocationLookupCodeFK: supportClassroomLocationLookupCodeFK,
                                ClassroomLookupCodeFK: classroomLookupCodeFK,
                                EducationProgramFTEFK: parseInt(ftePK),
                                EducationProgramFTESupportPK: parseInt(supportElement.dataset.supportPk),
                                FRCPPInstitutionFK: frcppInstitutionFK,
                                SupportLevelCodeFK: supportLevelCodeFK,
                                SupportTypeCodeFK: supportTypeCodeFK,
                                UISelectedSupportTypeGroupCodeFK: supportTypeGroupCodeFK,
                                ExitingViaTransferOfEntityInd: exitingViaTransferOfEntity
                            };
                            supports.push(fteSupportSaveData);
                        }
                    }
                    let fteSaveData = {
                        ClassroomLocationLookupCodeFK: classroomLocationLookupCodeFK,
                        FullTimeLookupCodeFK: fullTimeLookupCodeFK,
                        FTEIdentifierName: educationProgramFTEId,
                        EducationProgramFTEPK: parseInt(ftePK),
                        PlanFK: that.planFK
                    };
                    let thisFTESaveData = {
                        FTE: fteSaveData,
                        Supports: supports
                    };
                    saveData.push(thisFTESaveData);
                }
            }
            if (shouldSave || forceSave) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Base/SaveEducationProgramFTE', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    return __awaiter(this, void 0, void 0, function* () {
                        if (xhr.status === 200) {
                            if (ftePK != null) {
                                const json = JSON.parse(xhr.responseText);
                                const validData = json.validData;
                                SpecialEducationEducationProgramCaseloadFTE.fteValidation(ftePK);
                                const elementButton = document.querySelector(`.lazyAccordionTrigger[data-educationprogramftefk='${ftePK}']`);
                                elementButton.dataset.validdata = validData;
                                yield SpecialEducationEducationProgramCaseloadFTE.getValidProgramCount();
                                SpecialEducationEducationProgramCaseloadFTE.fullPageValidation();
                                SpecialEducationEducationProgramCaseloadFTE.planPageCompleteCheck();
                                resolve("Successfully saved FTE");
                            }
                            else {
                                yield SpecialEducationEducationProgramCaseloadFTE.getValidProgramCount();
                                SpecialEducationEducationProgramCaseloadFTE.fullPageValidation();
                                SpecialEducationEducationProgramCaseloadFTE.planPageCompleteCheck();
                                resolve("Successfully saved");
                            }
                        }
                        else {
                            reject("There was an unexpected error saving");
                        }
                    });
                };
                xhr.send(JSON.stringify({
                    "Data": saveData,
                    "PlanFK": that.planFK,
                    "AllowExceedsFTE": that.allowExceedsFTE
                }));
            }
            else {
                resolve("Nothing To Save");
            }
        });
    }
    //Top level save method used by back/continue buttons, clicking on link, etc. Calls the promiseSave method and controls redirect.
    save(referrer, ftePK = null) {
        let that = this;
        let core = that._core;
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let forceSave = false;
        if (referrer === "save") {
            forceSave = true;
        }
        that.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();
            if (referrer === "save") {
                //Reset query so that all results show
                window.location.href = window.location.href.split('?')[0] + '?fromSave=true';
            }
            else if (refreshPage && refreshPage !== "") {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
    }
    //Creates a new FTE record in database and prepends partial view to DOM
    createNewFTE() {
        let that = this;
        return new Promise((resolve, reject) => {
            let newFTEID = document.getElementById("createNewFTEId");
            let newClassroomLocation = document.getElementById("createNewClassroomLocation");
            let newFullTime = document.getElementById("createNewFullTime");
            let data = {
                EducationProgramFTEPK: 0,
                ClassroomLocationLookupCodeFK: parseInt(newClassroomLocation.value),
                FullTimeLookupCodeFK: parseInt(newFullTime.value),
                FTEIdentifierName: newFTEID.value,
                PlanFK: that.planFK
            };
            let saveData = {
                FTE: data,
                AllowExceedsFTE: that.allowExceedsFTE
            };
            if (newFTEID != null && newFTEID.value != "" && newClassroomLocation != null && newClassroomLocation.selectedIndex != 0 && newFullTime != null && newFullTime.selectedIndex != 0) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/CreateNewFTE', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        let fteList = document.getElementById("fteContainerInner");
                        if (fteList != null) {
                            $(fteList).prepend(xhr.responseText);
                            newFTEID.value = "";
                            newClassroomLocation.selectedIndex = 0;
                            newFullTime.selectedIndex = 0;
                            let newFTEAccordion = fteList.querySelector(".lazyAccordion-trigger");
                            if (newFTEAccordion != null) {
                                new SpecialEducationProgramCaseloadFTELazyAccordion(newFTEAccordion.id);
                            }
                            else {
                                new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                            }
                            resolve("Successfully created new FTE");
                        }
                    }
                    else if (xhr.status === 409) {
                        reject("This FTE ID already exists, please use a unique name.");
                    }
                    else {
                        reject("There was an unexpected error creating new FTE");
                    }
                };
                xhr.send(JSON.stringify(saveData));
            }
            else {
                reject("All fields must have a value to create a new FTE");
            }
        });
    }
    //Creates a new support record in database and appends partial view to DOM
    createNewSupport(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CreateNewSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let supportList = document.querySelector(`.supportsContainer[data-fte-pk='${ftePK}']`);
                    if (supportList != null) {
                        $(supportList).append(xhr.responseText);
                        resolve("Successfully added support");
                    }
                    else {
                        reject("There was an unexpected error adding the support");
                    }
                }
                else {
                    reject("There was an unexpected error creating new support");
                }
            };
            xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }
    //Soft deletes support from database
    deleteSupport(supportPK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let supportContainer = document.querySelector(`.supportRow[data-support-pk='${supportPK}']`);
                    if (supportContainer != null) {
                        let deleteSupportButton = document.querySelector(`.deleteSupport[data-support-pk='${supportPK}']`);
                        let nextFocusable = Core.getNextFocusableElement(deleteSupportButton);
                        supportContainer.parentNode.removeChild(supportContainer);
                        nextFocusable.focus();
                        resolve("Successfully deleted support");
                    }
                    else {
                        reject("There was an unexpected error removing this support from the page");
                    }
                }
                else {
                    reject("There was an unexpected error deleting this support");
                }
            };
            xhr.send("supportPK=" + supportPK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }
    //Determines whether to show the message that the FTE has no supports in the UI
    checkSupportCount(ftePK) {
        let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
        let noSupportsMessage = document.querySelector(`.noSupportsMessage[data-fte-pk='${ftePK}']`);
        if (noSupportsMessage != null) {
            if (supports.length > 0) {
                noSupportsMessage.classList.add("hide");
            }
            else {
                noSupportsMessage.classList.remove("hide");
            }
        }
    }
    //Soft deletes FTE record from database
    deleteFTE(ftePK) {
        let that = this;
        that.deleteFTEConfirmModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFTE', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let allFTEContainer = document.getElementById("fteContainerInner");
                let fteContainer = document.querySelector(`.fteContainer[data-fte-pk='${ftePK}']`);
                let accordion = Core.findClosest(fteContainer, ".lazyAccordion");
                //Get next focusable accordion
                let accordionTriggers = allFTEContainer.querySelectorAll(".lazyAccordion-trigger");
                if (accordionTriggers.length > 1) {
                    let arr = Array.prototype.slice.call(accordionTriggers);
                    let thisAccordionTrigger = accordion.querySelector(".lazyAccordion-trigger");
                    if (thisAccordionTrigger != null) {
                        let index = arr.indexOf(thisAccordionTrigger);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                }
                else {
                    //Focus on create fte if no fte left
                    let create = document.getElementById("createNewFTEId");
                    if (create != null) {
                        create.focus();
                    }
                }
                if (accordion != null) {
                    accordion.parentNode.removeChild(accordion);
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted FTE", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error removing this FTE from the page", 'error', 3000, null);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE", 'error', 3000, null);
            }
        };
        xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
    }
    //Refreshes the possible Support Types that can be selected in the UI based on the Support Type Group that was selected
    refreshSupportTypeSubList(supportPK) {
        let that = this;
        let supportTypeGroup = document.querySelector(`.supportTypeGroup[data-support-pk='${supportPK}']`);
        return new Promise((resolve, reject) => {
            if (supportTypeGroup != null) {
                if (supportTypeGroup.value != "") {
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/SpecialEducation/RefreshSupportTypeSubList', true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            let supportTypeListContainer = document.querySelector(`.supportTypesContainer[data-support-pk='${supportPK}']`);
                            if (supportTypeListContainer != null) {
                                supportTypeListContainer.innerHTML = xhr.responseText;
                                let supportTypeDropdown = supportTypeListContainer.querySelector(".supportType[data-forcerequired='true']");
                                if (supportTypeDropdown != null) {
                                    that._core.forceElementRequired(supportTypeDropdown);
                                }
                            }
                            resolve();
                        }
                        else {
                            reject("Error refreshing support types");
                        }
                    };
                    xhr.send(`supportPK=${supportPK}&ftePK=${supportTypeGroup.dataset.ftePk}&supportTypeGroupCodeFK=${supportTypeGroup.value}&templateFK=${that.templateFK}`);
                }
                else {
                    let container = document.querySelector(`.supportTypesContainer[data-support-pk='${supportTypeGroup.dataset.supportPk}']`);
                    if (container != null) {
                        container.innerHTML = "";
                    }
                    resolve();
                }
            }
            else {
                reject("Support type group not found");
            }
        });
    }
    //Refreshes the possible Support Levels that can be selected in the UI based on the Support Type that was selected
    refreshSupportLevels(supportPK) {
        let that = this;
        let currentLevelPK = null;
        let currentTypePK = null;
        let currentLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`);
        if (currentLevel != null && currentLevel.value != "") {
            currentLevelPK = currentLevel.value;
        }
        let currentType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`);
        if (currentType != null && currentType.value != "") {
            currentTypePK = currentType.value;
        }
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/RefreshSupportLevels', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let supportLevelListContainer = document.querySelector(`.supportLevelsContainer[data-support-pk='${supportPK}']`);
                    if (supportLevelListContainer != null) {
                        supportLevelListContainer.innerHTML = xhr.responseText;
                        let supportLevelDropdown = supportLevelListContainer.querySelector(".supportLevel[data-forcerequired='true']");
                        if (supportLevelDropdown != null) {
                            that._core.forceElementRequired(supportLevelDropdown);
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing support levels");
                }
            };
            xhr.send(`supportPK=${supportPK}&ftePK=${currentLevel.dataset.ftePk}&currentLevelPK=${currentLevelPK}&currentTypePK=${currentTypePK}&templateFK=${that.templateFK}`);
        });
    }
    //Shows the max possible caseload count in the UI given the currently selected Support Level and Type for the specified Support
    refreshMaxPossibleCaseLoad(supportPK) {
        let that = this;
        let supportType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`);
        let supportLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`);
        return new Promise((resolve, reject) => {
            if (supportType != null && supportType.value != "" && supportLevel != null && supportLevel.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/RefreshMaxPossibleCaseLoad', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    let jsonResponse = JSON.parse(xhr.response);
                    if (xhr.status === 200 && jsonResponse.success) {
                        let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`);
                        if (maxCaseLoadContainer != null) {
                            maxCaseLoadContainer.classList.remove("hide");
                        }
                        let maxCaseLoadElement = document.querySelector(`.maxCaseLoad[data-support-pk='${supportPK}']`);
                        if (maxCaseLoadElement != null) {
                            maxCaseLoadElement.innerHTML = jsonResponse.maxCaseLoadCount;
                        }
                        resolve();
                    }
                    else {
                        reject("Error refreshing max caseload");
                    }
                };
                xhr.send(`supportTypePK=${supportType.value}&supportLevelPK=${supportLevel.value}`);
            }
            else {
                let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`);
                if (maxCaseLoadContainer != null) {
                    maxCaseLoadContainer.classList.add("hide");
                }
            }
        });
    }
    //Refreshes the FTE for each support in the FTE and the FTE as a whole. If the FTE is over the limit, the error will display.
    refreshFTEPercentage(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];
            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-fte-pk='${ftePK}']`);
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }
            let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = support;
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;
                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }
                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }
                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }
                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;
                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-fte-pk='${ftePK}']`);
                    if (overOneMessage != null) {
                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }
                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        }
                        else {
                            overOneMessage.classList.add("hide");
                        }
                    }
                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`);
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        }
                        else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }
    //The classroom location for a support is dependent upon the selected classroom location for the FTE. This function handles this in the UI
    adjustSupportClassroomLocation(ftePK) {
        return new Promise((resolve, reject) => {
            let classroomLocationLookupCode = "";
            let fteClassroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`);
            if (fteClassroomLocation != null) {
                classroomLocationLookupCode = fteClassroomLocation.options[fteClassroomLocation.selectedIndex].dataset.lookupCode;
            }
            //Get the supports for this FTE
            let classroomLocationsSupport = document.querySelectorAll(`.classroomLocationSupport[data-fte-pk='${ftePK}']`);
            for (let classroomLocationDropdown of classroomLocationsSupport) {
                let classroomLocationDropdownElement = classroomLocationDropdown;
                if (classroomLocationLookupCode === "specialeducationlocationelementary" || classroomLocationLookupCode === "specialeducationlocationsecondary") {
                    for (var i = 0; i < classroomLocationDropdownElement.options.length; ++i) {
                        if (classroomLocationDropdownElement.options[i].dataset.lookupCode === classroomLocationLookupCode) {
                            classroomLocationDropdownElement.selectedIndex = i;
                            classroomLocationDropdownElement.disabled = true;
                            break;
                        }
                    }
                }
                else {
                    classroomLocationDropdownElement.disabled = false;
                }
            }
            resolve();
        });
    }
    //Based on the selection of "Classroom Location" and "Age Range", make the "Age Range Justification" optional or required
    checkAgeRangeJustification(supportPK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let makeRequired = false;
            let classroomLocation = document.querySelector(`.classroomLocationSupport[data-support-pk='${supportPK}']`);
            let ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportPK}']`);
            let ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportPK}']`);
            if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
                if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {
                    let range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);
                    let classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;
                    switch (classroomLocationLookupCode) {
                        case 'specialeducationlocationelementary':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationsecondary':
                            if (range > 4) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationmultiple':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                    }
                }
            }
            let justification = document.querySelector(`.ageRangeJustification[data-support-pk='${supportPK}']`);
            if (justification != null) {
                if (makeRequired) {
                    that._core.forceElementRequired(justification);
                }
                else {
                    that._core.forceElementOptional(justification);
                }
            }
            resolve();
        });
    }
    //Shows a loader next to the FTE percentage while application figures out what the new percentage is
    toggleFTESupportLoader(supportPK, show) {
        let loaderContainer = document.querySelector(`.loaderContainer[data-support-pk='${supportPK}']`);
        if (loaderContainer != null) {
            if (show) {
                loaderContainer.classList.remove("hide");
            }
            else {
                loaderContainer.classList.add("hide");
            }
        }
    }
    //Searches FTE based on criteria and refreshes DOM with partial view. Also pushes history state so that back and continue buttons in browser work.
    search(fromHistory = false) {
        let that = this;
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        let fteIDValue = fteID != null ? fteID.value : "";
        let buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
        let supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;
        let query = {
            PlanFK: that.planFK,
            FTEIdentifierName: fteIDValue,
            FRCPPInstitutionFK: buildingValue,
            SupportTypeCodePK: supportTypeValue
        };
        let queryString = `?fteId=${query.FTEIdentifierName}&frcppInstitutionFK=${query.FRCPPInstitutionFK}&supportTypeCodeFK=${query.SupportTypeCodePK}`;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/FTESearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("fteContainer");
                    if (container != null) {
                        container.innerHTML = xhr.responseText;
                        //Add to history so back button works
                        if (!fromHistory) {
                            history.pushState(query, '', queryString);
                        }
                        let searchResultsMessage = document.getElementById("searchResultsMessage");
                        if (searchResultsMessage != null) {
                            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
                            searchResultsMessage.focus();
                        }
                        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
                        exportToExcelFilteredButton.classList.remove("hide");
                        SpecialEducationFTETransferOfEntity.fullPageValidation();
                        resolve();
                    }
                    else {
                        reject("There was an error loading FTE");
                    }
                }
                else {
                    reject("There was an unexpected error during search");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }
    //Clears search fields, saves page (in case there were changes), and refreshes DOM
    clearSearch() {
        let that = this;
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        if (fteID != null) {
            fteID.value = "";
        }
        if (building != null) {
            building.selectedIndex = 0;
        }
        if (supportType != null) {
            supportType.selectedIndex = 0;
        }
        Core.showLoader();
        that.promiseSave()
            .then(() => {
            return that.search(true);
        })
            .then(() => {
            that.initializeFTEHashes();
            that.handleClearDisabled();
            that._core.initializeRequiredFields(that.validationClasses);
            //reinitialize accordions
            new SpecialEducationProgramCaseloadFTELazyAccordion(null);
            Core.hideLoader();
            let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
            exportToExcelFilteredButton.classList.add("hide");
        })
            .catch((error) => {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
        history.pushState(null, '', window.location.href.split('?')[0]);
        document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }
    //Calculates a hash for an FTE. This is used to determine whether the FTe has changed for saving purposes.
    calculateFTEHash(ftePK) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;
        let allElements = document.querySelectorAll(`[data-hashable][data-fte-pk='${ftePK}']`);
        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                let inputElement = element;
                if (inputElement.value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (inputElement.value !== "") {
                    value = inputElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLSelectElement) {
                let selectElement = element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLTextAreaElement) {
                let textAreaElement = element;
                if (textAreaElement.value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
        }
        return hash;
    }
    //Determines whether an FTE has changed by comparing the current hash value to a prior hash value
    fteHasChanged(ftePK) {
        let that = this;
        let changed = false;
        if (that.fteHashes[ftePK] !== undefined) {
            let newHash = that.calculateFTEHash(ftePK);
            if (newHash !== that.fteHashes[ftePK]) {
                changed = true;
            }
        }
        else {
            //Be on the safe side and say it has changed
            changed = true;
        }
        return changed;
    }
    //Calculates a hash value for each FTE on the page and is stored in an object as a property of this class.
    initializeFTEHashes() {
        let that = this;
        that.fteHashes = {};
        let fteContainers = document.getElementsByClassName("fteContainer");
        for (let fte of fteContainers) {
            let ftePK = parseInt(fte.dataset.ftePk);
            let hash = that.calculateFTEHash(ftePK);
            that.fteHashes[ftePK] = hash;
        }
    }
    //The save button only becomes available for an FTE if there is a change within the FTE
    handleFTESaveDisabled(ftePK) {
        let saveButton = document.querySelector(`.saveFTEButton[data-fte-pk='${ftePK}']`);
        if (saveButton != null) {
            if (this.fteHasChanged(ftePK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            }
            else {
                saveButton.disabled = true;
            }
        }
    }
    //Determines whether the clear button should become enabled
    handleClearDisabled() {
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        let clearButton = document.getElementById("searchClear");
        if (fteID != null && building != null && supportType != null && clearButton != null) {
            if (fteID.value !== "" || building.selectedIndex > 0 || supportType.selectedIndex > 0) {
                clearButton.disabled = false;
            }
            else {
                clearButton.disabled = true;
            }
        }
    }
    //Custom client-side validation for the page
    //Collapses all FTE accordions on the page
    collapseAllFTE() {
        let fteContainer = document.getElementById("fteContainerInner");
        if (fteContainer != null) {
            let openToggles = fteContainer.querySelectorAll(".Accordion-trigger.open");
            for (let toggle of openToggles) {
                let toggleElement = toggle;
                toggleElement.click();
            }
        }
    }
    //Expands all FTE accordions on the page
    expandAllFTE() {
        let fteContainer = document.getElementById("fteContainerInner");
        if (fteContainer != null) {
            let closedToggles = fteContainer.querySelectorAll(".Accordion-trigger:not(.open)");
            for (let toggle of closedToggles) {
                let toggleElement = toggle;
                toggleElement.click();
            }
        }
    }
    //Exports FTE and support data to excel. If they click the Filtered button, the data in the Excel file will be filtered based on search criteria
    exportToExcel(filtered = false) {
        let that = this;
        return new Promise((resolve, reject) => {
            let fteID = document.getElementById("searchFTEId");
            let building = document.getElementById("searchBuilding");
            let supportType = document.getElementById("searchSupportType");
            let fteIDValue = "";
            let buildingValue = null;
            let supportTypeValue = null;
            if (filtered) {
                fteIDValue = fteID != null ? fteID.value : "";
                buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
                supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;
            }
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/EducationProgramFTEDataExport?planFK=${that.planFK}&fteId=${fteIDValue}&frcppInstitutionFK=${buildingValue}&supportTypeCodeFK=${supportTypeValue}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "EducationProgramFTE.xlsx";
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send();
        });
    }
    static fteValidation(educationProgramFTEFK) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);
        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    }
                    else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }
        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    }
                    else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }
        this.updateFTEPercentage(educationProgramFTEFK);
    }
    static updateFTEPercentage(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];
            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`);
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }
            let supports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = support;
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;
                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }
                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }
                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }
                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;
                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-ftetransferofentity-pk='${ftePK}']`);
                    if (overOneMessage != null) {
                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }
                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        }
                        else {
                            overOneMessage.classList.add("hide");
                        }
                    }
                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`);
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        }
                        else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }
    static initializeLoadedFTE(educationProgramFTEFK) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);
        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        this.updateFTEPercentage(educationProgramFTEFK);
    }
    static fullPageValidation(fromPageLoad = false) {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownInvalidAccordions = document.querySelectorAll(".lazyAccordionTrigger[data-validdata='false']");
        const totalErrorsNotShown = totalErrors - shownInvalidAccordions.length;
        let errorCount = 0;
        for (const accordion of allAccordions) {
            const accElement = accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;
            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            }
            else {
                accElement.classList.remove("error");
            }
        }
        const messageContainer = document.getElementById("validationMessageContainer");
        const validationIcon = document.getElementById("validationMessageIcon");
        const messageContainerColumn = document.getElementById("validationColumn");
        if (!fromPageLoad || errorCount > 0 || totalErrorsNotShown > 0) {
            messageContainerColumn.classList.add("show");
        }
        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");
        const message = document.getElementById("validationMessage");
        if (errorCount > 0) {
            let word = "are";
            let facility = "FTEs";
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "FTE";
                error = "error";
            }
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = '';
            if (!fromPageLoad) {
                message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            }
            let facilityWord = "are";
            let facilityFacility = "FTEs";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "FTE";
            }
            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            if (message !== null) {
                message.innerHTML = "The page has been successfully saved.";
                message.classList.add("show");
            }
        }
    }
    static clearValidationMessage() {
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }
    static getValidProgramCount() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                const planForm = document.getElementById("specialEducationEducationProgramCaseloadFTEForm");
                const planFK = planForm.dataset.planfk;
                const response = yield fetch(`/SpecialEducation/GetValidProgramFTECountAsync/${planFK}`, { credentials: 'include' });
                if (response.ok) {
                    const value = yield response.text();
                    const field = document.getElementById("validCount");
                    field.value = value;
                    resolve("Valid program count updated");
                }
                else {
                    reject("Error updating program count");
                }
            }));
        });
    }
    static planPageCompleteCheck() {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById("specialEducationEducationProgramCaseloadFTEForm");
            const planFK = form.dataset.planfk;
            const pageCode = form.dataset.pagecode;
            const response = yield fetch(`/SpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' });
            if (response.ok) {
                const value = yield response.json();
                const menuElement = document.querySelector("#leftBarspecialeducationprogramcaseloadParent .status-indicator");
                const parentMenuElement = document.querySelector("#leftBarspecialeducationprogramprofileParent .status-indicator");
                if (value.thisPage) {
                    menuElement.classList.add("complete");
                }
                else {
                    menuElement.classList.remove("complete");
                }
                if (value.parentPage) {
                    parentMenuElement.classList.add("complete");
                }
                else {
                    parentMenuElement.classList.remove("complete");
                }
            }
        });
    }
    //Mark FTE Complete
    markFTEComplete(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/MarkFTECompleteByAdmin', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve("FTE Marked Complete");
                }
                else {
                    reject("There was an unexpected error");
                }
            };
            xhr.send("programFTEFK=" + ftePK);
        });
    }
}
// Special Education Special Education Facilities
class SpecialEducationSpecialEducationFacilities {
    constructor() {
        let that = this;
        // Boilerplate
        this._core = new Core();
        SpecialEducationSpecialEducationFacilities._staticCore = new Core();
        SpecialEducationSpecialEducationFacilities.staticValidationClasses = ["specialEducationSpecialEducationFacilitiesField"];
        if (this._core.Workflows.length > 0) {
            for (let seprnWorkflow of this._core.Workflows) {
                SpecialEducationSpecialEducationFacilities.initializeSEPRNWorkflow(seprnWorkflow);
            }
        }
        new SpecialEducationFacilitiesLazyAccordion();
        this._core.leftnav(this);
        this.validationClasses = ['specialEducationSpecialEducationFacilitiesField'];
        const deleteFacilityConfirmButton = document.getElementById("deleteFacilityConfirm");
        if (deleteFacilityConfirmButton !== null)
            deleteFacilityConfirmButton.addEventListener("click", (e) => this.deleteSpecialEducationFacility(deleteFacilityConfirmButton));
        const deleteFacilityCancelButton = document.getElementById("deleteFacilityCancel");
        if (deleteFacilityCancelButton !== null)
            deleteFacilityCancelButton.addEventListener("click", (e) => this.deleteFacilityCancel());
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById('hashValue');
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        // Get form element and PlanFK
        const form = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        if (form != null) {
            this.planFK = parseInt(form.dataset.planfk);
        }
        // Set search event on search button
        const searchButton = document.getElementById("facilitiesSearchButton");
        if (searchButton !== null) {
            //1. Save
            //2. Run Search
            searchButton.addEventListener("click", () => {
                Core.showLoader();
                SpecialEducationSpecialEducationFacilities.promiseSave(null, true, false, false, true).then(() => {
                    return SpecialEducationSpecialEducationFacilities.search();
                }).then(() => {
                    // reinitialize accordions
                    new SpecialEducationFacilitiesLazyAccordion();
                    Core.hideLoader();
                    SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
                    SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
                    SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
                    that._core.initializeWorkflows();
                    if (that._core.Workflows.length > 0) {
                        for (let seprnWorkflow of this._core.Workflows) {
                            SpecialEducationSpecialEducationFacilities.initializeSEPRNWorkflow(seprnWorkflow);
                        }
                    }
                    Tab.Init();
                }).catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        // Set clear event on clear button
        const clearButton = document.getElementById("facilitiesClearButton");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                SpecialEducationSpecialEducationFacilities.clearSearch();
            });
        }
        // Set file upload/delete events
        SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
        SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
        SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
        // Set add a location event on button
        const addLocationButton = document.getElementById('specialEducationSpecialEducationFacilitiesAddALocation');
        if (addLocationButton != null) {
            addLocationButton.addEventListener('click', () => {
                Core.showLoader();
                this.addLocation()
                    .then((response) => {
                    Core.hideLoader();
                    this._core.initializeRequiredFields(this.validationClasses);
                    Core.createHTMLAlert('alertMessageDiv', response, 'success', 3000, null);
                    SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
                    SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
                    SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
                });
            });
        }
        document.addEventListener("click", (e) => {
            let target = e.target;
            //Adding a support
            if (target.classList.contains("createSEPRNButton") && "facilityPk" in target.dataset) {
                SpecialEducationSpecialEducationFacilities.createSEPRN(parseInt(target.dataset.facilityPk));
            }
            else if (target.classList.contains("showDifferences") && "facilityPk" in target.dataset) {
                that.showSEPRNDifferences(parseInt(target.dataset.facilityPk));
            }
            else if (target.classList.contains("delete-facility-button")) {
                that.showDeleteFacilityConfirmation(target);
            }
        });
        document.addEventListener("change", (e) => {
            let target = e.target;
            if (target.classList.contains("proposedAction") && "facilityPk" in target.dataset) {
                let proposedActionElement = target;
                let selectedProposedAction = proposedActionElement.options[proposedActionElement.selectedIndex];
                if (selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    //Open up editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']`);
                    for (let editable of allEditable) {
                        editable.removeAttribute("readonly");
                        editable.removeAttribute("disabled");
                    }
                }
                else {
                    //disable editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']:not([data-propertypk='1184'])`);
                    for (let editable of allEditable) {
                        if (editable instanceof HTMLInputElement) {
                            if (editable.hasAttribute("type") && editable.getAttribute("type") == "file" || editable.getAttribute("type") == "radio") {
                                editable.disabled = true;
                            }
                            else {
                                editable.readOnly = true;
                            }
                        }
                        else if (editable instanceof HTMLSelectElement || editable instanceof HTMLButtonElement) {
                            editable.disabled = true;
                        }
                    }
                }
                var additionalInfo = document.querySelector(`.additionalInformation[data-facility-pk='${proposedActionElement.dataset.facilityPk}']`);
                if (selectedProposedAction.dataset.lookupCode == "proposedActionDelete" || selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    if (additionalInfo != null) {
                        that._core.forceElementRequired(additionalInfo);
                    }
                }
                else {
                    if (additionalInfo != null) {
                        that._core.forceElementOptional(additionalInfo);
                    }
                }
            }
        });
        let closeSEPRNCompareButton = document.getElementById('closeModalcompareFacilitiesModal');
        if (closeSEPRNCompareButton != null) {
            closeSEPRNCompareButton.addEventListener("click", () => {
                const modal = new Modal('compareFacilitiesModal', null);
                modal.hide();
            });
        }
        const facilitiesExportToExcelButton = document.getElementById("specialEducationFacilitiesExportToExcel");
        if (facilitiesExportToExcelButton !== null) {
            facilitiesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        SpecialEducationSpecialEducationFacilities.initializeFacilityHashes();
        SpecialEducationSpecialEducationFacilities.fullPageValidation(true);
    }
    deleteSpecialEducationFacility(button) {
        const facilityFK = button.dataset.facilitypk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteSpecialEducationFacility', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const response = JSON.parse(xhr.response);
            if (xhr.status === 200 && response.success) {
                const accordionTrigger = document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${facilityFK}']`);
                accordionTrigger.parentElement.parentElement.remove();
                const modal = new Modal("deleteFacilityModal", null);
                modal.hide();
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the facility', 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred deleting the facility, please try again.', 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(facilityFK));
    }
    showSEPRNDifferences(facilityPK) {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/SpecialEducation/GetFacilityComparison?facilityPK=' + facilityPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal = new Modal('compareFacilitiesModal', null);
                modal.show();
                const compareFacilitiesBody = document.getElementById('compareFacilitiesBody');
                if (compareFacilitiesBody != null) {
                    $(compareFacilitiesBody).html(xhr.responseText);
                    that.constructAutofillInputsSEPRN();
                    that.highlightChangedFieldsSEPRN();
                }
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }
    handleMaintained3YearsAssurance() {
        let facilities = document.querySelectorAll(`.facilityContainer[data-seprn-comparison='false'][data-facility-pk]`);
        for (let facility of facilities) {
            let facilityEl = facility;
            let assuranceRowSchoolMaintained3Years = document.querySelector(`.assurance-check-row[data-facility-pk='${facilityEl.dataset.facilityPk}'][data-property-code='specialEducationFacilitiesLocationMaintained3Years']`);
            if (assuranceRowSchoolMaintained3Years != null) {
                assuranceRowSchoolMaintained3Years.classList.add("hide");
            }
            let proposedAction = document.querySelector(`.proposedAction[data-facility-pk='${facilityEl.dataset.facilityPk}']`);
            if (proposedAction != null) {
                proposedAction.addEventListener("change", function () {
                    let selectedOption = proposedAction.options[proposedAction.selectedIndex];
                    if (selectedOption.dataset.lookupCode == "proposedActionChange" || selectedOption.dataset.lookupCode == "proposedActionDelete") {
                        assuranceRowSchoolMaintained3Years.classList.remove("hide");
                    }
                    else {
                        assuranceRowSchoolMaintained3Years.classList.add("hide");
                    }
                });
            }
        }
    }
    static initializeSEPRNWorkflow(seprnWorkflow) {
        let seprnContainer = document.querySelector(`.seprnContainer[data-facility-pk][data-workflow-instance-pk='${seprnWorkflow.WorkflowInstancePK}']`);
        if (seprnContainer != null) {
            let specialEducationFacilityPK = parseInt(seprnContainer.dataset.facilityPk);
            //Override so that review submission stays on current page instead of going to review admin
            seprnWorkflow.ReviewURL = '';
            seprnWorkflow.BeforeSave = () => this.promiseSave(specialEducationFacilityPK, false, false, true);
            let proposedChangeAction = seprnWorkflow.ActionResponses.filter(ac => ac.ActionCode == "seprnType");
            if (proposedChangeAction.length > 0) {
                let proposedChangeActionRadio = proposedChangeAction[0];
                let deletedRadio = proposedChangeActionRadio.RadioElements.filter(re => "lookupCode" in re.dataset && re.dataset.lookupCode == "proposedActionDelete");
                if (deletedRadio != null && deletedRadio.length > 0) {
                    if (deletedRadio[0].checked) {
                        const actionsToDisable = ['onsiteReviewSEPRN', 'ebbFlowSEPRN', 'noiseInterfereSEPRN', 'designedForInstructionSEPRN', 'readilyAccessibleSEPRN', 'squareFeet28SEPRN'];
                        for (let action of seprnWorkflow.ActionResponses.filter(a => actionsToDisable.indexOf(a.ActionCode) >= 0 && a instanceof WorkflowActionResponseRadio)) {
                            let radioAction = action;
                            for (let radio of radioAction.RadioElements) {
                                radio.disabled = true;
                                if ("lookupCode" in radio.dataset && radio.dataset.lookupCode == "actionNA") {
                                    radio.checked = true;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    static constructAutofillInputs() {
        const areaMeasurementEles = document.querySelectorAll('.specialEducationSpeicalEducationFacilitiesAreMeasurementField');
        for (let ele of areaMeasurementEles) {
            const element = ele;
            ele.addEventListener('change', (e) => this.autofillInputs(element.dataset.facilityPk, false));
        }
        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = c;
            const facilityPK = container.dataset.facilityPk;
            this.autofillInputs(facilityPK, true);
        }
    }
    constructAutofillInputsSEPRN() {
        // Get measurement values from the inputs
        const originalAreaMeasurements = {
            WidthFeet: {
                Element: document.querySelector(`#originalContainer .feet.width-ft`),
                Value: parseInt(document.querySelector(`#originalContainer .feet.width-ft`).value),
            },
            WidthInches: {
                Element: document.querySelector(`#originalContainer .inches.width-in`),
                Value: parseInt(document.querySelector(`.inches.width-in`).value)
            },
            LengthFeet: {
                Element: document.querySelector(`#originalContainer .feet.length-ft`),
                Value: parseInt(document.querySelector(`#originalContainer .feet.length-ft`).value),
            },
            LengthInches: {
                Element: document.querySelector(`#originalContainer .inches.length-in`),
                Value: parseInt(document.querySelector(`#originalContainer .inches.length-in`).value)
            }
        };
        // Calculate total inches (inches + feet*12) 
        const originalTotalWidthInches = originalAreaMeasurements.WidthInches.Value + (originalAreaMeasurements.WidthFeet.Value * 12);
        const originalTotalLengthInches = originalAreaMeasurements.LengthInches.Value + (originalAreaMeasurements.LengthFeet.Value * 12);
        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const originalAreaInches = originalTotalWidthInches * originalTotalLengthInches;
        const originalAreaSqFeet = Math.floor(originalAreaInches / 144);
        const originalMaxStudents = Math.floor(originalAreaSqFeet / 28);
        // Update UI
        const originalAreaEle = document.querySelector(`#originalContainer .specialEducationFacilitiesAreaMeasurement`);
        originalAreaEle.value = `${originalAreaSqFeet}sqft`;
        const originalMaxStudentsEle = document.querySelector(`#originalContainer .specialEducationFacilitiesMaxStudents`);
        originalMaxStudentsEle.value = `${originalMaxStudents}`;
        // Get measurement values from the inputs
        const newAreaMeasurements = {
            WidthFeet: {
                Element: document.querySelector(`#newContainer .feet.width-ft`),
                Value: parseInt(document.querySelector(`#newContainer .feet.width-ft`).value),
            },
            WidthInches: {
                Element: document.querySelector(`#newContainer .inches.width-in`),
                Value: parseInt(document.querySelector(`.inches.width-in`).value)
            },
            LengthFeet: {
                Element: document.querySelector(`#newContainer .feet.length-ft`),
                Value: parseInt(document.querySelector(`#newContainer .feet.length-ft`).value),
            },
            LengthInches: {
                Element: document.querySelector(`#newContainer .inches.length-in`),
                Value: parseInt(document.querySelector(`#newContainer .inches.length-in`).value)
            }
        };
        // Calculate total inches (inches + feet*12) 
        const newTotalWidthInches = newAreaMeasurements.WidthInches.Value + (newAreaMeasurements.WidthFeet.Value * 12);
        const newTotalLengthInches = newAreaMeasurements.LengthInches.Value + (newAreaMeasurements.LengthFeet.Value * 12);
        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const newAreaInches = newTotalWidthInches * newTotalLengthInches;
        const newAreaSqFeet = Math.floor(newAreaInches / 144);
        const newMaxStudents = Math.floor(newAreaSqFeet / 28);
        // Update UI
        const newAreaEle = document.querySelector(`#newContainer .specialEducationFacilitiesAreaMeasurement`);
        newAreaEle.value = `${newAreaSqFeet}sqft`;
        const newMaxStudentsEle = document.querySelector(`#newContainer .specialEducationFacilitiesMaxStudents`);
        newMaxStudentsEle.value = `${newMaxStudents}`;
    }
    highlightChangedFieldsSEPRN() {
        let originalFields = document.querySelectorAll('#originalContainer .specialEducationSpecialEducationFacilitiesField[data-propertypk]');
        for (let field of originalFields) {
            let fieldElement = field;
            let usedRadios = [];
            if (fieldElement instanceof HTMLInputElement) {
                let correspondingFieldElement = document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if (correspondingFieldElement !== null) {
                    if (fieldElement.hasAttribute("type") && fieldElement.type == "radio" && usedRadios.indexOf(fieldElement.name) == -1) {
                        usedRadios.push(fieldElement.name);
                        //Get checked value of original and compare to new
                        let checkedOriginal = document.querySelector(`[name='${fieldElement.name}']:checked`);
                        let checkedNew = document.querySelector(`[name='${correspondingFieldElement.name}']:checked`);
                        if (checkedOriginal != null && checkedNew != null && checkedOriginal.value != checkedNew.value) {
                            let trContainer = Core.findClosest(checkedNew, "tr");
                            if (trContainer != null) {
                                trContainer.classList.add("different");
                            }
                        }
                    }
                    else {
                        if (fieldElement.value != correspondingFieldElement.value) {
                            correspondingFieldElement.classList.add("different");
                        }
                    }
                }
            }
            else if (fieldElement instanceof HTMLSelectElement) {
                let correspondingFieldElement = document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if (correspondingFieldElement !== null && fieldElement.value != correspondingFieldElement.value) {
                    correspondingFieldElement.classList.add("different");
                }
            }
        }
        //Files
        let originalFile = document.querySelector('#originalContainer .uploadFileColumn[data-fileupload-pk]');
        let newFile = document.querySelector('#newContainer .uploadFileColumn[data-fileupload-pk]');
        if ((originalFile != null && newFile != null && originalFile.dataset.fileuploadPk != newFile.dataset.fileuploadPk) || (originalFile == null && newFile != null)) {
            let fileContainer = newFile.querySelector('.uploaded-file-container');
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        }
        else if (originalFile != null && newFile == null) {
            let fileContainer = originalFile.querySelector('.uploaded-file-container');
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        }
    }
    static constructFileUploadElements() {
        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = c;
            const facilityPK = container.dataset.facilityPk;
            const fileUploader = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityPK}']`);
            if (fileUploader !== null) {
                fileUploader.addEventListener('change', (e) => SpecialEducationSpecialEducationFacilities.uploadFile(e.target, facilityPK));
            }
        }
    }
    static constructFileDeleteElements(facilityFK = null) {
        if (facilityFK) {
            const deleteButton = document.querySelector(`.deleteFile[data-facility-pk='${facilityFK}']`);
            if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                deleteButton.addEventListener('click', (e) => this.showDeleteFileConfirmation(deleteButton));
            }
        }
        else {
            const deleteFileButtons = document.getElementsByClassName('deleteFile');
            for (let deleteButton of deleteFileButtons) {
                if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                    deleteButton.addEventListener('click', (e) => this.showDeleteFileConfirmation(deleteButton));
                }
            }
        }
        const deleteFileConfirmButton = document.getElementById('deleteFileConfirm');
        if (deleteFileConfirmButton !== null) {
            deleteFileConfirmButton.addEventListener('click', (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        }
        const deleteFileCancelButton = document.getElementById('deleteFileCancel');
        if (deleteFileCancelButton !== null) {
            deleteFileCancelButton.addEventListener('click', (e) => this.deleteFileCancel());
        }
    }
    addLocation() {
        const frcppInstitutionSelect = document.getElementById('specialEducationFacilitiesNewBuildingName');
        const frcppInstitution = frcppInstitutionSelect[frcppInstitutionSelect.selectedIndex].dataset.lookupCode;
        const roomDescInput = document.getElementById('specialEducationFacilitiesRoomNewNumber');
        const roomDesc = roomDescInput.value;
        return new Promise((resolve, reject) => {
            const data = {
                SpecialEducationFacilityPK: 0,
                PlanFK: this.planFK,
                FRCPPInstitutionFK: parseInt(frcppInstitution),
                RoomDesc: roomDesc
            };
            if (roomDescInput != null && roomDesc != '' && frcppInstitutionSelect != null && frcppInstitutionSelect.selectedIndex != 0) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/CreateNewFacility', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const facilityList = document.getElementById('facilitiesContainerInner');
                        if (facilityList != null) {
                            $(facilityList).prepend(xhr.responseText);
                            const newFacilityAccordion = facilityList.querySelector('.lazy-accordion-trigger');
                            if (newFacilityAccordion != null) {
                                new SpecialEducationFacilitiesLazyAccordion(newFacilityAccordion.id);
                                SpecialEducationSpecialEducationFacilities.bindSaveFacilities();
                            }
                            resolve('Successfully created new facility.');
                        }
                    }
                    else {
                        reject('There was an unexpected error creating a new facility, please try again.');
                    }
                };
                xhr.send(JSON.stringify(data));
            }
            else {
                reject('Both a Building and a Room must be specified to add a location.');
            }
        });
    }
    static autofillInputs(facilityFK, onPageLoad = false) {
        // Get measurement values from the inputs
        const areaMeasurements = {
            WidthFeet: {
                Element: document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`).value),
            },
            WidthInches: {
                Element: document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`).value)
            },
            LengthFeet: {
                Element: document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`).value),
            },
            LengthInches: {
                Element: document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`).value)
            }
        };
        if (!onPageLoad) {
            // Validate: user has entered a number >= 0, and if it is an inches measurement, 12 > number >= 0
            for (let am in areaMeasurements) {
                const measurement = areaMeasurements[am];
                if (measurement.Value === '' || measurement.Value === null || isNaN(measurement.Value) || parseInt(measurement.Value) < 0) {
                    measurement.Element.value = 0;
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a number greater than or equal to 0.', 'error', 3000, null);
                    return;
                }
                else if ((am === 'WidthInches' || am === 'LengthInches') && parseInt(measurement.Value) > 11) {
                    measurement.Element.value = 0;
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter an inches value between 0 and 11.', 'error', 3000, null);
                }
            }
        }
        // Calculate total inches (inches + feet*12) 
        const totalWidthInches = areaMeasurements.WidthInches.Value + (areaMeasurements.WidthFeet.Value * 12);
        const totalLengthInches = areaMeasurements.LengthInches.Value + (areaMeasurements.LengthFeet.Value * 12);
        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const areaInches = totalWidthInches * totalLengthInches;
        const areaSqFeet = Math.floor(areaInches / 144);
        const maxStudents = Math.floor(areaSqFeet / 28);
        // Update UI
        const areaEle = document.querySelector(`.specialEducationFacilitiesAreaMeasurement[data-facility-pk='${facilityFK}']`);
        areaEle.value = `${areaSqFeet}sqft`;
        const maxStudentsEle = document.querySelector(`.specialEducationFacilitiesMaxStudents[data-facility-pk='${facilityFK}']`);
        maxStudentsEle.value = `${maxStudents}`;
    }
    collapseAllFacilities() {
        const facilitiesContainer = document.getElementById('facilitiesContainerInner');
        if (facilitiesContainer != null) {
            const openToggles = facilitiesContainer.querySelectorAll('.lazy-accordion-trigger.open');
            for (let toggle of openToggles) {
                const toggleElement = toggle;
                toggleElement.click();
            }
        }
    }
    expandAllFacilities() {
        const facilitiesContainer = document.getElementById('facilitiesContainerInner');
        if (facilitiesContainer != null) {
            const closedToggles = facilitiesContainer.querySelectorAll('.lazy-accordion-trigger:not(.open)');
            for (let toggle of closedToggles) {
                const toggleElement = toggle;
                toggleElement.click();
            }
        }
    }
    static search(fromHistory = false, fromClear = false) {
        const frcppInstitutionSelect = document.getElementById('specialEducationFacilitiesBuildingName');
        const frcppInstitutionFK = frcppInstitutionSelect != null && frcppInstitutionSelect.selectedIndex > 0
            ? parseInt(frcppInstitutionSelect[frcppInstitutionSelect.selectedIndex].dataset.lookupCode)
            : null;
        const roomDescInput = document.getElementById('specialEducationFacilitiesRoomNumber');
        const roomDesc = roomDescInput != null && roomDescInput.value != ''
            ? roomDescInput.value
            : null;
        const planFormElement = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        const planFK = planFormElement.dataset.planfk;
        let query = {
            PlanFK: planFK,
            FRCPPInstitutionFK: frcppInstitutionFK,
            RoomDesc: roomDesc
        };
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/FacilitiesSearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const container = document.getElementById('facilitiesContainer');
                    if (container) {
                        container.innerHTML = xhr.responseText;
                        SpecialEducationSpecialEducationFacilities.fullPageValidation();
                        resolve();
                    }
                    else {
                        reject("There was an error loading the facilities, please try again.");
                    }
                }
                else {
                    reject("There was an error loading the facilities, please try again.");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }
    static clearSearch() {
        const frcppInstitutionSelect = document.getElementById("specialEducationFacilitiesBuildingName");
        const roomDescInput = document.getElementById("specialEducationFacilitiesRoomNumber");
        const planFormElement = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        const planFK = planFormElement.dataset.planFK;
        if (frcppInstitutionSelect != null) {
            frcppInstitutionSelect.selectedIndex = 0;
        }
        if (roomDescInput != null) {
            roomDescInput.value = '';
        }
        Core.showLoader();
        this.promiseSave()
            .then(() => {
            return this.search(true, true);
        })
            .then(() => {
            //reinitialize accordions
            new SpecialEducationFacilitiesLazyAccordion(null);
            Core.hideLoader();
            SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
            SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
            SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
        })
            .catch((error) => {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    static uploadFile(e, facilityFK) {
        Core.showLoader();
        const uploadFeaturesForm = document.querySelector(`.specialEducationFacilitiesUploadFeatures[data-facility-pk='${facilityFK}']`);
        const formData = new FormData(uploadFeaturesForm);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFacilitiesFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        const formFile = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityFK}']`);
                        formFile.value = '';
                        formFile.dataset.hasuploaded = 'true';
                        const fileUpload = response.fileUpload;
                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/SpecialEducation/GetFacilityFileUploadPartialView?fileUploadPK=${fileUpload.fileUploadPK}&fileContentType=${fileUpload.fileContentType}&fileName=${fileUpload.fileName}&specialEducationFacilityPK=${fileUpload.specialEducationFacilityPK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`);
                                fileUploadDiv.innerHTML = xhrForPartialView.response;
                                SpecialEducationSpecialEducationFacilities.constructFileDeleteElements(facilityFK);
                            }
                        };
                        xhrForPartialView.send();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    static showDeleteFileConfirmation(deleteButton) {
        const fileUploadPK = deleteButton.dataset.fileuploadPk;
        const facilityPK = deleteButton.dataset.facilityPk;
        if (fileUploadPK && parseInt(fileUploadPK) > 0) {
            const modal = new Modal('deleteFileModal', null);
            modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "facilitypk", facilityPK);
            modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "fileuploadpk", fileUploadPK);
            modal.show();
        }
    }
    static deleteFileConfirm(confirmButton) {
        const facilityFK = parseInt(confirmButton.dataset.facilityPk);
        const containerElement = document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`);
        if (containerElement !== null) {
            containerElement.removeChild(containerElement.firstChild);
        }
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    static deleteFileCancel() {
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    showDeleteFacilityConfirmation(deleteButton) {
        const facilityPK = deleteButton.dataset.facilityPk;
        const modal = new Modal('deleteFacilityModal', null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFacilityConfirm", "facilitypk", facilityPK);
        const id = document.getElementById("deleteFacilityConfirm");
        id.dataset.facilitypk = facilityPK;
        modal.show();
    }
    deleteFacilityCancel() {
        const modal = new Modal('deleteFacilityModal', null);
        modal.hide();
    }
    customValidation(facilityPK = null) {
        let hasInputErrors = false;
        let hasSelectErrors = false;
        let errorElements = [];
        let totalErrors = 0;
        let showMessage = false;
        let parent = document;
        if (facilityPK != null) {
            let facility = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
            if (facility != null) {
                parent = facility;
            }
        }
        const roomDescInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.room-desc');
        for (let i of roomDescInputs) {
            const input = i;
            if (!input.value) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            }
            else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }
        const footInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.feet');
        for (let i of footInputs) {
            const input = i;
            var inputValue = parseInt(input.value);
            if (inputValue === 0) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            }
            else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }
        const requriedSelects = parent.querySelectorAll('select.specialEducationSpecialEducationFacilitiesField.required-facility-field');
        for (let s of requriedSelects) {
            const select = s;
            if (select.value === '0') {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
                select.classList.add('missing-field');
                select.setAttribute('aria-invalid', 'true');
                hasSelectErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: select, type: 'select' });
            }
            else {
                select.classList.remove('missing-field');
                select.removeAttribute('aria-invalid');
            }
        }
        for (let element of errorElements) {
            const accordion = element.element.closest('.Accordion-panel');
            accordion.classList.add('open');
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    firstFocusableEl.focus();
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
        return !(hasInputErrors || hasSelectErrors);
    }
    static validateElements(facilityPK = null) {
        let hasInputErrors = false;
        let hasSelectErrors = false;
        let errorElements = [];
        let parent = document;
        if (facilityPK != null) {
            let facility = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
            if (facility != null) {
                parent = facility;
            }
        }
        const roomDescInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.room-desc');
        for (let i of roomDescInputs) {
            const input = i;
            if (!input.value) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                errorElements.push({ element: input, type: 'input' });
            }
            else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }
        const requriedSelects = parent.querySelectorAll('select.specialEducationSpecialEducationFacilitiesField.required-facility-field');
        for (let s of requriedSelects) {
            const select = s;
            if (select.value === '0') {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
                select.classList.add('missing-field');
                select.setAttribute('aria-invalid', 'true');
                hasSelectErrors = true;
                errorElements.push({ element: select, type: 'select' });
            }
            else {
                select.classList.remove('missing-field');
                select.removeAttribute('aria-invalid');
            }
        }
        if (hasInputErrors || hasSelectErrors) {
            for (let element of errorElements) {
                const accordion = Core.findClosest(element, '.lazyAccordionTrigger');
                if (accordion) {
                    accordion.classList.add('open');
                }
            }
            const firstErrorElement = errorElements[0];
            if (firstErrorElement.type === 'input') {
                Core.createHTMLAlert('alertMessageDiv', 'Please enter a room number', 'error', 3000, null);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
            }
            firstErrorElement.element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        return !(hasInputErrors || hasSelectErrors);
    }
    static promiseSave(specialEducationFacilityPK = null, forceSave = false, onWindowUnload = false, forSEPRN = false, fromBackOrContinue = false) {
        const formElement = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
        const planFK = parseInt(formElement.dataset.planfk);
        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData = [];
            let facilitiesToSave = [];
            if (fromBackOrContinue) {
                const listOfLoadedFacilities = document.querySelectorAll(`.lazyAccordionTrigger[data-loaded='true']`);
                for (const openFacility of listOfLoadedFacilities) {
                    const loadedFacility = openFacility;
                    const facilityFK = parseInt(loadedFacility.dataset.specialeducationfacilityfk);
                    if (this.facilityHasChanged(facilityFK)) {
                        const theseFacilities = document.querySelectorAll(`.facilityContainer[data-seprn-comparison="false"][data-facility-pk='${facilityFK}']`);
                        for (const fac of theseFacilities)
                            facilitiesToSave.push(fac);
                    }
                }
            }
            else if (specialEducationFacilityPK !== null) {
                const theseFacilities = document.querySelectorAll(`.facilityContainer[data-seprn-comparison="false"][data-facility-pk='${specialEducationFacilityPK}']`);
                for (const fac of theseFacilities)
                    facilitiesToSave.push(fac);
            }
            for (let facilityToSave of facilitiesToSave) {
                const facility = facilityToSave;
                let assuranceChecksData = [];
                if ('facilityPk' in facility.dataset) {
                    shouldSave = true;
                    let facilityPK = facility.dataset.facilityPk;
                    // Get FRCPPInstitutionFK
                    let frcppInstitutionFK = null;
                    let instition = facility.querySelector(`.frcpp-institution[data-facility-pk='${facilityPK}']`);
                    if (instition != null && instition.value != '') {
                        frcppInstitutionFK = instition[instition.selectedIndex].dataset.lookupCode;
                    }
                    // Get RoomDesc
                    let roomDesc = null;
                    let room = facility.querySelector(`.room-desc[data-facility-pk='${facilityPK}']`);
                    if (room != null && room.value != '') {
                        roomDesc = room.value;
                    }
                    else if (onWindowUnload) {
                        roomDesc = 0;
                    }
                    // Get SchoolBuildingLookupCodeFK
                    let schoolBuildingLookupCodeFK = null;
                    let schoolBuilding = facility.querySelector(`.school-building[data-facility-pk='${facilityPK}']`);
                    if (schoolBuilding != null && schoolBuilding.value != '') {
                        schoolBuildingLookupCodeFK = schoolBuilding[schoolBuilding.selectedIndex].dataset.lookupCode;
                    }
                    // Get BuildingDescriptionLookupCodeFK
                    let buildingDescriptionLookupCodeFK = null;
                    let buildingDescription = facility.querySelector(`.building-description[data-facility-pk='${facilityPK}']`);
                    if (buildingDescription != null && buildingDescription.value != '') {
                        buildingDescriptionLookupCodeFK = buildingDescription[buildingDescription.selectedIndex].dataset.lookupCode;
                    }
                    // Get ClassroomWidth
                    let classroomWidth = null;
                    let widthFt = facility.querySelector(`.width-ft[data-facility-pk='${facilityPK}']`);
                    let widthIn = facility.querySelector(`.width-in[data-facility-pk='${facilityPK}']`);
                    if (widthFt != null && widthFt.value != '' && widthIn != null && widthIn.value != '') {
                        let width = parseInt(widthIn.value) + (parseInt(widthFt.value) * 12);
                        classroomWidth = width;
                    }
                    // Get Classroomlength
                    let classroomLength = null;
                    let lengthFt = facility.querySelector(`.length-ft[data-facility-pk='${facilityPK}']`);
                    let lengthIn = facility.querySelector(`.length-in[data-facility-pk='${facilityPK}']`);
                    if (lengthFt != null && lengthFt.value != '' && lengthIn != null && lengthIn.value != '') {
                        let length = parseInt(lengthIn.value) + (parseInt(lengthFt.value) * 12);
                        classroomLength = length;
                    }
                    // Get FileUploadFK
                    let fileUploadFK = null;
                    let file = facility.querySelector(`button.delete-row.deleteFile[data-facility-pk='${facilityPK}']`);
                    if (file != null) {
                        fileUploadFK = file.dataset.fileuploadPk;
                    }
                    // Get ImplementationDate
                    let implementationDate = null;
                    let date = facility.querySelector(`.implementation-date[data-facility-pk='${facilityPK}']`);
                    if (date != null && date.value != '') {
                        implementationDate = date.value;
                    }
                    // Get assurance checks
                    let assuranceChecks = [];
                    let assuranceChecksForFacility = facility.querySelectorAll(`.assurance-check-row[data-facility-pk='${facilityPK}']`);
                    for (let assuranceCheck of assuranceChecksForFacility) {
                        let assuranceCheckElement = assuranceCheck;
                        if ('facilityPk' in assuranceCheckElement.dataset) {
                            // Get assurance PK
                            let specialEducationFacilityAssuranceCheckPK = null;
                            let assurancePK = assuranceCheckElement.dataset.assurancePk;
                            if (assurancePK != null && assurancePK != '') {
                                specialEducationFacilityAssuranceCheckPK = assurancePK;
                            }
                            // Get PropertyFK
                            let propertyFK = null;
                            let property = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']`);
                            propertyFK = property.dataset.propertypk;
                            // Get LookupCodeFK (Yes/No)
                            let lookupCodeFK = null;
                            let lookupCode = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']:checked`);
                            lookupCodeFK = lookupCode.value;
                            const facilityAssuranceCheckSaveData = {
                                SpecialEducationFacilityAssuranceCheckPK: parseInt(specialEducationFacilityAssuranceCheckPK),
                                SpecialEducationFacilityFK: parseInt(facilityPK),
                                PropertyFK: parseInt(propertyFK),
                                LookupCodeFK: parseInt(lookupCodeFK)
                            };
                            assuranceChecks.push(facilityAssuranceCheckSaveData);
                        }
                    }
                    const facilitiesSaveData = {
                        SpecialEducationFacilityPK: parseInt(facilityPK),
                        FRCPPInstitutionFK: frcppInstitutionFK,
                        RoomDesc: roomDesc,
                        SchoolBuildingLookupCodeFK: schoolBuildingLookupCodeFK,
                        BuildingDescriptionLookupCodeFK: buildingDescriptionLookupCodeFK,
                        ClassroomWidth: classroomWidth,
                        ClassroomLength: classroomLength,
                        FileUploadFK: fileUploadFK,
                        ImplementationDate: implementationDate,
                        PlanFK: planFK
                    };
                    const thisFacilitiesSaveData = {
                        Facility: facilitiesSaveData,
                        AssuranceChecks: assuranceChecks
                    };
                    saveData.push(thisFacilitiesSaveData);
                }
            }
            if (shouldSave || forceSave) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationFacilities', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        if (specialEducationFacilityPK != null) {
                            const response = JSON.parse(xhr.responseText);
                            const accordionButton = document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${specialEducationFacilityPK}']`);
                            if (accordionButton)
                                accordionButton.dataset.validdata = response.isValid.toString().toLowerCase();
                            resolve('Successfully saved Facility');
                        }
                        else {
                            resolve('Successfully saved');
                        }
                    }
                    else {
                        reject('There was an unexpected error saving');
                    }
                };
                xhr.send(JSON.stringify({
                    'Data': saveData,
                    'PlanFK': planFK,
                    'CanSaveSEPRN': forSEPRN
                }));
            }
            else {
                resolve('Nothing To Save');
            }
        });
    }
    save(referrer, facilityPK = null) {
        let refreshPage = '';
        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url');
        }
        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        }
        else {
            refreshPage = referrer;
        }
        Core.showLoader();
        SpecialEducationSpecialEducationFacilities.promiseSave(null, true, false, false, true).then((response) => {
            Core.hideLoader();
            if (referrer === 'save') {
                //Reset query so that all results show
                window.location.href = '/SpecialEducation/SpecialEducationSpecialEducationFacilities/' + this.planFK + '?fromSave=true';
            }
            else if (refreshPage && refreshPage !== '') {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
            Core.hideLoader();
        });
    }
    static createSEPRN(facilityPK) {
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        let proposedActionValue = "";
        let additionalInformationValue = "";
        let proposedActionElement = document.querySelector(`.proposedAction[data-facility-pk='${facilityPK}']`);
        if (proposedActionElement != null) {
            proposedActionValue = proposedActionElement.value;
        }
        let additionalInformationElement = document.querySelector(`.additionalInformation[data-facility-pk='${facilityPK}']`);
        if (additionalInformationElement != null) {
            additionalInformationValue = additionalInformationElement.value;
        }
        if (proposedActionElement.value != "") {
            let selected = proposedActionElement[proposedActionElement.selectedIndex];
            if ((selected.dataset.lookupCode == "proposedActionDelete" || selected.dataset.lookupCode == "proposedActionChange") && additionalInformationValue == "") {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'Please specify a reason/explanation for this SEPRN', 'error', 3000, null);
            }
            else if (selected.dataset.lookupCode == "proposedActionNew" && !this.validateElements(facilityPK)) {
                Core.hideLoader();
            }
            else {
                fetch('/SpecialEducation/CreateSEPRN', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        FacilityPK: facilityPK,
                        ProposedAction: proposedActionValue,
                        AdditionalInformation: additionalInformationValue
                    })
                })
                    .then((response) => {
                    if (response.ok) {
                        return this.promiseSave(facilityPK, false, false, true);
                    }
                    else {
                        throw new Error('Unsuccesfully created SEPRN');
                    }
                })
                    .then((response) => {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully submitted SEPRN', 'success', 3000, window.location.reload());
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue submitting SEPRN', 'error', 3000, null);
                    Core.hideLoader();
                });
            }
        }
        else {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a proposed action for the SEPRN', 'error', 3000, null);
            Core.hideLoader();
        }
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationFacilitiesExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationFacilties.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    static bindSaveFacilities() {
        const facilitiesSave = document.getElementsByClassName("facilitiesSave");
        for (const facility of facilitiesSave)
            facility.addEventListener("click", (e) => SpecialEducationSpecialEducationFacilities.saveFacility(e.target));
    }
    static saveFacility(saveButton) {
        const facilityFK = parseInt(saveButton.dataset.facilitypk);
        let needsSave = false;
        if (this.facilityHasChanged(facilityFK)) {
            this.showFacilityLoader(facilityFK);
            SpecialEducationSpecialEducationFacilities.promiseSave(facilityFK, true).then((response) => __awaiter(this, void 0, void 0, function* () {
                this.hideFacilityLoader(facilityFK);
                Core.createHTMLAlert('alertMessageDiv', "Facility saved successfully.", 'success', 3000, null);
                //rebuild the hash. We don't want to be able to save this facility again without changes.
                this.addFacilityHash(facilityFK, true);
                SpecialEducationSpecialEducationFacilities.validateElements(facilityFK);
                yield SpecialEducationSpecialEducationFacilities.getValidFacilityCount();
                SpecialEducationSpecialEducationFacilities.fullPageValidation();
                SpecialEducationSpecialEducationFacilities.planPageCompleteCheck();
            })).catch((error) => {
                Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
                this.hideFacilityLoader(facilityFK);
            });
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "No changes to save.", "warning", 3000, null);
        }
    }
    static facilityHasChanged(facilityFK) {
        let needsSave = false;
        //If the hash doesn't exist, it's likely a new school record, so it will need to be saved.
        if (!SpecialEducationSpecialEducationFacilities.facilityHashes[facilityFK]) {
            needsSave = true;
        }
        else {
            const newHashValue = SpecialEducationSpecialEducationFacilities.calculateFacilityHash(facilityFK);
            if (SpecialEducationSpecialEducationFacilities.facilityHashes[facilityFK] !== newHashValue) {
                needsSave = true;
            }
        }
        return needsSave;
    }
    static calculateFacilityHash(facilityFK) {
        const that = this;
        let hash = "0";
        let value;
        let newHash;
        let allElements = document.querySelectorAll(`[data-hashable][data-facilityfk='${facilityFK}']`);
        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                const inputElement = element;
                if (inputElement.type === "radio") {
                    if (inputElement.checked) {
                        value = inputElement.value;
                        newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
                else {
                    if (inputElement.value === "") {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
            }
            else if (element instanceof HTMLSelectElement) {
                const selectElement = element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value;
                    newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLTextAreaElement) {
                const textAreaElement = element;
                if (textAreaElement.value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLAnchorElement) {
                const anchorElement = element;
                value = anchorElement.dataset.fileuploadPk;
                newHash = SpecialEducationSpecialEducationFacilities._staticCore.hashCode(value);
                hash = hash + newHash;
            }
        }
        return hash;
    }
    static initializeFacilityHashes() {
        this.facilityHashes = {};
    }
    //add the facility hash the array. If the facility already exists, don't add or update the hash UNLESS rebuild is true (in this case, it's)
    //likely coming from a save, so a new hash should be generated.
    static addFacilityHash(facilityFK, rebuild = false) {
        if (facilityFK > 0) {
            if (!this.facilityHashes[facilityFK] || rebuild) {
                this.facilityHashes[facilityFK] = this.calculateFacilityHash(facilityFK);
            }
        }
    }
    static showFacilityLoader(facilityFK) {
        const savingContainer = document.querySelector(`.special-education-saving[data-facilityfk='${facilityFK}']`);
        const saverContainer = document.querySelector(`.special-education-saver[data-facilityfk='${facilityFK}']`);
        savingContainer.classList.add("save");
        saverContainer.classList.add("save");
    }
    static hideFacilityLoader(facilityFK) {
        const savingContainer = document.querySelector(`.special-education-saving[data-facilityfk='${facilityFK}']`);
        const saverContainer = document.querySelector(`.special-education-saver[data-facilityfk='${facilityFK}']`);
        savingContainer.classList.remove("save");
        saverContainer.classList.remove("save");
    }
    static fullPageValidation(fromPageLoad = false) {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownInvalidAccordions = document.querySelectorAll(".lazyAccordionTrigger[data-validdata='false']");
        const totalErrorsNotShown = totalErrors - shownInvalidAccordions.length;
        let errorCount = 0;
        for (const accordion of allAccordions) {
            const accElement = accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;
            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            }
            else {
                accElement.classList.remove("error");
            }
        }
        const messageContainer = document.getElementById("validationMessageContainer");
        const validationIcon = document.getElementById("validationMessageIcon");
        const messageContainerColumn = document.getElementById("validationColumn");
        if (!fromPageLoad || errorCount > 0 || totalErrorsNotShown > 0) {
            messageContainerColumn.classList.add("show");
        }
        const message = document.getElementById("validationMessage");
        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");
        if (errorCount > 0) {
            let word = "are";
            let facility = "facilities";
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "facility";
            }
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = '';
            if (!fromPageLoad) {
                message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            }
            let facilityWord = "are";
            let facilityFacility = "facilities";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "facility";
            }
            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            if (message !== null) {
                message.innerHTML = "The page has been successfully saved.";
                message.classList.add("show");
            }
        }
    }
    static getValidFacilityCount() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                const planForm = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
                const planFK = planForm.dataset.planfk;
                const response = yield fetch(`/SpecialEducation/GetValidFacilitiesCount/${planFK}`, { credentials: 'include' });
                if (response.ok) {
                    const value = yield response.text();
                    const field = document.getElementById("validCount");
                    field.value = value;
                    resolve("Valid facility count updated");
                }
                else {
                    reject("Error updating facility count");
                }
            }));
        });
    }
    static facilityValidation(specialEducationFacilityFK) {
        const elementsInsideAccordion = document.querySelectorAll(`.specialEducationFacilityAccordionField[data-facility-pk='${specialEducationFacilityFK}']`);
        for (const elementInside of elementsInsideAccordion) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                    if (inputElement.hasAttribute("name")) {
                        const radioName = inputElement.getAttribute("name");
                        const radioButtons = document.getElementsByName(radioName);
                        let isChecked = false;
                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                            let radioElement = radioButtons[i];
                            if (radioElement.checked) {
                                isChecked = true;
                            }
                        }
                        if (!isChecked) {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                        }
                        else {
                            inputElement.classList.remove("missing-field");
                            inputElement.setAttribute("aria-invalid", "false");
                            Core.removeErrorLabelForInput(inputElement);
                        }
                    }
                }
                else {
                    if (inputElement.dataset.percent === "1.00") {
                        if (inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                        }
                        else {
                            inputElement.classList.remove("missing-field");
                            inputElement.setAttribute("aria-invalid", "false");
                            Core.removeErrorLabelForInput(inputElement);
                        }
                    }
                }
            }
        }
    }
    static initializeLoadedFacility(specialEducationFacilityFK) {
        this._staticCore.initializeRequiredFields(this.staticValidationClasses, false, true);
    }
    static clearValidationMessage() {
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }
    static planPageCompleteCheck() {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById("specialEducationSpecialEducationFacilitiesForm");
            const planFK = form.dataset.planfk;
            const pageCode = form.dataset.pagecode;
            const response = yield fetch(`/SpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' });
            if (response.ok) {
                const value = yield response.json();
                const menuElement = document.querySelector("#leftBarspecialeducationfacilitiesParent .status-indicator");
                const parentMenuElement = document.querySelector("#leftBarspecialeducationprogramprofileParent .status-indicator");
                if (value.thisPage) {
                    menuElement.classList.add("complete");
                }
                else {
                    menuElement.classList.remove("complete");
                }
                if (value.parentPage) {
                    parentMenuElement.classList.add("complete");
                }
                else {
                    parentMenuElement.classList.remove("complete");
                }
            }
        });
    }
}
// Special Education Transfer of Entity
class SpecialEducationFTETransferOfEntity {
    constructor() {
        const that = this;
        this._core = new Core();
        SpecialEducationFTETransferOfEntity._staticCore = new Core();
        const form = document.getElementById("specialEducationEducationProgramFTETransferOfEntityForm");
        if (form != null) {
            that.planFK = parseInt(form.dataset.planfk);
            that.templateFK = parseInt(form.dataset.templatefk);
            SpecialEducationFTETransferOfEntity._staticPlanFK = parseInt(form.dataset.planfk);
        }
        const canCreateElement = document.getElementById("canCreate");
        if (canCreateElement !== null)
            if (canCreateElement.value === "true")
                that.canCreate = true;
            else
                that.canCreate = false;
        else
            that.canCreate == false;
        const hdnAllowExceedsFTE = document.getElementById("hdnAllowExceedsFTE");
        if (hdnAllowExceedsFTE != null && hdnAllowExceedsFTE.value === "True") {
            that.allowExceedsFTE = true;
        }
        else {
            that.allowExceedsFTE = false;
        }
        if (this.canCreate) {
            new SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion();
            that.deleteSupportConfirmModal = new Modal("deleteSupportConfirmModal", null);
            that.deleteFTEConfirmModal = new Modal("deleteFTEConfirmModal", null);
            this.validationClasses = [
                "fteID",
                "classroomLocationFTE",
                "buildingName",
                "supportTypeGroup",
                "supportType",
                "supportLevel",
                "caseLoad",
                "identifyClassroom",
                "classroomLocationSupport",
                "ageRangeFrom",
                "ageRangeEnd",
                "ageRangeJustification",
                "alertedIU",
                "afterMarch",
                "implementationDate",
            ];
            this._core.leftnav(this);
            this._core.tabLinkSave(this);
            this._core.initializeRequiredFields(this.validationClasses);
            SpecialEducationFTETransferOfEntity.initializeFTEHashes();
            that.handleClearDisabled();
            const hidden = this._core.createHash(this.validationClasses);
            const hiddenInput = document.getElementById("hashValue");
            if (hiddenInput !== null) {
                hiddenInput.value = hidden;
            }
            //Event Listener for creating new FTE
            const createNewFTE = document.getElementById("createNewFTETransferOfEntity");
            if (createNewFTE != null) {
                createNewFTE.addEventListener("click", () => {
                    Core.showLoader();
                    that.createNewFTETransferOfEntity()
                        .then((response) => {
                        Core.hideLoader();
                        that._core.initializeRequiredFields(that.validationClasses);
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                });
            }
            //Event listener for deleting a support
            const deleteSupportConfirmButton = document.getElementById("deleteSupportConfirm");
            if (deleteSupportConfirmButton != null) {
                deleteSupportConfirmButton.addEventListener("click", () => {
                    that.deleteSupportConfirmModal.hide();
                    if ("supportPk" in deleteSupportConfirmButton.dataset && "ftetransferofentityPk" in deleteSupportConfirmButton.dataset) {
                        Core.showLoader();
                        that.deleteSupport(parseInt(deleteSupportConfirmButton.dataset.supportPk))
                            .then((response) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                            that.handleFTESaveDisabled(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                            that.checkSupportCount(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                            that.refreshFTEPercentage(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        })
                            .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this support. Please try again later.", 'error', 3000, null);
                    }
                });
            }
            //Event listener for confirming deletion of FTE
            const deleteFTEConfirmButton = document.getElementById("deleteFTEConfirm");
            if (deleteFTEConfirmButton != null) {
                deleteFTEConfirmButton.addEventListener("click", () => {
                    if ("ftetransferofentityPk" in deleteFTEConfirmButton.dataset) {
                        that.deleteFTE(parseInt(deleteFTEConfirmButton.dataset.ftetransferofentityPk));
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE. Please try again later.", 'error', 3000, null);
                    }
                });
            }
            //Event listener for cancelling the delete of a support
            const deleteSupportCancelButton = document.getElementById("deleteSupportCancel");
            if (deleteSupportCancelButton != null) {
                deleteSupportCancelButton.addEventListener("click", () => {
                    that.deleteSupportConfirmModal.hide();
                });
            }
            //Event listener for cancelling the delete of an FTE
            const deleteFTECancelButton = document.getElementById("deleteFTECancel");
            if (deleteFTECancelButton != null) {
                deleteFTECancelButton.addEventListener("click", () => {
                    that.deleteFTEConfirmModal.hide();
                });
            }
            //Event listener for clicking the search button
            const searchButton = document.getElementById("searchButton");
            if (searchButton !== null) {
                //1. Save page (will only save FTE that have changed)
                //2. Run Search
                searchButton.addEventListener("click", () => {
                    Core.showLoader();
                    SpecialEducationFTETransferOfEntity.promiseSave()
                        .then(() => {
                        return that.search();
                    })
                        .then(() => {
                        SpecialEducationFTETransferOfEntity.initializeFTEHashes();
                        that._core.initializeRequiredFields(that.validationClasses);
                        //reinitialize accordions
                        new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                        Core.hideLoader();
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                });
            }
            //Event listener for searching when hitting enter
            const fteIDSearch = document.getElementById("searchFTEId");
            if (fteIDSearch !== null) {
                fteIDSearch.addEventListener("keypress", (e) => {
                    if (e.keyCode === 13) {
                        Core.showLoader();
                        SpecialEducationFTETransferOfEntity.promiseSave()
                            .then(() => {
                            return that.search();
                        })
                            .then(() => {
                            that._core.initializeRequiredFields(that.validationClasses);
                            SpecialEducationFTETransferOfEntity.initializeFTEHashes();
                            //reinitialize accordions
                            new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                            Core.hideLoader();
                        })
                            .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                    }
                });
                fteIDSearch.addEventListener("input", () => {
                    that.handleClearDisabled();
                });
            }
            //Clears the search fields
            const clearButton = document.getElementById("searchClear");
            if (clearButton !== null) {
                clearButton.addEventListener("click", () => {
                    that.clearSearch();
                });
            }
            //When changing building filter, determine whether clear button should be enabled
            const buildingSearch = document.getElementById("searchBuilding");
            const supportTypeSearch = document.getElementById("searchSupportType");
            if (buildingSearch != null) {
                buildingSearch.addEventListener("change", () => {
                    that.handleClearDisabled();
                });
            }
            //When changing support type filter, determine whether clear button should be enabled
            if (supportTypeSearch != null) {
                supportTypeSearch.addEventListener("change", () => {
                    that.handleClearDisabled();
                });
            }
            //When user has searched, focus on search result message
            const searchResultsMessage = document.getElementById("searchResultsMessage");
            if (searchResultsMessage != null) {
                document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
                searchResultsMessage.focus();
            }
            //Event listener for exporting all data to excel
            const exportToExcelButton = document.getElementById("specialEducationTransferOfEntityExportToExcel");
            if (exportToExcelButton != null) {
                exportToExcelButton.addEventListener("click", () => {
                    Core.showLoader();
                    that.exportToExcel()
                        .then((response) => {
                        Core.hideLoader();
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                });
            }
            document.addEventListener("click", (e) => {
                const target = e.target;
                //Adding a support
                if (target.classList.contains("addSupportButton") && "ftetransferofentityPk" in target.dataset) {
                    Core.showLoader();
                    that.createNewSupport(parseInt(target.dataset.ftetransferofentityPk))
                        .then((response) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                        that.checkSupportCount(parseInt(target.dataset.ftetransferofentityPk));
                        that.adjustSupportClassroomLocation(parseInt(target.dataset.ftetransferofentityPk));
                    })
                        .then(() => {
                        that._core.initializeRequiredFields(that.validationClasses);
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
                //Deleting a support
                else if (target.classList.contains("deleteSupport") && "supportPk" in target.dataset && "ftetransferofentityPk" in target.dataset) {
                    that.deleteSupportConfirmModal.show();
                    deleteSupportConfirmButton.dataset.supportPk = target.dataset.supportPk;
                    deleteSupportConfirmButton.dataset.ftetransferofentityPk = target.dataset.ftetransferofentityPk;
                    that.deleteSupportConfirmModal.callingId = target.id;
                }
                //Deleting an FTE
                else if (target.classList.contains("deleteFTE") && "ftetransferofentityPk" in target.dataset) {
                    that.deleteFTEConfirmModal.show();
                    deleteFTEConfirmButton.dataset.ftetransferofentityPk = target.dataset.ftetransferofentityPk;
                    that.deleteFTEConfirmModal.callingId = target.id;
                }
                //Saving an FTE
                else if (target.classList.contains("saveFTEButton") && "ftetransferofentityPk" in target.dataset) {
                    Core.showLoader();
                    SpecialEducationFTETransferOfEntity.promiseSave(parseInt(target.dataset.ftetransferofentityPk))
                        .then((response) => {
                        //Recalculate hash and handle disabling the save button for the FTE
                        SpecialEducationFTETransferOfEntity.fteHashes[parseInt(target.dataset.ftetransferofentityPk)] = SpecialEducationFTETransferOfEntity.calculateFTEHash(parseInt(target.dataset.ftetransferofentityPk));
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
                // Submitting a SEPRN action
                else if (target.classList.contains("createSEPRNButton") && "ftetransferofentityPk" in target.dataset) {
                    SpecialEducationFTETransferOfEntity.createSEPRN(parseInt(target.dataset.ftetransferofentityPk));
                }
                // Delete a file
                else if (target.classList.contains("deleteFile") && "ftetransferofentityPk" in target.dataset) {
                    const fileUploadPK = target.dataset.fileuploadfk;
                    const transferOfEntityPK = target.dataset.ftetransferofentityPk;
                    const modal = new Modal("deleteFileModal", null);
                    modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "fileuploadpk", fileUploadPK);
                    modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "transferofentitypk", transferOfEntityPK);
                    modal.show();
                }
            });
            document.addEventListener("change", (e) => {
                const target = e.target;
                //Handle Support Type Group changing
                if (target.classList.contains("supportTypeGroup") && "supportPk" in target.dataset && "ftetransferofentityPk" in target.dataset) {
                    //1. Refresh the support type list
                    //2. Refresh the support level list (1 needs to happen first)
                    //3. Refresh FTE percent (1 and 2 need to happen first)
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.refreshSupportTypeSubList(parseInt(target.dataset.supportPk))
                        .then(() => {
                        return that.refreshSupportLevels(parseInt(target.dataset.supportPk));
                    })
                        .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        return that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk));
                    })
                        .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                        .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });
                }
                //Handle Support Type changing
                else if (target.classList.contains("supportType") && "supportPk" in target.dataset) {
                    //1. Refresh the support level list
                    //2. Refresh FTE percent (1 needs to happen first)
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.refreshSupportLevels(parseInt(target.dataset.supportPk))
                        .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                        const ftePK = parseInt(target.dataset.ftetransferofentityPk);
                        return that.refreshFTEPercentage(ftePK);
                    })
                        .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                        .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });
                }
                //Handle Support Level changing
                else if (target.classList.contains("supportLevel") && "supportPk" in target.dataset) {
                    //1. Refresh FTE percent
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk))
                        .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                        .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });
                }
                //Handle Classroom Location for FTE changing
                else if (target.classList.contains("classroomLocationFTE") && "ftetransferofentityPk" in target.dataset) {
                    that.adjustSupportClassroomLocation(parseInt(target.dataset.ftetransferofentityPk))
                        .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    });
                }
                //Handle Full-time/Part-time changing
                else if (target.classList.contains("fullTime") && "ftetransferofentityPk" in target.dataset) {
                    //1. Refresh FTE percent
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk))
                        .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                        .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });
                }
                //Handle Classroom Location for Support changing
                else if (target.classList.contains("classroomLocationSupport") && "supportPk" in target.dataset) {
                    that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                        .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    });
                }
                //The radio buttons for the 2 yes/no questions need to be handled separately here, so we're not getting them using the HTMLInputElement type for a catch all
                else if ("hashable" in target.dataset && "ftetransferofentityPk" in target.dataset && (target.classList.contains("alertedIU") || target.classList.contains("afterMarch"))) {
                    const ftePK = parseInt(target.dataset.ftetransferofentityPk);
                    that.handleFTESaveDisabled(ftePK);
                }
                //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
                else if ("hashable" in target.dataset && "ftetransferofentityPk" in target.dataset && !(target instanceof HTMLInputElement) && !(target instanceof HTMLTextAreaElement)) {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                }
                //Handle the proposed action change
                else if (target.classList.contains("proposedAction") && "fteTransferOfEntityPk" in target.dataset) {
                    let proposedActionElement = target;
                    let selectedProposedAction = proposedActionElement.options[proposedActionElement.selectedIndex];
                    if (selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                        //Open up editable fields
                        let allEditable = document.querySelectorAll(`[data-editable][data-ftetransferofentity-pk='${proposedActionElement.dataset.ftetransferofentityPk}']`);
                        for (let editable of allEditable) {
                            editable.removeAttribute("readonly");
                            editable.removeAttribute("disabled");
                        }
                    }
                    else {
                        //disable editable fields
                        let allEditable = document.querySelectorAll(`[data-editable][data-ftetransferofentity-pk='${proposedActionElement.dataset.facilityPk}']:not([data-propertypk='1184'])`);
                        for (let editable of allEditable) {
                            if (editable instanceof HTMLInputElement) {
                                if (editable.hasAttribute("type") && editable.getAttribute("type") == "file" || editable.getAttribute("type") == "radio") {
                                    editable.disabled = true;
                                }
                                else {
                                    editable.readOnly = true;
                                }
                            }
                            else if (editable instanceof HTMLSelectElement || editable instanceof HTMLButtonElement) {
                                editable.disabled = true;
                            }
                        }
                    }
                    var additionalInfo = document.querySelector(`.additionalInformation[data-ftetransferofentity-pk='${proposedActionElement.dataset.facilityPk}']`);
                    if (selectedProposedAction.dataset.lookupCode == "proposedActionDelete" || selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                        if (additionalInfo != null) {
                            that._core.forceElementRequired(additionalInfo);
                        }
                    }
                    else {
                        if (additionalInfo != null) {
                            that._core.forceElementOptional(additionalInfo);
                        }
                    }
                }
                else if (target.classList.contains("educationProgramProfileFileField"))
                    this.uploadFile(e.target);
            });
            document.addEventListener("input", (e) => {
                //On a slight delay so that ajax call isn't made until finished typing
                const target = e.target;
                //Handle Caseload changing
                if (target.classList.contains("caseLoad") && "supportPk" in target.dataset) {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    if (typeof (that.typingTimer) !== 'undefined') {
                        clearTimeout(that.typingTimer);
                    }
                    that.typingTimer = setTimeout(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                        that.refreshFTEPercentage(parseInt(target.dataset.ftetransferofentityPk))
                            .then(() => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        })
                            .catch((error) => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                    }, 500);
                }
                //Handle age Range From for Support changing
                else if (target.classList.contains("ageRangeFrom") && "supportPk" in target.dataset) {
                    that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                        .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    });
                }
                //Handle age Range End for Support changing
                else if (target.classList.contains("ageRangeEnd") && "supportPk" in target.dataset) {
                    that.checkAgeRangeJustification(parseInt(target.dataset.supportPk))
                        .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                    });
                }
                //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
                else if ("hashable" in target.dataset && "ftetransferofentityPk" in target.dataset && (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)) {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftetransferofentityPk));
                }
            });
            //This is called when clicking the back/forward buttons in the browser. This is using the History API
            window.addEventListener('popstate', (e) => {
                const query = e.state;
                const fteID = document.getElementById("searchFTEId");
                const building = document.getElementById("searchBuilding");
                const supportType = document.getElementById("searchSupportType");
                if (fteID != null) {
                    if (query != null && query.FTEIdentifierName != null) {
                        fteID.value = query.FTEIdentifierName;
                    }
                    else {
                        fteID.value = "";
                    }
                }
                if (building != null) {
                    if (query != null && query.FRCPPInstitutionFK != null) {
                        building.value = query.FRCPPInstitutionFK.toString();
                    }
                    else {
                        building.selectedIndex = 0;
                    }
                }
                if (supportType != null) {
                    if (query != null && query.SupportTypeCodePK != null) {
                        supportType.value = query.SupportTypeCodePK.toString();
                    }
                    else {
                        supportType.selectedIndex = 0;
                    }
                }
                that.handleClearDisabled();
                Core.showLoader();
                SpecialEducationFTETransferOfEntity.promiseSave()
                    .then(() => {
                    return that.search(true);
                })
                    .then(() => {
                    SpecialEducationFTETransferOfEntity.initializeFTEHashes();
                    //reinitialize accordions
                    new SpecialEducationProgramCaseloadFTELazyAccordion(null);
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
            SpecialEducationFTETransferOfEntity.fullPageValidation(true);
        }
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    //Top level save method used by back/continue buttons, clicking on link, etc. Calls the promiseSave method and controls redirect.
    save(referrer, ftePK = null) {
        let that = this;
        let core = that._core;
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let forceSave = false;
        if (referrer === "save") {
            forceSave = true;
        }
        SpecialEducationFTETransferOfEntity.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();
            if (referrer === "save") {
                //Reset query so that all results show
                window.location.href = window.location.href.split('?')[0] + '?fromSave=true';
            }
            else if (refreshPage && refreshPage !== "") {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
    }
    //Creates a new FTE record in database and prepends partial view to DOM
    createNewFTETransferOfEntity() {
        const that = this;
        return new Promise((resolve, reject) => {
            const newFTEID = document.getElementById("createNewFTETransferOfEntityId");
            const newClassroomLocation = document.getElementById("createNewClassroomLocation");
            const newFullTime = document.getElementById("createNewFullTime");
            const data = {
                EducationProgramFTETransferOfEntityPK: 0,
                ClassroomLocationLookupCodeFK: parseInt(newClassroomLocation.value),
                FullTimeLookupCodeFK: parseInt(newFullTime.value),
                FTEIdentifierName: newFTEID.value,
                PlanFK: that.planFK,
                AlertedTheIULookupCodeFK: null,
                TransferAfterMarchLookupCodeFK: null,
                ImplementationDate: null,
                TransferOfEntityFormFileUploadFK: null
            };
            const saveData = {
                FTE: data,
                AllowExceedsFTE: that.allowExceedsFTE
            };
            if (newFTEID != null && newFTEID.value != "" && newClassroomLocation != null && newClassroomLocation.selectedIndex != 0 && newFullTime != null && newFullTime.selectedIndex != 0) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/CreateNewFTETransferOfEntity', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const fteList = document.getElementById("fteContainerInner");
                        if (fteList != null) {
                            $(fteList).prepend(xhr.responseText);
                            newFTEID.value = "";
                            newClassroomLocation.selectedIndex = 0;
                            newFullTime.selectedIndex = 0;
                            const newFTEAccordion = fteList.querySelector(".lazyAccordion-trigger");
                            if (newFTEAccordion != null) {
                                new SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion(newFTEAccordion.id);
                            }
                            else {
                                new SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion(null);
                            }
                            resolve("Successfully created new FTE Transfer of Entity");
                        }
                    }
                    else if (xhr.status === 409) {
                        reject("This FTE ID already exists, please use a unique name.");
                    }
                    else {
                        reject("There was an unexpected error creating new FTE Transfer of Entity");
                    }
                };
                xhr.send(JSON.stringify(saveData));
            }
            else {
                reject("All fields must have a value to create a new FTE Transfer of Entity");
            }
        });
    }
    //Creates a new support record in database and appends partial view to DOM
    createNewSupport(fteTransferOfEntityPK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CreateNewTransferOfEntitySupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let supportList = document.querySelector(`.supportsContainer[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
                    if (supportList != null) {
                        $(supportList).append(xhr.responseText);
                        resolve("Successfully added support");
                    }
                    else {
                        reject("There was an unexpected error adding the support");
                    }
                }
                else {
                    reject("There was an unexpected error creating new support");
                }
            };
            xhr.send("fteTransferOfEntityPK=" + fteTransferOfEntityPK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }
    //Soft deletes support from database
    deleteSupport(supportPK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteTransferOfEntitySupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let supportContainer = document.querySelector(`.supportRow[data-support-pk='${supportPK}']`);
                    if (supportContainer != null) {
                        let deleteSupportButton = document.querySelector(`.deleteSupport[data-support-pk='${supportPK}']`);
                        let nextFocusable = Core.getNextFocusableElement(deleteSupportButton);
                        supportContainer.parentNode.removeChild(supportContainer);
                        nextFocusable.focus();
                        resolve("Successfully deleted support");
                    }
                    else {
                        reject("There was an unexpected error removing this support from the page");
                    }
                }
                else {
                    reject("There was an unexpected error deleting this support");
                }
            };
            xhr.send("supportPK=" + supportPK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
        });
    }
    //Determines whether to show the message that the FTE has no supports in the UI
    checkSupportCount(ftePK) {
        let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
        let noSupportsMessage = document.querySelector(`.noSupportsMessage[data-fte-pk='${ftePK}']`);
        if (noSupportsMessage != null) {
            if (supports.length > 0) {
                noSupportsMessage.classList.add("hide");
            }
            else {
                noSupportsMessage.classList.remove("hide");
            }
        }
    }
    //Soft deletes FTE record from database
    deleteFTE(ftePK) {
        let that = this;
        that.deleteFTEConfirmModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFTETransferOfEntity', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {
                let allFTEContainer = document.getElementById("fteContainerInner");
                let fteContainer = document.querySelector(`.fteContainer[data-ftetransferofentity-pk='${ftePK}']`);
                let accordion = Core.findClosest(fteContainer, ".lazyAccordion");
                //Get next focusable accordion
                let accordionTriggers = allFTEContainer.querySelectorAll(".lazyAccordion-trigger");
                if (accordionTriggers.length > 1) {
                    let arr = Array.prototype.slice.call(accordionTriggers);
                    let thisAccordionTrigger = accordion.querySelector(".lazyAccordion-trigger");
                    if (thisAccordionTrigger != null) {
                        let index = arr.indexOf(thisAccordionTrigger);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                }
                else {
                    //Focus on create fte if no fte left
                    let create = document.getElementById("createNewFTEId");
                    if (create != null) {
                        create.focus();
                    }
                }
                if (accordion != null) {
                    accordion.parentNode.removeChild(accordion);
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted FTE", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error removing this FTE from the page", 'error', 3000, null);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE", 'error', 3000, null);
            }
        };
        xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK + "&allowExceedsFTE=" + that.allowExceedsFTE);
    }
    //Refreshes the possible Support Types that can be selected in the UI based on the Support Type Group that was selected
    refreshSupportTypeSubList(supportPK) {
        let that = this;
        let supportTypeGroup = document.querySelector(`.supportTypeGroup[data-support-pk='${supportPK}']`);
        return new Promise((resolve, reject) => {
            if (supportTypeGroup != null) {
                if (supportTypeGroup.value != "") {
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/SpecialEducation/RefreshSupportTypeSubListTransferOfEntity', true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            let supportTypeListContainer = document.querySelector(`.supportTypesContainer[data-support-pk='${supportPK}']`);
                            if (supportTypeListContainer != null) {
                                supportTypeListContainer.innerHTML = xhr.responseText;
                                let supportTypeDropdown = supportTypeListContainer.querySelector(".supportType[data-forcerequired='true']");
                                if (supportTypeDropdown != null) {
                                    that._core.forceElementRequired(supportTypeDropdown);
                                }
                            }
                            resolve();
                        }
                        else {
                            reject("Error refreshing support types");
                        }
                    };
                    xhr.send(`supportPK=${supportPK}&fteTransferOfEntityPK=${supportTypeGroup.dataset.ftetransferofentityPk}&supportTypeGroupCodeFK=${supportTypeGroup.value}&templateFK=${that.templateFK}`);
                }
                else {
                    let container = document.querySelector(`.supportTypesContainer[data-support-pk='${supportTypeGroup.dataset.supportPk}']`);
                    if (container != null) {
                        container.innerHTML = "";
                    }
                    resolve();
                }
            }
            else {
                reject("Support type group not found");
            }
        });
    }
    //Refreshes the possible Support Levels that can be selected in the UI based on the Support Type that was selected
    refreshSupportLevels(supportPK) {
        let that = this;
        let currentLevelPK = null;
        let currentTypePK = null;
        let currentLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`);
        if (currentLevel != null && currentLevel.value != "") {
            currentLevelPK = currentLevel.value;
        }
        let currentType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`);
        if (currentType != null && currentType.value != "") {
            currentTypePK = currentType.value;
        }
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/RefreshSupportLevelsTransferOfEntity', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let supportLevelListContainer = document.querySelector(`.supportLevelsContainer[data-support-pk='${supportPK}']`);
                    if (supportLevelListContainer != null) {
                        supportLevelListContainer.innerHTML = xhr.responseText;
                        let supportLevelDropdown = supportLevelListContainer.querySelector(".supportLevel[data-forcerequired='true']");
                        if (supportLevelDropdown != null) {
                            that._core.forceElementRequired(supportLevelDropdown);
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing support levels");
                }
            };
            xhr.send(`supportPK=${supportPK}&fteTransferOfEntityPK=${currentLevel.dataset.ftetransferofentityPk}&currentLevelPK=${currentLevelPK}&currentTypePK=${currentTypePK}&templateFK=${that.templateFK}`);
        });
    }
    //Shows the max possible caseload count in the UI given the currently selected Support Level and Type for the specified Support
    refreshMaxPossibleCaseLoad(supportPK) {
        let that = this;
        let supportType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`);
        let supportLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`);
        return new Promise((resolve, reject) => {
            if (supportType != null && supportType.value != "" && supportLevel != null && supportLevel.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/RefreshMaxPossibleCaseLoad', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    let jsonResponse = JSON.parse(xhr.response);
                    if (xhr.status === 200 && jsonResponse.success) {
                        let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`);
                        if (maxCaseLoadContainer != null) {
                            maxCaseLoadContainer.classList.remove("hide");
                        }
                        let maxCaseLoadElement = document.querySelector(`.maxCaseLoad[data-support-pk='${supportPK}']`);
                        if (maxCaseLoadElement != null) {
                            maxCaseLoadElement.innerHTML = jsonResponse.maxCaseLoadCount;
                        }
                        resolve();
                    }
                    else {
                        reject("Error refreshing max caseload");
                    }
                };
                xhr.send(`supportTypePK=${supportType.value}&supportLevelPK=${supportLevel.value}`);
            }
            else {
                let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`);
                if (maxCaseLoadContainer != null) {
                    maxCaseLoadContainer.classList.add("hide");
                }
            }
        });
    }
    //Refreshes the FTE for each support in the FTE and the FTE as a whole. If the FTE is over the limit, the error will display.
    refreshFTEPercentage(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];
            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`);
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }
            let supports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = support;
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;
                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }
                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }
                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }
                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTETransferOfEntityDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;
                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-ftetransferofentity-pk='${ftePK}']`);
                    if (overOneMessage != null) {
                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }
                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        }
                        else {
                            overOneMessage.classList.add("hide");
                        }
                    }
                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`);
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        }
                        else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }
    //The classroom location for a support is dependent upon the selected classroom location for the FTE. This function handles this in the UI
    adjustSupportClassroomLocation(ftePK) {
        return new Promise((resolve, reject) => {
            let classroomLocationLookupCode = "";
            let fteClassroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`);
            if (fteClassroomLocation != null) {
                classroomLocationLookupCode = fteClassroomLocation.options[fteClassroomLocation.selectedIndex].dataset.lookupCode;
            }
            //Get the supports for this FTE
            let classroomLocationsSupport = document.querySelectorAll(`.classroomLocationSupport[data-fte-pk='${ftePK}']`);
            for (let classroomLocationDropdown of classroomLocationsSupport) {
                let classroomLocationDropdownElement = classroomLocationDropdown;
                if (classroomLocationLookupCode === "specialeducationlocationelementary" || classroomLocationLookupCode === "specialeducationlocationsecondary") {
                    for (var i = 0; i < classroomLocationDropdownElement.options.length; ++i) {
                        if (classroomLocationDropdownElement.options[i].dataset.lookupCode === classroomLocationLookupCode) {
                            classroomLocationDropdownElement.selectedIndex = i;
                            classroomLocationDropdownElement.disabled = true;
                            break;
                        }
                    }
                }
                else {
                    classroomLocationDropdownElement.disabled = false;
                }
            }
            resolve();
        });
    }
    //Based on the selection of "Classroom Location" and "Age Range", make the "Age Range Justification" optional or required
    checkAgeRangeJustification(supportPK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let makeRequired = false;
            let classroomLocation = document.querySelector(`.classroomLocationSupport[data-support-pk='${supportPK}']`);
            let ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportPK}']`);
            let ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportPK}']`);
            if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
                if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {
                    let range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);
                    let classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;
                    switch (classroomLocationLookupCode) {
                        case 'specialeducationlocationelementary':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationsecondary':
                            if (range > 4) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationmultiple':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                    }
                }
            }
            let justification = document.querySelector(`.ageRangeJustification[data-support-pk='${supportPK}']`);
            if (justification != null) {
                if (makeRequired) {
                    that._core.forceElementRequired(justification);
                }
                else {
                    that._core.forceElementOptional(justification);
                }
            }
            resolve();
        });
    }
    //Shows a loader next to the FTE percentage while application figures out what the new percentage is
    toggleFTESupportLoader(supportPK, show) {
        let loaderContainer = document.querySelector(`.loaderContainer[data-support-pk='${supportPK}']`);
        if (loaderContainer != null) {
            if (show) {
                loaderContainer.classList.remove("hide");
            }
            else {
                loaderContainer.classList.add("hide");
            }
        }
    }
    //Searches FTE based on criteria and refreshes DOM with partial view. Also pushes history state so that back and continue buttons in browser work.
    search(fromHistory = false) {
        let that = this;
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        let fteIDValue = fteID != null ? fteID.value : "";
        let buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
        let supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;
        let query = {
            PlanFK: that.planFK,
            FTEIdentifierName: fteIDValue,
            FRCPPInstitutionFK: buildingValue,
            SupportTypeCodePK: supportTypeValue
        };
        let queryString = `?fteId=${query.FTEIdentifierName}&frcppInstitutionFK=${query.FRCPPInstitutionFK}&supportTypeCodeFK=${query.SupportTypeCodePK}`;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/TransferOfEntitySearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("fteContainer");
                    if (container != null) {
                        container.innerHTML = xhr.responseText;
                        //Add to history so back button works
                        if (!fromHistory) {
                            history.pushState(query, '', queryString);
                        }
                        let searchResultsMessage = document.getElementById("searchResultsMessage");
                        if (searchResultsMessage != null) {
                            document.title = searchResultsMessage.textContent + " - Transfer of Entity - Future Ready Comprehensive Planning Portal";
                            searchResultsMessage.focus();
                        }
                        SpecialEducationFTETransferOfEntity.fullPageValidation();
                        resolve();
                    }
                    else {
                        reject("There was an error loading Transfer of Entity records");
                    }
                }
                else {
                    reject("There was an unexpected error during search");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }
    //Clears search fields, saves page (in case there were changes), and refreshes DOM
    clearSearch() {
        let that = this;
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        if (fteID != null) {
            fteID.value = "";
        }
        if (building != null) {
            building.selectedIndex = 0;
        }
        if (supportType != null) {
            supportType.selectedIndex = 0;
        }
        Core.showLoader();
        SpecialEducationFTETransferOfEntity.promiseSave()
            .then(() => {
            return that.search(true);
        })
            .then(() => {
            SpecialEducationFTETransferOfEntity.initializeFTEHashes();
            that.handleClearDisabled();
            that._core.initializeRequiredFields(that.validationClasses);
            //reinitialize accordions
            new SpecialEducationProgramCaseloadFTELazyAccordion(null);
            Core.hideLoader();
            let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
            exportToExcelFilteredButton.classList.add("hide");
        })
            .catch((error) => {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
        history.pushState(null, '', window.location.href.split('?')[0]);
        document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }
    //The save button only becomes available for an FTE if there is a change within the FTE
    handleFTESaveDisabled(ftePK) {
        let saveButton = document.querySelector(`.saveFTEButton[data-ftetransferofentity-pk='${ftePK}']`);
        if (saveButton != null) {
            if (SpecialEducationFTETransferOfEntity.fteHasChanged(ftePK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            }
            else {
                saveButton.disabled = true;
            }
        }
    }
    //Determines whether the clear button should become enabled
    handleClearDisabled() {
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        let clearButton = document.getElementById("searchClear");
        if (fteID != null && building != null && supportType != null && clearButton != null) {
            if (fteID.value !== "" || building.selectedIndex > 0 || supportType.selectedIndex > 0) {
                clearButton.disabled = false;
            }
            else {
                clearButton.disabled = true;
            }
        }
    }
    //Exports FTE Transfer of Entity and support data to excel.
    exportToExcel() {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/EducationProgramFTETransferOfEntityDataExport?planFK=${that.planFK}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "EducationProgramFTETransferOfEntity.xlsx";
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send();
        });
    }
    uploadFile(e) {
        const core = this._core;
        Core.showLoader();
        const formId = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formId);
        const formData = new FormData(uploadSampleForm);
        const fteTransferOfEntityPK = e.dataset.ftetransferofentityPk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadTransferOfEntityFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.educationProgramProfileFileField[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadedTransferOfEntityFileList[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
                        const uploadedContainer = document.querySelector(`.uploadedTransferOfEntityFile[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
                        uploadedContainer.classList.remove("hide");
                        const fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-8");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        const newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        const fileA = document.createElement("a");
                        fileA.dataset.fileuploadfk = res.payload.fileUploadPK;
                        fileA.setAttribute("data-ftetransferofentity-pk", fteTransferOfEntityPK);
                        fileA.dataset.sequencenbr = "0";
                        fileA.classList.add("file");
                        fileA.classList.add("educationProgramProfileFileUpload");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    deleteFileConfirm(e) {
        const confirmButton = e.target;
        Core.showLoader();
        const fileUploadPK = confirmButton.dataset.fileuploadpk;
        const transferOfEntityPK = confirmButton.dataset.transferofentitypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteTransferOfEntityFile', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        const containerElement = document.querySelector(`.educationProgramProfileFile[data-fieluploadfk='${fileUploadPK}'][data-ftetransferofentity-pk='${transferOfEntityPK}']`);
                        if (containerElement !== null) {
                            containerElement.remove();
                        }
                        const fileUploads = document.querySelector(`.uploadedTransferOfEntityFile[data-ftetransferofentity-pk='${transferOfEntityPK}']`);
                        fileUploads.classList.add("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send("fileUploadPK=" + fileUploadPK + "&transferOfEntityPK=" + transferOfEntityPK);
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    //Save method that returns a promise. If ftePK is passed, only the specified FTE will be saved. Otherwise, all FTE on page will be saved (if there are changes)
    static promiseSave(ftePK = null, forceSave = false) {
        //let that = this;
        let core = this._staticCore;
        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData = [];
            let fteToSave;
            if (ftePK !== null) {
                fteToSave = document.querySelectorAll(`.fteContainer[data-ftetransferofentity-pk='${ftePK}']`);
            }
            else {
                //Get all the FTE on the page
                fteToSave = document.querySelectorAll(".fteContainer");
            }
            for (let fte of fteToSave) {
                let fteSupportsData = [];
                if ("ftetransferofentityPk" in fte.dataset && this.fteHasChanged(parseInt(fte.dataset.ftetransferofentityPk))) {
                    shouldSave = true;
                    let ftePK = fte.dataset.ftetransferofentityPk;
                    //Get FTEId
                    let educationProgramFTEId = null;
                    let fteID = fte.querySelector(`.fteID[data-ftetransferofentity-pk='${ftePK}']`);
                    if (fteID != null && fteID.value != "") {
                        educationProgramFTEId = fteID.value;
                    }
                    //Get Classroom Location Lookup
                    let classroomLocationLookupCodeFK = null;
                    let classroomLocationFTE = fte.querySelector(`.classroomLocationFTE[data-ftetransferofentity-pk='${ftePK}']`);
                    if (classroomLocationFTE != null && classroomLocationFTE.value != "") {
                        classroomLocationLookupCodeFK = parseInt(classroomLocationFTE.value);
                    }
                    //Get Full Time Lookup
                    let fullTimeLookupCodeFK = null;
                    let fullTimeFTE = fte.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`);
                    if (fullTimeFTE != null && fullTimeFTE.value != "") {
                        fullTimeLookupCodeFK = parseInt(fullTimeFTE.value);
                    }
                    //Get March 31 question
                    let march31LookupCodeFK = null;
                    const march31FTE = fte.querySelector(`.afterMarch[data-ftetransferofentity-pk='${ftePK}']:checked`);
                    if (march31FTE != null && march31FTE.value != "") {
                        march31LookupCodeFK = parseInt(march31FTE.value);
                    }
                    //Get Alerted IU question
                    let alertedIULookupCodeFK = null;
                    const alertedIUFTE = fte.querySelector(`.alertedIU[data-ftetransferofentity-pk='${ftePK}']:checked`);
                    if (alertedIUFTE != null && alertedIUFTE.value != "") {
                        alertedIULookupCodeFK = parseInt(alertedIUFTE.value);
                    }
                    //Implementation Date
                    let implementationDate = null;
                    const implementationDateFTE = fte.querySelector(`.implementationDate[data-ftetransferofentity-pk='${ftePK}']`);
                    if (implementationDateFTE != null && implementationDateFTE.value != "") {
                        implementationDate = implementationDateFTE.value;
                    }
                    let fileUploadFK = null;
                    const fileUploadFTE = fte.querySelector(`.educationProgramProfileFileUpload[data-ftetransferofentity-pk='${ftePK}']`);
                    if (fileUploadFTE !== null) {
                        fileUploadFK = fileUploadFTE.dataset.fileuploadfk;
                    }
                    //Get supports
                    let supports = [];
                    let supportsForFTE = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
                    for (let support of supportsForFTE) {
                        let supportElement = support;
                        if ("supportPk" in supportElement.dataset) {
                            //buildingName
                            let frcppInstitutionFK = null;
                            let buildingName = supportElement.querySelector(`.buildingName[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (buildingName != null && buildingName.value != "") {
                                frcppInstitutionFK = parseInt(buildingName.value);
                            }
                            //supportTypeGroup
                            let supportTypeGroupCodeFK = null;
                            let supportTypeGroup = supportElement.querySelector(`.supportTypeGroup[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportTypeGroup != null && supportTypeGroup.value != "") {
                                supportTypeGroupCodeFK = parseInt(supportTypeGroup.value);
                            }
                            //supportType
                            let supportTypeCodeFK = null;
                            let supportType = supportElement.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportType != null && supportType.value != "") {
                                supportTypeCodeFK = parseInt(supportType.value);
                            }
                            //supportLevel
                            let supportLevelCodeFK = null;
                            let supportLevel = supportElement.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportLevel != null && supportLevel.value != "") {
                                supportLevelCodeFK = parseInt(supportLevel.value);
                            }
                            //caseLoad
                            let caseLoadCount = null;
                            let caseLoad = supportElement.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (caseLoad != null && caseLoad.value != "") {
                                caseLoadCount = parseInt(caseLoad.value);
                            }
                            //identifyclassroom
                            let classroomLookupCodeFK = null;
                            let identifyclassroom = supportElement.querySelector(`.identifyClassroom[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (identifyclassroom != null && identifyclassroom.value != "") {
                                classroomLookupCodeFK = parseInt(identifyclassroom.value);
                            }
                            //classroomLocationSupport
                            let supportClassroomLocationLookupCodeFK = null;
                            let classroomLocationSupport = supportElement.querySelector(`.classroomLocationSupport[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (classroomLocationSupport != null && classroomLocationSupport.value != "") {
                                supportClassroomLocationLookupCodeFK = parseInt(classroomLocationSupport.value);
                            }
                            //ageRangeFrom
                            let ageRangeFromNbr = null;
                            let ageRangeFrom = supportElement.querySelector(`.ageRangeFrom[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeFrom != null && ageRangeFrom.value != "") {
                                ageRangeFromNbr = parseInt(ageRangeFrom.value);
                            }
                            //ageRangeEnd
                            let ageRangeToNbr = null;
                            let ageRangeEnd = supportElement.querySelector(`.ageRangeEnd[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeEnd != null && ageRangeEnd.value != "") {
                                ageRangeToNbr = parseInt(ageRangeEnd.value);
                            }
                            //ageRangeJustification
                            let ageRangeJustificationText = "";
                            let ageRangeJustification = supportElement.querySelector(`.ageRangeJustification[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeJustification != null && ageRangeJustification.value != "") {
                                ageRangeJustificationText = ageRangeJustification.value;
                            }
                            let fteSupportSaveData = {
                                AgeRangeFromNbr: ageRangeFromNbr,
                                AgeRangeJustification: ageRangeJustificationText,
                                AgeRangeToNbr: ageRangeToNbr,
                                CaseLoadCount: caseLoadCount,
                                ClassroomLocationLookupCodeFK: supportClassroomLocationLookupCodeFK,
                                ClassroomLookupCodeFK: classroomLookupCodeFK,
                                EducationProgramFTETransferOfEntityFK: parseInt(ftePK),
                                EducationProgramFTETransferOfEntitySupportPK: parseInt(supportElement.dataset.supportPk),
                                FRCPPInstitutionFK: frcppInstitutionFK,
                                SupportLevelCodeFK: supportLevelCodeFK,
                                SupportTypeCodeFK: supportTypeCodeFK,
                                UISelectedSupportTypeGroupCodeFK: supportTypeGroupCodeFK
                            };
                            supports.push(fteSupportSaveData);
                        }
                    }
                    const fteSaveData = {
                        ClassroomLocationLookupCodeFK: classroomLocationLookupCodeFK,
                        FullTimeLookupCodeFK: fullTimeLookupCodeFK,
                        FTEIdentifierName: educationProgramFTEId,
                        EducationProgramFTETransferOfEntityPK: parseInt(ftePK),
                        PlanFK: this._staticPlanFK,
                        TransferAfterMarchLookupCodeFK: march31LookupCodeFK,
                        AlertedTheIULookupCodeFK: alertedIULookupCodeFK,
                        ImplementationDate: implementationDate,
                        TransferOfEntityFormFileUploadFK: fileUploadFK
                    };
                    const thisFTESaveData = {
                        FTE: fteSaveData,
                        Supports: supports
                    };
                    saveData.push(thisFTESaveData);
                }
            }
            if (shouldSave || forceSave) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Base/SaveEducationProgramFTETransferOfEntity', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    return __awaiter(this, void 0, void 0, function* () {
                        if (xhr.status === 200) {
                            if (ftePK != null) {
                                const json = JSON.parse(xhr.responseText);
                                const validData = json.validData;
                                SpecialEducationFTETransferOfEntity.fteValidation(ftePK);
                                const elementButton = document.querySelector(`.lazyAccordionTrigger[data-educationprogramftetransferofentityfk='${ftePK}']`);
                                elementButton.dataset.validdata = validData;
                                yield SpecialEducationFTETransferOfEntity.getValidProgramCount();
                                SpecialEducationFTETransferOfEntity.fullPageValidation();
                                resolve("Successfully saved FTE");
                            }
                            else {
                                yield SpecialEducationFTETransferOfEntity.getValidProgramCount();
                                SpecialEducationFTETransferOfEntity.fullPageValidation();
                                resolve("Successfully saved");
                            }
                        }
                        else {
                            reject("There was an unexpected error saving");
                        }
                    });
                };
                xhr.send(JSON.stringify({
                    "Data": saveData,
                    "PlanFK": this._staticPlanFK,
                    "AllowExceedsFTE": this._staticAllowExceedsFTE
                }));
            }
            else {
                resolve("Nothing To Save");
            }
        });
    }
    //Determines whether an FTE has changed by comparing the current hash value to a prior hash value
    static fteHasChanged(ftePK) {
        let changed = false;
        if (SpecialEducationFTETransferOfEntity.fteHashes[ftePK] !== undefined) {
            let newHash = this.calculateFTEHash(ftePK);
            if (newHash !== this.fteHashes[ftePK]) {
                changed = true;
            }
        }
        else {
            //Be on the safe side and say it has changed
            changed = true;
            //And initialize the hashes again.
            this.initializeFTEHashes();
        }
        return changed;
    }
    //Calculates a hash value for each FTE on the page and is stored in an object as a property of this class.
    static initializeFTEHashes() {
        this.fteHashes = {};
        let fteContainers = document.getElementsByClassName("lazy-accordion-panel");
        for (let fte of fteContainers) {
            let ftePK = parseInt(fte.dataset.educationprogramftetransferofentityfk);
            let hash = this.calculateFTEHash(ftePK);
            this.fteHashes[ftePK] = hash;
        }
    }
    //Calculates a hash for an FTE. This is used to determine whether the FTe has changed for saving purposes.
    static calculateFTEHash(ftePK) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;
        let allElements = document.querySelectorAll(`[data-hashable][data-ftetransferofentity-pk='${ftePK}']`);
        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                let inputElement = element;
                if (inputElement.type === "radio") {
                    if (inputElement.checked) {
                        value = inputElement.value;
                        newHash = that._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
                else {
                    if (inputElement.value === "") {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = that._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
            }
            else if (element instanceof HTMLSelectElement) {
                let selectElement = element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value;
                    newHash = that._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLTextAreaElement) {
                let textAreaElement = element;
                if (textAreaElement.value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = that._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            }
        }
        return hash;
    }
    static fteValidation(educationProgramFTEFK) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);
        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    }
                    else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }
        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    }
                    else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }
        this.updateFTEPercentage(educationProgramFTEFK);
    }
    static updateFTEPercentage(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];
            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-ftetransferofentity-pk='${ftePK}']`);
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }
            let supports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = support;
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;
                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }
                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }
                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }
                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/CalculateFTETransferOfEntityDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;
                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-ftetransferofentity-pk='${ftePK}']`);
                    if (overOneMessage != null) {
                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }
                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        }
                        else {
                            overOneMessage.classList.add("hide");
                        }
                    }
                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`);
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        }
                        else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }
    static updateHash(educationProgramFTEFK) {
        const hash = SpecialEducationFTETransferOfEntity.calculateFTEHash(educationProgramFTEFK);
        SpecialEducationFTETransferOfEntity.fteHashes[educationProgramFTEFK] = hash;
    }
    static initializeLoadedFTE(educationProgramFTEFK) {
        this.updateHash(educationProgramFTEFK);
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftetransferofentityfk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftetansferofentityfk='${educationProgramFTEFK}'] select`);
        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        this.updateFTEPercentage(educationProgramFTEFK);
    }
    static fullPageValidation(fromPageLoad = false) {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownInvalidAccordions = document.querySelectorAll(".lazyAccordionTrigger[data-validdata='false']");
        const totalErrorsNotShown = totalErrors - shownInvalidAccordions.length;
        let errorCount = 0;
        for (const accordion of allAccordions) {
            const accElement = accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;
            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            }
            else {
                accElement.classList.remove("error");
            }
        }
        const messageContainer = document.getElementById("validationMessageContainer");
        const validationIcon = document.getElementById("validationMessageIcon");
        const messageContainerColumn = document.getElementById("validationColumn");
        if (!fromPageLoad || errorCount > 0 || totalErrorsNotShown > 0) {
            messageContainerColumn.classList.add("show");
        }
        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");
        const message = document.getElementById("validationMessage");
        if (errorCount > 0) {
            let word = "are";
            let facility = "FTEs";
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "FTE";
                error = "error";
            }
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = '';
            if (!fromPageLoad) {
                message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            }
            let facilityWord = "are";
            let facilityFacility = "FTEs";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "FTE";
            }
            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            if (message !== null) {
                message.innerHTML = "The page has been successfully saved.";
                message.classList.add("show");
            }
        }
    }
    static clearValidationMessage() {
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }
    static getValidProgramCount() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                const planForm = document.getElementById("specialEducationEducationProgramFTETransferOfEntityForm");
                const planFK = planForm.dataset.planfk;
                const response = yield fetch(`/SpecialEducation/GetValidProgramFTETransferOfEntityCountAsync/${planFK}`, { credentials: 'include' });
                if (response.ok) {
                    const value = yield response.text();
                    const field = document.getElementById("validCount");
                    field.value = value;
                    resolve("Valid program count updated");
                }
                else {
                    reject("Error updating program count");
                }
            }));
        });
    }
    static createSEPRN(fteTransferOfEntityPK) {
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        let proposedActionValue = "";
        let additionalInformationValue = "";
        let proposedActionElement = document.querySelector(`.proposedAction[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (proposedActionElement != null) {
            proposedActionValue = proposedActionElement.value;
        }
        let additionalInformationElement = document.querySelector(`.additionalInformation[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (additionalInformationElement != null) {
            additionalInformationValue = additionalInformationElement.value;
        }
        if (proposedActionElement.value != "") {
            const selected = proposedActionElement[proposedActionElement.selectedIndex];
            if ((selected.dataset.lookupCode == "proposedActionDelete" || selected.dataset.lookupCode == "proposedActionChange") && additionalInformationValue == "") {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'Please specify a reason/explanation for this Transfer of Entity', 'error', 3000, null);
            }
            else if (selected.dataset.lookupCode == "proposedActionNew" && !this.validateElements(fteTransferOfEntityPK, true)) {
                Core.hideLoader();
            }
            else {
                fetch('/SpecialEducation/CreateTransferOfEntitySEPRN', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        FTETransferOfEntityFK: fteTransferOfEntityPK,
                        ProposedAction: proposedActionValue,
                        AdditionalInformation: additionalInformationValue
                    })
                })
                    .then((response) => {
                    if (response.ok) {
                        return this.promiseSave(fteTransferOfEntityPK, false);
                    }
                    else {
                        throw new Error('Creating Transfer of Entity failed');
                    }
                })
                    .then((response) => {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully submitted this Transfer of Entity', 'success', 3000, window.location.reload());
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue submitting this Transfer of Entity', 'error', 3000, null);
                    Core.hideLoader();
                });
            }
        }
        else {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a proposed action for the Transfer of Entity', 'error', 3000, null);
            Core.hideLoader();
        }
    }
    static checkAgeRange(supportFK) {
        let makeRequired = false;
        const classroomLocation = document.querySelector(`.classroomLocationSupport[data-support-pk='${supportFK}']`);
        const ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportFK}']`);
        const ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportFK}']`);
        if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
            if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {
                const range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);
                const classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;
                switch (classroomLocationLookupCode) {
                    case 'specialeducationlocationelementary':
                        if (range > 3) {
                            makeRequired = true;
                        }
                        break;
                    case 'specialeducationlocationsecondary':
                        if (range > 4) {
                            makeRequired = true;
                        }
                        break;
                    case 'specialeducationlocationmultiple':
                        if (range > 3) {
                            makeRequired = true;
                        }
                        break;
                }
            }
        }
        return makeRequired;
    }
    static validateElements(fteTransferOfEntityPK = null, showAlerts) {
        let hasErrors = false;
        let hasSupportErrors = false;
        const fullTime = document.querySelector(`.fullTime[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (fullTime !== null) {
            if (fullTime.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please select a Full or Part Time identifier', 'error', 3000, null);
                }
            }
        }
        const classroomLocationFTE = document.querySelector(`.classroomLocationFTE[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (classroomLocationFTE !== null) {
            if (classroomLocationFTE.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please select a Classroom Location', 'error', 3000, null);
                }
            }
        }
        const fteID = document.querySelector(`.fteID[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (fteID !== null) {
            if (fteID.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a value for the FTE ID', 'error', 3000, null);
                }
            }
        }
        const afterMarch = document.querySelector(`.afterMarch[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']:checked`);
        if (afterMarch !== null && afterMarch.value === "") {
            hasErrors = true;
            if (showAlerts) {
                Core.createHTMLAlert('alertMessageDiv', 'Please select an option for the question "Is the transfer of entity after March 31?"', 'error', 3000, null);
            }
        }
        const alertedIU = document.querySelector(`.alertedIU[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']:checked`);
        if (alertedIU !== null && alertedIU.value === "") {
            hasErrors = true;
            if (showAlerts) {
                Core.createHTMLAlert('alertMessageDiv', 'Please select an option for the question "Have you alerted the IU?"', 'error', 3000, null);
            }
        }
        const implementationDate = document.querySelector(`.implementationDate[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        if (implementationDate !== null) {
            if (implementationDate.value === "") {
                hasErrors = true;
                if (showAlerts) {
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a date for the Implementation Date', 'error', 3000, null);
                }
            }
        }
        const allSupports = document.querySelectorAll(`.supportRow[data-ftetransferofentity-pk='${fteTransferOfEntityPK}']`);
        for (const thisSupport of allSupports) {
            const supportElement = thisSupport;
            const supportPK = supportElement.dataset.supportPk;
            const buildingName = document.querySelector(`.buildingName[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (buildingName !== null) {
                if (buildingName.value === "") {
                    hasSupportErrors = true;
                }
            }
            let supportTypeGroupCode = "";
            const supportTypeGroup = document.querySelector(`.supportTypeGroup[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (supportTypeGroup !== null) {
                if (supportTypeGroup.value === "") {
                    hasSupportErrors = true;
                    supportTypeGroupCode = supportTypeGroup.dataset.lookupcode;
                }
            }
            if (supportTypeGroupCode === "lifeskillssupport") {
                const supportType = document.querySelector(`.supportType[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
                if (supportType !== null) {
                    if (supportType.value === "") {
                        hasSupportErrors = true;
                    }
                }
            }
            const supportLevel = document.querySelector(`.supportLevel[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (supportLevel !== null) {
                if (supportLevel.value === "") {
                    hasSupportErrors = true;
                }
            }
            const caseLoad = document.querySelector(`.caseLoad[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (caseLoad !== null) {
                if (caseLoad.value === "") {
                    hasSupportErrors = true;
                }
            }
            const identifyClassroom = document.querySelector(`.identifyClassroom[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (identifyClassroom !== null) {
                if (identifyClassroom.value === "") {
                    hasSupportErrors = true;
                }
            }
            const ageRangeFrom = document.querySelector(`.ageRangeFrom[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (ageRangeFrom !== null) {
                if (ageRangeFrom.value === "") {
                    hasSupportErrors = true;
                }
            }
            const ageRangeEnd = document.querySelector(`.buildingName[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
            if (ageRangeEnd !== null) {
                if (ageRangeEnd.value === "") {
                    hasSupportErrors = true;
                }
            }
            if (this.checkAgeRange(parseInt(supportPK))) {
                const ageRangeJustification = document.querySelector(`.ageRangeJustification[data-ftetransferofentity-pk='${fteTransferOfEntityPK}'][data-support-pk='${supportPK}']`);
                if (ageRangeJustification !== null) {
                    if (ageRangeJustification.value === "") {
                        hasSupportErrors = true;
                    }
                }
            }
        }
        if (hasSupportErrors && showAlerts) {
            Core.createHTMLAlert('alertMessageDiv', 'One or more supports have errors. Please correct them before proceeding.', 'error', 3000, null);
        }
        return !(hasErrors || hasSupportErrors);
    }
}
// Special Education Special Education Support Services
class SpecialEducationSpecialEducationSupportServices {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationSpecialEducationSupportServicesField", "specialEducationSpecialEducationSupportServicesSelectField"];
        let specialEducationSpecialEducationSupportServicesSaveButton = document.getElementById("specialEducationSpecialEducationSupportServicesSave");
        if (specialEducationSpecialEducationSupportServicesSaveButton !== null)
            specialEducationSpecialEducationSupportServicesSaveButton.addEventListener("click", (e) => this.save("save"));
        let steeringCommitteeAddRows = document.getElementById("specialEducationSupportServicesAddRows");
        if (steeringCommitteeAddRows !== null)
            steeringCommitteeAddRows.addEventListener("click", (e) => this.supportServicesAddRows(e));
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteSteeringCommitteeConfirmButton = document.getElementById("specialEducationDeleteSupportServicesDeleteConfirm");
        if (deleteSteeringCommitteeConfirmButton !== null)
            deleteSteeringCommitteeConfirmButton.addEventListener("click", (e) => this.deleteRowConfirmConfirm(e));
        let deleteSteeringCommitteeCancelButton = document.getElementById("specialEducationDeleteSupportServicesDeleteCancel");
        if (deleteSteeringCommitteeCancelButton !== null)
            deleteSteeringCommitteeCancelButton.addEventListener("click", (e) => this.deleteRowConfirmCancel());
        this.bindDeleteRowButtons();
        let wordPluralizer = document.getElementById("specialEducationSupportServicesAddRowsNumber");
        if (wordPluralizer !== null)
            wordPluralizer.addEventListener("change", (e) => this.checkForPlural());
        const supportServicesExportToExcelButton = document.getElementById("specialEducationSupportServicesExportToExcel");
        if (supportServicesExportToExcelButton !== null) {
            supportServicesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationSpecialEducationSupportServicesFormElement = document.getElementById("specialEducationSpecialEducationSupportServicesForm");
        let inputs = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationSpecialEducationSupportServicesFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationSpecialEducationSupportServicesFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationSupportServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    supportServicesAddRows(e) {
        let target = e.target;
        let core = this._core;
        let value = document.getElementById("specialEducationSupportServicesAddRowsNumber");
        let _that = this;
        let planFK = target.dataset.planfk;
        if (value.value === "") {
            Core.createHTMLAlert("alertMessageDiv", "Please specify the number of rows to add", 'error', 3000, null);
        }
        else {
            let rows = parseInt(value.value);
            let row = 0;
            let table = document.getElementById("supportServices");
            let trs = table.rows;
            for (let tr of trs) {
                if (parseInt(tr.getAttribute("data-row")) > row)
                    row = parseInt(tr.getAttribute("data-row"));
            }
            row++;
            for (let i = 0; i < rows; i++) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/SupportServicesRow', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        $("#supportServices tbody").append(xhr.responseText);
                        value.value = "";
                        let rows = document.getElementsByClassName("removeSupportServicesRow");
                        let row = rows[rows.length - 1];
                        _that.bindDeleteRowButtons();
                        Core.createHTMLAlert("alertMessageDiv", "New Support Service Row Added", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                };
                xhr.send(`row=${row}&planFK=${planFK}`);
                row++;
            }
        }
    }
    bindDeleteRowButtons() {
        let deleteButtons = document.getElementsByClassName("removeSupportServicesRow");
        for (let deleteButton of deleteButtons) {
            let deleteButtonElement = deleteButton;
            deleteButtonElement.addEventListener("click", (e) => this.showDeleteRowConfirm(e));
        }
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("specialEducationDeleteSupportServicesRow", null);
        modal.addAttributeToElement("specialEducationDeleteSupportServicesRow", "#specialEducationDeleteSupportServicesDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirmCancel() {
        let modal = new Modal("specialEducationDeleteSupportServicesRow", null);
        modal.hide();
    }
    deleteRowConfirmConfirm(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planProps = [];
        let core = this._core;
        let formData = document.getElementById("specialEducationSpecialEducationSupportServicesForm");
        let planPK = parseInt(formData.dataset.planpk);
        let allBlank = true;
        Core.showLoader();
        let committeeFields = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesField");
        for (let comm of committeeFields) {
            let inputElement = comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-1")
                    allBlank = false;
            }
        }
        let committeeSelectFields = document.getElementsByClassName("specialEducationSpecialEducationSupportServicesSelectField");
        for (let comm of committeeSelectFields) {
            let inputElement = comm;
            if (inputElement.dataset.row === row) {
                planProps.push(inputElement.dataset.planpropertypk);
                if (inputElement.value !== "" && inputElement.value != "-1")
                    allBlank = false;
            }
        }
        if (allBlank) {
            let allTheRows = document.getElementsByClassName("supportServicesRow");
            for (let thisRow of allTheRows) {
                let thisRowElement = thisRow;
                if (thisRowElement.dataset.row === row) {
                    thisRowElement.parentNode.removeChild(thisRowElement);
                    let modal = new Modal("specialEducationDeleteSupportServicesRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The support services row has been removed.", 'success', 3000, null);
                }
            }
            Core.hideLoader();
        }
        else {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteSupportServicesRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let allTheRows = document.getElementsByClassName("supportServicesRow");
                    for (let thisRow of allTheRows) {
                        let thisRowElement = thisRow;
                        if (thisRowElement.dataset.row === row) {
                            thisRowElement.parentNode.removeChild(thisRowElement);
                        }
                    }
                    let modal = new Modal("specialEducationDeleteSupportServicesRow", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The support services row has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planProps));
        }
    }
    checkForPlural() {
        let word = document.getElementById("rowWord");
        let num = document.getElementById("specialEducationSupportServicesAddRowsNumber");
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }
        word.textContent = newWord;
    }
    initializeRequiredFieldsCustom() {
        //If any field in a row is filled out, force rest of row required (except email)
        let steeringCommitteeTable = document.getElementById("supportServices");
        let steeringCommitteeTableRows = steeringCommitteeTable.querySelectorAll("tr[data-row]");
        for (let row of steeringCommitteeTableRows) {
            let elements = row.getElementsByClassName("specialEducationSpecialEducationSupportServicesTable");
            for (let element of elements) {
                if (element instanceof HTMLSelectElement) {
                    if (element.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    if (element.value !== "") {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationSpecialEducationSupportServicesForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationSupportServicesExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationSupportServices.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}
// Special Education Special Education Personnel Development
class SpecialEducationSpecialEducationPersonnelDevelopment {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationPersonnelDevelopmentField", "specialEducationPersonnelDevelopmentMultiSelectField"];
        let specialEducationSpecialEducationPersonnelDevelopmentSaveButton = document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentSave");
        if (specialEducationSpecialEducationPersonnelDevelopmentSaveButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentSaveButton.addEventListener("click", (e) => this.save("save"));
        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton = document.getElementById("specialEducationPersonnelDevelopmentAddTrainingTopic");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton.addEventListener("click", (e) => this.addTrainingTopic());
        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton = document.getElementById("specialEducationAddTrainingTopicNameCreate");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton.addEventListener("click", (e) => this.addTrainingTopicCreate());
        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton = document.getElementById("specialEducationAddTrainingTopicNameCancel");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton.addEventListener("click", (e) => this.addTrainingTopicCancel());
        this.bindAddTrainingButtons();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.bindDeleteTraining();
        const deleteTrainingConfirm = document.getElementById("deleteTrainingConfirm");
        if (deleteTrainingConfirm !== null)
            deleteTrainingConfirm.addEventListener("click", (e) => this.deleteTrainingConfirm(e));
        const deleteTrainingCancel = document.getElementById("deleteTrainingCancel");
        if (deleteTrainingCancel !== null)
            deleteTrainingCancel.addEventListener("click", (e) => this.deleteTrainingCancel());
        this.bindDeleteTrainingTopic();
        const deleteTrainingTopicConfirm = document.getElementById("deleteTrainingTopicConfirm");
        if (deleteTrainingTopicConfirm !== null)
            deleteTrainingTopicConfirm.addEventListener("click", (e) => this.deleteTrainingTopicConfirm(e));
        const deleteTrainingTopicCancel = document.getElementById("deleteTrainingTopicCancel");
        if (deleteTrainingTopicCancel !== null)
            deleteTrainingTopicCancel.addEventListener("click", (e) => this.deleteTrainingTopicCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let saveButton = document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentSave");
        saveButton.disabled = true;
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentForm");
        let inputs = document.getElementsByClassName("specialEducationPersonnelDevelopmentField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let dataPointPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            planPK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let dataPointPlanPropertyTextValue = element.dataset.datapointplanpropertytextvalue;
            let dataPointRowNumber = 0;
            if (element.dataset.datapointpropertypk !== "" && element.dataset.datapointpropertypk !== "0")
                dataPointPropertyPK = parseInt(element.dataset.datapointpropertypk);
            if (element.dataset.datapointplanpropertypk !== "" && element.dataset.datapointplanpropertypk !== "0")
                dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            if (element.dataset.datapointrownumber !== "" && element.dataset.datapointrownumber !== "0")
                dataPointRowNumber = parseInt(element.dataset.datapointrownumber);
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPropertyPK: dataPointPropertyPK,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    DataPointPlanPropertyTextValue: dataPointPlanPropertyTextValue,
                    DataPointRowNumber: dataPointRowNumber
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("specialEducationPersonnelDevelopmentMultiSelectCheckField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                let dataPointPropertyPK = 0;
                let dataPointPlanPropertyPK = 0;
                let dataPointPlanPropertyTextValue = element.dataset.datapointplanpropertytextvalue;
                let dataPointRowNumber = 0;
                if (element.dataset.datapointpropertypk !== "" && element.dataset.datapointpropertypk !== "0")
                    dataPointPropertyPK = parseInt(element.dataset.datapointpropertypk);
                if (element.dataset.datapointplanpropertypk !== "" && element.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                if (element.dataset.datapointrownumber !== "" && element.dataset.datapointrownumber !== "0")
                    dataPointRowNumber = parseInt(element.dataset.datapointrownumber);
                if (element.checked || hadValue) {
                    let val = "";
                    if (element.checked)
                        val = "on";
                    else
                        val = "off";
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: val,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPropertyPK: dataPointPropertyPK,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        DataPointPlanPropertyTextValue: dataPointPlanPropertyTextValue,
                        DataPointRowNumber: dataPointRowNumber
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationPersonnelDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    saveButton.disabled = false;
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                    saveButton.disabled = false;
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    saveButton.disabled = false;
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                saveButton.disabled = false;
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
                saveButton.disabled = false;
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindAddTrainingButtons() {
        let trainingButtons = document.getElementsByClassName("specialEducationPersonnelDevelopmentAddTraining");
        for (let trainingButton of trainingButtons) {
            let element = trainingButton;
            if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e) => this.addTraining(e));
                element.addEventListener("click", (e) => this.addTraining(e));
                element.dataset.listener = "true";
            }
        }
    }
    addTraining(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let propertyCode = button.dataset.propertycode;
        let planPropertyPK = button.dataset.datapointplanpropertypk;
        let newRow = 0;
        let dataPointRowNumber = 0;
        let newTopicPlanPropertyPK = 0;
        const accordionPropertyFK = button.dataset.accordionpropertyfk;
        let personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertyCode='${propertyCode}'] .specialEducationPersonnelDevelopmentField`);
        if (propertyCode.indexOf("Other") > 0) {
            if (button.dataset.datapointrownumber !== "" && button.dataset.datapointrownumber !== "0") {
                dataPointRowNumber = parseInt(button.dataset.datapointrownumber);
            }
            personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertycode='${propertyCode}'] .specialEducationPersonnelDevelopmentField[data-datapointrownumber='${dataPointRowNumber}']`);
        }
        if (button.dataset.datapointrownumber !== "" && button.dataset.datapointrownumber !== "0")
            dataPointRowNumber = parseInt(button.dataset.datapointrownumber);
        if (button.dataset.datapointplanpropertypk !== "" && button.dataset.datapointplanpropertypk !== "0")
            newTopicPlanPropertyPK = parseInt(button.dataset.datapointplanpropertypk);
        for (let thisRow of personnelRows) {
            let thisRowElement = thisRow;
            if (newRow < parseInt(thisRowElement.dataset.row))
                newRow = parseInt(thisRowElement.dataset.row);
        }
        newRow++;
        const that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentTraining', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}']`);
                if (propertyCode.indexOf("Other") > 0)
                    element = document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}'][data-datapointplanpropertypk='${planPropertyPK}']`);
                var div = document.createElement("div");
                div.classList.add("personnel-development-row");
                div.dataset.propertycode = propertyCode;
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                $('.multiSelect').MultiSelect();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCode=${propertyCode}&newTopicPlanPropertyPK=${newTopicPlanPropertyPK}&OtherRowNumber=${dataPointRowNumber}&AccordionPropertyFK=${accordionPropertyFK}`);
    }
    addTrainingTopic() {
        let modal = new Modal("addATrainingModal", null);
        modal.show();
    }
    addTrainingTopicCancel() {
        let modal = new Modal("addATrainingModal", null);
        modal.hide();
    }
    addTrainingTopicCreate() {
        let newTopicNameElement = document.getElementById("specialEducationAddTrainingTopicName");
        let newTopicName = newTopicNameElement.value;
        let core = this._core;
        let planFK = 0;
        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentForm");
        planFK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);
        let that = this;
        let row = 0;
        let rowElements = document.querySelectorAll(".personnelDevelopmentTraining");
        for (let rowElement of rowElements) {
            let ele = rowElement;
            if (row < parseInt(ele.dataset.row))
                row = parseInt(ele.dataset.row);
        }
        row++;
        if (newTopicName !== "") {
            let trainingTopic = {
                "PlanFK": planFK,
                "TopicName": newTopicName,
                "NewRow": row.toString()
            };
            let specialEducationAddTrainingTopicErrorMessage = document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.add("hide-message");
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentAddTrainingTopic', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let newId = `${newTopicName.replace(/\s/g, '')}customAccordion`;
                    let div = document.createElement("div");
                    div.innerHTML = xhr.responseText;
                    div.id = newId;
                    let trainingTopicsElement = document.getElementById("specialEducationTrainingTopics");
                    trainingTopicsElement.appendChild(div);
                    new CustomAccordion(newId);
                    let modalText = document.getElementById("specialEducationAddTrainingTopicName");
                    modalText.value = "";
                    $('.multiSelect').MultiSelect();
                    let modal = new Modal("addATrainingModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "Successfully added a new training topic", 'success', 3000, null);
                    that.bindAddTrainingButtons();
                    that.bindDeleteTrainingTopic();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(trainingTopic));
        }
        else {
            let specialEducationAddTrainingTopicErrorMessage = document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.remove("hide-message");
        }
    }
    initializeRequiredFieldsCustom(allClasses, refresh = false) {
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        if (refresh) {
            let allElements = document.querySelectorAll(classesToValidate);
            for (let element of allElements) {
                let htmlElement = element;
                htmlElement.removeAttribute("aria-required");
                let label = Core.findLabelForInput(htmlElement);
                if (label !== null) {
                    label.classList.remove("isRequired");
                    let asterisk = label.querySelector(".required-label");
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        const allTrainings = document.querySelectorAll(`.specialEducationPersonnelDevelopmentDescriptionOfTraining`);
        for (const training of allTrainings) {
            const ele = training;
            if (ele.value !== "") {
                const row = ele.dataset.row;
                const allElements = document.querySelectorAll(`.specialEducationPersonnelDevelopmentTrainingRecord[data-row='${row}']`);
                for (const innerElement of allElements) {
                    const thisEle = innerElement;
                    if (thisEle.classList.contains("multiSelect")) {
                        let multiselectCheckboxes = thisEle.getElementsByTagName("input");
                        let hasSelection = false;
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }
                        if (!hasSelection) {
                            this._core.forceElementRequired(thisEle);
                        }
                    }
                    else {
                        this._core.forceElementRequired(thisEle);
                    }
                }
            }
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = element;
                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");
                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated = false;
                                    let isChecked = false;
                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }
                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }
                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            }
                            else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLSelectElement) {
                            let selectElement = element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                            let hasSelection = false;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }
                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (htmlElement.classList.contains("multiSelect")) {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                            let hasSelection = false;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }
                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    bindDeleteTraining() {
        const allDeletes = document.getElementsByClassName("deleteTraining");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e) => this.showDeleteTraining(e));
    }
    showDeleteTraining(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const dataPointRowNumber = button.dataset.datapointrownumber;
        const propertyCode = button.dataset.propertycode;
        const modal = new Modal("deleteTrainingModal", null);
        modal.addAttributeToElement("deleteTrainingModal", "#deleteTrainingConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteTrainingModal", "#deleteTrainingConfirm", "datapointrownumber", dataPointRowNumber);
        modal.addAttributeToElement("deleteTrainingModal", "#deleteTrainingConfirm", "propertycode", propertyCode);
        modal.show();
    }
    deleteTrainingCancel() {
        const modal = new Modal("deleteTrainingModal", null);
        modal.hide();
    }
    deleteTrainingConfirm(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        let dataPointRowNumber = button.dataset.datapointrownumber;
        const propertyCode = button.dataset.propertycode;
        let count = 0;
        let personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertyCode='${propertyCode}']`);
        for (let thisRow of personnelRows) {
            count++;
        }
        if (count > 1) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteTraining', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let theRow = document.querySelector(`.personnel-development-row[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);
                    if (theRow !== null)
                        theRow.remove();
                    const modal = new Modal("deleteTrainingModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The training record has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify({
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                RowNbr: dataPointRowNumber
            }));
        }
        else {
            //show alert to let them know to just change the data
            alert("This is the last row.  Please change your data.");
        }
    }
    bindDeleteTrainingTopic() {
        const allDeletes = document.getElementsByClassName("deleteTrainingTopic");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e) => this.showDeleteTrainingTopic(e));
    }
    showDeleteTrainingTopic(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const dataPointRowNumber = button.dataset.datapointrownumber;
        const modal = new Modal("deleteTrainingTopicModal", null);
        modal.addAttributeToElement("deleteTrainingTopicModal", "#deleteTrainingTopicConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteTrainingTopicModal", "#deleteTrainingTopicConfirm", "datapointrownumber", dataPointRowNumber);
        modal.show();
    }
    deleteTrainingTopicCancel() {
        const modal = new Modal("deleteTrainingTopicModal", null);
        modal.hide();
    }
    deleteTrainingTopicConfirm(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const dataPointRowNumber = button.dataset.datapointrownumber;
        let planProperty = [];
        const allPlanPropertyElements = document.querySelectorAll(`input[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);
        for (const element of allPlanPropertyElements) {
            if (element.dataset.planpropertypk !== null && element.dataset.planpropertypk !== "") {
                if (parseInt(element.dataset.planpropertypk) > 0) {
                    planProperty.push(element.dataset.planpropertypk);
                }
            }
        }
        if (planProperty.length === 0) {
            Core.hideLoader();
            const topRow = document.querySelector(`.personnelDevelopmentTraining[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const accordionButton = document.querySelector(`#personnelDevelopmentAccordionSpecialEducationPersonnelOther${dataPointPlanPropertyPK}`);
            accordionButton.parentElement.remove();
            topRow.parentElement.remove();
            const modal = new Modal("deleteTrainingTopicModal", null);
            modal.hide();
        }
        else {
            const deleteData = {
                "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                "AllPlanProperties": planProperty
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteTrainingTopic', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    const topRow = document.querySelector(`.personnelDevelopmentTraining[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    const accordionButton = document.querySelector(`#personnelDevelopmentAccordionSpecialEducationPersonnelOther${dataPointPlanPropertyPK}`);
                    accordionButton.parentElement.remove();
                    topRow.parentElement.remove();
                    const modal = new Modal("deleteTrainingTopicModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The training record has been removed.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(deleteData));
        }
    }
}
// Special Education Signatures & Affirmations
class SpecialEducationSignaturesAndAffirmations {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationSignaturesAndAffirmationsField", "specialEducationSignaturesAndAffirmationsCheckboxField", "specialEducationSignaturesAndAffirmationsFileField"];
        let specialEducationSignaturesAndAffirmationsFormSaveButton = document.getElementById("specialEducationSignaturesAndAffirmationsSave");
        if (specialEducationSignaturesAndAffirmationsFormSaveButton !== null)
            specialEducationSignaturesAndAffirmationsFormSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fileUploader = document.getElementById("uploadMinutes");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteButtons();
        const exports = document.getElementsByClassName("fullReport");
        for (const exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLoader();
                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocX/FullReportSpecialEducationExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    Core.hideLoader();
                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = "SpecialEducationReport_Full";
                        filename = filename + ".docx";
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        }
                        else {
                            var a = document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                };
                xhr.send(`planFK=${exportOption.dataset.planfk}`);
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let specialEducationSignaturesAndAffirmationsFormElement = document.getElementById("specialEducationSignaturesAndAffirmationsForm");
        let inputs = document.getElementsByClassName("specialEducationSignaturesAndAffirmationsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(specialEducationSignaturesAndAffirmationsFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("specialEducationSignatureAndAffirmationCheckField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "on",
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSignaturesAndAffirmations', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = document.getElementById("uploadMinutes");
        let formData = new FormData(uploadSampleForm);
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("uploadMinutes");
                        formFile.value = "";
                        formFile.dataset.hasUploaded = "true";
                        that.bindDeleteButtons();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");
        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// Special Education Summary Checklist & Submission
class SpecialEducationSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationSummaryChecklistAndSubmissionField"];
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
//Common Validation functions (Class)
class ImprovementAndPlanningValidation {
    static doCustomValidation(checkboxFieldClass, textboxFieldClass) {
        let core = new Core();
        let isChecked = false;
        let checkboxElements = document.getElementsByClassName(checkboxFieldClass);
        let checkboxElement = document.createElement("input");
        for (let checkbox of checkboxElements) {
            checkboxElement = checkbox;
        }
        if (checkboxElement.checked)
            isChecked = true;
        let uniqueFields = document.querySelectorAll(`.${textboxFieldClass}[data-percent='1.00']`);
        let rows = [];
        for (let field of uniqueFields) {
            let ele = field;
            let thisRow = ele.dataset.row;
            if (rows.indexOf(thisRow) < 0)
                rows.push(thisRow);
        }
        let isValid = false;
        let firstValid = false;
        let secondValid = false;
        for (let row of rows) {
            let first = true;
            firstValid = false;
            secondValid = false;
            let elementsInRow = uniqueFields.forEach(element => {
                if (element.dataset.row === row) {
                    if (first) {
                        if (element.value !== "")
                            firstValid = true;
                        first = false;
                    }
                    else {
                        if (element.value !== "")
                            secondValid = true;
                    }
                }
                if (firstValid && secondValid) {
                    isValid = true;
                    return;
                }
            });
        }
        let showTheMessage = isChecked || isValid;
        let validationClasses = [textboxFieldClass, checkboxFieldClass];
        core.clientSideValidation(validationClasses);
        let showMessage = !showTheMessage;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
}
// Special Education Monitoring of Personnel Development
class SpecialEducationMonitoringPersonnelDevelopment {
    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = ["specialEducationPersonnelDevelopmentField"];
        this._deleteTrainingCompletionConfirmModal = new Modal("deleteTrainingCompletionConfirmModal", null);
        let specialEducationSpecialEducationPersonnelDevelopmentSaveButton = document.getElementById("specialEducationMonitoringPersonnelDevelopmentSave");
        if (specialEducationSpecialEducationPersonnelDevelopmentSaveButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentSaveButton.addEventListener("click", (e) => this.save("save"));
        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton = document.getElementById("specialEducationPersonnelDevelopmentAddTrainingTopic");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicButton.addEventListener("click", (e) => this.addTrainingTopic());
        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton = document.getElementById("specialEducationAddTrainingTopicNameCreate");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCreateButton.addEventListener("click", (e) => this.addTrainingTopicCreate());
        let specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton = document.getElementById("specialEducationAddTrainingTopicNameCancel");
        if (specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton !== null)
            specialEducationSpecialEducationPersonnelDevelopmentAddTrainingTopicCancelButton.addEventListener("click", (e) => this.addTrainingTopicCancel());
        this.bindAddTrainingButtons();
        this.bindAddMonitoringButtons();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.bindUploaders();
        this.bindDeleteFiles();
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        document.addEventListener("click", (e) => {
            let target = e.target;
            //Adding a support
            if (target.classList.contains("deleteTrainingCompletion") && "trainingPk" in target.dataset) {
                that.deleteTrainingCompletion(e);
            }
        });
        let deleteTrainingCompletionConfirmButton = document.getElementById("deleteTrainingCompletionConfirm");
        if (deleteTrainingCompletionConfirmButton !== null)
            deleteTrainingCompletionConfirmButton.addEventListener("click", () => { that.deleteTrainingCompletionConfirm(); });
        let deleteTrainingCompletionCancelButton = document.getElementById("deleteTrainingCompletionCancel");
        if (deleteTrainingCompletionCancelButton !== null)
            deleteTrainingCompletionCancelButton.addEventListener("click", () => { that._deleteTrainingCompletionConfirmModal.hide(); });
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = document.getElementById("specialEducationMonitoringPersonnelDevelopmentForm");
        let planPK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);
        let personnelDevelopmentMonitoring = document.getElementsByClassName("personnelDevelopmentMonitoring");
        let planMonitoringPK = 0;
        if (personnelDevelopmentMonitoring.length > 0)
            planMonitoringPK = parseInt((personnelDevelopmentMonitoring[0]).dataset.planmonitoringfk);
        let monitoringData;
        let monitoring = {
            PlanMonitoringPK: planMonitoringPK,
            PlanFK: planPK,
            PersonnelDevelopment: []
        };
        let personnelDevelopments = document.getElementsByClassName("personnelDevelopmentTraining");
        for (let personnelDevelopment of personnelDevelopments) { //Autism, Parent Training, etc
            let personnelDevelopmentElement = personnelDevelopment;
            let personnelDevelopmentRows = personnelDevelopmentElement.querySelectorAll(".personnel-development-row");
            for (let personnelDevelopmentRow of personnelDevelopmentRows) {
                let personnelDevelopmentRowElement = personnelDevelopmentRow;
                let dataPointPlanPropertyPK = personnelDevelopmentRowElement.dataset.datapointplanpropertypk;
                let dataPointRowNumber = personnelDevelopmentRowElement.dataset.datapointrownumber;
                let descriptionOfTrainingElement = personnelDevelopmentRowElement.querySelector(`.specialEducationPersonnelDevelopmentDescriptionOfTraining[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);
                if (descriptionOfTrainingElement) {
                    let planPropertyPK = parseInt(descriptionOfTrainingElement.dataset.planpropertypk);
                    if (planPropertyPK > 0) {
                        let dataPointRow = parseInt(personnelDevelopmentRowElement.dataset.datapointrownumber);
                        let personnelDevelopmentData = {
                            PlanMonitoringPersonnelDevelopmentPK: 0,
                            PlanPropertyPK: planPropertyPK,
                            RowNbr: dataPointRow,
                            PersonnelDevelopmentTraining: []
                        };
                        let trainingRows = personnelDevelopmentRowElement.querySelectorAll(`.monitoring[data-datapointrownumber='${dataPointRow}'] .monitoring-row`);
                        for (let trainingRow of trainingRows) {
                            let trainingRowElement = trainingRow;
                            let planMonitoringPersonnelDevelopmentFK = trainingRowElement.dataset.planmonitoringpersonneldevelopmentpk;
                            personnelDevelopmentData.PlanMonitoringPersonnelDevelopmentPK = parseInt(planMonitoringPersonnelDevelopmentFK);
                            let planMonitoringPersonnelDevelopmentTrainingPK = trainingRowElement.dataset.planmonitoringpersonneldevelopmenttrainingpk;
                            let row = parseInt(trainingRowElement.dataset.row);
                            let dateField = trainingRowElement.querySelector(`.specialEducationPersonnelDevelopmentMonitoringField[data-row='${row}']`);
                            let date = dateField.value;
                            let agendaFiles = trainingRowElement.querySelectorAll(`.uploadAgendaFileList[data-row='${row}'] .uploaded-file-container a`);
                            let agendaFileData = [];
                            for (let agendaFile of agendaFiles) {
                                let ele = agendaFile;
                                let file = {
                                    FileUploadPK: parseInt(ele.dataset.fileuploadpk),
                                    MonitoringFilePK: parseInt(ele.dataset.planmonitoringpersonneldevelopmentmonitoringfilepk),
                                    RowNbr: row
                                };
                                agendaFileData.push({
                                    FileUploadPK: file.FileUploadPK,
                                    MonitoringFilePK: file.MonitoringFilePK,
                                    RowNbr: file.RowNbr
                                });
                            }
                            let attendanceFiles = trainingRowElement.querySelectorAll(`.uploadAttendanceFileList[data-row='${row}'] .uploaded-file-container a`);
                            let attendanceFileData = [];
                            for (let attendanceFile of attendanceFiles) {
                                let ele = attendanceFile;
                                let file = {
                                    FileUploadPK: parseInt(ele.dataset.fileuploadpk),
                                    MonitoringFilePK: parseInt(ele.dataset.planmonitoringpersonneldevelopmentmonitoringfilepk),
                                    RowNbr: row
                                };
                                attendanceFileData.push({
                                    FileUploadPK: file.FileUploadPK,
                                    MonitoringFilePK: file.MonitoringFilePK,
                                    RowNbr: file.RowNbr
                                });
                            }
                            let trainingData = {
                                SpecialEducationMonitoringPersonnelDevelopmentTrainingPK: parseInt(planMonitoringPersonnelDevelopmentTrainingPK),
                                Date: date,
                                RowNbr: row,
                                AgendaFiles: agendaFileData,
                                AttendanceFiles: attendanceFileData
                            };
                            personnelDevelopmentData.PersonnelDevelopmentTraining.push({
                                AgendaFiles: trainingData.AgendaFiles,
                                AttendanceFiles: trainingData.AttendanceFiles,
                                Date: trainingData.Date,
                                RowNbr: trainingData.RowNbr,
                                SpecialEducationMonitoringPersonnelDevelopmentTrainingPK: trainingData.SpecialEducationMonitoringPersonnelDevelopmentTrainingPK
                            });
                        }
                        monitoring.PersonnelDevelopment.push({
                            PersonnelDevelopmentTraining: [...personnelDevelopmentData.PersonnelDevelopmentTraining],
                            PlanPropertyPK: personnelDevelopmentData.PlanPropertyPK,
                            RowNbr: personnelDevelopmentData.RowNbr,
                            PlanMonitoringPersonnelDevelopmentPK: personnelDevelopmentData.PlanMonitoringPersonnelDevelopmentPK
                        });
                    }
                }
            }
        }
        monitoringData = {
            PersonnelDevelopment: [...monitoring.PersonnelDevelopment],
            PlanMonitoringPK: monitoring.PlanMonitoringPK,
            PlanFK: planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/SaveSpecialEducationSpecialEducationPersonnelDevelopmentMonitoring', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The page information was successfully saved.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (monitoringData.PersonnelDevelopment.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(monitoringData));
        }
    }
    bindAddTrainingButtons() {
        let trainingButtons = document.getElementsByClassName("specialEducationPersonnelDevelopmentAddTraining");
        for (let trainingButton of trainingButtons) {
            let element = trainingButton;
            if (element.dataset.listener !== "true") {
                element.removeEventListener("click", (e) => this.addTraining(e));
                element.addEventListener("click", (e) => this.addTraining(e));
                element.dataset.listener = "true";
            }
        }
    }
    addTraining(e) {
        let core = this._core;
        let button = e.target;
        let planFK = button.dataset.planfk;
        let propertyCode = button.dataset.propertycode;
        let newRow = 0;
        let dataPointRowNumber = 0;
        let newTopicPlanPropertyPK = 0;
        let personnelRows = document.querySelectorAll(`.personnel-development-row[data-propertyCode='${propertyCode}'] .specialEducationPersonnelDevelopmentField`);
        for (let thisRow of personnelRows) {
            let thisRowElement = thisRow;
            if (button.dataset.datapointrownumber !== "" && button.dataset.datapointrownumber !== "0")
                dataPointRowNumber = parseInt(button.dataset.datapointrownumber);
            if (button.dataset.datapointplanpropertypk !== "" && button.dataset.datapointplanpropertypk !== "0")
                newTopicPlanPropertyPK = parseInt(button.dataset.datapointplanpropertypk);
            if (newRow < parseInt(thisRowElement.dataset.row))
                newRow = parseInt(thisRowElement.dataset.row);
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentTraining', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}']`);
                if (propertyCode.indexOf("Other") > 0)
                    element = document.querySelector(`.personnelDevelopmentTraining[data-propertycode='${propertyCode}'][data-row='${dataPointRowNumber}']`);
                var div = document.createElement("div");
                div.classList.add("personnel-development-row");
                div.dataset.propertycode = propertyCode;
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&rowNumber=${newRow}&propertyCode=${propertyCode}&newTopicPlanPropertyPK=${newTopicPlanPropertyPK}`);
    }
    addTrainingTopic() {
        let modal = new Modal("addATrainingModal", null);
        modal.show();
    }
    addTrainingTopicCancel() {
        let modal = new Modal("addATrainingModal", null);
        modal.hide();
    }
    addTrainingTopicCreate() {
        let newTopicNameElement = document.getElementById("specialEducationAddTrainingTopicName");
        let newTopicName = newTopicNameElement.value;
        let core = this._core;
        let planFK = 0;
        let specialEducationSpecialEducationPersonnelDevelopmentFormElement = document.getElementById("specialEducationSpecialEducationPersonnelDevelopmentForm");
        planFK = parseInt(specialEducationSpecialEducationPersonnelDevelopmentFormElement.dataset.planfk);
        let that = this;
        let row = 0;
        let rowElements = document.querySelectorAll(".personnel-development-row[data-propertycode='specialEducationPersonnelOther']");
        for (let rowElement of rowElements) {
            let ele = rowElement;
            if (row < parseInt(ele.dataset.row))
                row = parseInt(ele.dataset.row);
        }
        row++;
        if (newTopicName !== "") {
            let trainingTopic = {
                "PlanFK": planFK,
                "TopicName": newTopicName,
                "NewRow": row.toString()
            };
            let specialEducationAddTrainingTopicErrorMessage = document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.add("hide-message");
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentAddTrainingTopic', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let newId = `${newTopicName.replace(/\s/g, '')}customAccordion`;
                    let div = document.createElement("div");
                    div.innerHTML = xhr.responseText;
                    div.id = newId;
                    let trainingTopicsElement = document.getElementById("specialEducationTrainingTopics");
                    trainingTopicsElement.appendChild(div);
                    new CustomAccordion(newId);
                    let modalText = document.getElementById("specialEducationAddTrainingTopicName");
                    modalText.value = "";
                    let modal = new Modal("addATrainingModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "Successfully added a new training topic", 'success', 3000, null);
                    that.bindAddTrainingButtons();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(trainingTopic));
        }
        else {
            let specialEducationAddTrainingTopicErrorMessage = document.getElementById("specialEducationAddTrainingTopicErrorMessage");
            specialEducationAddTrainingTopicErrorMessage.classList.remove("hide-message");
        }
    }
    uploadFile(e, formClass) {
        let core = this._core;
        Core.showLoader();
        let element = e.target;
        let dataPointPlanPropertyFK = element.dataset.datapointplanpropertypk;
        let rowNumber = element.dataset.row;
        let topLevelRowNumber = element.dataset.toplevelrow;
        let uploadMinutesForm = document.querySelector(`.${formClass}[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${rowNumber}'][data-toplevelrow='${topLevelRowNumber}']`);
        let formData = new FormData(uploadMinutesForm);
        const that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.${formClass}File[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${rowNumber}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.${formClass}FileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-row='${rowNumber}'][data-toplevelrow='${topLevelRowNumber}']`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.monitoringfilepk = "0";
                        fileA.dataset.planmonitoringpersonneldevelopmentmonitoringfilepk = "0";
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.srcElement;
        let trainingPk = buttonElement.dataset.trainingpk;
        let trainingFileType = buttonElement.dataset.trainingfiletype;
        let trainingFilePk = buttonElement.dataset.trainingfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "trainingpk", trainingPk);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "trainingfiletype", trainingFileType);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "trainingfilepk", trainingFilePk);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.srcElement;
        let trainingPk = buttonElement.dataset.trainingpk;
        let trainingFileType = buttonElement.dataset.trainingfiletype;
        let trainingFilePk = buttonElement.dataset.trainingfilepk;
        const deleteData = {
            "trainingFilePk": trainingFilePk,
            "trainingFileType": trainingFileType
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-trainingpk='${trainingPk}'][data-trainingfiletype='${trainingFileType}'][data-trainingfilepk='${trainingFilePk}']`);
                    element.remove();
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(deleteData));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindAddMonitoringButtons() {
        let monitoringButtons = document.getElementsByClassName("specialEducationPersonnelDevelopmentAddTrainingCompletion");
        for (let monitoringButton of monitoringButtons)
            monitoringButton.addEventListener("click", (e) => this.addMonitoring(e));
    }
    addMonitoring(e) {
        let button = e.target;
        let core = this._core;
        let dataPointRowNumber = button.dataset.datapointrownumber;
        let dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        let newRow = 0;
        let planElement = document.getElementById("specialEducationMonitoringPersonnelDevelopmentForm");
        let planFK = planElement.dataset.planfk;
        let planMonitoringPersonnelDevelopmentFK = button.dataset.planmonitoringpersonneldevelopmentfk;
        let monitoringRows = document.querySelectorAll(`.monitoring-row[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);
        for (let thisRow of monitoringRows) {
            let thisRowElement = thisRow;
            if (newRow < parseInt(thisRowElement.dataset.row))
                newRow = parseInt(thisRowElement.dataset.row);
        }
        newRow++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SpecialEducation/AddPersonnelDevelopmentMonitoring', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                let element = document.querySelector(`.monitoring[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-datapointrownumber='${dataPointRowNumber}']`);
                var div = document.createElement("div");
                div.classList.add("monitoring-row");
                div.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                div.dataset.datapointrownumber = dataPointRowNumber;
                div.dataset.row = newRow.toString();
                div.dataset.planmonitoringpersonneldevelopmentpk = planMonitoringPersonnelDevelopmentFK;
                div.dataset.planmonitoringpersonneldevelopmenttrainingpk = "0";
                div.innerHTML = xhr.responseText;
                element.appendChild(div);
                that.bindUploaders();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}&dataPointRowNumber=${dataPointRowNumber}&newRow=${newRow}&planMonitoringPersonnelDevelopmentFK=${planMonitoringPersonnelDevelopmentFK}`);
    }
    deleteTrainingCompletion(e) {
        let button = e.target;
        this._trainingCompletionToDeletePK = parseInt(button.dataset.trainingPk);
        this._trainingCompletionToDeleteButton = button;
        this._deleteTrainingCompletionConfirmModal.show();
    }
    deleteTrainingCompletionConfirm() {
        let that = this;
        try {
            let container = Core.findClosest(that._trainingCompletionToDeleteButton, ".trainingCompletionContainer");
            let nextFocusable = Core.getNextFocusableElement(that._trainingCompletionToDeleteButton);
            if (that._trainingCompletionToDeletePK > 0) {
                Core.showLoader();
                fetch(`/SpecialEducation/DeleteTrainingCompletion?TrainingCompletionPK=${that._trainingCompletionToDeletePK}`, {
                    method: 'POST'
                })
                    .then((response) => {
                    Core.hideLoader();
                    return response.json();
                })
                    .then((json) => {
                    if (json.success) {
                        container.parentNode.removeChild(container);
                        Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted training completion', 'success', 3000, null);
                        that._deleteTrainingCompletionConfirmModal.hide();
                        if (nextFocusable) {
                            nextFocusable.focus();
                        }
                    }
                    else {
                        throw new Error("training completion deletion unsuccessful");
                    }
                })
                    .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue deleting training completion.', 'error', 3000, null);
                    Core.hideLoader();
                });
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted training completion', 'success', 3000, null);
                container.parentNode.removeChild(container);
                that._deleteTrainingCompletionConfirmModal.hide();
                if (nextFocusable) {
                    nextFocusable.focus();
                }
            }
        }
        catch (error) {
            Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue deleting training completion.', 'error', 3000, null);
        }
    }
    bindUploaders() {
        let agendaFileUploaders = document.getElementsByClassName("uploadAgenda");
        for (let agendaFileUploader of agendaFileUploaders)
            agendaFileUploader.addEventListener("change", (e) => this.uploadFile(e, "uploadAgenda"));
        let attendanceFileUploaders = document.getElementsByClassName("uploadAttendance");
        for (let attendanceFileUploader of attendanceFileUploaders)
            attendanceFileUploader.addEventListener("change", (e) => this.uploadFile(e, "uploadAttendance"));
    }
}
class SpecialEducationFacilitiesLazyAccordion extends LazyAccordion {
    loadContent(accordion, contentElement) {
        return __awaiter(this, void 0, void 0, function* () {
            const icon = accordion.querySelector(".lazy-accordion-loaded-icon");
            icon.classList.replace("empty", "loading");
            this.show(accordion, contentElement);
            const specialEducationFacilityFK = accordion.dataset.specialeducationfacilityfk;
            const action = accordion.dataset.action;
            const controller = accordion.dataset.controller;
            const url = `/${controller}/${action}/${specialEducationFacilityFK}`;
            //load
            const response = yield fetch(`${url}`, { credentials: 'include' });
            if (response.ok) {
                contentElement.innerHTML = yield response.text();
                icon.classList.replace("loading", "loaded");
                SpecialEducationSpecialEducationFacilities.constructAutofillInputs();
                SpecialEducationSpecialEducationFacilities.constructFileDeleteElements();
                SpecialEducationSpecialEducationFacilities.constructFileUploadElements();
                SpecialEducationSpecialEducationFacilities.bindSaveFacilities();
                SpecialEducationSpecialEducationFacilities.addFacilityHash(parseInt(specialEducationFacilityFK));
                SpecialEducationSpecialEducationFacilities.initializeLoadedFacility(parseInt(specialEducationFacilityFK));
                if (accordion.dataset.validdata === "false") {
                    SpecialEducationSpecialEducationFacilities.facilityValidation(parseInt(specialEducationFacilityFK));
                }
                $('.actionMultiSelect').MultiSelect({
                    ShowAllCheckbox: false
                });
                const tabContainer = document.querySelector(`.seprnContainer[data-facility-pk='${specialEducationFacilityFK}'] .row .review-container .review-container-actions .tab-container`);
                if (tabContainer)
                    new Tab(tabContainer);
                const seprnContainer = document.querySelector(`.seprnContainer[data-facility-pk='${specialEducationFacilityFK}']`);
                if (seprnContainer !== null) {
                    const workflowPK = seprnContainer.dataset.workflowInstancePk;
                    if (workflowPK && parseInt(workflowPK) > 0)
                        new Workflow(parseInt(workflowPK));
                }
            }
        });
    }
}
class SpecialEducationProgramCaseloadFTELazyAccordion extends LazyAccordion {
    loadContent(accordion, contentElement) {
        return __awaiter(this, void 0, void 0, function* () {
            const icon = accordion.querySelector(".lazy-accordion-loaded-icon");
            icon.classList.replace("empty", "loading");
            this.show(accordion, contentElement);
            const educationProgramFTEFK = accordion.dataset.educationprogramftefk;
            const action = accordion.dataset.action;
            const controller = accordion.dataset.controller;
            const url = `/${controller}/${action}/${educationProgramFTEFK}`;
            //load
            const response = yield fetch(`${url}`, { credentials: 'include' });
            if (response.ok) {
                contentElement.innerHTML = yield response.text();
                icon.classList.replace("loading", "loaded");
                //SpecialEducationFTETransferOfEntity.addFacilityHash(parseInt(specialEducationFacilityFK));
                SpecialEducationFTETransferOfEntity.initializeLoadedFTE(parseInt(educationProgramFTEFK));
                if (accordion.dataset.validdata === "false") {
                    SpecialEducationFTETransferOfEntity.fteValidation(parseInt(educationProgramFTEFK));
                }
            }
        });
    }
}
class SpecialEducationProgramCaseloadFTETransferOfEntityLazyAccordion extends LazyAccordion {
    loadContent(accordion, contentElement) {
        return __awaiter(this, void 0, void 0, function* () {
            const icon = accordion.querySelector(".lazy-accordion-loaded-icon");
            icon.classList.replace("empty", "loading");
            this.show(accordion, contentElement);
            const educationProgramFTETransferOfEntityFK = accordion.dataset.educationprogramftetransferofentityfk;
            const action = accordion.dataset.action;
            const controller = accordion.dataset.controller;
            const url = `/${controller}/${action}/${educationProgramFTETransferOfEntityFK}`;
            //load
            const response = yield fetch(`${url}`, { credentials: 'include' });
            if (response.ok) {
                contentElement.innerHTML = yield response.text();
                icon.classList.replace("loading", "loaded");
                SpecialEducationFTETransferOfEntity.initializeLoadedFTE(parseInt(educationProgramFTETransferOfEntityFK));
                if (accordion.dataset.validdata === "false") {
                    SpecialEducationFTETransferOfEntity.fteValidation(parseInt(educationProgramFTETransferOfEntityFK));
                }
                const tabContainer = document.querySelector(`.seprnContainer[data-ftetransferofentity-pk='${educationProgramFTETransferOfEntityFK}'] .row .review-container .review-container-actions .tab-container`);
                if (tabContainer)
                    new Tab(tabContainer);
                const seprnContainer = document.querySelector(`.seprnContainer[data-ftetransferofentity-pk='${educationProgramFTETransferOfEntityFK}']`);
                if (seprnContainer !== null) {
                    const workflowPK = seprnContainer.dataset.workflowInstancePk;
                    if (workflowPK && parseInt(workflowPK) > 0)
                        new Workflow(parseInt(workflowPK));
                }
            }
        });
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
//Profile
class HomeEducationProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["homeEducationProfileField"];
        let homeEducationProfileSaveButton = document.getElementById("homeEducationProfileSave");
        if (homeEducationProfileSaveButton !== null)
            homeEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("homeEducationProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("homeEducationProfileForm");
        let inputs = document.getElementsByClassName("homeEducationProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/HomeEducationPrivateTutoring/SaveHomeEducationPrivateTutoringProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("homeEducationProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/HomeEducationPrivateTutoring/SaveHomeEducationPrivateTutoringProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Signature & Assurance
class HomeEducationSignatureAndAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["homeEducationSignatureAndAssuranceField", "homeEducationSignatureAndAssuranceRadioField"];
        let homeEducationSignatureAndAssuranceSaveButton = document.getElementById("homeEducationSignatureAndAssuranceSave");
        if (homeEducationSignatureAndAssuranceSaveButton !== null)
            homeEducationSignatureAndAssuranceSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this.checkForNeedsDetail();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const homeEducationSignatureAndAssuranceACSReviews = document.getElementsByClassName("homeEducationSignatureAndAssuranceACSReview");
        for (const homeEducationSignatureAndAssuranceACSReview of homeEducationSignatureAndAssuranceACSReviews)
            homeEducationSignatureAndAssuranceACSReview.addEventListener("change", (e) => this.checkForNeedsDetail());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let signatureAndAssuranceFormElement = document.getElementById("homeEducationSignatureAndAssuranceForm");
        let inputs = document.getElementsByClassName("homeEducationSignatureAndAssuranceField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("homeEducationSignatureAndAssuranceRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/HomeEducationPrivateTutoring/SaveHomeEducationPrivateTutoringSignatureAndAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkForNeedsDetail() {
        const homeEducationSignatureAndAssuranceACSReviews = document.getElementsByClassName("homeEducationSignatureAndAssuranceACSReview");
        const homeEducationSignatureAndAssuranceReviewACSNoExplainationElement = document.getElementById("homeEducationSignatureAndAssuranceReviewACSNoExplaination");
        const homeEducationReviewNoMoreDetailsContainer = document.getElementById("homeEducationReviewNoMoreDetails");
        for (const homeEducationSignatureAndAssuranceACSReview of homeEducationSignatureAndAssuranceACSReviews) {
            const ele = homeEducationSignatureAndAssuranceACSReview;
            if (ele.checked) {
                if (ele.dataset.lookupcode === "yes") {
                    this._core.forceElementOptional(homeEducationSignatureAndAssuranceReviewACSNoExplainationElement);
                    homeEducationReviewNoMoreDetailsContainer.classList.add("hide");
                }
                else {
                    this._core.forceElementRequired(homeEducationSignatureAndAssuranceReviewACSNoExplainationElement);
                    homeEducationReviewNoMoreDetailsContainer.classList.remove("hide");
                }
            }
        }
    }
}
//Summary Checklist & Submission
class HomeEducationSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        this._core.leftnav(this);
        this.validationClasses = ["homeEducationSummaryChecklistAndSubmissionField"];
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/HomeEducationPrivateTutoring/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// HomeEducationACS
class HomeEducationACS {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["homeEducationACSField", "homeEducationACSRadioField"];
        this.changePrivateTutor();
        let homeEducationACSSaveButton = document.getElementById("homeEducationACSSave");
        if (homeEducationACSSaveButton !== null)
            homeEducationACSSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const acsButton = document.getElementById("homeEducationPrivateTutoringACS");
        if (acsButton !== null)
            acsButton.addEventListener("click", (e) => this.runACSReport());
        const privateTutorRadios = document.getElementsByClassName("privateTutorRadio");
        for (const privateTutorRadio of privateTutorRadios)
            privateTutorRadio.addEventListener("change", (e) => this.changePrivateTutor());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("homeEducationACSForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("homeEducationACSField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("homeEducationACSRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/HomeEducationPrivateTutoring/SaveHomeEducationACS', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    runACSReport() {
        let formElement = document.getElementById("homeEducationACSForm");
        let planFK = formElement.dataset.planfk;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/HomeEducationPrivateTutoring/GenerateHomeEducationPrivateTutoringACS', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "HomeEducationPrivateTutoringACS.pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    changePrivateTutor() {
        const privateTutorRadios = document.getElementsByClassName("privateTutorRadio");
        const homeEducationACSExplainthechangeinstudentnumbers = document.getElementById("homeEducationACSExplainthechangeinstudentnumbers");
        const homeEducationACSExplainthechangeinstudentnumbersContainer = document.getElementById("explainTheReason");
        for (const privateTutorRadio of privateTutorRadios) {
            const element = privateTutorRadio;
            if (element.checked) {
                if (element.dataset.lookupcode === "yes") {
                    homeEducationACSExplainthechangeinstudentnumbersContainer.classList.remove("hide");
                    this._core.forceElementRequired(homeEducationACSExplainthechangeinstudentnumbers);
                }
                else {
                    homeEducationACSExplainthechangeinstudentnumbersContainer.classList.add("hide");
                    this._core.forceElementOptional(homeEducationACSExplainthechangeinstudentnumbers);
                }
            }
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
//Profile
class InterscholasticAthleticOpportunitiesProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["interscholasticAthleticProfileField"];
        let interscholasticAthleticOpportunitiesProfileSaveButton = document.getElementById("interscholasticAthleticOpportunitiesProfileSave");
        if (interscholasticAthleticOpportunitiesProfileSaveButton !== null)
            interscholasticAthleticOpportunitiesProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("interscholasticAthleticProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("interscholasticAthleticProfileForm");
        let inputs = document.getElementsByClassName("interscholasticAthleticProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticOpportunitiesProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("interscholasticAthleticProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticOpportunitiesProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Signature & Assurance
class InterscholasticAthleticOpportunitiesSignatureAndAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["interscholasticAthleticOpportunitiesSignatureAndAssuranceField", "interscholasticAthleticOpportunitiesSignatureAndAssuranceRadioField", "interscholasticAthleticOpportunitiesSignatureAndAssuranceCheckboxField"];
        let interscholasticAthleticOpportunitiesSignatureAndAssuranceSaveButton = document.getElementById("interscholasticAthleticOpportunitiesSignatureAndAssuranceSave");
        if (interscholasticAthleticOpportunitiesSignatureAndAssuranceSaveButton !== null)
            interscholasticAthleticOpportunitiesSignatureAndAssuranceSaveButton.addEventListener("click", (e) => this.save("save"));
        this.checkForNeedsDetail();
        this.checkForNeedsSchoolList();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview");
        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview of interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews)
            interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview.addEventListener("change", (e) => this.checkForNeedsDetail());
        const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools");
        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool of interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools)
            interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool.addEventListener("change", (e) => this.checkForNeedsSchoolList());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let signatureAndAssuranceFormElement = document.getElementById("interscholasticAthleticOpportunitiesSignatureAndAssuranceForm");
        let inputs = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(signatureAndAssuranceFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticOpportunitiesSignatureAndAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkForNeedsDetail() {
        const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview");
        const interscholasticAthleticSignatureReviewACSReportNoDetailElement = document.getElementById("interscholasticAthleticSignatureReviewACSReportNoDetail");
        const interscholasticReviewNoMoreDetailsContainer = document.getElementById("interscholasticReviewNoMoreDetails");
        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview of interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReviews) {
            const ele = interscholasticAthleticOpportunitiesSignatureAndAssuranceACSReview;
            if (ele.checked) {
                if (ele.dataset.lookupcode === "yes") {
                    this._core.forceElementOptional(interscholasticAthleticSignatureReviewACSReportNoDetailElement);
                    interscholasticReviewNoMoreDetailsContainer.classList.add("hide");
                }
                else {
                    this._core.forceElementRequired(interscholasticAthleticSignatureReviewACSReportNoDetailElement);
                    interscholasticReviewNoMoreDetailsContainer.classList.remove("hide");
                }
            }
        }
    }
    checkForNeedsSchoolList() {
        const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools = document.getElementsByClassName("interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools");
        const interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurveyElement = document.getElementById("interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurvey");
        const interscholasticIdentifySchoolsContainer = document.getElementById("interscholasticIdentifySchools");
        for (const interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool of interscholasticAthleticOpportunitiesSignatureAndAssuranceSchools) {
            const ele = interscholasticAthleticOpportunitiesSignatureAndAssuranceSchool;
            if (ele.checked) {
                if (ele.dataset.lookupcode === "yes") {
                    this._core.forceElementOptional(interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurveyElement);
                    interscholasticIdentifySchoolsContainer.classList.add("hide");
                }
                else {
                    this._core.forceElementRequired(interscholasticAthleticSignaturePleaseidentifyalleligibleschoolsnotincludedinthesurveyElement);
                    interscholasticIdentifySchoolsContainer.classList.remove("hide");
                }
            }
        }
    }
}
// InterscholasticAthleticACS
class InterscholasticAthleticACS {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["interscholasticAthleticACSField", "interscholasticAthleticACSRadioField"];
        let interscholasticAthleticACSSaveButton = document.getElementById("interscholasticAthleticACSSave");
        if (interscholasticAthleticACSSaveButton !== null)
            interscholasticAthleticACSSaveButton.addEventListener("click", (e) => this.save("save"));
        this.changePIAASportsTeam();
        this.changeCurrentAndPastYears();
        this.changeIsAccurate();
        this.changeGreaterThan20();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const acsButton = document.getElementById("interscholasticAthleticOpportunitiesACS");
        if (acsButton !== null)
            acsButton.addEventListener("click", (e) => this.runACSReport());
        const piaaSportsTeamsRadios = document.getElementsByClassName("piaaSportsTeamsRadio");
        for (const piaaSportsTeamsRadio of piaaSportsTeamsRadios)
            piaaSportsTeamsRadio.addEventListener("change", (e) => this.changePIAASportsTeam(true));
        const currentAndPastYearRadios = document.getElementsByClassName("currentAndPastYearRadio");
        for (const currentAndPastYearRadio of currentAndPastYearRadios)
            currentAndPastYearRadio.addEventListener("change", (e) => this.changeCurrentAndPastYears());
        const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
        for (const isThisAccurateRadio of isThisAccurateRadios)
            isThisAccurateRadio.addEventListener("change", (e) => this.changeIsAccurate(true));
        const isThisAccurateCloseButton = document.getElementById("isThisAccurateClose");
        if (isThisAccurateCloseButton !== null)
            isThisAccurateCloseButton.addEventListener("click", (e) => this.isThisAccurateCloseModal());
        const piaaSportsTeamCloseButton = document.getElementById("piaaSportsTeamClose");
        if (piaaSportsTeamCloseButton !== null)
            piaaSportsTeamCloseButton.addEventListener("click", (e) => this.piaaSportsTeamCloseModal());
        const greaterThan20Radios = document.getElementsByClassName("greaterThan20Radio");
        for (const greaterThan20Radio of greaterThan20Radios)
            greaterThan20Radio.addEventListener("change", (e) => this.changeGreaterThan20());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("interscholasticAthleticACSForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("interscholasticAthleticACSField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("interscholasticAthleticACSRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/SaveInterscholasticAthleticACS', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    runACSReport() {
        let formElement = document.getElementById("interscholasticAthleticACSForm");
        let planFK = formElement.dataset.planfk;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/InterscholasticAthleticOpportunities/GenerateInterscholasticAthleticOpportunitiesACS', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "InterscholasticAthleticOpportunitiesACS.pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    changePIAASportsTeam(fromChange = false) {
        const piaaSportsTeamsRadios = document.getElementsByClassName("piaaSportsTeamsRadio");
        for (const piaaSportsTeamsRadio of piaaSportsTeamsRadios) {
            const element = piaaSportsTeamsRadio;
            if (element.checked) {
                const lookup = element.dataset.lookupcode;
                if (lookup === "no" && fromChange) {
                    const modal = new Modal("piaaSportsTeamModal", null);
                    modal.show();
                }
            }
        }
    }
    changeCurrentAndPastYears() {
        const currentAndPastYearRadios = document.getElementsByClassName("currentAndPastYearRadio");
        for (const currentAndPastYearRadio of currentAndPastYearRadios) {
            const element = currentAndPastYearRadio;
            const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
            const isThisAccurate = document.getElementById("isThisAccurate");
            for (const isThisAccurateRadio of isThisAccurateRadios) {
                const isThisAccurateElement = isThisAccurateRadio;
                if (element !== null && element.checked) {
                    if (element.dataset.lookupcode === "yes") {
                        isThisAccurate.classList.remove("hide");
                        this._core.forceElementRequired(isThisAccurateElement);
                    }
                    else {
                        isThisAccurate.classList.add("hide");
                        this._core.forceElementOptional(isThisAccurateElement);
                    }
                }
            }
        }
    }
    changeIsAccurate(fromChange = false) {
        const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
        for (const isThisAccurateRadio of isThisAccurateRadios) {
            const element = isThisAccurateRadio;
            if (element.checked) {
                const explainContainer = document.getElementById("isThisAccurateExplainContainer");
                const explainTextArea = document.getElementById("interscholasticAthleticACSIsthisaccurateExplain");
                if (element.dataset.lookupcode === "no" && fromChange) {
                    const modal = new Modal("isThisAccurateNoModal", null);
                    modal.show();
                    explainContainer.classList.add("hide");
                    this._core.forceElementOptional(explainTextArea);
                }
                else if (element.dataset.lookupcode === "yes") {
                    explainContainer.classList.remove("hide");
                    this._core.forceElementRequired(explainTextArea);
                }
                else {
                    explainContainer.classList.add("hide");
                    this._core.forceElementOptional(explainTextArea);
                }
            }
        }
    }
    piaaSportsTeamCloseModal() {
        const modal = new Modal("piaaSportsTeamModal", null);
        modal.hide();
    }
    isThisAccurateCloseModal() {
        const modal = new Modal("isThisAccurateNoModal", null);
        modal.hide();
    }
    changeGreaterThan20() {
        const greaterThan20Radios = document.getElementsByClassName("greaterThan20Radio");
        for (const greaterThan20Radio of greaterThan20Radios) {
            const element = greaterThan20Radio;
            if (element !== null && element.checked) {
                const explainationElement = document.getElementById("interscholasticAthleticACSExplainthechangeinstudentnumbers");
                const greaterThan20ReasonContainer = document.getElementById("greaterThan20Reason");
                if (element.dataset.lookupcode === "yes") {
                    greaterThan20ReasonContainer.classList.remove("hide");
                    this._core.forceElementRequired(explainationElement);
                }
                else {
                    greaterThan20ReasonContainer.classList.add("hide");
                    this._core.forceElementOptional(explainationElement);
                }
            }
        }
    }
    initializeRequiredFieldsCustom(allValidationClasses) {
        this._core.initializeRequiredFields(allValidationClasses);
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        /*
            Customized validation for this page:
                if piaaSportsTeamsRadio === no, add error.
                if currentAndPastYearRadio === yes && isThisAccurateRadio === no, add error.
        */
        const piaaSportsTeamsRadios = document.getElementsByClassName("piaaSportsTeamsRadio");
        for (const piaaSportsTeamsRadio of piaaSportsTeamsRadios) {
            const element = piaaSportsTeamsRadio;
            if (element !== null && element.checked) {
                if (element.dataset.lookupcode === "no") {
                    element.classList.add("missing-field");
                    element.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(element);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        const currentAndPastYearRadios = document.getElementsByClassName("currentAndPastYearRadio");
        for (const currentAndPastYearRadio of currentAndPastYearRadios) {
            const element = currentAndPastYearRadio;
            if (element !== null && element.checked) {
                if (element.dataset.lookupcode === "yes") {
                    const isThisAccurateRadios = document.getElementsByClassName("isThisAccurateRadio");
                    for (const isThisAccurateRadio of isThisAccurateRadios) {
                        const accurateElement = isThisAccurateRadio;
                        if (accurateElement !== null && accurateElement.checked) {
                            if (accurateElement.dataset.lookupcode === "no") {
                                accurateElement.classList.add("missing-field");
                                accurateElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(accurateElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        //Run the normal checks.
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox") {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                }
                                else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
//Summary Checklist & Submission
class InterscholasticAthleticOpportunitiesSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        this._core.leftnav(this);
        this.validationClasses = ["interscholasticAthleticOpportunitiesSummaryChecklistAndSubmissionField"];
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/InterscholasticAthleticOpportunities/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="../../../node_modules/foundation-sites/js/typescript/foundation.d.ts"/>
// Profile and Plan Essentials
class SchoolReconfigurationsProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["schoolReconfigurationsProfileField", "schoolReconfigurationsProfileSelectField", "schoolReconfigurationsProfileCheckboxField"];
        this.checkNewHire();
        let specialEducationProfileSaveButton = document.getElementById("schoolReconfigurationsProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("schoolReconfigurationsProfileForm"));
        const schoolReconfigurationsNewHireCheckbox = document.querySelector(".schoolReconfigurationsNewHire");
        if (schoolReconfigurationsNewHireCheckbox !== null)
            schoolReconfigurationsNewHireCheckbox.addEventListener("change", (e) => this.checkNewHire());
        this.bindDeleteFiles();
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        const fileUploader = document.getElementById("reconfigurationProfileCEODocumentation");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("schoolReconfigurationsProfileForm");
        const templateCode = profileFormElement.dataset.templatecode;
        let inputs = document.getElementsByClassName("schoolReconfigurationsProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("schoolReconfigurationsProfileCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("schoolReconfigurationsProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const allFileElements = [];
        let files = document.getElementsByClassName("schoolReconfigurationsProfileFileUploadField");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "SaveData": allSaveElements,
            "TemplateCode": templateCode,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("schoolReconfigurationsProfileForm");
        const templateCode = profileFormElement.dataset.templatecode;
        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const allFileElements = [];
        var allData = {
            "SaveData": allSaveElements,
            "TemplateCode": templateCode,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    checkNewHire() {
        const schoolReconfigurationsNewHireCheckbox = document.querySelector(".schoolReconfigurationsNewHire");
        const reconfigurationProfileChiefAdministratorName = document.getElementById("reconfigurationProfileChiefAdministratorName");
        const reconfigurationProfileChiefAdministratorEmail = document.getElementById("reconfigurationProfileChiefAdministratorEmail");
        const reconfigurationProfileNewChiefAdministratorName = document.getElementById("reconfigurationProfileNewChiefAdministratorName");
        const reconfigurationProfileNewChiefAdministratorEmail = document.getElementById("reconfigurationProfileNewChiefAdministratorEmail");
        if (reconfigurationProfileChiefAdministratorName !== null && reconfigurationProfileChiefAdministratorEmail !== null && reconfigurationProfileNewChiefAdministratorName !== null && reconfigurationProfileNewChiefAdministratorEmail !== null) {
            if (schoolReconfigurationsNewHireCheckbox.checked) {
                this._core.forceElementOptional(reconfigurationProfileChiefAdministratorName);
                this._core.forceElementOptional(reconfigurationProfileChiefAdministratorEmail);
                this._core.forceElementRequired(reconfigurationProfileNewChiefAdministratorName);
                this._core.forceElementRequired(reconfigurationProfileNewChiefAdministratorEmail);
            }
            else {
                this._core.forceElementRequired(reconfigurationProfileChiefAdministratorName);
                this._core.forceElementRequired(reconfigurationProfileChiefAdministratorEmail);
                this._core.forceElementOptional(reconfigurationProfileNewChiefAdministratorName);
                this._core.forceElementOptional(reconfigurationProfileNewChiefAdministratorEmail);
            }
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let row = e.dataset.row;
        const that = this;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.schoolReconfigurationsProfileFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList`);
                        let currentFileList = fileList.querySelectorAll(`.uploadFileList`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsProfileFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.schoolReconfigurationsProfileFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles`);
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
}
// Reconfiguration Details
class SchoolReconfigurationsDetail {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["schoolReconfigurationsDetailField", "schoolReconfigurationsDetailSelectField", "schoolReconfigurationsDetailCheckboxField"];
        let specialEducationProfileSaveButton = document.getElementById("schoolReconfigurationsDetailSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this.onlyTelephoneChange();
        this._core.leftnav(this);
        this.intializeCheckboxes();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            let showMessage = this.initializeRequiredFieldsCustom();
            this._core.doValidation(this.validationClasses, showMessage);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let modalCloseButton = document.getElementById("reconfigurationDetailsNoticeClose");
        if (modalCloseButton !== null)
            modalCloseButton.addEventListener("click", (e) => this.closeModal());
        let createNewReconfiguration = document.getElementById("schoolReconfigurationsCreate");
        if (createNewReconfiguration !== null)
            createNewReconfiguration.addEventListener("click", (e) => this.createNewReconfiguration());
        let schoolReconfigurationsAddMailingAddressOriginalButtons = document.getElementsByClassName("schoolReconfigurationsAddMailingAddressOriginal");
        for (let schoolReconfigurationsAddMailingAddressOriginalButton of schoolReconfigurationsAddMailingAddressOriginalButtons)
            schoolReconfigurationsAddMailingAddressOriginalButton.addEventListener("click", (e) => this.addMailingAddress(e));
        let schoolReconfigurationsAddMailingAddressProposedButtons = document.getElementsByClassName("schoolReconfigurationsAddMailingAddressProposed");
        for (let schoolReconfigurationsAddMailingAddressProposedButton of schoolReconfigurationsAddMailingAddressProposedButtons)
            schoolReconfigurationsAddMailingAddressProposedButton.addEventListener("click", (e) => this.addMailingAddress(e));
        let schoolReconfigurationsAddPhysicalAddressOriginalButtons = document.getElementsByClassName("schoolReconfigurationsAddPhysicalAddressOriginal");
        for (let schoolReconfigurationsAddPhysicalAddressOriginalButton of schoolReconfigurationsAddPhysicalAddressOriginalButtons)
            schoolReconfigurationsAddPhysicalAddressOriginalButton.addEventListener("click", (e) => this.addPhysicalAddress(e));
        let schoolReconfigurationsAddPhysicalAddressProposedButtons = document.getElementsByClassName("schoolReconfigurationsAddPhysicalAddressProposed");
        for (let schoolReconfigurationsAddPhysicalAddressProposedButton of schoolReconfigurationsAddPhysicalAddressProposedButtons)
            schoolReconfigurationsAddPhysicalAddressProposedButton.addEventListener("click", (e) => this.addPhysicalAddress(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e) => this.deleteFile(e));
        let formForNotice = document.getElementById("schoolReconfigurationsDetailForm");
        if (formForNotice.dataset.shownotice === "true") {
            let noticeModal = new Modal("noticeModal", null);
            noticeModal.show();
        }
        let exportToExcelButton = document.getElementById("schoolReconfigurationsDetailExportToExcel");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        this.bindCheckboxes();
        this.bindUploadButtons();
        this.bindSchoolNameSearch();
        this.bindAddEnrollmentButtons();
        this.bindAddEnrollmentChecks();
        this.bindDeleteSchoolReconfigurationButtons();
        let deleteReconfigurationCancelButton = document.getElementById("deleteReconfigurationCancel");
        if (deleteReconfigurationCancelButton !== null)
            deleteReconfigurationCancelButton.addEventListener("click", (e) => this.deleteReconfigurationCancel());
        let deleteReconfigurationConfirmButton = document.getElementById("deleteReconfigurationConfirm");
        if (deleteReconfigurationConfirmButton !== null)
            deleteReconfigurationConfirmButton.addEventListener("click", (e) => this.deleteReconfigurationConfirm(e));
        let reconfigurationDetailsNoReconfigurationsCheckbox = document.getElementById("reconfigurationdetailsnoreconfigurations");
        if (reconfigurationDetailsNoReconfigurationsCheckbox !== null)
            reconfigurationDetailsNoReconfigurationsCheckbox.addEventListener("click", (e) => this.noReconfigurations());
        let deleteReconfigurationEnrollmentCancelButton = document.getElementById("deleteEnrollmentCancel");
        if (deleteReconfigurationEnrollmentCancelButton !== null)
            deleteReconfigurationEnrollmentCancelButton.addEventListener("click", (e) => this.deleteEnrollmentRowCancel());
        let deleteReconfigurationEnrollmentConfirmButton = document.getElementById("deleteEnrollmentConfirm");
        if (deleteReconfigurationEnrollmentConfirmButton !== null)
            deleteReconfigurationEnrollmentConfirmButton.addEventListener("click", (e) => this.deleteEnrollmentRowConfirm(e));
        this.noReconfigurations();
        this.bindDeleteSchoolEnrollmentButtons();
        this.bindDeleteButtons();
        this.bindReconfigurationPrincipalFileDeletes();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    intializeCheckboxes() {
        let reconfigurationDetailsNoReconfigurationsCheckbox = document.getElementById("reconfigurationdetailsnoreconfigurations");
        if (reconfigurationDetailsNoReconfigurationsCheckbox && !reconfigurationDetailsNoReconfigurationsCheckbox.checked) {
            let allCheckboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
            for (let checkbox of allCheckboxes) {
                let element = checkbox;
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                if (element.classList.contains("schoolReconfigurationsDetailAddressCheckboxField")) {
                    let proposed = element.dataset.proposed;
                    if (proposed === "mailing") {
                        let tabElement = document.querySelector(`.mailingAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        if (element.checked) {
                            tabElement.classList.remove("hide");
                            let inputElements = document.querySelectorAll("#mailingAddressProposed input");
                            for (let input of inputElements) {
                                let ele = input;
                                if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                    this._core.forceElementRequired(ele);
                                else
                                    this._core.forceElementOptional(ele);
                            }
                            let mailingContentElement = document.querySelector(`.mailingAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (mailingContentElement.classList.contains("hide"))
                                mailingContentElement.classList.remove("hide");
                            let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElements) {
                                let ele = reqElement;
                                this._core.forceElementRequired(ele);
                            }
                        }
                        else {
                            tabElement.classList.add("hide");
                            let mailingContentElement = document.querySelector(`.mailingAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (!mailingContentElement.classList.contains("hide"))
                                mailingContentElement.classList.add("hide");
                            let toBeRequiredElementsNR = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElementsNR) {
                                let ele = reqElement;
                                this._core.forceElementOptional(ele);
                            }
                        }
                    }
                    else if (proposed === "physical") {
                        let physicalTabElement = document.querySelector(`.physicalAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`); //There will only be 1 here.
                        if (element.checked) {
                            physicalTabElement.classList.remove("hide");
                            let inputElements = document.querySelectorAll(`.physicalAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                            for (let input of inputElements) {
                                let ele = input;
                                if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                    this._core.forceElementRequired(ele);
                                else
                                    this._core.forceElementOptional(ele);
                            }
                            let physicalContentElement = document.querySelector(`.physicalAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (physicalContentElement.classList.contains("hide"))
                                physicalContentElement.classList.remove("hide");
                            let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElements) {
                                let ele = reqElement;
                                this._core.forceElementRequired(ele);
                            }
                        }
                        else {
                            if (physicalTabElement)
                                physicalTabElement.classList.add("hide");
                            let physicalContentElement = document.querySelector(`.physicalAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            if (!physicalContentElement.classList.contains("hide"))
                                physicalContentElement.classList.add("hide");
                            let toBeRequiredElementsNR = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                            for (let reqElement of toBeRequiredElementsNR) {
                                let ele = reqElement;
                                this._core.forceElementOptional(ele);
                            }
                        }
                    }
                    else if (proposed === "same") {
                        let container = document.querySelector(`.physicalAddressContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        let elements = container.querySelectorAll("input");
                        if (element.checked) {
                            for (let ele of elements) {
                                ele.disabled = true;
                                ele.readOnly = true;
                                this._core.forceElementOptional(ele);
                            }
                            let buttons = container.querySelectorAll("button");
                            for (let ele of buttons) {
                                ele.disabled = true;
                            }
                        }
                        else {
                            let buttons = container.querySelectorAll("button");
                            for (let ele of buttons) {
                                if (ele.disabled)
                                    ele.disabled = false;
                            }
                        }
                    }
                }
                else {
                    if (element.checked) {
                        let proposedType = element.dataset.proposed;
                        let proposedElementBox = document.querySelector(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        if (proposedElementBox !== null) {
                            if (element.checked) {
                                proposedElementBox.classList.remove("hide");
                                let allInputs = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                                for (let input of allInputs) {
                                    let inputEle = input;
                                    if (!inputEle.classList.contains("schoolReconfigurationsDetailAddressLine2Field") && !inputEle.classList.contains("notForced"))
                                        this._core.forceElementRequired(inputEle);
                                    else
                                        this._core.forceElementOptional(inputEle);
                                }
                                let allSelects = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] select`);
                                for (let select of allSelects) {
                                    let selectEle = select;
                                    this._core.forceElementRequired(selectEle);
                                }
                            }
                            else {
                                proposedElementBox.classList.add("hide");
                                proposedElementBox.classList.remove("hide");
                                let allInputs = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                                for (let input of allInputs) {
                                    let inputEle = input;
                                    this._core.forceElementOptional(inputEle);
                                }
                                let allSelects = proposedElementBox.querySelectorAll(`.proposed-change[data-proposed='${proposedType}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] select`);
                                for (let select of allSelects) {
                                    let selectEle = select;
                                    this._core.forceElementOptional(selectEle);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    toggleProposed(e) {
        return __awaiter(this, void 0, void 0, function* () {
            let checkbox = e.target;
            let dataPointPlanPropertyPK = checkbox.dataset.datapointplanpropertypk;
            let proposed = checkbox.dataset.proposed;
            let proposedElement = document.querySelector(`.proposed-change[data-proposed='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (checkbox.checked) {
                proposedElement.classList.remove("hide");
                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = reqElement;
                    this._core.forceElementRequired(ele);
                }
                if (proposed === "principal") {
                    const institutionPK = checkbox.dataset.institutionpk;
                    let response = yield fetch(`/SchoolReconfigurations/GetICInfo/${institutionPK}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.json();
                        const nameElement = document.querySelector(`.currentPrincipal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        nameElement.value = output.principalName;
                    }
                }
                else if (proposed == "telephone") {
                    const institutionPK = checkbox.dataset.institutionpk;
                    let response = yield fetch(`/SchoolReconfigurations/GetICInfo/${institutionPK}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.json();
                        const phoneElement = document.querySelector(`.currentPhoneNumber[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        phoneElement.value = output.phone;
                    }
                }
            }
            else {
                proposedElement.classList.add("hide");
                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = reqElement;
                    this._core.forceElementOptional(ele);
                }
            }
            if (proposed === "school") {
                let pdeSection = document.querySelector(".school-reconfiguration-section[data-special='pde']");
                if (pdeSection) {
                    if (checkbox.checked)
                        pdeSection.classList.remove("hidden");
                    else
                        pdeSection.classList.add("hidden");
                }
            }
        });
    }
    toggleMailingProposed(e) {
        let checkboxElement = e.target;
        let dataPointPlanPropertyPK = checkboxElement.dataset.datapointplanpropertypk;
        let proposed = checkboxElement.dataset.proposed;
        if (proposed === "mailing") {
            let tabElement = document.querySelector(`.mailingAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (tabElement.classList.contains("hide"))
                tabElement.classList.remove("hide");
            else
                tabElement.classList.add("hide");
            let mailingContentElement = document.querySelector(`.mailingAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (mailingContentElement.classList.contains("hide")) {
                mailingContentElement.classList.remove("hide");
                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = reqElement;
                    this._core.forceElementRequired(ele);
                }
            }
            else {
                mailingContentElement.classList.add("hide");
                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = reqElement;
                    this._core.forceElementOptional(ele);
                }
            }
        }
        else if (proposed === "physical") {
            let physicalTabElement = document.querySelector(`.physicalAddressProposedTab[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (physicalTabElement.classList.contains("hide"))
                physicalTabElement.classList.remove("hide");
            else
                physicalTabElement.classList.add("hide");
            let physicalContentElement = document.querySelector(`.physicalAddressOriginalContent[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (physicalContentElement.classList.contains("hide")) {
                physicalContentElement.classList.remove("hide");
                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = reqElement;
                    this._core.forceElementRequired(ele);
                }
            }
            else {
                physicalContentElement.classList.add("hide");
                let toBeRequiredElements = document.querySelectorAll(`.canBeRequired[data-group='${proposed}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let reqElement of toBeRequiredElements) {
                    let ele = reqElement;
                    this._core.forceElementOptional(ele);
                }
            }
        }
        else if (proposed === "same") {
            let container = document.querySelector(`.physicalAddressContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            let elements = container.querySelectorAll("input");
            for (let ele of elements) {
                if (ele.disabled) {
                    ele.disabled = false;
                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                        this._core.forceElementRequired(ele);
                    else
                        this._core.forceElementOptional(ele);
                }
                else {
                    ele.disabled = true;
                    this._core.forceElementOptional(ele);
                }
                if (ele.readOnly) {
                    ele.readOnly = false;
                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                        this._core.forceElementRequired(ele);
                    else
                        this._core.forceElementOptional(ele);
                }
                else {
                    ele.readOnly = true;
                    this._core.forceElementOptional(ele);
                }
            }
            let buttons = container.querySelectorAll("button");
            for (let ele of buttons) {
                if (ele.disabled) {
                    ele.disabled = false;
                }
                else {
                    ele.disabled = true;
                }
            }
        }
    }
    createNewReconfiguration() {
        let dropdown = document.getElementById("reconfigurationdetailsschoolnamecreate");
        let dropdownValue = parseInt(dropdown.value);
        let planForm = document.getElementById("schoolReconfigurationsDetailForm");
        let planFK = planForm.dataset.planfk;
        let core = this._core;
        let that = this;
        let newRow = 0;
        if (dropdownValue > 0) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/AddReconfiguration', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let container = document.getElementById("schoolReconfigurationList");
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = xhr.responseText;
                    container.appendChild(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    that.bindCheckboxes();
                    that.bindUploadButtons();
                    that.bindDeleteSchoolReconfigurationButtons();
                    that.bindSchoolNameSearch();
                    let mailing = new Foundation.Tabs($(".mailingAddressTabs"));
                    let physical = new Foundation.Tabs($(".physicalAddressTabs"));
                    let reconfigurationDetailsNoReconfigurationsCheckbox = document.getElementById("reconfigurationdetailsnoreconfigurations");
                    if (reconfigurationDetailsNoReconfigurationsCheckbox !== null)
                        if (reconfigurationDetailsNoReconfigurationsCheckbox.checked)
                            reconfigurationDetailsNoReconfigurationsCheckbox.checked = false;
                    reconfigurationDetailsNoReconfigurationsCheckbox.disabled = true;
                    Core.createHTMLAlert("alertMessageDiv", "School Reconfiguration Added", 'success', 2000, window.location.reload());
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                }
            };
            xhr.send(`institutionPK=${dropdownValue}&planFK=${planFK}&row=${newRow}`);
        }
    }
    addMailingAddress(e) {
        let planForm = document.getElementById("schoolReconfigurationsDetailForm");
        let planFK = planForm.dataset.planfk;
        let core = this._core;
        let that = this;
        let type = e.target.dataset.type;
        Core.showLoader();
        let dataPointPlanPropertyPK = e.target.dataset.datapointplanpropertypk;
        let currentRows = document.querySelectorAll(`.school-reconfiguration-mailingaddress[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let currentRow = 0;
        for (let thisRow of currentRows) {
            let thisRowElement = thisRow;
            let thisRowVal = parseInt(thisRowElement.dataset.row);
            if (thisRowVal > currentRow)
                currentRow = thisRowVal;
        }
        currentRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/AddMailingAddress', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.querySelector(`.mailAddressList[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                var parser = new DOMParser();
                var el = parser.parseFromString(xhr.responseText, "text/html");
                let actualNode = el.childNodes[0].querySelector(".school-reconfiguration-mailingaddress");
                container.appendChild(actualNode);
                Core.createHTMLAlert("alertMessageDiv", "School Address Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&row=${currentRow}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}&addressType=${type}`);
    }
    addPhysicalAddress(e) {
        let planForm = document.getElementById("schoolReconfigurationsDetailForm");
        let planFK = planForm.dataset.planfk;
        let core = this._core;
        let that = this;
        let type = e.target.dataset.type;
        Core.showLoader();
        let dataPointPlanPropertyPK = e.target.dataset.datapointplanpropertypk;
        let currentRows = document.querySelectorAll(`.school-reconfiguration-mailingaddress[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let currentRow = 0;
        for (let thisRow of currentRows) {
            let thisRowElement = thisRow;
            let thisRowVal = parseInt(thisRowElement.dataset.row);
            if (thisRowVal > currentRow)
                currentRow = thisRowVal;
        }
        currentRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/AddMailingAddress', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.querySelector(`.mailPhysicalList[data-type='${type}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                var parser = new DOMParser();
                var el = parser.parseFromString(xhr.responseText, "text/html");
                let actualNode = el.childNodes[0].querySelector(".school-reconfiguration-mailingaddress");
                container.appendChild(actualNode);
                Core.createHTMLAlert("alertMessageDiv", "School Address Added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&row=${currentRow}&dataPointPlanPropertyPK=${dataPointPlanPropertyPK}&addressType=${type}`);
    }
    bindCheckboxes() {
        let checkboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
        for (let checkbox of checkboxes)
            if (!checkbox.classList.contains("schoolReconfigurationsDetailAddressCheckboxField") && !checkbox.classList.contains("schoolReconfigurationPrincipalCharterSchool"))
                if (!checkbox.classList.contains("schoolReconfigurationsDetailOnlyTelephoneChange"))
                    checkbox.addEventListener("change", (e) => this.toggleProposed(e));
                else
                    checkbox.addEventListener("change", (e) => this.toggleMailingProposed(e));
        const schoolReconfigurationPrincipalCharterSchoolCheckboxes = document.getElementsByClassName("schoolReconfigurationPrincipalCharterSchool");
        for (const schoolCheck of schoolReconfigurationPrincipalCharterSchoolCheckboxes)
            schoolCheck.addEventListener("change", (e) => this.togglePrincipalCharterSchool(e));
        const schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox = document.querySelector(".schoolReconfigurationsDetailOnlyTelephoneChange");
        if (schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox !== null)
            schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox.addEventListener("change", (e) => this.onlyTelephoneChange());
    }
    bindUploadButtons() {
        let fileUploaders = document.getElementsByClassName("schoolReconfigurationsDetailUploadFileField");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        const uploadType = e.dataset.uploadtype;
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.schoolReconfigurationsDetailFileField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`);
                        let currentFileList = document.querySelectorAll(`.uploadFileColumn[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}'][data-uploadtype='${uploadType}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add(`schoolReconfigurationsDetailFileUploadField`);
                        fileA.classList.add(`schoolReconfigurationsFileUploadFileField`);
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");
        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    bindAddEnrollmentButtons() {
        let allEnrollmentAddButtons = document.getElementsByClassName("schoolReconfigurationEnrollmentChangeEntryAdd");
        for (let enrollmentAddButton of allEnrollmentAddButtons)
            enrollmentAddButton.addEventListener("click", (e) => this.addEnrollmentRecord(e));
    }
    bindAddEnrollmentChecks() {
        let allElements = document.getElementsByClassName("schoolReconfigurationEnrollmentChangeEntry");
        for (let element of allElements)
            element.addEventListener("input", (e) => this.checkEnrollmentForComplete(e));
    }
    deleteFile(e) {
        const fileButton = e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = fileButton.dataset.datapointplanpropertypk;
        const fileGroup = fileButton.dataset.filegroup;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
            deleteConfirmButton.dataset.filegroup = fileGroup;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let dataPointPlanPropertyPK = confirmButton.dataset.datapointplanpropertypk;
        const fileGroup = confirmButton.dataset.filegroup;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.remove();
                        }
                        if (fileGroup === "principal") {
                            let uploads = document.querySelectorAll(`.uploadFileList[data-uploadtype='charter'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .uploaded-file-container`);
                            if (uploads.length === 0) {
                                let uploadContainer = document.querySelector(`.uploadedPrinicipalFiles[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                                uploadContainer.classList.add("hide");
                            }
                        }
                        else if (fileGroup === "gradeconfig") {
                            let uploads = document.querySelectorAll(`.uploadMultiyearChangeFileList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .uploaded-file-container`);
                            if (uploads.length === 0) {
                                let uploadContainer = document.querySelector(`.multiGradeUploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                                uploadContainer.classList.add("hide");
                            }
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("schoolReconfigurationsDetailForm");
        let inputs = document.getElementsByClassName("schoolReconfigurationsDetailField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let enrollments = document.querySelectorAll(".schoolReconfigurationEnrollmentChangeEntryValue[data-isnewdata='True']");
        for (let ele of enrollments) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let isNewData = element.dataset.isnewdata;
            if (isNewData === "true" || isNewData === "True") {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.textContent,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let enrollmentGrades = document.querySelectorAll(".schoolReconfigurationEnrollmentChangeEntryGradeValue[data-isnewdata='True']");
        for (let ele of enrollmentGrades) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let lookupCodeFK = parseInt(element.dataset.gradelookupcodepk);
            let isNewData = element.dataset.isnewdata;
            if (isNewData === "true" || isNewData === "True") {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: lookupCodeFK,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let noReconfigCheckboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxNoReconfigurationsField");
        for (let ele of noReconfigCheckboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("schoolReconfigurationsDetailSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let files = document.getElementsByClassName("schoolReconfigurationsFileUploadFileField");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            let saveItem = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsDetail', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    closeModal() {
        let modal = new Modal("noticeModal", null);
        modal.hide();
    }
    initializeRequiredFieldsCustom() {
        let byPass = false;
        let showMessage = false;
        let totalErrors = 0;
        let reconfigurationDetailsNoReconfigurationsCheckbox = document.getElementById("reconfigurationdetailsnoreconfigurations");
        if (reconfigurationDetailsNoReconfigurationsCheckbox !== null) {
            if (reconfigurationDetailsNoReconfigurationsCheckbox.checked)
                byPass = true;
        }
        if (!byPass) {
            let checkboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
            for (let checkbox of checkboxes) {
                let checkboxElement = checkbox;
                let group = checkboxElement.dataset.group;
                let dataPointPlanPropertyPK = parseInt(checkboxElement.dataset.datapointplanpropertypk);
                if (checkboxElement.classList.contains("schoolReconfigurationsDetailAddressCheckboxField")) {
                    if (group === "mailing") {
                        let proposed = checkboxElement.dataset.proposed;
                        if (proposed === "same") {
                            let physicalFields = document.querySelectorAll(`.physicalAddressOriginal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                            if (checkboxElement.checked) {
                                for (let field of physicalFields) {
                                    let ele = field;
                                    this._core.forceElementOptional(ele);
                                }
                            }
                        }
                        else if (proposed === "mailing") {
                            let mailAddressProposedFields = document.querySelectorAll(`.mailingAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                            if (checkboxElement.checked) {
                                for (let field of mailAddressProposedFields) {
                                    let ele = field;
                                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                        this._core.forceElementRequired(ele);
                                    else
                                        this._core.forceElementOptional(ele);
                                }
                            }
                            else {
                                for (let field of mailAddressProposedFields) {
                                    let ele = field;
                                    this._core.forceElementOptional(ele);
                                }
                            }
                        }
                        else if (proposed === "physical") {
                            let mailAddressProposedFields = document.querySelectorAll(`.physicalAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                            if (checkboxElement.checked) {
                                for (let field of mailAddressProposedFields) {
                                    let ele = field;
                                    if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                        this._core.forceElementRequired(ele);
                                    else
                                        this._core.forceElementOptional(ele);
                                }
                            }
                            else {
                                for (let field of mailAddressProposedFields) {
                                    let ele = field;
                                    this._core.forceElementOptional(ele);
                                }
                            }
                        }
                    }
                    else if (group === "physical") {
                        let physicalAddressProposedFields = document.querySelectorAll(`.physicalAddressProposed[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] input`);
                        for (let field of physicalAddressProposedFields) {
                            if (checkboxElement.checked) {
                                //There was an issue with the physical address that causes the Line2 field to always become required, this gets around that.
                                let ele = field;
                                if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                    this._core.forceElementRequired(ele);
                                else
                                    this._core.forceElementOptional(ele);
                            }
                            else {
                                let ele = field;
                                this._core.forceElementOptional(ele);
                            }
                        }
                    }
                }
                else {
                    let fields = document.querySelectorAll(`input.schoolReconfigurationsDetailField[data-group='${group}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    if (checkboxElement.checked) {
                        for (let element of fields) {
                            let ele = element;
                            if (!ele.classList.contains("schoolReconfigurationsDetailAddressLine2Field"))
                                this._core.forceElementRequired(ele);
                            else
                                this._core.forceElementOptional(ele);
                        }
                    }
                }
            }
            let formattedAllClasses = [];
            formattedAllClasses.push(".schoolReconfigurationsDetailField");
            formattedAllClasses.push(".schoolReconfigurationsDetailSelectField");
            formattedAllClasses.push(".schoolReconfigurationsDetailCheckboxField");
            let classesToValidate = formattedAllClasses.join(",");
            let allElements = document.querySelectorAll(classesToValidate);
            for (let element of allElements) {
                let htmlElement = element;
                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        htmlElement.setAttribute("force-required", "true");
                        let label = Core.findLabelForInput(htmlElement);
                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
                //Manual override when data-forcerequired=true
                if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                    htmlElement.dataset.percent = "1.00";
                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        let label = Core.findLabelForInput(htmlElement);
                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }
            for (let element of allElements) {
                let htmlElement = element;
                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = element;
                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "text" && inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "date" && inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLSelectElement) {
                        let selectElement = element;
                        if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                            selectElement.classList.add("missing-field");
                            selectElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
        }
        let calculateCheckboxes = document.querySelectorAll(".schoolReconfigurationsDetailCheckboxField[data-group='enrollment']");
        for (let calc of calculateCheckboxes) {
            let ele = calc;
            let dataPointPlanPropertyPK = parseInt(ele.dataset.datapointplanpropertypk);
            let calculated = this.calculateEnrollments(dataPointPlanPropertyPK);
            let calculationErrorMessage = document.querySelector(`.calculation-error[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (calculated === false) {
                totalErrors++;
                calculationErrorMessage.classList.remove("hide");
                showMessage = true;
            }
            else {
                calculationErrorMessage.classList.add("hide");
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    exportToExcel() {
        let that = this;
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("schoolReconfigurationsDetailForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SchoolReconfigurationsDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SchoolReconfigurationDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    bindDeleteSchoolReconfigurationButtons() {
        let buttons = document.getElementsByClassName("deleteThisSchoolReconfiguration");
        for (let button of buttons)
            button.addEventListener("click", (e) => this.deleteSchoolReconfiguration(e));
    }
    deleteSchoolReconfiguration(e) {
        this.showDeleteRowConfirm(e);
    }
    showDeleteRowConfirm(e) {
        let buttonElement = e.target;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let modal = new Modal("deleteSchoolReconfigurationModal", null);
        modal.addAttributeToElement("deleteSchoolReconfigurationModal", "#deleteReconfigurationConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }
    deleteReconfigurationCancel() {
        let modal = new Modal("deleteSchoolReconfigurationModal", null);
        modal.hide();
    }
    deleteReconfigurationConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let container = document.querySelector(`.schoolReconfigurationSection[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let allElements = container.querySelectorAll(`.schoolReconfigurationsDetailField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        planProps.push(dataPointPlanPropertyPK);
        const that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteSchoolReconfigurationDetail', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let accordionElement = document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();
                let modal = new Modal("deleteSchoolReconfigurationModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The school reconfiguration has been removed.", 'success', 3000, null);
                that.noReconfigurations();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindSchoolNameSearch() {
        let allSchoolNames = document.getElementsByClassName("schoolReconfigurationsSchoolName");
        for (let schoolName of allSchoolNames)
            schoolName.addEventListener("blur", (e) => this.schoolNameSearch(e));
    }
    schoolNameSearch(e) {
        let textElement = e.target;
        let schoolName = textElement.value;
        let dataPointPlanPropertyPK = textElement.dataset.datapointplanpropertypk;
        if (schoolName !== "") {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/SearchForSchool', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let res = JSON.parse(xhr.responseText);
                    let element = document.querySelector(`.schoolReconfigurationDetailBranchField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    if (res.instDetail.auNumber !== null && res.instDetail.auNumber > 0) {
                        element.value = res.instDetail.auNumber;
                    }
                }
            };
            xhr.send(JSON.stringify(schoolName));
        }
    }
    noReconfigurations() {
        let reconfigurationDetailsNoReconfigurationsCheckbox = document.getElementById("reconfigurationdetailsnoreconfigurations");
        let schoolReconfigurationList = document.getElementById("schoolReconfigurationList");
        let alreadyExistsElements = schoolReconfigurationList.querySelectorAll(".Accordion");
        let alreadyExists = false;
        if (alreadyExistsElements.length > 0)
            alreadyExists = true;
        if (alreadyExists) {
            if (reconfigurationDetailsNoReconfigurationsCheckbox !== null) {
                if (reconfigurationDetailsNoReconfigurationsCheckbox.checked) {
                    reconfigurationDetailsNoReconfigurationsCheckbox.checked = false;
                }
                reconfigurationDetailsNoReconfigurationsCheckbox.disabled = true;
            }
        }
        else
            reconfigurationDetailsNoReconfigurationsCheckbox.disabled = false;
        if (reconfigurationDetailsNoReconfigurationsCheckbox !== null) {
            let schoolReconfigurationsCreateButton = document.getElementById("schoolReconfigurationsCreate");
            if (reconfigurationDetailsNoReconfigurationsCheckbox.checked) {
                schoolReconfigurationsCreateButton.disabled = true;
            }
            else {
                schoolReconfigurationsCreateButton.disabled = false;
            }
        }
    }
    addEnrollmentRecord(e) {
        let button = e.target;
        let dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const enrollmentTableData = document.querySelector(`.enrollmentTableData[data-datapointplanpropertypk="${dataPointPlanPropertyPK}"]`);
        const schoolReconfigurationEnrollmentChangeEntryAdd = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntryAdd[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        schoolReconfigurationEnrollmentChangeEntryAdd.disabled = true;
        let planFK = 0;
        let formElement = document.getElementById("schoolReconfigurationsDetailForm");
        planFK = parseInt(formElement.dataset.planfk);
        let row = 0;
        let rowElements = document.querySelectorAll(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr`);
        for (let thisRow of rowElements) {
            let ele = thisRow;
            let theRow = parseInt(ele.dataset.row);
            if (theRow > row)
                row = theRow;
        }
        row++;
        let gradeElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntryGrade[data-type='grade'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let originalInPersonElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let originalVirtualElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedInPersonElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedVirtualElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let grade = gradeElement.value;
        let gradeCaption = gradeElement[gradeElement.selectedIndex].textContent;
        let originalInPerson = originalInPersonElement.value;
        let originalVirtual = originalVirtualElement.value;
        let proposedInPerson = proposedInPersonElement.value;
        let proposedVirtual = proposedVirtualElement.value;
        let data = {
            "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
            "Grade": grade,
            "GradeCaption": gradeCaption,
            "OriginalInPerson": originalInPerson,
            "OriginalVirtual": originalVirtual,
            "ProposedInPerson": proposedInPerson,
            "ProposedVirtual": proposedVirtual,
            "RowNumber": row,
            "PlanFK": planFK
        };
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/AddEnrollmentRecord', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.querySelector(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody`);
                let newTr = document.createElement("tr");
                newTr.innerHTML = xhr.responseText;
                newTr.dataset.row = row.toString();
                newTr.dataset.datapointplanpropertypk = dataPointPlanPropertyPK.toString();
                newTr.dataset.newrow = "yes";
                container.appendChild(newTr);
                gradeElement.selectedIndex = 0;
                originalInPersonElement.value = "";
                originalVirtualElement.value = "";
                proposedInPersonElement.value = "";
                proposedVirtualElement.value = "";
                that.bindDeleteSchoolEnrollmentButtons();
                enrollmentTableData.classList.remove("hide");
                Core.createHTMLAlert("alertMessageDiv", "Enrollment added", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(data));
    }
    checkEnrollmentForComplete(e) {
        let element = e.target;
        let dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
        let originalInPersonElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let originalVirtualElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='original-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedInPersonElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let proposedVirtualElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntry[data-type='proposed-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let originalInPersonNum = parseInt(originalInPersonElement.value);
        let originalVirtualNum = parseInt(originalVirtualElement.value);
        let proposedInPersonNum = parseInt(proposedInPersonElement.value);
        let proposedVirtualNum = parseInt(proposedVirtualElement.value);
        let buttonElement = document.querySelector(`.schoolReconfigurationEnrollmentChangeEntryAdd[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (isNaN(originalInPersonNum) || isNaN(originalVirtualNum) || isNaN(proposedInPersonNum) || isNaN(proposedVirtualNum))
            buttonElement.disabled = true;
        else
            buttonElement.disabled = false;
    }
    calculateEnrollments(dataPointPlanPropertyPK) {
        let formElement = document.getElementById("schoolReconfigurationsDetailForm");
        let calculateEnrollment = formElement.dataset.calculateenrollments;
        if (calculateEnrollment && calculateEnrollment === "true") {
            let currentEnrollmentTotalElement = document.querySelector(`.schoolReconfigurationsDetailField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-group='enrollment'][data-type='current']`);
            let proposedEnrollmentTotalElement = document.querySelector(`.schoolReconfigurationsDetailField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-group='enrollment'][data-type='proposed']`);
            if (currentEnrollmentTotalElement.value !== "" && proposedEnrollmentTotalElement.value !== "") {
                let currentEnrollmentTotal = parseInt(currentEnrollmentTotalElement.value);
                let proposedEnrollmentTotal = parseInt(proposedEnrollmentTotalElement.value);
                if (!isNaN(currentEnrollmentTotal) && !isNaN(proposedEnrollmentTotal)) {
                    let currentEnrollmentCount = 0;
                    let proposedEnrollmentCount = 0;
                    let enrollmentBreakdownCurrentInPersonElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='original-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let enrollmentBreakdownCurrentVirtualElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='original-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let enrollmentBreakdownProposedInPersonElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='proposed-inperson'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let enrollmentBreakdownProposedVirtualElements = document.querySelectorAll(`.schoolReconfigurationEnrollmentChangeEntryValue[data-type='proposed-virtual'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    for (let element of enrollmentBreakdownCurrentInPersonElements) {
                        let ele = element;
                        currentEnrollmentCount += parseInt(ele.textContent);
                    }
                    for (let element of enrollmentBreakdownCurrentVirtualElements) {
                        let ele = element;
                        currentEnrollmentCount += parseInt(ele.textContent);
                    }
                    for (let element of enrollmentBreakdownProposedInPersonElements) {
                        let ele = element;
                        proposedEnrollmentCount += parseInt(ele.textContent);
                    }
                    for (let element of enrollmentBreakdownProposedVirtualElements) {
                        let ele = element;
                        proposedEnrollmentCount += parseInt(ele.textContent);
                    }
                    return ((currentEnrollmentTotal === currentEnrollmentCount) && (proposedEnrollmentTotal === proposedEnrollmentCount));
                }
                else
                    return false;
            }
        }
        //We dont want to actually do the check, so make this value === true.
        return true;
    }
    bindDeleteSchoolEnrollmentButtons() {
        let deleteButtons = document.getElementsByClassName("schoolReconfigurationEnrollmentChangeEntryDelete");
        for (let deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.deleteEnrollmentRow(e));
    }
    deleteEnrollmentRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
        let modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
        modal.addAttributeToElement("deleteSchoolReconfigurationEnrollmentModal", "#deleteEnrollmentConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteSchoolReconfigurationEnrollmentModal", "#deleteEnrollmentConfirm", "row", row);
        modal.show();
    }
    deleteEnrollmentRowCancel() {
        let modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
        modal.hide();
    }
    deleteEnrollmentRowConfirm(e) {
        let confirmButton = e.target;
        let core = this._core;
        Core.showLoader();
        let dataPointPlanPropertyPK = confirmButton.dataset.datapointplanpropertypk;
        let row = confirmButton.dataset.row;
        let isNew = false;
        let isNewRowTRElement = document.querySelector(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr[data-row='${row}']`);
        if (isNewRowTRElement !== null) {
            let isNewValue = isNewRowTRElement.dataset.newrow;
            if (isNewValue)
                if (isNewValue === "yes")
                    isNew = true;
        }
        if (!isNew) {
            let allElementsToDelete = document.querySelectorAll(`.schoolReconfigurationEnrollmentRecordValue[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
            let planPropertyPKs = [];
            for (let element of allElementsToDelete) {
                let ele = element;
                planPropertyPKs.push(ele.dataset.planpropertypk);
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/DeleteEnrollment', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status == 200) {
                    if (xhr.responseText !== null) {
                        let res = JSON.parse(xhr.responseText);
                        if (res.success === true) {
                            isNewRowTRElement.remove();
                            const anyRows = document.querySelectorAll(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr`);
                            if (anyRows.length === 0) {
                                const enrollmentTableData = document.querySelector(`.enrollmentTableData[data-datapointplanpropertypk="${dataPointPlanPropertyPK}"]`);
                                enrollmentTableData.classList.add("hide");
                            }
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "The enrollment has been successfully deleted.", 'success', 3000, null);
                        }
                        else {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the enrollment.", 'error', 3000, null);
                        }
                    }
                    let modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
                    modal.hide();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the enrollment.", 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planPropertyPKs));
        }
        else {
            isNewRowTRElement.remove();
            let modal = new Modal("deleteSchoolReconfigurationEnrollmentModal", null);
            modal.hide();
            const anyRows = document.querySelectorAll(`.enrollmentEntryResults[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] tbody tr`);
            if (anyRows.length === 0) {
                const enrollmentTableData = document.querySelector(`.enrollmentTableData[data-datapointplanpropertypk="${dataPointPlanPropertyPK}"]`);
                enrollmentTableData.classList.add("hide");
            }
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The enrollment has been successfully deleted.", 'success', 3000, null);
        }
    }
    togglePrincipalCharterSchool(e) {
        const ele = e.target;
        const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;
        const uploadElement = document.querySelector(`.schoolReconfigurationPrincipalCharterSchoolUpload[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (uploadElement !== null) {
            if (ele.checked) {
                this._core.forceElementRequired(uploadElement);
            }
            else {
                this._core.forceElementOptional(uploadElement);
            }
        }
    }
    bindReconfigurationPrincipalFileDeletes() {
        let fileButtons = document.getElementsByClassName("schoolReconfigurationsPrincipalDeleteFile");
        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e) => this.deletePrincipalFile(e));
    }
    deletePrincipalFile(e) {
        const fileButton = e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = fileButton.dataset.datapointplanpropertypk;
        const fileGroup = fileButton.dataset.filegroup;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
            deleteConfirmButton.dataset.filegroup = fileGroup;
            modal.show();
        }
    }
    onlyTelephoneChange() {
        const schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox = document.querySelector(".schoolReconfigurationsDetailOnlyTelephoneChange");
        if (schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox !== null) {
            const checked = schoolReconfigurationsDetailOnlyTelephoneChangeCheckbox.checked;
            const schoolReconfigurationsDetailEnrollmentButtonField = document.querySelector(".schoolReconfigurationsDetailEnrollmentButtonField");
            const allCheckboxes = document.getElementsByClassName("schoolReconfigurationsDetailCheckboxField");
            const allEnrollmentFields = document.getElementsByClassName("schoolReconfigurationsDetailEnrollmentField");
            const schoolReconfigurationsDetailEnrollmentSelectField = document.querySelector(".schoolReconfigurationsDetailEnrollmentSelectField");
            if (checked) {
                for (const checkbox of allCheckboxes) {
                    const element = checkbox;
                    if (!(element.classList.contains("schoolReconfigurationsDetailTelephoneChange") || element.classList.contains("schoolReconfigurationsDetailOnlyTelephoneChange"))) {
                        element.checked = false;
                        element.disabled = true;
                    }
                }
                for (const enrollmentElement of allEnrollmentFields) {
                    const element = enrollmentElement;
                    this._core.forceElementOptional(element);
                    element.readOnly = true;
                }
                if (schoolReconfigurationsDetailEnrollmentButtonField !== null)
                    schoolReconfigurationsDetailEnrollmentButtonField.disabled = true;
                if (schoolReconfigurationsDetailEnrollmentSelectField !== null) {
                    schoolReconfigurationsDetailEnrollmentSelectField.disabled = true;
                    this._core.forceElementOptional(schoolReconfigurationsDetailEnrollmentSelectField);
                }
            }
            else {
                for (const checkbox of allCheckboxes) {
                    const element = checkbox;
                    if (!(element.classList.contains("schoolReconfigurationsDetailTelephoneChange") || element.classList.contains("schoolReconfigurationsDetailOnlyTelephoneChange"))) {
                        element.disabled = false;
                    }
                }
                for (const enrollmentElement of allEnrollmentFields) {
                    const element = enrollmentElement;
                    this._core.forceElementRequired(element);
                    element.readOnly = false;
                }
                if (schoolReconfigurationsDetailEnrollmentButtonField !== null)
                    schoolReconfigurationsDetailEnrollmentButtonField.disabled = false;
                if (schoolReconfigurationsDetailEnrollmentSelectField !== null) {
                    schoolReconfigurationsDetailEnrollmentSelectField.disabled = false;
                    this._core.forceElementRequired(schoolReconfigurationsDetailEnrollmentSelectField);
                }
            }
        }
    }
}
// School Reconfigurations School Closure 
class SchoolReconfigurationsSchoolClosure {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["schoolReconfigurationsClosureCheckboxField", "schoolReconfigurationsClosureField", "schoolReconfigurationsClosureUploadField", "schoolReconfigurationsClosureRadioField", "schoolReconfigurationsClosureSelectField"];
        let specialEducationProfileSaveButton = document.getElementById("schoolReconfigurationsSchoolClosureSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        let showMessage = this.initializeRequiredFieldsCustom(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses, showMessage);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let schoolReconfigurationsClosureCreateButton = document.getElementById("schoolReconfigurationsClosureCreate");
        if (schoolReconfigurationsClosureCreateButton !== null)
            schoolReconfigurationsClosureCreateButton.addEventListener("click", (e) => this.createSchoolClosure(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e) => this.deleteFile(e));
        let exportToExcelDetailsButton = document.getElementById("schoolReconfigurationsClosureExportToExcel");
        if (exportToExcelDetailsButton != null) {
            exportToExcelDetailsButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        this.bindUploadButtons();
        this.bindDeleteSchoolClosureButtons();
        let deleteReconfigurationClosureCancelButton = document.getElementById("deleteClosureCancel");
        if (deleteReconfigurationClosureCancelButton !== null)
            deleteReconfigurationClosureCancelButton.addEventListener("click", (e) => this.deleteClosureCancel());
        let deleteReconfigurationClosureConfirmButton = document.getElementById("deleteClosureConfirm");
        if (deleteReconfigurationClosureConfirmButton !== null)
            deleteReconfigurationClosureConfirmButton.addEventListener("click", (e) => this.deleteClosureConfirm(e));
        let reconfigurationDetailsNoClosuresCheckbox = document.getElementById("reconfigurationdetailsclosingnoclosings");
        if (reconfigurationDetailsNoClosuresCheckbox !== null)
            reconfigurationDetailsNoClosuresCheckbox.addEventListener("click", (e) => this.noClosures());
        this.noClosures();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("schoolReconfigurationsSchoolClosureForm");
        let inputs = document.getElementsByClassName("schoolReconfigurationsClosureField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("schoolReconfigurationsClosureCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxesNoClosure = document.getElementsByClassName("schoolReconfigurationsClosureCheckboxNoClosureField");
        for (let ele of checkboxesNoClosure) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("schoolReconfigurationsClosureRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let selects = document.getElementsByClassName("schoolReconfigurationsClosureSelectField");
        for (let selectEle of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let selectElement = selectEle;
            let rowNumber = selectElement.dataset.row;
            let propertyPK = parseInt(selectElement.dataset.propertypk);
            let hadValue = false;
            if (selectElement.dataset.planpropertypk !== "" && selectElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(selectElement.dataset.planpropertypk);
                hadValue = true;
            }
            if ((selectElement.value !== "" && selectElement.value !== "0") || (hadValue && selectElement.value !== "0")) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(selectElement.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let files = document.getElementsByClassName("schoolReconfigurationsClosureUploadFileField");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            let saveItem = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsBuildingClosure', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    createSchoolClosure(e) {
        let dropdown = document.getElementById("reconfigurationdetailsclosingnameofschool");
        let dropdownValue = parseInt(dropdown.value);
        let planForm = document.getElementById("schoolReconfigurationsSchoolClosureForm");
        let planFK = planForm.dataset.planfk;
        let core = this._core;
        let that = this;
        let newRow = 0;
        if (dropdownValue > 0) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/AddSchoolClosure', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let container = document.getElementById("schoolClosureList");
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = xhr.responseText;
                    container.appendChild(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    that.bindUploadButtons();
                    that.initializeNewAccordion();
                    Core.createHTMLAlert("alertMessageDiv", "Building Closure Added", 'success', 3000, null);
                    let reconfigurationDetailsNoReconfigurationsCheckbox = document.getElementById("reconfigurationdetailsnoreconfigurations");
                    if (reconfigurationDetailsNoReconfigurationsCheckbox !== null)
                        if (reconfigurationDetailsNoReconfigurationsCheckbox.checked)
                            reconfigurationDetailsNoReconfigurationsCheckbox.checked = false;
                    reconfigurationDetailsNoReconfigurationsCheckbox.disabled = true;
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                }
            };
            xhr.send(`institutionPK=${dropdownValue}&planFK=${planFK}&row=${newRow}`);
        }
    }
    bindUploadButtons() {
        let fileUploaders = document.getElementsByClassName("schoolReconfigurationsClosureUploadField");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.schoolReconfigurationsClosureUploadField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.schoolReconfigurationsClosureFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        let currentFileList = fileList.querySelectorAll(".schoolReconfigurationsClosureUploadField");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.closureUploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsClosureUploadFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");
        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    initializeRequiredFieldsCustom(validationClasses) {
        let byPass = false;
        let showMessage = false;
        let totalErrors = 0;
        let reconfigurationDetailsNoClosuresCheckbox = document.getElementById("reconfigurationdetailsclosingnoclosings");
        if (reconfigurationDetailsNoClosuresCheckbox !== null) {
            if (reconfigurationDetailsNoClosuresCheckbox.checked)
                byPass = true;
        }
        if (!byPass) {
            let formattedAllClasses = [];
            for (const cssClass of validationClasses)
                formattedAllClasses.push(`.${cssClass}`);
            let classesToValidate = formattedAllClasses.join(",");
            let allElements = document.querySelectorAll(classesToValidate);
            for (let element of allElements) {
                let htmlElement = element;
                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        htmlElement.setAttribute("force-required", "true");
                        let label = Core.findLabelForInput(htmlElement);
                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
                //Manual override when data-forcerequired=true
                if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                    htmlElement.dataset.percent = "1.00";
                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        let label = Core.findLabelForInput(htmlElement);
                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }
            for (let element of allElements) {
                let htmlElement = element;
                if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = element;
                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "text" && inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && inputElement.value === "") {
                            if (inputElement.hasAttribute("name")) {
                                let radioName = inputElement.getAttribute("name");
                                let radioButtons = document.getElementsByName(radioName);
                                let alreadyValidated = false;
                                let isChecked = false;
                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                    let radioElement = radioButtons[i];
                                    if (radioElement.classList.contains("missing-field")) {
                                        alreadyValidated = true;
                                    }
                                    if (radioElement.checked) {
                                        isChecked = true;
                                    }
                                }
                                if (!alreadyValidated && !isChecked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLSelectElement) {
                        let textAreaElement = element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
            let message = document.getElementById("validationMessage");
            if (totalErrors === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            }
            else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");
            if (goToError !== null) {
                let that = this;
                let firstFocusableEl = document.querySelector(".missing-field");
                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                        if (accordion) {
                            let id = accordion.getAttribute("aria-labelledby");
                            let accordionElement = document.getElementById(id);
                            accordionElement.click();
                        }
                        if (firstFocusableEl.classList.contains("mce")) {
                            tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                        }
                        else {
                            firstFocusableEl.focus();
                        }
                    });
                }
                else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
            this._core.initializeRequiredFields(validationClasses);
            return showMessage;
        }
        this._core.initializeRequiredFields(validationClasses);
    }
    initializeNewAccordion() {
        let formattedAllClasses = [];
        formattedAllClasses.push(".schoolReconfigurationsClosureField");
        formattedAllClasses.push(".schoolReconfigurationsClosureRadioField");
        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    htmlElement.setAttribute("force-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
    exportToExcel() {
        let that = this;
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("schoolReconfigurationsSchoolClosureForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SchoolReconfigurationsClosureExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SchoolReconfigurationClosure.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    bindDeleteSchoolClosureButtons() {
        let buttons = document.getElementsByClassName("deleteThisSchoolClosure");
        for (let button of buttons)
            button.addEventListener("click", (e) => this.deleteSchoolClosure(e));
    }
    deleteSchoolClosure(e) {
        this.showDeleteClosureConfirm(e);
    }
    showDeleteClosureConfirm(e) {
        let buttonElement = e.target;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let modal = new Modal("deleteSchoolClosureModal", null);
        modal.addAttributeToElement("deleteSchoolClosureModal", "#deleteClosureConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }
    deleteClosureCancel() {
        let modal = new Modal("deleteSchoolClosureModal", null);
        modal.hide();
    }
    deleteClosureConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let container = document.querySelector(`.schoolReconfigurationSection[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let allElements = container.querySelectorAll(`.schoolReconfigurationsClosureField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let allRadioElements = container.querySelectorAll(`.schoolReconfigurationsClosureRadioField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let radEle of allRadioElements) {
            let element = radEle;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        planProps.push(dataPointPlanPropertyPK);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteSchoolClosure', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let accordionElement = document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();
                let modal = new Modal("deleteSchoolClosureModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The school closure has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    noClosures() {
        let reconfigurationDetailsNoClosuresCheckbox = document.getElementById("reconfigurationdetailsclosingnoclosings");
        let schoolReconfigurationList = document.getElementById("schoolClosureList");
        let alreadyExistsElements = schoolReconfigurationList.querySelectorAll(".Accordion");
        let alreadyExists = false;
        if (alreadyExistsElements.length > 0)
            alreadyExists = true;
        if (alreadyExists) {
            reconfigurationDetailsNoClosuresCheckbox.disabled = true;
        }
        if (reconfigurationDetailsNoClosuresCheckbox !== null) {
            let schoolReconfigurationsCreateButton = document.getElementById("schoolReconfigurationsClosureCreate");
            if (reconfigurationDetailsNoClosuresCheckbox.checked) {
                schoolReconfigurationsCreateButton.disabled = true;
            }
            else {
                schoolReconfigurationsCreateButton.disabled = false;
            }
        }
    }
}
// Signatures & Assurances
class SchoolReconfigurationsSignaturesAndAssurances {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["schoolReconfigurationsSignaturesField", "schoolReconfigurationsSignaturesFileField", "schoolReconfigurationsSignaturesCheckboxField"];
        let specialEducationSignaturesSaveButton = document.getElementById("schoolReconfigurationsSignaturesSave");
        if (specialEducationSignaturesSaveButton !== null)
            specialEducationSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        let fileUploaderBoardStatement = document.getElementById("reconfigurationdetailssignaturesuploadboardstatement");
        if (fileUploaderBoardStatement !== null) {
            fileUploaderBoardStatement.addEventListener("change", (e) => this.uploadFile(e.target, "reconfigurationdetailssignaturesuploadboardstatementForm", "reconfigurationdetailssignaturesuploadboardstatement"));
        }
        let fileUploaderLEAReconfigurationLetter = document.getElementById("reconfigurationdetailssignaturesuploadleareconfigurationletter");
        if (fileUploaderLEAReconfigurationLetter !== null) {
            fileUploaderLEAReconfigurationLetter.addEventListener("change", (e) => this.uploadFile(e.target, "reconfigurationdetailssignaturesuploadleareconfigurationletterForm", "reconfigurationdetailssignaturesuploadleareconfigurationletter"));
        }
        this.checkForOnlyAdminChanges();
        const schoolReconfigurationsSignaturesNoAdminChangesCheckbox = document.getElementById("reconfigurationSignaturesAdministratorChangesOnly");
        if (schoolReconfigurationsSignaturesNoAdminChangesCheckbox !== null)
            schoolReconfigurationsSignaturesNoAdminChangesCheckbox.addEventListener("change", (e) => this.checkForOnlyAdminChanges());
        const reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement = document.getElementById("reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement");
        if (reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement !== null)
            reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement.addEventListener("change", (e) => this.uploadFileSignatures(e.target, "reconfigurationSignaturesUploadofBoardStatementorAffirmationStatementFormId", "reconfigurationSignaturesUploadofBoardStatementorAffirmationStatement"));
        const reconfigurationSignaturesUploadofLEAReconfigurationLetter = document.getElementById("reconfigurationSignaturesUploadofLEAReconfigurationLetter");
        if (reconfigurationSignaturesUploadofLEAReconfigurationLetter !== null)
            reconfigurationSignaturesUploadofLEAReconfigurationLetter.addEventListener("change", (e) => this.uploadFileSignatures(e.target, "reconfigurationSignaturesUploadofLEAReconfigurationLetterFormId", "reconfigurationSignaturesUploadofLEAReconfigurationLetter"));
        const reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional = document.getElementById("reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional");
        if (reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional !== null)
            reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional.addEventListener("change", (e) => this.uploadFileSignatures(e.target, "reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptionalFormId", "reconfigurationSignaturesUploadAccuracyCertificationStatementACSOptional"));
        const reconfigurationSignaturesUploadofExecutedCharter = document.getElementById("reconfigurationSignaturesUploadofExecutedCharter");
        if (reconfigurationSignaturesUploadofExecutedCharter !== null)
            reconfigurationSignaturesUploadofExecutedCharter.addEventListener("change", (e) => this.uploadFileSignatures(e.target, "reconfigurationSignaturesUploadofExecutedCharterFormId", "reconfigurationSignaturesUploadofExecutedCharter"));
        const reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes = document.getElementById("reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes");
        if (reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes !== null)
            reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes.addEventListener("change", (e) => this.uploadFileSignatures(e.target, "reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutesFormId", "reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes"));
        this.bindDeleteFileButtons();
        const reconfigurationSignaturesCharterSchool = document.getElementById("reconfigurationSignaturesCharterSchool");
        if (reconfigurationSignaturesCharterSchool !== null)
            reconfigurationSignaturesCharterSchool.addEventListener("change", (e) => this.checkForCharter());
        this.checkForCharter();
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let ceoNameInInstructionsTextField = document.getElementById("reconfigurationdetailssignaturessuperintendent");
        if (ceoNameInInstructionsTextField !== null) {
            ceoNameInInstructionsTextField.addEventListener("input", (e) => this.updateName(e));
            if (ceoNameInInstructionsTextField.value !== "") {
                let ceoNameElement = document.getElementById("ceoNameInInstructions");
                ceoNameElement.textContent = ceoNameInInstructionsTextField.value;
            }
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];
        const formElement = document.getElementById("schoolReconfigurationsSignaturesForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const inputs = document.getElementsByClassName("schoolReconfigurationsSignaturesField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("schoolReconfigurationsSignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        const files = document.getElementsByClassName("schoolReconfigurationsSignaturesFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        const allData = {
            "SaveData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFileSignatures(e, formElementId, fileElementId) {
        let core = this._core;
        Core.showLoader();
        const formElement = document.getElementById(formElementId);
        let formName = e.dataset.formname;
        let formData = new FormData(formElement);
        let propertyPK = e.dataset.propertypk;
        const that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.getElementById(fileElementId);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-formname='${formName}']`);
                        let currentFileList = document.querySelectorAll(".uploadFileList[data-formname='${formName}']");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-formname='${formName}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsSignaturesFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFileButtons();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    uploadFile(e, formElementId, fileElementId) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = document.getElementById(formElementId);
        let formData = new FormData(uploadSampleForm);
        const that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById(fileElementId);
                        formFile.value = "";
                        formFile.dataset.hasuploaded = "true";
                        that.bindDeleteFileButtons();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFileButtons() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons) {
            if ("planpropertyfilepk" in deleteButton.dataset && parseInt(deleteButton.dataset.planpropertyfilepk) > 0) {
                deleteButton.addEventListener("click", (e) => this.showDeleteFileConfirmation(deleteButton));
            }
        }
    }
    showDeleteFileConfirmation(deleteButton) {
        let planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let fileType = "";
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                            fileType = containerElement.dataset.filetype;
                        }
                        const fileList = document.querySelectorAll(`.uploadFileList[data-filetype='${fileType}'] .uploadFileColumn[data-filetype='${fileType}']`);
                        if (fileList.length === 0) {
                            const fileListElement = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                            fileListElement.classList.add("hide");
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    updateName(e) {
        let textElement = e.target;
        let ceoNameElement = document.getElementById("ceoNameInInstructions");
        if (textElement && textElement.value !== "") {
            ceoNameElement.textContent = textElement.value;
        }
    }
    checkForCharter() {
        const charterElement = document.getElementById("reconfigurationSignaturesCharterSchool");
        if (charterElement != null) {
            const reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes = document.getElementById("reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes");
            const reconfigurationSignaturesUploadofExecutedCharter = document.getElementById("reconfigurationSignaturesUploadofExecutedCharter");
            const phoneOnly = document.getElementById("phoneOnly");
            if (charterElement.checked) {
                if (phoneOnly !== null && phoneOnly.value === "true")
                    this._core.forceElementOptional(reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes);
                else
                    this._core.forceElementRequired(reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes);
                this._core.forceElementRequired(reconfigurationSignaturesUploadofExecutedCharter);
            }
            else {
                this._core.forceElementOptional(reconfigurationSignaturesUploadofAuthorizingSchoolDistrictMinutes);
                this._core.forceElementOptional(reconfigurationSignaturesUploadofExecutedCharter);
            }
        }
    }
    doCustomValidation(allClasses) {
        let showMessage = this.clientSideValidationCustom(allClasses);
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        const allFileUploaders = document.getElementsByClassName("schoolReconfigurationsSignaturesFileValidationField");
        for (const fileUploader of allFileUploaders) {
            const element = fileUploader;
            if (element.dataset.percent === "1.00") {
                const fileType = element.dataset.filetype;
                const anyFiles = document.querySelectorAll(`.uploadedFiles[data-filetype='${fileType}'] .uploadFileColumn[data-filetype='${fileType}']`);
                if (anyFiles.length === 0) {
                    element.classList.add("missing-field");
                    element.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(element);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    checkForOnlyAdminChanges() {
        const schoolReconfigurationsSignaturesNoAdminChangesCheckbox = document.getElementById("reconfigurationSignaturesAdministratorChangesOnly");
        if (schoolReconfigurationsSignaturesNoAdminChangesCheckbox !== null) {
            const checkEle = schoolReconfigurationsSignaturesNoAdminChangesCheckbox;
            const schoolReconfigurationsSignaturesNoAdminChangesItems = document.getElementsByClassName("schoolReconfigurationsSignaturesNoAdminChangesItem");
            for (const schoolReconfigurationsSignaturesNoAdminChangesItem of schoolReconfigurationsSignaturesNoAdminChangesItems) {
                const ele = schoolReconfigurationsSignaturesNoAdminChangesItem;
                if (checkEle.checked) {
                    ele.readOnly = true;
                    ele.disabled = true;
                    this._core.forceElementOptional(ele);
                }
                else {
                    ele.readOnly = false;
                    ele.disabled = false;
                    if (ele.dataset.originalpercent === "1.0")
                        this._core.forceElementRequired(ele);
                }
            }
            const reconfigurationdetailssignaturessuperintendent = document.getElementById("reconfigurationdetailssignaturessuperintendent");
            const reconfigurationdetailssignaturessuperintendentdate = document.getElementById("reconfigurationdetailssignaturessuperintendentdate");
            const reconfigurationSignaturesSinglePointofContactName = document.getElementById("reconfigurationSignaturesSinglePointofContactName");
            const reconfigurationSignaturesSinglePointofContactDate = document.getElementById("reconfigurationSignaturesSinglePointofContactDate");
            if (checkEle.checked) {
                this._core.forceElementOptional(reconfigurationdetailssignaturessuperintendent);
                this._core.forceElementOptional(reconfigurationdetailssignaturessuperintendentdate);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactName);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactDate);
            }
            else {
                this._core.forceElementRequired(reconfigurationdetailssignaturessuperintendent);
                this._core.forceElementRequired(reconfigurationdetailssignaturessuperintendentdate);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactName);
                this._core.forceElementRequired(reconfigurationSignaturesSinglePointofContactDate);
            }
        }
    }
}
// Summary & Submission
class SchoolReconfigurationsSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SchoolReconfigurations/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// SchoolReconfigurationsOpening
class SchoolReconfigurationsOpening {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["schoolReconfigurationsOpeningField", "schoolReconfigurationsOpeningCheckboxField", "schoolReconfigurationsOpeningRadioField", "schoolReconfigurationsOpeningSelectField", "schoolReconfigurationsOpeningFileField"];
        let reconfigurationsOpeningSaveButton = document.getElementById("reconfigurationsOpeningSave");
        if (reconfigurationsOpeningSaveButton !== null)
            reconfigurationsOpeningSaveButton.addEventListener("click", (e) => this.save("save"));
        const reconfigurationOpeningRequestInvolves = document.getElementById("reconfigurationOpeningRequestInvolves");
        if (reconfigurationOpeningRequestInvolves !== null)
            reconfigurationOpeningRequestInvolves.addEventListener("change", (e) => this.checkRequest());
        this.bindSameAsMailingAddress();
        this.checkRequest();
        const schoolReconfigurationsOpeningSameAsMailings = document.getElementsByClassName("schoolReconfigurationsOpeningSameAsMailing");
        for (const schoolReconfigurationsOpeningSameAsMailing of schoolReconfigurationsOpeningSameAsMailings)
            this.sameAsMailingAddress(schoolReconfigurationsOpeningSameAsMailing);
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const schoolReconfigurationsCreateBuildingOpeningButton = document.getElementById("schoolReconfigurationsCreateBuildingOpening");
        if (schoolReconfigurationsCreateBuildingOpeningButton !== null)
            schoolReconfigurationsCreateBuildingOpeningButton.addEventListener("click", (e) => this.createSchoolOpening());
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteReconfigurationClosureCancelButton = document.getElementById("deleteOpeningCancel");
        if (deleteReconfigurationClosureCancelButton !== null)
            deleteReconfigurationClosureCancelButton.addEventListener("click", (e) => this.deleteSchoolOpeningCancel());
        let deleteReconfigurationClosureConfirmButton = document.getElementById("deleteOpeningConfirm");
        if (deleteReconfigurationClosureConfirmButton !== null)
            deleteReconfigurationClosureConfirmButton.addEventListener("click", (e) => this.deleteSchoolOpeningConfirm(e));
        this.bindUploadButtons();
        this.bindDeleteFileButtons();
        this.bindDeleteSchoolButtons();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("reconfigurationsOpeningForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let inputs = document.getElementsByClassName("schoolReconfigurationsOpeningField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("schoolReconfigurationsOpeningRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("schoolReconfigurationsOpeningUploadFileField");
        for (let ele of files) {
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            const propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            const fileUploadPK = parseInt(element.dataset.fileuploadpk);
            const propertyPK = parseInt(element.dataset.propertypk);
            const planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            let saveItem = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };
            allFileElements.push(saveItem);
        }
        let outsideSaveElements = [];
        let selects = document.getElementsByClassName("schoolReconfigurationsOpeningSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (parseInt(element.value) > -1) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: 0,
                        IsDeletedInd: false
                    };
                    outsideSaveElements.push(saveItem);
                }
            }
        }
        const checkboxes = document.getElementsByClassName("schoolReconfigurationsOpeningCheckboxField");
        for (let ele of checkboxes) {
            planPK = parseInt(formElement.dataset.planfk);
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false,
                ButtonRow: 0,
                DataPointPlanPropertyPK: dataPointPlanPropertyPK
            };
            allSaveElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "OutsideData": outsideSaveElements,
            "PlanPK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveSchoolReconfigurationsOpening', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0 && outsideSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    createSchoolOpening() {
        return __awaiter(this, void 0, void 0, function* () {
            const textElement = document.getElementById("reconfigurationOpeningNameofSchool");
            const schoolName = textElement.value;
            const planFK = textElement.dataset.planfk;
            const currentList = document.getElementsByClassName("reconfigurationOpeningExplaination");
            let currentRow = 0;
            if (schoolName !== "") {
                Core.showLoader();
                for (const item of currentList) {
                    const ele = item;
                    let thisRow = parseInt(ele.dataset.row);
                    if (!isNaN(thisRow) && thisRow > currentRow)
                        currentRow = thisRow;
                }
                currentRow++;
                const data = {
                    "SchoolName": schoolName,
                    "PlanFK": planFK,
                    "Row": currentRow,
                };
                const config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const response = yield fetch("/SchoolReconfigurations/CreateBuildingOpening", config);
                if (response.ok) {
                    const output = yield response.text();
                    let container = document.getElementById("buildingOpeningList");
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = output;
                    container.appendChild(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    this.bindUploadButtons();
                    this.bindSameAsMailingAddress();
                    let mailing = new Foundation.Tabs($(".mailingAddressTabs"));
                    this._core.initializeRequiredFields(this.validationClasses, false, true);
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The Building Opening record has been added!", 'success', 2000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a school name before adding a school.", 'warning', 2000, null);
            }
        });
    }
    bindUploadButtons() {
        let fileUploaders = document.getElementsByClassName("schoolReconfigurationsOpeningFileField");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let that = this;
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.schoolReconfigurationsOpeningFileField[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        let currentFileList = fileList.querySelectorAll(".schoolReconfigurationsClosureUploadField");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("schoolReconfigurationsOpeningUploadFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFileButtons() {
        let fileButtons = document.getElementsByClassName("deleteFile");
        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    bindDeleteSchoolButtons() {
        let fileButtons = document.getElementsByClassName("deleteThisSchoolOpening");
        for (let fileButton of fileButtons)
            fileButton.addEventListener("click", (e) => this.showDeleteSchool(e));
    }
    showDeleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    showDeleteSchool(e) {
        let fileButton = e.target;
        let datapointPlanPropertyFilePK = fileButton.dataset.datapointplanpropertypk;
        let modal = new Modal("deleteSchoolOpeningModal", null);
        modal.addAttributeToElement("deleteSchoolOpeningModal", "#deleteOpeningConfirm", "datapointplanpropertypk", datapointPlanPropertyFilePK);
        modal.show();
    }
    deleteSchoolOpeningConfirm(e) {
        const buttonElement = e.target;
        let datapointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteSchoolOpening', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The school opening has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${datapointPlanPropertyPK}']`);
                        if (containerElement !== null) {
                            containerElement.parentElement.parentElement.remove();
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this record, please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteSchoolOpeningModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify(datapointPlanPropertyPK));
    }
    deleteSchoolOpeningCancel() {
        let modal = new Modal("deleteSchoolOpeningModal", null);
        modal.hide();
    }
    checkRequest() {
        const element = document.getElementById("reconfigurationOpeningRequestInvolves");
        if (element !== null) {
            if (element.selectedIndex > 0) {
                const option = element[element.selectedIndex];
                const classes = [];
                classes.push("schoolReconfigurationsOpeningField");
                classes.push("schoolReconfigurationsOpeningRadioField");
                classes.push("schoolReconfigurationsOpeningFileField");
                if (option.dataset.lookupcode === "reconfigurationOpeningRequestInvolves") {
                    for (const thisClass of classes) {
                        const allTheseElements = document.getElementsByClassName(thisClass);
                        for (const thisElement of allTheseElements) {
                            const thisEle = thisElement;
                            if (!thisEle.classList.contains("address2") && !thisEle.classList.contains("ext"))
                                this._core.forceElementRequired(thisEle);
                        }
                    }
                }
                else {
                    for (const thisClass of classes) {
                        const allTheseElements = document.getElementsByClassName(thisClass);
                        for (const thisElement of allTheseElements)
                            this._core.forceElementOptional(thisElement);
                    }
                }
            }
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let customMessage = false;
        const selectElement = document.getElementById("reconfigurationOpeningRequestInvolves");
        let override = false;
        if (selectElement !== null) {
            const selectedOption = selectElement[selectElement.selectedIndex];
            if (selectedOption.dataset.lookupcode === "reconfigurationOpeningRequestDoesNotInvolve")
                override = true;
        }
        if (!override) {
            //make sure there is at least one school in the list
            const buildingOpeningList = document.querySelectorAll("#buildingOpeningList .Accordion");
            if (buildingOpeningList !== null && buildingOpeningList.length > 0) {
                let formattedAllClasses = [];
                allClasses.forEach(function (part, index) {
                    formattedAllClasses[index] = "." + allClasses[index];
                });
                let classesToValidate = formattedAllClasses.join(",");
                //Remove all validation messages
                [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
                    e.parentNode.removeChild(e);
                });
                //Remove missing field class
                [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
                    e.classList.remove("missing-field");
                });
                //Remove hasBeenValidated class
                [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
                    e.classList.remove("hasBeenValidated");
                });
                let allElements = document.querySelectorAll(classesToValidate);
                for (let element of allElements) {
                    let alreadyExists = false;
                    let htmlElement = element;
                    if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                        //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                        var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                        for (let otherElement of otherElements) {
                            let otherHtmlElement = otherElement;
                            if (otherHtmlElement.classList.contains("missing-field")) {
                                alreadyExists = true;
                            }
                            else {
                                if (otherHtmlElement instanceof HTMLInputElement) {
                                    if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                        //Check to see if a prior group of radio buttons has already been validated
                                        let OtherInputElement = otherHtmlElement;
                                        if (OtherInputElement.hasAttribute("name")) {
                                            let radioGroupName = OtherInputElement.getAttribute("name");
                                            let radios = document.getElementsByName(radioGroupName);
                                            let radioIsChecked = false;
                                            let isAlreadyValidated = false;
                                            for (var i = 0, length = radios.length; i < length; i++) {
                                                let radioElement = radios[i];
                                                if (radioElement.checked) {
                                                    radioIsChecked = true;
                                                }
                                                if (radioElement.classList.contains("missing-field")) {
                                                    isAlreadyValidated = true;
                                                }
                                            }
                                            if (isAlreadyValidated || radioIsChecked) {
                                                alreadyExists = true;
                                            }
                                        }
                                    }
                                    else {
                                        let OtherInputElement = otherHtmlElement;
                                        if (OtherInputElement.value !== "") {
                                            alreadyExists = true;
                                        }
                                    }
                                }
                                else if (otherHtmlElement instanceof HTMLSelectElement) {
                                    let otherSelectElement = otherHtmlElement;
                                    if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                                else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                                    let otherTextAreaElement = otherHtmlElement;
                                    if (otherTextAreaElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                                else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                                    //See if any options have been checked in multiselect
                                    let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                                    for (let selectBox of multiselectCheckboxes) {
                                        if (selectBox.checked) {
                                            alreadyExists = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                            if (!element.classList.contains("missing-field")) {
                                //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                                if ("isValid" in htmlElement.dataset) {
                                    if (htmlElement.dataset.isValid === "false") {
                                        htmlElement.classList.add("missing-field");
                                        htmlElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(htmlElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else {
                                    if (element instanceof HTMLInputElement) {
                                        let inputElement = element;
                                        //Only validate once for radio buttons
                                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                            if (inputElement.hasAttribute("name")) {
                                                let radioName = inputElement.getAttribute("name");
                                                let radioButtons = document.getElementsByName(radioName);
                                                let alreadyValidated = false;
                                                let isChecked = false;
                                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                                    let radioElement = radioButtons[i];
                                                    if (radioElement.classList.contains("missing-field")) {
                                                        alreadyValidated = true;
                                                    }
                                                    if (radioElement.checked) {
                                                        isChecked = true;
                                                    }
                                                }
                                                if (!alreadyValidated && !isChecked) {
                                                    inputElement.classList.add("missing-field");
                                                    inputElement.setAttribute("aria-invalid", "true");
                                                    Core.createErrorLabelForInput(inputElement);
                                                    showMessage = true;
                                                    totalErrors++;
                                                }
                                            }
                                        }
                                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                                inputElement.classList.add("missing-field");
                                                inputElement.setAttribute("aria-invalid", "true");
                                                Core.createErrorLabelForInput(inputElement);
                                                showMessage = true;
                                                totalErrors++;
                                            }
                                        }
                                        else if (inputElement.value === "") {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                    else if (element instanceof HTMLSelectElement) {
                                        let selectElement = element;
                                        //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                        if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                            selectElement.classList.add("missing-field");
                                            selectElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(selectElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                    else if (element instanceof HTMLTextAreaElement) {
                                        let textAreaElement = element;
                                        if (textAreaElement.value === "") {
                                            textAreaElement.classList.add("missing-field");
                                            textAreaElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(textAreaElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                    else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                        let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                        let hasSelection = false;
                                        for (let selectBox of multiselectCheckboxes) {
                                            if (selectBox.checked) {
                                                hasSelection = true;
                                                break;
                                            }
                                        }
                                        if (!hasSelection) {
                                            htmlElement.classList.add("missing-field");
                                            htmlElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(htmlElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else {
                totalErrors++;
                customMessage = true;
                showMessage = true;
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1 && !customMessage) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else if (totalErrors === 1 && customMessage) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are no schools added to this list. You must add a school.</p>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    bindSameAsMailingAddress() {
        const schoolReconfigurationsOpeningSameAsMailings = document.getElementsByClassName("schoolReconfigurationsOpeningSameAsMailing");
        for (const schoolReconfigurationsOpeningSameAsMailing of schoolReconfigurationsOpeningSameAsMailings) {
            schoolReconfigurationsOpeningSameAsMailing.addEventListener("change", (e) => this.sameAsMailingAddress(e.target));
            this.sameAsMailingAddress(schoolReconfigurationsOpeningSameAsMailing);
        }
    }
    sameAsMailingAddress(checkbox) {
        const dataPointPlanPropertyFK = checkbox.dataset.datapointplanpropertypk;
        const allPhysicalAddressInputs = document.querySelectorAll(`.schoolReconfigurationsOpeningPhysical[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        if (checkbox.checked)
            for (const physical of allPhysicalAddressInputs) {
                const element = physical;
                this._core.forceElementOptional(element);
                element.readOnly = true;
                element.disabled = true;
            }
        else
            for (const physical of allPhysicalAddressInputs) {
                const element = physical;
                this._core.forceElementRequired(element);
                element.readOnly = false;
                element.disabled = false;
            }
    }
}
// Administrator Changes
class SchoolReconfigurationsAdministratorChanges {
    constructor() {
        let that = this;
        this._core = new Core();
        const reconfigurationAdminChangeNoticeClose = document.getElementById("reconfigurationAdminChangeNoticeClose");
        if (reconfigurationAdminChangeNoticeClose !== null)
            reconfigurationAdminChangeNoticeClose.addEventListener("click", (e) => this.closeNoticeModal());
        this.bindChiefLEAAdminDropdowns();
        this.bindCommissionChanges();
        const reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges = document.getElementById("reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges");
        if (reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges !== null) {
            reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges.addEventListener("change", (e) => this.noAdministrativeChanges(e.target));
            this.noAdministrativeChanges(reconfigurationAdministratorChangesTherearecurrentlynoAdministrativeChanges);
        }
        const dropdowns = document.getElementsByClassName("reconfigurationAdministratorChangesChiefLEAAdminUpdateField");
        for (const dropdown of dropdowns)
            this.changeChiefLEAAdminDropdown(dropdown);
        this.validationClasses = ["reconfigurationAdministratorChangesField", "reconfigurationAdministratorChangesRadioField", "reconfigurationAdministratorChangesSelectField", "reconfigurationAdministratorChangesCheckboxField", "reconfigurationAdministratorChangesFileField", "reconfigurationAdministratorChangesPrincipalField"];
        let reconfigurationAdministratorChangesSaveButton = document.getElementById("reconfigurationAdministratorChangesSave");
        if (reconfigurationAdministratorChangesSaveButton !== null)
            reconfigurationAdministratorChangesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doCustomValidation(this.validationClasses);
            const noticeModal = new Modal("reconfigurationAdminChangeNoticeClose", null);
            noticeModal.show();
        }
        this.customInitializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const createAdministrativeUpdateButton = document.getElementById("createAdministrativeUpdate");
        if (createAdministrativeUpdateButton !== null)
            createAdministrativeUpdateButton.addEventListener("click", (e) => this.createAdministrativeUpdate());
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        const deleteAdminUpdateConfirmButton = document.getElementById("deleteAdminUpdateConfirm");
        if (deleteAdminUpdateConfirmButton !== null)
            deleteAdminUpdateConfirmButton.addEventListener("click", (e) => this.deleteAdminUpdateConfirm(e));
        const deleteAdminUpdateCancelButton = document.getElementById("deleteAdminUpdateCancel");
        if (deleteAdminUpdateCancelButton !== null)
            deleteAdminUpdateCancelButton.addEventListener("click", (e) => this.deleteAdminUpdateCancel());
        this.bindUploadFiles();
        this.bindDeleteFiles();
        this.bindDeleteAdministrativeUpdates();
        const reconfigurationAdministratorChangesAdministrativeUpdate = document.getElementById("reconfigurationAdministratorChangesAdministrativeUpdate");
        if (reconfigurationAdministratorChangesAdministrativeUpdate !== null)
            reconfigurationAdministratorChangesAdministrativeUpdate.addEventListener("change", (e) => this.changeAdminUpdateSelection(e));
        document.addEventListener("click", (e) => {
            let target = e.target;
            if (target.classList.contains("submitReconfigAdminChange") && "datapointplanpropertypk" in target.dataset) {
                that.save("save", [parseInt(target.dataset.datapointplanpropertypk)]);
            }
        });
    }
    changeAdminUpdateSelection(e) {
        const element = e.target;
        const option = element[element.selectedIndex];
        const allOptions = document.querySelectorAll(`#reconfigurationAdministratorChangesUpdateCategoryType option`);
        const dropdown = document.getElementById("reconfigurationAdministratorChangesUpdateCategoryType");
        dropdown.value = "0";
        if (option.dataset.lookupcode === "administrativeUpdateAssistantChiefSchoolPrincipal" || option.dataset.lookupcode === "administrativeUpdateAssistantVicePrincipal") {
            for (const option of allOptions) {
                const ele = option;
                if (ele.classList.contains("canBeHidden"))
                    ele.classList.add("hide");
            }
        }
        else {
            for (const option of allOptions) {
                const ele = option;
                if (ele.classList.contains("canBeHidden"))
                    ele.classList.remove("hide");
            }
        }
    }
    noAdministrativeChanges(element) {
        const allRadioElements = document.getElementsByClassName("reconfigurationAdministratorChangesRadioField");
        const allTextElements = document.getElementsByClassName("reconfigurationAdministratorChangesField");
        const allSelectElements = document.getElementsByClassName("reconfigurationAdministratorChangesSelectField");
        const allFileElements = document.getElementsByClassName("reconfigurationAdministratorChangesFileField");
        const allDeleteFileElements = document.getElementsByClassName("deleteFile");
        const createAdministrativeUpdate = document.getElementById("createAdministrativeUpdate");
        const allDeleteAdminElements = document.getElementsByClassName("deleteAdminUpdate");
        if (element.checked) {
            for (var radio of allRadioElements) {
                const ele = radio;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var text of allTextElements) {
                const ele = text;
                if (!(ele.dataset.omitreadonly && ele.dataset.omitreadonly === "true")) {
                    ele.readOnly = true;
                    ele.disabled = true;
                }
            }
            for (var select of allSelectElements) {
                const ele = select;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var file of allFileElements) {
                const ele = file;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var del of allDeleteFileElements) {
                const ele = del;
                ele.readOnly = true;
                ele.disabled = true;
            }
            for (var del of allDeleteAdminElements) {
                const ele = del;
                ele.readOnly = true;
                ele.disabled = true;
            }
            createAdministrativeUpdate.disabled = true;
        }
        else {
            for (var radio of allRadioElements) {
                const ele = radio;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var text of allTextElements) {
                const ele = text;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var select of allSelectElements) {
                const ele = select;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var file of allFileElements) {
                const ele = file;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var del of allDeleteFileElements) {
                const ele = del;
                ele.readOnly = false;
                ele.disabled = false;
            }
            for (var del of allDeleteAdminElements) {
                const ele = del;
                ele.readOnly = false;
                ele.disabled = false;
            }
            createAdministrativeUpdate.disabled = false;
        }
    }
    closeNoticeModal() {
        const modal = new Modal("reconfigurationAdminChangeNoticeClose", null);
        modal.hide();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer, dataPointPlanPropertyPKsToSubmitForReview = null) {
        const allSaveElements = [];
        const allAdminSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("reconfigurationAdministratorChangesForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("reconfigurationAdministratorChangesField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        //This is a special case in a different place, it was just easier to handle it separately.
        const principalInputs = document.getElementsByClassName("reconfigurationAdministratorChangesPrincipalField");
        for (const ele of principalInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("reconfigurationAdministratorChangesRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const checkboxes = document.getElementsByClassName("reconfigurationAdministratorChangesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
                if (!element.checked)
                    textValue = "off";
            }
            if (element.checked)
                textValue = "on";
            if (textValue !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        const administratorCheckboxes = document.getElementsByClassName("reconfigurationAdministratorChangesNoChanges");
        for (let ele of administratorCheckboxes) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
                if (!element.checked)
                    textValue = "off";
            }
            if (element.checked)
                textValue = "on";
            if (textValue !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                };
                allAdminSaveElements.push(saveItem);
            }
        }
        const selects = document.getElementsByClassName("reconfigurationAdministratorChangesSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        const allFileElements = [];
        let files = document.getElementsByClassName("reconfigurationAdministratorChangesFileUploadField");
        for (let ele of files) {
            const element = ele;
            const sequenceNbr = parseInt(element.dataset.sequencenbr);
            const planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            const propertyPK = parseInt(element.dataset.propertypk);
            const planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            const planPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const fileUploadFK = parseInt(element.dataset.fileuploadpk);
            const fileItem = {
                PlanFK: planPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK,
                PlanPropertyPK: planPropertyPK,
                FileUploadPK: fileUploadFK,
                File: null
            };
            allFileElements.push(fileItem);
        }
        const saveData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK,
            "AdminCheckboxes": allAdminSaveElements,
            "FileData": allFileElements,
            "DataPointPlanPropertyPKsToSubmitForReview": dataPointPlanPropertyPKsToSubmitForReview
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/SaveReconfigurationAdministratorChanges', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Page saved! Reloading.", "success", 3000, null);
                    window.location.reload();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0 && allAdminSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    createAdministrativeUpdate() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const adminElement = document.getElementById("reconfigurationAdministratorChangesAdministrativeUpdate");
            const updateElement = document.getElementById("reconfigurationAdministratorChangesUpdateCategoryType");
            const planForm = document.getElementById("reconfigurationAdministratorChangesForm");
            const planFK = planForm.dataset.planfk;
            const that = this;
            const adminValue = adminElement.value;
            const updateValue = updateElement.value;
            if (!isNaN(parseInt(adminValue)) && parseInt(adminValue) > 0 && !isNaN(parseInt(updateValue)) && parseInt(updateValue) > 0) {
                let row = 0;
                const allRows = document.querySelectorAll(".rowCheck");
                for (const r of allRows) {
                    const ele = r;
                    const thisRow = parseInt(ele.dataset.row);
                    if (!isNaN(thisRow) && thisRow > row)
                        row = thisRow;
                }
                row++;
                let response = yield fetch(`/SchoolReconfigurations/CreateAdministrativeUpdate/${adminValue}/${updateValue}/${row}/${planFK}`, { credentials: 'include' });
                if (response.ok) {
                    const output = yield response.text();
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = output;
                    0;
                    const container = document.querySelector("#administratorChanges");
                    container.prepend(newDiv);
                    const thisId = `accordionUpdate${adminValue}${updateValue}${row}Id`;
                    new CustomAccordion(thisId);
                    that.bindDeleteAdministrativeUpdates();
                    that.bindCommissionChanges();
                    that.bindChiefLEAAdminDropdowns();
                    that.bindUploadFiles();
                    that.customInitializeRequiredFields(that.validationClasses);
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The Administrative Update has been added!", "success", 2000, null);
                    adminElement.selectedIndex = 0;
                    updateElement.selectedIndex = 0;
                    Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "The Administrative Update and Update Category Type selections are both required.", "warning", 2000, null);
            }
        });
    }
    bindUploadFiles() {
        const uploaders = document.getElementsByClassName("reconfigurationAdministratorChangesFileField");
        for (const upload of uploaders)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const row = e.dataset.row;
        const that = this;
        const files = e.dataset.files;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const dataPointPlanPropertyPK = e.dataset.datapointplanpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.reconfigurationAdministratorChangesFileField[data-formname='${formName}'][data-files='${files}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-files='${files}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-files='${files}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-files='${files}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("reconfigurationAdministratorChangesFileUploadField");
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const files = buttonElement.dataset.files;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteAdminFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.reconfigurationAdministratorChangesFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-file='${files}'] .uploadFileColumn[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-file='${files}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindDeleteAdministrativeUpdates() {
        const deleteAdminUpdateButtons = document.getElementsByClassName("deleteAdminUpdate");
        for (const deleteAdminUpdateButton of deleteAdminUpdateButtons)
            deleteAdminUpdateButton.addEventListener("click", (e) => this.showDeleteAdminUpdate(e));
    }
    showDeleteAdminUpdate(e) {
        const buttonElement = e.target;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const modal = new Modal("deleteAdminUpdateModal", null);
        modal.addAttributeToElement("deleteAdminUpdateModal", "#deleteAdminUpdateConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }
    deleteAdminUpdateConfirm(e) {
        const buttonElement = e.target;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/DeleteAdminUpdate', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const accordionTitle = document.querySelector(`.Accordion-panel[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    const accordionContainer = document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    accordionTitle.remove();
                    accordionContainer.remove();
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteAdminUpdateModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteAdminUpdateModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(dataPointPlanPropertyPK));
    }
    deleteAdminUpdateCancel() {
        const modal = new Modal("deleteAdminUpdateModal", null);
        modal.hide();
    }
    bindChiefLEAAdminDropdowns() {
        const dropdowns = document.getElementsByClassName("reconfigurationAdministratorChangesChiefLEAAdminUpdateField");
        for (const dropdown of dropdowns) {
            dropdown.addEventListener("change", (e) => this.changeChiefLEAAdminDropdown(e.target));
            this.changeChiefLEAAdminDropdown(dropdown);
        }
    }
    changeChiefLEAAdminDropdown(selectElement) {
        const dataPointPlanPropertyPK = selectElement.dataset.datapointplanpropertypk;
        if (parseInt(selectElement.value) > 0) {
            const selectedOption = selectElement[selectElement.selectedIndex];
            const selectedLookupCode = selectedOption.dataset.lookupcode;
            if (selectedLookupCode === "chiefLEAAdminUpdateExecutiveDirector" || selectedLookupCode === "chiefLEAAdminUpdateSuperintendent") {
                this.vacancyChange(false, dataPointPlanPropertyPK);
                this.charterCEOChange(false, dataPointPlanPropertyPK);
                this.chiefLEAAdministratorInformationChange(true, dataPointPlanPropertyPK);
                this.chiefLEACompleteCommissionChange(true, dataPointPlanPropertyPK);
                this.actingSubstituteExtensionChange(true, dataPointPlanPropertyPK);
                const allCommissions = document.querySelectorAll(`.reconfigurationAdministratorChangesCommission[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (const commission of allCommissions)
                    this.commissionChanges(commission);
                this.fiveFiveTwoNineChange(true, dataPointPlanPropertyPK);
            }
            else if (selectedLookupCode === "chiefLEAAdminpdateCEOCharter") {
                this.vacancyChange(false, dataPointPlanPropertyPK);
                this.fiveFiveTwoNineChange(false, dataPointPlanPropertyPK);
                this.charterCEOChange(true, dataPointPlanPropertyPK);
                this.chiefLEAAdministratorInformationChange(false, dataPointPlanPropertyPK);
                this.chiefLEACompleteCommissionChange(false, dataPointPlanPropertyPK);
                this.actingSubstituteExtensionChange(false, dataPointPlanPropertyPK);
                const commission = document.querySelector(`.reconfigurationAdministratorChangesCommission[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                if (commission !== null)
                    this.commissionChanges(commission);
            }
        }
        else {
            this.vacancyChange(false, dataPointPlanPropertyPK);
            this.fiveFiveTwoNineChange(false, dataPointPlanPropertyPK);
            this.charterCEOChange(false, dataPointPlanPropertyPK);
            this.chiefLEAAdministratorInformationChange(false, dataPointPlanPropertyPK);
            this.chiefLEACompleteCommissionChange(false, dataPointPlanPropertyPK);
            this.actingSubstituteExtensionChange(false, dataPointPlanPropertyPK);
            const commission = document.querySelector(`.reconfigurationAdministratorChangesCommission[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (commission !== null)
                this.commissionChanges(commission);
        }
    }
    bindCommissionChanges() {
        const allCommissions = document.getElementsByClassName("reconfigurationAdministratorChangesCommission");
        for (const commission of allCommissions) {
            commission.addEventListener("change", (e) => this.commissionChanges(e.target));
            this.commissionChanges(commission);
        }
    }
    commissionChanges(element) {
        const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
        const lookupCode = element.dataset.lookupcode;
        if (element.checked) {
            if (lookupCode === "chiefLEACommisionYes" || lookupCode === "assistantLEACommissionYes") {
                this.vacancyChange(false, dataPointPlanPropertyPK);
            }
            else {
                this.vacancyChange(true, dataPointPlanPropertyPK);
            }
        }
    }
    vacancyChange(isRequired, dataPointPlanPropertyPK) {
        const vacancyContainer = document.querySelector(`.evidenceOfVacancyContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const vacancyElement = document.querySelector(`.reconfigurationAdministratorChangesVacancy[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (vacancyContainer && vacancyElement)
            if (isRequired) {
                vacancyContainer.classList.remove("hide");
                this._core.forceElementRequired(vacancyElement);
            }
            else {
                vacancyContainer.classList.add("hide");
                this._core.forceElementOptional(vacancyElement);
            }
    }
    fiveFiveTwoNineChange(isRequired, dataPointPlanPropertyPK) {
        const fiveFiveTwoNineContainer = document.querySelector(`.fiveFiveTwoNineContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const fiveFiveTwoNineElement = document.querySelector(`.reconfigurationAdministratorChanges5529[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (fiveFiveTwoNineContainer && fiveFiveTwoNineElement)
            if (isRequired) {
                fiveFiveTwoNineContainer.classList.remove("hide");
                this._core.forceElementRequired(fiveFiveTwoNineElement);
            }
            else {
                fiveFiveTwoNineContainer.classList.add("hide");
                this._core.forceElementOptional(fiveFiveTwoNineElement);
            }
    }
    charterCEOChange(isRequired, dataPointPlanPropertyPK) {
        const chiefLEAAdminCEOContainer = document.querySelector(`.chiefLEAAdminCEOContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const charterCEOFields = document.querySelectorAll(`.reconfigurationAdministratorChangesCEOField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const charterCEOUploads = document.querySelectorAll(`.reconfigurationAdministratorChangesCEOFileField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (isRequired) {
            chiefLEAAdminCEOContainer.classList.remove("hide");
            for (const charterCEOField of charterCEOFields) {
                const ele = charterCEOField;
                this._core.forceElementRequired(ele);
            }
            for (const charterCEOUpload of charterCEOUploads) {
                const ele = charterCEOUpload;
                this._core.forceElementRequired(ele);
            }
        }
        else {
            chiefLEAAdminCEOContainer.classList.add("hide");
            for (const charterCEOField of charterCEOFields) {
                const ele = charterCEOField;
                this._core.forceElementOptional(ele);
            }
            for (const charterCEOUpload of charterCEOUploads) {
                const ele = charterCEOUpload;
                this._core.forceElementOptional(ele);
            }
        }
    }
    chiefLEAAdministratorInformationChange(isRequired, dataPointPlanPropertyPK) {
        const chiefLEAAdministratorInformationContainer = document.querySelector(`.chiefLEAAdministratorInformationContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const chiefLEAAdministratorInformationContainerFields = document.querySelectorAll(`.chiefLEAAdministratorInformationContainerField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (isRequired) {
            chiefLEAAdministratorInformationContainer.classList.remove("hide");
            for (const chiefLEAAdministratorInformationContainerField of chiefLEAAdministratorInformationContainerFields) {
                const ele = chiefLEAAdministratorInformationContainerField;
                if (!(ele.dataset.ignorerequired && ele.dataset.ignorerequired === "true"))
                    this._core.forceElementRequired(ele);
            }
        }
        else {
            chiefLEAAdministratorInformationContainer.classList.add("hide");
            for (const chiefLEAAdministratorInformationContainerField of chiefLEAAdministratorInformationContainerFields) {
                const ele = chiefLEAAdministratorInformationContainerField;
                this._core.forceElementOptional(ele);
            }
        }
    }
    chiefLEACompleteCommissionChange(isRequired, dataPointPlanPropertyPK) {
        const chiefLEACompleteCommissionContainer = document.querySelector(`.chiefLEACompleteCommissionContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const chiefLEACompleteCommissionContainerFields = document.querySelectorAll(`.chiefLEACompleteCommissionContainerField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (isRequired) {
            chiefLEACompleteCommissionContainer.classList.remove("hide");
            for (const chiefLEACompleteCommissionContainerField of chiefLEACompleteCommissionContainerFields) {
                const ele = chiefLEACompleteCommissionContainerField;
                this._core.forceElementRequired(ele);
            }
        }
        else {
            chiefLEACompleteCommissionContainer.classList.add("hide");
            for (const chiefLEACompleteCommissionContainerField of chiefLEACompleteCommissionContainerFields) {
                const ele = chiefLEACompleteCommissionContainerField;
                this._core.forceElementOptional(ele);
            }
        }
    }
    actingSubstituteExtensionChange(isRequired, dataPointPlanPropertyPK) {
        const actingSubstituteExtensionContainer = document.querySelector(`.actingSubstituteExtensionContainer[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const actingSubstituteExtensionContainerFields = document.querySelectorAll(`.actingSubstituteExtensionContainerField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        if (actingSubstituteExtensionContainer)
            if (isRequired) {
                actingSubstituteExtensionContainer.classList.remove("hide");
                for (const actingSubstituteExtensionContainerField of actingSubstituteExtensionContainerFields) {
                    const ele = actingSubstituteExtensionContainerField;
                    this._core.forceElementRequired(ele);
                }
            }
            else {
                actingSubstituteExtensionContainer.classList.add("hide");
                for (const actingSubstituteExtensionContainerField of actingSubstituteExtensionContainerFields) {
                    const ele = actingSubstituteExtensionContainerField;
                    this._core.forceElementOptional(ele);
                }
            }
    }
    doCustomValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            const htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!element.classList.contains("missing-field")) {
                    //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                    if ("isValid" in htmlElement.dataset) {
                        if (htmlElement.dataset.isValid === "false") {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = element;
                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");
                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated = false;
                                    let isChecked = false;
                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }
                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }
                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            }
                            else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                if (!inputElement.checked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLSelectElement) {
                            let selectElement = element;
                            //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                            if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                            let hasSelection = false;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }
                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (htmlElement.classList.contains("multiSelect")) {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                            let hasSelection = false;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }
                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    customInitializeRequiredFields(allClasses) {
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
class ReviewAdmin {
    constructor() {
        let that = this;
        this._core = new Core();
        this.searchForm = document.getElementById("reviewSearchForm");
        this._core.activateSubMenu("pdeApproval");
        this._core.bindChangeLEA();
        let clearButton = document.getElementById("clearSearch");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                that.clearSearchFilter();
            });
        }
        let pagingHdnInput = document.getElementById("Query_PageNumber");
        window.pagingAction = function (pageToGoTo) {
            if (pagingHdnInput !== null && that.searchForm !== null) {
                pagingHdnInput.value = pageToGoTo;
                that.searchForm.submit();
            }
        };
        this.searchForm.addEventListener("submit", function (e) {
            e.preventDefault();
            if (that.validateSearch()) {
                if (pagingHdnInput !== null) {
                    pagingHdnInput.value = "1";
                }
                that.searchForm.submit();
            }
        });
        $('[data-multi-select]').MultiSelect();
    }
    clearSearchFilter() {
        const inputs = this.searchForm.querySelectorAll("input");
        for (let input of inputs) {
            if (input instanceof HTMLInputElement) {
                input.value = "";
                input.checked = false;
            }
            if (input instanceof HTMLSelectElement) {
                input.selectedIndex = 0;
            }
        }
        this.searchForm.submit();
    }
    validateSearch() {
        const daysInReviewMin = this.searchForm.querySelector("[name='Query.DaysInReviewMin']");
        const daysInReviewMax = this.searchForm.querySelector("[name='Query.DaysInReviewMax']");
        const timesInRevisionMin = this.searchForm.querySelector("[name='Query.TimesInRevisionMin']");
        const timesInRevisionMax = this.searchForm.querySelector("[name='Query.TimesInRevisionMax']");
        const daysSinceSubmissionMin = this.searchForm.querySelector("[name='Query.DaysSinceSubmissionMin']");
        const daysSinceSubmissionMax = this.searchForm.querySelector("[name='Query.DaysSinceSubmissionMax']");
        [].forEach.call(this.searchForm.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        if (daysInReviewMin !== null && daysInReviewMax !== null && parseInt(daysInReviewMax.value) < parseInt(daysInReviewMin.value)) {
            Core.createHTMLAlert("alertMessageDiv", "Days in Review Max cannot be less than Days in Review Min", "error", 3000, null);
            daysInReviewMax.classList.add("missing-field");
            return false;
        }
        if (timesInRevisionMin !== null && timesInRevisionMax !== null && parseInt(timesInRevisionMax.value) < parseInt(timesInRevisionMin.value)) {
            Core.createHTMLAlert("alertMessageDiv", "Times in Revision Max cannot be less than Times in Revision Min", "error", 3000, null);
            timesInRevisionMax.classList.add("missing-field");
            return false;
        }
        if (daysSinceSubmissionMin !== null && daysSinceSubmissionMax !== null && parseInt(daysSinceSubmissionMax.value) < parseInt(daysSinceSubmissionMin.value)) {
            Core.createHTMLAlert("alertMessageDiv", "Days Since Submission Max cannot be less than Days Since Submission Max Min", "error", 3000, null);
            daysSinceSubmissionMax.classList.add("missing-field");
            return false;
        }
        return true;
    }
}
class ReviewQueue extends ReviewAdmin {
    constructor() {
        super();
        let that = this;
        that.backToLEAModal = new Modal("backToLEAModal", null);
        let reviewConfirmButton = document.getElementById("reviewConfirm");
        if (reviewConfirmButton !== null)
            reviewConfirmButton.addEventListener("click", (e) => this.reviewPlanConfirm(e));
        let reviewCancelButton = document.getElementById("reviewCancel");
        if (reviewCancelButton !== null)
            reviewCancelButton.addEventListener("click", (e) => this.reviewPlanCancel(e));
        let backToLEAConfirmButton = document.getElementById("backToLEAConfirm");
        if (backToLEAConfirmButton !== null)
            backToLEAConfirmButton.addEventListener("click", (e) => this.backToLEAConfirm());
        let backToLEACancelButton = document.getElementById("backToLEACancel");
        if (backToLEACancelButton !== null)
            backToLEACancelButton.addEventListener("click", (e) => this.backToLEACancel());
        let emailLinks = document.querySelectorAll(".emailLink[data-plan-pk]");
        for (let link of emailLinks) {
            let linkElement = link;
            linkElement.addEventListener("click", () => {
                ReviewQueue.emailLEA(parseInt(linkElement.dataset.planPk));
            });
        }
        this.bindReview();
        this.bindResume();
        this.bindBackToLEA();
        let workflowLogButtons = document.querySelectorAll(".openWorkflowLog[data-workflow-instance-fk][data-planfk]");
        for (let btn of workflowLogButtons) {
            let btnEl = btn;
            btnEl.addEventListener("click", function () {
                that.showWorkflowLog(parseInt(btnEl.dataset.workflowInstanceFk), parseInt(btnEl.dataset.planfk));
            });
        }
        let workflowChangeButtons = document.querySelectorAll(".moveWorkflow[data-workflow-instance-fk][data-planfk]");
        for (let btn of workflowChangeButtons) {
            let btnEl = btn;
            btnEl.addEventListener("click", function () {
                that.showWorkflowStateChanger(parseInt(btnEl.dataset.workflowInstanceFk), parseInt(btnEl.dataset.planfk));
            });
        }
    }
    resumePlan(e) {
        let button = e.target;
        let planFK = button.dataset.planfk;
        let planTypeCode = button.dataset.plantypecode;
        let planTypeFK = button.dataset.plantypefk;
        window.location.href = `/Review/Resume/${planFK}/${planTypeCode}/${planTypeFK}`;
    }
    reviewPlan(e) {
        let button = e.target;
        let planFK = button.dataset.planfk;
        let planTypeCode = button.dataset.plantypecode;
        let planTypeFK = button.dataset.plantypefk;
        let planTypeGroupCodeFK = button.dataset.plantypegroupcodefk;
        //Load Modal
        let modal = new Modal("reviewModal", null);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "planfk", planFK);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "plantypecode", planTypeCode);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "plantypefk", planTypeFK);
        modal.addAttributeToElement("reviewModal", "#reviewConfirm", "plantypegroupcodefk", planTypeGroupCodeFK);
        modal.show();
    }
    reviewPlanConfirm(e) {
        let modal = new Modal("reviewModal", null);
        modal.hide();
        let reviewText = document.getElementById("reviewEmailCustom");
        let that = this;
        let reviewConfirmButton = document.getElementById("reviewConfirm");
        let planFK = reviewConfirmButton.dataset.planfk;
        let planTypeCode = reviewConfirmButton.dataset.plantypecode;
        let planTypeFK = reviewConfirmButton.dataset.plantypefk;
        let planTypeGroupCodeFK = reviewConfirmButton.dataset.plantypegroupcodefk;
        Core.showLoader();
        let data = {
            "PlanFK": parseInt(planFK),
            "EmailText": reviewText.value
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/StartReview', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                var resp = JSON.parse(xhr.response);
                if (resp.success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The plan is now being reviewed. Please wait while you are redirected to the plan", "success", 3000, null);
                    let redirect = `/Review/Resume/${planFK}/${planTypeCode}/${planTypeFK}`;
                    let reviewText = document.getElementById("reviewEmailCustom");
                    reviewText.value = "";
                    setTimeout(function () { window.location.href = redirect; }, 3000);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", resp.reason, "error", 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                Core.hideLoader();
            }
        };
        xhr.send(JSON.stringify(data));
    }
    reviewPlanCancel(e) {
        let modal = new Modal("reviewModal", null);
        modal.hide();
        let reviewText = document.getElementById("reviewEmailCustom");
        reviewText.value = "";
    }
    backToLEA(e) {
        let that = this;
        let button = e.target;
        that.backToLEAPlanPK = parseInt(button.dataset.planPk);
        that.backToLEAModal.callingId = button.id;
        that.backToLEAModal.show();
        let backToLEAText = document.getElementById("backToLEAComment");
        backToLEAText.value = "";
    }
    backToLEACancel() {
        let that = this;
        that.backToLEAModal.hide();
    }
    backToLEAConfirm() {
        let that = this;
        that.backToLEAModal.hide();
        let reviewText = document.getElementById("backToLEAComment");
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/BackToLEA', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                var resp = JSON.parse(xhr.response);
                if (resp.success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The plan/report has been sent back to the LEA.", "success", 3000, window.location.reload());
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", resp.reason, "error", 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                Core.hideLoader();
            }
        };
        xhr.send(`PlanPK=${that.backToLEAPlanPK}&AdditionalComments=${reviewText.value}`);
    }
    bindResume() {
        let reviewStopGapResumePlanButtons = document.getElementsByClassName("reviewStopGapResumePlan");
        for (let resume of reviewStopGapResumePlanButtons)
            resume.addEventListener("click", (e) => this.resumePlan(e));
    }
    bindReview() {
        let reviewStopGapReviewPlanButtons = document.getElementsByClassName("reviewStopGapReviewPlan");
        for (let review of reviewStopGapReviewPlanButtons)
            review.addEventListener("click", (e) => this.reviewPlan(e));
    }
    bindBackToLEA() {
        let reviewStopGapBackToLEAButtons = document.getElementsByClassName("returnToLEA");
        for (let backToLEA of reviewStopGapBackToLEAButtons)
            backToLEA.addEventListener("click", (e) => this.backToLEA(e));
    }
    showWorkflowLog(workflowInstancePK, planPK) {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/Review/GetWorkflowLog?workflowInstancePK=' + workflowInstancePK + '&planPK=' + planPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal = new Modal('workflowLogModal', null);
                modal.show();
                const body = document.getElementById('workflowLogModalBody');
                if (body != null) {
                    $(body).html(xhr.responseText);
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }
    showWorkflowStateChanger(workflowInstancePK, planPK) {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/Review/GetWorkflowStateChanger?workflowInstancePK=' + workflowInstancePK + '&planPK=' + planPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal = new Modal('workflowStateChanger', null);
                modal.show();
                const workflowStateChangerBody = document.getElementById('workflowStateChangerBody');
                if (workflowStateChangerBody != null) {
                    $(workflowStateChangerBody).html(xhr.responseText);
                    let stateSelector = document.getElementById("moveToState");
                    let submitButton = document.getElementById("moveWorkflowInstanceToState");
                    if (stateSelector != null) {
                        stateSelector.addEventListener("change", function (e) {
                            let eventContainer = document.getElementById("possibleEventsContainer");
                            eventContainer.classList.add("hide");
                            submitButton.disabled = true;
                            if (stateSelector.value != "") {
                                submitButton.disabled = false;
                                Core.showLoader();
                                const eventXHR = new XMLHttpRequest();
                                eventXHR.open('GET', '/Review/GetWorkflowEventsForState?workflowInstancePK=' + workflowInstancePK + '&statePK=' + stateSelector.value, true);
                                eventXHR.setRequestHeader('Content-type', 'application/json');
                                eventXHR.onload = function () {
                                    Core.hideLoader();
                                    if (eventXHR.status === 200) {
                                        $(eventContainer).html(eventXHR.responseText);
                                        eventContainer.classList.remove("hide");
                                    }
                                    else {
                                        Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred loading possible Events', 'error', 3000, null);
                                    }
                                };
                                eventXHR.send();
                            }
                        });
                    }
                    if (submitButton != null) {
                        submitButton.addEventListener("click", function () {
                            if (stateSelector != null && stateSelector.value != "") {
                                Core.showLoader();
                                const moveXHR = new XMLHttpRequest();
                                moveXHR.open('POST', '/Review/MoveWorkflowInstanceToState?workflowInstancePK=' + workflowInstancePK + '&statePK=' + stateSelector.value, true);
                                moveXHR.setRequestHeader('Content-type', 'application/json');
                                moveXHR.onload = function () {
                                    Core.hideLoader();
                                    var resp = JSON.parse(moveXHR.response);
                                    if (moveXHR.status === 200 && resp.success) {
                                        Core.createHTMLAlert('alertMessageDiv', 'Successfully moved Workflow.', 'success', 3000, window.location.reload());
                                    }
                                    else {
                                        Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred moving workflow.', 'error', 3000, null);
                                    }
                                };
                                var moveModel = {
                                    WorkflowInstancePK: workflowInstancePK,
                                    StatePK: stateSelector.value,
                                    OmittedEvents: []
                                };
                                let omittedEvents = document.querySelectorAll(".workflowEventToOmit");
                                for (let oe of omittedEvents) {
                                    let oeEl = oe;
                                    if (!oeEl.checked) {
                                        moveModel.OmittedEvents.push(parseInt(oeEl.value));
                                    }
                                }
                                moveXHR.send(JSON.stringify(moveModel));
                            }
                        });
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }
    static emailLEA(planPK) {
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/Review/GetPlanContactInformation?planFK=${planPK}`, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                var resp = JSON.parse(xhr.response);
                if (resp.success === true) {
                    Core.hideLoader();
                    window.location.href = `mailto:${resp.emailAddress}`;
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", resp.reason, "error", 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                Core.hideLoader();
            }
        };
        xhr.send();
    }
}
class ReviewAssign extends ReviewAdmin {
    constructor() {
        super();
        let that = this;
        this.bindAddButtons();
        this.bindSaveButtons();
        this.bindDeleteButtons();
        //For the autoselect dropdowns (i.e. datalists), when selection is made, update the main input with the data-value
        document.addEventListener("input", (event) => {
            let el = event.target;
            if (el.classList.contains("reviewerAutoComplete") && el.hasAttribute("list")) {
                let elInput = el;
                //Find the corresponding option in the datalist and grab its data-value
                var selectedDataListOption = document.querySelector(`#${elInput.getAttribute("list")} option[value='${elInput.value}'][data-value]`);
                if (selectedDataListOption != null) {
                    elInput.dataset.value = selectedDataListOption.dataset.value;
                }
            }
        });
    }
    bindAddButtons() {
        let bindAddButtons = document.getElementsByClassName("addButton");
        for (let bindAddButton of bindAddButtons) {
            bindAddButton.addEventListener("click", (e) => this.addSelect(e));
        }
    }
    bindSaveButtons() {
        let bindSaveButtons = document.getElementsByClassName("assignButton");
        for (let bindSaveButton of bindSaveButtons) {
            bindSaveButton.addEventListener("click", (e) => this.save(e));
        }
    }
    bindDeleteButtons() {
        let bindDeleteButtons = document.getElementsByClassName("deleteButton");
        for (let bindDeleteButton of bindDeleteButtons) {
            let bindDeleteButtonEl = bindDeleteButton;
            if (!("deleteEventAdded" in bindDeleteButtonEl.dataset)) {
                bindDeleteButtonEl.addEventListener("click", (e) => this.deleteUser(e));
                bindDeleteButtonEl.dataset.deleteEventAdded = "true";
            }
        }
    }
    addSelect(e) {
        let core = new Core();
        let that = this;
        let button = e.currentTarget;
        //let userPK = button.dataset.usereddiruser;
        let currentPage = button.dataset.currentpage;
        let divName = button.dataset.add;
        let groupPk = button.dataset.grouppk;
        let pdeBureauPK = button.dataset.pdebureaupk;
        let frcppInstitutionPK = button.dataset.frcppinstitutionpk;
        let planPK = button.dataset.planFk;
        let div = document.getElementById(divName);
        let rows = div.querySelectorAll("select");
        let rowCount = 1;
        for (let row of rows) {
            rowCount++;
        }
        let xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('POST', '/Review/AssignDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                //let element = div.querySelector("tbody");
                $(div).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Reviewer Added", 'success', 3000, null);
                that.bindDeleteButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("currentPage=" + currentPage + "&groupPK=" + groupPk + "&pdebureaupk=" + pdeBureauPK + "&frcppInstitutionPK=" + frcppInstitutionPK + "&rowCount=" + rowCount + "&planPK=" + planPK);
    }
    save(e) {
        let core = new Core();
        let allSaveElements = [];
        let button = e.currentTarget;
        let process = parseInt(button.dataset.process);
        let institution = parseInt(button.dataset.institution);
        let divName = button.dataset.div;
        let div = document.getElementById(divName);
        let planFK = parseInt(button.dataset.planFk);
        let selectElements = div.getElementsByClassName("groupSelect");
        let selectElementValue = 0;
        for (let selectElement of selectElements) {
            let element = selectElement;
            selectElementValue = parseInt(element.value);
            let groupPK = parseInt(element.dataset.group);
            if (selectElementValue !== 0) {
                let saveItem = {
                    MyPDESuitePortalObjectID: selectElementValue,
                    GroupPK: groupPK,
                    FRCPPInstitutionPK: institution,
                    ProcessPK: process,
                    PlanFK: planFK
                };
                allSaveElements.push(saveItem);
            }
        }
        let autoCompleteElements = div.querySelectorAll(".reviewerAutoComplete[data-group][data-value]");
        for (let autoCompleteElement of autoCompleteElements) {
            let element = autoCompleteElement;
            if (element.dataset.value !== "") {
                let saveItem = {
                    MyPDESuitePortalObjectID: parseInt(element.dataset.value),
                    GroupPK: parseInt(element.dataset.group),
                    FRCPPInstitutionPK: institution,
                    ProcessPK: process,
                    PlanFK: planFK
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allSaveElements));
    }
    deleteUser(e) {
        let core = new Core();
        let allSaveElements = [];
        let button = e.currentTarget;
        let portalObjectID = parseInt(button.dataset.user);
        let fRCPPInstitutionPK = parseInt(button.dataset.institution);
        let groupPK = parseInt(button.dataset.group);
        let div = Core.findClosest(button, ".reviewerContainer");
        if (portalObjectID === 0 || isNaN(portalObjectID)) {
            div.parentNode.removeChild(div);
            return;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/DeleteAssignedUser', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                //parent.parentElement.removeChild(parent);
                div.parentNode.removeChild(div);
                Core.createHTMLAlert("alertMessageDiv", "User Successfully Removed", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("portalObjectID=" + portalObjectID + "&groupPK=" + groupPK + "&fRCPPInstitutionPK=" + fRCPPInstitutionPK);
    }
}
class ReviewAssignByProcess {
    constructor() {
        let that = this;
        that._core = new Core();
        that._core.bindChangeLEA();
        let processDropDown = document.getElementById("processSelection");
        processDropDown.addEventListener("change", () => {
            if (processDropDown.value) {
                that.loadProcessDetails(parseInt(processDropDown.value));
            }
            else {
                let container = document.getElementById("processGroupDetails");
                if (container != null) {
                    container.innerHTML = "";
                }
            }
        });
        //For the autoselect dropdowns (i.e. datalists), when selection is made, update the main input with the data-value
        document.addEventListener("click", (event) => {
            let el = event.target;
            if (el.classList.contains("addAllInstProcessGroupUser") && "processGroupPk" in el.dataset) {
                //See if there's a value for user selected
                var userSelection = document.querySelector(`.allInstProcessGroupUserSelect[data-process-group-pk='${el.dataset.processGroupPk}']`);
                if (userSelection != null && userSelection.value) {
                    that.addProcessGroupUser(parseInt(el.dataset.processGroupPk), parseInt(userSelection.value));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Please select a reviewer to add.", 'error', 3000, null);
                }
            }
            else if (el.classList.contains("removeAllInstProcessGroupUser") && "processGroupUserPk" in el.dataset) {
                that.removeProcessGroupUser(parseInt(el.dataset.processGroupUserPk));
            }
        });
    }
    loadProcessDetails(processFK) {
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/Review/GetAssignByProcessDetails?processPK=${processFK}`, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let container = document.getElementById("processGroupDetails");
                if (container != null) {
                    container.innerHTML = xhr.responseText;
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error loading details for this process.", 'error', 3000, null);
            }
        };
        xhr.send();
    }
    addProcessGroupUser(processGroupFK, userFK) {
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/AssignUserToProcess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                let processDropDown = document.getElementById("processSelection");
                that.loadProcessDetails(parseInt(processDropDown.value));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error adding reviewer.", 'error', 3000, null);
            }
        };
        xhr.send(`processGroupPK=${processGroupFK}&userFK=${userFK}`);
    }
    removeProcessGroupUser(processGroupUserFK) {
        let that = this;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/RemoveAllInstProcessAccessForUser', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                let processDropDown = document.getElementById("processSelection");
                that.loadProcessDetails(parseInt(processDropDown.value));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an error removing reviewer.", 'error', 3000, null);
            }
        };
        xhr.send(`processGroupUserPK=${processGroupUserFK}`);
    }
}
class SpecialEducationReviewAssign {
    constructor() {
        this._core = new Core();
        this.bindAddRowButtons();
        this.bindSaveButtons();
        this.bindDeleteButtons();
        this.bindDeleteRowButtons();
        //this._core.activateSubMenu("pdeApproval");
    }
    bindSaveButtons() {
        let saveUserAccessButtons = document.getElementsByClassName("saveUserAccess");
        for (let saveUserAccessButton of saveUserAccessButtons) {
            saveUserAccessButton.addEventListener("click", (e) => this.save(e));
        }
    }
    bindAddRowButtons() {
        let bindAddRowButtons = document.getElementsByClassName("addAnotherRow");
        for (let bindAddRowButton of bindAddRowButtons) {
            bindAddRowButton.addEventListener("click", (e) => this.addRow(e));
        }
    }
    bindDeleteButtons() {
        let deleteUserAccessButtons = document.getElementsByClassName("deleteUserAccess");
        for (let deleteUserAccessButton of deleteUserAccessButtons) {
            deleteUserAccessButton.addEventListener("click", (e) => this.deleteUserAccess(e));
        }
    }
    bindDeleteRowButtons() {
        let deleteUserAccessButtons = document.getElementsByClassName("deleteRow");
        for (let deleteUserAccessButton of deleteUserAccessButtons) {
            deleteUserAccessButton.addEventListener("click", (e) => this.deleteUserRow(e));
        }
    }
    save(e) {
        let core = new Core();
        Core.showLoader();
        let allSaveElements = [];
        let allDeleteElements = [];
        let saveObject;
        let button = e.currentTarget;
        let userid = button.dataset.userid;
        let tableid = button.dataset.tableid;
        let table = document.getElementById(tableid);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let thisrow = row;
            let rowid = thisrow.dataset.rownumber;
            let select = document.getElementById("select_" + rowid);
            let selectElement = select;
            let selectElementValue = 0;
            selectElementValue = parseInt(selectElement.value);
            let reviewer = document.getElementById("reviewer_" + rowid);
            let reviewerElement = reviewer;
            if (reviewerElement.checked) {
                let saveItem = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allSaveElements.push(saveItem);
            }
            ;
            if (reviewerElement.checked === false && (reviewerElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                let deleteitem = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allDeleteElements.push(deleteitem);
            }
            ;
            let approver = document.getElementById("approver_" + rowid);
            if (approver != null) {
                let approverElement = approver;
                if (approverElement.checked) {
                    let saveItem = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allSaveElements.push(saveItem);
                }
                ;
                if (approverElement.checked === false && (approverElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                    let deleteItem = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allDeleteElements.push(deleteItem);
                }
                ;
            }
        }
        saveObject = {
            SaveList: allSaveElements,
            DeleteList: allDeleteElements,
            UserPK: parseInt(userid)
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveSpecialEducationAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    deleteUserAccess(e) {
        let core = new Core();
        Core.showLoader();
        let allSaveElements = [];
        let allDeleteElements = [];
        let saveObject;
        let button = e.currentTarget;
        let userid = button.dataset.userid;
        let tableid = button.dataset.tableid;
        let table = document.getElementById(tableid);
        let rows = table.querySelectorAll("tr[data-rownumber]");
        for (let row of rows) {
            let thisrow = row;
            let rowid = thisrow.dataset.rownumber;
            let select = document.getElementById("select_" + rowid);
            let selectElement = select;
            let selectElementValue = 0;
            selectElementValue = parseInt(selectElement.value);
            let reviewer = document.getElementById("reviewer_" + rowid);
            let reviewerElement = reviewer;
            if (reviewerElement.checked) {
                let deleteitem = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allDeleteElements.push(deleteitem);
            }
            ;
            if (reviewerElement.checked === false && (reviewerElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                let deleteitem = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(reviewerElement.dataset.seprn),
                    ReviewPK: parseInt(reviewerElement.dataset.review),
                };
                allDeleteElements.push(deleteitem);
            }
            ;
            let approver = document.getElementById("approver_" + rowid);
            if (approver != null) {
                let approverElement = approver;
                if (approverElement.checked) {
                    let deleteItem = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allDeleteElements.push(deleteItem);
                }
                ;
                if (approverElement.checked === false && (approverElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                    let deleteItem = {
                        SpecialEducationLearningNetworkFK: selectElementValue,
                        UserFK: parseInt(userid),
                        SeprnPK: parseInt(approverElement.dataset.seprn),
                        ReviewPK: parseInt(approverElement.dataset.review),
                    };
                    allDeleteElements.push(deleteItem);
                }
                ;
            }
        }
        saveObject = {
            SaveList: allSaveElements,
            DeleteList: allDeleteElements,
            UserPK: parseInt(userid)
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveSpecialEducationAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
    addRow(e) {
        let core = new Core();
        let that = this;
        let button = e.currentTarget;
        let userid = button.dataset.userid;
        let tableid = button.dataset.tableid;
        let isReviewer = button.dataset.isreviewer;
        let isApprover = button.dataset.isapprover;
        let table = document.getElementById(tableid);
        let rows = document.querySelectorAll("tr[data-rownumber]");
        let rowCount = 1;
        for (let row of rows) {
            rowCount++;
        }
        let xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('POST', '/Review/SpecialEducationAssignDataFieldRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let element = table.querySelector("tbody");
                $(element).append(xhr.responseText);
                Core.createHTMLAlert("alertMessageDiv", "New Reviewer Added", 'success', 3000, null);
                that.bindDeleteRowButtons();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("userPK=" + userid + "&rowCount=" + rowCount + "&isApprover=" + isApprover + "&isReviewer=" + isReviewer);
    }
    deleteUserRow(e) {
        let core = new Core();
        Core.showLoader();
        let allSaveElements = [];
        let allDeleteElements = [];
        let saveObject;
        let button = e.currentTarget;
        let userid = button.dataset.userid;
        let rowid = button.dataset.rowid;
        let row = document.getElementById(rowid);
        let parent = button.parentElement.parentElement; //To the TR
        let select = document.getElementById("select_" + rowid);
        let selectElement = select;
        let selectElementValue = 0;
        selectElementValue = parseInt(selectElement.value);
        let reviewer = document.getElementById("reviewer_" + rowid);
        let reviewerElement = reviewer;
        if (reviewerElement.checked) {
            let deleteitem = {
                SpecialEducationLearningNetworkFK: selectElementValue,
                UserFK: parseInt(userid),
                SeprnPK: parseInt(reviewerElement.dataset.seprn),
                ReviewPK: parseInt(reviewerElement.dataset.review),
            };
            allDeleteElements.push(deleteitem);
        }
        ;
        if (reviewerElement.checked === false && (reviewerElement.dataset.currentlyisassigned).toLowerCase() === "true") {
            let deleteitem = {
                SpecialEducationLearningNetworkFK: selectElementValue,
                UserFK: parseInt(userid),
                SeprnPK: parseInt(reviewerElement.dataset.seprn),
                ReviewPK: parseInt(reviewerElement.dataset.review),
            };
            allDeleteElements.push(deleteitem);
        }
        ;
        let approver = document.getElementById("approver_" + rowid);
        if (approver != null) {
            let approverElement = approver;
            if (approverElement.checked) {
                let deleteItem = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(approverElement.dataset.seprn),
                    ReviewPK: parseInt(approverElement.dataset.review),
                };
                allDeleteElements.push(deleteItem);
            }
            ;
            if (approverElement.checked === false && (approverElement.dataset.currentlyisassigned).toLowerCase() === "true") {
                let deleteItem = {
                    SpecialEducationLearningNetworkFK: selectElementValue,
                    UserFK: parseInt(userid),
                    SeprnPK: parseInt(approverElement.dataset.seprn),
                    ReviewPK: parseInt(approverElement.dataset.review),
                };
                allDeleteElements.push(deleteItem);
            }
            ;
        }
        saveObject = {
            SaveList: allSaveElements,
            DeleteList: allDeleteElements,
            UserPK: parseInt(userid)
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Review/SaveSpecialEducationAssignRecords', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                parent.parentElement.removeChild(parent);
                Core.createHTMLAlert("alertMessageDiv", "Save Successful", 'success', 3000, core.pageReload(true, null));
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(saveObject));
    }
}
class AuthorizingLEAReviewDashboard extends ReviewQueue {
    constructor() {
        super();
        let exports = document.getElementsByClassName("exportToWord");
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLoader();
                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('GET', `/ExportDocx/FullReportCharterSchoolExport?planFK=${exportOption.dataset.planPk}`, true);
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    Core.hideLoader();
                    if (xhr.status === 200) {
                        let blob = this.response;
                        let leaName = "";
                        let leaNameElement = document.querySelector(`.districtName[data-plan-pk='${exportOption.dataset.planPk}']`);
                        if (leaNameElement != null) {
                            leaName = leaNameElement.innerHTML;
                        }
                        let filename = `${leaName} - Charter School Annual Report.docx`;
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        }
                        else {
                            var a = document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                };
                xhr.send();
            });
        }
        this._core.activateSubMenu("AuthorizingLEAReviewQueue");
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
class Page {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null) {
        this._core = new Core();
        this._navigationCode = navigationCode;
        this._pageCode = pageCode;
        this._pageLabel = pageLabel;
        this._planPK = planPK;
        this._templateFK = templateFK;
        this._fieldset = new Fieldset(this._navigationCode, this._pageLabel);
        this._fileHandler = new FileHandler(this._fieldset.File, this._navigationCode, this._pageCode, this._planPK);
        this._saveHandler = new SaveHandler(this._pageCode, this._planPK, this._pageLabel, this._fieldset, this._navigationCode);
        this._validationHandler = new ValidationHandler(this._fieldset, customValidationFields);
        this._groupHandler = new GroupHandler(this._core, this._navigationCode, this._pageCode, this._planPK, this._templateFK, this._validationHandler);
        this._radioButtonControlHandler = new RadioButtonControlHandler(this._core, this._validationHandler);
        this._checkboxControlHandler = new CheckboxControlHandler(this._core, this._validationHandler);
        this._exportHandler = new ExportHandler(this._core, this._planPK, this._templateFK);
        this._pageSectionInfoHandler = new PageSectionInfoHandler();
        this._validationHandler.initializeRequiredFields();
        this._fromSaveElement = document.getElementById('fromSave');
        const hiddenInput = document.getElementById('hashValue');
        if (hiddenInput)
            hiddenInput.value = this._saveHandler.createHash();
    }
    getCore() {
        return this._core;
    }
}
class Fieldset {
    constructor(navigationCode, pageLabel) {
        this.Accordion = `${navigationCode + pageLabel}AccordionField`;
        this.Button = `${navigationCode + pageLabel}Button`;
        this.Calendar = `${navigationCode + pageLabel}DateField`;
        this.Checkbox = `${navigationCode + pageLabel}CheckboxField`;
        this.File = `${navigationCode + pageLabel}FileField`;
        this.MultiSelect = `${navigationCode + pageLabel}MultiSelectField`;
        this.MultiSelectOption = `${navigationCode + pageLabel}MultiSelectOptionField`;
        this.Radio = `${navigationCode + pageLabel}RadioField`;
        this.Select = `${navigationCode + pageLabel}SelectField`;
        this.Text = `${navigationCode + pageLabel}TextField`;
        this.Textarea = `${navigationCode + pageLabel}TextAreaField`;
        this.ValidationClasses = [
            this.Calendar,
            this.Checkbox,
            this.File,
            this.MultiSelect,
            this.Radio,
            this.Select,
            this.Text,
            this.Textarea
        ];
        this.SaveableClasses = [
            this.Calendar,
            this.Checkbox,
            this.MultiSelectOption,
            this.Radio,
            this.Select,
            this.Text,
            this.Textarea
        ];
    }
}
class SharedPageAccordion {
    constructor(accordionId) {
        const accordion = document.getElementById(accordionId);
        const accordionButton = accordion;
        accordionButton.setAttribute('data-hasclick', 'true');
        accordionButton.addEventListener('click', (e) => this.toggle(accordion.id));
    }
    toggle(id) {
        const element = document.getElementById(id);
        const contentId = element.getAttribute('aria-controls');
        const contentElement = document.getElementById(contentId);
        contentElement.classList.contains('open')
            ? this.hide(element, contentElement)
            : this.show(element, contentElement);
    }
    show(element, contentElement) {
        element.classList.add('open');
        element.setAttribute('aria-expanded', 'true');
        contentElement.classList.add('open');
    }
    hide(element, contentElement) {
        element.classList.remove('open');
        element.setAttribute('aria-expanded', 'false');
        contentElement.classList.remove('open');
    }
}
class HttpRequestHandler {
    static post(url, successMessage, errorMessage, data, contentType) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let message = successMessage;
            let alertType = 'success';
            let request = {
                method: 'POST',
                credentials: 'include',
                body: data
            };
            if (contentType)
                request.headers = { 'Content-Type': contentType };
            let response;
            try {
                response = yield fetch(url, request);
            }
            catch (_a) {
                message = errorMessage;
                alertType = 'error';
            }
            if (!response.ok) {
                message = errorMessage;
                alertType = 'error';
            }
            Core.createHTMLAlert('alertMessageDiv', message, alertType, 3000, null);
            Core.hideLoader();
            return response;
        });
    }
    static get(url, data) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader;
            const parameters = new URLSearchParams(data);
            const response = yield fetch(`${url}?${parameters}`, { credentials: 'include' });
            Core.hideLoader();
            return response;
        });
    }
}
class SaveHandler {
    constructor(pageCode, planPK, pageLabel, fieldset, navigationCode) {
        this._core = new Core();
        this.GROUP = 4;
        this.TABLE = 2;
        this.SkipElements = [];
        this._planPK = planPK;
        this._pageCode = pageCode;
        this._pageLabel = pageLabel;
        this._fieldset = fieldset;
        this._navigationCode = navigationCode;
        this.constructEventListeners();
    }
    /****************************
     ****** Public Methods ******
     ****************************/
    createHash() {
        return this._core.createHash(this._fieldset.SaveableClasses);
    }
    save(referrer) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            if (this._core.Workflows.length > 0) {
                yield Workflow.SaveAllWorkflows(this._core.Workflows);
            }
            const refreshPage = this.setRefreshPage(referrer);
            if (referrer !== 'save' && this._core.checkSave(this) === false) {
                window.location.href = refreshPage;
                return false;
            }
            const planPropertiesToSave = this.getAllPlanPropertiesToSave();
            return this.sendXMLHttpRequest(JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: planPropertiesToSave
            }), `/${this._navigationCode}/save${this._navigationCode}page/`, referrer, refreshPage);
        });
    }
    saveExecutiveFieldsForPDEPlanWriters(propertyGroupCodes) {
        const planPropertiesToSave = [];
        for (const propertyGroupCode of propertyGroupCodes) {
            planPropertiesToSave.push(...this.getAllPlanPropertiesToSave(propertyGroupCode));
        }
        return this.sendXMLHttpRequest(JSON.stringify({
            PlanPK: this._planPK,
            PageCode: this._pageCode,
            PlanPropertyModels: planPropertiesToSave
        }), `/${this._navigationCode}/save${this._navigationCode}page/`, 'save', null);
    }
    sendXMLHttpRequest(data, url, referrer = 'save', refreshPage = null, noReload = false) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = () => {
            Core.hideLoader();
            if (xhr.status === 200) {
                if (noReload) {
                    return true;
                }
                if (referrer === 'save') {
                    this._core.pageReload(true, this._planPK);
                    return true;
                }
                if (refreshPage) {
                    window.location.href = refreshPage;
                    return true;
                }
            }
            Core.createHTMLAlert('alertMessageDiv', `Request failed.  Returned status of ${xhr.status}`, 'error', 3000, null);
            return false;
        };
        xhr.send(data);
        return false;
    }
    newsave(referrer) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(referrer === 'save' || this._core.checkSave(this))) {
                window.location.href = referrer;
                return false;
            }
            const refreshPage = this.setRefreshPage(referrer);
            const planPropertiesToSave = this.getAllPlanPropertiesToSave();
            if (!planPropertiesToSave.length) {
                if (referrer === 'save') {
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a value to save first', 'warning', 3000, null);
                    this._core.doValidation(this._fieldset.ValidationClasses);
                }
                else if (refreshPage) {
                    window.location.href = refreshPage;
                }
                return;
            }
            const response = yield HttpRequestHandler.post(`/${this._navigationCode}/save${this._navigationCode}page/`, 'Successfully saved the page.', 'Could not save the page.', JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: planPropertiesToSave
            }), 'application/json');
            const json = yield response.json();
            if (json.success) {
                if (referrer === 'save') {
                    console.log('a');
                    this._core.pageReload(true, this._planPK);
                }
                if (refreshPage) {
                    console.log('b');
                    window.location.href = refreshPage;
                }
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'Request failed.  Returned status of ' + response.status, 'error', 3000, null);
            }
        });
    }
    setRefreshPage(referrer) {
        let refreshPage = referrer;
        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url');
        }
        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        }
        return refreshPage;
    }
    getRowNumber(element) {
        let rowNumber;
        if (parseInt(element.dataset.propertygrouptypecode) === this.GROUP) {
            const container = element.closest('.property-group');
            rowNumber = parseInt(container.dataset.row);
        }
        if (parseInt(element.dataset.propertygrouptypecode) === this.TABLE) {
            const container = element.closest('.table-row');
            rowNumber = parseInt(container.dataset.row);
        }
        return rowNumber;
    }
    deletePlanProperty(planPropertyFK, successText, errorText) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = yield HttpRequestHandler.post(`/${this._navigationCode}/DeletePlanProperties`, successText, errorText, JSON.stringify([planPropertyFK]), 'application/json');
            return request.ok;
        });
    }
    savePlanProperty(planProperty, successText, errorText) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = yield HttpRequestHandler.post(`/${this._navigationCode}/save${this._navigationCode}page/`, successText, errorText, JSON.stringify({
                PlanPK: this._planPK,
                PageCode: this._pageCode,
                PlanPropertyModels: [planProperty]
            }), 'application/json');
            return request.ok;
        });
    }
    getAllPlanPropertiesToSave(propertyGroupCodeFK = null) {
        const allPlanPropertiesToSave = [];
        for (const field of this._fieldset.SaveableClasses) {
            const elements = propertyGroupCodeFK
                ? document.querySelectorAll(`.${field}[data-propertygroupcodepk='${propertyGroupCodeFK}']`)
                : document.querySelectorAll(`.${field}`);
            if (elements === null || elements === void 0 ? void 0 : elements.length) {
                const planPropertiesToSave = this.pushPlanPropertiesToSave(elements, field);
                allPlanPropertiesToSave.push(...planPropertiesToSave);
            }
        }
        return allPlanPropertiesToSave;
    }
    /*****************************
     ****** Private Methods ******
     *****************************/
    constructEventListeners() {
        const saveButton = document.getElementById(`${this._navigationCode}${this._pageLabel}SaveButton`);
        if (saveButton)
            saveButton.addEventListener('click', (e) => typeof this.customSave !== 'undefined' ? this.customSave('save') : this.save('save'));
        const continueButton = document.getElementById('continueButton');
        if (continueButton)
            continueButton.addEventListener('click', (e) => typeof this.customSave !== 'undefined' ? this.customSave('continue') : this.save('continue'));
        const backButton = document.getElementById('backButton');
        if (backButton)
            backButton.addEventListener('click', (e) => typeof this.customSave !== 'undefined' ? this.customSave('back') : this.save('back'));
        const navLinks = document.querySelectorAll('a.navLink');
        for (const link of navLinks)
            link.addEventListener('click', (e) => this.save(link.dataset.redirectUrl));
    }
    pushPlanPropertiesToSave(elements, field) {
        let planPropertiesToSave = [];
        for (const element of elements) {
            if (this.SkipElements.indexOf(element.dataset.propertypk) >= 0)
                continue;
            let textValue = null;
            let lookupCode = null;
            let forceSave = false;
            switch (field) {
                case this._fieldset.Calendar:
                case this._fieldset.Text:
                    textValue = this.getInputPlanProperty(element);
                    break;
                case this._fieldset.Checkbox:
                case this._fieldset.MultiSelectOption:
                    textValue = this.getCheckboxPlanProperty(element);
                    break;
                case this._fieldset.Radio:
                    lookupCode = this.getRadioButtonPlanProperty(element);
                    break;
                case this._fieldset.Select:
                    lookupCode = this.getSelectPlanProperty(element);
                    break;
                case this._fieldset.Textarea:
                    const result = this.getTextAreaPlanProperty(element);
                    textValue = result.Value;
                    forceSave = result.ForceSave;
                    break;
            }
            if (textValue != null || lookupCode || forceSave) {
                planPropertiesToSave.push({
                    PropertyFK: parseInt(element.dataset.propertypk),
                    PlanFK: this._planPK,
                    PlanPropertyPK: parseInt(element.dataset.planpropertypk),
                    LookupCodeFK: lookupCode,
                    TextValue: textValue,
                    RowNbr: this.getRowNumber(element),
                    IsDeletedInd: false,
                    ParentPlanPropertyPK: "parentplanpropertypk" in element.dataset && parseInt(element.dataset.parentplanpropertypk) > 0 ? parseInt(element.dataset.parentplanpropertypk) : null,
                    PlanPropertyRelationTypePK: "propertyrelationtype" in element.dataset && parseInt(element.dataset.propertyrelationtype) > 0 ? parseInt(element.dataset.propertyrelationtype) : null
                });
            }
        }
        return planPropertiesToSave;
    }
    getInputPlanProperty(element) {
        const inputElement = element;
        if (inputElement.value === '' && inputElement.dataset.planpropertypk === '0')
            return;
        if (inputElement.value === inputElement.defaultValue && !inputElement.classList.contains('prepopulated') && inputElement.dataset.planpropertypk !== '0')
            return;
        return inputElement.value;
    }
    getCheckboxPlanProperty(element) {
        const checkboxElement = element;
        if (!checkboxElement.checked && checkboxElement.dataset.planpropertypk === '0')
            return;
        if (checkboxElement.checked === checkboxElement.defaultChecked)
            return;
        return checkboxElement.checked ? 'on' : 'off';
    }
    getRadioButtonPlanProperty(element) {
        const radioButtonElement = element;
        /*
         * Disabling this check for now in the K12 plan, specifically
         * because validating file uploads could break if this check
         * is in place. May want to revisit the logic here later.
         */
        //if (radioButtonElement.checked === radioButtonElement.defaultChecked) return
        if (!radioButtonElement.checked)
            return;
        return parseInt(radioButtonElement.value);
    }
    getSelectPlanProperty(element) {
        const selectElement = element;
        if (selectElement.options[selectElement.selectedIndex].value === '0' && selectElement.dataset.planpropertypk === '0')
            return;
        if (selectElement.options[selectElement.selectedIndex].defaultSelected)
            return;
        return parseInt(selectElement.value);
    }
    getTextAreaPlanProperty(element) {
        const textAreaElement = element;
        if ((textAreaElement.value === '' && textAreaElement.dataset.planpropertypk === '0')
            || (textAreaElement.value === textAreaElement.defaultValue)) {
            return { Value: null, ForceSave: false };
        }
        return { Value: textAreaElement.value, ForceSave: !textAreaElement.value };
    }
}
class FileHandler {
    constructor(fileField, navigationCode, pageCode, planFK) {
        this._fileField = fileField;
        this._navigationCode = navigationCode;
        this._pageCode = pageCode;
        this._planFK = planFK;
        this.constructEventListeners();
    }
    constructEventListeners() {
        const deleteFileCancelButton = document.getElementById('deleteFileCancel');
        if (deleteFileCancelButton)
            deleteFileCancelButton.addEventListener('click', (e) => this.deleteFileCancel());
        const deleteFileConfirmButton = document.getElementById('deleteFileConfirm');
        if (deleteFileConfirmButton)
            deleteFileConfirmButton.addEventListener('click', (e) => this.deleteFile(deleteFileConfirmButton));
        this.constructFileUploadElements();
        this.constructFileDeleteElements();
    }
    constructFileUploadElements() {
        const fileUploadElements = document.querySelectorAll(`.${this._fileField}`);
        for (const element of fileUploadElements)
            element.addEventListener('change', (e) => this.uploadFile(e.srcElement));
    }
    constructFileDeleteElements() {
        const deleteFileButtons = document.getElementsByClassName('deleteFile');
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener('click', (e) => this.showDeleteFileConfirmation(deleteFileButton));
    }
    uploadFile(input) {
        return __awaiter(this, void 0, void 0, function* () {
            if (input.files.length > 0) {
                if (!input.hasAttribute("accept") || input.accept === "" || input.accept.includes(input.files[0].type)) {
                    const uploadFileForm = input.parentElement;
                    const dataset = uploadFileForm.dataset;
                    const formData = new FormData(uploadFileForm);
                    const planFK = document.querySelector('main').dataset.planfk;
                    formData.append('planFK', planFK);
                    const response = yield HttpRequestHandler.post(`/${this._navigationCode}/UploadFile/`, 'File successfully uploaded!', 'There was an error uploading your file, please try again.', formData);
                    const json = yield response.json();
                    if (json.success) {
                        input.value = '';
                        input.dataset.hasuploaded = 'true';
                        this.getFileUploadPartialView(json.payload, dataset.propertypk, dataset.row);
                        ValidationHandler.runServerSideValidation(this._navigationCode, this._planFK, this._pageCode);
                        if (typeof this.OnUpload === "function") {
                            this.OnUpload(input);
                        }
                    }
                }
                else {
                    input.value = '';
                    Core.createHTMLAlert("alertMessageDiv", "Please upload a file with the correct file type.", 'error', 3000, null);
                }
            }
        });
    }
    deleteFile(confirmButton) {
        return __awaiter(this, void 0, void 0, function* () {
            const planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
            const response = yield HttpRequestHandler.post(`/${this._navigationCode}/DeleteFiles`, 'File successfully deleted.', 'There was an error deleting this file, please try again', JSON.stringify([planPropertyFilePK]), 'application/json');
            const json = yield response.json();
            if (json.success) {
                this.deleteFileSuccess(planPropertyFilePK);
                ValidationHandler.runServerSideValidation(this._navigationCode, this._planFK, this._pageCode);
            }
        });
    }
    showDeleteFileConfirmation(deleteButton) {
        const planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            const modal = new Modal('deleteFileModal', null);
            const deleteConfirmButton = document.getElementById('deleteFileConfirm');
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileCancel() {
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    deleteFileSuccess(planPropertyFilePK) {
        const fileUploadElement = document.querySelector(`.upload-file-column[data-planpropertyfilepk='${planPropertyFilePK}']`);
        if (fileUploadElement)
            fileUploadElement.remove();
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    getFileUploadPartialView(planPropertyFile, propertyPK, row) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield HttpRequestHandler.get(`/${this._navigationCode}/GetFileUploadSharedPartialView`, {
                planPropertyFilePK: planPropertyFile.planPropertyFilePK,
                contentType: planPropertyFile.contentType,
                filename: planPropertyFile.filename
            });
            const text = yield response.text();
            if (text) {
                const fileUploadDiv = document.querySelector(`.file-upload-div-inner[data-propertypk='${propertyPK}'][data-row='${row}']`);
                fileUploadDiv.insertAdjacentHTML('beforeend', text);
                this.constructFileDeleteElements();
            }
        });
    }
}
class ValidationHandler {
    constructor(fieldset, customValidationFields = null) {
        this.Correction = 0;
        this._fieldset = fieldset;
        if (customValidationFields === null || customValidationFields === void 0 ? void 0 : customValidationFields.length) {
            this._fieldset.ValidationClasses.push(...customValidationFields);
        }
    }
    validate() {
        const errorCount = this.validateElements() - this.Correction;
        const showErrorMessage = !!errorCount;
        const singularOrPlural = {
            toBe: errorCount === 1 ? 'is' : 'are',
            plural: errorCount === 1 ? '' : 's',
            first: errorCount === 1 ? '' : 'first'
        };
        const message = document.getElementById('validationMessage');
        message.innerHTML = `<p class='validationErrorCountMessage'>There ${singularOrPlural.toBe} ${errorCount} issue${singularOrPlural.plural} to fix on this page.</p><a id='goToFirstError' href='javascript:void(0)'>Go to ${singularOrPlural.first} issue.</a>`;
        const goToError = document.getElementById('goToFirstError');
        if (goToError) {
            const firstMissingElement = document.querySelector('.missing-field');
            if (firstMissingElement) {
                goToError.addEventListener('click', (e) => {
                    const accordion = Core.findClosest(firstMissingElement, '.Accordion-panel');
                    if (accordion) {
                        const id = accordion.getAttribute("aria-labelledby");
                        const accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstMissingElement.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstMissingElement.id);
                    }
                    else if (firstMissingElement.classList.contains("multi-select-label")) {
                        const parent = Core.findClosest(firstMissingElement, '.multi-select');
                        if (parent != null) {
                            parent.focus();
                        }
                    }
                    else {
                        if (!firstMissingElement.hasAttribute("tabindex")) {
                            firstMissingElement.setAttribute("tabindex", "-1");
                        }
                        firstMissingElement.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        const messageContainerColumn = document.getElementById('validationColumn');
        const messageContainer = document.getElementById('validationMessageContainer');
        messageContainerColumn.classList.add('show');
        const validationIcon = document.getElementById('validationMessageIcon');
        setTimeout(() => messageContainer.focus(), 500);
        if (showErrorMessage) {
            const message = document.getElementById('validationMessage');
            messageContainer.classList.add('warning');
            message.classList.add('show');
            validationIcon.innerHTML = `<i class='fas fa-exclamation-triangle'></i>`;
        }
        else {
            messageContainer.classList.add('success');
            validationIcon.innerHTML = `<i class='fas fa-check-circle'></i>`;
            const message = document.getElementById('validationMessage');
            if (message !== null) {
                message.classList.remove("show");
            }
            const successMessage = document.getElementById('saveSuccess');
            if (successMessage)
                successMessage.innerHTML = 'The page has been successfully saved.';
        }
    }
    validateElements(skipElements = []) {
        let errorCount = 0;
        let radioButtonName = '';
        let classesToValidate = this._fieldset.ValidationClasses.map(vclass => "." + vclass).join(",");
        const allElements = document.querySelectorAll(`${classesToValidate}`);
        for (const validationClass of this._fieldset.ValidationClasses) {
            const elements = document.querySelectorAll(`.${validationClass}`);
            if (validationClass == this._fieldset.Checkbox) {
                errorCount += this.validateCheckboxGroups(elements, skipElements);
            }
            for (const element of elements) {
                if (skipElements.indexOf(element.dataset.propertypk) >= 0)
                    continue;
                const isElementRequired = element.dataset.percent && element.dataset.percent === '1.00';
                if (isElementRequired) {
                    let isElementInTable = element.dataset.propertygrouptypecode == '2'
                        && !element.classList.contains('first-table-row');
                    let isElementInMinimumRequiredRow = false;
                    if (this.MinRequiredGroupRows != null && parseInt(element.dataset.propertygroupcodepk) in this.MinRequiredGroupRows) {
                        //Get distinct row numbers
                        let rowNumbersToValidate = [...allElements].filter(el => el.dataset.propertygroupcodepk == element.dataset.propertygroupcodepk).map(el => parseInt(el.dataset.row)).sort().slice(0, this.MinRequiredGroupRows[parseInt(element.dataset.propertygroupcodepk)]);
                        if (rowNumbersToValidate != null && parseInt(element.dataset.row) in rowNumbersToValidate) {
                            isElementInMinimumRequiredRow = true;
                        }
                    }
                    //If there is an element in the row with a planproperty (i.e. it is filled out), then the element is required
                    let otherElementInRowIsFilledOut = [...allElements].some((el) => "planpropertypk" in el.dataset
                        && parseInt(el.dataset.planpropertypk) > 0
                        && el.dataset.propertygroupcodepk == element.dataset.propertygroupcodepk
                        && el.dataset.row == element.dataset.row);
                    if (isElementInTable && !isElementInMinimumRequiredRow && !otherElementInRowIsFilledOut)
                        continue;
                    let isValid = true;
                    switch (validationClass) {
                        case this._fieldset.Text:
                        case this._fieldset.Calendar:
                            isValid = this.validateInputElement(element);
                            break;
                        case this._fieldset.Radio:
                            const radioButton = element;
                            isValid = this.validateRadioButtonElement(radioButton, radioButtonName);
                            radioButtonName = radioButton.name;
                            break;
                        case this._fieldset.File:
                            isValid = this.validateFileElement(element);
                            break;
                        case this._fieldset.MultiSelect:
                            isValid = this.validateMultiSelectElement(element);
                            break;
                        case this._fieldset.Select:
                            isValid = this.validateSelectElement(element);
                            break;
                        case this._fieldset.Textarea:
                            isValid = this.validateTextareaElement(element);
                            break;
                        default:
                            break;
                    }
                    if (!isValid) {
                        if (element.classList.contains(this._fieldset.MultiSelect)) {
                            const span = element.parentElement.querySelector('span.multi-select-label');
                            span.classList.add('missing-field');
                        }
                        element.classList.add('missing-field');
                        element.setAttribute('aria-invalid', 'true');
                        Core.createErrorLabelForInput(element);
                        errorCount++;
                    }
                }
            }
        }
        return errorCount;
    }
    validateCheckboxGroups(checkboxes, skipElements = []) {
        let errorCount = 0;
        let currentPropertyGroupCode = '';
        for (const checkbox of checkboxes) {
            if (skipElements.indexOf(checkbox.dataset.propertypk) >= 0)
                continue;
            if ((checkbox.dataset.propertygrouptypecode === '11') && currentPropertyGroupCode !== checkbox.dataset.propertygroupcodepk) {
                currentPropertyGroupCode = checkbox.dataset.propertygroupcodepk;
                const checkboxesInGroup = document.querySelectorAll(`input[data-propertygroupcodepk='${currentPropertyGroupCode}']`);
                let areAnyCheckboxesChecked = false;
                for (const checkboxInGroup of checkboxesInGroup) {
                    if (checkboxInGroup.checked) {
                        areAnyCheckboxesChecked = true;
                        break;
                    }
                }
                if (!areAnyCheckboxesChecked) {
                    errorCount++;
                    const label = document.querySelector(`label[data-propertygroupcodepk='${currentPropertyGroupCode}']`);
                    if (label && !label.classList.contains('hasBeenValidated')) {
                        label.classList.add('hasBeenValidated');
                        label.classList.add('missing-field');
                        label.innerHTML = `<span class='missing-field-label'><i class='fas fa-exclamation-triangle' aria-hidden='true'></i></span> ${label.innerHTML}`;
                    }
                }
            }
        }
        return errorCount;
    }
    validateSelectElement(select) {
        return select.selectedIndex > 0;
    }
    validateTextareaElement(textarea) {
        return !!textarea.value;
    }
    validateInputElement(input) {
        return !!input.value;
    }
    validateFileElement(file) {
        return file.dataset.hasplanpropertyfiles === 'true';
    }
    validateMultiSelectElement(list) {
        const checkedCheckboxes = list.querySelectorAll('input:checked');
        return !!checkedCheckboxes.length;
    }
    validateRadioButtonElement(radio, name) {
        // Added this check so that an unchecked yes/no radio button doesn't count as 2 errors, only 1
        if (radio.name === name)
            return true;
        const allRadioButtons = document.getElementsByName(radio.name);
        for (const radioButton of allRadioButtons)
            if (radioButton.checked)
                return true;
        return false;
    }
    forceElementRequired(element) {
        element.dataset.percent = '1.00';
        element.setAttribute('aria-required', 'true');
        element.dataset.forcerequired = 'true';
        const label = Core.findLabelForInput(element);
        if (label && !label.classList.contains('isRequired')) {
            let labelPlaceholder = label.querySelector(".requiredLabelPlaceholder");
            if (labelPlaceholder) {
                labelPlaceholder.innerHTML = "<span class='required-label'>*</span>";
            }
            else {
                label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`;
            }
            label.classList.add('isRequired');
        }
    }
    forceElementOptional(element) {
        element.dataset.percent = '0.00';
        element.setAttribute('aria-required', 'false');
        element.dataset.forcerequired = 'false';
        const label = Core.findLabelForInput(element);
        if (label) {
            label.classList.remove('isRequired');
            const requiredLabel = label.querySelector('.required-label');
            if (requiredLabel)
                requiredLabel.parentNode.removeChild(requiredLabel);
        }
    }
    initializeRequiredFields() {
        //let formattedAllClasses = [];
        //allClasses.forEach(function (part, index) {
        //    formattedAllClasses[index] = "." + allClasses[index];
        //});
        //let classesToValidate = formattedAllClasses.join(",");
        //if (refresh) {
        //    let allElements = document.querySelectorAll(classesToValidate);
        //    for (let element of allElements) {
        //        let htmlElement = <HTMLElement>element;
        //        htmlElement.removeAttribute("aria-required");
        //        let label = Core.findLabelForInput(htmlElement);
        //        if (label !== null) {
        //            label.classList.remove("isRequired");
        //            let asterisk = label.querySelector(".required-label") as HTMLElement;
        //            if (asterisk != null) {
        //                asterisk.parentNode.removeChild(asterisk);
        //            }
        //        }
        //    }
        //}
        const allElements = [];
        for (const validationClass of this._fieldset.ValidationClasses) {
            const elementsWithClass = document.querySelectorAll(`.${validationClass}`);
            allElements.push(...elementsWithClass);
        }
        for (const element of allElements) {
            const isRequired = (element.dataset.percent && element.dataset.percent === '1.00')
                || (element.dataset.forcerequired && element.dataset.forcerequired === 'true');
            const hasAriaRequiredAttribute = element.hasAttribute('aria-required') && element.getAttribute('aria-required') === 'true';
            if (isRequired && !hasAriaRequiredAttribute) {
                element.setAttribute('aria-required', 'true');
                const label = Core.findLabelForInput(element);
                if (label && !label.classList.contains('isRequired')) {
                    let labelPlaceholder = label.querySelector(".requiredLabelPlaceholder");
                    if (labelPlaceholder) {
                        labelPlaceholder.innerHTML = "<span class='required-label'>*</span>";
                    }
                    else {
                        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`;
                    }
                    label.classList.add('isRequired');
                }
            }
        }
    }
    static runServerSideValidation(navigationCode, planFK, pageCode) {
        return __awaiter(this, void 0, void 0, function* () {
            const request = yield fetch(`/${navigationCode}/RunValidation/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    PlanFK: planFK,
                    PageCode: pageCode
                })
            });
            return (request === null || request === void 0 ? void 0 : request.ok) && (yield request.json());
        });
    }
}
class RadioButtonControlHandler {
    constructor(core, validationHandler) {
        this.RADIOBUTTON_YES = 100;
        this.RADIOBUTTON_NO = 101;
        this.RADIOBUTTONCONTROL_PROPERTYGROUPTYPECODE = 5;
        this._core = core;
        this._validationHandler = validationHandler;
        this.constructRadioButtonControl();
    }
    constructRadioButtonControl() {
        const radioButtons = document.querySelectorAll(`input[type='radio'][data-propertygrouptypecode='${this.RADIOBUTTONCONTROL_PROPERTYGROUPTYPECODE}']`);
        for (const radioButton of radioButtons) {
            if (document.querySelector(`[data-property-relation-parent-property-fk='${radioButton.dataset.propertypk}']`)) {
                radioButton.addEventListener('click', (e) => this.toggleRadioButtonControl(radioButton));
            }
        }
        this.toggleRadioButtonControlOnload();
    }
    toggleRadioButtonControlOnload() {
        const yesRadioButtons = document.querySelectorAll(`input[type='radio'][data-propertygrouptypecode='${this.RADIOBUTTONCONTROL_PROPERTYGROUPTYPECODE}'][data-lookuplabel='Yes']`);
        for (const yesRadioButton of yesRadioButtons) {
            if (document.querySelector(`[data-property-relation-parent-property-fk='${yesRadioButton.dataset.propertypk}']`)) {
                const radioButtons = document.querySelectorAll(`input[type='radio'][name='${yesRadioButton.name}']`);
                let isChecked = false;
                radioButtons.forEach(button => {
                    if (button.checked) {
                        this.toggleRadioButtonControl(button);
                        isChecked = true;
                    }
                });
                if (!isChecked)
                    this.toggleRadioButtonControl(yesRadioButton, true);
            }
        }
    }
    toggleRadioButtonControl(radioButton, hideAllElements = false) {
        const propertyGroupCode = radioButton.dataset.propertygroupcodepk;
        const yesElements = document.querySelectorAll(`[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.RADIOBUTTON_YES}'][data-property-relation-parent-property-fk='${radioButton.dataset.propertypk}']`);
        const noElements = document.querySelectorAll(`[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.RADIOBUTTON_NO}'][data-property-relation-parent-property-fk='${radioButton.dataset.propertypk}']`);
        if (hideAllElements) {
            // Hide all elements regardless of radiobutton selection. Used for when nothing is selected (i.e. an unsaved page)
            (new Set([
                ...yesElements,
                ...noElements
            ])).forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none');
                this._validationHandler.forceElementOptional(element);
            });
        }
        else if (radioButton.dataset.lookuplabel === 'Yes') {
            // Radio Button selected yes, show the 'yes' elements and hide the 'no' elements
            yesElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none');
                this._validationHandler.forceElementRequired(element);
            });
            noElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none');
                this._validationHandler.forceElementOptional(element);
            });
        }
        else {
            // Radio Button selected no, vice versa
            noElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none');
                this._validationHandler.forceElementRequired(element);
            });
            yesElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none');
                this._validationHandler.forceElementOptional(element);
            });
        }
        if (this.Callback)
            this.Callback();
    }
}
class CheckboxControlHandler {
    constructor(core, validationHandler) {
        this.CHECKBOXCONTROL_CHECKED = 102;
        this.CHECKBOXCONTROL_UNCHECKED = 103;
        this.CHECKBOXCONTROL_PROPERTYGROUPTYPECODE = 6;
        this._core = core;
        this._validationHandler = validationHandler;
        this.constructCheckboxControl();
    }
    constructCheckboxControl() {
        const checkboxes = document.querySelectorAll(`input[type='checkbox'][data-propertygrouptypecode='${this.CHECKBOXCONTROL_PROPERTYGROUPTYPECODE}']`);
        for (const checkbox of checkboxes) {
            checkbox.addEventListener('click', (e) => this.toggleCheckboxControl(checkbox));
            this.toggleCheckboxControl(checkbox);
        }
    }
    toggleCheckboxControl(checkbox) {
        const propertyGroupCode = checkbox.dataset.propertygroupcodepk;
        const checkedElements = document.querySelectorAll(`[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.CHECKBOXCONTROL_CHECKED}'][data-property-relation-parent-property-fk='${checkbox.dataset.propertypk}']`);
        const uncheckedElements = document.querySelectorAll(`[data-propertygroupcodepk='${propertyGroupCode}'][data-propertyrelationtype='${this.CHECKBOXCONTROL_UNCHECKED}'][data-property-relation-parent-property-fk='${checkbox.dataset.propertypk}']`);
        if (checkbox.checked) {
            checkedElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none');
                if (element.classList.contains('force-optional'))
                    this._validationHandler.forceElementRequired(element);
            });
            uncheckedElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none');
                if (element.dataset.percent !== '0.00') {
                    this._validationHandler.forceElementOptional(element);
                    element.classList.add('force-optional');
                }
            });
        }
        else {
            uncheckedElements.forEach(element => {
                element.closest('.shared-page-row').classList.remove('display-none');
                if (element.classList.contains('force-optional'))
                    this._validationHandler.forceElementRequired(element);
            });
            checkedElements.forEach(element => {
                element.closest('.shared-page-row').classList.add('display-none');
                if (element.dataset.percent !== '0.00') {
                    this._validationHandler.forceElementOptional(element);
                    element.classList.add('force-optional');
                }
            });
        }
    }
}
class GroupHandler {
    constructor(core, _navigationCode, pageCode, planFK, templateFK, validationHandler) {
        this._core = core;
        this._navigationCode = _navigationCode;
        this._pageCode = pageCode;
        this._planFK = planFK;
        this._templateFK = templateFK;
        this._validationHandler = validationHandler;
        this.constructEventListeners();
    }
    constructEventListeners() {
        const addGroupButtons = document.querySelectorAll('.add-group-button');
        for (const button of addGroupButtons) {
            button.addEventListener('click', (e) => this.addGroup(button));
        }
        this.constructDeleteGroupButtons();
        this.constructDeleteTableRowsButtons();
    }
    constructDeleteGroupButtons(propertyGroupCodePK = null, row = null) {
        const deleteGroupButtons = propertyGroupCodePK && row
            ? document.querySelectorAll(`button.delete-group-button[data-propertygroupcodepk='${propertyGroupCodePK}'][data-row='${row}']`)
            : document.querySelectorAll('.delete-group-button');
        for (const button of deleteGroupButtons)
            button.addEventListener('click', (e) => this.deleteGroup(button));
    }
    constructDeleteTableRowsButtons() {
        const deleteRowButtons = document.querySelectorAll('.delete-row-button');
        for (const button of deleteRowButtons)
            button.addEventListener('click', (e) => this.deleteTableRow(button));
    }
    addGroup(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const propertyGroupCodePK = button.dataset.propertygroupcodepk;
            const propertyGroupDescription = button.dataset.propertygroupdescription;
            const row = button.dataset.row;
            const selectElement = document.querySelector(`select[data-propertygroupcodepk='${propertyGroupCodePK}']`);
            const lookupGroupCode = selectElement ? selectElement.dataset.lookupgroupcode : null;
            const addRowsInput = document.querySelector(`input.add-button-input[data-propertygroupcodepk='${propertyGroupCodePK}']`);
            const numberOfRowsToAdd = addRowsInput ? parseInt(addRowsInput.value) : 1;
            const request = yield HttpRequestHandler.post(`/${this._navigationCode}/AddPropertyGroup`, `Successfully added ${propertyGroupDescription}`, `There was an error adding a ${propertyGroupDescription}, please try again`, JSON.stringify({
                LookupGroupCode: lookupGroupCode,
                NavigationCode: this._navigationCode,
                NextRow: parseInt(row),
                NumberOfPropertyGroupsToAdd: numberOfRowsToAdd,
                PageCode: this._pageCode,
                PropertyGroupCodePK: propertyGroupCodePK,
                TemplateFK: this._templateFK
            }), 'application/json');
            if (request.ok) {
                const text = yield request.text();
                if (text) {
                    const propertyGroupContainer = document.querySelector(`.property-group-section[data-propertygroupcodepk='${propertyGroupCodePK}']`);
                    propertyGroupContainer.insertAdjacentHTML('beforeend', text);
                    const elementsInGroup = this.getAllElementsInPropertyGroup(propertyGroupCodePK, row);
                    elementsInGroup.forEach(element => this._validationHandler.forceElementRequired(element));
                    this.constructDeleteGroupButtons(propertyGroupCodePK, row);
                    this.constructDeleteTableRowsButtons();
                    button.dataset.row = (numberOfRowsToAdd + parseInt(row)).toString();
                    if (this.Callback)
                        this.Callback();
                }
            }
        });
    }
    deletePlanProperties(propertyGroupCodePK, propertyGroupDescription, row) {
        return __awaiter(this, void 0, void 0, function* () {
            let successfullyDeletedPlanProperties = true;
            const elementsInGroup = this.getAllElementsInPropertyGroup(propertyGroupCodePK, row);
            const planPropertyPKs = [];
            elementsInGroup.forEach(element => {
                if (parseInt(element.dataset.planpropertypk))
                    planPropertyPKs.push(element.dataset.planpropertypk);
            });
            if (planPropertyPKs.length) {
                const request = yield HttpRequestHandler.post(`/${this._navigationCode}/DeletePlanProperties`, `Successfully deleted ${propertyGroupDescription}`, `There was an error deleting the ${propertyGroupDescription}, please try again`, JSON.stringify(planPropertyPKs), 'application/json');
                if (request.ok) {
                    const json = yield request.json();
                    if (json.Success === 'false') {
                        successfullyDeletedPlanProperties = false;
                    }
                    else {
                        ValidationHandler.runServerSideValidation(this._navigationCode, this._planFK, this._pageCode);
                    }
                }
                else {
                    successfullyDeletedPlanProperties = false;
                }
            }
            return successfullyDeletedPlanProperties;
        });
    }
    deleteGroup(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const propertyGroupCodePK = button.dataset.propertygroupcodepk;
            const propertyGroupDescription = button.dataset.propertygroupdescription;
            const row = button.dataset.row;
            if (this.deletePlanProperties(propertyGroupCodePK, propertyGroupDescription, row)) {
                const propertyGroupContainer = document.querySelector(`div.property-group[data-propertygroupcodepk='${propertyGroupCodePK}'][data-row='${row}']`);
                propertyGroupContainer.remove();
            }
        });
    }
    deleteTableRow(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const propertyGroupCodePK = button.dataset.propertygroupcodepk;
            const propertyGroupDescription = button.dataset.propertygroupdescription;
            const row = button.dataset.row;
            if (this.deletePlanProperties(propertyGroupCodePK, propertyGroupDescription, row)) {
                button.closest('tr').remove();
            }
        });
    }
    getAllElementsInPropertyGroup(propertyGroupCodePK, row) {
        return document.querySelectorAll(`[data-propertygroupcodepk='${propertyGroupCodePK}'][data-row='${row}'][data-propertypk]`);
    }
}
class ExportHandler {
    constructor(core, planPK, templateFK) {
        this._core = core;
        this._planPK = planPK;
        this._templateFK = templateFK;
        this.constructEventListeners();
    }
    constructEventListeners() {
        const exportToPDFButton = document.getElementById("pdfExportButton");
        if (exportToPDFButton)
            exportToPDFButton.addEventListener('click', (e) => this.exportToPDF());
    }
    exportToPDF() {
        Core.showLoader();
        //Get all components for given template
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/OutcomeBasedReports/FullReportDataExportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "PDF Export";
                filename = filename + ".pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + this._planPK + "&templateFK=" + this._templateFK);
    }
}
class SummaryChecklistAndSubmissionHandler {
    constructor(navigationCode) {
        this._core = new Core();
        this._navigationCode = navigationCode;
        this.constructEventListeners();
        this._lateEmailModal = new Modal('sendLateEmailModal', 'sendLateEmail');
        this._core.leftnav(this);
    }
    constructEventListeners() {
        // Back and Submit buttons
        const backButton = document.getElementById('backButton');
        backButton === null || backButton === void 0 ? void 0 : backButton.addEventListener('click', (e) => this.back());
        const submitButton = document.getElementById('submitPlanButton');
        submitButton === null || submitButton === void 0 ? void 0 : submitButton.addEventListener('click', (e) => this.submit(submitButton));
        // Late Email Buttons
        const sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]');
        sendLateEmailButton === null || sendLateEmailButton === void 0 ? void 0 : sendLateEmailButton.addEventListener('click', () => {
            this._lateEmailPlanFK = parseInt(sendLateEmailButton.dataset.planFk);
            this._lateEmailModal.show();
        });
        const sendLateEmailConfirmButton = document.querySelector('#sendLateEmailConfirm');
        if (sendLateEmailConfirmButton != null)
            sendLateEmailConfirmButton.addEventListener('click', () => this.sendLateEmail());
        const sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
        if (sendLateEmailDueDate) {
            sendLateEmailDueDate.addEventListener('change', () => {
                if (sendLateEmailConfirmButton)
                    sendLateEmailConfirmButton.disabled = false;
            });
        }
        // Withdraw plan buttons
        const withdrawPlanButton = document.getElementById('withdrawPlanButton');
        withdrawPlanButton === null || withdrawPlanButton === void 0 ? void 0 : withdrawPlanButton.addEventListener('click', (e) => this.withdrawPlan(withdrawPlanButton));
        const withdrawPlanConfirmButton = document.getElementById('withdrawPlanConfirm');
        withdrawPlanConfirmButton === null || withdrawPlanConfirmButton === void 0 ? void 0 : withdrawPlanConfirmButton.addEventListener('click', (e) => this.withdrawPlanConfirm(withdrawPlanConfirmButton));
        const withdrawPlanCancelButton = document.getElementById('withdrawPlanCancel');
        withdrawPlanCancelButton === null || withdrawPlanCancelButton === void 0 ? void 0 : withdrawPlanCancelButton.addEventListener('click', (e) => this.withdrawPlanCancel());
    }
    back() {
        let newPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(submitButton) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!submitButton.classList.contains('disabled')) {
                Core.showLoader();
                const planFK = parseInt(submitButton.dataset.planfk);
                const response = yield fetch(`/${this._navigationCode}/SubmitPlan/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(planFK)
                });
                Core.hideLoader();
                if ((response === null || response === void 0 ? void 0 : response.ok) && (yield response.json()).success) {
                    Core.createHTMLAlert('alertMessageDiv', 'This report has been submitted for review!', 'success', 3000, window.location.reload());
                    setTimeout(() => { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'Request failed. Please try again', 'error', 3000, null);
                }
            }
        });
    }
    sendLateEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            let that = this;
            Core.showLoader();
            let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
            const response = yield fetch(`/${this._navigationCode}/SendLateEmail/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: `planFK=${that._lateEmailPlanFK}&newDueDateStr=${sendLateEmailDueDate.value}`
            });
            Core.hideLoader();
            this._lateEmailModal.hide();
            if ((response === null || response === void 0 ? void 0 : response.ok) && (yield response.json()).success) {
                Core.createHTMLAlert('alertMessageDiv', 'Successfully sent late email', 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error sending late email', 'error', 3000, null);
            }
            const sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]');
            if (sendLateEmailButton)
                sendLateEmailButton.disabled = true;
        });
    }
    withdrawPlan(button) {
        const planFK = button.dataset.planfk;
        const modal = new Modal('withdrawPlanModal', null);
        modal.addAttributeToElement('withdrawPlanModal', '#withdrawPlanConfirm', 'planfk', planFK);
        modal.show();
    }
    withdrawPlanConfirm(confirmButton) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let planFK = confirmButton.dataset.planfk;
            const response = yield fetch(`/${this._navigationCode}/WithdrawPlan/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(planFK)
            });
            Core.hideLoader();
            if ((response === null || response === void 0 ? void 0 : response.ok) && (yield response.json()).success) {
                Core.createHTMLAlert('alertMessageDiv', 'The plan has been withdrawn.', 'success', 3000, null);
                //back to report page
                setTimeout(() => { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected withdrawing this plan.', 'error', 3000, null);
            }
            let modal = new Modal('withdrawPlanModal', null);
            modal.hide();
        });
    }
    withdrawPlanCancel() {
        let modal = new Modal('withdrawPlanModal', null);
        modal.hide();
    }
}
class ProfileHandler {
    constructor(navigationCode, planPK, propertyCodePrefix) {
        this._core = new Core();
        this._navigationCode = navigationCode;
        this._propertyCodePrefix = propertyCodePrefix;
        this._planPK = planPK;
        const refreshProfileButton = document.getElementById(`${this._propertyCodePrefix}ProfileRefreshProfile`);
        refreshProfileButton.addEventListener('click', (e) => this.refreshProfile());
    }
    refreshProfile() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`/${this._navigationCode}/GetICDataForProfile/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `planFK=${this._planPK}`
            });
            if (response.ok) {
                let wasUpdated = false;
                const json = yield response.json();
                const leaName = document.getElementById(`${this._propertyCodePrefix}ProfileLEAName`);
                const aun = document.getElementById(`${this._propertyCodePrefix}ProfileAUN`);
                const address1 = document.getElementById(`${this._propertyCodePrefix}ProfileAddress1`);
                const address2 = document.getElementById(`${this._propertyCodePrefix}ProfileAddress2`);
                const city = document.getElementById(`${this._propertyCodePrefix}ProfileCity`);
                const state = document.getElementById(`${this._propertyCodePrefix}ProfileState`);
                const zipCode = document.getElementById(`${this._propertyCodePrefix}ProfileZipCode`);
                const county = document.getElementById(`${this._propertyCodePrefix}ProfileCounty`);
                const directorName = document.getElementById(`${this._propertyCodePrefix}ProfileDirectorName`);
                const directorEmail = document.getElementById(`${this._propertyCodePrefix}ProfileDirectorEmail`);
                const directorPhone = document.getElementById(`${this._propertyCodePrefix}ProfileDirectorPhone`);
                const institutionName = document.getElementById(`${this._propertyCodePrefix}ProfileInstitutionName`);
                const federalEIN = document.getElementById(`${this._propertyCodePrefix}ProfileFederalEIN`);
                const vendorIN = document.getElementById(`${this._propertyCodePrefix}ProfileVendorIN`);
                const iu = document.getElementById(`${this._propertyCodePrefix}ProfileIntermediateUnit`);
                if (leaName && leaName.value !== json.instDetails.instName) {
                    leaName.value = json.instDetails.instName;
                    wasUpdated = true;
                }
                if (aun && parseInt(aun.value) !== json.instDetails.auNumber) {
                    aun.value = json.instDetails.auNumber;
                    wasUpdated = true;
                }
                if (address1 && address1.value !== json.instDetails.addressLine1) {
                    address1.value = json.instDetails.addressLine1;
                    wasUpdated = true;
                }
                if (address2 && address2.value !== json.instDetails.addressLine2) {
                    address2.value = json.instDetails.addressLine2;
                    wasUpdated = true;
                }
                if (city && city.value !== json.instDetails.city) {
                    city.value = json.instDetails.city;
                    wasUpdated = true;
                }
                if (state && state.value !== json.instDetails.state) {
                    state.value = json.instDetails.state;
                    wasUpdated = true;
                }
                if (zipCode && zipCode.value !== json.instDetails.zipCode) {
                    zipCode.value = json.instDetails.zipCode;
                    wasUpdated = true;
                }
                if (county && county.value !== json.instDetails.countyName) {
                    county.value = json.instDetails.countyName;
                    wasUpdated = true;
                }
                if (institutionName && institutionName.value !== json.instDetails.instName) {
                    institutionName.value = json.instDetails.instName;
                    wasUpdated = true;
                }
                if (vendorIN && vendorIN.value !== json.instDetails.vendorId) {
                    vendorIN.value = json.instDetails.vendorID;
                    wasUpdated = true;
                }
                if (federalEIN && federalEIN.value !== json.instDetails.federalEmployerIdNumber) {
                    federalEIN.value = json.instDetails.federalEmployerIdNumber;
                    wasUpdated = true;
                }
                if (directorName && directorName.value !== json.superintendentInfo.adminName) {
                    directorName.value = json.superintendentInfo.adminName;
                    wasUpdated = true;
                }
                if (directorEmail && directorEmail.value !== json.superintendentInfo.email) {
                    directorEmail.value = json.superintendentInfo.email;
                    wasUpdated = true;
                }
                if (directorPhone && directorPhone.value !== json.superintendentInfo.phoneNumber) {
                    directorPhone.value = json.superintendentInfo.phoneNumber;
                    wasUpdated = true;
                }
                if (iu && iu.value !== json.instDetails.iuNumber) {
                    iu.value = json.instDetails.iuNumber;
                    wasUpdated = true;
                }
                if (wasUpdated) {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully updated the profile with the most recent EdNA data, please save your changes.', 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'Profile is up to date with the most recent EdNA data.', 'success', 3000, null);
                }
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null);
            }
        });
    }
}
class PageSectionInfoHandler {
    constructor() {
        this._moreInfoModal = new Modal("pageSectionMoreInfoModal", null);
        this.constructEventListeners();
    }
    constructEventListeners() {
        let that = this;
        const moreInfoButtons = document.querySelectorAll("button.pageSectionMoreInfo[data-instructions][data-title]");
        for (let but of moreInfoButtons) {
            but.addEventListener("click", () => {
                that._moreInfoModal.show();
                that._moreInfoModal.callingId = but.id;
                let infoContainer = document.getElementById("pageSectionMoreInfoInstructions");
                if (infoContainer != null) {
                    infoContainer.innerHTML = but.dataset.instructions;
                }
                let title = document.getElementById("h1ModalpageSectionMoreInfoModal");
                if (title != null) {
                    title.innerHTML = but.dataset.title;
                }
            });
        }
        let closeModalButton = document.getElementById("closeMoreInfo");
        if (closeModalButton != null) {
            closeModalButton.addEventListener("click", () => {
                that._moreInfoModal.hide();
            });
        }
    }
}
/// <reference path="page.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
class CharterSchoolPage extends Page {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, runValidation = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        if (runValidation && this._fromSaveElement && this._fromSaveElement.value === 'true')
            this._validationHandler.validate();
    }
}
class CharterSchoolAnnualReportSchoolProfile extends CharterSchoolPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        this.constructAUNSearchInputs = () => {
            const aunSearchInputs = document.querySelectorAll(`.${this._fieldset.Text}[data-propertypk='2101']`);
            for (const input of aunSearchInputs)
                this.constructAUNSearch(input);
            this.formatElements();
        };
        this.constructEventListeners();
        this.formatElements();
        const currentCharterUpload = document.querySelector("input[type='file'][data-propertycode='charterschoolprofilecurrentcharterupload']");
        if (currentCharterUpload) {
            currentCharterUpload.accept = "application/pdf";
        }
    }
    formatElements() {
        document.querySelectorAll('select').forEach(select => select.closest('.shared-page-row').classList.add('d-flex'));
    }
    constructEventListeners() {
        this.constructCyberCharterCheckbox();
        const refreshProfileButton = document.getElementById('charterschoolprofilerefreshprofile');
        refreshProfileButton.addEventListener('click', (e) => this.refreshProfile());
        this.constructAUNSearchInputs();
        this._groupHandler.Callback = this.constructAUNSearchInputs;
    }
    constructCyberCharterCheckbox() {
        const modal = new Modal('cyberCharterModal', null);
        const cyberCharterCheckbox = document.getElementById('charterschoolprofilecybercharter');
        if (cyberCharterCheckbox.checked) {
            const addAnAuthorizorButton = document.getElementById('charterschooladdAuthorizor');
            addAnAuthorizorButton.classList.add('display-none');
            if (this._fromSaveElement && this._fromSaveElement.value === 'true') {
                this._validationHandler.Correction = 7;
                this._validationHandler.validate();
            }
        }
        if (cyberCharterCheckbox)
            cyberCharterCheckbox.addEventListener('click', (e) => this.cyberCharterSelect(cyberCharterCheckbox, modal));
        const cyberCharterConfirm = document.getElementById('cyberCharterConfirm');
        if (cyberCharterConfirm)
            cyberCharterConfirm.addEventListener('click', (e) => this.cyberCharterConfirm(modal));
        const cyberCharterCancel = document.getElementById('cyberCharterCancel');
        if (cyberCharterCancel)
            cyberCharterCancel.addEventListener('click', (e) => this.cyberCharterCancel(cyberCharterCheckbox, modal));
        if (cyberCharterCheckbox.checked)
            this.cyberCharterConfirm(modal);
    }
    constructAUNSearch(aunInput) {
        aunInput.addEventListener('blur', (e) => this.findSchoolInfoFromAunBlur(aunInput));
        aunInput.addEventListener('input', (e) => this.findSchoolInfoFromAunInput(aunInput));
    }
    refreshProfile() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`/${this._navigationCode}/GetICDataForProfile/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `planFK=${this._planPK}`
            });
            if (response.ok) {
                let wasUpdated = false;
                const json = yield response.json();
                if (!json.instDetails)
                    Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null);
                const leaName = document.getElementById('charterschoolprofileleaname');
                const aun = document.getElementById('charterschoolprofileaun');
                const address1 = document.getElementById('charterschoolprofileaddress1');
                const address2 = document.getElementById('charterschoolprofileaddress2');
                const city = document.getElementById('charterschoolprofilecity');
                const state = document.getElementById('charterschoolprofilestate');
                const zipCode = document.getElementById('charterschoolprofilezipcode');
                const ceoName = document.getElementById('charterschoolprofileceoname');
                const ceoEmail = document.getElementById('charterschoolprofileceoemail');
                const ceoPhone = document.getElementById('charterschoolprofileceophone');
                if (leaName.value !== json.instDetails.instName) {
                    leaName.value = json.instDetails.instName;
                    wasUpdated = true;
                }
                if (parseInt(aun.value) !== json.instDetails.auNumber) {
                    aun.value = json.instDetails.auNumber;
                    wasUpdated = true;
                }
                if (address1.value !== json.instDetails.addressLine1) {
                    address1.value = json.instDetails.addressLine1;
                    wasUpdated = true;
                }
                if (address2.value !== json.instDetails.addressLine2) {
                    address2.value = json.instDetails.addressLine2;
                    wasUpdated = true;
                }
                if (city.value !== json.instDetails.city) {
                    city.value = json.instDetails.city;
                    wasUpdated = true;
                }
                if (state.value !== json.instDetails.state) {
                    state.value = json.instDetails.state;
                    wasUpdated = true;
                }
                if (zipCode.value !== json.instDetails.zipCode) {
                    zipCode.value = json.instDetails.zipCode;
                    wasUpdated = true;
                }
                if (zipCode.value !== json.instDetails.zipCode) {
                    zipCode.value = json.instDetails.zipCode;
                    wasUpdated = true;
                }
                if (ceoName.value !== json.superintendentInfo.adminName) {
                    ceoName.value = json.superintendentInfo.adminName;
                    wasUpdated = true;
                }
                if (ceoEmail.value !== json.superintendentInfo.email) {
                    ceoEmail.value = json.superintendentInfo.email;
                    wasUpdated = true;
                }
                if (ceoPhone.value !== json.superintendentInfo.phoneNumber) {
                    ceoPhone.value = json.superintendentInfo.phoneNumber;
                    wasUpdated = true;
                }
                if (wasUpdated) {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully updated the profile with the most recent EdNA data, please save your changes.', 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'Profile is up to date with the most recent EdNA data.', 'success', 3000, null);
                }
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null);
            }
        });
    }
    findSchoolInfoFromAunBlur(input) {
        if (input.value.length < 9)
            Core.createHTMLAlert('alertMessageDiv', 'The AUN must be 9 digits. If your AUN contains leading zeros, please include them here.', 'warning', 3000, null);
    }
    findSchoolInfoFromAunInput(input) {
        const value = input.value;
        const row = input.dataset.row;
        if (value.length === 9) {
            if (!isNaN(parseInt(value))) {
                this.runFindSchool(value, row);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'The AUN must be 9 digits. Please check your entry for invalid characters and try again.', 'warning', 3000, null);
            }
        }
    }
    runFindSchool(value, row) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const request = yield HttpRequestHandler.post(`/${this._navigationCode}/FindSchoolDataFromAUN`, 'Successfully retrieved data', 'There was an error retrieving the data, please try again.', JSON.stringify(value), 'application/json');
            if (request.ok) {
                const json = yield request.json();
                if (json.success) {
                    const leaName = json.instDetails.instName;
                    const superName = json.superintendentDetails.adminName;
                    const superEmail = json.superintendentDetails.email;
                    const superPhone = json.superintendentDetails.phoneNumber;
                    const superExt = json.superintendentDetails.extension;
                    const leaElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1815'][data-row='${row}']`);
                    const superNameElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1818'][data-row='${row}']`);
                    const superEmailElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1819'][data-row='${row}']`);
                    const superPhoneElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1820'][data-row='${row}']`);
                    const superExtElement = document.querySelector(`.${this._fieldset.Text}[data-propertypk='1821'][data-row='${row}']`);
                    if (leaName)
                        leaElement.value = leaName;
                    if (superName)
                        superNameElement.value = superName;
                    if (superEmail)
                        superEmailElement.value = superEmail;
                    if (superPhone)
                        superPhoneElement.value = superPhone;
                    if (superExt)
                        superExtElement.value = superExt;
                }
            }
        });
    }
    cyberCharterSelect(cyberCharterCheckbox, modal) {
        if (!cyberCharterCheckbox.checked) {
            const addAnAuthorizorButton = document.getElementById('charterschooladdAuthorizor');
            addAnAuthorizorButton.classList.remove('display-none');
            this._saveHandler.save('save');
            return;
        }
        modal.show();
    }
    cyberCharterConfirm(modal) {
        const addAnAuthorizorButton = document.getElementById('charterschooladdAuthorizor');
        addAnAuthorizorButton.classList.add('display-none');
        const deleteAuthorizorButtons = document.querySelectorAll('.charterschooldeleteAuthorizorButton ');
        for (const button of deleteAuthorizorButtons) {
            const deleteAuthorizorButton = button;
            this._groupHandler.deleteGroup(deleteAuthorizorButton);
        }
        modal.hide();
    }
    cyberCharterCancel(cyberCharterCheckbox, modal) {
        cyberCharterCheckbox.checked = false;
        modal.hide();
    }
}
class CharterSchoolAnnualReportGovernanceAndStaff extends CharterSchoolPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        this.constructQualityOfTeachingInputs();
    }
    constructQualityOfTeachingInputs() {
        const tableHeaders = document.querySelectorAll('tr.shared-page-table-header-row th');
        for (let column = 0; column < tableHeaders.length; column++) {
            const inputsInColumn = document.querySelectorAll(`input.column-${column}:not(.prepopulated)`);
            const totalInput = document.querySelector(`input.column-${column}.prepopulated`);
            for (const input of inputsInColumn)
                input.addEventListener('input', (e) => this.totalizeColumn(inputsInColumn, totalInput));
        }
    }
    totalizeColumn(inputsInColumn, totalInput) {
        let total = 0;
        for (const input of inputsInColumn) {
            if (input.value)
                total += parseInt(input.value);
        }
        totalInput.value = total.toString();
    }
}
class CharterSchoolAnnualReportFiscalMatters extends CharterSchoolPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, false);
        this.toggleConsolidatedReviewSection = () => {
            //see if "no" was selected for Federal Programs Consolidated Review Document(s) 
            var yesChecked = document.querySelector(`input[name="charterschoolfiscalconsolidatedreviewdocumentsRadio"][data-lookuplabel='Yes']:checked`);
            var sectionToHide = document.querySelector(".shared-page-table-container[data-propertygroupcodepk='90']");
            if (yesChecked != null) {
                if (sectionToHide != null) {
                    $(sectionToHide).prev(".shared-page-title").show();
                    $(sectionToHide).prev(".page-section-break").show();
                    $(sectionToHide).show();
                    let textAreas = document.querySelectorAll(".shared-page-table-container[data-propertygroupcodepk='90'] .charterschoolFiscalMattersTextAreaField");
                    for (let el of textAreas) {
                        this._validationHandler.forceElementRequired(el);
                    }
                }
            }
            else {
                if (sectionToHide != null) {
                    $(sectionToHide).prev(".shared-page-title").hide();
                    $(sectionToHide).prev(".page-section-break").hide();
                    $(sectionToHide).hide();
                    let textAreas = document.querySelectorAll(".shared-page-table-container[data-propertygroupcodepk='90'] .charterschoolFiscalMattersTextAreaField");
                    for (let el of textAreas) {
                        this._validationHandler.forceElementOptional(el);
                    }
                }
            }
        };
        this.toggleConsolidatedReviewSection();
        if (this._fromSaveElement && this._fromSaveElement.value === 'true')
            this._validationHandler.validate();
        this._radioButtonControlHandler.Callback = this.toggleConsolidatedReviewSection;
    }
}
class SpecialEducationPersonnelDevelopment {
    constructor(pageLabel, planPK) {
        this._core = new Core();
        this._pageLabel = pageLabel;
        this._planPK = planPK;
        this._fieldset = new Fieldset('charterSchool', this._pageLabel);
        this._validationHandler = new ValidationHandler(this._fieldset);
        this._core.leftnav(this);
        let noTrainingCheckboxes = document.querySelectorAll("[data-propertycode='charterschoolpersonneldevelopmentdidnotoffertraining'][data-accordionpk]");
        for (let checkbox of noTrainingCheckboxes) {
            this.handleNoTrainingCheckbox(checkbox);
            checkbox.addEventListener("click", () => {
                this.handleNoTrainingCheckbox(checkbox);
            });
        }
        this._core.initializeRequiredFields(this._fieldset.ValidationClasses, false, true);
        const fromSaveElement = document.getElementById('fromSave');
        if (fromSaveElement && fromSaveElement.value === 'true') {
            this._validationHandler.validate();
        }
        const hidden = this.createHash();
        const hiddenInput = document.getElementById('hashValue');
        if (hiddenInput)
            hiddenInput.value = hidden;
        this.constructEventListeners();
    }
    getCore() {
        return this._core;
    }
    createHash() {
        return this._core.createHash(this._fieldset.ValidationClasses);
    }
    constructEventListeners() {
        const accordions = document.getElementsByClassName(`${this._fieldset.Accordion}`);
        for (const accordion of accordions)
            new CharterSchoolAccordion(accordion.id);
        const addTrainingButtons = document.querySelectorAll(`.${this._fieldset.Button}[data-propertypk='2105']`);
        for (const button of addTrainingButtons)
            this.constructAddTrainingEventListener(button);
        const addDateTrainingCompletionButtons = document.querySelectorAll(`.${this._fieldset.Button}`);
        for (const button of addDateTrainingCompletionButtons)
            if (button.hasAttribute('data-trainingcompletion'))
                this.constructAddDateTrainingCompletionEventListener(button);
        const deleteTrainingButtons = document.querySelectorAll('.charterSchoolPersonnelDevelopmentDeleteTrainingButton');
        for (const button of deleteTrainingButtons)
            this.constructDeleteTrainingEventListener(button);
        const deleteDateTrainingCompletionButtons = document.querySelectorAll('.charterSchoolPersonnelDevelopmentDeleteButton');
        for (const button of deleteDateTrainingCompletionButtons)
            this.constructDeleteDateTrainingCompletionEventListener(button);
        const charterSchoolSaveButton = document.getElementById(`charterSchool${this._pageLabel}Save`);
        if (charterSchoolSaveButton)
            charterSchoolSaveButton.addEventListener('click', (e) => this.save('save'));
        const fileUploadElements = document.querySelectorAll(`.${this._fieldset.File}`);
        for (const element of fileUploadElements)
            this.constructFileUploadElementEventListeners(element);
        const deleteFileCancelButton = document.getElementById('deleteFileCancel');
        if (deleteFileCancelButton)
            deleteFileCancelButton.addEventListener('click', (e) => this.deleteFileCancel());
        const deleteFileConfirmButton = document.getElementById('deleteFileConfirm');
        if (deleteFileConfirmButton)
            deleteFileConfirmButton.addEventListener('click', (e) => this.deleteFile(deleteFileConfirmButton));
        this.constructFileDeleteElements();
    }
    constructFileDeleteElements() {
        const deleteFileButtons = document.getElementsByClassName('deleteFile');
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener('click', (e) => this.showDeleteFileConfirmation(deleteFileButton));
    }
    constructFileUploadElementEventListeners(input) {
        input.addEventListener('change', (e) => this.uploadFile(input));
    }
    constructAddTrainingEventListener(button) {
        button.addEventListener('click', (e) => this.addTraining(button));
    }
    constructAddDateTrainingCompletionEventListener(button) {
        button.addEventListener('click', (e) => this.addDateTrainingCompletion(button));
    }
    constructDeleteTrainingEventListener(button) {
        button.addEventListener('click', (e) => this.deleteTraining(button));
    }
    constructDeleteDateTrainingCompletionEventListener(button) {
        button.addEventListener('click', (e) => this.deleteTrainingCompletion(button));
    }
    uploadFile(input) {
        return __awaiter(this, void 0, void 0, function* () {
            const uploadFileForm = input.parentElement;
            const formData = new FormData(uploadFileForm);
            formData.append('planFK', this._planPK.toString());
            const response = yield HttpRequestHandler.post('/CharterSchool/UploadPersonnelDevelopmentFile', 'File successfully uploaded!', 'There was an error uploading your file, please try again.', formData);
            const json = yield response.json();
            if (json && json.success) {
                input.value = '';
                input.dataset.hasuploaded = 'true';
                this.getFileUploadPartialView(json.planPropertyFile, input.dataset);
            }
        });
    }
    deleteFile(confirmButton) {
        return __awaiter(this, void 0, void 0, function* () {
            const planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
            const response = yield HttpRequestHandler.post('/CharterSchool/DeleteFiles', 'File successfully deleted.', 'There was an error deleting this file, please try again', JSON.stringify([planPropertyFilePK]), 'application/json');
            const json = yield response.json();
            if (json.success)
                this.deleteFileSuccess(planPropertyFilePK);
        });
    }
    showDeleteFileConfirmation(deleteButton) {
        const planPropertyFilePK = deleteButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            const modal = new Modal('deleteFileModal', null);
            const deleteConfirmButton = document.getElementById('deleteFileConfirm');
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileCancel() {
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    deleteFileSuccess(planPropertyFilePK) {
        const fileUploadElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
        if (fileUploadElement)
            fileUploadElement.remove();
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    getFileUploadPartialView(planPropertyFile, dataset) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield HttpRequestHandler.get('/CharterSchool/GetCharterSchoolFileUploadPartialView', {
                planPropertyFilePK: planPropertyFile.planPropertyFilePK,
                contentType: planPropertyFile.contentType,
                filename: planPropertyFile.filename
            });
            const text = yield response.text();
            if (response.ok && text) {
                const fileUploadDiv = document.querySelector(`.charterSchoolFileUploadDivInner[data-propertypk='${dataset.propertypk}'][data-accordiontitle='${dataset.accordiontitle}'][data-training='${dataset.training}'][data-trainingcompletion='${dataset.trainingcompletion}']`);
                fileUploadDiv.insertAdjacentHTML('beforeend', text);
                this.constructFileDeleteElements();
            }
        });
    }
    addTraining(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataset = button.dataset;
            const training = parseInt(dataset.training);
            const nextTraining = training + 1;
            const addtrainingModel = JSON.stringify({
                PlanPK: this._planPK,
                AccordionPK: dataset.accordionpk,
                Training: nextTraining,
                AccordionTitle: dataset.accordiontitle
            });
            const response = yield HttpRequestHandler.post('/CharterSchool/AddPersonnelDevelopmentTraining', 'Successfully added a training.', 'There was an error adding a training, please try again', addtrainingModel, 'application/json');
            const text = yield response.text();
            if (response.ok && text) {
                const accordionTitle = dataset.accordiontitle;
                button.parentElement.insertAdjacentHTML('beforebegin', text);
                button.dataset.training = nextTraining.toString();
                const newTrainingInputs = document.querySelectorAll(`.personnel-development-training.personnel-development-row[data-accordiontitle='${accordionTitle}'][data-training='${nextTraining}'] input`);
                for (const input of newTrainingInputs)
                    this._validationHandler.forceElementRequired(input);
                const newAddDateTrainingCompletionButton = document.querySelector(`.${this._fieldset.Button}[data-accordiontitle='${accordionTitle}'][data-training='${nextTraining}']`);
                this.constructAddDateTrainingCompletionEventListener(newAddDateTrainingCompletionButton);
                const newTrainingCompletionFileUploadInputs = document.querySelectorAll(`.${this._fieldset.File}[data-accordiontitle='${accordionTitle}'][data-training='${nextTraining}']`);
                for (const fileUpload of newTrainingCompletionFileUploadInputs) {
                    this.constructFileUploadElementEventListeners(fileUpload);
                }
                const newDeleteTrainingButton = document.querySelector(`.charterSchoolPersonnelDevelopmentDeleteTrainingButton[data-accordiontitle='${accordionTitle}'][data-training='${nextTraining}']`);
                this.constructDeleteTrainingEventListener(newDeleteTrainingButton);
                this.addDeleteTrainingCompletionButton(dataset);
            }
        });
    }
    addDateTrainingCompletion(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataset = button.dataset;
            const accordionTitle = dataset.accordiontitle;
            const training = dataset.training;
            const trainingCompletion = dataset.trainingcompletion;
            const nextTrainingCompletion = parseInt(trainingCompletion) + 1;
            const addDateTrainingCompletionModel = JSON.stringify({
                AccordionPK: dataset.accordionpk,
                AccordionTitle: dataset.accordiontitle,
                PlanPK: this._planPK,
                TrainingCompletion: nextTrainingCompletion,
                Training: dataset.training,
            });
            const response = yield HttpRequestHandler.post('/CharterSchool/AddPersonnelDevelopmentDateTrainingCompletion', 'Successfully added a training completion record.', 'There was an error adding a training completion, please try again.', addDateTrainingCompletionModel, 'application/json');
            const text = yield response.text();
            if (response.ok && text) {
                const buttonRow = button.closest('div');
                buttonRow.insertAdjacentHTML('beforebegin', text);
                const newTrainingCompletionInputs = document.querySelectorAll(`div.personnel-development-training-completion[data-accordiontitle='${accordionTitle}'][data-training='${training}'][data-trainingcompletion='${nextTrainingCompletion}'] input`);
                for (const input of newTrainingCompletionInputs)
                    this._validationHandler.forceElementRequired(input);
                button.dataset.trainingcompletion = nextTrainingCompletion.toString();
                const newTrainingCompletionFileUploadInputs = document.querySelectorAll(`.${this._fieldset.File}[data-accordiontitle='${accordionTitle}'][data-training='${training}'][data-trainingcompletion='${nextTrainingCompletion}']`);
                for (const fileUpload of newTrainingCompletionFileUploadInputs) {
                    this.constructFileUploadElementEventListeners(fileUpload);
                }
                this.addDeleteTrainingCompletionButton(dataset, nextTrainingCompletion);
            }
        });
    }
    addDeleteTrainingCompletionButton(dataset, trainingCompletion = 0) {
        const deleteTrainingCompletionButton = document.querySelector(`.charterSchoolPersonnelDevelopmentDeleteButton[data-accordionpk='${dataset.accordionpk}'][data-training='${dataset.training}'][data-trainingcompletion='${trainingCompletion}']`);
        deleteTrainingCompletionButton.addEventListener('click', (e) => this.deleteTrainingCompletion(deleteTrainingCompletionButton));
    }
    deleteTraining(deleteTrainingButton) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataset = deleteTrainingButton.dataset;
            let count = 0;
            let personnelRows = document.querySelectorAll(`.personnel-development-training.personnel-development-row[data-accordiontitle='${dataset.accordiontitle}']`);
            for (let thisRow of personnelRows) {
                count++;
            }
            if (count > 1) {
                const training = deleteTrainingButton.closest('.personnel-development-training.personnel-development-row');
                const elementsInTraining = training.querySelectorAll(`[data-training='${dataset.training}']`);
                const planPropertiesToDelete = [];
                for (const element of elementsInTraining) {
                    if (element.dataset.planpropertypk) {
                        planPropertiesToDelete.push(element.dataset.planpropertypk);
                    }
                }
                const filesToDelete = training.querySelectorAll(`.charterSchoolFileUploadDivInner .uploadFileColumn`);
                const planPropertyFilePKsToDelete = [];
                for (const file of filesToDelete)
                    planPropertyFilePKsToDelete.push(file.dataset.planpropertyfilepk);
                const accordion = document.querySelector(`.${this._fieldset.Accordion}[data-propertypk='${dataset.accordionpk}']`);
                const data = {
                    PlanPropertyPKs: planPropertiesToDelete,
                    PlanPropertyFilePKs: planPropertyFilePKsToDelete,
                    AccordionPlanPropertyPK: accordion.dataset.planpropertypk
                };
                const response = yield HttpRequestHandler.post('/CharterSchool/DeletePersonnelDevelopmentTraining', 'Successfully deleted the training.', 'There was an error deleting this training , please try again', JSON.stringify(data), 'application/json');
                const text = yield response.text();
                if (response.ok && text)
                    training.remove();
            }
            else {
                //show alert to let them know to just change the data
                alert("This is the last row.  Please change your data.");
            }
        });
    }
    deleteTrainingCompletion(deleteTrainingCompletionButton) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataset = deleteTrainingCompletionButton.dataset;
            const dateFieldToDelete = document.querySelector(`.${this._fieldset.Calendar}[data-accordionpk='${dataset.accordionpk}'][data-training='${dataset.training}'][data-trainingcompletion='${dataset.trainingcompletion}']`);
            const dateFieldToDeletePlanPropertyPK = [dateFieldToDelete.dataset.planpropertypk];
            const filesToDelete = document.querySelectorAll(`.charterSchoolFileUploadDivInner[data-relatedplanpropertypk='${dateFieldToDeletePlanPropertyPK}'] .uploadFileColumn`);
            const planPropertyFilePKsToDelete = [];
            for (const f of filesToDelete) {
                const file = f;
                planPropertyFilePKsToDelete.push(file.dataset.planpropertyfilepk);
            }
            const accordion = document.querySelector(`.${this._fieldset.Accordion}[data-propertypk='${dataset.accordionpk}']`);
            const data = {
                PlanPropertyPKs: dateFieldToDeletePlanPropertyPK,
                PlanPropertyFilePKs: planPropertyFilePKsToDelete,
                AccordionPlanPropertyPK: accordion.dataset.planpropertypk
            };
            const response = yield HttpRequestHandler.post('/CharterSchool/DeletePersonnelDevelopmentDateTrainingCompletion', 'Successfully deleted the training completion.', 'There was an error deleting this training completion , please try again', JSON.stringify(data), 'application/json');
            const text = yield response.text();
            if (response.ok && text) {
                const trainingcompletion = document.querySelector(`.personnel-development-training-completion[data-accordionpk='${dataset.accordionpk}'][data-training='${dataset.training}'][data-trainingcompletion='${dataset.trainingcompletion}']`);
                if (trainingcompletion)
                    trainingcompletion.remove();
            }
        });
    }
    save(referrer) {
        Core.showLoader();
        let refreshPage = referrer;
        let planPK = this._planPK;
        if (referrer !== 'save' && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        if (referrer === 'continue')
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url');
        if (referrer === 'back')
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        else
            refreshPage = referrer;
        const allDataToSave = this.getAllDataToSave();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', `/CharterSchool/SaveCharterSchool${this._pageLabel}`, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = () => {
            Core.hideLoader();
            if (referrer === 'save') {
                if (xhr.status === 200) {
                    this._core.pageReload(true, planPK);
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'Request failed.  Returned status of ' + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200 && refreshPage && refreshPage !== '') {
                    window.location.href = refreshPage;
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'Request failed.  Returned status of ' + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allDataToSave.length === 0) {
            if (referrer === 'save') {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'Please enter a value to save first', 'warning', 3000, null);
                this._core.doValidation(this._fieldset.ValidationClasses);
            }
            else {
                if (refreshPage && refreshPage !== '') {
                    window.location.href = refreshPage;
                }
                else {
                    Core.hideLoader();
                }
            }
        }
        else {
            xhr.send(JSON.stringify(allDataToSave));
        }
    }
    getAllDataToSave() {
        const allDataToSave = [];
        const accordions = document.getElementsByClassName(this._fieldset.Accordion);
        if (accordions && accordions.length) {
            for (const accordion of accordions) {
                const accordionPlanProperty = this.getPlanPropertyToSave(accordion, this._fieldset.Accordion);
                const accordionElements = this.getAccordionElements(accordion);
                if (accordionPlanProperty && accordionElements && accordionElements.length) {
                    const personnelDevelopmentSaveDatum = {
                        Accordion: accordionPlanProperty,
                        Elements: accordionElements
                    };
                    allDataToSave.push(personnelDevelopmentSaveDatum);
                }
            }
        }
        return allDataToSave;
    }
    getAccordionElements(accordion) {
        const personnelDevelopmentSaveData = [];
        const dataset = accordion.dataset;
        for (const field of this._fieldset.SaveableClasses) {
            const accordionElements = document.querySelectorAll(`.${field}[data-accordionpk='${dataset.propertypk}']`);
            for (const e of accordionElements) {
                const element = e;
                const planProperty = this.getPlanPropertyToSave(element, field);
                const personnelDevelopmentSaveDatum = {
                    PlanPropertyPK: planProperty.PlanPropertyPK,
                    PlanFK: this._planPK,
                    PropertyFK: planProperty.PropertyFK,
                    TextValue: planProperty.TextValue,
                    LookupCodeFK: planProperty.LookupCodeFK,
                    RowNbr: planProperty.RowNbr,
                    IsDeletedInd: false,
                    ParentPropertyPK: parseInt(dataset.propertypk)
                };
                personnelDevelopmentSaveData.push(personnelDevelopmentSaveDatum);
            }
        }
        return personnelDevelopmentSaveData;
    }
    getPlanPropertyToSave(element, field) {
        let planProperty;
        switch (field) {
            case this._fieldset.Accordion:
                planProperty = this.getAccordionPlanProperty(element);
                break;
            case this._fieldset.Calendar:
            case this._fieldset.Text:
                planProperty = this.getInputPlanProperty(element);
                break;
            case this._fieldset.Checkbox:
                planProperty = this.getCheckboxPlanProperty(element);
                break;
        }
        return planProperty;
    }
    getAccordionPlanProperty(element) {
        const accordionElement = element;
        if (accordionElement.disabled)
            return null;
        const planProperty = {
            PlanPropertyPK: parseInt(accordionElement.dataset.planpropertypk),
            PlanFK: this._planPK,
            PropertyFK: parseInt(accordionElement.dataset.propertypk),
            TextValue: accordionElement.dataset.label,
            LookupCodeFK: null,
            RowNbr: null,
            IsDeletedInd: false
        };
        return planProperty;
    }
    getInputPlanProperty(element) {
        const inputElement = element;
        if (inputElement.readOnly)
            return null;
        const planProperty = {
            PlanPropertyPK: parseInt(inputElement.dataset.planpropertypk),
            PlanFK: this._planPK,
            PropertyFK: parseInt(inputElement.dataset.propertypk),
            TextValue: inputElement.value,
            LookupCodeFK: null,
            RowNbr: parseInt(inputElement.dataset.training),
            IsDeletedInd: false
        };
        return planProperty;
    }
    getCheckboxPlanProperty(element) {
        const inputElement = element;
        const planProperty = {
            PlanPropertyPK: parseInt(inputElement.dataset.planpropertypk),
            PlanFK: this._planPK,
            PropertyFK: parseInt(inputElement.dataset.propertypk),
            TextValue: inputElement.checked ? 'on' : 'off',
            LookupCodeFK: null,
            RowNbr: parseInt(inputElement.dataset.training),
            IsDeletedInd: false
        };
        return planProperty;
    }
    handleNoTrainingCheckbox(checkbox) {
        let containerToToggle = document.querySelector(`.offersTrainingContainer[data-accordionpk='${checkbox.dataset.accordionpk}']`);
        if (containerToToggle != null) {
            if (checkbox.checked) {
                containerToToggle.classList.add("hide");
            }
            else {
                containerToToggle.classList.remove("hide");
            }
        }
        for (const field of this._fieldset.SaveableClasses) {
            const accordionElements = document.querySelectorAll(`.${field}[data-accordionpk='${checkbox.dataset.accordionpk}']:not([data-propertycode='charterschoolpersonneldevelopmentdidnotoffertraining'])`);
            for (const e of accordionElements) {
                const element = e;
                if (checkbox.checked) {
                    this._validationHandler.forceElementOptional(element);
                }
                else {
                    if ("initiallyrequired" in element.dataset && element.dataset.initiallyrequired === "true") {
                        this._validationHandler.forceElementRequired(element);
                    }
                }
            }
        }
        let fileFields = containerToToggle.querySelectorAll(`.charterSchoolSpecialEducationPersonnelDevelopmentFileField`);
        for (const fileField of fileFields) {
            if (checkbox.checked) {
                this._validationHandler.forceElementOptional(fileField);
            }
            else {
                if ("initiallyrequired" in fileField.dataset && fileField.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementRequired(fileField);
                }
            }
        }
    }
}
class CharterSchoolAnnualReportSpecialEducation extends CharterSchoolPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
    }
}
class CharterSchoolAnnualReportFacilitiesAndAgreements extends CharterSchoolPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, false);
        this.handleShowMOM = () => {
            let checkbox = document.getElementById("charterschoolfacilitiesnomom");
            for (const field of this._fieldset.SaveableClasses) {
                const requiredElements = document.querySelectorAll(`.${field}[data-propertygroupcodepk='89']`);
                for (const e of requiredElements) {
                    const element = e;
                    if (checkbox.checked) {
                        this._validationHandler.forceElementOptional(element);
                    }
                    else {
                        if ("initiallyrequired" in element.dataset && element.dataset.initiallyrequired === "true") {
                            this._validationHandler.forceElementRequired(element);
                        }
                    }
                }
            }
            let fileFields = document.querySelectorAll(".charterschoolFacilitiesAndAgreementsFileField[data-propertygroupcodepk='212']");
            for (const fileField of fileFields) {
                if (checkbox.checked) {
                    this._validationHandler.forceElementOptional(fileField);
                }
                else {
                    if ("initiallyrequired" in fileField.dataset && fileField.dataset.initiallyrequired === "true") {
                        this._validationHandler.forceElementRequired(fileField);
                    }
                }
            }
            var sectionsToHide = document.querySelectorAll(".shared-page-table-container[data-propertygroupcodepk='89'], .property-group[data-propertygroupcodepk='212']");
            if (sectionsToHide != null) {
                if (checkbox.checked) {
                    sectionsToHide.forEach(el => el.classList.add("hide"));
                }
                else {
                    sectionsToHide.forEach(el => el.classList.remove("hide"));
                }
            }
        };
        this.handleShowArticulationAgreements = () => {
            let checkbox = document.getElementById("charterschoolfacilitiesnoarticulationagreements");
            for (const field of this._fieldset.SaveableClasses) {
                const requiredElements = document.querySelectorAll(`.${field}[data-propertygroupcodepk='226']`);
                for (const e of requiredElements) {
                    const element = e;
                    if (checkbox.checked) {
                        this._validationHandler.forceElementOptional(element);
                    }
                    else {
                        if ("initiallyrequired" in element.dataset && element.dataset.initiallyrequired === "true") {
                            this._validationHandler.forceElementRequired(element);
                        }
                    }
                }
            }
            let fileFields = document.querySelectorAll(".charterschoolFacilitiesAndAgreementsFileField[data-propertygroupcodepk='226']");
            for (const fileField of fileFields) {
                if (checkbox.checked) {
                    this._validationHandler.forceElementOptional(fileField);
                }
                else {
                    if ("initiallyrequired" in fileField.dataset && fileField.dataset.initiallyrequired === "true") {
                        this._validationHandler.forceElementRequired(fileField);
                    }
                }
            }
            var sectionToHide = document.querySelector(".property-group-section[data-propertygroupcodepk='226']");
            if (sectionToHide != null) {
                let addGroup = $(sectionToHide).next(".shared-page-button")[0];
                if (checkbox.checked) {
                    sectionToHide.classList.add("hide");
                    if (addGroup != null) {
                        addGroup.classList.add("hide");
                    }
                }
                else {
                    sectionToHide.classList.remove("hide");
                    if (addGroup != null) {
                        addGroup.classList.remove("hide");
                    }
                }
            }
        };
        let that = this;
        //Memorandum of understanding
        that.handleShowMOM();
        let noMOMCheckbox = document.getElementById("charterschoolfacilitiesnomom");
        if (noMOMCheckbox != null) {
            noMOMCheckbox.addEventListener("change", that.handleShowMOM);
        }
        //Articulation Agreements
        that.handleShowArticulationAgreements();
        let noArticulationsCheckbox = document.getElementById("charterschoolfacilitiesnoarticulationagreements");
        if (noArticulationsCheckbox != null) {
            noArticulationsCheckbox.addEventListener("change", that.handleShowArticulationAgreements);
        }
        if (that._fromSaveElement && that._fromSaveElement.value === 'true')
            that._validationHandler.validate();
    }
}
class CharterSchoolAnnualReportManagementSurvey extends CharterSchoolPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, false);
        this.forceOptionalFields = () => {
            const address2 = document.getElementById('charterschoolmanagementsurveyaddress2');
            const additonalComments = document.getElementById('charterschoolmanagementsurveyadditionalcomments');
            this._validationHandler.forceElementOptional(address2);
            this._validationHandler.forceElementOptional(additonalComments);
        };
        this.forceOptionalFields();
        if (this._fromSaveElement && this._fromSaveElement.value === 'true')
            this._validationHandler.validate();
        this._radioButtonControlHandler.Callback = this.forceOptionalFields;
    }
}
class CharterSchoolAnnualReportSignaturesAndAffirmations extends CharterSchoolPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        const calendar = document.getElementById('charterschoolsignaturesaffirmationsdateofapproval');
        const row = calendar.closest('.charterschool-content-row');
        row.insertAdjacentHTML('afterend', '<a href="/documents/affirmation_statement_charterschool.docx" target="_blank" aria-label="Download the Board Affirmation Document"><i class="fas fa-file-download"></i>&nbsp; Download Board Affirmation Statement</a>');
        this.constructEventListeners();
    }
    constructEventListeners() {
        this.constructFileUploadSuccessEventListener();
        // this.checkForSignoff()
    }
    constructFileUploadSuccessEventListener() {
        this._fileHandler.uploadFile = null;
        const fileUploadElement = document.querySelector(`.${this._fieldset.File}`);
        if (fileUploadElement)
            fileUploadElement.addEventListener('change', (e) => this.uploadFile(fileUploadElement));
    }
    uploadFile(fileUploadElement) {
        return __awaiter(this, void 0, void 0, function* () {
            const uploadFileForm = fileUploadElement.parentElement;
            const formData = new FormData(uploadFileForm);
            const planFK = document.querySelector('main').dataset.planfk;
            formData.append('planFK', planFK);
            const response = yield HttpRequestHandler.post(`/${this._navigationCode}/UploadFile/`, 'File successfully uploaded!', 'There was an error uploading your file, please try again.', formData);
            const json = yield response.json();
            if (json.success)
                this._core.pageReload(false, this._planPK);
        });
    }
}
class CharterSchoolAnnualReportSummaryChecklistAndSubmission {
    constructor() {
        let that = this;
        this._core = new Core();
        that._lateEmailModal = new Modal("sendLateEmailModal", "sendLateEmail");
        let backButton = document.getElementById('backButton');
        if (backButton !== null)
            backButton.addEventListener('click', (e) => this.back(e));
        let submitButton = document.getElementById('submitPlanButton');
        if (submitButton !== null)
            submitButton.addEventListener('click', (e) => this.submit(e));
        let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]');
        if (sendLateEmailButton != null)
            sendLateEmailButton.addEventListener('click', () => {
                that._lateEmailPlanFK = parseInt(sendLateEmailButton.dataset.planFk);
                that._lateEmailModal.show();
            });
        let sendLateEmailConfirmButton = document.querySelector('#sendLateEmailConfirm');
        if (sendLateEmailConfirmButton != null)
            sendLateEmailConfirmButton.addEventListener('click', () => this.sendLateEmail());
        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
        if (sendLateEmailDueDate != null) {
            sendLateEmailDueDate.addEventListener('change', () => {
                if (sendLateEmailConfirmButton != null) {
                    sendLateEmailConfirmButton.disabled = false;
                }
            });
        }
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let refreshPage = '';
        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url');
        }
        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== '') {
            window.location.href = refreshPage;
        }
        else {
            Core.hideLoader();
        }
    }
    submit(e) {
        let element = e.srcElement;
        if (!element.classList.contains('disabled')) {
            Core.showLoader();
            let planFK = parseInt(element.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CharterSchool/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = () => {
                xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true
                    ? Core.createHTMLAlert('alertMessageDiv', 'This report has been submitted for review!', 'success', 3000, window.location.reload())
                    : Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred while submitting report. Please try again later.', 'error', 3000, null);
                Core.hideLoader();
            };
            xhr.send('planFK=' + planFK);
        }
    }
    sendLateEmail() {
        let that = this;
        Core.showLoader();
        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CharterSchool/SendLateEmail', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = () => {
            xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true
                ? Core.createHTMLAlert('alertMessageDiv', 'Successfully sent late email', 'success', 3000, null)
                : Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error sending late email', 'error', 3000, null);
            Core.hideLoader();
            that._lateEmailModal.hide();
            let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]');
            if (sendLateEmailButton != null) {
                sendLateEmailButton.disabled = true;
            }
        };
        xhr.send(`planFK=${that._lateEmailPlanFK}&newDueDateStr=${sendLateEmailDueDate.value}`);
    }
}
class CharterSchoolAccordion {
    constructor(accordionId) {
        const accordion = document.getElementById(accordionId);
        const accordionButton = accordion;
        accordionButton.setAttribute('data-hasclick', 'true');
        accordionButton.addEventListener('click', (e) => this.toggle(accordion.id));
    }
    toggle(id) {
        const element = document.getElementById(id);
        const contentId = element.getAttribute('aria-controls');
        const contentElement = document.getElementById(contentId);
        contentElement.classList.contains('open')
            ? this.hide(element, contentElement)
            : this.show(element, contentElement);
    }
    show(element, contentElement) {
        element.classList.add('open');
        element.setAttribute('aria-expanded', 'true');
        contentElement.classList.add('open');
    }
    hide(element, contentElement) {
        element.classList.remove('open');
        element.setAttribute('aria-expanded', 'false');
        contentElement.classList.remove('open');
    }
}
/// <reference path="../core.ts" />
class Modal {
    constructor(modalId, callingId) {
        this.id = modalId;
        this.callingId = callingId;
    }
    show() {
        let _that = this;
        let element = document.getElementById(this.id);
        Core.trapFocus(element);
        element.classList.add("opened");
        element.setAttribute("aria-hidden", "false");
        let closeElementId = "closeModal" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.addEventListener("click", function (e) { _that.hide(); });
        closeElement.addEventListener("keypress", function (e) { if (Core.a11yClick(e)) {
            _that.hide();
        } });
        let h1ElementId = "h1Modal" + this.id;
        let h1Element = document.getElementById(h1ElementId);
        h1Element.focus();
    }
    hide() {
        let _that = this;
        let element = document.getElementById(this.id);
        element.classList.remove("opened");
        element.setAttribute("aria-hidden", "true");
        let closeElementId = "closeModal" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.removeEventListener("click", function (e) { _that.hide(); });
        closeElement.removeEventListener("keypress", function (e) { if (Core.a11yClick(e)) {
            _that.hide();
        } });
        if (this.callingId !== null) {
            let callingElement = document.getElementById(this.callingId);
            callingElement.focus();
        }
    }
    addAttributeToElement(modalId, elementQuery, attributeName, attributeValue, noDataPart = false) {
        let modalElement = document.getElementById(modalId);
        let targetElements = modalElement.querySelectorAll(elementQuery);
        let dataAttributeName;
        if (!noDataPart)
            dataAttributeName = "data-" + attributeName;
        else
            dataAttributeName = attributeName;
        for (let target of targetElements) {
            let targetElement = target;
            if (targetElement.hasAttribute(dataAttributeName)) {
                targetElement.dataset[attributeName] = attributeValue;
            }
            else {
                targetElement.setAttribute(dataAttributeName, attributeValue);
            }
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/modal.ts" />
//Profile
class FlexibleInstructionalDaysProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionalDaysProfileField"];
        let specialEducationProfileSaveButton = document.getElementById("flexibleInstructionDaysProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("flexibleInstructionDaysProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("flexibleInstructionDaysProfileForm");
        let inputs = document.getElementsByClassName("flexibleInstructionalDaysProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("flexibleInstructionDaysProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Narratives
class FlexibleInstructionalDaysNarratives {
    constructor() {
        this._core = new Core();
        this.noContingencyPlan();
        this.validationClasses = ["flexibleInstructionalDaysNarrativesField"];
        let specialEducatioNarrativesSaveButton = document.getElementById("flexibleInstructionDaysNarrativesSave");
        if (specialEducatioNarrativesSaveButton !== null)
            specialEducatioNarrativesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
        const noContingencyPlan = document.querySelector(".noContingencyPlan");
        if (noContingencyPlan !== null) {
            noContingencyPlan.addEventListener("change", (e) => this.noContingencyPlan());
            if (noContingencyPlan.checked) {
                const reviewAccordions = document.querySelectorAll("[data-review-container] .Accordion");
                if (reviewAccordions.length > 4) {
                    for (let i = 4; i < reviewAccordions.length; i++) {
                        const reviewQuestions = reviewAccordions.item(i).querySelectorAll("[data-action-type='componentCompliantNA'][data-lookup-code='actionNA']");
                        for (let question of reviewQuestions) {
                            let compliantActionElement = question;
                            compliantActionElement.click();
                            compliantActionElement.disabled = true;
                        }
                    }
                }
            }
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("flexibleInstructionDaysNarrativesForm");
        let inputs = document.getElementsByClassName("flexibleInstructionalDaysNarrativesField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("flexibleInstructionalDaysNarrativesCheckboxField");
        for (let ele of checkboxes) {
            const element = ele;
            const propertyPK = parseInt(element.dataset.propertypk);
            const planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            const saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysNarratives', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    noContingencyPlan() {
        const noContingencyPlan = document.querySelector(".noContingencyPlan");
        const allContingencyDivs = document.querySelectorAll(".noContingencyPlanDiv");
        const allContingencyTexts = document.querySelectorAll(".noContingencyPlanText");
        if (noContingencyPlan !== null) {
            if (noContingencyPlan.checked) {
                for (const div of allContingencyDivs) {
                    const element = div;
                    element.classList.add("hide");
                }
                for (const text of allContingencyTexts) {
                    const element = text;
                    this._core.forceElementOptional(element);
                }
            }
            else {
                for (const div of allContingencyDivs) {
                    const element = div;
                    element.classList.remove("hide");
                }
                for (const text of allContingencyTexts) {
                    const element = text;
                    this._core.forceElementRequired(element);
                }
            }
        }
    }
}
//Exemplars
class FlexibleInstructionalDaysExemplars {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionalDaysExemplarField", "flexibleInstructionDaysExemplarsRadioField"];
        let specialEducatioNarrativesSaveButton = document.getElementById("flexibleInstructionDaysExemplarsSave");
        if (specialEducatioNarrativesSaveButton !== null)
            specialEducatioNarrativesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let showMessage = this.initializeFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses, showMessage);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exemplarUploads = document.getElementsByClassName("flexibleInstructionDaysExemplarsFileField");
        for (let exemplarUpload of exemplarUploads)
            exemplarUpload.addEventListener("change", (e) => this.uploadFile(e.target));
        let radios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioExemplarsField");
        for (let radio of radios)
            radio.addEventListener("change", (e) => this.changeExemplarRadio(e));
        let cteRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioCTEField");
        for (let cteRadio of cteRadios)
            cteRadio.addEventListener("change", (e) => this.changeCTERadio(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e) => this.deleteFile(e));
        this.showFiles();
        this.handleRadios();
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let allFileElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("flexibleInstructionDaysExemplarsForm");
        let inputs = document.getElementsByClassName("flexibleInstructionDaysExemplarsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let fakeFiles = document.getElementsByClassName("flexibleInstructionDaysExemplarsFileField");
        for (let ele of fakeFiles) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: "File",
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let files = document.getElementsByClassName("flexibleInstructionDaysExemplarsFile");
        for (let ele of files) {
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyPK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            let propertyCode = element.dataset.propertycode;
            let planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanFK: planPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyPK,
                PropertyCode: propertyCode,
                PlanPropertyPK: planPropertyPK
            };
            allFileElements.push(saveItem);
        }
        let allSaveData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysExemplars', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allSaveData));
    }
    showFiles() {
        let allContainers = document.getElementsByClassName("uploadedExemplarFileList");
        for (let container of allContainers) {
            let containerElement = container;
            let exemplar = containerElement.dataset.exemplar;
            let elements = containerElement.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='${exemplar}']`);
            if (elements.length > 0) {
                let containingContainer = document.querySelector(`.uploadedExemplarFiles[data-exemplar='${exemplar}']`);
                if (containingContainer.classList.contains("hide"))
                    containingContainer.classList.remove("hide");
            }
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let exemplar = e.dataset.exemplar;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPlanPropertyFilePK = e.dataset.planpropertyplanpropertyfilepk;
        let planPropertyPK = e.dataset.planpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadedExemplarFileList[data-exemplar='${exemplar}']`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("flexibleInstructionDaysExemplarsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`.uploadedExemplarFiles[data-exemplar='${exemplar}']`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    changeExemplarRadio(e) {
        let radioElement = e.target;
        let exemplar = radioElement.dataset.exemplar;
        let lookupCode = radioElement.dataset.lookupcode;
        let exemplarContainer = document.querySelector(`.exemplars .exemplar[data-exemplar='${exemplar}']`);
        if (lookupCode === "no") {
            let inputs = exemplarContainer.querySelectorAll("input");
            for (let input of inputs) {
                let element = input;
                if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                    element.disabled = true;
                    this._core.forceElementOptional(element);
                }
                else if (element.classList.contains("flexibleInstructionDaysExemplarsField")) {
                    element.readOnly = true;
                    this._core.forceElementOptional(element);
                }
            }
        }
        else {
            let inputs = exemplarContainer.querySelectorAll("input");
            for (let input of inputs) {
                let element = input;
                if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                    element.disabled = false;
                    this._core.forceElementRequired(element);
                }
                else if (element.classList.contains("flexibleInstructionDaysExemplarsField")) {
                    element.readOnly = false;
                    this._core.forceElementRequired(element);
                }
            }
        }
    }
    changeCTERadio(e) {
        let radioElement = e.target;
        let lookupCode = radioElement.dataset.lookupcode;
        let exemplarContainer = document.getElementById("cteExemplars");
        if (lookupCode === "no") {
            exemplarContainer.classList.add("hide");
            let dateElementG = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementOptional(dateElementG);
            let fileElementG = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementOptional(fileElementG);
            let dateElementH = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementOptional(dateElementH);
            let fileElementH = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementOptional(fileElementH);
        }
        else {
            exemplarContainer.classList.remove("hide");
            let dateElementG = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementRequired(dateElementG);
            let fileElementG = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
            this._core.forceElementRequired(fileElementG);
            let dateElementH = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementRequired(dateElementH);
            let fileElementH = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
            this._core.forceElementRequired(fileElementH);
        }
    }
    handleRadios() {
        let allRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioExemplarsField");
        for (let radio of allRadios) {
            let radioElement = radio;
            if (radioElement.checked) {
                let exemplar = radioElement.dataset.exemplar;
                let lookupCode = radioElement.dataset.lookupcode;
                let exemplarContainer = document.querySelector(`.exemplars .exemplar[data-exemplar='${exemplar}']`);
                if (lookupCode === "no") {
                    let inputs = exemplarContainer.querySelectorAll("input");
                    for (let input of inputs) {
                        let element = input;
                        if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                            element.disabled = true;
                            this._core.forceElementOptional(element);
                        }
                        else if (element.classList.contains("flexibleInstructionDaysExemplarsField")) {
                            element.readOnly = true;
                            this._core.forceElementOptional(element);
                        }
                    }
                }
                else {
                    let inputs = exemplarContainer.querySelectorAll("input");
                    for (let input of inputs) {
                        let element = input;
                        if (element.classList.contains("flexibleInstructionDaysExemplarsFileField")) {
                            element.disabled = false;
                            this._core.forceElementRequired(element);
                        }
                        else if (element.classList.contains("flexibleInstructionDaysExemplarsField")) {
                            element.readOnly = false;
                            this._core.forceElementRequired(element);
                        }
                    }
                }
            }
        }
        let allCTERadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioCTEField");
        for (let radio of allCTERadios) {
            let radioElement = radio;
            if (radioElement.checked) {
                let lookupCode = radioElement.dataset.lookupcode;
                let exemplarContainer = document.getElementById("cteExemplars");
                if (lookupCode === "no") {
                    exemplarContainer.classList.add("hide");
                    let dateElementG = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementOptional(dateElementG);
                    let fileElementG = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementOptional(fileElementG);
                    let dateElementH = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementOptional(dateElementH);
                    let fileElementH = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementOptional(fileElementH);
                }
                else {
                    exemplarContainer.classList.remove("hide");
                    let dateElementG = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementRequired(dateElementG);
                    let fileElementG = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixG']`);
                    this._core.forceElementRequired(fileElementG);
                    let dateElementH = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementRequired(dateElementH);
                    let fileElementH = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    this._core.forceElementRequired(fileElementH);
                }
            }
        }
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.flexibleInstructionDaysExemplarsFile[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    initializeFieldsCustom() {
        let showMessage = false;
        let coreRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioExemplarsField");
        for (let coreRadio of coreRadios) {
            let element = coreRadio;
            if (element.checked) {
                let label = element.dataset.lookuplabel;
                if (label.toLowerCase() === "yes".toLowerCase()) {
                    let labelExemplar = element.dataset.exemplar;
                    let dateElement = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='${labelExemplar}']`);
                    if (dateElement.value === "") {
                        showMessage = true;
                        this._core.forceElementRequired(dateElement);
                    }
                    let fileElements = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='${labelExemplar}']`);
                    if (fileElements.length < 0) {
                        let uploadElement = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='${labelExemplar}']`);
                        this._core.forceElementRequired(uploadElement);
                    }
                }
                else {
                    let labelExemplar = element.dataset.exemplar;
                    let dateElement = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='${labelExemplar}']`);
                    if (dateElement.value === "") {
                        this._core.forceElementOptional(dateElement);
                    }
                    let fileElements = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='${labelExemplar}']`);
                    if (fileElements.length < 0) {
                        let uploadElement = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='${labelExemplar}']`);
                        this._core.forceElementOptional(uploadElement);
                    }
                }
            }
        }
        let cteRadios = document.getElementsByClassName("flexibleInstructionDaysExemplarsRadioCTEField");
        for (let cteRadio of cteRadios) {
            let element = cteRadio;
            if (element.checked) {
                let label = element.dataset.lookuplabel;
                if (label.toLowerCase() === "yes".toLowerCase()) {
                    let dateElementG = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    if (dateElementG.value === "") {
                        showMessage = true;
                        this._core.forceElementRequired(dateElementG);
                    }
                    let fileElementsG = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='$flexibleInstructionDaysExemplarsAppendixH']`);
                    if (fileElementsG.length < 0) {
                        showMessage = true;
                        let uploadElementG = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                        this._core.forceElementRequired(uploadElementG);
                    }
                    let dateElementH = document.querySelector(`.flexibleInstructionDaysExemplarsField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    if (dateElementH.value === "") {
                        showMessage = true;
                        this._core.forceElementRequired(dateElementH);
                    }
                    let fileElementsH = document.querySelectorAll(`.flexibleInstructionDaysExemplarsFile[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                    if (fileElementsH.length < 0) {
                        showMessage = true;
                        let uploadElementH = document.querySelector(`.flexibleInstructionDaysExemplarsFileField[data-exemplar='flexibleInstructionDaysExemplarsAppendixH']`);
                        this._core.forceElementRequired(uploadElementH);
                    }
                }
            }
        }
        let formattedAllClasses = [];
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsField");
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsRadioCTEField");
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsRadioExemplarsField");
        formattedAllClasses.push(".flexibleInstructionDaysExemplarsFileField");
        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    htmlElement.setAttribute("force-required", "true");
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                }
            }
        }
        let totalErrors = 0;
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (element instanceof HTMLInputElement) {
                    let inputElement = element;
                    if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                        if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "text" && inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                        showMessage = true;
                        totalErrors++;
                    }
                    else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "date" && inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                        showMessage = true;
                        totalErrors++;
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    firstFocusableEl.focus();
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
//Signatures & Assurances
class FlexibleInstructionalDaysSignaturesAndAssurances {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysSignaturesAndAssurancesField", "flexibleInstructionDaysSignaturesAndAssurancesNewFileField", "flexibleInstructionDaysSignaturesAndAssurancesCheckboxField"];
        let specialEducationProfileSaveButton = document.getElementById("flexibleInstructionDaysSignaturesAndAssurancesSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let boardMinutesUploads = document.getElementsByClassName("flexibleInstructionDaysFileUploadField");
        for (let boardMinute of boardMinutesUploads)
            boardMinute.addEventListener("change", (e) => this.uploadFile(e.target));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("flexibleInstructionDaysSignaturesAndAssurancesForm");
        let inputs = document.getElementsByClassName("flexibleInstructionDaysField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("flexibleInstructionDaysCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionalDaysSignaturesAndAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let uploadSampleForm = document.getElementById("flexibleInstructionDaysSignaturesBoardMinutesUploadForm");
        let formData = new FormData(uploadSampleForm);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/UploadFile', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully uploaded! Save your progress to see the updated list of files.", 'success', 3000, null);
                        let formFile = document.getElementById("flexibleInstructionDaysSignaturesBoardMinutesUpload");
                        formFile.value = "";
                        let uploadedFilesElement = document.getElementById("uploadedFiles");
                        uploadedFilesElement.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}
//Summary and Submission
class FlexibleInstructionalDaysSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        let withdrawPlanButton = document.getElementById("withdrawPlanButton");
        if (withdrawPlanButton !== null)
            withdrawPlanButton.addEventListener("click", (e) => this.withdrawPlan(e));
        let withdrawPlanConfirmButton = document.getElementById("withdrawPlanConfirm");
        if (withdrawPlanConfirmButton !== null)
            withdrawPlanConfirmButton.addEventListener("click", (e) => this.withdrawPlanConfirm(e));
        let withdrawPlanCancelButton = document.getElementById("withdrawPlanCancel");
        if (withdrawPlanCancelButton !== null)
            withdrawPlanCancelButton.addEventListener("click", (e) => this.withdrawPlanCancel());
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FlexibleInstructionalDays/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
    withdrawPlan(e) {
        let fileButton = e.target;
        let planFK = fileButton.dataset.planfk;
        let modal = new Modal("withdrawPlanModal", null);
        modal.addAttributeToElement("withdrawPlanModal", "#withdrawPlanConfirm", "planfk", planFK);
        modal.show();
    }
    withdrawPlanConfirm(e) {
        let confirmButton = e.target;
        let core = this._core;
        Core.showLoader();
        let planFK = confirmButton.dataset.planfk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/WithdrawPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The plan has been withdrawn.", 'success', 3000, null);
                        //back to report page
                        setTimeout(function () { window.location.href = "/Reports/StateRequiredReports"; }, 3000);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected withdrawing this plan.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("withdrawPlanModal", null);
            modal.hide();
        };
        xhr.send("planFK=" + planFK);
    }
    withdrawPlanCancel() {
        let modal = new Modal("withdrawPlanModal", null);
        modal.hide();
    }
}
// FlexibleInstructionDaysExELAK8
class FlexibleInstructionDaysExELAK8 {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];
        let flexibleInstructionDaysExELAK8SaveButton = document.getElementById("flexibleInstructionDaysExELAK8Save");
        if (flexibleInstructionDaysExELAK8SaveButton !== null)
            flexibleInstructionDaysExELAK8SaveButton.addEventListener("click", (e) => this.save("save"));
        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e) => this.checkForYes(e.target));
            this.checkForYes(radio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysELAK8AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e) => this.addStandardRow(e));
        this.bindDeletes();
        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExELAK8Form");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExELAK8', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteStandardRow(e));
    }
    showDeleteStandardRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteRowCancel() {
        let modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }
    addStandardRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("flexibleInstructionDaysExELAK8Form");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/ELAK8/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.innerHTML = output;
                const container = document.querySelector("#flexibleExemplarsStandards tbody");
                container.appendChild(newTR);
                that.bindDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    checkForYes(e) {
        const radio = e;
        if (radio.checked) {
            const value = radio.dataset.lookupcode;
            const exemplarInformationDiv = document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementRequired(thisEle);
                }
            }
            else {
                exemplarInformationDiv.classList.add("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}
// FlexibleInstructionDaysExELA912
class FlexibleInstructionDaysExELA912 {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];
        let flexibleInstructionDaysExELA912SaveButton = document.getElementById("flexibleInstructionDaysExELA912Save");
        if (flexibleInstructionDaysExELA912SaveButton !== null)
            flexibleInstructionDaysExELA912SaveButton.addEventListener("click", (e) => this.save("save"));
        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e) => this.checkForYes(e.target));
            this.checkForYes(radio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysELA912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e) => this.addStandardRow(e));
        this.bindDeletes();
        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExELA912Form");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExELA912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteStandardRow(e));
    }
    showDeleteStandardRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteRowCancel() {
        let modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }
    addStandardRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("flexibleInstructionDaysExELA912Form");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/ELA912/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.innerHTML = output;
                const container = document.querySelector("#flexibleExemplarsStandards tbody");
                container.appendChild(newTR);
                that.bindDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    checkForYes(e) {
        const radio = e;
        if (radio.checked) {
            const value = radio.dataset.lookupcode;
            const exemplarInformationDiv = document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementRequired(thisEle);
                }
            }
            else {
                exemplarInformationDiv.classList.add("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}
// FlexibleInstructionDaysExMathK8
class FlexibleInstructionDaysExMathK8 {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];
        let flexibleInstructionDaysExMathK8SaveButton = document.getElementById("flexibleInstructionDaysExMathK8Save");
        if (flexibleInstructionDaysExMathK8SaveButton !== null)
            flexibleInstructionDaysExMathK8SaveButton.addEventListener("click", (e) => this.save("save"));
        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e) => this.checkForYes(e.target));
            this.checkForYes(radio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysMathK8AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e) => this.addStandardRow(e));
        this.bindDeletes();
        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExMathK8Form");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExMathK8', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteStandardRow(e));
    }
    showDeleteStandardRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteRowCancel() {
        let modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }
    addStandardRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("flexibleInstructionDaysExMathK8Form");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/MathK8/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.innerHTML = output;
                const container = document.querySelector("#flexibleExemplarsStandards tbody");
                container.appendChild(newTR);
                that.bindDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    checkForYes(e) {
        const radio = e;
        if (radio.checked) {
            const value = radio.dataset.lookupcode;
            const exemplarInformationDiv = document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementRequired(thisEle);
                }
            }
            else {
                exemplarInformationDiv.classList.add("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}
// FlexibleInstructionDaysExMath912
class FlexibleInstructionDaysExMath912 {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];
        let flexibleInstructionDaysExMath912SaveButton = document.getElementById("flexibleInstructionDaysExMath912Save");
        if (flexibleInstructionDaysExMath912SaveButton !== null)
            flexibleInstructionDaysExMath912SaveButton.addEventListener("click", (e) => this.save("save"));
        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e) => this.checkForYes(e.target));
            this.checkForYes(radio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysMath912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e) => this.addStandardRow(e));
        this.bindDeletes();
        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExMath912Form");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExMath912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteStandardRow(e));
    }
    showDeleteStandardRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteRowCancel() {
        let modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }
    addStandardRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("flexibleInstructionDaysExMath912Form");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/Math912/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.innerHTML = output;
                const container = document.querySelector("#flexibleExemplarsStandards tbody");
                container.appendChild(newTR);
                that.bindDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    checkForYes(e) {
        const radio = e;
        if (radio.checked) {
            const value = radio.dataset.lookupcode;
            const exemplarInformationDiv = document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementRequired(thisEle);
                }
            }
            else {
                exemplarInformationDiv.classList.add("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}
// FlexibleInstructionDaysExScience912
class FlexibleInstructionDaysExScience912 {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];
        let flexibleInstructionDaysExScience912SaveButton = document.getElementById("flexibleInstructionDaysExScience912Save");
        if (flexibleInstructionDaysExScience912SaveButton !== null)
            flexibleInstructionDaysExScience912SaveButton.addEventListener("click", (e) => this.save("save"));
        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e) => this.checkForYes(e.target));
            this.checkForYes(radio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysScience912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e) => this.addStandardRow(e));
        this.bindDeletes();
        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExScience912Form");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExScience912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteStandardRow(e));
    }
    showDeleteStandardRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteRowCancel() {
        let modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }
    addStandardRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("flexibleInstructionDaysExScience912Form");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/Science912/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.innerHTML = output;
                const container = document.querySelector("#flexibleExemplarsStandards tbody");
                container.appendChild(newTR);
                that.bindDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    checkForYes(e) {
        const radio = e;
        if (radio.checked) {
            const value = radio.dataset.lookupcode;
            const exemplarInformationDiv = document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementRequired(thisEle);
                }
            }
            else {
                exemplarInformationDiv.classList.add("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}
// FlexibleInstructionDaysExSocial912
class FlexibleInstructionDaysExSocial912 {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField"];
        let flexibleInstructionDaysExSocial912SaveButton = document.getElementById("flexibleInstructionDaysExSocial912Save");
        if (flexibleInstructionDaysExSocial912SaveButton !== null)
            flexibleInstructionDaysExSocial912SaveButton.addEventListener("click", (e) => this.save("save"));
        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e) => this.checkForYes(e.target));
            this.checkForYes(radio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const flexibleInstructionalDaysELAK8AddStandardButton = document.getElementById("flexibleInstructionalDaysSocial912AddStandard");
        if (flexibleInstructionalDaysELAK8AddStandardButton !== null)
            flexibleInstructionalDaysELAK8AddStandardButton.addEventListener("click", (e) => this.addStandardRow(e));
        this.bindDeletes();
        const flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysELAK8StandardRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExSocial912Form");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExSocial912', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteStandardRow(e));
    }
    showDeleteStandardRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsStandards tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteRowCancel() {
        let modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }
    addStandardRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("flexibleInstructionDaysExSocial912Form");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#flexibleExemplarsStandards tbody tr");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/Social912/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.innerHTML = output;
                const container = document.querySelector("#flexibleExemplarsStandards tbody");
                container.appendChild(newTR);
                that.bindDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    checkForYes(e) {
        const radio = e;
        if (radio.checked) {
            const value = radio.dataset.lookupcode;
            const exemplarInformationDiv = document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementRequired(thisEle);
                }
            }
            else {
                exemplarInformationDiv.classList.add("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}
// FlexibleInstructionDaysExCTE
class FlexibleInstructionDaysExCTE {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysExField", "flexibleInstructionDaysExRadioField", "flexibleInstructionDaysExCheckboxField"];
        let flexibleInstructionDaysExCTESaveButton = document.getElementById("flexibleInstructionDaysExCTESave");
        if (flexibleInstructionDaysExCTESaveButton !== null)
            flexibleInstructionDaysExCTESaveButton.addEventListener("click", (e) => this.save("save"));
        const radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (const radio of radios) {
            radio.addEventListener("change", (e) => this.checkForYes(e.target));
            this.checkForYes(radio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const flexibleInstructionalDaysAddStandardButtons = document.getElementsByClassName("flexibleInstructionalDaysAddStandard");
        for (const flexibleInstructionalDaysAddStandardButton of flexibleInstructionalDaysAddStandardButtons)
            flexibleInstructionalDaysAddStandardButton.addEventListener("click", (e) => this.addStandardRow(e));
        const flexibleInstructionalDaysAddTaskButtons = document.getElementsByClassName("flexibleInstructionalDaysAddTask");
        for (const flexibleInstructionalDaysAddTaskButton of flexibleInstructionalDaysAddTaskButtons)
            flexibleInstructionalDaysAddTaskButton.addEventListener("click", (e) => this.addTaskRow(e));
        this.bindStandardDeletes();
        this.bindTaskDeletes();
        const flexibleInstructionalDaysStandardRowDeleteCancelButton = document.getElementById("flexibleExemplarsStandardRowDeleteCancel");
        if (flexibleInstructionalDaysStandardRowDeleteCancelButton !== null)
            flexibleInstructionalDaysStandardRowDeleteCancelButton.addEventListener("click", (e) => this.deleteStandardRowCancel());
        const flexibleInstructionalDaysStandardRowDeleteConfirmButton = document.getElementById("flexibleExemplarsStandardRowDeleteConfirm");
        if (flexibleInstructionalDaysStandardRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysStandardRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteStandardRowConfirm(e));
        const flexibleInstructionalDaysTaskRowDeleteCancelButton = document.getElementById("flexibleExemplarsTaskRowDeleteCancel");
        if (flexibleInstructionalDaysTaskRowDeleteCancelButton !== null)
            flexibleInstructionalDaysTaskRowDeleteCancelButton.addEventListener("click", (e) => this.deleteTaskRowCancel());
        const flexibleInstructionalDaysTaskRowDeleteConfirmButton = document.getElementById("flexibleExemplarsTaskRowDeleteConfirm");
        if (flexibleInstructionalDaysTaskRowDeleteConfirmButton !== null)
            flexibleInstructionalDaysTaskRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteTaskRowConfirm(e));
        initializeAllNA();
        initializeAllYes();
        initializeAllNo();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("flexibleInstructionDaysExCTEForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("flexibleInstructionDaysExField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("flexibleInstructionDaysExRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let checkboxes = document.getElementsByClassName("flexibleInstructionDaysExCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/SaveFlexibleInstructionDaysExCTE', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindStandardDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarStandardRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteStandardRow(e));
    }
    showDeleteStandardRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let exemplar = buttonElement.dataset.exemplar;
        let type = buttonElement.dataset.type;
        let modal = new Modal("deleteStandardRowModal", null);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "exemplar", exemplar);
        modal.addAttributeToElement("deleteStandardRowModal", "#flexibleExemplarsStandardRowDeleteConfirm", "type", type);
        modal.show();
    }
    deleteStandardRowConfirm(e) {
        const buttonElement = e.target;
        let planProps = [];
        const row = buttonElement.dataset.row;
        const exemplar = buttonElement.dataset.exemplar;
        const type = buttonElement.dataset.type;
        const allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}'][data-exemplar='${exemplar}'][data-type='${type}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsStandards${exemplar} tbody tr[data-row='${row}'][data-type='${type}']`);
                rowElement.remove();
                let modal = new Modal("deleteStandardRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteStandardRowCancel() {
        let modal = new Modal("deleteStandardRowModal", null);
        modal.hide();
    }
    addStandardRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let formElement = document.getElementById("flexibleInstructionDaysExCTEForm");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            const buttonElement = e.target;
            const exemplar = buttonElement.dataset.exemplar;
            let allRows = document.querySelectorAll(`#flexibleExemplarsStandards${exemplar} tbody tr`);
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddStandard/${planFK}/${pageCode}/CTE/${newRow}/${exemplar}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.dataset.type = "Standard";
                newTR.classList.add("flexibleInstructionalDaysStandards");
                newTR.innerHTML = output;
                const container = document.querySelector(`#flexibleExemplarsStandards${exemplar} tbody`);
                container.appendChild(newTR);
                that.bindStandardDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Standard has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    bindTaskDeletes() {
        const allDeleteRows = document.getElementsByClassName("deleteFlexibleInstructionExemplarTaskRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteTaskRow(e));
    }
    showDeleteTaskRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let exemplar = buttonElement.dataset.exemplar;
        let type = buttonElement.dataset.type;
        let modal = new Modal("deleteTaskRowModal", null);
        modal.addAttributeToElement("deleteTaskRowModal", "#flexibleExemplarsTaskRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteTaskRowModal", "#flexibleExemplarsTaskRowDeleteConfirm", "exemplar", exemplar);
        modal.addAttributeToElement("deleteTaskRowModal", "#flexibleExemplarsTaskRowDeleteConfirm", "type", type);
        modal.show();
    }
    deleteTaskRowConfirm(e) {
        const buttonElement = e.target;
        let planProps = [];
        const row = buttonElement.dataset.row;
        const exemplar = buttonElement.dataset.exemplar;
        const type = buttonElement.dataset.type;
        const allElements = document.querySelectorAll(`.flexibleInstructionDaysExField[data-row='${row}'][data-exemplar='${exemplar}'][data-type='${type}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FlexibleInstructionalDays/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#flexibleExemplarsTasks${exemplar} tbody tr[data-row='${row}'][data-type='${type}']`);
                rowElement.remove();
                let modal = new Modal("deleteTaskRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteTaskRowCancel() {
        let modal = new Modal("deleteTaskRowModal", null);
        modal.hide();
    }
    addTaskRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let formElement = document.getElementById("flexibleInstructionDaysExCTEForm");
            const planFK = formElement.dataset.planfk;
            const that = this;
            let newRow = 0;
            const buttonElement = e.target;
            const exemplar = buttonElement.dataset.exemplar;
            let allRows = document.querySelectorAll(`#flexibleExemplarsTasks${exemplar} tbody tr`);
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            const button = e.target;
            const pageCode = button.dataset.pagecode;
            let response = yield fetch(`/FlexibleInstructionalDays/AddTask/${planFK}/${pageCode}/CTE/${exemplar}/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.dataset.type = "Task";
                newTR.classList.add("flexibleInstructionalDaysTasks");
                newTR.innerHTML = output;
                const container = document.querySelector(`#flexibleExemplarsTasks${exemplar} tbody`);
                container.appendChild(newTR);
                that.bindTaskDeletes();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The PA Academic Task has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    checkForYes(e) {
        const radio = e;
        if (radio.checked) {
            const value = radio.dataset.lookupcode;
            const exemplarInformationDiv = document.getElementById("exemplarInformation");
            if (value === "yes") {
                exemplarInformationDiv.classList.remove("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementRequired(thisEle);
                }
            }
            else {
                exemplarInformationDiv.classList.add("hide");
                const allElements = document.getElementsByClassName("flexibleInstructionDaysExField");
                for (const ele of allElements) {
                    let thisEle = ele;
                    this._core.forceElementOptional(thisEle);
                }
            }
        }
    }
}
function initializeAllNA() {
    let buttons = document.querySelectorAll(".notApplicableFIDS[data-action-group-code-fk]");
    for (let btn of buttons) {
        let btnEl = btn;
        btnEl.addEventListener("click", () => {
            let compliantActionsNA = document.querySelectorAll(`[data-action-type='componentCompliantNA'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionNA']`);
            for (let compliantAction of compliantActionsNA) {
                let compliantActionElement = compliantAction;
                compliantActionElement.click();
            }
        });
    }
}
function initializeAllYes() {
    let buttons = document.querySelectorAll(".allYesFIDS[data-action-group-code-fk]");
    for (let btn of buttons) {
        let btnEl = btn;
        btnEl.addEventListener("click", () => {
            let compliantActionsYes = document.querySelectorAll(`[data-action-type='componentCompliantNA'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionYes'], [data-action-type='componentCompliant'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionYes']`);
            for (let compliantAction of compliantActionsYes) {
                let compliantActionElement = compliantAction;
                compliantActionElement.click();
            }
        });
    }
}
function initializeAllNo() {
    let buttons = document.querySelectorAll(".allNoFIDS[data-action-group-code-fk]");
    for (let btn of buttons) {
        let btnEl = btn;
        btnEl.addEventListener("click", () => {
            let compliantActionsNo = document.querySelectorAll(`[data-action-type='componentCompliantNA'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionNo'], [data-action-type='componentCompliant'][data-action-group-code-fk='${btnEl.dataset.actionGroupCodeFk}'][data-lookup-code='actionNo']`);
            for (let compliantAction of compliantActionsNo) {
                let compliantActionElement = compliantAction;
                compliantActionElement.click();
            }
        });
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// Graduation Pathways Profile
class GraduationReportGraduationPathwaysProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["graduationReportProfileField"];
        let graduationPathwaysProfileSaveButton = document.getElementById("graduationReportProfileSave");
        if (graduationPathwaysProfileSaveButton !== null)
            graduationPathwaysProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        this.initializeRequiredFieldsCustom(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let graduationReportProfileGrade12EnrollmentElement = document.getElementById("graduationReportProfileGrade12Enrollment");
        if (graduationReportProfileGrade12EnrollmentElement !== null)
            graduationReportProfileGrade12EnrollmentElement.addEventListener("input", (e) => this.checkEnrollment());
        let graduationReportProfileGrade12NonGraduationElement = document.getElementById("graduationReportProfileGrade12NonGraduation");
        if (graduationReportProfileGrade12NonGraduationElement !== null)
            graduationReportProfileGrade12NonGraduationElement.addEventListener("input", (e) => this.checkEnrollment());
        let graduationReportProfileGrade12GraduationElement = document.getElementById("graduationReportProfileGrade12Graduation");
        if (graduationReportProfileGrade12GraduationElement !== null)
            graduationReportProfileGrade12GraduationElement.addEventListener("input", (e) => this.checkEnrollment());
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("graduationReportProfileForm"));
        const graduationReportPathwaysData = document.getElementById("graduationReportPathwaysData");
        if (graduationReportPathwaysData !== null)
            graduationReportPathwaysData.addEventListener("click", (e) => this.showGetDataFromPathways(e));
        const getPathwaysDataConfirm = document.getElementById("getPathwaysDataConfirm");
        if (getPathwaysDataConfirm !== null)
            getPathwaysDataConfirm.addEventListener("click", (e) => this.getDataFromPathways(e));
        const getPathwaysDataCancel = document.getElementById("getPathwaysDataCancel");
        if (getPathwaysDataCancel !== null)
            getPathwaysDataCancel.addEventListener("click", (e) => this.closeGetDataFromPathways());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("graduationReportProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("graduationReportProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportGraduationPathwaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("graduationReportProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportGraduationPathwaysProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkEnrollment() {
        let grade12Enrollment = 0;
        let grade12Grad = 0;
        let isEmpty = false;
        let graduationReportProfileGrade12EnrollmentElement = document.getElementById("graduationReportProfileGrade12Enrollment");
        if (graduationReportProfileGrade12EnrollmentElement !== null)
            grade12Enrollment = parseInt(graduationReportProfileGrade12EnrollmentElement.value);
        let graduationReportProfileGrade12NonGraduationElement = document.getElementById("graduationReportProfileGrade12NonGraduation");
        let graduationReportProfileGrade12GraduationElement = document.getElementById("graduationReportProfileGrade12Graduation");
        if (graduationReportProfileGrade12GraduationElement !== null) {
            if (graduationReportProfileGrade12GraduationElement.value === "" || graduationReportProfileGrade12GraduationElement.value === null)
                isEmpty = true;
            else
                grade12Grad = parseInt(graduationReportProfileGrade12GraduationElement.value);
        }
        if (!isEmpty) {
            const eligibleErrorElement = document.getElementById("enrollmentErrorEligibleToGraduate");
            const enrollmentErrorElement = document.getElementById("enrollmentErrorGraduated");
            if (!isNaN(grade12Enrollment)) {
                if (grade12Enrollment >= 0) {
                    eligibleErrorElement.classList.add("hide");
                    graduationReportProfileGrade12EnrollmentElement.classList.remove("missing-field");
                }
                else {
                    eligibleErrorElement.classList.remove("hide");
                    graduationReportProfileGrade12EnrollmentElement.classList.add("missing-field");
                }
            }
            if (!isNaN(grade12Grad) && !isNaN(grade12Enrollment)) {
                if (grade12Grad >= 0 && grade12Grad <= grade12Enrollment) {
                    enrollmentErrorElement.classList.add("hide");
                    graduationReportProfileGrade12GraduationElement.classList.remove("missing-field");
                }
                else {
                    enrollmentErrorElement.classList.remove("hide");
                    graduationReportProfileGrade12GraduationElement.classList.add("missing-field");
                }
            }
            if (!isNaN(grade12Grad) && !isNaN(grade12Enrollment)) {
                const nonGrads = grade12Enrollment - grade12Grad;
                graduationReportProfileGrade12NonGraduationElement.value = nonGrads.toString();
            }
        }
        else
            graduationReportProfileGrade12NonGraduationElement.value = "";
    }
    initializeRequiredFieldsCustom(validationClasses) {
        this._core.initializeRequiredFields(this.validationClasses);
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = element;
                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");
                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated = false;
                                    let isChecked = false;
                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }
                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }
                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            }
                            else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLSelectElement) {
                            let selectElement = element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                            let hasSelection = false;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }
                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        this.checkEnrollment();
        let graduationReportProfileGrade12EnrollmentElement = document.getElementById("graduationReportProfileGrade12Enrollment");
        let graduationReportProfileGrade12GraduationElement = document.getElementById("graduationReportProfileGrade12Graduation");
        const eligibleErrorElement = document.getElementById("enrollmentErrorEligibleToGraduate");
        const enrollmentErrorElement = document.getElementById("enrollmentErrorGraduated");
        if (eligibleErrorElement) {
            if (!eligibleErrorElement.classList.contains("hide")) {
                totalErrors++;
                showMessage = true;
                Core.createErrorLabelForInput(graduationReportProfileGrade12EnrollmentElement);
            }
        }
        if (enrollmentErrorElement) {
            if (!enrollmentErrorElement.classList.contains("hide")) {
                totalErrors++;
                showMessage = true;
                Core.createErrorLabelForInput(graduationReportProfileGrade12GraduationElement);
            }
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    showGetDataFromPathways(e) {
        const button = e.target;
        const planFK = button.dataset.planfk;
        const modal = new Modal("getPathwaysDataModal", null);
        let getDataConfirmButton = document.getElementById("getPathwaysDataConfirm");
        getDataConfirmButton.dataset.planfk = planFK;
        modal.show();
    }
    closeGetDataFromPathways() {
        const modal = new Modal("getPathwaysDataModal", null);
        modal.hide();
    }
    getDataFromPathways(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLongLoader();
            const button = e.target;
            const planFK = button.dataset.planfk;
            let response = yield fetch(`/GraduationReport/GetPathwaysData/${planFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.json();
                if (output.success === true && output.pathwaysHasData === true) {
                    Core.hideLongLoader();
                    this.closeGetDataFromPathways();
                    Core.createHTMLAlert("alertMessageDiv", "Data from the Pathways to Graduation tool has been imported. You will need to verify each page for correct data.", "success", 3000, null);
                    this._core.pageReload(false, parseInt(planFK));
                }
                else if (output.success === true && output.pathwaysHasData === false) {
                    Core.hideLongLoader();
                    this.closeGetDataFromPathways();
                    Core.createHTMLAlert("alterMessageDiv", "There wasn't any data from the Pathways to Graduation tool.", "warning", 3000, null);
                    const modal = new Modal("getPathwaysDataModal", null);
                    modal.hide();
                }
                else {
                    Core.hideLongLoader();
                    this.closeGetDataFromPathways();
                    Core.createHTMLAlert("alterMessageDiv", "There was an issue gathering the data from the Pathways to Graduation tool. Please try again.", "error", 3000, null);
                    const modal = new Modal("getPathwaysDataModal", null);
                    modal.hide();
                }
            }
            else {
                Core.hideLongLoader();
                Core.createHTMLAlert("alterMessageDiv", "There was an issue gathering the data from the Pathways to Graduation tool. Please try again.", "error", 3000, null);
                const modal = new Modal("getPathwaysDataModal", null);
                modal.hide();
            }
        });
    }
}
// Pathways
class GraduationReportPathways {
    constructor() {
        let that = this;
        this._core = new Core();
        this.validationClasses = ["graduationReportPathwaysField"];
        let graduationPathwaySaveButton = document.getElementById("graduationReportPathwaysSave");
        if (graduationPathwaySaveButton !== null)
            graduationPathwaySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const graduationReportWaiversStudentinGrade12Element = document.getElementById("graduationReportWaiversStudentinGrade12");
        graduationReportWaiversStudentinGrade12Element.addEventListener("input", (e) => this.checkMoreThanFivePercent());
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const allSlideouts = document.getElementsByClassName("showPathwaySlideout");
        for (const slideout of allSlideouts)
            this.bindSlideout(slideout);
        this.checkMoreThanFivePercent();
        let fromSaveElement = document.getElementById("fromSave");
        this.initializeRequiredFieldsCustom(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.calculateTotal();
            this.calculateAlternativePathways();
            this.calculateEvidencePathwaySectionOnes();
            this.calculateEvidencePathwaySectionTwos();
            this.allGraduateValidation();
            this.allSectionOneAndTwoValidation();
            this.extenuatingCircumstance();
            this.doValidationCustom(this.validationClasses);
        }
        const allGraduatesFields = document.getElementsByClassName("graduationReportPathwaysCalculatedAverageField");
        for (let graduatesField of allGraduatesFields) {
            graduatesField.addEventListener("input", function () {
                that.graduateValidation(graduatesField);
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let allCheckboxSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("graduationReportPathwaysForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("graduationReportPathwaysField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportPathways', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.createHTMLAlert("alertMessageDiv", "Page saved successfully! Please wait.", "success", 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    initializeRequiredFieldsCustom(validationClasses) {
        this._core.initializeRequiredFields(validationClasses);
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!element.classList.contains("missing-field")) {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = element;
                        if (inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
        }
        const totalGraduatesElement = document.getElementById("graduatedTotalCount");
        const totalGraduates = parseInt(totalGraduatesElement.value);
        if (!isNaN(totalGraduates)) {
            //We need to check several rules here:
            //1. The total of all graduates (for all pathways) (class="graduationReportPathwaysNumberGraduated") is EQUAL to the total graduates listed in the profile. This must include the "pathways" from the Waivers page.
            //2. The total of all graduates in the alternative assessment pathway breakdown (class = "graduationReportPathwaysAlternativePathway") is EQUAL to the total graduates for the pathway (id="graduationReportPathwaysAlternativeGrade12Graduated").
            //3. The total of all graduates in the evidence based pathway breakdown section one (class = "graduationReportPathwaysEvidencePathwaySectionOne") is EQUAL Or Greater than to the total graduates for the pathway (id="graduationReportPathwaysEvidenceGrade12Graduated"").
            //4. The total of all graduates in the evidence based pathway breakdown sections one and two (classes = "graduationReportPathwaysEvidencePathwaySectionOne, graduationReportPathwaysEvidencePathwaySectionTwo") are not more than 3x the total graduates for the pathway.
            //5. The total of all graduates in the extenuating circumstances breakdown (class = "graduationReportWaiverExtenuating") is less than or equal to the total graduates for the pathway (id="graduationReportPathwaysWaiverGrade12Graduated").
            //1. 
            const allGradTotals = document.getElementsByClassName("graduationReportPathwaysNumberGraduated");
            let totalGrads = 0;
            for (const grad of allGradTotals) {
                const ele = grad;
                let val = parseInt(ele.value);
                if (!isNaN(val))
                    totalGrads += val;
            }
            if (totalGrads !== totalGraduates) {
                totalErrors++;
                showMessage = true;
                for (const grad of allGradTotals) {
                    const ele = grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }
            //2.
            const alternativeGradsElement = document.getElementById("graduationReportPathwaysAlternativeGrade12Graduated");
            const totalAlternativeGrads = parseInt(alternativeGradsElement.value);
            const alternativeBreakdowns = document.getElementsByClassName("graduationReportPathwaysAlternativePathway");
            let totalAlternativeBreakdown = 0;
            for (const alternative of alternativeBreakdowns) {
                const ele = alternative;
                const val = parseInt(ele.value);
                if (!isNaN(val))
                    totalAlternativeBreakdown += val;
            }
            if (totalAlternativeBreakdown !== totalAlternativeGrads) {
                totalErrors++;
                showMessage = true;
                for (const grad of alternativeBreakdowns) {
                    const ele = grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }
            //3.
            const evidenceGradsElement = document.getElementById("graduationReportPathwaysEvidenceGrade12Graduated");
            const totalEvidenceGrads = parseInt(evidenceGradsElement.value);
            const evidenceBreakdowns = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionOne");
            let totalEvidenceBreakdown = 0;
            for (const evidence of evidenceBreakdowns) {
                const ele = evidence;
                const val = parseInt(ele.value);
                if (!isNaN(val)) {
                    totalEvidenceBreakdown += val;
                    if (val > totalEvidenceGrads) {
                        Core.createErrorLabelForInput(ele);
                        ele.classList.add("missing-field");
                        ele.setAttribute("aria-invalid", "true");
                        totalErrors++;
                        showMessage = true;
                    }
                }
            }
            if (totalEvidenceBreakdown < totalEvidenceGrads) {
                totalErrors++;
                showMessage = true;
                for (const grad of evidenceBreakdowns) {
                    const ele = grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }
            //4.
            const evidenceSectionTwoBreakdowns = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionTwo");
            let totalSectionTwoBreakdowns = 0;
            for (const evidenceSectionTwo of evidenceSectionTwoBreakdowns) {
                const ele = evidenceSectionTwo;
                const val = parseInt(ele.value);
                if (!isNaN(val))
                    totalSectionTwoBreakdowns += val;
            }
            const totalOfTwoSections = totalSectionTwoBreakdowns + totalEvidenceBreakdown;
            if (totalOfTwoSections > (3 * totalEvidenceGrads)) {
                showMessage = true;
                totalErrors++;
                for (const grad of [...evidenceBreakdowns, ...evidenceSectionTwoBreakdowns]) {
                    const ele = grad;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
            }
            //5.
            const waiverGradsElement = document.getElementById("graduationReportPathwaysWaiverGrade12Graduated");
            const totalWaiverGrads = parseInt(waiverGradsElement.value);
            const waiverBreakdowns = document.getElementsByClassName("graduationReportWaiverExtenuating");
            let totalWaiverBreakdown = 0;
            for (const waiver of waiverBreakdowns) {
                const ele = waiver;
                const val = parseInt(ele.value);
                if (!isNaN(val))
                    totalWaiverBreakdown += val;
            }
            if (totalWaiverBreakdown !== totalWaiverGrads) {
                totalErrors++;
                showMessage = true;
                for (const waiver of waiverBreakdowns) {
                    const ele = waiver;
                    Core.createErrorLabelForInput(ele);
                    ele.classList.add("missing-field");
                    ele.setAttribute("aria-invalid", "true");
                }
                Core.createErrorLabelForInput(waiverGradsElement);
                waiverGradsElement.classList.add("missing-field");
                waiverGradsElement.setAttribute("aria-invalid", "true");
            }
        }
        const message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    allGraduateValidation() {
        const allGraduatesFields = document.getElementsByClassName("graduationReportPathwaysCalculatedAverageField");
        for (let graduatesField of allGraduatesFields)
            this.graduateValidation(graduatesField);
    }
    graduateValidation(element) {
        let elementId = element.id;
        let graduatedElementId = element.dataset.graduated;
        if (elementId === graduatedElementId) {
            let graduatedValue = 0;
            let enrollmentValue = 0;
            let enrollmentElement = document.getElementById("graduatedTotalCount");
            if (enrollmentElement && enrollmentElement.value !== "") {
                enrollmentValue = parseInt(enrollmentElement.value);
            }
            let graduatedElement = element;
            if (graduatedElement && graduatedElement.value !== "") {
                graduatedValue = parseInt(graduatedElement.value);
            }
            if (graduatedValue > enrollmentValue) {
                graduatedElement.classList.add("missing-field");
                let errorElement = document.querySelector(`.graduatedError[data-graduated='${elementId}']`);
                if (errorElement)
                    errorElement.classList.remove("hide");
            }
            else {
                let errorElement = document.querySelector(`.graduatedError[data-graduated='${elementId}']`);
                if (errorElement)
                    errorElement.classList.add("hide");
                graduatedElement.classList.remove("missing-field");
            }
        }
        this.calculatePercentage(element);
    }
    calculatePercentage(element) {
        const graduatedElementId = element.dataset.graduated;
        const enrollmentElementId = element.dataset.enrollment;
        const percentageElementId = element.dataset.average;
        const graduatedElement = document.getElementById(graduatedElementId);
        const enrollmentElement = document.getElementById(enrollmentElementId);
        const percentageElement = document.getElementById(percentageElementId);
        if (graduatedElement && enrollmentElement && percentageElement) {
            const graduatedValue = parseFloat(graduatedElement.value);
            const enrollmentValue = parseFloat(enrollmentElement.value);
            let percentageValue = parseFloat(percentageElement.value);
            if (!isNaN(enrollmentValue)) {
                const calc = (graduatedValue / enrollmentValue) * 100;
                percentageValue = parseFloat(calc.toFixed(2));
            }
            if (isNaN(percentageValue))
                percentageValue = 0;
            percentageElement.value = percentageValue.toString();
        }
    }
    calculateAlternativePathways() {
        const graduationReportPathwaysAlternativePathwayFields = document.getElementsByClassName("graduationReportPathwaysAlternativePathway");
        let total = 0;
        for (const graduationReportPathwaysAlternativePathway of graduationReportPathwaysAlternativePathwayFields) {
            const element = graduationReportPathwaysAlternativePathway;
            const value = parseInt(element.value);
            if (!isNaN(value))
                total += value;
        }
        const totalGradsElement = document.getElementById("graduationReportPathwaysAlternativeGrade12Graduated");
        const totalGradsValue = parseInt(totalGradsElement.value);
        const alternativeAssessmentPathwayGraduatedErrorElement = document.getElementById("alternativeAssessmentPathwayGraduatedError");
        if (!isNaN(totalGradsValue)) {
            if (total !== totalGradsValue) {
                alternativeAssessmentPathwayGraduatedErrorElement.classList.remove("hide");
                for (const graduationReportPathwaysAlternativePathway of graduationReportPathwaysAlternativePathwayFields) {
                    graduationReportPathwaysAlternativePathway.classList.add("missing-field");
                }
            }
            else {
                alternativeAssessmentPathwayGraduatedErrorElement.classList.add("hide");
                for (const graduationReportPathwaysAlternativePathway of graduationReportPathwaysAlternativePathwayFields) {
                    graduationReportPathwaysAlternativePathway.classList.remove("missing-field");
                }
            }
        }
    }
    calculateEvidencePathwaySectionOnes() {
        const graduationReportPathwaysEvidencePathwayFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionOne");
        let total = 0;
        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
            const element = graduationReportPathwaysEvidencePathway;
            const value = parseInt(element.value);
            if (!isNaN(value))
                total += value;
        }
        const totalGradsElement = document.getElementById("graduationReportPathwaysEvidenceGrade12Graduated");
        const totalGradsValue = parseInt(totalGradsElement.value);
        const evidenceAssessmentPathwayGraduatedErrorElement = document.getElementById("evidenceAssessmentPathwayGraduatedError");
        if (!isNaN(totalGradsValue)) {
            if (total < totalGradsValue) {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.remove("hide");
                for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
                    graduationReportPathwaysEvidencePathway.classList.add("missing-field");
                }
            }
            else {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.add("hide");
                for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
                    graduationReportPathwaysEvidencePathway.classList.remove("missing-field");
                }
            }
        }
        const sectionOneOnlyGreaterThanValidationError = document.getElementById("sectionOneOnlyGreaterThanValidationError");
        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
            const element = graduationReportPathwaysEvidencePathway;
            const value = parseInt(element.value);
            if (!isNaN(value)) {
                if (value > totalGradsValue)
                    sectionOneOnlyGreaterThanValidationError.classList.remove("hide");
            }
        }
        this.calculateEvidenceBothSections();
    }
    calculateEvidencePathwaySectionTwos() {
        this.calculateEvidenceBothSections();
    }
    sectionOneAndTwoInputValidation(e) {
        const element = e;
        const totalGrads = document.getElementById("graduatedTotalCount");
        const totalGradsValue = parseInt(totalGrads.value);
        const thisFieldValue = parseInt(element.value);
        const sectionOneAndTwoValidationError = document.getElementById("sectionOneAndTwoValidationError");
        if (totalGradsValue < thisFieldValue) {
            sectionOneAndTwoValidationError.classList.remove("hide");
        }
        else {
            sectionOneAndTwoValidationError.classList.add("hide");
        }
    }
    calculateEvidenceBothSections() {
        const graduationReportPathwaysEvidencePathwayFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathway");
        let total = 0;
        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields) {
            const element = graduationReportPathwaysEvidencePathway;
            const value = parseInt(element.value);
            if (!isNaN(value))
                total += value;
        }
        const totalGradsElement = document.getElementById("graduationReportPathwaysEvidenceGrade12Graduated");
        const totalGradsValue = parseInt(totalGradsElement.value);
        const evidenceAssessmentPathwayGraduatedErrorElement = document.getElementById("evidenceAssessmentPathwayGraduatedCombinedError");
        if (!isNaN(totalGradsValue)) {
            const highestGradsValue = totalGradsValue * 3;
            if (total > highestGradsValue) {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.remove("hide");
            }
            else {
                evidenceAssessmentPathwayGraduatedErrorElement.classList.add("hide");
            }
        }
    }
    bindSlideout(button) {
        const slideoutId = button.dataset.slideout;
        const callingId = button.id;
        const slideout = new Slideout(slideoutId, callingId);
        button.addEventListener("click", (e) => slideout.show());
        button.addEventListener("keypress", (e) => { if (Core.a11yClick(e)) {
            slideout.show();
        } });
    }
    calculateTotal() {
        const calculateTotalFields = document.getElementsByClassName("graduationReportPathwaysNumberGraduated");
        let total = 0;
        for (const totalField of calculateTotalFields) {
            const element = totalField;
            const thisVal = parseInt(element.value);
            if (!isNaN(thisVal))
                total += thisVal;
        }
        if (total > 0) {
            const graduatedTotalCountElement = document.getElementById("graduatedTotalCount");
            const graduatedTotalCount = parseInt(graduatedTotalCountElement.value);
            const graduationExceedsEnrollmentErrorElement = document.getElementById("graduationExceedsEnrollmentError");
            if (total !== graduatedTotalCount) {
                graduationExceedsEnrollmentErrorElement.classList.remove("hide");
                for (const totalField of calculateTotalFields) {
                    totalField.classList.add("missing-field");
                }
            }
            else {
                graduationExceedsEnrollmentErrorElement.classList.add("hide");
                for (const totalField of calculateTotalFields) {
                    totalField.classList.remove("missing-field");
                }
            }
        }
    }
    checkMoreThanFivePercent() {
        const graduationReportWaiversStudentinGrade12Element = document.getElementById("graduationReportWaiversStudentinGrade12");
        const graduationReportWaiversStudentinGrade12Value = parseInt(graduationReportWaiversStudentinGrade12Element.value);
        const graduationReportPathwaysWaiverGrade12EnrollmentElement = document.getElementById("graduationReportPathwaysWaiverGrade12Enrollment");
        const graduationReportPathwaysWaiverGrade12EnrollmentValue = parseInt(graduationReportPathwaysWaiverGrade12EnrollmentElement.value);
        if (!isNaN(graduationReportWaiversStudentinGrade12Value) && !isNaN(graduationReportPathwaysWaiverGrade12EnrollmentValue)) {
            const percent = graduationReportWaiversStudentinGrade12Value / graduationReportPathwaysWaiverGrade12EnrollmentValue;
            const allOverFiveFields = document.getElementsByClassName("graduationReportWaiversOverFiveField");
            const container = document.getElementById("moreThanFivePercent");
            if (percent > 0.05) {
                for (const field of allOverFiveFields) {
                    const ele = field;
                    this._core.forceElementRequired(ele);
                }
                container.classList.remove("hide");
            }
            else {
                for (const field of allOverFiveFields) {
                    const ele = field;
                    this._core.forceElementOptional(ele);
                }
                container.classList.add("hide");
            }
        }
    }
    allSectionOneAndTwoValidation() {
        const graduationReportPathwaysEvidencePathwayFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionOne");
        for (const graduationReportPathwaysEvidencePathway of graduationReportPathwaysEvidencePathwayFields)
            this.sectionOneAndTwoInputValidation(graduationReportPathwaysEvidencePathway);
        const graduationReportPathwaysEvidencePathwaySectionTwoFields = document.getElementsByClassName("graduationReportPathwaysEvidencePathwaySectionTwo");
        for (const graduationReportPathwaysEvidencePathwaySectionTwo of graduationReportPathwaysEvidencePathwaySectionTwoFields)
            this.sectionOneAndTwoInputValidation(graduationReportPathwaysEvidencePathwaySectionTwo);
    }
    extenuatingCircumstance() {
        const graduationReportPathwaysWaiverGrade12GraduatedElement = document.getElementById("graduationReportPathwaysWaiverGrade12Graduated");
        const graduationReportPathwaysWaiverGrade12GraduatedValue = parseInt(graduationReportPathwaysWaiverGrade12GraduatedElement.value);
        const allExtenuatingInputs = document.getElementsByClassName("graduationReportWaiverExtenuating");
        let totalExtenuating = 0;
        for (const extenuating of allExtenuatingInputs) {
            const element = extenuating;
            const thisVal = parseInt(element.value);
            if (!isNaN(thisVal))
                totalExtenuating += thisVal;
        }
        const waiverGraduationErrorElement = document.getElementById("waiverGraduationError");
        if (!isNaN(graduationReportPathwaysWaiverGrade12GraduatedValue)) {
            if (totalExtenuating !== graduationReportPathwaysWaiverGrade12GraduatedValue) {
                for (const extenuating of allExtenuatingInputs) {
                    const element = extenuating;
                    element.classList.add("missing-field");
                }
                waiverGraduationErrorElement.classList.remove("hide");
            }
            else {
                for (const extenuating of allExtenuatingInputs) {
                    const element = extenuating;
                    element.classList.remove("missing-field");
                }
                waiverGraduationErrorElement.classList.add("hide");
            }
        }
    }
}
// Graduation Pathway Percentages
class GraduationReportGraduationPathwayPercentages {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["graduationReportPathwayPercentagesField"];
        let graduationPathwayPercentagesSaveButton = document.getElementById("graduationReportGraduationPathwayPercentagesSave");
        if (graduationPathwayPercentagesSaveButton !== null)
            graduationPathwayPercentagesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const graduationReportPathwayPercentagesNonGraduatesEligibleElement = document.getElementById("graduationReportPathwayPercentagesNonGraduatesEligible");
        if (graduationReportPathwayPercentagesNonGraduatesEligibleElement !== null)
            graduationReportPathwayPercentagesNonGraduatesEligibleElement.addEventListener("input", (e) => this.checkGradNumber());
        this.checkGradNumber();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const allSlideouts = document.getElementsByClassName("showPathwaySlideout");
        for (const slideout of allSlideouts)
            this.bindSlideout(slideout);
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("graduationReportGraduationPathwayPercentagesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("graduationReportPathwayPercentagesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationPathwayPercentages', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    bindSlideout(button) {
        const slideoutId = button.dataset.slideout;
        const callingId = button.id;
        const slideout = new Slideout(slideoutId, callingId);
        button.addEventListener("click", (e) => slideout.show());
        button.addEventListener("keypress", (e) => { if (Core.a11yClick(e)) {
            slideout.show();
        } });
    }
    checkGradNumber() {
        const numberNotGraduatedElement = document.getElementById("numberNotGraduated");
        const numberNotGraduatedValue = parseInt(numberNotGraduatedElement.value);
        const numberNotGraduatedErrorElement = document.getElementById("numberNotGraduatedError");
        const graduationReportPathwayPercentagesNonGraduatesEligibleElement = document.getElementById("graduationReportPathwayPercentagesNonGraduatesEligible");
        const graduationReportPathwayPercentagesNonGraduatesEligibleValue = parseInt(graduationReportPathwayPercentagesNonGraduatesEligibleElement.value);
        if (!isNaN(graduationReportPathwayPercentagesNonGraduatesEligibleValue) && !isNaN(numberNotGraduatedValue)) {
            if (graduationReportPathwayPercentagesNonGraduatesEligibleValue > numberNotGraduatedValue) {
                numberNotGraduatedErrorElement.classList.remove("hide");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.classList.add("missing-field");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(graduationReportPathwayPercentagesNonGraduatesEligibleElement);
            }
            else {
                numberNotGraduatedErrorElement.classList.add("hide");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.classList.remove("missing-field");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.setAttribute("aria-invalid", "false");
                Core.removeErrorLabelForInput(graduationReportPathwayPercentagesNonGraduatesEligibleElement);
            }
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!element.classList.contains("missing-field")) {
                    //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                    if ("isValid" in htmlElement.dataset) {
                        if (htmlElement.dataset.isValid === "false") {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = element;
                            if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        const numberNotGraduatedElement = document.getElementById("numberNotGraduated");
        const numberNotGraduatedValue = parseInt(numberNotGraduatedElement.value);
        const numberNotGraduatedErrorElement = document.getElementById("numberNotGraduatedError");
        const graduationReportPathwayPercentagesNonGraduatesEligibleElement = document.getElementById("graduationReportPathwayPercentagesNonGraduatesEligible");
        const graduationReportPathwayPercentagesNonGraduatesEligibleValue = parseInt(graduationReportPathwayPercentagesNonGraduatesEligibleElement.value);
        if (!isNaN(graduationReportPathwayPercentagesNonGraduatesEligibleValue) && !isNaN(numberNotGraduatedValue)) {
            if (graduationReportPathwayPercentagesNonGraduatesEligibleValue > numberNotGraduatedValue) {
                numberNotGraduatedErrorElement.classList.remove("hide");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.classList.add("missing-field");
                graduationReportPathwayPercentagesNonGraduatesEligibleElement.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(graduationReportPathwayPercentagesNonGraduatesEligibleElement);
                totalErrors++;
                showMessage = true;
            }
        }
        const totalGradErrorElement = document.getElementById("totalGradError");
        if (totalGradErrorElement !== null) {
            if (!totalGradErrorElement.classList.contains("hide")) {
                showMessage = true;
                totalErrors++;
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
//Signatures and Assurances
class GraduationReportSignaturesAndAssurances {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["graduationReportSignaturesAndAssurancesCheckboxField", "graduationReportSignaturesAndAssurancesField"];
        let signaturesSaveButton = document.getElementById("graduationReportSignaturesAndAssurancesSave");
        if (signaturesSaveButton !== null)
            signaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        let fileUploader = document.getElementById("graduationReportSignaturesAndAssurancesBoardGraduationPolicy");
        if (fileUploader !== null) {
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e) => this.deleteFile(e));
        let exports = document.getElementsByClassName("fullReport");
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLongLoader();
                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocx/FullReportgraduationrequirementsExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    Core.hideLongLoader();
                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = exportOption.textContent;
                        filename = filename + ".docx";
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        }
                        else {
                            var a = document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                };
                xhr.send("planFK=" + exportOption.dataset.planfk);
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.getElementById(`${e.id}`);
                        formFile.value = "";
                        let fileList = document.getElementById("uploadedFiles");
                        let currentFileList = fileList.querySelectorAll(".uploadFileList");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.getElementById("uploadedFiles");
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("graduationReportSignaturesAndAssurancesFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let formElement = document.getElementById("graduationReportSignaturesAndAssurancesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let checkInputs = document.getElementsByClassName("graduationReportSignaturesAndAssurancesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let row = element.dataset.row;
            let rowNumber = 0;
            if (row)
                rowNumber = parseInt(element.dataset.row);
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let textInputs = document.getElementsByClassName("graduationReportSignaturesAndAssurancesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("graduationReportSignaturesAndAssurancesFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/GraduationReport/SaveGraduationReportSignaturesAndAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
}
//Summary Checklist and Submission
class GraduationReportSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        this._core.leftnav(this);
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        //this._core.initializeRequiredFields(this.validationClasses);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        //Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        if (refreshPage && refreshPage !== "")
            window.location.href = refreshPage;
        else
            Core.hideLoader();
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/GraduationReport/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
//Profile
class EducatorEffectivenessProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["educatorEffectivenessProfileField", "educatorEffectivenessTeacherSystemsRadioField", "educatorEffectivenessTeacherSystemsSelectField"];
        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessProfileSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("educatorEffectivenessProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("educatorEffectivenessProfileForm");
        let inputs = document.getElementsByClassName("educatorEffectivenessProfileField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("educatorEffectivenessProfileForm");
        let inputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Educator Effectiveness Teacher Evaluation Details
class EducatorEffectivenessTeacherDetails {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["educatorEffectivenessAggregateField", "educatorEffectivenessDetailsField"];
        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessTeacherDetailsSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        this._core.initializeRequiredFields(this.validationClasses);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let educatorEffectivenessSchoolAddNotListedButton = document.getElementById("educatorEffectivenessSchoolAddNotListed");
        if (educatorEffectivenessSchoolAddNotListedButton !== null)
            educatorEffectivenessSchoolAddNotListedButton.addEventListener("click", (e) => this.addTeacherSchool(e));
        this.bindCalculations();
        let searchButton = document.getElementById("educatorEffectivenessSchoolNameSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e) => this.search());
        let searchClearButton = document.getElementById("educatorEffectivenessSchoolNameSearchClear");
        if (searchClearButton !== null)
            searchClearButton.addEventListener("click", (e) => this.clearSearch());
        this.populateEmptyZeros();
        let educatorEffectivenessTeacherDetailsExportToExcelButton = document.getElementById("educatorEffectivenessTeacherDetailsExportToExcel");
        if (educatorEffectivenessTeacherDetailsExportToExcelButton !== null) {
            educatorEffectivenessTeacherDetailsExportToExcelButton.addEventListener("click", () => {
                Core.showLongLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLongLoader();
                })
                    .catch((error) => {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        this.bindDeleteAdditionalUserRows();
        const deleteAdditionalUserRowConfirm = document.getElementById("deleteAdditionalUserRowConfirm");
        if (deleteAdditionalUserRowConfirm !== null)
            deleteAdditionalUserRowConfirm.addEventListener("click", (e) => this.deleteAdditionalUserRowConfirm(e));
        const deleteAdditionalUserRowCancel = document.getElementById("deleteAdditionalUserRowCancel");
        if (deleteAdditionalUserRowCancel !== null)
            deleteAdditionalUserRowCancel.addEventListener("click", (e) => this.deleteAdditionalUserRowCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        let addRows = false;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            //if the element to create (global) is not empty, run a separate function to create
            //empty elements in the back end for the principal and the non-teaching professionals.
            //then just continue normally.
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("educatorEffectivenessTeacherDetailsForm");
        let inputs = document.getElementsByClassName("educatorEffectivenessDetailsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let additionalInputs = document.getElementsByClassName("educatorEffectivenessDetailsAdditionalField");
        for (let ele of additionalInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            const theType = element.dataset.columntype;
            let value = "";
            if (theType && theType === "schoolname") {
                value = element.dataset.schoolname;
            }
            else if (theType && theType == "schoolbranch") {
                value = element.dataset.schoolbranch;
            }
            if (value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        //This is intentional. We need the aggregate text fields to have a different class for calculation purposes, but we still want to save them here
        let aggregates = document.getElementsByClassName("educatorEffectivenessAggregateField");
        for (let ele of aggregates) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessTeacherDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addTeacherSchool(e) {
        const buttonElement = e.target;
        const schoolNameElement = document.getElementById("educatorEffectivenessTeacherDetailsAddSchoolName");
        const branchElement = document.getElementById("educatorEffectivenessTeacherDetailsAddSchoolBranch");
        const planFK = buttonElement.dataset.planfk;
        let showESSA = "false";
        if (buttonElement.dataset.showessa)
            showESSA = buttonElement.dataset.showessa;
        const isLockdown = buttonElement.dataset.islockdown;
        const isReview = buttonElement.dataset.isreview;
        const that = this;
        if (branchElement.value !== "" && schoolNameElement.value !== "") {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/AddSchoolToList', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let tr = document.createElement("tr");
                    tr.innerHTML = xhr.responseText;
                    tr.dataset.row = branchElement.value;
                    tr.classList.add("additionalSchoolUserRow");
                    let placementElement = document.querySelector("#schoolList tbody");
                    placementElement.append(tr);
                    that.populateEmptyZeros();
                    that.bindCalculations();
                    schoolNameElement.value = "";
                    branchElement.value = "";
                    that.bindDeleteAdditionalUserRows();
                    Core.createHTMLAlert("alertMessageDiv", "School successfully added to the list", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(`planFK=${planFK}&schoolName=${schoolNameElement.value}&branch=${branchElement.value}&showESSA=${showESSA}&isLockdown=${isLockdown}&isReview=${isReview}`);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "School Name and Branch number are required to add a school.", 'error', 3000, null);
        }
    }
    calculateRows(e) {
        let inputElement = e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalTR = 0;
        let totalTRTNR = 0;
        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorOthers[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = item;
            if (ele.value !== "") {
                let parsed = parseInt(ele.value);
                if (!isNaN(parsed)) {
                    totalTR += parsed;
                }
            }
        }
        let elementTR = document.querySelector(`.educatorEffectivenessCalculatorTR[data-row='${thisRowNumber}']`);
        elementTR.value = totalTR.toString();
        let elementTNR = document.querySelector(`.educatorEffectivenessCalculatorTNR[data-row='${thisRowNumber}']`);
        let tnrValue = 0;
        if (elementTNR && elementTNR.value !== "")
            tnrValue = parseInt(elementTNR.value);
        if (!isNaN(tnrValue)) {
            totalTRTNR = totalTR + tnrValue;
        }
        let elementTRTNR = document.querySelector(`.educatorEffectivenessCalculatorTRTNR[data-row='${thisRowNumber}']`);
        elementTRTNR.value = totalTRTNR.toString();
        this.calculateAggregate();
    }
    calculateESSARows(e) {
        let inputElement = e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalESSA = 0;
        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorThumbs[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = item;
            if (ele.value !== "") {
                let parsed = parseInt(ele.value);
                if (!isNaN(parsed)) {
                    totalESSA += parsed;
                }
            }
        }
        let elementESSA = document.querySelector(`.educatorEffectivenessCalculatorESSA[data-row='${thisRowNumber}']`);
        elementESSA.value = totalESSA.toString();
        this.calculateAggregate();
    }
    calculateAggregate() {
        let columns = [];
        columns.push("1U");
        columns.push("2U");
        columns.push("2S");
        columns.push("3S");
        columns.push("4S");
        columns.push("TNR");
        columns.push("TR");
        columns.push("TRTNR");
        columns.push("ThumbsDown");
        columns.push("ThumbsUp");
        columns.push("ESSA");
        for (let column of columns) {
            let allColumnElements = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-column='${column}']`);
            let currentColumnTotal = 0;
            for (let currentElement of allColumnElements) {
                let ele = currentElement;
                let currentValue = parseInt(ele.value);
                if (!isNaN(currentValue)) {
                    currentColumnTotal += currentValue;
                }
            }
            let thisAggregateElement = document.querySelector(`.educatorEffectivenessAggregateField[data-column='${column}']`);
            thisAggregateElement.value = currentColumnTotal.toString();
        }
    }
    search() {
        //Start by removing all .search-found and .search-hide classes
        this.clearSearch(false);
        let searchTermElement = document.getElementById("educatorEffectivenessTeacherDetailsSearchSchoolName");
        if (searchTermElement && searchTermElement.value !== "") {
            let allSchoolNames = document.querySelectorAll(".educatorEffectivenessSchoolName[data-columntype='schoolname']");
            for (let school of allSchoolNames) {
                let element = school;
                if (element.textContent.toLowerCase().indexOf(searchTermElement.value.toLowerCase()) >= 0) {
                    //label    td              tr
                    element.parentElement.parentElement.classList.add("search-found");
                }
                else {
                    element.parentElement.parentElement.classList.add("search-hide");
                }
            }
        }
    }
    clearSearch(clearText = true) {
        let allFoundClassess = document.querySelectorAll(".school-list .search-found");
        for (let ele of allFoundClassess)
            ele.classList.remove("search-found");
        let allHideClassess = document.querySelectorAll(".school-list .search-hide");
        for (let ele of allHideClassess)
            ele.classList.remove("search-hide");
        if (clearText) {
            let textElement = document.getElementById("educatorEffectivenessTeacherDetailsSearchSchoolName");
            if (textElement !== null)
                textElement.value = "";
        }
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("educatorEffectivenessTeacherDetailsForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/EducatorEffectivenessTeacherEducatorEffectivenessTeacherDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `EducatorEffectivenessTeacherDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    populateEmptyZeros() {
        let allFields = document.querySelectorAll("#schoolList .educatorEffectivenessDetailsField");
        for (let field of allFields) {
            let ele = field;
            if (ele.value === "")
                ele.value = "0";
        }
    }
    bindCalculations() {
        const educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e) => this.calculateRows(e));
        const educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e) => this.calculateRows(e));
        const educatorEffectivenessCalculationsThumbs = document.getElementsByClassName("educatorEffectivenessCalculatorThumbs");
        for (let educatorEffectivenessCalculationsThumb of educatorEffectivenessCalculationsThumbs)
            educatorEffectivenessCalculationsThumb.addEventListener("blur", (e) => this.calculateESSARows(e));
    }
    bindDeleteAdditionalUserRows() {
        const deleteAdditionalUserRows = document.getElementsByClassName("deleteAdditionalUserRow");
        for (const deleteAdditionalUserRow of deleteAdditionalUserRows)
            deleteAdditionalUserRow.addEventListener("click", (e) => this.showDeleteAdditionalUserRow(e));
    }
    showDeleteAdditionalUserRow(e) {
        const button = e.target;
        const row = button.dataset.row;
        const modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.addAttributeToElement("deleteAdditionalUserRowModal", "#deleteAdditionalUserRowConfirm", "row", row);
        modal.show();
    }
    deleteAdditionalUserRowConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const row = button.dataset.row;
            const allPlanProperties = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-row='${row}']`);
            const planProps = [];
            for (const pp of allPlanProperties) {
                const ele = pp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0") {
                    planProps.push(ele.dataset.planpropertypk);
                }
            }
            const schoolName = document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolname']`);
            const schoolBranch = document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolbranch']`);
            planProps.push(schoolName.dataset.planpropertypk);
            planProps.push(schoolBranch.dataset.planpropertypk);
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planProps)
            };
            const response = yield fetch(`/EducatorEffectiveness/DeleteTeacherDetailsAdditionalUserRow`, settings);
            if (response.ok) {
                const thisTR = document.querySelector(`.additionalSchoolUserRow[data-row='${row}']`);
                thisTR.remove();
                const modal = new Modal("deleteAdditionalUserRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The additional school row has been removed", 'success', 3000, null);
            }
            else {
                const modal = new Modal("deleteAdditionalUserRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the additional school row. Please try again.", 'success', 3000, null);
            }
        });
    }
    deleteAdditionalUserRowCancel() {
        const modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.hide();
    }
}
//Educator Effectiveness Principal Evaluation Details
class EducatorEffectivenessPrincipalDetails {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["educatorEffectivenessAggregateField", "educatorEffectivenessDetailsField", "educatorEffectivenessDetailsCheckboxField"];
        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessPrincipalDetailsSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let educatorEffectivenessSchoolAddNotListedButton = document.getElementById("educatorEffectivenessSchoolAddNotListed");
        if (educatorEffectivenessSchoolAddNotListedButton !== null)
            educatorEffectivenessSchoolAddNotListedButton.addEventListener("click", (e) => this.addPrincipalSchool(e));
        let educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e) => this.calculateRows(e));
        let educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e) => this.calculateRows(e));
        let educatorEffectivenessCalculationsThumbs = document.getElementsByClassName("educatorEffectivenessCalculatorThumbs");
        for (let educatorEffectivenessCalculationsThumb of educatorEffectivenessCalculationsThumbs)
            educatorEffectivenessCalculationsThumb.addEventListener("blur", (e) => this.calculateESSARows(e));
        let searchButton = document.getElementById("educatorEffectivenessSchoolNameSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e) => this.search());
        let searchClearButton = document.getElementById("educatorEffectivenessSchoolNameSearchClear");
        if (searchClearButton !== null)
            searchClearButton.addEventListener("click", (e) => this.clearSearch());
        this.populateEmptyZeros();
        let educatorEffectivenessPrincipalDetailsExportToExcelButton = document.getElementById("educatorEffectivenessPrincipalDetailsExportToExcel");
        if (educatorEffectivenessPrincipalDetailsExportToExcelButton !== null) {
            educatorEffectivenessPrincipalDetailsExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        this.bindDeleteAdditionalUserRows();
        const deleteAdditionalUserRowConfirm = document.getElementById("deleteAdditionalUserRowConfirm");
        if (deleteAdditionalUserRowConfirm !== null)
            deleteAdditionalUserRowConfirm.addEventListener("click", (e) => this.deleteAdditionalUserRowConfirm(e));
        const deleteAdditionalUserRowCancel = document.getElementById("deleteAdditionalUserRowCancel");
        if (deleteAdditionalUserRowCancel !== null)
            deleteAdditionalUserRowCancel.addEventListener("click", (e) => this.deleteAdditionalUserRowCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("educatorEffectivenessPrincipalDetailsForm");
        let inputs = document.getElementsByClassName("educatorEffectivenessDetailsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("educatorEffectivenessDetailsCheckboxField");
        for (let ele of checkboxes) {
            const element = ele;
            const propertyPK = parseInt(element.dataset.propertypk);
            const planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            const saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: 0,
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let additionalInputs = document.getElementsByClassName("educatorEffectivenessDetailsAdditionalField");
        for (let ele of additionalInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            const theType = element.dataset.columntype;
            let value = "";
            if (theType && theType === "schoolname") {
                value = element.dataset.schoolname;
            }
            else if (theType && theType == "schoolbranch") {
                value = element.dataset.schoolbranch;
            }
            if (value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        //This is intentional. We need the aggregate text fields to have a different class for calculation purposes, but we still want to save them here
        let aggregates = document.getElementsByClassName("educatorEffectivenessAggregateField");
        for (let ele of aggregates) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessPrincipalDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addPrincipalSchool(e) {
        let buttonElement = e.target;
        let schoolNameElement = document.getElementById("educatorEffectivenessPrincipalDetailsAddSchoolName");
        let branchElement = document.getElementById("educatorEffectivenessPrincipalDetailsAddSchoolBranch");
        let planFK = buttonElement.dataset.planfk;
        let showESSA = "false";
        if (buttonElement.dataset.showessa)
            showESSA = buttonElement.dataset.showessa;
        let isLockdown = buttonElement.dataset.islockdown;
        let isReview = buttonElement.dataset.isreview;
        const that = this;
        if (branchElement.value !== "" && schoolNameElement.value !== "") {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/AddSchoolToListPrincipal', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let tr = document.createElement("tr");
                    tr.innerHTML = xhr.responseText;
                    let placementElement = document.querySelector("#schoolList tbody");
                    placementElement.append(tr);
                    schoolNameElement.value = "";
                    branchElement.value = "";
                    that.bindDeleteAdditionalUserRows();
                    that.populateEmptyZeros();
                    that.bindCalculations();
                    Core.createHTMLAlert("alertMessageDiv", "School successfully added to the list", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(`planFK=${planFK}&schoolName=${schoolNameElement.value}&branch=${branchElement.value}&showESSA=${showESSA}&isLockdown=${isLockdown}&isReview=${isReview}`);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "School Name and Branch number are required to add a school.", 'error', 3000, null);
        }
    }
    calculateRows(e) {
        let inputElement = e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalTR = 0;
        let totalTRTNR = 0;
        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorOthers[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = item;
            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalTR += parsed;
                }
            }
        }
        let elementTR = document.querySelector(`.educatorEffectivenessCalculatorTR[data-row='${thisRowNumber}']`);
        elementTR.value = totalTR.toFixed(2);
        let elementTNR = document.querySelector(`.educatorEffectivenessCalculatorTNR[data-row='${thisRowNumber}']`);
        let tnrValue = 0;
        if (elementTNR && elementTNR.value !== "")
            tnrValue = parseFloat(elementTNR.value);
        if (!isNaN(tnrValue)) {
            totalTRTNR = totalTR + tnrValue;
        }
        let elementTRTNR = document.querySelector(`.educatorEffectivenessCalculatorTRTNR[data-row='${thisRowNumber}']`);
        elementTRTNR.value = totalTRTNR.toFixed(2);
        this.calculateAggregate();
    }
    calculateESSARows(e) {
        let inputElement = e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalESSA = 0;
        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorThumbs[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = item;
            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalESSA += parsed;
                }
            }
        }
        let elementESSA = document.querySelector(`.educatorEffectivenessCalculatorESSA[data-row='${thisRowNumber}']`);
        elementESSA.value = totalESSA.toFixed(2);
        this.calculateAggregate();
    }
    calculateAggregate() {
        let columns = [];
        columns.push("1U");
        columns.push("2U");
        columns.push("2S");
        columns.push("3S");
        columns.push("4S");
        columns.push("TNR");
        columns.push("TR");
        columns.push("TRTNR");
        for (let column of columns) {
            let allColumnElements = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-column='${column}']`);
            let currentColumnTotal = 0;
            for (let currentElement of allColumnElements) {
                let ele = currentElement;
                let currentValue = parseFloat(ele.value);
                if (!isNaN(currentValue)) {
                    currentColumnTotal += currentValue;
                }
            }
            let thisAggregateElement = document.querySelector(`.educatorEffectivenessAggregateField[data-column='${column}']`);
            thisAggregateElement.value = currentColumnTotal.toFixed(2);
        }
    }
    search() {
        //Start by removing all .search-found and .search-hide classes
        this.clearSearch(false);
        let searchTermElement = document.getElementById("educatorEffectivenessPrincipalDetailsSearchSchoolName");
        if (searchTermElement && searchTermElement.value !== "") {
            let allSchoolNames = document.querySelectorAll(".educatorEffectivenessSchoolName[data-columntype='schoolname']");
            for (let school of allSchoolNames) {
                let element = school;
                if (element.textContent.toLowerCase().indexOf(searchTermElement.value.toLowerCase()) >= 0) {
                    //label    td              tr
                    element.parentElement.parentElement.classList.add("search-found");
                }
                else {
                    element.parentElement.parentElement.classList.add("search-hide");
                }
            }
        }
    }
    clearSearch(clearText = true) {
        let allFoundClassess = document.querySelectorAll(".school-list .search-found");
        for (let ele of allFoundClassess)
            ele.classList.remove("search-found");
        let allHideClassess = document.querySelectorAll(".school-list .search-hide");
        for (let ele of allHideClassess)
            ele.classList.remove("search-hide");
        if (clearText) {
            let textElement = document.getElementById("educatorEffectivenessPrincipalDetailsSearchSchoolName");
            if (textElement !== null)
                textElement.value = "";
        }
    }
    exportToExcel() {
        let that = this;
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("educatorEffectivenessPrincipalDetailsForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/EducatorEffectivenessTeacherEducatorEffectivenessPrincipalDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `EducatorEffectivenessPrincipalDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    populateEmptyZeros() {
        let allFields = document.querySelectorAll("#schoolList .educatorEffectivenessDetailsField");
        for (let field of allFields) {
            let ele = field;
            if (ele.value === "")
                ele.value = "0";
        }
    }
    bindDeleteAdditionalUserRows() {
        const deleteAdditionalUserRows = document.getElementsByClassName("deleteAdditionalUserRow");
        for (const deleteAdditionalUserRow of deleteAdditionalUserRows)
            deleteAdditionalUserRow.addEventListener("click", (e) => this.showDeleteAdditionalUserRow(e));
    }
    showDeleteAdditionalUserRow(e) {
        const button = e.target;
        const row = button.dataset.row;
        const modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.addAttributeToElement("deleteAdditionalUserRowModal", "#deleteAdditionalUserRowConfirm", "row", row);
        modal.show();
    }
    deleteAdditionalUserRowConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const row = button.dataset.row;
            const allPlanProperties = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-row='${row}']`);
            const planProps = [];
            for (const pp of allPlanProperties) {
                const ele = pp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0") {
                    planProps.push(ele.dataset.planpropertypk);
                }
            }
            const schoolName = document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolname']`);
            const schoolBranch = document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolbranch']`);
            planProps.push(schoolName.dataset.planpropertypk);
            planProps.push(schoolBranch.dataset.planpropertypk);
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planProps)
            };
            const response = yield fetch(`/EducatorEffectiveness/DeleteTeacherDetailsAdditionalUserRow`, settings);
            if (response.ok) {
                const thisTR = document.querySelector(`.additionalSchoolUserRow[data-row='${row}']`);
                thisTR.remove();
                const modal = new Modal("deleteAdditionalUserRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The additional school row has been removed", 'success', 3000, null);
            }
            else {
                const modal = new Modal("deleteAdditionalUserRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the additional school row. Please try again.", 'success', 3000, null);
            }
        });
    }
    deleteAdditionalUserRowCancel() {
        const modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.hide();
    }
    bindCalculations() {
        const educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e) => this.calculateRows(e));
        const educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e) => this.calculateRows(e));
    }
}
//Educator Effectiveness Non-Teaching Professional Evaluation Details
class EducatorEffectivenessNonTeachingProfessionalDetails {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["educatorEffectivenessAggregateField", "educatorEffectivenessDetailsField"];
        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let educatorEffectivenessSchoolAddNotListedButton = document.getElementById("educatorEffectivenessSchoolAddNotListed");
        if (educatorEffectivenessSchoolAddNotListedButton !== null)
            educatorEffectivenessSchoolAddNotListedButton.addEventListener("click", (e) => this.addNonTeachingSchool(e));
        let educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e) => this.calculateRows(e));
        let educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e) => this.calculateRows(e));
        let educatorEffectivenessCalculationsThumbs = document.getElementsByClassName("educatorEffectivenessCalculatorThumbs");
        for (let educatorEffectivenessCalculationsThumb of educatorEffectivenessCalculationsThumbs)
            educatorEffectivenessCalculationsThumb.addEventListener("blur", (e) => this.calculateESSARows(e));
        let searchButton = document.getElementById("educatorEffectivenessSchoolNameSearch");
        if (searchButton !== null)
            searchButton.addEventListener("click", (e) => this.search());
        let searchClearButton = document.getElementById("educatorEffectivenessSchoolNameSearchClear");
        if (searchClearButton !== null)
            searchClearButton.addEventListener("click", (e) => this.clearSearch());
        this.populateEmptyZeros();
        let educatorEffectivenessNonTeachingProfessionalDetailsExportToExcelButton = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsExportToExcel");
        if (educatorEffectivenessNonTeachingProfessionalDetailsExportToExcelButton !== null) {
            educatorEffectivenessNonTeachingProfessionalDetailsExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        this.bindDeleteAdditionalUserRows();
        const deleteAdditionalUserRowConfirm = document.getElementById("deleteAdditionalUserRowConfirm");
        if (deleteAdditionalUserRowConfirm !== null)
            deleteAdditionalUserRowConfirm.addEventListener("click", (e) => this.deleteAdditionalUserRowConfirm(e));
        const deleteAdditionalUserRowCancel = document.getElementById("deleteAdditionalUserRowCancel");
        if (deleteAdditionalUserRowCancel !== null)
            deleteAdditionalUserRowCancel.addEventListener("click", (e) => this.deleteAdditionalUserRowCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let profileFormElement = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsForm");
        let inputs = document.getElementsByClassName("educatorEffectivenessDetailsField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        //This is intentional. We need the aggregate text fields to have a different class for calculation purposes, but we still want to save them here
        let aggregates = document.getElementsByClassName("educatorEffectivenessAggregateField");
        for (let ele of aggregates) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let additionalInputs = document.getElementsByClassName("educatorEffectivenessDetailsAdditionalField");
        for (let ele of additionalInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(profileFormElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            const theType = element.dataset.columntype;
            let value = "";
            if (theType && theType === "schoolname") {
                value = element.dataset.schoolname;
            }
            else if (theType && theType == "schoolbranch") {
                value = element.dataset.schoolbranch;
            }
            if (value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessNonTeachingProfessionalDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLongLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLongLoader();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLongLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLongLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addNonTeachingSchool(e) {
        let buttonElement = e.target;
        let schoolNameElement = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsAddSchoolName");
        let branchElement = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsAddSchoolBranch");
        let planFK = buttonElement.dataset.planfk;
        let showESSA = "false";
        if (buttonElement.dataset.showessa)
            showESSA = buttonElement.dataset.showessa;
        let isLockdown = buttonElement.dataset.islockdown;
        let isReview = buttonElement.dataset.isreview;
        const that = this;
        if (branchElement.value !== "" && schoolNameElement.value !== "") {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/AddSchoolToListNonTeaching', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let tr = document.createElement("tr");
                    tr.innerHTML = xhr.responseText;
                    let placementElement = document.querySelector("#schoolList tbody");
                    placementElement.append(tr);
                    schoolNameElement.value = "";
                    branchElement.value = "";
                    that.bindDeleteAdditionalUserRows();
                    that.populateEmptyZeros();
                    that.bindCalculations();
                    Core.createHTMLAlert("alertMessageDiv", "School successfully added to the list", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
                that.bindDeleteAdditionalUserRows();
            };
            xhr.send(`planFK=${planFK}&schoolName=${schoolNameElement.value}&branch=${branchElement.value}&showESSA=${showESSA}&isLockdown=${isLockdown}&isReview=${isReview}`);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "School Name and Branch number are required to add a school.", 'error', 3000, null);
        }
    }
    calculateRows(e) {
        let inputElement = e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalTR = 0;
        let totalTRTNR = 0;
        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorOthers[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = item;
            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalTR += parsed;
                }
            }
        }
        let elementTR = document.querySelector(`.educatorEffectivenessCalculatorTR[data-row='${thisRowNumber}']`);
        elementTR.value = totalTR.toFixed(2);
        let elementTNR = document.querySelector(`.educatorEffectivenessCalculatorTNR[data-row='${thisRowNumber}']`);
        let tnrValue = 0;
        if (elementTNR && elementTNR.value !== "")
            tnrValue = parseFloat(elementTNR.value);
        if (!isNaN(tnrValue)) {
            totalTRTNR = totalTR + tnrValue;
        }
        let elementTRTNR = document.querySelector(`.educatorEffectivenessCalculatorTRTNR[data-row='${thisRowNumber}']`);
        elementTRTNR.value = totalTRTNR.toFixed(2);
        this.calculateAggregate();
    }
    calculateESSARows(e) {
        let inputElement = e.target;
        let thisRowNumber = inputElement.dataset.row;
        let totalESSA = 0;
        let thisRow = document.querySelectorAll(`.educatorEffectivenessCalculatorThumbs[data-row='${thisRowNumber}']`);
        for (let item of thisRow) {
            let ele = item;
            if (ele.value !== "") {
                let parsed = parseFloat(ele.value);
                if (!isNaN(parsed)) {
                    totalESSA += parsed;
                }
            }
        }
        let elementESSA = document.querySelector(`.educatorEffectivenessCalculatorESSA[data-row='${thisRowNumber}']`);
        elementESSA.value = totalESSA.toFixed(2);
        this.calculateAggregate();
    }
    calculateAggregate() {
        let columns = [];
        columns.push("1U");
        columns.push("2U");
        columns.push("2S");
        columns.push("3S");
        columns.push("4S");
        columns.push("TNR");
        columns.push("TR");
        columns.push("TRTNR");
        for (let column of columns) {
            let allColumnElements = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-column='${column}']`);
            let currentColumnTotal = 0;
            for (let currentElement of allColumnElements) {
                let ele = currentElement;
                let currentValue = parseFloat(ele.value);
                if (!isNaN(currentValue)) {
                    currentColumnTotal += currentValue;
                }
            }
            let thisAggregateElement = document.querySelector(`.educatorEffectivenessAggregateField[data-column='${column}']`);
            thisAggregateElement.value = currentColumnTotal.toFixed(2);
        }
    }
    search() {
        this.clearSearch(false);
        let searchTermElement = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsSearchSchoolName");
        if (searchTermElement && searchTermElement.value !== "") {
            let allSchoolNames = document.querySelectorAll(".educatorEffectivenessSchoolName[data-columntype='schoolname']");
            for (let school of allSchoolNames) {
                let element = school;
                if (element.textContent.toLowerCase().indexOf(searchTermElement.value.toLowerCase()) >= 0) {
                    //label    td              tr
                    element.parentElement.parentElement.classList.add("search-found");
                }
                else {
                    element.parentElement.parentElement.classList.add("search-hide");
                }
            }
        }
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/EducatorEffectivenessTeacherEducatorEffectivenessNonTeachingProfessionalDetailsExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `EducatorEffectivenessNonTeachingProfessionalDetails.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    populateEmptyZeros() {
        let allFields = document.querySelectorAll("#schoolList .educatorEffectivenessDetailsField");
        for (let field of allFields) {
            let ele = field;
            if (ele.value === "")
                ele.value = "0";
        }
    }
    clearSearch(clearText = true) {
        let allFoundClassess = document.querySelectorAll(".school-list .search-found");
        for (let ele of allFoundClassess)
            ele.classList.remove("search-found");
        let allHideClassess = document.querySelectorAll(".school-list .search-hide");
        for (let ele of allHideClassess)
            ele.classList.remove("search-hide");
        if (clearText) {
            let textElement = document.getElementById("educatorEffectivenessNonTeachingProfessionalDetailsSearchSchoolName");
            if (textElement !== null)
                textElement.value = "";
        }
    }
    bindDeleteAdditionalUserRows() {
        const deleteAdditionalUserRows = document.getElementsByClassName("deleteAdditionalUserRow");
        for (const deleteAdditionalUserRow of deleteAdditionalUserRows)
            deleteAdditionalUserRow.addEventListener("click", (e) => this.showDeleteAdditionalUserRow(e));
    }
    showDeleteAdditionalUserRow(e) {
        const button = e.target;
        const row = button.dataset.row;
        const modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.addAttributeToElement("deleteAdditionalUserRowModal", "#deleteAdditionalUserRowConfirm", "row", row);
        modal.show();
    }
    deleteAdditionalUserRowConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const row = button.dataset.row;
            const allPlanProperties = document.querySelectorAll(`.educatorEffectivenessDetailsField[data-row='${row}']`);
            const planProps = [];
            for (const pp of allPlanProperties) {
                const ele = pp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0") {
                    planProps.push(ele.dataset.planpropertypk);
                }
            }
            const schoolName = document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolname']`);
            const schoolBranch = document.querySelector(`.educatorEffectivenessDetailsAdditionalField[data-row='${row}'][data-columntype='schoolbranch']`);
            planProps.push(schoolName.dataset.planpropertypk);
            planProps.push(schoolBranch.dataset.planpropertypk);
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planProps)
            };
            const response = yield fetch(`/EducatorEffectiveness/DeleteTeacherDetailsAdditionalUserRow`, settings);
            if (response.ok) {
                const thisTR = document.querySelector(`.additionalSchoolUserRow[data-row='${row}']`);
                thisTR.remove();
                const modal = new Modal("deleteAdditionalUserRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The additional school row has been removed", 'success', 3000, null);
            }
            else {
                const modal = new Modal("deleteAdditionalUserRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the additional school row. Please try again.", 'success', 3000, null);
            }
        });
    }
    deleteAdditionalUserRowCancel() {
        const modal = new Modal("deleteAdditionalUserRowModal", null);
        modal.hide();
    }
    bindCalculations() {
        const educatorEffectivenessCalculations = document.getElementsByClassName("educatorEffectivenessCalculatorOthers");
        for (let educatorEffectivenessCalculation of educatorEffectivenessCalculations)
            educatorEffectivenessCalculation.addEventListener("blur", (e) => this.calculateRows(e));
        const educatorEffectivenessCalculationsTNR = document.getElementsByClassName("educatorEffectivenessCalculatorTNR");
        for (let educatorEffectivenessCalculationTNR of educatorEffectivenessCalculationsTNR)
            educatorEffectivenessCalculationTNR.addEventListener("blur", (e) => this.calculateRows(e));
    }
}
//Educator Effectiveness Signatures and Assurances
class EducatorEffectivenessSignaturesAndAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["flexibleInstructionDaysField"];
        let specialEducationProfileSaveButton = document.getElementById("educatorEffectivenessSignaturesAndAssuranceSave");
        if (specialEducationProfileSaveButton !== null)
            specialEducationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allSaveElements = [];
        let planPK = 0;
        let formElement = document.getElementById("educatorEffectivenessSignaturesAndAssuranceForm");
        let inputs = document.getElementsByClassName("flexibleInstructionDaysField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/EducatorEffectiveness/SaveEducatorEffectivenessSignaturesAndAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'warning', 3000, null);
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
//Educator Effectiveness Summary Checklist & Submission
class EducatorEffectivenessSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/EducatorEffectiveness/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/modal.ts" />
// SafeSchoolsBullyingProfile
class SafeSchoolsBullyingProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsBullyingProfileField"];
        let safeSchoolsBullyingProfileSaveButton = document.getElementById("safeSchoolsBullyingProfileSave");
        if (safeSchoolsBullyingProfileSaveButton !== null)
            safeSchoolsBullyingProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("safeSchoolsBullyingProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsBullyingProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("safeSchoolsBullyingProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsBullyingPolicy
class SafeSchoolsBullyingPolicy {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsBullyingPolicyField", "safeSchoolsBullyingPolicyRadioField", "safeSchoolsBullyingPolicyCheckboxField", "safeSchoolsBullyingPolicyFileField"];
        let safeSchoolsBullyingPolicySaveButton = document.getElementById("safeSchoolsBullyingPolicySave");
        if (safeSchoolsBullyingPolicySaveButton !== null)
            safeSchoolsBullyingPolicySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e) => this.deleteFile(e));
        let fileUploader = document.getElementById("safeSchoolsBullyingPolicyUploadofBullyingPolicy");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        this.bindFileDeletes();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingPolicyForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsBullyingPolicyField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("safeSchoolsBullyingPolicyRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let checkInputs = document.getElementsByClassName("safeSchoolsBullyingPolicyCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let textValue = "";
            if (element.checked) {
                textValue = "on";
            }
            else {
                textValue = "off";
            }
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false,
            };
            allSaveElements.push(saveItem);
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingPolicy', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.getElementById(`${e.id}`);
                        formFile.value = "";
                        let fileList = document.getElementById("uploadedFiles");
                        let currentFileList = fileList.querySelectorAll(".uploadFileList");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.getElementById("uploadedFiles");
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindFileDeletes();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindFileDeletes() {
        let allButtons = document.getElementsByClassName("deleteFile");
        for (let delButton of allButtons)
            delButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                        let uploads = document.querySelectorAll(`#uploadedFiles .uploaded-file-container`);
                        if (uploads.length === 0) {
                            let uploadContainer = document.querySelector(`#uploadedFiles`);
                            uploadContainer.classList.add("hide");
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    affirmationClick(checkbox) {
        const dateReviewed = document.getElementById("safeSchoolsBullyingPolicyDateReviewed");
        const dateReviewedRow = document.getElementById("dateReviewedRow");
        if (checkbox.checked) {
            this._core.forceElementRequired(dateReviewed);
            dateReviewedRow.classList.remove("hide");
        }
        else {
            this._core.forceElementOptional(dateReviewed);
            dateReviewedRow.classList.add("hide");
        }
    }
}
// SafeSchoolsBullyingSignatures
class SafeSchoolsBullyingSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsBullyingSignaturesField", "safeSchoolsBullyingSignaturesCheckboxField"];
        let safeSchoolsBullyingSignaturesSaveButton = document.getElementById("safeSchoolsBullyingSignaturesSave");
        if (safeSchoolsBullyingSignaturesSaveButton !== null)
            safeSchoolsBullyingSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBullyingSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsBullyingSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("safeSchoolsBullyingSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBullyingSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsBullyingSummaryChecklist
class SafeSchoolsBullyingSummaryChecklist {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// SafeSchoolsBusEvacuationProfile
class SafeSchoolsBusEvacuationProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsBusEvacuationProfileField"];
        let safeSchoolsBusEvacuationProfileSaveButton = document.getElementById("safeSchoolsBusEvacuationProfileSave");
        if (safeSchoolsBusEvacuationProfileSaveButton !== null)
            safeSchoolsBusEvacuationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("safeSchoolsBusEvacuationProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsBusEvacuationProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("safeSchoolsBusEvacuationProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsBusEvacuationBusEvacuationDrillACS
class SafeSchoolsBusEvacuationBusEvacuationDrillACS {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsBusEvacuationBusEvacuationDrillACSField", "safeSchoolsBusEvacuationBusEvacuationDrillACSRadioField", "safeSchoolsBusEvacuationBusEvacuationDrillACSFileUploadField"];
        this.checkPIMSQuestion();
        let safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillACSSave");
        if (safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let evacuationDrillReportButton = document.getElementById("evacuationDrillReport");
        if (evacuationDrillReportButton !== null)
            evacuationDrillReportButton.addEventListener("click", (e) => this.generatePDF());
        const pimsQuestionRadios = document.getElementsByClassName("pimsQuestion");
        for (const pimsQuestionRadio of pimsQuestionRadios)
            pimsQuestionRadio.addEventListener("change", (e) => this.checkPIMSQuestion());
        const safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS");
        if (safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS.addEventListener("change", (e) => this.uploadFile(e));
        this.bindDeleteFiles();
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e.target));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillACSForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const radioInputs = document.getElementsByClassName("safeSchoolsBusEvacuationBusEvacuationDrillACSRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = "0";
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const allFileElements = [];
        const files = document.getElementsByClassName("safeSchoolsBusEvacuationBusEvacuationDrillACSFileField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = 0;
            if (!isNaN(parseInt(element.dataset.fileuploadpk)))
                fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const data = {
            "Elements": allSaveElements,
            "Files": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationBusEvacuationDrillACS', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }
    generatePDF() {
        let formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillACSForm");
        let planFK = formElement.dataset.planfk;
        Core.showLoader();
        this.howManySchools(parseInt(planFK)).then((count) => {
            let filename = "BusEvacuationDrillDetail.pdf";
            if (count > 20) {
                filename = "BusEvacuationDrillDetail.zip";
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/GenerateBusEvacuationDrillACSPDF', true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = 'blob';
            xhr.onload = function () {
                Core.hideLoader();
                if (xhr.status === 200) {
                    let blob = this.response;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    howManySchools(planFK) {
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            let count = 0;
            const response = yield fetch(`/SafeSchools/GetSchoolCount/${planFK}`, { credentials: 'include' });
            if (response.ok) {
                const json = yield response.json();
                count = json.schoolCount;
                resolve(count);
            }
            else {
                reject("Error");
            }
        }));
    }
    checkPIMSQuestion() {
        const pimsQuestionRadio = document.querySelector(".pimsQuestion:checked");
        if (pimsQuestionRadio !== null) {
            const yesSelected = pimsQuestionRadio.dataset.lookupcode === "yes";
            const busEvacPIMSFileUpload = document.getElementById("busEvacPIMSFileUpload");
            const safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS");
            if (yesSelected) {
                busEvacPIMSFileUpload.classList.add("hide");
                this._core.forceElementOptional(safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS);
            }
            else {
                busEvacPIMSFileUpload.classList.remove("hide");
                this._core.forceElementRequired(safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS);
            }
        }
    }
    uploadFile(e) {
        const core = this._core;
        Core.showLoader();
        const element = e.target;
        const formName = element.dataset.formname;
        const uploadPIMS = document.querySelector(`#${formName}`);
        const formData = new FormData(uploadPIMS);
        const that = this;
        const propertyFK = element.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        element.value = "";
                        let fileList = document.querySelector(`#pimsFileUploads`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        fileA.classList.add("safeSchoolsBusEvacuationBusEvacuationDrillACSFileField");
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyFK;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                        const busEvacPIMSFileUploadFiles = document.getElementById("busEvacPIMSFileUploadFiles");
                        busEvacPIMSFileUploadFiles.classList.remove("hide");
                        fileList.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFile(e) {
        const fileButton = e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        const modal = new Modal("deleteFileModal", null);
        const deleteConfirmButton = document.getElementById("deleteFileConfirm");
        deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
        modal.show();
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindDeleteFiles() {
        const allDeletes = document.getElementsByClassName("deleteFile");
        for (const del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteFile(e));
    }
}
// SafeSchoolsBusEvacuationBusEvacuationDrillSummary
class SafeSchoolsBusEvacuationBusEvacuationDrillSummary {
    constructor() {
        this.validationClasses = ["safeSchoolsBusEvacuationBusEvacuationDrillSummaryField"];
        this._core = new Core();
        let safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillSummarySave");
        if (safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillACSSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let evacuationDrillReportButton = document.getElementById("evacuationDrillSummary");
        if (evacuationDrillReportButton !== null)
            evacuationDrillReportButton.addEventListener("click", (e) => this.generatePDF());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillSummaryForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsBusEvacuationBusEvacuationDrillSummaryField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchoolsBusEvacuation/SaveSafeSchoolsBusEvacuationBusEvacuationSummary', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    generatePDF() {
        let formElement = document.getElementById("safeSchoolsBusEvacuationBusEvacuationDrillSummaryForm");
        let planFK = formElement.dataset.planfk;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/GenerateBusEvacuationDrillSummaryPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "BusEvacuationDrillSummary.pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
}
// SafeSchoolsBusEvacuationSignatures
class SafeSchoolsBusEvacuationSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsBusEvacuationSignaturesField", "safeSchoolsBusEvacuationSignaturesCheckboxField", "safeSchoolsBusEvacuationSignaturesRadioField"];
        let safeSchoolsBusEvacuationSignaturesSaveButton = document.getElementById("safeSchoolsBusEvacuationSignaturesSave");
        if (safeSchoolsBusEvacuationSignaturesSaveButton !== null)
            safeSchoolsBusEvacuationSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const safeSchoolsBusEvacuationSignaturesProvideTransportationRadios = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesProvideTransportation");
        for (const safeSchoolsBusEvacuationSignaturesProvideTransportationRadio of safeSchoolsBusEvacuationSignaturesProvideTransportationRadios)
            safeSchoolsBusEvacuationSignaturesProvideTransportationRadio.addEventListener("change", (e) => this.changeTransportation());
        this.changeTransportation();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsBusEvacuationSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        const radioInputs = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsBusEvacuationSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(validationClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        validationClasses.forEach(function (part, index) {
            //if(validationClasses[index].indexOf("Checkbox") < 0)
            formattedAllClasses[index] = "." + validationClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        //need to put this back in if we add the other checkboxes back in
        //const anyChecked = document.querySelectorAll(".safeSchoolsBusEvacuationSignaturesCheckboxField:checked");
        //const allCheckboxes = document.querySelectorAll(".safeSchoolsBusEvacuationSignaturesCheckboxField");
        //if (anyChecked.length === 0) {
        //    for (const check of allCheckboxes)
        //        this._core.forceElementRequired(check as HTMLElement);
        //    totalErrors++;
        //    showMessage = true;
        //} else {
        //    for (const check of allCheckboxes)
        //        this._core.forceElementOptional(check as HTMLElement);
        //}
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox" && !inputElement.checked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    initializeRequiredFieldsCustom(validationClasses) {
        this._core.initializeRequiredFields(validationClasses);
    }
    changeTransportation() {
        const safeSchoolsBusEvacuationSignaturesProvideTransportationRadios = document.getElementsByClassName("safeSchoolsBusEvacuationSignaturesProvideTransportation");
        const assuranceContainer = document.getElementById("assurance");
        const safeSchoolsBusEvacuationsSignaturesCertificationElement = document.getElementById("safeSchoolsBusEvacuationsSignaturesCertification");
        for (const safeSchoolsBusEvacuationSignaturesProvideTransportationRadio of safeSchoolsBusEvacuationSignaturesProvideTransportationRadios) {
            const element = safeSchoolsBusEvacuationSignaturesProvideTransportationRadio;
            if (element.checked) {
                if (element.dataset.lookupcode === "yes") {
                    assuranceContainer.classList.remove("hide");
                    this._core.forceElementRequired(safeSchoolsBusEvacuationsSignaturesCertificationElement);
                }
                else if (element.dataset.lookupcode === "no") {
                    assuranceContainer.classList.add("hide");
                    this._core.forceElementOptional(safeSchoolsBusEvacuationsSignaturesCertificationElement);
                }
            }
        }
    }
}
// SafeSchoolsBusEvacuationSummaryChecklist
class SafeSchoolsBusEvacuationSummaryChecklist {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// SafeSchoolsFireEvacuationProfile
class SafeSchoolsFireEvacuationProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsFireEvacuationProfileField"];
        let safeSchoolsFireEvacuationProfileSaveButton = document.getElementById("safeSchoolsFireEvacuationProfileSave");
        if (safeSchoolsFireEvacuationProfileSaveButton !== null)
            safeSchoolsFireEvacuationProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("safeSchoolsFireEvacuationProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsFireEvacuationProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("safeSchoolsFireEvacuationProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsFireEvacuationReport
class SafeSchoolsFireEvacuationReport {
    constructor() {
        this._core = new Core();
        this.checkPIMSQuestion();
        this.validationClasses = ["safeSchoolsFireEvacuationReportField", "safeSchoolsFireEvacuationReportRadioField", "safeSchoolsFireEvacuationReportFileUploadField"];
        let safeSchoolsFireEvacuationReportSaveButton = document.getElementById("safeSchoolsFireEvacuationReportSave");
        if (safeSchoolsFireEvacuationReportSaveButton !== null)
            safeSchoolsFireEvacuationReportSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fireDrillReportButton = document.getElementById("fireDrillReport");
        if (fireDrillReportButton !== null)
            fireDrillReportButton.addEventListener("click", (e) => this.generatePDF());
        const pimsQuestionRadios = document.getElementsByClassName("pimsQuestion");
        for (const pimsQuestionRadio of pimsQuestionRadios)
            pimsQuestionRadio.addEventListener("change", (e) => this.checkPIMSQuestion());
        const safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS = document.getElementById("safeSchoolsFireEvacuationFireEvacuationReportUploadRecordfromPIMS");
        if (safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS !== null)
            safeSchoolsBusEvacuationBusEvacuationDrillUploadRecordfromPIMS.addEventListener("change", (e) => this.uploadFile(e));
        this.bindDeleteFiles();
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e.target));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const radioInputs = document.getElementsByClassName("safeSchoolsFireEvacuationReportRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = "0";
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const allFileElements = [];
        const files = document.getElementsByClassName("safeSchoolsFireEvacuationReportFileField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = 0;
            if (!isNaN(parseInt(element.dataset.fileuploadpk)))
                fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const data = {
            "Elements": allSaveElements,
            "Files": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationReport', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }
    generatePDF() {
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planFK = formElement.dataset.planfk;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/GenerateFireDrillReportPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "FireEvacuationDrillDetail.pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    checkPIMSQuestion() {
        const pimsQuestionRadio = document.querySelector(".pimsQuestion:checked");
        if (pimsQuestionRadio !== null) {
            const yesSelected = pimsQuestionRadio.dataset.lookupcode === "yes";
            const fireEvacPIMSFileUpload = document.getElementById("fireEvacPIMSFileUpload");
            const safeSchoolsFireEvacuationFireEvacuationDrillUploadRecordfromPIMS = document.getElementById("safeSchoolsFireEvacuationFireEvacuationReportUploadRecordfromPIMS");
            if (yesSelected) {
                fireEvacPIMSFileUpload.classList.add("hide");
                this._core.forceElementOptional(safeSchoolsFireEvacuationFireEvacuationDrillUploadRecordfromPIMS);
            }
            else {
                fireEvacPIMSFileUpload.classList.remove("hide");
                this._core.forceElementRequired(safeSchoolsFireEvacuationFireEvacuationDrillUploadRecordfromPIMS);
            }
        }
    }
    uploadFile(e) {
        const core = this._core;
        Core.showLoader();
        const element = e.target;
        const formName = element.dataset.formname;
        const uploadPIMS = document.querySelector(`#${formName}`);
        const formData = new FormData(uploadPIMS);
        const that = this;
        const propertyFK = element.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        element.value = "";
                        let fileList = document.querySelector(`#pimsFileUploads`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        fileA.classList.add("safeSchoolsFireEvacuationReportFileField");
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyFK;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                        const busEvacPIMSFileUploadFiles = document.getElementById("fireEvacPIMSFileUploadFiles");
                        busEvacPIMSFileUploadFiles.classList.remove("hide");
                        fileList.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFile(e) {
        const fileButton = e.target;
        const planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        const modal = new Modal("deleteFileModal", null);
        const deleteConfirmButton = document.getElementById("deleteFileConfirm");
        deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
        modal.show();
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/Base/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                        const allUploads = document.querySelectorAll("#pimsFileUploads .uploadFileColumn");
                        if (allUploads.length === 0) {
                            const busEvacPIMSFileUploadFiles = document.getElementById("fireEvacPIMSFileUploadFiles");
                            busEvacPIMSFileUploadFiles.classList.add("hide");
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindDeleteFiles() {
        const allDeletes = document.getElementsByClassName("deleteFile");
        for (const del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteFile(e));
    }
}
// SafeSchoolsFireEvacuationDrill 
class SafeSchoolsFireEvacuationDrill {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsFireEvacuationReportField"];
        let safeSchoolsFireEvacuationReportSaveButton = document.getElementById("safeSchoolsFireEvacuationReportSave");
        if (safeSchoolsFireEvacuationReportSaveButton !== null)
            safeSchoolsFireEvacuationReportSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fireDrillSummaryButton = document.getElementById("fireDrillSummary");
        if (fireDrillSummaryButton !== null)
            fireDrillSummaryButton.addEventListener("click", (e) => this.generatePDF());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsFireEvacuationReportField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationReport', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    generatePDF() {
        let formElement = document.getElementById("safeSchoolsFireEvacuationReportForm");
        let planFK = formElement.dataset.planfk;
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/GenerateFireDrillSummaryPDF', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "FireEvacuationDrillSummary.pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
}
// SafeSchoolsFireEvacuationSignatures
class SafeSchoolsFireEvacuationSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsFireEvacuationSignaturesField", "safeSchoolsFireEvacuationSignaturesCheckboxField"];
        let safeSchoolsFireEvacuationSignaturesSaveButton = document.getElementById("safeSchoolsFireEvacuationSignaturesSave");
        if (safeSchoolsFireEvacuationSignaturesSaveButton !== null)
            safeSchoolsFireEvacuationSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsFireEvacuationSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsFireEvacuationSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("safeSchoolsFireEvacuationSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsFireEvacuationSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsFireEvacuationSummaryChecklist
class SafeSchoolsFireEvacuationSummaryChecklist {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// SafeSchoolsMOUProfile
class SafeSchoolsMOUProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsMOUProfileField"];
        let safeSchoolsMOUProfileSaveButton = document.getElementById("safeSchoolsMOUProfileSave");
        if (safeSchoolsMOUProfileSaveButton !== null)
            safeSchoolsMOUProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("safeSchoolsMOUProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsMOUProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("safeSchoolsMOUProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsMOUDocumentation
class SafeSchoolsMOUDocumentation {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsMOUDocumentationField", "safeSchoolsMOUDocumentationCheckField", "safeSchoolssMOUDocumentationFileField", "safeSchoolsMOUDocumentationSelectField"];
        let safeSchoolsMOUDocumentationSaveButton = document.getElementById("safeSchoolsMOUDocumentationSave");
        if (safeSchoolsMOUDocumentationSaveButton !== null)
            safeSchoolsMOUDocumentationSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteButton of deleteFileButtons)
            deleteButton.addEventListener("click", (e) => this.deleteFile(e));
        this.bindFileUploads();
        this.bindDeleteButtons();
        this.bindDeleteMOUs();
        let deleteEnforcementConfirmButton = document.getElementById("safeSchoolsMOURowConfirm");
        if (deleteEnforcementConfirmButton !== null)
            deleteEnforcementConfirmButton.addEventListener("click", (e) => this.deleteMOUConfirm(e));
        let deleteEnforcementCancelButton = document.getElementById("safeSchoolsMOURowCancel");
        if (deleteEnforcementCancelButton !== null)
            deleteEnforcementCancelButton.addEventListener("click", (e) => this.deleteMOUCancel());
        let safeSchoolsMOUDocumentationAddMOUButton = document.getElementById("safeSchoolsMOUDocumentationAddMOU");
        if (safeSchoolsMOUDocumentationAddMOUButton !== null)
            safeSchoolsMOUDocumentationAddMOUButton.addEventListener("click", (e) => this.addAnotherMOU());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUDocumentationForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsMOUDocumentationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("safeSchoolsMOUDocumentationCheckField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let selects = document.getElementsByClassName("safeSchoolsMOUDocumentationSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.row);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUDocumentation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let row = e.dataset.row;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.getElementById(`${e.id}`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.row = row;
                        fileA.classList.add("safeSchoolsFileField");
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteButtons();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteButtons() {
        let allDeleteButtons = document.getElementsByClassName("deleteFile");
        for (let delButton of allDeleteButtons)
            delButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        let row = fileButton.dataset.row;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.row = row;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let row = confirmButton.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                        let uploads = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
                        if (uploads.length === 0) {
                            let uploadContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                            uploadContainer.classList.add("hide");
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    addAnotherMOU() {
        let planForm = document.getElementById("safeSchoolsMOUDocumentationForm");
        let planFK = planForm.dataset.planfk;
        let that = this;
        let newRow = 0;
        Core.showLoader();
        let list = document.getElementsByClassName("mouListItem");
        for (let item of list) {
            let ele = item;
            if (ele.dataset.row && parseInt(ele.dataset.row) > newRow) {
                newRow = parseInt(ele.dataset.row);
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/AddSafeSchoolsMOUDocumentation', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.getElementById("mouList");
                let newDiv = document.createElement("div");
                newDiv.innerHTML = xhr.responseText;
                newDiv.dataset.row = newRow.toString();
                newDiv.classList.add("mouListItem");
                newDiv.classList.add("mou-list-item");
                container.append(newDiv);
                that.bindFileUploads();
                that.bindDeleteMOUs();
                that._core.initializeRequiredFields(that.validationClasses, false, true);
                Core.createHTMLAlert("alertMessageDiv", "MOU record added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}`);
    }
    bindDeleteMOUs() {
        const deletes = document.getElementsByClassName("deleteMOURow");
        for (const del of deletes) {
            del.addEventListener("click", (e) => this.showDeleteMOURow(e));
        }
    }
    showDeleteMOURow(e) {
        const button = e.target;
        const row = button.dataset.row;
        let modal = new Modal("deleteMOUModal", null);
        modal.addAttributeToElement("deleteMOUModal", "#safeSchoolsMOURowConfirm", "row", row);
        modal.show();
    }
    deleteMOUCancel() {
        let modal = new Modal("deleteMOUModal", null);
        modal.hide();
    }
    deleteMOUConfirm(e) {
        const button = e.target;
        const row = button.dataset.row;
        let core = this._core;
        Core.showLoader();
        const planPropertyFiles = [];
        const planPropertyPKs = [];
        const allChecks = document.querySelectorAll(`.safeSchoolsMOUDocumentationCheckField[data-row='${row}']`);
        for (const check of allChecks) {
            const ele = check;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }
        const alltexts = document.querySelectorAll(`.safeSchoolsMOUDocumentationField[data-row='${row}']`);
        for (const text of alltexts) {
            const ele = text;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }
        const allSelects = document.querySelectorAll(`.safeSchoolsMOUDocumentationSelectField[data-row='${row}']`);
        for (const select of allSelects) {
            const ele = select;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }
        const allFiles = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
        for (const file of allFiles) {
            const ele = file;
            if (ele.dataset.planpropertyfilepk && parseInt(ele.dataset.planpropertyfilepk) > 0)
                planPropertyFiles.push(ele.dataset.planpropertyfilepk);
        }
        const data = {
            "PlanPropertyPKs": planPropertyPKs,
            "PlanPropertyFilePKs": planPropertyFiles
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteMOURow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The MOU has been successfully deleted.", 'success', 3000, null);
                        const container = document.querySelector(`.mouListItem[data-row='${row}']`);
                        if (container)
                            container.remove();
                        let modal = new Modal("deleteMOUModal", null);
                        modal.hide();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
            }
            let modal = new Modal("deleteMOUModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify(data));
    }
    bindFileUploads() {
        let fileUploaders = document.getElementsByClassName("safeSchoolsMOUDocumentationUploader");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
}
// SafeSchoolsMOUSignatures
class SafeSchoolsMOUSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsMOUSignaturesField", "safeSchoolsMOUSignaturesCheckboxField"];
        let safeSchoolsMOUSignaturesSaveButton = document.getElementById("safeSchoolsMOUSignaturesSave");
        if (safeSchoolsMOUSignaturesSaveButton !== null)
            safeSchoolsMOUSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsMOUSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsMOUSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("safeSchoolsMOUSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsMOUSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsMOUSummaryChecklist
class SafeSchoolsMOUSummaryChecklist {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// SafeSchoolsAnnualACSProfile
class SafeSchoolsAnnualACSProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsAnnualACSProfileField"];
        let safeSchoolsAnnualACSProfileSaveButton = document.getElementById("safeSchoolsAnnualACSProfileSave");
        if (safeSchoolsAnnualACSProfileSaveButton !== null)
            safeSchoolsAnnualACSProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let educatorEffectivenessExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (educatorEffectivenessExecutiveSaveButton !== null)
            educatorEffectivenessExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("safeSchoolsAnnualACSProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsAnnualACSProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("safeSchoolsAnnualACSProfileSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SafeSchoolsAnnualACSSafeSchoolsACS
class SafeSchoolsAnnualACSSafeSchoolsACS {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsAnnualACSSafeSchoolsACSField"];
        let safeSchoolsAnnualACSMisconductSaveButton = document.getElementById("safeSchoolsAnnualACSSafeSchoolsACSSave");
        if (safeSchoolsAnnualACSMisconductSaveButton !== null)
            safeSchoolsAnnualACSMisconductSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exportLEAReportButton = document.getElementById("safeSchoolsAnnualACSLEAExport");
        if (exportLEAReportButton !== null)
            exportLEAReportButton.addEventListener("click", (e) => this.exportReport(e));
        let exportSchoolReportButton = document.getElementById("safeSchoolsAnnualACSSchoolExport");
        if (exportSchoolReportButton !== null)
            exportSchoolReportButton.addEventListener("click", (e) => this.exportReport(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSMisconductForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsAnnualACSMisconductField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSMisconduct', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    exportReport(e) {
        let formElement = document.getElementById("safeSchoolsAnnualACSSafeSchoolsACSForm");
        let planFK = formElement.dataset.planfk;
        let button = e.target;
        let pageCode = button.dataset.pagecode;
        let whichReport = button.dataset.report;
        Core.showLoader();
        let url = `SafeSchoolsAnnualReport${whichReport}Export`;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', `/SafeSchools/${url}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'blob';
        xhr.onload = function () {
            Core.hideLoader();
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = `SafeSchoolsACS${whichReport}.pdf`;
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}&pageCode=${pageCode}`);
    }
}
// SafeSchoolsAnnualACSSignatures
class SafeSchoolsAnnualACSSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["safeSchoolsAnnualACSSignaturesField", "safeSchoolsAnnualACSSignaturesUploadChiefLawSignature", "safeSchoolsAnnualACSSignaturesCheckboxField"];
        let safeSchoolsAnnualACSSignaturesSaveButton = document.getElementById("safeSchoolsAnnualACSSignaturesSave");
        if (safeSchoolsAnnualACSSignaturesSaveButton !== null)
            safeSchoolsAnnualACSSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this.bindExplain();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let safeSchoolsACSLawEnforcementAddButton = document.getElementById("safeSchoolsACSLawEnforcementAdd");
        if (safeSchoolsACSLawEnforcementAddButton !== null)
            safeSchoolsACSLawEnforcementAddButton.addEventListener("click", (e) => this.addLawEnforcement());
        this.bindUploaders();
        this.bindDeleteButtons();
        this.bindDeleteEnforcements();
        let deleteEnforcementConfirmButton = document.getElementById("safeSchoolsEnforcementRowConfirm");
        if (deleteEnforcementConfirmButton !== null)
            deleteEnforcementConfirmButton.addEventListener("click", (e) => this.deleteEnforcementConfirm(e));
        let deleteEnforcementCancelButton = document.getElementById("safeSchoolsEnforcementRowCancel");
        if (deleteEnforcementCancelButton !== null)
            deleteEnforcementCancelButton.addEventListener("click", (e) => this.deleteEnforcementCancel());
        let exportReport = document.getElementById("exportFullReport");
        if (exportReport !== null) {
            exportReport.addEventListener("click", function () {
                Core.showLongLoader();
                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocx/FullReportSafeSchoolsAnnualACSExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    Core.hideLongLoader();
                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = exportReport.textContent;
                        filename = filename + ".pdf";
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        }
                        else {
                            var a = document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                };
                xhr.send("planFK=" + exportReport.dataset.planfk + "&templateFK=" + exportReport.dataset.templatefk);
            });
        }
        const safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignatureUploader = document.querySelector(".safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignature");
        if (safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignatureUploader !== null)
            safeSchoolsAnnualACSSignaturesChiefLawEnforcementOfficerSignatureUploader.addEventListener("change", (e) => this.uploadLawEnforcement(e.target));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("safeSchoolsAnnualACSSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.row);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/SaveSafeSchoolsAnnualACSSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData === null) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let row = e.dataset.row;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.getElementById(`${e.id}`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.row = row;
                        fileA.classList.add("safeSchoolsFileField");
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteButtons();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    deleteFile(e) {
        let fileButton = e.target;
        let planPropertyFilePK = fileButton.dataset.planpropertyfilepk;
        let row = fileButton.dataset.row;
        if (planPropertyFilePK && parseInt(planPropertyFilePK) > 0) {
            let modal = new Modal("deleteFileModal", null);
            let deleteConfirmButton = document.getElementById("deleteFileConfirm");
            deleteConfirmButton.dataset.planpropertyfilepk = planPropertyFilePK;
            deleteConfirmButton.dataset.row = row;
            modal.show();
        }
    }
    deleteFileConfirm(confirmButton) {
        let core = this._core;
        Core.showLoader();
        let row = confirmButton.dataset.row;
        let planPropertyFilePK = confirmButton.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteFiles', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file has been successfully deleted.", 'success', 3000, null);
                        let containerElement = document.querySelector(`.uploadFileColumn[data-planpropertyfilepk='${planPropertyFilePK}']`);
                        if (containerElement !== null) {
                            containerElement.parentNode.removeChild(containerElement);
                        }
                        let uploads = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
                        if (uploads.length === 0) {
                            let uploadContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                            uploadContainer.classList.add("hide");
                        }
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting file.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file removal failed. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify([planPropertyFilePK]));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindUploaders() {
        let fileUploaders = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesUploadChiefLawSignature");
        for (let fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    bindDeleteButtons() {
        let allDeleteButtons = document.getElementsByClassName("deleteFile");
        for (let delButton of allDeleteButtons)
            delButton.addEventListener("click", (e) => this.deleteFile(e));
    }
    addLawEnforcement() {
        let planForm = document.getElementById("safeSchoolsAnnualACSSignaturesForm");
        let planFK = planForm.dataset.planfk;
        let that = this;
        let newRow = 0;
        Core.showLoader();
        let list = document.getElementsByClassName("safeSchoolsACSLawEnforcement");
        for (let item of list) {
            let ele = item;
            if (ele.dataset.row && parseInt(ele.dataset.row) > newRow) {
                newRow = parseInt(ele.dataset.row);
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/AddSafeSchoolsAnnualACSLawEnforcement', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let container = document.getElementById("safeSchoolsACSLawEnforcementList");
                let newDiv = document.createElement("div");
                newDiv.innerHTML = xhr.responseText;
                newDiv.dataset.row = newRow.toString();
                newDiv.classList.add("safeSchoolsACSLawEnforcement");
                newDiv.classList.add("mou-list-item");
                container.append(newDiv);
                that.bindUploaders();
                that.bindExplain();
                let file = document.getElementById("safeSchoolsAnnualACSSignaturesUploadChiefLawSignature" + newRow.toString());
                that._core.forceElementRequired(file);
                that.bindDeleteEnforcements();
                Core.createHTMLAlert("alertMessageDiv", "Local Enforcement Agency with School Entity Jurisdiction record added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}`);
    }
    bindExplain() {
        const checkboxes = document.getElementsByClassName("safeSchoolsAnnualACSSignaturesCheckboxFieldHide");
        for (let checkbox of checkboxes) {
            checkbox.addEventListener("click", (e) => this.ExplanationClick(e.target));
            this.ExplanationClick(checkbox);
        }
    }
    ExplanationClick(checkbox) {
        const row = checkbox.dataset.row;
        const ExplainInput = document.getElementById("safeSchoolsAnnualACSSignaturesExplanation" + row);
        const ExplainRow = document.getElementById("Explanation_" + row);
        if (checkbox.checked) {
            this._core.forceElementRequired(ExplainInput);
            ExplainRow.classList.remove("hide");
        }
        else {
            this._core.forceElementOptional(ExplainInput);
            ExplainRow.classList.add("hide");
        }
    }
    bindDeleteEnforcements() {
        const deletes = document.getElementsByClassName("deleteEnforcementRow");
        for (const del of deletes) {
            del.addEventListener("click", (e) => this.showDeleteEnforcementRow(e));
        }
    }
    showDeleteEnforcementRow(e) {
        const button = e.target;
        const row = button.dataset.row;
        let modal = new Modal("deleteEnforcementModal", null);
        modal.addAttributeToElement("deleteEnforcementModal", "#safeSchoolsEnforcementRowConfirm", "row", row);
        modal.show();
    }
    deleteEnforcementCancel() {
        let modal = new Modal("deleteEnforcementModal", null);
        modal.hide();
    }
    deleteEnforcementConfirm(e) {
        const button = e.target;
        const row = button.dataset.row;
        let core = this._core;
        Core.showLoader();
        const planPropertyFiles = [];
        const planPropertyPKs = [];
        const allChecks = document.querySelectorAll(`.safeSchoolsAnnualACSSignaturesCheckboxField[data-row='${row}']`);
        for (const check of allChecks) {
            const ele = check;
            if (ele.dataset.planpropertypk && parseInt(ele.dataset.planpropertypk) > 0)
                planPropertyPKs.push(ele.dataset.planpropertypk);
        }
        const allFiles = document.querySelectorAll(`.uploadFileColumn[data-row='${row}']`);
        for (const file of allFiles) {
            const ele = file;
            if (ele.dataset.planpropertyfilepk && parseInt(ele.dataset.planpropertyfilepk) > 0)
                planPropertyFiles.push(ele.dataset.planpropertyfilepk);
        }
        const data = {
            "PlanPropertyPKs": planPropertyPKs,
            "PlanPropertyFilePKs": planPropertyFiles
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/DeleteEnforcementRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The row has been successfully deleted.", 'success', 3000, null);
                        const container = document.querySelector(`.safeSchoolsACSLawEnforcement[data-row='${row}']`);
                        if (container)
                            container.remove();
                        let modal = new Modal("deleteEnforcementModal", null);
                        modal.hide();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting the row.", 'error', 3000, null);
            }
            let modal = new Modal("deleteFileModal", null);
            modal.hide();
        };
        xhr.send(JSON.stringify(data));
    }
    uploadLawEnforcement(e) {
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let row = "0";
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SafeSchools/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.getElementById(`${e.id}`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-lawofficer="true"]`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-lawofficer="true"]`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.row = row;
                        fileA.classList.add("safeSchoolsFileField");
                        fileA.classList.add("safeSchoolsFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteButtons();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}
// SafeSchoolsAnnualACSSummary
class SafeSchoolsAnnualACSSummary {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SafeSchools/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
class Workflow {
    //Initialize all actions in the DOM
    constructor(workflowInstancePK) {
        this.ReviewURL = '/Review/HandleReviewPageLocation';
        let that = this;
        that.WorkflowInstancePK = workflowInstancePK;
        that.ActionResponses = [];
        that.CanSave = true;
        that.ConfirmDialog = new WorkflowConfirmDialog();
        that.BeforeSave = () => Promise.resolve();
        let actions = document.querySelectorAll(`[data-action][data-workflow-instance-fk='${workflowInstancePK}']`);
        let usedRadioNames = [];
        for (let action of actions) {
            let actionElement = action;
            if (actionElement instanceof HTMLInputElement) {
                if (actionElement.type == "radio") {
                    if (usedRadioNames.indexOf(actionElement.name) === -1) {
                        that.ActionResponses.push(new WorkflowActionResponseRadio(actionElement));
                        usedRadioNames.push(actionElement.name);
                    }
                }
                else if (actionElement.type == "checkbox") {
                    that.ActionResponses.push(new WorkflowActionResponseCheckbox(actionElement));
                }
                else {
                    that.ActionResponses.push(new WorkflowActionResponseInput(actionElement));
                }
            }
            else if (actionElement instanceof HTMLSelectElement) {
                that.ActionResponses.push(new WorkflowActionResponseSelect(actionElement));
            }
            else if (actionElement instanceof HTMLButtonElement) {
                let buttonInit = new WorkflowActionResponseButton(actionElement);
                that.ActionResponses.push(buttonInit);
                //Handle save button clicks
                switch (buttonInit.ActionType) {
                    case "saveButton":
                        if (!("eventAdded" in actionElement.dataset)) {
                            actionElement.dataset.eventAdded = "true";
                            actionElement.addEventListener("click", () => {
                                let url = window.location.href;
                                if (url.indexOf("fromReviewSave") === -1) {
                                    if (window.location.search.length > 0) {
                                        url += "&fromReviewSave=true";
                                    }
                                    else {
                                        url += "?fromReviewSave=true";
                                    }
                                }
                                Core.showLoader();
                                let beforeSavePromise = that.BeforeSave();
                                beforeSavePromise
                                    .then(() => {
                                    return that.Save();
                                })
                                    .then((success) => {
                                    window.location.href = url;
                                })
                                    .catch((error) => {
                                    Core.hideLoader();
                                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                });
                            });
                        }
                        break;
                    case "submitReviewButton":
                    case "submitReviewButtonWithoutValidation":
                    case "denyReviewButton":
                        if (!("eventAdded" in actionElement.dataset)) {
                            actionElement.dataset.eventAdded = "true";
                            actionElement.addEventListener("click", () => {
                                if (buttonInit.ActionType == "submitReviewButton" && this.ActionResponses.some(action => !action.IsModalAction && action.IsRequired && !action.HasValue())) {
                                    that.Validate();
                                }
                                else {
                                    that.ConfirmDialog.confirm(buttonInit.Element.innerHTML, buttonInit.Element.id)
                                        .then((confirm) => {
                                        if (confirm) {
                                            buttonInit.SubmitAction = true;
                                            Core.showLoader();
                                            let beforeSavePromise = that.BeforeSave();
                                            beforeSavePromise
                                                .then(() => {
                                                return that.Save();
                                            })
                                                .then((success) => {
                                                if (buttonInit.ActionCode == 'authorizedLEAAcknowledge') {
                                                    Core.createHTMLAlert("alertMessageDiv", 'Successfully acknowledged report', 'success', 3000, window.location.reload());
                                                }
                                                else {
                                                    window.location.href = that.ReviewURL;
                                                }
                                            })
                                                .catch((error) => {
                                                Core.hideLoader();
                                                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                        Core.hideLoader();
                                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                    });
                                }
                            });
                        }
                        break;
                    case "modalApproveConfirm":
                    case "modalReturnConfirm":
                        if (!("eventAdded" in actionElement.dataset)) {
                            actionElement.dataset.eventAdded = "true";
                            actionElement.addEventListener("click", () => {
                                //First, check to see that all required fields are filled out
                                let allRequiredFilledOut = true;
                                for (let action of that.ActionResponses) {
                                    //Only check elements that are in the same modal as the button
                                    if (action.IsModalAction && action.IsRequired && buttonInit.ModalContainerId == action.ModalContainerId && !action.HasValue()) {
                                        allRequiredFilledOut = false;
                                        break;
                                    }
                                }
                                if (allRequiredFilledOut) {
                                    buttonInit.SubmitAction = true;
                                    Core.showLoader();
                                    let beforeSavePromise = that.BeforeSave();
                                    beforeSavePromise
                                        .then(() => {
                                        return that.Save(buttonInit.ModalContainerId);
                                    })
                                        .then((success) => {
                                        window.location.href = that.ReviewURL;
                                    })
                                        .catch((error) => {
                                        Core.hideLoader();
                                        that.ReturnModal.hide();
                                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                                    });
                                }
                                else {
                                    let alert = document.querySelector(`#${buttonInit.ModalContainerId} .workflowModalAlert`);
                                    if (alert != null) {
                                        alert.classList.remove("hide");
                                        //Show errors for required elements without value
                                        for (let action of that.ActionResponses) {
                                            if (action.IsModalAction && action.IsRequired && buttonInit.ModalContainerId == action.ModalContainerId && !action.HasValue()) {
                                                action.Element.classList.add("missing-field");
                                                action.Element.setAttribute("aria-invalid", "true");
                                                Core.createErrorLabelForInput(action.Element);
                                            }
                                        }
                                    }
                                }
                            });
                        }
                }
            }
            else if (actionElement instanceof HTMLTextAreaElement) {
                that.ActionResponses.push(new WorkflowActionResponseTextArea(actionElement));
            }
        }
        //Initialize multiselect
        $('.actionMultiSelect').MultiSelect({
            ShowAllCheckbox: false
        });
        that.InitializeRequiredActions();
        if (window.location.href.indexOf("fromReviewSave") !== -1) {
            that.Validate();
        }
        //Event listener for deleting comments
        let deleteCommentButtons = document.getElementsByClassName("deleteComment");
        for (let deleteButton of deleteCommentButtons) {
            if ("workflowInstanceActionResponsePk" in deleteButton.dataset) {
                if (!("eventAdded" in deleteButton.dataset)) {
                    deleteButton.dataset.eventAdded = "true";
                    deleteButton.addEventListener("click", () => {
                        that.DeleteComment(parseInt(deleteButton.dataset.workflowInstanceActionResponsePk));
                    });
                }
            }
        }
        //Initialize Modals
        if (document.getElementById(`workflowReturnModal${that.WorkflowInstancePK}`) != null) {
            that.ReturnModal = new Modal(`workflowReturnModal${that.WorkflowInstancePK}`, null);
            //Open modal on click of modalTriggerReturn action type
            let modalTriggerReturn = document.querySelector(`.review-container-actions [data-action-type='modalTriggerReturn'][data-workflow-instance-fk='${that.WorkflowInstancePK}']`);
            if (modalTriggerReturn != null) {
                if (!("eventAdded" in modalTriggerReturn.dataset)) {
                    modalTriggerReturn.dataset.eventAdded = "true";
                    modalTriggerReturn.addEventListener("click", () => {
                        if (that.ActionResponses.some(action => !action.IsModalAction && action.IsRequired && !action.HasValue())) {
                            that.Validate();
                        }
                        else {
                            that.ReturnModal.show();
                        }
                    });
                }
            }
            let cancelButton = document.getElementById(`returnCancel${that.WorkflowInstancePK}`);
            if (cancelButton != null) {
                if (!("eventAdded" in cancelButton.dataset)) {
                    cancelButton.dataset.eventAdded = "true";
                    cancelButton.addEventListener("click", () => {
                        that.ReturnModal.hide();
                    });
                }
            }
        }
        else {
            that.ReturnModal = null;
        }
        if (document.getElementById(`workflowApproveModal${that.WorkflowInstancePK}`) != null) {
            that.ApproveModal = new Modal(`workflowApproveModal${that.WorkflowInstancePK}`, null);
            //Open modal on click of modalTriggerApprove action type (should only be one on page)
            let modalTriggerApprove = document.querySelector(`.review-container-actions [data-action-type='modalTriggerApprove'][data-workflow-instance-fk='${that.WorkflowInstancePK}']`);
            if (modalTriggerApprove != null) {
                if (!("eventAdded" in modalTriggerApprove.dataset)) {
                    modalTriggerApprove.dataset.eventAdded = "true";
                    modalTriggerApprove.addEventListener("click", () => {
                        if (that.ActionResponses.some(action => !action.IsModalAction && action.IsRequired && !action.HasValue())) {
                            that.Validate();
                        }
                        else {
                            that.ApproveModal.show();
                        }
                    });
                }
            }
            let cancelButton = document.getElementById(`approveCancel${that.WorkflowInstancePK}`);
            if (cancelButton != null) {
                if (!("eventAdded" in cancelButton.dataset)) {
                    cancelButton.dataset.eventAdded = "true";
                    cancelButton.addEventListener("click", () => {
                        that.ApproveModal.hide();
                    });
                }
            }
        }
        else {
            that.ApproveModal = null;
        }
        //If there are multiple "Feedback to LEA" dropdowns on the page, check only the actions in that group. Else, look at all compliant actions
        let feedbackComments = document.querySelectorAll(`[data-action][data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA']`);
        let feedbackToLEAActions = [];
        let hasMultipleFeedback = false;
        for (let comment of feedbackComments) {
            let commentElement = comment;
            if (feedbackToLEAActions.indexOf(commentElement.dataset.actionPk) == -1) {
                feedbackToLEAActions.push(commentElement.dataset.actionPk);
                if (feedbackToLEAActions.length > 1) {
                    hasMultipleFeedback = true;
                    break;
                }
            }
        }
        //Initialize compliant radio buttons event listener. 
        /*
        Here's the logic:
        When "no" is selected, check the comment like '%Comment' and any comment with lookupcode like '%CommentNonCompliant%'
        When "yes" is selected, check the comment like '%CommentYes'
        The comment with lookupcode like '%CommentCompliant' is checked when all compliant actions are checked "yes" or "na" and not all of them are "na"
        */
        let compliantActions = document.querySelectorAll(`[data-action-type='componentCompliant'][data-workflow-instance-fk='${workflowInstancePK}']:not([disabled]), [data-action-type='componentCompliantNA'][data-workflow-instance-fk='${workflowInstancePK}']:not([disabled])`);
        for (let compliantAction of compliantActions) {
            let compliantActionElement = compliantAction;
            if (!("eventAdded" in compliantActionElement.dataset)) {
                compliantActionElement.addEventListener("change", () => {
                    //Specific edge case
                    if (compliantActionElement.checked && compliantActionElement.dataset.lookupCode == "actionYes" && compliantActionElement.dataset.actionCode == "ch49FirstYearIncluded") {
                        //Check the option of NA for the action 'ch49FirstYearIncludedExplanation'
                        let relatedRadioExplanationNA = document.querySelector("[data-action-code='ch49FirstYearIncludedExplanation'][data-lookup-code='actionNA']");
                        if (relatedRadioExplanationNA != null) {
                            relatedRadioExplanationNA.click();
                        }
                    }
                    //Find the corresponding comment
                    let comment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}Comment']`);
                    if (compliantActionElement.dataset.lookupCode == "actionNo" && compliantActionElement.checked) {
                        //Check the corresponding comment
                        if (comment != null && !comment.checked) {
                            comment.disabled = false;
                            comment.click();
                            comment.disabled = true;
                        }
                        //Uncheck the compliant comment because they said "no"
                        let compliantComment;
                        if (hasMultipleFeedback) {
                            compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentCompliant']`);
                        }
                        else {
                            compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentCompliant']`);
                        }
                        if (compliantComment != null && compliantComment.checked) {
                            compliantComment.disabled = false;
                            compliantComment.click();
                            compliantComment.disabled = true;
                        }
                        //Uncheck comment like '%CommentYes'
                        let yesComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}CommentYes']`);
                        if (yesComment != null && yesComment.checked) {
                            yesComment.disabled = false;
                            yesComment.click();
                            yesComment.disabled = true;
                        }
                        //Check the '%CommentNonCompliant%' comment
                        let nonCompliantComment;
                        if (hasMultipleFeedback) {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentNonCompliant']`);
                        }
                        else {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentNonCompliant']`);
                        }
                        if (nonCompliantComment != null && !nonCompliantComment.checked) {
                            nonCompliantComment.disabled = false;
                            nonCompliantComment.click();
                            nonCompliantComment.disabled = true;
                        }
                    }
                    else if ((compliantActionElement.dataset.lookupCode == "actionYes" || compliantActionElement.dataset.lookupCode == "actionNA") && compliantActionElement.checked) {
                        if (comment != null && comment.checked) {
                            comment.disabled = false;
                            comment.click();
                            comment.disabled = true;
                        }
                        //Check comment like '%CommentYes'
                        if (compliantActionElement.dataset.lookupCode == "actionYes") {
                            let yesComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}CommentYes']`);
                            if (yesComment != null) {
                                yesComment.disabled = false;
                                yesComment.click();
                                yesComment.disabled = true;
                            }
                        }
                        else {
                            //Uncheck comment like '%CommentYes'
                            let yesComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code='${compliantActionElement.dataset.actionCode}CommentYes']`);
                            if (yesComment != null && yesComment.checked) {
                                yesComment.disabled = false;
                                yesComment.click();
                                yesComment.disabled = true;
                            }
                        }
                        //Check to see if all of the other compliant actions are checked yes. If so, check the comment like '%CommentCompliant'
                        let allCheckedYesNA = true;
                        let atLeastOneCheckedYes = false;
                        let atLeastOneCheckedNo = false;
                        let compliantActionsToCheck;
                        if (hasMultipleFeedback) {
                            compliantActionsToCheck = document.querySelectorAll(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliant'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}']:not([disabled]), [data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliantNA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}']:not([disabled])`);
                        }
                        else {
                            compliantActionsToCheck = document.querySelectorAll(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliant'], [data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='componentCompliantNA']:not([disabled])`);
                        }
                        for (let act of compliantActionsToCheck) {
                            let actElement = act;
                            let checkedRadio = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][name='${actElement.name}']:checked`);
                            if (checkedRadio != null) {
                                //Custom logic for the edge case in Induction Plan - Documentation of Participation
                                if (checkedRadio.dataset.actionCode == "ch49FirstYearIncluded") {
                                    if (checkedRadio.dataset.lookupCode == "actionNo") {
                                        //Check to see if ch49FirstYearIncludedExplanation action is "Yes"
                                        let relatedRadio = document.querySelectorAll("[data-action-code='ch49FirstYearIncludedExplanation']");
                                        let relatedIsCheckedYes = false;
                                        for (let radio of relatedRadio) {
                                            let radioElement = radio;
                                            if (radioElement.checked && radioElement.dataset.lookupCode == "actionYes") {
                                                relatedIsCheckedYes = true;
                                            }
                                        }
                                        if (!relatedIsCheckedYes) {
                                            allCheckedYesNA = false;
                                        }
                                    }
                                }
                                else if (checkedRadio.dataset.lookupCode != "actionYes" && checkedRadio.dataset.lookupCode != "actionNA") {
                                    allCheckedYesNA = false;
                                }
                                if (checkedRadio.dataset.lookupCode == "actionYes") {
                                    atLeastOneCheckedYes = true;
                                }
                                if (checkedRadio.dataset.lookupCode == "actionNo") {
                                    atLeastOneCheckedNo = true;
                                }
                            }
                            else {
                                allCheckedYesNA = false;
                            }
                        }
                        if (allCheckedYesNA && atLeastOneCheckedYes) {
                            let compliantComment;
                            if (hasMultipleFeedback) {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentCompliant']`);
                            }
                            else {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentCompliant']`);
                            }
                            if (compliantComment != null && !compliantComment.checked) {
                                compliantComment.disabled = false;
                                compliantComment.click();
                                compliantComment.disabled = true;
                            }
                        }
                        else {
                            let compliantComment;
                            if (hasMultipleFeedback) {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentCompliant']`);
                            }
                            else {
                                compliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentCompliant']`);
                            }
                            if (compliantComment != null && compliantComment.checked) {
                                compliantComment.disabled = false;
                                compliantComment.click();
                                compliantComment.disabled = true;
                            }
                        }
                        let nonCompliantComment;
                        if (hasMultipleFeedback) {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-action-group-code-fk='${compliantActionElement.dataset.actionGroupCodeFk}'][data-lookup-code$='CommentNonCompliant']`);
                        }
                        else {
                            nonCompliantComment = document.querySelector(`[data-workflow-instance-fk='${workflowInstancePK}'][data-action-type='feedbackToLEA'][data-lookup-code$='CommentNonCompliant']`);
                        }
                        if (nonCompliantComment != null) {
                            if ((atLeastOneCheckedNo && !nonCompliantComment.checked) || (!atLeastOneCheckedNo && nonCompliantComment.checked)) {
                                nonCompliantComment.disabled = false;
                                nonCompliantComment.click();
                                nonCompliantComment.disabled = true;
                            }
                        }
                    }
                });
                compliantActionElement.dataset.eventAdded = "true";
            }
        }
    }
    Save(modalId = null) {
        let that = this;
        return new Promise((resolve, reject) => {
            if (that.CanSave) {
                that.CanSave = false;
                let allSaveData = that.GetWorkflowSaveData(modalId);
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/Base/SaveActions', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    //Allow saving again
                    that.CanSave = true;
                    if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                        resolve("Successfully saved review");
                    }
                    else {
                        reject("There was an unexpected error saving review");
                    }
                };
                if (allSaveData.length > 0) {
                    xhr.send(JSON.stringify(allSaveData));
                }
                else {
                    that.CanSave = true;
                    resolve("Nothing to save");
                }
            }
            else {
                resolve("Unable to save review");
            }
        });
    }
    GetWorkflowSaveData(modalId = null) {
        let that = this;
        let allSaveData = [];
        for (let actionResponse of that.ActionResponses.filter(ar => !ar.IsReadOnly && (ar.HasChanged() || (!ar.IsComplete && ar.HasValue())))) {
            if ((modalId != null && actionResponse.IsModalAction && modalId == actionResponse.ModalContainerId) || !actionResponse.IsModalAction) {
                let saveItem = actionResponse.GetSaveItem();
                if (saveItem != null) {
                    allSaveData.push(saveItem);
                }
            }
        }
        return allSaveData;
    }
    InitializeRequiredActions() {
        let that = this;
        for (let action of that.ActionResponses) {
            if (action.IsRequired) {
                action.Element.setAttribute("aria-required", "true");
                let label = Core.findLabelForInput(action.Element);
                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }
        }
    }
    Validate() {
        let that = this;
        let errorCount = 0;
        for (let action of that.ActionResponses.filter(a => !a.IsModalAction)) {
            if (action.IsRequired && !action.HasValue()) {
                action.Element.classList.add("missing-field");
                action.Element.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(action.Element);
                errorCount++;
            }
        }
        let messageContainerColumn = document.querySelector(`.validationColumnReview[data-workflow-instance-fk='${that.WorkflowInstancePK}']`);
        let messageContainer = messageContainerColumn.querySelector(".validationMessageContainerReview");
        let message = messageContainerColumn.querySelector(".validationMessageReview");
        messageContainerColumn.classList.add("show");
        let validationIcon = messageContainerColumn.querySelector(".validationMessageIconReview");
        if (errorCount > 0) {
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
            let successMessage = messageContainerColumn.querySelector(".saveSuccessReview");
            if (successMessage != null) {
                successMessage.classList.add("hide");
            }
            let accordions = document.querySelectorAll(`.review-container-actions[data-workflow-instance-fk='${that.WorkflowInstancePK}'] .Accordion`);
            for (let accordion of accordions) {
                let accordionElement = accordion;
                let issues = accordionElement.querySelector(".hasBeenValidated");
                if (issues != null) {
                    let trigger = accordionElement.querySelector(".Accordion-trigger");
                    if (trigger != null) {
                        trigger.click();
                    }
                }
            }
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = messageContainerColumn.querySelector(".saveSuccessReview");
            if (successMessage !== null) {
                successMessage.innerHTML = "The review has been successfully saved.";
                successMessage.classList.remove("hide");
            }
        }
        if (errorCount === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix for this review component</p>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix for this review component</p>";
        }
        messageContainer.focus();
        return errorCount === 0;
    }
    DeleteComment(responsePK) {
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('DELETE', '/Base/DeleteWorkflowResponse', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Successfully deleted comment", 'success', 3000, null);
                //Remove comment from DOM
                let deleteButton = document.querySelector(`.deleteComment[data-workflow-instance-action-response-pk='${responsePK}']`);
                if (deleteButton != null) {
                    let container = Core.findClosest(deleteButton, "[data-comment-container]");
                    if (container != null) {
                        let nextFocusable = Core.getNextFocusableElement(deleteButton);
                        container.parentNode.removeChild(container);
                        if (nextFocusable != null) {
                            nextFocusable.focus();
                        }
                    }
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting comment", 'error', 3000, null);
            }
        };
        xhr.send(responsePK.toString());
    }
    //Saves all workflows in one call
    static SaveAllWorkflows(workflows) {
        return new Promise((resolve, reject) => {
            let allSaveData = [];
            for (let workflow of workflows) {
                if (workflow.CanSave) {
                    //Set this to false for now so that user can't hit the save button again and save. (Safeguard for double saving)
                    workflow.CanSave = false;
                    let workflowSaveData = workflow.GetWorkflowSaveData();
                    if (workflowSaveData != null && workflowSaveData.length > 0) {
                        allSaveData = [...workflowSaveData, ...allSaveData];
                    }
                }
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/Base/SaveActions', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                workflows.forEach(workflow => workflow.CanSave = true);
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    resolve("Successfully saved review");
                }
                else {
                    reject("There was an unexpected error saving review");
                }
            };
            if (allSaveData.length > 0) {
                xhr.send(JSON.stringify(allSaveData));
            }
            else {
                workflows.forEach(workflow => workflow.CanSave = true);
                resolve("Nothing to save");
            }
        });
    }
}
class WorkflowActionResponse {
    constructor(actionElement) {
        this.Element = actionElement;
        this.ActionType = "actionType" in actionElement.dataset ? actionElement.dataset.actionType : null;
        this.ActionPK = "actionPk" in actionElement.dataset ? parseInt(actionElement.dataset.actionPk) : null;
        this.ActionCode = "actionCode" in actionElement.dataset ? actionElement.dataset.actionCode : null;
        this.ActionType = "actionType" in actionElement.dataset ? actionElement.dataset.actionType : null;
        this.WorkflowInstanceFK = "workflowInstanceFk" in actionElement.dataset ? parseInt(actionElement.dataset.workflowInstanceFk) : null;
        this.WorkflowInstanceActionResponsePK = "workflowInstanceActionResponsePk" in actionElement.dataset && parseInt(actionElement.dataset.workflowInstanceActionResponsePk) > 0 ? parseInt(actionElement.dataset.workflowInstanceActionResponsePk) : null;
        this.RowNbr = "row" in actionElement.dataset ? parseInt(actionElement.dataset.row) : null;
        this.ActionType = "actionType" in actionElement.dataset ? actionElement.dataset.actionType : null;
        this.IsRequired = "required" in actionElement.dataset ? true : false;
        this.IsModalAction = "modalAction" in actionElement.dataset ? true : false;
        this.IsComplete = "isComplete" in actionElement.dataset ? true : false;
        this.IsReadOnly = "isReadOnly" in actionElement.dataset ? true : false;
        this.ModalContainerId = "modalId" in actionElement.dataset ? actionElement.dataset.modalId : null;
        this.SaveItem = {
            ActionPK: this.ActionPK,
            IsDeletedInd: false,
            WorkflowInstanceFK: this.WorkflowInstanceFK,
            RowNbr: this.RowNbr,
            WorkflowInstanceActionResponsePK: this.WorkflowInstanceActionResponsePK
        };
    }
}
class WorkflowActionResponseInput extends WorkflowActionResponse {
    constructor(actionElement) {
        super(actionElement);
        this.InputElement = actionElement;
        this.OriginalInputValue = this.InputElement.value;
    }
    GetSaveItem() {
        let that = this;
        if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
            that.SaveItem.TextValue = that.InputElement.value;
            return that.SaveItem;
        }
        else {
            return null;
        }
    }
    HasValue() {
        return this.InputElement.value != "";
    }
    HasChanged() {
        return this.OriginalInputValue != this.InputElement.value;
    }
}
class WorkflowActionResponseTextArea extends WorkflowActionResponse {
    constructor(actionElement) {
        super(actionElement);
        this.TextAreaElement = actionElement;
        this.OriginalTextValue = this.TextAreaElement.value;
    }
    GetSaveItem() {
        let that = this;
        if (that.ActionType == "additionalFeedbackToLEA" || that.ActionType == "internalComments") {
            if (that.TextAreaElement.value != that.OriginalTextValue) {
                that.SaveItem.TextValue = that.TextAreaElement.value;
                return that.SaveItem;
            }
            else {
                return null;
            }
        }
        if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
            that.SaveItem.TextValue = that.TextAreaElement.value;
            return that.SaveItem;
        }
        else {
            return null;
        }
    }
    HasValue() {
        return this.TextAreaElement.value != "";
    }
    HasChanged() {
        return this.OriginalTextValue != this.TextAreaElement.value;
    }
}
class WorkflowActionResponseSelect extends WorkflowActionResponse {
    constructor(actionElement) {
        super(actionElement);
        let that = this;
        that.SelectElement = actionElement;
        if (that.ActionType == "emailTemplateSelect") {
            that.OriginalEmailTemplateFK = parseInt(that.SelectElement.value);
        }
        else {
            that.OriginalLookupCodeFK = parseInt(that.SelectElement.value);
        }
    }
    GetSaveItem() {
        let that = this;
        if (that.ActionType == "emailTemplateSelect") {
            if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
                that.SaveItem.EmailTemplateFK = parseInt(that.SelectElement.value);
                return that.SaveItem;
            }
        }
        else {
            if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
                that.SaveItem.LookupCodeFK = parseInt(that.SelectElement.value);
                return that.SaveItem;
            }
        }
        return null;
    }
    HasValue() {
        return this.SelectElement.selectedIndex > 0;
    }
    HasChanged() {
        let that = this;
        if (that.ActionType == "emailTemplateSelect") {
            return that.OriginalEmailTemplateFK != that.SelectElement.selectedIndex;
        }
        else {
            return that.OriginalLookupCodeFK != that.SelectElement.selectedIndex;
        }
    }
}
class WorkflowActionResponseRadio extends WorkflowActionResponse {
    constructor(actionElement) {
        super(actionElement);
        let that = this;
        //Get all radio inputs with this name
        that.RadioElements = [];
        that.RadioName = actionElement.name;
        let radioInputs = document.querySelectorAll(`[name='${actionElement.name}']`);
        for (let radioInput of radioInputs) {
            let radioElement = radioInput;
            that.RadioElements.push(radioElement);
            if (radioElement.checked) {
                that.OriginalLookupCodeFK = parseInt(radioElement.value);
            }
        }
    }
    GetSaveItem() {
        let that = this;
        for (let radioElement of that.RadioElements) {
            if (radioElement.checked && (that.HasValue() || that.WorkflowInstanceActionResponsePK != null)) {
                that.SaveItem.LookupCodeFK = parseInt(radioElement.value);
                return that.SaveItem;
            }
        }
        return null;
    }
    HasValue() {
        let that = this;
        for (let radioElement of that.RadioElements) {
            if (radioElement.checked) {
                return true;
            }
        }
        return false;
    }
    HasChanged() {
        let that = this;
        let newLookupCodeFK;
        for (let radioElement of that.RadioElements) {
            if (radioElement.checked && (that.HasValue() || that.WorkflowInstanceActionResponsePK != null)) {
                newLookupCodeFK = parseInt(radioElement.value);
                break;
            }
        }
        return newLookupCodeFK != this.OriginalLookupCodeFK;
    }
}
class WorkflowActionResponseButton extends WorkflowActionResponse {
    constructor(actionElement) {
        super(actionElement);
        this.ButtonElement = actionElement;
        this.SubmitAction = false;
    }
    GetSaveItem() {
        let that = this;
        if (that.SubmitAction) {
            that.SaveItem.TextValue = "Submitted";
            return that.SaveItem;
        }
        else {
            return null;
        }
    }
    HasValue() {
        return false;
    }
    HasChanged() {
        return this.SubmitAction;
    }
}
class WorkflowActionResponseCheckbox extends WorkflowActionResponse {
    constructor(actionElement) {
        super(actionElement);
        let that = this;
        that.CheckboxElement = actionElement;
        that.OriginalIsChecked = that.CheckboxElement.checked;
    }
    GetSaveItem() {
        let that = this;
        if (that.HasValue() || that.WorkflowInstanceActionResponsePK != null) {
            if ("lookupCodeFk" in that.CheckboxElement.dataset) {
                that.SaveItem.LookupCodeFK = parseInt(that.CheckboxElement.dataset.lookupCodeFk);
            }
            if (that.CheckboxElement.checked) {
                that.SaveItem.TextValue = "on";
            }
            else {
                that.SaveItem.TextValue = "off";
            }
            return that.SaveItem;
        }
        else {
            return null;
        }
    }
    HasValue() {
        return this.CheckboxElement.checked;
    }
    HasChanged() {
        return this.OriginalIsChecked != this.CheckboxElement.checked;
    }
}
class WorkflowInstanceActionResponseSave {
}
class WorkflowConfirmDialog {
    constructor() {
        this.ConfirmModal = new Modal("workflowConfirmModal", null);
    }
    confirm(confirmText, returnFocusTo) {
        let that = this;
        let modalElement = document.getElementById(that.ConfirmModal.id);
        if (modalElement != null) {
            let text = modalElement.querySelector("#workflowConfirmText");
            if (text != null) {
                text.innerHTML = confirmText;
            }
        }
        return new Promise((resolve, reject) => {
            that.ConfirmModal.show();
            that.ConfirmModal.callingId = returnFocusTo;
            let confirmButton = document.getElementById("workflowConfirmButton");
            if (confirmButton != null) {
                if (that.ConfirmEventListener !== undefined) {
                    confirmButton.removeEventListener("click", that.ConfirmEventListener);
                }
                confirmButton.addEventListener("click", that.ConfirmEventListener = function () {
                    that.ConfirmModal.hide();
                    resolve(true);
                });
            }
            else {
                that.ConfirmModal.hide();
                reject("An unexpected error occurred. Please try again later.");
            }
            let cancelButton = document.getElementById("workflowCancelButton");
            if (cancelButton != null) {
                if (that.CancelEventListener !== undefined) {
                    cancelButton.removeEventListener("click", that.CancelEventListener);
                }
                cancelButton.addEventListener("click", that.CancelEventListener = function () {
                    that.ConfirmModal.hide();
                    resolve(false);
                });
            }
            else {
                that.ConfirmModal.hide();
                reject("An unexpected error occurred. Please try again later.");
            }
        });
    }
}
/// <reference path="page.ts" />
class K12GuidancePlanPage extends Page {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        if (this._fromSaveElement && this._fromSaveElement.value === 'true' && pageCode !== 'k12guidanceplanprofile')
            this._validationHandler.validate();
    }
}
class K12GuidancePlanGuidancePlanProfile extends K12GuidancePlanPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        this.setUpProfile();
    }
    setUpProfile() {
        const refreshProfileButton = document.getElementById('k12guidancePlanProfileRefreshProfile');
        refreshProfileButton.addEventListener('click', (e) => this.refreshProfile());
        document.querySelectorAll('[data-percent="1.00"][disabled="disabled"]:not([data-propertygroupcode="801"])')
            .forEach(element => this._core.forceElementOptional(element));
        let steeringCommitteeExportExcel = document.getElementById("exportCounselors");
        if (steeringCommitteeExportExcel !== null)
            steeringCommitteeExportExcel.addEventListener("click", (e) => this.exportExcel());
        var additionalCounselors = document.querySelectorAll('[data-percent="1.00"][data-propertygroupcodepk="820"][data-row="0"]'); /* as unknown as HTMLCollectionOf<HTMLInputElement>;*/
        var counselors = additionalCounselors;
        var counter = 0;
        for (let counselor of counselors) {
            if (counselor.value !== "") {
                counter++;
            }
        }
        if (counter === 0) {
            additionalCounselors.forEach(element => this._core.forceElementOptional(element));
        }
        if (this._fromSaveElement && this._fromSaveElement.value === 'true')
            this._validationHandler.validate();
        const saveExecutiveButton = document.getElementById('k12guidanceplanGuidancePlanProfileSaveButtonExecutive');
        if (saveExecutiveButton)
            saveExecutiveButton.addEventListener('click', (e) => this._saveHandler.saveExecutiveFieldsForPDEPlanWriters(['802']));
    }
    refreshProfile() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield fetch(`/${this._navigationCode}/GetICDataForProfile/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: `planFK=${this._planPK}`
            });
            if (response.ok) {
                let wasUpdated = false;
                const json = yield response.json();
                const leaName = document.getElementById('k12guidancePlanProfileLEAName');
                const aun = document.getElementById('k12guidancePlanProfileAUN');
                const address1 = document.getElementById('k12guidancePlanProfileAddress1');
                const address2 = document.getElementById('k12guidancePlanProfileAddress2');
                const city = document.getElementById('k12guidancePlanProfileCity');
                const state = document.getElementById('k12guidancePlanProfileState');
                const zipCode = document.getElementById('k12guidancePlanProfileZipCode');
                const directorName = document.getElementById('k12guidancePlanProfileDirectorName');
                const directorEmail = document.getElementById('k12guidancePlanProfileDirectorEmail');
                const directorPhone = document.getElementById('k12guidancePlanProfileDirectorPhone');
                if (leaName.value !== json.instDetails.instName) {
                    leaName.value = json.instDetails.instName;
                    wasUpdated = true;
                }
                if (parseInt(aun.value) !== json.instDetails.auNumber) {
                    aun.value = json.instDetails.auNumber;
                    wasUpdated = true;
                }
                if (address1.value !== json.instDetails.addressLine1) {
                    address1.value = json.instDetails.addressLine1;
                    wasUpdated = true;
                }
                if (address2.value !== json.instDetails.addressLine2) {
                    address2.value = json.instDetails.addressLine2;
                    wasUpdated = true;
                }
                if (city.value !== json.instDetails.city) {
                    city.value = json.instDetails.city;
                    wasUpdated = true;
                }
                if (state.value !== json.instDetails.state) {
                    state.value = json.instDetails.state;
                    wasUpdated = true;
                }
                if (zipCode.value !== json.instDetails.zipCode) {
                    zipCode.value = json.instDetails.zipCode;
                    wasUpdated = true;
                }
                if (zipCode.value !== json.instDetails.zipCode) {
                    zipCode.value = json.instDetails.zipCode;
                    wasUpdated = true;
                }
                if (directorName.value !== json.superintendentInfo.adminName) {
                    directorName.value = json.superintendentInfo.adminName;
                    wasUpdated = true;
                }
                if (directorEmail.value !== json.superintendentInfo.email) {
                    directorEmail.value = json.superintendentInfo.email;
                    wasUpdated = true;
                }
                if (directorPhone.value !== json.superintendentInfo.phoneNumber) {
                    directorPhone.value = json.superintendentInfo.phoneNumber;
                    wasUpdated = true;
                }
                if (wasUpdated) {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully updated the profile with the most recent EdNA data, please save your changes.', 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'Profile is up to date with the most recent EdNA data.', 'success', 3000, null);
                }
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an error retrieving data from EdNA, please try again.', 'error', 3000, null);
            }
        });
    }
    exportExcel() {
        let core = this._core;
        Core.showLoader();
        let planPK = this._planPK;
        window.open("/ExportExcel/CounselorsDataExport?id=" + planPK);
        setTimeout(function () {
            Core.hideLoader();
        }, 5000);
    }
}
class K12GuidancePlanGuidanceMissionStatementAndCTEPrograms extends K12GuidancePlanPage {
}
class K12GuidancePlanBoardApprovedGuidancePlan extends K12GuidancePlanPage {
}
class K12GuidancePlanCareerAndTechnicalCurricula extends K12GuidancePlanPage {
}
class K12GuidancePlanEducationalCareerPlan extends K12GuidancePlanPage {
}
class K12GuidancePlanOccupationalInformation extends K12GuidancePlanPage {
}
class K12GuidancePlanCumulativeRecords extends K12GuidancePlanPage {
}
class K12GuidancePlanOrientationToCareerAndTechnicalOptions extends K12GuidancePlanPage {
}
class K12GuidancePlanCareerPlacementServices extends K12GuidancePlanPage {
}
class K12GuidancePlanConsultationWithTeachersAndAdministrators extends K12GuidancePlanPage {
}
class K12GuidancePlanAndStakeholderInvolvement extends K12GuidancePlanPage {
}
class K12GuidancePlanFollowUpStudies extends K12GuidancePlanPage {
}
class K12GuidancePlanSignaturesAndAssurances extends K12GuidancePlanPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        this.constructEventListeners();
    }
    constructEventListeners() {
        const checkboxes = document.querySelectorAll('input[type=checkbox]');
        for (const checkbox of checkboxes) {
            if (!checkbox.disabled) {
                checkbox.addEventListener('click', (e) => this.toggleCheckboxControl(checkbox));
                this.toggleCheckboxControl(checkbox);
            }
        }
    }
    toggleCheckboxControl(checkbox) {
        const checkboxControlledElements = document.querySelectorAll(`input[data-propertygroupcodepk='${checkbox.dataset.propertygroupcodepk}']:not([type=checkbox])`);
        checkboxControlledElements.forEach(element => {
            element.disabled = !checkbox.checked;
            if (!checkbox.checked)
                element.value = '';
        });
    }
}
class K12GuidancePlanSummaryChecklistAndSubmission {
    constructor() {
        this._core = new Core();
        this._lateEmailModal = new Modal("sendLateEmailModal", "sendLateEmail");
        const backButton = document.getElementById('backButton');
        if (backButton !== null)
            backButton.addEventListener('click', (e) => this.back(e));
        const submitButton = document.getElementById('submitPlanButton');
        if (submitButton !== null)
            submitButton.addEventListener('click', (e) => this.submit(e));
        let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]');
        if (sendLateEmailButton != null)
            sendLateEmailButton.addEventListener('click', () => {
                this._lateEmailPlanFK = parseInt(sendLateEmailButton.dataset.planFk);
                this._lateEmailModal.show();
            });
        let sendLateEmailConfirmButton = document.querySelector('#sendLateEmailConfirm');
        if (sendLateEmailConfirmButton != null)
            sendLateEmailConfirmButton.addEventListener('click', () => this.sendLateEmail());
        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
        if (sendLateEmailDueDate != null) {
            sendLateEmailDueDate.addEventListener('change', () => {
                if (sendLateEmailConfirmButton != null) {
                    sendLateEmailConfirmButton.disabled = false;
                }
            });
        }
        let withdrawPlanButton = document.getElementById("withdrawPlanButton");
        if (withdrawPlanButton !== null)
            withdrawPlanButton.addEventListener("click", (e) => this.withdrawPlan(e));
        let withdrawPlanConfirmButton = document.getElementById("withdrawPlanConfirm");
        if (withdrawPlanConfirmButton !== null)
            withdrawPlanConfirmButton.addEventListener("click", (e) => this.withdrawPlanConfirm(e));
        let withdrawPlanCancelButton = document.getElementById("withdrawPlanCancel");
        if (withdrawPlanCancelButton !== null)
            withdrawPlanCancelButton.addEventListener("click", (e) => this.withdrawPlanCancel());
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        const element = e.srcElement;
        if (!element.classList.contains('disabled')) {
            Core.showLoader();
            const planFK = parseInt(element.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/K12GuidancePlan/SubmitK12GuidancePlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = () => {
                Core.hideLoader();
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert('alertMessageDiv', 'This report has been submitted for review!', 'success', 3000, window.location.reload());
                    setTimeout(() => { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred while submitting report. Please try again later.', 'error', 3000, null);
                }
            };
            xhr.send('planFK=' + planFK);
        }
    }
    sendLateEmail() {
        let that = this;
        Core.showLoader();
        let sendLateEmailDueDate = document.getElementById('sendLateEmailNewDate');
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/K12GuidancePlan/SendLateEmail', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = () => {
            xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true
                ? Core.createHTMLAlert('alertMessageDiv', 'Successfully sent late email', 'success', 3000, null)
                : Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected error sending late email', 'error', 3000, null);
            Core.hideLoader();
            that._lateEmailModal.hide();
            let sendLateEmailButton = document.querySelector('#sendLateEmail[data-plan-fk]');
            if (sendLateEmailButton != null) {
                sendLateEmailButton.disabled = true;
            }
        };
        xhr.send(`planFK=${that._lateEmailPlanFK}&newDueDateStr=${sendLateEmailDueDate.value}`);
    }
    withdrawPlan(e) {
        let fileButton = e.srcElement;
        let planFK = fileButton.dataset.planfk;
        let modal = new Modal("withdrawPlanModal", null);
        modal.addAttributeToElement("withdrawPlanModal", "#withdrawPlanConfirm", "planfk", planFK);
        modal.show();
    }
    withdrawPlanConfirm(e) {
        let confirmButton = e.srcElement;
        let core = this._core;
        Core.showLoader();
        let planFK = confirmButton.dataset.planfk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/K12GuidancePlan/WithdrawPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The plan has been withdrawn.", 'success', 3000, null);
                        //back to report page
                        setTimeout(function () { window.location.href = "/Reports/StateRequiredReports"; }, 3000);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected withdrawing this plan.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected withdrawing this plan. Please try again.", 'error', 3000, null);
            }
            let modal = new Modal("withdrawPlanModal", null);
            modal.hide();
        };
        xhr.send("planFK=" + planFK);
    }
    withdrawPlanCancel() {
        let modal = new Modal("withdrawPlanModal", null);
        modal.hide();
    }
}
/// <reference path="page.ts" />
/// <reference path="interfaces/educatorinduction/Interfaces.ts" />
class EducatorInductionPlanPage extends Page {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null, runValidation = true) {
        var _a;
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields);
        if (runValidation && ((_a = this._fromSaveElement) === null || _a === void 0 ? void 0 : _a.value) === 'true')
            this._validationHandler.validate();
    }
}
class EducatorInductionPlanProfileAndPlanEssentials extends EducatorInductionPlanPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        this._profileHandler = new ProfileHandler(this._navigationCode, this._planPK, 'educatorInduction');
        this.setUpProfile();
    }
    setUpProfile() {
        //const refreshProfileButton = document.getElementById('k12guidancePlanProfileRefreshProfile') as HTMLButtonElement
        //refreshProfileButton.addEventListener('click', (e: Event) => this.refreshProfile())
        //document.querySelectorAll('[data-percent="1.00"][disabled="disabled"]:not([data-propertygroupcode="801"])')
        //    .forEach(element => this._core.forceElementOptional(element as HTMLElement))
        const saveExecutiveButton = document.getElementById('educatorinductionplanProfileAndPlanEssentialsSaveButtonExecutive');
        if (saveExecutiveButton)
            saveExecutiveButton.addEventListener('click', (e) => this._saveHandler.saveExecutiveFieldsForPDEPlanWriters(['4000']));
    }
}
class EducatorInductionPlanPlanCommittee extends EducatorInductionPlanPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = true) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, null, false);
        this._validationHandler.MinRequiredGroupRows = { 4002: 2 };
        if (this._fromSaveElement && this._fromSaveElement.value === 'true')
            this._validationHandler.validate();
    }
}
class EducatorInductionPlanMentors extends EducatorInductionPlanPage {
}
class EducatorInductionPlanNeedsAssessment extends EducatorInductionPlanPage {
}
class EducatorInductionPlanPlanTopicAreas extends EducatorInductionPlanPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, ['multiSelectTopicAreasTopicAreas']);
        this.constructEventListeners();
    }
    /**
     * Adds all the event listeners to the various elements on the page
     */
    constructEventListeners() {
        // Create Accordions
        const accordions = document.getElementsByClassName('plan-topic-areas-accordion');
        for (const accordion of accordions)
            new SharedPageAccordion(accordion.id);
        // Wire up the 'Report Selected Topics' button
        const reportSelectedTopicsButton = document.getElementById('educatorInductionPlanTopicAreasReportSelectedTopics');
        reportSelectedTopicsButton === null || reportSelectedTopicsButton === void 0 ? void 0 : reportSelectedTopicsButton.addEventListener('click', (e) => this.reportSelectedTopics());
        // Wire up the 'Add' buttons for observational components
        const addObservationalComponentsButtons = document.querySelectorAll('.add-observational-components-button');
        if (addObservationalComponentsButtons.length) {
            for (const button of addObservationalComponentsButtons) {
                const parentPlanPropertyFK = parseInt(button.closest('.Accordion-panel').dataset.planpropertypk);
                button.addEventListener('click', (e) => this.addObservationalComponents(parentPlanPropertyFK));
            }
        }
        // Wire up the 'Report Additional Topics' Button
        const reportAdditionalTopicButton = document.getElementById('educatorInductionPlanTopicAreasReportAdditionalTopicButton');
        reportAdditionalTopicButton === null || reportAdditionalTopicButton === void 0 ? void 0 : reportAdditionalTopicButton.addEventListener('click', (e) => this.reportAdditionalTopic());
        // Wire up the checkboxes to delete the components when going from checked -> unchecked
        const observationalComponentInputs = document.querySelectorAll('input[name="component"]');
        for (const component of observationalComponentInputs) {
            component.addEventListener('click', (e) => this.deleteSelectedComponentOnUncheck(component));
        }
        const deleteTopicButtons = document.querySelectorAll('button.delete-topic-button');
        for (const button of deleteTopicButtons) {
            button.addEventListener('click', (e) => this.deleteTopic(button));
        }
        // Wire up the delete buttons on the components
        this.constructDeleteSelectedComponentButtons();
    }
    deleteTopic(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const topicAccordion = button.closest('.plan-topic-areas-accordion-container');
            const topicPlanPropertyModel = {
                PlanFK: this._planPK,
                PlanPropertyPK: parseInt(topicAccordion.dataset.planpropertypk),
                PropertyFK: 0,
                TextValue: null,
                LookupCodeFK: null,
                RowNbr: null,
                IsDeletedInd: false
            };
            Core.showLoader();
            const response = yield fetch(`/${this._navigationCode}/DeleteTopic/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: [topicPlanPropertyModel] })
            });
            if ((response === null || response === void 0 ? void 0 : response.ok) && response.json()) {
                Core.hideLoader();
                topicAccordion.remove();
                const topicOptionInput = document.querySelector(`input[name="topic"][data-planpropertypk="${topicAccordion.dataset.planpropertypk}"]`);
                if (topicOptionInput != null) {
                    topicOptionInput.checked = false;
                    topicOptionInput.dataset.planpropertypk = '0';
                    const topicOptionDropdownList = topicOptionInput.closest('#topicOptionsList');
                    const topicOptionDropdownLabel = topicOptionInput.closest('#multi-select-plugin').querySelector('label');
                    this.updateMultiSelectDropdownLabelOnDelete(topicOptionDropdownList, topicOptionDropdownLabel);
                }
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the Topic.', 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an error deleting the Topic, please try again.', 'error', 3000, null);
            }
        });
    }
    /**
     * Wires up the delete component buttons on the page to call the deleteSelectedComponents method
     */
    constructDeleteSelectedComponentButtons() {
        const deleteSelectedComponentButtons = document.querySelectorAll('.delete-selected-component-button');
        if (deleteSelectedComponentButtons.length) {
            for (const button of deleteSelectedComponentButtons) {
                button.addEventListener('click', (e) => this.deleteSelectedComponent(button.dataset.planpropertypk));
            }
        }
    }
    /**
     * Provides a conditional statement to only fire the deleteSelectedComponent method if the input goes from
     * checked to unchecked
     * @param component The checkbox of the Observational Component dropdown being checked/unchecked
     */
    deleteSelectedComponentOnUncheck(component) {
        // Components has been unchecked, and was checked when the page loaded (i.e. is still checked in the DB)
        if (!component.checked && component.dataset.waschecked === 'true') {
            this.deleteSelectedComponent(component.dataset.planpropertypk);
            component.dataset.waschecked = 'false';
        }
    }
    /**
     * Checks to see if there are any outstanding topics to report, and if so makes a call to silently save the page
     */
    reportSelectedTopics() {
        return __awaiter(this, void 0, void 0, function* () {
            const topicsToReport = this.checkForSelectedCheckboxes('topic', 'Please select a topic to report.', 'All selected topics have been reported!');
            if ((topicsToReport === null || topicsToReport === void 0 ? void 0 : topicsToReport.length) && (yield this.savePlanTopicAreas(topicsToReport)))
                this.reloadPageOnReportingTopics();
        });
    }
    reloadPageOnReportingTopics() {
        Core.showLoader();
        const url = window.location.href;
        const fromSave = '?fromSave=true';
        if (url.includes(fromSave))
            url.replace(fromSave, '');
        window.location.href = url;
    }
    reportAdditionalTopic() {
        return __awaiter(this, void 0, void 0, function* () {
            const additionalTopicToReportInput = document.querySelector('[data-propertycode="educatorInductionPlanTopicAreasAdditionalTopic"]');
            if (!additionalTopicToReportInput.value) {
                Core.createHTMLAlert('alertMessageDiv', 'Please enter a name for the additional topic.', 'warning', 3000, null);
                return;
            }
            const response = yield fetch(`/${this._navigationCode}/SaveEducatorInductionPlanTopicAreas/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    PageCode: this._pageCode,
                    PlanPK: this._planPK,
                    PlanPropertyModels: [{
                            PlanPropertyPK: parseInt(additionalTopicToReportInput.dataset.planpropertypk),
                            PlanFK: this._planPK,
                            PropertyFK: parseInt(additionalTopicToReportInput.dataset.propertypk),
                            TextValue: additionalTopicToReportInput.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(additionalTopicToReportInput.dataset.row),
                            IsDeletedInd: false
                        }]
                })
            });
            if ((response === null || response === void 0 ? void 0 : response.ok) && (yield response.json())) {
                this.reloadPageOnReportingTopics();
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an issue reporting this topic, please try again.', 'error', 3000, null);
            }
        });
    }
    /**
     * Checks to see that any checkboxes with a given name are selected at all, and if they have been changed
     * @param name The name of the group of checkboxes
     * @param noCheckboxesSelectedMessage The display message for the HTMLAlert if no checkboxes are selected
     * @param noCheckboxesChangedMessage The display message for the HTMLAlert if no checkboxes have been changed (newly checked/unchecked)
     * @param parentPlanPropertyFK The optional parent plan property key (for observational components and timelines)
     */
    checkForSelectedCheckboxes(name, noCheckboxesSelectedMessage, noCheckboxesChangedMessage, parentPlanPropertyFK = null) {
        const parentPlanPropertySelector = parentPlanPropertyFK
            ? `[data-parentplanpropertypk="${parentPlanPropertyFK}"]`
            : '';
        let selectedCheckboxes = document.querySelectorAll(`input[name="${name}"]${parentPlanPropertySelector}:checked`);
        if (!selectedCheckboxes.length) {
            Core.createHTMLAlert('alertMessageDiv', noCheckboxesSelectedMessage, 'warning', 3000, null);
            return;
        }
        selectedCheckboxes
            = document.querySelectorAll(`input[name="${name}"]${parentPlanPropertySelector}[data-waschecked="false"]:checked, input[name="${name}"]${parentPlanPropertySelector}[data-waschecked="true"]:not(:checked)`);
        if (!selectedCheckboxes.length) {
            Core.createHTMLAlert('alertMessageDiv', noCheckboxesChangedMessage, 'warning', 3000, null);
            return;
        }
        return selectedCheckboxes;
    }
    /**
     * Custom save method for the page.
     * @param propertiesToSave List of all properties to save.
     */
    savePlanTopicAreas(propertiesToSave) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const planPropertiesToSave = this.getAllPlanPropertiesToSave(propertiesToSave);
            const response = yield fetch(`/${this._navigationCode}/SaveEducatorInductionPlanTopicAreas/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: planPropertiesToSave })
            });
            Core.hideLoader();
            return yield response.json();
        });
    }
    /**
     * Returns a list of all plan properties that need to be saved.
     * @param elementsToSave The list of elements corresponding to reportable topics (pre-filtered in other methods to
     * include only elements that need plan properties).
     */
    getAllPlanPropertiesToSave(elementsToSave) {
        const allPlanPropertiesToSave = [];
        for (const element of elementsToSave) {
            allPlanPropertiesToSave.push({
                PlanPropertyPK: parseInt(element.dataset.planpropertypk),
                PlanFK: this._planPK,
                PropertyFK: parseInt(element.dataset.propertypk),
                TextValue: element.checked ? 'on' : 'off',
                LookupCodeFK: null,
                RowNbr: this._saveHandler.getRowNumber(element),
                IsDeletedInd: false,
                ParentPlanPropertyPK: "parentplanpropertypk" in element.dataset && parseInt(element.dataset.parentplanpropertypk) > 0 ? parseInt(element.dataset.parentplanpropertypk) : null,
                PlanPropertyRelationTypePK: "propertyrelationtype" in element.dataset && parseInt(element.dataset.propertyrelationtype) > 0 ? parseInt(element.dataset.propertyrelationtype) : null
            });
        }
        return allPlanPropertiesToSave;
    }
    /**
     * Checks to see that there are any observational components to save when the user clicks the 'Add' button, calls the saveObservationalComponents method if so.
     * @param parentPlanPropertyFK The plan property PK of the Topic Area checkbox corresponding to the accordion.
     */
    addObservationalComponents(parentPlanPropertyFK) {
        return __awaiter(this, void 0, void 0, function* () {
            const componentsToAdd = this.checkForSelectedCheckboxes('component', 'Please select observational components to add.', 'All selected components have been added!', parentPlanPropertyFK);
            return (componentsToAdd === null || componentsToAdd === void 0 ? void 0 : componentsToAdd.length) ? yield this.saveObservationalComponents(componentsToAdd, parentPlanPropertyFK) : false;
        });
    }
    /**
     * Glorified save method specifically for the observational components. Makes a call to save the page (which will set the checkbox text values to 'on' in
     * the database), then fetches the '_SelectedObservationalComponentsForTeaching.cshtml' view to update the UI.
     * @param componentsToAdd List of the checkboxes to save, which dictate the UI to display the observational components for teaching.
     * @param parentPlanPropertyFK The plan property PK of the Topic Area checkbox corresponding to the accordion.
     */
    saveObservationalComponents(componentsToAdd, parentPlanPropertyFK) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            try {
                const planPropertiesToSave = this.getAllPlanPropertiesToSave(componentsToAdd);
                const response = yield fetch(`/${this._navigationCode}/SaveObservationalComponents/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: planPropertiesToSave })
                });
                Core.hideLoader();
                const text = yield response.text();
                if (text) {
                    const componentsContainerOuter = document.querySelector(`.observational-compenents-container-outer[data-planpropertyfk="${parentPlanPropertyFK}"]`);
                    if (componentsContainerOuter != null) {
                        $(componentsContainerOuter).html(text);
                        $('.multiSelectTopicAreasTopicAreas').MultiSelect();
                        const addObservationalComponentsButtons = componentsContainerOuter.querySelectorAll('.add-observational-components-button');
                        if (addObservationalComponentsButtons.length) {
                            for (const button of addObservationalComponentsButtons) {
                                const parentPlanPropertyFK = parseInt(button.closest('.Accordion-panel').dataset.planpropertypk);
                                button.addEventListener('click', (e) => this.addObservationalComponents(parentPlanPropertyFK));
                            }
                        }
                        // Wire up the checkboxes to delete the components when going from checked -> unchecked
                        const observationalComponentInputs = componentsContainerOuter.querySelectorAll('input[name="component"]');
                        for (const component of observationalComponentInputs) {
                            component.addEventListener('click', (e) => this.deleteSelectedComponentOnUncheck(component));
                        }
                        const deleteSelectedComponentButtons = componentsContainerOuter.querySelectorAll('.delete-selected-component-button');
                        if (deleteSelectedComponentButtons.length) {
                            for (const button of deleteSelectedComponentButtons) {
                                button.addEventListener('click', (e) => this.deleteSelectedComponent(button.dataset.planpropertypk));
                            }
                        }
                        Core.createHTMLAlert('alertMessageDiv', 'Successfully added the Observational Component for Teaching.', 'success', 3000, null);
                    }
                }
                else {
                    return false;
                }
            }
            catch (error) {
                return false;
            }
            return true;
        });
    }
    /**
     * Makes a call to 'delete' (set the TextValue to "off") the plan property record in the database and, if successful, updates the UI
     * @param componentPlanPropertyPK The plan property PK of the component to delete
     */
    deleteSelectedComponent(componentPlanPropertyPK) {
        return __awaiter(this, void 0, void 0, function* () {
            const inputElement = document.querySelector(`input[name="component"][data-planpropertypk="${componentPlanPropertyPK}"]`);
            const planProperty = {
                PropertyFK: parseInt(inputElement.dataset.propertypk),
                PlanFK: this._planPK,
                PlanPropertyPK: parseInt(inputElement.dataset.planpropertypk),
                TextValue: 'off',
                LookupCodeFK: null,
                IsDeletedInd: false,
                RowNbr: null
            };
            const success = yield this._saveHandler.savePlanProperty(planProperty, 'Successfully deleted the observational component.', 'There was an error deleting the observational component, please try again.');
            if (success) {
                // Uncheck the component in the components dropdown
                const observationalComponentCheckbox = document.querySelector(`input[name="component"][data-planpropertypk="${componentPlanPropertyPK}"]`);
                observationalComponentCheckbox.checked = false;
                observationalComponentCheckbox.dataset.waschecked = "false";
                // Remove the component from the UI
                document.querySelector(`.selected-component-container[data-planpropertypk="${componentPlanPropertyPK}"]`).remove();
                const observationalComponentLabel = observationalComponentCheckbox.closest('li');
                observationalComponentLabel.classList.remove('selected');
                const parentPlanPropertyFK = observationalComponentCheckbox.dataset.parentplanpropertypk;
                // Update the rest of the UI depending on if any components are selected or not
                const multiSelectDropdown = document.querySelector(`ul.observational-components-list[data-parentplanpropertyfk="${parentPlanPropertyFK}"]`);
                const multiSelectDropdownLabel = document.querySelector(`label.observational-components-dropdown-label[data-parentplanpropertyfk="${parentPlanPropertyFK}"]`);
                const numberOfSelectedObservationalComponents = this.updateMultiSelectDropdownLabelOnDelete(multiSelectDropdown, multiSelectDropdownLabel);
                if (!numberOfSelectedObservationalComponents) {
                    const componentsContainerOuter = document.querySelector(`.selected-components-container-outer[data-parentplanpropertyfk="${parentPlanPropertyFK}"]`);
                    componentsContainerOuter.classList.add('display-none');
                    multiSelectDropdownLabel.innerText = 'Select a value';
                }
            }
            return success;
        });
    }
    updateMultiSelectDropdownLabelOnDelete(dropdown, label) {
        const allCheckboxes = dropdown.querySelectorAll('input');
        const checkedCheckboxes = dropdown.querySelectorAll('input:checked');
        const itemCase = checkedCheckboxes.length === 1 ? 'item' : 'items';
        label.innerText = checkedCheckboxes.length > 0 ? `${checkedCheckboxes.length} ${itemCase} selected` : 'Select a value';
        dropdown.setAttribute('aria-label', `${checkedCheckboxes.length} ${itemCase} selected of ${allCheckboxes.length} items`);
        return checkedCheckboxes.length;
    }
}
class EducatorInductionPlanEvaluationAndMonitoring extends EducatorInductionPlanPage {
}
class EducatorInductionPlanDocumentationOfParticipationAndCompletion extends EducatorInductionPlanPage {
}
class EducatorInductionPlanSignatureAndAssurances extends EducatorInductionPlanPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        this.constructEventListeners();
    }
    constructEventListeners() {
        const checkboxes = document.querySelectorAll('input[type=checkbox]'); // Assurance Checkboxes
        const signOffElements = document.querySelectorAll('[data-propertygroupcodepk="4010"]'); // Signature and Date inputs
        for (const checkbox of checkboxes) {
            if (checkbox.disabled) {
                // Checkboxes are disabled (user doesn't have access), disable sign off
                this.disableSignOffElements(signOffElements);
                return;
            }
            // Checkboxes are enabled (user has access), add toggle sign off listener
            checkbox.addEventListener('click', (e) => this.toggleEnableSignOff(checkboxes, signOffElements));
            this.toggleEnableSignOff(checkboxes, signOffElements);
        }
    }
    toggleEnableSignOff(checkboxes, signOffElements) {
        for (const checkbox of checkboxes) {
            if (!checkbox.checked) {
                // If any checkboxes are unchecked, disable sign off
                this.disableSignOffElements(signOffElements);
                return;
            }
        }
        // All checkboxes are checked, enable sign off
        for (const element of signOffElements)
            element.disabled = false;
    }
    disableSignOffElements(signOffElements) {
        for (const element of signOffElements)
            element.disabled = true;
    }
}
class EducatorInductionSummaryChecklistAndSubmission extends EducatorInductionPlanPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK);
        this._summaryChecklistAndSubmissionHandler = new SummaryChecklistAndSubmissionHandler(navigationCode);
    }
}
/// <reference path="page.ts" />
/// <reference path="interfaces/educatorinduction/Interfaces.ts" />
class CyberCharterSchoolRenewalApplicationPage extends Page {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null, useGenericValidation = true) {
        var _a;
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields);
        if (((_a = this._fromSaveElement) === null || _a === void 0 ? void 0 : _a.value) === 'true' && useGenericValidation)
            this._validationHandler.validate();
    }
}
class CyberCharterSchoolRenewalApplicationProfileAndPlanEssentials extends CyberCharterSchoolRenewalApplicationPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates);
        this._profileHandler = new ProfileHandler(this._navigationCode, this._planPK, 'cyberCharter');
    }
}
class CyberCharterSchoolRenewalApplicationApplicationFacts extends CyberCharterSchoolRenewalApplicationPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields);
        const label = document.getElementById('cyberCharterApplicationFactsGradesEducated');
        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`;
    }
}
class CyberCharterSchoolRenewalApplicationStudentAchievementAndOtherOutcomes extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationProgressTowardInitialGoals extends CyberCharterSchoolRenewalApplicationPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null) {
        var _a;
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields, false);
        let that = this;
        const createSchoolDesignationButton = document.getElementById('cyberCharterProgressSchoolDesignationCreate');
        if (createSchoolDesignationButton)
            createSchoolDesignationButton.addEventListener('click', (e) => that.createSchoolDesignation());
        const deleteSchoolDesignationButtons = document.querySelectorAll('.delete-school-designation-button');
        if (deleteSchoolDesignationButtons === null || deleteSchoolDesignationButtons === void 0 ? void 0 : deleteSchoolDesignationButtons.length)
            for (const button of deleteSchoolDesignationButtons)
                button.addEventListener('click', (e) => that.deleteSchoolDesignation(button));
        var hasDesignationRadio = document.querySelectorAll(".cybercharterschoolrenewalapplicationProgressTowardInitialGoalsRadioField[data-propertycode='cyberCharterProgressStateAssessmentComprehensiveSupport']");
        if (hasDesignationRadio != null && hasDesignationRadio.length > 0) {
            for (let radio of hasDesignationRadio) {
                radio.addEventListener("change", function () {
                    that.handleHasDesignation();
                });
            }
        }
        that.handleHasDesignation();
        that._saveHandler.SkipElements.push(...['50349', '50350']);
        if (((_a = that._fromSaveElement) === null || _a === void 0 ? void 0 : _a.value) === 'true')
            that._validationHandler.validate();
    }
    deleteSchoolDesignation(button) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const schoolDesignationAccordion = button.closest('.school-designation-accordion-container');
            const schoolDesignationPlanPropertyModel = {
                PlanFK: this._planPK,
                PlanPropertyPK: parseInt(schoolDesignationAccordion.dataset.planpropertypk),
                PropertyFK: 0,
                TextValue: null,
                LookupCodeFK: null,
                RowNbr: null,
                IsDeletedInd: false
            };
            const response = yield fetch(`/${this._navigationCode}/DeleteSchoolDesignation/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ PlanPK: this._planPK, PageCode: this._pageCode, PlanPropertyModels: [schoolDesignationPlanPropertyModel] })
            });
            if ((response === null || response === void 0 ? void 0 : response.ok) && response.json()) {
                schoolDesignationAccordion.remove();
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the School Designation.', 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'There was an error deleting the School Designation, please try again.', 'error', 3000, null);
            }
            Core.hideLoader();
        });
    }
    createSchoolDesignation() {
        return __awaiter(this, void 0, void 0, function* () {
            const grade = document.getElementById('cyberCharterProgressSchoolDesignationGrade');
            const subject = document.getElementById('cyberCharterProgressSchoolDesignationSubject');
            const measurableStudentGroups = document.querySelectorAll('input[name="studentGroup"][data-propertypk]:checked');
            if (!grade.selectedIndex) {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a grade from the Grade dropdown menu.', 'warning', 3000, null);
                return;
            }
            if (!subject.selectedIndex) {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a subject from the Subject dropdown menu.', 'warning', 3000, null);
                return;
            }
            if (!measurableStudentGroups.length) {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a measurable student group from dropdown menu.', 'warning', 3000, null);
                return;
            }
            const gradePlanProp = {
                PropertyFK: parseInt(grade.dataset.propertypk),
                PlanFK: this._planPK,
                PlanPropertyPK: 0,
                TextValue: null,
                LookupCodeFK: parseInt(grade.value),
                IsDeletedInd: false,
                RowNbr: null
            };
            const subjectPlanProp = {
                PropertyFK: parseInt(subject.dataset.propertypk),
                PlanFK: this._planPK,
                PlanPropertyPK: 0,
                TextValue: null,
                LookupCodeFK: parseInt(subject.value),
                IsDeletedInd: false,
                RowNbr: null
            };
            Core.showLoader();
            const request = yield fetch(`/${this._navigationCode}/CreateSchoolDesignation/`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    PlanPK: this._planPK,
                    Grade: gradePlanProp,
                    Subject: subjectPlanProp,
                    MeasurableStudentGroupPropertyFKs: [...measurableStudentGroups].map(sg => sg.dataset.propertypk)
                })
            });
            if ((request === null || request === void 0 ? void 0 : request.ok) && (yield request.json()))
                this.reloadPageOnCreatingSchoolDesignation();
            Core.hideLoader();
        });
    }
    reloadPageOnCreatingSchoolDesignation() {
        const url = window.location.href;
        const fromSave = '?fromSave=true';
        if (url.includes(fromSave))
            url.replace(fromSave, '');
        this._saveHandler.sendXMLHttpRequest(JSON.stringify({
            PlanPK: this._planPK,
            PageCode: this._pageCode,
            PlanPropertyModels: this._saveHandler.getAllPlanPropertiesToSave()
        }), `/${this._navigationCode}/savecybercharterschoolrenewalapplicationpage/`, null, null, true);
        window.location.href = window.location.href;
    }
    handleHasDesignation() {
        var hasDesignationRadioYesEl = document.querySelectorAll(".cybercharterschoolrenewalapplicationProgressTowardInitialGoalsRadioField[data-propertycode='cyberCharterProgressStateAssessmentComprehensiveSupport'][data-lookuplabel='Yes']:checked");
        var hasDesignation = hasDesignationRadioYesEl != null && hasDesignationRadioYesEl.length > 0;
        var designationFieldsContainer = document.getElementById("designationFields");
        if (hasDesignation) {
            designationFieldsContainer.classList.remove("hide");
        }
        else {
            designationFieldsContainer.classList.add("hide");
        }
        const allElements = [];
        for (const validationClass of this._fieldset.ValidationClasses) {
            const elementsWithClass = document.querySelectorAll(`.${validationClass}:not([data-propertycode='cyberCharterProgressStateAssessmentComprehensiveSupport'])`);
            allElements.push(...elementsWithClass);
        }
        for (const element of allElements) {
            if (hasDesignation) {
                if (element.closest('.shared-page-row')) {
                    element.closest('.shared-page-row').classList.remove('hide');
                }
                if (element.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementRequired(element);
                }
            }
            else {
                if (element.closest('.shared-page-row')) {
                    element.closest('.shared-page-row').classList.add('hide');
                }
                if (element.dataset.initiallyrequired === "true") {
                    this._validationHandler.forceElementOptional(element);
                }
            }
        }
        if (hasDesignation) {
            var improvementPlan = document.querySelector("[data-propertycode='cyberCharterProgressStateAssessmentSubmittedImprovementPlan'][data-lookuplabel='No']");
            var improvementPlanExplanation = document.querySelector("[data-propertycode='cyberCharterProgressStateAssessmentSubmittedImprovementPlanExplanation']");
            if (improvementPlan != null && improvementPlan.checked) {
                this._validationHandler.forceElementRequired(improvementPlanExplanation);
            }
            else {
                this._validationHandler.forceElementOptional(improvementPlanExplanation);
            }
        }
    }
}
class CyberCharterSchoolRenewalApplicationBenchmarksAndGoals extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationEducationalPrograms extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationSchoolOperationsAndManagemnet extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationStaffEvaluationAndProfessionalDevelopment extends CyberCharterSchoolRenewalApplicationPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields);
        const label = document.getElementById('cyberCharterStaffEvaluationRetirementSystemLabel');
        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`;
    }
}
class CyberCharterSchoolRenewalApplicationFinancialSolvency extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationStudentServices extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationSchoolGovernance extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationOverallSchoolDesign extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationCommunicationToParentsGuardiansAndCommunity extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationStudentEnrollment extends CyberCharterSchoolRenewalApplicationPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null, useGenericValidation = false) {
        var _a;
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields, useGenericValidation);
        this.setUpPage();
        if (((_a = this._fromSaveElement) === null || _a === void 0 ? void 0 : _a.value) === 'true')
            this.validateStudentEnrollment();
    }
    setUpPage() {
        const label = document.getElementById('cyberCharterStudentEnrollmentDeclineWhatYears');
        label.innerHTML = `${label.innerHTML} <span class='required-label'>*</span>`;
        // this._validationHandler.validate = this.validateStudentEnrollment
        this._enrollementDeclineRadioButtons = document.getElementsByName('cyberCharterStudentEnrollmentDeclineGreaterThan10PercentRadio');
        this._enrollementVarianceRadioButtons = document.getElementsByName('cyberCharterStudentEnrollmentUnderOverEnrolledRadio');
        for (const button of this._enrollementDeclineRadioButtons) {
            if (button.dataset.lookuplabel === 'Yes') {
                button.addEventListener('click', (e) => this.toggleEnrollmentDeclineCheckboxVisibility(button, true));
                if (button.checked) {
                    this.toggleEnrollmentDeclineCheckboxVisibility(button, true);
                }
            }
            else if (button.dataset.lookuplabel === 'No') {
                button.addEventListener('click', (e) => this.toggleEnrollmentDeclineCheckboxVisibility(button, false));
                if (button.checked) {
                    this.toggleEnrollmentDeclineCheckboxVisibility(button, false);
                }
            }
        }
        for (const button of this._enrollementVarianceRadioButtons) {
            if (button.dataset.lookuplabel === 'Yes') {
                button.addEventListener('click', (e) => this.toggleEnrollmentVarianceTableVisibility(button, true));
                if (button.checked) {
                    this.toggleEnrollmentVarianceTableVisibility(button, true);
                }
            }
            else if (button.dataset.lookuplabel === 'No') {
                button.addEventListener('click', (e) => this.toggleEnrollmentVarianceTableVisibility(button, false));
                if (button.checked) {
                    this.toggleEnrollmentVarianceTableVisibility(button, false);
                }
            }
        }
    }
    toggleEnrollmentDeclineCheckboxVisibility(button, isYes) {
        const checkboxContainer = document.querySelector('.property-group[data-propertygroupcodepk="5029"]');
        if (button.checked && isYes) {
            checkboxContainer.classList.remove('display-none');
        }
        else if (button.checked && !isYes) {
            checkboxContainer.classList.add('display-none');
        }
    }
    toggleEnrollmentVarianceTableVisibility(button, isYes) {
        const tableContainer = document.querySelector('.shared-page-table-container[data-propertygroupcodepk="5032"]');
        if (button.checked && isYes) {
            tableContainer.classList.remove('display-none');
        }
        else if (button.checked && !isYes) {
            tableContainer.classList.add('display-none');
        }
    }
    validateStudentEnrollment() {
        const skipElements = [];
        for (const button of this._enrollementVarianceRadioButtons) {
            if (button.dataset.lookuplabel === 'No' && button.checked) {
                skipElements.push('50262');
                skipElements.push('50263');
            }
        }
        for (const button of this._enrollementDeclineRadioButtons) {
            if (button.dataset.lookuplabel === 'No' && button.checked) {
                skipElements.push('50235');
                skipElements.push('50236');
                skipElements.push('50237');
                skipElements.push('50238');
                skipElements.push('50239');
            }
        }
        const errorCount = this._validationHandler.validateElements(skipElements);
        const showErrorMessage = !!errorCount;
        const singularOrPlural = {
            toBe: errorCount === 1 ? 'is' : 'are',
            plural: errorCount === 1 ? '' : 's',
            first: errorCount === 1 ? '' : 'first'
        };
        const message = document.getElementById('validationMessage');
        message.innerHTML = `<p class='validationErrorCountMessage'>There ${singularOrPlural.toBe} ${errorCount} issue${singularOrPlural.plural} to fix on this page.</p><a id='goToFirstError' href='javascript:void(0)'>Go to ${singularOrPlural.first} issue.</a>`;
        const goToError = document.getElementById('goToFirstError');
        if (goToError) {
            const firstMissingElement = document.querySelector('.missing-field');
            if (firstMissingElement) {
                goToError.addEventListener('click', (e) => {
                    const accordion = Core.findClosest(firstMissingElement, '.Accordion-panel');
                    if (accordion) {
                        const id = accordion.getAttribute('aria-labelledby');
                        const accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (!firstMissingElement.hasAttribute("tabindex")) {
                        firstMissingElement.setAttribute("tabindex", "-1");
                    }
                    firstMissingElement.focus();
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        const messageContainerColumn = document.getElementById('validationColumn');
        const messageContainer = document.getElementById('validationMessageContainer');
        messageContainerColumn.classList.add('show');
        const validationIcon = document.getElementById('validationMessageIcon');
        setTimeout(() => messageContainer.focus(), 500);
        if (showErrorMessage) {
            const message = document.getElementById('validationMessage');
            messageContainer.classList.add('warning');
            message.classList.add('show');
            validationIcon.innerHTML = `<i class='fas fa-exclamation-triangle'></i>`;
        }
        else {
            messageContainer.classList.add('success');
            validationIcon.innerHTML = `<i class='fas fa-check-circle'></i>`;
            const successMessage = document.getElementById('saveSuccess');
            if (successMessage)
                successMessage.innerHTML = 'The page has been successfully saved.';
        }
    }
}
class CyberCharterSchoolRenewalApplicationPoliciesAndProcedures extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationTechnologyAndSupport extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationTruancyPolicies extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationExtracurricularActivities extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationSchoolSafety extends CyberCharterSchoolRenewalApplicationPage {
}
class CyberCharterSchoolRenewalApplicationSignatureAndAssurances extends CyberCharterSchoolRenewalApplicationPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false, customValidationFields = null) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates, customValidationFields);
        let that = this;
        that._fileHandler.OnUpload = function (input) {
            if (input.dataset.propertycode === "cyberCharterSignaturesAndAffirmationsUploadBoardAffirmationStatement") {
                that._saveHandler.save("save");
            }
        };
    }
}
class CyberCharterSchoolRenewalApplicationSummaryChecklistAndSubmission extends CyberCharterSchoolRenewalApplicationPage {
    constructor(navigationCode, pageCode, pageLabel, planPK, templateFK, allowDuplicates = false) {
        super(navigationCode, pageCode, pageLabel, planPK, templateFK);
        this._summaryChecklistAndSubmissionHandler = new SummaryChecklistAndSubmissionHandler(navigationCode);
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// FederalLEAProfile
class FederalLEAProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalLEAProfileField"];
        let federalLEAProfileSaveButton = document.getElementById("federalLEAProfileSave");
        if (federalLEAProfileSaveButton !== null)
            federalLEAProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("federalLEAProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalLEAProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// FederalLEAParent
class FederalLEAParent {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalLEAParentField", "federalLEAParentRadioField", "federalLEAParentFileField"];
        let federalLEAParentSaveButton = document.getElementById("federalLEAParentSave");
        if (federalLEAParentSaveButton !== null)
            federalLEAParentSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let uploads = document.getElementsByClassName("federalLEAParentFileField");
        for (let upload of uploads)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAParentForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalLEAParentField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("federalLEAParentRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("federalLEAParentFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAParent', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;
        const fileType = e.dataset.filetype;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalLEAParentFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`.uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = buttonElement.dataset.planfk;
        const fileType = buttonElement.dataset.filetype;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planfk", planFK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const fileType = buttonElement.dataset.filetype;
        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}'][data-filetype='${fileType}']`);
                    element.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileColumn[data-filetype='${fileType}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// FederalLEATransportation
class FederalLEATransportation {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalLEATransportationField", "federalLEATransportationRadioField", "federalLEATransportationCheckboxField", "federalLEATransportationFileField"];
        let federalLEATransportationSaveButton = document.getElementById("federalLEATransportationSave");
        if (federalLEATransportationSaveButton !== null)
            federalLEATransportationSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let uploads = document.getElementsByClassName("federalLEATransportationFileField");
        for (let upload of uploads)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEATransportationForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalLEATransportationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("federalLEATransportationRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let checkInputs = document.getElementsByClassName("federalLEATransportationCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("safeSchoolsFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEATransportation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadedFiles`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalLEAParentFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`.uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}
// FederalLEAHead
class FederalLEAHead {
    constructor() {
        this._core = new Core();
        this.checkForExempt();
        this.validationClasses = ["federalLEAHeadField", "federalLEAHeadRadioField", "federalLEAHeadFileField", "federalLEAHeadCheckboxField", "federalLEAHeadSelectField"];
        let federalLEAHeadSaveButton = document.getElementById("federalLEAHeadSave");
        if (federalLEAHeadSaveButton !== null)
            federalLEAHeadSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let uploads = document.getElementsByClassName("federalLEAHeadFileField");
        for (let upload of uploads)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
        const federalLEAHeadExempt = document.getElementById("federalLEAHeadExempt");
        if (federalLEAHeadExempt !== null)
            federalLEAHeadExempt.addEventListener("click", (e) => this.checkForExempt());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAHeadForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalLEAHeadField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("federalLEAHeadRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let checkInputs = document.getElementsByClassName("federalLEAHeadCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: textValue,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let selects = document.getElementsByClassName("federalLEAHeadSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("federalLEAHeadFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let saveItem = {
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAHead', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadedFiles`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalLEAParentFileField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`.uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    checkForExempt() {
        const federalLEAHeadExempt = document.getElementById("federalLEAHeadExempt");
        const allElements = document.getElementsByClassName("federalLEAHeadElement");
        for (const ele of allElements) {
            if (ele instanceof HTMLInputElement) {
                const element = ele;
                if (federalLEAHeadExempt.checked) {
                    element.readOnly = true;
                    element.disabled = true;
                    this._core.forceElementOptional(element);
                }
                else {
                    element.readOnly = false;
                    element.disabled = false;
                    if (element.dataset.originalpercent === "1.0") {
                        this._core.forceElementRequired(element);
                    }
                }
            }
            else if (ele instanceof HTMLSelectElement) {
                const element = ele;
                if (federalLEAHeadExempt.checked) {
                    element.disabled = true;
                    this._core.forceElementOptional(element);
                }
                else {
                    element.disabled = false;
                    if (element.dataset.originalpercent === "1.0") {
                        this._core.forceElementRequired(element);
                    }
                }
            }
            else if (ele instanceof HTMLTextAreaElement) {
                const element = ele;
                if (federalLEAHeadExempt.checked) {
                    element.readOnly = true;
                    this._core.forceElementOptional(element);
                }
                else {
                    element.readOnly = false;
                    if (element.dataset.originalpercent === "1.0") {
                        this._core.forceElementRequired(element);
                    }
                }
            }
        }
    }
}
// FederalLEAEquity
class FederalLEAEquity {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalLEAEquityField", "federalLEAEquityCheckboxField", "federalLEAEquitySelectField", "federalLEAEquitySpecialSelectField"];
        let federalLEAEquitySaveButton = document.getElementById("federalLEAEquitySave");
        if (federalLEAEquitySaveButton !== null)
            federalLEAEquitySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let equityWorksheetCreateButton = document.getElementById("equityWorksheetCreate");
        if (equityWorksheetCreateButton !== null)
            equityWorksheetCreateButton.addEventListener("click", (e) => this.addEquityWorksheet());
        this.bindDeleteEquityWorksheets();
        let deleteEquityWorksheetCancelButton = document.getElementById("deleteEquityWorksheetCancel");
        if (deleteEquityWorksheetCancelButton !== null)
            deleteEquityWorksheetCancelButton.addEventListener("click", (e) => this.deleteEquityWorksheetCancel());
        let deleteEquityWorksheetConfirmButton = document.getElementById("deleteEquityWorksheetConfirm");
        if (deleteEquityWorksheetConfirmButton !== null)
            deleteEquityWorksheetConfirmButton.addEventListener("click", (e) => this.deleteEquityWorksheetConfirm(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let allAccordionSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEAEquityForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalLEAEquityField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.dataset.datapointplanpropertypk) {
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        ButtonRow: 0,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK
                    };
                    allAccordionSaveElements.push(saveItem);
                }
            }
            else {
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let checkInputs = document.getElementsByClassName("federalLEAEquityCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let textValue = "";
            if (element.checked)
                textValue = "on";
            else
                textValue = "off";
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            if (element.dataset.datapointplanpropertypk) {
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK
                };
                allAccordionSaveElements.push(saveItem);
            }
            else {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let specialSelects = document.getElementsByClassName("federalLEAEquitySpecialSelectField");
        for (let ele of specialSelects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("federalLEAEquitySelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.dataset.datapointplanpropertypk) {
                let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: '',
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        ButtonRow: 0,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK
                    };
                    allAccordionSaveElements.push(saveItem);
                }
            }
            else {
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: '',
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let everything = {
            "ElementData": allSaveElements,
            "AccordionElementData": allAccordionSaveElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEAEquity', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(everything));
        }
    }
    addEquityWorksheet() {
        let dropdown = document.getElementById("equityWorksheetSchoolName");
        let dropdownValue = parseInt(dropdown.value);
        let planForm = document.getElementById("federalLEAEquityForm");
        let planFK = planForm.dataset.planfk;
        let that = this;
        let newRow = 0;
        if (dropdownValue > 0) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsLEA/AddEquityWorksheet', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    let container = document.getElementById("equityWorksheets");
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = xhr.responseText;
                    container.appendChild(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    that.bindDeleteEquityWorksheets();
                    Core.createHTMLAlert("alertMessageDiv", "Equity Plan Worksheet added!", 'success', 2000, null);
                    dropdown.selectedIndex = 0;
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                    Core.hideLoader();
                }
            };
            xhr.send(`institutionPK=${dropdownValue}&planFK=${planFK}&row=${newRow}`);
        }
    }
    bindDeleteEquityWorksheets() {
        let allDeletes = document.getElementsByClassName("deleteThisWorksheet");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.deleteThisWorksheet(e));
    }
    deleteThisWorksheet(e) {
        let buttonElement = e.target;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let modal = new Modal("deleteEquityWorksheetModal", null);
        modal.addAttributeToElement("deleteEquityWorksheetModal", "#deleteEquityWorksheetConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }
    deleteEquityWorksheetCancel() {
        let modal = new Modal("deleteEquityWorksheetModal", null);
        modal.hide();
    }
    deleteEquityWorksheetConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let container = document.querySelector(`.equityWorksheet[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        let allElements = container.querySelectorAll(`.equityWorksheetField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        planProps.push(dataPointPlanPropertyPK);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/DeleteEquityWorksheet', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let accordionElement = document.querySelector(`.Accordion-trigger[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();
                let modal = new Modal("deleteEquityWorksheetModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The equity worksheet has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}
// FederalLEASignatures
class FederalLEASignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalLEASignaturesField"];
        let federalLEASignaturesSaveButton = document.getElementById("federalLEASignaturesSave");
        if (federalLEASignaturesSaveButton !== null)
            federalLEASignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalLEASignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalLEASignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsLEA/SaveFederalLEASignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Page successfully saved. Please wait while the page refreshes.", 'success', 3000, null);
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// FederalLEASummary
class FederalLEASummary {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsLEA/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// FederalSchoolProfile
class FederalSchoolProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSchoolProfileField"];
        let federalSchoolProfileSaveButton = document.getElementById("federalSchoolProfileSave");
        if (federalSchoolProfileSaveButton !== null)
            federalSchoolProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("federalSchoolProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSchoolProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSchoolProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSchool/SaveFederalSchoolProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// FederalSchoolParent
class FederalSchoolParent {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSchoolParentField", "federalSchoolParentFileUploadField"];
        let federalSchoolParentSaveButton = document.getElementById("federalSchoolParentSave");
        if (federalSchoolParentSaveButton !== null)
            federalSchoolParentSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let upload = document.getElementById("federalSchoolParentParentandFamilyEngagementPolicy");
        if (upload !== null)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSchoolParentForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let allFileElements = [];
        let files = document.getElementsByClassName("federalSchoolsParentFile");
        for (let ele of files) {
            let element = ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let thisData = {
                "PropertyPK": propertyPK,
                "PlanPropertyFilePK": planPropertyFilePK,
                "PlanFK": planPK
            };
            allFileElements.push(thisData);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSchool/SaveFederalSchoolParent', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allFileElements));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSchool/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadFileList`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalSchoolsParentFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`#uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
        this.bindDeleteFiles();
    }
    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = buttonElement.dataset.planfk;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planfk", planFK);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const files = buttonElement.dataset.files;
        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSchool/DeleteSchoolParentFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}']`);
                    element.remove();
                    const moreFiles = document.querySelectorAll(`.federalSchoolsParentFile`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                    that._core.pageReload(true, planFK);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// FederalSchoolCompact
class FederalSchoolCompact {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSchoolCompactField", "federalSchoolCompactFileUploadField"];
        let federalSchoolCompactSaveButton = document.getElementById("federalSchoolCompactSave");
        if (federalSchoolCompactSaveButton !== null)
            federalSchoolCompactSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let upload = document.getElementById("federalSchoolCompactSchoolParentStudentCompact");
        if (upload !== null)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSchoolCompactForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let allFileElements = [];
        let files = document.getElementsByClassName("federalSchoolsCompactFile");
        for (let ele of files) {
            let element = ele;
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let thisData = {
                "PropertyPK": propertyPK,
                "PlanPropertyFilePK": planPropertyFilePK,
                "PlanFK": planPK
            };
            allFileElements.push(thisData);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSchool/SaveFederalSchoolCompact', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allFileElements));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let propertyCode = e.id;
        let planPropertyPK = e.dataset.planpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSchool/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadFileList`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalSchoolsCompactFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`#uploadedFiles`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
        this.bindDeleteFiles();
    }
    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilefk;
        const planFK = buttonElement.dataset.planfk;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planfk", planFK);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const files = buttonElement.dataset.files;
        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSchool/DeleteSchoolCompactFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}']`);
                    element.remove();
                    const moreFiles = document.querySelectorAll(`.federalSchoolsCompactFile`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                    that._core.pageReload(true, planFK);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// FederalSchoolSummary
class FederalSchoolSummary {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSchool/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// FederalFiscalProfile
class FederalFiscalProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalFiscalProfileField"];
        let federalFiscalProfileSaveButton = document.getElementById("federalFiscalProfileSave");
        if (federalFiscalProfileSaveButton !== null)
            federalFiscalProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("federalFiscalProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalFiscalProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// FederalFiscalSignatures
class FederalFiscalSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalFiscalSignaturesField", "federalFiscalSignaturesCheckboxField"];
        let federalFiscalSignaturesSaveButton = document.getElementById("federalFiscalSignaturesSave");
        if (federalFiscalSignaturesSaveButton !== null)
            federalFiscalSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
            this.assuranceCheck();
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalFiscalSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("federalFiscalSignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    assuranceCheck() {
        const allAssurances = document.getElementsByClassName("federalFiscalSignaturesCheckboxField");
        let showAssuranceWarning = false;
        for (const assurance of allAssurances) {
            const ele = assurance;
            if (!ele.checked)
                showAssuranceWarning = true;
        }
        const warningElement = document.getElementById("assuranceWarning");
        if (showAssuranceWarning)
            warningElement.classList.remove("hide");
        else
            warningElement.classList.add("hide");
    }
}
// FederalFiscalSummary
class FederalFiscalSummary {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsFiscal/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// FederalFiscalEquipmentInventory
class FederalFiscalEquipmentInventory {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalFiscalEquipmentInventoryField", "federalFiscalEquipmentInventoryCheckboxField", "federalFiscalEquipmentInventoryRadioField", "federalFiscalEquipmentInventoryFileUploadField"];
        let federalFiscalEquipmentInventorySaveButton = document.getElementById("federalFiscalEquipmentInventorySave");
        if (federalFiscalEquipmentInventorySaveButton !== null)
            federalFiscalEquipmentInventorySaveButton.addEventListener("click", (e) => this.save("save"));
        this.useFederalFunds();
        this.ownEquipmentInventory();
        this.bindEquipmentTransferred();
        this.bindEquipmentDisposed();
        this.bindDeleteEquipment();
        this.bindOwnEquipment();
        this.bindDeleteFiles();
        this.bindUseFederalFunds();
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const insertEquipmentCreateButton = document.getElementById("federalFiscalTechnologyInsertEquipment");
        if (insertEquipmentCreateButton !== null)
            insertEquipmentCreateButton.addEventListener("click", (e) => this.addEquipment());
        const deleteInsertEquipmentCancelButton = document.getElementById("deleteEquipmentCancel");
        if (deleteInsertEquipmentCancelButton !== null)
            deleteInsertEquipmentCancelButton.addEventListener("click", (e) => this.deleteEquipmentCancel());
        const deleteInsertEquipmentConfirmButton = document.getElementById("deleteEquipmentConfirm");
        if (deleteInsertEquipmentConfirmButton !== null)
            deleteInsertEquipmentConfirmButton.addEventListener("click", (e) => this.deleteEquipmentConfirm(e));
        const uploads = document.getElementsByClassName("fileUploaders");
        for (const upload of uploads)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const allFileElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalEquipmentInventoryForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("federalFiscalEquipmentInventoryField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("federalFiscalEquipmentInventoryCheckboxField");
        for (const ele of checkboxes) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("federalFiscalEquipmentInventoryRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const files = document.getElementsByClassName("federalFiscalEquipmentInventoryFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = 0;
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const allData = {
            "SaveData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalEquipmentInventory', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    bindDeleteEquipment() {
        const allDeletes = document.getElementsByClassName("deleteThisEquipment");
        for (const del of allDeletes)
            del.addEventListener("click", (e) => this.deleteThisEquipment(e));
    }
    deleteThisEquipment(e) {
        const buttonElement = e.target;
        const row = buttonElement.dataset.row;
        const modal = new Modal("deleteEquipmentModal", null);
        modal.addAttributeToElement("deleteEquipmentModal", "#deleteEquipmentConfirm", "row", row);
        modal.show();
    }
    deleteEquipmentConfirm(e) {
        const buttonElement = e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;
        const container = document.querySelector(`.equipmentContainer[data-row='${row}']`);
        const allElements = container.querySelectorAll(`.equipmentField[data-row='${row}']`);
        for (const ele of allElements) {
            const element = ele;
            const planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        const accElement = document.querySelector(`.Accordion-trigger[data-row='${row}']`);
        const accPlanPropPK = accElement.dataset.planpropertypk;
        planProps.push(parseInt(accPlanPropPK));
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/DeleteEquipment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const accordionElement = document.querySelector(`.Accordion-trigger[data-row='${row}']`);
                accordionElement.parentElement.parentElement.parentElement.remove();
                const modal = new Modal("deleteEquipmentModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The equity worksheet has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteEquipmentCancel() {
        const modal = new Modal("deleteEquipmentModal", null);
        modal.hide();
    }
    addEquipment() {
        const equipment = document.getElementById("federalFiscalTechnologyEquipmentNameorQuickReference");
        const equipmentValue = equipment.value;
        const planForm = document.getElementById("federalFiscalEquipmentInventoryForm");
        const planFK = planForm.dataset.planfk;
        const that = this;
        let newRow = -1;
        if (equipmentValue !== "") {
            Core.showLoader();
            const allAccordions = document.getElementsByClassName("Accordion-panel");
            if (allAccordions && allAccordions.length > 0) {
                for (const accordion of allAccordions) {
                    const accord = accordion;
                    if (parseInt(accord.dataset.row) > newRow)
                        newRow = parseInt(accord.dataset.row);
                }
            }
            newRow++;
            const data = {
                "EquipmentName": equipmentValue,
                "PlanFK": planFK,
                "Row": newRow
            };
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsFiscal/AddEquipment', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    const container = document.getElementById("equipment");
                    const newDiv = document.createElement("div");
                    newDiv.innerHTML = xhr.responseText;
                    container.appendChild(newDiv);
                    const idControl = newDiv.querySelector(".Accordion h2 button");
                    const id = idControl.id;
                    new CustomAccordion(id);
                    that.bindDeleteEquipment();
                    that.bindEquipmentTransferred();
                    that.bindEquipmentDisposed();
                    equipment.value = "";
                    Core.createHTMLAlert("alertMessageDiv", "Equipment record added!", 'success', 2000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                    Core.hideLoader();
                }
            };
            xhr.send(JSON.stringify(data));
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", `Enter a value in the Equipment Name or Quick Reference field to add a record.`, 'warning', 3000, null);
        }
    }
    useFederalFunds() {
        const useFederalFunds = document.querySelector(".useFederalFunds:checked");
        if (useFederalFunds) {
            const value = useFederalFunds.dataset.lookupcode;
            if (value === "yes") {
                const allElementsToDisable = document.querySelectorAll(".equipmentInventory");
                for (const element of allElementsToDisable) {
                    if (element instanceof HTMLButtonElement) {
                        const ele = element;
                        ele.disabled = false;
                    }
                    else {
                        const ele = element;
                        ele.disabled = false;
                        ele.readOnly = false;
                        if (ele.dataset.originalpercent === "1.00")
                            this._core.forceElementRequired(ele);
                    }
                }
            }
            else {
                //Nothing else needs to be filled in.
                const allElementsToEnable = document.querySelectorAll(".equipmentInventory");
                for (const element of allElementsToEnable) {
                    if (element instanceof HTMLButtonElement) {
                        const ele = element;
                        ele.disabled = true;
                    }
                    else {
                        const ele = element;
                        ele.disabled = true;
                        ele.readOnly = true;
                        this._core.forceElementOptional(ele);
                    }
                }
            }
        }
    }
    ownEquipmentInventory() {
        const ownEquipmentInventory = document.querySelector(".ownEquipmentInventory:checked");
        if (ownEquipmentInventory !== null) {
            const value = ownEquipmentInventory.dataset.lookupcode;
            const federalFiscalTechnologyEquipmentNameorQuickReference = document.getElementById("federalFiscalTechnologyEquipmentNameorQuickReference");
            const federalFiscalTechnologyInsertEquipment = document.getElementById("federalFiscalTechnologyInsertEquipment");
            const federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment = document.getElementById("federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment");
            let hasAccordions = false;
            const accordions = document.getElementsByClassName("Accordion");
            if (accordions && accordions.length > 0)
                hasAccordions = true;
            if (value === "no") {
                federalFiscalTechnologyEquipmentNameorQuickReference.disabled = false;
                federalFiscalTechnologyEquipmentNameorQuickReference.readOnly = false;
                federalFiscalTechnologyInsertEquipment.disabled = false;
                federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment.disabled = true;
                this._core.forceElementOptional(federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment);
                if (hasAccordions) {
                    const allElementsToEnable = document.querySelectorAll(".equipmentField");
                    for (const element of allElementsToEnable) {
                        const ele = element;
                        ele.disabled = false;
                        ele.readOnly = false;
                        if (ele.dataset.originalpercent === "1.00")
                            this._core.forceElementRequired(ele);
                    }
                }
            }
            else {
                federalFiscalTechnologyEquipmentNameorQuickReference.disabled = true;
                federalFiscalTechnologyEquipmentNameorQuickReference.readOnly = true;
                federalFiscalTechnologyInsertEquipment.disabled = true;
                federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment.disabled = false;
                this._core.forceElementRequired(federalFiscalEquipmentInventoryUploadLEAInventoryManagementofEquipment);
                if (hasAccordions) {
                    const allElementsToDisable = document.querySelectorAll(".equipmentField");
                    for (const element of allElementsToDisable) {
                        const ele = element;
                        ele.disabled = true;
                        ele.readOnly = true;
                        this._core.forceElementOptional(ele);
                    }
                }
            }
        }
    }
    equipmentTransferred(element) {
        const row = element.dataset.row;
        const targetElements = document.querySelectorAll(`.equipmentTransferredTarget[data-row='${row}']`);
        if (!element.checked) {
            for (const targetElement of targetElements) {
                const ele = targetElement;
                ele.readOnly = true;
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            }
        }
        else {
            for (const targetElement of targetElements) {
                const ele = targetElement;
                ele.readOnly = false;
                ele.disabled = false;
                if (ele.dataset.originalpercent === "1.00")
                    this._core.forceElementRequired(ele);
            }
        }
    }
    uploadFile(e) {
        Core.showLoader();
        let formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const propertyCode = e.id;
        const planPropertyPK = "0"; // e.dataset.planpropertypk;
        const file = e.dataset.file;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-file='${file}']`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalFiscalEquipmentInventoryFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = buttonElement.dataset.planfk;
        const fileType = buttonElement.dataset.filetype;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const fileType = buttonElement.dataset.filetype;
        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}'][data-filetype='${fileType}']`);
                    element.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileColumn[data-filetype='${fileType}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    equipmentDisposed(equipmentDisposedElement) {
        if (equipmentDisposedElement !== null && equipmentDisposedElement.checked) {
            const row = equipmentDisposedElement.dataset.row;
            const element = document.querySelector(`.equipmentInventory[data-row='${row}'][data-disposed='disposed']`);
            if (equipmentDisposedElement.dataset.lookupcode === "yes") {
                element.disabled = false;
                element.readOnly = false;
                this._core.forceElementRequired(element);
            }
            else {
                element.disabled = true;
                element.readOnly = true;
                this._core.forceElementOptional(element);
            }
        }
    }
    bindEquipmentDisposed() {
        const equipmentDisposedElements = document.getElementsByClassName("disposed");
        for (const equipmentDisposedElement of equipmentDisposedElements) {
            equipmentDisposedElement.addEventListener("change", (e) => this.equipmentDisposed(e.target));
            this.equipmentDisposed(equipmentDisposedElement);
        }
    }
    bindEquipmentTransferred() {
        const equipmentTransferreds = document.getElementsByClassName("equipmentTransferred");
        for (const equipmentTransferred of equipmentTransferreds) {
            equipmentTransferred.addEventListener("change", (e) => this.equipmentTransferred(e.target));
            this.equipmentTransferred(equipmentTransferred);
        }
    }
    bindOwnEquipment() {
        const ownEquipmentInventorys = document.getElementsByClassName("ownEquipmentInventory");
        for (const ownEquipmentInventory of ownEquipmentInventorys)
            ownEquipmentInventory.addEventListener("change", (e) => this.ownEquipmentInventory());
    }
    bindUseFederalFunds() {
        const useFederalFunds = document.getElementsByClassName("useFederalFunds");
        for (const useFederalFund of useFederalFunds)
            useFederalFund.addEventListener("change", (e) => this.useFederalFunds());
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const federalFiscalTechnologyEquipmentNameorQuickReferenceElement = document.getElementById("federalFiscalTechnologyEquipmentNameorQuickReference");
        if (!federalFiscalTechnologyEquipmentNameorQuickReferenceElement.disabled) {
            const allAccordions = document.querySelectorAll(`#equipment .Accordion`);
            if (allAccordions.length === 0) {
                federalFiscalTechnologyEquipmentNameorQuickReferenceElement.classList.add("missing-field");
                federalFiscalTechnologyEquipmentNameorQuickReferenceElement.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(federalFiscalTechnologyEquipmentNameorQuickReferenceElement);
                showMessage = true;
                totalErrors++;
            }
        }
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "checkbox") {
                                    if (OtherInputElement.checked) {
                                        alreadyExists = true;
                                    }
                                }
                                else {
                                    if (OtherInputElement.value !== "") {
                                        alreadyExists = true;
                                    }
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex > 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// FederalFiscalUGGPolicies
class FederalFiscalUGGPolicies {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalFiscalUGGPoliciesFileUploadField"];
        let federalFiscalUGGPoliciesSaveButton = document.getElementById("federalFiscalUGGPoliciesSave");
        if (federalFiscalUGGPoliciesSaveButton !== null)
            federalFiscalUGGPoliciesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const uploads = document.getElementsByClassName("fileUploaders");
        for (const upload of uploads)
            upload.addEventListener("change", (e) => this.uploadFile(e.target));
        this.bindDeleteFiles();
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalFiscalUGGPoliciesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalFiscalUGGPoliciesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const allFileElements = [];
        let files = document.getElementsByClassName("federalFiscalUGGPoliciesFile");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const allData = {
            "SaveData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/SaveFederalFiscalUGGPolicies', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                    Core.createHTMLAlert("alertMessageDiv", "Page successefully saved. Please wait.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        let formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const propertyCode = e.id;
        const planPropertyPK = e.dataset.planpropertypk;
        const file = e.dataset.file;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-file='${file}']`);
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.planpropertyfilepk = res.payload.planPropertyFilePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.dataset.planpropertypk = planPropertyPK;
                        fileA.dataset.propertycode = propertyCode;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("federalFiscalUGGPoliciesFile");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        let fullContainer = document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        if (fullContainer.classList.contains("hide"))
                            fullContainer.classList.remove("hide");
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        const deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (const deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = buttonElement.dataset.planfk;
        const file = buttonElement.dataset.file;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planfk", planFK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "file", file);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const planFK = parseInt(buttonElement.dataset.planfk);
        const file = buttonElement.dataset.file;
        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsFiscal/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.uploadFileColumn[data-planpropertyfilefk='${planPropertyFilePK}'][data-file='${file}']`);
                    element.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileColumn[data-file='${file}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        const modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/modal.ts" />
// FederalSSAEProfile
class FederalSSAEProfile {
    constructor() {
        this._core = new Core();
        this.fundsSelection();
        this.validationClasses = ["federalSSAEProfileField", "federalSSAEProfileRadioField"];
        let federalSSAEProfileSaveButton = document.getElementById("federalSSAEProfileSave");
        if (federalSSAEProfileSaveButton !== null)
            federalSSAEProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("federalSSAEProfileForm"));
        const federalSSAEProfileFunds = document.getElementsByClassName("federalSSAEProfileFunds");
        for (const federalSSAEProfileFund of federalSSAEProfileFunds)
            federalSSAEProfileFund.addEventListener("change", (e) => this.fundsSelection());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSSAEProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("federalSSAEProfileRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    fundsSelection() {
        const federalSSAEProfileFunds = document.querySelector(".federalSSAEProfileFunds:checked");
        const fundsDescriptionContainer = document.getElementById("fundsDescription");
        const fundsDescription = document.getElementById("federalSSAEProfileFundsDescription");
        const form = document.getElementById("federalSSAEProfileForm");
        const planFK = form.dataset.planfk;
        if (federalSSAEProfileFunds !== null) {
            if (federalSSAEProfileFunds.dataset.lookupcode === "no") {
                fundsDescriptionContainer.classList.remove("hide");
                this._core.forceElementRequired(fundsDescription);
            }
            else {
                fundsDescriptionContainer.classList.add("hide");
                this._core.forceElementOptional(fundsDescription);
            }
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/FederalSSAEFunds', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue setting statuses. Please try your selection again.", 'error', 3000, null);
            };
            xhr.send(`planFK=${planFK}&lookupCode=${federalSSAEProfileFunds.dataset.lookupcode}`);
        }
    }
}
// FederalSSAEPreparing
class FederalSSAEPreparing {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAEPreparingField", "federalSSAEPreparingSelectField"];
        let federalSSAEPreparingSaveButton = document.getElementById("federalSSAEPreparingSave");
        if (federalSSAEPreparingSaveButton !== null)
            federalSSAEPreparingSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let federalPreparingAddRowButton = document.getElementById("federalPreparingAddRow");
        if (federalPreparingAddRowButton !== null)
            federalPreparingAddRowButton.addEventListener("click", (e) => this.addPreparingRow());
        this.bindDeletePreparingNeedsAssessmentRow();
        let deleteRowCancelButton = document.getElementById("deletePreparingRowCancel");
        if (deleteRowCancelButton !== null)
            deleteRowCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let deleteRowConfirmButton = document.getElementById("deletePreparingRowConfirm");
        if (deleteRowConfirmButton !== null)
            deleteRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const federalSSAEProfileAddRowsNumberInput = document.getElementById("federalPreparingAddRowsNumber");
        if (federalSSAEProfileAddRowsNumberInput !== null)
            federalSSAEProfileAddRowsNumberInput.addEventListener("input", (e) => this.checkForPlural());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEPreparingForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSSAEPreparingField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selects = document.getElementsByClassName("federalSSAEPreparingSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEPreparing', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addPreparingRow() {
        return __awaiter(this, void 0, void 0, function* () {
            let planForm = document.getElementById("federalSSAEPreparingForm");
            let planFK = planForm.dataset.planfk;
            let that = this;
            let newRow = 0;
            const rows = document.getElementsByClassName("preparingRow");
            for (const row of rows) {
                const ele = row;
                if (ele.dataset.row) {
                    const thisRow = parseInt(ele.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
            }
            const numElement = document.getElementById("federalPreparingAddRowsNumber");
            const num = parseInt(numElement.value);
            if (!isNaN(num)) {
                for (let i = 0; i < num; i++) {
                    newRow++;
                    let response = yield fetch(`/FederalProgramsSSAE/AddPreparingForNeedsAssessmentRow/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        Core.hideLoader();
                        let newTR = document.createElement("tr");
                        newTR.classList.add("needs-assessment");
                        newTR.innerHTML = yield response.text();
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#preparingForNeedsAssessment tbody");
                        table.append(newTR);
                        that.bindDeletePreparingNeedsAssessmentRow();
                        Core.createHTMLAlert("alertMessageDiv", "Record added!", 'success', 2000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${response.status}'`, 'error', 3000, null);
                        Core.hideLoader();
                    }
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Please enter a valid number to add rows to the table.`, 'warning', 3000, null);
            }
        });
    }
    bindDeletePreparingNeedsAssessmentRow() {
        let rowElements = document.getElementsByClassName("deletePreparingNeedsAssessmentRow");
        for (let row of rowElements) {
            row.addEventListener("click", (e) => this.deleteThisRow(e));
        }
    }
    deleteThisRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deletePreparingRowModal", null);
        modal.addAttributeToElement("deletePreparingRowModal", "#deletePreparingRowConfirm", "row", row);
        modal.show();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.preparingAssessment[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeletePreparingNeedsAssessmentRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.needs-assessment[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deletePreparingRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The record has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteRowCancel() {
        let modal = new Modal("deletePreparingRowModal", null);
        modal.hide();
    }
    checkForPlural() {
        let word = document.getElementById("rowWord");
        let num = document.getElementById("federalPreparingAddRowsNumber");
        let newWord = "rows";
        if (parseInt(num.value) === 1) {
            newWord = "row";
        }
        word.textContent = newWord;
    }
}
// FederalSSAENeedsDesc
class FederalSSAENeedsDesc {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAENeedsDescField"];
        let federalSSAENeedsDescSaveButton = document.getElementById("federalSSAENeedsDescSave");
        if (federalSSAENeedsDescSaveButton !== null)
            federalSSAENeedsDescSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAENeedsDescForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSSAENeedsDescField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAENeedsDesc', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// FederalSSAEWellRounded
class FederalSSAEWellRounded {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAEWellRoundedField"];
        let federalSSAEWellRoundedSaveButton = document.getElementById("federalSSAEWellRoundedSave");
        if (federalSSAEWellRoundedSaveButton !== null)
            federalSSAEWellRoundedSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let comprehensivePlanDataButton = document.getElementById("comprehensivePlanData");
        if (comprehensivePlanDataButton !== null)
            comprehensivePlanDataButton.addEventListener("click", (e) => this.checkForComprehensiveData(e));
        let runDataCancelButton = document.getElementById("runDataCancel");
        if (runDataCancelButton !== null)
            runDataCancelButton.addEventListener("click", (e) => this.runDataCancel());
        let runDataConfirmButton = document.getElementById("runDataConfirm");
        if (runDataConfirmButton !== null)
            runDataConfirmButton.addEventListener("click", (e) => this.runDataConfirm(e));
        const closeNoCompPlan = document.getElementById("noCompPlanClose");
        if (closeNoCompPlan !== null)
            closeNoCompPlan.addEventListener("click", (e) => this.closeNoCompPlan());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let strengthElements = [];
        let challengeElements = [];
        let observationElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEWellRoundedForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let strengths = document.querySelectorAll(".federalSSAEWellRoundedField[data-type='strength']");
        for (let ele of strengths) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                };
                strengthElements.push(saveItem);
            }
        }
        let challenges = document.querySelectorAll(".federalSSAEWellRoundedField[data-type='challenge']");
        for (let ele of challenges) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                };
                challengeElements.push(saveItem);
            }
        }
        let observations = document.querySelectorAll(".federalSSAEWellRoundedField[data-type='observation']");
        for (let ele of observations) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    ButtonRow: 0,
                    DataPointPlanPropertyPK: parseInt(element.dataset.datapointplanpropertypk)
                };
                observationElements.push(saveItem);
            }
        }
        let saveData = {
            "Strengths": strengthElements,
            "Challenges": challengeElements,
            "Observations": observationElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEWellRounded', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveData === null) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    checkForComprehensiveData(e) {
        return __awaiter(this, void 0, void 0, function* () {
            let element = e.target;
            let planFK = element.dataset.planfk;
            let existingDataElement = document.getElementById("existingData");
            if (existingDataElement.value === "true") {
                let modal = new Modal("confirmDataModal", null);
                modal.addAttributeToElement("confirmDataModal", "#runDataConfirm", "planfk", planFK);
                modal.show();
            }
            else {
                yield this.getComprehensivePlanData(planFK);
            }
        });
    }
    runDataCancel() {
        return __awaiter(this, void 0, void 0, function* () {
            let modal = new Modal("confirmDataModal", null);
            modal.hide();
        });
    }
    closeNoCompPlan() {
        const modal = new Modal("noCompPlanModal", null);
        modal.hide();
    }
    runDataConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            let element = e.target;
            let planFK = element.dataset.planfk;
            let modal = new Modal("confirmDataModal", null);
            modal.hide();
            yield this.getComprehensivePlanData(planFK);
        });
    }
    getComprehensivePlanData(planFK) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let response = yield fetch(`/FederalProgramsSSAE/ComprehensivePlanData/${planFK}`, { credentials: 'include' });
            if (response.ok) {
                let json = yield response.json();
                if (json.pageSummary.noCompPlan === true) {
                    Core.hideLoader();
                    let modal = new Modal("noCompPlanModal", null);
                    modal.show();
                }
                else {
                    let strengthContainer = document.getElementById("strengths");
                    let challengeContainer = document.getElementById("challenges");
                    if (json.pageSummary.strengths !== null && json.pageSummary.strengths.length > 0) {
                        strengthContainer.innerHTML = "";
                        for (let strength of json.pageSummary.strengths) {
                            let divRow = document.createElement("div");
                            divRow.classList.add("row");
                            let divColumn = document.createElement("div");
                            divColumn.classList.add("medium-12");
                            divColumn.classList.add("column");
                            let textArea = document.createElement("textarea");
                            textArea.rows = 3;
                            textArea.classList.add("federalSSAEWellRoundedField");
                            textArea.setAttribute("aria-label", "Strengths");
                            textArea.dataset.datapointpropertypk = strength.primaryPropertyFK;
                            textArea.dataset.datapointplanpropertypk = strength.primaryPlanPropertyFK;
                            textArea.dataset.percent = "0";
                            textArea.dataset.row = strength.rowNbr;
                            textArea.dataset.type = "strength";
                            textArea.dataset.propertypk = json.pageSummary.strengthPropertyPK;
                            textArea.dataset.planpropertypk = "0";
                            textArea.textContent = strength.textValue;
                            textArea.readOnly = true;
                            divColumn.appendChild(textArea);
                            divRow.appendChild(divColumn);
                            strengthContainer.appendChild(divRow);
                        }
                    }
                    if (json.pageSummary.challenges !== null && json.pageSummary.challenges.length > 0) {
                        challengeContainer.innerHTML = "";
                        for (let challenge of json.pageSummary.challenges) {
                            let divRow = document.createElement("div");
                            divRow.classList.add("row");
                            let divColumn = document.createElement("div");
                            divColumn.classList.add("medium-12");
                            divColumn.classList.add("column");
                            let textArea = document.createElement("textarea");
                            textArea.rows = 3;
                            textArea.classList.add("federalSSAEWellRoundedField");
                            textArea.setAttribute("aria-label", "Challenges");
                            textArea.dataset.datapointpropertypk = challenge.primaryPropertyFK;
                            textArea.dataset.datapointplanpropertypk = challenge.primaryPlanPropertyFK;
                            textArea.dataset.percent = "0";
                            textArea.dataset.row = challenge.rowNbr;
                            textArea.dataset.type = "challenge";
                            textArea.dataset.propertypk = json.pageSummary.challengePropertyPK;
                            textArea.dataset.planpropertypk = "0";
                            textArea.textContent = challenge.textValue;
                            textArea.readOnly = true;
                            divColumn.appendChild(textArea);
                            divRow.appendChild(divColumn);
                            challengeContainer.appendChild(divRow);
                        }
                    }
                    if (json.pageSummary.notableObservations !== null) {
                        let textArea = document.getElementById("federalSSAEWellRoundedNotable");
                        textArea.textContent = json.pageSummary.notableObservations.textValue;
                        textArea.dataset.datapointplanpropertypk = json.pageSummary.notableObservations.planPropertyPK;
                        textArea.dataset.row = "0";
                    }
                    let existingDataElement = document.getElementById("existingData");
                    existingDataElement.value = "true";
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Data from the comprehensive plan has been loaded. Please make sure to save the page.", "success", 3000, null);
                }
            }
        });
    }
}
// FederalSSAESafeSchools
class FederalSSAESafeSchools {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAESafeSchoolsField"];
        let federalSSAESafeSchoolsSaveButton = document.getElementById("federalSSAESafeSchoolsSave");
        if (federalSSAESafeSchoolsSaveButton !== null)
            federalSSAESafeSchoolsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let federalSSAESafeSchoolAddDataButton = document.getElementById("federalSSAESafeSchoolAddData");
        if (federalSSAESafeSchoolAddDataButton !== null)
            federalSSAESafeSchoolAddDataButton.addEventListener("click", (e) => this.addSafeSchoolRow());
        let safeSchoolsDeleteCancelButton = document.getElementById("safeSchoolsDeleteCancel");
        if (safeSchoolsDeleteCancelButton !== null)
            safeSchoolsDeleteCancelButton.addEventListener("click", (e) => this.cancelDelete());
        let safeSchoolsDeleteConfirmButton = document.getElementById("safeSchoolsDeleteConfirm");
        if (safeSchoolsDeleteConfirmButton !== null)
            safeSchoolsDeleteConfirmButton.addEventListener("click", (e) => this.confirmDelete(e));
        this.bindDeleteSafeSchoolsRow();
        let safeSchoolsDeleteSummaryCancelButton = document.getElementById("safeSchoolsSummaryDeleteCancel");
        if (safeSchoolsDeleteSummaryCancelButton !== null)
            safeSchoolsDeleteSummaryCancelButton.addEventListener("click", (e) => this.cancelSummaryDelete());
        let safeSchoolsDeleteSummaryConfirmButton = document.getElementById("safeSchoolsSummaryDeleteConfirm");
        if (safeSchoolsDeleteSummaryConfirmButton !== null)
            safeSchoolsDeleteSummaryConfirmButton.addEventListener("click", (e) => this.confirmSummaryDelete(e));
        this.bindDeleteSafeSchoolsSummaryRow();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAESafeSchoolsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSSAESafeSchoolsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAESafeSchools', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addSafeSchoolRow() {
        let planForm = document.getElementById("federalSSAESafeSchoolsForm");
        let planFK = planForm.dataset.planfk;
        let className = "federalSSAESafeSchoolsField";
        let pageCode = planForm.dataset.pagecode;
        let that = this;
        let newRow = 0;
        let allRows = document.querySelectorAll("#federalSSAESafeSchoolsData tbody tr");
        for (let row of allRows) {
            let rowEle = row;
            let thisRow = parseInt(rowEle.dataset.row);
            if (thisRow > newRow)
                newRow = thisRow;
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/AddSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let newTR = document.createElement("tr");
                newTR.innerHTML = xhr.responseText;
                newTR.dataset.row = newRow.toString();
                let table = document.querySelector("#federalSSAESafeSchoolsData tbody");
                table.append(newTR);
                that.bindDeleteSafeSchoolsRow();
                Core.createHTMLAlert("alertMessageDiv", "Row added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}&className=${className}&pageCode=${pageCode}`);
    }
    bindDeleteSafeSchoolsRow() {
        let deleteSafeSchoolsRowButtons = document.getElementsByClassName("deleteSafeSchoolsRow");
        for (let deleteSafeSchoolsRowButton of deleteSafeSchoolsRowButtons)
            deleteSafeSchoolsRowButton.addEventListener("click", (e) => this.showDeleteSafeSchoolRow(e));
    }
    showDeleteSafeSchoolRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteSafeSchoolsRowModal", null);
        modal.addAttributeToElement("deleteSafeSchoolsRowModal", "#safeSchoolsDeleteConfirm", "row", row);
        modal.show();
    }
    cancelDelete() {
        let modal = new Modal("deleteSafeSchoolsRowModal", null);
        modal.hide();
    }
    confirmDelete(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`#federalSSAESafeSchoolsData tbody tr[data-row='${row}'] .federalSSAESafeSchoolsField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#federalSSAESafeSchoolsData tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteSafeSchoolsRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindDeleteSafeSchoolsSummaryRow() {
        let deleteSafeSchoolsRowButtons = document.getElementsByClassName("deleteSafeSchoolsStrengthChallengeRow");
        for (let deleteSafeSchoolsRowButton of deleteSafeSchoolsRowButtons)
            deleteSafeSchoolsRowButton.addEventListener("click", (e) => this.showDeleteSafeSchoolSummaryRow(e));
    }
    showDeleteSafeSchoolSummaryRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;
        let modal = new Modal("deleteSafeSchoolsSummaryRowModal", null);
        modal.addAttributeToElement("deleteSafeSchoolsSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteSafeSchoolsSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "propertycode", propertyCode);
        modal.show();
    }
    cancelSummaryDelete() {
        let modal = new Modal("deleteSafeSchoolsSummaryRowModal", null);
        modal.hide();
    }
    confirmSummaryDelete(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;
        let element = document.querySelector(`.federalSSAESafeSchoolsField[data-propertycode='${propertyCode}'][data-row='${row}']`);
        let planProp = element.dataset.planpropertypk;
        if (planProp && parseInt(planProp) > 0) {
            planProps.push(planProp);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.federalSSAESafeSchoolsField[data-propertycode='${propertyCode}'][data-row='${row}']`);
                rowElement.textContent = "";
                rowElement.innerText = "";
                rowElement.value = "";
                rowElement.dataset.planpropertypk = "0";
                let modal = new Modal("deleteSafeSchoolsSummaryRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}
// FederalSSAEEffectiveTech
class FederalSSAEEffectiveTech {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAEEffectiveTechField"];
        let federalSSAEEffectiveTechSaveButton = document.getElementById("federalSSAEEffectiveTechSave");
        if (federalSSAEEffectiveTechSaveButton !== null)
            federalSSAEEffectiveTechSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let federalSSAEEffectiveTechAddDataButton = document.getElementById("federalSSAEEffectiveTechAddData");
        if (federalSSAEEffectiveTechAddDataButton !== null)
            federalSSAEEffectiveTechAddDataButton.addEventListener("click", (e) => this.addEffectiveTechRow());
        let safeSchoolsDeleteCancelButton = document.getElementById("safeSchoolsDeleteCancel");
        if (safeSchoolsDeleteCancelButton !== null)
            safeSchoolsDeleteCancelButton.addEventListener("click", (e) => this.cancelDataRowDelete());
        let safeSchoolsDeleteConfirmButton = document.getElementById("safeSchoolsDeleteConfirm");
        if (safeSchoolsDeleteConfirmButton !== null)
            safeSchoolsDeleteConfirmButton.addEventListener("click", (e) => this.confirmDataRowDelete(e));
        this.bindDeleteEffectiveTechDataRow();
        let safeSchoolsDeleteSummaryCancelButton = document.getElementById("safeSchoolsSummaryDeleteCancel");
        if (safeSchoolsDeleteSummaryCancelButton !== null)
            safeSchoolsDeleteSummaryCancelButton.addEventListener("click", (e) => this.cancelEffectiveTechSummaryDelete());
        let safeSchoolsDeleteSummaryConfirmButton = document.getElementById("safeSchoolsSummaryDeleteConfirm");
        if (safeSchoolsDeleteSummaryConfirmButton !== null)
            safeSchoolsDeleteSummaryConfirmButton.addEventListener("click", (e) => this.confirmEffectiveTechSummaryDelete(e));
        this.bindDeleteEffectiveTechSummaryRow();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEEffectiveTechForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSSAEEffectiveTechField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEEffectiveTech', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addEffectiveTechRow() {
        let planForm = document.getElementById("federalSSAEEffectiveTechForm");
        let planFK = planForm.dataset.planfk;
        let className = "federalSSAEEffectiveTechField";
        let pageCode = planForm.dataset.pagecode;
        let that = this;
        let newRow = 0;
        let allRows = document.querySelectorAll("#federalSSAEEffectiveTechData tbody tr");
        for (let row of allRows) {
            let rowEle = row;
            let thisRow = parseInt(rowEle.dataset.row);
            if (thisRow > newRow)
                newRow = thisRow;
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/AddSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let newTR = document.createElement("tr");
                newTR.innerHTML = xhr.responseText;
                newTR.dataset.row = newRow.toString();
                let table = document.querySelector("#federalSSAEEffectiveTechData tbody");
                table.append(newTR);
                that.bindDeleteEffectiveTechDataRow();
                Core.createHTMLAlert("alertMessageDiv", "Row added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                Core.hideLoader();
            }
        };
        xhr.send(`planFK=${planFK}&row=${newRow}&className=${className}&pageCode=${pageCode}`);
    }
    bindDeleteEffectiveTechDataRow() {
        let deleteEffectiveTechRowButtons = document.getElementsByClassName("deleteSafeSchoolsRow");
        for (let deleteEffectiveTechRowButton of deleteEffectiveTechRowButtons)
            deleteEffectiveTechRowButton.addEventListener("click", (e) => this.showDeleteEffectiveTechDataRow(e));
    }
    showDeleteEffectiveTechDataRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteEffectiveTechRowModal", null);
        modal.addAttributeToElement("deleteEffectiveTechRowModal", "#safeSchoolsDeleteConfirm", "row", row);
        modal.show();
    }
    cancelDataRowDelete() {
        let modal = new Modal("deleteEffectiveTechRowModal", null);
        modal.hide();
    }
    confirmDataRowDelete(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`#federalSSAEEffectiveTechData tbody tr[data-row='${row}'] .federalSSAESafeSchoolsField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#federalSSAEEffectiveTechData tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteEffectiveTechRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindDeleteEffectiveTechSummaryRow() {
        let deleteSafeSchoolsRowButtons = document.getElementsByClassName("deleteEffectiveTechStrengthChallengeRow");
        for (let deleteSafeSchoolsRowButton of deleteSafeSchoolsRowButtons)
            deleteSafeSchoolsRowButton.addEventListener("click", (e) => this.showDeleteEffectiveTechSummaryRow(e));
    }
    showDeleteEffectiveTechSummaryRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;
        let modal = new Modal("deleteEffectiveTechSummaryRowModal", null);
        modal.addAttributeToElement("deleteEffectiveTechSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteEffectiveTechSummaryRowModal", "#safeSchoolsSummaryDeleteConfirm", "propertycode", propertyCode);
        modal.show();
    }
    cancelEffectiveTechSummaryDelete() {
        let modal = new Modal("deleteEffectiveTechSummaryRowModal", null);
        modal.hide();
    }
    confirmEffectiveTechSummaryDelete(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let propertyCode = buttonElement.dataset.propertycode;
        let element = document.querySelector(`.federalSSAEEffectiveTechField[data-propertycode='${propertyCode}'][data-row='${row}']`);
        let planProp = element.dataset.planpropertypk;
        if (planProp && parseInt(planProp) > 0) {
            planProps.push(planProp);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/DeleteSafeSchoolsDataRow', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.federalSSAEEffectiveTechField[data-propertycode='${propertyCode}'][data-row='${row}']`);
                rowElement.textContent = "";
                rowElement.innerText = "";
                rowElement.value = "";
                rowElement.dataset.planpropertypk = "0";
                let modal = new Modal("deleteEffectiveTechSummaryRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}
// FederalSSAESummaryOfStrengths
class FederalSSAESummaryOfStrengths {
    constructor() {
        this._core = new Core();
        this.uncheckChallengeForConsiderationModal = new Modal("uncheckChallengeForConsiderationModal", null);
        this.uncheckStrengthForConsiderationModal = new Modal("uncheckStrengthForConsiderationModal", null);
        let that = this;
        let uncheckChallengeConfirmButton = document.getElementById("uncheckChallengeForConsiderationConfirm");
        if (uncheckChallengeConfirmButton !== null) {
            uncheckChallengeConfirmButton.addEventListener("click", function () {
                if ("challengecheckplanpropertypk" in uncheckChallengeConfirmButton.dataset && parseInt(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk) > 0) {
                    that.uncheckChallengeForConsideration(uncheckChallengeConfirmButton.dataset.challengecheckplanpropertypk);
                }
                else {
                    that.uncheckChallengeForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this challenge for consideration", "error", 3000, null);
                }
            });
        }
        const uncheckChallengeCancelButton = document.getElementById("uncheckChallengeForConsiderationCancel");
        if (uncheckChallengeCancelButton !== null) {
            uncheckChallengeCancelButton.addEventListener("click", function () {
                that.uncheckChallengeForConsiderationModal.hide();
                if (uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk) {
                    const checkbox = document.querySelector(`[data-planpropertypk='${uncheckChallengeCancelButton.dataset.challengecheckplanpropertypk}']`);
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }
        const uncheckStrengthConfirmButton = document.getElementById("uncheckStrengthForConsiderationConfirm");
        if (uncheckStrengthConfirmButton !== null) {
            uncheckStrengthConfirmButton.addEventListener("click", function () {
                if ("strengthcheckplanpropertypk" in uncheckStrengthConfirmButton.dataset && parseInt(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk) > 0) {
                    that.uncheckStrengthForConsideration(uncheckStrengthConfirmButton.dataset.strengthcheckplanpropertypk);
                }
                else {
                    that.uncheckStrengthForConsiderationModal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking this strength for consideration", "error", 3000, null);
                }
            });
        }
        const uncheckStrengthCancelButton = document.getElementById("uncheckStrengthForConsiderationCancel");
        if (uncheckStrengthCancelButton !== null) {
            uncheckStrengthCancelButton.addEventListener("click", function () {
                that.uncheckStrengthForConsiderationModal.hide();
                if (uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk) {
                    const checkbox = document.querySelector(`[data-planpropertypk='${uncheckStrengthCancelButton.dataset.strengthcheckplanpropertypk}']`);
                    if (checkbox != null) {
                        checkbox.checked = true;
                    }
                }
            });
        }
        this.validationClasses = ["federalSSAESummaryOfStrengthsCheckboxField", "federalSSAESummaryOfChallengesCheckboxField"];
        let federalSSAEAnalyzingSaveButton = document.getElementById("federalSSAESummaryOfStrengthsSave");
        if (federalSSAEAnalyzingSaveButton !== null)
            federalSSAEAnalyzingSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const checkboxes = document.getElementsByClassName("checkboxField");
        for (let checkbox of checkboxes)
            checkbox.addEventListener("click", (e) => this.checkForReadonly(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let allStrengthElements = [];
        let allChallengeElements = [];
        let observationElement;
        let planElement = document.getElementById("federalSSAESummaryOfStrengthsForm");
        let planFK = planElement.dataset.planfk;
        let allElements = document.getElementsByClassName("federalSSAESummaryOfStrengthsCheckboxField");
        for (let element of allElements) {
            let htmlElement = element;
            let primaryPlanPropertyPK = 0;
            let secondaryPlanPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                secondaryPlanPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.dataset.primaryplanpropertypk && htmlElement.dataset.primaryplanpropertypk !== "0") {
                primaryPlanPropertyPK = parseInt(htmlElement.dataset.primaryplanpropertypk);
            }
            let val = "off";
            if (htmlElement.checked) {
                val = "on";
            }
            if (!(secondaryPlanPropertyPK === 0 && val === "off")) { //If the planpropertypk = 0 AND it's not checked, we don't need to save it.
                let saveItem = {
                    PrimaryPlanPropertyPK: primaryPlanPropertyPK,
                    SecondaryPlanPropertyPK: secondaryPlanPropertyPK,
                    PlanFK: parseInt(planFK),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                };
                allStrengthElements.push(saveItem);
            }
        }
        allElements = document.getElementsByClassName("federalSSAESummaryOfChallengesCheckboxField");
        for (let element of allElements) {
            let htmlElement = element;
            let primaryPlanPropertyPK = 0;
            let secondaryPlanPropertyPK = 0;
            let rowNumber = parseInt(htmlElement.dataset.row);
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                secondaryPlanPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            if (htmlElement.dataset.primaryplanpropertypk && htmlElement.dataset.primaryplanpropertypk !== "0") {
                primaryPlanPropertyPK = parseInt(htmlElement.dataset.primaryplanpropertypk);
            }
            let val = "off";
            if (htmlElement.checked) {
                val = "on";
            }
            if (!(secondaryPlanPropertyPK === 0 && val === "off")) { //If the planpropertypk = 0 AND it's not checked, we don't need to save it.
                let saveItem = {
                    PrimaryPlanPropertyPK: primaryPlanPropertyPK,
                    SecondaryPlanPropertyPK: secondaryPlanPropertyPK,
                    PlanFK: parseInt(planFK),
                    PropertyFK: parseInt(htmlElement.dataset.propertypk),
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: rowNumber,
                    IsDeletedInd: false,
                };
                allChallengeElements.push(saveItem);
            }
        }
        let observationsElements = document.getElementsByClassName("federalSSAESummaryOfStrengthsObservationsField");
        for (let element of observationsElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let rowNumber = 0;
            if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            }
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: parseInt(planFK),
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                TextValue: htmlElement.value,
                LookupCodeFK: null,
                RowNbr: rowNumber,
                IsDeletedInd: false
            };
            observationElement = saveItem;
        }
        let allSaveElements = {
            "Strengths": allStrengthElements,
            "Challenges": allChallengeElements,
            "Observations": observationElement,
            "PlanFK": planFK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAESummaryOfStrengths', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, parseInt(planFK));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        };
        if (allChallengeElements.length === 0 && allStrengthElements.length === 0 && allSaveElements.Observations.TextValue === "") {
            if (referrer === "save") {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value to save first", 'error', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    customDoValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        //Check to see if at least two challenges have been checked for consideration
        let challengeCheckboxes = document.getElementsByClassName("federalSSAESummaryOfChallengesCheckboxField");
        let challengeCheckedCount = 0;
        for (let check of challengeCheckboxes) {
            if (check.checked) {
                challengeCheckedCount++;
            }
        }
        if (challengeCheckedCount < 2) {
            totalErrors++;
            showMessage = true;
        }
        //Check to see if at least one strength has been checked for consideration
        let strengthCheckboxes = document.getElementsByClassName("federalSSAESummaryOfStrengthsCheckboxField");
        let strengthCheckedCount = 0;
        for (let check of strengthCheckboxes) {
            if (check.checked) {
                strengthCheckedCount++;
            }
        }
        if (strengthCheckedCount === 0) {
            totalErrors++;
            showMessage = true;
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    customInitializeRequiredFields(allClasses, refresh = false, allowDuplicates = false) {
        this._core.initializeRequiredFields(allClasses);
    }
    uncheckChallengeForConsideration(planPropertyPK) {
        let that = this;
        that.uncheckChallengeForConsiderationModal.hide();
        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`);
        if (checkbox != null && "primaryplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/UncheckChallengeForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Challenge successfully unchecked for consideration", 'success', 3000, null);
                    //Uncheck checkbox and reset values
                    checkbox.checked = false;
                    checkbox.dataset.initiallychecked = "false";
                    checkbox.dataset.planpropertypk = "0";
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.primaryplanpropertypk);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking challenge for consideration. Please try again later.", "error", 3000, null);
        }
    }
    uncheckStrengthForConsideration(planPropertyPK) {
        let that = this;
        that.uncheckStrengthForConsiderationModal.hide();
        let checkbox = document.querySelector(`[data-planpropertypk='${planPropertyPK}']`);
        if (checkbox != null && "primaryplanpropertypk" in checkbox.dataset) {
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/UncheckStrengthForConsideration', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Strength successfully unchecked for consideration", 'success', 3000, null);
                    //Uncheck checkbox and reset values
                    if (checkbox != null) {
                        checkbox.checked = false;
                        checkbox.dataset.initiallychecked = "false";
                        checkbox.dataset.planpropertypk = "0";
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(checkbox.dataset.primaryplanpropertypk);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error unchecking strength for consideration. Please try again later.", "error", 3000, null);
        }
    }
    checkForReadonly(e) {
        let checkbox = e.target;
        if (checkbox.readOnly) {
            if (checkbox.checked)
                checkbox.checked = false;
            else
                checkbox.checked = true;
            return false;
        }
        else {
            if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "federalSSAESummaryOfStrengthStrengthCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckStrengthForConsiderationModal.show();
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationConfirm", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckStrengthForConsiderationModal.addAttributeToElement(this.uncheckStrengthForConsiderationModal.id, "#uncheckStrengthForConsiderationCancel", "strengthcheckplanpropertypk", checkbox.dataset.planpropertypk);
                    if (checkbox.id) {
                        this.uncheckStrengthForConsiderationModal.callingId = checkbox.id;
                    }
                }
            }
            else if ("propertycode" in checkbox.dataset && checkbox.dataset.propertycode == "federalSSAESummaryOfStrengthChallengeCheckForConsideration") {
                //if has a plan property and has been unchecked, show warning that things down the line will be affected
                if (checkbox.dataset.planpropertypk && parseInt(checkbox.dataset.planpropertypk) > 0 && !checkbox.checked && "initiallychecked" in checkbox.dataset && checkbox.dataset.initiallychecked == "true") {
                    e.preventDefault();
                    this.uncheckChallengeForConsiderationModal.show();
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationConfirm", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);
                    this.uncheckChallengeForConsiderationModal.addAttributeToElement(this.uncheckChallengeForConsiderationModal.id, "#uncheckChallengeForConsiderationCancel", "challengecheckplanpropertypk", checkbox.dataset.planpropertypk);
                    if (checkbox.id) {
                        this.uncheckChallengeForConsiderationModal.callingId = checkbox.id;
                    }
                }
            }
        }
    }
}
// FederalSSAEAnalyzing
class FederalSSAEAnalyzing {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAEAnalyzingField", "federalSSAEAnalyzingCheckboxField"];
        let federalSSAEAnalyzingSaveButton = document.getElementById("federalSSAEAnalyzingSave");
        if (federalSSAEAnalyzingSaveButton !== null)
            federalSSAEAnalyzingSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes = document.getElementsByClassName("federalSSAEAnalyzingChallengesPriorityChallenge");
        for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes)
            federalSSAEAnalyzingChallengesPriorityChallengeCheckbox.addEventListener("change", (e) => this.checkPriorityChallenge(e));
        let deletePriorityRowCancelButton = document.getElementById("deleteAnalyzingPriorityCancel");
        if (deletePriorityRowCancelButton !== null)
            deletePriorityRowCancelButton.addEventListener("click", (e) => this.priorityChallengeDeleteCancel());
        let deletePriorityRowConfirmButton = document.getElementById("deleteAnalyzingPriorityConfirm");
        if (deletePriorityRowConfirmButton !== null)
            deletePriorityRowConfirmButton.addEventListener("click", (e) => this.priorityChallengeDeleteConfirm(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allStrengthElements = [];
        const allChallengeElements = [];
        const allChallengeCheckElements = [];
        const allPriorityChallengeElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("federalSSAEAnalyzingForm");
        const planFK = parseInt(formElement.dataset.planfk);
        //Get all the textboxes for challenges
        const challengeElements = document.querySelectorAll(".federalSSAEAnalyzingField[data-descriptor='challenge']");
        for (let element of challengeElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            let thisData = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: htmlElement.value,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
            allChallengeElements.push(thisData);
        }
        //Get all the checkboxes for challenges
        const challengeCheckElements = document.querySelectorAll(".federalSSAEAnalyzingCheckboxField[data-descriptor='challenge']");
        for (let element of challengeCheckElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            let checkValue = "";
            if (htmlElement.checked)
                checkValue = "on";
            let thisData = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: checkValue,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
            allChallengeCheckElements.push(thisData);
        }
        //Get all the textboxes for Strengths
        const strengthElements = document.querySelectorAll(".federalSSAEAnalyzingField[data-descriptor='strength']");
        for (let element of strengthElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            let thisData = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: htmlElement.value,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK
            };
            allStrengthElements.push(thisData);
        }
        //Get all the textboxes for Priority Statements
        const priorityElements = document.querySelectorAll(".federalSSAEAnalyzingField[data-descriptor='priority']");
        for (let element of priorityElements) {
            let htmlElement = element;
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let challengePointPlanPropertyPK = 0;
            if (htmlElement.dataset.planpropertypk !== "")
                planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
            if (htmlElement.dataset.datapointplanpropertypk !== "")
                dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
            if (htmlElement.dataset.challengepointplanpropertypk !== "")
                challengePointPlanPropertyPK = parseInt(htmlElement.dataset.challengepointplanpropertypk);
            let thisData = {
                RowNbr: parseInt(htmlElement.dataset.row),
                TextValue: htmlElement.value,
                PropertyFK: parseInt(htmlElement.dataset.propertypk),
                PlanPropertyPK: planPropertyPK,
                DataPointPropertyPK: parseInt(htmlElement.dataset.datapointpropertypk),
                DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                PlanFK: planFK,
                ChallengePointPlanPropertyPK: challengePointPlanPropertyPK
            };
            allPriorityChallengeElements.push(thisData);
        }
        const allData = {
            "Strengths": allStrengthElements,
            "Challenges": allChallengeElements,
            "ChallengeChecks": allChallengeCheckElements,
            "PriorityChallenges": allPriorityChallengeElements,
            "PlanFK": planFK
        };
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEAnalyzing', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allData));
    }
    checkPriorityChallenge(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const formElement = document.getElementById("federalSSAEAnalyzingForm");
            const planFK = formElement.dataset.planfk;
            const checkbox = e.target;
            if (checkbox.disabled) {
                return false;
            }
            else {
                const challengePointPlanPropertyPK = checkbox.dataset.datapointplanpropertypk;
                const challengePointElement = document.querySelector(`.federalSSAEAnalyzingField[data-planpropertypk='${challengePointPlanPropertyPK}']`);
                const planPropertyPK = checkbox.dataset.planpropertypk;
                if (checkbox.checked) {
                    const dataModel = {
                        "ChallengePointPlanPropertyPK": challengePointPlanPropertyPK,
                        "PlanFK": planFK
                    };
                    const settings = {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(dataModel)
                    };
                    const response = yield fetch(`/FederalProgramsSSAE/PriorityChallengesRow`, settings);
                    if (response.ok) {
                        const output = yield response.text();
                        const tableElement = document.querySelector("#priorityChallenges tbody");
                        const trEle = document.createElement("tr");
                        trEle.innerHTML = output;
                        tableElement.appendChild(trEle);
                        Core.createHTMLAlert("alertMessageDiv", "New Priority Challenge row added!", "success", 3000, null);
                    }
                    this.checkLimits();
                }
                else {
                    let modal = new Modal("deleteAnalyzingPriorityModal", null);
                    modal.addAttributeToElement("deleteAnalyzingPriorityModal", "#deleteAnalyzingPriorityConfirm", "challengepointplanpropertypk", challengePointPlanPropertyPK);
                    modal.addAttributeToElement("deleteAnalyzingPriorityModal", "#deleteAnalyzingPriorityConfirm", "checkboxplanpropertypk", planPropertyPK);
                    modal.show();
                }
            }
        });
    }
    priorityChallengeDeleteConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const challengePointPlanPropertyPK = button.dataset.challengepointplanpropertypk;
            const checkboxPlanPropertyPK = button.dataset.checkboxplanpropertypk;
            //Check to see if the corresponding element is saved, if it is, throw the modal up for a delete, because it will have to delete the data.
            const priorityChallengeElement = document.querySelector(`.federalSSAEAnalyzingPriorityChallengeField[data-prioritychallengeselected='true']`);
            const priorityChallengeDiscussionElement = document.querySelector(`.federalSSAEAnalyzingPriorityChallengeField[data-prioritychallengediscussion='true'][data-challengepointplanpropertypk='${challengePointPlanPropertyPK}']`);
            let removeIt = true;
            const priorityChallengePlanPropertyPK = priorityChallengeDiscussionElement.dataset.planpropertypk;
            if (priorityChallengePlanPropertyPK !== "0") {
                const response = yield fetch(`/FederalProgramsSSAE/DeletePriorityChallenge/${priorityChallengePlanPropertyPK}/${checkboxPlanPropertyPK}`, { credentials: 'include' });
                if (response.ok) {
                    const success = yield response.text();
                    if (success === "true")
                        removeIt = true;
                    else if (success === "false")
                        removeIt = false;
                }
                else {
                    removeIt = false;
                }
                let modal = new Modal("deleteAnalyzingPriorityModal", null);
                modal.hide();
            }
            if (removeIt) {
                priorityChallengeDiscussionElement.parentElement.parentElement.remove();
                Core.createHTMLAlert("alertMessageDiv", "The priority challenge has been removed.", "success", 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing this record. Please try again.", "success", 3000, null);
            }
        });
    }
    priorityChallengeDeleteCancel() {
        let modal = new Modal("deleteAnalyzingPriorityModal", null);
        modal.hide();
    }
    checkLimits() {
        const federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes = document.getElementsByClassName("federalSSAEAnalyzingChallengesPriorityChallenge");
        let checkedCount = 0;
        const maxChecked = 4;
        for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes) {
            const ele = federalSSAEAnalyzingChallengesPriorityChallengeCheckbox;
            if (ele.checked)
                checkedCount++;
        }
        if (checkedCount == maxChecked) {
            for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes) {
                const ele = federalSSAEAnalyzingChallengesPriorityChallengeCheckbox;
                if (!ele.checked)
                    ele.disabled = true;
            }
        }
        else {
            for (const federalSSAEAnalyzingChallengesPriorityChallengeCheckbox of federalSSAEAnalyzingChallengesPriorityChallengeCheckboxes) {
                const ele = federalSSAEAnalyzingChallengesPriorityChallengeCheckbox;
                ele.disabled = false;
            }
        }
    }
}
// FederalSSAEGoalSetting
class FederalSSAEGoalSetting {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAEGoalSettingField", "federalSSAEGoalSettingSelectField"];
        let federalSSAEGoalSettingSaveButton = document.getElementById("federalSSAEGoalSettingSave");
        if (federalSSAEGoalSettingSaveButton !== null)
            federalSSAEGoalSettingSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const measurableGoalInputs = document.getElementsByClassName("measurableGoalStatement");
        for (const measurableGoalInput of measurableGoalInputs)
            measurableGoalInput.addEventListener("input", (e) => this.changeTargetYear4(e));
        const deleteGoals = document.getElementsByClassName("deleteGoal");
        for (const deleteGoal of deleteGoals)
            deleteGoal.addEventListener("click", (e) => this.showDelete(e));
        const federalSSAEGoalSettingDeleteCancel = document.getElementById("federalSSAEGoalSettingDeleteCancel");
        if (federalSSAEGoalSettingDeleteCancel !== null)
            federalSSAEGoalSettingDeleteCancel.addEventListener("click", (e) => this.cancelDelete());
        const federalSSAEGoalSettingDeleteConfirm = document.getElementById("federalSSAEGoalSettingDeleteConfirm");
        if (federalSSAEGoalSettingDeleteConfirm !== null)
            federalSSAEGoalSettingDeleteConfirm.addEventListener("click", (e) => this.confirmDelete(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEGoalSettingForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const allSmartGoals = [];
        const smartGoals = document.querySelectorAll(".federalSSAEGoalSettingField[data-smartgoal='true']");
        for (const smart of smartGoals) {
            const element = smart;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            const propertyRelationTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let planPropertyPK = 0;
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    PropertyRelationTypePK: propertyRelationTypePK,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK
                };
                allSmartGoals.push(saveItem);
            }
        }
        for (const smartGoal of allSmartGoals) {
            const row = smartGoal.RowNbr;
            const dataPointPlanPropertyPK = smartGoal.DataPointPlanPropertyPK;
            const allOtherFields = [];
            const allTheseInputElements = document.querySelectorAll(`.federalSSAEGoalSettingField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of allTheseInputElements) {
                const thisEle = ele;
                // We don't want the smart goal to be added to this list, since we're pulling it separately.
                if (!(thisEle.dataset.smartgoal && thisEle.dataset.smartgoal === "true")) {
                    const rowNumber = thisEle.dataset.row;
                    const propertyPK = parseInt(thisEle.dataset.propertypk);
                    const dataPointPlanPropertyPK = parseInt(thisEle.dataset.datapointplanpropertypk);
                    const propertyRelationTypePK = parseInt(thisEle.dataset.propertyrelationtypepk);
                    let planPropertyPK = 0;
                    let hadValue = false;
                    if (thisEle.dataset.planpropertypk !== "" && thisEle.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(thisEle.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (thisEle.value !== "" || hadValue) {
                        const saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: thisEle.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            PropertyRelationTypePK: propertyRelationTypePK,
                            DataPointPlanPropertyPK: dataPointPlanPropertyPK
                        };
                        allOtherFields.push(saveItem);
                    }
                }
            }
            const allTheseSelectElements = document.querySelectorAll(`.federalSSAEGoalSettingSelectField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of allTheseSelectElements) {
                const thisEle = ele;
                const rowNumber = thisEle.dataset.row;
                const propertyPK = parseInt(thisEle.dataset.propertypk);
                const dataPointPlanPropertyPK = parseInt(thisEle.dataset.datapointplanpropertypk);
                const propertyRelationTypePK = parseInt(thisEle.dataset.propertyrelationtypepk);
                let planPropertyPK = 0;
                let lookupCodePK = 0;
                let hadValue = false;
                if (thisEle.dataset.planpropertypk !== "" && thisEle.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(thisEle.dataset.planpropertypk);
                    hadValue = true;
                }
                if (thisEle.value !== "" || hadValue) {
                    lookupCodePK = parseInt(thisEle.value);
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: '',
                        LookupCodeFK: lookupCodePK,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        PropertyRelationTypePK: propertyRelationTypePK,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK
                    };
                    allOtherFields.push(saveItem);
                }
            }
            const thisSet = {
                "SmartGoal": smartGoal,
                "OtherFields": allOtherFields,
            };
            allSaveElements.push(thisSet);
        }
        var allData = {
            "SaveData": allSaveElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEGoalSetting', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData.SaveData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    changeTargetYear4(e) {
        const measurableInput = e.target;
        const row = measurableInput.dataset.row;
        const dataPointPlanPropertyPK = measurableInput.dataset.datapointplanpropertypk;
        const targetYear4Input = document.querySelector(`.measurableGoalTargetStatement[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        targetYear4Input.value = measurableInput.value;
    }
    showDelete(e) {
        const button = e.target;
        const goalPlanPropertyPK = button.dataset.goalplanpropertypk;
        const container = button.dataset.container;
        const modal = new Modal("federalSSAEGoalSettingDelete", null);
        modal.addAttributeToElement("federalSSAEGoalSettingDelete", "#federalSSAEGoalSettingDeleteConfirm", "goalplanpropertypk", goalPlanPropertyPK);
        modal.addAttributeToElement("federalSSAEGoalSettingDelete", "#federalSSAEGoalSettingDeleteConfirm", "container", container);
        modal.show();
    }
    cancelDelete() {
        const modal = new Modal("federalSSAEGoalSettingDelete", null);
        modal.hide();
    }
    confirmDelete(e) {
        const button = e.target;
        const goalPlanPropertyPK = button.dataset.goalplanpropertypk;
        const container = button.dataset.container;
        this.deleteGoal(goalPlanPropertyPK);
    }
    deleteGoal(goalPlanPropertyPK) {
        let that = this;
        const modal = new Modal("federalSSAEGoalSettingDelete", null);
        modal.hide();
        let goal = document.querySelector(`[data-planpropertypk='${goalPlanPropertyPK}']`);
        if (goal != null && "row" in goal.dataset) {
            let container = document.getElementById(goal.dataset.container);
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/DeleteGoal', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Goal successfully deleted", 'success', 3000, null);
                    //reset fields
                    let goalFields = document.querySelectorAll(`.goalSettingField[data-row='${goal.dataset.row}'], .goalSettingLookupField[data-row='${goal.dataset.row}']`);
                    for (let field of goalFields) {
                        let htmlElement = field;
                        //Lookup
                        if (field instanceof HTMLSelectElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.selectedIndex = 0;
                        }
                        else if (field.classList.contains("goalSettingCheckbox")) {
                            //checkbox
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.checked = false;
                            that.omitQuarterlyTargets(htmlField);
                        }
                        else if (field instanceof HTMLInputElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }
                        else if (field instanceof HTMLTextAreaElement) {
                            let htmlField = field;
                            htmlField.dataset.planpropertypk = "0";
                            htmlField.value = "";
                        }
                        //remove delete button for the goal
                        let deleteButton = document.querySelector(`.deleteGoal[data-goalplanpropertypk='${goalPlanPropertyPK}']`);
                        if (deleteButton != null) {
                            deleteButton.parentNode.removeChild(deleteButton);
                        }
                    }
                    let firstField = goalFields[0];
                    firstField.focus();
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted goal", "success", 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
                }
                Core.hideLoader();
            };
            xhr.send(goalPlanPropertyPK);
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this goal. Please try again later.", "error", 3000, null);
        }
    }
    omitQuarterlyTargets(element) {
        let core = this._core;
        if (element.readOnly && !("omitset" in element.dataset)) {
            if (element.checked) {
                element.checked = false;
            }
            else {
                element.checked = true;
            }
            return false;
        }
        else {
            let quarterlyTargets = document.querySelectorAll(`.goalSettingField[data-quarterlytarget='true'][data-row='${element.dataset.row}'][data-buttonrow='${element.dataset.buttonrow}']`);
            for (let target of quarterlyTargets) {
                element.dataset.omitset = "true";
                if (element.checked) {
                    //Make optional
                    core.forceElementOptional(target);
                    target.readOnly = true;
                    target.value = '';
                }
                else {
                    //Make required (if first)
                    if (("index" in target.dataset) && target.dataset.index == "0") {
                        core.forceElementRequired(target);
                    }
                    else {
                        core.forceElementOptional(target);
                    }
                    target.readOnly = false;
                }
            }
        }
    }
}
// FederalSSAEActionPlan
class FederalSSAEActionPlan {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAEActionPlanField"];
        let federalSSAEActionPlanSaveButton = document.getElementById("federalSSAEActionPlanSave");
        if (federalSSAEActionPlanSaveButton !== null)
            federalSSAEActionPlanSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.bindCreateActionPlans();
        this.bindAddAnActionStep();
        const that = this;
        //use event delegation for up/down buttons that are possibly added dynamically
        document.addEventListener("click", function (event) {
            let target = event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("up")) {
                that.moveActionStep(event, true);
            }
        });
        document.addEventListener("click", function (event) {
            let target = event.target;
            if (target.tagName == "BUTTON" && target.classList.contains("up-down") && target.classList.contains("down")) {
                that.moveActionStep(event, false);
            }
        });
        const addEvidenceBasedStrategyButton = document.getElementById("addMoreEvidenceBasedStrategies");
        if (addEvidenceBasedStrategyButton !== null) {
            addEvidenceBasedStrategyButton.addEventListener("click", (e) => this.addEvidenceBasedStrategies("actionPlanEvidenceBasedStrategy"));
        }
        this.bindDeleteActionStep();
        this.bindDeleteActionPlan();
        const federalSSAEActionStepDeleteConfirm = document.getElementById("federalSSAEActionStepDeleteConfirm");
        if (federalSSAEActionStepDeleteConfirm !== null)
            federalSSAEActionStepDeleteConfirm.addEventListener("click", (e) => this.actionStepDeleteConfirm(e));
        const federalSSAEActionStepDeleteCancel = document.getElementById("federalSSAEActionStepDeleteCancel");
        if (federalSSAEActionStepDeleteCancel !== null)
            federalSSAEActionStepDeleteCancel.addEventListener("click", (e) => this.actionStepDeleteCancel());
        const federalSSAEActionPlanDeleteConfirm = document.getElementById("federalSSAEActionPlanDeleteConfirm");
        if (federalSSAEActionPlanDeleteConfirm !== null)
            federalSSAEActionPlanDeleteConfirm.addEventListener("click", (e) => this.actionPlanDeleteConfirm(e));
        const federalSSAEActionPlanDeleteCancel = document.getElementById("federalSSAEActionPlanDeleteCancel");
        if (federalSSAEActionPlanDeleteCancel !== null)
            federalSSAEActionPlanDeleteCancel.addEventListener("click", (e) => this.actionPlanDeleteCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEActionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let allActionPlans = [];
        let allActionSteps = [];
        let allPdSteps = [];
        let allComSteps = [];
        let allGoals = [];
        let allOthers = [];
        let actionStepOthers = [];
        let allElements = document.getElementsByClassName("actionPlanField");
        for (let element of allElements) {
            let planPropertyPK = 0;
            let dataPointPlanPropertyPK = 0;
            let buttonRow = 0;
            let htmlElement;
            let saveItem;
            if (element.classList.contains("actionPlanSelectedMeasurableGoals")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.datapointplanpropertypk && htmlElement.dataset.datapointplanpropertypk !== "0")
                    dataPointPlanPropertyPK = parseInt(htmlElement.dataset.datapointplanpropertypk);
                else
                    dataPointPlanPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.checked) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("actionPlanPDStepField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else if (element.classList.contains("actionPlanComStepField")) {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                if (htmlElement.value !== "") {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: "",
                        LookupCodeFK: parseInt(htmlElement.value),
                        RowNbr: rowNumber,
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: 0,
                        ButtonRow: buttonRow
                    };
                }
            }
            else {
                htmlElement = element;
                let rowNumber = parseInt(htmlElement.dataset.row);
                if ("overriderow" in htmlElement.dataset) {
                    rowNumber = parseInt(htmlElement.dataset.overriderow);
                }
                if (htmlElement.dataset.planpropertypk && htmlElement.dataset.planpropertypk !== "0")
                    planPropertyPK = parseInt(htmlElement.dataset.planpropertypk);
                else
                    planPropertyPK = 0;
                if (htmlElement.dataset.buttonrow && htmlElement.dataset.buttonrow !== "0")
                    buttonRow = parseInt(htmlElement.dataset.buttonrow);
                else
                    buttonRow = 0;
                let toDelete = (htmlElement.value === "");
                if (htmlElement.value !== "" || planPropertyPK > 0) {
                    saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planFK,
                        PropertyFK: parseInt(htmlElement.dataset.propertypk),
                        TextValue: htmlElement.value,
                        LookupCodeFK: null,
                        RowNbr: rowNumber,
                        IsDeletedInd: toDelete,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: buttonRow
                    };
                }
            }
            if (htmlElement.classList.contains("evidenceBasedStrategyName") && saveItem !== undefined)
                allActionPlans.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanActionStepField") && saveItem !== undefined)
                allActionSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanPDStepField") && saveItem !== undefined)
                allPdSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanComStepField") && saveItem !== undefined)
                allComSteps.push(saveItem);
            else if (htmlElement.classList.contains("actionPlanSelectedMeasurableGoals") && saveItem !== undefined)
                allGoals.push(saveItem);
            else if (htmlElement.classList.contains("actionStepOtherField") && saveItem !== undefined)
                actionStepOthers.push(saveItem);
            else if (saveItem !== undefined)
                allOthers.push(saveItem);
        }
        const nonPlanSaveElements = [];
        const nonPlanElements = document.getElementsByClassName("federalSSAEActionPlanNonActionPlanField");
        for (let ele of nonPlanElements) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planFK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                nonPlanSaveElements.push(saveItem);
            }
        }
        let saveElement = {
            "ActionPlans": allActionPlans,
            "ActionSteps": allActionSteps,
            "PDSteps": allPdSteps,
            "ComSteps": allComSteps,
            "GoalSteps": allGoals,
            "ActionStepOther": actionStepOthers,
            "Others": allOthers,
            "NonActionPlanSaveElements": nonPlanSaveElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEActionPlan', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planFK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveElement.ActionPlans.length === 0 && saveElement.ActionStepOther.length === 0 && saveElement.ActionSteps.length === 0 && saveElement.ComSteps.length === 0 && saveElement.GoalSteps.length === 0 && saveElement.Others.length === 0 && saveElement.PDSteps.length === 0) {
            if (referrer === "save") {
                core.pageReload(true, planFK);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveElement));
        }
    }
    bindCreateActionPlans() {
        const actionPlanButtons = document.getElementsByClassName("createActionPlan");
        for (const button of actionPlanButtons) {
            const but = button;
            but.addEventListener("click", (e) => this.addActionPlan(e.target));
        }
    }
    bindAddAnActionStep() {
        const addActionSteps = document.getElementsByClassName("addAnActionStep");
        for (const action of addActionSteps) {
            const addActionStep = action;
            const buttonRow = parseInt(addActionStep.dataset.buttonrow);
            addActionStep.addEventListener("click", (e) => this.addActionStep(buttonRow));
        }
    }
    addActionPlan(button) {
        let _that = this;
        let dontDoIt = false;
        let row = button.dataset.row;
        let textElement = document.getElementsByClassName("evidenceBasedStrategyName");
        let hasName = true;
        for (let tElement of textElement) {
            let properElement = tElement;
            if (properElement.dataset.row === row) {
                if (properElement.value === "") {
                    dontDoIt = true;
                    hasName = false;
                }
            }
        }
        let selectedSequence = this.itemsSelected(row);
        if (selectedSequence.length === 0) {
            dontDoIt = true;
        }
        if (!dontDoIt) {
            let rowNumber = button.dataset.row;
            let textBoxId = "actionPlanEvidenceBasedStrategyName" + rowNumber.toString();
            let evidenceNameElement = document.getElementById(textBoxId);
            let newAccordionTitle = "Action Plan for: " + evidenceNameElement.value;
            let accordionParent = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var thisElement;
            for (let accordion of accordionParent) {
                let acc = accordion;
                if (acc.dataset.row == rowNumber) {
                    thisElement = acc;
                }
            }
            let theSpan = thisElement.querySelector("span.Accordion-title");
            theSpan.textContent = newAccordionTitle;
            evidenceNameElement.readOnly = true;
            //Now show the accordion.
            let actionPlanElements = document.getElementsByClassName("actionPlanActionPlanAccordion");
            var actionPlanElement;
            for (let ap of actionPlanElements) {
                let ape = ap;
                if (ape.dataset.row === rowNumber) {
                    actionPlanElement = ape;
                }
            }
            //Get the measurable goals list to display inside the accordion
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/MeasurableGoalsList', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let element = document.getElementById("actionPlanActionPlanSection" + rowNumber);
                    let divElement = element;
                    let measurableGoals = divElement.querySelector(".actionPlanMeasurableGoalsReaderList");
                    let measurableGoalsElement = measurableGoals;
                    $(measurableGoalsElement).append(xhr.responseText);
                    let allMeasurableGoals = divElement.querySelectorAll(".measurableGoalModal");
                    for (let goal of allMeasurableGoals) {
                        let goalElement = goal;
                        let modalValue = goalElement.dataset.measurablegoal;
                        goal.addEventListener("click", (e) => _that.showGoalModal(modalValue));
                    }
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New Action Plan Row Added", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(selectedSequence));
            actionPlanElement.classList.remove("hide-action-plan");
            actionPlanElement.classList.add("open");
            button.classList.add("hide-action-plan");
            _that.recalculateUpDown();
        }
        else {
            if (!hasName) {
                Core.createHTMLAlert("alertMessageDiv", "A name for the evidence-based strategy must be provided", 'error', 3000, null);
            }
            else if (selectedSequence.length === 0) {
                Core.createHTMLAlert("alertMessageDiv", "At least one measurable goal must be selected", 'error', 3000, null);
            }
        }
    }
    addActionStep(buttonRow) {
        let that = this;
        let newRow = 0;
        let formElement = document.getElementById("federalSSAEActionPlanForm");
        let planFK = parseInt(formElement.dataset.planfk);
        let actionStepFields = document.getElementsByClassName("actionPlanActionStepField");
        for (let action of actionStepFields) {
            let actionElement = action;
            if (buttonRow === parseInt(actionElement.dataset.buttonrow)) {
                if (newRow < parseInt(actionElement.dataset.row)) {
                    newRow = parseInt(actionElement.dataset.row);
                }
            }
        }
        newRow++;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/AddActionStep', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                $(".actionStepContainer[data-buttonrow='" + buttonRow + "']").append(xhr.responseText);
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "New Action Step Added", 'success', 3000, null);
                that.recalculateUpDown();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send("planFK=" + planFK + "&newRow=" + newRow + "&buttonRow=" + buttonRow);
    }
    showGoalModal(modalText) {
        let element = document.getElementById("actionPlanMeasurableGoalsDescription");
        element.innerHTML = modalText;
        let modal = new Modal('ActionPlanMeasurableGoalDescriptionModal', null);
        modal.show();
    }
    itemsSelected(row) {
        let selectElements = document.getElementsByClassName("multiSelectMeasurableGoals");
        let elementSequence = [];
        for (let selectElement of selectElements) {
            let element = selectElement;
            if (element.dataset.row === row) {
                let selection = element.querySelectorAll("ul li.selected");
                if (selection.length > 0) {
                    for (let s of selection) {
                        let sElement = s;
                        let nextElement = sElement.querySelector("input");
                        let inputElement = nextElement;
                        elementSequence.push(inputElement.dataset.planpropertypk);
                    }
                }
            }
        }
        return elementSequence;
    }
    recalculateUpDown() {
        let actionStepContainers = document.getElementsByClassName("actionStepContainer");
        for (let container of actionStepContainers) {
            let actionSteps = container.querySelectorAll(".actionStepsRow");
            if (actionSteps != null) {
                let i = 1;
                for (let actionStep of actionSteps) {
                    let upArrow = actionStep.querySelector("button.up");
                    let downArrow = actionStep.querySelector("button.down");
                    if (upArrow != null && downArrow != null && upArrow.dataset.canedit == "true" && downArrow.dataset.canedit == "true") {
                        //Reset the state up the up and down arrows
                        upArrow.classList.remove("hide");
                        upArrow.disabled = false;
                        downArrow.classList.remove("hide");
                        downArrow.disabled = false;
                        if (actionSteps.length == 1) {
                            //Only one action step so hide the up/down arrows
                            upArrow.classList.add("hide");
                            downArrow.classList.add("hide");
                        }
                        else {
                            if (i === 1) {
                                upArrow.disabled = true;
                            }
                            if (i === actionSteps.length) {
                                downArrow.disabled = true;
                            }
                        }
                    }
                    i++;
                }
            }
        }
    }
    moveActionStep(e, up) {
        let that = this;
        e.preventDefault();
        let button = e.target;
        if (button != null) {
            let actionStepContainer = Core.findClosest(button, ".action-steps-row");
            if (actionStepContainer != null) {
                let sibling;
                if (up) {
                    sibling = actionStepContainer.previousElementSibling;
                    actionStepContainer.parentNode.insertBefore(actionStepContainer, sibling);
                }
                else {
                    sibling = actionStepContainer.nextElementSibling;
                    actionStepContainer.parentNode.insertBefore(sibling, actionStepContainer);
                }
                let siblingElement = sibling;
                let thisRow = actionStepContainer.dataset.overriderow;
                let siblingRow = siblingElement.dataset.overriderow;
                //swap override row number for the two
                actionStepContainer.dataset.overriderow = siblingRow;
                let thisActionStepFields = actionStepContainer.querySelectorAll(`[data-overriderow='${thisRow}']`);
                for (let field of thisActionStepFields) {
                    let htmlField = field;
                    htmlField.dataset.overriderow = siblingRow;
                }
                siblingElement.dataset.overriderow = thisRow;
                let siblingActionStepFields = siblingElement.querySelectorAll(`[data-overriderow='${siblingRow}']`);
                for (let field of siblingActionStepFields) {
                    let htmlField = field;
                    htmlField.dataset.overriderow = thisRow;
                }
                this.recalculateUpDown();
                if (button.disabled) {
                    if (up) {
                        let downButton = actionStepContainer.querySelector("button.up-down.down");
                        if (downButton != null && !downButton.disabled) {
                            downButton.focus();
                        }
                    }
                    else {
                        let upButton = actionStepContainer.querySelector("button.up-down.up");
                        if (upButton != null && !upButton.disabled) {
                            upButton.focus();
                        }
                    }
                }
                else {
                    button.focus();
                }
                Core.createHTMLAlert("alertMessageDiv", "Action Step Has Been Reordered", "success", 3000, null);
            }
        }
        this._core.initializeRequiredFields(this.validationClasses, true);
    }
    addEvidenceBasedStrategies(classNameBase) {
        let core = this._core;
        let planFKElement = document.getElementById("federalSSAEActionPlanForm");
        let planFK = parseInt(planFKElement.dataset.planfk);
        let _that = this;
        let value = 1;
        let table = document.getElementById("evidenceBasedStrategy");
        let findRows = document.getElementsByClassName("addAnActionStep");
        let rowCount = 0;
        for (let row of findRows) {
            let element = row;
            if (rowCount < parseInt(element.dataset.buttonrow)) {
                rowCount = parseInt(element.dataset.buttonrow);
            }
        }
        for (let i = 1; i <= value; i++) {
            rowCount++;
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/EvidenceBasedStrategy');
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    $("#evidenceBasedStrategy > tbody").append("<tr><td>" + xhr.responseText + "</td></tr>");
                    $('.multiSelectMeasurableGoals').MultiSelect();
                    _that.bindCreateActionPlans();
                    _that.bindAddAnActionStep();
                    new CustomAccordion("actionPlanActionPlanAccordion" + rowCount.toString());
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "New Evidence-Based Strategy Row Added", 'success', 3000, null);
                    _that.recalculateUpDown();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send("classNameBase=" + classNameBase + "&rowNumber=" + rowCount + "&planFK=" + planFK);
        }
    }
    bindDeleteActionStep() {
        const deleteActionSteps = document.getElementsByClassName("deleteActionStep");
        for (const deleteActionStep of deleteActionSteps)
            deleteActionStep.addEventListener("click", (e) => this.showDeleteActionStep(e));
    }
    showDeleteActionStep(e) {
        const button = e.target;
        const actionStepPlanPropertyPK = button.dataset.actionstepplanpropertypk;
        const container = button.dataset.container;
        const modal = new Modal("deleteActionStepModal", null);
        modal.addAttributeToElement("deleteActionStepModal", "#federalSSAEActionStepDeleteConfirm", "actionstepplanpropertypk", actionStepPlanPropertyPK);
        modal.addAttributeToElement("deleteActionStepModal", "#federalSSAEActionStepDeleteConfirm", "container", container);
        modal.show();
    }
    actionStepDeleteConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const actionStepPlanPropertyPK = button.dataset.actionstepplanpropertypk;
            const container = button.dataset.container;
            const modal = new Modal("deleteActionStepModal", null);
            const response = yield fetch(`/FederalProgramsSSAE/DeleteActionStep/${actionStepPlanPropertyPK}`, { credentials: 'include' });
            if (response.ok) {
                const nextFocusable = Core.getNextFocusableElement(button);
                const containerObj = document.getElementById(container);
                containerObj.remove();
                this.recalculateUpDown();
                this._core.initializeRequiredFields(this.validationClasses, true);
                nextFocusable.focus();
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The Action Step was removed successfully.", 'success', 3000, null);
            }
            else {
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "Request failed. There was an error deleting this record. Please try again.", 'error', 3000, null);
            }
        });
    }
    actionStepDeleteCancel() {
        const modal = new Modal("deleteActionStepModal", null);
        modal.hide();
    }
    bindDeleteActionPlan() {
        const deleteActionPlans = document.getElementsByClassName("deleteActionPlan");
        for (const deleteActionPlan of deleteActionPlans)
            deleteActionPlan.addEventListener("click", (e) => this.showDeleteActionPlan(e));
    }
    showDeleteActionPlan(e) {
        const button = e.target;
        const actionPlanPlanPropertyPK = button.dataset.actionplanplanpropertypk;
        const modal = new Modal("deleteActionPlanModal", null);
        modal.addAttributeToElement("deleteActionPlanModal", "#federalSSAEActionPlanDeleteConfirm", "actionplanplanpropertypk", actionPlanPlanPropertyPK);
        modal.show();
    }
    actionPlanDeleteCancel() {
        const modal = new Modal("deleteActionPlanModal", null);
        modal.hide();
    }
    actionPlanDeleteConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const actionPlanPlanPropertyPK = button.dataset.actionplanplanpropertypk;
            const modal = new Modal("deleteActionPlanModal", null);
            let response = yield fetch(`/FederalProgramsSSAE/DeleteActionPlan/${actionPlanPlanPropertyPK}`, { credentials: 'include' });
            if (response.ok) {
                //Get next focusable evidence based strategy name
                let strategyNames = document.querySelectorAll(".evidenceBasedStrategyName");
                if (strategyNames.length > 1) {
                    let arr = Array.prototype.slice.call(strategyNames);
                    let thisStrategyName = document.querySelector(`.evidenceBasedStrategyName[data-planpropertypk='${actionPlanPlanPropertyPK}']`);
                    if (thisStrategyName != null) {
                        let index = arr.indexOf(thisStrategyName);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                }
                else {
                    //Focus on add button if no strategies left
                    let addButton = document.getElementById("addMoreEvidenceBasedStrategies");
                    if (addButton != null) {
                        addButton.focus();
                    }
                }
                //remove from DOM
                let containers = document.querySelectorAll(`[data-containeractionplanplanpropertypk='${actionPlanPlanPropertyPK}'`);
                for (let container of containers) {
                    container.parentNode.removeChild(container);
                }
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The Action Plan was removed successfully.", 'success', 3000, null);
            }
            else {
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the Action Plan, please try again.", 'error', 3000, null);
            }
        });
    }
}
// FederalSSAESignaturesAndAssurances
class FederalSSAESignaturesAndAssurances {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAESignaturesAndAssurancesField"];
        let federalSSAESignaturesAndAssurancesSaveButton = document.getElementById("federalSSAESignaturesAndAssurancesSave");
        if (federalSSAESignaturesAndAssurancesSaveButton !== null)
            federalSSAESignaturesAndAssurancesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAESignaturesAndAssurancesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSSAESignaturesAndAssurancesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAESignaturesAndAssurances', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// FederalSSAESummaryChecklist
class FederalSSAESummaryChecklist {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/FederalProgramsSSAE/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
// FederalSSAEPerformance
class FederalSSAEPerformance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["federalSSAEPerformanceField"];
        let federalSSAEPerformanceSaveButton = document.getElementById("federalSSAEPerformanceSave");
        if (federalSSAEPerformanceSaveButton !== null)
            federalSSAEPerformanceSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("federalSSAEPerformanceForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("federalSSAEPerformanceField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/FederalProgramsSSAE/SaveFederalSSAEPerformance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// Act35Profile
class Act35Profile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["act35SurveyField"];
        let act35ProfileSaveButton = document.getElementById("act35ProfileSave");
        if (act35ProfileSaveButton !== null)
            act35ProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        let act35ProfileExecutiveSaveButton = document.getElementById("ExecutiveSave");
        if (act35ProfileExecutiveSaveButton !== null)
            act35ProfileExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("act35ProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35ProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("act35SurveyField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35ProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Profile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Act35Survey
class Act35Survey {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["act35SurveyField", "act35SurveyRadioField"];
        let act35SurveySaveButton = document.getElementById("act35SurveySave");
        if (act35SurveySaveButton !== null)
            act35SurveySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this.calculateValues();
        let showMessage = this.initializeRequiredFieldsCustom();
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses, showMessage);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let graduationReportAct35SurveyAddACourseButton = document.getElementById("reportAct35SurveyAddACourse");
        if (graduationReportAct35SurveyAddACourseButton !== null)
            graduationReportAct35SurveyAddACourseButton.addEventListener("click", (e) => this.addAct35Course(e));
        this.bindDeleteButtons();
        let deleteAct35SurveyCancelButton = document.getElementById("deleteAct35SurveyCancel");
        if (deleteAct35SurveyCancelButton !== null)
            deleteAct35SurveyCancelButton.addEventListener("click", (e) => this.deleteAct35SurveyCancel());
        let deleteAct35SurveyConfirmButton = document.getElementById("deleteAct35SurveyConfirm");
        if (deleteAct35SurveyConfirmButton !== null)
            deleteAct35SurveyConfirmButton.addEventListener("click", (e) => this.deleteAct35SurveyConfirm(e));
        this.bindCalculations();
        const additionalCommentsRadio = document.getElementsByClassName("act35SurveyRadioField");
        for (const ele of additionalCommentsRadio) {
            ele.addEventListener("change", (e) => this.changeAdditionalComments());
        }
        this.changeAdditionalComments();
    }
    bindDeleteButtons() {
        let deleteAct35SurveyButtons = document.getElementsByClassName("deleteAct35Survey");
        for (let deleteAct35SurveyButton of deleteAct35SurveyButtons)
            deleteAct35SurveyButton.addEventListener("click", (e) => this.deleteAct35Survey(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35SurveyForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("act35SurveyField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("act35SurveySelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("act35SurveyRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Survey', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addAct35Course(e) {
        let srcElement = e.srcElement;
        let core = this._core;
        let planFK = srcElement.dataset.planfk;
        let isLockdown = srcElement.dataset.islockdown;
        let isReview = srcElement.dataset.isreview;
        let row = 0;
        let allRows = document.getElementsByClassName("act35-civics");
        for (let thisRow of allRows) {
            if (parseInt(thisRow.getAttribute("data-row")) > row)
                row = parseInt(thisRow.getAttribute("data-row"));
        }
        row++;
        let that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/AddAct35Course', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let div = document.createElement("div");
                div.classList.add("act35-civics");
                div.dataset.row = row.toString();
                div.innerHTML = xhr.responseText;
                let parent = document.getElementById("civicToolkit");
                parent.appendChild(div);
                $('.multiSelect').MultiSelect();
                that.bindDeleteButtons();
                that.bindCalculations();
                Core.createHTMLAlert("alertMessageDiv", "A Course has been added", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`row=${row}&planFK=${planFK}&isLockdown=${isLockdown}$isReview=${isReview}`);
    }
    initializeRequiredFieldsCustom() {
        let showMessage = false;
        let allRows = [];
        let totalErrors = 0;
        let allClasses = ["act35SurveyMultiselectCheckField", "act35SurveyField", "act35SurveyRadioField"];
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                        alreadyExists = true;
                    }
                }
                if (!alreadyExists) {
                    if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                        htmlElement.setAttribute("aria-required", "true");
                        let label = Core.findLabelForInput(htmlElement);
                        if (label !== null && !label.classList.contains("isRequired")) {
                            label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                            label.classList.add("isRequired");
                        }
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        //get the rows.
        let rowElements = document.getElementsByClassName("act35SurveyField");
        for (let row of rowElements) {
            let rowElement = row;
            let thisRow = parseInt(rowElement.dataset.row);
            if (allRows.indexOf(thisRow) === -1)
                allRows.push(thisRow);
        }
        let rowSuccesses = [];
        for (let rowNum of allRows) {
            let successes = [];
            let textElements = document.querySelectorAll(`.act35SurveyField[data-row='${rowNum}']`);
            for (let text of textElements) {
                let textElement = text;
                if (textElement.value !== "")
                    successes.push(true);
                else
                    successes.push(false);
            }
            let typeOfAssessmentChecks = document.querySelectorAll(`.act35SurveyMultiselectCheckField[data-type='TypeOfAssessment'][data-row='${rowNum}']`);
            let typeOfAssessmentIsChecked = false;
            for (let typeOfAssessment of typeOfAssessmentChecks) {
                let ele = typeOfAssessment;
                if (ele.checked)
                    typeOfAssessmentIsChecked = true;
            }
            let gradeChecks = document.querySelectorAll(`.act35SurveyMultiselectCheckField[data-type='Grade'][data-row='${rowNum}']`);
            let gradeIsChecked = false;
            for (let grade of gradeChecks) {
                let ele = grade;
                if (ele.checked)
                    gradeIsChecked = true;
            }
            successes.push(typeOfAssessmentIsChecked);
            successes.push(gradeIsChecked);
            let allFieldsFilled = false;
            if (successes.indexOf(false) > -1) {
                if (successes.indexOf(true) === -1) {
                    //If everything is false, this is fine. As long as ONE of the rows has a full set of data, we're good.
                    allFieldsFilled = true;
                }
                else {
                    allFieldsFilled = false;
                }
            }
            else {
                //If everything is true, this is good to go
                allFieldsFilled = true;
            }
            rowSuccesses.push(allFieldsFilled);
        }
        let allFails = document.querySelectorAll(".graduationReportNumbers[data-failed='failed']");
        let allFailedRows = [];
        if (allFails.length > 0) {
            for (let fail of allFails)
                allFailedRows.push(fail.dataset.row);
            for (let row of allFailedRows) {
                let assessedElement = document.querySelector(`.act35SurveyField[data-row='${row}'][data-assessed='yes']`);
                let passedElement = document.querySelector(`.act35SurveyField[data-row='${row}'][data-passed='yes']`);
                let perfectElement = document.querySelector(`.act35SurveyField[data-row='${row}'][data-perfect='yes']`);
                assessedElement.classList.add("missing-element");
                passedElement.classList.add("missing-element");
                perfectElement.classList.add("missing-element");
                rowSuccesses.push(false);
                totalErrors++;
                showMessage = true;
            }
        }
        let missingComments = false;
        const radios = document.querySelectorAll(".act35SurveyRadioField");
        for (const radio of radios) {
            const ele = radio;
            if (ele.checked) {
                const val = ele.dataset.lookupcode;
                if (val === "yes") {
                    const additionalCommentsElement = document.getElementById("act35Act35SurveyAdditionalComments");
                    if (additionalCommentsElement.value === "") {
                        missingComments = true;
                        showMessage = true;
                        totalErrors++;
                        additionalCommentsElement.classList.add("missing-field");
                        additionalCommentsElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(additionalCommentsElement);
                    }
                }
            }
        }
        //if any of the rowSuccesses are false, something is missing, so this is "invalid"
        if (rowSuccesses.indexOf(false) > -1 || missingComments) {
            for (let rowNum of allRows) {
                let textElements = document.querySelectorAll(`.act35SurveyField[data-row='${rowNum}']`);
                for (let text of textElements) {
                    let textElement = text;
                    if (textElement.value === "") {
                        textElement.classList.add("missing-field");
                        textElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(textElement);
                        showMessage = true;
                        totalErrors++;
                    }
                }
            }
            let message = document.getElementById("validationMessage");
            if (totalErrors === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            }
            else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");
            if (goToError !== null) {
                let that = this;
                let firstFocusableEl = document.querySelector(".missing-field");
                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                        if (accordion) {
                            let id = accordion.getAttribute("aria-labelledby");
                            let accordionElement = document.getElementById(id);
                            accordionElement.click();
                        }
                        firstFocusableEl.focus();
                    });
                }
                else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
        }
        else {
            showMessage = false;
        }
        return showMessage;
    }
    deleteAct35Survey(e) {
        let buttonElement = e.srcElement;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteAct35SurveyModal", null);
        modal.addAttributeToElement("deleteAct35SurveyModal", "#deleteAct35SurveyConfirm", "row", row);
        modal.show();
    }
    deleteAct35SurveyCancel() {
        let modal = new Modal("deleteAct35SurveyModal", null);
        modal.hide();
    }
    deleteAct35SurveyConfirm(e) {
        let buttonElement = e.srcElement;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.act35SurveyField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let allCheckElements = document.querySelectorAll(`.act35SurveyMultiselectCheckField[data-row='${row}']`);
        for (let chk of allCheckElements) {
            let checkElement = chk;
            let planProp = checkElement.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/DeleteAct35Survey', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let divElement = document.querySelector(`.act35-civics[data-row='${row}']`);
                divElement.remove();
                let modal = new Modal("deleteAct35SurveyModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The Act 35 Survey row has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindCalculations() {
        let assessedElements = document.querySelectorAll(`.act35SurveyField[data-assessed='yes']`);
        for (let ele of assessedElements)
            ele.addEventListener("blur", (e) => this.calculateValues());
        let passedElements = document.querySelectorAll(`.act35SurveyField[data-passed='yes']`);
        for (let ele of passedElements)
            ele.addEventListener("blur", (e) => this.calculateValues());
        let perfectElements = document.querySelectorAll(`.act35SurveyField[data-perfect='yes']`);
        for (let ele of perfectElements)
            ele.addEventListener("blur", (e) => this.calculateValues());
    }
    calculateValues() {
        let rows = [];
        let allRows = document.querySelectorAll(".act35-civics");
        for (let row of allRows) {
            let ele = row;
            rows.push(ele.dataset.row);
        }
        for (let row of rows) {
            let assessedElement = document.querySelector(`.act35SurveyField[data-row='${row}'][data-assessed='yes']`);
            let passedElement = document.querySelector(`.act35SurveyField[data-row='${row}'][data-passed='yes']`);
            let perfectElement = document.querySelector(`.act35SurveyField[data-row='${row}'][data-perfect='yes']`);
            let assessed = 0;
            let passed = 0;
            let perfect = 0;
            if (assessedElement)
                assessed = parseInt(assessedElement.value);
            if (passedElement)
                passed = parseInt(passedElement.value);
            if (perfectElement)
                perfect = parseInt(perfectElement.value);
            let errorElement = document.querySelector(`.act35ReportNumbers[data-row='${row}']`);
            if ((passed > assessed) || (perfect > assessed) || (perfect > passed)) {
                errorElement.classList.remove("hide");
                errorElement.dataset.failed = "failed";
            }
            else {
                errorElement.classList.add("hide");
                errorElement.removeAttribute("data-failed");
            }
        }
    }
    changeAdditionalComments() {
        const additionalCommentsTextAreaContainer = document.querySelector(".additional-comments");
        const radioElements = document.querySelectorAll(".act35SurveyRadioField");
        for (const radio of radioElements) {
            const ele = radio;
            if (ele.checked) {
                const additionalCommentsElement = document.getElementById("act35Act35SurveyAdditionalComments");
                const lookup = ele.dataset.lookupcode;
                if (lookup.toLowerCase() === "yes") {
                    additionalCommentsTextAreaContainer.classList.remove("hide");
                    this._core.forceElementRequired(additionalCommentsElement);
                }
                else {
                    additionalCommentsTextAreaContainer.classList.add("hide");
                    this._core.forceElementOptional(additionalCommentsElement);
                }
            }
        }
    }
}
// Act35Signatures
class Act35Signatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["act35SignaturesField", "act35SignaturesCheckboxField"];
        let act35SignaturesSaveButton = document.getElementById("act35SignaturesSave");
        if (act35SignaturesSaveButton !== null)
            act35SignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let exports = document.getElementsByClassName("fullReport");
        for (let exportOption of exports) {
            exportOption.addEventListener("click", function () {
                Core.showLongLoader();
                //Get all components for given template
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/ExportDocx/FullReportCivicKnowledgeSurveyExport', true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    Core.hideLongLoader();
                    if (xhr.status === 200) {
                        let blob = this.response;
                        let filename = exportOption.textContent;
                        filename = filename + ".docx";
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                            window.navigator.msSaveBlob(blob, filename);
                        }
                        else {
                            var a = document.createElement("a");
                            var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                            document.body.appendChild(a);
                            a.style.display = "none";
                            a.href = blobUrl;
                            a.download = filename;
                            a.click();
                        }
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue generating this report. Please try again later.", 'error', 3000, null);
                    }
                };
                xhr.send("planFK=" + exportOption.dataset.planfk);
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("act35SignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("act35SignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("act35SignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CivicKnowledgeSurvey/SaveAct35Signatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// Act35Summary
class Act35Summary {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CivicKnowledgeSurvey/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/lazyAccordion.ts" />
// SpecialEducationIUProfileIdentify
class SpecialEducationIUProfileIdentify {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUProfileIdentifyField"];
        let specialEducationIUProfileIdentifySaveButton = document.getElementById("specialEducationIUProfileIdentifySave");
        if (specialEducationIUProfileIdentifySaveButton !== null)
            specialEducationIUProfileIdentifySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("specialEducationIUProfileIdentifyForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProfileIdentifyForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUProfileIdentifyField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProfileIdentify', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SpecialEducationIUProfileDemo
class SpecialEducationIUProfileDemo {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUProfileDemoField"];
        let specialEducationIUProfileDemoSaveButton = document.getElementById("specialEducationIUProfileDemoSave");
        if (specialEducationIUProfileDemoSaveButton !== null)
            specialEducationIUProfileDemoSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProfileDemoForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUProfileDemoField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProfileDemo', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SpecialEducationIUProfileNarrative
class SpecialEducationIUProfileNarrative {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUProfileNarrativeField"];
        let specialEducationIUProfileNarrativeSaveButton = document.getElementById("specialEducationIUProfileNarrativeSave");
        if (specialEducationIUProfileNarrativeSaveButton !== null)
            specialEducationIUProfileNarrativeSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProfileNarrativeForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUProfileNarrativeField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProfileNarrative', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SpecialEducationIUProgramAdmin
class SpecialEducationIUProgramAdmin {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField", "specialEducationIUCoreServicesCheckboxField"];
        this.notApplicable();
        let specialEducationIUProgramAdminSaveButton = document.getElementById("specialEducationIUProgramAdminSave");
        if (specialEducationIUProgramAdminSaveButton !== null)
            specialEducationIUProgramAdminSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true")
            this.doValidationCustom(this.validationClasses);
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.specialEducationCore = new SpecialEducationIUCoreServicesData();
        const specialEducationIUStateFundsProgramAdminNotApplicable = document.getElementById("specialEducationIUStateFundsProgramAdminNotApplicable");
        if (specialEducationIUStateFundsProgramAdminNotApplicable !== null)
            specialEducationIUStateFundsProgramAdminNotApplicable.addEventListener("change", (e) => this.notApplicable());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProgramAdminForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProgramAdmin', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const specialEducationIUStateFundsProgramAdminNotApplicable = document.getElementById("specialEducationIUStateFundsProgramAdminNotApplicable");
        if (!specialEducationIUStateFundsProgramAdminNotApplicable.checked) {
            const description = document.querySelector("#specialEducationIUStateFundsProgramAdminServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }
            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = rowTR;
                const row = ele.dataset.row;
                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }
            let selectCol = false;
            let nameCol = false;
            let fteCol = false;
            let oneRowComplete = false;
            for (const tableRow of allRows) {
                const nameElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);
                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);
                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    }
                    else {
                        selectCol = true;
                    }
                }
                else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                }
                else {
                    selectCol = true;
                }
                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                }
                else {
                    nameCol = true;
                }
                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                }
                else {
                    fteCol = true;
                }
                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }
        let message = document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    notApplicable() {
        const specialEducationIUStateFundsProgramAdminNotApplicable = document.getElementById("specialEducationIUStateFundsProgramAdminNotApplicable");
        if (specialEducationIUStateFundsProgramAdminNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsProgramAdminNotApplicable.checked);
        }
    }
    checkNotApplicable(checked) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (const text of allTextElements) {
            const ele = text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }
        for (const select of allSelectElements) {
            const ele = select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }
        const iuSpecialEducationCoreServiceAddRowNumber = document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            }
            else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}
// SpecialEducationIUDataCollection
class SpecialEducationIUDataCollection {
    constructor() {
        this._core = new Core();
        this.notApplicable();
        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField"];
        let specialEducationIUDataCollectionSaveButton = document.getElementById("specialEducationIUDataCollectionSave");
        if (specialEducationIUDataCollectionSaveButton !== null)
            specialEducationIUDataCollectionSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const specialEducationIUDataCollectionNotApplicable = document.getElementById("specialEducationIUDataCollectionNotApplicable");
        if (specialEducationIUDataCollectionNotApplicable !== null)
            specialEducationIUDataCollectionNotApplicable.addEventListener("change", (e) => this.notApplicable());
        this.specialEducationCore = new SpecialEducationIUCoreServicesData();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUDataCollectionForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUDataCollection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const specialEducationIUDataCollectionNotApplicable = document.getElementById("specialEducationIUDataCollectionNotApplicable");
        if (!specialEducationIUDataCollectionNotApplicable.checked) {
            const description = document.querySelector("#specialEducationIUStateFundsDataCollectionServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }
            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = rowTR;
                const row = ele.dataset.row;
                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }
            let selectCol = false;
            let nameCol = false;
            let fteCol = false;
            let oneRowComplete = false;
            for (const tableRow of allRows) {
                const nameElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);
                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);
                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    }
                    else {
                        selectCol = true;
                    }
                }
                else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                }
                else {
                    selectCol = true;
                }
                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                }
                else {
                    nameCol = true;
                }
                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                }
                else {
                    fteCol = true;
                }
                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }
        let message = document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    notApplicable() {
        const specialEducationIUDataCollectionNotApplicable = document.getElementById("specialEducationIUDataCollectionNotApplicable");
        if (specialEducationIUDataCollectionNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUDataCollectionNotApplicable.checked);
        }
    }
    checkNotApplicable(checked) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (const text of allTextElements) {
            const ele = text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }
        for (const select of allSelectElements) {
            const ele = select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }
        const iuSpecialEducationCoreServiceAddRowNumber = document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            }
            else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}
// SpecialEducationIUProgramEval
class SpecialEducationIUProgramEval {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField"];
        this.notApplicable();
        let specialEducationIUProgramEvalSaveButton = document.getElementById("specialEducationIUProgramEvalSave");
        if (specialEducationIUProgramEvalSaveButton !== null)
            specialEducationIUProgramEvalSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.specialEducationCore = new SpecialEducationIUCoreServicesData();
        const specialEducationIUStateFundsProgramEvaluationNotApplicable = document.getElementById("specialEducationIUStateFundsProgramEvaluationNotApplicable");
        if (specialEducationIUStateFundsProgramEvaluationNotApplicable !== null)
            specialEducationIUStateFundsProgramEvaluationNotApplicable.addEventListener("change", (e) => this.notApplicable());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProgramEvalForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProgramEval', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const specialEducationIUStateFundsProgramEvaluationNotApplicable = document.getElementById("specialEducationIUStateFundsProgramEvaluationNotApplicable");
        if (!specialEducationIUStateFundsProgramEvaluationNotApplicable.checked) {
            const description = document.querySelector("#specialEducationIUStateFundsProgramEvaluationServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }
            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = rowTR;
                const row = ele.dataset.row;
                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }
            let selectCol = false;
            let nameCol = false;
            let fteCol = false;
            let oneRowComplete = false;
            for (const tableRow of allRows) {
                const nameElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);
                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);
                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    }
                    else {
                        selectCol = true;
                    }
                }
                else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                }
                else {
                    selectCol = true;
                }
                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                }
                else {
                    nameCol = true;
                }
                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                }
                else {
                    fteCol = true;
                }
                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }
        let message = document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    notApplicable() {
        const specialEducationIUStateFundsProgramEvaluationNotApplicable = document.getElementById("specialEducationIUStateFundsProgramEvaluationNotApplicable");
        if (specialEducationIUStateFundsProgramEvaluationNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsProgramEvaluationNotApplicable.checked);
        }
    }
    checkNotApplicable(checked) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (const text of allTextElements) {
            const ele = text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }
        for (const select of allSelectElements) {
            const ele = select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }
        const iuSpecialEducationCoreServiceAddRowNumber = document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            }
            else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}
// SpecialEducationIURelatedServices
class SpecialEducationIURelatedServices {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField"];
        this.notApplicable();
        let specialEducationIURelatedServicesSaveButton = document.getElementById("specialEducationIURelatedServicesSave");
        if (specialEducationIURelatedServicesSaveButton !== null)
            specialEducationIURelatedServicesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.specialEducationCore = new SpecialEducationIUCoreServicesData();
        const specialEducationIUStateFundsRelatedServicesNotApplicable = document.getElementById("specialEducationIUStateFundsRelatedServicesNotApplicable");
        if (specialEducationIUStateFundsRelatedServicesNotApplicable !== null)
            specialEducationIUStateFundsRelatedServicesNotApplicable.addEventListener("change", (e) => this.notApplicable());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIURelatedServicesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIURelatedServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const specialEducationIUStateFundsRelatedServicesNotApplicable = document.getElementById("specialEducationIUStateFundsRelatedServicesNotApplicable");
        if (!specialEducationIUStateFundsRelatedServicesNotApplicable.checked) {
            const description = document.querySelector("#specialEducationIUStateFundsRelatedServicesServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }
            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = rowTR;
                const row = ele.dataset.row;
                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }
            let selectCol = false;
            let nameCol = false;
            let fteCol = false;
            let oneRowComplete = false;
            for (const tableRow of allRows) {
                const nameElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);
                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);
                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    }
                    else {
                        selectCol = true;
                    }
                }
                else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                }
                else {
                    selectCol = true;
                }
                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                }
                else {
                    nameCol = true;
                }
                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                }
                else {
                    fteCol = true;
                }
                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }
        let message = document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    notApplicable() {
        const specialEducationIUStateFundsRelatedServicesNotApplicable = document.getElementById("specialEducationIUStateFundsRelatedServicesNotApplicable");
        if (specialEducationIUStateFundsRelatedServicesNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsRelatedServicesNotApplicable.checked);
        }
    }
    checkNotApplicable(checked) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (const text of allTextElements) {
            const ele = text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }
        for (const select of allSelectElements) {
            const ele = select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }
        const iuSpecialEducationCoreServiceAddRowNumber = document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            }
            else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}
// SpecialEducationIUAccess
class SpecialEducationIUAccess {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField", "specialEducationIUCoreServicesTargetsSelectField"];
        this.notApplicable();
        this.notApplicableTarget();
        let specialEducationIUAccessSaveButton = document.getElementById("specialEducationIUAccessSave");
        if (specialEducationIUAccessSaveButton !== null)
            specialEducationIUAccessSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this.specialEducationCore = new SpecialEducationIUCoreServicesData();
        const specialEducationIUStateFundsAccessNotApplicable = document.getElementById("specialEducationIUStateFundsAccessNotApplicable");
        if (specialEducationIUStateFundsAccessNotApplicable !== null)
            specialEducationIUStateFundsAccessNotApplicable.addEventListener("change", (e) => this.notApplicable());
        const specialEducationIUStateFundsAccessTargetsNotApplicable = document.getElementById("specialEducationIUStateFundsAccessTargetsNotApplicable");
        if (specialEducationIUStateFundsAccessTargetsNotApplicable !== null)
            specialEducationIUStateFundsAccessTargetsNotApplicable.addEventListener("change", (e) => this.notApplicableTarget());
        let iuSpecialEducationProgramTargetsAddRowButton = document.getElementById("iuSpecialEducationCoreServicesTargetsAddRow");
        if (iuSpecialEducationProgramTargetsAddRowButton !== null)
            iuSpecialEducationProgramTargetsAddRowButton.addEventListener("click", (e) => this.addTargetsProgramRow());
        let iuSpecialEducationCoreServicesTargetsRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesTargetsRowDeleteCancel");
        if (iuSpecialEducationCoreServicesTargetsRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesTargetsRowDeleteCancelButton.addEventListener("click", (e) => this.deleteTargetsRowCancel());
        let iuSpecialEducationCoreServicesRowTargetsDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesTargetsRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowTargetsDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowTargetsDeleteConfirmButton.addEventListener("click", (e) => this.deleteTargetsRowConfirm(e));
        this.setupTargetsOtherDropdowns();
        this.bindTargetsOtherDropdown();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUAccessForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let textTargetsInputs = document.getElementsByClassName("specialEducationIUCoreServicesTargetsField");
        for (let ele of textTargetsInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const targetSelects = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");
        for (const ele of targetSelects) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const specialEducationIUStateFundsAccessNotApplicable = document.getElementById("specialEducationIUStateFundsAccessNotApplicable");
        if (!specialEducationIUStateFundsAccessNotApplicable.checked) {
            const description = document.querySelector("#specialEducationIUStateFundsAccessServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }
            const specialEducationIUStateFundsAccessEstimated = document.querySelector("#specialEducationIUStateFundsAccessEstimated");
            if (specialEducationIUStateFundsAccessEstimated.value === "") {
                specialEducationIUStateFundsAccessEstimated.classList.add("missing-field");
                specialEducationIUStateFundsAccessEstimated.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(specialEducationIUStateFundsAccessEstimated);
                showMessage = true;
                totalErrors++;
            }
            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = rowTR;
                const row = ele.dataset.row;
                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }
            let oneRowComplete = false;
            for (const tableRow of allRows) {
                const nameElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);
                let selectCol = false;
                let nameCol = false;
                let fteCol = false;
                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);
                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    }
                    else {
                        selectCol = true;
                    }
                }
                else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                }
                else {
                    selectCol = true;
                }
                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                }
                else {
                    nameCol = true;
                }
                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                }
                else {
                    if (fteElement.value !== "") {
                        const fteVal = parseFloat(fteElement.value);
                        if (!isNaN(fteVal)) {
                            if (fteVal < 0 || fteVal > 1) {
                                fteElement.classList.add("missing-field");
                                fteElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fteElement);
                                showMessage = true;
                                totalErrors++;
                                fteCol = false;
                            }
                            else
                                fteCol = true;
                        }
                        else {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    }
                    else {
                        if (!oneRowComplete && (nameCol || selectCol)) {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    }
                }
                if (!oneRowComplete)
                    oneRowComplete = fteCol && nameCol && selectCol;
            }
        }
        const specialEducationIUStateFundsAccessTargetsNotApplicable = document.getElementById("specialEducationIUStateFundsAccessTargetsNotApplicable");
        if (!specialEducationIUStateFundsAccessTargetsNotApplicable.checked) {
            const specialEducationIUStateFundsAccessTargeted = document.querySelector("#specialEducationIUStateFundsAccessTargeted");
            if (specialEducationIUStateFundsAccessTargeted.value === "") {
                specialEducationIUStateFundsAccessTargeted.classList.add("missing-field");
                specialEducationIUStateFundsAccessTargeted.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(specialEducationIUStateFundsAccessTargeted);
                showMessage = true;
                totalErrors++;
            }
            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServicesTargets table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = rowTR;
                const row = ele.dataset.row;
                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }
            let oneRowComplete = false;
            for (const tableRow of allRows) {
                const nameElement = document.querySelector(`.specialEducationIUCoreServicesTargetsField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = document.querySelector(`.specialEducationIUCoreServicesTargetsField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = document.querySelector(`.specialEducationIUCoreServicesTargetsField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = document.querySelector(`.specialEducationIUCoreServicesTargetsSelectField[data-row='${tableRow}']`);
                let selectCol = false;
                let nameCol = false;
                let fteCol = false;
                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);
                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    }
                    else {
                        selectCol = true;
                    }
                }
                else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                }
                else {
                    selectCol = true;
                }
                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                }
                else {
                    nameCol = true;
                }
                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                }
                else {
                    if (fteElement.value !== "") {
                        const fteVal = parseFloat(fteElement.value);
                        if (!isNaN(fteVal)) {
                            if (fteVal < 0 || fteVal > 1) {
                                fteElement.classList.add("missing-field");
                                fteElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fteElement);
                                showMessage = true;
                                totalErrors++;
                                fteCol = false;
                            }
                            else
                                fteCol = true;
                        }
                        else {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    }
                    else {
                        if (!oneRowComplete && (nameCol || selectCol)) {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    }
                }
                if (!oneRowComplete)
                    oneRowComplete = fteCol && nameCol && selectCol;
            }
        }
        let message = document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    notApplicable() {
        const specialEducationIUStateFundsAccessNotApplicable = document.getElementById("specialEducationIUStateFundsAccessNotApplicable");
        if (specialEducationIUStateFundsAccessNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsAccessNotApplicable.checked);
        }
    }
    checkNotApplicable(checked) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (const text of allTextElements) {
            const ele = text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }
        for (const select of allSelectElements) {
            const ele = select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }
        const iuSpecialEducationCoreServiceAddRowNumber = document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServicesAddRow !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            }
            else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
    notApplicableTarget() {
        const specialEducationIUStateFundsAccessTargetsNotApplicable = document.getElementById("specialEducationIUStateFundsAccessTargetsNotApplicable");
        if (specialEducationIUStateFundsAccessTargetsNotApplicable !== null)
            this.checkNotApplicableTarget(specialEducationIUStateFundsAccessTargetsNotApplicable.checked);
    }
    checkNotApplicableTarget(checked) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");
        for (const text of allTextElements) {
            const ele = text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }
        for (const select of allSelectElements) {
            const ele = select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            }
            else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }
        const iuSpecialEducationCoreServiceTargetsAddRowNumber = document.getElementById("iuSpecialEducationCoreServiceTargetsAddRowNumber");
        const iuSpecialEducationCoreServicesTargetsAddRow = document.getElementById("iuSpecialEducationCoreServicesTargetsAddRow");
        if (iuSpecialEducationCoreServicesTargetsAddRow !== null && iuSpecialEducationCoreServiceTargetsAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceTargetsAddRowNumber.readOnly = true;
                iuSpecialEducationCoreServicesTargetsAddRow.disabled = true;
            }
            else {
                iuSpecialEducationCoreServiceTargetsAddRowNumber.readOnly = false;
                iuSpecialEducationCoreServicesTargetsAddRow.disabled = false;
            }
        }
    }
    setupTargetsOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");
        for (let selectElement of selectElements)
            this.targetsDropdownChange(selectElement);
    }
    showTargetsDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteCoreServicesTargetsRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesTargetsRowModal", "#iuSpecialEducationCoreServicesTargetsRowDeleteConfirm", "row", row);
        modal.show();
    }
    bindTargetsDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteTargetsProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showTargetsDeleteRow(e));
    }
    bindTargetsOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.targetsDropdownChange(e.target));
    }
    targetsDropdownChange(e) {
        let selectElement = e;
        let row = selectElement.dataset.row;
        let otherElement = document.querySelector(`.specialEducationIUCoreServicesTargetOtherField[data-row='${row}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        }
        else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }
    addTargetsProgramRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formName = "";
            let valueElement = document.getElementById("iuSpecialEducationCoreServiceTargetsAddRowNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
                formName = valueElement.dataset.formname;
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planForm = document.getElementById(formName);
                let planFK = planForm.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServicesTargets table tbody tr");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/SpecialEducationIUAccessTargetsAddRow/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.innerHTML = output;
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#specialEducationIUStateFundsCoreServicesTargets table tbody");
                        table.append(newTR);
                        that.bindTargetsDeleteProgramRows();
                        that.bindTargetsOtherDropdown();
                    }
                }
                valueElement.value = "";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `Row${plural} added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                valueElement.value = "";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteTargetsRowCancel() {
        let modal = new Modal("deleteCoreServicesTargetsRowModal", null);
        modal.hide();
    }
    deleteTargetsRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#specialEducationIUStateFundsCoreServicesTargets table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let warningRowElement = document.querySelector(`#specialEducationIUStateFundsCoreServicesTargets table tbody tr[data-row='${row}']`);
                if (warningRowElement != null) {
                    warningRowElement.remove();
                }
                let modal = new Modal("deleteCoreServicesTargetsRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}
// SpecialEducationIUCoreServicesData
class SpecialEducationIUCoreServicesData {
    constructor() {
        let iuSpecialEducationProgramAddRowButton = document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationProgramAddRowButton !== null)
            iuSpecialEducationProgramAddRowButton.addEventListener("click", (e) => this.addProgramRow());
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        this.setupOtherDropdowns();
        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
    }
    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }
    showDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }
    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.dropdownChange(e.target));
    }
    dropdownChange(e) {
        let selectElement = e;
        let row = selectElement.dataset.row;
        let otherElement = document.querySelector(`.specialEducationIUCoreServicesField.coreservices-select-other[data-row='${row}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        }
        else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }
    addProgramRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let method = "";
            let formName = "";
            let valueElement = document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
                method = valueElement.dataset.method;
                formName = valueElement.dataset.formname;
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planForm = document.getElementById(formName);
                let planFK = planForm.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tbody tr");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/${method}/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.innerHTML = output;
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#specialEducationIUStateFundsCoreServices table tbody");
                        table.append(newTR);
                        that.bindDeleteProgramRows();
                        that.bindOtherDropdown();
                    }
                }
                valueElement.value = "";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `Row${plural} added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                valueElement.value = "";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#specialEducationIUStateFundsCoreServices table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let warningRowElement = document.querySelector(`#specialEducationIUStateFundsCoreServices table tbody tr[data-row='${row}']`);
                if (warningRowElement != null) {
                    warningRowElement.remove();
                }
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}
// SpecialEducationIUProgramListing
class SpecialEducationIUProgramListing {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUProgramListingRadioField", "specialEducationIUProgramListingField"];
        this.checkForICP();
        this.forceAllElements();
        let specialEducationIUProgramListingSaveButton = document.getElementById("specialEducationIUProgramListingSave");
        if (specialEducationIUProgramListingSaveButton !== null)
            specialEducationIUProgramListingSaveButton.addEventListener("click", (e) => this.save("save"));
        this.changeQuestion();
        this.checkForFunding(true);
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        this.checkForICPNo(fromSaveElement.value);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, true, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let questionMasters = document.getElementsByClassName("icpQuestionMaster");
        for (let questionMaster of questionMasters)
            questionMaster.addEventListener("change", (e) => this.changeQuestion());
        const iuSpecialEducationAccessHasICPAlertMessageClose = document.getElementById("iuSpecialEducationAccessHasICPAlertMessageClose");
        if (iuSpecialEducationAccessHasICPAlertMessageClose !== null)
            iuSpecialEducationAccessHasICPAlertMessageClose.addEventListener("click", (e) => this.closeICPWarning());
        const addNewICPNameButton = document.getElementById("addNewICPName");
        if (addNewICPNameButton !== null)
            addNewICPNameButton.addEventListener("click", (e) => this.addNewICPName());
        const specialEducationIUStateFundsFundingAddICPSelect = document.getElementById("specialEducationIUStateFundsFundingAddICP");
        if (specialEducationIUStateFundsFundingAddICPSelect !== null)
            specialEducationIUStateFundsFundingAddICPSelect.addEventListener("change", (e) => this.changeICPName());
        const specialEducationIUFundingNameDeleteConfirm = document.getElementById("specialEducationIUFundingNameDeleteConfirm");
        if (specialEducationIUFundingNameDeleteConfirm !== null)
            specialEducationIUFundingNameDeleteConfirm.addEventListener("click", (e) => this.deleteConfirm(e));
        const specialEducationIUFundingNameDeleteCancel = document.getElementById("specialEducationIUFundingNameDeleteCancel");
        if (specialEducationIUFundingNameDeleteCancel !== null)
            specialEducationIUFundingNameDeleteCancel.addEventListener("click", (e) => this.deleteCancel());
        this.bindDeleteButtons();
        const specialEducationIUFundingRadios = document.getElementsByClassName("icpChildQuestion");
        for (const specialEducationIUFundingRadio of specialEducationIUFundingRadios)
            specialEducationIUFundingRadio.addEventListener("change", (e) => this.checkForFunding(false));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allRadioSaveElements = [];
        const allTextSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProgramListingForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let radios = document.getElementsByClassName("specialEducationIUProgramListingRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allRadioSaveElements.push(saveItem);
                }
            }
        }
        let inputs = document.getElementsByClassName("specialEducationIUProgramListingField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allTextSaveElements.push(saveItem);
            }
        }
        const data = {
            "RadioSaveElements": allRadioSaveElements,
            "TextSaveElements": allTextSaveElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProgramListing', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allRadioSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }
    changeQuestion() {
        let question = document.querySelector(".icpQuestionMaster[name='specialEducationIUICPHasICPName']:checked");
        let icpQuestionsElement = document.getElementById("icpQuestions");
        if (question && icpQuestionsElement) {
            if (question.dataset.lookupcode.toLowerCase() === "yes") {
                icpQuestionsElement.classList.remove("hide");
                let allRadios = document.getElementsByClassName("specialEducationIUProgramListingRadioField");
                for (let radio of allRadios) {
                    let ele = radio;
                    if (!ele.classList.contains("icpQuestionMaster")) {
                        this._core.forceElementRequired(ele);
                    }
                }
            }
            else {
                icpQuestionsElement.classList.add("hide");
                let allRadios = document.getElementsByClassName("specialEducationIUProgramListingRadioField");
                for (let radio of allRadios) {
                    let ele = radio;
                    if (!ele.classList.contains("icpQuestionMaster")) {
                        this._core.forceElementOptional(ele);
                    }
                }
            }
        }
    }
    checkForICPNo(saveElementValue) {
        const icpQuestionElement = document.querySelector(".icpQuestionMaster:checked");
        const childrenQuestions = document.querySelectorAll(".icpChildQuestion");
        if (icpQuestionElement) {
            if (icpQuestionElement.dataset.lookupcode === "no") {
                if (saveElementValue === "true") {
                    const warningMessageElement = document.getElementById("onlyForICPNo");
                    warningMessageElement.classList.remove("hide");
                }
                for (const question of childrenQuestions) {
                    const ele = question;
                    this._core.forceElementOptional(ele);
                }
            }
            else {
                for (const question of childrenQuestions) {
                    const ele = question;
                    this._core.forceElementRequired(ele);
                }
            }
        }
    }
    showICPWarning() {
        const allICPAnswers = document.querySelectorAll(".icpChildQuestion:checked");
        let showAlert = false;
        if (allICPAnswers) {
            for (const answer of allICPAnswers) {
                const ele = answer;
                if (ele.dataset.lookupcode === "no")
                    showAlert = true;
            }
            if (showAlert) {
                let modal = new Modal("hasICPALertMessage", null);
                modal.show();
            }
        }
    }
    closeICPWarning() {
        let modal = new Modal("hasICPALertMessage", null);
        modal.hide();
    }
    addNewICPName() {
        Core.showLoader();
        const planForm = document.getElementById("specialEducationIUProgramListingForm");
        const planFK = planForm.dataset.planfk;
        const icpNameElement = document.getElementById("specialEducationIUStateFundsFundingAddICP");
        const icpNameSelectedElement = icpNameElement[icpNameElement.selectedIndex];
        let checksOut = true;
        let icpName = "";
        if (icpNameElement.selectedIndex === 0) {
            checksOut = false;
        }
        else if (icpNameSelectedElement.dataset.label === "Other (Name or Describe)") {
            const otherElement = document.getElementById("specialEducationIUStateFundsFundingAddICPOtherId");
            if (otherElement !== null && otherElement.value !== "")
                icpName = otherElement.value;
            else
                checksOut = false;
        }
        else {
            icpName = icpNameSelectedElement.dataset.label;
        }
        const that = this;
        if (checksOut) {
            let data = {
                "ICPName": icpName,
                "PlanFK": planFK
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/AddNewICP', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("icpContainer");
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = xhr.responseText;
                    container.appendChild(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    icpNameElement.selectedIndex = 0;
                    that.bindDeleteButtons();
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "ICP Name record added!", 'success', 2000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                    Core.hideLoader();
                }
            };
            xhr.send(JSON.stringify(data));
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", `You must select an ICP name to continue. If you have selected "Other (Name of Describe)", you must enter a value in the Other Name or Description field.`, 'warning', 3000, null);
            Core.hideLoader();
        }
    }
    forceAllElements() {
        const allElements = document.getElementsByClassName("specialEducationIUFundingField");
        for (let element of allElements)
            this._core.forceElementRequired(element);
    }
    checkForICP() {
        const hasICP = document.getElementById("hasICP");
        if (hasICP.value === "true") {
            const allElements = document.querySelectorAll("input");
            for (const element of allElements) {
                const ele = element;
                this._core.forceElementOptional(ele);
                ele.readOnly = true;
                ele.disabled = true;
            }
            const saveButton = document.getElementById("specialEducationIUFundingSave");
            saveButton.disabled = true;
        }
    }
    changeICPName() {
        const specialEducationIUStateFundsFundingAddICPSelect = document.getElementById("specialEducationIUStateFundsFundingAddICP");
        if (specialEducationIUStateFundsFundingAddICPSelect !== null) {
            const selectedElement = specialEducationIUStateFundsFundingAddICPSelect[specialEducationIUStateFundsFundingAddICPSelect.selectedIndex];
            const icpName = document.getElementById("icpName");
            const icpNameOther = document.getElementById("icpNameOther");
            if (selectedElement.dataset.label === "Other (Name or Describe)") {
                icpName.classList.remove("medium-8");
                icpName.classList.add("medium-4");
                icpNameOther.classList.remove("hide");
            }
            else {
                icpName.classList.add("medium-8");
                icpName.classList.remove("medium-4");
                icpNameOther.classList.add("hide");
            }
        }
    }
    bindDeleteButtons() {
        const deleteNameSections = document.getElementsByClassName("deleteNameSection");
        for (const deleteNameSection of deleteNameSections)
            deleteNameSection.addEventListener("click", (e) => this.showDelete(e));
    }
    showDelete(e) {
        const element = e.target;
        const dataPointPlanPropertyFK = element.dataset.datapointplanpropertypk;
        const modal = new Modal("deleteFundingNameSectionModal", null);
        modal.addAttributeToElement("deleteFundingNameSectionModal", "#specialEducationIUFundingNameDeleteConfirm", "datapointplanpropertyfk", dataPointPlanPropertyFK);
        modal.show();
    }
    deleteCancel() {
        const modal = new Modal("deleteFundingNameSectionModal", null);
        modal.hide();
    }
    deleteConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertyfk;
            const response = yield fetch(`/IUSpecialEducation/DeleteNameSection/${dataPointPlanPropertyFK}`, { credentials: 'include' });
            if (response.ok) {
                const resp = yield response.json();
                if (resp.success === true) {
                    Core.createHTMLAlert("alertMessageDiv", "Name Section removed.", 'success', 2000, null);
                    const containerToRemove = document.querySelector(`.Accordion-trigger[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                    containerToRemove.parentElement.parentElement.remove();
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the Name Section, please try again.`, 'error', 3000, null);
                Core.hideLoader();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the Name Section, please try again.`, 'error', 3000, null);
                Core.hideLoader();
            }
            let modal = new Modal("deleteFundingNameSectionModal", null);
            modal.hide();
        });
    }
    checkForFunding(fromLoad) {
        const icpMaster = document.querySelector(".icpQuestionMaster:checked");
        const icpChildren = document.querySelectorAll(".icpChildQuestion:checked");
        let showFunding = true;
        let childFunding = true;
        if (icpMaster) {
            if (icpMaster.dataset.lookupcode === "yes") {
                for (const child of icpChildren) {
                    const ele = child;
                    if (ele.dataset.lookupcode === "no") {
                        showFunding = false;
                        childFunding = false;
                    }
                }
            }
            else {
                showFunding = false;
            }
        }
        const fundingForApprovedPrograms = document.getElementById("fundingForApprovedPrograms");
        if (showFunding) {
            fundingForApprovedPrograms.classList.remove("hide");
        }
        else {
            fundingForApprovedPrograms.classList.add("hide");
        }
        if (!childFunding && !fromLoad) {
            this.showICPWarning();
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// SpecialEducationIUComponent2
class SpecialEducationIUComponent2 {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUComponent2Field"];
        let specialEducationIUComponent2SaveButton = document.getElementById("specialEducationIUComponent2Save");
        if (specialEducationIUComponent2SaveButton !== null)
            specialEducationIUComponent2SaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
            this.checkFTEValues();
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const specialEducationIUComponent2FTEValues = document.getElementsByClassName("specialEducationIUComponent2FTEValue");
        for (const specialEducationIUComponent2FTEValue of specialEducationIUComponent2FTEValues)
            specialEducationIUComponent2FTEValue.addEventListener("input", (e) => this.calculateTotalFTE());
        this.calculateTotalFTE();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUComponent2Form");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUComponent2Field");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUComponent2', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    checkFTEValues() {
        const allFTEFields = document.getElementsByClassName("specialEducationIUComponent2FTEValue");
        let totalFTEValue = 0;
        for (const fteField of allFTEFields) {
            let element = fteField;
            if (element.value !== "") {
                const fteValue = parseFloat(element.value);
                if (!isNaN(fteValue))
                    totalFTEValue += fteValue;
            }
        }
        if (totalFTEValue < 5.0) {
            const errorElement = document.getElementById("specialEducationIUFiveError");
            errorElement.classList.remove("hide");
            errorElement.classList.add("showError");
        }
    }
    calculateTotalFTE() {
        let totalFTE = 0;
        const specialEducationIUComponent2FTEValues = document.getElementsByClassName("specialEducationIUComponent2FTEValue");
        for (const specialEducationIUComponent2FTEValue of specialEducationIUComponent2FTEValues) {
            const ele = specialEducationIUComponent2FTEValue;
            const doubleValue = parseFloat(ele.value);
            if (!isNaN(doubleValue)) {
                totalFTE += doubleValue;
            }
        }
        const totalElement = document.getElementById("specialEducationIUComponent2TotalFTE");
        totalElement.value = totalFTE.toString();
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        const errorsShown = document.querySelectorAll(".initiative-error.showError");
        if (errorsShown.length > 0) {
            totalErrors += errorsShown.length;
            showMessage = true;
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// SpecialEducationIUEquitable
class SpecialEducationIUEquitable {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUEquitableField", "specialEducationIUEquitableSelectField"];
        let specialEducationIUEquitableSaveButton = document.getElementById("specialEducationIUEquitableSave");
        if (specialEducationIUEquitableSaveButton !== null)
            specialEducationIUEquitableSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let iuSpecialEducationFTEsAllocatedAddRowButton = document.getElementById("iuSpecialEducationFTEsAllocatedAddRow");
        if (iuSpecialEducationFTEsAllocatedAddRowButton !== null)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e) => this.addRow());
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();
        this.bindTotalFTE();
        this.totalFTE();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUEquitableForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUEquitableField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUEquitableSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUEquitable', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUEquitableSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }
    showDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }
    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUEquitableSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.dropdownChange(e.target));
    }
    dropdownChange(e) {
        let selectElement = e;
        let row = selectElement.dataset.row;
        let otherElement = document.querySelector(`.specialEducationIUEquitableField.coreservices-select-other[data-row='${row}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        }
        else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }
    addRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("specialEducationIUEquitableForm");
            let valueElement = document.getElementById("iuSpecialEducationFTEsAllocatedAddRowNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll("#specialEducationIUEquitablePosition table tbody tr");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/AddEquitableParticipationRow/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.innerHTML = output;
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#specialEducationIUEquitablePosition table tbody");
                        table.append(newTR);
                        that.bindDeleteProgramRows();
                        that.bindOtherDropdown();
                        that.bindTotalFTE();
                    }
                }
                valueElement.value = "";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `Row${plural} added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                valueElement.value = "";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#specialEducationIUEquitablePosition table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindTotalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields)
            specialEducationIUProgramFTEField.addEventListener("input", (e) => this.totalFTE());
    }
    totalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        let totalFTE = 0;
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields) {
            const ele = specialEducationIUProgramFTEField;
            const value = parseFloat(ele.value);
            if (!isNaN(value))
                totalFTE += value;
        }
        const specialEducationIUComponent3EquitableFTETotal = document.getElementById("specialEducationIUComponent3EquitableFTETotal");
        specialEducationIUComponent3EquitableFTETotal.value = totalFTE.toString();
    }
}
// SpecialEducationIUFlowThrough
class SpecialEducationIUFlowThrough {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUFlowThroughField", "specialEducationIUFlowThroughSelectField"];
        let specialEducationIUFlowThroughSaveButton = document.getElementById("specialEducationIUFlowThroughSave");
        if (specialEducationIUFlowThroughSaveButton !== null)
            specialEducationIUFlowThroughSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let iuSpecialEducationFTEsAllocatedAddRowButton = document.getElementById("iuSpecialEducationFTEsAllocatedAddRow");
        if (iuSpecialEducationFTEsAllocatedAddRowButton !== null)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e) => this.addRow());
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();
        this.bindTotalFTE();
        this.totalFTE();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUFlowThroughForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUFlowThroughField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUFlowThroughSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUFlowThrough', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUFlowThroughSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }
    showDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }
    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUFlowThroughSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.dropdownChange(e.target));
    }
    dropdownChange(e) {
        let selectElement = e;
        let row = selectElement.dataset.row;
        let otherElement = document.querySelector(`.specialEducationIUFlowThroughField.coreservices-select-other[data-row='${row}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        }
        else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }
    addRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("specialEducationIUFlowThroughForm");
            let valueElement = document.getElementById("iuSpecialEducationFTEsAllocatedAddRowNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll("#specialEducationIUFlowThroughPosition table tbody tr");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/AddFlowThroughRow/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.innerHTML = output;
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#specialEducationIUFlowThroughPosition table tbody");
                        table.append(newTR);
                        that.bindDeleteProgramRows();
                        that.bindOtherDropdown();
                        that.bindTotalFTE();
                    }
                }
                valueElement.value = "";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `Row${plural} added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                valueElement.value = "";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#specialEducationIUFlowThroughPosition table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindTotalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields)
            specialEducationIUProgramFTEField.addEventListener("input", (e) => this.totalFTE());
    }
    totalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        let totalFTE = 0;
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields) {
            const ele = specialEducationIUProgramFTEField;
            const value = parseFloat(ele.value);
            if (!isNaN(value))
                totalFTE += value;
        }
        const specialEducationIUComponent3EquitableFTETotal = document.getElementById("specialEducationIUComponent3FlowServicesFTETotal");
        specialEducationIUComponent3EquitableFTETotal.value = totalFTE.toString();
    }
}
// SpecialEducationIUProgramProfiles
class SpecialEducationIUProgramProfiles {
    constructor() {
        let that = this;
        this._core = new Core();
        SpecialEducationIUProgramProfiles._staticCore = new Core();
        let form = document.getElementById("specialEducationEducationProgramCaseloadIUFTEForm");
        if (form != null) {
            that.planFK = parseInt(form.dataset.planfk);
            that.templateFK = parseInt(form.dataset.templatefk);
        }
        new SpecialEducationIUProgramProfilesLazyAccordion();
        SpecialEducationIUProgramProfiles.getValidProgramCount();
        const allowExceedsFTE = document.getElementById("allowExceedsFTE");
        if (allowExceedsFTE != null && allowExceedsFTE.value === "True")
            that.allowExceedsFTE = true;
        else
            that.allowExceedsFTE = false;
        that.deleteSupportConfirmModal = new Modal("deleteSupportConfirmModal", null);
        that.deleteFTEConfirmModal = new Modal("deleteFTEConfirmModal", null);
        this.validationClasses = [
            "fteID",
            "additionalJustification",
            "description",
            "buildingType",
            "supportTypeGroup",
            "supportType",
            "supportLevel",
            "caseLoad",
            "ageRangeFrom",
            "ageRangeEnd",
            "ageRangeJustification",
            "operatedBy",
            "fund",
            "classroomLocationFTE",
            "fullTime",
            "supportIdentifyClassroomOther"
        ];
        let specialEducationEducationProgramCaseloadFTEFormSaveButton = document.getElementById("iUSpecialEducationEducationProgramCaseloadFTESave");
        if (specialEducationEducationProgramCaseloadFTEFormSaveButton !== null) {
            specialEducationEducationProgramCaseloadFTEFormSaveButton.addEventListener("click", (e) => this.save("save"));
        }
        this._core.leftnav(this);
        this._core.tabLinkSave(this);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customValidation();
        }
        that.initializeFTEHashes();
        that.handleClearDisabled();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        //Event Listener for creating new FTE
        let createNewFTE = document.getElementById("createNewFTE");
        if (createNewFTE != null) {
            createNewFTE.addEventListener("click", () => {
                Core.showLoader();
                that.createNewFTE()
                    .then((response) => {
                    Core.hideLoader();
                    that._core.initializeRequiredFields(that.validationClasses);
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        //Event listener for deleting a support
        let deleteSupportConfirmButton = document.getElementById("deleteSupportConfirm");
        if (deleteSupportConfirmButton != null) {
            deleteSupportConfirmButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
                if ("supportPk" in deleteSupportConfirmButton.dataset && "ftePk" in deleteSupportConfirmButton.dataset) {
                    Core.showLoader();
                    that.deleteSupport(parseInt(deleteSupportConfirmButton.dataset.supportPk))
                        .then((response) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                        that.handleFTESaveDisabled(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        that.checkSupportCount(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        that.refreshFTEPercentage(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this support. Please try again later.", 'error', 3000, null);
                }
            });
        }
        //Event listener for confirming deletion of FTE
        let deleteFTEConfirmButton = document.getElementById("deleteFTEConfirm");
        if (deleteFTEConfirmButton != null) {
            deleteFTEConfirmButton.addEventListener("click", () => {
                if ("ftePk" in deleteFTEConfirmButton.dataset) {
                    that.deleteFTE(parseInt(deleteFTEConfirmButton.dataset.ftePk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE. Please try again later.", 'error', 3000, null);
                }
            });
        }
        //Event listener for cancelling the delete of a support
        let deleteSupportCancelButton = document.getElementById("deleteSupportCancel");
        if (deleteSupportCancelButton != null) {
            deleteSupportCancelButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
            });
        }
        //Event listener for cancelling the delete of an FTE
        let deleteFTECancelButton = document.getElementById("deleteFTECancel");
        if (deleteFTECancelButton != null) {
            deleteFTECancelButton.addEventListener("click", () => {
                that.deleteFTEConfirmModal.hide();
            });
        }
        //Event listener for clicking the search button
        let searchButton = document.getElementById("searchButton");
        if (searchButton !== null) {
            //1. Save page (will only save FTE that have changed)
            //2. Run Search
            searchButton.addEventListener("click", () => {
                Core.showLoader();
                that.promiseSave()
                    .then(() => {
                    return that.search();
                })
                    .then(() => {
                    that.initializeFTEHashes();
                    that._core.initializeRequiredFields(that.validationClasses);
                    //reinitialize accordions
                    new SpecialEducationIUProgramProfilesLazyAccordion();
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        //Event listener for searching when hitting enter
        let fteIDSearch = document.getElementById("searchFTEId");
        if (fteIDSearch !== null) {
            fteIDSearch.addEventListener("keypress", (e) => {
                if (e.keyCode === 13) {
                    Core.showLoader();
                    that.promiseSave()
                        .then(() => {
                        return that.search();
                    })
                        .then(() => {
                        that._core.initializeRequiredFields(that.validationClasses);
                        that.initializeFTEHashes();
                        //reinitialize accordions
                        new SpecialEducationIUProgramProfilesLazyAccordion();
                        Core.hideLoader();
                    })
                        .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }
            });
            fteIDSearch.addEventListener("input", () => {
                that.handleClearDisabled();
            });
        }
        //Clears the search fields
        let clearButton = document.getElementById("searchClear");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                that.clearSearch();
            });
        }
        //When changing building filter, determine whether clear button should be enabled
        let buildingSearch = document.getElementById("searchBuilding");
        let supportTypeSearch = document.getElementById("searchSupportType");
        if (buildingSearch != null) {
            buildingSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }
        //When changing support type filter, determine whether clear button should be enabled
        if (supportTypeSearch != null) {
            supportTypeSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }
        //When user has searched, focus on search result message
        let searchResultsMessage = document.getElementById("searchResultsMessage");
        if (searchResultsMessage != null) {
            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
            searchResultsMessage.focus();
        }
        //Event listener for exporting all data to excel
        let exportToExcelButton = document.getElementById("exportExcelFTE");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        //Event listener for exporting data to excel based on search criteria
        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
        if (exportToExcelFilteredButton != null) {
            exportToExcelFilteredButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel(true)
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        document.addEventListener("click", (e) => {
            let target = e.target;
            //Adding a support
            if (target.classList.contains("addSupportButton") && "ftePk" in target.dataset) {
                Core.showLoader();
                that.createNewSupport(parseInt(target.dataset.ftePk))
                    .then((response) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    that.checkSupportCount(parseInt(target.dataset.ftePk));
                    that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk));
                })
                    .then(() => {
                    that._core.initializeRequiredFields(that.validationClasses);
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            }
            //Deleting a support
            else if (target.classList.contains("deleteSupport") && "supportPk" in target.dataset && "ftePk" in target.dataset) {
                that.deleteSupportConfirmModal.show();
                deleteSupportConfirmButton.dataset.supportPk = target.dataset.supportPk;
                deleteSupportConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteSupportConfirmModal.callingId = target.id;
            }
            //Deleting an FTE
            else if (target.classList.contains("deleteFTE") && "ftePk" in target.dataset) {
                that.deleteFTEConfirmModal.show();
                deleteFTEConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteFTEConfirmModal.callingId = target.id;
            }
            //Saving an FTE
            else if (target.classList.contains("saveFTEButton") && "ftePk" in target.dataset) {
                Core.showLoader();
                that.promiseSave(parseInt(target.dataset.ftePk))
                    .then((response) => {
                    //Recalculate hash and handle disabling the save button for the FTE
                    SpecialEducationIUProgramProfiles.clearValidationMessage();
                    that.fteHashes[parseInt(target.dataset.ftePk)] = that.calculateFTEHash(parseInt(target.dataset.ftePk));
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    SpecialEducationIUProgramProfiles.fteValidation(parseInt(target.dataset.ftePk));
                    SpecialEducationIUProgramProfiles.getValidProgramCount();
                    SpecialEducationIUProgramProfiles.fullPageValidation();
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            }
        });
        document.addEventListener("change", (e) => {
            let target = e.target;
            //Handle Support Type Group changing
            if (target.classList.contains("supportTypeGroup") && "supportPk" in target.dataset && "ftePk" in target.dataset) {
                //1. Refresh the support type list
                //2. Refresh the support level list (1 needs to happen first)
                //3. Refresh FTE percent (1 and 2 need to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.refreshSupportTypeSubList(parseInt(target.dataset.supportPk))
                    .then(() => {
                    return that.refreshSupportLevels(parseInt(target.dataset.supportPk));
                })
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                })
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Support Type changing
            else if (target.classList.contains("supportType") && "supportPk" in target.dataset) {
                //1. Refresh the support level list
                //2. Refresh FTE percent (1 needs to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.refreshSupportLevels(parseInt(target.dataset.supportPk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                })
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Support Level changing
            else if (target.classList.contains("supportLevel") && "supportPk" in target.dataset) {
                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Classroom Location for FTE changing
            else if (target.classList.contains("classroomLocationFTE") && "ftePk" in target.dataset) {
                that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //Handle Full-time/Part-time changing
            else if (target.classList.contains("fullTime") && "ftePk" in target.dataset) {
                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                })
                    .catch((error) => {
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                });
            }
            //Handle Classroom Location for Support changing
            else if (target.classList.contains("classroomLocationSupport") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk), parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //Handle the change of the OperatedBy (Identify Classroom) field.
            else if (target.classList.contains("operatedBy") && "supportPk" in target.dataset) {
                SpecialEducationIUProgramProfiles.changeOperatedByIdentifyClassroom(target);
            }
            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && !(target instanceof HTMLInputElement) && !(target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            }
        });
        document.addEventListener("input", (e) => {
            //On a slight delay so that ajax call isn't made until finished typing
            let target = e.target;
            //Handle Caseload changing
            if (target.classList.contains("caseLoad") && "supportPk" in target.dataset) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                if (typeof (that.typingTimer) !== 'undefined') {
                    clearTimeout(that.typingTimer);
                }
                that.typingTimer = setTimeout(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                    that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                        .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                        .catch((error) => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
                }, 500);
            }
            //Handle age Range From for Support changing
            else if (target.classList.contains("ageRangeFrom") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk), parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //Handle age Range End for Support changing
            else if (target.classList.contains("ageRangeEnd") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk), parseInt(target.dataset.ftePk))
                    .then(() => {
                    that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                });
            }
            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            }
        });
        //This is called when clicking the back/forward buttons in the browser. This is using the History API
        window.addEventListener('popstate', (e) => {
            let query = e.state;
            let fteID = document.getElementById("searchFTEId");
            let building = document.getElementById("searchBuilding");
            let supportType = document.getElementById("searchSupportType");
            if (fteID != null) {
                if (query != null && query.FTEIdentifierName != null) {
                    fteID.value = query.FTEIdentifierName;
                }
                else {
                    fteID.value = "";
                }
            }
            if (building != null) {
                if (query != null && query.FRCPPInstitutionFK != null) {
                    building.value = query.FRCPPInstitutionFK.toString();
                }
                else {
                    building.selectedIndex = 0;
                }
            }
            if (supportType != null) {
                if (query != null && query.SupportTypeCodePK != null) {
                    supportType.value = query.SupportTypeCodePK.toString();
                }
                else {
                    supportType.selectedIndex = 0;
                }
            }
            that.handleClearDisabled();
            Core.showLoader();
            that.promiseSave()
                .then(() => {
                return that.search(true);
            })
                .then(() => {
                that.initializeFTEHashes();
                //reinitialize accordions
                new SpecialEducationIUProgramProfilesLazyAccordion();
                Core.hideLoader();
            })
                .catch((error) => {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
            });
        });
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    //Save method that returns a promise. If ftePK is passed, only the specified FTE will be saved. Otherwise, all FTE on page will be saved (if there are changes)
    promiseSave(ftePK = null, forceSave = false) {
        let that = this;
        let core = this._core;
        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData = [];
            let fteToSave;
            if (ftePK !== null) {
                fteToSave = document.querySelectorAll(`.fteContainer[data-fte-pk='${ftePK}']`);
            }
            else {
                //Get all the FTE on the page
                fteToSave = document.querySelectorAll(".fteContainer");
            }
            for (let fte of fteToSave) {
                let fteSupportsData = [];
                if ("ftePk" in fte.dataset && that.fteHasChanged(parseInt(fte.dataset.ftePk))) {
                    shouldSave = true;
                    let ftePK = fte.dataset.ftePk;
                    //Get FTEId
                    let educationProgramFTEId = null;
                    let fteID = fte.querySelector(`.fteID[data-fte-pk='${ftePK}']`);
                    if (fteID != null && fteID.value != "") {
                        educationProgramFTEId = fteID.value;
                    }
                    let classroomLocationFTE = null;
                    const classroomLocationFTEElement = fte.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`);
                    if (fteID != null && fteID.value != "") {
                        classroomLocationFTE = classroomLocationFTEElement.value;
                    }
                    let fullTime = null;
                    const fullTimeElement = fte.querySelector(`.fullTime[data-fte-pk='${ftePK}']`);
                    if (fteID != null && fteID.value != "") {
                        fullTime = fullTimeElement.value;
                    }
                    //Get supports
                    const supports = [];
                    const iuSupports = [];
                    const supportsForFTE = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
                    for (let support of supportsForFTE) {
                        let supportElement = support;
                        if ("supportPk" in supportElement.dataset) {
                            //FTEDescription
                            let FTEDescriptionText = "";
                            let description = supportElement.querySelector(`.description[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (description != null && description.value != "") {
                                FTEDescriptionText = description.value;
                            }
                            //supportTypeGroup
                            let supportTypeGroupCodeFK = null;
                            let supportTypeGroup = supportElement.querySelector(`.supportTypeGroup[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportTypeGroup != null && supportTypeGroup.value != "") {
                                supportTypeGroupCodeFK = parseInt(supportTypeGroup.value);
                            }
                            //supportType
                            let supportTypeCodeFK = null;
                            let supportType = supportElement.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportType != null && supportType.value != "") {
                                supportTypeCodeFK = parseInt(supportType.value);
                            }
                            //supportLevel
                            let supportLevelCodeFK = null;
                            let supportLevel = supportElement.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (supportLevel != null && supportLevel.value != "") {
                                supportLevelCodeFK = parseInt(supportLevel.value);
                            }
                            //caseLoad
                            let caseLoadCount = null;
                            let caseLoad = supportElement.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (caseLoad != null && caseLoad.value != "") {
                                caseLoadCount = parseInt(caseLoad.value);
                            }
                            //buildingType
                            let typeOfBuildingLookupCodeFK = null;
                            let buildingType = supportElement.querySelector(`.buildingType[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (buildingType != null && buildingType.value != "") {
                                typeOfBuildingLookupCodeFK = parseInt(buildingType.value);
                            }
                            //ageRangeFrom
                            let ageRangeFromNbr = null;
                            let ageRangeFrom = supportElement.querySelector(`.ageRangeFrom[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeFrom != null && ageRangeFrom.value != "") {
                                ageRangeFromNbr = parseInt(ageRangeFrom.value);
                            }
                            //ageRangeEnd
                            let ageRangeToNbr = null;
                            let ageRangeEnd = supportElement.querySelector(`.ageRangeEnd[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeEnd != null && ageRangeEnd.value != "") {
                                ageRangeToNbr = parseInt(ageRangeEnd.value);
                            }
                            //ageRangeJustification
                            let ageRangeJustificationText = "";
                            let ageRangeJustification = supportElement.querySelector(`.ageRangeJustification[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (ageRangeJustification != null && ageRangeJustification.value != "") {
                                ageRangeJustificationText = ageRangeJustification.value;
                            }
                            //OperatedByLookup
                            let operatedByLookupCodeFK = null;
                            let operatedBy = supportElement.querySelector(`.operatedBy[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (operatedBy != null && operatedBy.value != "") {
                                operatedByLookupCodeFK = parseInt(operatedBy.value);
                            }
                            //FundLookup
                            let fundLookupCodeFk = null;
                            let fund = supportElement.querySelector(`.fund[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (fund != null && fund.value != "") {
                                fundLookupCodeFk = parseInt(fund.value);
                            }
                            //LEAName
                            let leaName = null;
                            let leaNameEle = supportElement.querySelector(`.supportLEAName[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (leaNameEle != null && leaNameEle.value != "") {
                                leaName = leaNameEle.value;
                            }
                            //SchoolBuildingName
                            let schoolBuildingName = null;
                            let scholBuildingNameEle = supportElement.querySelector(`.supportSchoolBuildingName[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (scholBuildingNameEle != null && scholBuildingNameEle.value != "") {
                                schoolBuildingName = scholBuildingNameEle.value;
                            }
                            //ClassroomLocation
                            let classroomLocation = null;
                            let classroomLocationEle = supportElement.querySelector(`.supportClassroomLocation[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (classroomLocationEle != null && classroomLocationEle.value != "") {
                                classroomLocation = classroomLocationEle.value;
                            }
                            let identifyClassroomOther = null;
                            const identifyClassroomOtherEle = supportElement.querySelector(`.supportIdentifyClassroomOther[data-support-pk='${supportElement.dataset.supportPk}']`);
                            if (identifyClassroomOtherEle != null && identifyClassroomOtherEle.value != "") {
                                identifyClassroomOther = identifyClassroomOtherEle.value;
                            }
                            let fteSupportSaveData = {
                                AgeRangeFromNbr: ageRangeFromNbr,
                                AgeRangeJustification: ageRangeJustificationText,
                                AgeRangeToNbr: ageRangeToNbr,
                                CaseLoadCount: caseLoadCount,
                                ClassroomLocationLookupCodeFK: null,
                                ClassroomLookupCodeFK: null,
                                EducationProgramFTEFK: parseInt(ftePK),
                                EducationProgramFTESupportPK: parseInt(supportElement.dataset.supportPk),
                                FRCPPInstitutionFK: null,
                                SupportLevelCodeFK: supportLevelCodeFK,
                                SupportTypeCodeFK: supportTypeCodeFK,
                                UISelectedSupportTypeGroupCodeFK: supportTypeGroupCodeFK,
                            };
                            let iuSupportsSaveData = {
                                EducationProgramFTESupportIUPK: parseInt(supportElement.dataset.iusupportPk),
                                EducationProgramFTESupportFK: parseInt(supportElement.dataset.supportPk),
                                FTEDescription: FTEDescriptionText,
                                TypeOfBuildingLookupCodeFK: typeOfBuildingLookupCodeFK,
                                OperatedByLookupCodeFK: operatedByLookupCodeFK,
                                FundLookupCodeFk: fundLookupCodeFk,
                                LEAName: leaName,
                                SchoolBuildingName: schoolBuildingName,
                                ClassroomLocation: classroomLocation,
                                IdentifyClassroomOther: identifyClassroomOther
                            };
                            supports.push(fteSupportSaveData);
                            iuSupports.push(iuSupportsSaveData);
                        }
                    }
                    const fteSaveData = {
                        ClassroomLocationLookupCodeFK: classroomLocationFTE,
                        FullTimeLookupCodeFK: fullTime,
                        FTEIdentifierName: educationProgramFTEId,
                        EducationProgramFTEPK: parseInt(ftePK),
                        PlanFK: that.planFK
                    };
                    const thisFTESaveData = {
                        FTE: fteSaveData,
                        Supports: supports,
                        IUSupports: iuSupports
                    };
                    saveData.push(thisFTESaveData);
                }
            }
            if (shouldSave || forceSave) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/IUSpecialEducation/SaveEducationProgramIUFTE', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        if (ftePK != null) {
                            const json = JSON.parse(xhr.responseText);
                            const validData = json.validData;
                            const elementButton = document.querySelector(`.lazyAccordionTrigger[data-educationprogramftefk='${ftePK}']`);
                            elementButton.dataset.validdata = validData;
                            SpecialEducationIUProgramProfiles.getValidProgramCount();
                            SpecialEducationIUProgramProfiles.fteValidation(ftePK);
                            SpecialEducationIUProgramProfiles.fullPageValidation();
                            SpecialEducationIUProgramProfiles.planPageCompleteCheck();
                            resolve("Successfully saved FTE");
                        }
                        else {
                            resolve("Successfully saved");
                        }
                    }
                    else {
                        reject("There was an unexpected error saving");
                    }
                };
                xhr.send(JSON.stringify({
                    "Data": saveData,
                    "PlanPropertyData": [],
                    "PlanFK": that.planFK
                }));
            }
            else {
                resolve("Nothing To Save");
            }
        });
    }
    //Top level save method used by back/continue buttons, clicking on link, etc. Calls the promiseSave method and controls redirect.
    save(referrer, ftePK = null) {
        let that = this;
        let core = that._core;
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let forceSave = false;
        if (referrer === "save") {
            forceSave = true;
        }
        if (referrer !== "save" && this._core.checkSave(this) === true) {
            refreshPage = referrer;
            forceSave = true;
        }
        that.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();
            if (referrer === "save") {
                //Reset query so that all results show
                window.location.href = window.location.href.split('?')[0] + '?fromSave=true';
            }
            else if (refreshPage && refreshPage !== "") {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
    }
    //Creates a new FTE record in database and prepends partial view to DOM
    createNewFTE() {
        let that = this;
        return new Promise((resolve, reject) => {
            let newFTEID = document.getElementById("createNewFTEId");
            let newClassroomLocation = document.getElementById("createNewClassroomLocation");
            let newFullTime = document.getElementById("createNewFullTime");
            let data = {
                EducationProgramFTEPK: 0,
                ClassroomLocationLookupCodeFK: parseInt(newClassroomLocation.value),
                FullTimeLookupCodeFK: parseInt(newFullTime.value),
                FTEIdentifierName: newFTEID.value,
                PlanFK: that.planFK
            };
            let saveData = {
                FTE: data,
                AllowExceedsFTE: that.allowExceedsFTE
            };
            if (newFTEID != null && newFTEID.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/IUSpecialEducation/CreateNewIUFTE', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        let fteList = document.getElementById("fteContainerInner");
                        if (fteList != null) {
                            $(fteList).prepend(xhr.responseText);
                            newFTEID.value = "";
                            newClassroomLocation.selectedIndex = 0;
                            newFullTime.selectedIndex = 0;
                            let newFTEAccordion = fteList.querySelector(".Accordion-trigger");
                            if (newFTEAccordion != null)
                                new SpecialEducationIUProgramProfilesLazyAccordion(newFTEAccordion.id);
                            else
                                new SpecialEducationIUProgramProfilesLazyAccordion();
                            resolve("Successfully created new FTE");
                        }
                    }
                    else if (xhr.status === 409) {
                        reject("This FTE ID already exists, please use a unique name.");
                    }
                    else {
                        reject("There was an unexpected error creating new FTE");
                    }
                };
                xhr.send(JSON.stringify(data));
            }
            else {
                reject("All fields must have a value to create a new FTE");
            }
        });
    }
    //Creates a new support record in database and appends partial view to DOM
    createNewSupport(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/CreateNewIUSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let supportList = document.querySelector(`.supportsContainer[data-fte-pk='${ftePK}']`);
                    if (supportList != null) {
                        $(supportList).append(xhr.responseText);
                        resolve("Successfully added support");
                    }
                    else {
                        reject("There was an unexpected error adding the support");
                    }
                }
                else {
                    reject("There was an unexpected error creating new support");
                }
            };
            xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK);
        });
    }
    //Soft deletes support from database
    deleteSupport(supportPK) {
        let that = this;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteIUSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let supportContainer = document.querySelector(`.supportRow[data-support-pk='${supportPK}']`);
                    if (supportContainer != null) {
                        let deleteSupportButton = document.querySelector(`.deleteSupport[data-support-pk='${supportPK}']`);
                        let nextFocusable = Core.getNextFocusableElement(deleteSupportButton);
                        supportContainer.parentNode.removeChild(supportContainer);
                        nextFocusable.focus();
                        resolve("Successfully deleted support");
                    }
                    else {
                        reject("There was an unexpected error removing this support from the page");
                    }
                }
                else {
                    reject("There was an unexpected error deleting this support");
                }
            };
            xhr.send("supportPK=" + supportPK + "&planFK=" + that.planFK);
        });
    }
    //Determines whether to show the message that the FTE has no supports in the UI
    checkSupportCount(ftePK) {
        let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
        let noSupportsMessage = document.querySelector(`.noSupportsMessage[data-fte-pk='${ftePK}']`);
        if (noSupportsMessage != null) {
            if (supports.length > 0) {
                noSupportsMessage.classList.add("hide");
            }
            else {
                noSupportsMessage.classList.remove("hide");
            }
        }
    }
    //Soft deletes FTE record from database
    deleteFTE(ftePK) {
        let that = this;
        that.deleteFTEConfirmModal.hide();
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteIUFTE', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            return __awaiter(this, void 0, void 0, function* () {
                Core.hideLoader();
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let allFTEContainer = document.getElementById("fteContainerInner");
                    let fteContainer = document.querySelector(`.fteContainer[data-fte-pk='${ftePK}']`);
                    let accordion = Core.findClosest(fteContainer, ".lazyAccordion");
                    //Get next focusable accordion
                    let accordionTriggers = allFTEContainer.querySelectorAll(".lazy-accordion-trigger");
                    if (accordionTriggers.length > 1) {
                        let arr = Array.prototype.slice.call(accordionTriggers);
                        let thisAccordionTrigger = accordion.querySelector(".lazy-accordion-trigger");
                        if (thisAccordionTrigger != null) {
                            let index = arr.indexOf(thisAccordionTrigger);
                            if (index > -1) {
                                let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                                nextFocusable.focus();
                            }
                        }
                    }
                    else {
                        //Focus on create fte if no fte left
                        let create = document.getElementById("createNewFTEId");
                        if (create != null) {
                            create.focus();
                        }
                    }
                    if (accordion != null) {
                        accordion.parentNode.removeChild(accordion);
                        Core.createHTMLAlert("alertMessageDiv", "Successfully deleted FTE", 'success', 3000, null);
                        SpecialEducationIUProgramProfiles.clearValidationMessage();
                        yield SpecialEducationIUProgramProfiles.getValidProgramCount();
                        SpecialEducationIUProgramProfiles.fullPageValidation();
                        yield SpecialEducationIUProgramProfiles.planPageCompleteCheck();
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error removing this FTE from the page", 'error', 3000, null);
                    }
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE", 'error', 3000, null);
                }
            });
        };
        xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK);
    }
    //Refreshes the possible Support Types that can be selected in the UI based on the Support Type Group that was selected
    refreshSupportTypeSubList(supportPK) {
        let that = this;
        let supportTypeGroup = document.querySelector(`.supportTypeGroup[data-support-pk='${supportPK}']`);
        return new Promise((resolve, reject) => {
            if (supportTypeGroup != null) {
                if (supportTypeGroup.value != "") {
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/IUSpecialEducation/RefreshIUSupportTypeSubList', true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            const supportTypeListContainer = document.querySelector(`.supportTypesContainer[data-support-pk='${supportPK}']`);
                            //let supportTypeListContainer = document.getElementById('SupportTypesContainer' + '_' + supportPK);
                            if (supportTypeListContainer != null) {
                                supportTypeListContainer.innerHTML = xhr.responseText;
                                let supportTypeDropdown = supportTypeListContainer.querySelector(".supportType[data-forcerequired='true']");
                                if (supportTypeDropdown != null) {
                                    that._core.forceElementRequired(supportTypeDropdown);
                                }
                            }
                            resolve();
                        }
                        else {
                            reject("Error refreshing support types");
                        }
                    };
                    xhr.send(`supportPK=${supportPK}&ftePK=${supportTypeGroup.dataset.ftePk}&supportTypeGroupCodeFK=${supportTypeGroup.value}&templateFK=${that.templateFK}`);
                }
                else {
                    let container = document.querySelector(`.supportTypesContainer[data-support-pk='${supportTypeGroup.dataset.supportPk}']`);
                    if (container != null) {
                        container.innerHTML = "";
                    }
                    resolve();
                }
            }
            else {
                reject("Support type group not found");
            }
        });
    }
    //Refreshes the possible Support Levels that can be selected in the UI based on the Support Type that was selected
    refreshSupportLevels(supportPK) {
        let that = this;
        let currentLevelPK = null;
        let currentTypePK = null;
        let currentLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`);
        if (currentLevel != null && currentLevel.value != "") {
            currentLevelPK = currentLevel.value;
        }
        let currentType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`);
        if (currentType != null && currentType.value != "") {
            currentTypePK = currentType.value;
        }
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/RefreshSupportLevels', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let supportLevelListContainer = document.querySelector(`.supportLevelsContainer[data-support-pk='${supportPK}']`);
                    if (supportLevelListContainer != null) {
                        supportLevelListContainer.innerHTML = xhr.responseText;
                        let supportLevelDropdown = supportLevelListContainer.querySelector(".supportLevel[data-forcerequired='true']");
                        if (supportLevelDropdown != null) {
                            that._core.forceElementRequired(supportLevelDropdown);
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing support levels");
                }
            };
            xhr.send(`supportPK=${supportPK}&ftePK=${currentLevel.dataset.ftePk}&currentLevelPK=${currentLevelPK}&currentTypePK=${currentTypePK}&templateFK=${that.templateFK}`);
        });
    }
    //Shows the max possible caseload count in the UI given the currently selected Support Level and Type for the specified Support
    refreshMaxPossibleCaseLoad(supportPK) {
        let that = this;
        let supportType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`);
        let supportLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`);
        return new Promise((resolve, reject) => {
            if (supportType != null && supportType.value != "" && supportLevel != null && supportLevel.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/RefreshMaxPossibleCaseLoad', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    let jsonResponse = JSON.parse(xhr.response);
                    if (xhr.status === 200 && jsonResponse.success) {
                        let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`);
                        if (maxCaseLoadContainer != null) {
                            maxCaseLoadContainer.classList.remove("hide");
                        }
                        let maxCaseLoadElement = document.querySelector(`.maxCaseLoad[data-support-pk='${supportPK}']`);
                        if (maxCaseLoadElement != null) {
                            maxCaseLoadElement.innerHTML = jsonResponse.maxCaseLoadCount;
                        }
                        resolve();
                    }
                    else {
                        reject("Error refreshing max caseload");
                    }
                };
                xhr.send(`supportTypePK=${supportType.value}&supportLevelPK=${supportLevel.value}`);
            }
            else {
                let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`);
                if (maxCaseLoadContainer != null) {
                    maxCaseLoadContainer.classList.add("hide");
                }
            }
        });
    }
    //Refreshes the FTE for each support in the FTE and the FTE as a whole. If the FTE is over the limit, the error will display.
    refreshFTEPercentage(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];
            //Determine whether full-time/part-time
            //IU Special Education Plans don't take in to account part time positions.  So we set this equal to fulltime
            let fullTimeLookupCode = "fulltime";
            let fullTime = document.querySelector(`.fullTime[data-fte-pk='${ftePK}']`);
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }
            let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = support;
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;
                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }
                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }
                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }
                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;
                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-fte-pk='${ftePK}']`);
                    if (overOneMessage != null) {
                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }
                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        }
                        else {
                            overOneMessage.classList.add("hide");
                        }
                    }
                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`);
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        }
                        else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }
    //The classroom location for a support is dependent upon the selected classroom location for the FTE. This function handles this in the UI
    adjustSupportClassroomLocation(ftePK) {
        return new Promise((resolve, reject) => {
            let classroomLocationLookupCode = "";
            let fteClassroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`);
            if (fteClassroomLocation != null) {
                classroomLocationLookupCode = fteClassroomLocation.options[fteClassroomLocation.selectedIndex].dataset.lookupCode;
            }
            //Get the supports for this FTE
            let classroomLocationsSupport = document.querySelectorAll(`.classroomLocationSupport[data-fte-pk='${ftePK}']`);
            for (let classroomLocationDropdown of classroomLocationsSupport) {
                let classroomLocationDropdownElement = classroomLocationDropdown;
                if (classroomLocationLookupCode === "specialeducationlocationelementary" || classroomLocationLookupCode === "specialeducationlocationsecondary") {
                    for (var i = 0; i < classroomLocationDropdownElement.options.length; ++i) {
                        if (classroomLocationDropdownElement.options[i].dataset.lookupCode === classroomLocationLookupCode) {
                            classroomLocationDropdownElement.selectedIndex = i;
                            classroomLocationDropdownElement.disabled = true;
                            break;
                        }
                    }
                }
                else {
                    classroomLocationDropdownElement.disabled = false;
                }
            }
            resolve();
        });
    }
    //Based on the selection of "Classroom Location" and "Age Range", make the "Age Range Justification" optional or required
    checkAgeRangeJustification(supportPK, ftePk) {
        let that = this;
        return new Promise((resolve, reject) => {
            let makeRequired = false;
            let classroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePk}']`);
            let ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportPK}']`);
            let ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportPK}']`);
            if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
                if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {
                    let range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);
                    let classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;
                    switch (classroomLocationLookupCode) {
                        case 'specialeducationlocationelementary':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationsecondary':
                            if (range > 4) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationmultiple':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                    }
                }
            }
            let justification = document.querySelector(`.ageRangeJustification[data-support-pk='${supportPK}']`);
            if (justification != null) {
                if (makeRequired) {
                    that._core.forceElementRequired(justification);
                }
                else {
                    that._core.forceElementOptional(justification);
                }
            }
            resolve();
        });
    }
    //Shows a loader next to the FTE percentage while application figures out what the new percentage is
    toggleFTESupportLoader(supportPK, show) {
        let loaderContainer = document.querySelector(`.loaderContainer[data-support-pk='${supportPK}']`);
        if (loaderContainer != null) {
            if (show) {
                loaderContainer.classList.remove("hide");
            }
            else {
                loaderContainer.classList.add("hide");
            }
        }
    }
    //Searches FTE based on criteria and refreshes DOM with partial view. Also pushes history state so that back and continue buttons in browser work.
    search(fromHistory = false) {
        let that = this;
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        let fteIDValue = fteID != null ? fteID.value : "";
        let buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
        let supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;
        let query = {
            PlanFK: that.planFK,
            FTEIdentifierName: fteIDValue,
            BuildingTypePK: buildingValue,
            SupportTypeCodePK: supportTypeValue
        };
        let queryString = `?fteId=${query.FTEIdentifierName}&BuildingTypePK=${query.BuildingTypePK}&supportTypeCodeFK=${query.SupportTypeCodePK}`;
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/IUFTESearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("fteContainer");
                    if (container != null) {
                        container.innerHTML = xhr.responseText;
                        //Add to history so back button works
                        if (!fromHistory) {
                            history.pushState(query, '', queryString);
                        }
                        let searchResultsMessage = document.getElementById("searchResultsMessage");
                        if (searchResultsMessage != null) {
                            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
                            searchResultsMessage.focus();
                        }
                        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
                        if (exportToExcelFilteredButton != null) {
                            exportToExcelFilteredButton.classList.remove("hide");
                        }
                        resolve();
                    }
                    else {
                        reject("There was an error loading FTE");
                    }
                }
                else {
                    reject("There was an unexpected error during search");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }
    //Clears search fields, saves page (in case there were changes), and refreshes DOM
    clearSearch() {
        let that = this;
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        if (fteID != null) {
            fteID.value = "";
        }
        if (building != null) {
            building.selectedIndex = 0;
        }
        if (supportType != null) {
            supportType.selectedIndex = 0;
        }
        Core.showLoader();
        that.promiseSave()
            .then(() => {
            return that.search(true);
        })
            .then(() => {
            that.initializeFTEHashes();
            that.handleClearDisabled();
            that._core.initializeRequiredFields(that.validationClasses);
            //reinitialize accordions
            new SpecialEducationIUProgramProfilesLazyAccordion(null);
            Core.hideLoader();
            let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
            if (exportToExcelFilteredButton != null) {
                exportToExcelFilteredButton.classList.add("hide");
            }
        })
            .catch((error) => {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
        history.pushState(null, '', window.location.href.split('?')[0]);
        document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }
    //Calculates a hash for an FTE. This is used to determine whether the FTe has changed for saving purposes.
    calculateFTEHash(ftePK) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;
        let allElements = document.querySelectorAll(`[data-hashable][data-fte-pk='${ftePK}']`);
        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                let inputElement = element;
                if (inputElement.value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (inputElement.value !== "") {
                    value = inputElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLSelectElement) {
                let selectElement = element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLTextAreaElement) {
                let textAreaElement = element;
                if (textAreaElement.value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
        }
        return hash;
    }
    //Determines whether an FTE has changed by comparing the current hash value to a prior hash value
    fteHasChanged(ftePK) {
        let that = this;
        let changed = false;
        if (that.fteHashes[ftePK] !== undefined) {
            let newHash = that.calculateFTEHash(ftePK);
            if (newHash !== that.fteHashes[ftePK]) {
                changed = true;
            }
        }
        else {
            //Be on the safe side and say it has changed
            changed = true;
        }
        return changed;
    }
    //Calculates a hash value for each FTE on the page and is stored in an object as a property of this class.
    initializeFTEHashes() {
        let that = this;
        that.fteHashes = {};
        let fteContainers = document.getElementsByClassName("fteContainer");
        for (let fte of fteContainers) {
            let ftePK = parseInt(fte.dataset.ftePk);
            let hash = that.calculateFTEHash(ftePK);
            that.fteHashes[ftePK] = hash;
        }
    }
    //The save button only becomes available for an FTE if there is a change within the FTE
    handleFTESaveDisabled(ftePK) {
        let saveButton = document.querySelector(`.saveFTEButton[data-fte-pk='${ftePK}']`);
        if (saveButton != null) {
            if (this.fteHasChanged(ftePK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            }
            else {
                saveButton.disabled = true;
            }
        }
    }
    //Determines whether the clear button should become enabled
    handleClearDisabled() {
        let fteID = document.getElementById("searchFTEId");
        let building = document.getElementById("searchBuilding");
        let supportType = document.getElementById("searchSupportType");
        let clearButton = document.getElementById("searchClear");
        if (fteID != null && building != null && supportType != null && clearButton != null) {
            if (fteID.value !== "" || building.selectedIndex > 0 || supportType.selectedIndex > 0) {
                clearButton.disabled = false;
            }
            else {
                clearButton.disabled = true;
            }
        }
    }
    //Custom client-side validation for the page
    customValidation() {
        let that = this;
        let errorCount = 0;
        let allElements = document.querySelectorAll("[data-forcerequired='true']");
        for (let element of allElements) {
            let htmlElement = element;
            if (htmlElement instanceof HTMLInputElement) {
                let inputElement = htmlElement;
                if (inputElement.value === "") {
                    inputElement.classList.add("missing-field");
                    inputElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(inputElement);
                    errorCount++;
                }
            }
            else if (htmlElement instanceof HTMLSelectElement) {
                let selectElement = htmlElement;
                if (selectElement.selectedIndex === 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    errorCount++;
                }
            }
            else if (htmlElement instanceof HTMLTextAreaElement) {
                let textAreaElement = htmlElement;
                if (textAreaElement.value === "") {
                    textAreaElement.classList.add("missing-field");
                    textAreaElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(textAreaElement);
                    errorCount++;
                }
            }
        }
        //See if any over 1 FTE
        let visibleFTEErrors = document.querySelectorAll(".overOneFTEMessage:not(.hide)");
        errorCount += visibleFTEErrors.length;
        if (errorCount > 0) {
            //Set text for error message
            let message = document.getElementById("validationMessage");
            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            }
            else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");
            if (goToError !== null) {
                let firstFocusableEl = document.querySelector(".missing-field, .overOneFTEMessage:not(.hide)");
                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                }
                else {
                    goToError.parentNode.removeChild(goToError);
                }
            }
            this._core.doValidation(null, true);
        }
        else {
            this._core.doValidation(null, false);
        }
    }
    //Exports FTE and support data to excel. If they click the Filtered button, the data in the Excel file will be filtered based on search criteria
    exportToExcel(filtered = false) {
        let that = this;
        return new Promise((resolve, reject) => {
            let fteID = document.getElementById("searchFTEId");
            let building = document.getElementById("searchBuilding");
            let supportType = document.getElementById("searchSupportType");
            let fteIDValue = "";
            let buildingValue = null;
            let supportTypeValue = null;
            if (filtered) {
                fteIDValue = fteID != null ? fteID.value : "";
                buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
                supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;
            }
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/SpecialEducationIUProgramProfileExcelExport?planFK=${that.planFK}&fteId=${fteIDValue}&frcppInstitutionFK=${buildingValue}&supportTypeCodeFK=${supportTypeValue}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "EducationProgramFTE.xlsx";
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send();
        });
    }
    //Handle the change of the Identify Classroom ("operatedby") dropdown. Each selection will hide/show fields.
    static changeOperatedByIdentifyClassroom(e) {
        const element = e;
        const selected = element[element.selectedIndex];
        const supportFK = element.dataset.supportPk;
        const classroomLocationContainer = document.querySelector(`.supportClassroomLocationContainer[data-support-pk='${supportFK}']`);
        const leaNameContainer = document.querySelector(`.supportLEANameContainer[data-support-pk='${supportFK}']`);
        const schoolBuildingNameContainer = document.querySelector(`.supportSchoolBuildingNameContainer[data-support-pk='${supportFK}']`);
        const identifyClassroomOtherContainer = document.querySelector(`.supportIdentifyClassroomOtherContainer[data-support-pk='${supportFK}']`);
        const classroomLocation = document.querySelector(`.supportClassroomLocation[data-support-pk='${supportFK}']`);
        const leaName = document.querySelector(`.supportLEAName[data-support-pk='${supportFK}']`);
        const schoolBuildingName = document.querySelector(`.supportSchoolBuildingName[data-support-pk='${supportFK}']`);
        const identifyClassroomOther = document.querySelector(`.supportIdentifyClassroomOther[data-support-pk='${supportFK}']`);
        if (selected.dataset.lookupcode && selected.dataset.lookupcode === "iuSpecialEducationIdentifyClassroomLEA") { //
            classroomLocationContainer.classList.add("hide");
            leaNameContainer.classList.remove("hide");
            schoolBuildingNameContainer.classList.remove("hide");
            identifyClassroomOtherContainer.classList.add("hide");
            this._staticCore.forceElementOptional(classroomLocation);
            this._staticCore.forceElementRequired(leaName);
            this._staticCore.forceElementRequired(schoolBuildingName);
            this._staticCore.forceElementOptional(identifyClassroomOther);
        }
        else if (selected.dataset.lookupcode && selected.dataset.lookupcode === "iuSpecialEducationIdentifyClassroomIU") {
            classroomLocationContainer.classList.remove("hide");
            leaNameContainer.classList.add("hide");
            schoolBuildingNameContainer.classList.add("hide");
            identifyClassroomOtherContainer.classList.add("hide");
            this._staticCore.forceElementRequired(classroomLocation);
            this._staticCore.forceElementOptional(leaName);
            this._staticCore.forceElementOptional(schoolBuildingName);
            this._staticCore.forceElementOptional(identifyClassroomOther);
        }
        else if (selected.dataset.lookupcode && selected.dataset.lookupcode === "iuSpecialEducationIdentifyClassroomOther") {
            classroomLocationContainer.classList.add("hide");
            leaNameContainer.classList.add("hide");
            schoolBuildingNameContainer.classList.add("hide");
            identifyClassroomOtherContainer.classList.remove("hide");
            this._staticCore.forceElementOptional(classroomLocation);
            this._staticCore.forceElementOptional(leaName);
            this._staticCore.forceElementOptional(schoolBuildingName);
            this._staticCore.forceElementRequired(identifyClassroomOther);
        }
        else {
            classroomLocationContainer.classList.add("hide");
            leaNameContainer.classList.add("hide");
            schoolBuildingNameContainer.classList.add("hide");
            identifyClassroomOtherContainer.classList.add("hide");
            this._staticCore.forceElementOptional(classroomLocation);
            this._staticCore.forceElementOptional(leaName);
            this._staticCore.forceElementOptional(schoolBuildingName);
            this._staticCore.forceElementOptional(identifyClassroomOther);
        }
    }
    static checkOperatedByIdentifyClassroom(educationProgramFTEFK) {
        const allOperatedBySelects = document.querySelectorAll(`.operatedBy[data-fte-pk='${educationProgramFTEFK}']`);
        for (const operatedBySelect of allOperatedBySelects) {
            SpecialEducationIUProgramProfiles.changeOperatedByIdentifyClassroom(operatedBySelect);
        }
    }
    static fteValidation(educationProgramFTEFK) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);
        const textareaElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] textarea`);
        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    }
                    else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }
        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    }
                    else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }
        for (const elementInside of textareaElements) {
            if (elementInside instanceof HTMLTextAreaElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    }
                    else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }
        this.updateFTEPercentage(educationProgramFTEFK);
    }
    static updateFTEPercentage(ftePK) {
        let that = this;
        return new Promise((resolve, reject) => {
            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];
            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-fte-pk='${ftePK}']`);
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }
            let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = support;
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`);
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`);
                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;
                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }
                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }
                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }
                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;
                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-fte-pk='${ftePK}']`);
                    if (overOneMessage != null) {
                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }
                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        }
                        else {
                            overOneMessage.classList.add("hide");
                        }
                    }
                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`);
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        }
                        else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }
                    resolve();
                }
                else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }
    static initializeLoadedFTE(educationProgramFTEFK) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);
        const textAreaElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] textarea`);
        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        for (const elementInside of textAreaElements) {
            if (elementInside instanceof HTMLTextAreaElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        this.updateFTEPercentage(educationProgramFTEFK);
    }
    static fullPageValidation() {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const totalErrorsNotShown = totalErrors - shownAccordions.length;
        let errorCount = 0;
        for (const accordion of allAccordions) {
            const accElement = accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;
            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            }
            else {
                accElement.classList.remove("error");
            }
        }
        const messageContainer = document.getElementById("validationMessageContainer");
        const validationIcon = document.getElementById("validationMessageIcon");
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.add("show");
        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");
        const message = document.getElementById("validationMessage");
        if (errorCount > 0) {
            let word = "are";
            let facility = "FTEs";
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "FTE";
                error = "error";
            }
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            let facilityWord = "are";
            let facilityFacility = "FTEs";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "FTE";
            }
            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            if (message !== null) {
                message.innerHTML = "The page has been successfully saved.";
                message.classList.add("show");
            }
        }
    }
    static clearValidationMessage() {
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }
    static getValidProgramCount() {
        return __awaiter(this, void 0, void 0, function* () {
            const planForm = document.getElementById("specialEducationEducationProgramCaseloadIUFTEForm");
            const planFK = planForm.dataset.planfk;
            const response = yield fetch(`/IUSpecialEducation/GetValidProgramFTECountAsync/${planFK}`, { credentials: 'include' });
            if (response.ok) {
                const value = yield response.text();
                const field = document.getElementById("validCount");
                field.value = value;
            }
        });
    }
    static searchValidation() {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const totalErrorsNotShown = totalErrors - shownAccordions.length;
        let errorCount = 0;
        for (const accordion of allAccordions) {
            const accElement = accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;
            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            }
            else {
                accElement.classList.remove("error");
            }
        }
        const messageContainer = document.getElementById("validationMessageContainer");
        const validationIcon = document.getElementById("validationMessageIcon");
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.add("show");
        if (errorCount > 0) {
            const message = document.getElementById("validationMessage");
            let word = "are";
            let facility = "facilities";
            let error = "issues";
            if (errorCount === 1) {
                word = "is";
                facility = "facility";
            }
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            if (errorCount < totalErrors) {
                let facilityWord = "are";
                let facilityFacility = "facilities";
                let facilityError = "issues";
                if (totalErrorsNotShown === 1) {
                    facilityWord = "is";
                    facilityFacility = "facility";
                }
                message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            }
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else if (errorCount === 0 && totalErrorsNotShown > 0) {
            const message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.innerHTML += `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            let facilityWord = "are";
            let facilityFacility = "facilities";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "facility";
            }
            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            const successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    static planPageCompleteCheck() {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById("specialEducationEducationProgramCaseloadIUFTEForm");
            const planFK = form.dataset.planfk;
            const pageCode = form.dataset.pagecode;
            const response = yield fetch(`/IUSpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' });
            if (response.ok) {
                const value = yield response.json();
                const menuElement = document.querySelector("#leftBarspecialEducationIUProgramProfilesParent .status-indicator");
                if (value.thisPage) {
                    menuElement.classList.add("complete");
                }
                else {
                    menuElement.classList.remove("complete");
                }
            }
        });
    }
}
// SpecialEducationIUOpServices
class SpecialEducationIUOpServices {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUOpServicesField"];
        let specialEducationIUOpServicesSaveButton = document.getElementById("specialEducationIUOpServicesSave");
        if (specialEducationIUOpServicesSaveButton !== null)
            specialEducationIUOpServicesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUOpServicesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUOpServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUOpServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SpecialEducationIUOperated
class SpecialEducationIUOperated {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUOperatedField"];
        let specialEducationIUOperatedSaveButton = document.getElementById("specialEducationIUOperatedSave");
        if (specialEducationIUOperatedSaveButton !== null)
            specialEducationIUOperatedSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUOperatedForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUOperatedField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUOperated', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SpecialEducationIUIUSupportStaff
class SpecialEducationIUIUSupportStaff {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUIUSupportStaffField", "specialEducationIUIUSupportStaffSelectField"];
        let specialEducationIUIUSupportStaffSaveButton = document.getElementById("specialEducationIUIUSupportStaffSave");
        if (specialEducationIUIUSupportStaffSaveButton !== null)
            specialEducationIUIUSupportStaffSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const createSupportService = document.getElementById("createSupportService");
        if (createSupportService !== null)
            createSupportService.addEventListener("click", (e) => this.addNewSupportService());
        const specialEducationIUIUSupportStaffLocationDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteConfirm");
        if (specialEducationIUIUSupportStaffLocationDeleteConfirm !== null)
            specialEducationIUIUSupportStaffLocationDeleteConfirm.addEventListener("click", (e) => this.deleteLocationConfirm(e.target));
        const specialEducationIUIUSupportStaffLocationDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteCancel");
        if (specialEducationIUIUSupportStaffLocationDeleteCancel !== null)
            specialEducationIUIUSupportStaffLocationDeleteCancel.addEventListener("click", (e) => this.deleteLocationCancel());
        const specialEducationIUIUSupportStaffSupportDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteConfirm");
        if (specialEducationIUIUSupportStaffSupportDeleteConfirm !== null)
            specialEducationIUIUSupportStaffSupportDeleteConfirm.addEventListener("click", (e) => this.deleteSupportConfirm(e.target));
        const specialEducationIUIUSupportStaffSupportDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteCancel");
        if (specialEducationIUIUSupportStaffSupportDeleteCancel !== null)
            specialEducationIUIUSupportStaffSupportDeleteCancel.addEventListener("click", (e) => this.deleteSupportCancel());
        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();
        this.setupLocationOtherDropdowns();
        this.bindAddLocationButtons();
        this.bindDeleteLocationItems();
        this.bindAddSupportRowButtons();
        this.bindDeleteSupports();
        const specialEducationIUIUSupportStaffExportToExcel = document.getElementById("specialEducationIUIUSupportStaffExportToExcel");
        if (specialEducationIUIUSupportStaffExportToExcel !== null) {
            specialEducationIUIUSupportStaffExportToExcel.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        const supportStaffServicesSearchButton = document.getElementById("supportStaffServicesSearchButton");
        supportStaffServicesSearchButton.addEventListener("click", (e) => this.search());
        const supportStaffServicesClearButton = document.getElementById("supportStaffServicesClearButton");
        supportStaffServicesClearButton.addEventListener("click", (e) => this.clearSearch());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const saveData = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUIUSupportStaffForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const accordions = document.querySelectorAll("button.supportStaffServices");
        for (const accordion of accordions) {
            const accordionElement = accordion;
            const dataPointPlanPropertyPK = accordionElement.dataset.datapointplanpropertypk;
            const fteRows = [];
            const rows = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectWithOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const select of rows) {
                const allSaveElements = [];
                const element = select;
                const row = element.dataset.row;
                let selects = false;
                let inputs = false;
                let locations = false;
                const specialEducationIUIUSupportStaffSelectForSaveFields = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffSelectForSaveField of specialEducationIUIUSupportStaffSelectForSaveFields) {
                    let planPropertyPK = 0;
                    let element = specialEducationIUIUSupportStaffSelectForSaveField;
                    let rowNumber = element.dataset.row;
                    let propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (element.value !== "0" || hadValue) {
                        const saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: null,
                            LookupCodeFK: parseInt(element.value),
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };
                        allSaveElements.push(saveItem);
                        selects = true;
                    }
                }
                const specialEducationIUIUSupportStaffForSaveFields = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffForSaveField of specialEducationIUIUSupportStaffForSaveFields) {
                    let planPropertyPK = 0;
                    const element = specialEducationIUIUSupportStaffForSaveField;
                    const rowNumber = element.dataset.row;
                    const propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (element.value !== "" || hadValue) {
                        const saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: element.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };
                        allSaveElements.push(saveItem);
                        inputs = true;
                    }
                }
                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const locationItem of locationItems) {
                    const name = locationItem.querySelector(`.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const rowNumber = name.dataset.row;
                    const propertyPK = name.dataset.propertypk;
                    let planPropertyPK = 0;
                    if (name.dataset.planpropertypk && name.dataset.planpropertypk !== "") {
                        planPropertyPK = parseInt(name.dataset.planpropertypk);
                    }
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: parseInt(propertyPK),
                        TextValue: name.textContent,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                    locations = true;
                }
                const rowToSave = {
                    "SaveElements": allSaveElements
                };
                if (selects || inputs || locations)
                    fteRows.push(rowToSave);
            }
            const thisAccordion = {
                "PlanFK": planPK,
                "FTERows": fteRows
            };
            saveData.push(thisAccordion);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUIUSupportStaff', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            }
        };
        if (saveData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUIUSupportStaffSelectWithOtherField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }
    setupLocationOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUIUSupportLocationSelectField");
        for (let selectElement of selectElements)
            this.locationDropdownChange(selectElement);
    }
    showDeleteRow(e) {
        const deleteButton = e.target;
        const row = deleteButton.dataset.row;
        const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
        const modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }
    bindDeleteProgramRows() {
        const allDeletes = document.getElementsByClassName("deleteSupportStaffRow");
        for (const del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    bindOtherDropdown() {
        const selectElements = document.getElementsByClassName("specialEducationIUIUSupportStaffSelectWithOtherField");
        for (const selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.dropdownChange(e.target));
        const locationSelectElements = document.getElementsByClassName("specialEducationIUIUSupportLocationSelectField");
        for (const selectElement of locationSelectElements)
            selectElement.addEventListener("change", (e) => this.locationDropdownChange(e.target));
    }
    bindAddSupportRowButtons() {
        const iuSpecialEducationFTEsAllocatedAddRowButtons = document.getElementsByClassName("iuSpecialEducationSupportStaffAddRow");
        for (const iuSpecialEducationFTEsAllocatedAddRowButton of iuSpecialEducationFTEsAllocatedAddRowButtons)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e) => this.addRow(e));
    }
    dropdownChange(e) {
        const selectElement = e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;
        const selectContainer = document.querySelector(`.buildingContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = document.querySelector(`.otherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = document.querySelector(`.specialEducationIUSupportStaffOtherField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        }
        else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }
    locationDropdownChange(e) {
        const selectElement = e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;
        const selectContainer = document.querySelector(`.locationContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = document.querySelector(`.locationOtherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = document.querySelector(`.specialEducationIUIUSupportLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        }
        else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }
    addRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const numberOfRows = 1;
            const formElement = document.getElementById("specialEducationIUIUSupportStaffForm");
            const button = e.target;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                const planFK = formElement.dataset.planfk;
                const that = this;
                let newRow = 0;
                const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/AddSupportStaffAndServicesRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        const newDiv = document.createElement("div");
                        newDiv.classList.add("operatedServiceRow");
                        newDiv.classList.add("operated-service-row");
                        newDiv.classList.add("margin-bottom-large");
                        newDiv.dataset.row = newRow.toString();
                        newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK.toString();
                        newDiv.innerHTML = output;
                        const accordion = document.querySelector(`.supportStaffList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        accordion.appendChild(newDiv);
                        this.bindAddLocationButtons();
                        this.bindOtherDropdown();
                        this.bindDeleteProgramRows();
                    }
                }
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The row was successfully added.", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", 'An error occured adding this row. Please try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        const buttonElement = e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const allElements = document.querySelectorAll(`.specialEducationIUSupportField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allElements) {
            const element = ele;
            const planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        const allLocations = document.querySelectorAll(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allLocations) {
            const element = ele;
            const planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowDataWithRelated', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    addNewSupportService() {
        return __awaiter(this, void 0, void 0, function* () {
            let staffServiceLookupCodeFK = 0;
            let fteId = "";
            const specialEducationIUIUSupportStaffForm = document.getElementById("specialEducationIUIUSupportStaffForm");
            const planFK = specialEducationIUIUSupportStaffForm.dataset.planfk;
            const specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices");
            if (specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices !== null) {
                const selectElement = specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices;
                const lookupCodeFK = selectElement.value;
                staffServiceLookupCodeFK = parseInt(lookupCodeFK);
            }
            const specialEducationIUProfilesIUCreateSupportServiceIUFTEId = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUFTEId");
            if (specialEducationIUProfilesIUCreateSupportServiceIUFTEId !== null) {
                const inputElement = specialEducationIUProfilesIUCreateSupportServiceIUFTEId;
                const textValue = inputElement.value;
                fteId = textValue;
            }
            if (fteId === "" || staffServiceLookupCodeFK === 0) {
                Core.createHTMLAlert("alertMessageDiv", "To add a record, enter a value in the FTE ID field and select a value from the IU Support Staff/Services dropdown.", 'warning', 3000, null);
            }
            else {
                Core.showLoader();
                const dataModel = {
                    "PlanFK": planFK,
                    "FTEID": fteId,
                    "StaffServiceLookupCodeFK": staffServiceLookupCodeFK
                };
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };
                const response = yield fetch(`/IUSpecialEducation/AddSupportStaffAndServicesAccordion`, settings);
                if (response.ok) {
                    const output = yield response.text();
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = output;
                    let container = document.getElementById("iuOperatedServices");
                    container.append(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    const specialEducationIUProfilesIUCreateSupportServiceIUFTEId = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUFTEId");
                    if (specialEducationIUProfilesIUCreateSupportServiceIUFTEId !== null) {
                        specialEducationIUProfilesIUCreateSupportServiceIUFTEId.value = "";
                    }
                    const specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices");
                    if (specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices !== null) {
                        specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices.selectedIndex = 0;
                    }
                    this.bindAddLocationButtons();
                    this.bindAddSupportRowButtons();
                    this.bindDeleteSupports();
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The IU Support/Services record has been added.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding this record. Please try again.", 'error', 3000, null);
                }
            }
        });
    }
    bindAddLocationButtons() {
        const addLocation = document.getElementsByClassName("addLocation");
        for (const location of addLocation) {
            location.addEventListener("click", (e) => this.addLocation(e));
        }
    }
    addLocation(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const row = button.dataset.row;
            const propertyPK = button.dataset.propertypk;
            const form = document.getElementById("specialEducationIUIUSupportStaffForm");
            const planFK = form.dataset.planfk;
            const locationDropdown = document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const locationOtherInput = document.querySelector(`.specialEducationIUIUSupportLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (locationDropdown.value === "0") {
                Core.createHTMLAlert("alertMessageDiv", "Please select a location to add.", 'warning', 3000, null);
            }
            else {
                let locationText = "";
                let proceed = true;
                const locationDropdownOption = locationDropdown[locationDropdown.selectedIndex];
                locationText = locationDropdownOption.text;
                if (locationDropdownOption.dataset.lookupcode === "specialEducationIUSupportStaffLocationOther") {
                    locationText = locationOtherInput.value;
                }
                if (locationDropdownOption.dataset.lookupcode === "specialEducationIUSupportStaffLocationOther" && locationText === "") {
                    proceed = false;
                    Core.createHTMLAlert("alertMessageDiv", "Please enter a value for 'Other'.", 'error', 3000, null);
                }
                const potentialDupes = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}'] .name`);
                for (const potential of potentialDupes) {
                    const span = potential;
                    if (span.textContent.toLowerCase() === locationText.toLowerCase()) {
                        proceed = false;
                        Core.createHTMLAlert("alertMessageDiv", "A location with this name has already been added. Please choose a different one.", 'error', 3000, null);
                    }
                }
                if (proceed) {
                    const dataModel = {
                        "PlanFK": planFK,
                        "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                        "Row": row,
                        "Location": locationText,
                        "PlanPropertyPK": 0,
                        "PropertyPK": propertyPK
                    };
                    const settings = {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(dataModel)
                    };
                    const response = yield fetch(`/IUSpecialEducation/AddSupportStaffAndServicesLocation`, settings);
                    if (response.ok) {
                        const output = yield response.text();
                        const newDiv = document.createElement("div");
                        newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                        newDiv.dataset.planpropertypk = "0";
                        newDiv.dataset.row = row;
                        newDiv.classList.add("location-list-item");
                        newDiv.classList.add("locationItem");
                        newDiv.classList.add("medium-4");
                        newDiv.classList.add("column");
                        newDiv.classList.add("end");
                        newDiv.innerHTML = output;
                        const locationList = document.querySelector(`.locationList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        locationList.appendChild(newDiv);
                        this.bindDeleteLocationItems();
                        locationOtherInput.value = "";
                        locationDropdown.selectedIndex = 0;
                        this.locationDropdownChange(locationDropdown);
                        Core.createHTMLAlert("alertMessageDiv", "Location added.", 'success', 3000, null);
                    }
                }
            }
        });
    }
    bindDeleteLocationItems() {
        const deleteButtons = document.getElementsByClassName("deleteStaffLocationItem");
        for (const deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.deleteLocationItem(e));
        }
    }
    deleteLocationItem(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = parseInt(button.dataset.planpropertypk);
        const row = button.dataset.row;
        const item = document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);
        if (planPropertyPK === 0) {
            item.remove();
        }
        else {
            this.showDeleteLocation(button);
        }
    }
    showDeleteLocation(button) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const row = button.dataset.row;
        const deleteLocationModal = new Modal("deleteLocationModal", null);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "row", row);
        deleteLocationModal.show();
    }
    deleteLocationCancel() {
        const deleteLocationModal = new Modal("deleteLocationModal", null);
        deleteLocationModal.hide();
    }
    deleteLocationConfirm(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const planPropertyPK = button.dataset.planpropertypk;
            const row = button.dataset.row;
            const deleteLocationModal = new Modal("deleteLocationModal", null);
            deleteLocationModal.hide();
            Core.showLoader();
            const item = document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);
            const response = yield fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesLocation/${dataPointPlanPropertyPK}/${planPropertyPK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const json = yield response.json();
                Core.hideLoader();
                if (json.success) {
                    item.remove();
                    Core.createHTMLAlert("alertMessageDiv", "The location was successfully removed.", 'success', 3000, null);
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
            }
        });
    }
    bindDeleteSupports() {
        const iuSpecialEducationSupportStaffDeleteSupports = document.getElementsByClassName("iuSpecialEducationSupportStaffDeleteSupport");
        for (const iuSpecialEducationSupportStaffDeleteSupport of iuSpecialEducationSupportStaffDeleteSupports)
            iuSpecialEducationSupportStaffDeleteSupport.addEventListener("click", (e) => this.showDeleteSupport(e.target));
    }
    showDeleteSupport(button) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const deleteSupportModal = new Modal("deleteSupportModal", null);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteSupportModal.show();
    }
    deleteSupportCancel() {
        const deleteSupportModal = new Modal("deleteSupportModal", null);
        deleteSupportModal.hide();
    }
    deleteSupportConfirm(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const planPropertyPK = button.dataset.planpropertypk;
            const accordion = document.querySelector(`.Accordion-panel.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const accordionTrigger = document.querySelector(`.Accordion-trigger.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const accordionHeader = accordionTrigger.parentElement;
            const response = yield fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesSupport/${dataPointPlanPropertyPK}`, { credentials: 'include' });
            if (response.ok) {
                const json = yield response.json();
                if (json.success) {
                    accordion.remove();
                    accordionHeader.remove();
                    Core.createHTMLAlert("alertMessageDiv", "The Support was successfully removed.", 'success', 3000, null);
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the Support record, please try again.", 'error', 3000, null);
                const deleteSupportModal = new Modal("deleteSupportModal", null);
                deleteSupportModal.hide();
            }
        });
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const allAccordions = document.querySelectorAll(".Accordion-panel.supportStaffServices");
        for (const accordion of allAccordions) {
            const accordionEle = accordion;
            const dataPointPlanPropertyPK = accordionEle.dataset.datapointplanpropertypk;
            const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            let hasAtLeastOneRow = false;
            for (const thisRow of allRows) {
                let hasSomeValue = false;
                const ele = thisRow;
                const row = ele.dataset.row;
                const selects = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = select;
                    const selectValue = parseInt(selectEle.value);
                    if (!isNaN(selectValue) && selectValue > 0)
                        hasSomeValue = true;
                }
                if (!hasSomeValue) {
                    const inputs = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const input of inputs) {
                        const inputElement = input;
                        if (inputElement.value !== "" && inputElement.value.trim() !== "")
                            hasSomeValue = true;
                    }
                    if (!hasSomeValue) {
                        const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        if (locationItems && locationItems.length > 0)
                            hasSomeValue = true;
                    }
                }
                else {
                    //There is at least one item filled out. We need to make sure everything is filled out in this row now.
                    hasAtLeastOneRow = true;
                    const selects = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const select of selects) {
                        const selectEle = select;
                        const selectValue = parseInt(selectEle.value);
                        if (isNaN(selectValue) || selectValue === 0) {
                            selectEle.classList.add("missing-field");
                            selectEle.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectEle);
                            showMessage = true;
                            totalErrors++;
                        }
                        if (selectEle.hasAttribute("data-hasother")) {
                            const selected = selectEle[selectEle.selectedIndex];
                            if (selected.dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                                const otherField = document.querySelector(`.specialEducationIUSupportStaffOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                                if (otherField.value === "" || otherField.value.trim() === "") {
                                    otherField.classList.add("missing-field");
                                    otherField.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(otherField);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                    const inputs = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const input of inputs) {
                        const inputElement = input;
                        if (!inputElement.hasAttribute("data-other") && inputElement.value === "" && inputElement.value.trim() === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    if (locationItems === null || locationItems.length === 0) {
                        const specialEducationIUContractLocationSelectField = document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        if (specialEducationIUContractLocationSelectField !== null) {
                            specialEducationIUContractLocationSelectField.classList.add("missing-field");
                            specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
            //If there isn't at least one row filled out for this (each) accordion, we need to flag it as incomplete.
            if (!hasAtLeastOneRow) {
                const firstRow = allRows[0];
                const row = firstRow.dataset.row;
                const selects = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = select;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }
                const locationSelect = document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if (locationSelect !== null) {
                    const selectEle = locationSelect;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }
                const inputs = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const input of inputs) {
                    const inputElement = input;
                    inputElement.classList.add("missing-field");
                    inputElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(inputElement);
                    showMessage = true;
                    totalErrors++;
                }
                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if (locationItems && locationItems.length > 0) {
                    const specialEducationIUContractLocationSelectField = document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    specialEducationIUContractLocationSelectField.classList.add("missing-field");
                    specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUIUSupportStaffForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUIUOperatedServicesProfileExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUIUOperatedServicesProfile.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    search() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("specialEducationIUIUSupportStaffForm");
            const planFK = form.dataset.planfk;
            const pageCode = form.dataset.pagecode;
            let fteId = "";
            let supportStaffServices = 0;
            const specialEducationIUProfilesIUSearchSupportServiceIUFTEId = document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUFTEId");
            if (specialEducationIUProfilesIUSearchSupportServiceIUFTEId !== null)
                fteId = specialEducationIUProfilesIUSearchSupportServiceIUFTEId.value;
            const specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices = document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices");
            if (specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices !== null)
                supportStaffServices = parseInt(specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices.value);
            //Need the title, lookup, planfk and pagecode and a new method to do the search and replace the accordions in the container.
            const dataModel = {
                "PlanFK": planFK,
                "AccordionTitle": fteId,
                "PageCode": pageCode,
                "SupportStaffServicesLookupCodeFK": supportStaffServices
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            const response = yield fetch(`/IUSpecialEducation/SearchSupportStaffAndServices`, settings);
            if (response.ok) {
                const output = yield response.text();
                const iuOperatedServices = document.getElementById("iuOperatedServices");
                iuOperatedServices.innerHTML = output;
                specialEducationIUProfilesIUSearchSupportServiceIUFTEId.value = "";
                specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices.value = "0";
                Core.hideLoader();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue with the search, please try again.", 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    clearSearch() {
        return __awaiter(this, void 0, void 0, function* () {
            const specialEducationIUProfilesIUSearchSupportServiceIUFTEId = document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUFTEId");
            if (specialEducationIUProfilesIUSearchSupportServiceIUFTEId !== null)
                specialEducationIUProfilesIUSearchSupportServiceIUFTEId.value = "";
            const specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices = document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices");
            if (specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices !== null)
                specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices.value = "0";
            yield this.search();
        });
    }
}
// SpecialEducationIUContractStaff
class SpecialEducationIUContractStaff {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUContractStaffField", "specialEducationIUContractStaffSelectField"];
        let specialEducationIUContractStaffSaveButton = document.getElementById("specialEducationIUContractStaffSave");
        if (specialEducationIUContractStaffSaveButton !== null)
            specialEducationIUContractStaffSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let iuSpecialEducationFTEsAllocatedAddRowButton = document.getElementById("iuSpecialEducationContractStaffAddRow");
        if (iuSpecialEducationFTEsAllocatedAddRowButton !== null)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e) => this.addRow(e));
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const specialEducationIUIUSupportStaffLocationDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteConfirm");
        if (specialEducationIUIUSupportStaffLocationDeleteConfirm !== null)
            specialEducationIUIUSupportStaffLocationDeleteConfirm.addEventListener("click", (e) => this.deleteLocationConfirm(e.target));
        const specialEducationIUIUSupportStaffLocationDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteCancel");
        if (specialEducationIUIUSupportStaffLocationDeleteCancel !== null)
            specialEducationIUIUSupportStaffLocationDeleteCancel.addEventListener("click", (e) => this.deleteLocationCancel());
        const specialEducationIUIUSupportStaffSupportDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteConfirm");
        if (specialEducationIUIUSupportStaffSupportDeleteConfirm !== null)
            specialEducationIUIUSupportStaffSupportDeleteConfirm.addEventListener("click", (e) => this.deleteSupportConfirm(e.target));
        const specialEducationIUIUSupportStaffSupportDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteCancel");
        if (specialEducationIUIUSupportStaffSupportDeleteCancel !== null)
            specialEducationIUIUSupportStaffSupportDeleteCancel.addEventListener("click", (e) => this.deleteSupportCancel());
        const createSupportService = document.getElementById("createSupportService");
        if (createSupportService !== null)
            createSupportService.addEventListener("click", (e) => this.addNewContractService());
        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.bindAddContractRowButtons();
        this.bindAddLocationButtons();
        this.setupOtherDropdowns();
        this.bindDeleteLocationItems();
        this.bindDeleteSupports();
        const specialEducationIUIUSupportStaffExportToExcel = document.getElementById("specialEducationIUContractStaffExportToExcel");
        if (specialEducationIUIUSupportStaffExportToExcel !== null) {
            specialEducationIUIUSupportStaffExportToExcel.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        const supportStaffServicesSearchButton = document.getElementById("supportStaffServicesSearchButton");
        supportStaffServicesSearchButton.addEventListener("click", (e) => this.search());
        const supportStaffServicesClearButton = document.getElementById("supportStaffServicesClearButton");
        supportStaffServicesClearButton.addEventListener("click", (e) => this.clearSearch());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const saveData = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUContractStaffForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const accordions = document.querySelectorAll("button.supportStaffServices");
        for (const accordion of accordions) {
            const accordionElement = accordion;
            const dataPointPlanPropertyPK = accordionElement.dataset.datapointplanpropertypk;
            const fteRows = [];
            const rows = document.querySelectorAll(`.specialEducationIUContractStaffSelectWithOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const select of rows) {
                const allSaveElements = [];
                const element = select;
                const row = element.dataset.row;
                let selects = false;
                let inputs = false;
                let locations = false;
                const specialEducationIUIUSupportStaffSelectForSaveFields = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffSelectForSaveField of specialEducationIUIUSupportStaffSelectForSaveFields) {
                    let planPropertyPK = 0;
                    let element = specialEducationIUIUSupportStaffSelectForSaveField;
                    let rowNumber = element.dataset.row;
                    let propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (element.value !== "0" || hadValue) {
                        const saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: null,
                            LookupCodeFK: parseInt(element.value),
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };
                        allSaveElements.push(saveItem);
                        selects = true;
                    }
                }
                const specialEducationIUIUSupportStaffForSaveFields = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffForSaveField of specialEducationIUIUSupportStaffForSaveFields) {
                    let planPropertyPK = 0;
                    const element = specialEducationIUIUSupportStaffForSaveField;
                    const rowNumber = element.dataset.row;
                    const propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }
                    if (element.value !== "" || hadValue) {
                        const saveItem = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: element.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };
                        allSaveElements.push(saveItem);
                        inputs = true;
                    }
                }
                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const locationItem of locationItems) {
                    const name = locationItem.querySelector(`.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const rowNumber = name.dataset.row;
                    const propertyPK = name.dataset.propertypk;
                    let planPropertyPK = 0;
                    if (name.dataset.planpropertypk && name.dataset.planpropertypk !== "") {
                        planPropertyPK = parseInt(name.dataset.planpropertypk);
                    }
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: parseInt(propertyPK),
                        TextValue: name.textContent,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                    locations = true;
                }
                const rowToSave = {
                    "SaveElements": allSaveElements
                };
                if (selects || inputs || locations)
                    fteRows.push(rowToSave);
            }
            const thisAccordion = {
                "PlanFK": planPK,
                "FTERows": fteRows
            };
            saveData.push(thisAccordion);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUContractStaff', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    showDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let dataPointPlanProperty = deleteButton.dataset.datapointplanpropertypk;
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanProperty);
        modal.show();
    }
    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteContractStaffRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUContractStaffSelectWithOtherField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.dropdownChange(e.target));
        const locationSelectElements = document.getElementsByClassName("specialEducationIUContractLocationSelectField");
        for (const selectElement of locationSelectElements)
            selectElement.addEventListener("change", (e) => this.locationDropdownChange(e.target));
    }
    dropdownChange(e) {
        const selectElement = e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;
        const selectContainer = document.querySelector(`.buildingContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = document.querySelector(`.otherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = document.querySelector(`.specialEducationIUContractStaffOtherField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        }
        else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }
    addRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const numberOfRows = 1;
            const formElement = document.getElementById("specialEducationIUContractStaffForm");
            const button = e.target;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                const planFK = formElement.dataset.planfk;
                const that = this;
                let newRow = 0;
                const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/AddContactStaffAndServicesRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        const newDiv = document.createElement("div");
                        newDiv.classList.add("operatedServiceRow");
                        newDiv.classList.add("operated-service-row");
                        newDiv.classList.add("margin-bottom-large");
                        newDiv.dataset.row = newRow.toString();
                        newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK.toString();
                        newDiv.innerHTML = output;
                        const accordion = document.querySelector(`.supportStaffList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        accordion.appendChild(newDiv);
                        this.bindOtherDropdown();
                        this.bindAddLocationButtons();
                        this.bindDeleteProgramRows();
                        this.bindDeleteSupports();
                    }
                }
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The row was successfully added.", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", 'An error occured adding this row. Please try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        const buttonElement = e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const allElements = document.querySelectorAll(`.specialEducationIUContractField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allElements) {
            const element = ele;
            const planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        const allLocations = document.querySelectorAll(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allLocations) {
            const element = ele;
            const planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowDataWithRelated', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindAddContractRowButtons() {
        const iuSpecialEducationFTEsAllocatedAddRowButtons = document.getElementsByClassName("iuSpecialEducationContractStaffAddRow");
        for (const iuSpecialEducationFTEsAllocatedAddRowButton of iuSpecialEducationFTEsAllocatedAddRowButtons)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e) => this.addRow(e));
    }
    addNewContractService() {
        return __awaiter(this, void 0, void 0, function* () {
            let staffServiceLookupCodeFK = 0;
            let fteId = "";
            const specialEducationIUIUSupportStaffForm = document.getElementById("specialEducationIUContractStaffForm");
            const planFK = specialEducationIUIUSupportStaffForm.dataset.planfk;
            const specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices = document.getElementById("specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices");
            if (specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices !== null) {
                const selectElement = specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices;
                const lookupCodeFK = selectElement.value;
                staffServiceLookupCodeFK = parseInt(lookupCodeFK);
            }
            const specialEducationIUProfilesContractSupportStaffServiceCreateFTEId = document.getElementById("specialEducationIUProfilesContractSupportStaffServiceCreateFTEId");
            if (specialEducationIUProfilesContractSupportStaffServiceCreateFTEId !== null) {
                const inputElement = specialEducationIUProfilesContractSupportStaffServiceCreateFTEId;
                const textValue = inputElement.value;
                fteId = textValue;
            }
            if (fteId === "" || staffServiceLookupCodeFK === 0) {
                Core.createHTMLAlert("alertMessageDiv", "To add a record, enter a value in the FTE ID field and select a value from the Contracted Support/Service dropdown.", 'warning', 3000, null);
            }
            else {
                Core.showLoader();
                const dataModel = {
                    "PlanFK": planFK,
                    "FTEID": fteId,
                    "StaffServiceLookupCodeFK": staffServiceLookupCodeFK
                };
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };
                const response = yield fetch(`/IUSpecialEducation/AddContractStaffAndServicesAccordion`, settings);
                if (response.ok) {
                    const output = yield response.text();
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = output;
                    let container = document.getElementById("iuContractedStaffServices");
                    container.append(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    const specialEducationIUProfilesIUCreateSupportServiceIUFTEId = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUFTEId");
                    if (specialEducationIUProfilesIUCreateSupportServiceIUFTEId !== null) {
                        specialEducationIUProfilesIUCreateSupportServiceIUFTEId.value = "";
                    }
                    const specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices");
                    if (specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices !== null) {
                        specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices.selectedIndex = 0;
                    }
                    this.bindAddLocationButtons();
                    this.bindAddContractRowButtons();
                    this.bindDeleteSupports();
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "The IU Support/Services record has been added.", 'success', 3000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding this record. Please try again.", 'error', 3000, null);
                }
            }
        });
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUContractStaffForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUContractServicesProfileExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUContractServices.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    search() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("specialEducationIUContractStaffForm");
            const planFK = form.dataset.planfk;
            const pageCode = form.dataset.pagecode;
            let fteId = "";
            let supportStaffServices = 0;
            const specialEducationIUProfilesContractSupportStaffServicesSearchFTEId = document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchFTEId");
            if (specialEducationIUProfilesContractSupportStaffServicesSearchFTEId !== null)
                fteId = specialEducationIUProfilesContractSupportStaffServicesSearchFTEId.value;
            const specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices = document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices");
            if (specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices !== null)
                supportStaffServices = parseInt(specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices.value);
            //Need the title, lookup, planfk and pagecode and a new method to do the search and replace the accordions in the container.
            const dataModel = {
                "PlanFK": planFK,
                "AccordionTitle": fteId,
                "PageCode": pageCode,
                "SupportStaffServicesLookupCodeFK": supportStaffServices
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            const response = yield fetch(`/IUSpecialEducation/SearchSupportStaffAndServices`, settings);
            if (response.ok) {
                const output = yield response.text();
                const iuOperatedServices = document.getElementById("iuContractedStaffServices");
                iuOperatedServices.innerHTML = output;
                specialEducationIUProfilesContractSupportStaffServicesSearchFTEId.value = "";
                specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices.value = "0";
                Core.hideLoader();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue with the search, please try again.", 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    clearSearch() {
        return __awaiter(this, void 0, void 0, function* () {
            const specialEducationIUProfilesContractSupportStaffServicesSearchFTEId = document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchFTEId");
            if (specialEducationIUProfilesContractSupportStaffServicesSearchFTEId !== null)
                specialEducationIUProfilesContractSupportStaffServicesSearchFTEId.value = "";
            const specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices = document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices");
            if (specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices !== null)
                specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices.value = "0";
            yield this.search();
        });
    }
    locationDropdownChange(e) {
        const selectElement = e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;
        const selectContainer = document.querySelector(`.locationContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = document.querySelector(`.locationOtherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = document.querySelector(`.specialEducationIUContractLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        }
        else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }
    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUContractStaffSelectWithOtherField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }
    bindAddLocationButtons() {
        const addLocation = document.getElementsByClassName("addLocation");
        for (const location of addLocation) {
            location.addEventListener("click", (e) => this.addLocation(e));
        }
    }
    addLocation(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const row = button.dataset.row;
            const propertyPK = button.dataset.propertypk;
            const form = document.getElementById("specialEducationIUContractStaffForm");
            const planFK = form.dataset.planfk;
            const locationDropdown = document.querySelector(`.specialEducationIUContractLocationSelectField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const locationOtherInput = document.querySelector(`.specialEducationIUContractLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            if (locationDropdown.value === "0") {
                Core.createHTMLAlert("alertMessageDiv", "Please select a location to add.", 'warning', 3000, null);
            }
            else {
                let locationText = "";
                let proceed = true;
                const locationDropdownOption = locationDropdown[locationDropdown.selectedIndex];
                locationText = locationDropdownOption.text;
                if (locationDropdownOption.dataset.lookupcode === "specialEducationIUContractStaffLocationOther") {
                    locationText = locationOtherInput.value;
                }
                if (locationDropdownOption.dataset.lookupcode === "specialEducationIUContractStaffLocationOther" && locationText === "") {
                    proceed = false;
                    Core.createHTMLAlert("alertMessageDiv", "Please enter a value for 'Other'.", 'error', 3000, null);
                }
                const potentialDupes = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}'] .name`);
                for (const potential of potentialDupes) {
                    const span = potential;
                    if (span.textContent.toLowerCase() === locationText.toLowerCase()) {
                        proceed = false;
                        Core.createHTMLAlert("alertMessageDiv", "A location with this name has already been added. Please choose a different one.", 'error', 3000, null);
                    }
                }
                if (proceed) {
                    const dataModel = {
                        "PlanFK": planFK,
                        "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                        "Row": row,
                        "Location": locationText,
                        "PlanPropertyPK": 0,
                        "PropertyPK": propertyPK
                    };
                    const settings = {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(dataModel)
                    };
                    const response = yield fetch(`/IUSpecialEducation/AddSupportStaffAndServicesLocation`, settings);
                    if (response.ok) {
                        const output = yield response.text();
                        const newDiv = document.createElement("div");
                        newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                        newDiv.dataset.planpropertypk = "0";
                        newDiv.dataset.row = row;
                        newDiv.classList.add("location-list-item");
                        newDiv.classList.add("locationItem");
                        newDiv.classList.add("medium-4");
                        newDiv.classList.add("column");
                        newDiv.classList.add("end");
                        newDiv.innerHTML = output;
                        const locationList = document.querySelector(`.locationList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        locationList.appendChild(newDiv);
                        this.bindDeleteLocationItems();
                        locationOtherInput.value = "";
                        locationDropdown.selectedIndex = 0;
                        this.locationDropdownChange(locationDropdown);
                        Core.createHTMLAlert("alertMessageDiv", "Location added.", 'success', 3000, null);
                    }
                }
            }
        });
    }
    bindDeleteLocationItems() {
        const deleteButtons = document.getElementsByClassName("deleteStaffLocationItem");
        for (const deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e) => this.deleteLocationItem(e));
        }
    }
    deleteLocationItem(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = parseInt(button.dataset.planpropertypk);
        const row = button.dataset.row;
        const item = document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);
        if (planPropertyPK === 0) {
            item.remove();
        }
        else {
            this.showDeleteLocation(button);
        }
    }
    showDeleteLocation(button) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const row = button.dataset.row;
        const deleteLocationModal = new Modal("deleteLocationModal", null);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "row", row);
        deleteLocationModal.show();
    }
    deleteLocationCancel() {
        const deleteLocationModal = new Modal("deleteLocationModal", null);
        deleteLocationModal.hide();
    }
    deleteLocationConfirm(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const planPropertyPK = button.dataset.planpropertypk;
            const row = button.dataset.row;
            const deleteLocationModal = new Modal("deleteLocationModal", null);
            deleteLocationModal.hide();
            Core.showLoader();
            const item = document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);
            const response = yield fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesLocation/${dataPointPlanPropertyPK}/${planPropertyPK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const json = yield response.json();
                Core.hideLoader();
                if (json.success) {
                    item.remove();
                    Core.createHTMLAlert("alertMessageDiv", "The location was successfully removed.", 'success', 3000, null);
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
            }
        });
    }
    bindDeleteSupports() {
        const iuSpecialEducationSupportStaffDeleteSupports = document.getElementsByClassName("iuSpecialEducationSupportStaffDeleteSupport");
        for (const iuSpecialEducationSupportStaffDeleteSupport of iuSpecialEducationSupportStaffDeleteSupports)
            iuSpecialEducationSupportStaffDeleteSupport.addEventListener("click", (e) => this.showDeleteSupport(e.target));
    }
    showDeleteSupport(button) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const deleteSupportModal = new Modal("deleteSupportModal", null);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteSupportModal.show();
    }
    deleteSupportCancel() {
        const deleteSupportModal = new Modal("deleteSupportModal", null);
        deleteSupportModal.hide();
    }
    deleteSupportConfirm(button) {
        return __awaiter(this, void 0, void 0, function* () {
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const planPropertyPK = button.dataset.planpropertypk;
            const accordion = document.querySelector(`.Accordion-panel.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const accordionTrigger = document.querySelector(`.Accordion-trigger.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const accordionHeader = accordionTrigger.parentElement;
            const response = yield fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesSupport/${dataPointPlanPropertyPK}`, { credentials: 'include' });
            if (response.ok) {
                const json = yield response.json();
                if (json.success) {
                    accordion.remove();
                    accordionHeader.remove();
                    Core.createHTMLAlert("alertMessageDiv", "The record was successfully removed.", 'success', 3000, null);
                }
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the record, please try again.", 'error', 3000, null);
                const deleteSupportModal = new Modal("deleteSupportModal", null);
                deleteSupportModal.hide();
            }
        });
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const allAccordions = document.querySelectorAll(".Accordion-panel.supportStaffServices");
        for (const accordion of allAccordions) {
            const accordionEle = accordion;
            const dataPointPlanPropertyPK = accordionEle.dataset.datapointplanpropertypk;
            const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            let hasAtLeastOneRow = false;
            for (const thisRow of allRows) {
                let hasSomeValue = false;
                const ele = thisRow;
                const row = ele.dataset.row;
                const selects = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = select;
                    const selectValue = parseInt(selectEle.value);
                    if (!isNaN(selectValue) && selectValue > 0)
                        hasSomeValue = true;
                }
                if (!hasSomeValue) {
                    const inputs = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const input of inputs) {
                        const inputElement = input;
                        if (inputElement.value !== "" && inputElement.value.trim() !== "")
                            hasSomeValue = true;
                    }
                    if (!hasSomeValue) {
                        const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        if (locationItems && locationItems.length > 0)
                            hasSomeValue = true;
                    }
                }
                else {
                    //There is at least one item filled out. We need to make sure everything is filled out in this row now.
                    hasAtLeastOneRow = true;
                    const selects = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const select of selects) {
                        const selectEle = select;
                        const selectValue = parseInt(selectEle.value);
                        if (isNaN(selectValue) || selectValue === 0) {
                            selectEle.classList.add("missing-field");
                            selectEle.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectEle);
                            showMessage = true;
                            totalErrors++;
                        }
                        if (selectEle.hasAttribute("data-hasother")) {
                            const selected = selectEle[selectEle.selectedIndex];
                            if (selected.dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                                const otherField = document.querySelector(`.specialEducationIUContractStaffOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                                if (otherField.value === "" || otherField.value.trim() === "") {
                                    otherField.classList.add("missing-field");
                                    otherField.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(otherField);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                    const inputs = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const input of inputs) {
                        const inputElement = input;
                        if (!inputElement.hasAttribute("data-other") && inputElement.value === "" && inputElement.value.trim() === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    if (locationItems === null || locationItems.length === 0) {
                        const specialEducationIUContractLocationSelectField = document.querySelector(`.specialEducationIUContractLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        if (specialEducationIUContractLocationSelectField !== null) {
                            specialEducationIUContractLocationSelectField.classList.add("missing-field");
                            specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
            //If there isn't at least one row filled out for this (each) accordion, we need to flag it as incomplete.
            if (!hasAtLeastOneRow) {
                const firstRow = allRows[0];
                const row = firstRow.dataset.row;
                const selects = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = select;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }
                const locationSelect = document.querySelector(`.specialEducationIUContractLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if (locationSelect !== null) {
                    const selectEle = locationSelect;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }
                const inputs = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const input of inputs) {
                    const inputElement = input;
                    inputElement.classList.add("missing-field");
                    inputElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(inputElement);
                    showMessage = true;
                    totalErrors++;
                }
                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if (locationItems && locationItems.length > 0) {
                    const specialEducationIUContractLocationSelectField = document.querySelector(`.specialEducationIUContractLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    specialEducationIUContractLocationSelectField.classList.add("missing-field");
                    specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    initializeRequiredFieldsCustom(allClasses, refresh = false, allowDuplicates = false) {
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        const classesToValidate = formattedAllClasses.join(",");
        if (refresh) {
            const allElements = document.querySelectorAll(classesToValidate);
            for (const element of allElements) {
                const htmlElement = element;
                htmlElement.removeAttribute("aria-required");
                const label = Core.findLabelForInput(htmlElement);
                if (label !== null) {
                    label.classList.remove("isRequired");
                    const asterisk = label.querySelector(".required-label");
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }
        const allElements = document.querySelectorAll(classesToValidate);
        for (const element of allElements) {
            const htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    const label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
}
// SpecialEducationIUFacilities
class SpecialEducationIUFacilities {
    constructor() {
        this._core = new Core();
        let that = this;
        this.validationClasses = ["specialEducationIUFacilitiesField", "specialEducationSpecialEducationFacilitiesField"];
        SpecialEducationIUFacilities.staticValidationClasses = ["specialEducationIUFacilitiesField", "specialEducationSpecialEducationFacilitiesField"];
        SpecialEducationIUFacilities._staticCore = new Core();
        if (this._core.Workflows.length > 0) {
            for (let seprnWorkflow of this._core.Workflows) {
                SpecialEducationSpecialEducationFacilities.initializeSEPRNWorkflow(seprnWorkflow);
            }
        }
        this._core.leftnav(this);
        new SpecialEducationIUFacilitiesLazyAccordion();
        SpecialEducationIUFacilities.initializeFacilityHashes();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        // Get form element and PlanFK
        const form = document.getElementById("specialEducationIUFacilitiesForm");
        if (form != null) {
            this.planFK = parseInt(form.dataset.planfk);
            SpecialEducationIUFacilities.staticPlanFK = parseInt(form.dataset.planfk);
        }
        // Set search event on search button
        const searchButton = document.getElementById("facilitiesSearchButton");
        if (searchButton !== null) {
            //1. Save
            //2. Run Search
            searchButton.addEventListener("click", () => {
                Core.showLoader();
                SpecialEducationIUFacilities.promiseSave().then(() => {
                    return this.search();
                }).then(() => {
                    // reinitialize accordions
                    new SpecialEducationIUFacilitiesLazyAccordion();
                    Core.hideLoader();
                    SpecialEducationIUFacilities.constructAutofillInputs();
                    SpecialEducationIUFacilities.constructFileUploadElements();
                    SpecialEducationIUFacilities.constructFileDeleteElements();
                    that._core.initializeWorkflows();
                    if (that._core.Workflows.length > 0) {
                        for (let seprnWorkflow of this._core.Workflows) {
                            SpecialEducationIUFacilities.initializeSEPRNWorkflow(seprnWorkflow);
                        }
                    }
                    Tab.Init();
                }).catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        // Set clear event on clear button
        const clearButton = document.getElementById("facilitiesClearButton");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                this.clearSearch();
            });
        }
        // Set file upload/delete events
        SpecialEducationIUFacilities.constructAutofillInputs();
        SpecialEducationIUFacilities.constructFileUploadElements();
        SpecialEducationIUFacilities.constructFileDeleteElements();
        // Set add a location event on button
        const addLocationButton = document.getElementById('specialEducationSpecialEducationFacilitiesAddALocation');
        if (addLocationButton != null) {
            addLocationButton.addEventListener('click', () => {
                Core.showLoader();
                this.addLocation()
                    .then((response) => {
                    Core.hideLoader();
                    this._core.initializeRequiredFields(this.validationClasses);
                    Core.createHTMLAlert('alertMessageDiv', response, 'success', 3000, null);
                    SpecialEducationIUFacilities.constructAutofillInputs();
                    SpecialEducationIUFacilities.constructFileUploadElements();
                    SpecialEducationIUFacilities.constructFileDeleteElements();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
                });
            });
        }
        document.addEventListener("click", (e) => {
            const target = e.target;
            //Save
            if (target.classList.contains("facilitiesSave") && "facilitypk" in target.dataset) {
                const facilityFK = target.dataset.facilitypk;
                Core.showLoader();
                SpecialEducationIUFacilities.promiseSave(parseInt(facilityFK)).then((response) => {
                    Core.hideLoader();
                    SpecialEducationIUFacilities.initializeFacilityHashes();
                    SpecialEducationIUFacilities.handleFacilitySaveDisabled(parseInt(facilityFK));
                    SpecialEducationIUFacilities.validateElements(facilityFK);
                    SpecialEducationIUFacilities.initializeLoadedFacilityFields(facilityFK);
                    SpecialEducationIUFacilities.fullPageValidation();
                });
            }
            if (target.classList.contains("createSEPRNButton") && "facilityPk" in target.dataset) {
                SpecialEducationIUFacilities.createSEPRN(parseInt(target.dataset.facilityPk));
            }
            else if (target.classList.contains("showDifferences") && "facilityPk" in target.dataset) {
                that.showSEPRNDifferences(parseInt(target.dataset.facilityPk));
            }
            else if (target.classList.contains("iuFacilityDelete")) {
                that.showDeleteFacilityConfirmation(target);
            }
        });
        const deleteFacilityConfirmButton = document.getElementById("deleteFacilityConfirm");
        if (deleteFacilityConfirmButton !== null)
            deleteFacilityConfirmButton.addEventListener("click", (e) => this.deleteSpecialEducationFacility(deleteFacilityConfirmButton));
        const deleteFacilityCancelButton = document.getElementById("deleteFacilityCancel");
        if (deleteFacilityCancelButton !== null)
            deleteFacilityCancelButton.addEventListener("click", (e) => this.deleteFacilityCancel());
        document.addEventListener("input", (e) => {
            const target = e.target;
            if ("facilityPK" in target.dataset)
                SpecialEducationIUFacilities.handleFacilitySaveDisabled(parseInt(target.dataset.facilityPk));
        });
        document.addEventListener("change", (e) => {
            let target = e.target;
            SpecialEducationIUFacilities.handleFacilitySaveDisabled(parseInt(target.dataset.facilityPk));
            if (target.classList.contains("proposedAction") && "facilityPk" in target.dataset) {
                let proposedActionElement = target;
                let selectedProposedAction = proposedActionElement.options[proposedActionElement.selectedIndex];
                if (selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    //Open up editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']`);
                    for (let editable of allEditable) {
                        editable.removeAttribute("readonly");
                        editable.removeAttribute("disabled");
                    }
                }
                else {
                    //disable editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']:not([data-propertypk='1184'])`);
                    for (let editable of allEditable) {
                        if (editable instanceof HTMLInputElement) {
                            if (editable.hasAttribute("type") && editable.getAttribute("type") == "file" || editable.getAttribute("type") == "radio") {
                                editable.disabled = true;
                            }
                            else {
                                editable.readOnly = true;
                            }
                        }
                        else if (editable instanceof HTMLSelectElement || editable instanceof HTMLButtonElement) {
                            editable.disabled = true;
                        }
                    }
                }
                var additionalInfo = document.querySelector(`.additionalInformation[data-facility-pk='${proposedActionElement.dataset.facilityPk}']`);
                if (selectedProposedAction.dataset.lookupCode == "proposedActionDelete" || selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    if (additionalInfo != null) {
                        that._core.forceElementRequired(additionalInfo);
                    }
                }
                else {
                    if (additionalInfo != null) {
                        that._core.forceElementOptional(additionalInfo);
                    }
                }
            }
            else if (target.classList.contains("operatedInRequiredFields")) {
                this.checkForRequiredFieldsFromOperatedIn(target);
            }
        });
        let closeSEPRNCompareButton = document.getElementById('closeModalcompareFacilitiesModal');
        if (closeSEPRNCompareButton != null) {
            closeSEPRNCompareButton.addEventListener("click", () => {
                const modal = new Modal('compareFacilitiesModal', null);
                modal.hide();
            });
        }
        const facilitiesExportToExcelButton = document.getElementById("specialEducationFacilitiesExportToExcel");
        if (facilitiesExportToExcelButton !== null) {
            facilitiesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        const buildingNamesForOther = document.getElementsByClassName("buildingName");
        for (const buildingName of buildingNamesForOther)
            buildingName.addEventListener("change", (e) => this.checkForBuildingOther(e.target));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let refreshPage = '';
        Core.showLoader();
        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url');
        }
        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        }
        else {
            refreshPage = referrer;
        }
        let forceSave = false;
        if (referrer === 'save') {
            forceSave = true;
        }
        SpecialEducationIUFacilities.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();
            if (referrer === 'save') {
                //Reset query so that all results show
                window.location.href = '/IUSpecialEducation/SpecialEducationIUFacilities/' + this.planFK + '?fromSave=true';
            }
            else if (refreshPage && refreshPage !== '') {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
            Core.hideLoader();
        });
    }
    constructAutofillInputsSEPRN() {
        // Get measurement values from the inputs
        const originalAreaMeasurements = {
            WidthFeet: {
                Element: document.querySelector(`#originalContainer .feet.width-ft`),
                Value: parseInt(document.querySelector(`#originalContainer .feet.width-ft`).value),
            },
            WidthInches: {
                Element: document.querySelector(`#originalContainer .inches.width-in`),
                Value: parseInt(document.querySelector(`.inches.width-in`).value)
            },
            LengthFeet: {
                Element: document.querySelector(`#originalContainer .feet.length-ft`),
                Value: parseInt(document.querySelector(`#originalContainer .feet.length-ft`).value),
            },
            LengthInches: {
                Element: document.querySelector(`#originalContainer .inches.length-in`),
                Value: parseInt(document.querySelector(`#originalContainer .inches.length-in`).value)
            }
        };
        // Calculate total inches (inches + feet*12) 
        const originalTotalWidthInches = originalAreaMeasurements.WidthInches.Value + (originalAreaMeasurements.WidthFeet.Value * 12);
        const originalTotalLengthInches = originalAreaMeasurements.LengthInches.Value + (originalAreaMeasurements.LengthFeet.Value * 12);
        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const originalAreaInches = originalTotalWidthInches * originalTotalLengthInches;
        const originalAreaSqFeet = Math.floor(originalAreaInches / 144);
        const originalMaxStudents = Math.floor(originalAreaSqFeet / 28);
        // Update UI
        const originalAreaEle = document.querySelector(`#originalContainer .specialEducationFacilitiesAreaMeasurement`);
        originalAreaEle.value = `${originalAreaSqFeet}sqft`;
        const originalMaxStudentsEle = document.querySelector(`#originalContainer .specialEducationFacilitiesMaxStudents`);
        originalMaxStudentsEle.value = `${originalMaxStudents}`;
        // Get measurement values from the inputs
        const newAreaMeasurements = {
            WidthFeet: {
                Element: document.querySelector(`#newContainer .feet.width-ft`),
                Value: parseInt(document.querySelector(`#newContainer .feet.width-ft`).value),
            },
            WidthInches: {
                Element: document.querySelector(`#newContainer .inches.width-in`),
                Value: parseInt(document.querySelector(`.inches.width-in`).value)
            },
            LengthFeet: {
                Element: document.querySelector(`#newContainer .feet.length-ft`),
                Value: parseInt(document.querySelector(`#newContainer .feet.length-ft`).value),
            },
            LengthInches: {
                Element: document.querySelector(`#newContainer .inches.length-in`),
                Value: parseInt(document.querySelector(`#newContainer .inches.length-in`).value)
            }
        };
        // Calculate total inches (inches + feet*12) 
        const newTotalWidthInches = newAreaMeasurements.WidthInches.Value + (newAreaMeasurements.WidthFeet.Value * 12);
        const newTotalLengthInches = newAreaMeasurements.LengthInches.Value + (newAreaMeasurements.LengthFeet.Value * 12);
        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const newAreaInches = newTotalWidthInches * newTotalLengthInches;
        const newAreaSqFeet = Math.floor(newAreaInches / 144);
        const newMaxStudents = Math.floor(newAreaSqFeet / 28);
        // Update UI
        const newAreaEle = document.querySelector(`#newContainer .specialEducationFacilitiesAreaMeasurement`);
        newAreaEle.value = `${newAreaSqFeet}sqft`;
        const newMaxStudentsEle = document.querySelector(`#newContainer .specialEducationFacilitiesMaxStudents`);
        newMaxStudentsEle.value = `${newMaxStudents}`;
    }
    addLocation() {
        const buildingNameEle = document.getElementById("specialEducationIUProfilesFacilitiesAddBuildingName");
        const buildingName = buildingNameEle.value;
        let buildingNameOther = "";
        const whichOne = "new";
        if (buildingName.toLowerCase() === "other") {
            const buildingNameOtherEle = document.querySelector(`.otherElement[data-field='${whichOne}']`);
            buildingNameOther = buildingNameOtherEle.value;
        }
        const roomDescInput = document.getElementById('specialEducationIUProfilesFacilitiesRoom');
        const roomDesc = roomDescInput.value;
        const that = this;
        return new Promise((resolve, reject) => {
            const data = {
                SpecialEducationIUFacilityPK: 0,
                PlanFK: this.planFK,
                RoomDesc: roomDesc,
                BuildingName: buildingName,
                BuildingNameOther: buildingNameOther
            };
            if (roomDescInput != null && roomDesc != "" && buildingName != null) {
                if (buildingName.toLowerCase() !== "other" && buildingNameOther === "") {
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', '/IUSpecialEducation/CreateNewIUFacility', true);
                    xhr.setRequestHeader('Content-type', 'application/json');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            const facilityList = document.getElementById('facilitiesContainerInner');
                            if (facilityList != null) {
                                $(facilityList).prepend(xhr.responseText);
                                const newFacilityAccordion = facilityList.querySelector(`.lazy-accordion-trigger`);
                                if (newFacilityAccordion != null) {
                                    new SpecialEducationIUFacilitiesLazyAccordion(newFacilityAccordion.id);
                                    newFacilityAccordion.setAttribute("data-isnew", "true");
                                }
                                SpecialEducationIUFacilities.clearValidationMessage();
                                SpecialEducationIUFacilities.fullPageValidation();
                                SpecialEducationIUFacilities.planPageCompleteCheck();
                                resolve('Successfully created new facility.');
                                SpecialEducationIUFacilities.bindOperatedByChanges();
                            }
                        }
                        else {
                            reject('There was an unexpected error creating a new facility, please try again.');
                        }
                    };
                    xhr.send(JSON.stringify(data));
                }
                else {
                    reject("A value for 'Other' must be specified when selecting 'Other' for the Building Name");
                }
            }
            else {
                reject('Both a Building and a Room must be specified to add a location.');
            }
        });
    }
    search(fromHistory = false) {
        const frcppInstitutionSelect = document.getElementById('specialEducationIUProfilesFacilitiesSearchBuilding');
        const frcppInstitutionFK = frcppInstitutionSelect != null && frcppInstitutionSelect.selectedIndex > 0
            ? parseInt(frcppInstitutionSelect[frcppInstitutionSelect.selectedIndex].dataset.lookupCode)
            : null;
        const roomDescInput = document.getElementById('specialEducationIUProfilesFacilitiesSearchRoom');
        const roomDesc = roomDescInput != null && roomDescInput.value != ''
            ? roomDescInput.value
            : null;
        let query = {
            PlanFK: this.planFK,
            FRCPPInstitutionFK: frcppInstitutionFK,
            RoomDesc: roomDesc
        };
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/FacilitiesSearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const container = document.getElementById('facilitiesContainer');
                    if (container) {
                        container.innerHTML = xhr.responseText;
                        resolve();
                    }
                    else {
                        reject("There was an error loading the facilities, please try again.");
                    }
                }
                else {
                    reject("There was an error loading the facilities, please try again.");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }
    clearSearch() {
        const frcppInstitutionSelect = document.getElementById("specialEducationFacilitiesBuildingName");
        const roomDescInput = document.getElementById("specialEducationFacilitiesRoomNumber");
        if (frcppInstitutionSelect != null) {
            frcppInstitutionSelect.selectedIndex = 0;
        }
        if (roomDescInput != null) {
            roomDescInput.value = '';
        }
        Core.showLoader();
        SpecialEducationIUFacilities.promiseSave()
            .then(() => {
            return this.search(true);
        })
            .then(() => {
            //reinitialize accordions
            new SpecialEducationIUFacilitiesLazyAccordion(null);
            Core.hideLoader();
            SpecialEducationIUFacilities.initializeFacilityHashes();
            SpecialEducationIUFacilities.constructAutofillInputs();
            SpecialEducationIUFacilities.constructFileUploadElements();
            SpecialEducationIUFacilities.constructFileDeleteElements();
        })
            .catch((error) => {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
        //history.pushState(null, '', '/SpecialEducation/SpecialEducationEducationProgramCaseloadFTE/' + that.planFK);
        //document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }
    showSEPRNDifferences(facilityPK) {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/IUSpecialEducation/GetFacilityComparison?facilityPK=' + facilityPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal = new Modal('compareFacilitiesModal', null);
                modal.show();
                const compareFacilitiesBody = document.getElementById('compareFacilitiesBody');
                if (compareFacilitiesBody != null) {
                    $(compareFacilitiesBody).html(xhr.responseText);
                    that.constructAutofillInputsSEPRN();
                    that.highlightChangedFieldsSEPRN();
                }
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }
    highlightChangedFieldsSEPRN() {
        let originalFields = document.querySelectorAll('#originalContainer .specialEducationSpecialEducationFacilitiesField');
        for (let field of originalFields) {
            let fieldElement = field;
            let usedRadios = [];
            if (fieldElement instanceof HTMLInputElement) {
                let correspondingFieldElement = document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if (fieldElement.hasAttribute("type") && fieldElement.type == "radio" && usedRadios.indexOf(fieldElement.name) == -1) {
                    usedRadios.push(fieldElement.name);
                    //Get checked value of original and compare to new
                    let checkedOriginal = document.querySelector(`[name='${fieldElement.name}']:checked`);
                    let checkedNew = document.querySelector(`[name='${correspondingFieldElement.name}']:checked`);
                    if (checkedOriginal != null && checkedNew != null && checkedOriginal.value != checkedNew.value) {
                        let trContainer = Core.findClosest(checkedNew, "tr");
                        if (trContainer != null) {
                            trContainer.classList.add("different");
                        }
                    }
                }
                else {
                    if (fieldElement.value != correspondingFieldElement.value) {
                        correspondingFieldElement.classList.add("different");
                    }
                }
            }
            else if (fieldElement instanceof HTMLSelectElement) {
                let correspondingFieldElement = document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if (fieldElement.value != correspondingFieldElement.value) {
                    correspondingFieldElement.classList.add("different");
                }
            }
        }
        //Files
        let originalFile = document.querySelector('#originalContainer .uploadFileColumn[data-fileupload-pk]');
        let newFile = document.querySelector('#newContainer .uploadFileColumn[data-fileupload-pk]');
        if ((originalFile != null && newFile != null && originalFile.dataset.fileuploadPk != newFile.dataset.fileuploadPk) || (originalFile == null && newFile != null)) {
            let fileContainer = newFile.querySelector('.uploaded-file-container');
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        }
        else if (originalFile != null && newFile == null) {
            let fileContainer = originalFile.querySelector('.uploaded-file-container');
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        }
    }
    showDeleteFacilityConfirmation(deleteButton) {
        const facilityPK = deleteButton.dataset.facilityPk;
        const modal = new Modal('deleteFacilityModal', null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFacilityConfirm", "facilitypk", facilityPK);
        const id = document.getElementById("deleteFacilityConfirm");
        id.dataset.facilitypk = facilityPK;
        modal.show();
    }
    deleteFacilityCancel() {
        const modal = new Modal('deleteFacilityModal', null);
        modal.hide();
    }
    deleteSpecialEducationFacility(button) {
        const facilityFK = button.dataset.facilitypk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteSpecialEducationFacility', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const response = JSON.parse(xhr.response);
            if (xhr.status === 200 && response.success) {
                const accordionTrigger = document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${facilityFK}']`);
                accordionTrigger.parentElement.parentElement.remove();
                const modal = new Modal("deleteFacilityModal", null);
                modal.hide();
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the facility', 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred deleting the facility, please try again.', 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(facilityFK));
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUFacilitiesForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUFacilitiesExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `IUSpecialEducationFacilties.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    customValidation(facilityPK = null) {
        let hasInputErrors = false;
        let hasSelectErrors = false;
        let errorElements = [];
        let totalErrors = 0;
        let showMessage = false;
        let parent = document;
        if (facilityPK != null) {
            let facility = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
            if (facility != null) {
                parent = facility;
            }
        }
        const roomDescInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.room-desc');
        for (let i of roomDescInputs) {
            const input = i;
            if (!input.value) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            }
            else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }
        const footInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.feet');
        for (let i of footInputs) {
            const input = i;
            var inputValue = parseInt(input.value);
            if (inputValue === 0) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            }
            else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }
        const requriedSelects = parent.querySelectorAll('select.specialEducationSpecialEducationFacilitiesField.required-facility-field');
        for (let s of requriedSelects) {
            const select = s;
            if (select.value === '0') {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
                select.classList.add('missing-field');
                select.setAttribute('aria-invalid', 'true');
                hasSelectErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: select, type: 'select' });
            }
            else {
                select.classList.remove('missing-field');
                select.removeAttribute('aria-invalid');
            }
        }
        for (let element of errorElements) {
            const accordion = element.element.closest('.Accordion-panel');
            accordion.classList.add('open');
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    firstFocusableEl.focus();
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
        return !(hasInputErrors || hasSelectErrors);
    }
    checkForBuildingOther(select) {
        const thisVal = select.value;
        const whichOne = select.dataset.field;
        const otherContainer = document.querySelector(`.otherContainer[data-field='${whichOne}']`);
        const buildingContainer = document.querySelector(`.buildingContainer[data-field='${whichOne}']`);
        const otherElement = document.querySelector(`.otherElement[data-field='${whichOne}']`);
        if (thisVal.toLowerCase() === "other") {
            this._core.forceElementRequired(otherElement);
            otherContainer.classList.remove("hide");
            buildingContainer.classList.add("short");
        }
        else {
            this._core.forceElementOptional(otherElement);
            otherContainer.classList.add("hide");
            buildingContainer.classList.remove("short");
        }
    }
    checkForRequiredFieldsFromOperatedIn(select) {
        const selectedItem = select[select.selectedIndex];
        const facilityFK = selectedItem.dataset.facilityfk;
        const operatedInContainer = document.querySelector(`.operatedInContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherContainer = document.querySelector(`.operatedInOtherContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherElement = document.querySelector(`.operatedInOtherElement[data-facility-pk='${facilityFK}']`);
        const operatedInLEAContainer = document.querySelector(`.operatedInLEANameContainer[data-facilityfk='${facilityFK}']`);
        const operatedInLEAElement = document.querySelector(`.operatedInLEAName[data-facility-pk='${facilityFK}']`);
        if (selectedItem.dataset.lookup === "specialEducationIUOperatedInOther") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.remove("hide");
            this._core.forceElementRequired(operatedInOtherElement);
            operatedInLEAContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInLEAElement);
        }
        else if (selectedItem.dataset.lookup === "specialEducationIUOperatedInLEA") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInOtherElement);
            operatedInLEAContainer.classList.remove("hide");
            this._core.forceElementRequired(operatedInLEAElement);
        }
        else {
            operatedInContainer.classList.remove("short");
            operatedInOtherContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInOtherElement);
            operatedInLEAContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInLEAElement);
        }
    }
    static checkForBuildingOtherStatic(facilityFK) {
        const select = document.querySelector(`.building-name[data-facility-pk='${facilityFK}']`);
        const thisVal = select.value;
        const otherContainer = document.querySelector(`.otherContainer[data-facility-pk='${facilityFK}']`);
        const buildingContainer = document.querySelector(`.buildingContainer[data-facility-pk='${facilityFK}']`);
        const otherElement = document.querySelector(`.otherElement[data-facility-pk='${facilityFK}']`);
        if (thisVal.toLowerCase() === "other") {
            this._staticCore.forceElementRequired(otherElement);
            otherContainer.classList.remove("hide");
            buildingContainer.classList.add("short");
        }
        else {
            this._staticCore.forceElementOptional(otherElement);
            otherContainer.classList.add("hide");
            buildingContainer.classList.remove("short");
        }
    }
    static checkForRequiredFieldsFromOperatedInStatic(facilityFK) {
        const select = document.querySelector(`.operated-in[data-facility-pk='${facilityFK}']`);
        const selectedItem = select[select.selectedIndex];
        const operatedInContainer = document.querySelector(`.operatedInContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherContainer = document.querySelector(`.operatedInOtherContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherElement = document.querySelector(`.operatedInOtherElement[data-facility-pk='${facilityFK}']`);
        const operatedInLEAContainer = document.querySelector(`.operatedInLEANameContainer[data-facilityfk='${facilityFK}']`);
        const operatedInLEAElement = document.querySelector(`.operatedInLEAName[data-facility-pk='${facilityFK}']`);
        if (selectedItem.dataset.lookup === "specialEducationIUOperatedInOther") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.remove("hide");
            this._staticCore.forceElementRequired(operatedInOtherElement);
            operatedInLEAContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInLEAElement);
        }
        else if (selectedItem.dataset.lookup === "specialEducationIUOperatedInLEA") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInOtherElement);
            operatedInLEAContainer.classList.remove("hide");
            this._staticCore.forceElementRequired(operatedInLEAElement);
        }
        else {
            operatedInContainer.classList.remove("short");
            operatedInOtherContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInOtherElement);
            operatedInLEAContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInLEAElement);
        }
    }
    static constructAutofillInputs() {
        const areaMeasurementEles = document.querySelectorAll('.specialEducationSpeicalEducationFacilitiesAreMeasurementField');
        for (let ele of areaMeasurementEles) {
            const element = ele;
            ele.addEventListener('change', (e) => this.autofillInputs(element.dataset.facilityPk, false));
        }
        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = c;
            const facilityPK = container.dataset.facilityPk;
            this.autofillInputs(facilityPK, true);
        }
    }
    static constructFileUploadElements() {
        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = c;
            const facilityPK = container.dataset.facilityPk;
            const fileUploader = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityPK}']`);
            if (fileUploader !== null) {
                fileUploader.addEventListener('change', (e) => this.uploadFile(e.target, facilityPK));
            }
        }
    }
    static constructFileDeleteElements(facilityFK = null) {
        if (facilityFK) {
            const deleteButton = document.querySelector(`.deleteFile[data-facility-pk='${facilityFK}']`);
            if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                deleteButton.addEventListener('click', (e) => this.showDeleteFileConfirmation(deleteButton));
            }
        }
        else {
            const deleteFileButtons = document.getElementsByClassName('deleteFile');
            for (let deleteButton of deleteFileButtons) {
                if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                    deleteButton.addEventListener('click', (e) => this.showDeleteFileConfirmation(deleteButton));
                }
            }
        }
        const deleteFileConfirmButton = document.getElementById('deleteFileConfirm');
        if (deleteFileConfirmButton !== null) {
            deleteFileConfirmButton.addEventListener('click', (e) => this.deleteFileConfirm(deleteFileConfirmButton));
        }
        const deleteFileCancelButton = document.getElementById('deleteFileCancel');
        if (deleteFileCancelButton !== null) {
            deleteFileCancelButton.addEventListener('click', (e) => this.deleteFileCancel());
        }
    }
    static autofillInputs(facilityFK, onPageLoad = false) {
        // Get measurement values from the inputs
        const areaMeasurements = {
            WidthFeet: {
                Element: document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`).value),
            },
            WidthInches: {
                Element: document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`).value)
            },
            LengthFeet: {
                Element: document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`).value),
            },
            LengthInches: {
                Element: document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt(document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`).value)
            }
        };
        if (!onPageLoad) {
            // Validate: user has entered a number >= 0, and if it is an inches measurement, 12 > number >= 0
            for (let am in areaMeasurements) {
                const measurement = areaMeasurements[am];
                if (measurement.Value === '' || measurement.Value === null || isNaN(measurement.Value) || parseInt(measurement.Value) < 0) {
                    measurement.Element.value = 0;
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a number greater than or equal to 0.', 'error', 3000, null);
                    return;
                }
                else if ((am === 'WidthInches' || am === 'LengthInches') && parseInt(measurement.Value) > 11) {
                    measurement.Element.value = 0;
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter an inches value between 0 and 11.', 'error', 3000, null);
                }
            }
        }
        // Calculate total inches (inches + feet*12) 
        let totalWidthInches = areaMeasurements.WidthInches.Value + (areaMeasurements.WidthFeet.Value * 12);
        let totalLengthInches = areaMeasurements.LengthInches.Value + (areaMeasurements.LengthFeet.Value * 12);
        if (isNaN(totalWidthInches))
            totalWidthInches = 0;
        if (isNaN(totalLengthInches))
            totalLengthInches = 0;
        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const areaInches = totalWidthInches * totalLengthInches;
        const areaSqFeet = Math.floor(areaInches / 144);
        const maxStudents = Math.floor(areaSqFeet / 28);
        // Update UI
        const areaEle = document.querySelector(`.specialEducationFacilitiesAreaMeasurement[data-facility-pk='${facilityFK}']`);
        areaEle.value = `${areaSqFeet}sqft`;
        const maxStudentsEle = document.querySelector(`.specialEducationFacilitiesMaxStudents[data-facility-pk='${facilityFK}']`);
        maxStudentsEle.value = `${maxStudents}`;
    }
    static uploadFile(e, facilityFK) {
        const core = this._staticCore;
        Core.showLoader();
        const uploadFeaturesForm = document.querySelector(`.specialEducationIUProfilesFacilitiesUploadFeaturesforeachClassroomSpace[data-facility-pk='${facilityFK}']`);
        const formData = new FormData(uploadFeaturesForm);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFacilitiesFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);
                        const formFile = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityFK}']`);
                        formFile.value = '';
                        formFile.dataset.hasuploaded = 'true';
                        const fileUpload = response.fileUpload;
                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/IUSpecialEducation/GetFacilityFileUploadPartialView?fileUploadPK=${fileUpload.fileUploadPK}&fileContentType=${fileUpload.fileContentType}&fileName=${fileUpload.fileName}&specialEducationFacilityPK=${fileUpload.specialEducationFacilityPK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`);
                                fileUploadDiv.innerHTML = xhrForPartialView.response;
                                this.constructFileDeleteElements(facilityFK);
                            }
                        };
                        xhrForPartialView.send();
                        this.initializeFacilityHashes();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    static showDeleteFileConfirmation(deleteButton) {
        const fileUploadPK = deleteButton.dataset.fileuploadPk;
        const facilityPK = deleteButton.dataset.facilityPk;
        if (fileUploadPK && parseInt(fileUploadPK) > 0) {
            const modal = new Modal('deleteFileModal', null);
            const deleteConfirmButton = document.getElementById('deleteFileConfirm');
            deleteConfirmButton.dataset.fileuploadPk = fileUploadPK;
            deleteConfirmButton.dataset.facilityPk = facilityPK;
            modal.show();
        }
    }
    static deleteFileConfirm(confirmButton) {
        const facilityFK = parseInt(confirmButton.dataset.facilityPk);
        const containerElement = document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`);
        if (containerElement !== null) {
            containerElement.removeChild(containerElement.firstChild);
            this.initializeFacilityHashes();
        }
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    static deleteFileCancel() {
        const modal = new Modal('deleteFileModal', null);
        modal.hide();
    }
    static validateElements(facilityPK) {
        const parent = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
        const textInputs = parent.querySelectorAll("input[type='text'], input[type='number'], input[type='date']");
        const selects = parent.querySelectorAll("select");
        let hasInputErrors = false;
        let hasSelectErrors = false;
        for (const input of textInputs) {
            const element = input;
            if ("facilityPk" in element.dataset && "percent" in element.dataset) {
                //The feet fields are somewhat of a special case, so handle them separately (inches will be validated the normal
                //way, because a classroom can't simply be inches in either direction)/
                if (!(element.classList.contains("feet"))) {
                    if (element.dataset.percent === "1.00" && element.value === "") {
                        element.classList.add("missing-field");
                        element.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(element);
                        hasInputErrors = true;
                    }
                    else {
                        element.classList.remove("missing-field");
                        element.removeAttribute("aria-invalid");
                        Core.removeErrorLabelForInput(element);
                    }
                }
                else {
                    const allFeet = document.querySelectorAll(`.feet[data-facility-pk='${facilityPK}']`);
                    let missingDimensions = false;
                    for (const feet of allFeet) {
                        const ele = feet;
                        if (ele.value === "" || ele.value === "0") {
                            missingDimensions = true;
                        }
                        for (const secondFeet of allFeet) {
                            const element = secondFeet;
                            if (missingDimensions) {
                                element.classList.add("missing-field");
                                element.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(element);
                                hasInputErrors = true;
                            }
                            else {
                                element.classList.remove("missing-field");
                                element.removeAttribute("aria-invalid");
                                Core.removeErrorLabelForInput(element);
                            }
                        }
                    }
                }
            }
        }
        for (const select of selects) {
            const element = select;
            if ("facilityPk" in element.dataset && "percent" in element.dataset) {
                if (element.dataset.percent === "1.00" && (element.value === "" || element.value === "0")) {
                    element.classList.add("missing-field");
                    element.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(element);
                    hasSelectErrors = true;
                }
                else {
                    element.classList.remove("missing-field");
                    element.removeAttribute("aria-invalid");
                    Core.removeErrorLabelForInput(element);
                }
            }
        }
        return !(hasInputErrors || hasSelectErrors);
    }
    static promiseSave(specialEducationFacilityPK = null, forceSave = false, onWindowUnload = false, forSEPRN = false) {
        let _this = this;
        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData = [];
            let facilitiesToSave;
            if (specialEducationFacilityPK !== null) {
                facilitiesToSave = document.querySelectorAll(`.facilityContainer[data-facility-pk='${specialEducationFacilityPK}']`);
            }
            else {
                //Get all the facilities on the page
                facilitiesToSave = document.querySelectorAll('.facilityContainer');
            }
            for (let facility of facilitiesToSave) {
                let assuranceChecksData = [];
                if ('facilityPk' in facility.dataset) {
                    shouldSave = true;
                    let facilityPK = facility.dataset.facilityPk;
                    // Get Building Name 
                    let buildingName = "";
                    const buildingNameEle = facility.querySelector(`.building-name[data-facility-pk='${facilityPK}']`);
                    if (buildingNameEle !== null)
                        buildingName = buildingNameEle.value;
                    let buildingNameOther = "";
                    const buildingNameOtherEle = facility.querySelector(`.building-name-other[data-facility-pk='${facilityPK}']`);
                    if (buildingNameOtherEle !== null)
                        buildingNameOther = buildingNameOtherEle.value;
                    // Get RoomDesc
                    let roomDesc = null;
                    let room = facility.querySelector(`.room-desc[data-facility-pk='${facilityPK}']`);
                    if (room !== null && room.value != '') {
                        roomDesc = room.value;
                    }
                    else if (onWindowUnload) {
                        roomDesc = 0;
                    }
                    let operatedInLookupCodeFK = null;
                    let operatedIn = facility.querySelector(`.operated-in[data-facility-pk='${facilityPK}']`);
                    let hasOther = false;
                    let hasLEA = false;
                    if (operatedIn !== null) {
                        operatedInLookupCodeFK = operatedIn.value;
                        const selectedItem = operatedIn[operatedIn.selectedIndex];
                        if (selectedItem !== null)
                            if (selectedItem.dataset.lookup === "specialEducationIUOperatedInLEA")
                                hasLEA = true;
                            else if (selectedItem.dataset.lookup === "specialEducationIUOperatedInOther")
                                hasOther = true;
                    }
                    let operatedInLEA = null;
                    if (hasLEA) {
                        let operatedInLEAEle = facility.querySelector(`.operated-in-lea[data-facility-pk='${facilityPK}']`);
                        if (operatedInLEAEle !== null)
                            operatedInLEA = operatedInLEAEle.value;
                    }
                    let operatedInOther = null;
                    if (hasOther) {
                        let operatedInOtherEle = facility.querySelector(`.operated-in-other[data-facility-pk='${facilityPK}']`);
                        if (operatedInOtherEle !== null)
                            operatedInOther = operatedInOtherEle.value;
                    }
                    // Get BuildingDescriptionLookupCodeFK
                    let buildingDescriptionLookupCodeFK = null;
                    let buildingDescription = facility.querySelector(`.building-description[data-facility-pk='${facilityPK}']`);
                    if (buildingDescription != null && buildingDescription.value != '') {
                        buildingDescriptionLookupCodeFK = buildingDescription[buildingDescription.selectedIndex].dataset.lookupCode;
                    }
                    // Get SchoolBuildingLookupCodeFK
                    let schoolBuildingLookupCodeFK = null;
                    let schoolBuilding = facility.querySelector(`.school-building[data-facility-pk='${facilityPK}']`);
                    if (schoolBuilding != null && schoolBuilding.value != '') {
                        schoolBuildingLookupCodeFK = schoolBuilding[schoolBuilding.selectedIndex].dataset.lookupCode;
                    }
                    // Get ClassroomWidth
                    let classroomWidth = null;
                    let widthFt = facility.querySelector(`.width-ft[data-facility-pk='${facilityPK}']`);
                    let widthIn = facility.querySelector(`.width-in[data-facility-pk='${facilityPK}']`);
                    if (widthFt != null && widthFt.value != '' && widthIn != null && widthIn.value != '') {
                        let width = parseInt(widthIn.value) + (parseInt(widthFt.value) * 12);
                        classroomWidth = width;
                    }
                    // Get Classroomlength
                    let classroomLength = null;
                    let lengthFt = facility.querySelector(`.length-ft[data-facility-pk='${facilityPK}']`);
                    let lengthIn = facility.querySelector(`.length-in[data-facility-pk='${facilityPK}']`);
                    if (lengthFt != null && lengthFt.value != '' && lengthIn != null && lengthIn.value != '') {
                        let length = parseInt(lengthIn.value) + (parseInt(lengthFt.value) * 12);
                        classroomLength = length;
                    }
                    // Get FileUploadFK
                    let fileUploadFK = null;
                    let file = facility.querySelector(`button.delete-row.deleteFile[data-facility-pk='${facilityPK}']`);
                    if (file != null) {
                        fileUploadFK = file.dataset.fileuploadPk;
                    }
                    // Get ImplementationDate
                    let implementationDate = null;
                    let date = facility.querySelector(`.implementation-date[data-facility-pk='${facilityPK}']`);
                    if (date != null && date.value != '') {
                        implementationDate = date.value;
                    }
                    // Get assurance checks
                    let assuranceChecks = [];
                    let assuranceChecksForFacility = document.querySelectorAll(`.assurance-check-row[data-facility-pk='${facilityPK}']`);
                    for (let assuranceCheck of assuranceChecksForFacility) {
                        let assuranceCheckElement = assuranceCheck;
                        if ('facilityPk' in assuranceCheckElement.dataset) {
                            // Get assurance PK
                            let specialEducationFacilityAssuranceCheckPK = null;
                            let assurancePK = assuranceCheckElement.dataset.assurancePk;
                            if (assurancePK != null && assurancePK != '') {
                                specialEducationFacilityAssuranceCheckPK = assurancePK;
                            }
                            // Get PropertyFK
                            let propertyFK = null;
                            let property = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']`);
                            propertyFK = property.dataset.propertypk;
                            // Get LookupCodeFK (Yes/No)
                            let lookupCodeFK = null;
                            let lookupCode = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']:checked`);
                            lookupCodeFK = lookupCode.value;
                            const facilityAssuranceCheckSaveData = {
                                SpecialEducationIUFacilityAssuranceCheckPK: parseInt(specialEducationFacilityAssuranceCheckPK),
                                SpecialEducationIUFacilityFK: parseInt(facilityPK),
                                PropertyFK: parseInt(propertyFK),
                                LookupCodeFK: parseInt(lookupCodeFK)
                            };
                            assuranceChecks.push(facilityAssuranceCheckSaveData);
                        }
                    }
                    const facilitiesSaveData = {
                        SpecialEducationIUFacilityPK: parseInt(facilityPK),
                        RoomDesc: roomDesc,
                        SchoolBuildingLookupCodeFK: schoolBuildingLookupCodeFK,
                        BuildingDescriptionLookupCodeFK: buildingDescriptionLookupCodeFK,
                        ClassroomWidth: classroomWidth,
                        ClassroomLength: classroomLength,
                        FileUploadFK: fileUploadFK,
                        ImplementationDate: implementationDate,
                        PlanFK: _this.staticPlanFK,
                        OperatedInLookupCodeFK: operatedInLookupCodeFK,
                        OperatedInLEAName: operatedInLEA,
                        OperatedInOther: operatedInOther,
                        BuildingName: buildingName,
                        BuildingNameOther: buildingNameOther
                        //StatusCode
                    };
                    const thisFacilitiesSaveData = {
                        Facility: facilitiesSaveData,
                        AssuranceChecks: assuranceChecks
                    };
                    saveData.push(thisFacilitiesSaveData);
                }
            }
            if (shouldSave || forceSave) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUFacilities', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        if (specialEducationFacilityPK != null) {
                            const response = JSON.parse(xhr.responseText);
                            const accordionButton = document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${specialEducationFacilityPK}']`);
                            if (accordionButton)
                                accordionButton.dataset.validdata = response.isValid.toString().toLowerCase();
                            SpecialEducationIUFacilities.getValidFacilityCount();
                            SpecialEducationIUFacilities.clearValidationMessage();
                            SpecialEducationIUFacilities.fullPageValidation();
                            SpecialEducationIUFacilities.initializeLoadedFacility(specialEducationFacilityPK);
                            SpecialEducationIUFacilities.planPageCompleteCheck();
                            resolve('Successfully saved Facility');
                        }
                        else {
                            resolve('Successfully saved');
                        }
                    }
                    else {
                        reject('There was an unexpected error saving');
                    }
                };
                xhr.send(JSON.stringify({
                    'Data': saveData,
                    'PlanFK': _this.staticPlanFK,
                    'CanSaveSEPRN': forSEPRN
                }));
            }
            else {
                resolve('Nothing To Save');
            }
        });
    }
    static bindOperatedByChanges() {
        const operatedBys = document.getElementsByClassName("operatedBy");
        for (const operatedBy of operatedBys)
            operatedBy.addEventListener("change", (e) => this.changeOperatedBy(e.target));
    }
    static changeOperatedBy(element) {
        const facilityFK = element.dataset.facilityPk;
        const selectedItem = element[element.selectedIndex];
        const value = selectedItem.dataset.lookup;
        const schoolDistrictNameInput = document.querySelector(`.schoolDistrictName[data-facility-pk='${facilityFK}']`);
        const districtNameElement = document.querySelector(`.operatedBySchoolDistrictName[data-facilityfk='${facilityFK}']`);
        if (value === "specialEducationIUOperatedBySchoolDistrict") {
            districtNameElement.classList.remove("hide");
            this._staticCore.forceElementRequired(schoolDistrictNameInput);
        }
        else {
            districtNameElement.classList.add("hide");
            this._staticCore.forceElementOptional(schoolDistrictNameInput);
        }
    }
    //Calculates a hash value for each facility on the page and is stored in an object as a property of this class.
    static initializeFacilityHashes() {
        let that = this;
        that.facilityHashes = {};
        let facilityContainers = document.getElementsByClassName("facilityContainer");
        for (let facility of facilityContainers) {
            let facilityPK = parseInt(facility.dataset.facilityPk);
            let hash = that.calculateFacilityHash(facilityPK);
            that.facilityHashes[facilityPK] = hash;
        }
    }
    //Calculates a hash for a facility. This is used to determine whether the facility has changed for saving purposes.
    static calculateFacilityHash(facilityPK) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;
        let allElements = document.querySelectorAll(`[data-hashable][data-facility-pk='${facilityPK}']`);
        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                const inputElement = element;
                if (inputElement.type === "radio") {
                    if (inputElement.checked) {
                        value = inputElement.value;
                        newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
                else {
                    if (inputElement.value === "") {
                        value = 0;
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
            }
            else if (element instanceof HTMLSelectElement) {
                const selectElement = element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value;
                    newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLTextAreaElement) {
                const textAreaElement = element;
                if (textAreaElement.value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            }
            else if (element instanceof HTMLAnchorElement) {
                const anchorElement = element;
                value = anchorElement.dataset.fileuploadPk;
                newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                hash = hash + newHash;
            }
        }
        return hash;
    }
    static initializeLoadedFacility(facilityFK) {
        this.constructAutofillInputs();
        this.bindOperatedByChanges();
        const operatedBys = document.getElementsByClassName("operatedBy");
        for (const operatedBy of operatedBys)
            this.changeOperatedBy(operatedBy);
        this.initializeFacilityHashes();
        this.handleFacilitySaveDisabled(facilityFK);
        this.validateElements(facilityFK);
    }
    //The save button only becomes available for an facility if there is a change within the facility
    static handleFacilitySaveDisabled(facilityPK) {
        let saveButton = document.querySelector(`.facilitiesSave[data-facilitypk='${facilityPK}']`);
        if (saveButton != null) {
            if (this.facilityHasChanged(facilityPK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            }
            else {
                saveButton.disabled = true;
            }
        }
    }
    //Determines whether a facility has changed by comparing the current hash value to a prior hash value
    static facilityHasChanged(facilityPK) {
        let that = this;
        let changed = false;
        if (that.facilityHashes[facilityPK] !== undefined) {
            let newHash = that.calculateFacilityHash(facilityPK);
            if (newHash !== that.facilityHashes[facilityPK]) {
                changed = true;
            }
        }
        else {
            //Be on the safe side and say it has changed
            changed = true;
        }
        return changed;
    }
    static initializeLoadedFacilityFields(facilityFK) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-specialeducationfacilityfk='${facilityFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-specialeducationfacilityfk='${facilityFK}'] select`);
        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
    static fullPageValidation() {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const totalErrorsNotShown = totalErrors - shownAccordions.length;
        let errorCount = 0;
        for (const accordion of allAccordions) {
            const accElement = accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;
            if (valid === "false" && !accElement.dataset.isnew) {
                accElement.classList.add("error");
                errorCount++;
            }
            else {
                accElement.classList.remove("error");
            }
        }
        const messageContainer = document.getElementById("validationMessageContainer");
        const validationIcon = document.getElementById("validationMessageIcon");
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.add("show");
        const message = document.getElementById("validationMessage");
        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");
        if (errorCount > 0) {
            let word = "are";
            let facility = "facilities";
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "facility";
            }
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;
            let facilityWord = "are";
            let facilityFacility = "facilities";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "facility";
            }
            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            if (message !== null) {
                message.innerHTML = "The page has been successfully saved.";
                message.classList.add("show");
            }
        }
    }
    static getValidFacilityCount() {
        return __awaiter(this, void 0, void 0, function* () {
            const planForm = document.getElementById("specialEducationIUFacilitiesForm");
            const planFK = planForm.dataset.planfk;
            const response = yield fetch(`/IUSpecialEducation/GetValidFacilitiesCount/${planFK}`, { credentials: 'include' });
            if (response.ok) {
                const value = yield response.text();
                const field = document.getElementById("validCount");
                field.value = value;
            }
        });
    }
    static clearValidationMessage() {
        const messageContainerColumn = document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }
    static planPageCompleteCheck() {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById("specialEducationIUFacilitiesForm");
            const planFK = form.dataset.planfk;
            const pageCode = form.dataset.pagecode;
            const response = yield fetch(`/IUSpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' });
            if (response.ok) {
                const value = yield response.json();
                const menuElement = document.querySelector("#leftBarspecialEducationIUFacilitiesParent .status-indicator");
                if (value.thisPage) {
                    menuElement.classList.add("complete");
                }
                else {
                    menuElement.classList.remove("complete");
                }
            }
        });
    }
    static createSEPRN(facilityPK) {
        Core.showLoader();
        let xhr = new XMLHttpRequest();
        let proposedActionValue = "";
        let additionalInformationValue = "";
        let proposedActionElement = document.querySelector(`.proposedAction[data-facility-pk='${facilityPK}']`);
        if (proposedActionElement != null) {
            proposedActionValue = proposedActionElement.value;
        }
        let additionalInformationElement = document.querySelector(`.additionalInformation[data-facility-pk='${facilityPK}']`);
        if (additionalInformationElement != null) {
            additionalInformationValue = additionalInformationElement.value;
        }
        if (proposedActionElement.value != "") {
            let selected = proposedActionElement[proposedActionElement.selectedIndex];
            if ((selected.dataset.lookupCode == "proposedActionDelete" || selected.dataset.lookupCode == "proposedActionChange") && additionalInformationValue == "") {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'Please specify a reason/explanation for this SEPRN', 'error', 3000, null);
            }
            else if (selected.dataset.lookupCode == "proposedActionNew" && !this.validateElements(facilityPK)) {
                Core.hideLoader();
            }
            else {
                fetch('/IUSpecialEducation/CreateSEPRN', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        IUFacilityPK: facilityPK,
                        ProposedAction: proposedActionValue,
                        AdditionalInformation: additionalInformationValue
                    })
                })
                    .then(() => this.promiseSave(facilityPK, false, false, true))
                    .then((response) => {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully submitted SEPRN', 'success', 3000, window.location.reload());
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue submitting SEPRN', 'error', 3000, null);
                    Core.hideLoader();
                });
            }
        }
        else {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a proposed action for the SEPRN', 'error', 3000, null);
            Core.hideLoader();
        }
    }
    static initializeSEPRNWorkflow(seprnWorkflow) {
        let seprnContainer = document.querySelector(`.seprnContainer[data-facility-pk][data-workflow-instance-pk='${seprnWorkflow.WorkflowInstancePK}']`);
        if (seprnContainer != null) {
            let specialEducationFacilityPK = parseInt(seprnContainer.dataset.facilityPk);
            //Override so that review submission stays on current page instead of going to review admin
            seprnWorkflow.ReviewURL = '';
            seprnWorkflow.BeforeSave = () => this.promiseSave(specialEducationFacilityPK, false, false, true);
            let proposedChangeAction = seprnWorkflow.ActionResponses.filter(ac => ac.ActionCode == "seprnType");
            if (proposedChangeAction.length > 0) {
                let proposedChangeActionRadio = proposedChangeAction[0];
                let deletedRadio = proposedChangeActionRadio.RadioElements.filter(re => "lookupCode" in re.dataset && re.dataset.lookupCode == "proposedActionDelete");
                if (deletedRadio != null && deletedRadio.length > 0) {
                    if (deletedRadio[0].checked) {
                        const actionsToDisable = ['onsiteReviewSEPRN', 'ebbFlowSEPRN', 'noiseInterfereSEPRN', 'designedForInstructionSEPRN', 'readilyAccessibleSEPRN', 'squareFeet28SEPRN'];
                        for (let action of seprnWorkflow.ActionResponses.filter(a => actionsToDisable.indexOf(a.ActionCode) >= 0 && a instanceof WorkflowActionResponseRadio)) {
                            let radioAction = action;
                            for (let radio of radioAction.RadioElements) {
                                radio.disabled = true;
                                if ("lookupCode" in radio.dataset && radio.dataset.lookupCode == "actionNA") {
                                    radio.checked = true;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// SpecialEducationIUTraining
class SpecialEducationIUTraining {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUPersonnelCheckboxField", "specialEducationIUTrainingCheckboxField", "specialEducationIUTrainingField", "specialEducationIUPersonnelFileField"];
        let specialEducationIUTrainingSaveButton = document.getElementById("specialEducationIUTrainingSave");
        if (specialEducationIUTrainingSaveButton !== null)
            specialEducationIUTrainingSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation();
        }
        this.customInitializeRequiredFields();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fileUploader = document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    customDoValidation() {
        let showMessage = this.customClientSideValidation();
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation() {
        let showMessage = false;
        let totalErrors = 0;
        const specialEducationIUTrainingSummaryFields = document.querySelectorAll(".specialEducationIUTrainingSummary[data-percent='1.00']");
        const specialEducationIUTrainingPersonnelNeedsAssessmentFields = document.querySelectorAll(".specialEducationIUTrainingPersonnelNeedsAssessment:checked");
        const specialEducationIUTrainingPopulationAssessedFields = document.querySelectorAll(".specialEducationIUTrainingPopulationAssessed:checked");
        const specialEducationIUTrainingProcedureFields = document.querySelectorAll(".specialEducationIUTrainingProcedure:checked");
        const specialEducationIUTrainingNeedsField = document.querySelector(".specialEducationIUTrainingNeeds");
        const files = document.querySelectorAll(".specialEducationIUPersonnelFileUpload");
        const specialEducationIUTrainingProcedureOtherField = document.querySelector(".specialEducationIUTrainingProcedureOther");
        const specialEducationIUTechnicalPersonnelOtherTextField = document.querySelector("#specialEducationIUTechnicalPersonnelOtherText");
        const specialEducationIUTrainingPersonnelNeedsAssessmentTitle = document.getElementById("specialEducationIUTrainingPersonnelNeedsAssessmentTitle");
        const specialEducationIUTrainingPopulationAssessedTitle = document.getElementById("specialEducationIUTrainingPopulationAssessedTitle");
        const specialEducationIUTrainingProcedureTitle = document.getElementById("specialEducationIUTrainingProcedureTitle");
        const specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData = document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData");
        if (specialEducationIUTrainingPersonnelNeedsAssessmentFields.length === 0) {
            specialEducationIUTrainingPersonnelNeedsAssessmentTitle.classList.add("missing-field");
            specialEducationIUTrainingPersonnelNeedsAssessmentTitle.setAttribute("aria-invalid", "true");
            showMessage = true;
            totalErrors++;
        }
        if (specialEducationIUTrainingPopulationAssessedFields.length === 0) {
            specialEducationIUTrainingPopulationAssessedTitle.classList.add("missing-field");
            specialEducationIUTrainingPopulationAssessedTitle.setAttribute("aria-invalid", "true");
            showMessage = true;
            totalErrors++;
        }
        if (specialEducationIUTrainingNeedsField.value === "") {
            specialEducationIUTrainingNeedsField.classList.add("missing-field");
            specialEducationIUTrainingNeedsField.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(specialEducationIUTrainingNeedsField);
            showMessage = true;
            totalErrors++;
        }
        if (files.length === 0) {
            specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData.classList.add("missing-field");
            specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData);
            showMessage = true;
            totalErrors++;
        }
        for (const specialEducationIUTrainingSummaryField of specialEducationIUTrainingSummaryFields) {
            const field = specialEducationIUTrainingSummaryField;
            if (field.value === "") {
                field.classList.add("missing-field");
                field.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(field);
                showMessage = true;
                totalErrors++;
            }
        }
        if (specialEducationIUTrainingProcedureOtherField.checked) {
            if (specialEducationIUTechnicalPersonnelOtherTextField.value === "") {
                specialEducationIUTechnicalPersonnelOtherTextField.classList.add("missing-field");
                specialEducationIUTechnicalPersonnelOtherTextField.setAttribute("aria-invalid", "true");
                specialEducationIUTrainingProcedureTitle.classList.add("missing-field");
                specialEducationIUTrainingProcedureTitle.setAttribute("aria-invalid", "true");
                showMessage = true;
                totalErrors++;
            }
        }
        else if (specialEducationIUTrainingProcedureFields.length === 0 && !specialEducationIUTrainingProcedureOtherField.checked) {
            specialEducationIUTrainingProcedureTitle.innerHTML += " <span class='required-label'>*</span>";
            specialEducationIUTrainingProcedureTitle.classList.add("isRequired");
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        return showMessage;
    }
    customInitializeRequiredFields() {
        const specialEducationIUTrainingPersonnelNeedsAssessmentTitle = document.getElementById("specialEducationIUTrainingPersonnelNeedsAssessmentTitle");
        const specialEducationIUTrainingPopulationAssessedTitle = document.getElementById("specialEducationIUTrainingPopulationAssessedTitle");
        const specialEducationIUTrainingProcedureTitle = document.getElementById("specialEducationIUTrainingProcedureTitle");
        const specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectDataLabel = document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectDataLabelId");
        specialEducationIUTrainingPersonnelNeedsAssessmentTitle.innerHTML += " <span class='required-label'>*</span>";
        specialEducationIUTrainingPersonnelNeedsAssessmentTitle.classList.add("isRequired");
        specialEducationIUTrainingPopulationAssessedTitle.innerHTML += " <span class='required-label'>*</span>";
        specialEducationIUTrainingPopulationAssessedTitle.classList.add("isRequired");
        specialEducationIUTrainingProcedureTitle.innerHTML += " <span class='required-label'>*</span>";
        specialEducationIUTrainingProcedureTitle.classList.add("isRequired");
        const classes = ["specialEducationIUTrainingSummary", "specialEducationIUPersonnelFileField", "specialEducationIUTrainingNeeds"];
        this._core.initializeRequiredFields(classes);
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const allFileElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUTrainingForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("specialEducationIUTrainingField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.querySelectorAll(".specialEducationIUPersonnelCheckboxField, .specialEducationIUTrainingCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const files = document.getElementsByClassName("specialEducationIUPersonnelFileUpload");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = element.dataset.fileuploadpk;
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }
        const saveData = {
            "SaveElements": allSaveElements,
            "FileElements": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTraining', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`#specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList`);
                        let currentFileList = fileList.querySelectorAll(".uploadFileList");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("specialEducationIUPersonnelFileUpload");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.addAttributeToElement("iuPersonnelDeleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.specialEducationIUPersonnelFileUpload[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById("uploadedFiles");
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.hide();
    }
}
// SpecialEducationIUPersonnel
class SpecialEducationIUPersonnel {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUPersonnelField", "specialEducationIUPersonnelCheckboxField"];
        let specialEducationIUPersonnelSaveButton = document.getElementById("specialEducationIUPersonnelSave");
        if (specialEducationIUPersonnelSaveButton !== null)
            specialEducationIUPersonnelSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fileUploader = document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUPersonnelForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUPersonnelField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("specialEducationIUPersonnelCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("specialEducationIUPersonnelFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUPersonnel', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`#specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList`);
                        let currentFileList = fileList.querySelectorAll(".uploadFileList");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("specialEducationIUPersonnelFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.addAttributeToElement("iuPersonnelDeleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.specialEducationIUPersonnelFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById("uploadedFiles");
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.hide();
    }
}
// SpecialEducationIUPDEObjectives
class SpecialEducationIUPDEObjectives {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUPDEObjectivesCheckboxField"];
        let specialEducationIUPDEObjectivesSaveButton = document.getElementById("specialEducationIUPDEObjectivesSave");
        if (specialEducationIUPDEObjectivesSaveButton !== null)
            specialEducationIUPDEObjectivesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUPDEObjectivesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let checkboxes = document.getElementsByClassName("specialEducationIUPDEObjectivesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUPDEObjectives', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Nothing to save.", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    doValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        const required = [
            "specialEducationIUTechnicalPDEObjectivesTraining",
            "specialEducationIUTechnicalPDEObjectivesFederalGrants",
        ];
        const others = [
            "specialEducationIUTechnicalPDEObjectivesDevelop",
            "specialEducationIUTechnicalPDEObjectivesLocalSchoolDistricts",
            "specialEducationIUTechnicalPDEObjectivesLocalCommunity",
            "specialEducationIUTechnicalPDEObjectivesCoordinateDataSystem",
            "specialEducationIUTechnicalPDEObjectivesDevelopingTraining",
            "specialEducationIUTechnicalPDEObjectivesAssistLEA"
        ];
        for (const prop of required) {
            const checkbox = document.getElementById(prop);
            if (!checkbox.checked)
                totalErrors++;
        }
        let otherCount = 0;
        for (const prop of others) {
            const checkbox = document.getElementById(prop);
            if (checkbox.checked)
                otherCount++;
        }
        if (otherCount < 3)
            totalErrors++;
        let message = document.getElementById("validationMessage");
        if (totalErrors > 0) {
            message.innerHTML = "<p class='validationErrorCountMessage'>Intermediate Units MUST satisfy the objectives participation requirements.</p>";
            showMessage = true;
        }
        return showMessage;
    }
}
// SpecialEducationIUDevelopment
class SpecialEducationIUDevelopment {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUDevelopmentField", "specialEducationIUDevelopmentRadioField"];
        let specialEducationIUDevelopmentSaveButton = document.getElementById("specialEducationIUDevelopmentSave");
        if (specialEducationIUDevelopmentSaveButton !== null)
            specialEducationIUDevelopmentSaveButton.addEventListener("click", (e) => this.save("save"));
        this.setupRadioElements();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let addIndicatorTrainingGoalButtons = document.getElementsByClassName("addIndicatorTrainingGoal");
        for (let addIndicatorTrainingGoalButton of addIndicatorTrainingGoalButtons)
            addIndicatorTrainingGoalButton.addEventListener("click", (e) => this.addIndicatorTrainingGoal(e));
        let radioChanges = document.getElementsByClassName("specialEducationIUDevelopmentRadioField");
        for (let radioButton of radioChanges)
            radioButton.addEventListener("change", (e) => this.changeRadioElement(e.target));
        let iuSpecialEducationTechnicalAssistanceRecordDeleteCancelButton = document.getElementById("iuSpecialEducationTechnicalAssistanceRecordDeleteCancel");
        if (iuSpecialEducationTechnicalAssistanceRecordDeleteCancelButton !== null)
            iuSpecialEducationTechnicalAssistanceRecordDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationTechnicalAssistanceRecordDeleteConfirmButton = document.getElementById("iuSpecialEducationTechnicalAssistanceRecordDeleteConfirm");
        if (iuSpecialEducationTechnicalAssistanceRecordDeleteConfirmButton !== null)
            iuSpecialEducationTechnicalAssistanceRecordDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        this.bindDeleteIndicatorGoal();
        const specialEducationIUDevelopmentExportToExcel = document.getElementById("specialEducationIUDevelopmentExportToExcel");
        if (specialEducationIUDevelopmentExportToExcel !== null)
            specialEducationIUDevelopmentExportToExcel.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUDevelopmentForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUDevelopmentField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("specialEducationIUDevelopmentRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addIndicatorTrainingGoal(e) {
        return __awaiter(this, void 0, void 0, function* () {
            let buttonElement = e.target;
            let propertyCodeSection = buttonElement.dataset.propertycodesection;
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("specialEducationIUDevelopmentForm");
            numberOfRows = 1;
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll(".personnelDevelopmentPlanRowItem");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                newRow++;
                let response = yield fetch(`/IUSpecialEducation/AddPersonnelDevelopmentIndicatorRow/${planFK}/${newRow}/${propertyCodeSection}`, { credentials: 'include' });
                if (response.ok) {
                    const output = yield response.text();
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = output;
                    newDiv.dataset.row = newRow.toString();
                    newDiv.dataset.propertycodesection = propertyCodeSection;
                    newDiv.classList.add("personnelDevelopmentPlanRowItem");
                    newDiv.classList.add("margin-top-xl");
                    let planRow = document.querySelector(`.personnelDevelopmentContainer[data-propertycodesection='${propertyCodeSection}']`);
                    planRow.append(newDiv);
                    that.bindDeleteIndicatorGoal();
                }
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Record added!", 'success', 2000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", 'There was an issue adding a row.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    setupRadioElements() {
        let radioChanges = document.getElementsByClassName("specialEducationIUDevelopmentRadioField");
        for (let radioButton of radioChanges)
            this.changeRadioElement(radioButton);
    }
    changeRadioElement(radioElement) {
        let propertyCodeSection = radioElement.dataset.propertycodesection;
        let personnelDevelopmentPlanRowElement = document.querySelector(`.personnelDevelopmentPlanRow[data-propertycodesection='${propertyCodeSection}']`);
        if (radioElement.checked) {
            if (radioElement.dataset.lookuplabel.toLowerCase() === "yes") {
                if (personnelDevelopmentPlanRowElement !== null) {
                    personnelDevelopmentPlanRowElement.classList.remove("hide");
                    let allElementsToRequire = document.querySelectorAll(`.personnelDevelopmentPlanRow[data-propertycodesection='${propertyCodeSection}'] .specialEducationIUDevelopmentPersonnelSPPField`);
                    for (let ele of allElementsToRequire) {
                        let htmlEle = ele;
                        this._core.forceElementRequired(htmlEle);
                    }
                }
            }
            else {
                if (personnelDevelopmentPlanRowElement !== null) {
                    personnelDevelopmentPlanRowElement.classList.add("hide");
                    let allElementsToNotRequire = document.querySelectorAll(`.personnelDevelopmentPlanRow[data-propertycodesection='${propertyCodeSection}'] .specialEducationIUDevelopmentPersonnelSPPField`);
                    for (let ele of allElementsToNotRequire) {
                        let htmlEle = ele;
                        this._core.forceElementOptional(htmlEle);
                    }
                }
            }
        }
    }
    bindDeleteIndicatorGoal() {
        let indicatorDeleteGoalButtons = document.getElementsByClassName("deleteIndicatorGoal");
        for (let indicatorDeleteGoalButton of indicatorDeleteGoalButtons)
            indicatorDeleteGoalButton.addEventListener("click", (e) => this.deleteIndicatorGoal(e));
    }
    deleteIndicatorGoal(e) {
        let button = e.target;
        let propertyCodeSection = button.dataset.propertycodesection;
        let row = button.dataset.row;
        let modal = new Modal("deleteTechnicalAssistanceRecordModal", null);
        modal.addAttributeToElement("deleteTechnicalAssistanceRecordModal", "#iuSpecialEducationTechnicalAssistanceRecordDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteTechnicalAssistanceRecordModal", "#iuSpecialEducationTechnicalAssistanceRecordDeleteConfirm", "propertycodesection", propertyCodeSection);
        modal.show();
    }
    deleteRowCancel() {
        let modal = new Modal("deleteTechnicalAssistanceRecordModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let propertyCodeSection = buttonElement.dataset.propertycodesection;
        let allElements = document.querySelectorAll(`.specialEducationIUDevelopmentPersonnelSPPField[data-row='${row}'][data-propertycodesection='${propertyCodeSection}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.personnelDevelopmentPlanRowItem[data-row='${row}'][data-propertycodesection='${propertyCodeSection}']`);
                rowElement.remove();
                let modal = new Modal("deleteTechnicalAssistanceRecordModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The record has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    initializeRequiredFieldsCustom(allClasses) {
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                        alreadyExists = true;
                    }
                }
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                ////check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                //var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                //for (let otherElement of otherElements) {
                //    let otherHtmlElement = <HTMLElement>otherElement;
                //    if (otherHtmlElement.classList.contains("missing-field")) {
                //        alreadyExists = true;
                //    } else {
                //        if (otherHtmlElement instanceof HTMLInputElement) {
                //            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                //                //Check to see if a prior group of radio buttons has already been validated
                //                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                //                if (OtherInputElement.hasAttribute("name")) {
                //                    let radioGroupName = OtherInputElement.getAttribute("name");
                //                    let radios = document.getElementsByName(radioGroupName);
                //                    let radioIsChecked: boolean = false;
                //                    let isAlreadyValidated: boolean = false;
                //                    for (var i = 0, length = radios.length; i < length; i++) {
                //                        let radioElement = <HTMLInputElement>radios[i];
                //                        if (radioElement.checked) {
                //                            radioIsChecked = true;
                //                        }
                //                        if (radioElement.classList.contains("missing-field")) {
                //                            isAlreadyValidated = true;
                //                        }
                //                    }
                //                    if (isAlreadyValidated || radioIsChecked) {
                //                        alreadyExists = true;
                //                    }
                //                }
                //            } else {
                //                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                //                if (OtherInputElement.value !== "") {
                //                    alreadyExists = true;
                //                }
                //            }
                //        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                //            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                //            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                //                alreadyExists = true;
                //            }
                //        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                //            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                //            if (otherTextAreaElement.value !== "") {
                //                alreadyExists = true;
                //            }
                //        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                //            //See if any options have been checked in multiselect
                //            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                //            for (let selectBox of multiselectCheckboxes) {
                //                if (selectBox.checked) {
                //                    alreadyExists = true;
                //                    break;
                //                }
                //            }
                //        }
                //    }
                //}
                if (!element.classList.contains("missing-field")) {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = element;
                        //Only validate once for radio buttons
                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                            if (inputElement.hasAttribute("name")) {
                                let radioName = inputElement.getAttribute("name");
                                let radioButtons = document.getElementsByName(radioName);
                                let alreadyValidated = false;
                                let isChecked = false;
                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                    let radioElement = radioButtons[i];
                                    if (radioElement.classList.contains("missing-field")) {
                                        alreadyValidated = true;
                                    }
                                    if (radioElement.checked) {
                                        isChecked = true;
                                    }
                                }
                                if (!alreadyValidated && !isChecked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                        else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLSelectElement) {
                        let selectElement = element;
                        if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                            selectElement.classList.add("missing-field");
                            selectElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                        let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                        let hasSelection = false;
                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }
                        if (!hasSelection) {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUDevelopmentForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUDevelopmentExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUPersonnelDevelopmentPlan.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}
// SpecialEducationIUStaffVacancies
class SpecialEducationIUStaffVacancies {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUStaffVacanciesField", "specialEducationIUStaffVacanciesSelectField", "specialEducationIUStaffVacanciesCheckboxField"];
        this.checkNoVacancies();
        this.setupOtherDropdowns();
        let specialEducationIUStaffVacanciesSaveButton = document.getElementById("specialEducationIUStaffVacanciesSave");
        if (specialEducationIUStaffVacanciesSaveButton !== null)
            specialEducationIUStaffVacanciesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let iuSpecialEducationStaffVacanciesAddRowButton = document.getElementById("iuSpecialEducationStaffVacanciesAddRow");
        if (iuSpecialEducationStaffVacanciesAddRowButton !== null)
            iuSpecialEducationStaffVacanciesAddRowButton.addEventListener("click", (e) => this.addRow());
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        this.bindDeleteStaffVacanciesRows();
        this.bindOtherDropdown();
        const specialEducationIUStaffVacanciesExportToExcelButton = document.getElementById("specialEducationIUStaffVacanciesExportToExcel");
        if (specialEducationIUStaffVacanciesExportToExcelButton !== null) {
            specialEducationIUStaffVacanciesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        const specialEducationIUTechnicalStaffVacanciesNoVacancies = document.getElementById("specialEducationIUTechnicalStaffVacanciesNoVacancies");
        if (specialEducationIUTechnicalStaffVacanciesNoVacancies !== null)
            specialEducationIUTechnicalStaffVacanciesNoVacancies.addEventListener("change", (e) => this.checkNoVacancies());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUStaffVacanciesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUStaffVacanciesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("specialEducationIUStaffVacanciesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUStaffVacanciesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUStaffVacancies', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUStaffVacanciesSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }
    showDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }
    bindDeleteStaffVacanciesRows() {
        let allDeletes = document.getElementsByClassName("deleteStaffRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUStaffVacanciesSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.dropdownChange(e.target));
    }
    dropdownChange(e) {
        let selectElement = e;
        let row = selectElement.dataset.row;
        let otherElement = document.querySelector(`.specialEducationIUStaffVacanciesField.coreservices-select-other[data-row='${row}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        }
        else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }
    addRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("specialEducationIUStaffVacanciesForm");
            let valueElement = document.getElementById("iuSpecialEducatioStaffVacanciesAddRowNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll("#specialEducationIUStaffVacancies table tbody tr");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/AddStaffVacanciesRow/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.innerHTML = output;
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#specialEducationIUStaffVacancies table tbody");
                        table.append(newTR);
                        that.bindDeleteStaffVacanciesRows();
                        that.bindOtherDropdown();
                    }
                }
                valueElement.value = "";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `Row${plural} added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                valueElement.value = "";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        const buttonElement = e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        const allElements = document.querySelectorAll(`.specialEducationIUStaffField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allElements) {
            const element = ele;
            const planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#iuSupportStaffAndServicesProfile table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    customInitializeRequiredFields(allClasses) {
        const specialEducationIUStaffVacanciesPositionDropdowns = document.getElementsByClassName("specialEducationIUStaffVacanciesPositionDropdown");
        for (const specialEducationIUStaffVacanciesPositionDropdown of specialEducationIUStaffVacanciesPositionDropdowns) {
            const select = specialEducationIUStaffVacanciesPositionDropdown;
            if (select.selectedIndex > 0) {
                const row = select.dataset.row;
                if (select[select.selectedIndex].dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                    const otherRowElement = document.querySelector(`.specialEducationIUStaffItemOther[data-row='${row}'`);
                    if (otherRowElement.value === "") {
                        otherRowElement.setAttribute("aria-required", "true");
                        otherRowElement.setAttribute("data-forcerequired", "true");
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    customDoValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission = document.getElementById("specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission");
        if (specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission === null || specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission.value === "") {
            showMessage = true;
            totalErrors++;
            specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission.classList.add("missing-field");
            specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission);
        }
        const specialEducationIUTechnicalStaffVacanciesNoVacancies = document.getElementById("specialEducationIUTechnicalStaffVacanciesNoVacancies");
        if (specialEducationIUTechnicalStaffVacanciesNoVacancies !== null && specialEducationIUTechnicalStaffVacanciesNoVacancies.checked) {
        }
        else {
            const allRows = document.querySelectorAll(".vacanciesTable tr[data-row]");
            for (const thisRow of allRows) {
                const trRow = thisRow;
                const row = trRow.dataset.row;
                const position = document.querySelector(`.specialEducationIUStaffVacanciesPositionDropdown[data-row='${row}']`);
                if (position.selectedIndex > 0) {
                    if (position[position.selectedIndex].dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                        const otherElement = document.querySelector(`.specialEducationIUStaffItemOther[data-row="${row}"]`);
                        if (otherElement === null || otherElement.value === "") {
                            showMessage = true;
                            totalErrors++;
                            otherElement.classList.add("missing-field");
                            otherElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(otherElement);
                        }
                    }
                    const elementaryCheckbox = document.querySelector(`.elementary[data-row='${row}`);
                    const secondaryCheckbox = document.querySelector(`.secondary[data-row='${row}`);
                    const elementaryFTE = document.querySelector(`.elementaryFTE[data-row='${row}`);
                    const secondaryFTE = document.querySelector(`.secondaryFTE[data-row='${row}`);
                    if (elementaryCheckbox !== null && elementaryCheckbox.checked) {
                        if (elementaryFTE === null || elementaryFTE.value === "") {
                            showMessage = true;
                            totalErrors++;
                            elementaryFTE.classList.add("missing-field");
                            elementaryFTE.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(elementaryFTE);
                        }
                    }
                    if (secondaryCheckbox !== null && secondaryCheckbox.checked) {
                        if (secondaryFTE === null || secondaryFTE.value === "") {
                            showMessage = true;
                            totalErrors++;
                            secondaryFTE.classList.add("missing-field");
                            secondaryFTE.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(secondaryFTE);
                        }
                    }
                }
                else {
                    const elementaryCheckbox = document.querySelector(`.elementary[data-row='${row}`);
                    const secondaryCheckbox = document.querySelector(`.secondary[data-row='${row}`);
                    if ((elementaryCheckbox !== null && elementaryCheckbox.checked) || (secondaryCheckbox !== null && secondaryCheckbox.checked)) {
                        showMessage = true;
                        totalErrors++;
                        position.classList.add("missing-field");
                        position.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(position);
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUStaffVacanciesForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUStaffVacanciesExportToExcel`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUStaffVacancies.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    checkNoVacancies() {
        const iuSpecialEducationStaffVacanciesAddRow = document.getElementById("iuSpecialEducationStaffVacanciesAddRow");
        const iuSpecialEducatioStaffVacanciesAddRowNumber = document.getElementById("iuSpecialEducatioStaffVacanciesAddRowNumber");
        const allFields = document.getElementsByClassName("specialEducationIUStaffField");
        const deletes = document.getElementsByClassName("deleteStaffRow");
        const specialEducationIUTechnicalStaffVacanciesNoVacancies = document.getElementById("specialEducationIUTechnicalStaffVacanciesNoVacancies");
        if (specialEducationIUTechnicalStaffVacanciesNoVacancies !== null)
            if (specialEducationIUTechnicalStaffVacanciesNoVacancies.checked) {
                iuSpecialEducationStaffVacanciesAddRow.disabled = true;
                iuSpecialEducatioStaffVacanciesAddRowNumber.readOnly = true;
                for (const field of allFields) {
                    this._core.forceElementOptional(field);
                    if (field instanceof HTMLInputElement) {
                        field.readOnly = true;
                        field.disabled = true;
                    }
                    else if (field instanceof HTMLSelectElement)
                        field.disabled = true;
                }
                for (const button of deletes)
                    button.disabled = true;
            }
            else {
                iuSpecialEducationStaffVacanciesAddRow.disabled = false;
                iuSpecialEducatioStaffVacanciesAddRowNumber.readOnly = false;
                for (const field of allFields) {
                    if (!field.classList.contains("notRequired"))
                        this._core.forceElementRequired(field);
                    if (field instanceof HTMLInputElement) {
                        field.readOnly = false;
                        field.disabled = false;
                    }
                    else if (field instanceof HTMLSelectElement)
                        field.disabled = false;
                }
                for (const button of deletes)
                    button.disabled = false;
            }
    }
}
// SpecialEducationIUDifficultFill
class SpecialEducationIUDifficultFill {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUDifficultFillField", "specialEducationIUDifficultFillSelectField"];
        this.checkNoVacancies();
        let specialEducationIUDifficultFillSaveButton = document.getElementById("specialEducationIUDifficultFillSave");
        if (specialEducationIUDifficultFillSaveButton !== null)
            specialEducationIUDifficultFillSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this.customInitializeRequiredFields(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let iuSpecialEducationDifficultToFillAddRowButton = document.getElementById("iuSpecialEducationDifficultFillAddRow");
        if (iuSpecialEducationDifficultToFillAddRowButton !== null)
            iuSpecialEducationDifficultToFillAddRowButton.addEventListener("click", (e) => this.addRow());
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        this.bindDeleteStaffVacanciesRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();
        const specialEducationIUStaffVacanciesExportToExcelButton = document.getElementById("specialEducationIUDifficultFillExportToExcel");
        if (specialEducationIUStaffVacanciesExportToExcelButton !== null) {
            specialEducationIUStaffVacanciesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        const specialEducationIUTechnicalDifficultFillNoVacancies = document.getElementById("specialEducationIUTechnicalDifficultFillNoVacancies");
        if (specialEducationIUTechnicalDifficultFillNoVacancies !== null)
            specialEducationIUTechnicalDifficultFillNoVacancies.addEventListener("change", (e) => this.checkNoVacancies());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUDifficultFillForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUDifficultFillField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("specialEducationIUDifficultFillSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkInputs = document.getElementsByClassName("specialEducationIUDifficultFillCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
            }
            let value = "off";
            if (element.checked)
                value = "on";
            const saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUDifficultFill', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUDifficultFillSelectWithOtherField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }
    showDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }
    bindDeleteStaffVacanciesRows() {
        let allDeletes = document.getElementsByClassName("deleteStaffRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUDifficultFillSelectWithOtherField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e) => this.dropdownChange(e.target));
    }
    dropdownChange(e) {
        let selectElement = e;
        let row = selectElement.dataset.row;
        let otherElement = document.querySelector(`.specialEducationIUDifficultFillField.coreservices-select-other[data-row='${row}']`);
        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        }
        else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }
    addRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("specialEducationIUDifficultFillForm");
            let valueElement = document.getElementById("iuSpecialEducationDifficultFillAddRowNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll("#specialEducationIUDifficultFill table tbody tr");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/IUSpecialEducation/AddDifficultFillRow/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.innerHTML = output;
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#specialEducationIUDifficultFill table tbody");
                        table.append(newTR);
                        that.bindDeleteStaffVacanciesRows();
                        that.bindOtherDropdown();
                    }
                }
                valueElement.value = "";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `Row${plural} added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                valueElement.value = "";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.specialEducationIUDifficultFill[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#specialEducationIUDifficultFill table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    customDoValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const specialEducationIUTechnicalDifficultFillNoVacancies = document.getElementById("specialEducationIUTechnicalDifficultFillNoVacancies");
        if (specialEducationIUTechnicalDifficultFillNoVacancies !== null && specialEducationIUTechnicalDifficultFillNoVacancies.checked) {
            //if the checkbox is checked, everything is good.
        }
        else {
            const allRows = document.querySelectorAll(`#difficultToFillPositionList tr`);
            for (const tabRow of allRows) {
                const rowEle = tabRow;
                const row = rowEle.dataset.row;
                const positionSelect = document.querySelector(`.position[data-row='${row}']`);
                const elementarySelect = document.querySelector(`.elementary[data-row='${row}']`);
                let hasPosition = false;
                let hasElementary = false;
                if (positionSelect !== null && positionSelect.selectedIndex > 0) {
                    hasPosition = true;
                    const selectedValue = positionSelect[positionSelect.selectedIndex];
                    if (selectedValue !== null && selectedValue.dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                        const otherField = document.querySelector(`.specialEducationIUDifficultFillOtherField[data-row='${row}']`);
                        if (otherField === null || otherField.value === "") {
                            otherField.classList.add("missing-field");
                            otherField.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(otherField);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
                if (elementarySelect !== null && elementarySelect.selectedIndex > 0) {
                    hasElementary = true;
                }
                if (hasPosition && !hasElementary) {
                    elementarySelect.classList.add("missing-field");
                    elementarySelect.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(elementarySelect);
                    showMessage = true;
                    totalErrors++;
                }
                else if (!hasPosition && hasElementary) {
                    positionSelect.classList.add("missing-field");
                    positionSelect.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(positionSelect);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    customInitializeRequiredFields(allClasses, refresh = false, allowDuplicates = false) {
        const specialEducationIUDifficultFillPositionDropdowns = document.getElementsByClassName("specialEducationIUDifficultFillPositionDropdown");
        for (const specialEducationIUDifficultFillPositionDropdown of specialEducationIUDifficultFillPositionDropdowns) {
            const select = specialEducationIUDifficultFillPositionDropdown;
            if (select.selectedIndex > 0) {
                const row = select.dataset.row;
                const allRowSelectElements = document.querySelectorAll(`.specialEducationIUDifficultFillElementaryDropdown[data-row='${row}'`);
                for (const selectEle of allRowSelectElements) {
                    const ele = selectEle;
                    if (ele.selectedIndex <= 0) {
                        ele.setAttribute("aria-required", "true");
                        ele.dataset.forcerequired = "true";
                        ele.dataset.isValid = "false";
                    }
                }
                if (select[select.selectedIndex].dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                    const otherRowElement = document.querySelector(`.specialEducationIUDifficultFillOtherField[data-row='${row}'`);
                    if (otherRowElement.value === "") {
                        otherRowElement.setAttribute("aria-required", "true");
                        otherRowElement.setAttribute("data-forcerequired", "true");
                    }
                }
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUDifficultFillForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUDifficultFillExportToExcel`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUDifficultFill.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    checkNoVacancies() {
        const iuSpecialEducationDifficultFillAddRow = document.getElementById("iuSpecialEducationDifficultFillAddRow");
        const iuSpecialEducatioDifficultFillAddRowNumber = document.getElementById("iuSpecialEducationDifficultFillAddRowNumber");
        const allFields = document.getElementsByClassName("specialEducationIUDifficultFill");
        const deletes = document.getElementsByClassName("deleteStaffRow");
        const specialEducationIUTechnicalDifficultFillNoVacancies = document.getElementById("specialEducationIUTechnicalDifficultFillNoVacancies");
        if (specialEducationIUTechnicalDifficultFillNoVacancies !== null)
            if (specialEducationIUTechnicalDifficultFillNoVacancies.checked) {
                iuSpecialEducationDifficultFillAddRow.disabled = true;
                iuSpecialEducatioDifficultFillAddRowNumber.readOnly = true;
                for (const field of allFields) {
                    this._core.forceElementOptional(field);
                    if (field instanceof HTMLSelectElement)
                        field.disabled = true;
                }
                for (const button of deletes)
                    button.disabled = true;
            }
            else {
                iuSpecialEducationDifficultFillAddRow.disabled = false;
                iuSpecialEducatioDifficultFillAddRowNumber.readOnly = false;
                for (const field of allFields) {
                    this._core.forceElementRequired(field);
                    if (field instanceof HTMLSelectElement)
                        field.disabled = false;
                }
                for (const button of deletes)
                    button.disabled = false;
            }
    }
}
// SpecialEducationIUTACContact
class SpecialEducationIUTACContact {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUTACContactField"];
        let specialEducationIUTACContactSaveButton = document.getElementById("specialEducationIUTACContactSave");
        if (specialEducationIUTACContactSaveButton !== null)
            specialEducationIUTACContactSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUTACContactForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUTACContactField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTACContact', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// SpecialEducationIUTACStaff
class SpecialEducationIUTACStaff {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUTACStaffField"];
        let specialEducationIUTACStaffSaveButton = document.getElementById("specialEducationIUTACStaffSave");
        if (specialEducationIUTACStaffSaveButton !== null)
            specialEducationIUTACStaffSaveButton.addEventListener("click", (e) => this.save("save"));
        this.checkForNoServices();
        this.bindAddButtons();
        this.bindDeleteButtons();
        this.bindFTECalculations();
        this.checkFTETotalsForSections();
        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffFTE");
        for (const fte of allFTEs)
            this.calculateFTE(fte);
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const specialEducationIUTACStaffCheckboxFields = document.getElementsByClassName("specialEducationIUTACStaffCheckboxField");
        for (const specialEducationIUTACStaffCheckboxField of specialEducationIUTACStaffCheckboxFields)
            specialEducationIUTACStaffCheckboxField.addEventListener("change", (e) => this.checkForNoServices());
        const tacStaffExportToExcelButton = document.getElementById("specialEducationTACStaffExportToExcel");
        if (tacStaffExportToExcelButton !== null) {
            tacStaffExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUTACStaffForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const allData = [];
        const allAccordions = document.querySelectorAll(".Accordion-trigger.tacAccordion");
        for (const accordion of allAccordions) {
            const acc = accordion;
            const allInnerElements = [];
            const lookupCodeFK = acc.dataset.lookupcodefk;
            const dataPointPlanPropertyFK = acc.dataset.datapointplanpropertyfk;
            const propertyFK = acc.dataset.propertyfk;
            const textElementsInside = document.querySelectorAll(`.specialEducationIUTACStaffField[data-lookupcodefk='${lookupCodeFK}'`);
            for (const elementInside of textElementsInside) {
                let planPropertyPK = 0;
                const element = elementInside;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allInnerElements.push(saveItem);
                }
            }
            const totalTextElementsInside = document.querySelectorAll(`.specialEducationIUTACStaffFTETotal[data-lookupcodefk='${lookupCodeFK}'`);
            for (const elementInside of totalTextElementsInside) {
                let planPropertyPK = 0;
                const element = elementInside;
                const rowNumber = "0";
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allInnerElements.push(saveItem);
                }
            }
            const checkboxes = document.querySelectorAll(`.specialEducationIUTACStaffCheckboxField[data-lookupcodefk='${lookupCodeFK}']`);
            for (let ele of checkboxes) {
                let planPropertyPK = 0;
                const element = ele;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.checked || hadValue) {
                    let val = "";
                    if (element.checked)
                        val = "on";
                    else
                        val = "off";
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: val,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allInnerElements.push(saveItem);
                }
            }
            allData.push({
                "DataPointPlanPropertyFK": dataPointPlanPropertyFK,
                "LookupCodeFK": lookupCodeFK,
                "PropertyFK": propertyFK,
                "Elements": allInnerElements
            });
        }
        const saveData = {
            "PlanFK": planPK,
            "SaveData": allData
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTACStaff', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }
    bindAddButtons() {
        let specialEducationTACAddInitiativeAddButtons = document.getElementsByClassName("addInitiativeRow");
        for (let specialEducationTACAddInitiativeAddButton of specialEducationTACAddInitiativeAddButtons)
            specialEducationTACAddInitiativeAddButton.addEventListener("click", (e) => this.addInitiativeRow(e));
    }
    bindDeleteButtons() {
        let specialEducationTACAddInitiativeDeleteButtons = document.getElementsByClassName("deleteInitiativeRow");
        for (let specialEducationTACAddInitiativeDeleteButton of specialEducationTACAddInitiativeDeleteButtons)
            specialEducationTACAddInitiativeDeleteButton.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    showDeleteRow(e) {
        const deleteButton = e.target;
        const row = deleteButton.dataset.row;
        const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
        const lookupCode = deleteButton.dataset.lookupcode;
        const lookupCodeFK = deleteButton.dataset.lookupcodefk;
        const modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcode", lookupCode);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcodefk", lookupCodeFK);
        modal.show();
    }
    addInitiativeRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const addButton = e.target;
            const dataPointPlanPropertyPK = addButton.dataset.datapointplanpropertypk;
            const lookupCode = addButton.dataset.lookupcode;
            const lookupCodeFK = addButton.dataset.lookupcodefk;
            const formElement = document.getElementById("specialEducationIUTACStaffForm");
            const planFK = formElement.dataset.planfk;
            let newRow = 0;
            const allRows = document.querySelectorAll(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody tr`);
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            let response = yield fetch(`/IUSpecialEducation/AddTACInitiativeRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}/${lookupCode}/${lookupCodeFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.dataset.row = newRow.toString();
                newTR.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                newTR.dataset.lookupcode = lookupCode;
                const table = document.querySelector(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody`);
                table.append(newTR);
                this.bindDeleteButtons();
                this.bindFTECalculations();
                const message = `Row added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                const message = `There was an issue adding the row. Please try again.`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'error', 2000, null);
            }
        });
    }
    deleteRowCancel() {
        const modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let allElements = document.querySelectorAll(`.specialEducationIUTACStaffField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.initiativeRows[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] table tbody tr[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindFTECalculations() {
        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffFTE");
        for (let fte of allFTEs)
            fte.addEventListener("input", (e) => this.calculateFTE(e.target));
    }
    calculateFTE(fteField) {
        const dataPointPlanPropertyPK = fteField.dataset.datapointplanpropertypk;
        let totalFTE = 0;
        const theseFTEs = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let fte of theseFTEs) {
            const fteElement = fte;
            const thisValue = parseFloat(fteElement.value);
            if (!isNaN(thisValue))
                totalFTE += thisValue;
        }
        const totalFTEElement = document.querySelector(`.specialEducationIUTACStaffFTETotal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        totalFTEElement.value = totalFTE.toString();
    }
    checkFTETotalsForSections() {
        const allRows = document.getElementsByClassName("initiativeRows");
        //1. Compare with Component 2.
        for (const row of allRows) {
            const ele = row;
            const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;
            const lookupCode = ele.dataset.lookupcode;
            const thisFTETotalElement = document.querySelector(`.specialEducationIUTACStaffFTETotal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const thisSectionComponent2TotalElement = document.querySelector(`#component2${lookupCode}`);
            const thisFTETotal = parseFloat(thisFTETotalElement.value);
            const thisSectionComponent2Total = parseFloat(thisSectionComponent2TotalElement.value);
            if (!isNaN(thisSectionComponent2Total) && !isNaN(thisFTETotal)) {
                if (thisFTETotal !== thisSectionComponent2Total) {
                    const errorMessage = document.querySelector(`#category2Match`);
                    errorMessage.classList.remove("hide");
                }
            }
        }
        //2. Special cases for TaC
        const highIncidenceRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeAcademics']`);
        let highIncidenceTotal = 0;
        let highIncidenceValid = false;
        let showHighIncidenceError = true;
        for (const highIncidenceRow of highIncidenceRows) {
            const fteEle = highIncidenceRow;
            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    highIncidenceTotal += fteEleValue;
                if (fteEleValue >= 0.2)
                    highIncidenceValid = true;
            }
            if (highIncidenceTotal > 1.0 && highIncidenceValid)
                showHighIncidenceError = false;
        }
        const lowIncidenceRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeBehaviorInteragencyCoordination']`);
        let lowIncidenceTotal = 0;
        let lowIncidenceValid = false;
        let showLowIncidenceError = true;
        for (const lowIncidenceRow of lowIncidenceRows) {
            const fteEle = lowIncidenceRow;
            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    lowIncidenceTotal += fteEleValue;
                if (fteEleValue >= 0.2)
                    lowIncidenceValid = true;
            }
            if (lowIncidenceTotal >= 1.0 && lowIncidenceValid)
                showLowIncidenceError = true;
        }
        const attractPrepareRetainRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeInclusivePracticesLeastRestrictiveEnvironmentLRE']`);
        let attractPrepareRetainTotal = 0;
        let attractPrepareRetainValid = false;
        let showAttractPrepareRetainError = true;
        for (const attractPrepareRetainRow of attractPrepareRetainRows) {
            const fteEle = attractPrepareRetainRow;
            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    attractPrepareRetainTotal += fteEleValue;
                if (fteEleValue >= 0.5)
                    attractPrepareRetainValid = true;
            }
            if (attractPrepareRetainTotal >= 1.0 && attractPrepareRetainValid)
                showAttractPrepareRetainError = true;
        }
        const postSchoolOutcomesRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeAutism']`);
        let postSchoolOutcomesTotal = 0;
        let postSchoolOutcomesValid = false;
        let showPostSchoolOutscomesError = true;
        for (const postSchoolOutcomesRow of postSchoolOutcomesRows) {
            const fteEle = postSchoolOutcomesRow;
            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    postSchoolOutcomesTotal += fteEleValue;
                if (fteEleValue >= 0.2)
                    postSchoolOutcomesValid = true;
            }
            if (postSchoolOutcomesTotal >= 1.0 && postSchoolOutcomesValid)
                showPostSchoolOutscomesError = true;
        }
        const collaborativePartnershipsRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeAssistiveTechnology']`);
        let collaborativePartnershipsTotal = 0;
        let collaborativePartnershipsValid = false;
        let showCollaborativePartnershipError = true;
        for (const collaborativePartnershipsRow of collaborativePartnershipsRows) {
            const fteEle = collaborativePartnershipsRow;
            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    collaborativePartnershipsTotal += fteEleValue;
                if (fteEleValue >= 0.2)
                    collaborativePartnershipsValid = true;
            }
            if (collaborativePartnershipsTotal >= 1.0 && collaborativePartnershipsValid)
                showCollaborativePartnershipError = true;
        }
        if (!showHighIncidenceError) {
            const specialErrorElement = document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeAcademics']`);
            specialErrorElement.classList.remove("hide");
        }
        if (!showLowIncidenceError) {
            const specialErrorElement = document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeBehaviorInteragencyCoordination']`);
            specialErrorElement.classList.remove("hide");
        }
        if (!showAttractPrepareRetainError) {
            const specialErrorElement = document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeInclusivePracticesLeastRestrictiveEnvironmentLRE']`);
            specialErrorElement.classList.remove("hide");
        }
        if (!showPostSchoolOutscomesError) {
            const specialErrorElement = document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeAutism']`);
            specialErrorElement.classList.remove("hide");
        }
        if (!showCollaborativePartnershipError) {
            const specialErrorElement = document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeAssistiveTechnology']`);
            specialErrorElement.classList.remove("hide");
        }
    }
    customInitializeRequiredFields(allClasses) {
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        const classesToValidate = formattedAllClasses.join(",");
        const allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
    customDoValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const allAccordions = document.querySelectorAll(".Accordion-trigger.tacAccordion");
        for (const accordion of allAccordions) {
            const button = accordion;
            const lookupCodeFK = button.dataset.lookupcodefk;
            const noServices = document.querySelector(`.specialEducationIUTACStaffCheckboxField[data-lookupcodefk='${lookupCodeFK}']`);
            if (noServices.checked) {
                //Validation is done!
            }
            else {
                const rows = document.querySelectorAll(`.specialEducationIUTACStaffField.name[data-lookupcodefk='${lookupCodeFK}']`);
                const allRows = [];
                for (const r of rows) {
                    const rowEle = r;
                    const row = rowEle.dataset.row;
                    allRows.push(row);
                }
                if (allRows.length > 0) {
                    let hasAtLeastOneRow = false;
                    for (const row of allRows) {
                        let hasName = false;
                        let hasFTE = false;
                        let hasFund = false;
                        const nameElement = document.querySelector(`.specialEducationIUTACStaffField.name[data-lookupcodefk='${lookupCodeFK}'][data-row='${row}']`);
                        const fteElement = document.querySelector(`.specialEducationIUTACStaffField.fte[data-lookupcodefk='${lookupCodeFK}'][data-row='${row}']`);
                        const fundElement = document.querySelector(`.specialEducationIUTACStaffField.fund[data-lookupcodefk='${lookupCodeFK}'][data-row='${row}']`);
                        if (nameElement === null || nameElement.value === "") {
                            if ((fteElement !== null && fteElement.value !== "") || (fundElement !== null && fundElement.value !== "")) {
                                nameElement.classList.add("missing-field");
                                nameElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(nameElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            hasName = true;
                        }
                        if (fteElement === null || fteElement.value === "") {
                            if ((nameElement !== null && nameElement.value !== "") || (fundElement !== null && fundElement.value !== "")) {
                                fteElement.classList.add("missing-field");
                                fteElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fteElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            hasFTE = true;
                        }
                        if (fundElement === null || fundElement.value === "") {
                            if ((nameElement !== null && nameElement.value !== "") || (fteElement !== null && fteElement.value !== "")) {
                                fundElement.classList.add("missing-field");
                                fundElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fundElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            hasFund = true;
                        }
                        if (hasName && hasFTE && hasFund)
                            hasAtLeastOneRow = true;
                    }
                }
            }
        }
        const message = document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("initiative-error");
        if (allWarnings.length > 0) {
            for (const warning of allWarnings) {
                if (!(warning.classList.contains("hide"))) {
                    showMessage = true;
                    totalErrors += 1;
                }
            }
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    checkForNoServices() {
        const specialEducationIUTACStaffCheckboxFields = document.getElementsByClassName("specialEducationIUTACStaffCheckboxField");
        for (const specialEducationIUTACStaffCheckboxField of specialEducationIUTACStaffCheckboxFields) {
            const element = specialEducationIUTACStaffCheckboxField;
            const lookupCodeFK = element.dataset.lookupcodefk;
            const allFields = document.querySelectorAll(`.specialEducationIUTACStaffField[data-lookupcodefk='${lookupCodeFK}']`);
            for (const field of allFields) {
                const ele = field;
                if (element.checked) {
                    this._core.forceElementOptional(ele);
                    ele.readOnly = true;
                }
                else {
                    this._core.forceElementRequired(ele);
                    ele.readOnly = false;
                }
            }
            const button = document.querySelector(`.addInitiativeRow[data-lookupcodefk='${lookupCodeFK}']`);
            if (button !== null)
                if (element.checked)
                    button.disabled = true;
                else
                    button.disabled = false;
        }
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUTACStaffForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUTACStaffIDEABExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `TaCStaffRoster-IDEA-B-Funds.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}
// SpecialEducationIUTACStaffNonIDEA
class SpecialEducationIUTACStaffNonIDEA {
    constructor() {
        this._core = new Core();
        this.fundsSelect();
        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffNonIDEABFTE");
        for (let fte of allFTEs)
            this.calculateFTE(fte);
        this.validationClasses = ["specialEducationIUTACStaffNonIDEAField", "specialEducationIUTACStaffNonIDEASelectField"];
        const specialEducationIUTACStaffNonIDEASaveButton = document.getElementById("specialEducationIUTACStaffNonIDEASave");
        if (specialEducationIUTACStaffNonIDEASaveButton !== null)
            specialEducationIUTACStaffNonIDEASaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const specialEducationTACStaffNonIDEAInitiativeAdd = document.getElementById("specialEducationTACStaffNonIDEAInitiativeAdd");
        if (specialEducationTACStaffNonIDEAInitiativeAdd !== null)
            specialEducationTACStaffNonIDEAInitiativeAdd.addEventListener("click", (e) => this.nonIdeaInitiativeAdd(e));
        this.bindFundsSelectChange();
        this.bindAddRowButtons();
        this.bindFTECalculations();
        const iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const specialEducationIUTACStaffNonIDEABAddAnInitiative = document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiative");
        if (specialEducationIUTACStaffNonIDEABAddAnInitiative)
            specialEducationIUTACStaffNonIDEABAddAnInitiative.addEventListener("change", (e) => this.checkForOther());
        const specialEducationTACNonIdeaBInitiativeDeleteConfirm = document.getElementById("specialEducationTACNonIdeaBInitiativeDeleteConfirm");
        if (specialEducationTACNonIdeaBInitiativeDeleteConfirm !== null)
            specialEducationTACNonIdeaBInitiativeDeleteConfirm.addEventListener("click", (e) => this.deleteInitiativeConfirm(e));
        const specialEducationTACNonIdeaBInitiativeDeleteCancel = document.getElementById("specialEducationTACNonIdeaBInitiativeDeleteCancel");
        if (specialEducationTACNonIdeaBInitiativeDeleteCancel !== null)
            specialEducationTACNonIdeaBInitiativeDeleteCancel.addEventListener("click", (e) => this.deleteInitiativeCancel());
        const tacStaffExportToExcelButton = document.getElementById("specialEducationTACStaffNonIDEABExportToExcel");
        if (tacStaffExportToExcelButton !== null) {
            tacStaffExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                    Core.hideLoader();
                })
                    .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }
        this.bindDeleteInitiatives();
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUTACStaffNonIDEAForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const allData = [];
        const accordions = document.querySelectorAll(`.Accordion-trigger.tacAccordion`);
        for (const accord of accordions) {
            const element = accord;
            const dataPointPlanPropertyPK = element.dataset.datapointplanpropertyfk;
            const lookupCodeFK = element.dataset.lookupcodefk;
            const allSaveElements = [];
            const textInputs = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of textInputs) {
                let planPropertyPK = 0;
                const element = ele;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
            const selects = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEASelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of selects) {
                let planPropertyPK = 0;
                const element = ele;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "0" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
            if (allSaveElements.length > 0) {
                const thisData = {
                    "PlanFK": planPK,
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "Elements": allSaveElements
                };
                allData.push(thisData);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTACStaffNonIDEA', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allData));
    }
    nonIdeaInitiativeAdd(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const formElement = document.getElementById("specialEducationIUTACStaffNonIDEAForm");
            const planFK = formElement.dataset.planfk;
            let initiativeValue = 0;
            let initiativeOtherValue = "";
            const initiativeElement = document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiative");
            if (initiativeElement !== null)
                initiativeValue = parseInt(initiativeElement.value);
            if (initiativeValue === 9999) {
                const specialEducationIUTACStaffNonIDEABAddAnInitiativeOther = document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiativeOther");
                if (specialEducationIUTACStaffNonIDEABAddAnInitiativeOther)
                    initiativeOtherValue = specialEducationIUTACStaffNonIDEABAddAnInitiativeOther.value;
            }
            let alreadyExists = false;
            const existingAccordion = document.querySelector(`.Accordion-trigger[data-lookupcodefk='${initiativeValue}']`);
            if (existingAccordion !== null)
                alreadyExists = true;
            if (alreadyExists && initiativeValue !== 9999) {
                Core.createHTMLAlert("alertMessageDiv", "This initiative already exists. Make edits to the existing initiative or pick another one.", 'warning', 2000, null);
                Core.hideLoader();
            }
            else {
                const data = {
                    "InitiativeLookupCodeFK": initiativeValue,
                    "InitiativeOtherValue": initiativeOtherValue,
                    "PlanFK": planFK,
                };
                const config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                };
                const response = yield fetch("/IUSpecialEducation/AddNonIdeaTaCInitiative", config);
                if (response.ok) {
                    const initiativeContainer = document.getElementById("initiativesContainer");
                    const output = yield response.text();
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = output;
                    initiativeContainer.append(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    this.bindAddRowButtons();
                    this.bindFTECalculations();
                    this.bindDeleteInitiatives();
                    initiativeElement.selectedIndex = 0;
                    const message = `Initiative added!`;
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the initiative, please try again.", 'error', 2000, null);
                }
            }
        });
    }
    nonIdeaInitiativeRowAdd(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const addButton = e.target;
            const dataPointPlanPropertyPK = addButton.dataset.datapointplanpropertypk;
            const lookupCode = addButton.dataset.lookupcode;
            const lookupCodeFK = addButton.dataset.lookupcodefk;
            const formElement = document.getElementById("specialEducationIUTACStaffNonIDEAForm");
            const planFK = formElement.dataset.planfk;
            let newRow = 0;
            const allRows = document.querySelectorAll(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody tr`);
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            let response = yield fetch(`/IUSpecialEducation/AddNonIdeaTACInitiativeRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}/${lookupCode}/${lookupCodeFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.dataset.row = newRow.toString();
                newTR.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                newTR.dataset.lookupcode = lookupCode;
                const table = document.querySelector(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody`);
                table.append(newTR);
                this.bindDeleteButtons();
                this.bindFundsSelectChange();
                this.bindFTECalculations();
                const message = `Row added!`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                const message = `There was an issue adding the row. Please try again.`;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'error', 2000, null);
            }
        });
    }
    bindAddButtons() {
        const specialEducationTACAddInitiativeAddButtons = document.getElementsByClassName("addInitiativeRow");
        for (const specialEducationTACAddInitiativeAddButton of specialEducationTACAddInitiativeAddButtons)
            specialEducationTACAddInitiativeAddButton.addEventListener("click", (e) => this.nonIdeaInitiativeAdd(e));
    }
    bindAddRowButtons() {
        const specialEducationTACAddInitiativeAddRowButtons = document.getElementsByClassName("addInitiativeRow");
        for (const specialEducationTACAddInitiativeAddRowButton of specialEducationTACAddInitiativeAddRowButtons)
            specialEducationTACAddInitiativeAddRowButton.addEventListener("click", (e) => this.nonIdeaInitiativeRowAdd(e));
    }
    bindFundsSelectChange() {
        const allSelects = document.getElementsByClassName("coreservices-select");
        for (const select of allSelects)
            select.addEventListener("change", (e) => this.fundsSelect());
    }
    fundsSelect() {
        const allSelects = document.getElementsByClassName("coreservices-select");
        for (const select of allSelects) {
            const element = select;
            const selected = element[element.selectedIndex];
            const row = element.dataset.row;
            const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;
            const otherElement = document.querySelector(`.other[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
            if (selected.dataset.lookupcode === "specialEducationIUNonIDEABFundsO") {
                if (otherElement !== null) {
                    element.classList.add("with-other");
                    otherElement.classList.remove("hide");
                    this._core.forceElementRequired(otherElement);
                }
            }
            else {
                if (otherElement !== null) {
                    element.classList.remove("with-other");
                    otherElement.classList.add("hide");
                    this._core.forceElementOptional(otherElement);
                }
            }
        }
    }
    deleteRowCancel() {
        const modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let allElements = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`.initiativeRows[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] table tbody tr[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                rowElement.remove();
                let modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    bindFTECalculations() {
        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffNonIDEABFTE");
        for (let fte of allFTEs)
            fte.addEventListener("input", (e) => this.calculateFTE(e.target));
    }
    calculateFTE(fteField) {
        const dataPointPlanPropertyPK = fteField.dataset.datapointplanpropertypk;
        let totalFTE = 0;
        const theseFTEs = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEABFTE[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let fte of theseFTEs) {
            const fteElement = fte;
            const thisValue = parseFloat(fteElement.value);
            if (!isNaN(thisValue))
                totalFTE += thisValue;
        }
        const totalFTEElement = document.querySelector(`.specialEducationIUTACStaffFTETotal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        totalFTEElement.value = totalFTE.toString();
    }
    bindDeleteButtons() {
        let specialEducationTACAddInitiativeDeleteButtons = document.getElementsByClassName("deleteInitiativeRow");
        for (let specialEducationTACAddInitiativeDeleteButton of specialEducationTACAddInitiativeDeleteButtons)
            specialEducationTACAddInitiativeDeleteButton.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    showDeleteRow(e) {
        const deleteButton = e.target;
        const row = deleteButton.dataset.row;
        const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
        const lookupCode = deleteButton.dataset.lookupcode;
        const lookupCodeFK = deleteButton.dataset.lookupcodefk;
        const modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcode", lookupCode);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcodefk", lookupCodeFK);
        modal.show();
    }
    checkForOther() {
        const specialEducationIUTACStaffNonIDEABAddAnInitiative = document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiative");
        if (specialEducationIUTACStaffNonIDEABAddAnInitiative !== null) {
            const otherInitiative = document.getElementById("otherInitiative");
            if (specialEducationIUTACStaffNonIDEABAddAnInitiative.value === "9999") {
                otherInitiative.classList.remove("hide");
            }
            else {
                otherInitiative.classList.add("hide");
            }
        }
    }
    customInitializeRequiredFields(allClasses) {
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        const classesToValidate = formattedAllClasses.join(",");
        const allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {
                htmlElement.dataset.percent = "1.00";
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);
                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
    customDoValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const allAccordions = document.querySelectorAll(".Accordion-trigger.tacAccordion");
        for (const accordion of allAccordions) {
            const button = accordion;
            const lookupCodeFK = button.dataset.lookupcodefk;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertyfk;
            const rows = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const allRows = [];
            for (const r of rows) {
                const rowEle = r;
                const row = rowEle.dataset.row;
                allRows.push(row);
            }
            if (allRows.length > 0) {
                let hasAtLeastOneRow = false;
                for (const row of allRows) {
                    let hasName = false;
                    let hasFTE = false;
                    let hasFund = false;
                    let hasOther = false;
                    const nameElement = document.querySelector(`.specialEducationIUTACStaffNonIDEAField.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const fteElement = document.querySelector(`.specialEducationIUTACStaffNonIDEAField.fte[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const fundElement = document.querySelector(`.specialEducationIUTACStaffNonIDEASelectField.fund[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    if (nameElement.value.trim() === "") {
                        if ((fteElement !== null && fteElement.value !== "") || (fundElement !== null && fundElement.value !== "0")) {
                            nameElement.classList.add("missing-field");
                            nameElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(nameElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else {
                        hasName = true;
                    }
                    if (fteElement.value.trim() === "") {
                        if ((nameElement !== null && nameElement.value.trim() !== "") || (fundElement !== null && fundElement.value.trim() !== "0")) {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else {
                        hasFTE = true;
                    }
                    if (fundElement.value.trim() === "0") {
                        if ((nameElement !== null && nameElement.value.trim() !== "") || (fteElement !== null && fteElement.value.trim() !== "")) {
                            fundElement.classList.add("missing-field");
                            fundElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fundElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                    else {
                        hasFund = true;
                        const selectedElement = fundElement[fundElement.selectedIndex];
                        if (selectedElement.dataset.lookupcode === "specialEducationIUNonIDEABFundsO") {
                            const otherElement = document.querySelector(`.specialEducationIUTACStaffNonIDEAField.other[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                            if (otherElement !== null && otherElement.value !== "") {
                                hasOther = true;
                            }
                            else {
                                otherElement.classList.add("missing-field");
                                otherElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(otherElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            //This needs to be true here since it won't exist for this (which makes it valid). 
                            hasOther = true;
                        }
                    }
                    if (hasName && hasFTE && hasFund && hasOther)
                        hasAtLeastOneRow = true;
                }
            }
        }
        const message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    exportToExcel() {
        return new Promise((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUTACStaffNonIDEAForm");
            let planFK = parseInt(planForm.dataset.planfk);
            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUTACStaffNonIDEABExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `TaCStaffRoster-NonIDEA-B-Funds.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
    bindDeleteInitiatives() {
        const deleteButtons = document.getElementsByClassName("deleteInitiativeRow");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.showDeleteInitiative(e));
    }
    showDeleteInitiative(e) {
        const button = e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const lookupCodeFK = button.dataset.lookupcodefk;
        const lookupCode = button.dataset.lookupcode;
        const modal = new Modal("deleteInitiativeModal", null);
        modal.addAttributeToElement("deleteInitiativeModal", "#specialEducationTACNonIdeaBInitiativeDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteInitiativeModal", "#specialEducationTACNonIdeaBInitiativeDeleteConfirm", "lookupcode", lookupCode);
        modal.addAttributeToElement("deleteInitiativeModal", "#specialEducationTACNonIdeaBInitiativeDeleteConfirm", "lookupcodefk", lookupCodeFK);
        modal.show();
    }
    deleteInitiativeConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
            const lookupCodeFK = button.dataset.lookupcodefk;
            const lookupCode = button.dataset.lookupcode;
            const form = document.getElementById("specialEducationIUTACStaffNonIDEAForm");
            const planFK = form.dataset.planfk;
            const planProps = [];
            const inputs = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const pp of inputs) {
                const ele = pp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planProps.push(ele.dataset.planpropertypk);
            }
            const selects = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEASelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const pp of selects) {
                const ele = pp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planProps.push(ele.dataset.planpropertypk);
            }
            planProps.push(dataPointPlanPropertyPK);
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planProps)
            };
            let response = yield fetch(`/IUSpecialEducation/DeleteRowDataWithRelated`, settings);
            if (response.ok) {
                const output = yield response.json();
                if (output.success) {
                    const accordionHeader = document.querySelector(`.Accordion-trigger.tacAccordion[data-datapointplanpropertyfk='${dataPointPlanPropertyPK}']`);
                    const accordion = document.querySelector(`.Accordion-panel.tacAccordion[data-datapointplanpropertyfk='${dataPointPlanPropertyPK}']`);
                    accordionHeader.parentElement.remove();
                    accordion.remove();
                    const modal = new Modal("deleteInitiativeModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "The initiative has been removed.", 'success', 3000, null);
                }
                else {
                    const modal = new Modal("deleteInitiativeModal", null);
                    modal.hide();
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the initiative, please try again.", 'error', 3000, null);
                }
            }
            else {
                const modal = new Modal("deleteInitiativeModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the initiative, please try again.", 'error', 3000, null);
            }
        });
    }
    deleteInitiativeCancel() {
        const modal = new Modal("deleteInitiativeModal", null);
        modal.hide();
    }
}
// SpecialEducationIUSignatures
class SpecialEducationIUSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["specialEducationIUSignaturesField", "specialEducationIUSignaturesCheckboxField", "specialEducationIUSignaturesFileField"];
        let specialEducationIUSignaturesSaveButton = document.getElementById("specialEducationIUSignaturesSave");
        if (specialEducationIUSignaturesSaveButton !== null)
            specialEducationIUSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let fileUploader = document.getElementById("specialEducationIUSignaturesUploadofSchoolBoardMinutesorAffirmationStatement");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        let transferFileUploader = document.getElementById("specialEducationIUSignaturesTransferOfEntityLetters");
        if (transferFileUploader !== null)
            transferFileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("specialEducationIUSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("specialEducationIUSignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("specialEducationIUSignaturesFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        const file = e.dataset.file;
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        e.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-file='${file}'`);
                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-file='${file}']`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.classList.add("specialEducationIUSignaturesFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("signaturesDeleteFileModal", null);
        modal.addAttributeToElement("signaturesDeleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.specialEducationIUSignaturesFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById("uploadedFiles");
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                const modal = new Modal("signaturesDeleteFileModal", null);
                modal.hide();
            }
            else {
                const modal = new Modal("signaturesDeleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("signaturesDeleteFileModal", null);
        modal.hide();
    }
}
// SpecialEducationIUSummary
class SpecialEducationIUSummary {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
class SpecialEducationIUProgramProfilesLazyAccordion extends LazyAccordion {
    loadContent(accordion, contentElement) {
        return __awaiter(this, void 0, void 0, function* () {
            const icon = accordion.querySelector(".lazy-accordion-loaded-icon");
            icon.classList.replace("empty", "loading");
            this.show(accordion, contentElement);
            const educationProgramFTEFK = accordion.dataset.educationprogramftefk;
            const action = accordion.dataset.action;
            const controller = accordion.dataset.controller;
            const url = `/${controller}/${action}/${educationProgramFTEFK}`;
            //load
            const response = yield fetch(`${url}`, { credentials: 'include' });
            if (response.ok) {
                contentElement.innerHTML = yield response.text();
                icon.classList.replace("loading", "loaded");
                SpecialEducationIUProgramProfiles.initializeLoadedFTE(parseInt(educationProgramFTEFK));
                if (accordion.dataset.validdata === "false") {
                    SpecialEducationIUProgramProfiles.fteValidation(parseInt(educationProgramFTEFK));
                }
                SpecialEducationIUProgramProfiles.checkOperatedByIdentifyClassroom(parseInt(educationProgramFTEFK));
            }
        });
    }
}
class SpecialEducationIUFacilitiesLazyAccordion extends LazyAccordion {
    loadContent(accordion, contentElement) {
        return __awaiter(this, void 0, void 0, function* () {
            const icon = accordion.querySelector(".lazy-accordion-loaded-icon");
            icon.classList.replace("empty", "loading");
            this.show(accordion, contentElement);
            const facilityFK = accordion.dataset.specialeducationfacilityfk;
            const action = accordion.dataset.action;
            const controller = accordion.dataset.controller;
            const url = `/${controller}/${action}/${facilityFK}`;
            //load
            const response = yield fetch(`${url}`, { credentials: 'include' });
            if (response.ok) {
                contentElement.innerHTML = yield response.text();
                icon.classList.replace("loading", "loaded");
                SpecialEducationIUFacilities.initializeLoadedFacility(parseInt(facilityFK));
                SpecialEducationIUFacilities.initializeLoadedFacilityFields(facilityFK);
                if (accordion.dataset.validdata === "false") {
                    SpecialEducationIUFacilities.validateElements(facilityFK);
                }
                const tabContainer = document.querySelector(`.seprnContainer[data-facility-pk='${facilityFK}'] .row .review-container .review-container-actions .tab-container`);
                if (tabContainer)
                    new Tab(tabContainer);
                const seprnContainer = document.querySelector(`.seprnContainer[data-facility-pk='${facilityFK}']`);
                if (seprnContainer !== null) {
                    const workflowPK = seprnContainer.dataset.workflowInstancePk;
                    if (workflowPK && parseInt(workflowPK) > 0)
                        new Workflow(parseInt(workflowPK));
                }
                SpecialEducationIUFacilities.checkForRequiredFieldsFromOperatedInStatic(parseInt(facilityFK));
                SpecialEducationIUFacilities.checkForBuildingOtherStatic(parseInt(facilityFK));
            }
        });
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// CatsSystemContactInformation
class CatsSystemContactInformation {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemContactInformationField", "catsSystemContactInformationSelectField"];
        let catsSystemContactInformationSaveButton = document.getElementById("catsSystemContactInformationSave");
        if (catsSystemContactInformationSaveButton !== null)
            catsSystemContactInformationSaveButton.addEventListener("click", (e) => this.save("save"));
        let catsSystemExecutiveSaveButton = document.getElementById("executiveSave");
        if (catsSystemExecutiveSaveButton !== null)
            catsSystemExecutiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null) {
            refreshButton.addEventListener("click", (e) => this.refreshProfile());
        }
        let phoneNumber = document.getElementById("catsSystemContactInfornationCatsPhoneNumber");
        if (phoneNumber !== null) {
            phoneNumber.addEventListener("blur", (e) => this.maskPhoneNumber(e));
        }
        this.saveAccess();
    }
    maskPhoneNumber(e) {
        let element = e.target;
        let x = element.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        element.value = '(' + x[1] + ') ' + x[2] + '-' + x[3];
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemContactInformationForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemContactInformationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("catsSystemContactInformationSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemContactInformation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemContactInformationForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("profileICDataSave");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemContactInformation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    refreshProfile() {
        const formName = "catsSystemContactInformationForm";
        Core.showLoader();
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/GetICDataForContact', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                const leaNameElement = document.querySelector(".profileICData[data-field='LEAName']");
                const leaAUNElement = document.querySelector(".profileICData[data-field='LEAAUN']");
                const schoolNameElement = document.querySelector(".profileICData[data-field='SchoolName']");
                const schoolNumberElement = document.querySelector(".profileICData[data-field='SchoolNumber']");
                const schoolCountyElement = document.querySelector(".profileICData[data-field='SchoolCounty']");
                const iuElement = document.querySelector(".profileICData[data-field='IU']");
                const schoolAddressLine1Element = document.querySelector(".profileICData[data-field='SchoolAddress1']");
                const schoolAddressLine2Element = document.querySelector(".profileICData[data-field='SchoolAddress2']");
                const schoolCityElement = document.querySelector(".profileICData[data-field='SchoolCity']");
                const schoolStateElement = document.querySelector(".profileICData[data-field='SchoolState']");
                const schoolZipCodeElement = document.querySelector(".profileICData[data-field='SchoolZip']");
                const leaPhoneElement = document.querySelector(".profileICData[data-field='LEAPhone']");
                const leaPhoneExtElement = document.querySelector(".profileICData[data-field='LEAPhoneExt']");
                const schoolPhoneElement = document.querySelector(".profileICData[data-field='SchoolPhone']");
                const schoolPhoneExtElement = document.querySelector(".profileICData[data-field='SchoolPhoneExt']");
                const instDetails = JSON.parse(xhr.response);
                if (leaNameElement)
                    leaNameElement.value = instDetails.leaInstDetails.instName;
                if (leaAUNElement)
                    leaAUNElement.value = instDetails.leaInstDetails.auNumber;
                if (schoolNameElement)
                    schoolNameElement.value = instDetails.schoolInstDetails.instName;
                if (schoolNumberElement)
                    schoolNumberElement.value = instDetails.schoolInstDetails.pubSchOrBranchNumber;
                if (schoolCountyElement)
                    schoolCountyElement.value = instDetails.schoolInstDetails.countyName;
                if (iuElement)
                    iuElement.value = instDetails.iuInstDetails.instName;
                if (schoolAddressLine1Element)
                    schoolAddressLine1Element.value = instDetails.schoolInstDetails.addressLine1;
                if (schoolAddressLine2Element)
                    schoolAddressLine2Element.value = instDetails.schoolInstDetails.addressLine2;
                if (schoolCityElement)
                    schoolCityElement.value = instDetails.schoolInstDetails.city;
                if (schoolStateElement)
                    schoolStateElement.value = instDetails.schoolInstDetails.state;
                if (schoolZipCodeElement)
                    schoolZipCodeElement.value = instDetails.schoolInstDetails.zipCode;
                if (leaPhoneElement)
                    leaPhoneElement.value = instDetails.leaInstDetails.phoneNumber;
                if (leaPhoneExtElement)
                    leaPhoneExtElement.value = instDetails.leaInstDetails.phoneNumberExtn;
                if (schoolPhoneElement)
                    schoolPhoneElement.value = instDetails.schoolInstDetails.phoneNumber;
                if (schoolPhoneExtElement)
                    schoolPhoneExtElement.value = instDetails.schoolInstDetails.phoneNumberExtn;
                const superNameElement = document.querySelector(".profileICData[data-field='SuperName']");
                const superTitleElement = document.querySelector(".profileICData[data-field='SuperTitle']");
                const superPhoneElement = document.querySelector(".profileICData[data-field='SuperPhone']");
                const superPhoneExtElement = document.querySelector(".profileICData[data-field='SuperPhoneExt']");
                const superEmailElement = document.querySelector(".profileICData[data-field='SuperEmail']");
                if (superNameElement)
                    superNameElement.value = instDetails.administratorInfo.adminName;
                if (superTitleElement)
                    superTitleElement.value = instDetails.administratorInfo.jobTitle;
                if (superPhoneElement)
                    superPhoneElement.value = instDetails.administratorInfo.phoneNumber;
                if (superEmailElement)
                    superEmailElement.value = instDetails.administratorInfo.email;
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Contact Information has been refreshed, please remember to save the changes.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    saveAccess() {
        const formName = "catsSystemContactInformationForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
}
// CatsSystemCommonSupportService
class CatsSystemCommonSupportService {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemCommonSupportServiceField", "catsSystemStudentTechnicalFileField", "catsSystemCommonSupportServiceRadioField"];
        let catsSystemCommonSupportServiceSaveButton = document.getElementById("catsSystemCommonSupportServiceSave");
        if (catsSystemCommonSupportServiceSaveButton !== null)
            catsSystemCommonSupportServiceSaveButton.addEventListener("click", (e) => this.save("save"));
        this.checkForCooperative();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const fileCourseCatalog = document.querySelector(`.catsSystemStudentTechnicalFileField[data-file='coursecatalog']`);
        if (fileCourseCatalog !== null)
            fileCourseCatalog.addEventListener("change", (e) => this.uploadFile(e.target, "coursecatalog"));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        this.saveAccess();
        const catsSystemCommonSupportCoops = document.getElementsByClassName("catsSystemCommonSupportCoop");
        for (const catsSystemCommonSupportCoop of catsSystemCommonSupportCoops)
            catsSystemCommonSupportCoop.addEventListener("change", (e) => this.checkForCooperative());
        const addCooperativeRowButton = document.getElementById("addCooperativeRow");
        if (addCooperativeRowButton !== null)
            addCooperativeRowButton.addEventListener("click", (e) => this.addCooperativeRow());
        this.bindDeleteRows();
        let deleteCooperativeRowConfirmButton = document.getElementById("catsSystemCooperativeDeleteConfirm");
        if (deleteCooperativeRowConfirmButton !== null)
            deleteCooperativeRowConfirmButton.addEventListener("click", (e) => this.deleteCooperativeRowConfirm(e));
        let deleteCooperativeRowCancelButton = document.getElementById("catsSystemCooperativeDeleteCancel");
        if (deleteCooperativeRowCancelButton !== null)
            deleteCooperativeRowCancelButton.addEventListener("click", (e) => this.deleteCooperativeRowCancel());
        this.bindTeacherCerts();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCommonSupportServiceForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemCommonSupportServiceField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("catsSystemStudentTechnicalFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        const radios = document.getElementsByClassName("catsSystemCommonSupportServiceRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemCommonSupportService', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    saveAccess() {
        const formName = "catsSystemCommonSupportServiceForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    uploadFile(e, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.catsSystemStudentTechnicalFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemStudentTechnicalFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemStudentTechnicalFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    checkForCooperative() {
        const catsSystemCommonSupportCoops = document.getElementsByClassName("catsSystemCommonSupportCoop");
        for (const catsSystemCommonSupportCoop of catsSystemCommonSupportCoops) {
            const element = catsSystemCommonSupportCoop;
            if (element.checked) {
                const checkedValue = element.dataset.lookupcode;
                const container = document.getElementById("cooperativeTable");
                if (checkedValue === "yes") {
                    container.classList.remove("hide");
                }
                else {
                    container.classList.add("hide");
                }
            }
        }
    }
    addCooperativeRow() {
        return __awaiter(this, void 0, void 0, function* () {
            const formElement = document.getElementById("catsSystemCommonSupportServiceForm");
            const planFK = formElement.dataset.planfk;
            const addNewPPID = document.getElementById("addNewPPID");
            Core.showLoader();
            let ppid = "";
            if (addNewPPID !== null) {
                ppid = addNewPPID.value;
            }
            if (ppid !== "") {
                //First, check to see if this PPID has already been added
                let ppidNotInList = true;
                const allPPIDS = document.querySelectorAll(`.catsSystemCommonSupportServicePPID[data-ppid='${ppid}']`);
                if (allPPIDS.length > 0) {
                    ppidNotInList = false;
                    Core.createHTMLAlert("alertMessageDiv", "This PPID is already in the list, you can only add it once.", 'warning', 3000, null);
                }
                if (ppidNotInList) {
                    //2 step process:
                    //1. call ValidTIMSWithCoopCert to make sure the ppid is valid + has the coop cert.
                    //2. If 1 == true, call the partial build to display the record.
                    const validPPIDResponse = yield fetch(`/CATSSystem/ValidTIMSWithCoopCert/${ppid}`);
                    if (validPPIDResponse.ok) {
                        const validResp = yield validPPIDResponse.json();
                        if (validResp.ppidFound) {
                            const allCoopRows = document.querySelectorAll(`.cooperativeRow`);
                            let newRow = -1;
                            for (const coopRow of allCoopRows) {
                                const ele = coopRow;
                                const thisRow = parseInt(ele.dataset.row);
                                if (thisRow > newRow)
                                    newRow = thisRow;
                            }
                            newRow++;
                            const response = yield fetch(`/CATSSystem/AddCooperativeRow/${planFK}/${ppid}/${newRow}`, { credentials: 'include' });
                            if (response.ok) {
                                const output = yield response.text();
                                const container = document.createElement("tr");
                                container.dataset.row = newRow.toString();
                                container.classList.add("cooperativeRow");
                                container.innerHTML = output;
                                const table = document.querySelector("#cooperativeData tbody");
                                table.append(container);
                                this.bindDeleteRows();
                                //These extra rows are necessary.
                                //The first one is for consistency, so the current row will match the background of the
                                //injected row (row "3" here). The injected row will contain the data that comes from TIMS when
                                //the user clicks on the name in the initial row created here.
                                const secondTR = document.createElement("tr");
                                secondTR.dataset.row = newRow.toString();
                                secondTR.classList.add("hide");
                                secondTR.classList.add("cooperativeRowSecond");
                                secondTR.setAttribute("aria-hidden", "true");
                                table.append(secondTR);
                                const thirdTR = document.createElement("tr");
                                thirdTR.dataset.row = newRow.toString();
                                thirdTR.dataset.ppid = ppid;
                                thirdTR.classList.add("ppidCertDisplay");
                                thirdTR.classList.add("hide");
                                thirdTR.classList.add("cooperativeRowThird");
                                table.append(thirdTR);
                                const cooperativeTableData = document.getElementById("cooperativeTableData");
                                cooperativeTableData.classList.remove("hide");
                                Core.hideLoader();
                                addNewPPID.value = "";
                                this.bindTeacherCerts();
                            }
                        }
                        else {
                            Core.createHTMLAlert("alertMessageDiv", validResp.message, 'warning', 3000, null);
                        }
                    }
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Please enter a PPID to add.", 'warning', 3000, null);
            }
        });
    }
    bindDeleteRows() {
        const allDeleteButtons = document.getElementsByClassName("deleteCoopRecord");
        for (const deleteButton of allDeleteButtons)
            deleteButton.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    showDeleteRow(e) {
        const button = e.target;
        const row = button.dataset.row;
        let modal = new Modal("deleteCooperativeRowModal", null);
        modal.addAttributeToElement("deleteCooperativeRowModal", "#catsSystemCooperativeDeleteConfirm", "row", row);
        modal.show();
    }
    deleteCooperativeRowConfirm(e) {
        Core.showLoader();
        const buttonElement = e.target;
        const row = buttonElement.dataset.row;
        const hiddenElement = document.querySelector(`.catsSystemCommonSupportServicePPID[data-row='${row}']`);
        const rowElement = document.querySelector(`.cooperativeRow[data-row='${row}']`);
        const secondRowElement = document.querySelector(`.cooperativeRowSecond[data-row='${row}']`);
        const thirdRowElement = document.querySelector(`.cooperativeRowThird[data-row='${row}']`);
        if (hiddenElement.dataset.planpropertypk && hiddenElement.dataset.planpropertypk !== "" && hiddenElement.dataset.planpropertypk !== "0") {
            const planPropertys = [];
            const thisRow = document.querySelectorAll(`.catsSystemCommonSupportServiceData[data-row='${row}']`);
            for (const item of thisRow) {
                const ele = item;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planPropertys.push(parseInt(ele.dataset.planpropertypk));
            }
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CATSSystem/DeleteCooperativeRow', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                        rowElement.remove();
                        secondRowElement.remove();
                        thirdRowElement.remove();
                        Core.createHTMLAlert("alertMessageDiv", "Row successfully removed.", 'success', 3000, null);
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the row. Please try again.", 'error', 3000, null);
                    }
                    Core.hideLoader();
                    let modal = new Modal("deleteCooperativeRowModal", null);
                    modal.hide();
                }
                else {
                    let modal = new Modal("deleteCooperativeRowModal", null);
                    modal.hide();
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            };
            xhr.send(JSON.stringify(planPropertys));
        }
        else {
            rowElement.remove();
            secondRowElement.remove();
            thirdRowElement.remove();
        }
    }
    deleteCooperativeRowCancel() {
        let modal = new Modal("deleteCooperativeRowModal", null);
        modal.hide();
    }
    bindTeacherCerts() {
        const teacherCerts = document.getElementsByClassName("teacherCerts");
        for (const teacherCert of teacherCerts)
            teacherCert.addEventListener("click", (e) => this.openTeacherCert(e.target));
    }
    openTeacherCert(element) {
        return __awaiter(this, void 0, void 0, function* () {
            const ppid = element.dataset.ppid;
            const row = element.dataset.row;
            const ppidCertDisplay = document.querySelector(`.ppidCertDisplay[data-row='${row}'][data-ppid='${ppid}']`);
            const planForm = document.getElementById("catsSystemCommonSupportServiceForm");
            const planFK = planForm.dataset.planfk;
            const response = yield fetch(`/CATSSystem/GetTeacherCertificationData/${ppid}/${planFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const newTD = document.createElement("td");
                newTD.colSpan = 6;
                newTD.innerHTML = output;
                ppidCertDisplay.append(newTD);
                ppidCertDisplay.classList.remove("hide");
                this.bindCloseCert();
            }
        });
    }
    bindCloseCert() {
        const closeCerts = document.getElementsByClassName("closeCert");
        for (const closeCert of closeCerts)
            closeCert.addEventListener("click", (e) => this.closeCert(e.target));
    }
    closeCert(button) {
        const ppid = button.dataset.ppid;
        const container = document.querySelector(`.ppidCertDisplay[data-ppid='${ppid}']`);
        container.innerHTML = "";
        container.classList.add("hide");
    }
}
// CatsSystemAdvisoryCommitteeDetails
class CatsSystemAdvisoryCommitteeDetails {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemAdvisoryCommitteeDetailsField", "catsSystemAdvisoryCommitteeDetailsFileField"];
        let catsSystemAdvisoryCommitteeDetailsSaveButton = document.getElementById("catsSystemAdvisoryCommitteeDetailsSave");
        if (catsSystemAdvisoryCommitteeDetailsSaveButton !== null)
            catsSystemAdvisoryCommitteeDetailsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let deleteCommitteeConfirmButton = document.getElementById("deleteCommitteeConfirm");
        if (deleteCommitteeConfirmButton !== null)
            deleteCommitteeConfirmButton.addEventListener("click", (e) => this.deleteCommitteeConfirm(e));
        let deleteCommitteeCancelButton = document.getElementById("deleteCommitteeCancel");
        if (deleteCommitteeCancelButton !== null)
            deleteCommitteeCancelButton.addEventListener("click", (e) => this.deleteCommitteeCancel());
        this.bindFileUploaders();
        this.bindDeleteFiles();
        this.bindDeleteCommittees();
        this.saveAccess();
        let catsSystemAddAdvisoryCommitteeButton = document.getElementById("catsSystemAddAdvisoryCommittee");
        if (catsSystemAddAdvisoryCommitteeButton !== null)
            catsSystemAddAdvisoryCommitteeButton.addEventListener("click", (e) => this.addAdvisoryCommittee());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemAdvisoryCommitteeDetailsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.row);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAdvisoryCommitteeDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    saveAccess() {
        const formName = "catsSystemAdvisoryCommitteeDetailsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    bindFileUploaders() {
        let fileUploaders = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsFileInputField");
        for (const fileUploader of fileUploaders) {
            let ele = fileUploader;
            let listener = ele.dataset.listener;
            if (listener === "false") {
                ele.addEventListener("change", (e) => this.uploadFile(e.target));
                ele.setAttribute("listener", "true");
                ele.dataset.listener = "true";
            }
        }
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemAdvisoryCommitteeDetailsFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles${row}`);
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    addAdvisoryCommittee() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("catsSystemAdvisoryCommitteeDetailsForm");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#advisoryCommitteeDetail .row");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            let response = yield fetch(`/CATSSystem/AddSystemAdvisoryCommitteeRow/${planFK}/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const container = document.createElement("div");
                container.classList.add("row");
                container.classList.add("committee-detail-row");
                container.classList.add("committeeDetailRow");
                container.dataset.row = newRow.toString();
                const containerInner = document.createElement("div");
                containerInner.classList.add("medium-12");
                containerInner.classList.add("column");
                const newDiv = document.createElement("div");
                newDiv.innerHTML = output;
                containerInner.appendChild(newDiv);
                container.appendChild(containerInner);
                let table = document.querySelector("#advisoryCommitteeDetail");
                table.append(container);
                that.bindDeleteFiles();
                that.bindFileUploaders();
                that.bindDeleteCommittees();
            }
            this._core.initializeRequiredFields(this.validationClasses, false, true);
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The Advisory Committees Details Row has been added!", 'success', 2000, null);
        });
    }
    bindDeleteCommittees() {
        let deleteCommitteeButtons = document.getElementsByClassName("deletecommittee");
        for (let deleteCommitteeButton of deleteCommitteeButtons)
            deleteCommitteeButton.addEventListener("click", (e) => this.showDeleteCommittee(e));
    }
    showDeleteCommittee(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteCommitteeModal", null);
        modal.addAttributeToElement("deleteCommitteeModal", "#deleteCommitteeConfirm", "row", row);
        modal.show();
    }
    deleteCommitteeConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        let allSaveElements = [];
        let formElement = document.getElementById("catsSystemAdvisoryCommitteeDetailsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (rowNumber === row) {
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("catsSystemAdvisoryCommitteeDetailsFileUploadField");
        for (let ele of files) {
            let element = ele;
            let fileRowNumber = element.dataset.row;
            let sequenceNbr = parseInt(element.dataset.row);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            if (fileRowNumber === row) {
                let saveItem = {
                    PlanPropertyFilePK: planPropertyFilePK,
                    SequenceNbr: sequenceNbr,
                    FileUploadPK: fileUploadPK,
                    PropertyPK: propertyPK,
                    PlanFK: planPK
                };
                allFileElements.push(saveItem);
            }
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteAdvisoryCommitteeData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.committeeDetailRow[data-row='${row}']`);
                    element.remove();
                    Core.createHTMLAlert("alertMessageDiv", "Committee successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the Committee. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteCommitteeModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteCommitteeModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(allData));
    }
    deleteCommitteeCancel() {
        let modal = new Modal("deleteCommitteeModal", null);
        modal.hide();
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let row = e.dataset.row;
        const that = this;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.catsSystemAdvisoryCommitteeDetailsFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);
                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-row='${row}']`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`#uploadedFiles${row}`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemAdvisoryCommitteeDetailsFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}
// CatsSystemLEAWebsite
class CatsSystemLEAWebsite {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemLEAWebsiteField", "catsSystemLEAWebsiteFileField"];
        const catsSystemLEAWebsiteSaveButton = document.getElementById("catsSystemLEAWebsiteSave");
        if (catsSystemLEAWebsiteSaveButton !== null)
            catsSystemLEAWebsiteSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const fileUploader = document.getElementById("catsSystemLEAWebsiteLEAWebsiteHousingArticulationAgreements");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        this.bindDeleteRows();
        this.saveAccess();
        const catsSystemAddLEAURLButton = document.getElementById("catsSystemAddLEAURL");
        if (catsSystemAddLEAURLButton !== null)
            catsSystemAddLEAURLButton.addEventListener("click", (e) => this.addLEAURL());
        const deleteURLRowConfirmButton = document.getElementById("catsSystemCATSRowDeleteConfirm");
        if (deleteURLRowConfirmButton !== null)
            deleteURLRowConfirmButton.addEventListener("click", (e) => this.deleteURLRowConfirm(e));
        const deleteURLRowCancelButton = document.getElementById("catsSystemCATSRowDeleteCancel");
        if (deleteURLRowCancelButton !== null)
            deleteURLRowCancelButton.addEventListener("click", (e) => this.deleteURLRowCancel());
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemLEAWebsiteFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById("uploadedFiles");
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemLEAWebsiteForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemLEAWebsiteField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("catsSystemLEAWebsiteFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemLEAWebsite', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    saveAccess() {
        const formName = "catsSystemLEAWebsiteForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let row = e.dataset.row;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        const that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`#catsSystemLEAWebsiteLEAWebsiteHousingArticulationAgreements`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList`);
                        let currentFileList = fileList.querySelectorAll(`.uploadFileList`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemLEAWebsiteFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    addLEAURL() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("catsSystemLEAWebsiteForm");
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;
            let allRows = document.querySelectorAll("#leaURLList tbody tr");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            let response = yield fetch(`/CATSSystem/AddLEAURL/${planFK}/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.dataset.row = newRow.toString();
                newTR.innerHTML = output;
                const container = document.querySelector("#leaURLList tbody");
                container.appendChild(newTR);
                that.bindDeleteRows();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The Website URL Housing Articulation Agreement(s) Row has been added!", 'success', 2000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding a new row. Please try again.", 'error', 2000, null);
            }
        });
    }
    bindDeleteRows() {
        const allDeleteRows = document.getElementsByClassName("deleteURLRow");
        for (const deleteRow of allDeleteRows)
            deleteRow.addEventListener("click", (e) => this.showDeleteURLRow(e));
    }
    showDeleteURLRow(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteRowModal", null);
        modal.addAttributeToElement("deleteRowModal", "#catsSystemCATSRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteURLRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.catsSystemLEAWebsiteField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#leaWebsites table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    deleteURLRowCancel() {
        let modal = new Modal("deleteRowModal", null);
        modal.hide();
    }
}
// CatsSystemProgramList
class CatsSystemProgramList {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemProgramListField"];
        let catsSystemProgramListSaveButton = document.getElementById("catsSystemProgramListSave");
        if (catsSystemProgramListSaveButton !== null)
            catsSystemProgramListSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addNewProgramButton = document.getElementById("addNewProgram");
        if (addNewProgramButton !== null)
            addNewProgramButton.addEventListener("click", (e) => this.addNewProgram());
        const viewListButton = document.getElementById("viewList");
        if (viewListButton !== null)
            viewListButton.addEventListener("click", (e) => this.filter());
        const showDeletedProgramsElement = document.getElementById("showDeletedPrograms");
        if (showDeletedProgramsElement !== null) {
            showDeletedProgramsElement.addEventListener("click", (e) => this.showDeletedPrograms(e.target));
        }
        const catsButton = document.getElementById("CATS320");
        if (catsButton !== null) {
            catsButton.addEventListener("click", (e) => this.printCats(e));
        }
        const studentIndustryCertsButton = document.getElementById("StudentIndustryCerts");
        if (studentIndustryCertsButton !== null) {
            studentIndustryCertsButton.addEventListener("click", (e) => this.printStudentIndustryCerts(e));
        }
        const exportToWordButtons = document.getElementsByClassName("exportProgramToWord");
        for (const exportToWordButton of exportToWordButtons)
            exportToWordButton.addEventListener("click", (e) => this.exportToWord(e));
        this.saveAccess();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    showDeletedPrograms(checkbox) {
        let planFK = checkbox.dataset.planfk;
        let href = `/CATSSystem/CatsSystemProgramList/${planFK}`;
        if (checkbox.checked) {
            location.href = href + '?showDeleted=true';
        }
        else {
            location.href = href;
        }
    }
    filter() {
        let select = document.getElementById("programListSelect");
        let searchString = select.value.toUpperCase();
        let searchRows = document.getElementsByClassName("rowForSearch");
        let startYearElement = document.getElementById("catsSystemProgramListSchoolYears");
        let endYearElement = document.getElementById("catsSystemProgramListSchoolYearsEnd");
        let startYear = parseInt(startYearElement.value);
        let endYear = parseInt(endYearElement.value);
        let useYears = false;
        for (let searchRow of searchRows) {
            let count = 0;
            let element = searchRow;
            let searchRowValue = element.dataset.planstatus.toUpperCase();
            let rowYear = parseInt(element.dataset.year);
            if (rowYear && rowYear > 0 && startYear > 0 && endYear > 0) {
                useYears = true;
            }
            let showThisYear = false;
            if (startYear <= rowYear && rowYear <= endYear) {
                showThisYear = true;
            }
            if (useYears) {
                if (searchString == "ALL" && showThisYear && element.style.display == "none") {
                    element.style.display = "";
                }
                else if ((searchString != searchRowValue && searchString != "ALL") || !showThisYear) {
                    element.style.display = "none";
                }
                else if (searchString == searchRowValue && element.style.display == "none" && showThisYear) {
                    element.style.display = "";
                }
            }
            else {
                if (searchString == "ALL" && element.style.display == "none") {
                    element.style.display = "";
                }
                else if (searchString != searchRowValue && searchString != "ALL") {
                    element.style.display = "none";
                }
                else if (searchString == searchRowValue && element.style.display == "none") {
                    element.style.display = "";
                }
            }
        }
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemProgramListForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemProgramListField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemProgramList', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    saveAccess() {
        const formName = "catsSystemProgramListForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    addNewProgram() {
        let planForm = document.getElementById("catsSystemProgramListForm");
        let planFK = planForm.dataset.planfk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/AddNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const returnData = JSON.parse(xhr.responseText);
                const newPlanPK = returnData.newPlanPK;
                const newProgramPK = returnData.newProgramPK;
                const href = `/CATSSystem/CatsSystemProgramData/${planFK}/${newPlanPK}/${newProgramPK}`;
                Core.createHTMLAlert("alertMessageDiv", "A new program outline has been created.  Please wait while we redirect you to the program.", 'success', 3000, null);
                setTimeout(function () { window.location.href = href; }, 3000);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    exportToWord(e) {
        Core.showLoader();
        const button = e.target;
        const catsProgramFK = button.dataset.catsprogramfk;
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/ExportDocx/FullReportCATSProgramExport/${catsProgramFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "Program.docx";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
                Core.hideLoader();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating the document. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send();
    }
    printCats(e) {
        Core.showLoader();
        let formElement = document.getElementById("catsSystemProgramListForm");
        let catsPlanFK = parseInt(formElement.dataset.planfk);
        let xhr = new XMLHttpRequest();
        /*xhr.open('GET', `/ExportDocx/CATS320WordExport/${catsPlanFK}`, true);*/
        xhr.open('GET', `/CATSSystem/CATS320PDFExport/${catsPlanFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "CATS-320.pdf";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
                Core.hideLoader();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating the document. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send();
    }
    printStudentIndustryCerts(e) {
        Core.showLoader();
        let formElement = document.getElementById("catsSystemProgramListForm");
        let catsPlanFK = parseInt(formElement.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/ExportDocx/CATSStudentIndustryCertificationsWordExport/${catsPlanFK}`, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.responseType = "blob";
        xhr.onload = function () {
            if (xhr.status === 200) {
                let blob = this.response;
                let filename = "Student Industry Credentials.docx";
                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                    document.body.appendChild(a);
                    a.style.display = "none";
                    a.href = blobUrl;
                    a.download = filename;
                    a.click();
                }
                Core.hideLoader();
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue generating the document. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send();
    }
}
// CatsSystemSUMMARYOFPROGRAMS
class CatsSystemSUMMARYOFPROGRAMS {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        this._core.leftnav(this);
        this.saveAccess();
        const addNewProgramButton = document.getElementById("catsSystemSummaryOfProgramsAddNewProgram");
        if (addNewProgramButton !== null)
            addNewProgramButton.addEventListener("click", (e) => this.addNewProgram());
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemSUMMARYOFPROGRAMSForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    addNewProgram() {
        let planForm = document.getElementById("catsSystemSUMMARYOFPROGRAMSForm");
        let planFK = planForm.dataset.planfk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/AddNewProgram', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const returnData = JSON.parse(xhr.responseText);
                const newPlanPK = returnData.newPlanPK;
                const newProgramPK = returnData.newProgramPK;
                const href = `/CATSSystem/CatsSystemProgramData/${planFK}/${newPlanPK}/${newProgramPK}`;
                Core.createHTMLAlert("alertMessageDiv", "A new program outline has been created.  Please wait while we redirect you to the program.", 'success', 3000, null);
                setTimeout(function () { window.location.href = href; }, 3000);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
}
// CatsSystemPROGRAMSSUBMISSION
class CatsSystemPROGRAMSSUBMISSION {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemPROGRAMSSUBMISSIONField"];
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const proceedToSignedAssuranceButton = document.getElementById("proceedToSignedAssurance");
        if (proceedToSignedAssuranceButton !== null)
            proceedToSignedAssuranceButton.addEventListener("click", (e) => this.proceedToSignedAssurance());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemPROGRAMSSUBMISSIONForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    proceedToSignedAssurance() {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById("catsSystemPROGRAMSSUBMISSIONForm");
            const planFK = form.dataset.planfk;
            Core.showLoader();
            let checkedPrograms = [];
            const allChecks = document.querySelectorAll(`.catsSystemSubmissionItem:checked`);
            for (const check of allChecks) {
                const element = check;
                const catsProgramPK = parseInt(element.dataset.catsprogrampk);
                checkedPrograms.push(catsProgramPK);
            }
            if (checkedPrograms.length > 0) {
                const dataModel = {
                    "PlanFK": planFK,
                    "Programs": checkedPrograms
                };
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };
                const response = yield fetch(`/CATSSystem/CreateSubmission`, settings);
                if (response.ok) {
                    const output = yield response.json();
                    const url = `/CATSSystem/CatsSystemSIGNATUREASSURANCE/${planFK}/${output.catsSubmissionPK}`;
                    window.location.href = url;
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "Please choose one or more programs to submit.", 'warning', 3000, null);
                Core.hideLoader();
            }
        });
    }
}
// CatsSystemSIGNATUREASSURANCE
class CatsSystemSIGNATUREASSURANCE {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemSIGNATUREASSURANCEField"];
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const submitPlanButton = document.getElementById("submitPlanButton");
        if (submitPlanButton !== null)
            submitPlanButton.addEventListener("click", (e) => this.submit(e));
        const catsSystemSignaturesWarningCloseButton = document.getElementById("catsSystemSignaturesWarningClose");
        if (catsSystemSignaturesWarningCloseButton !== null)
            catsSystemSignaturesWarningCloseButton.addEventListener("click", (e) => this.closeWarning());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemSIGNATUREASSURANCEForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.planfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    submit(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const button = e.target;
            const allowSubmit = button.dataset.allowsubmit;
            if (allowSubmit === "false") {
                Core.hideLoader();
                const modal = new Modal("signaturesWarningModal", null);
                modal.show();
            }
            else {
                const catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirectorElement = document.getElementById("catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirector");
                const catsSystemSIGNATUREASSURANCEDateElement = document.getElementById("catsSystemSIGNATUREASSURANCEDate");
                let hasName = true;
                let hasDate = true;
                if (catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirectorElement !== null) {
                    const name = catsSystemSIGNATUREASSURANCEChiefSchoolAdministratorCTEDirectorElement.value;
                    if (name === "")
                        hasName = false;
                }
                if (catsSystemSIGNATUREASSURANCEDateElement !== null) {
                    const date = catsSystemSIGNATUREASSURANCEDateElement.value;
                    if (date === "")
                        hasDate = false;
                }
                if (!hasName || !hasDate) {
                    Core.hideLoader();
                    const modal = new Modal("signaturesWarningModal", null);
                    modal.show();
                }
                else {
                    const formElement = document.getElementById("catsSystemSIGNATUREASSURANCEForm");
                    const planFK = parseInt(formElement.dataset.planfk);
                    const catsSubmissionFK = parseInt(formElement.dataset.catssubmissionfk);
                    const allSaveElements = [];
                    let textInputs = document.getElementsByClassName("catsSystemSIGNATUREASSURANCEField");
                    for (let ele of textInputs) {
                        let planPropertyPK = 0;
                        let element = ele;
                        let rowNumber = 0;
                        let propertyPK = parseInt(element.dataset.propertypk);
                        let hadValue = false;
                        if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                            planPropertyPK = parseInt(element.dataset.planpropertypk);
                            hadValue = true;
                        }
                        if (element.value !== "" || hadValue) {
                            let saveItem = {
                                PlanPropertyPK: planPropertyPK,
                                PlanFK: planFK,
                                PropertyFK: propertyPK,
                                TextValue: element.value,
                                LookupCodeFK: null,
                                RowNbr: rowNumber,
                                IsDeletedInd: false
                            };
                            allSaveElements.push(saveItem);
                        }
                    }
                    const dataModel = {
                        "PlanFK": planFK,
                        "CATSSubmissionFK": catsSubmissionFK,
                        "SaveData": allSaveElements
                    };
                    const settings = {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(dataModel)
                    };
                    const submissionResponse = yield fetch(`/CATSSystem/SubmitCATS`, settings);
                    if (submissionResponse) {
                        const resp = yield submissionResponse.json();
                        if (resp.success === true) {
                            button.disabled = true;
                            Core.createHTMLAlert("alertMessageDiv", "The selected programs have been submitted for review. Please wait for re-direction.", 'success', 3000, null);
                            const url = `/CATSSystem/CatsSystemProgramList/${planFK}`;
                            setTimeout(function () { window.location.href = url; }, 3000);
                        }
                        else {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "There was an issue submitting the programs. Please try again.", 'error', 3000, null);
                        }
                    }
                }
            }
        });
    }
    closeWarning() {
        const modal = new Modal("signaturesWarningModal", null);
        modal.hide();
    }
}
// CatsSystemProgramData
class CatsSystemProgramData {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemProgramDataCheckboxField", "catsSystemProgramDataRadioField", "catsSystemProgramDataSelectField", "catsSystemProgramDataFileField"];
        const catsSystemProgramDataSaveButton = document.getElementById("catsSystemProgramDataSave");
        if (catsSystemProgramDataSaveButton !== null)
            catsSystemProgramDataSaveButton.addEventListener("click", (e) => this.save("save"));
        const pathway = document.getElementById("catsSystemProgramDataProgramType");
        if (pathway !== null) {
            pathway.addEventListener("click", (e) => this.lockdownPathway(e.target));
            this.lockdownPathway(pathway);
        }
        const teacherFurloughed = document.querySelectorAll(".catsSystemProgramDataRadioField[data-save='teacherfurloughed']");
        for (const teacher of teacherFurloughed) {
            const element = teacher;
            element.addEventListener("change", (e) => this.checkTeacherFurloughed(e.target));
            this.checkTeacherFurloughed(element);
        }
        this.checkMarkForDeletion();
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.cipAlreadySelected();
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        const instructionalRadioButtons = document.getElementsByClassName("catsSystemProgramDataTOPRadioField");
        for (const instructionalRadioButton of instructionalRadioButtons)
            instructionalRadioButton.addEventListener("change", (e) => this.programAreaOrCluster(e.target));
        const programAreaOrClusterRadio = document.querySelector(".catsSystemProgramDataTOPRadioField:checked");
        if (programAreaOrClusterRadio !== null)
            this.programAreaOrCluster(programAreaOrClusterRadio);
        const clusterProgramAreaSelects = document.getElementsByClassName("catsSystemProgramDataClusterPASelectField");
        for (const clusterProgramAreaSelect of clusterProgramAreaSelects) {
            clusterProgramAreaSelect.addEventListener("change", (e) => this.narrowCIPs(e.target));
        }
        const fileDeletionFile = document.querySelector(`.catsSystemProgramDataFileField[data-file='programdeletion']`);
        if (fileDeletionFile !== null)
            fileDeletionFile.addEventListener("change", (e) => this.uploadFile(e.target, "programdeletion"));
        const programApprovalFile = document.querySelector(`.catsSystemProgramDataFileField[data-file='programapproval']`);
        if (programApprovalFile !== null)
            programApprovalFile.addEventListener("change", (e) => this.uploadFile(e.target, "programapproval"));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        let lockoutexceptions = document.getElementsByClassName("lockoutException");
        for (let lockoutexception of lockoutexceptions) {
            lockoutexception.addEventListener("click", (e) => this._core.updateLockoutException(e));
        }
        const catsSystemProgramDataCIPCodeElement = document.getElementById("catsSystemProgramDataCIPCode");
        if (catsSystemProgramDataCIPCodeElement !== null)
            catsSystemProgramDataCIPCodeElement.addEventListener("change", (e) => this.changeCIPValue(e));
        const catsSystemProgramDataMarkforDeletion = document.getElementById("catsSystemProgramDataMarkforDeletion");
        if (catsSystemProgramDataMarkforDeletion !== null)
            catsSystemProgramDataMarkforDeletion.addEventListener("change", (e) => this.toggleMarkForDeletion());
        const catsSystemCATSProgramDataMarkForDeleteConfirm = document.getElementById("catsSystemCATSProgramDataMarkForDeleteConfirm");
        if (catsSystemCATSProgramDataMarkForDeleteConfirm !== null)
            catsSystemCATSProgramDataMarkForDeleteConfirm.addEventListener("click", (e) => this.markForDeletionConfirm());
        const catsSystemCATSProgramDataMarkForDeleteCancel = document.getElementById("catsSystemCATSProgramDataMarkForDeleteCancel");
        if (catsSystemCATSProgramDataMarkForDeleteCancel !== null)
            catsSystemCATSProgramDataMarkForDeleteCancel.addEventListener("click", (e) => this.markForDeletionCancel());
        this.bindDeleteFiles();
        this.cipAlreadySelected();
    }
    setHash() {
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemProgramDataForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        return __awaiter(this, void 0, void 0, function* () {
            let allSaveElements = [];
            const core = this._core;
            if (referrer !== "save" && this._core.checkSave(this) === false) {
                window.location.href = referrer;
                return false;
            }
            Core.showLoader();
            let refreshPage = "";
            if (referrer === "continue") {
                refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
            }
            if (referrer === "back") {
                refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
            }
            else {
                refreshPage = referrer;
            }
            const formElement = document.getElementById("catsSystemProgramDataForm");
            const planPK = parseInt(formElement.dataset.planfk);
            const programPK = parseInt(formElement.dataset.programfk);
            const typeOfProgramElement = document.querySelector(".catsSystemProgramDataRadioField[data-save='typeofprogram']:checked");
            const clusterElement = document.querySelector(".catsSystemProgramDataSelectField[data-save='cluster']");
            const programAreaElement = document.querySelector(".catsSystemProgramDataSelectField[data-save='programarea']");
            const cipElement = document.querySelector(".catsSystemProgramDataSelectField[data-save='cip']");
            const programTypeElement = document.querySelector(".catsSystemProgramDataSelectField[data-save='programtype']");
            const programLengthElement = document.querySelector(".catsSystemProgramDataSelectField[data-save='programlength']");
            const startSchoolYearElement = document.querySelector(".catsSystemProgramDataSelectField[data-save='schoolyearstart']");
            const posElement = document.querySelector(".catsSystemProgramDataRadioField[data-save='pos']:checked");
            const markForDeletionElement = document.querySelector(".catsSystemProgramDataCheckboxField[data-save='markfordeletion']");
            const teacherFurloghedElement = document.querySelector(".catsSystemProgramDataRadioField[data-save='teacherfurloughed']:checked");
            let typeOfProgram = null;
            let cluster = null;
            let programArea = null;
            let cip = null;
            let programType = null;
            let programLength = null;
            let startSchoolYear = null;
            let pos = null;
            let markForDeletion = null;
            let teacherFurloughed = null;
            if (typeOfProgramElement !== null)
                typeOfProgram = typeOfProgramElement.value;
            if (clusterElement !== null)
                cluster = clusterElement.value;
            if (programAreaElement !== null)
                programArea = programAreaElement.value;
            if (cipElement !== null && cipElement.value !== "0")
                cip = cipElement.value;
            if (programTypeElement !== null && programTypeElement.value !== "0")
                programType = programTypeElement.value;
            if (programLengthElement !== null && programLengthElement.value !== "0")
                programLength = programLengthElement.value;
            if (startSchoolYearElement !== null && startSchoolYearElement.value !== "0")
                startSchoolYear = startSchoolYearElement.value;
            if (posElement !== null)
                pos = posElement.value;
            if (typeOfProgramElement !== null && typeOfProgramElement.value !== "0")
                typeOfProgram = typeOfProgramElement.value;
            if (markForDeletionElement !== null)
                if (markForDeletionElement.checked)
                    markForDeletion = true;
                else
                    markForDeletion = false;
            if (teacherFurloghedElement !== null)
                teacherFurloughed = teacherFurloghedElement.value;
            const saveData = {
                "PlanFK": planPK,
                "ProgramFK": programPK,
                "TypeOfProgram": typeOfProgram,
                "Cluster": cluster,
                "ProgramArea": programArea,
                "Cip": cip,
                "ProgramType": programType,
                "ProgramLength": programLength,
                "StartSchoolYear": startSchoolYear,
                "Pos": pos,
                "MarkForDeletion": markForDeletion,
                "TeacherFurloughed": teacherFurloughed
            };
            let isDuplicate = false;
            let response = yield fetch(`/CATSSystem/CheckForDuplicateCIP/${planPK}/${cip}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.json();
                if (output.isDuplicate === true)
                    isDuplicate = true;
                if (!isDuplicate) {
                    let allFileElements = [];
                    let files = document.getElementsByClassName("catsSystemProgramDataFileUploadField");
                    for (let ele of files) {
                        let element = ele;
                        let sequenceNbr = parseInt(element.dataset.sequencenbr);
                        let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
                        let fileUploadPK = parseInt(element.dataset.fileuploadpk);
                        let propertyPK = parseInt(element.dataset.propertypk);
                        let fileType = element.dataset.save;
                        let saveItem = {
                            PlanPropertyFilePK: planPropertyFilePK,
                            SequenceNbr: sequenceNbr,
                            FileUploadPK: fileUploadPK,
                            PropertyPK: propertyPK,
                            PlanFK: planPK,
                            FileType: fileType
                        };
                        allFileElements.push(saveItem);
                    }
                    var allData = {
                        "ElementData": saveData,
                        "FileData": allFileElements
                    };
                    let programDataXHR = new XMLHttpRequest();
                    programDataXHR.open('POST', '/CATSSystem/SaveCatsSystemProgramData', true);
                    programDataXHR.setRequestHeader('Content-Type', 'application/json');
                    programDataXHR.onload = function () {
                        if (referrer === "save") {
                            if (programDataXHR.status === 200) {
                                Core.hideLoader();
                                core.pageReload(true, planPK);
                            }
                            else {
                                Core.hideLoader();
                                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + programDataXHR.status, 'error', 3000, null);
                            }
                        }
                        else {
                            if (programDataXHR.status === 200) {
                                if (refreshPage && refreshPage !== "")
                                    window.location.href = refreshPage;
                                else
                                    Core.hideLoader();
                            }
                            else {
                                Core.hideLoader();
                                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + programDataXHR.status, 'error', 3000, null);
                            }
                        }
                    };
                    programDataXHR.send(JSON.stringify(allData));
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This program CIP already exists. Please change the CIP and save again.", 'error', 3000, null);
                }
            }
        });
    }
    programAreaOrCluster(radioElement) {
        if (radioElement && radioElement.checked) {
            const whichOne = radioElement.dataset.lookuplabel;
            const clusterElement = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cluster']");
            const programAreaElement = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='programarea']");
            const cipSelect = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");
            if (whichOne.toLowerCase().indexOf("cluster") >= 0) {
                programAreaElement.selectedIndex = 0;
                //cipSelect.selectedIndex = 0;)
                clusterElement.disabled = false;
                programAreaElement.disabled = true;
            }
            else if (whichOne.toLowerCase().indexOf("program") >= 0) {
                clusterElement.selectedIndex = 0;
                //cipSelect.selectedIndex = 0;
                clusterElement.disabled = true;
                programAreaElement.disabled = false;
            }
            cipSelect.disabled = true;
            const leaveDisabled = clusterElement.dataset.leavedisabled;
            if (leaveDisabled === "true") {
                clusterElement.disabled = true;
                programAreaElement.disabled = true;
                cipSelect.disabled = true;
            }
        }
    }
    cipAlreadySelected() {
        const selectedElement = document.querySelector(".catsSystemProgramDataTOPRadioField:checked");
        if (selectedElement) {
            const typeOfProgram = selectedElement.dataset.lookupcode;
            const cipSelect = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");
            const cluster = document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='cluster']");
            const programArea = document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='programarea']");
            if (cipSelect.selectedIndex > 0) {
                const selectedIndex = cipSelect.selectedIndex;
                const selectedOption = cipSelect[selectedIndex];
                const allCips = document.querySelectorAll(".catsSystemProgramDataSelectField[data-typeofprogram='cip'] option");
                for (let cip of allCips) {
                    let ele = cip;
                    if (ele.classList.contains("hide"))
                        ele.classList.remove("hide");
                }
                if (selectedOption) {
                    if (typeOfProgram.toLowerCase().indexOf("cluster") >= 0) {
                        const clusterPK = selectedOption.dataset.cluster;
                        const programAreaPK = selectedOption.dataset.programarea;
                        cluster.value = clusterPK;
                        for (let i = 0; i < cipSelect.length; i++) {
                            const option = cipSelect[i];
                            if (option.dataset.cluster !== clusterPK)
                                cipSelect[i].classList.add("hide");
                        }
                        programArea.value = programAreaPK;
                        programArea.disabled = true;
                        cipSelect.disabled = false;
                    }
                    else if (typeOfProgram.toLowerCase().indexOf("program") >= 0) {
                        const programAreaPK = selectedOption.dataset.programarea;
                        const clusterPK = selectedOption.dataset.cluster;
                        programArea.value = programAreaPK;
                        for (let i = 0; i < cipSelect.length; i++) {
                            const option = cipSelect[i];
                            if (option.dataset.programarea !== programAreaPK)
                                cipSelect[i].classList.add("hide");
                        }
                        cluster.value = clusterPK;
                        cluster.disabled = true;
                        cipSelect.disabled = false;
                    }
                }
            }
            const leaveDisabled = cluster.dataset.leavedisabled;
            if (leaveDisabled === "true") {
                cluster.disabled = true;
                programArea.disabled = true;
                cipSelect.disabled = true;
            }
        }
        this.setHash();
    }
    narrowCIPs(selectElement, resetIndex = true, firstRun = false) {
        const typeOfProgram = selectElement.dataset.typeofprogram;
        const cipSelect = document.querySelector(".catsSystemProgramDataSelectField[data-typeofprogram='cip']");
        if (resetIndex)
            cipSelect.selectedIndex = 0;
        const cluster = document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='cluster']");
        const programArea = document.querySelector(".catsSystemProgramDataClusterPASelectField[data-typeofprogram='programarea']");
        if (cluster.selectedIndex >= 0 || programArea.selectedIndex >= 0)
            firstRun = false; //Override this if there is a value selected.
        const allCips = document.querySelectorAll(".catsSystemProgramDataSelectField[data-typeofprogram='cip'] option");
        for (let cip of allCips) {
            let ele = cip;
            if (ele.classList.contains("hide"))
                ele.classList.remove("hide");
        }
        if (typeOfProgram === "cluster") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            }
            else {
                cipSelect.disabled = false;
                const clusterPK = selectElement.value;
                for (let i = 0; i < cipSelect.length; i++) {
                    const option = cipSelect[i];
                    if (option.dataset.cluster !== clusterPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        }
        else if (typeOfProgram === "programarea") {
            if (selectElement.selectedIndex <= 0) {
                cipSelect.disabled = true;
            }
            else {
                cipSelect.disabled = false;
                const programAreaPK = selectElement.value;
                for (let i = 0; i < cipSelect.length; i++) {
                    const option = cipSelect[i];
                    if (option.dataset.programarea !== programAreaPK) {
                        cipSelect[i].classList.add("hide");
                    }
                }
            }
        }
        if (!firstRun)
            cipSelect.disabled = false;
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemProgramDataFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    //const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    //if (moreFiles.length === 0) {
                    //    const uploadsElement = <HTMLDivElement>document.getElementById(`uploadedFiles${row}`);
                    //    uploadsElement.classList.add("hide");
                    //}
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    uploadFile(e, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.catsSystemProgramDataFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`);
                        const currentFileList = fileList.querySelectorAll(`.uploadFileList[data-file='${fileType}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemProgramDataFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    checkMarkForDeletion() {
        const checkbox = document.getElementById("catsSystemProgramDataMarkforDeletion");
        const catsSystemProgramDataLetterofExplanationforProgramDeletionElement = document.getElementById("catsSystemProgramDataLetterofExplanationforProgramDeletion");
        const catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApprovalElement = document.getElementById("catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApproval");
        const teacherFurloughedRadios = document.querySelectorAll(`.catsSystemProgramDataRadioField[data-save='teacherfurloughed']`);
        const teacherFurloughedElement = document.getElementById("teacherFurloughed");
        const markForDeletionContainerElement = document.getElementById("markForDeletionContainer");
        if (checkbox.checked) {
            this._core.forceElementRequired(catsSystemProgramDataLetterofExplanationforProgramDeletionElement);
            this._core.forceElementRequired(catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApprovalElement);
            teacherFurloughedElement.classList.remove("hide");
            for (const radio of teacherFurloughedRadios)
                this._core.forceElementRequired(radio);
            markForDeletionContainerElement.classList.add("marked-for-deletion");
        }
        else {
            this._core.forceElementOptional(catsSystemProgramDataLetterofExplanationforProgramDeletionElement);
            this._core.forceElementOptional(catsSystemProgramDataBoardMinutesContainingCurtailmentofProgramApprovalElement);
            teacherFurloughedElement.classList.add("hide");
            for (const radio of teacherFurloughedRadios)
                this._core.forceElementOptional(radio);
            markForDeletionContainerElement.classList.remove("marked-for-deletion");
        }
    }
    lockdownPathway(select) {
        const pathwayRadios = document.querySelectorAll(`.catsSystemProgramDataRadioField[data-save='pos']`);
        if (select.value === "11101") {
            for (const radio of pathwayRadios) {
                var element = radio;
                var lookupValue = element.dataset.lookupcode;
                if (lookupValue = "no")
                    element.checked = true;
                element.disabled = true;
            }
        }
        else {
            for (const radio of pathwayRadios) {
                var element = radio;
                element.disabled = false;
            }
        }
    }
    checkTeacherFurloughed(radioButton) {
        if (radioButton.checked) {
            const value = radioButton.dataset.lookuplabel.toLowerCase();
            const errorMessage = document.getElementById("teacherFurloughedWarningMessage");
            if (value === "yes") {
                errorMessage.classList.remove("hide");
            }
            else {
                errorMessage.classList.add("hide");
            }
        }
    }
    changeCIPValue(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const selectElement = e.target;
            const selectedCIPFK = selectElement.value;
            const programTypeSelect = document.getElementById("catsSystemProgramDataProgramType");
            const response = yield fetch(`/CATSSystem/GetCIPProgramTypeLookup/${selectedCIPFK}`, { credentials: 'include' });
            if (response.ok) {
                const json = yield response.json();
                if (json.programTypeLookupCodeFK !== 0) {
                    programTypeSelect.value = json.programTypeLookupCodeFK;
                }
            }
        });
    }
    toggleMarkForDeletion() {
        const catsSystemProgramDataMarkforDeletion = document.getElementById("catsSystemProgramDataMarkforDeletion");
        if (catsSystemProgramDataMarkforDeletion !== null) {
            if (catsSystemProgramDataMarkforDeletion.checked) {
                const modal = new Modal("markForDeletionModal", null);
                modal.show();
            }
            else {
                this.checkMarkForDeletion();
            }
        }
    }
    markForDeletionCancel() {
        const catsSystemProgramDataMarkforDeletion = document.getElementById("catsSystemProgramDataMarkforDeletion");
        if (catsSystemProgramDataMarkforDeletion !== null) {
            if (catsSystemProgramDataMarkforDeletion.checked) {
                catsSystemProgramDataMarkforDeletion.checked = false;
            }
        }
        const modal = new Modal("markForDeletionModal", null);
        modal.hide();
        this.checkMarkForDeletion();
    }
    markForDeletionConfirm() {
        const modal = new Modal("markForDeletionModal", null);
        modal.hide();
        this.checkMarkForDeletion();
    }
}
// CatsSystemLaborMarketAnalysis
class CatsSystemLaborMarketAnalysis {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemLaborMarketAnalysisField", "catsSystemLaborMarketAnalysisSelectField", "catsSystemLaborMarketAnalysisFileField", "catsSystemLaborMarketAnalysisAdvisoryCommitteeField"];
        const catsSystemLaborMarketAnalysisSaveButton = document.getElementById("catsSystemLaborMarketAnalysisSave");
        if (catsSystemLaborMarketAnalysisSaveButton !== null)
            catsSystemLaborMarketAnalysisSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this.initializeRequiredFieldsCustom();
        this.saveAccess();
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        const hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const catsSystemLaborMarketAnalysisAddRowAddRowButton = document.getElementById("catsSystemLaborMarketAnalysisAddRow");
        if (catsSystemLaborMarketAnalysisAddRowAddRowButton !== null)
            catsSystemLaborMarketAnalysisAddRowAddRowButton.addEventListener("click", (e) => this.addRow());
        const catsSystemLaborMarketAnalysisRowDeleteCancelButton = document.getElementById("catsSystemAdvisoryCommitteeRowDeleteCancel");
        if (catsSystemLaborMarketAnalysisRowDeleteCancelButton !== null)
            catsSystemLaborMarketAnalysisRowDeleteCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const catsSystemLaborMarketAnalysisRowDeleteConfirmButton = document.getElementById("catsSystemAdvisoryCommitteeRowDeleteConfirm");
        if (catsSystemLaborMarketAnalysisRowDeleteConfirmButton !== null)
            catsSystemLaborMarketAnalysisRowDeleteConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const catsSystemLaborMarketAnalysisAddRowNumberElement = document.getElementById("catsSystemLaborMarketAnalysisAddRowNumber");
        if (catsSystemLaborMarketAnalysisAddRowNumberElement !== null)
            catsSystemLaborMarketAnalysisAddRowNumberElement.addEventListener("input", (e) => this.checkPlural(e));
        this.bindDeleteCommitteeRows();
        const fileMeeting1 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='meeting1']`);
        if (fileMeeting1 !== null)
            fileMeeting1.addEventListener("change", (e) => this.uploadFile(e.target, "meeting1"));
        const fileMeeting2 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='meeting2']`);
        if (fileMeeting2 !== null)
            fileMeeting2.addEventListener("change", (e) => this.uploadFile(e.target, "meeting2"));
        const fileSupport1 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='support1']`);
        if (fileSupport1 !== null)
            fileSupport1.addEventListener("change", (e) => this.uploadFile(e.target, "support1"));
        const fileSupport2 = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-file='support2']`);
        if (fileSupport2 !== null)
            fileSupport2.addEventListener("change", (e) => this.uploadFile(e.target, "support2"));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemLaborMarketAnalysisForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let parentPlanFK = parseInt(formElement.dataset.parentplanfk);
        let textInputs = document.getElementsByClassName("catsSystemLaborMarketAnalysisField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let committeeTextInputs = document.getElementsByClassName("catsSystemLaborMarketAnalysisAdvisoryCommitteeInputField");
        for (let ele of committeeTextInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("catsSystemLaborMarketAnalysisSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("catsSystemLaborMarketAnalysisRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let checkboxes = document.getElementsByClassName("catsSystemLaborMarketAnalysisPathwayCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = element.dataset.cipcode;
            else
                val = "";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("catsSystemLaborMarketAnalysisFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ParentPlanFK": parentPlanFK,
            "CATSProgramPlanFK": planPK,
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemLaborMarketAnalysis', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    saveAccess() {
        const formName = "catsSystemLaborMarketAnalysisForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemLaborMarketAnalysisFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindDeleteCommitteeRows() {
        let allDeletes = document.getElementsByClassName("deleteCommitteeRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    showDeleteRow(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteAdvisoryCommitteeRowModal", null);
        modal.addAttributeToElement("deleteAdvisoryCommitteeRowModal", "#catsSystemAdvisoryCommitteeRowDeleteConfirm", "row", row);
        modal.show();
    }
    addRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("catsSystemLaborMarketAnalysisForm");
            let valueElement = document.getElementById("catsSystemLaborMarketAnalysisAddRowNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll("#occupationalAdvisoryCommittee table tbody tr");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/CATSSystem/AddOccupationalAdvisoryCommitteeRow/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.innerHTML = output;
                        newTR.dataset.row = newRow.toString();
                        let table = document.querySelector("#occupationalAdvisoryCommittee table tbody");
                        table.append(newTR);
                        that.bindDeleteCommitteeRows();
                    }
                }
                valueElement.value = "";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `${numberOfRows} row${plural} added!`;
                const wordElement = document.getElementById("catsSystemLaborMarketAnalysisAddRowNumberWord");
                wordElement.innerText = "rows";
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            }
            else {
                valueElement.value = "";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteRowCancel() {
        let modal = new Modal("deleteAdvisoryCommitteeRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.catsSystemLaborMarketAnalysisAdvisoryCommitteeField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#occupationalAdvisoryCommittee table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteAdvisoryCommitteeRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    checkPlural(e) {
        const catsSystemLaborMarketAnalysisAddRowNumberElement = e.target;
        const wordElement = document.getElementById("catsSystemLaborMarketAnalysisAddRowNumberWord");
        const value = parseInt(catsSystemLaborMarketAnalysisAddRowNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        }
        else {
            wordElement.innerText = "rows";
        }
    }
    uploadFile(e, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.catsSystemLaborMarketAnalysisFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemLaborMarketAnalysisFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        if (fileType.indexOf("meeting") >= 0)
                            fileList.innerHTML = "";
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    initializeRequiredFieldsCustom() {
        //If any field in a row is filled out, force rest of row required (except email)
        let committeeTable = document.getElementById("occupationalAdvisoryCommitteeTable");
        let committeeTableRows = committeeTable.querySelectorAll("tr[data-row]");
        let rowCounter = 0;
        for (let row of committeeTableRows) {
            let elements = row.getElementsByClassName("catsSystemLaborMarketAnalysisAdvisoryCommitteeField");
            for (let element of elements) {
                if (element instanceof HTMLSelectElement) {
                    let htmlElement = element;
                    if (htmlElement.selectedIndex > 0) {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
                else if (element instanceof HTMLInputElement) {
                    let htmlElement = element;
                    if (element.value != "") {
                        for (let otherElement of elements) {
                            otherElement.dataset.forcerequired = "true";
                        }
                        break;
                    }
                }
            }
            for (let ele of elements) {
                let valueCounter = 0;
                if (ele instanceof HTMLSelectElement) {
                    let htmlElement = ele;
                    if (htmlElement.selectedIndex > 0) {
                        valueCounter++;
                    }
                }
                else if (ele instanceof HTMLInputElement) {
                    let htmlElement = ele;
                    if (ele.value != "") {
                        valueCounter++;
                    }
                }
                if (valueCounter === 4) {
                    rowCounter++;
                }
            }
        }
        let alreadyApproved = document.getElementById("alreadyApproved");
        if (alreadyApproved !== null && alreadyApproved.value === "true") {
            const fieldsToForce = document.querySelectorAll(".forceApprovedOAC");
            for (const field of fieldsToForce) {
                const ele = field;
                this._core.forceElementRequired(ele);
            }
        }
        this._core.initializeRequiredFields(this.validationClasses);
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        //do the pathway validation
        let pathwayEle = document.getElementById("pathwayIsThere");
        if (pathwayEle != null) {
            const allChecks = document.querySelectorAll(".catscheckbox:checked");
            var label = document.getElementById("catsSystemLaborMarketAnalysisProgramsLabelId");
            if (allChecks.length <= 1) {
                label.classList.add("hasBeenValidated");
                label.innerHTML = "<span class='missing-field-label'><i class='fas fa-exclamation-triangle' aria-hidden='true'></i></span> " + label.innerHTML + " <span class='required-label'>*</span>";
                label.classList.add("isRequired");
                label.classList.add("missing-field");
                label.setAttribute("aria-invalid", "true");
                showMessage = true;
                totalErrors++;
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// CatsSystemStudentTechnical
class CatsSystemStudentTechnical {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemStudentTechnicalField", "catsSystemStudentTechnicalFileField"];
        let catsSystemStudentTechnicalSaveButton = document.getElementById("catsSystemStudentTechnicalSave");
        if (catsSystemStudentTechnicalSaveButton !== null)
            catsSystemStudentTechnicalSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const fileTaskGrid = document.querySelector(`.catsSystemStudentTechnicalFileField[data-file='taskgrid']`);
        if (fileTaskGrid !== null)
            fileTaskGrid.addEventListener("change", (e) => this.uploadFile(e.target, "taskgrid"));
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemStudentTechnicalForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemStudentTechnicalField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("catsSystemStudentTechnicalFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemStudentTechnical', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    saveAccess() {
        const formName = "catsSystemStudentTechnicalForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    uploadFile(e, fileType) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.catsSystemStudentTechnicalFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList[data-file='${fileType}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${fileType}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.save = fileType;
                        fileA.classList.add("catsSystemStudentTechnicalFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "row", row);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let row = buttonElement.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.catsSystemStudentTechnicalFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-row='${row}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-row='${row}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles${row}`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// CatsSystemAccountabilityTargets
class CatsSystemAccountabilityTargets {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemAccountabilityTargetsField", "catsSystemAccountabilityTargetsRadioField", "catsSystemAccountabilityTargetsSelectField"];
        let catsSystemAccountabilityTargetsSaveButton = document.getElementById("catsSystemAccountabilityTargetsSave");
        if (catsSystemAccountabilityTargetsSaveButton !== null)
            catsSystemAccountabilityTargetsSaveButton.addEventListener("click", (e) => this.save("save"));
        const flexibleInstructionDaysExRadioFields = document.getElementsByClassName("catsSystemAccountabilityTargetsNewApprovalRadioField");
        for (const flexibleInstructionDaysExRadioField of flexibleInstructionDaysExRadioFields) {
            flexibleInstructionDaysExRadioField.addEventListener("change", (e) => this.checkForSchoolPerformance(e.target));
            this.checkForSchoolPerformance(flexibleInstructionDaysExRadioField);
        }
        this.bindAssessmentTypeDropdowns();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let catsSystemAddAccountibilityTargetButton = document.getElementById("catsSystemAddAccountabilityTarget");
        if (catsSystemAddAccountibilityTargetButton !== null)
            catsSystemAddAccountibilityTargetButton.addEventListener("click", (e) => this.addAccountabilityTarget());
        this.changeAccountabilityTargets();
        this.updateAccountabilityTargets();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemAccountabilityTargetsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemAccountabilityTargetsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemAccountabilityTargetsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("catsSystemAccountabilityTargetsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let selectInputs = document.getElementsByClassName("catsSystemAccountabilityTargetsSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccountabilityTargets', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                this.doValidationCustom(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addAccountabilityTarget() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let formElement = document.getElementById("catsSystemAccountabilityTargetsForm");
            let planFK = formElement.dataset.planfk;
            let newRow = 0;
            let allRows = document.querySelectorAll(".accountabilityTargetsRow");
            for (let row of allRows) {
                let rowEle = row;
                let thisRow = parseInt(rowEle.dataset.row);
                if (thisRow > newRow)
                    newRow = thisRow;
            }
            newRow++;
            let response = yield fetch(`/CATSSystem/AddAccountabilityTarget/${planFK}/${newRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newDiv = document.createElement("div");
                newDiv.innerHTML = output;
                newDiv.dataset.row = newRow.toString();
                newDiv.classList.add("accountabilityTargetsRow");
                let comtainer = document.querySelector("#accountabilityTargets");
                comtainer.append(newDiv);
                this.bindAssessmentTypeDropdowns();
            }
            const message = `Assessment row item has been added!`;
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        });
    }
    initializeRequiredFieldsCustom(validationClasses) {
        this._core.initializeRequiredFields(this.validationClasses);
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = element;
                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");
                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated = false;
                                    let isChecked = false;
                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }
                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }
                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            }
                            else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLSelectElement) {
                            let selectElement = element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                            let hasSelection = false;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }
                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        let numberOfStudents;
        let onlyNumbersElement = document.getElementsByClassName("onlyNumbers");
        for (let ele of onlyNumbersElement) {
            let element = ele;
            numberOfStudents = Number(element.value);
            if (!isNaN(numberOfStudents)) {
                element.classList.remove("missing-field");
                //showMessage = false;
            }
            else {
                totalErrors++;
                showMessage = true;
                element.classList.add("missing-field");
            }
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    checkForSchoolPerformance(radio) {
        if (radio.checked) {
            const catsSystemAccountabilityTargetsSchoolPerformanceFields = document.getElementsByClassName("catsSystemAccountabilityTargetsSchoolPerformanceField");
            if (radio.dataset.lookupcode === "yes") {
                for (const field of catsSystemAccountabilityTargetsSchoolPerformanceFields) {
                    const ele = field;
                    ele.disabled = true;
                    this._core.forceElementOptional(ele);
                    if (ele.dataset.lookupcode === "no")
                        ele.checked = true;
                }
                this.changeAssessmentType(true);
            }
            else {
                for (const field of catsSystemAccountabilityTargetsSchoolPerformanceFields) {
                    const ele = field;
                    ele.disabled = false;
                    this._core.forceElementRequired(ele);
                    this.changeAssessmentType(true);
                }
            }
        }
    }
    changeAccountabilityTargets() {
        return __awaiter(this, void 0, void 0, function* () {
            const yearElement = document.getElementById("catsSystemAccountabilityTargetsTargetYear");
            const academicYearFK = yearElement.dataset.academicyearfk;
            let response = yield fetch(`/CATSSystem/ChangeAccountabilityTargets/${academicYearFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.json();
                //clear everything first
                const allTargets = document.querySelectorAll(".catsSystemAccountabilityTargetsEditField");
                for (const target of allTargets)
                    target.value = "0";
                if (output.narrowedList.length > 0) {
                    for (const ele of output.narrowedList) {
                        const code = ele.performanceIndicatorCode;
                        const textElement = document.querySelector(`.catsSystemAccountabilityTargetsEditField[data-code='${code}']`);
                        textElement.value = ele.annualStateTargetPercent;
                    }
                }
                this.updateAccountabilityTargets();
            }
        });
    }
    updateAccountabilityTargets() {
        const spanElement = document.getElementById("annualStateTargetsYear");
        if (spanElement !== null) {
            const yearElement = document.getElementById("catsSystemAccountabilityTargetsTargetYear");
            spanElement.textContent = yearElement.value;
        }
    }
    bindAssessmentTypeDropdowns() {
        const allAssessmentTypes = document.getElementsByClassName("catsSystemAccountabilityTargetsSelectField");
        for (const assessmentType of allAssessmentTypes) {
            assessmentType.addEventListener("change", (e) => this.changeAssessmentType(false));
            this.changeAssessmentType(true);
        }
    }
    changeAssessmentType(firstLoad) {
        let that = this;
        let row = 0;
        const selectElement = document.querySelector(`.catsSystemAccountabilityTargetsSelectField[data-row='${row}']`);
        const selectedOption = selectElement[selectElement.selectedIndex];
        const selectedText = selectedOption.text;
        /*const row = assessmentType.dataset.row;*/
        const codeElement = document.querySelector(`.catsSystemAccountabilityTargetsTestCodeField[data-row='${row}']`);
        const levelElement = document.getElementById("catsSystemAccountabilityTargetsExpectedLevelofStudentPerformanceonNOCTINIMS");
        const teacher5S4FurloughedRadios = document.querySelectorAll(`.catsSystemAccountabilityTargetsRadioField[name='catsSystemAccountabilityTargets5S4SchoolPerformanceName']`);
        const teacher5S1FurloughedRadios = document.querySelectorAll(`.catsSystemAccountabilityTargetsRadioField[name='catsSystemAccountabilityTargets5S1SchoolPerformanceName']`);
        let tr = document.getElementById("tr5S4");
        const newProgramRadios = document.querySelectorAll(`.catsSystemAccountabilityTargetsRadioField[name='catsSystemAccountabilityTargetsNewProgramApprovalName']`);
        var newProgram = false;
        for (const radio of newProgramRadios) {
            if (radio.checked) {
                if (radio.dataset.lookuplabel === "Yes") {
                    newProgram = true;
                }
                else {
                    newProgram = false;
                }
            }
        }
        if (selectedText === "NOCTI") {
            codeElement.readOnly = false;
            levelElement.readOnly = false;
            that._core.forceElementRequired(levelElement);
            for (const radio of teacher5S4FurloughedRadios) {
                this._core.forceElementRequired(radio);
                tr.classList.remove("hide");
            }
            if (!firstLoad)
                codeElement.value = "";
        }
        else if (selectedText === "NIMS") {
            codeElement.value = "0001";
            codeElement.readOnly = true;
            levelElement.readOnly = false;
            that._core.forceElementRequired(levelElement);
            for (const radio of teacher5S4FurloughedRadios) {
                this._core.forceElementRequired(radio);
                tr.classList.remove("hide");
            }
        }
        else if (selectedText === "Test Exempt") {
            codeElement.value = "0000";
            codeElement.readOnly = true;
            levelElement.value = "";
            levelElement.readOnly = true;
            that._core.forceElementOptional(levelElement);
            for (const radio of teacher5S4FurloughedRadios) {
                this._core.forceElementRequired(radio);
                tr.classList.add("hide");
            }
            if (!newProgram) {
                for (const radio of teacher5S1FurloughedRadios) {
                    this._core.forceElementRequired(radio);
                }
            }
        }
    }
}
// CatsSystemProspectiveStudents
class CatsSystemProspectiveStudents {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemProspectiveStudentsField"];
        let catsSystemProspectiveStudentsSaveButton = document.getElementById("catsSystemProspectiveStudentsSave");
        if (catsSystemProspectiveStudentsSaveButton !== null)
            catsSystemProspectiveStudentsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemProspectiveStudentsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemProspectiveStudentsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemProspectiveStudentsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemProspectiveStudents', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                this.doValidationCustom(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    initializeRequiredFieldsCustom(validationClasses) {
        this._core.initializeRequiredFields(this.validationClasses);
        let showMessage = false;
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLInputElement) {
                            let inputElement = element;
                            //Only validate once for radio buttons
                            if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                if (inputElement.hasAttribute("name")) {
                                    let radioName = inputElement.getAttribute("name");
                                    let radioButtons = document.getElementsByName(radioName);
                                    let alreadyValidated = false;
                                    let isChecked = false;
                                    for (var i = 0, length = radioButtons.length; i < length; i++) {
                                        let radioElement = radioButtons[i];
                                        if (radioElement.classList.contains("missing-field")) {
                                            alreadyValidated = true;
                                        }
                                        if (radioElement.checked) {
                                            isChecked = true;
                                        }
                                    }
                                    if (!alreadyValidated && !isChecked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                            }
                            else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (inputElement.value === "") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLSelectElement) {
                            let selectElement = element;
                            if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                selectElement.classList.add("missing-field");
                                selectElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(selectElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLTextAreaElement) {
                            let textAreaElement = element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                            let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                            let hasSelection = false;
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    hasSelection = true;
                                    break;
                                }
                            }
                            if (!hasSelection) {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        let numberOfStudents;
        let prospectiveStudentsElement = document.getElementById("catsSystemProspectiveStudentsofProspectiveStudents");
        if (prospectiveStudentsElement !== null)
            numberOfStudents = Number(prospectiveStudentsElement.value);
        if (!isNaN(numberOfStudents) && numberOfStudents > 0) {
            prospectiveStudentsElement.classList.remove("missing-field");
            showMessage = false;
        }
        else {
            totalErrors++;
            showMessage = true;
            prospectiveStudentsElement.classList.add("missing-field");
        }
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// CatsSystemTechAndResources
class CatsSystemTechAndResources {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemTechAndResourcesField"];
        let catsSystemTechAndResourcesSaveButton = document.getElementById("catsSystemTechAndResourcesSave");
        if (catsSystemTechAndResourcesSaveButton !== null)
            catsSystemTechAndResourcesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemTechAndResourcesForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemTechAndResourcesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemTechAndResourcesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemTechAndResources', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// CatsSystemSupportService
class CatsSystemSupportService {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemSupportServiceField"];
        let catsSystemSupportServiceSaveButton = document.getElementById("catsSystemSupportServiceSave");
        if (catsSystemSupportServiceSaveButton !== null)
            catsSystemSupportServiceSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemSupportServiceForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemSupportServiceField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemSupportService', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// CatsSystemCareerAndTech
class CatsSystemCareerAndTech {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemCareerAndTechField", "catsSystemCareerAndTechFileField"];
        let catsSystemCareerAndTechSaveButton = document.getElementById("catsSystemCareerAndTechSave");
        if (catsSystemCareerAndTechSaveButton !== null)
            catsSystemCareerAndTechSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, false, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const catsSystemAddCTSOButton = document.getElementById("catsSystemAddCTSO");
        if (catsSystemAddCTSOButton !== null)
            catsSystemAddCTSOButton.addEventListener("click", (e) => this.addCTSO());
        this.bindUploaders();
        this.bindDeletes();
        this.saveAccess();
        const catsSystemCTSODeleteConfirmButton = document.getElementById("catsSystemCTSODeleteConfirm");
        if (catsSystemCTSODeleteConfirmButton !== null)
            catsSystemCTSODeleteConfirmButton.addEventListener("click", (e) => this.deleteCTSOConfirm(e));
        const catsSystemCTSODeleteCancelButton = document.getElementById("catsSystemCTSODeleteCancel");
        if (catsSystemCTSODeleteCancelButton !== null)
            catsSystemCTSODeleteCancelButton.addEventListener("click", (e) => this.deleteCTSOCancel());
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemCareerAndTechForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCareerAndTechForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemCareerAndTechField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };
                allSaveElements.push(saveItem);
            }
        }
        let files = document.getElementsByClassName("catsSystemCareerAndTechFileUploadField");
        let allFileElements = [];
        for (let ele of files) {
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let propertyRelationshipTypePK = parseInt(element.dataset.propertyrelationtypepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let planPropertyPlanPropertyFilePK = parseInt(element.dataset.planpropertyplanpropertyfilepk);
            let saveItem = {
                PlanPropertyPK: dataPointPlanPropertyPK,
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                PropertyRelationshipTypePK: propertyRelationshipTypePK,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanPropertyPlanPropertyFilePK: planPropertyPlanPropertyFilePK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanPK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemCareerAndTech', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Add a CTSO (or data inside of an added CTSO).", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    addCTSO() {
        return __awaiter(this, void 0, void 0, function* () {
            const form = document.getElementById("catsSystemCareerAndTechForm");
            const planFK = form.dataset.planfk;
            const dropdown = document.getElementById("catsSystemCareerAndTechCTSODropdown");
            const dropdownValue = parseInt(dropdown.value);
            if (dropdownValue && !isNaN(dropdownValue) && dropdownValue > 0) {
                Core.showLoader();
                const response = yield fetch(`/CATSSystem/AddCTSO/${planFK}/${dropdownValue}`, { credentials: 'include' });
                if (response.ok) {
                    const output = yield response.text();
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = output;
                    let container = document.getElementById("ctsoContainer");
                    container.append(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);
                    this.bindUploaders();
                    this.bindDeletes();
                    this._core.initializeRequiredFields(this.validationClasses, false, true);
                }
                Core.hideLoader();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Please select a CTSO value to add to the list`, "warning", 3000, null);
            }
        });
    }
    bindUploaders() {
        const fileUploaders = document.getElementsByClassName("catsSystemCareerAndTechUploadCTSOMembershipDocuments");
        for (const fileUploader of fileUploaders) {
            let ele = fileUploader;
            let listener = ele.dataset.listener;
            if (listener === "false") {
                ele.addEventListener("change", (e) => this.uploadFile(e.target));
                ele.setAttribute("listener", "true");
                ele.dataset.listener = "true";
            }
        }
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let dataPointPlanPropertyFK = e.dataset.datapointplanpropertypk;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let that = this;
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/SchoolReconfigurations/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.catsSystemCareerAndTechUploadCTSOMembershipDocuments[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        let currentFileList = fileList.querySelectorAll(".uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']");
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.datapointplanpropertypk = dataPointPlanPropertyFK.toString();
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.propertyrelationtypepk = propertyRelationTypePK;
                        fileA.dataset.planpropertyplanpropertyfilepk = "0";
                        fileA.classList.add("catsSystemCareerAndTechFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteCTSO");
        for (const thisDelete of allDeletes)
            thisDelete.addEventListener("click", (e) => this.showDeleteCTSO(e));
    }
    showDeleteCTSO(e) {
        const buttonElement = e.target;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;
        const modal = new Modal("deleteCTSOModal", null);
        modal.addAttributeToElement("deleteCTSOModal", "#catsSystemCTSODeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyFK);
        modal.show();
    }
    deleteCTSOCancel() {
        const modal = new Modal("deleteCTSOModal", null);
        modal.hide();
    }
    deleteCTSOConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const buttonElement = e.target;
            const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertypk;
            Core.showLoader();
            const response = yield fetch(`/CATSSystem/DeleteCTSO/${dataPointPlanPropertyFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                if (output === "true") {
                    const container = document.querySelector(`.Accordion-trigger[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                    // button > h2          > div.Accordion     > div
                    container.parentElement.parentElement.parentElement.remove();
                    Core.createHTMLAlert("alertMessageDiv", `The record was successfully removed.`, "success", 3000, null);
                    Core.hideLoader();
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
                    Core.hideLoader();
                }
                const modal = new Modal("deleteCTSOModal", null);
                modal.hide();
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the record. Please try again.`, "error", 3000, null);
                Core.hideLoader();
                const modal = new Modal("deleteCTSOModal", null);
                modal.hide();
            }
        });
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.catsSystemCareerAndTechFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// CatsSystemAdvisoryCommittees
class CatsSystemAdvisoryCommittees {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemAdvisoryCommitteesField", "catsSystemAdvisoryCommitteesFileField"];
        let catsSystemAdvisoryCommitteesSaveButton = document.getElementById("catsSystemAdvisoryCommitteesSave");
        if (catsSystemAdvisoryCommitteesSaveButton !== null)
            catsSystemAdvisoryCommitteesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addCommitteeButton = document.getElementById("catsSystemAddAdvisoryCommitteesDetails");
        if (addCommitteeButton !== null)
            addCommitteeButton.addEventListener("click", (e) => this.addAdvisoryCommitteesDetails());
        this.bindFileUploaders();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemAdvisoryCommitteesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemAdvisoryCommitteesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("catsSystemAdvisoryCommitteesFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.row);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAdvisoryCommittees', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    addAdvisoryCommitteesDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemAdvisoryCommitteesForm");
            const planFK = form.dataset.planfk;
            const allRows = document.querySelectorAll(".advisoryCommitteesDetailsRow");
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddAdvisoryCommitteesDetails/${planFK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newDiv = document.createElement("div");
                newDiv.innerHTML = output;
                newDiv.classList.add("advisoryCommitteesDetailsRow");
                newDiv.dataset.row = row.toString();
                newDiv.classList.add("advisory-committees-details");
                let container = document.getElementById("advisoryCommitteesDetails");
                container.append(newDiv);
                this.bindFileUploaders();
            }
            Core.hideLoader();
        });
    }
    bindFileUploaders() {
        const fileUploaders = document.getElementsByClassName("catsSystemAdvisoryCommitteesFileField");
        for (const fileUploader of fileUploaders)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let row = e.dataset.row;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`#catsSystemAdvisoryCommitteeMeetingMinutes${row}`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = row.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemAdvisoryCommitteesFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                        //that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
}
// CatsSystemScope
class CatsSystemScope {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemScopeField", "catsSystemScopeFileField"];
        let catsSystemScopeSaveButton = document.getElementById("catsSystemScopeSave");
        if (catsSystemScopeSaveButton !== null)
            catsSystemScopeSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        this._core.initializeRequiredFields(this.validationClasses);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        const catsSystemScopeAndSequenceAddArticulationAgreementButton = document.getElementById("catsSystemScopeAndSequenceAddArticulationAgreement");
        if (catsSystemScopeAndSequenceAddArticulationAgreementButton !== null)
            catsSystemScopeAndSequenceAddArticulationAgreementButton.addEventListener("click", (e) => this.addArticulationAgreement());
        this.bindUploaders();
        this.bindDeleteFiles();
        this.bindDeleteSecondaryArticulationAgreements();
        this.bindDeletePrimaryArticulationAgreements();
        const catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirmButton = document.getElementById("catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirm");
        if (catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirmButton !== null)
            catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirmButton.addEventListener("click", (e) => this.deleteSecondaryArticulationAgreementConfirm(e));
        const catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancelButton = document.getElementById("catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancel");
        if (catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancelButton !== null)
            catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteCancelButton.addEventListener("click", (e) => this.deleteSecondaryArticulationAgreementCancel());
        const catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirmButton = document.getElementById("catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirm");
        if (catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirmButton !== null)
            catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirmButton.addEventListener("click", (e) => this.deletePrimaryArticulationAgreementConfirm(e));
        const catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancelButton = document.getElementById("catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancel");
        if (catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancelButton !== null)
            catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteCancelButton.addEventListener("click", (e) => this.deletePrimaryArticulationAgreementCancel());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemScopeForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("catsSystemScopeForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const programFK = parseInt(formElement.dataset.catsprogramfk);
        let primaryArticulations = [];
        let secondaryArticulations = [];
        const allArticulations = document.querySelectorAll(".articulationAgreementContainer");
        for (const articulation of allArticulations) {
            const ele = articulation;
            const articulationAgreementPK = ele.dataset.articulationagreementpk;
            const sequence = ele.dataset.sequence;
            const articulationCode = ele.dataset.articulationcode;
            const nameQuery = document.querySelector(`.catsSystemScopeArticulationNameField[data-articulationagreementpk='${articulationAgreementPK}']`);
            let articulationName = "";
            if (nameQuery && nameQuery.value !== "")
                articulationName = nameQuery.value;
            const typeQuery = document.querySelector(`.catsSystemScopeArticulationTypeField[data-articulationagreementpk='${articulationAgreementPK}']`);
            let articulationType = "";
            if (typeQuery && typeQuery.value !== "")
                articulationType = typeQuery.value;
            //Primary file
            const primaryFile = document.querySelector(`.catsSystemScopePrimaryFileUploadField[data-articulationagreementpk='${articulationAgreementPK}'][data-sequence='${sequence}']`);
            let primaryFilePlanPropertyFilePK = "0";
            let primaryFileFileUploadPK = "0";
            let primaryFileSequenceNbr = "0";
            let primaryPropertyPK = "0";
            let articulationAgreementFilePK = "0";
            if (articulationCode === "catsArticulationAgreementStatewide")
                primaryFilePlanPropertyFilePK = null;
            if (primaryFile !== null) {
                primaryFilePlanPropertyFilePK = primaryFile.dataset.planpropertyfilepk;
                primaryFileFileUploadPK = primaryFile.dataset.fileuploadpk;
                primaryFileSequenceNbr = primaryFile.dataset.filesequence;
                primaryPropertyPK = primaryFile.dataset.propertypk;
                articulationAgreementFilePK = primaryFile.dataset.catsarticulationagreementplanpropertyfilepk;
            }
            const primaryArticulation = {
                "ArticulationAgreementPK": articulationAgreementPK,
                "CATSArticulationAgreementPlanPropertyFilePK": articulationAgreementFilePK,
                "ArticulationName": articulationName,
                "ArticulationTypeName": articulationType,
                "SequenceNbr": sequence,
                "PlanPropertyFilePK": primaryFilePlanPropertyFilePK,
                "FileUploadPK": primaryFileFileUploadPK,
                "FileSequenceNbr": primaryFileSequenceNbr,
                "PlanPK": planPK,
                "CATSProgramPK": programFK,
                "PropertyPK": primaryPropertyPK
            };
            primaryArticulations.push(primaryArticulation);
        }
        const allSecondaryArticulations = document.querySelectorAll(`.catsSystemScopeSecondaryFileUploadField`);
        for (const secondary of allSecondaryArticulations) {
            const ele = secondary;
            const sequence = ele.dataset.filesequence;
            const fileUploadPK = ele.dataset.fileuploadpk;
            const articulationAgreementPK = ele.dataset.articulationagreementpk;
            const catsArticulationAgreementPlanPropertyFilePK = ele.dataset.catsarticulationagreementplanpropertyfilepk;
            const propertyPK = ele.dataset.propertypk;
            const filePlanPropertyFilePK = ele.dataset.planpropertyfilepk;
            const secondaryArticulation = {
                "CATSArticulationAgreementPlanPropertyFilePK": catsArticulationAgreementPlanPropertyFilePK,
                "ArticulationAgreementPK": articulationAgreementPK,
                "FileUploadPK": fileUploadPK,
                "Sequence": sequence,
                "PropertyPK": propertyPK,
                "PlanPropertyFilePK": filePlanPropertyFilePK
            };
            secondaryArticulations.push(secondaryArticulation);
        }
        const data = {
            "PrimaryArticulations": primaryArticulations,
            "SecondaryArticulations": secondaryArticulations,
            "PlanPK": planPK
        };
        if (data !== null && data.PrimaryArticulations.length > 0) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CATSSystem/SaveCatsSystemScope', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (referrer === "save") {
                    if (xhr.status === 200) {
                        Core.hideLoader();
                        core.pageReload(true, planPK);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                }
                else {
                    if (xhr.status === 200) {
                        if (refreshPage && refreshPage !== "")
                            window.location.href = refreshPage;
                        else
                            Core.hideLoader();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    }
                }
            };
            xhr.send(JSON.stringify(data));
        }
        else {
            Core.createHTMLAlert("alertMessageDiv", `Each CIP must have an associated articulation agreement.`, 'warning', 5000, null);
            Core.hideLoader();
        }
    }
    addArticulationAgreement() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const selection = document.getElementById("catsSystemScopeArticulationAgreementwith");
            let selectionLookupPK = "";
            let type = "lookup";
            if (selection !== null) {
                selectionLookupPK = selection.value;
                const option = selection[selection.selectedIndex];
                type = option.dataset.type;
            }
            const form = document.getElementById("catsSystemScopeForm");
            let planFK = form.dataset.planfk;
            let parentPlanFK = form.dataset.parentplanfk;
            let catsProgramFK = form.dataset.catsprogramfk;
            let newSequence = 0;
            const allArticulations = document.querySelectorAll(".articulationAgreementContainer");
            for (const articulation of allArticulations) {
                const ele = articulation;
                let thisSeq = parseInt(ele.dataset.sequence);
                if (!isNaN(thisSeq) && thisSeq > newSequence)
                    newSequence = thisSeq;
            }
            newSequence++;
            let response = yield fetch(`/CATSSystem/AddArticulationAgreement/${parentPlanFK}/${planFK}/${catsProgramFK}/${selectionLookupPK}/${type}/${newSequence}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const newDiv = document.createElement("div");
                newDiv.classList.add("articulationAgreement");
                newDiv.dataset.sequence = newSequence.toString();
                newDiv.innerHTML = output;
                const deleteButton = newDiv.querySelector(".deleteArticulationAgreement");
                const articulationAgreementPK = deleteButton.dataset.articulationagreementpk;
                newDiv.dataset.articulationagreementpk = articulationAgreementPK;
                const container = document.getElementById("catsSystemArticulationAgreements");
                container.appendChild(newDiv);
                selection.selectedIndex = -1;
                this.bindUploaders();
                this.bindDeletePrimaryArticulationAgreements();
                this._core.initializeRequiredFields(this.validationClasses);
                Core.hideLoader();
            }
            else {
                Core.hideLoader();
            }
        });
    }
    bindUploaders() {
        const uploaders = document.getElementsByClassName("catsSystemScopeFileUploader");
        for (const uploader of uploaders)
            uploader.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        Core.showLoader();
        let formName = e.dataset.formname;
        const articulationAgreementPK = e.dataset.articulationagreementpk;
        const articulationName = e.dataset.articulationname;
        const articulationSequence = e.dataset.sequence;
        const uploadSampleForm = document.getElementById(formName);
        const formData = new FormData(uploadSampleForm);
        const propertyPK = e.dataset.propertypk;
        const pageForm = document.getElementById("catsSystemScopeForm");
        const planFK = pageForm.dataset.planfk;
        const programFK = pageForm.dataset.catsprogramfk;
        const that = this;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    const res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        const formFile = document.querySelector(`.catsSystemScopeFileUploader[data-formname='${formName}']`);
                        formFile.value = "";
                        const primaryCheck = document.querySelectorAll(`.primaryFile[data-articulationagreementpk='${articulationAgreementPK}'] .uploadFileColumn`);
                        let sequence = 1;
                        if (primaryCheck && primaryCheck.length == 0) {
                            let fileWrapper = document.createElement("div");
                            fileWrapper.classList.add("medium-6");
                            fileWrapper.classList.add("columns");
                            fileWrapper.classList.add("end");
                            fileWrapper.classList.add("uploadFileColumn");
                            let newFile = document.createElement("div");
                            newFile.classList.add("uploaded-file-container");
                            let fileA = document.createElement("a");
                            fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                            fileA.dataset.row = sequence.toString();
                            fileA.dataset.propertypk = propertyPK;
                            fileA.dataset.filesequence = sequence.toString();
                            fileA.dataset.sequence = articulationSequence.toString();
                            fileA.dataset.propertypk = propertyPK;
                            fileA.dataset.planpropertyfilepk = "0";
                            fileA.dataset.primaryfile = 'primary';
                            fileA.dataset.articulationagreementpk = articulationAgreementPK;
                            fileA.classList.add("catsSystemScopeFileUploadField");
                            fileA.classList.add("catsSystemScopePrimaryFileUploadField");
                            fileA.href = "javascript:void(0);";
                            fileA.text = res.payload.filename;
                            newFile.appendChild(fileA);
                            fileWrapper.appendChild(newFile);
                            const primaryFileContainer = document.querySelector(`.primaryFile[data-articulationagreementpk='${articulationAgreementPK}']`);
                            primaryFileContainer.appendChild(fileWrapper);
                            //that.bindDeleteFiles();
                            const uploadedFilesContainer = document.querySelector(`.uploadedFiles[data-articulationagreementpk='${articulationAgreementPK}']`);
                            uploadedFilesContainer.classList.remove("hide");
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        }
                        else {
                            const allCurrentFiles = document.querySelectorAll(`.catsSystemScopeFileUploadField[data-articulationagreementpk='${articulationAgreementPK}']`);
                            let thisSequence = 1; //The default will start at 1, since there will ALWAYS be 1 file there before this is run.
                            for (const currentFile of allCurrentFiles) {
                                const ele = currentFile;
                                const seq = parseInt(ele.dataset.filesequence);
                                if (seq > thisSequence)
                                    thisSequence = seq;
                            }
                            thisSequence++;
                            const data = {
                                "SequenceNbr": thisSequence,
                                "ArticulationName": articulationName,
                                "ArticulationAgreementPK": articulationAgreementPK,
                                "PlanFK": planFK,
                                "CATSProgramFK": programFK,
                                "FileUploadFK": res.payload.fileUploadPK,
                                "Filename": res.payload.filename,
                                "IsPrimaryFileInd": false
                            };
                            const xhrInner = new XMLHttpRequest();
                            xhrInner.open('POST', '/CATSSystem/AddScopeAndSequenceSecondaryFile', true);
                            xhrInner.setRequestHeader('Content-Type', 'application/json');
                            xhrInner.onload = function () {
                                if (xhr.responseText !== null) {
                                    const output = xhrInner.responseText;
                                    const newDiv = document.createElement("div");
                                    newDiv.classList.add("articulationAgreement");
                                    newDiv.dataset.sequence = thisSequence.toString();
                                    newDiv.innerHTML = output;
                                    const container = document.getElementById("catsSystemArticulationAgreements");
                                    container.appendChild(newDiv);
                                    Core.hideLoader();
                                    Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                                }
                                else {
                                    Core.hideLoader();
                                    Core.createHTMLAlert("alertMessageDiv", "There was an issue uploading the file. Please try again.", 'error', 3000, null);
                                }
                            };
                            xhrInner.send(JSON.stringify(data));
                        }
                        that.bindDeleteFiles();
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        const buttonElement = e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const catsArticulationAgreementPlanPropertyFilePK = buttonElement.dataset.catsarticulationagreementplanpropertyfilepk;
        const modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "articulationagreementpk", articulationAgreementPK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "catsarticulationagreementplanpropertyfilepk", catsArticulationAgreementPlanPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const catsArticulationAgreementPlanPropertyFilePK = parseInt(buttonElement.dataset.catsarticulationagreementplanpropertyfilepk);
        const modal = new Modal("deleteFileModal", null);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteArticulationFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.catsSystemScopePrimaryFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.primaryFile[data-articulationagreementpk='${articulationAgreementPK}'] .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles[data-articulationagreementpk='${articulationAgreementPK}']`);
                        uploadsElement.classList.add("hide");
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                modal.hide();
            }
            else {
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(catsArticulationAgreementPlanPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    bindDeleteSecondaryArticulationAgreements() {
        const allSecondaryAgreements = document.getElementsByClassName("removeSecondaryArticulationAgreement");
        for (const agreement of allSecondaryAgreements)
            agreement.addEventListener("click", (e) => this.showDeleteSecondaryArticulationAgreement(e));
    }
    showDeleteSecondaryArticulationAgreement(e) {
        const buttonElement = e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const modal = new Modal("deleteSecondaryArticulationAgreementModal", null);
        modal.addAttributeToElement("deleteSecondaryArticulationAgreementModal", "#catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirm", "articulationagreementpk", articulationAgreementPK);
        modal.addAttributeToElement("deleteSecondaryArticulationAgreementModal", "#catsSystemScopeAndSequenceSecondaryArticulationAgreementDeleteConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteSecondaryArticulationAgreementCancel() {
        let modal = new Modal("deleteSecondaryArticulationAgreementModal", null);
        modal.hide();
    }
    deleteSecondaryArticulationAgreementConfirm(e) {
        const buttonElement = e.target;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const modal = new Modal("deleteSecondaryArticulationAgreementModal", null);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteSecondaryArticulationAgreementData', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.removeSecondaryArticulationAgreementContainer[data-articulationagreementpk='${articulationAgreementPK}'][data-planpropertyfilepk='${planPropertyFilePK}']`);
                    element.remove();
                    Core.createHTMLAlert("alertMessageDiv", "Articulation Agreement successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the articulation agreement. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                modal.hide();
            }
            else {
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`articulationAgreementPK=${articulationAgreementPK}&planPropertyFilePK=${planPropertyFilePK}`);
    }
    bindDeletePrimaryArticulationAgreements() {
        const allPrimaryAgreements = document.getElementsByClassName("deleteArticulationAgreement");
        for (const agreement of allPrimaryAgreements)
            agreement.addEventListener("click", (e) => this.showDeletePrimaryArticulationAgreement(e));
    }
    showDeletePrimaryArticulationAgreement(e) {
        const buttonElement = e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const modal = new Modal("deletePrimaryArticulationAgreementModal", null);
        modal.addAttributeToElement("deletePrimaryArticulationAgreementModal", "#catsSystemScopeAndSequencePrimaryArticulationAgreementDeleteConfirm", "articulationagreementpk", articulationAgreementPK);
        modal.show();
    }
    deletePrimaryArticulationAgreementCancel() {
        let modal = new Modal("deletePrimaryArticulationAgreementModal", null);
        modal.hide();
    }
    deletePrimaryArticulationAgreementConfirm(e) {
        const buttonElement = e.target;
        const articulationAgreementPK = buttonElement.dataset.articulationagreementpk;
        const modal = new Modal("deletePrimaryArticulationAgreementModal", null);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeletePrimaryArticulationAgreementData', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.articulationAgreement[data-articulationagreementpk='${articulationAgreementPK}']`);
                    element.remove();
                    Core.createHTMLAlert("alertMessageDiv", "Articulation Agreement successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the articulation agreement. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                modal.hide();
            }
            else {
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`articulationAgreementPK=${articulationAgreementPK}`);
    }
}
// CatsSystemsSecondary
class CatsSystemSecondary {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemSecondaryField"];
        let catsSystemSecondarySaveButton = document.getElementById("catsSystemSecondarySave");
        if (catsSystemSecondarySaveButton !== null)
            catsSystemSecondarySaveButton.addEventListener("click", (e) => this.save("save"));
        this.alignTechnical();
        this.sumHours();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addSecondarySchoolTechnicalRowButton = document.querySelector(`.addSecondarySchoolTechnicalRow`);
        if (addSecondarySchoolTechnicalRowButton !== null)
            addSecondarySchoolTechnicalRowButton.addEventListener("click", (e) => this.addSecondarySchoolTechnicalRow());
        const addSecondarySchoolAcademicRows = document.getElementsByClassName("addSecondarySchoolAcademicRow");
        for (const addSecondarySchoolAcademicRow of addSecondarySchoolAcademicRows)
            addSecondarySchoolAcademicRow.addEventListener("click", (e) => this.addSecondarySchoolAcademicRow(e));
        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e) => this.deleteScopeCancel());
        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e) => this.deleteScopeConfirm(e));
        this.bindDeleteButtons();
        this.bindSumHours();
    }
    bindSumHours() {
        const catsSystemScopeAndSequenceSecondaryHoursElements = document.getElementsByClassName("catsSystemScopeAndSequenceSecondaryHours");
        for (const catsSystemScopeAndSequenceSecondaryHours of catsSystemScopeAndSequenceSecondaryHoursElements)
            catsSystemScopeAndSequenceSecondaryHours.addEventListener("input", (e) => this.sumHours());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemSecondaryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemSecondaryForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemSecondaryField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                };
                allSaveElements.push(saveItem);
            }
        }
        const allData = {
            "ElementData": allSaveElements,
            "PlanFK": planPK,
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemSecondary', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    addSecondarySchoolTechnicalRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemSecondaryForm");
            const propertyCodeType = "technical";
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(".secondaryTechnicalRow");
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            let isFirstRow = false;
            if (allRows.length === 0)
                isFirstRow = true;
            const response = yield fetch(`/CATSSystem/AddSecondarySchoolTechnicalCoursesRow/${planPK}/${programPK}/${row}/${isFirstRow}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("secondaryTechnicalRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.propertycodetype = propertyCodeType;
                newTR.dataset.row = row.toString();
                let container = document.querySelector("#secondarySchoolTechnicalCourses tbody");
                container.append(newTR);
                this.bindDeleteButtons();
                this.alignTechnical();
                this.bindSumHours();
            }
            Core.hideLoader();
        });
    }
    addSecondarySchoolAcademicRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const propertyCodeType = buttonElement.dataset.propertycodetype;
            const form = document.getElementById("catsSystemSecondaryForm");
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(`.secondaryAcademicRow[data-propertycodetype='${propertyCodeType}']`);
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddSecondarySchoolAcademicCoursesRow/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("secondaryAcademicRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.propertycodetype = propertyCodeType;
                newTR.dataset.row = row.toString();
                let container = document.querySelector(`.secondarySchoolAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
                container.append(newTR);
                this.bindDeleteButtons();
            }
            Core.hideLoader();
        });
    }
    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.showDeleteRow(deleteButton));
    }
    showDeleteRow(e) {
        const buttonElement = e;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }
    deleteScopeCancel() {
        const modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }
    deleteScopeConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const row = button.dataset.row;
            const propertyCodeType = button.dataset.propertycodetype;
            const planProperties = [];
            const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
            for (const prop of allPropertiesInRow) {
                const element = prop;
                if (element.dataset.planpropertypk !== "0")
                    planProperties.push(element.dataset.planpropertypk);
            }
            const dataModel = {
                "PropertyCodeType": propertyCodeType,
                "Row": row,
                "PlanPropertyValues": planProperties
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            const response = yield fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings);
            if (response.ok) {
                const row = document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);
                const modal = new Modal("deleteScopeModal", null);
                modal.hide();
                row.remove();
                Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
            }
        });
    }
    alignTechnical() {
        const lengthElement = document.getElementById("catsSystemSecondaryForm");
        const lengthValue = lengthElement.dataset.length;
        let length = 0;
        if (lengthValue !== "")
            length = parseInt(lengthValue);
        const grade12s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade12");
        const grade11s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade11");
        const grade10s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade10");
        const grade9s = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade9");
        const grade12Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade12Hours");
        const grade11Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade11Hours");
        const grade10Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade10Hours");
        const grade9Hours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryGrade9Hours");
        const catsSystemScopeAndSequenceSecondaryTotalHours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryTotalHours");
        if (length == 1) {
            //grade 12 only
            //total hours: 720
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12);
                grade12.readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour);
                grade12Hour.readOnly = false;
            }
            for (const grade11 of grade11s) {
                this._core.forceElementOptional(grade11);
                grade11.readOnly = true;
                grade11.value = "";
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementOptional(grade11Hour);
                grade11Hour.readOnly = true;
                grade11Hour.value = "";
            }
            for (const grade10 of grade10s) {
                this._core.forceElementOptional(grade10);
                grade10.readOnly = true;
                grade10.value = "";
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementOptional(grade10Hour);
                grade10Hour.readOnly = true;
                grade10Hour.value = "";
            }
            for (const grade9 of grade9s) {
                this._core.forceElementOptional(grade9);
                grade9.readOnly = true;
                grade9.value = "";
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementOptional(grade9Hour);
                grade9Hour.readOnly = true;
                grade9Hour.value = "";
                ;
            }
            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                totalHoursElement.dataset.totalhours = "720";
        }
        else if (length == 2) {
            //grade 12,11
            //total hours: 720
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12);
                grade12.readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour);
                grade12Hour.readOnly = false;
            }
            for (const grade11 of grade11s) {
                this._core.forceElementRequired(grade11);
                grade11.readOnly = false;
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementRequired(grade11Hour);
                grade11Hour.readOnly = false;
            }
            for (const grade10 of grade10s) {
                this._core.forceElementOptional(grade10);
                grade10.readOnly = true;
                grade10.value = "";
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementOptional(grade10Hour);
                grade10Hour.readOnly = true;
                grade10Hour.value = "";
            }
            for (const grade9 of grade9s) {
                this._core.forceElementOptional(grade9);
                grade9.readOnly = true;
                grade9.value = "";
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementOptional(grade9Hour);
                grade9Hour.readOnly = true;
                grade9Hour.value = "";
            }
            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                totalHoursElement.dataset.totalhours = "720";
        }
        else if (length == 3) {
            //grade 12,11,10
            //total hours: 1080
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12);
                grade12.readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour);
                grade12Hour.readOnly = false;
            }
            for (const grade11 of grade11s) {
                this._core.forceElementRequired(grade11);
                grade11.readOnly = false;
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementRequired(grade11Hour);
                grade11Hour.readOnly = false;
            }
            for (const grade10 of grade10s) {
                this._core.forceElementRequired(grade10);
                grade10.readOnly = false;
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementRequired(grade10Hour);
                grade10Hour.readOnly = false;
            }
            for (const grade9 of grade9s) {
                this._core.forceElementOptional(grade9);
                grade9.readOnly = true;
                grade9.value = "";
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementOptional(grade9Hour);
                grade9Hour.readOnly = true;
                grade9Hour.value = "";
            }
            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                totalHoursElement.dataset.totalhours = "1080";
        }
        else if (length == 4) {
            //grade 12,11,10,9
            //total hours: 1320
            for (const grade12 of grade12s) {
                this._core.forceElementRequired(grade12);
                grade12.readOnly = false;
            }
            for (const grade12Hour of grade12Hours) {
                this._core.forceElementRequired(grade12Hour);
                grade12Hour.readOnly = false;
            }
            for (const grade11 of grade11s) {
                this._core.forceElementRequired(grade11);
                grade11.readOnly = false;
            }
            for (const grade11Hour of grade11Hours) {
                this._core.forceElementRequired(grade11Hour);
                grade11Hour.readOnly = false;
            }
            for (const grade10 of grade10s) {
                this._core.forceElementRequired(grade10);
                grade10.readOnly = false;
            }
            for (const grade10Hour of grade10Hours) {
                this._core.forceElementRequired(grade10Hour);
                grade10Hour.readOnly = false;
            }
            for (const grade9 of grade9s) {
                this._core.forceElementRequired(grade9);
                grade9.readOnly = false;
            }
            for (const grade9Hour of grade9Hours) {
                this._core.forceElementRequired(grade9Hour);
                grade9Hour.readOnly = false;
            }
            for (const totalHoursElement of catsSystemScopeAndSequenceSecondaryTotalHours)
                totalHoursElement.dataset.totalhours = "1320";
        }
    }
    sumHours() {
        const catsSystemScopeAndSequenceSecondaryTotalHours = document.querySelectorAll(".catsSystemScopeAndSequenceSecondaryTotalHours");
        for (const totalHours of catsSystemScopeAndSequenceSecondaryTotalHours) {
            let currentTotal = 0;
            const totalHoursElement = totalHours;
            const row = totalHoursElement.dataset.row;
            const catsSystemScopeAndSequenceSecondaryHoursElements = document.querySelectorAll(`.catsSystemScopeAndSequenceSecondaryHours`);
            for (const catsSystemScopeAndSequenceSecondaryHours of catsSystemScopeAndSequenceSecondaryHoursElements) {
                if (catsSystemScopeAndSequenceSecondaryHours.value !== "") {
                    const thisNumber = parseInt(catsSystemScopeAndSequenceSecondaryHours.value);
                    if (!isNaN(thisNumber))
                        currentTotal += thisNumber;
                }
            }
            totalHoursElement.value = currentTotal.toString();
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = otherElement;
                    if (otherHtmlElement.classList.contains("missing-field")) {
                        alreadyExists = true;
                    }
                    else {
                        if (otherHtmlElement instanceof HTMLInputElement) {
                            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                                //Check to see if a prior group of radio buttons has already been validated
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.hasAttribute("name")) {
                                    let radioGroupName = OtherInputElement.getAttribute("name");
                                    let radios = document.getElementsByName(radioGroupName);
                                    let radioIsChecked = false;
                                    let isAlreadyValidated = false;
                                    for (var i = 0, length = radios.length; i < length; i++) {
                                        let radioElement = radios[i];
                                        if (radioElement.checked) {
                                            radioIsChecked = true;
                                        }
                                        if (radioElement.classList.contains("missing-field")) {
                                            isAlreadyValidated = true;
                                        }
                                    }
                                    if (isAlreadyValidated || radioIsChecked) {
                                        alreadyExists = true;
                                    }
                                }
                            }
                            else {
                                let OtherInputElement = otherHtmlElement;
                                if (OtherInputElement.value !== "") {
                                    alreadyExists = true;
                                }
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLSelectElement) {
                            let otherSelectElement = otherHtmlElement;
                            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                            let otherTextAreaElement = otherHtmlElement;
                            if (otherTextAreaElement.value !== "") {
                                alreadyExists = true;
                            }
                        }
                        else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                            //See if any options have been checked in multiselect
                            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input");
                            for (let selectBox of multiselectCheckboxes) {
                                if (selectBox.checked) {
                                    alreadyExists = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = element;
                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");
                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated = false;
                                        let isChecked = false;
                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }
                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }
                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                }
                                else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                }
                                else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLSelectElement) {
                                let selectElement = element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                            else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input");
                                let hasSelection = false;
                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }
                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }
        const allTotalHoursFields = document.getElementsByClassName("catsSystemScopeAndSequenceSecondaryTotalHours");
        for (const totalHoursField of allTotalHoursFields) {
            const element = totalHoursField;
            const requiredTotalHours = parseInt(element.dataset.totalhours);
            const currentHours = parseInt(element.value);
            let valid = false;
            if (!isNaN(requiredTotalHours) && !isNaN(currentHours)) {
                if (requiredTotalHours <= currentHours)
                    valid = true;
            }
            if (!valid) {
                element.classList.add("missing-field");
                element.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(element);
                showMessage = true;
                totalErrors++;
                const errorMessage = document.querySelector(`.minimumHoursError[data-row='${element.dataset.row}']`);
                const hoursNumber = document.querySelector(`.minimumHours[data-row='${element.dataset.row}']`);
                hoursNumber.textContent = element.dataset.totalhours;
                errorMessage.classList.remove("hide");
                const minimiumHoursErrorPlaceholder = document.querySelector(`.minimiumHoursErrorPlaceholder[data-row='${element.dataset.row}']`);
                minimiumHoursErrorPlaceholder.classList.remove("hide");
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// CatsSystemPostSecondary
class CatsSystemPostSecondary {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemPostSecondaryField"];
        let catsSystemPostSecondarySaveButton = document.getElementById("catsSystemPostSecondarySave");
        if (catsSystemPostSecondarySaveButton !== null)
            catsSystemPostSecondarySaveButton.addEventListener("click", (e) => this.save("save"));
        const allRequiredCheckboxes = document.getElementsByClassName("catsSystemPostSecondaryRequiredField");
        for (const required of allRequiredCheckboxes) {
            required.addEventListener("change", (e) => this.checkRequired(e.target));
            this.checkRequired(required);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addPostSecondarySchoolTechnicalRowButton = document.querySelector(`.addPostSecondarySchoolTechnicalRow`);
        if (addPostSecondarySchoolTechnicalRowButton !== null)
            addPostSecondarySchoolTechnicalRowButton.addEventListener("click", (e) => this.addPostSecondarySchoolTechnicalRow());
        const addPostSecondarySchoolAcademicRows = document.getElementsByClassName("addPostSecondarySchoolAcademicRow");
        for (const addPostSecondarySchoolAcademicRow of addPostSecondarySchoolAcademicRows)
            addPostSecondarySchoolAcademicRow.addEventListener("click", (e) => this.addPostSecondarySchoolAcademicRow(e));
        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e) => this.deleteScopeCancel());
        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e) => this.deleteScopeConfirm(e));
        this.bindDeleteButtons();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemPostSecondaryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemPostSecondaryForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let articulationAgreementPK = parseInt(formElement.dataset.articulationagreementfk);
        let textInputs = document.getElementsByClassName("catsSystemPostSecondaryField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("catsSystemPostSecondaryCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
                if (!element.checked)
                    textValue = "off";
            }
            if (element.checked)
                textValue = "on";
            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };
                allSaveElements.push(saveItem);
            }
        }
        var allData = {
            "ArticulationAgreementPK": articulationAgreementPK,
            "ElementData": allSaveElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemPostSecondary', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    addPostSecondarySchoolTechnicalRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemPostSecondaryForm");
            const articulationAgreementPK = form.dataset.articulationagreementfk;
            const propertyCodeType = "technical";
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(".postSecondaryTechnicalRow");
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddPostSecondarySchoolTechnicalCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("postSecondaryTechnicalRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.propertycodetype = propertyCodeType;
                newTR.dataset.row = row.toString();
                let container = document.querySelector("#postSecondarySchoolTechnicalCourses tbody");
                container.append(newTR);
                this.bindDeleteButtons();
            }
            Core.hideLoader();
        });
    }
    addPostSecondarySchoolAcademicRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const propertyCodeType = buttonElement.dataset.propertycodetype;
            const form = document.getElementById("catsSystemPostSecondaryForm");
            const articulationAgreementPK = form.dataset.articulationagreementfk;
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(`.postSecondaryAcademicRow[data-propertycodetype='${propertyCodeType}']`);
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddPostSecondarySchoolAcademicCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("postSecondaryAcademicRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.propertycodetype = propertyCodeType;
                newTR.dataset.row = row.toString();
                let container = document.querySelector(`.postSecondarySchoolAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
                container.append(newTR);
                this.bindDeleteButtons();
            }
            Core.hideLoader();
        });
    }
    checkRequired(checkbox) {
        const codeType = checkbox.dataset.codetype;
        const allTextFields = document.querySelectorAll(`.catsSystemPostSecondaryField[data-propertycodetype='${codeType}']`);
        for (const text of allTextFields) {
            const ele = text;
            if (checkbox.checked)
                this._core.forceElementRequired(ele);
            else
                this._core.forceElementOptional(ele);
        }
        const requiredElement = document.querySelector(`.postSecondaryAcademicRequired[data-propertycodetype='${codeType}']`);
        const button = document.querySelector(`.addRowButton[data-propertycodetype='${codeType}']`);
        if (checkbox.checked) {
            requiredElement.classList.remove("hide");
            button.disabled = false;
        }
        else {
            requiredElement.classList.add("hide");
            button.disabled = true;
        }
    }
    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.showDeleteRow(e));
    }
    showDeleteRow(e) {
        const buttonElement = e.target;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }
    deleteScopeCancel() {
        const modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }
    deleteScopeConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const row = button.dataset.row;
            const propertyCodeType = button.dataset.propertycodetype;
            const planProperties = [];
            const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
            for (const prop of allPropertiesInRow) {
                const element = prop;
                if (element.dataset.planpropertypk !== "0")
                    planProperties.push(element.dataset.planpropertypk);
            }
            const dataModel = {
                "PropertyCodeType": propertyCodeType,
                "Row": row,
                "PlanPropertyValues": planProperties
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            const response = yield fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings);
            if (response.ok) {
                const row = document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);
                const modal = new Modal("deleteScopeModal", null);
                modal.hide();
                row.remove();
                Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
            }
        });
    }
}
// CatsSystemDualEnrollment
class CatsSystemDualEnrollment {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemDualEnrollmentField", "catsSystemDualEnrollmentRadioField", "catsSystemDualEnrollmentCheckboxField"];
        let catsSystemDualEnrollmentSaveButton = document.getElementById("catsSystemDualEnrollmentSave");
        if (catsSystemDualEnrollmentSaveButton !== null)
            catsSystemDualEnrollmentSaveButton.addEventListener("click", (e) => this.save("save"));
        const yesNoRadios = document.getElementsByClassName("catsSystemDualEnrollmentRadioField");
        for (const yesNoRadio of yesNoRadios) {
            yesNoRadio.addEventListener("change", (e) => this.checkForYesNo(e.target));
            this.checkForYesNo(yesNoRadio);
        }
        const allRequiredCheckboxes = document.getElementsByClassName("catsSystemDualEnrollmentRequiredField");
        for (const notRequired of allRequiredCheckboxes) {
            notRequired.addEventListener("change", (e) => this.checkRequired(e.target));
            this.checkRequired(notRequired);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addDualEnrollmentSchoolTechnicalRowButton = document.querySelector(`.addDualEnrollmentTechnicalRow`);
        if (addDualEnrollmentSchoolTechnicalRowButton !== null)
            addDualEnrollmentSchoolTechnicalRowButton.addEventListener("click", (e) => this.addDualEnrollmentSchoolTechnicalRow());
        const addDualEnrollmentSchoolAcademicRows = document.getElementsByClassName("addDualEnrollmentAcademicRow");
        for (const addDualEnrollmentSchoolAcademicRow of addDualEnrollmentSchoolAcademicRows)
            addDualEnrollmentSchoolAcademicRow.addEventListener("click", (e) => this.addDualEnrollmentSchoolAcademicRow(e));
        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e) => this.deleteScopeCancel());
        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e) => this.deleteScopeConfirm(e));
        this.bindDeleteButtons();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemDualEnrollmentForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemDualEnrollmentForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const articulationAgreementPK = parseInt(formElement.dataset.articulationagreementfk);
        const textInputs = document.getElementsByClassName("catsSystemDualEnrollmentField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("catsSystemDualEnrollmentCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let textValue = "off";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
                if (!element.checked)
                    textValue = "off";
            }
            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
            if (element.checked)
                textValue = "on";
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("catsSystemDualEnrollmentRadioField");
        for (const ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                let catsArticulationAgreementPlanPropertyFK = 0;
                if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                    catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0,
                        CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        var allData = {
            "ElementData": allSaveElements,
            "ArticulationAgreementPK": articulationAgreementPK,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemDualEnrollment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    if (xhr.responseText === "true") {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "Data has been successfully saved. Please wait.", 'success', 3000, null);
                        core.pageReload(true, planPK);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue saving your data. Please try again.", 'error', 3000, null);
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    addDualEnrollmentSchoolTechnicalRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemDualEnrollmentForm");
            const propertyCodeType = "technical";
            const articulationAgreementPK = form.dataset.articulationagreementfk;
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(".dualEnrollmentTechnicalRow");
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddDualEnrollmentTechnicalCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("dualEnrollmentTechnicalRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.row = row.toString();
                newTR.dataset.propertycodetype = propertyCodeType;
                let container = document.querySelector("#dualEnrollmentTechnicalCourses tbody");
                container.append(newTR);
                this.bindDeleteButtons();
            }
            Core.hideLoader();
        });
    }
    addDualEnrollmentSchoolAcademicRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const propertyCodeType = buttonElement.dataset.propertycodetype;
            const form = document.getElementById("catsSystemDualEnrollmentForm");
            const articulationAgreementPK = form.dataset.articulationagreementfk;
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(`.dualEnrollmentAcademicRow[data-propertycodetype='${propertyCodeType}']`);
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddDualEnrollmentAcademicCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("dualEnrollmentAcademicRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.propertycodetype = propertyCodeType;
                newTR.dataset.row = row.toString();
                let container = document.querySelector(`.dualEnrollmentAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
                container.append(newTR);
                this.bindDeleteButtons();
            }
            Core.hideLoader();
        });
    }
    checkRequired(checkbox) {
        const codeType = checkbox.dataset.codetype;
        const allTextFields = document.querySelectorAll(`.catsSystemDualEnrollmentField.required[data-propertycodetype='${codeType}']`);
        for (const text of allTextFields) {
            const ele = text;
            if (checkbox.checked)
                this._core.forceElementRequired(ele);
            else
                this._core.forceElementOptional(ele);
        }
        const requiredElement = document.querySelector(`.dualEnrollmentAcademicRequired[data-propertycodetype='${codeType}']`);
        const button = document.querySelector(`.addRowButton[data-propertycodetype='${codeType}']`);
        if (checkbox.checked) {
            requiredElement.classList.remove("hide");
            button.disabled = false;
        }
        else {
            requiredElement.classList.add("hide");
            button.disabled = true;
        }
    }
    checkForYesNo(radio) {
        let allRequired = false;
        let go = false;
        if (radio.checked) {
            if (radio.dataset.lookuplabel === "Yes") {
                allRequired = true;
                go = true;
            }
            else {
                allRequired = false;
                go = true;
            }
        }
        if (go) {
            const allTextFields = document.querySelectorAll(`.catsSystemDualEnrollmentField.required`);
            for (const text of allTextFields) {
                const ele = text;
                const propertyCodeType = ele.dataset.propertycodetype;
                const checkEle = document.querySelector(`.catsSystemDualEnrollmentRequiredField[data-codetype='${propertyCodeType}']`);
                if (allRequired) {
                    if (checkEle && checkEle.checked)
                        this._core.forceElementRequired(ele);
                    else
                        this._core.forceElementOptional(ele);
                    checkEle.disabled = false;
                }
                else {
                    this._core.forceElementOptional(ele);
                    if (checkEle.checked)
                        checkEle.click();
                    checkEle.disabled = true;
                }
            }
        }
    }
    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.showDeleteRow(deleteButton));
    }
    showDeleteRow(e) {
        const buttonElement = e;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }
    deleteScopeCancel() {
        const modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }
    deleteScopeConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const row = button.dataset.row;
            const propertyCodeType = button.dataset.propertycodetype;
            const planProperties = [];
            const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
            for (const prop of allPropertiesInRow) {
                const element = prop;
                if (element.dataset.planpropertypk !== "0")
                    planProperties.push(element.dataset.planpropertypk);
            }
            const dataModel = {
                "PropertyCodeType": propertyCodeType,
                "Row": row,
                "PlanPropertyValues": planProperties
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            const response = yield fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings);
            if (response.ok) {
                const row = document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);
                const modal = new Modal("deleteScopeModal", null);
                modal.hide();
                row.remove();
                Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
            }
        });
    }
}
// CatsSystemCredits
class CatsSystemCredits {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemCreditsField", "catsSystemCreditsFileField", "catsSystemCreditsCheckboxField", "catsSystemCreditsRadioField"];
        let catsSystemCreditsSaveButton = document.getElementById("catsSystemCreditsSave");
        if (catsSystemCreditsSaveButton !== null)
            catsSystemCreditsSaveButton.addEventListener("click", (e) => this.save("save"));
        const allNotRequiredCheckboxes = document.getElementsByClassName("catsSystemCreditsCheckboxField");
        for (const notRequired of allNotRequiredCheckboxes) {
            notRequired.addEventListener("change", (e) => this.checkRequired(e.target));
            this.checkRequired(notRequired);
        }
        const yesNoRadios = document.getElementsByClassName("catsSystemCreditsRadioField");
        for (const yesNoRadio of yesNoRadios) {
            yesNoRadio.addEventListener("change", (e) => this.checkForYesNo(e.target));
            this.checkForYesNo(yesNoRadio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addCreditsSchoolTechnicalRowButton = document.querySelector(`.addCreditsTechnicalRow`);
        if (addCreditsSchoolTechnicalRowButton !== null)
            addCreditsSchoolTechnicalRowButton.addEventListener("click", (e) => this.addCreditsSchoolTechnicalRow());
        const addCreditsSchoolAcademicRows = document.getElementsByClassName("addCreditsAcademicRow");
        for (const addCreditsSchoolAcademicRow of addCreditsSchoolAcademicRows)
            addCreditsSchoolAcademicRow.addEventListener("click", (e) => this.addCreditsSchoolAcademicRow(e));
        const fileUploader = document.getElementById("catsSystemCreditsArticulatedCreditsUploadArticulationAgreement");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e) => this.uploadFile(e.target));
        const catsSystemScopeDeleteCancelButton = document.getElementById("catsSystemScopeDeleteCancel");
        if (catsSystemScopeDeleteCancelButton !== null)
            catsSystemScopeDeleteCancelButton.addEventListener("click", (e) => this.deleteScopeCancel());
        const catsSystemScopeDeleteConfirmButton = document.getElementById("catsSystemScopeDeleteConfirm");
        if (catsSystemScopeDeleteConfirmButton !== null)
            catsSystemScopeDeleteConfirmButton.addEventListener("click", (e) => this.deleteScopeConfirm(e));
        this.bindDeleteButtons();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemCreditsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCreditsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        const articulationAgreementPK = parseInt(formElement.dataset.articulationagreementfk);
        let textInputs = document.getElementsByClassName("catsSystemCreditsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("catsSystemCreditsCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            let textValue = "";
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
                if (!element.checked)
                    textValue = "off";
            }
            if (element.checked)
                textValue = "on";
            let catsArticulationAgreementPlanPropertyFK = 0;
            if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: textValue,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("catsSystemCreditsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                let catsArticulationAgreementPlanPropertyFK = 0;
                if (element.dataset.catsarticulationagreementplanpropertyfk && element.dataset.catsarticulationagreementplanpropertyfk !== "")
                    catsArticulationAgreementPlanPropertyFK = parseInt(element.dataset.catsarticulationagreementplanpropertyfk);
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0,
                        CATSArticulationAgreementPlanPropertyFK: catsArticulationAgreementPlanPropertyFK
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        var allData = {
            "ElementData": allSaveElements,
            "ArticulationAgreementPK": articulationAgreementPK,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemCredits', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    addCreditsSchoolTechnicalRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemCreditsForm");
            const articulationAgreementPK = form.dataset.articulationagreementfk;
            const propertyCodeType = "technical";
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(".articulatedCreditsTechnicalRow");
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddArticulatedCreditsTechnicalCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("articulatedCreditsTechnicalRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.row = row.toString();
                newTR.dataset.propertycodetype = propertyCodeType;
                let container = document.querySelector("#articulatedCreditsTechnicalCourses tbody");
                container.append(newTR);
                this.bindDeleteButtons();
            }
            Core.hideLoader();
        });
    }
    addCreditsSchoolAcademicRow(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const propertyCodeType = buttonElement.dataset.propertycodetype;
            const form = document.getElementById("catsSystemCreditsForm");
            const articulationAgreementPK = form.dataset.articulationagreementfk;
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(`.articulatedCreditsAcademicRow[data-propertycodetype='${propertyCodeType}']`);
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddArticulatedCreditsAcademicCoursesRow/${articulationAgreementPK}/${planPK}/${programPK}/${row}/${propertyCodeType}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("articulatedCreditsAcademicRow");
                newTR.classList.add("scopeAndSequenceRow");
                newTR.dataset.propertycodetype = propertyCodeType;
                newTR.dataset.row = row.toString();
                let container = document.querySelector(`.articulatedCreditsAcademicCourses[data-propertycodetype='${propertyCodeType}'] tbody`);
                container.append(newTR);
                this.bindDeleteButtons();
            }
            Core.hideLoader();
        });
    }
    uploadFile(e) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;
        let row = e.dataset.row;
        const that = this;
        let uploadSampleForm = document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        let propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        let formFile = document.querySelector(`.catsSystemCreditsFileField[data-formname='${formName}']`);
                        formFile.value = "";
                        let fileList = document.querySelector(`.uploadFileList`);
                        let currentFileList = fileList.querySelectorAll(`.uploadFileList`);
                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = file;
                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }
                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        sequence++;
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("catsSystemCreditsFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    checkRequired(checkbox) {
        const codeType = checkbox.dataset.codetype;
        if (codeType !== "technical") {
            const allTextFields = document.querySelectorAll(`.catsSystemCreditsField[data-propertycodetype='${codeType}']`);
            for (const text of allTextFields) {
                const ele = text;
                if (checkbox.checked)
                    this._core.forceElementRequired(ele);
                else
                    this._core.forceElementOptional(ele);
            }
            const requiredElement = document.querySelector(`.articulatedCreditsAcademicRequired[data-propertycodetype='${codeType}']`);
            const button = document.querySelector(`.addRowButton[data-propertycodetype='${codeType}']`);
            if (checkbox.checked) {
                requiredElement.classList.remove("hide");
                button.disabled = false;
            }
            else {
                requiredElement.classList.add("hide");
                button.disabled = true;
            }
        }
    }
    checkForYesNo(radio) {
        let allRequired = false;
        let go = false;
        if (radio.checked) {
            if (radio.dataset.lookuplabel === "Yes") {
                allRequired = true;
                go = true;
            }
            else {
                allRequired = false;
                go = true;
            }
        }
        if (go) {
            const allTextFields = document.querySelectorAll(`.catsSystemCreditsField`);
            for (const text of allTextFields) {
                const ele = text;
                const propertyCodeType = ele.dataset.propertycodetype;
                const checkEle = document.querySelector(`.catsSystemCreditsRequiredField[data-codetype='${propertyCodeType}']`);
                if (allRequired) {
                    if (propertyCodeType !== "technical") {
                        if (checkEle && checkEle.checked)
                            this._core.forceElementRequired(ele);
                        else
                            this._core.forceElementOptional(ele);
                    }
                    else {
                        this._core.forceElementRequired(ele);
                    }
                    if (checkEle)
                        checkEle.disabled = false;
                }
                else {
                    this._core.forceElementOptional(ele);
                    if (checkEle) {
                        if (checkEle.checked)
                            checkEle.click();
                        checkEle.disabled = true;
                    }
                }
            }
        }
    }
    bindDeleteButtons() {
        const deleteButtons = document.getElementsByClassName("deleteScope");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e) => this.showDeleteRow(deleteButton));
    }
    showDeleteRow(e) {
        const buttonElement = e;
        const row = buttonElement.dataset.row;
        const propertyCodeType = buttonElement.dataset.propertycodetype;
        const modal = new Modal("deleteScopeModal", null);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "propertycodetype", propertyCodeType);
        modal.addAttributeToElement("deleteScopeModal", "#catsSystemScopeDeleteConfirm", "row", row);
        modal.show();
    }
    deleteScopeCancel() {
        const modal = new Modal("deleteScopeModal", null);
        modal.hide();
    }
    deleteScopeConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const row = button.dataset.row;
            const propertyCodeType = button.dataset.propertycodetype;
            const planProperties = [];
            const allPropertiesInRow = document.querySelectorAll(`.catsSystemScopeAndSequenceField[data-row='${row}'][data-propertycodetype='${propertyCodeType}']`);
            for (const prop of allPropertiesInRow) {
                const element = prop;
                if (element.dataset.planpropertypk !== "0")
                    planProperties.push(element.dataset.planpropertypk);
            }
            const dataModel = {
                "PropertyCodeType": propertyCodeType,
                "Row": row,
                "PlanPropertyValues": planProperties
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            const response = yield fetch(`/CATSSystem/DeleteScopeAndSequenceRow`, settings);
            if (response.ok) {
                const row = document.querySelector(`.scopeAndSequenceRow[data-row='${dataModel.Row}'][data-propertycodetype='${dataModel.PropertyCodeType}']`);
                const modal = new Modal("deleteScopeModal", null);
                modal.hide();
                row.remove();
                Core.createHTMLAlert("alertMessageDiv", "The row was successfully deleted.", 'success', 3000, null);
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting this row. Please try again.", 'error', 3000, null);
            }
        });
    }
}
// CatsSystemStudentIndustry
class CatsSystemStudentIndustry {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemStudentIndustryField", "catsSystemStudentIndustryCheckboxField"];
        let catsSystemStudentIndustrySaveButton = document.getElementById("catsSystemStudentIndustrySave");
        if (catsSystemStudentIndustrySaveButton !== null)
            catsSystemStudentIndustrySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemStudentIndustryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allCredentialElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("catsSystemStudentIndustryForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const programPK = parseInt(formElement.dataset.programfk);
        const checkboxes = document.getElementsByClassName("catsSystemStudentIndustryCheckboxField");
        for (let ele of checkboxes) {
            let element = ele;
            let hadValue = false;
            let catsStudentIndustryCredentialPK = 0;
            if (element.dataset.catsstudentindustrycredentialpk !== "" && element.dataset.catsstudentindustrycredentialpk !== "0") {
                catsStudentIndustryCredentialPK = parseInt(element.dataset.catsstudentindustrycredentialpk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let isSelected = false;
                if (element.checked)
                    isSelected = true;
                const catsTeacherFK = parseInt(element.dataset.catsteacherfk);
                const credentialFK = parseInt(element.dataset.industrycertificationcodepk);
                const saveItem = {
                    CATSStudentIndustryCredentialPK: catsStudentIndustryCredentialPK,
                    PlanFK: planPK,
                    CATSProgramFK: programPK,
                    CATSIndustryCertificationCodeFK: credentialFK,
                    IsSelectedInd: isSelected
                };
                allCredentialElements.push(saveItem);
            }
        }
        const noCredentialsElement = document.querySelector(".catsSystemStudentIndustryNoCredentialsCheckboxField");
        let noCredentials = {
            "NoCredentials": null,
            "CATSStudentIndustryCredentialPK": null,
            "ProgramFK": programPK
        };
        if (noCredentialsElement !== null) {
            const catsStudentIndustryCredentialPK = noCredentialsElement.dataset.catsstudentindustrycredentialpk;
            noCredentials.NoCredentials = noCredentialsElement.checked;
            noCredentials.CATSStudentIndustryCredentialPK = catsStudentIndustryCredentialPK;
        }
        const allData = {
            "StudentCredentials": allCredentialElements,
            "NoCredentials": noCredentials
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemStudentIndustry', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    core.pageReload(true, planPK);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                    Core.hideLoader();
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allData));
    }
}
// CatsSystemTeacherIndustry
class CatsSystemTeacherIndustry {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["workEmail"];
        let catsSystemTeacherIndustrySaveButton = document.getElementById("catsSystemTeacherIndustrySave");
        if (catsSystemTeacherIndustrySaveButton !== null)
            catsSystemTeacherIndustrySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        const employmentStatuses = document.getElementsByClassName("catsSystemTeacherIndustryEmploymentStatus");
        for (const employmentStatus of employmentStatuses) {
            employmentStatus.addEventListener("change", (e) => this.changeEmploymentStatus(e));
        }
        const catsSystemTeacherIndustryTeacherAddTeacherButton = document.getElementById("catsSystemTeacherIndustryTeacherAddTeacher");
        if (catsSystemTeacherIndustryTeacherAddTeacherButton !== null)
            catsSystemTeacherIndustryTeacherAddTeacherButton.addEventListener("click", (e) => this.addTeacher());
        this.bindTeacherCredentials();
        const catsSystemTeacherIndustryTIMSReminderModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryTIMSClose");
        if (catsSystemTeacherIndustryTIMSReminderModalCloseButton !== null)
            catsSystemTeacherIndustryTIMSReminderModalCloseButton.addEventListener("click", (e) => this.closeTIMSReminderModal());
        const catsSystemTeacherIndustryTeacherExistsModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryExistsClose");
        if (catsSystemTeacherIndustryTeacherExistsModalCloseButton !== null)
            catsSystemTeacherIndustryTeacherExistsModalCloseButton.addEventListener("click", (e) => this.closeTeacherExistsModal());
        const catsSystemTeacherIndustryTeacherNotInTIMSModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryNotInTIMSClose");
        if (catsSystemTeacherIndustryTeacherNotInTIMSModalCloseButton !== null)
            catsSystemTeacherIndustryTeacherNotInTIMSModalCloseButton.addEventListener("click", (e) => this.closeTeacherNotInTIMSModal());
        const catsSystemTeacherIndustryTeacherNotValidInCIPModalCloseButton = document.getElementById("catsSystemCATSTeacherIndustryNotValidInCIPClose");
        if (catsSystemTeacherIndustryTeacherNotValidInCIPModalCloseButton !== null)
            catsSystemTeacherIndustryTeacherNotValidInCIPModalCloseButton.addEventListener("click", (e) => this.closeTeacherNotValidInCIPModal());
        const catsSystemTeacherIndustryCredentialsTeacherDeleteConfirmButton = document.getElementById("catsSystemTeacherIndustryCredentialsDeleteConfirm");
        if (catsSystemTeacherIndustryCredentialsTeacherDeleteConfirmButton !== null)
            catsSystemTeacherIndustryCredentialsTeacherDeleteConfirmButton.addEventListener("click", (e) => this.deleteTeacherConfirm(e));
        const catsSystemTeacherIndustryCredentialsTeacherDeleteCancelButton = document.getElementById("catsSystemTeacherIndustryCredentialsDeleteCancel");
        if (catsSystemTeacherIndustryCredentialsTeacherDeleteCancelButton !== null)
            catsSystemTeacherIndustryCredentialsTeacherDeleteCancelButton.addEventListener("click", (e) => this.deleteTeacherCancel());
        this.bindDeleteTeachers();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemTeacherIndustryForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allCredentialElements = [];
        let allTeacherElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLongLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("catsSystemTeacherIndustryForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const programPK = parseInt(formElement.dataset.programpk);
        let showNotice = false;
        const teacherRows = document.getElementsByClassName("teacherRow");
        for (const teacherRow of teacherRows) {
            const ppidFKElement = teacherRow.querySelector("td[data-type='PPIDFK']");
            const ppidFK = parseInt(ppidFKElement.dataset.value);
            const teacherPK = parseInt(ppidFKElement.dataset.catsteacherfk);
            const emailElement = teacherRow.querySelector(".workEmail");
            const emailAddress = emailElement.value;
            const oldEmailAddress = emailElement.dataset.existingemail;
            if (oldEmailAddress !== "" && oldEmailAddress !== emailAddress)
                showNotice = true;
            const noCredentials = document.querySelector(`.allIndustryCredentialsNoCredentials[data-catsteacherfk='${teacherPK}']`);
            const teacherItem = {
                EmailAddress: emailAddress,
                CATSTeacherFK: teacherPK
            };
            if (ppidFK && ppidFK > 0)
                teacherItem.TIMSPPIDHolderFK = ppidFK;
            if (noCredentials) {
                teacherItem.NoCredentials = noCredentials.checked;
            }
            allTeacherElements.push(teacherItem);
        }
        const checkboxes = document.getElementsByClassName("catsSystemTeacherIndustryCheckboxField");
        for (let ele of checkboxes) {
            let element = ele;
            let hadValue = false;
            let catsTeacherIndustryCredentialPK = 0;
            if (element.dataset.catsteacherindustrycredentialpk !== "" && element.dataset.catsteacherindustrycredentialpk !== "0") {
                catsTeacherIndustryCredentialPK = parseInt(element.dataset.catsteacherindustrycredentialpk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let isSelected = false;
                if (element.checked)
                    isSelected = true;
                const catsTeacherFK = parseInt(element.dataset.catsteacherfk);
                const credentialFK = parseInt(element.dataset.industrycertificationcodepk);
                const saveItem = {
                    CATSTeacherIndustryCredentialPK: catsTeacherIndustryCredentialPK,
                    PlanFK: planPK,
                    CATSProgramFK: programPK,
                    CATSTeacherFK: catsTeacherFK,
                    CATSIndustryCertificationCodeFK: credentialFK,
                    IsSelectedInd: isSelected
                };
                allCredentialElements.push(saveItem);
            }
        }
        var saveData = {
            "TeacherData": allTeacherElements,
            "Credentials": allCredentialElements
        };
        if (showNotice) {
            let modal = new Modal("timsReminderModal", null);
            modal.show();
        }
        const that = this;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemTeacherIndustry', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    const whichOpenElement = document.getElementById("teacherIndustryCredentialsOpen");
                    if (whichOpenElement) {
                        const whichOpen = whichOpenElement.dataset.open;
                        const ppidFK = whichOpenElement.dataset.timsppidholderfk;
                        if (whichOpen === "TeacherIndustryCredentials") {
                            const buttonElement = document.querySelector(`.catsSystemTeacherIndustryTeacherAddTeacherCredentials[data-timsppidholderfk='${ppidFK}']`);
                            Core.hideLongLoader();
                            buttonElement.click();
                        }
                        else if (whichOpen === "TeacherData") {
                            const anchorElement = document.querySelector(`.teacherCredentials[data-timsppidholderfk='${ppidFK}']`);
                            Core.hideLongLoader();
                            anchorElement.click();
                        }
                    }
                    else {
                        Core.hideLongLoader();
                    }
                    that.customValidation();
                }
                else {
                    Core.hideLongLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else
                Core.hideLongLoader();
        };
        xhr.send(JSON.stringify(saveData));
    }
    changeEmploymentStatus(e) {
        const radioButton = e.target;
        const ppidElement = document.getElementById("catsSystemTeacherIndustryProfessionalPersonnelID");
        const nameElement = document.getElementById("catsSystemTeacherIndustryTeacherName");
        if (radioButton.checked) {
            const employmentStatus = radioButton.dataset.lookuplabel;
            if (employmentStatus.toLowerCase().indexOf("to be") >= 0) {
                ppidElement.disabled = true;
                nameElement.disabled = false;
                ppidElement.value = "";
                nameElement.value = "";
            }
            else {
                ppidElement.disabled = false;
                nameElement.disabled = true;
                ppidElement.value = "";
                nameElement.value = "";
            }
        }
    }
    addTeacher() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemTeacherIndustryForm");
            const programPK = form.dataset.programpk;
            const planFK = form.dataset.planfk;
            const teacherEmploymentElement = document.querySelector(".catsSystemTeacherIndustryEmploymentStatus:checked");
            if (teacherEmploymentElement !== null) {
                const ppidElement = document.getElementById("catsSystemTeacherIndustryProfessionalPersonnelID");
                const nameElement = document.getElementById("catsSystemTeacherIndustryTeacherName");
                const employmentStatusLookupCodeElement = document.querySelector(".catsSystemTeacherIndustryEmploymentStatus:checked");
                const employmentStatusLookupCodeFK = employmentStatusLookupCodeElement.value;
                let dataModel = {
                    "Name": "",
                    "PPID": "",
                    "ProgramPK": programPK,
                    "EmploymentStatusLookupCodeFK": employmentStatusLookupCodeFK,
                    "PlanFK": planFK
                };
                let newEmployee = false;
                let allowContinue = true;
                const employmentStatus = teacherEmploymentElement.dataset.lookuplabel;
                if (employmentStatus.toLowerCase().indexOf("to be") >= 0) {
                    if (nameElement && nameElement.value !== "") {
                        const name = nameElement.value;
                        dataModel.Name = name;
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "You must enter a name for a to be employed teacher.", "warning", 3000, null);
                        allowContinue = false;
                    }
                    newEmployee = true;
                }
                else {
                    if (ppidElement && ppidElement.value !== "") {
                        const ppid = ppidElement.value;
                        dataModel.PPID = ppid.trim();
                    }
                    else {
                        Core.createHTMLAlert("alertMessageDiv", "You must enter a PPID for an employed teacher.", "warning", 3000, null);
                        allowContinue = false;
                    }
                    newEmployee = false;
                }
                if (allowContinue) {
                    let teacherExists = false;
                    let notValidForCIP = false;
                    let notInTIMS = false;
                    const settings = {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(dataModel)
                    };
                    if (newEmployee === false) {
                        if (dataModel.PPID !== "") {
                            const teacherExistsResponse = yield fetch(`/CATSSystem/TeacherExists`, settings);
                            if (teacherExistsResponse) {
                                const output = yield teacherExistsResponse.json();
                                if (output.teacherExists === true)
                                    teacherExists = true;
                                if (output.notValidForCIP === true)
                                    notValidForCIP = true;
                                if (output.notInTIMS === true)
                                    notInTIMS = true;
                            }
                        }
                    }
                    if (notInTIMS === true) {
                        Core.hideLoader();
                        let modal = new Modal("notInTIMSModal", null);
                        modal.show();
                    }
                    else if (teacherExists === true) {
                        Core.hideLoader();
                        let modal = new Modal("teacherExistsModal", null);
                        modal.show();
                    }
                    else if (notValidForCIP === true) {
                        Core.hideLoader();
                        let modal = new Modal("notValidInCIPModal", null);
                        modal.show();
                    }
                    else {
                        const addTeacherResponse = yield fetch(`/CATSSystem/AddTeacher`, settings);
                        if (addTeacherResponse.ok) {
                            const addTeacherOutput = yield addTeacherResponse.text();
                            let addTeacherNewTR = document.createElement("tr");
                            addTeacherNewTR.classList.add("teacherRow");
                            addTeacherNewTR.innerHTML = addTeacherOutput;
                            const addTeacherContainer = document.querySelector("#teacherTable tbody");
                            addTeacherContainer.appendChild(addTeacherNewTR);
                            const addTeacherContainerElement = document.getElementById("teacherContainer");
                            addTeacherContainerElement.classList.remove("hide");
                            ppidElement.value = "";
                            ppidElement.disabled = true;
                            nameElement.value = "";
                            nameElement.disabled = true;
                            employmentStatusLookupCodeElement.checked = false;
                            Core.hideLoader();
                        }
                    }
                    this.bindTeacherCredentials();
                    this.bindDeleteTeachers();
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", "You must choose an employment status and fill out the appropriate field.", "warning", 3000, null);
            }
        });
    }
    bindTeacherCredentials() {
        const allTeachers = document.getElementsByClassName("teacherCredentials");
        for (const teacher of allTeachers) {
            teacher.addEventListener("click", (e) => this.showTeacherCredentials(e));
        }
        const allTeacherAddCredentials = document.getElementsByClassName("catsSystemTeacherIndustryTeacherAddTeacherCredentials");
        for (const cred of allTeacherAddCredentials)
            cred.addEventListener("click", (e) => this.showAddTeacherCredentials(e));
        const allUpdatePPIDs = document.getElementsByClassName("catsSystemTeacherIndustryTeacherUpdatePPID");
        for (const update of allUpdatePPIDs)
            update.addEventListener("click", (e) => this.updatePPIDForTeacher(e));
    }
    showTeacherCredentials(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const link = e.target;
            const timsPPIDHolderFK = link.dataset.timsppidholderfk;
            const programFK = link.dataset.programfk;
            Core.showLoader();
            const response = yield fetch(`/CATSSystem/GetCredentials/${timsPPIDHolderFK}/${programFK}`, { credentials: 'include' });
            if (response.ok) {
                let container = document.querySelector("#teacherData");
                container.innerHTML = "";
                const output = yield response.text();
                const newDiv = document.createElement("div");
                newDiv.innerHTML = output;
                container.append(newDiv);
            }
            const credsResponse = yield fetch(`/CATSSystem/ShowTeacherIndustryCredentials/${programFK}/${timsPPIDHolderFK}/false`, { credentials: 'include' });
            if (credsResponse.ok) {
                const output = yield credsResponse.text();
                const newDiv = document.createElement("div");
                newDiv.classList.add("margin-top-xl");
                newDiv.innerHTML = output;
                let container = document.querySelector("#teacherData");
                container.append(newDiv);
                Core.hideLoader();
            }
        });
    }
    showAddTeacherCredentials(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const timsPPIDHolderFK = button.dataset.timsppidholderfk;
            const programFK = button.dataset.programfk;
            Core.showLoader();
            const response = yield fetch(`/CATSSystem/ShowTeacherIndustryCredentials/${programFK}/${timsPPIDHolderFK}/true`, { credentials: 'include' });
            if (response.ok) {
                let container = document.querySelector("#teacherData");
                container.innerHTML = "";
                const output = yield response.text();
                const newDiv = document.createElement("div");
                newDiv.innerHTML = output;
                container.append(newDiv);
                Core.hideLoader();
            }
        });
    }
    closeTeacherExistsModal() {
        let modal = new Modal("teacherExistsModal", null);
        modal.hide();
    }
    closeTeacherNotValidInCIPModal() {
        let modal = new Modal("notValidInCIPModal", null);
        modal.hide();
    }
    closeTeacherNotInTIMSModal() {
        let modal = new Modal("notInTIMSModal", null);
        modal.hide();
    }
    closeTIMSReminderModal() {
        let modal = new Modal("timsReminderModal", null);
        modal.hide();
    }
    customValidation() {
        return __awaiter(this, void 0, void 0, function* () {
            let showMessage = false;
            const allEmailElements = document.querySelectorAll(`.workEmail[data-catsteacheremployement='catsTeacherIndustryCredentialsEmploymentEmployed']`);
            //reset the page so it's properly re-validated each time.
            for (const email of allEmailElements) {
                const emailElement = email;
                emailElement.classList.remove("missing-field");
                const attr = emailElement.attributes.getNamedItem("aria-invalid");
                if (attr)
                    emailElement.attributes.removeNamedItem("aria-invalid");
                Core.removeErrorLabelForInput(emailElement);
            }
            let totalErrors = 0;
            for (const email of allEmailElements) {
                const emailElement = email;
                if (emailElement.value === "") {
                    emailElement.classList.add("missing-field");
                    emailElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(emailElement);
                    showMessage = true;
                    totalErrors++;
                }
            }
            let additionalMessageNeeded = false;
            let additionalMessage = "";
            const customValidation = yield this.customPageValidation();
            if (customValidation > 0) {
                totalErrors += customValidation;
                showMessage = true;
                additionalMessage = "One or more teachers require credentials to be added. Click the \"Add Credentials\" button to view/add credentials.";
                additionalMessageNeeded = true;
            }
            let message = document.getElementById("validationMessage");
            let messageContainerColumn = document.getElementById("validationColumn");
            let messageContainer = document.getElementById("validationMessageContainer");
            messageContainer.classList.remove("warning");
            messageContainer.classList.remove("success");
            messageContainerColumn.classList.add("show");
            let validationIcon = document.getElementById("validationMessageIcon");
            if (totalErrors > 0) {
                if (totalErrors === 1) {
                    message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
                    if (additionalMessageNeeded)
                        message.innerHTML += `<p>${additionalMessage}</p>`;
                }
                else {
                    message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
                    if (additionalMessageNeeded)
                        message.innerHTML += `<p>${additionalMessage}</p>`;
                }
                let goToError = document.getElementById("goToFirstError");
                if (goToError !== null) {
                    let that = this;
                    let firstFocusableEl = document.querySelector(".missing-field");
                    if (firstFocusableEl !== null) {
                        goToError.addEventListener("click", function () {
                            let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                            if (accordion) {
                                let id = accordion.getAttribute("aria-labelledby");
                                let accordionElement = document.getElementById(id);
                                accordionElement.click();
                            }
                            if (firstFocusableEl.classList.contains("mce")) {
                                tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                            }
                            else {
                                firstFocusableEl.focus();
                            }
                        });
                    }
                    else {
                        goToError.parentNode.removeChild(goToError);
                    }
                }
            }
            setTimeout(function () {
                messageContainer.focus();
            }, 500);
            const leftBarItem = document.querySelector("#leftBarcatsSystemTeacherIndustryParent[data-pagecode='catsSystemTeacherIndustry'] .status-indicator");
            if (showMessage) {
                let message = document.getElementById("validationMessage");
                messageContainer.classList.add("warning");
                message.classList.add("show");
                validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
                leftBarItem.classList.remove("vertical-align-height");
                leftBarItem.classList.remove("complete");
            }
            else {
                messageContainer.classList.add("success");
                validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
                let successMessage = document.getElementById("validationMessage");
                if (successMessage !== null) {
                    successMessage.innerHTML = "The page has been successfully saved.";
                    successMessage.classList.remove("validation-message");
                }
                leftBarItem.classList.add("vertical-align-height");
                leftBarItem.classList.add("complete");
            }
        });
    }
    updatePPIDForTeacher(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const catsTeacherFK = button.dataset.catsteacherfk;
            const catsProgramFK = button.dataset.programfk;
            const planFK = button.dataset.planfk;
            Core.showLoader();
            const response = yield fetch(`/CATSSystem/ShowUpdatePPID/${catsProgramFK}/${catsTeacherFK}/${planFK}`, { credentials: 'include' });
            if (response.ok) {
                const container = document.querySelector(`.updateRow[data-catsteacherfk='${catsTeacherFK}']`);
                const output = yield response.text();
                container.innerHTML = output;
                container.classList.remove("hide");
                Core.hideLoader();
                this.bindFindPPIDForUpdate();
            }
        });
    }
    bindFindPPIDForUpdate() {
        const button = document.getElementById("catsSystemTeacherIndustryTeacherAddTeacherAdd");
        button.addEventListener("click", (e) => this.findTeacher());
    }
    findTeacher() {
        return __awaiter(this, void 0, void 0, function* () {
            const ppidElement = document.getElementById("catsSystemTeacherIndustryProfessionalPersonnelIDAddId");
            const ppid = ppidElement.value;
            const catsProgramFK = ppidElement.dataset.catsprogramfk;
            const planFK = ppidElement.dataset.planfk;
            const catsTeacherFK = ppidElement.dataset.catsteacherfk;
            if (ppid === "") {
                Core.createHTMLAlert("alertMessageDiv", "Enter a PPID to search for that teacher.", "warning", 3000, null);
            }
            else {
                let dataModel = {
                    "Name": "",
                    "PPID": ppid,
                    "ProgramPK": catsProgramFK,
                    "PlanFK": planFK
                };
                let teacherExists = false;
                let notValidForCIP = false;
                let notInTIMS = false;
                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };
                if (dataModel.PPID !== "") {
                    const teacherExistsResponse = yield fetch(`/CATSSystem/TeacherExists`, settings);
                    if (teacherExistsResponse) {
                        const output = yield teacherExistsResponse.json();
                        if (output.teacherExists === true)
                            teacherExists = true;
                        if (output.notValidForCIP === true)
                            notValidForCIP = true;
                        if (output.notInTIMS === true)
                            notInTIMS = true;
                    }
                }
                if (notInTIMS === true) {
                    let modal = new Modal("notInTIMSModal", null);
                    modal.show();
                }
                else if (teacherExists === true) {
                    let modal = new Modal("teacherExistsModal", null);
                    modal.show();
                }
                else if (notValidForCIP === true) {
                    let modal = new Modal("notValidInCIPModal", null);
                    modal.show();
                }
                else {
                    const response = yield fetch(`/CATSSystem/GetTeacherInformation/${ppid}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.json();
                        const ppidHolderFK = output.ppidHolderFK;
                        const name = output.name;
                        const container = document.querySelector(`.updateTeacherInfo[data-catsprogramfk='${catsProgramFK}'][data-catsteacherfk='${catsTeacherFK}']`);
                        const newPPIDDiv = document.createElement("div");
                        newPPIDDiv.classList.add("medium-4");
                        newPPIDDiv.classList.add("column");
                        newPPIDDiv.classList.add("updateRowPPID");
                        newPPIDDiv.dataset.ppid = ppid;
                        newPPIDDiv.dataset.catsteacherfk = catsTeacherFK;
                        newPPIDDiv.dataset.catsprogramfk = catsProgramFK;
                        newPPIDDiv.dataset.ppidholderfk = ppidHolderFK;
                        newPPIDDiv.innerHTML = ppid;
                        container.appendChild(newPPIDDiv);
                        const newNameDiv = document.createElement("div");
                        newNameDiv.classList.add("medium-4");
                        newNameDiv.classList.add("column");
                        newNameDiv.innerHTML = name;
                        container.appendChild(newNameDiv);
                        const newButtonDiv = document.createElement("div");
                        newButtonDiv.classList.add("medium-4");
                        newButtonDiv.classList.add("column");
                        const newButton = document.createElement("button");
                        newButton.classList.add("add-row-button");
                        newButton.classList.add("updateTeacherWithPPID");
                        newButton.dataset.catsteacherfk = catsTeacherFK;
                        newButton.dataset.ppidholderfk = ppidHolderFK;
                        newButton.innerHTML = "<i class='fas fa-user-edit'></i> Confirm Update";
                        newButtonDiv.append(newButton);
                        container.classList.add("update-teacher-info");
                        container.append(newButtonDiv);
                        this.bindUpdateByPPIDButton();
                    }
                }
            }
        });
    }
    bindUpdateByPPIDButton() {
        const buttons = document.getElementsByClassName("updateTeacherWithPPID");
        for (const button of buttons)
            button.addEventListener("click", (e) => this.updateTeacher(e));
    }
    updateTeacher(e) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = e.target;
            const catsTeacherFK = button.dataset.catsteacherfk;
            const ppidHolderFK = button.dataset.ppidholderfk;
            const response = yield fetch(`/CATSSystem/UpdateTeacherInformation/${catsTeacherFK}/${ppidHolderFK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                const thisTR = document.querySelector(`.teacherRow[data-catsteacherfk='${catsTeacherFK}']`);
                thisTR.innerHTML = output;
                const updateRowHide = document.querySelector(`.updateRowHide[data-catsteacherfk='${catsTeacherFK}']`);
                const updateRow = document.querySelector(`.updateRow[data-catsteacherfk='${catsTeacherFK}']`);
                updateRowHide.remove();
                updateRow.remove();
                this.bindTeacherCredentials();
                this.bindDeleteTeachers();
            }
        });
    }
    bindDeleteTeachers() {
        const allSecondaryAgreements = document.getElementsByClassName("deleteTeacher");
        for (const agreement of allSecondaryAgreements)
            agreement.addEventListener("click", (e) => this.showDeleteTeacher(e));
    }
    showDeleteTeacher(e) {
        const buttonElement = e.target;
        const catsTeacherFK = buttonElement.dataset.catsteacherfk;
        const modal = new Modal("teacherDeleteModal", null);
        modal.addAttributeToElement("teacherDeleteModal", "#catsSystemTeacherIndustryCredentialsDeleteConfirm", "catsteacherfk", catsTeacherFK);
        modal.show();
    }
    deleteTeacherCancel() {
        let modal = new Modal("teacherDeleteModal", null);
        modal.hide();
    }
    deleteTeacherConfirm(e) {
        const buttonElement = e.target;
        const catsTeacherFK = buttonElement.dataset.catsteacherfk;
        const modal = new Modal("teacherDeleteModal", null);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteTeacher', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.deleteTeacher[data-catsteacherfk='${catsTeacherFK}']`);
                    element.parentElement.parentElement.remove();
                    Core.createHTMLAlert("alertMessageDiv", "The teacher was successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the teacher. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                modal.hide();
            }
            else {
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(`catsTeacherFK=${catsTeacherFK}`);
    }
    customPageValidation() {
        return __awaiter(this, void 0, void 0, function* () {
            //This page is unique since there are two partial views that could be displayed in the same spot. We need to check the one partial view
            //to make sure there is valid data, but the only way to do that is on the server side.
            const formElement = document.getElementById("catsSystemTeacherIndustryForm");
            const programPK = parseInt(formElement.dataset.programpk);
            let retVal = 0;
            const response = yield fetch(`/CATSSystem/TeacherIndustryCredentialsCustomClientValidation/${programPK}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.json();
                if (output === false)
                    retVal = 1;
            }
            return retVal;
        });
    }
}
// CatsSystemSponsors
class CatsSystemSponsors {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemSponsorsField", "catsSystemSponsorsRadioField"];
        let catsSystemSponsorsSaveButton = document.getElementById("catsSystemSponsorsSave");
        if (catsSystemSponsorsSaveButton !== null)
            catsSystemSponsorsSaveButton.addEventListener("click", (e) => this.save("save"));
        const catsSystemSponsorsShowSponsorsRadios = document.getElementsByClassName("catsSystemSponsorsShowSponsors");
        for (const catsSystemSponsorsShowSponsorsRadio of catsSystemSponsorsShowSponsorsRadios) {
            catsSystemSponsorsShowSponsorsRadio.addEventListener("click", (e) => this.changeSponsor(e.target));
            this.changeSponsor(catsSystemSponsorsShowSponsorsRadio);
        }
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addSponsorRowButton = document.querySelector(`#addSponsorRow`);
        if (addSponsorRowButton !== null)
            addSponsorRowButton.addEventListener("click", (e) => this.addSponsorRow());
        const deleteURLRowConfirmButton = document.getElementById("catsSystemCATSRowDeleteConfirm");
        if (deleteURLRowConfirmButton !== null)
            deleteURLRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const deleteURLRowCancelButton = document.getElementById("catsSystemCATSRowDeleteCancel");
        if (deleteURLRowCancelButton !== null)
            deleteURLRowCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        this.bindDeletes();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemSponsorsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemSponsorsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemSponsorsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radios = document.getElementsByClassName("catsSystemSponsorsRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemSponsors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addSponsorRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemSponsorsForm");
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(".sponsorsRow");
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddSponsor/${planPK}/${programPK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("sponsorsRow");
                newTR.dataset.row = row.toString();
                let container = document.querySelector("#sponsorsTable tbody");
                container.append(newTR);
                this.bindDeletes();
            }
            Core.hideLoader();
        });
    }
    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteSponsorRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDelete(e));
    }
    showDelete(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteRowModal", null);
        modal.addAttributeToElement("deleteRowModal", "#catsSystemCATSRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowCancel() {
        let modal = new Modal("deleteRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.catsSystemSponsorData[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#sponsorsTable tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
    changeSponsor(e) {
        const ele = e;
        if (ele.checked) {
            const label = ele.dataset.lookuplabel;
            const catsSponsorsList = document.getElementById("catsSponsorsList");
            const allDataFields = document.getElementsByClassName("catsSystemSponsorData");
            if (label === "Yes") {
                catsSponsorsList.classList.remove("hide");
                for (const field of allDataFields) {
                    let fieldEle = field;
                    this._core.forceElementRequired(fieldEle);
                }
            }
            else {
                catsSponsorsList.classList.add("hide");
                for (const field of allDataFields) {
                    let fieldEle = field;
                    this._core.forceElementOptional(fieldEle);
                }
            }
        }
    }
}
// CatsSystemIndustryAccreditation
class CatsSystemIndustryAccreditation {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemIndustryAccreditationField", "catsSystemIndustryAccreditationSelectField", "catsSystemIndustryAccreditationCheckboxField"];
        let catsSystemIndustryAccreditationSaveButton = document.getElementById("catsSystemIndustryAccreditationSave");
        if (catsSystemIndustryAccreditationSaveButton !== null)
            catsSystemIndustryAccreditationSaveButton.addEventListener("click", (e) => this.save("save"));
        const allDropdowns = document.getElementsByClassName("catsSystemIndustryAccreditationAccreditationField");
        for (const drop of allDropdowns)
            this.checkForOther(drop);
        this.disableAccredidations();
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const addIndustryAccreditationRowButton = document.querySelector(`#addIndustryAccreditationRow`);
        if (addIndustryAccreditationRowButton !== null)
            addIndustryAccreditationRowButton.addEventListener("click", (e) => this.addIndustryAccreditationRow());
        this.bindOtherDropdowns();
        this.bindDeletes();
        //const allDropdowns = document.getElementsByClassName("catsSystemIndustryAccreditationAccreditationField");
        //for (const drop of allDropdowns)
        //    this.checkForOther(<HTMLSelectElement>drop);
        const deleteURLRowConfirmButton = document.getElementById("catsSystemCATSRowDeleteConfirm");
        if (deleteURLRowConfirmButton !== null)
            deleteURLRowConfirmButton.addEventListener("click", (e) => this.deleteRowConfirm(e));
        const deleteURLRowCancelButton = document.getElementById("catsSystemCATSRowDeleteCancel");
        if (deleteURLRowCancelButton !== null)
            deleteURLRowCancelButton.addEventListener("click", (e) => this.deleteRowCancel());
        const accredidationButton = document.getElementById("catsSystemIndustryAccreditationNoIndustryAccreditation");
        if (accredidationButton !== null)
            accredidationButton.addEventListener("click", (e) => this.disableAccredidations());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemIndustryAccreditationForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemIndustryAccreditationForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemIndustryAccreditationField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let selectInputs = document.getElementsByClassName("catsSystemIndustryAccreditationSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("catsSystemIndustryAccreditationCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "";
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemIndustryAccreditation', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addIndustryAccreditationRow() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const form = document.getElementById("catsSystemIndustryAccreditationForm");
            const planPK = form.dataset.planfk;
            const programPK = form.dataset.programfk;
            const allRows = document.querySelectorAll(".industryAccreditationRow");
            let row = 0;
            for (let thisRow of allRows) {
                const ele = thisRow;
                const currentRow = parseInt(ele.dataset.row);
                if (!isNaN(currentRow) && currentRow > row)
                    row = currentRow;
            }
            row++;
            const response = yield fetch(`/CATSSystem/AddIndustryAccreditation/${planPK}/${programPK}/${row}`, { credentials: 'include' });
            if (response.ok) {
                const output = yield response.text();
                let newTR = document.createElement("tr");
                newTR.innerHTML = output;
                newTR.classList.add("industryAccreditationRow");
                newTR.classList.add("industry-accreditation");
                newTR.dataset.row = row.toString();
                let container = document.querySelector("#industryAccreditationTable tbody");
                container.append(newTR);
                this.bindDeletes();
                this.bindOtherDropdowns();
            }
            Core.hideLoader();
        });
    }
    disableAccredidations() {
        const accredidationButton = document.getElementById("catsSystemIndustryAccreditationNoIndustryAccreditation");
        let elements = document.getElementsByClassName("catsSystemIndustryAccreditationNOTOtherField");
        let otherElements = document.getElementsByClassName("catsSystemIndustryAccreditationOtherField");
        let button = document.getElementById("addIndustryAccreditationRow");
        let accredidationElement = accredidationButton;
        //    const catsSponsorsList = <HTMLDivElement>document.getElementById("catsSponsorsList");
        //    const allDataFields = document.getElementsByClassName("catsSystemSponsorData");
        //    if (label === "Yes") {
        //        catsSponsorsList.classList.remove("hide");
        //        for (const field of allDataFields) {
        //            let fieldEle = <HTMLInputElement>field;
        //            this._core.forceElementRequired(fieldEle);
        //        }
        //    } else {
        //        catsSponsorsList.classList.add("hide");
        //        for (const field of allDataFields) {
        //            let fieldEle = <HTMLInputElement>field;
        //            this._core.forceElementOptional(fieldEle);
        //        }
        //    }
        //}
        if (accredidationElement.checked) {
            for (let ele of elements) {
                let element = ele;
                element.disabled = true;
                this._core.forceElementOptional(element);
                button.disabled = true;
            }
            for (let ele of otherElements) {
                let element = ele;
                if (!element.classList.contains("hide")) {
                    element.disabled = true;
                }
            }
        }
        else {
            for (let ele of elements) {
                let element = ele;
                element.disabled = false;
                this._core.forceElementRequired(element);
                button.disabled = false;
            }
            for (let ele of otherElements) {
                let element = ele;
                if (!element.classList.contains("hide")) {
                    element.disabled = false;
                }
            }
        }
    }
    bindOtherDropdowns() {
        const allDropdowns = document.getElementsByClassName("catsSystemIndustryAccreditationAccreditationField");
        for (const drop of allDropdowns)
            drop.addEventListener("change", (e) => this.checkForOther(e.target));
    }
    bindDeletes() {
        const allDeletes = document.getElementsByClassName("deleteIndustryAccreditationRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDelete(e));
    }
    checkForOther(e) {
        const dropdown = e;
        const row = dropdown.dataset.row;
        const otherField = document.querySelector(`.catsSystemIndustryAccreditationOtherField[data-row='${row}']`);
        if (dropdown[dropdown.selectedIndex].textContent.indexOf("Other") >= 0) {
            otherField.classList.remove("hide");
            dropdown.classList.add("other");
            let element = otherField;
            this._core.forceElementRequired(element);
        }
        else {
            otherField.classList.add("hide");
            dropdown.classList.remove("other");
            let element = otherField;
            this._core.forceElementOptional(element);
        }
    }
    showDelete(e) {
        let buttonElement = e.target;
        let row = buttonElement.dataset.row;
        let modal = new Modal("deleteRowModal", null);
        modal.addAttributeToElement("deleteRowModal", "#catsSystemCATSRowDeleteConfirm", "row", row);
        modal.show();
    }
    deleteRowCancel() {
        let modal = new Modal("deleteRowModal", null);
        modal.hide();
    }
    deleteRowConfirm(e) {
        let buttonElement = e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let allElements = document.querySelectorAll(`.catsSystemIndustryAccreditationData[data-row='${row}']`);
        for (let ele of allElements) {
            let element = ele;
            let planProp = element.dataset.planpropertypk;
            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                let rowElement = document.querySelector(`#industryAccreditationTable tbody tr[data-row='${row}']`);
                rowElement.remove();
                let modal = new Modal("deleteRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}
// CatsSystemComments
class CatsSystemComments {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["catsSystemCommentsField"];
        let catsSystemCommentsSaveButton = document.getElementById("catsSystemCommentsSave");
        if (catsSystemCommentsSaveButton !== null)
            catsSystemCommentsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        this.saveAccess();
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemCommentsForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("catsSystemCommentsForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("catsSystemCommentsField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemComments', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// CatsSystemSummaryofNewProgram
class CatsSystemSummaryofNewProgram {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
        this.saveAccess();
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    saveAccess() {
        const formName = "catsSystemSummaryofNewProgramForm";
        let planFK = 0;
        let form = document.getElementById(formName);
        planFK = parseInt(form.dataset.parentplanfk);
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/CATSSystem/SaveCatsSystemAccess', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
            }
        };
        xhr.send(`planFK=${planFK}`);
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/CATSSystem/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// ContinuingProfAppIUProfile
class ContinuingProfAppIUProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUProfileField", "continuingProfAppIUProfileRadioField"];
        let continuingProfAppIUProfileSaveButton = document.getElementById("continuingProfAppIUProfileSave");
        if (continuingProfAppIUProfileSaveButton !== null)
            continuingProfAppIUProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("continuingProfAppIUProfileForm"));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUProfileForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUProfileField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let radioInputs = document.getElementsByClassName("continuingProfAppIUProfileRadioField");
        for (let ele of radioInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// ContinuingProfAppIUCPE
class ContinuingProfAppIUCPE {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUCPEField", "continuingProfAppIUCPEFileField"];
        let continuingProfAppIUCPESaveButton = document.getElementById("continuingProfAppIUCPESave");
        if (continuingProfAppIUCPESaveButton !== null)
            continuingProfAppIUCPESaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const instructorInformationAddButton = document.getElementById("continuingProfessionalEdAddInstructor");
        if (instructorInformationAddButton !== null)
            instructorInformationAddButton.addEventListener("click", (e) => this.addInstructor());
        const instructorInformationDeleteCancelButton = document.getElementById("continuingProfessionalEdDeleteInstructorCancel");
        if (instructorInformationDeleteCancelButton !== null)
            instructorInformationDeleteCancelButton.addEventListener("click", (e) => this.deleteInstructorCancel());
        const instructorInformationDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeleteInstructorConfirm");
        if (instructorInformationDeleteConfirmButton !== null)
            instructorInformationDeleteConfirmButton.addEventListener("click", (e) => this.deleteInstructorConfirm(e));
        this.bindDeleteInstructor();
        const instructorInformationAddInstructorNumberElement = document.getElementById("continuingProfessionalEdInstructorAddNumber");
        if (instructorInformationAddInstructorNumberElement !== null)
            instructorInformationAddInstructorNumberElement.addEventListener("input", (e) => this.checkPlural(e));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        this.bindUploadFiles();
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUCPEForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUCPEField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("continuingProfAppIUCPEFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUCPE', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    bindUploadFiles() {
        const fileResumes = document.querySelectorAll(`.continuingProfAppIUCPEUploadInstructorResume`);
        for (const fileResume of fileResumes)
            fileResume.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    uploadFile(e) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;
        const row = e.dataset.row;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.continuingProfAppIUCPEFileField[data-row='${row}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList[data-row='${row}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-row='${row}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = row;
                        fileA.dataset.row = row;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUCPEFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUCPEFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.getElementById(`uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    addInstructor() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("continuingProfAppIUCPEForm");
            let valueElement = document.getElementById("continuingProfessionalEdInstructorAddNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll(".instrutor");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                let added = false;
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/ContinuingProfessionalEdAppIU/AddInstructorInformation/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newDiv = document.createElement("div");
                        newDiv.classList.add("instructor");
                        newDiv.innerHTML = output;
                        newDiv.dataset.row = newRow.toString();
                        let container = document.querySelector("#instructors");
                        container.append(newDiv);
                        that.bindDeleteInstructor();
                        that.bindUploadFiles();
                        added = true;
                    }
                }
                valueElement.value = "1";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `${numberOfRows} instructor${plural} added!`;
                Core.hideLoader();
                if (added)
                    Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the instructor. Please try again.", 'error', 2000, null);
            }
            else {
                valueElement.value = "1";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    checkPlural(e) {
        const instructorInformationAddInstructorNumberElement = e.target;
        const wordElement = document.getElementById("continuingProfessionalEdInstructorAddNumberWord");
        const value = parseInt(instructorInformationAddInstructorNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "instructor";
            else
                wordElement.innerText = "instructors";
        }
        else {
            wordElement.innerText = "instructors";
        }
    }
    deleteInstructorCancel() {
        let modal = new Modal("deleteInstructorInformationModal", null);
        modal.hide();
    }
    deleteInstructorConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const row = buttonElement.dataset.row;
            const planProps = [];
            const planPropFiles = [];
            const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCPEField[data-row='${row}']`);
            for (const planProp of planPropertyValues) {
                const ele = planProp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planProps.push(ele.dataset.planpropertypk);
            }
            const planPropertyFileValues = document.querySelectorAll(`.continuingProfAppIUCPEFileUploadField[data-row='${row}']`);
            for (const planPropFile of planPropertyFileValues) {
                const ele = planPropFile;
                if (ele.dataset.planpropertyfilepk && ele.dataset.planpropertyfilepk !== "" && ele.dataset.planpropertyfilepk !== "0")
                    planPropFiles.push(ele.dataset.planpropertyfilepk);
            }
            const dataModel = {
                "PlanPropertyPKs": planProps,
                "PlanPropertyFilePKs": planPropFiles
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            let modal = new Modal("deleteInstructorInformationModal", null);
            modal.hide();
            const response = yield fetch(`/ContinuingProfessionalEdAppIU/DeleteInstructor`, settings);
            if (response.ok) {
                const container = document.querySelector(`.instructor[data-row='${row}']`);
                container.remove();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The instructor was successfully deleted!", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the instructor. Please try again.", 'error', 3000, null);
            }
        });
    }
    showDeleteInstructorInformation(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteInstructorInformationModal", null);
        modal.addAttributeToElement("deleteInstructorInformationModal", "#continuingProfessionalEdDeleteInstructorConfirm", "row", row);
        modal.show();
    }
    bindDeleteInstructor() {
        let allDeletes = document.getElementsByClassName("deleteInstructorInformation");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteInstructorInformation(e));
    }
}
// ContinuingProfAppIUStatement
class ContinuingProfAppIUStatement {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUStatementField"];
        let continuingProfAppIUStatementSaveButton = document.getElementById("continuingProfAppIUStatementSave");
        if (continuingProfAppIUStatementSaveButton !== null)
            continuingProfAppIUStatementSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUStatementForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUStatementField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUStatement', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// ContinuingProfAppIUCourseContent
class ContinuingProfAppIUCourseContent {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUCourseContentField", "continuingProfAppIUCourseContentFileField"];
        let continuingProfAppIUCourseContentSaveButton = document.getElementById("continuingProfAppIUCourseContentSave");
        if (continuingProfAppIUCourseContentSaveButton !== null)
            continuingProfAppIUCourseContentSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const fileUploads = document.querySelectorAll(`.continuingProfAppIUCourseContentUploadControl`);
        for (const fileUpload of fileUploads)
            fileUpload.addEventListener("change", (e) => this.uploadFile(e.target));
        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        const certificationAddButton = document.getElementById("continuingProfessionalEdAddCertification");
        if (certificationAddButton !== null)
            certificationAddButton.addEventListener("click", (e) => this.addCertification());
        const certificationDeleteCancelButton = document.getElementById("continuingProfessionalEdDeleteCertificationCancel");
        if (certificationDeleteCancelButton !== null)
            certificationDeleteCancelButton.addEventListener("click", (e) => this.deleteCertificationCancel());
        const certificationDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeleteCertificationConfirm");
        if (certificationDeleteConfirmButton !== null)
            certificationDeleteConfirmButton.addEventListener("click", (e) => this.deleteCertificationConfirm(e));
        this.bindDeleteCertification();
        const instructorInformationAddCertificationNumberElement = document.getElementById("continuingProfessionalEdCertificationAddNumber");
        if (instructorInformationAddCertificationNumberElement !== null)
            instructorInformationAddCertificationNumberElement.addEventListener("input", (e) => this.checkPlural(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUCourseContentForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUCourseContentField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("continuingProfAppIUCourseContentFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUCourseContent', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;
        const fileType = e.dataset.filetype;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.continuingProfAppIUCourseContentFileField[data-filetype='${fileType}']`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList[data-filetype='${fileType}']`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUCourseContentFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUCPEFileField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList[data-filetype='${fileType}'] .uploadFileColumn[data-filetype='${fileType}']`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles[data-filetype='${fileType}']`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    addCertification() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("continuingProfAppIUCourseContentForm");
            let valueElement = document.getElementById("continuingProfessionalEdCertificationAddNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll(".instrutor");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                let added = false;
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/ContinuingProfessionalEdAppIU/AddCertification/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.classList.add("certification");
                        newTR.dataset.row = newRow.toString();
                        newTR.innerHTML = output;
                        let container = document.querySelector("#certificationList tbody");
                        container.append(newTR);
                        that.bindDeleteCertification();
                        added = true;
                    }
                }
                valueElement.value = "1";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `${numberOfRows} instructor${plural} added!`;
                Core.hideLoader();
                if (added)
                    Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the instructor. Please try again.", 'error', 2000, null);
            }
            else {
                valueElement.value = "1";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deleteCertificationCancel() {
        let modal = new Modal("deleteCertificationModal", null);
        modal.hide();
    }
    deleteCertificationConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const row = buttonElement.dataset.row;
            const planProps = [];
            const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCertificationField[data-row='${row}']`);
            for (const planProp of planPropertyValues) {
                const ele = planProp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planProps.push(ele.dataset.planpropertypk);
            }
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planProps)
            };
            let modal = new Modal("deleteCertificationModal", null);
            modal.hide();
            const response = yield fetch(`/ContinuingProfessionalEdAppIU/DeleteCertification`, settings);
            if (response.ok) {
                const container = document.querySelector(`.certification[data-row='${row}']`);
                container.remove();
                const certificationListAnyLeft = document.querySelectorAll("#certificationList .certification");
                if (certificationListAnyLeft.length === 0) {
                    const continuingProfessionalEdCertificationAddNumber = document.getElementById("continuingProfessionalEdCertificationAddNumber");
                    const continuingProfessionalEdAddCertificationButton = document.getElementById("continuingProfessionalEdAddCertification");
                    continuingProfessionalEdCertificationAddNumber.value = "1";
                    continuingProfessionalEdAddCertificationButton.click();
                }
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The certification was successfully deleted!", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the certification. Please try again.", 'error', 3000, null);
            }
        });
    }
    showDeleteCertification(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteCertificationModal", null);
        modal.addAttributeToElement("deleteCertificationModal", "#continuingProfessionalEdDeleteCertificationConfirm", "row", row);
        modal.show();
    }
    bindDeleteCertification() {
        let allDeletes = document.getElementsByClassName("deleteCertificationRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteCertification(e));
    }
    checkPlural(e) {
        const instructorInformationAddCertificationNumberElement = e.target;
        const wordElement = document.getElementById("continuingProfessionalEdCertificationAddNumberWord");
        const value = parseInt(instructorInformationAddCertificationNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        }
        else {
            wordElement.innerText = "rows";
        }
    }
}
// ContinuingProfAppIUDesign
class ContinuingProfAppIUDesign {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUDesignField"];
        let continuingProfAppIUDesignSaveButton = document.getElementById("continuingProfAppIUDesignSave");
        if (continuingProfAppIUDesignSaveButton !== null)
            continuingProfAppIUDesignSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUDesignForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUDesignField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUDesign', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// ContinuingProfAppIUOF
class ContinuingProfAppIUOF {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUOFField", "continuingProfAppIUOFCheckboxField", "continuingProfAppIUOFSelectField"];
        let continuingProfAppIUOFSaveButton = document.getElementById("continuingProfAppIUOFSave");
        if (continuingProfAppIUOFSaveButton !== null)
            continuingProfAppIUOFSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customOFDoValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, true);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const observationalFrameworkAddButton = document.getElementById("continuingProfessionalEdAddOF");
        if (observationalFrameworkAddButton !== null)
            observationalFrameworkAddButton.addEventListener("click", (e) => this.addObservationalFramework());
        const observationalFrameworkDeleteCancelButton = document.getElementById("continuingProfessionalEdDeleteOFCancel");
        if (observationalFrameworkDeleteCancelButton !== null)
            observationalFrameworkDeleteCancelButton.addEventListener("click", (e) => this.deleteObservationalFrameworkCancel());
        const observationalFrameworkDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeleteOFConfirm");
        if (observationalFrameworkDeleteConfirmButton !== null)
            observationalFrameworkDeleteConfirmButton.addEventListener("click", (e) => this.deleteObservationalFrameworkConfirm(e));
        this.bindDeleteObservationalFramework();
        const addObservationalFrameworkNumberElement = document.getElementById("continuingProfessionalEdOFAddNumber");
        if (addObservationalFrameworkNumberElement !== null)
            addObservationalFrameworkNumberElement.addEventListener("input", (e) => this.checkPlural(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let allCheckElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUOFForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUOFField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const selects = document.getElementsByClassName("continuingProfAppIUOFSelectField");
        for (let ele of selects) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: "",
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const allData = {
            "PlanFK": planPK,
            "SaveData": allSaveElements,
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/SaveContinuingProfAppIUOF', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData === null) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    addObservationalFramework() {
        return __awaiter(this, void 0, void 0, function* () {
            let numberOfRows = 0;
            let formElement = document.getElementById("continuingProfAppIUOFForm");
            let valueElement = document.getElementById("continuingProfessionalEdOFAddNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            const totalNumberOfRowsElements = document.querySelectorAll(".observationalFramework");
            let totalNumberOfRows = 0;
            if (totalNumberOfRows !== null)
                totalNumberOfRows = totalNumberOfRowsElements.length;
            if (totalNumberOfRows === 3) {
                const tooManyMessage = "Identify no more than three Observational Framework Components to which the course is aligned.";
                Core.createHTMLAlert("alertMessageDiv", tooManyMessage, 'warning', 2000, null);
            }
            else {
                if (!isNaN(numberOfRows) && numberOfRows > 0) {
                    Core.showLoader();
                    let planFK = formElement.dataset.planfk;
                    let that = this;
                    let newRow = 0;
                    let allRows = document.querySelectorAll(".observationalFramework");
                    for (let row of allRows) {
                        let rowEle = row;
                        let thisRow = parseInt(rowEle.dataset.row);
                        if (thisRow > newRow)
                            newRow = thisRow;
                    }
                    let added = false;
                    for (let i = 0; i < numberOfRows; i++) {
                        newRow++;
                        let response = yield fetch(`/ContinuingProfessionalEdAppIU/AddObservationalFramework/${planFK}/${newRow}`, { credentials: 'include' });
                        if (response.ok) {
                            const output = yield response.text();
                            let newTR = document.createElement("tr");
                            newTR.classList.add("observationalFramework");
                            newTR.dataset.row = newRow.toString();
                            newTR.innerHTML = output;
                            let container = document.querySelector("#observationalFrameworks tbody");
                            container.append(newTR);
                            that.bindDeleteObservationalFramework();
                            added = true;
                        }
                    }
                    valueElement.value = "1";
                    let plural = "s";
                    if (numberOfRows === 1)
                        plural = "";
                    const message = `${numberOfRows} instructor${plural} added!`;
                    Core.hideLoader();
                    if (added)
                        Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
                    else
                        Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the instructor. Please try again.", 'error', 2000, null);
                }
                else {
                    valueElement.value = "1";
                    Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                    Core.hideLoader();
                }
            }
        });
    }
    deleteObservationalFrameworkCancel() {
        let modal = new Modal("deleteObservationalFrameworkModal", null);
        modal.hide();
    }
    deleteObservationalFrameworkConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const row = buttonElement.dataset.row;
            const planProps = [];
            const planPropFiles = [];
            const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCertificationField[data-row='${row}']`);
            for (const planProp of planPropertyValues) {
                const ele = planProp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planProps.push(ele.dataset.planpropertypk);
            }
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planProps)
            };
            let modal = new Modal("deleteObservationalFrameworkModal", null);
            modal.hide();
            const response = yield fetch(`/ContinuingProfessionalEdAppIU/DeleteObservationalFramework`, settings);
            if (response.ok) {
                const container = document.querySelector(`.observationalFramework[data-row='${row}']`);
                container.remove();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The observational framework was successfully deleted!", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the observational framework. Please try again.", 'error', 3000, null);
            }
        });
    }
    showDeleteObservationalFramework(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deleteObservationalFrameworkModal", null);
        modal.addAttributeToElement("deleteObservationalFrameworkModal", "#continuingProfessionalEdDeleteOFConfirm", "row", row);
        modal.show();
    }
    bindDeleteObservationalFramework() {
        let allDeletes = document.getElementsByClassName("deleteObservationalFrameworkRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeleteObservationalFramework(e));
    }
    checkPlural(e) {
        const instructorInformationAddCertificationNumberElement = e.target;
        const wordElement = document.getElementById("continuingProfessionalEdOFAddNumberWord");
        const value = parseInt(instructorInformationAddCertificationNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        }
        else {
            wordElement.innerText = "rows";
        }
    }
    customOFDoValidation(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.customOFClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    customOFClientSideValidation(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        let classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        let allElements = document.querySelectorAll(classesToValidate);
        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        if (element instanceof HTMLTextAreaElement) {
                            const textAreaElement = element;
                            if (textAreaElement.value === "") {
                                textAreaElement.classList.add("missing-field");
                                textAreaElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(textAreaElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                        else if (element instanceof HTMLSelectElement) {
                            const select = element;
                            if (select.value === "0") {
                                select.classList.add("missing-field");
                                select.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(select);
                                showMessage = true;
                                totalErrors++;
                            }
                        }
                    }
                }
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}
// ContinuingProfAppIUPACore
class ContinuingProfAppIUPACore {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUPACoreField"];
        let continuingProfAppIUPACoreSaveButton = document.getElementById("continuingProfAppIUPACoreSave");
        if (continuingProfAppIUPACoreSaveButton !== null)
            continuingProfAppIUPACoreSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const paCoreStandardAddButton = document.getElementById("continuingProfessionalEdAddPACore");
        if (paCoreStandardAddButton !== null)
            paCoreStandardAddButton.addEventListener("click", (e) => this.addPACoreStandard());
        const paCoreStandardDeleteCancelButton = document.getElementById("continuingProfessionalEdDeletePACoreCancel");
        if (paCoreStandardDeleteCancelButton !== null)
            paCoreStandardDeleteCancelButton.addEventListener("click", (e) => this.deletePACoreStandardCancel());
        const paCoreStandardDeleteConfirmButton = document.getElementById("continuingProfessionalEdDeletePACoreConfirm");
        if (paCoreStandardDeleteConfirmButton !== null)
            paCoreStandardDeleteConfirmButton.addEventListener("click", (e) => this.deletePACoreStandardConfirm(e));
        this.bindDeletePAScoreStandard();
        const addPACoreStandardNumberElement = document.getElementById("continuingProfessionalEdPACoreAddNumber");
        if (addPACoreStandardNumberElement !== null)
            addPACoreStandardNumberElement.addEventListener("input", (e) => this.checkPlural(e));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUPACoreForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUPACoreField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUPACore', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    addPACoreStandard() {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            let numberOfRows = 0;
            let formElement = document.getElementById("continuingProfAppIUPACoreForm");
            let valueElement = document.getElementById("continuingProfessionalEdPACoreAddNumber");
            if (valueElement !== null) {
                numberOfRows = parseInt(valueElement.value);
            }
            if (!isNaN(numberOfRows) && numberOfRows > 0) {
                let planFK = formElement.dataset.planfk;
                let that = this;
                let newRow = 0;
                let allRows = document.querySelectorAll(".paCoreStandard");
                for (let row of allRows) {
                    let rowEle = row;
                    let thisRow = parseInt(rowEle.dataset.row);
                    if (thisRow > newRow)
                        newRow = thisRow;
                }
                let added = false;
                for (let i = 0; i < numberOfRows; i++) {
                    newRow++;
                    let response = yield fetch(`/ContinuingProfessionalEdAppIU/AddPACoreStandard/${planFK}/${newRow}`, { credentials: 'include' });
                    if (response.ok) {
                        const output = yield response.text();
                        let newTR = document.createElement("tr");
                        newTR.classList.add("paCoreStandard");
                        newTR.dataset.row = newRow.toString();
                        newTR.innerHTML = output;
                        let container = document.querySelector("#paCoreStandards tbody");
                        container.append(newTR);
                        that.bindDeletePAScoreStandard();
                        added = true;
                    }
                }
                valueElement.value = "1";
                let plural = "s";
                if (numberOfRows === 1)
                    plural = "";
                const message = `${numberOfRows} record${plural} added!`;
                Core.hideLoader();
                if (added)
                    Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
                else
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the record. Please try again.", 'error', 2000, null);
            }
            else {
                valueElement.value = "1";
                Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
                Core.hideLoader();
            }
        });
    }
    deletePACoreStandardCancel() {
        let modal = new Modal("deletePACoreModal", null);
        modal.hide();
    }
    deletePACoreStandardConfirm(e) {
        return __awaiter(this, void 0, void 0, function* () {
            Core.showLoader();
            const buttonElement = e.target;
            const row = buttonElement.dataset.row;
            const planProps = [];
            const planPropertyValues = document.querySelectorAll(`.continuingProfAppIUCertificationField[data-row='${row}']`);
            for (const planProp of planPropertyValues) {
                const ele = planProp;
                if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                    planProps.push(ele.dataset.planpropertypk);
            }
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(planProps)
            };
            let modal = new Modal("deletePACoreModal", null);
            modal.hide();
            const response = yield fetch(`/ContinuingProfessionalEdAppIU/DeletePlanProperties`, settings);
            if (response.ok) {
                const container = document.querySelector(`.paCoreStandard[data-row='${row}']`);
                container.remove();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The record was successfully deleted!", 'success', 3000, null);
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the record. Please try again.", 'error', 3000, null);
            }
        });
    }
    showDeletePACoreStandard(e) {
        let deleteButton = e.target;
        let row = deleteButton.dataset.row;
        let modal = new Modal("deletePACoreModal", null);
        modal.addAttributeToElement("deletePACoreModal", "#continuingProfessionalEdDeletePACoreConfirm", "row", row);
        modal.show();
    }
    bindDeletePAScoreStandard() {
        let allDeletes = document.getElementsByClassName("deletePACoreStandard");
        for (let del of allDeletes)
            del.addEventListener("click", (e) => this.showDeletePACoreStandard(e));
    }
    checkPlural(e) {
        const paCoreNumberElement = e.target;
        const wordElement = document.getElementById("continuingProfessionalEdPACoreAddNumberWord");
        const value = parseInt(paCoreNumberElement.value);
        if (!isNaN(value)) {
            if (value === 1)
                wordElement.innerText = "row";
            else
                wordElement.innerText = "rows";
        }
        else {
            wordElement.innerText = "rows";
        }
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        const classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const allElements = document.querySelectorAll(classesToValidate);
        const rows = [];
        for (const element of allElements) {
            const ele = element;
            const row = ele.dataset.row;
            if (rows.indexOf(row) === -1)
                rows.push(row);
        }
        let hasAtLeastOne = false;
        for (const row of rows) {
            const continuingProfAppIUPACoreAmplifyingExplanation = document.querySelector(`#continuingProfAppIUPACoreAmplifyingExplanation${row}`);
            const continuingProfAppIUPACoreStandards = document.querySelector(`#continuingProfAppIUPACoreStandards${row}`);
            let hasAmp = false;
            if (continuingProfAppIUPACoreAmplifyingExplanation !== null && continuingProfAppIUPACoreAmplifyingExplanation.value !== "") {
                hasAmp = true;
            }
            let hasCore = false;
            if (continuingProfAppIUPACoreStandards !== null && continuingProfAppIUPACoreStandards.value !== "") {
                hasCore = true;
            }
            if (!hasAtLeastOne)
                hasAtLeastOne = hasAmp && hasCore;
            if (hasAmp && !hasCore) {
                if (continuingProfAppIUPACoreStandards.value === "") {
                    continuingProfAppIUPACoreStandards.classList.add("missing-field");
                    continuingProfAppIUPACoreStandards.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(continuingProfAppIUPACoreStandards);
                    showMessage = true;
                    totalErrors++;
                }
            }
            else if (hasCore && !hasAmp) {
                if (continuingProfAppIUPACoreAmplifyingExplanation.value === "") {
                    continuingProfAppIUPACoreAmplifyingExplanation.classList.add("missing-field");
                    continuingProfAppIUPACoreAmplifyingExplanation.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(continuingProfAppIUPACoreAmplifyingExplanation);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        if (!hasAtLeastOne) {
            const row = rows[0];
            const continuingProfAppIUPACoreAmplifyingExplanation = document.querySelector(`#continuingProfAppIUPACoreAmplifyingExplanation${row}`);
            const continuingProfAppIUPACoreStandards = document.querySelector(`#continuingProfAppIUPACoreStandards${row}`);
            continuingProfAppIUPACoreStandards.classList.add("missing-field");
            continuingProfAppIUPACoreStandards.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(continuingProfAppIUPACoreStandards);
            showMessage = true;
            totalErrors++;
            continuingProfAppIUPACoreAmplifyingExplanation.classList.add("missing-field");
            continuingProfAppIUPACoreAmplifyingExplanation.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(continuingProfAppIUPACoreAmplifyingExplanation);
            showMessage = true;
            totalErrors++;
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    initializeRequiredFieldsCustom(allClasses) {
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        const classesToValidate = formattedAllClasses.join(",");
        const allElements = document.querySelectorAll(classesToValidate);
        const rows = [];
        for (const element of allElements) {
            const ele = element;
            const row = ele.dataset.row;
            if (rows.indexOf(row) === -1)
                rows.push(row);
        }
        let hasAtLeastOne = false;
        for (const row of rows) {
            const continuingProfAppIUPACoreAmplifyingExplanation = document.querySelector(`#continuingProfAppIUPACoreAmplifyingExplanation${row}`);
            const continuingProfAppIUPACoreStandards = document.querySelector(`#continuingProfAppIUPACoreStandards${row}`);
            let hasAmp = false;
            if (continuingProfAppIUPACoreAmplifyingExplanation !== null && continuingProfAppIUPACoreAmplifyingExplanation.value !== "") {
                hasAmp = true;
            }
            let hasCore = false;
            if (continuingProfAppIUPACoreStandards !== null && continuingProfAppIUPACoreStandards.value !== "") {
                hasCore = true;
            }
            if (!hasAtLeastOne)
                hasAtLeastOne = hasAmp && hasCore;
            if (hasAmp && !hasCore) {
                continuingProfAppIUPACoreStandards.setAttribute("aria-required", "true");
                let label = Core.findLabelForInput(continuingProfAppIUPACoreStandards);
                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }
            else if (hasCore && !hasAmp) {
                continuingProfAppIUPACoreAmplifyingExplanation.setAttribute("aria-required", "true");
                let label = Core.findLabelForInput(continuingProfAppIUPACoreAmplifyingExplanation);
                if (label !== null && !label.classList.contains("isRequired")) {
                    label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                    label.classList.add("isRequired");
                }
            }
        }
        if (!hasAtLeastOne) {
            const row = rows[0];
            const continuingProfAppIUPACoreAmplifyingExplanation = document.querySelector(`#continuingProfAppIUPACoreAmplifyingExplanation${row}`);
            const continuingProfAppIUPACoreStandards = document.querySelector(`#continuingProfAppIUPACoreStandards${row}`);
            continuingProfAppIUPACoreStandards.setAttribute("aria-required", "true");
            const coreLabel = Core.findLabelForInput(continuingProfAppIUPACoreStandards);
            if (coreLabel !== null && !coreLabel.classList.contains("isRequired")) {
                coreLabel.innerHTML = coreLabel.innerHTML + " <span class='required-label'>*</span>";
                coreLabel.classList.add("isRequired");
            }
            continuingProfAppIUPACoreAmplifyingExplanation.setAttribute("aria-required", "true");
            const ampLabel = Core.findLabelForInput(continuingProfAppIUPACoreAmplifyingExplanation);
            if (ampLabel !== null && !ampLabel.classList.contains("isRequired")) {
                ampLabel.innerHTML = ampLabel.innerHTML + " <span class='required-label'>*</span>";
                ampLabel.classList.add("isRequired");
            }
        }
    }
}
// ContinuingProfAppIUProject
class ContinuingProfAppIUProject {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUProjectField", "continuingProfAppIUProjectFileField"];
        let continuingProfAppIUProjectSaveButton = document.getElementById("continuingProfAppIUProjectSave");
        if (continuingProfAppIUProjectSaveButton !== null)
            continuingProfAppIUProjectSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        const fileUpload = document.querySelector(`.continuingProfAppIUProjectFileField`);
        if (fileUpload !== null)
            fileUpload.addEventListener("change", (e) => this.uploadFile(e.target));
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUProjectForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUProjectField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("continuingProfAppIUProjectFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUProject', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.continuingProfAppIUProjectFileField`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUProjectFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUProjectFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
}
// ContinuingProfAppIUSurvey
class ContinuingProfAppIUSurvey {
    constructor() {
        this._core = new Core();
        this.checkForUpload();
        this.validationClasses = ["continuingProfAppIUSurveyRadioField", "continuingProfAppIUSurveyFileField"];
        let continuingProfAppIUSurveySaveButton = document.getElementById("continuingProfAppIUSurveySave");
        if (continuingProfAppIUSurveySaveButton !== null)
            continuingProfAppIUSurveySaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        const fileUpload = document.querySelector(`.continuingProfAppIUSurveyFileField`);
        if (fileUpload !== null)
            fileUpload.addEventListener("change", (e) => this.uploadFile(e.target));
        const continuingProfAppIUSurveyRadioFields = document.getElementsByClassName("continuingProfAppIUSurveyRadioField");
        for (const continuingProfAppIUSurveyRadioField of continuingProfAppIUSurveyRadioFields)
            continuingProfAppIUSurveyRadioField.addEventListener("change", (e) => this.checkForUpload());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUSurveyForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let radios = document.getElementsByClassName("continuingProfAppIUSurveyRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    let saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                        ButtonRow: 0
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("continuingProfAppIUSurveyFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUSurvey', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.continuingProfAppIUSurveyFileField`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUSurveyFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUSurveyFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    checkForUpload() {
        const continuingProfAppIUSurveyRadioFields = document.getElementsByClassName("continuingProfAppIUSurveyRadioField");
        for (const continuingProfAppIUSurveyRadioField of continuingProfAppIUSurveyRadioFields) {
            const element = continuingProfAppIUSurveyRadioField;
            if (element.checked) {
                if (element.dataset.lookupcode === "yes") {
                    this.toggleUpload(true);
                }
                else {
                    this.toggleUpload(false);
                }
            }
        }
    }
    toggleUpload(show) {
        const surveyUpload = document.getElementById("surveyUpload");
        const continuingProfAppIUSurveyUploadFeedbackFormUsedinCourse = document.getElementById("continuingProfAppIUSurveyUploadFeedbackFormUsedinCourse");
        if (show) {
            surveyUpload.classList.remove("hide");
            this._core.forceElementRequired(continuingProfAppIUSurveyUploadFeedbackFormUsedinCourse);
        }
        else {
            surveyUpload.classList.add("hide");
            this._core.forceElementOptional(continuingProfAppIUSurveyUploadFeedbackFormUsedinCourse);
        }
    }
}
// ContinuingProfAppIUPreKImpact
class ContinuingProfAppIUPreKImpact {
    constructor() {
        this._core = new Core();
        this.otherChecked();
        this.validationClasses = ["continuingProfAppIUPreKImpactField", "continuingProfAppIUPreKImpactCheckboxsField", "continuingProfAppIUPreKImpactFileField"];
        let continuingProfAppIUPreKImpactSaveButton = document.getElementById("continuingProfAppIUPreKImpactSave");
        if (continuingProfAppIUPreKImpactSaveButton !== null)
            continuingProfAppIUPreKImpactSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e) => this.deleteFileConfirm(e));
        const deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e) => this.deleteFileCancel());
        this.bindDeleteFiles();
        const fileUpload = document.querySelector(`.continuingProfAppIUPreKImpactFileField`);
        if (fileUpload !== null)
            fileUpload.addEventListener("change", (e) => this.uploadFile(e.target));
        const otherField = document.querySelector(".continuingProfAppIUPreKImpactOtherCheckboxField");
        if (otherField !== null)
            otherField.addEventListener("change", (e) => this.otherChecked());
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUPreKImpactForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUPreKImpactField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let checkboxes = document.getElementsByClassName("continuingProfAppIUPreKImpactCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        let allFileElements = [];
        let files = document.getElementsByClassName("continuingProfAppIUPreKImpactFileUploadField");
        for (let ele of files) {
            let element = ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };
            allFileElements.push(saveItem);
        }
        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUPreKImpact', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }
    uploadFile(e) {
        Core.showLoader();
        const formName = e.dataset.formname;
        const uploadForm = document.getElementById(formName);
        const formData = new FormData(uploadForm);
        const propertyPK = e.dataset.propertypk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);
                        const formFile = document.querySelector(`.continuingProfAppIUPreKImpactFileField`);
                        formFile.value = "";
                        const fileList = document.querySelector(`.uploadFileList`);
                        let uploadedContainer = document.querySelector(`.uploadedFiles`);
                        uploadedContainer.classList.remove("hide");
                        let fileWrapper = document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.classList.add("continuingProfAppIUPreKImpactFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);
                        fileList.appendChild(fileWrapper);
                    }
                    else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            }
            else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }
    bindDeleteFiles() {
        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e) => this.showDeleteFile(e));
    }
    showDeleteFile(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        const fileType = buttonElement.dataset.filetype;
        let modal = new Modal("deleteFileModal", null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.addAttributeToElement("deleteFileModal", "#deleteFileConfirm", "filetype", fileType);
        modal.show();
    }
    deleteFileConfirm(e) {
        let buttonElement = e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/ContinuingProfessionalEdAppIU/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.continuingProfAppIUPreKImpactFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();
                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = document.querySelector(`.uploadedFiles`);
                        if (uploadsElement !== null) {
                            uploadsElement.classList.add("hide");
                        }
                    }
                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }
                Core.hideLoader();
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
            }
            else {
                let modal = new Modal("deleteFileModal", null);
                modal.hide();
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }
    deleteFileCancel() {
        let modal = new Modal("deleteFileModal", null);
        modal.hide();
    }
    doValidationCustom(allClasses, showMessageOverride) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = document.getElementById("validationColumn");
        let messageContainer = document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = document.getElementById("validationMessageIcon");
        setTimeout(function () {
            messageContainer.focus();
        }, 500);
        if (showMessage) {
            let message = document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        }
        else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";
            let successMessage = document.getElementById("saveSuccess");
            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved.";
            }
        }
    }
    clientSideValidationCustom(allClasses) {
        let showMessage = false;
        let totalErrors = 0;
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        const classesToValidate = formattedAllClasses.join(",");
        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });
        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });
        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });
        const preImpactChecks = document.getElementsByClassName("preImpactField:checked");
        if (preImpactChecks !== null && preImpactChecks.length > 0) {
            Core.createErrorLabelForInput(preImpactChecks[0]);
            showMessage = true;
            totalErrors++;
        }
        const otherCheck = document.querySelector(".continuingProfAppIUPreKImpactOtherCheckboxField");
        if (otherCheck !== null && otherCheck.checked) {
            const continuingProfAppIUPreKImpactOtherDescribe = document.getElementById("continuingProfAppIUPreKImpactOtherDescribe");
            if (continuingProfAppIUPreKImpactOtherDescribe !== null) {
                if (continuingProfAppIUPreKImpactOtherDescribe.value === "") {
                    continuingProfAppIUPreKImpactOtherDescribe.classList.add("missing-field");
                    continuingProfAppIUPreKImpactOtherDescribe.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(continuingProfAppIUPreKImpactOtherDescribe);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        const continuingProfAppIUPreKImpactUploadSampleDataSources = document.getElementById("continuingProfAppIUPreKImpactUploadSampleDataSources");
        if (continuingProfAppIUPreKImpactUploadSampleDataSources !== null) {
            if (!(continuingProfAppIUPreKImpactUploadSampleDataSources.dataset.hasuploaded && continuingProfAppIUPreKImpactUploadSampleDataSources.dataset.hasuploaded === "true")) {
                continuingProfAppIUPreKImpactUploadSampleDataSources.classList.add("missing-field");
                continuingProfAppIUPreKImpactUploadSampleDataSources.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(continuingProfAppIUPreKImpactUploadSampleDataSources);
                showMessage = true;
                totalErrors++;
            }
        }
        let message = document.getElementById("validationMessage");
        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        }
        else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");
        if (goToError !== null) {
            let that = this;
            let firstFocusableEl = document.querySelector(".missing-field");
            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");
                        let accordionElement = document.getElementById(id);
                        accordionElement.click();
                    }
                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    }
                    else {
                        firstFocusableEl.focus();
                    }
                });
            }
            else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
    initializeRequiredFieldsCustom(allClasses) {
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });
        const classesToValidate = formattedAllClasses.join(",");
        const preImpactChecks = document.getElementsByClassName("preImpactField");
        for (const pre of preImpactChecks) {
            const ele = pre;
            this._core.forceElementRequired(ele);
        }
        this._core.initializeRequiredFields(allClasses);
    }
    otherChecked() {
        const otherContainer = document.getElementById("otherContainer");
        const otherText = document.getElementById("continuingProfAppIUPreKImpactOtherDescribe");
        const otherField = document.querySelector(".continuingProfAppIUPreKImpactOtherCheckboxField");
        if (otherField !== null && otherField.checked) {
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherText);
        }
        else {
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherText);
        }
    }
}
// ContinuingProfAppIUSignatures
class ContinuingProfAppIUSignatures {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["continuingProfAppIUSignaturesField"];
        let continuingProfAppIUSignaturesSaveButton = document.getElementById("continuingProfAppIUSignaturesSave");
        if (continuingProfAppIUSignaturesSaveButton !== null)
            continuingProfAppIUSignaturesSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        let fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("continuingProfAppIUSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);
        let textInputs = document.getElementsByClassName("continuingProfAppIUSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                let saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/continuingprofessionaledappiu/SaveContinuingProfAppIUSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// ContinuingProfAppIUSummary
class ContinuingProfAppIUSummary {
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        let element = e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/continuingprofessionaledappiu/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// OpportunityScholarshipProfile
class OpportunityScholarshipProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["opportunityScholarshipProfileField"];
        const opportunityScholarshipProfileSaveButton = document.getElementById("opportunityScholarshipProfileSave");
        if (opportunityScholarshipProfileSaveButton !== null)
            opportunityScholarshipProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("opportunityScholarshipProfileForm"));
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("opportunityScholarshipProfileForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("opportunityScholarshipProfileField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/OpportunityScholarshipTaxCreditProgramParticipationForm/SaveOpportunityScholarshipProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// OpportunityScholarshipTuition
class OpportunityScholarshipTuition {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["opportunityScholarshipTuitionField", "opportunityScholarshipTuitionRadioField"];
        const opportunityScholarshipTuitionSaveButton = document.getElementById("opportunityScholarshipTuitionSave");
        if (opportunityScholarshipTuitionSaveButton !== null)
            opportunityScholarshipTuitionSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("opportunityScholarshipTuitionForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("opportunityScholarshipTuitionField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const radios = document.getElementsByClassName("opportunityScholarshipTuitionRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }
                if (element.value !== "" || hadValue) {
                    const saveItem = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };
                    allSaveElements.push(saveItem);
                }
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/OpportunityScholarshipTaxCreditProgramParticipationForm/SaveOpportunityScholarshipTuition', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// OpportunityScholarshipSignature
class OpportunityScholarshipSignature {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["opportunityScholarshipSignatureField", "opportunityScholarshipSignatureCheckboxField"];
        const opportunityScholarshipSignatureSaveButton = document.getElementById("opportunityScholarshipSignatureSave");
        if (opportunityScholarshipSignatureSaveButton !== null)
            opportunityScholarshipSignatureSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("opportunityScholarshipSignatureForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("opportunityScholarshipSignatureField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("opportunityScholarshipSignatureCheckboxField");
        for (const ele of checkboxes) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            const dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/OpportunityScholarshipTaxCreditProgramParticipationForm/SaveOpportunityScholarshipSignature', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// OpportunityScholarshipSummary
class OpportunityScholarshipSummary {
    constructor() {
        this._core = new Core();
        const backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        const submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        const newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        const element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            const planFK = parseInt(element.dataset.planfk);
            const core = this._core;
            Core.showLoader();
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/OpportunityScholarshipTaxCreditProgramParticipationForm/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// NinetyFivePercentLEAProfile
class NinetyFivePercentLEAProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["ninetyFivePercentLEAProfileField"];
        const ninetyFivePercentLEAProfileSaveButton = document.getElementById("ninetyFivePercentLEAProfileSave");
        if (ninetyFivePercentLEAProfileSaveButton !== null)
            ninetyFivePercentLEAProfileSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("ninetyFivePercentLEAProfileForm"));
        let executiveSaveButton = document.getElementById("ExecutiveSave");
        if (executiveSaveButton !== null)
            executiveSaveButton.addEventListener("click", (e) => this.executiveSave("save"));
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentLEAProfileForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("ninetyFivePercentLEAProfileField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
    executiveSave(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentLEAProfileForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("profileICData");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentLEAProfile', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// NinetyFivePercentComplianceDetails
class NinetyFivePercentComplianceDetails {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["ninetyFivePercentComplianceDetailsField"];
        const ninetyFivePercentComplianceDetailsSaveButton = document.getElementById("ninetyFivePercentComplianceDetailsSave");
        if (ninetyFivePercentComplianceDetailsSaveButton !== null)
            ninetyFivePercentComplianceDetailsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentComplianceDetailsForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("ninetyFivePercentComplianceDetailsField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentComplianceDetails', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// NinetyFivePercentSignatureAssurance
class NinetyFivePercentSignatureAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["ninetyFivePercentSignatureAssuranceField", "ninetyFivePercentSignatureAssuranceCheckboxField"];
        const ninetyFivePercentSignatureAssuranceSaveButton = document.getElementById("ninetyFivePercentSignatureAssuranceSave");
        if (ninetyFivePercentSignatureAssuranceSaveButton !== null)
            ninetyFivePercentSignatureAssuranceSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("ninetyFivePercentSignatureAssuranceForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("ninetyFivePercentSignatureAssuranceField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const checkboxes = document.getElementsByClassName("ninetyFivePercentSignatureAssuranceCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            let element = ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            planPropertyPK = parseInt(element.dataset.planpropertypk);
            let val = "";
            if (element.checked)
                val = "on";
            else
                val = "off";
            let saveItem = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: val,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SaveNinetyFivePercentSignatureAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// NinetyFivePercentChecklistSummary
class NinetyFivePercentChecklistSummary {
    constructor() {
        this._core = new Core();
        const backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        const submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        const newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        const element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            const planFK = parseInt(element.dataset.planfk);
            const core = this._core;
            Core.showLoader();
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/NinetyFivePercentAssessmentParticipation/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
// DriversEdProfile
class DriversEdProfile {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["driversEdProfileField", "driversEdProfileRadioField"];
        const formElement = document.getElementById("driversEdProfileForm");
        this._planFK = parseInt(formElement.dataset.planfk);
        this._pageCode = formElement.dataset.pagecode;
        const driversEdProfileSaveButton = document.getElementById("driversEdProfileSave");
        if (driversEdProfileSaveButton !== null)
            driversEdProfileSaveButton.addEventListener("click", (e) => __awaiter(this, void 0, void 0, function* () { return yield this.save("save"); }));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.newValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
        const executiveSaveButton = document.getElementById("executiveSave");
        if (executiveSaveButton !== null)
            executiveSaveButton.addEventListener("click", (e) => __awaiter(this, void 0, void 0, function* () { return yield this.save("save", true); }));
        const refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e) => Core.refreshProfile("driversEdProfileForm"));
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer_1) {
        return __awaiter(this, arguments, void 0, function* (referrer, executiveSave = false) {
            const core = this._core;
            let allSaveElements = [];
            if (referrer !== "save" && this._core.checkSave(this) === false) {
                window.location.href = referrer;
                return false;
            }
            Core.showLoader();
            let refreshPage = "";
            if (referrer === "continue") {
                refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
            }
            if (referrer === "back") {
                refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
            }
            else {
                refreshPage = referrer;
            }
            const formElement = document.getElementById("driversEdProfileForm");
            const planPK = parseInt(formElement.dataset.planfk);
            if (executiveSave) {
                const textInputs = this._core.getTextInputs(planPK, "profileICData");
                allSaveElements = [...textInputs];
            }
            else {
                const textInputs = this._core.getTextInputs(planPK, "driversEdProfileField");
                const radioInputs = this._core.getRadioInputs(planPK, "driversEdProfileRadioField");
                allSaveElements = [...textInputs, ...radioInputs];
            }
            const dataModel = {
                "PlanFK": planPK,
                "DataElements": allSaveElements,
                "RunValidation": true
            };
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };
            if (this._core.checkSave(this)) {
                const response = yield fetch(`/DriversEducation/SaveDriversEdProfile`, settings);
                if (response.ok) {
                    if (referrer === "save") {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", `Page saved successfully. Please wait.`, 'success', 3000, null);
                        core.pageReload(true, planPK);
                    }
                    else {
                        if (refreshPage && refreshPage !== "")
                            window.location.href = refreshPage;
                        else
                            Core.hideLoader();
                    }
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", `Request failed. Returned status of ${response.status}`, 'error', 3000, null);
                }
            }
            else {
                Core.createHTMLAlert("alertMessageDiv", `Nothing to save.`, 'warning', 3000, null);
            }
        });
    }
}
// DriversEdClassroomInstruction
class DriversEdClassroomInstruction {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["driversEdClassroomInstructionField"];
        const driversEdClassroomInstructionSaveButton = document.getElementById("driversEdClassroomInstructionSave");
        if (driversEdClassroomInstructionSaveButton !== null)
            driversEdClassroomInstructionSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("driversEdClassroomInstructionForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("driversEdClassroomInstructionField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/DriversEducation/SaveDriversEdClassroomInstruction', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// DriversEdPracticeDriving
class DriversEdPracticeDriving {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["driversEdPracticeDrivingField"];
        const driversEdPracticeDrivingSaveButton = document.getElementById("driversEdPracticeDrivingSave");
        if (driversEdPracticeDrivingSaveButton !== null)
            driversEdPracticeDrivingSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("driversEdPracticeDrivingForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("driversEdPracticeDrivingField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/DriversEducation/SaveDriversEdPracticeDriving', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// DriversEdInstructors
class DriversEdInstructors {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["driversEdInstructorsField"];
        const driversEdInstructorsSaveButton = document.getElementById("driversEdInstructorsSave");
        if (driversEdInstructorsSaveButton !== null)
            driversEdInstructorsSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("driversEdInstructorsForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("driversEdInstructorsField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/DriversEducation/SaveDriversEdInstructors', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// DriversEdSignatureAndAssurance
class DriversEdSignatureAndAssurance {
    constructor() {
        this._core = new Core();
        this.validationClasses = ["driversEdSignatureAndAssuranceField"];
        const driversEdSignatureAndAssuranceSaveButton = document.getElementById("driversEdSignatureAndAssuranceSave");
        if (driversEdSignatureAndAssuranceSaveButton !== null)
            driversEdSignatureAndAssuranceSaveButton.addEventListener("click", (e) => this.save("save"));
        this._core.leftnav(this);
        const fromSaveElement = document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);
        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }
    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }
    getCore() {
        return this._core;
    }
    save(referrer) {
        const allSaveElements = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";
        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("driversEdSignatureAndAssuranceForm");
        const planPK = parseInt(formElement.dataset.planfk);
        const textInputs = document.getElementsByClassName("driversEdSignatureAndAssuranceField");
        for (const ele of textInputs) {
            let planPropertyPK = 0;
            const element = ele;
            const rowNumber = element.dataset.row;
            const propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if (element.value !== "" || hadValue) {
                const saveItem = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/DriversEducation/SaveDriversEdSignatureAndAssurance', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}
// DriversEdSummary
class DriversEdSummary {
    constructor() {
        this._core = new Core();
        const backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e) => this.back(e));
        const submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e) => this.submit(e));
        this._core.leftnav(this);
    }
    back(e) {
        const newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }
    getCore() {
        return this._core;
    }
    submit(e) {
        const element = e.srcElement;
        if (!element.classList.contains("disabled")) {
            const planFK = parseInt(element.dataset.planfk);
            const core = this._core;
            Core.showLoader();
            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/DriversEducation/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());
                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports'; }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}
/// <reference path="../core.ts" />
class Slideout {
    constructor(slideoutId, callingId) {
        this.id = slideoutId;
        this.callingId = callingId;
    }
    show() {
        let _that = this;
        let element = document.getElementById(this.id);
        Core.trapFocus(element);
        element.classList.add("opened");
        element.setAttribute("aria-hidden", "false");
        let closeElementId = "closeSlideout" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.addEventListener("click", function (e) { _that.hide(); });
        closeElement.addEventListener("keypress", function (e) { if (Core.a11yClick(e)) {
            _that.hide();
        } });
        let h1ElementId = "h1Slideout" + this.id;
        let h1Element = document.getElementById(h1ElementId);
        h1Element.focus();
    }
    hide() {
        let _that = this;
        let element = document.getElementById(this.id);
        element.classList.remove("opened");
        element.setAttribute("aria-hidden", "true");
        let closeElementId = "closeSlideout" + this.id;
        let closeElement = document.getElementById(closeElementId);
        closeElement.removeEventListener("click", function (e) { _that.hide(); });
        closeElement.removeEventListener("keypress", function (e) { if (Core.a11yClick(e)) {
            _that.hide();
        } });
        let callingElement = document.getElementById(this.callingId);
        callingElement.focus();
    }
}
/// <reference path="../core.ts" />
class CustomAccordion {
    constructor(accordionId) {
        let accordions = document.getElementsByClassName("Accordion-trigger");
        for (let accordion of accordions) {
            let accordionButton = accordion;
            if (!accordionButton.hasAttribute("data-hasclick")) {
                accordion.setAttribute("data-hasclick", "true");
                accordion.addEventListener("click", (e) => this.toggle(accordion.id));
            }
        }
    }
    toggle(id) {
        let isOpen;
        let element = document.getElementById(id);
        let contentId = element.getAttribute("aria-controls");
        let contentElement = document.getElementById(contentId);
        if (contentElement.classList.contains("open")) {
            isOpen = true;
        }
        if (isOpen === true) {
            this.hide(element, contentElement);
        }
        else {
            this.show(element, contentElement);
        }
    }
    show(element, contentElement) {
        //let _that = this;
        //let element = document.getElementById(this.id);
        //element.classList.add("opened");
        //let closeElementId = "closeModal" + this.id;
        //let closeElement = document.getElementById(closeElementId);
        //closeElement.addEventListener("click", function (e) { _that.hide() });
        //closeElement.addEventListener("keypress", function (e) { if (_that.Core.a11yClick(e)) { _that.hide(); } });       
        element.classList.add("open");
        element.setAttribute("aria-expanded", "true");
        contentElement.classList.add("open");
        /*
         * 1. Get the id of the button
         * 2. Get the id of the content
         * 3. open the content
         * 4. change the button to have the other icon (add/remove class)
         */
    }
    hide(element, contentElement) {
        //let _that = this;
        //let element = document.getElementById(this.id);
        //element.classList.remove("opened");
        //let closeElementId = "closeModal" + this.id;
        //let closeElement = document.getElementById(closeElementId);
        //closeElement.removeEventListener("click", function (e) { _that.hide() });
        //closeElement.removeEventListener("keypress", function (e) { if (_that.Core.a11yClick(e)) { _that.hide(); } });       
        element.classList.remove("open");
        element.setAttribute("aria-expanded", "false");
        contentElement.classList.remove("open");
        /*
         * 1. Get the id of the button
         * 2. Get the id of the content
         * 3. close the content
         * 4. change the button to have the other icon (add/remove class)
         */
    }
}
/// <reference path="../core.ts" />
class Tab {
    constructor(container) {
        this.container = container;
        this.tabs = [];
        let tabTriggers = container.querySelectorAll("[data-tab-trigger][data-tab-content]");
        if (tabTriggers.length > 0) {
            let triggerIndex = 0;
            for (let trigger of tabTriggers) {
                let triggerElement = trigger;
                let tabContent = document.getElementById(triggerElement.dataset.tabContent);
                if (tabContent != null) {
                    let tabContentElement = tabContent;
                    let tabComponent = new TabComponent(triggerElement, tabContentElement);
                    if (triggerIndex == 0) {
                        tabComponent.Show();
                    }
                    else {
                        tabComponent.Hide();
                    }
                    this.tabs.push(tabComponent);
                    triggerIndex++;
                }
            }
        }
        //Add event listeners to triggers
        for (let tabComponent of this.tabs) {
            tabComponent.tabTrigger.addEventListener("click", () => {
                for (let otherTabComponent of this.tabs) {
                    otherTabComponent.Hide();
                }
                tabComponent.Show();
            });
        }
    }
    //Initialize all Tab in the DOM
    static Init() {
        let tabContainers = document.querySelectorAll("[data-tab-container]");
        for (let tabContainer of tabContainers) {
            let tabContainerElement = tabContainer;
            let tab = new Tab(tabContainerElement);
        }
    }
}
class TabComponent {
    constructor(trigger, content) {
        this.tabTrigger = trigger;
        this.tabContent = content;
        this.isActive = false;
    }
    Hide() {
        this.tabTrigger.classList.remove("active");
        this.tabContent.classList.add("hide");
        this.isActive = false;
    }
    Show() {
        this.tabTrigger.classList.add("active");
        this.tabContent.classList.remove("hide");
        this.isActive = true;
    }
}
