/// <reference path="core.ts" />
/// <reference path="interfaces/common/Interfaces.ts" />
/// <reference path="controls/lazyAccordion.ts" />

// SpecialEducationIUProfileIdentify
class SpecialEducationIUProfileIdentify {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUProfileIdentifyField"];

        let specialEducationIUProfileIdentifySaveButton = document.getElementById("specialEducationIUProfileIdentifySave");
        if (specialEducationIUProfileIdentifySaveButton !== null)
            specialEducationIUProfileIdentifySaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let refreshButton = document.getElementById("resetProfile");
        if (refreshButton !== null)
            refreshButton.addEventListener("click", (e: Event) => Core.refreshProfile("specialEducationIUProfileIdentifyForm"));
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProfileIdentifyForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUProfileIdentifyField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProfileIdentify', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SpecialEducationIUProfileDemo
class SpecialEducationIUProfileDemo {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUProfileDemoField"];

        let specialEducationIUProfileDemoSaveButton = document.getElementById("specialEducationIUProfileDemoSave");
        if (specialEducationIUProfileDemoSaveButton !== null)
            specialEducationIUProfileDemoSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProfileDemoForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUProfileDemoField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProfileDemo', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SpecialEducationIUProfileNarrative
class SpecialEducationIUProfileNarrative {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUProfileNarrativeField"];

        let specialEducationIUProfileNarrativeSaveButton = document.getElementById("specialEducationIUProfileNarrativeSave");
        if (specialEducationIUProfileNarrativeSaveButton !== null)
            specialEducationIUProfileNarrativeSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProfileNarrativeForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUProfileNarrativeField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProfileNarrative', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SpecialEducationIUProgramAdmin
class SpecialEducationIUProgramAdmin {
    validationClasses: string[];
    private specialEducationCore: SpecialEducationIUCoreServicesData;
    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField", "specialEducationIUCoreServicesCheckboxField"];

        this.notApplicable();

        let specialEducationIUProgramAdminSaveButton = document.getElementById("specialEducationIUProgramAdminSave");
        if (specialEducationIUProgramAdminSaveButton !== null)
            specialEducationIUProgramAdminSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true")
            this.doValidationCustom(this.validationClasses);
        this._core.initializeRequiredFields(this.validationClasses);


        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.specialEducationCore = new SpecialEducationIUCoreServicesData();

        const specialEducationIUStateFundsProgramAdminNotApplicable = document.getElementById("specialEducationIUStateFundsProgramAdminNotApplicable");
        if (specialEducationIUStateFundsProgramAdminNotApplicable !== null)
            specialEducationIUStateFundsProgramAdminNotApplicable.addEventListener("change", (e: Event) => this.notApplicable());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProgramAdminForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProgramAdmin', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const specialEducationIUStateFundsProgramAdminNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsProgramAdminNotApplicable");
        if (!specialEducationIUStateFundsProgramAdminNotApplicable.checked) {
            const description = <HTMLInputElement>document.querySelector("#specialEducationIUStateFundsProgramAdminServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }

            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = <HTMLTableRowElement>rowTR;
                const row = ele.dataset.row;

                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }

            let selectCol: boolean = false;
            let nameCol: boolean = false;
            let fteCol: boolean = false;

            let oneRowComplete: boolean = false;
            for (const tableRow of allRows) {
                const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = <HTMLSelectElement>document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);

                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);

                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    } else {
                        selectCol = true;
                    }
                } else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                } else {
                    selectCol = true;
                }

                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                } else {
                    nameCol = true;
                }

                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                } else {
                    fteCol = true;
                }

                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    notApplicable() {
        const specialEducationIUStateFundsProgramAdminNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsProgramAdminNotApplicable");
        if (specialEducationIUStateFundsProgramAdminNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsProgramAdminNotApplicable.checked);
        }
    }

    checkNotApplicable(checked: boolean) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");

        for (const text of allTextElements) {
            const ele = <HTMLInputElement>text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }

        for (const select of allSelectElements) {
            const ele = <HTMLSelectElement>select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }

        const iuSpecialEducationCoreServiceAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            } else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}

// SpecialEducationIUDataCollection
class SpecialEducationIUDataCollection {
    validationClasses: string[];
    private specialEducationCore: SpecialEducationIUCoreServicesData;
    private _core: Core;
    constructor() {
        this._core = new Core();

        this.notApplicable();

        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField"];

        let specialEducationIUDataCollectionSaveButton = document.getElementById("specialEducationIUDataCollectionSave");
        if (specialEducationIUDataCollectionSaveButton !== null)
            specialEducationIUDataCollectionSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const specialEducationIUDataCollectionNotApplicable = document.getElementById("specialEducationIUDataCollectionNotApplicable");
        if (specialEducationIUDataCollectionNotApplicable !== null)
            specialEducationIUDataCollectionNotApplicable.addEventListener("change", (e: Event) => this.notApplicable());

        this.specialEducationCore = new SpecialEducationIUCoreServicesData();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUDataCollectionForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUDataCollection', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const specialEducationIUDataCollectionNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUDataCollectionNotApplicable");
        if (!specialEducationIUDataCollectionNotApplicable.checked) {
            const description = <HTMLInputElement>document.querySelector("#specialEducationIUStateFundsDataCollectionServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }

            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = <HTMLTableRowElement>rowTR;
                const row = ele.dataset.row;

                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }

            let selectCol: boolean = false;
            let nameCol: boolean = false;
            let fteCol: boolean = false;

            let oneRowComplete: boolean = false;
            for (const tableRow of allRows) {
                const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = <HTMLSelectElement>document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);

                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);

                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    } else {
                        selectCol = true;
                    }
                } else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                } else {
                    selectCol = true;
                }

                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                } else {
                    nameCol = true;
                }

                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                } else {
                    fteCol = true;
                }

                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    notApplicable() {
        const specialEducationIUDataCollectionNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUDataCollectionNotApplicable");
        if (specialEducationIUDataCollectionNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUDataCollectionNotApplicable.checked);
        }
    }

    checkNotApplicable(checked: boolean) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");

        for (const text of allTextElements) {
            const ele = <HTMLInputElement>text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }

        for (const select of allSelectElements) {
            const ele = <HTMLSelectElement>select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }

        const iuSpecialEducationCoreServiceAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            } else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}

// SpecialEducationIUProgramEval
class SpecialEducationIUProgramEval {
    validationClasses: string[];
    private specialEducationCore: SpecialEducationIUCoreServicesData;

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField"];

        this.notApplicable();

        let specialEducationIUProgramEvalSaveButton = document.getElementById("specialEducationIUProgramEvalSave");
        if (specialEducationIUProgramEvalSaveButton !== null)
            specialEducationIUProgramEvalSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.specialEducationCore = new SpecialEducationIUCoreServicesData();

        const specialEducationIUStateFundsProgramEvaluationNotApplicable = document.getElementById("specialEducationIUStateFundsProgramEvaluationNotApplicable");
        if (specialEducationIUStateFundsProgramEvaluationNotApplicable !== null)
            specialEducationIUStateFundsProgramEvaluationNotApplicable.addEventListener("change", (e: Event) => this.notApplicable());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProgramEvalForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProgramEval', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const specialEducationIUStateFundsProgramEvaluationNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsProgramEvaluationNotApplicable");
        if (!specialEducationIUStateFundsProgramEvaluationNotApplicable.checked) {
            const description = <HTMLInputElement>document.querySelector("#specialEducationIUStateFundsProgramEvaluationServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }

            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = <HTMLTableRowElement>rowTR;
                const row = ele.dataset.row;

                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }

            let selectCol: boolean = false;
            let nameCol: boolean = false;
            let fteCol: boolean = false;

            let oneRowComplete: boolean = false;
            for (const tableRow of allRows) {
                const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = <HTMLSelectElement>document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);

                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);

                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    } else {
                        selectCol = true;
                    }
                } else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                } else {
                    selectCol = true;
                }

                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                } else {
                    nameCol = true;
                }

                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                } else {
                    fteCol = true;
                }

                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    notApplicable() {
        const specialEducationIUStateFundsProgramEvaluationNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsProgramEvaluationNotApplicable");
        if (specialEducationIUStateFundsProgramEvaluationNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsProgramEvaluationNotApplicable.checked);
        }
    }

    checkNotApplicable(checked: boolean) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");

        for (const text of allTextElements) {
            const ele = <HTMLInputElement>text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }

        for (const select of allSelectElements) {
            const ele = <HTMLSelectElement>select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }

        const iuSpecialEducationCoreServiceAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            } else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}

// SpecialEducationIURelatedServices
class SpecialEducationIURelatedServices {
    validationClasses: string[];
    private specialEducationCore: SpecialEducationIUCoreServicesData;

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField"];

        this.notApplicable();

        let specialEducationIURelatedServicesSaveButton = document.getElementById("specialEducationIURelatedServicesSave");
        if (specialEducationIURelatedServicesSaveButton !== null)
            specialEducationIURelatedServicesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.specialEducationCore = new SpecialEducationIUCoreServicesData();

        const specialEducationIUStateFundsRelatedServicesNotApplicable = document.getElementById("specialEducationIUStateFundsRelatedServicesNotApplicable");
        if (specialEducationIUStateFundsRelatedServicesNotApplicable !== null)
            specialEducationIUStateFundsRelatedServicesNotApplicable.addEventListener("change", (e: Event) => this.notApplicable());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIURelatedServicesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIURelatedServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const specialEducationIUStateFundsRelatedServicesNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsRelatedServicesNotApplicable");
        if (!specialEducationIUStateFundsRelatedServicesNotApplicable.checked) {
            const description = <HTMLInputElement>document.querySelector("#specialEducationIUStateFundsRelatedServicesServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }

            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = <HTMLTableRowElement>rowTR;
                const row = ele.dataset.row;

                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }

            let selectCol: boolean = false;
            let nameCol: boolean = false;
            let fteCol: boolean = false;

            let oneRowComplete: boolean = false;
            for (const tableRow of allRows) {
                const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = <HTMLSelectElement>document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);

                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);

                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    } else {
                        selectCol = true;
                    }
                } else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                } else {
                    selectCol = true;
                }

                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                } else {
                    nameCol = true;
                }

                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                } else {
                    fteCol = true;
                }

                oneRowComplete = fteCol && nameCol && selectCol;
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    notApplicable() {
        const specialEducationIUStateFundsRelatedServicesNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsRelatedServicesNotApplicable");
        if (specialEducationIUStateFundsRelatedServicesNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsRelatedServicesNotApplicable.checked);
        }
    }

    checkNotApplicable(checked: boolean) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");

        for (const text of allTextElements) {
            const ele = <HTMLInputElement>text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }

        for (const select of allSelectElements) {
            const ele = <HTMLSelectElement>select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }

        const iuSpecialEducationCoreServiceAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServiceAddRowNumber !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            } else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }
}

// SpecialEducationIUAccess
class SpecialEducationIUAccess {
    validationClasses: string[];
    private specialEducationCore: SpecialEducationIUCoreServicesData;

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUCoreServicesField", "specialEducationIUCoreServicesSelectField", "specialEducationIUCoreServicesTargetsSelectField"];

        this.notApplicable();
        this.notApplicableTarget();

        let specialEducationIUAccessSaveButton = document.getElementById("specialEducationIUAccessSave");
        if (specialEducationIUAccessSaveButton !== null)
            specialEducationIUAccessSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        this.specialEducationCore = new SpecialEducationIUCoreServicesData();

        const specialEducationIUStateFundsAccessNotApplicable = document.getElementById("specialEducationIUStateFundsAccessNotApplicable");
        if (specialEducationIUStateFundsAccessNotApplicable !== null)
            specialEducationIUStateFundsAccessNotApplicable.addEventListener("change", (e: Event) => this.notApplicable());

        const specialEducationIUStateFundsAccessTargetsNotApplicable = document.getElementById("specialEducationIUStateFundsAccessTargetsNotApplicable");
        if (specialEducationIUStateFundsAccessTargetsNotApplicable !== null)
            specialEducationIUStateFundsAccessTargetsNotApplicable.addEventListener("change", (e: Event) => this.notApplicableTarget());

        let iuSpecialEducationProgramTargetsAddRowButton = document.getElementById("iuSpecialEducationCoreServicesTargetsAddRow");
        if (iuSpecialEducationProgramTargetsAddRowButton !== null)
            iuSpecialEducationProgramTargetsAddRowButton.addEventListener("click", (e: Event) => this.addTargetsProgramRow());

        let iuSpecialEducationCoreServicesTargetsRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesTargetsRowDeleteCancel");
        if (iuSpecialEducationCoreServicesTargetsRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesTargetsRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteTargetsRowCancel());

        let iuSpecialEducationCoreServicesRowTargetsDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesTargetsRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowTargetsDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowTargetsDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteTargetsRowConfirm(e));

        this.setupTargetsOtherDropdowns();
        this.bindTargetsOtherDropdown();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUAccessForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUCoreServicesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let textTargetsInputs = document.getElementsByClassName("specialEducationIUCoreServicesTargetsField");
        for (let ele of textTargetsInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        const targetSelects = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");
        for (const ele of targetSelects) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.getElementsByClassName("specialEducationIUCoreServicesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUAccess', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const specialEducationIUStateFundsAccessNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsAccessNotApplicable");
        if (!specialEducationIUStateFundsAccessNotApplicable.checked) {
            const description = <HTMLInputElement>document.querySelector("#specialEducationIUStateFundsAccessServiceDescription");
            if (description.value === "") {
                description.classList.add("missing-field");
                description.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(description);
                showMessage = true;
                totalErrors++;
            }

            const specialEducationIUStateFundsAccessEstimated = <HTMLInputElement>document.querySelector("#specialEducationIUStateFundsAccessEstimated");
            if (specialEducationIUStateFundsAccessEstimated.value === "") {
                specialEducationIUStateFundsAccessEstimated.classList.add("missing-field");
                specialEducationIUStateFundsAccessEstimated.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(specialEducationIUStateFundsAccessEstimated);
                showMessage = true;
                totalErrors++;
            }

            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = <HTMLTableRowElement>rowTR;
                const row = ele.dataset.row;

                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }

            let oneRowComplete: boolean = false;
            for (const tableRow of allRows) {
                const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = <HTMLSelectElement>document.querySelector(`.specialEducationIUCoreServicesSelectField[data-row='${tableRow}']`);

                let selectCol: boolean = false;
                let nameCol: boolean = false;
                let fteCol: boolean = false;

                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);

                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    } else {
                        selectCol = true;
                    }
                } else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                } else {
                    selectCol = true;
                }

                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                } else {
                    nameCol = true;
                }

                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                } else {
                    if (fteElement.value !== "") {
                        const fteVal = parseFloat(fteElement.value);
                        if (!isNaN(fteVal)) {
                            if (fteVal < 0 || fteVal > 1) {
                                fteElement.classList.add("missing-field");
                                fteElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fteElement);
                                showMessage = true;
                                totalErrors++;
                                fteCol = false;
                            }
                            else
                                fteCol = true;
                        } else {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    } else {
                        if (!oneRowComplete && (nameCol || selectCol)) {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    }
                }

                if (!oneRowComplete)
                    oneRowComplete = fteCol && nameCol && selectCol;
            }
        }

        const specialEducationIUStateFundsAccessTargetsNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsAccessTargetsNotApplicable");
        if (!specialEducationIUStateFundsAccessTargetsNotApplicable.checked) {
            const specialEducationIUStateFundsAccessTargeted = <HTMLInputElement>document.querySelector("#specialEducationIUStateFundsAccessTargeted");
            if (specialEducationIUStateFundsAccessTargeted.value === "") {
                specialEducationIUStateFundsAccessTargeted.classList.add("missing-field");
                specialEducationIUStateFundsAccessTargeted.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(specialEducationIUStateFundsAccessTargeted);
                showMessage = true;
                totalErrors++;
            }

            const tableRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServicesTargets table tr[data-row]");
            const allRows = [];
            for (const rowTR of tableRows) {
                const ele = <HTMLTableRowElement>rowTR;
                const row = ele.dataset.row;

                if (allRows.indexOf(row) === -1)
                    allRows.push(row);
            }

            let oneRowComplete: boolean = false;
            for (const tableRow of allRows) {
                const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesTargetsField[data-row='${tableRow}'][data-field='name']`);
                const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesTargetsField[data-row='${tableRow}'][data-field='other']`);
                const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesTargetsField[data-row='${tableRow}'][data-field='fte']`);
                const selectElement = <HTMLSelectElement>document.querySelector(`.specialEducationIUCoreServicesTargetsSelectField[data-row='${tableRow}']`);

                let selectCol: boolean = false;
                let nameCol: boolean = false;
                let fteCol: boolean = false;

                if (selectElement[selectElement.selectedIndex].textContent === "Other") {
                    if (otherElement.value === "") {
                        otherElement.classList.add("missing-field");
                        otherElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(otherElement);

                        selectElement.classList.add("missing-field");
                        selectElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(selectElement);
                        showMessage = true;
                        totalErrors++;
                    } else {
                        selectCol = true;
                    }
                } else if (selectElement.value === "0" && !oneRowComplete) {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    showMessage = true;
                    totalErrors++;
                    selectCol = false;
                } else {
                    selectCol = true;
                }

                if (nameElement.value === "" && !oneRowComplete) {
                    nameElement.classList.add("missing-field");
                    nameElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(nameElement);
                    showMessage = true;
                    totalErrors++;
                    nameCol = false;
                } else {
                    nameCol = true;
                }

                if (fteElement.value === "" && !oneRowComplete) {
                    fteElement.classList.add("missing-field");
                    fteElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(fteElement);
                    showMessage = true;
                    totalErrors++;
                    fteCol = false;
                } else {
                    if (fteElement.value !== "") {
                        const fteVal = parseFloat(fteElement.value);
                        if (!isNaN(fteVal)) {
                            if (fteVal < 0 || fteVal > 1) {
                                fteElement.classList.add("missing-field");
                                fteElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fteElement);
                                showMessage = true;
                                totalErrors++;
                                fteCol = false;
                            }
                            else
                                fteCol = true;
                        } else {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    } else {
                        if (!oneRowComplete && (nameCol || selectCol)) {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                            fteCol = false;
                        }
                    }                   
                }

                if (!oneRowComplete)
                    oneRowComplete = fteCol && nameCol && selectCol;
            }
        }
        
        let message = <HTMLDivElement>document.getElementById("validationMessage");

        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    notApplicable() {
        const specialEducationIUStateFundsAccessNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsAccessNotApplicable");
        if (specialEducationIUStateFundsAccessNotApplicable !== null) {
            this.checkNotApplicable(specialEducationIUStateFundsAccessNotApplicable.checked);
        }
    }

    checkNotApplicable(checked: boolean) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");

        for (const text of allTextElements) {
            const ele = <HTMLInputElement>text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }

        for (const select of allSelectElements) {
            const ele = <HTMLSelectElement>select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }

        const iuSpecialEducationCoreServiceAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        const iuSpecialEducationCoreServicesAddRow = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationCoreServicesAddRow !== null && iuSpecialEducationCoreServiceAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = true;
                iuSpecialEducationCoreServicesAddRow.disabled = true;
            } else {
                iuSpecialEducationCoreServiceAddRowNumber.disabled = false;
                iuSpecialEducationCoreServicesAddRow.disabled = false;
            }
        }
    }

    notApplicableTarget() {
        const specialEducationIUStateFundsAccessTargetsNotApplicable = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsAccessTargetsNotApplicable");
        if (specialEducationIUStateFundsAccessTargetsNotApplicable !== null)
            this.checkNotApplicableTarget(specialEducationIUStateFundsAccessTargetsNotApplicable.checked);
    }

    checkNotApplicableTarget(checked: boolean) {
        const allTextElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsField");
        const allSelectElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");

        for (const text of allTextElements) {
            const ele = <HTMLInputElement>text;
            if (checked) {
                ele.readOnly = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.readOnly = false;
                this._core.forceElementRequired(ele);
            }
        }

        for (const select of allSelectElements) {
            const ele = <HTMLSelectElement>select;
            if (checked) {
                ele.disabled = true;
                this._core.forceElementOptional(ele);
            } else {
                ele.disabled = false;
                this._core.forceElementRequired(ele);
            }
        }
        
        const iuSpecialEducationCoreServiceTargetsAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceTargetsAddRowNumber");
        const iuSpecialEducationCoreServicesTargetsAddRow = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServicesTargetsAddRow");
        if (iuSpecialEducationCoreServicesTargetsAddRow !== null && iuSpecialEducationCoreServiceTargetsAddRowNumber !== null) {
            if (checked) {
                iuSpecialEducationCoreServiceTargetsAddRowNumber.readOnly = true;
                iuSpecialEducationCoreServicesTargetsAddRow.disabled = true;
            } else {
                iuSpecialEducationCoreServiceTargetsAddRowNumber.readOnly = false;
                iuSpecialEducationCoreServicesTargetsAddRow.disabled = false;
            }
        }
    }

    setupTargetsOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");
        for (let selectElement of selectElements)
            this.targetsDropdownChange(selectElement);
    }

    showTargetsDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteCoreServicesTargetsRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesTargetsRowModal", "#iuSpecialEducationCoreServicesTargetsRowDeleteConfirm", "row", row);
        modal.show();
    }

    bindTargetsDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteTargetsProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showTargetsDeleteRow(e));
    }

    bindTargetsOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesTargetsSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.targetsDropdownChange(e.target));
    }

    targetsDropdownChange(e) {
        let selectElement = <HTMLSelectElement>e;
        let row = selectElement.dataset.row;

        let otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesTargetOtherField[data-row='${row}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        } else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }

    async addTargetsProgramRow() {
        Core.showLoader();
        let numberOfRows = 0;
        let formName: string = "";
        let valueElement = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceTargetsAddRowNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
            formName = valueElement.dataset.formname;
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planForm = document.getElementById(formName);
            let planFK = planForm.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServicesTargets table tbody tr");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/SpecialEducationIUAccessTargetsAddRow/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.innerHTML = output;
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#specialEducationIUStateFundsCoreServicesTargets table tbody");

                    table.append(newTR);

                    that.bindTargetsDeleteProgramRows();
                    that.bindTargetsOtherDropdown();
                }
            }

            valueElement.value = "";
            let plural = "s";
            if (numberOfRows === 1) plural = "";
            const message = `Row${plural} added!`
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            valueElement.value = "";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteTargetsRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesTargetsRowModal", null);
        modal.hide();
    }

    deleteTargetsRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#specialEducationIUStateFundsCoreServicesTargets table tbody tr[data-row='${row}']`);
                rowElement.remove();
                let warningRowElement = document.querySelector(`#specialEducationIUStateFundsCoreServicesTargets table tbody tr[data-row='${row}']`);
                if (warningRowElement != null) {
                    warningRowElement.remove();
                }

                let modal: Modal = new Modal("deleteCoreServicesTargetsRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}

// SpecialEducationIUCoreServicesData
class SpecialEducationIUCoreServicesData {
    constructor() {
        let iuSpecialEducationProgramAddRowButton = document.getElementById("iuSpecialEducationCoreServicesAddRow");
        if (iuSpecialEducationProgramAddRowButton !== null)
            iuSpecialEducationProgramAddRowButton.addEventListener("click", (e: Event) => this.addProgramRow());

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        this.setupOtherDropdowns();
        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
    }

    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }

    showDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }

    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUCoreServicesSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.dropdownChange(e.target));
    }

    dropdownChange(e) {
        let selectElement = <HTMLSelectElement>e;
        let row = selectElement.dataset.row;

        let otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUCoreServicesField.coreservices-select-other[data-row='${row}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        } else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }

    async addProgramRow() {
        Core.showLoader();
        let numberOfRows = 0;
        let method: string = "";
        let formName: string = "";
        let valueElement = <HTMLInputElement>document.getElementById("iuSpecialEducationCoreServiceAddRowNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
            method = valueElement.dataset.method;
            formName = valueElement.dataset.formname;
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planForm = document.getElementById(formName);
            let planFK = planForm.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll("#specialEducationIUStateFundsCoreServices table tbody tr");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/${method}/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.innerHTML = output;
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#specialEducationIUStateFundsCoreServices table tbody");

                    table.append(newTR);

                    that.bindDeleteProgramRows();
                    that.bindOtherDropdown();
                }
            }

            valueElement.value = "";
            let plural = "s";
            if (numberOfRows === 1) plural = "";
            const message = `Row${plural} added!`
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            valueElement.value = "";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#specialEducationIUStateFundsCoreServices table tbody tr[data-row='${row}']`);
                rowElement.remove(); 
                let warningRowElement = document.querySelector(`#specialEducationIUStateFundsCoreServices table tbody tr[data-row='${row}']`);
                if (warningRowElement != null) {
                    warningRowElement.remove();
                }

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }
}

// SpecialEducationIUProgramListing
class SpecialEducationIUProgramListing {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUProgramListingRadioField", "specialEducationIUProgramListingField"];

        this.checkForICP();
        this.forceAllElements();

        let specialEducationIUProgramListingSaveButton = document.getElementById("specialEducationIUProgramListingSave");
        if (specialEducationIUProgramListingSaveButton !== null)
            specialEducationIUProgramListingSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.changeQuestion();
        this.checkForFunding(true);

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        this.checkForICPNo(fromSaveElement.value);
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses, true, true);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let questionMasters = document.getElementsByClassName("icpQuestionMaster");
        for (let questionMaster of questionMasters)
            questionMaster.addEventListener("change", (e: Event) => this.changeQuestion());

        const iuSpecialEducationAccessHasICPAlertMessageClose = document.getElementById("iuSpecialEducationAccessHasICPAlertMessageClose");
        if (iuSpecialEducationAccessHasICPAlertMessageClose !== null)
            iuSpecialEducationAccessHasICPAlertMessageClose.addEventListener("click", (e: Event) => this.closeICPWarning());

        const addNewICPNameButton = document.getElementById("addNewICPName");
        if (addNewICPNameButton !== null)
            addNewICPNameButton.addEventListener("click", (e: Event) => this.addNewICPName());

        const specialEducationIUStateFundsFundingAddICPSelect = document.getElementById("specialEducationIUStateFundsFundingAddICP");
        if (specialEducationIUStateFundsFundingAddICPSelect !== null)
            specialEducationIUStateFundsFundingAddICPSelect.addEventListener("change", (e: Event) => this.changeICPName());

        const specialEducationIUFundingNameDeleteConfirm = document.getElementById("specialEducationIUFundingNameDeleteConfirm");
        if (specialEducationIUFundingNameDeleteConfirm !== null)
            specialEducationIUFundingNameDeleteConfirm.addEventListener("click", (e: Event) => this.deleteConfirm(e));

        const specialEducationIUFundingNameDeleteCancel = document.getElementById("specialEducationIUFundingNameDeleteCancel");
        if (specialEducationIUFundingNameDeleteCancel !== null)
            specialEducationIUFundingNameDeleteCancel.addEventListener("click", (e: Event) => this.deleteCancel());

        this.bindDeleteButtons();

        const specialEducationIUFundingRadios = document.getElementsByClassName("icpChildQuestion");
        for (const specialEducationIUFundingRadio of specialEducationIUFundingRadios)
            specialEducationIUFundingRadio.addEventListener("change", (e: Event) => this.checkForFunding(false))
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allRadioSaveElements = [];
        const allTextSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUProgramListingForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let radios = document.getElementsByClassName("specialEducationIUProgramListingRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allRadioSaveElements.push(saveItem);
                }
            }
        }

        let inputs = document.getElementsByClassName("specialEducationIUProgramListingField");
        for (let ele of inputs) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let dataPointPlanPropertyPK = parseInt(element.dataset.datapointplanpropertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanPropertyExtended = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false,
                    DataPointPlanPropertyPK: dataPointPlanPropertyPK,
                    ButtonRow: 0
                };

                allTextSaveElements.push(saveItem);
            }
        }

        const data = {
            "RadioSaveElements": allRadioSaveElements,
            "TextSaveElements": allTextSaveElements,
            "PlanFK": planPK
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUProgramListing', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allRadioSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(data));
        }
    }

    changeQuestion() {
        let question = <HTMLInputElement>document.querySelector(".icpQuestionMaster[name='specialEducationIUICPHasICPName']:checked");
        let icpQuestionsElement = <HTMLDivElement>document.getElementById("icpQuestions");

        if (question && icpQuestionsElement) {
            if (question.dataset.lookupcode.toLowerCase() === "yes") {
                icpQuestionsElement.classList.remove("hide");

                let allRadios = document.getElementsByClassName("specialEducationIUProgramListingRadioField");
                for (let radio of allRadios) {
                    let ele = <HTMLInputElement>radio;

                    if (!ele.classList.contains("icpQuestionMaster")) {
                        this._core.forceElementRequired(ele);
                    }
                }
            } else {
                icpQuestionsElement.classList.add("hide");

                let allRadios = document.getElementsByClassName("specialEducationIUProgramListingRadioField");
                for (let radio of allRadios) {
                    let ele = <HTMLInputElement>radio;

                    if (!ele.classList.contains("icpQuestionMaster")) {
                        this._core.forceElementOptional(ele);
                    }
                }
            }
        }
    }

    checkForICPNo(saveElementValue: string) {
        const icpQuestionElement = <HTMLInputElement>document.querySelector(".icpQuestionMaster:checked");
        const childrenQuestions = document.querySelectorAll(".icpChildQuestion");
        if (icpQuestionElement) {
            if (icpQuestionElement.dataset.lookupcode === "no") {
                if (saveElementValue === "true") {
                    const warningMessageElement = <HTMLDivElement>document.getElementById("onlyForICPNo");
                    warningMessageElement.classList.remove("hide");
                }

                for (const question of childrenQuestions) {
                    const ele = <HTMLInputElement>question;
                    this._core.forceElementOptional(ele);
                }
            } else {
                for (const question of childrenQuestions) {
                    const ele = <HTMLInputElement>question;
                    this._core.forceElementRequired(ele);
                }
            }
        }
    }

    showICPWarning()
    {
        const allICPAnswers = document.querySelectorAll(".icpChildQuestion:checked");
        let showAlert: boolean = false;
        if (allICPAnswers) {
            for (const answer of allICPAnswers) {
                const ele = <HTMLInputElement>answer;
                if (ele.dataset.lookupcode === "no")
                    showAlert = true;
            }

            if (showAlert) {
                let modal: Modal = new Modal("hasICPALertMessage", null);
                modal.show();
            }
        }
    }

    closeICPWarning() {
        let modal: Modal = new Modal("hasICPALertMessage", null);
        modal.hide();
    }

    addNewICPName() {
        Core.showLoader();

        const planForm = document.getElementById("specialEducationIUProgramListingForm");
        const planFK = planForm.dataset.planfk;
        const icpNameElement = <HTMLSelectElement>document.getElementById("specialEducationIUStateFundsFundingAddICP");
        const icpNameSelectedElement = <HTMLOptionElement>icpNameElement[icpNameElement.selectedIndex];
        let checksOut = true;
        let icpName = "";

        if (icpNameElement.selectedIndex === 0) {
            checksOut = false;
        }
        else if (icpNameSelectedElement.dataset.label === "Other (Name or Describe)") {
            const otherElement = <HTMLInputElement>document.getElementById("specialEducationIUStateFundsFundingAddICPOtherId");
            if (otherElement !== null && otherElement.value !== "")
                icpName = otherElement.value;
            else
                checksOut = false;
        } else {
            icpName = icpNameSelectedElement.dataset.label;
        }

        const that = this;
        if (checksOut) {
            let data = {
                "ICPName": icpName,
                "PlanFK": planFK
            };
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/AddNewICP', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("icpContainer");
                    let newDiv = document.createElement("div");
                    newDiv.innerHTML = xhr.responseText;
                    container.appendChild(newDiv);
                    let idControl = newDiv.querySelector(".Accordion h2 button");
                    let id = idControl.id;
                    new CustomAccordion(id);

                    icpNameElement.selectedIndex = 0;
                    that.bindDeleteButtons();

                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "ICP Name record added!", 'success', 2000, null);
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", `Request failed.  Returned status of '${xhr.status}'`, 'error', 3000, null);
                    Core.hideLoader();
                }
            };
            xhr.send(JSON.stringify(data));
        } else {
            Core.createHTMLAlert("alertMessageDiv", `You must select an ICP name to continue. If you have selected "Other (Name of Describe)", you must enter a value in the Other Name or Description field.`, 'warning', 3000, null);
            Core.hideLoader();
        }
    }

    forceAllElements() {
        const allElements = document.getElementsByClassName("specialEducationIUFundingField");
        for (let element of allElements)
            this._core.forceElementRequired(element as HTMLElement);
    }

    checkForICP() {
        const hasICP = <HTMLInputElement>document.getElementById("hasICP");
        if (hasICP.value === "true") {
            const allElements = document.querySelectorAll("input");

            for (const element of allElements) {
                const ele = <HTMLInputElement>element;

                this._core.forceElementOptional(ele);
                ele.readOnly = true;
                ele.disabled = true;
            }

            const saveButton = <HTMLButtonElement>document.getElementById("specialEducationIUFundingSave");
            saveButton.disabled = true;
        }
    }

    changeICPName() {
        const specialEducationIUStateFundsFundingAddICPSelect = <HTMLSelectElement>document.getElementById("specialEducationIUStateFundsFundingAddICP");
        if (specialEducationIUStateFundsFundingAddICPSelect !== null) {
            const selectedElement = <HTMLOptionElement>specialEducationIUStateFundsFundingAddICPSelect[specialEducationIUStateFundsFundingAddICPSelect.selectedIndex];

            const icpName = <HTMLDivElement>document.getElementById("icpName");
            const icpNameOther = <HTMLDivElement>document.getElementById("icpNameOther");

            if (selectedElement.dataset.label === "Other (Name or Describe)") {
                icpName.classList.remove("medium-8");
                icpName.classList.add("medium-4");
                icpNameOther.classList.remove("hide");
            } else {
                icpName.classList.add("medium-8");
                icpName.classList.remove("medium-4");
                icpNameOther.classList.add("hide");
            }
        }
    }

    bindDeleteButtons() {
        const deleteNameSections = document.getElementsByClassName("deleteNameSection");
        for (const deleteNameSection of deleteNameSections)
            deleteNameSection.addEventListener("click", (e: Event) => this.showDelete(e));
    }

    showDelete(e: Event) {
        const element = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyFK = element.dataset.datapointplanpropertypk;

        const modal: Modal = new Modal("deleteFundingNameSectionModal", null);
        modal.addAttributeToElement("deleteFundingNameSectionModal", "#specialEducationIUFundingNameDeleteConfirm", "datapointplanpropertyfk", dataPointPlanPropertyFK);
        modal.show();
    }

    deleteCancel() {
        const modal: Modal = new Modal("deleteFundingNameSectionModal", null);
        modal.hide();
    }

    async deleteConfirm(e) {
        Core.showLoader();
        const buttonElement = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyFK = buttonElement.dataset.datapointplanpropertyfk;

        const response = await fetch(`/IUSpecialEducation/DeleteNameSection/${dataPointPlanPropertyFK}`, { credentials: 'include' })
        if (response.ok) {
            const resp = await response.json();

            if (resp.success === true) {
                Core.createHTMLAlert("alertMessageDiv", "Name Section removed.", 'success', 2000, null);

                const containerToRemove = <HTMLButtonElement>document.querySelector(`.Accordion-trigger[data-datapointplanpropertyfk='${dataPointPlanPropertyFK}']`);
                containerToRemove.parentElement.parentElement.remove();
            }
            else
                Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the Name Section, please try again.`, 'error', 3000, null);

            Core.hideLoader();
        } else {
            Core.createHTMLAlert("alertMessageDiv", `There was an issue removing the Name Section, please try again.`, 'error', 3000, null);
            Core.hideLoader();
        }

        let modal: Modal = new Modal("deleteFundingNameSectionModal", null);
        modal.hide();
    }

    checkForFunding(fromLoad: boolean) {
        const icpMaster = <HTMLInputElement>document.querySelector(".icpQuestionMaster:checked");
        const icpChildren = document.querySelectorAll(".icpChildQuestion:checked");

        let showFunding: boolean = true;
        let childFunding: boolean = true;

        if (icpMaster) {
            if (icpMaster.dataset.lookupcode === "yes") {
                for (const child of icpChildren) {
                    const ele = <HTMLInputElement>child;

                    if (ele.dataset.lookupcode === "no") {
                        showFunding = false;
                        childFunding = false;
                    }
                }
            } else {
                showFunding = false;
            }
        }

        const fundingForApprovedPrograms = document.getElementById("fundingForApprovedPrograms");
        if (showFunding) {
            fundingForApprovedPrograms.classList.remove("hide");
        } else {
            fundingForApprovedPrograms.classList.add("hide");
        }

        if (!childFunding && !fromLoad) {
            this.showICPWarning();
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            } else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// SpecialEducationIUComponent2
class SpecialEducationIUComponent2 {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUComponent2Field"];

        let specialEducationIUComponent2SaveButton = document.getElementById("specialEducationIUComponent2Save");
        if (specialEducationIUComponent2SaveButton !== null)
            specialEducationIUComponent2SaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
            this.checkFTEValues();
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const specialEducationIUComponent2FTEValues = document.getElementsByClassName("specialEducationIUComponent2FTEValue");
        for (const specialEducationIUComponent2FTEValue of specialEducationIUComponent2FTEValues)
            specialEducationIUComponent2FTEValue.addEventListener("input", (e: Event) => this.calculateTotalFTE());

        this.calculateTotalFTE();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUComponent2Form");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUComponent2Field");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUComponent2', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    checkFTEValues() {
        const allFTEFields = document.getElementsByClassName("specialEducationIUComponent2FTEValue");

        let totalFTEValue = 0;
        for (const fteField of allFTEFields) {
            let element = <HTMLInputElement>fteField;

            if (element.value !== "") {
                const fteValue = parseFloat(element.value)

                if (!isNaN(fteValue))
                    totalFTEValue += fteValue;
            }
        }

        if (totalFTEValue < 5.0) {
            const errorElement = <HTMLInputElement>document.getElementById("specialEducationIUFiveError");
            errorElement.classList.remove("hide");
            errorElement.classList.add("showError")
        }
    }

    calculateTotalFTE() {
        let totalFTE: number = 0;
        const specialEducationIUComponent2FTEValues = document.getElementsByClassName("specialEducationIUComponent2FTEValue");
        for (const specialEducationIUComponent2FTEValue of specialEducationIUComponent2FTEValues) {
            const ele = <HTMLInputElement>specialEducationIUComponent2FTEValue;
            const doubleValue = parseFloat(ele.value);

            if (!isNaN(doubleValue)) {
                totalFTE += doubleValue;
            }
        }

        const totalElement = <HTMLInputElement>document.getElementById("specialEducationIUComponent2TotalFTE");
        totalElement.value = totalFTE.toString();
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!alreadyExists || ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true")) {
                    if (!element.classList.contains("missing-field")) {
                        //For more custom validation, use data-is-valid to specify whether a field is valid/invalid
                        if ("isValid" in htmlElement.dataset) {
                            if (htmlElement.dataset.isValid === "false") {
                                htmlElement.classList.add("missing-field");
                                htmlElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(htmlElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            if (element instanceof HTMLInputElement) {
                                let inputElement = <HTMLInputElement>element;

                                //Only validate once for radio buttons
                                if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                                    if (inputElement.hasAttribute("name")) {
                                        let radioName = inputElement.getAttribute("name");

                                        let radioButtons = document.getElementsByName(radioName);
                                        let alreadyValidated: boolean = false;
                                        let isChecked: boolean = false;

                                        for (var i = 0, length = radioButtons.length; i < length; i++) {
                                            let radioElement = <HTMLInputElement>radioButtons[i];
                                            if (radioElement.classList.contains("missing-field")) {
                                                alreadyValidated = true;
                                            }

                                            if (radioElement.checked) {
                                                isChecked = true;
                                            }
                                        }

                                        if (!alreadyValidated && !isChecked) {
                                            inputElement.classList.add("missing-field");
                                            inputElement.setAttribute("aria-invalid", "true");
                                            Core.createErrorLabelForInput(inputElement);
                                            showMessage = true;
                                            totalErrors++;
                                        }
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                                    if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "checkbox") {
                                    if (!inputElement.checked) {
                                        inputElement.classList.add("missing-field");
                                        inputElement.setAttribute("aria-invalid", "true");
                                        Core.createErrorLabelForInput(inputElement);
                                        showMessage = true;
                                        totalErrors++;
                                    }
                                } else if (inputElement.value === "") {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLSelectElement) {
                                let selectElement = <HTMLSelectElement>element;
                                //if the page select element does not have a place holder it needs custom validation.  do not use this one.
                                if (selectElement.selectedIndex <= 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                                    selectElement.classList.add("missing-field");
                                    selectElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(selectElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if (element instanceof HTMLTextAreaElement) {
                                let textAreaElement = <HTMLTextAreaElement>element;
                                if (textAreaElement.value === "") {
                                    textAreaElement.classList.add("missing-field");
                                    textAreaElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(textAreaElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }

                            } else if (htmlElement.classList.contains("multiSelect")) {
                                let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                                let hasSelection = false;

                                for (let selectBox of multiselectCheckboxes) {
                                    if (selectBox.checked) {
                                        hasSelection = true;
                                        break;
                                    }
                                }

                                if (!hasSelection) {
                                    htmlElement.classList.add("missing-field");
                                    htmlElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(htmlElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }
                }
            }
        }

        const errorsShown = document.querySelectorAll(".initiative-error.showError");
        if (errorsShown.length > 0) {
            totalErrors += errorsShown.length;
            showMessage = true;
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }
}

// SpecialEducationIUEquitable
class SpecialEducationIUEquitable {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUEquitableField", "specialEducationIUEquitableSelectField"];

        let specialEducationIUEquitableSaveButton = document.getElementById("specialEducationIUEquitableSave");
        if (specialEducationIUEquitableSaveButton !== null)
            specialEducationIUEquitableSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let iuSpecialEducationFTEsAllocatedAddRowButton = document.getElementById("iuSpecialEducationFTEsAllocatedAddRow");
        if (iuSpecialEducationFTEsAllocatedAddRowButton !== null)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e: Event) => this.addRow());

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();
        this.bindTotalFTE();

        this.totalFTE();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUEquitableForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUEquitableField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUEquitableSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }


        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUEquitable', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUEquitableSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }

    showDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }

    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUEquitableSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.dropdownChange(e.target));
    }

    dropdownChange(e) {
        let selectElement = <HTMLSelectElement>e;
        let row = selectElement.dataset.row;
        let otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUEquitableField.coreservices-select-other[data-row='${row}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        } else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }

    async addRow() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("specialEducationIUEquitableForm");
        let valueElement = <HTMLInputElement>document.getElementById("iuSpecialEducationFTEsAllocatedAddRowNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll("#specialEducationIUEquitablePosition table tbody tr");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/AddEquitableParticipationRow/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.innerHTML = output;
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#specialEducationIUEquitablePosition table tbody");

                    table.append(newTR);

                    that.bindDeleteProgramRows();
                    that.bindOtherDropdown();
                    that.bindTotalFTE();
                }
            }

            valueElement.value = "";
            let plural = "s";
            if (numberOfRows === 1) plural = "";
            const message = `Row${plural} added!`
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            valueElement.value = "";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#specialEducationIUEquitablePosition table tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindTotalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields)
            specialEducationIUProgramFTEField.addEventListener("input", (e: Event) => this.totalFTE());
    }

    totalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        let totalFTE: number = 0;
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields) {
            const ele = <HTMLInputElement>specialEducationIUProgramFTEField;
            const value = parseFloat(ele.value);

            if (!isNaN(value))
                totalFTE += value;
        }

        const specialEducationIUComponent3EquitableFTETotal = <HTMLInputElement>document.getElementById("specialEducationIUComponent3EquitableFTETotal");
        specialEducationIUComponent3EquitableFTETotal.value = totalFTE.toString();
    }
}

// SpecialEducationIUFlowThrough
class SpecialEducationIUFlowThrough {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUFlowThroughField", "specialEducationIUFlowThroughSelectField"];

        let specialEducationIUFlowThroughSaveButton = document.getElementById("specialEducationIUFlowThroughSave");
        if (specialEducationIUFlowThroughSaveButton !== null)
            specialEducationIUFlowThroughSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let iuSpecialEducationFTEsAllocatedAddRowButton = document.getElementById("iuSpecialEducationFTEsAllocatedAddRow");
        if (iuSpecialEducationFTEsAllocatedAddRowButton !== null)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e: Event) => this.addRow());

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();

        this.bindTotalFTE();

        this.totalFTE();
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUFlowThroughForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUFlowThroughField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUFlowThroughSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUFlowThrough', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUFlowThroughSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }

    showDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }

    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteProgramRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUFlowThroughSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.dropdownChange(e.target));
    }

    dropdownChange(e) {
        let selectElement = <HTMLSelectElement>e;
        let row = selectElement.dataset.row;
        let otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUFlowThroughField.coreservices-select-other[data-row='${row}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        } else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }

    async addRow() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("specialEducationIUFlowThroughForm");
        let valueElement = <HTMLInputElement>document.getElementById("iuSpecialEducationFTEsAllocatedAddRowNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll("#specialEducationIUFlowThroughPosition table tbody tr");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/AddFlowThroughRow/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.innerHTML = output;
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#specialEducationIUFlowThroughPosition table tbody");

                    table.append(newTR);

                    that.bindDeleteProgramRows();
                    that.bindOtherDropdown();
                    that.bindTotalFTE();
                }
            }

            valueElement.value = "";
            let plural = "s";
            if (numberOfRows === 1) plural = "";
            const message = `Row${plural} added!`
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            valueElement.value = "";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.specialEducationIUProgramField[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#specialEducationIUFlowThroughPosition table tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindTotalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields)
            specialEducationIUProgramFTEField.addEventListener("input", (e: Event) => this.totalFTE());
    }

    totalFTE() {
        const specialEducationIUProgramFTEFields = document.getElementsByClassName("specialEducationIUProgramFTEField");
        let totalFTE: number = 0;
        for (const specialEducationIUProgramFTEField of specialEducationIUProgramFTEFields) {
            const ele = <HTMLInputElement>specialEducationIUProgramFTEField;
            const value = parseFloat(ele.value);

            if (!isNaN(value))
                totalFTE += value;
        }

        const specialEducationIUComponent3EquitableFTETotal = <HTMLInputElement>document.getElementById("specialEducationIUComponent3FlowServicesFTETotal");
        specialEducationIUComponent3EquitableFTETotal.value = totalFTE.toString();
    }
}

// SpecialEducationIUProgramProfiles
class SpecialEducationIUProgramProfiles {

    validationClasses: string[];
    planFK: number;
    templateFK: number;
    deleteSupportConfirmModal: Modal;
    deleteFTEConfirmModal: Modal;
    private typingTimer: number;
    private fteHashes: Object;
    private allowExceedsFTE: boolean;

    private _core: Core;
    private static _staticCore: Core;

    constructor() {
        let that = this;
        this._core = new Core();
        SpecialEducationIUProgramProfiles._staticCore = new Core();

        let form = document.getElementById("specialEducationEducationProgramCaseloadIUFTEForm");
        if (form != null) {
            that.planFK = parseInt(form.dataset.planfk);
            that.templateFK = parseInt(form.dataset.templatefk);
        }

        new SpecialEducationIUProgramProfilesLazyAccordion();
        SpecialEducationIUProgramProfiles.getValidProgramCount();

        const allowExceedsFTE = document.getElementById("allowExceedsFTE") as HTMLInputElement;
        if (allowExceedsFTE != null && allowExceedsFTE.value === "True")
            that.allowExceedsFTE = true;
        else
            that.allowExceedsFTE = false;

        that.deleteSupportConfirmModal = new Modal("deleteSupportConfirmModal", null);
        that.deleteFTEConfirmModal = new Modal("deleteFTEConfirmModal", null);

        this.validationClasses = [
            "fteID",
            "additionalJustification",
            "description",
            "buildingType",
            "supportTypeGroup",
            "supportType",
            "supportLevel",
            "caseLoad",
            "ageRangeFrom",
            "ageRangeEnd",
            "ageRangeJustification",
            "operatedBy",
            "fund",
            "classroomLocationFTE",
            "fullTime",
            "supportIdentifyClassroomOther"
        ];

        let specialEducationEducationProgramCaseloadFTEFormSaveButton = document.getElementById("iUSpecialEducationEducationProgramCaseloadFTESave");
        if (specialEducationEducationProgramCaseloadFTEFormSaveButton !== null) {
            specialEducationEducationProgramCaseloadFTEFormSaveButton.addEventListener("click", (e: Event) => this.save("save"));
        }

        this._core.leftnav(this);
        this._core.tabLinkSave(this);
        this._core.initializeRequiredFields(this.validationClasses);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customValidation();
        }

        that.initializeFTEHashes();
        that.handleClearDisabled();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        //Event Listener for creating new FTE
        let createNewFTE = document.getElementById("createNewFTE");
        if (createNewFTE != null) {
            createNewFTE.addEventListener("click", () => {
                Core.showLoader();
                that.createNewFTE()
                    .then((response) => {
                        Core.hideLoader();
                        that._core.initializeRequiredFields(that.validationClasses);
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        //Event listener for deleting a support
        let deleteSupportConfirmButton = document.getElementById("deleteSupportConfirm") as HTMLButtonElement;
        if (deleteSupportConfirmButton != null) {

            deleteSupportConfirmButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
                if ("supportPk" in deleteSupportConfirmButton.dataset && "ftePk" in deleteSupportConfirmButton.dataset) {
                    Core.showLoader();
                    that.deleteSupport(parseInt(deleteSupportConfirmButton.dataset.supportPk))
                        .then((response) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                            that.handleFTESaveDisabled(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                            that.checkSupportCount(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                            that.refreshFTEPercentage(parseInt(deleteSupportConfirmButton.dataset.ftePk));
                        })
                        .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this support. Please try again later.", 'error', 3000, null);
                }
            });
        }

        //Event listener for confirming deletion of FTE
        let deleteFTEConfirmButton = document.getElementById("deleteFTEConfirm") as HTMLButtonElement;
        if (deleteFTEConfirmButton != null) {

            deleteFTEConfirmButton.addEventListener("click", () => {

                if ("ftePk" in deleteFTEConfirmButton.dataset) {
                    that.deleteFTE(parseInt(deleteFTEConfirmButton.dataset.ftePk));
                }
                else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE. Please try again later.", 'error', 3000, null);
                }
            });
        }

        //Event listener for cancelling the delete of a support
        let deleteSupportCancelButton = document.getElementById("deleteSupportCancel") as HTMLButtonElement;
        if (deleteSupportCancelButton != null) {
            deleteSupportCancelButton.addEventListener("click", () => {
                that.deleteSupportConfirmModal.hide();
            });
        }

        //Event listener for cancelling the delete of an FTE
        let deleteFTECancelButton = document.getElementById("deleteFTECancel") as HTMLButtonElement;
        if (deleteFTECancelButton != null) {
            deleteFTECancelButton.addEventListener("click", () => {
                that.deleteFTEConfirmModal.hide();
            });
        }

        //Event listener for clicking the search button
        let searchButton = <HTMLButtonElement>document.getElementById("searchButton");
        if (searchButton !== null) {
            //1. Save page (will only save FTE that have changed)
            //2. Run Search
            searchButton.addEventListener("click", () => {

                Core.showLoader();
                that.promiseSave()
                    .then(() => {
                        return that.search();
                    })
                    .then(() => {
                        that.initializeFTEHashes();
                        that._core.initializeRequiredFields(that.validationClasses);
                        //reinitialize accordions
                        new SpecialEducationIUProgramProfilesLazyAccordion();
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });

            });
        }

        //Event listener for searching when hitting enter
        let fteIDSearch = <HTMLInputElement>document.getElementById("searchFTEId");
        if (fteIDSearch !== null) {
            fteIDSearch.addEventListener("keypress", (e: KeyboardEvent) => {
                if (e.keyCode === 13) {
                    Core.showLoader();
                    that.promiseSave()
                        .then(() => {
                            return that.search();
                        })
                        .then(() => {
                            that._core.initializeRequiredFields(that.validationClasses);
                            that.initializeFTEHashes();

                            //reinitialize accordions
                            new SpecialEducationIUProgramProfilesLazyAccordion();
                            Core.hideLoader();
                        })
                        .catch((error) => {
                            Core.hideLoader();
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                }
            });

            fteIDSearch.addEventListener("input", () => {
                that.handleClearDisabled();
            });
        }

        //Clears the search fields
        let clearButton = document.getElementById("searchClear");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                that.clearSearch();
            });
        }

        //When changing building filter, determine whether clear button should be enabled
        let buildingSearch = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportTypeSearch = document.getElementById("searchSupportType") as HTMLSelectElement;
        if (buildingSearch != null) {
            buildingSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }

        //When changing support type filter, determine whether clear button should be enabled
        if (supportTypeSearch != null) {
            supportTypeSearch.addEventListener("change", () => {
                that.handleClearDisabled();
            });
        }

        //When user has searched, focus on search result message
        let searchResultsMessage = document.getElementById("searchResultsMessage");
        if (searchResultsMessage != null) {
            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
            searchResultsMessage.focus();
        }

        //Event listener for exporting all data to excel
        let exportToExcelButton = document.getElementById("exportExcelFTE");
        if (exportToExcelButton != null) {
            exportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        //Event listener for exporting data to excel based on search criteria
        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE");
        if (exportToExcelFilteredButton != null) {
            exportToExcelFilteredButton.addEventListener("click", () => {
                Core.showLoader();
                that.exportToExcel(true)
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        document.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;

            //Adding a support
            if (target.classList.contains("addSupportButton") && "ftePk" in target.dataset) {
                Core.showLoader();
                that.createNewSupport(parseInt(target.dataset.ftePk))
                    .then((response) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                        that.checkSupportCount(parseInt(target.dataset.ftePk));
                        that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk));
                    })
                    .then(() => {
                        that._core.initializeRequiredFields(that.validationClasses);
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            }

            //Deleting a support
            else if (target.classList.contains("deleteSupport") && "supportPk" in target.dataset && "ftePk" in target.dataset) {
                that.deleteSupportConfirmModal.show();
                deleteSupportConfirmButton.dataset.supportPk = target.dataset.supportPk;
                deleteSupportConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteSupportConfirmModal.callingId = target.id;
            }

            //Deleting an FTE
            else if (target.classList.contains("deleteFTE") && "ftePk" in target.dataset) {
                that.deleteFTEConfirmModal.show();
                deleteFTEConfirmButton.dataset.ftePk = target.dataset.ftePk;
                that.deleteFTEConfirmModal.callingId = target.id;
            }

            //Saving an FTE
            else if (target.classList.contains("saveFTEButton") && "ftePk" in target.dataset) {

                Core.showLoader();
                that.promiseSave(parseInt(target.dataset.ftePk))
                    .then((response) => {

                        //Recalculate hash and handle disabling the save button for the FTE
                        SpecialEducationIUProgramProfiles.clearValidationMessage();
                        that.fteHashes[parseInt(target.dataset.ftePk)] = that.calculateFTEHash(parseInt(target.dataset.ftePk));
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                        SpecialEducationIUProgramProfiles.fteValidation(parseInt(target.dataset.ftePk));
                        SpecialEducationIUProgramProfiles.getValidProgramCount();
                        SpecialEducationIUProgramProfiles.fullPageValidation();

                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", response, 'success', 3000, null);
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });

            }
        });

        document.addEventListener("change", (e) => {
            let target = e.target as HTMLElement;

            //Handle Support Type Group changing
            if (target.classList.contains("supportTypeGroup") && "supportPk" in target.dataset && "ftePk" in target.dataset) {

                //1. Refresh the support type list
                //2. Refresh the support level list (1 needs to happen first)
                //3. Refresh FTE percent (1 and 2 need to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.refreshSupportTypeSubList(parseInt(target.dataset.supportPk))
                    .then(() => {
                        return that.refreshSupportLevels(parseInt(target.dataset.supportPk));
                    })
                    .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                        return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                    })
                    .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                    .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });

            }

            //Handle Support Type changing
            else if (target.classList.contains("supportType") && "supportPk" in target.dataset) {

                //1. Refresh the support level list
                //2. Refresh FTE percent (1 needs to happen first)
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.refreshSupportLevels(parseInt(target.dataset.supportPk))
                    .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                        return that.refreshFTEPercentage(parseInt(target.dataset.ftePk));
                    })
                    .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                    .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });

            }

            //Handle Support Level changing
            else if (target.classList.contains("supportLevel") && "supportPk" in target.dataset) {

                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                    .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                    .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });

            }

            //Handle Classroom Location for FTE changing
            else if (target.classList.contains("classroomLocationFTE") && "ftePk" in target.dataset) {
                that.adjustSupportClassroomLocation(parseInt(target.dataset.ftePk))
                    .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    });
            }

            //Handle Full-time/Part-time changing
            else if (target.classList.contains("fullTime") && "ftePk" in target.dataset) {

                //1. Refresh FTE percent
                that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                    .then(() => {
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    })
                    .catch((error) => {
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                    });

            }

            //Handle Classroom Location for Support changing
            else if (target.classList.contains("classroomLocationSupport") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk), parseInt(target.dataset.ftePk))
                    .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    });
            }

            //Handle the change of the OperatedBy (Identify Classroom) field.
            else if (target.classList.contains("operatedBy") && "supportPk" in target.dataset) {
                SpecialEducationIUProgramProfiles.changeOperatedByIdentifyClassroom(target);
            }

            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && !(target instanceof HTMLInputElement) && !(target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            }

        });

        document.addEventListener("input", (e) => {
            //On a slight delay so that ajax call isn't made until finished typing
            let target = e.target as HTMLElement;

            //Handle Caseload changing
            if (target.classList.contains("caseLoad") && "supportPk" in target.dataset) {

                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk))

                if (typeof (that.typingTimer) !== 'undefined') {
                    clearTimeout(that.typingTimer);
                }
                that.typingTimer = setTimeout(() => {
                    that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), true);

                    that.refreshFTEPercentage(parseInt(target.dataset.ftePk))
                        .then(() => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                        })
                        .catch((error) => {
                            that.toggleFTESupportLoader(parseInt(target.dataset.supportPk), false);
                            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                        });
                }, 500);
            }

            //Handle age Range From for Support changing
            else if (target.classList.contains("ageRangeFrom") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk), parseInt(target.dataset.ftePk))
                    .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    });
            }

            //Handle age Range End for Support changing
            else if (target.classList.contains("ageRangeEnd") && "supportPk" in target.dataset) {
                that.checkAgeRangeJustification(parseInt(target.dataset.supportPk), parseInt(target.dataset.ftePk))
                    .then(() => {
                        that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
                    });
            }

            //If any other field within the FTE which is hashable (i.e. indicates a change) is changed, handle whether the save button is disabled/enabled
            else if ("hashable" in target.dataset && "ftePk" in target.dataset && (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement)) {
                that.handleFTESaveDisabled(parseInt(target.dataset.ftePk));
            }
        });

        //This is called when clicking the back/forward buttons in the browser. This is using the History API
        window.addEventListener('popstate', (e) => {
            let query = e.state;

            let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
            let building = document.getElementById("searchBuilding") as HTMLSelectElement;
            let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

            if (fteID != null) {
                if (query != null && query.FTEIdentifierName != null) {
                    fteID.value = query.FTEIdentifierName;
                } else {
                    fteID.value = "";
                }
            }

            if (building != null) {
                if (query != null && query.FRCPPInstitutionFK != null) {
                    building.value = query.FRCPPInstitutionFK.toString();
                } else {
                    building.selectedIndex = 0;
                }
            }

            if (supportType != null) {
                if (query != null && query.SupportTypeCodePK != null) {
                    supportType.value = query.SupportTypeCodePK.toString();
                } else {
                    supportType.selectedIndex = 0;
                }
            }
            that.handleClearDisabled();
            Core.showLoader();
            that.promiseSave()
                .then(() => {
                    return that.search(true);
                })
                .then(() => {
                    that.initializeFTEHashes();

                    //reinitialize accordions
                    new SpecialEducationIUProgramProfilesLazyAccordion();
                    Core.hideLoader();
                })
                .catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
        });
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }
    
    //Save method that returns a promise. If ftePK is passed, only the specified FTE will be saved. Otherwise, all FTE on page will be saved (if there are changes)
    promiseSave(ftePK: number = null, forceSave: boolean = false) {
        let that = this;
        let core = this._core;

        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData: IEducationProgramIUSave[] = [];

            let fteToSave: NodeListOf<HTMLElement>;
            if (ftePK !== null) {
                fteToSave = document.querySelectorAll(`.fteContainer[data-fte-pk='${ftePK}']`) as NodeListOf<HTMLElement>;
            } else {
                //Get all the FTE on the page
                fteToSave = document.querySelectorAll(".fteContainer") as NodeListOf<HTMLElement>;
            }

            for (let fte of fteToSave) {
                let fteSupportsData: IEducationProgramFTEIUSupportSave[] = [];

                if ("ftePk" in fte.dataset && that.fteHasChanged(parseInt(fte.dataset.ftePk))) {
                    shouldSave = true;
                    let ftePK = fte.dataset.ftePk;

                    //Get FTEId
                    let educationProgramFTEId = null;
                    let fteID = fte.querySelector(`.fteID[data-fte-pk='${ftePK}']`) as HTMLInputElement;
                    if (fteID != null && fteID.value != "") {
                        educationProgramFTEId = fteID.value;
                    }

                    let classroomLocationFTE = null;
                    const classroomLocationFTEElement = fte.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
                    if (fteID != null && fteID.value != "") {
                        classroomLocationFTE = classroomLocationFTEElement.value;
                    }

                    let fullTime = null;
                    const fullTimeElement = fte.querySelector(`.fullTime[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
                    if (fteID != null && fteID.value != "") {
                        fullTime = fullTimeElement.value;
                    }
                    
                    //Get supports
                    const supports = [];
                    const iuSupports = [];
                    const supportsForFTE = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
                    for (let support of supportsForFTE) {
                        let supportElement = <HTMLElement>support;

                        if ("supportPk" in supportElement.dataset) {

                            //FTEDescription
                            let FTEDescriptionText = "";
                            let description = supportElement.querySelector(`.description[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLTextAreaElement;
                            if (description != null && description.value != "") {
                                FTEDescriptionText = description.value;
                            }

                            //supportTypeGroup
                            let supportTypeGroupCodeFK = null;
                            let supportTypeGroup = supportElement.querySelector(`.supportTypeGroup[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportTypeGroup != null && supportTypeGroup.value != "") {
                                supportTypeGroupCodeFK = parseInt(supportTypeGroup.value);
                            }
                            //supportType
                            let supportTypeCodeFK = null;
                            let supportType = supportElement.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportType != null && supportType.value != "") {
                                supportTypeCodeFK = parseInt(supportType.value);
                            }
                            //supportLevel
                            let supportLevelCodeFK = null;
                            let supportLevel = supportElement.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (supportLevel != null && supportLevel.value != "") {
                                supportLevelCodeFK = parseInt(supportLevel.value);
                            }
                            //caseLoad
                            let caseLoadCount = null;
                            let caseLoad = supportElement.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (caseLoad != null && caseLoad.value != "") {
                                caseLoadCount = parseInt(caseLoad.value);
                            }
                            //buildingType
                            let typeOfBuildingLookupCodeFK = null;
                            let buildingType = supportElement.querySelector(`.buildingType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (buildingType != null && buildingType.value != "") {
                                typeOfBuildingLookupCodeFK = parseInt(buildingType.value);
                            }
                            //ageRangeFrom
                            let ageRangeFromNbr = null;
                            let ageRangeFrom = supportElement.querySelector(`.ageRangeFrom[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeFrom != null && ageRangeFrom.value != "") {
                                ageRangeFromNbr = parseInt(ageRangeFrom.value);
                            }
                            //ageRangeEnd
                            let ageRangeToNbr = null;
                            let ageRangeEnd = supportElement.querySelector(`.ageRangeEnd[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeEnd != null && ageRangeEnd.value != "") {
                                ageRangeToNbr = parseInt(ageRangeEnd.value);
                            }
                            //ageRangeJustification
                            let ageRangeJustificationText = "";
                            let ageRangeJustification = supportElement.querySelector(`.ageRangeJustification[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (ageRangeJustification != null && ageRangeJustification.value != "") {
                                ageRangeJustificationText = ageRangeJustification.value;
                            }
                            //OperatedByLookup
                            let operatedByLookupCodeFK = null;
                            let operatedBy = supportElement.querySelector(`.operatedBy[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (operatedBy != null && operatedBy.value != "") {
                                operatedByLookupCodeFK = parseInt(operatedBy.value);
                            }
                            //FundLookup
                            let fundLookupCodeFk = null;
                            let fund = supportElement.querySelector(`.fund[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                            if (fund != null && fund.value != "") {
                                fundLookupCodeFk = parseInt(fund.value);
                            }
                            //LEAName
                            let leaName = null;
                            let leaNameEle = supportElement.querySelector(`.supportLEAName[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (leaNameEle != null && leaNameEle.value != "") {
                                leaName = leaNameEle.value;
                            }
                            //SchoolBuildingName
                            let schoolBuildingName = null;
                            let scholBuildingNameEle = supportElement.querySelector(`.supportSchoolBuildingName[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (scholBuildingNameEle != null && scholBuildingNameEle.value != "") {
                                schoolBuildingName = scholBuildingNameEle.value;
                            }
                            //ClassroomLocation
                            let classroomLocation = null;
                            let classroomLocationEle = supportElement.querySelector(`.supportClassroomLocation[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (classroomLocationEle != null && classroomLocationEle.value != "") {
                                classroomLocation = classroomLocationEle.value;
                            }

                            let identifyClassroomOther = null;
                            const identifyClassroomOtherEle = supportElement.querySelector(`.supportIdentifyClassroomOther[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;
                            if (identifyClassroomOtherEle != null && identifyClassroomOtherEle.value != "") {
                                identifyClassroomOther = identifyClassroomOtherEle.value;
                            }

                            let fteSupportSaveData: IEducationProgramFTEIUSupportSave = {
                                AgeRangeFromNbr: ageRangeFromNbr,
                                AgeRangeJustification: ageRangeJustificationText,
                                AgeRangeToNbr: ageRangeToNbr,
                                CaseLoadCount: caseLoadCount,
                                ClassroomLocationLookupCodeFK: null,
                                ClassroomLookupCodeFK: null,
                                EducationProgramFTEFK: parseInt(ftePK),
                                EducationProgramFTESupportPK: parseInt(supportElement.dataset.supportPk),
                                FRCPPInstitutionFK: null,
                                SupportLevelCodeFK: supportLevelCodeFK,
                                SupportTypeCodeFK: supportTypeCodeFK,
                                UISelectedSupportTypeGroupCodeFK: supportTypeGroupCodeFK,
                            }

                            let iuSupportsSaveData: IEducationProgramFTESupportIUSupportSave = {
                                EducationProgramFTESupportIUPK: parseInt(supportElement.dataset.iusupportPk),
                                EducationProgramFTESupportFK: parseInt(supportElement.dataset.supportPk),
                                FTEDescription: FTEDescriptionText,
                                TypeOfBuildingLookupCodeFK: typeOfBuildingLookupCodeFK,
                                OperatedByLookupCodeFK: operatedByLookupCodeFK,
                                FundLookupCodeFk: fundLookupCodeFk,
                                LEAName: leaName,
                                SchoolBuildingName: schoolBuildingName,
                                ClassroomLocation: classroomLocation,
                                IdentifyClassroomOther: identifyClassroomOther
                            }

                            supports.push(fteSupportSaveData);
                            iuSupports.push(iuSupportsSaveData)
                        }
                    }

                    const fteSaveData: IEducationProgramFTEIUSave = {
                        ClassroomLocationLookupCodeFK: classroomLocationFTE,
                        FullTimeLookupCodeFK: fullTime,
                        FTEIdentifierName: educationProgramFTEId,
                        EducationProgramFTEPK: parseInt(ftePK),
                        PlanFK: that.planFK
                    }

                    const thisFTESaveData: IEducationProgramIUSave = {
                        FTE: fteSaveData,
                        Supports: supports,
                        IUSupports : iuSupports
                    };

                    saveData.push(thisFTESaveData);
                }
            }

            if (shouldSave || forceSave) {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/IUSpecialEducation/SaveEducationProgramIUFTE', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        if (ftePK != null) {
                            const json = JSON.parse(xhr.responseText);
                            const validData = json.validData;

                            const elementButton = <HTMLButtonElement>document.querySelector(`.lazyAccordionTrigger[data-educationprogramftefk='${ftePK}']`);
                            elementButton.dataset.validdata = validData;

                            SpecialEducationIUProgramProfiles.getValidProgramCount();
                            SpecialEducationIUProgramProfiles.fteValidation(ftePK);
                            SpecialEducationIUProgramProfiles.fullPageValidation();

                            SpecialEducationIUProgramProfiles.planPageCompleteCheck();

                            resolve("Successfully saved FTE");
                        } else {
                            resolve("Successfully saved");
                        }
                    } else {
                        reject("There was an unexpected error saving");
                    }
                };
                xhr.send(JSON.stringify({
                    "Data": saveData,
                    "PlanPropertyData": [],
                    "PlanFK": that.planFK
                }));
            } else {
                resolve("Nothing To Save");
            }
        });
    }

    //Top level save method used by back/continue buttons, clicking on link, etc. Calls the promiseSave method and controls redirect.
    save(referrer, ftePK: number = null) {
        let that = this;
        let core = that._core;

        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }

        let forceSave = false;
        if (referrer === "save") {
            forceSave = true;
        }

        if (referrer !== "save" && this._core.checkSave(this) === true) {
            refreshPage = referrer;
            forceSave = true;
        }

        that.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();

            if (referrer === "save") {
                //Reset query so that all results show
                window.location.href = window.location.href.split('?')[0] + '?fromSave=true';
            } else if (refreshPage && refreshPage !== "") {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
        });
    }

    //Creates a new FTE record in database and prepends partial view to DOM
    createNewFTE() {
        let that = this;

        return new Promise((resolve, reject) => {
            let newFTEID = document.getElementById("createNewFTEId") as HTMLInputElement;
            let newClassroomLocation = document.getElementById("createNewClassroomLocation") as HTMLSelectElement;
            let newFullTime = document.getElementById("createNewFullTime") as HTMLSelectElement;

            let data: IEducationProgramFTESave = {
                EducationProgramFTEPK: 0,
                ClassroomLocationLookupCodeFK: parseInt(newClassroomLocation.value),
                FullTimeLookupCodeFK: parseInt(newFullTime.value),
                FTEIdentifierName: newFTEID.value,
                PlanFK: that.planFK
            };

            let saveData = {
                FTE: data,
                AllowExceedsFTE: that.allowExceedsFTE
            }

            if (newFTEID != null && newFTEID.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/IUSpecialEducation/CreateNewIUFTE', true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {

                        let fteList = document.getElementById("fteContainerInner");
                        if (fteList != null) {
                            $(fteList).prepend(xhr.responseText);
                            newFTEID.value = "";
                            newClassroomLocation.selectedIndex = 0;
                            newFullTime.selectedIndex = 0;

                            let newFTEAccordion = fteList.querySelector(".Accordion-trigger") as HTMLButtonElement;
                            if (newFTEAccordion != null)
                                new SpecialEducationIUProgramProfilesLazyAccordion(newFTEAccordion.id);
                            else
                                new SpecialEducationIUProgramProfilesLazyAccordion();

                            resolve("Successfully created new FTE");
                        }
                    } else if (xhr.status === 409) {
                        reject("This FTE ID already exists, please use a unique name.");
                    } else {
                        reject("There was an unexpected error creating new FTE");
                    }
                };
                xhr.send(JSON.stringify(data));
            } else {
                reject("All fields must have a value to create a new FTE");
            }
        });
    }

    //Creates a new support record in database and appends partial view to DOM
    createNewSupport(ftePK: number) {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/CreateNewIUSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {

                    let supportList = document.querySelector(`.supportsContainer[data-fte-pk='${ftePK}']`);
                    if (supportList != null) {
                        $(supportList).append(xhr.responseText);
                        resolve("Successfully added support");
                    } else {
                        reject("There was an unexpected error adding the support");
                    }
                } else {
                    reject("There was an unexpected error creating new support");
                }
            };
            xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK);
        });
    }

    //Soft deletes support from database
    deleteSupport(supportPK: number) {
        let that = this;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/DeleteIUSupport', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {

                    let supportContainer = document.querySelector(`.supportRow[data-support-pk='${supportPK}']`);
                    if (supportContainer != null) {

                        let deleteSupportButton = document.querySelector(`.deleteSupport[data-support-pk='${supportPK}']`) as HTMLElement;
                        let nextFocusable = Core.getNextFocusableElement(deleteSupportButton);

                        supportContainer.parentNode.removeChild(supportContainer);
                        nextFocusable.focus();
                        resolve("Successfully deleted support");
                    } else {
                        reject("There was an unexpected error removing this support from the page");
                    }
                } else {
                    reject("There was an unexpected error deleting this support");
                }
            };
            xhr.send("supportPK=" + supportPK + "&planFK=" + that.planFK);
        });
    }

    //Determines whether to show the message that the FTE has no supports in the UI
    checkSupportCount(ftePK: number) {
        let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
        let noSupportsMessage = document.querySelector(`.noSupportsMessage[data-fte-pk='${ftePK}']`) as HTMLElement;
        if (noSupportsMessage != null) {
            if (supports.length > 0) {
                noSupportsMessage.classList.add("hide");
            } else {
                noSupportsMessage.classList.remove("hide");
            }
        }
    }

    //Soft deletes FTE record from database
    deleteFTE(ftePK: number) {
        let that = this;

        that.deleteFTEConfirmModal.hide();
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteIUFTE', true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = async function () {
            Core.hideLoader();
            let jsonResponse = JSON.parse(xhr.response);
            if (xhr.status === 200 && jsonResponse.success) {

                let allFTEContainer = document.getElementById("fteContainerInner");
                let fteContainer = document.querySelector(`.fteContainer[data-fte-pk='${ftePK}']`);
                let accordion = Core.findClosest(fteContainer, ".lazyAccordion") as HTMLElement;

                //Get next focusable accordion
                let accordionTriggers = allFTEContainer.querySelectorAll(".lazy-accordion-trigger");
                if (accordionTriggers.length > 1) {
                    let arr = Array.prototype.slice.call(accordionTriggers);
                    let thisAccordionTrigger = accordion.querySelector(".lazy-accordion-trigger");
                    if (thisAccordionTrigger != null) {
                        let index = arr.indexOf(thisAccordionTrigger);
                        if (index > -1) {
                            let nextFocusable = arr[index + 1] || arr[index - 1] || arr[0];
                            nextFocusable.focus();
                        }
                    }
                } else {
                    //Focus on create fte if no fte left
                    let create = document.getElementById("createNewFTEId");
                    if (create != null) {
                        create.focus();
                    }
                }

                if (accordion != null) {
                    accordion.parentNode.removeChild(accordion);
                    Core.createHTMLAlert("alertMessageDiv", "Successfully deleted FTE", 'success', 3000, null);

                    SpecialEducationIUProgramProfiles.clearValidationMessage();
                    await SpecialEducationIUProgramProfiles.getValidProgramCount();

                    SpecialEducationIUProgramProfiles.fullPageValidation();
                    await SpecialEducationIUProgramProfiles.planPageCompleteCheck();

                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error removing this FTE from the page", 'error', 3000, null);
                }
            } else {
                Core.createHTMLAlert("alertMessageDiv", "There was an unexpected error deleting this FTE", 'error', 3000, null);
            }
        };
        xhr.send("ftePK=" + ftePK + "&planFK=" + that.planFK);
    }

    //Refreshes the possible Support Types that can be selected in the UI based on the Support Type Group that was selected
    refreshSupportTypeSubList(supportPK: number) {
        let that = this;

        let supportTypeGroup = document.querySelector(`.supportTypeGroup[data-support-pk='${supportPK}']`) as HTMLSelectElement;

        return new Promise<void>((resolve, reject) => {
            if (supportTypeGroup != null) {
                if (supportTypeGroup.value != "") {
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', '/IUSpecialEducation/RefreshIUSupportTypeSubList', true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            const supportTypeListContainer = document.querySelector(`.supportTypesContainer[data-support-pk='${supportPK}']`);
                            //let supportTypeListContainer = document.getElementById('SupportTypesContainer' + '_' + supportPK);
                            if (supportTypeListContainer != null) {
                                supportTypeListContainer.innerHTML = xhr.responseText;

                                let supportTypeDropdown = supportTypeListContainer.querySelector(".supportType[data-forcerequired='true']") as HTMLElement;
                                if (supportTypeDropdown != null) {
                                    that._core.forceElementRequired(supportTypeDropdown);
                                }
                            }

                            resolve();
                        } else {
                            reject("Error refreshing support types");
                        }
                    };
                    xhr.send(`supportPK=${supportPK}&ftePK=${supportTypeGroup.dataset.ftePk}&supportTypeGroupCodeFK=${supportTypeGroup.value}&templateFK=${that.templateFK}`);
                } else {
                    let container = document.querySelector(`.supportTypesContainer[data-support-pk='${supportTypeGroup.dataset.supportPk}']`);
                    if (container != null) {
                        container.innerHTML = "";
                    }
                    resolve();
                }
            } else {
                reject("Support type group not found");
            }
        });
    }

    //Refreshes the possible Support Levels that can be selected in the UI based on the Support Type that was selected
    refreshSupportLevels(supportPK: number) {
        let that = this;

        let currentLevelPK = null;
        let currentTypePK = null;

        let currentLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        if (currentLevel != null && currentLevel.value != "") {
            currentLevelPK = currentLevel.value;
        }

        let currentType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        if (currentType != null && currentType.value != "") {
            currentTypePK = currentType.value;
        }

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/RefreshSupportLevels', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {

                if (xhr.status === 200) {
                    let supportLevelListContainer = document.querySelector(`.supportLevelsContainer[data-support-pk='${supportPK}']`);
                    if (supportLevelListContainer != null) {
                        supportLevelListContainer.innerHTML = xhr.responseText;

                        let supportLevelDropdown = supportLevelListContainer.querySelector(".supportLevel[data-forcerequired='true']") as HTMLElement;
                        if (supportLevelDropdown != null) {
                            that._core.forceElementRequired(supportLevelDropdown);
                        }
                    }
                    resolve()
                } else {
                    reject("Error refreshing support levels");
                }
            };
            xhr.send(`supportPK=${supportPK}&ftePK=${currentLevel.dataset.ftePk}&currentLevelPK=${currentLevelPK}&currentTypePK=${currentTypePK}&templateFK=${that.templateFK}`);
        });
    }

    //Shows the max possible caseload count in the UI given the currently selected Support Level and Type for the specified Support
    refreshMaxPossibleCaseLoad(supportPK: number) {
        let that = this;

        let supportType = document.querySelector(`.supportType[data-support-pk='${supportPK}']`) as HTMLSelectElement;
        let supportLevel = document.querySelector(`.supportLevel[data-support-pk='${supportPK}']`) as HTMLSelectElement;

        return new Promise<void>((resolve, reject) => {
            if (supportType != null && supportType.value != "" && supportLevel != null && supportLevel.value != "") {
                let xhr = new XMLHttpRequest();
                xhr.open('POST', '/SpecialEducation/RefreshMaxPossibleCaseLoad', true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.onload = function () {
                    let jsonResponse = JSON.parse(xhr.response);
                    if (xhr.status === 200 && jsonResponse.success) {
                        let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`) as HTMLElement;
                        if (maxCaseLoadContainer != null) {
                            maxCaseLoadContainer.classList.remove("hide");
                        }

                        let maxCaseLoadElement = document.querySelector(`.maxCaseLoad[data-support-pk='${supportPK}']`) as HTMLElement;
                        if (maxCaseLoadElement != null) {
                            maxCaseLoadElement.innerHTML = jsonResponse.maxCaseLoadCount;
                        }

                        resolve();
                    } else {
                        reject("Error refreshing max caseload");
                    }
                };
                xhr.send(`supportTypePK=${supportType.value}&supportLevelPK=${supportLevel.value}`);
            } else {
                let maxCaseLoadContainer = document.querySelector(`.maxCaseLoadContainer[data-support-pk='${supportPK}']`) as HTMLElement;
                if (maxCaseLoadContainer != null) {
                    maxCaseLoadContainer.classList.add("hide");
                }
            }
        });
    }

    //Refreshes the FTE for each support in the FTE and the FTE as a whole. If the FTE is over the limit, the error will display.
    refreshFTEPercentage(ftePK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];

            //Determine whether full-time/part-time
            //IU Special Education Plans don't take in to account part time positions.  So we set this equal to fulltime
            let fullTimeLookupCode = "fulltime";
            let fullTime = document.querySelector(`.fullTime[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }

            let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = <HTMLElement>support
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;

                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;

                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }

                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }

                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }

                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;

                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-fte-pk='${ftePK}']`) as HTMLElement;
                    if (overOneMessage != null) {

                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }

                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        } else {
                            overOneMessage.classList.add("hide");
                        }
                    }

                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`) as HTMLInputElement;
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        } else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }

                    resolve();
                } else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }

    //The classroom location for a support is dependent upon the selected classroom location for the FTE. This function handles this in the UI
    adjustSupportClassroomLocation(ftePK: number) {
        return new Promise<void>((resolve, reject) => {
            let classroomLocationLookupCode: string = "";
            let fteClassroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
            if (fteClassroomLocation != null) {
                classroomLocationLookupCode = fteClassroomLocation.options[fteClassroomLocation.selectedIndex].dataset.lookupCode;
            }
            //Get the supports for this FTE
            let classroomLocationsSupport = document.querySelectorAll(`.classroomLocationSupport[data-fte-pk='${ftePK}']`);
            for (let classroomLocationDropdown of classroomLocationsSupport) {
                let classroomLocationDropdownElement = <HTMLSelectElement>classroomLocationDropdown;

                if (classroomLocationLookupCode === "specialeducationlocationelementary" || classroomLocationLookupCode === "specialeducationlocationsecondary") {
                    for (var i = 0; i < classroomLocationDropdownElement.options.length; ++i) {
                        if (classroomLocationDropdownElement.options[i].dataset.lookupCode === classroomLocationLookupCode) {
                            classroomLocationDropdownElement.selectedIndex = i;
                            classroomLocationDropdownElement.disabled = true;
                            break;
                        }
                    }
                } else {
                    classroomLocationDropdownElement.disabled = false;
                }
            }
            resolve();
        });
    }

    //Based on the selection of "Classroom Location" and "Age Range", make the "Age Range Justification" optional or required
    checkAgeRangeJustification(supportPK: number, ftePk: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            let makeRequired = false;

            let classroomLocation = document.querySelector(`.classroomLocationFTE[data-fte-pk='${ftePk}']`) as HTMLSelectElement;
            let ageRangeFrom = document.querySelector(`.ageRangeFrom[data-support-pk='${supportPK}']`) as HTMLInputElement;
            let ageRangeTo = document.querySelector(`.ageRangeEnd[data-support-pk='${supportPK}']`) as HTMLInputElement;
            if (classroomLocation != null && ageRangeFrom != null && ageRangeTo != null) {
                if (ageRangeFrom.value != "" && ageRangeTo.value != "" && parseInt(ageRangeFrom.value) && parseInt(ageRangeTo.value)) {

                    let range = parseInt(ageRangeTo.value) - parseInt(ageRangeFrom.value);

                    let classroomLocationLookupCode = classroomLocation.options[classroomLocation.selectedIndex].dataset.lookupCode;

                    switch (classroomLocationLookupCode) {
                        case 'specialeducationlocationelementary':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationsecondary':
                            if (range > 4) {
                                makeRequired = true;
                            }
                            break;
                        case 'specialeducationlocationmultiple':
                            if (range > 3) {
                                makeRequired = true;
                            }
                            break;
                    }
                }
            }

            let justification = document.querySelector(`.ageRangeJustification[data-support-pk='${supportPK}']`) as HTMLInputElement;
            if (justification != null) {
                if (makeRequired) {
                    that._core.forceElementRequired(justification);
                } else {
                    that._core.forceElementOptional(justification);
                }
            }

            resolve();
        });
    }

    //Shows a loader next to the FTE percentage while application figures out what the new percentage is
    toggleFTESupportLoader(supportPK: number, show: boolean) {
        let loaderContainer = document.querySelector(`.loaderContainer[data-support-pk='${supportPK}']`) as HTMLElement;
        if (loaderContainer != null) {
            if (show) {
                loaderContainer.classList.remove("hide");
            } else {
                loaderContainer.classList.add("hide");
            }
        }
    }

    //Searches FTE based on criteria and refreshes DOM with partial view. Also pushes history state so that back and continue buttons in browser work.
    search(fromHistory: boolean = false) {
        let that = this;

        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

        let fteIDValue = fteID != null ? fteID.value : "";
        let buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
        let supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;

        let query = {
            PlanFK: that.planFK,
            FTEIdentifierName: fteIDValue,
            BuildingTypePK: buildingValue,
            SupportTypeCodePK: supportTypeValue
        }

        let queryString = `?fteId=${query.FTEIdentifierName}&BuildingTypePK=${query.BuildingTypePK}&supportTypeCodeFK=${query.SupportTypeCodePK}`;

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/SpecialEducation/IUFTESearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let container = document.getElementById("fteContainer");
                    if (container != null) {
                        container.innerHTML = xhr.responseText;

                        //Add to history so back button works
                        if (!fromHistory) {
                            history.pushState(query, '', queryString);
                        }

                        let searchResultsMessage = document.getElementById("searchResultsMessage");
                        if (searchResultsMessage != null) {
                            document.title = searchResultsMessage.textContent + " - Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
                            searchResultsMessage.focus();
                        }

                        let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE") as HTMLButtonElement;
                        if (exportToExcelFilteredButton != null) {
                            exportToExcelFilteredButton.classList.remove("hide");
                        }

                        resolve();
                    }
                    else {
                        reject("There was an error loading FTE");
                    }
                } else {
                    reject("There was an unexpected error during search");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }

    //Clears search fields, saves page (in case there were changes), and refreshes DOM
    clearSearch() {
        let that = this;

        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

        if (fteID != null) {
            fteID.value = "";
        }

        if (building != null) {
            building.selectedIndex = 0;
        }

        if (supportType != null) {
            supportType.selectedIndex = 0;
        }

        Core.showLoader();
        that.promiseSave()
            .then(() => {
                return that.search(true);
            })
            .then(() => {
                that.initializeFTEHashes();
                that.handleClearDisabled();
                that._core.initializeRequiredFields(that.validationClasses);
                //reinitialize accordions
                new SpecialEducationIUProgramProfilesLazyAccordion(null);
                Core.hideLoader();

                let exportToExcelFilteredButton = document.getElementById("exportExcelFilteredFTE") as HTMLButtonElement;
                if (exportToExcelFilteredButton != null) {
                    exportToExcelFilteredButton.classList.add("hide");
                }
            })
            .catch((error) => {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
            });

        history.pushState(null, '', window.location.href.split('?')[0]);
        document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }

    //Calculates a hash for an FTE. This is used to determine whether the FTe has changed for saving purposes.
    calculateFTEHash(ftePK: number) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;

        let allElements = document.querySelectorAll(`[data-hashable][data-fte-pk='${ftePK}']`);

        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                let inputElement = <HTMLInputElement>element;

                if (inputElement.value === "") {
                    value = 0
                    hash = hash + value;
                }
                else if (inputElement.value !== "") {
                    value = inputElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLSelectElement) {
                let selectElement = <HTMLSelectElement>element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLTextAreaElement) {
                let textAreaElement = <HTMLTextAreaElement>element;

                if (textAreaElement.value === "") {
                    value = 0
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = that._core.hashCode(value);
                    hash = hash + newHash;
                }
            }
        }

        return hash;
    }

    //Determines whether an FTE has changed by comparing the current hash value to a prior hash value
    fteHasChanged(ftePK: number) {
        let that = this;
        let changed = false;
        if (that.fteHashes[ftePK] !== undefined) {
            let newHash = that.calculateFTEHash(ftePK);
            if (newHash !== that.fteHashes[ftePK]) {
                changed = true;
            }
        } else {
            //Be on the safe side and say it has changed
            changed = true;
        }

        return changed;
    }

    //Calculates a hash value for each FTE on the page and is stored in an object as a property of this class.
    initializeFTEHashes() {
        let that = this;

        that.fteHashes = {};

        let fteContainers = document.getElementsByClassName("fteContainer") as HTMLCollectionOf<HTMLElement>;
        for (let fte of fteContainers) {
            let ftePK = parseInt(fte.dataset.ftePk);
            let hash = that.calculateFTEHash(ftePK);
            that.fteHashes[ftePK] = hash;
        }
    }

    //The save button only becomes available for an FTE if there is a change within the FTE
    handleFTESaveDisabled(ftePK: number) {
        let saveButton = document.querySelector(`.saveFTEButton[data-fte-pk='${ftePK}']`) as HTMLButtonElement;
        if (saveButton != null) {
            if (this.fteHasChanged(ftePK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            } else {
                saveButton.disabled = true;
            }
        }
    }

    //Determines whether the clear button should become enabled
    handleClearDisabled() {
        let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
        let building = document.getElementById("searchBuilding") as HTMLSelectElement;
        let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;
        let clearButton = document.getElementById("searchClear") as HTMLButtonElement;

        if (fteID != null && building != null && supportType != null && clearButton != null) {
            if (fteID.value !== "" || building.selectedIndex > 0 || supportType.selectedIndex > 0) {
                clearButton.disabled = false;
            } else {
                clearButton.disabled = true;
            }
        }
    }

    //Custom client-side validation for the page
    customValidation() {
        let that = this;
        let errorCount = 0;

        let allElements = document.querySelectorAll("[data-forcerequired='true']");

        for (let element of allElements) {

            let htmlElement = <HTMLElement>element;

            if (htmlElement instanceof HTMLInputElement) {
                let inputElement = <HTMLInputElement>htmlElement;
                if (inputElement.value === "") {
                    inputElement.classList.add("missing-field");
                    inputElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(inputElement);
                    errorCount++;
                }
            } else if (htmlElement instanceof HTMLSelectElement) {
                let selectElement = <HTMLSelectElement>htmlElement;
                if (selectElement.selectedIndex === 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    selectElement.classList.add("missing-field");
                    selectElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectElement);
                    errorCount++;
                }
            } else if (htmlElement instanceof HTMLTextAreaElement) {
                let textAreaElement = <HTMLTextAreaElement>htmlElement;
                if (textAreaElement.value === "") {
                    textAreaElement.classList.add("missing-field");
                    textAreaElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(textAreaElement);
                    errorCount++;
                }
            }
        }

        //See if any over 1 FTE
        let visibleFTEErrors = document.querySelectorAll(".overOneFTEMessage:not(.hide)");
        errorCount += visibleFTEErrors.length;

        if (errorCount > 0) {
            //Set text for error message
            let message = <HTMLDivElement>document.getElementById("validationMessage");

            if (errorCount === 1) {
                message.innerHTML = "<p class='validationErrorCountMessage'>There is " + errorCount + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
            } else {
                message.innerHTML = "<p class='validationErrorCountMessage'>There are " + errorCount + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
            }
            let goToError = document.getElementById("goToFirstError");

            if (goToError !== null) {

                let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field, .overOneFTEMessage:not(.hide)");

                if (firstFocusableEl !== null) {
                    goToError.addEventListener("click", function () {
                        firstFocusableEl.focus();
                    });
                } else {
                    goToError.parentNode.removeChild(goToError);
                }
            }

            this._core.doValidation(null, true);

        } else {
            this._core.doValidation(null, false);
        }
    }

    //Exports FTE and support data to excel. If they click the Filtered button, the data in the Excel file will be filtered based on search criteria
    exportToExcel(filtered: boolean = false) {
        let that = this;

        return new Promise<void>((resolve, reject) => {
            let fteID = document.getElementById("searchFTEId") as HTMLInputElement;
            let building = document.getElementById("searchBuilding") as HTMLSelectElement;
            let supportType = document.getElementById("searchSupportType") as HTMLSelectElement;

            let fteIDValue = "";
            let buildingValue = null;
            let supportTypeValue = null;

            if (filtered) {
                fteIDValue = fteID != null ? fteID.value : "";
                buildingValue = building != null && building.selectedIndex > 0 ? parseInt(building.value) : null;
                supportTypeValue = supportType != null && supportType.selectedIndex > 0 ? parseInt(supportType.value) : null;
            }

            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/ExportExcel/SpecialEducationIUProgramProfileExcelExport?planFK=${that.planFK}&fteId=${fteIDValue}&frcppInstitutionFK=${buildingValue}&supportTypeCodeFK=${supportTypeValue}`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = "EducationProgramFTE.xlsx";

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var a = <HTMLAnchorElement>document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                } else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            }
            xhr.send();
        });
    }

    //Handle the change of the Identify Classroom ("operatedby") dropdown. Each selection will hide/show fields.
    static changeOperatedByIdentifyClassroom(e: HTMLElement) {
        const element = <HTMLSelectElement>e;
        const selected = element[element.selectedIndex] as HTMLOptionElement;
        const supportFK = element.dataset.supportPk;


        const classroomLocationContainer = <HTMLDivElement>document.querySelector(`.supportClassroomLocationContainer[data-support-pk='${supportFK}']`);
        const leaNameContainer = <HTMLDivElement>document.querySelector(`.supportLEANameContainer[data-support-pk='${supportFK}']`);
        const schoolBuildingNameContainer = <HTMLDivElement>document.querySelector(`.supportSchoolBuildingNameContainer[data-support-pk='${supportFK}']`);
        const identifyClassroomOtherContainer = <HTMLDivElement>document.querySelector(`.supportIdentifyClassroomOtherContainer[data-support-pk='${supportFK}']`);
        const classroomLocation = <HTMLDivElement>document.querySelector(`.supportClassroomLocation[data-support-pk='${supportFK}']`);
        const leaName = <HTMLDivElement>document.querySelector(`.supportLEAName[data-support-pk='${supportFK}']`);
        const schoolBuildingName = <HTMLDivElement>document.querySelector(`.supportSchoolBuildingName[data-support-pk='${supportFK}']`);
        const identifyClassroomOther = <HTMLDivElement>document.querySelector(`.supportIdentifyClassroomOther[data-support-pk='${supportFK}']`);

        
        if (selected.dataset.lookupcode && selected.dataset.lookupcode === "iuSpecialEducationIdentifyClassroomLEA") { //
            classroomLocationContainer.classList.add("hide");
            leaNameContainer.classList.remove("hide");
            schoolBuildingNameContainer.classList.remove("hide");
            identifyClassroomOtherContainer.classList.add("hide");
            
            this._staticCore.forceElementOptional(classroomLocation);
            this._staticCore.forceElementRequired(leaName);
            this._staticCore.forceElementRequired(schoolBuildingName);
            this._staticCore.forceElementOptional(identifyClassroomOther);
        } else if (selected.dataset.lookupcode && selected.dataset.lookupcode === "iuSpecialEducationIdentifyClassroomIU") {
            classroomLocationContainer.classList.remove("hide");
            leaNameContainer.classList.add("hide");
            schoolBuildingNameContainer.classList.add("hide");
            identifyClassroomOtherContainer.classList.add("hide");


            this._staticCore.forceElementRequired(classroomLocation);
            this._staticCore.forceElementOptional(leaName);
            this._staticCore.forceElementOptional(schoolBuildingName);
            this._staticCore.forceElementOptional(identifyClassroomOther);
        } else if (selected.dataset.lookupcode && selected.dataset.lookupcode === "iuSpecialEducationIdentifyClassroomOther") {
            classroomLocationContainer.classList.add("hide");
            leaNameContainer.classList.add("hide");
            schoolBuildingNameContainer.classList.add("hide");
            identifyClassroomOtherContainer.classList.remove("hide");

            this._staticCore.forceElementOptional(classroomLocation);
            this._staticCore.forceElementOptional(leaName);
            this._staticCore.forceElementOptional(schoolBuildingName);
            this._staticCore.forceElementRequired(identifyClassroomOther);
        } else {
            classroomLocationContainer.classList.add("hide");
            leaNameContainer.classList.add("hide");
            schoolBuildingNameContainer.classList.add("hide");
            identifyClassroomOtherContainer.classList.add("hide");

            this._staticCore.forceElementOptional(classroomLocation);
            this._staticCore.forceElementOptional(leaName);
            this._staticCore.forceElementOptional(schoolBuildingName);
            this._staticCore.forceElementOptional(identifyClassroomOther);
        }
    }

    static checkOperatedByIdentifyClassroom(educationProgramFTEFK: number) {
        const allOperatedBySelects = document.querySelectorAll(`.operatedBy[data-fte-pk='${educationProgramFTEFK}']`);
        for (const operatedBySelect of allOperatedBySelects) {
            SpecialEducationIUProgramProfiles.changeOperatedByIdentifyClassroom(operatedBySelect as HTMLElement);
        }
    }

    static fteValidation(educationProgramFTEFK: number) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);
        const textareaElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] textarea`);

        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    } else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }

        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = <HTMLSelectElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    } else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }

        for (const elementInside of textareaElements) {
            if (elementInside instanceof HTMLTextAreaElement) {
                const inputElement = <HTMLTextAreaElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    if (inputElement.value === "") {
                        inputElement.classList.add("missing-field");
                        inputElement.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(inputElement);
                    } else {
                        inputElement.classList.remove("missing-field");
                        inputElement.setAttribute("aria-invalid", "false");
                        Core.removeErrorLabelForInput(inputElement);
                    }
                }
            }
        }

        this.updateFTEPercentage(educationProgramFTEFK);
    }

    static updateFTEPercentage(ftePK: number) {
        let that = this;

        return new Promise<void>((resolve, reject) => {

            //Go through each support and collect info
            let typeLevelCaseLoadComboList = [];

            //Determine whether full-time/part-time
            let fullTimeLookupCode = "";
            let fullTime = document.querySelector(`.fullTime[data-fte-pk='${ftePK}']`) as HTMLSelectElement;
            if (fullTime != null) {
                fullTimeLookupCode = fullTime.options[fullTime.selectedIndex].dataset.lookupCode;
            }

            let supports = document.querySelectorAll(`.supportRow[data-fte-pk='${ftePK}']`);
            for (let support of supports) {
                let supportElement = <HTMLElement>support
                let supportType = support.querySelector(`.supportType[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let supportLevel = support.querySelector(`.supportLevel[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLSelectElement;
                let caseLoadCount = support.querySelector(`.caseLoad[data-support-pk='${supportElement.dataset.supportPk}']`) as HTMLInputElement;

                let supportTypeValue = null;
                let supportLevelValue = null;
                let caseLoadCountValue = null;

                if (supportType != null && supportType.value != "") {
                    supportTypeValue = parseInt(supportType.value);
                }

                if (supportLevel != null && supportLevel.value != "") {
                    supportLevelValue = parseInt(supportLevel.value);
                }

                if (caseLoadCount != null && caseLoadCount.value != "") {
                    caseLoadCountValue = parseInt(caseLoadCount.value);
                }

                typeLevelCaseLoadComboList.push({
                    "CaseLoadCount": caseLoadCountValue,
                    "SupportTypeCodeFK": supportTypeValue,
                    "SupportLevelCodeFK": supportLevelValue,
                    "SupportPK": parseInt(supportElement.dataset.supportPk)
                });
            }

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/CalculateFTEDetailsJSON', true);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.onload = function () {
                let jsonResponse = JSON.parse(xhr.response);
                if (xhr.status === 200 && jsonResponse.success) {
                    let ftePercentDetails = jsonResponse.fteDetails;
                    let isOver = ftePercentDetails.isOver;
                    let maxPercent = ftePercentDetails.maxPercent;
                    let fteSupportDetails = ftePercentDetails.supportFTEDetails;

                    let overOneMessage = document.querySelector(`.overOneFTEMessage[data-fte-pk='${ftePK}']`) as HTMLElement;
                    if (overOneMessage != null) {

                        let maxPercentage = overOneMessage.querySelector(".maxFTEPercentage");
                        if (maxPercentage != null) {
                            maxPercentage.innerHTML = maxPercent;
                        }

                        if (isOver) {
                            overOneMessage.classList.remove("hide");
                        } else {
                            overOneMessage.classList.add("hide");
                        }
                    }

                    for (let supportDetail of fteSupportDetails) {
                        let fteInputForSupport = document.querySelector(`.ftePercent[data-support-pk='${supportDetail.supportPK}']`) as HTMLInputElement;
                        if (supportDetail.supportFTEPercent != null) {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = supportDetail.supportFTEPercent;
                            }
                        } else {
                            if (fteInputForSupport != null) {
                                fteInputForSupport.value = "";
                            }
                        }
                    }

                    resolve();
                } else {
                    reject("Error refreshing FTE percentage");
                }
            };
            xhr.send(JSON.stringify({
                "TypesAndLevels": typeLevelCaseLoadComboList,
                "FullTimeLookupCode": fullTimeLookupCode
            }));
        });
    }

    static initializeLoadedFTE(educationProgramFTEFK: number) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] select`);
        const textAreaElements = document.querySelectorAll(`.lazy-accordion-panel[data-educationprogramftefk='${educationProgramFTEFK}'] textarea`);

        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = <HTMLSelectElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        for (const elementInside of textAreaElements) {
            if (elementInside instanceof HTMLTextAreaElement) {
                const inputElement = <HTMLTextAreaElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        this.updateFTEPercentage(educationProgramFTEFK);
    }

    static fullPageValidation() {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = <HTMLInputElement>document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const totalErrorsNotShown = totalErrors - shownAccordions.length;
        let errorCount: number = 0;
        for (const accordion of allAccordions) {
            const accElement = <HTMLButtonElement>accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;

            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            } else {
                accElement.classList.remove("error");
            }
        }

        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.add("show");

        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");

        const message = <HTMLDivElement>document.getElementById("validationMessage");

        if (errorCount > 0) {
            let word = "are";
            let facility = "FTEs"
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "FTE";
                error = "error";
            }

            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;

            let facilityWord = "are";
            let facilityFacility = "FTEs";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "FTE";
            }

            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;

            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            if (message !== null) {
                message.innerHTML = "The page has been successfully saved."
                message.classList.add("show");
            }
        }
    }

    static clearValidationMessage() {
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }

    static async getValidProgramCount() {
        const planForm = <HTMLDivElement>document.getElementById("specialEducationEducationProgramCaseloadIUFTEForm");
        const planFK = planForm.dataset.planfk;

        const response = await fetch(`/IUSpecialEducation/GetValidProgramFTECountAsync/${planFK}`, { credentials: 'include' })
        if (response.ok) {
            const value = await response.text();
            const field = <HTMLInputElement>document.getElementById("validCount");
            field.value = value;
        }
    }

    static searchValidation() {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = <HTMLInputElement>document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const totalErrorsNotShown = totalErrors - shownAccordions.length;


        let errorCount: number = 0;
        for (const accordion of allAccordions) {
            const accElement = <HTMLButtonElement>accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;

            if (valid === "false") {
                accElement.classList.add("error");
                errorCount++;
            } else {
                accElement.classList.remove("error");
            }
        }

        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.add("show");

        if (errorCount > 0) {
            const message = <HTMLDivElement>document.getElementById("validationMessage");
            let word = "are";
            let facility = "facilities"
            let error = "issues";
            if (errorCount === 1) {
                word = "is";
                facility = "facility";
            }

            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;

            if (errorCount < totalErrors) {
                let facilityWord = "are";
                let facilityFacility = "facilities";
                let facilityError = "issues";
                if (totalErrorsNotShown === 1) {
                    facilityWord = "is";
                    facilityFacility = "facility";
                }

                message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;
            }
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else if (errorCount === 0 && totalErrorsNotShown > 0) {
            const message = <HTMLDivElement>document.getElementById("validationMessage");

            messageContainer.classList.add("warning");
            message.innerHTML += `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;

            let facilityWord = "are";
            let facilityFacility = "facilities";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "facility";
            }

            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;

            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            const successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    static async planPageCompleteCheck() {
        const form = document.getElementById("specialEducationEducationProgramCaseloadIUFTEForm");
        const planFK = form.dataset.planfk;
        const pageCode = form.dataset.pagecode;

        const response = await fetch(`/IUSpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' })
        if (response.ok) {
            const value = await response.json();

            const menuElement = <HTMLDivElement>document.querySelector("#leftBarspecialEducationIUProgramProfilesParent .status-indicator");

            if (value.thisPage) {
                menuElement.classList.add("complete");
            } else {
                menuElement.classList.remove("complete");
            }
        }
    }
}

// SpecialEducationIUOpServices
class SpecialEducationIUOpServices {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUOpServicesField"];

        let specialEducationIUOpServicesSaveButton = document.getElementById("specialEducationIUOpServicesSave");
        if (specialEducationIUOpServicesSaveButton !== null)
            specialEducationIUOpServicesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUOpServicesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUOpServicesField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUOpServices', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SpecialEducationIUOperated
class SpecialEducationIUOperated {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUOperatedField"];

        let specialEducationIUOperatedSaveButton = document.getElementById("specialEducationIUOperatedSave");
        if (specialEducationIUOperatedSaveButton !== null)
            specialEducationIUOperatedSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUOperatedForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUOperatedField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUOperated', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SpecialEducationIUIUSupportStaff
class SpecialEducationIUIUSupportStaff {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUIUSupportStaffField", "specialEducationIUIUSupportStaffSelectField"];

        let specialEducationIUIUSupportStaffSaveButton = document.getElementById("specialEducationIUIUSupportStaffSave");
        if (specialEducationIUIUSupportStaffSaveButton !== null)
            specialEducationIUIUSupportStaffSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const createSupportService = document.getElementById("createSupportService");
        if (createSupportService !== null)
            createSupportService.addEventListener("click", (e: Event) => this.addNewSupportService());

        const specialEducationIUIUSupportStaffLocationDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteConfirm");
        if (specialEducationIUIUSupportStaffLocationDeleteConfirm !== null)
            specialEducationIUIUSupportStaffLocationDeleteConfirm.addEventListener("click", (e: Event) => this.deleteLocationConfirm(e.target as HTMLButtonElement));

        const specialEducationIUIUSupportStaffLocationDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteCancel");
        if (specialEducationIUIUSupportStaffLocationDeleteCancel !== null)
            specialEducationIUIUSupportStaffLocationDeleteCancel.addEventListener("click", (e: Event) => this.deleteLocationCancel());

        const specialEducationIUIUSupportStaffSupportDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteConfirm");
        if (specialEducationIUIUSupportStaffSupportDeleteConfirm !== null)
            specialEducationIUIUSupportStaffSupportDeleteConfirm.addEventListener("click", (e: Event) => this.deleteSupportConfirm(e.target as HTMLButtonElement));

        const specialEducationIUIUSupportStaffSupportDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteCancel");
        if (specialEducationIUIUSupportStaffSupportDeleteCancel !== null)
            specialEducationIUIUSupportStaffSupportDeleteCancel.addEventListener("click", (e: Event) => this.deleteSupportCancel());

        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();
        this.setupLocationOtherDropdowns();
        this.bindAddLocationButtons();
        this.bindDeleteLocationItems();
        this.bindAddSupportRowButtons();
        this.bindDeleteSupports();

        const specialEducationIUIUSupportStaffExportToExcel = document.getElementById("specialEducationIUIUSupportStaffExportToExcel");
        if (specialEducationIUIUSupportStaffExportToExcel !== null) {
            specialEducationIUIUSupportStaffExportToExcel.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        const supportStaffServicesSearchButton = document.getElementById("supportStaffServicesSearchButton");
        supportStaffServicesSearchButton.addEventListener("click", (e: Event) => this.search());

        const supportStaffServicesClearButton = document.getElementById("supportStaffServicesClearButton");
        supportStaffServicesClearButton.addEventListener("click", (e: Event) => this.clearSearch());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const saveData = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUIUSupportStaffForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const accordions = document.querySelectorAll("button.supportStaffServices");
        for (const accordion of accordions) {
            const accordionElement = <HTMLDivElement>accordion;
            const dataPointPlanPropertyPK = accordionElement.dataset.datapointplanpropertypk;

            const fteRows = [];

            const rows = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectWithOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const select of rows) {
                const allSaveElements = [];
                const element = <HTMLSelectElement>select;
                const row = element.dataset.row;

                let selects = false;
                let inputs = false;
                let locations = false;

                const specialEducationIUIUSupportStaffSelectForSaveFields = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffSelectForSaveField of specialEducationIUIUSupportStaffSelectForSaveFields) {
                    let planPropertyPK = 0;
                    let element = <HTMLSelectElement>specialEducationIUIUSupportStaffSelectForSaveField;
                    let rowNumber = element.dataset.row;
                    let propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (element.value !== "0" || hadValue) {
                        const saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: null,
                            LookupCodeFK: parseInt(element.value),
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };

                        allSaveElements.push(saveItem);

                        selects = true;
                    }
                }

                const specialEducationIUIUSupportStaffForSaveFields = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffForSaveField of specialEducationIUIUSupportStaffForSaveFields) {
                    let planPropertyPK = 0;
                    const element = <HTMLInputElement>specialEducationIUIUSupportStaffForSaveField;
                    const rowNumber = element.dataset.row;
                    const propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (element.value !== "" || hadValue) {
                        const saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: element.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };

                        allSaveElements.push(saveItem);

                        inputs = true;
                    }
                }

                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const locationItem of locationItems) {
                    const name = <HTMLSpanElement>locationItem.querySelector(`.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const rowNumber = name.dataset.row;
                    const propertyPK = name.dataset.propertypk;

                    let planPropertyPK = 0;
                    if (name.dataset.planpropertypk && name.dataset.planpropertypk !== "") {
                        planPropertyPK = parseInt(name.dataset.planpropertypk);
                    }

                    const saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: parseInt(propertyPK),
                        TextValue: name.textContent,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);

                    locations = true;
                }

                const rowToSave = {
                    "SaveElements": allSaveElements
                }

                if (selects || inputs || locations)
                    fteRows.push(rowToSave);
            }

            const thisAccordion = {
                "PlanFK": planPK,
                "FTERows": fteRows
            }

            saveData.push(thisAccordion);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUIUSupportStaff', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status + "'", 'error', 3000, null);
                }
            }
        };
        if (saveData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUIUSupportStaffSelectWithOtherField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }

    setupLocationOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUIUSupportLocationSelectField");
        for (let selectElement of selectElements)
            this.locationDropdownChange(selectElement);
    }

    showDeleteRow(e: Event) {
        const deleteButton = <HTMLButtonElement>e.target;
        const row = deleteButton.dataset.row;
        const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;

        const modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.show();
    }

    bindDeleteProgramRows() {
        const allDeletes = document.getElementsByClassName("deleteSupportStaffRow");
        for (const del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    bindOtherDropdown() {
        const selectElements = document.getElementsByClassName("specialEducationIUIUSupportStaffSelectWithOtherField");
        for (const selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.dropdownChange(e.target));

        const locationSelectElements = document.getElementsByClassName("specialEducationIUIUSupportLocationSelectField");
        for (const selectElement of locationSelectElements)
            selectElement.addEventListener("change", (e: Event) => this.locationDropdownChange(e.target));
    }

    bindAddSupportRowButtons() {
        const iuSpecialEducationFTEsAllocatedAddRowButtons = document.getElementsByClassName("iuSpecialEducationSupportStaffAddRow");
        for (const iuSpecialEducationFTEsAllocatedAddRowButton of iuSpecialEducationFTEsAllocatedAddRowButtons)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e: Event) => this.addRow(e));
    }

    dropdownChange(e) {
        const selectElement = <HTMLSelectElement>e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;

        const selectContainer = <HTMLDivElement>document.querySelector(`.buildingContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = <HTMLDivElement>document.querySelector(`.otherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUSupportStaffOtherField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        } else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }

    locationDropdownChange(e) {
        const selectElement = <HTMLSelectElement>e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;

        const selectContainer = <HTMLDivElement>document.querySelector(`.locationContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = <HTMLDivElement>document.querySelector(`.locationOtherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUIUSupportLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        } else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }

    async addRow(e: Event) {
        Core.showLoader();
        const numberOfRows = 1;
        const formElement = <HTMLDivElement>document.getElementById("specialEducationIUIUSupportStaffForm");
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        
        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            const planFK = formElement.dataset.planfk;
            const that = this;
            let newRow = 0;

            const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (let row of allRows) {
                let rowEle = <HTMLDivElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/AddSupportStaffAndServicesRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}`, { credentials: 'include' })
                if (response.ok) {
                    const output = await response.text();

                    const newDiv = <HTMLDivElement>document.createElement("div");
                    newDiv.classList.add("operatedServiceRow")
                    newDiv.classList.add("operated-service-row")
                    newDiv.classList.add("margin-bottom-large")
                    newDiv.dataset.row = newRow.toString(); 
                    newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK.toString();
                    newDiv.innerHTML = output;

                    const accordion = <HTMLDivElement>document.querySelector(`.supportStaffList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    accordion.appendChild(newDiv);

                    this.bindAddLocationButtons();
                    this.bindOtherDropdown();
                    this.bindDeleteProgramRows();
                }
            }

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The row was successfully added.", 'success', 2000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", 'An error occured adding this row. Please try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        const allElements = document.querySelectorAll(`.specialEducationIUSupportField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allElements) {
            const element = <HTMLElement>ele;
            const planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        const allLocations = document.querySelectorAll(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allLocations) {
            const element = <HTMLElement>ele;
            const planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowDataWithRelated', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    async addNewSupportService() {
        let staffServiceLookupCodeFK = 0;
        let fteId = "";

        const specialEducationIUIUSupportStaffForm = <HTMLElement>document.getElementById("specialEducationIUIUSupportStaffForm");
        const planFK = specialEducationIUIUSupportStaffForm.dataset.planfk;

        const specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices");
        if (specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices !== null) {
            const selectElement = <HTMLSelectElement>specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices;
            const lookupCodeFK = selectElement.value;

            staffServiceLookupCodeFK = parseInt(lookupCodeFK);
        }

        const specialEducationIUProfilesIUCreateSupportServiceIUFTEId = document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUFTEId");
        if (specialEducationIUProfilesIUCreateSupportServiceIUFTEId !== null) {
            const inputElement = <HTMLInputElement>specialEducationIUProfilesIUCreateSupportServiceIUFTEId;
            const textValue = inputElement.value;
            
            fteId = textValue;
        }


        if (fteId === "" || staffServiceLookupCodeFK === 0) {
            Core.createHTMLAlert("alertMessageDiv", "To add a record, enter a value in the FTE ID field and select a value from the IU Support Staff/Services dropdown.", 'warning', 3000, null);
        } else {
            Core.showLoader();
            const dataModel = {
                "PlanFK": planFK,
                "FTEID": fteId,
                "StaffServiceLookupCodeFK": staffServiceLookupCodeFK
            };

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };

            const response = await fetch(`/IUSpecialEducation/AddSupportStaffAndServicesAccordion`, settings)
            if (response.ok) {
                const output = await response.text();

                let newDiv = <HTMLDivElement>document.createElement("div");
                newDiv.innerHTML = output;

                let container = document.getElementById("iuOperatedServices");
                container.append(newDiv);

                let idControl = newDiv.querySelector(".Accordion h2 button");
                let id = idControl.id;
                new CustomAccordion(id);

                const specialEducationIUProfilesIUCreateSupportServiceIUFTEId = <HTMLInputElement>document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUFTEId");
                if (specialEducationIUProfilesIUCreateSupportServiceIUFTEId !== null) {
                    specialEducationIUProfilesIUCreateSupportServiceIUFTEId.value = "";
                }

                const specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices = <HTMLSelectElement>document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices");
                if (specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices !== null) {
                    specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices.selectedIndex = 0;
                }

                this.bindAddLocationButtons();
                this.bindAddSupportRowButtons();
                this.bindDeleteSupports();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The IU Support/Services record has been added.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding this record. Please try again.", 'error', 3000, null);
            }
        }
    }

    bindAddLocationButtons() {
        const addLocation = document.getElementsByClassName("addLocation");
        for (const location of addLocation) {
            location.addEventListener("click", (e: Event) => this.addLocation(e));
        }
    }

    async addLocation(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const row = button.dataset.row;
        const propertyPK = button.dataset.propertypk;

        const form = <HTMLElement>document.getElementById("specialEducationIUIUSupportStaffForm");
        const planFK = form.dataset.planfk;


        const locationDropdown = <HTMLSelectElement>document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const locationOtherInput = <HTMLInputElement>document.querySelector(`.specialEducationIUIUSupportLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (locationDropdown.value === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please select a location to add.", 'warning', 3000, null);
        } else {


            let locationText = "";
            let proceed: boolean = true;

            const locationDropdownOption = <HTMLOptionElement>locationDropdown[locationDropdown.selectedIndex];
            locationText = locationDropdownOption.text;
            if (locationDropdownOption.dataset.lookupcode === "specialEducationIUSupportStaffLocationOther") {
                locationText = locationOtherInput.value;
            }

            if (locationDropdownOption.dataset.lookupcode === "specialEducationIUSupportStaffLocationOther" && locationText === "") {
                proceed = false;
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value for 'Other'.", 'error', 3000, null);
            }

            const potentialDupes = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}'] .name`);
            for (const potential of potentialDupes) {
                const span = <HTMLSpanElement>potential;

                if (span.textContent.toLowerCase() === locationText.toLowerCase()) {
                    proceed = false;
                    Core.createHTMLAlert("alertMessageDiv", "A location with this name has already been added. Please choose a different one.", 'error', 3000, null);
                }
            }

            if (proceed) {
                const dataModel = {
                    "PlanFK": planFK,
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "Row": row,
                    "Location": locationText,
                    "PlanPropertyPK": 0,
                    "PropertyPK": propertyPK
                };

                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };
                const response = await fetch(`/IUSpecialEducation/AddSupportStaffAndServicesLocation`, settings)
                if (response.ok) {
                    const output = await response.text();

                    const newDiv = document.createElement("div");
                    newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                    newDiv.dataset.planpropertypk = "0";
                    newDiv.dataset.row = row;
                    newDiv.classList.add("location-list-item");
                    newDiv.classList.add("locationItem");
                    newDiv.classList.add("medium-4");
                    newDiv.classList.add("column");
                    newDiv.classList.add("end");
                    newDiv.innerHTML = output;

                    const locationList = <HTMLDivElement>document.querySelector(`.locationList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    locationList.appendChild(newDiv);

                    this.bindDeleteLocationItems();

                    locationOtherInput.value = "";
                    locationDropdown.selectedIndex = 0;
                    this.locationDropdownChange(locationDropdown);

                    Core.createHTMLAlert("alertMessageDiv", "Location added.", 'success', 3000, null);
                }
            }
        }
    }

    bindDeleteLocationItems() {
        const deleteButtons = document.getElementsByClassName("deleteStaffLocationItem");
        for (const deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.deleteLocationItem(e));
        }
    }

    deleteLocationItem(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = parseInt(button.dataset.planpropertypk);
        const row = button.dataset.row;

        const item = <HTMLDivElement>document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);

        if (planPropertyPK === 0) {
            item.remove();
        } else {
            this.showDeleteLocation(button)
        }
    }

    showDeleteLocation(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const row = button.dataset.row;

        const deleteLocationModal: Modal = new Modal("deleteLocationModal", null);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "row", row);
        deleteLocationModal.show();
    }

    deleteLocationCancel() {
        const deleteLocationModal: Modal = new Modal("deleteLocationModal", null);
        deleteLocationModal.hide();
    }

    async deleteLocationConfirm(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const row = button.dataset.row;

        const deleteLocationModal: Modal = new Modal("deleteLocationModal", null);
        deleteLocationModal.hide();

        Core.showLoader();

        const item = <HTMLDivElement>document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);

        const response = await fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesLocation/${dataPointPlanPropertyPK}/${planPropertyPK}/${row}`, { credentials: 'include' })
        if (response.ok) {
            const json = await response.json();

            Core.hideLoader();
            if (json.success) {
                item.remove();
                Core.createHTMLAlert("alertMessageDiv", "The location was successfully removed.", 'success', 3000, null);
            }
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
        }
    }

    bindDeleteSupports() {
        const iuSpecialEducationSupportStaffDeleteSupports = document.getElementsByClassName("iuSpecialEducationSupportStaffDeleteSupport");
        for (const iuSpecialEducationSupportStaffDeleteSupport of iuSpecialEducationSupportStaffDeleteSupports)
            iuSpecialEducationSupportStaffDeleteSupport.addEventListener("click", (e: Event) => this.showDeleteSupport(e.target as HTMLButtonElement));
    }

    showDeleteSupport(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;

        const deleteSupportModal: Modal = new Modal("deleteSupportModal", null);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteSupportModal.show();
    }

    deleteSupportCancel() {
        const deleteSupportModal: Modal = new Modal("deleteSupportModal", null);
        deleteSupportModal.hide();
    }

    async deleteSupportConfirm(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;

        const accordion = <HTMLDivElement>document.querySelector(`.Accordion-panel.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const accordionTrigger = <HTMLButtonElement>document.querySelector(`.Accordion-trigger.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const accordionHeader = <HTMLElement>accordionTrigger.parentElement;

        
        const response = await fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesSupport/${dataPointPlanPropertyPK}`, { credentials: 'include' })
        if (response.ok) {
            const json = await response.json();

            if (json.success) {
                accordion.remove();
                accordionHeader.remove();
                Core.createHTMLAlert("alertMessageDiv", "The Support was successfully removed.", 'success', 3000, null);
            }
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the Support record, please try again.", 'error', 3000, null);

            const deleteSupportModal: Modal = new Modal("deleteSupportModal", null);
            deleteSupportModal.hide();
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }
    
    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const allAccordions = document.querySelectorAll(".Accordion-panel.supportStaffServices");
        for (const accordion of allAccordions) {
            const accordionEle = <HTMLDivElement>accordion;
            const dataPointPlanPropertyPK = accordionEle.dataset.datapointplanpropertypk;
            const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            let hasAtLeastOneRow = false;

            for (const thisRow of allRows) {
                let hasSomeValue: boolean = false;
                const ele = <HTMLDivElement>thisRow;
                const row = ele.dataset.row;

                const selects = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = <HTMLSelectElement>select;
                    const selectValue = parseInt(selectEle.value);
                    if (!isNaN(selectValue) && selectValue > 0)
                        hasSomeValue = true;
                }

                if (!hasSomeValue) {
                    const inputs = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);

                    for (const input of inputs) {
                        const inputElement = <HTMLInputElement>input;

                        if (inputElement.value !== "" && inputElement.value.trim() !== "")
                            hasSomeValue = true;
                    }

                    if (!hasSomeValue) {
                        const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);

                        if (locationItems && locationItems.length > 0)
                            hasSomeValue = true;
                    }
                } else {
                    //There is at least one item filled out. We need to make sure everything is filled out in this row now.

                    hasAtLeastOneRow = true;

                    const selects = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const select of selects) {
                        const selectEle = <HTMLSelectElement>select;
                        const selectValue = parseInt(selectEle.value);
                        if (isNaN(selectValue) || selectValue === 0) {
                            selectEle.classList.add("missing-field");
                            selectEle.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectEle);
                            showMessage = true;
                            totalErrors++;
                        }

                        if (selectEle.hasAttribute("data-hasother")) {
                            const selected = <HTMLOptionElement>selectEle[selectEle.selectedIndex];

                            if (selected.dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                                const otherField = <HTMLInputElement>document.querySelector(`.specialEducationIUSupportStaffOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);

                                if (otherField.value === "" || otherField.value.trim() === "") {
                                    otherField.classList.add("missing-field");
                                    otherField.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(otherField);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }

                    const inputs = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const input of inputs) {
                        const inputElement = <HTMLInputElement>input;


                        if (!inputElement.hasAttribute("data-other") && inputElement.value === "" && inputElement.value.trim() === "")
                        {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }

                    const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    if (locationItems === null || locationItems.length === 0)
                    {
                        const specialEducationIUContractLocationSelectField = <HTMLSelectElement>document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        if (specialEducationIUContractLocationSelectField !== null) {
                            specialEducationIUContractLocationSelectField.classList.add("missing-field");
                            specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }

            //If there isn't at least one row filled out for this (each) accordion, we need to flag it as incomplete.
            if (!hasAtLeastOneRow) {
                const firstRow = <HTMLDivElement>allRows[0];

                const row = firstRow.dataset.row;
                const selects = document.querySelectorAll(`.specialEducationIUIUSupportStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = <HTMLSelectElement>select;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }

                const locationSelect = document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if(locationSelect !== null) {
                    const selectEle = <HTMLSelectElement>locationSelect;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }

                const inputs = document.querySelectorAll(`.specialEducationIUIUSupportStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const input of inputs) {
                    const inputElement = <HTMLInputElement>input;
                    inputElement.classList.add("missing-field");
                    inputElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(inputElement);
                    showMessage = true;
                    totalErrors++;
                }

                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if (locationItems && locationItems.length > 0) {
                    const specialEducationIUContractLocationSelectField = <HTMLSelectElement>document.querySelector(`.specialEducationIUIUSupportLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    specialEducationIUContractLocationSelectField.classList.add("missing-field");
                    specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
           

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUIUSupportStaffForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUIUOperatedServicesProfileExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUIUOperatedServicesProfile.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    async search() {
        Core.showLoader();
        const form = <HTMLElement>document.getElementById("specialEducationIUIUSupportStaffForm");
        const planFK = form.dataset.planfk;
        const pageCode = form.dataset.pagecode;
        let fteId = "";
        let supportStaffServices = 0;
        const specialEducationIUProfilesIUSearchSupportServiceIUFTEId = <HTMLInputElement>document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUFTEId");
        if (specialEducationIUProfilesIUSearchSupportServiceIUFTEId !== null)
            fteId = specialEducationIUProfilesIUSearchSupportServiceIUFTEId.value;

        const specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices = <HTMLSelectElement>document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices");
        if (specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices !== null)
            supportStaffServices = parseInt(specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices.value);

        //Need the title, lookup, planfk and pagecode and a new method to do the search and replace the accordions in the container.
        const dataModel = {
            "PlanFK": planFK,
            "AccordionTitle": fteId,
            "PageCode": pageCode,
            "SupportStaffServicesLookupCodeFK": supportStaffServices
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };
        const response = await fetch(`/IUSpecialEducation/SearchSupportStaffAndServices`, settings)
        if (response.ok) {

            const output = await response.text();

            const iuOperatedServices = <HTMLDivElement>document.getElementById("iuOperatedServices");
            iuOperatedServices.innerHTML = output;

            specialEducationIUProfilesIUSearchSupportServiceIUFTEId.value = "";
            specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices.value = "0";

            Core.hideLoader();
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue with the search, please try again.", 'error', 3000, null);

            Core.hideLoader();
        }
    }

    async clearSearch() {
        const specialEducationIUProfilesIUSearchSupportServiceIUFTEId = <HTMLInputElement>document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUFTEId");
        if (specialEducationIUProfilesIUSearchSupportServiceIUFTEId !== null)
            specialEducationIUProfilesIUSearchSupportServiceIUFTEId.value = "";

        const specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices = <HTMLSelectElement>document.getElementById("specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices");
        if (specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices !== null)
            specialEducationIUProfilesIUSearchSupportServiceIUSupportStaffServices.value = "0";

        await this.search();
    }
}

// SpecialEducationIUContractStaff
class SpecialEducationIUContractStaff {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUContractStaffField", "specialEducationIUContractStaffSelectField"];

        let specialEducationIUContractStaffSaveButton = document.getElementById("specialEducationIUContractStaffSave");
        if (specialEducationIUContractStaffSaveButton !== null)
            specialEducationIUContractStaffSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let iuSpecialEducationFTEsAllocatedAddRowButton = document.getElementById("iuSpecialEducationContractStaffAddRow");
        if (iuSpecialEducationFTEsAllocatedAddRowButton !== null)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e: Event) => this.addRow(e));

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const specialEducationIUIUSupportStaffLocationDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteConfirm");
        if (specialEducationIUIUSupportStaffLocationDeleteConfirm !== null)
            specialEducationIUIUSupportStaffLocationDeleteConfirm.addEventListener("click", (e: Event) => this.deleteLocationConfirm(e.target as HTMLButtonElement));

        const specialEducationIUIUSupportStaffLocationDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffLocationDeleteCancel");
        if (specialEducationIUIUSupportStaffLocationDeleteCancel !== null)
            specialEducationIUIUSupportStaffLocationDeleteCancel.addEventListener("click", (e: Event) => this.deleteLocationCancel());

        const specialEducationIUIUSupportStaffSupportDeleteConfirm = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteConfirm");
        if (specialEducationIUIUSupportStaffSupportDeleteConfirm !== null)
            specialEducationIUIUSupportStaffSupportDeleteConfirm.addEventListener("click", (e: Event) => this.deleteSupportConfirm(e.target as HTMLButtonElement));

        const specialEducationIUIUSupportStaffSupportDeleteCancel = document.getElementById("specialEducationIUIUSupportStaffSupportDeleteCancel");
        if (specialEducationIUIUSupportStaffSupportDeleteCancel !== null)
            specialEducationIUIUSupportStaffSupportDeleteCancel.addEventListener("click", (e: Event) => this.deleteSupportCancel());

        const createSupportService = document.getElementById("createSupportService");
        if (createSupportService !== null)
            createSupportService.addEventListener("click", (e: Event) => this.addNewContractService());

        this.bindDeleteProgramRows();
        this.bindOtherDropdown();
        this.bindAddContractRowButtons();
        this.bindAddLocationButtons();
        this.setupOtherDropdowns();
        this.bindDeleteLocationItems();
        this.bindDeleteSupports();
        
        const specialEducationIUIUSupportStaffExportToExcel = document.getElementById("specialEducationIUContractStaffExportToExcel");
        if (specialEducationIUIUSupportStaffExportToExcel !== null) {
            specialEducationIUIUSupportStaffExportToExcel.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        const supportStaffServicesSearchButton = document.getElementById("supportStaffServicesSearchButton");
        supportStaffServicesSearchButton.addEventListener("click", (e: Event) => this.search());

        const supportStaffServicesClearButton = document.getElementById("supportStaffServicesClearButton");
        supportStaffServicesClearButton.addEventListener("click", (e: Event) => this.clearSearch());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const saveData = [];
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUContractStaffForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const accordions = document.querySelectorAll("button.supportStaffServices");
        for (const accordion of accordions) {
            const accordionElement = <HTMLDivElement>accordion;
            const dataPointPlanPropertyPK = accordionElement.dataset.datapointplanpropertypk;

            const fteRows = [];

            const rows = document.querySelectorAll(`.specialEducationIUContractStaffSelectWithOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const select of rows) {
                const allSaveElements = [];
                const element = <HTMLSelectElement>select;
                const row = element.dataset.row;

                let selects = false;
                let inputs = false;
                let locations = false;

                const specialEducationIUIUSupportStaffSelectForSaveFields = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffSelectForSaveField of specialEducationIUIUSupportStaffSelectForSaveFields) {
                    let planPropertyPK = 0;
                    let element = <HTMLSelectElement>specialEducationIUIUSupportStaffSelectForSaveField;
                    let rowNumber = element.dataset.row;
                    let propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (element.value !== "0" || hadValue) {
                        const saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: null,
                            LookupCodeFK: parseInt(element.value),
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };

                        allSaveElements.push(saveItem);

                        selects = true;
                    }
                }

                const specialEducationIUIUSupportStaffForSaveFields = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const specialEducationIUIUSupportStaffForSaveField of specialEducationIUIUSupportStaffForSaveFields) {
                    let planPropertyPK = 0;
                    const element = <HTMLInputElement>specialEducationIUIUSupportStaffForSaveField;
                    const rowNumber = element.dataset.row;
                    const propertyPK = parseInt(element.dataset.propertypk);
                    let hadValue = false;
                    if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                        planPropertyPK = parseInt(element.dataset.planpropertypk);
                        hadValue = true;
                    }

                    if (element.value !== "" || hadValue) {
                        const saveItem: IPlanPropertyExtended = {
                            PlanPropertyPK: planPropertyPK,
                            PlanFK: planPK,
                            PropertyFK: propertyPK,
                            TextValue: element.value,
                            LookupCodeFK: null,
                            RowNbr: parseInt(rowNumber),
                            IsDeletedInd: false,
                            DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                            ButtonRow: 0
                        };

                        allSaveElements.push(saveItem);

                        inputs = true;
                    }
                }

                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const locationItem of locationItems) {
                    const name = <HTMLSpanElement>locationItem.querySelector(`.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const rowNumber = name.dataset.row;
                    const propertyPK = name.dataset.propertypk;

                    let planPropertyPK = 0;
                    if (name.dataset.planpropertypk && name.dataset.planpropertypk !== "") {
                        planPropertyPK = parseInt(name.dataset.planpropertypk);
                    }

                    const saveItem: IPlanPropertyExtended = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: parseInt(propertyPK),
                        TextValue: name.textContent,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false,
                        DataPointPlanPropertyPK: parseInt(dataPointPlanPropertyPK),
                        ButtonRow: 0
                    };

                    allSaveElements.push(saveItem);

                    locations = true;
                }

                const rowToSave = {
                    "SaveElements": allSaveElements
                }

                if (selects || inputs || locations)
                    fteRows.push(rowToSave);
            }

            const thisAccordion = {
                "PlanFK": planPK,
                "FTERows": fteRows
            }

            saveData.push(thisAccordion);
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUContractStaff', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (saveData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    showDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;
        let dataPointPlanProperty = deleteButton.dataset.datapointplanpropertypk;

        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanProperty);
        modal.show();
    }

    bindDeleteProgramRows() {
        let allDeletes = document.getElementsByClassName("deleteContractStaffRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUContractStaffSelectWithOtherField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.dropdownChange(e.target));

        const locationSelectElements = document.getElementsByClassName("specialEducationIUContractLocationSelectField");
        for (const selectElement of locationSelectElements)
            selectElement.addEventListener("change", (e: Event) => this.locationDropdownChange(e.target));
    }

    dropdownChange(e) {
        const selectElement = <HTMLSelectElement>e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;

        const selectContainer = <HTMLDivElement>document.querySelector(`.buildingContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = <HTMLDivElement>document.querySelector(`.otherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUContractStaffOtherField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        } else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }

    async addRow(e: Event) {
        Core.showLoader();
        const numberOfRows = 1;
        const formElement = <HTMLDivElement>document.getElementById("specialEducationIUContractStaffForm");
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            const planFK = formElement.dataset.planfk;
            const that = this;
            let newRow = 0;

            const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (let row of allRows) {
                let rowEle = <HTMLDivElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/AddContactStaffAndServicesRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}`, { credentials: 'include' })
                if (response.ok) {
                    const output = await response.text();

                    const newDiv = <HTMLDivElement>document.createElement("div");
                    newDiv.classList.add("operatedServiceRow")
                    newDiv.classList.add("operated-service-row")
                    newDiv.classList.add("margin-bottom-large")
                    newDiv.dataset.row = newRow.toString();
                    newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK.toString();
                    newDiv.innerHTML = output;

                    const accordion = <HTMLDivElement>document.querySelector(`.supportStaffList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                    accordion.appendChild(newDiv);

                    this.bindOtherDropdown();
                    this.bindAddLocationButtons();
                    this.bindDeleteProgramRows();
                    this.bindDeleteSupports();
                }
            }

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "The row was successfully added.", 'success', 2000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", 'An error occured adding this row. Please try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        const allElements = document.querySelectorAll(`.specialEducationIUContractField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allElements) {
            const element = <HTMLElement>ele;
            const planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        const allLocations = document.querySelectorAll(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allLocations) {
            const element = <HTMLElement>ele;
            const planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowDataWithRelated', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindAddContractRowButtons() {
        const iuSpecialEducationFTEsAllocatedAddRowButtons = document.getElementsByClassName("iuSpecialEducationContractStaffAddRow");
        for (const iuSpecialEducationFTEsAllocatedAddRowButton of iuSpecialEducationFTEsAllocatedAddRowButtons)
            iuSpecialEducationFTEsAllocatedAddRowButton.addEventListener("click", (e: Event) => this.addRow(e));
    }

    async addNewContractService() {
        let staffServiceLookupCodeFK = 0;
        let fteId = "";

        const specialEducationIUIUSupportStaffForm = <HTMLElement>document.getElementById("specialEducationIUContractStaffForm");
        const planFK = specialEducationIUIUSupportStaffForm.dataset.planfk;

        const specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices = document.getElementById("specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices");
        if (specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices !== null) {
            const selectElement = <HTMLSelectElement>specialEducationIUProfilesContractSupportStaffServiceCreateContractedSupportServices;
            const lookupCodeFK = selectElement.value;

            staffServiceLookupCodeFK = parseInt(lookupCodeFK);
        }

        const specialEducationIUProfilesContractSupportStaffServiceCreateFTEId = document.getElementById("specialEducationIUProfilesContractSupportStaffServiceCreateFTEId");
        if (specialEducationIUProfilesContractSupportStaffServiceCreateFTEId !== null) {
            const inputElement = <HTMLInputElement>specialEducationIUProfilesContractSupportStaffServiceCreateFTEId;
            const textValue = inputElement.value;

            fteId = textValue;
        }


        if (fteId === "" || staffServiceLookupCodeFK === 0) {
            Core.createHTMLAlert("alertMessageDiv", "To add a record, enter a value in the FTE ID field and select a value from the Contracted Support/Service dropdown.", 'warning', 3000, null);
        } else {
            Core.showLoader();
            const dataModel = {
                "PlanFK": planFK,
                "FTEID": fteId,
                "StaffServiceLookupCodeFK": staffServiceLookupCodeFK
            };

            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataModel)
            };

            const response = await fetch(`/IUSpecialEducation/AddContractStaffAndServicesAccordion`, settings)
            if (response.ok) {
                const output = await response.text();

                let newDiv = <HTMLDivElement>document.createElement("div");
                newDiv.innerHTML = output;

                let container = document.getElementById("iuContractedStaffServices");
                container.append(newDiv);

                let idControl = newDiv.querySelector(".Accordion h2 button");
                let id = idControl.id;
                new CustomAccordion(id);

                const specialEducationIUProfilesIUCreateSupportServiceIUFTEId = <HTMLInputElement>document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUFTEId");
                if (specialEducationIUProfilesIUCreateSupportServiceIUFTEId !== null) {
                    specialEducationIUProfilesIUCreateSupportServiceIUFTEId.value = "";
                }

                const specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices = <HTMLSelectElement>document.getElementById("specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices");
                if (specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices !== null) {
                    specialEducationIUProfilesIUCreateSupportServiceIUSupportStaffServices.selectedIndex = 0;
                }

                this.bindAddLocationButtons();
                this.bindAddContractRowButtons();
                this.bindDeleteSupports();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The IU Support/Services record has been added.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding this record. Please try again.", 'error', 3000, null);
            }
        }
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUContractStaffForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUContractServicesProfileExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUContractServices.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    async search() {
        Core.showLoader();
        const form = <HTMLElement>document.getElementById("specialEducationIUContractStaffForm");
        const planFK = form.dataset.planfk;
        const pageCode = form.dataset.pagecode;
        let fteId = "";
        let supportStaffServices = 0;
        const specialEducationIUProfilesContractSupportStaffServicesSearchFTEId = <HTMLInputElement>document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchFTEId");
        if (specialEducationIUProfilesContractSupportStaffServicesSearchFTEId !== null)
            fteId = specialEducationIUProfilesContractSupportStaffServicesSearchFTEId.value;

        const specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices = <HTMLSelectElement>document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices");
        if (specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices !== null)
            supportStaffServices = parseInt(specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices.value);

        //Need the title, lookup, planfk and pagecode and a new method to do the search and replace the accordions in the container.
        const dataModel = {
            "PlanFK": planFK,
            "AccordionTitle": fteId,
            "PageCode": pageCode,
            "SupportStaffServicesLookupCodeFK": supportStaffServices
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataModel)
        };
        const response = await fetch(`/IUSpecialEducation/SearchSupportStaffAndServices`, settings)
        if (response.ok) {

            const output = await response.text();

            const iuOperatedServices = <HTMLDivElement>document.getElementById("iuContractedStaffServices");
            iuOperatedServices.innerHTML = output;

            specialEducationIUProfilesContractSupportStaffServicesSearchFTEId.value = "";
            specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices.value = "0";

            Core.hideLoader();
        } else {
            Core.createHTMLAlert("alertMessageDiv", "There was an issue with the search, please try again.", 'error', 3000, null);

            Core.hideLoader();
        }
    }

    async clearSearch() {
        const specialEducationIUProfilesContractSupportStaffServicesSearchFTEId = <HTMLInputElement>document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchFTEId");
        if (specialEducationIUProfilesContractSupportStaffServicesSearchFTEId !== null)
            specialEducationIUProfilesContractSupportStaffServicesSearchFTEId.value = "";

        const specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices = <HTMLSelectElement>document.getElementById("specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices");
        if (specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices !== null)
            specialEducationIUProfilesContractSupportStaffServicesSearchContractedSupportServices.value = "0";

        await this.search();
    }

    locationDropdownChange(e) {
        const selectElement = <HTMLSelectElement>e;
        const row = selectElement.dataset.row;
        const dataPointPlanPropertyFK = selectElement.dataset.datapointplanpropertypk;

        const selectContainer = <HTMLDivElement>document.querySelector(`.locationContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherContainer = <HTMLDivElement>document.querySelector(`.locationOtherContainer[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);
        const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUContractLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyFK}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            selectContainer.classList.add("short");
            otherContainer.classList.remove("hide");
            this._core.forceElementRequired(otherElement);
        } else {
            selectContainer.classList.remove("short");
            otherContainer.classList.add("hide");
            this._core.forceElementOptional(otherElement);
        }
    }

    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUContractStaffSelectWithOtherField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }

    bindAddLocationButtons() {
        const addLocation = document.getElementsByClassName("addLocation");
        for (const location of addLocation) {
            location.addEventListener("click", (e: Event) => this.addLocation(e));
        }
    }

    async addLocation(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const row = button.dataset.row;
        const propertyPK = button.dataset.propertypk;

        const form = <HTMLElement>document.getElementById("specialEducationIUContractStaffForm");
        const planFK = form.dataset.planfk;

        const locationDropdown = <HTMLSelectElement>document.querySelector(`.specialEducationIUContractLocationSelectField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const locationOtherInput = <HTMLInputElement>document.querySelector(`.specialEducationIUContractLocationOther[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);

        if (locationDropdown.value === "0") {
            Core.createHTMLAlert("alertMessageDiv", "Please select a location to add.", 'warning', 3000, null);
        } else {
            let locationText = "";
            let proceed: boolean = true;

            const locationDropdownOption = <HTMLOptionElement>locationDropdown[locationDropdown.selectedIndex];
            locationText = locationDropdownOption.text;
            if (locationDropdownOption.dataset.lookupcode === "specialEducationIUContractStaffLocationOther") {
                locationText = locationOtherInput.value;
            }

            if (locationDropdownOption.dataset.lookupcode === "specialEducationIUContractStaffLocationOther" && locationText === "") {
                proceed = false;
                Core.createHTMLAlert("alertMessageDiv", "Please enter a value for 'Other'.", 'error', 3000, null);
            }

            const potentialDupes = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}'] .name`);
            for (const potential of potentialDupes) {
                const span = <HTMLSpanElement>potential;

                if (span.textContent.toLowerCase() === locationText.toLowerCase()) {
                    proceed = false;
                    Core.createHTMLAlert("alertMessageDiv", "A location with this name has already been added. Please choose a different one.", 'error', 3000, null);
                }
            }

            if (proceed) {
                const dataModel = {
                    "PlanFK": planFK,
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "Row": row,
                    "Location": locationText,
                    "PlanPropertyPK": 0,
                    "PropertyPK": propertyPK
                };

                const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataModel)
                };
                const response = await fetch(`/IUSpecialEducation/AddSupportStaffAndServicesLocation`, settings)
                if (response.ok) {
                    const output = await response.text();

                    const newDiv = document.createElement("div");
                    newDiv.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
                    newDiv.dataset.planpropertypk = "0";
                    newDiv.dataset.row = row;
                    newDiv.classList.add("location-list-item");
                    newDiv.classList.add("locationItem");
                    newDiv.classList.add("medium-4");
                    newDiv.classList.add("column");
                    newDiv.classList.add("end");
                    newDiv.innerHTML = output;

                    const locationList = <HTMLDivElement>document.querySelector(`.locationList[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    locationList.appendChild(newDiv);

                    this.bindDeleteLocationItems();

                    locationOtherInput.value = "";
                    locationDropdown.selectedIndex = 0;
                    this.locationDropdownChange(locationDropdown);

                    Core.createHTMLAlert("alertMessageDiv", "Location added.", 'success', 3000, null);
                }
            }
        }
    }

    bindDeleteLocationItems() {
        const deleteButtons = document.getElementsByClassName("deleteStaffLocationItem");
        for (const deleteButton of deleteButtons) {
            deleteButton.addEventListener("click", (e: Event) => this.deleteLocationItem(e));
        }
    }

    deleteLocationItem(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = parseInt(button.dataset.planpropertypk);
        const row = button.dataset.row;

        const item = <HTMLDivElement>document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);

        if (planPropertyPK === 0) {
            item.remove();
        } else {
            this.showDeleteLocation(button)
        }
    }

    showDeleteLocation(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const row = button.dataset.row;

        const deleteLocationModal: Modal = new Modal("deleteLocationModal", null);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteLocationModal.addAttributeToElement(deleteLocationModal.id, "#specialEducationIUIUSupportStaffLocationDeleteConfirm", "row", row);
        deleteLocationModal.show();
    }

    deleteLocationCancel() {
        const deleteLocationModal: Modal = new Modal("deleteLocationModal", null);
        deleteLocationModal.hide();
    }

    async deleteLocationConfirm(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;
        const row = button.dataset.row;

        const deleteLocationModal: Modal = new Modal("deleteLocationModal", null);
        deleteLocationModal.hide();

        Core.showLoader();

        const item = <HTMLDivElement>document.querySelector(`.locationItem[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-planpropertypk='${planPropertyPK}']`);

        const response = await fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesLocation/${dataPointPlanPropertyPK}/${planPropertyPK}/${row}`, { credentials: 'include' })
        if (response.ok) {
            const json = await response.json();

            Core.hideLoader();
            if (json.success) {
                item.remove();
                Core.createHTMLAlert("alertMessageDiv", "The location was successfully removed.", 'success', 3000, null);
            }
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
        } else {
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the location record, please try again.", 'error', 3000, null);
        }
    }

    bindDeleteSupports() {
        const iuSpecialEducationSupportStaffDeleteSupports = document.getElementsByClassName("iuSpecialEducationSupportStaffDeleteSupport");
        for (const iuSpecialEducationSupportStaffDeleteSupport of iuSpecialEducationSupportStaffDeleteSupports)
            iuSpecialEducationSupportStaffDeleteSupport.addEventListener("click", (e: Event) => this.showDeleteSupport(e.target as HTMLButtonElement));
    }

    showDeleteSupport(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;

        const deleteSupportModal: Modal = new Modal("deleteSupportModal", null);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        deleteSupportModal.addAttributeToElement(deleteSupportModal.id, "#specialEducationIUIUSupportStaffSupportDeleteConfirm", "planpropertypk", planPropertyPK);
        deleteSupportModal.show();
    }

    deleteSupportCancel() {
        const deleteSupportModal: Modal = new Modal("deleteSupportModal", null);
        deleteSupportModal.hide();
    }

    async deleteSupportConfirm(button: HTMLButtonElement) {
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const planPropertyPK = button.dataset.planpropertypk;

        const accordion = <HTMLDivElement>document.querySelector(`.Accordion-panel.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const accordionTrigger = <HTMLButtonElement>document.querySelector(`.Accordion-trigger.supportStaffServices[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        const accordionHeader = <HTMLElement>accordionTrigger.parentElement;


        const response = await fetch(`/IUSpecialEducation/DeleteSupportStaffAndServicesSupport/${dataPointPlanPropertyPK}`, { credentials: 'include' })
        if (response.ok) {
            const json = await response.json();

            if (json.success) {
                accordion.remove();
                accordionHeader.remove();
                Core.createHTMLAlert("alertMessageDiv", "The record was successfully removed.", 'success', 3000, null);
            }
            else
                Core.createHTMLAlert("alertMessageDiv", "There was an issue deleting the record, please try again.", 'error', 3000, null);

            const deleteSupportModal: Modal = new Modal("deleteSupportModal", null);
            deleteSupportModal.hide();
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const allAccordions = document.querySelectorAll(".Accordion-panel.supportStaffServices");
        for (const accordion of allAccordions) {
            const accordionEle = <HTMLDivElement>accordion;
            const dataPointPlanPropertyPK = accordionEle.dataset.datapointplanpropertypk;
            const allRows = document.querySelectorAll(`.operatedServiceRow[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            let hasAtLeastOneRow = false;

            for (const thisRow of allRows) {
                let hasSomeValue: boolean = false;
                const ele = <HTMLDivElement>thisRow;
                const row = ele.dataset.row;

                const selects = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = <HTMLSelectElement>select;
                    const selectValue = parseInt(selectEle.value);
                    if (!isNaN(selectValue) && selectValue > 0)
                        hasSomeValue = true;
                }

                if (!hasSomeValue) {
                    const inputs = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);

                    for (const input of inputs) {
                        const inputElement = <HTMLInputElement>input;

                        if (inputElement.value !== "" && inputElement.value.trim() !== "")
                            hasSomeValue = true;
                    }

                    if (!hasSomeValue) {
                        const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);

                        if (locationItems && locationItems.length > 0)
                            hasSomeValue = true;
                    }
                } else {
                    //There is at least one item filled out. We need to make sure everything is filled out in this row now.

                    hasAtLeastOneRow = true;

                    const selects = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const select of selects) {
                        const selectEle = <HTMLSelectElement>select;
                        const selectValue = parseInt(selectEle.value);
                        if (isNaN(selectValue) || selectValue === 0) {
                            selectEle.classList.add("missing-field");
                            selectEle.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectEle);
                            showMessage = true;
                            totalErrors++;
                        }

                        if (selectEle.hasAttribute("data-hasother")) {
                            const selected = <HTMLOptionElement>selectEle[selectEle.selectedIndex];

                            if (selected.dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                                const otherField = <HTMLInputElement>document.querySelector(`.specialEducationIUContractStaffOtherField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);

                                if (otherField.value === "" || otherField.value.trim() === "") {
                                    otherField.classList.add("missing-field");
                                    otherField.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(otherField);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        }
                    }

                    const inputs = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    for (const input of inputs) {
                        const inputElement = <HTMLInputElement>input;


                        if (!inputElement.hasAttribute("data-other") && inputElement.value === "" && inputElement.value.trim() === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    }

                    const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    if (locationItems === null || locationItems.length === 0) {
                        const specialEducationIUContractLocationSelectField = <HTMLSelectElement>document.querySelector(`.specialEducationIUContractLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                        if (specialEducationIUContractLocationSelectField !== null) {
                            specialEducationIUContractLocationSelectField.classList.add("missing-field");
                            specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                            showMessage = true;
                            totalErrors++;
                        }
                    }
                }
            }

            //If there isn't at least one row filled out for this (each) accordion, we need to flag it as incomplete.
            if (!hasAtLeastOneRow) {
                const firstRow = <HTMLDivElement>allRows[0];

                const row = firstRow.dataset.row;
                const selects = document.querySelectorAll(`.specialEducationIUContractStaffSelectForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const select of selects) {
                    const selectEle = <HTMLSelectElement>select;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }

                const locationSelect = document.querySelector(`.specialEducationIUContractLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if (locationSelect !== null) {
                    const selectEle = <HTMLSelectElement>locationSelect;
                    selectEle.classList.add("missing-field");
                    selectEle.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(selectEle);
                    showMessage = true;
                    totalErrors++;
                }

                const inputs = document.querySelectorAll(`.specialEducationIUContractStaffForSaveField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                for (const input of inputs) {
                    const inputElement = <HTMLInputElement>input;
                    inputElement.classList.add("missing-field");
                    inputElement.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(inputElement);
                    showMessage = true;
                    totalErrors++;
                }

                const locationItems = document.querySelectorAll(`.locationItem[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                if (locationItems && locationItems.length > 0) {
                    const specialEducationIUContractLocationSelectField = <HTMLSelectElement>document.querySelector(`.specialEducationIUContractLocationSelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    specialEducationIUContractLocationSelectField.classList.add("missing-field");
                    specialEducationIUContractLocationSelectField.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(specialEducationIUContractLocationSelectField);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }
        
        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    initializeRequiredFieldsCustom(allClasses: string[], refresh: boolean = false, allowDuplicates = false) {

        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        const classesToValidate = formattedAllClasses.join(",");

        if (refresh) {
            const allElements = document.querySelectorAll(classesToValidate);

            for (const element of allElements) {
                const htmlElement = <HTMLElement>element;

                htmlElement.removeAttribute("aria-required");
                const label = Core.findLabelForInput(htmlElement);

                if (label !== null) {
                    label.classList.remove("isRequired");
                    const asterisk = label.querySelector(".required-label") as HTMLElement;
                    if (asterisk != null) {
                        asterisk.parentNode.removeChild(asterisk);
                    }
                }
            }
        }

        const allElements = document.querySelectorAll(classesToValidate);

        for (const element of allElements) {
            const htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    const label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }
}

// SpecialEducationIUFacilities
class SpecialEducationIUFacilities {
    validationClasses: string[];
    static staticValidationClasses: string[];
    planFK: number;
    static staticPlanFK: number;

    private static facilityHashes: Object;
    private _core: Core;
    static _staticCore: Core;
    constructor() {
        this._core = new Core();
        let that = this;
        this.validationClasses = ["specialEducationIUFacilitiesField", "specialEducationSpecialEducationFacilitiesField"];
        SpecialEducationIUFacilities.staticValidationClasses = ["specialEducationIUFacilitiesField", "specialEducationSpecialEducationFacilitiesField"];
        SpecialEducationIUFacilities._staticCore = new Core();

        if (this._core.Workflows.length > 0) {
            for (let seprnWorkflow of this._core.Workflows) {
                SpecialEducationSpecialEducationFacilities.initializeSEPRNWorkflow(seprnWorkflow);
            }
        }

        this._core.leftnav(this);

        new SpecialEducationIUFacilitiesLazyAccordion();
        SpecialEducationIUFacilities.initializeFacilityHashes();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        // Get form element and PlanFK
        const form = document.getElementById("specialEducationIUFacilitiesForm");
        if (form != null) {
            this.planFK = parseInt(form.dataset.planfk);
            SpecialEducationIUFacilities.staticPlanFK = parseInt(form.dataset.planfk);
        }

        // Set search event on search button
        const searchButton = <HTMLButtonElement>document.getElementById("facilitiesSearchButton");
        if (searchButton !== null) {
            //1. Save
            //2. Run Search
            searchButton.addEventListener("click", () => {
                Core.showLoader();
                SpecialEducationIUFacilities.promiseSave().then(() => {
                    return this.search();

                }).then(() => {
                    // reinitialize accordions
                    new SpecialEducationIUFacilitiesLazyAccordion();
                    Core.hideLoader();

                    SpecialEducationIUFacilities.constructAutofillInputs();
                    SpecialEducationIUFacilities.constructFileUploadElements();
                    SpecialEducationIUFacilities.constructFileDeleteElements();

                    that._core.initializeWorkflows();
                    if (that._core.Workflows.length > 0) {
                        for (let seprnWorkflow of this._core.Workflows) {
                            SpecialEducationIUFacilities.initializeSEPRNWorkflow(seprnWorkflow);
                        }
                    }

                    Tab.Init();
                }).catch((error) => {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                });
            });
        }

        // Set clear event on clear button
        const clearButton = document.getElementById("facilitiesClearButton");
        if (clearButton !== null) {
            clearButton.addEventListener("click", () => {
                this.clearSearch();
            });
        }

        // Set file upload/delete events
        SpecialEducationIUFacilities.constructAutofillInputs();
        SpecialEducationIUFacilities.constructFileUploadElements();
        SpecialEducationIUFacilities.constructFileDeleteElements();

        // Set add a location event on button
        const addLocationButton = document.getElementById('specialEducationSpecialEducationFacilitiesAddALocation');
        if (addLocationButton != null) {
            addLocationButton.addEventListener('click', () => {
                Core.showLoader();
                this.addLocation()
                    .then((response) => {
                        Core.hideLoader();
                        this._core.initializeRequiredFields(this.validationClasses);
                        Core.createHTMLAlert('alertMessageDiv', response, 'success', 3000, null);
                        SpecialEducationIUFacilities.constructAutofillInputs();
                        SpecialEducationIUFacilities.constructFileUploadElements();
                        SpecialEducationIUFacilities.constructFileDeleteElements();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
                    });
            });
        }

        document.addEventListener("click", (e) => {
            const target = e.target as HTMLElement;

            //Save
            if (target.classList.contains("facilitiesSave") && "facilitypk" in target.dataset) {
                const facilityFK = target.dataset.facilitypk;

                Core.showLoader();
                SpecialEducationIUFacilities.promiseSave(parseInt(facilityFK)).then((response) => {
                    Core.hideLoader();
                    SpecialEducationIUFacilities.initializeFacilityHashes();
                    SpecialEducationIUFacilities.handleFacilitySaveDisabled(parseInt(facilityFK));
                    SpecialEducationIUFacilities.validateElements(facilityFK);
                    SpecialEducationIUFacilities.initializeLoadedFacilityFields(facilityFK);
                    SpecialEducationIUFacilities.fullPageValidation();
                });
            }

            if (target.classList.contains("createSEPRNButton") && "facilityPk" in target.dataset) {
                SpecialEducationIUFacilities.createSEPRN(parseInt(target.dataset.facilityPk));
            } else if (target.classList.contains("showDifferences") && "facilityPk" in target.dataset) {
                that.showSEPRNDifferences(parseInt(target.dataset.facilityPk));
            } else if (target.classList.contains("iuFacilityDelete")) {
                that.showDeleteFacilityConfirmation(<HTMLButtonElement>target);
            }
        });

        const deleteFacilityConfirmButton = <HTMLButtonElement>document.getElementById("deleteFacilityConfirm");
        if (deleteFacilityConfirmButton !== null)
            deleteFacilityConfirmButton.addEventListener("click", (e: Event) => this.deleteSpecialEducationFacility(deleteFacilityConfirmButton));

        const deleteFacilityCancelButton = document.getElementById("deleteFacilityCancel");
        if (deleteFacilityCancelButton !== null)
            deleteFacilityCancelButton.addEventListener("click", (e: Event) => this.deleteFacilityCancel());

        document.addEventListener("input", (e) => {
            const target = e.target as HTMLElement;
            if ("facilityPK" in target.dataset)
                SpecialEducationIUFacilities.handleFacilitySaveDisabled(parseInt(target.dataset.facilityPk));
        });

        document.addEventListener("change", (e) => {
            let target = e.target as HTMLElement;
            SpecialEducationIUFacilities.handleFacilitySaveDisabled(parseInt(target.dataset.facilityPk));
            if (target.classList.contains("proposedAction") && "facilityPk" in target.dataset) {
                let proposedActionElement = <HTMLSelectElement>target;
                let selectedProposedAction = <HTMLOptionElement>proposedActionElement.options[proposedActionElement.selectedIndex];
                if (selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    //Open up editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']`);
                    for (let editable of allEditable) {
                        editable.removeAttribute("readonly");
                        editable.removeAttribute("disabled");
                    }
                } else {
                    //disable editable fields
                    let allEditable = document.querySelectorAll(`[data-editable][data-facility-pk='${proposedActionElement.dataset.facilityPk}']:not([data-propertypk='1184'])`);
                    for (let editable of allEditable) {
                        if (editable instanceof HTMLInputElement) {

                            if (editable.hasAttribute("type") && editable.getAttribute("type") == "file" || editable.getAttribute("type") == "radio") {
                                editable.disabled = true;
                            } else {
                                editable.readOnly = true;
                            }

                        } else if (editable instanceof HTMLSelectElement || editable instanceof HTMLButtonElement) {
                            editable.disabled = true;
                        }
                    }
                }

                var additionalInfo = document.querySelector(`.additionalInformation[data-facility-pk='${proposedActionElement.dataset.facilityPk}']`) as HTMLElement;
                if (selectedProposedAction.dataset.lookupCode == "proposedActionDelete" || selectedProposedAction.dataset.lookupCode == "proposedActionChange") {
                    if (additionalInfo != null) {
                        that._core.forceElementRequired(additionalInfo);
                    }
                } else {
                    if (additionalInfo != null) {
                        that._core.forceElementOptional(additionalInfo);
                    }
                }
            } else if (target.classList.contains("operatedInRequiredFields")) {
                this.checkForRequiredFieldsFromOperatedIn(target as HTMLSelectElement);
            }
        });

        let closeSEPRNCompareButton = document.getElementById('closeModalcompareFacilitiesModal') as HTMLButtonElement;
        if (closeSEPRNCompareButton != null) {
            closeSEPRNCompareButton.addEventListener("click", () => {
                const modal: Modal = new Modal('compareFacilitiesModal', null);
                modal.hide();
            });
        }

        const facilitiesExportToExcelButton = document.getElementById("specialEducationFacilitiesExportToExcel");
        if (facilitiesExportToExcelButton !== null) {
            facilitiesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        const buildingNamesForOther = document.getElementsByClassName("buildingName");
        for (const buildingName of buildingNamesForOther)
            buildingName.addEventListener("change", (e: Event) => this.checkForBuildingOther(e.target as HTMLSelectElement));

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let refreshPage = '';
        Core.showLoader();

        if (referrer === 'continue') {
            refreshPage = document.getElementById('continueButton').getAttribute('data-redirect-url');
        }
        if (referrer === 'back') {
            refreshPage = document.getElementById('backButton').getAttribute('data-redirect-url');
        }
        else {
            refreshPage = referrer;
        }

        let forceSave = false;
        if (referrer === 'save') {
            forceSave = true;
        }

        SpecialEducationIUFacilities.promiseSave(null, forceSave).then((response) => {
            Core.hideLoader();
            if (referrer === 'save') {
                //Reset query so that all results show
                window.location.href = '/IUSpecialEducation/SpecialEducationIUFacilities/' + this.planFK + '?fromSave=true';
            } else if (refreshPage && refreshPage !== '') {
                window.location.href = refreshPage;
            }
        }).catch((error) => {
            Core.createHTMLAlert('alertMessageDiv', error, 'error', 3000, null);
            Core.hideLoader();
        });
    }

    constructAutofillInputsSEPRN() {
        // Get measurement values from the inputs
        const originalAreaMeasurements = {
            WidthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .feet.width-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#originalContainer .feet.width-ft`)).value),
            },
            WidthInches: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .inches.width-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.width-in`)).value)
            },
            LengthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .feet.length-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#originalContainer .feet.length-ft`)).value),
            },
            LengthInches: {
                Element: <HTMLInputElement>document.querySelector(`#originalContainer .inches.length-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#originalContainer .inches.length-in`)).value)
            }
        };

        // Calculate total inches (inches + feet*12) 
        const originalTotalWidthInches = originalAreaMeasurements.WidthInches.Value + (originalAreaMeasurements.WidthFeet.Value * 12);
        const originalTotalLengthInches = originalAreaMeasurements.LengthInches.Value + (originalAreaMeasurements.LengthFeet.Value * 12);

        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const originalAreaInches = originalTotalWidthInches * originalTotalLengthInches;
        const originalAreaSqFeet = Math.floor(originalAreaInches / 144);
        const originalMaxStudents = Math.floor(originalAreaSqFeet / 28);

        // Update UI
        const originalAreaEle = document.querySelector(`#originalContainer .specialEducationFacilitiesAreaMeasurement`);
        (<HTMLInputElement>originalAreaEle).value = `${originalAreaSqFeet}sqft`;
        const originalMaxStudentsEle = document.querySelector(`#originalContainer .specialEducationFacilitiesMaxStudents`);
        (<HTMLInputElement>originalMaxStudentsEle).value = `${originalMaxStudents}`;


        // Get measurement values from the inputs
        const newAreaMeasurements = {
            WidthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .feet.width-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#newContainer .feet.width-ft`)).value),
            },
            WidthInches: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .inches.width-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.width-in`)).value)
            },
            LengthFeet: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .feet.length-ft`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#newContainer .feet.length-ft`)).value),
            },
            LengthInches: {
                Element: <HTMLInputElement>document.querySelector(`#newContainer .inches.length-in`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`#newContainer .inches.length-in`)).value)
            }
        };

        // Calculate total inches (inches + feet*12) 
        const newTotalWidthInches = newAreaMeasurements.WidthInches.Value + (newAreaMeasurements.WidthFeet.Value * 12);
        const newTotalLengthInches = newAreaMeasurements.LengthInches.Value + (newAreaMeasurements.LengthFeet.Value * 12);

        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const newAreaInches = newTotalWidthInches * newTotalLengthInches;
        const newAreaSqFeet = Math.floor(newAreaInches / 144);
        const newMaxStudents = Math.floor(newAreaSqFeet / 28);

        // Update UI
        const newAreaEle = document.querySelector(`#newContainer .specialEducationFacilitiesAreaMeasurement`);
        (<HTMLInputElement>newAreaEle).value = `${newAreaSqFeet}sqft`;
        const newMaxStudentsEle = document.querySelector(`#newContainer .specialEducationFacilitiesMaxStudents`);
        (<HTMLInputElement>newMaxStudentsEle).value = `${newMaxStudents}`;
    }
    
    addLocation() {
        const buildingNameEle = document.getElementById("specialEducationIUProfilesFacilitiesAddBuildingName") as HTMLSelectElement;
        const buildingName = buildingNameEle.value;
        let buildingNameOther = "";
        const whichOne = "new";
        if (buildingName.toLowerCase() === "other") {

            const buildingNameOtherEle = document.querySelector(`.otherElement[data-field='${whichOne}']`) as HTMLInputElement;
            buildingNameOther = buildingNameOtherEle.value;
        }
        const roomDescInput = document.getElementById('specialEducationIUProfilesFacilitiesRoom') as HTMLInputElement;
        const roomDesc = roomDescInput.value;

        const that = this;

        return new Promise((resolve, reject) => {
            const data: IIUFacility = {
                SpecialEducationIUFacilityPK: 0,
                PlanFK: this.planFK,
                RoomDesc: roomDesc,
                BuildingName: buildingName,
                BuildingNameOther: buildingNameOther
            };

            if (roomDescInput != null && roomDesc != "" && buildingName != null) {
                if (buildingName.toLowerCase() !== "other" && buildingNameOther === "") {
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', '/IUSpecialEducation/CreateNewIUFacility', true);
                    xhr.setRequestHeader('Content-type', 'application/json');
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            const facilityList = document.getElementById('facilitiesContainerInner');
                            if (facilityList != null) {
                                $(facilityList).prepend(xhr.responseText);

                                const newFacilityAccordion = facilityList.querySelector(`.lazy-accordion-trigger`) as HTMLButtonElement;
                                if (newFacilityAccordion != null) {
                                    new SpecialEducationIUFacilitiesLazyAccordion(newFacilityAccordion.id);
                                    newFacilityAccordion.setAttribute("data-isnew", "true");
                                }

                                SpecialEducationIUFacilities.clearValidationMessage();
                                SpecialEducationIUFacilities.fullPageValidation();
                                SpecialEducationIUFacilities.planPageCompleteCheck();

                                resolve('Successfully created new facility.');

                                SpecialEducationIUFacilities.bindOperatedByChanges();
                            }
                        } else {
                            reject('There was an unexpected error creating a new facility, please try again.');
                        }
                    };
                    xhr.send(JSON.stringify(data));
                } else {
                    reject("A value for 'Other' must be specified when selecting 'Other' for the Building Name");
                }
            } else {
                reject('Both a Building and a Room must be specified to add a location.');
            }
        });
    }
    
    search(fromHistory: boolean = false) {
        const frcppInstitutionSelect = document.getElementById('specialEducationIUProfilesFacilitiesSearchBuilding') as HTMLSelectElement;
        const frcppInstitutionFK = frcppInstitutionSelect != null && frcppInstitutionSelect.selectedIndex > 0
            ? parseInt(frcppInstitutionSelect[frcppInstitutionSelect.selectedIndex].dataset.lookupCode)
            : null;

        const roomDescInput = document.getElementById('specialEducationIUProfilesFacilitiesSearchRoom') as HTMLInputElement;
        const roomDesc = roomDescInput != null && roomDescInput.value != ''
            ? roomDescInput.value
            : null;

        let query = {
            PlanFK: this.planFK,
            FRCPPInstitutionFK: frcppInstitutionFK,
            RoomDesc: roomDesc
        };

        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/FacilitiesSearchJSON', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const container = document.getElementById('facilitiesContainer');
                    if (container) {
                        container.innerHTML = xhr.responseText;

                        resolve();
                    }
                    else {
                        reject("There was an error loading the facilities, please try again.");
                    }
                } else {
                    reject("There was an error loading the facilities, please try again.");
                }
            };
            xhr.send(JSON.stringify(query));
        });
    }

    clearSearch() {
        const frcppInstitutionSelect = document.getElementById("specialEducationFacilitiesBuildingName") as HTMLSelectElement;
        const roomDescInput = document.getElementById("specialEducationFacilitiesRoomNumber") as HTMLInputElement;

        if (frcppInstitutionSelect != null) {
            frcppInstitutionSelect.selectedIndex = 0;
        }

        if (roomDescInput != null) {
            roomDescInput.value = '';
        }

        Core.showLoader();
        SpecialEducationIUFacilities.promiseSave()
            .then(() => {
                return this.search(true);
            })
            .then(() => {
                //reinitialize accordions
                new SpecialEducationIUFacilitiesLazyAccordion(null);
                Core.hideLoader();

                SpecialEducationIUFacilities.initializeFacilityHashes();
                SpecialEducationIUFacilities.constructAutofillInputs();
                SpecialEducationIUFacilities.constructFileUploadElements();
                SpecialEducationIUFacilities.constructFileDeleteElements();
            })
            .catch((error) => {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
            });

        //history.pushState(null, '', '/SpecialEducation/SpecialEducationEducationProgramCaseloadFTE/' + that.planFK);
        //document.title = "Education Program (Caseload FTE) - Future Ready Comprehensive Planning Portal";
    }

    showSEPRNDifferences(facilityPK: number) {
        let that = this;
        const xhr = new XMLHttpRequest();
        Core.showLoader();
        xhr.open('GET', '/IUSpecialEducation/GetFacilityComparison?facilityPK=' + facilityPK, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();
                const modal: Modal = new Modal('compareFacilitiesModal', null);
                modal.show();
                const compareFacilitiesBody = document.getElementById('compareFacilitiesBody');
                if (compareFacilitiesBody != null) {
                    $(compareFacilitiesBody).html(xhr.responseText);
                    that.constructAutofillInputsSEPRN();
                    that.highlightChangedFieldsSEPRN();
                }
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred', 'error', 3000, null);
            }
        };
        xhr.send();
    }

    highlightChangedFieldsSEPRN() {
        let originalFields = document.querySelectorAll('#originalContainer .specialEducationSpecialEducationFacilitiesField');
        for (let field of originalFields) {
            let fieldElement = <HTMLElement>field;

            let usedRadios: string[] = [];
            if (fieldElement instanceof HTMLInputElement) {
                let correspondingFieldElement = <HTMLInputElement>document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if (fieldElement.hasAttribute("type") && fieldElement.type == "radio" && usedRadios.indexOf(fieldElement.name) == -1) {
                    usedRadios.push(fieldElement.name);
                    //Get checked value of original and compare to new
                    let checkedOriginal = document.querySelector(`[name='${fieldElement.name}']:checked`) as HTMLInputElement;
                    let checkedNew = document.querySelector(`[name='${correspondingFieldElement.name}']:checked`) as HTMLInputElement;

                    if (checkedOriginal != null && checkedNew != null && checkedOriginal.value != checkedNew.value) {
                        let trContainer = Core.findClosest(checkedNew, "tr");
                        if (trContainer != null) {
                            trContainer.classList.add("different");
                        }
                    }
                }
                else {
                    if (fieldElement.value != correspondingFieldElement.value) {
                        correspondingFieldElement.classList.add("different");
                    }
                }
            } else if (fieldElement instanceof HTMLSelectElement) {
                let correspondingFieldElement = <HTMLSelectElement>document.querySelector(`#newContainer [data-propertypk='${fieldElement.dataset.propertypk}']`);
                if (fieldElement.value != correspondingFieldElement.value) {
                    correspondingFieldElement.classList.add("different");
                }
            }
        }

        //Files
        let originalFile = document.querySelector('#originalContainer .uploadFileColumn[data-fileupload-pk]') as HTMLElement;
        let newFile = document.querySelector('#newContainer .uploadFileColumn[data-fileupload-pk]') as HTMLElement;

        if ((originalFile != null && newFile != null && originalFile.dataset.fileuploadPk != newFile.dataset.fileuploadPk) || (originalFile == null && newFile != null)) {
            let fileContainer = newFile.querySelector('.uploaded-file-container') as HTMLElement;
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        } else if (originalFile != null && newFile == null) {
            let fileContainer = originalFile.querySelector('.uploaded-file-container') as HTMLElement;
            if (fileContainer != null) {
                fileContainer.classList.add("different");
            }
        }
    }

    showDeleteFacilityConfirmation(deleteButton: HTMLButtonElement) {
        const facilityPK = deleteButton.dataset.facilityPk;
        const modal: Modal = new Modal('deleteFacilityModal', null);
        modal.addAttributeToElement("deleteFileModal", "#deleteFacilityConfirm", "facilitypk", facilityPK);

        const id = <HTMLButtonElement>document.getElementById("deleteFacilityConfirm");
        id.dataset.facilitypk = facilityPK;

        modal.show();
    }

    deleteFacilityCancel() {
        const modal: Modal = new Modal('deleteFacilityModal', null);
        modal.hide();
    }

    deleteSpecialEducationFacility(button: HTMLButtonElement) {
        const facilityFK = button.dataset.facilitypk;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteSpecialEducationFacility', true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.onload = () => {
            const response = JSON.parse(xhr.response)
            if (xhr.status === 200 && response.success) {
                const accordionTrigger = <HTMLButtonElement>document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${facilityFK}']`);
                accordionTrigger.parentElement.parentElement.remove();

                const modal: Modal = new Modal("deleteFacilityModal", null);
                modal.hide();
                Core.createHTMLAlert('alertMessageDiv', 'Successfully deleted the facility', 'success', 3000, null);
            } else {
                Core.createHTMLAlert('alertMessageDiv', 'An unexpected error occurred deleting the facility, please try again.', 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(facilityFK));
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUFacilitiesForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUFacilitiesExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `IUSpecialEducationFacilties.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    customValidation(facilityPK: number = null) {

        let hasInputErrors = false;
        let hasSelectErrors = false;
        let errorElements = [];
        let totalErrors = 0;
        let showMessage: boolean = false;

        let parent = <Element>(document as unknown);
        if (facilityPK != null) {
            let facility = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
            if (facility != null) {
                parent = facility;
            }
        }

        const roomDescInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.room-desc');

        for (let i of roomDescInputs) {
            const input = <HTMLInputElement>i;
            if (!input.value) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            } else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }

        const footInputs = parent.querySelectorAll('input.specialEducationSpecialEducationFacilitiesField.feet');

        for (let i of footInputs) {
            const input = <HTMLInputElement>i;
            var inputValue = parseInt(input.value);
            if (inputValue === 0) {
                input.classList.add('missing-field');
                input.setAttribute('aria-invalid', 'true');
                hasInputErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: input, type: 'input' });
            } else {
                input.classList.remove('missing-field');
                input.removeAttribute('aria-invalid');
            }
        }

        const requriedSelects = parent.querySelectorAll('select.specialEducationSpecialEducationFacilitiesField.required-facility-field');

        for (let s of requriedSelects) {
            const select = <HTMLSelectElement>s;
            if (select.value === '0') {
                Core.createHTMLAlert('alertMessageDiv', 'Please select a school building and building description', 'error', 3000, null);
                select.classList.add('missing-field');
                select.setAttribute('aria-invalid', 'true');
                hasSelectErrors = true;
                totalErrors++;
                showMessage = true;
                errorElements.push({ element: select, type: 'select' });
            } else {
                select.classList.remove('missing-field');
                select.removeAttribute('aria-invalid');
            }
        }

        for (let element of errorElements) {
            const accordion = element.element.closest('.Accordion-panel');
            accordion.classList.add('open');
        }
        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    firstFocusableEl.focus();
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }

        return !(hasInputErrors || hasSelectErrors);
    }

    checkForBuildingOther(select: HTMLSelectElement) {
        const thisVal = select.value;
        const whichOne = select.dataset.field;

        const otherContainer = document.querySelector(`.otherContainer[data-field='${whichOne}']`);
        const buildingContainer = document.querySelector(`.buildingContainer[data-field='${whichOne}']`);
        const otherElement = <HTMLInputElement>document.querySelector(`.otherElement[data-field='${whichOne}']`);
        if (thisVal.toLowerCase() === "other") {
            this._core.forceElementRequired(otherElement);
            otherContainer.classList.remove("hide");
            buildingContainer.classList.add("short");
        } else {
            this._core.forceElementOptional(otherElement);
            otherContainer.classList.add("hide");
            buildingContainer.classList.remove("short");
        }
    }

    checkForRequiredFieldsFromOperatedIn(select: HTMLSelectElement) {
        const selectedItem = <HTMLOptionElement>select[select.selectedIndex];

        const facilityFK = selectedItem.dataset.facilityfk;

        const operatedInContainer = <HTMLDivElement>document.querySelector(`.operatedInContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherContainer = <HTMLDivElement>document.querySelector(`.operatedInOtherContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherElement = <HTMLInputElement>document.querySelector(`.operatedInOtherElement[data-facility-pk='${facilityFK}']`);
        const operatedInLEAContainer = <HTMLDivElement>document.querySelector(`.operatedInLEANameContainer[data-facilityfk='${facilityFK}']`);
        const operatedInLEAElement = <HTMLInputElement>document.querySelector(`.operatedInLEAName[data-facility-pk='${facilityFK}']`);

        
        if (selectedItem.dataset.lookup === "specialEducationIUOperatedInOther") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.remove("hide");
            this._core.forceElementRequired(operatedInOtherElement);

            operatedInLEAContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInLEAElement);
        } else if (selectedItem.dataset.lookup === "specialEducationIUOperatedInLEA") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInOtherElement);

            operatedInLEAContainer.classList.remove("hide");
            this._core.forceElementRequired(operatedInLEAElement);
        } else {
            operatedInContainer.classList.remove("short");
            operatedInOtherContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInOtherElement);

            operatedInLEAContainer.classList.add("hide");
            this._core.forceElementOptional(operatedInLEAElement);
        }
    }

    static checkForBuildingOtherStatic(facilityFK: number) {
        const select = <HTMLSelectElement>document.querySelector(`.building-name[data-facility-pk='${facilityFK}']`);
        const thisVal = select.value;

        const otherContainer = document.querySelector(`.otherContainer[data-facility-pk='${facilityFK}']`);
        const buildingContainer = document.querySelector(`.buildingContainer[data-facility-pk='${facilityFK}']`);
        const otherElement = <HTMLInputElement>document.querySelector(`.otherElement[data-facility-pk='${facilityFK}']`);
        if (thisVal.toLowerCase() === "other") {
            this._staticCore.forceElementRequired(otherElement);
            otherContainer.classList.remove("hide");
            buildingContainer.classList.add("short");
        } else {
            this._staticCore.forceElementOptional(otherElement);
            otherContainer.classList.add("hide");
            buildingContainer.classList.remove("short");
        }
    }

    static checkForRequiredFieldsFromOperatedInStatic(facilityFK: number) {
        const select = <HTMLSelectElement>document.querySelector(`.operated-in[data-facility-pk='${facilityFK}']`);
        const selectedItem = <HTMLOptionElement>select[select.selectedIndex];

        const operatedInContainer = <HTMLDivElement>document.querySelector(`.operatedInContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherContainer = <HTMLDivElement>document.querySelector(`.operatedInOtherContainer[data-facilityfk='${facilityFK}']`);
        const operatedInOtherElement = <HTMLInputElement>document.querySelector(`.operatedInOtherElement[data-facility-pk='${facilityFK}']`);
        const operatedInLEAContainer = <HTMLDivElement>document.querySelector(`.operatedInLEANameContainer[data-facilityfk='${facilityFK}']`);
        const operatedInLEAElement = <HTMLInputElement>document.querySelector(`.operatedInLEAName[data-facility-pk='${facilityFK}']`);

        if (selectedItem.dataset.lookup === "specialEducationIUOperatedInOther") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.remove("hide");
            this._staticCore.forceElementRequired(operatedInOtherElement);

            operatedInLEAContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInLEAElement);
        } else if (selectedItem.dataset.lookup === "specialEducationIUOperatedInLEA") {
            operatedInContainer.classList.add("short");
            operatedInOtherContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInOtherElement);

            operatedInLEAContainer.classList.remove("hide");
            this._staticCore.forceElementRequired(operatedInLEAElement);
        } else {
            operatedInContainer.classList.remove("short");
            operatedInOtherContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInOtherElement);

            operatedInLEAContainer.classList.add("hide");
            this._staticCore.forceElementOptional(operatedInLEAElement);
        }
    }

    static constructAutofillInputs() {
        const areaMeasurementEles = document.querySelectorAll('.specialEducationSpeicalEducationFacilitiesAreMeasurementField');
        for (let ele of areaMeasurementEles) {
            const element = <HTMLElement>ele;
            ele.addEventListener('change', (e: Event) => this.autofillInputs(element.dataset.facilityPk, false));
        }

        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = <HTMLElement>c;
            const facilityPK = container.dataset.facilityPk;
            this.autofillInputs(facilityPK, true);
        }
    }

    static constructFileUploadElements() {
        const containers = document.querySelectorAll('.facilityContainer');
        for (let c of containers) {
            const container = <HTMLElement>c;
            const facilityPK = container.dataset.facilityPk;

            const fileUploader = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityPK}']`);
            if (fileUploader !== null) {
                fileUploader.addEventListener('change', (e: Event) => this.uploadFile(<HTMLInputElement>e.target, facilityPK));
            }
        }
    }

    static constructFileDeleteElements(facilityFK = null) {
        if (facilityFK) {
            const deleteButton = document.querySelector(`.deleteFile[data-facility-pk='${facilityFK}']`) as HTMLButtonElement;
            if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                deleteButton.addEventListener('click', (e: Event) => this.showDeleteFileConfirmation(deleteButton));
            }
        } else {
            const deleteFileButtons = document.getElementsByClassName('deleteFile') as HTMLCollectionOf<HTMLButtonElement>;
            for (let deleteButton of deleteFileButtons) {
                if (deleteButton.dataset.fileuploadPk && parseInt(deleteButton.dataset.fileuploadPk) > 0) {
                    deleteButton.addEventListener('click', (e: Event) => this.showDeleteFileConfirmation(deleteButton));
                }
            }
        }

        const deleteFileConfirmButton = <HTMLButtonElement>document.getElementById('deleteFileConfirm');
        if (deleteFileConfirmButton !== null) {
            deleteFileConfirmButton.addEventListener('click', (e: Event) => this.deleteFileConfirm(deleteFileConfirmButton));
        }

        const deleteFileCancelButton = <HTMLButtonElement>document.getElementById('deleteFileCancel');
        if (deleteFileCancelButton !== null) {
            deleteFileCancelButton.addEventListener('click', (e: Event) => this.deleteFileCancel());
        }
    }

    static autofillInputs(facilityFK, onPageLoad: boolean = false) {

        // Get measurement values from the inputs
        const areaMeasurements = {
            WidthFeet: {
                Element: <HTMLInputElement>document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.feet.width-ft[data-facility-pk='${facilityFK}']`)).value),
            },
            WidthInches: {
                Element: <HTMLInputElement>document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.width-in[data-facility-pk='${facilityFK}']`)).value)
            },
            LengthFeet: {
                Element: <HTMLInputElement>document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.feet.length-ft[data-facility-pk='${facilityFK}']`)).value),
            },
            LengthInches: {
                Element: <HTMLInputElement>document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`),
                Value: parseInt((<HTMLInputElement>document.querySelector(`.inches.length-in[data-facility-pk='${facilityFK}']`)).value)
            }
        };

        if (!onPageLoad) {
            // Validate: user has entered a number >= 0, and if it is an inches measurement, 12 > number >= 0
            for (let am in areaMeasurements) {
                const measurement = areaMeasurements[am];
                if (measurement.Value === '' || measurement.Value === null || isNaN(measurement.Value) || parseInt(measurement.Value) < 0) {
                    measurement.Element.value = 0;
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter a number greater than or equal to 0.', 'error', 3000, null);
                    return;
                } else if ((am === 'WidthInches' || am === 'LengthInches') && parseInt(measurement.Value) > 11) {
                    measurement.Element.value = 0
                    Core.createHTMLAlert('alertMessageDiv', 'Please enter an inches value between 0 and 11.', 'error', 3000, null);
                }
            }
        }

        // Calculate total inches (inches + feet*12) 
        let totalWidthInches = areaMeasurements.WidthInches.Value + (areaMeasurements.WidthFeet.Value * 12);
        let totalLengthInches = areaMeasurements.LengthInches.Value + (areaMeasurements.LengthFeet.Value * 12);

        if (isNaN(totalWidthInches)) totalWidthInches = 0;
        if (isNaN(totalLengthInches)) totalLengthInches = 0;

        // Area = L x W, sqft = Area / (12*12) rounded down, students need at least 28sqft per
        const areaInches = totalWidthInches * totalLengthInches;
        const areaSqFeet = Math.floor(areaInches / 144);
        const maxStudents = Math.floor(areaSqFeet / 28);

        // Update UI
        const areaEle = document.querySelector(`.specialEducationFacilitiesAreaMeasurement[data-facility-pk='${facilityFK}']`);
        (<HTMLInputElement>areaEle).value = `${areaSqFeet}sqft`;
        const maxStudentsEle = document.querySelector(`.specialEducationFacilitiesMaxStudents[data-facility-pk='${facilityFK}']`);
        (<HTMLInputElement>maxStudentsEle).value = `${maxStudents}`;
    }

    static uploadFile(e: HTMLInputElement, facilityFK) {
        const core = this._staticCore;
        Core.showLoader();
        const uploadFeaturesForm = <HTMLFormElement>document.querySelector(`.specialEducationIUProfilesFacilitiesUploadFeaturesforeachClassroomSpace[data-facility-pk='${facilityFK}']`);
        const formData = new FormData(uploadFeaturesForm);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFacilitiesFile', true);
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.responseText !== null) {
                    const response = JSON.parse(xhr.responseText);
                    if (response.success) {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file has been successfully uploaded!', 'success', 3000, null);

                        const formFile = document.querySelector(`.classroom-features-upload[data-facility-pk='${facilityFK}']`) as HTMLInputElement;
                        formFile.value = '';
                        formFile.dataset.hasuploaded = 'true';

                        const fileUpload = response.fileUpload;

                        const xhrForPartialView = new XMLHttpRequest();
                        xhrForPartialView.open('GET', `/IUSpecialEducation/GetFacilityFileUploadPartialView?fileUploadPK=${fileUpload.fileUploadPK}&fileContentType=${fileUpload.fileContentType}&fileName=${fileUpload.fileName}&specialEducationFacilityPK=${fileUpload.specialEducationFacilityPK}`);
                        xhrForPartialView.onload = () => {
                            if (xhrForPartialView.status === 200) {
                                const fileUploadDiv = document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`) as HTMLDivElement;
                                fileUploadDiv.innerHTML = xhrForPartialView.response;
                                this.constructFileDeleteElements(facilityFK);
                            }
                        };
                        xhrForPartialView.send();

                        this.initializeFacilityHashes();
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'The file upload failed. Please try again.', 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    static showDeleteFileConfirmation(deleteButton: HTMLButtonElement) {
        const fileUploadPK = deleteButton.dataset.fileuploadPk;
        const facilityPK = deleteButton.dataset.facilityPk;

        if (fileUploadPK && parseInt(fileUploadPK) > 0) {
            const modal: Modal = new Modal('deleteFileModal', null);
            const deleteConfirmButton = <HTMLButtonElement>document.getElementById('deleteFileConfirm');
            deleteConfirmButton.dataset.fileuploadPk = fileUploadPK;
            deleteConfirmButton.dataset.facilityPk = facilityPK;
            modal.show();
        }
    }

    static deleteFileConfirm(confirmButton: HTMLButtonElement) {
        const facilityFK = parseInt(confirmButton.dataset.facilityPk);
        const containerElement = <HTMLElement>document.querySelector(`.file-upload-div[data-facility-pk='${facilityFK}']`);
        if (containerElement !== null) {
            containerElement.removeChild(containerElement.firstChild);

            this.initializeFacilityHashes();
        }

        const modal: Modal = new Modal('deleteFileModal', null);
        modal.hide();
    }

    static deleteFileCancel() {
        const modal: Modal = new Modal('deleteFileModal', null);
        modal.hide();
    }

    static validateElements(facilityPK) {
        const parent = document.querySelector(`.facilityContainer[data-facility-pk='${facilityPK}']`);
        const textInputs = parent.querySelectorAll("input[type='text'], input[type='number'], input[type='date']");
        const selects = parent.querySelectorAll("select");

        let hasInputErrors = false;
        let hasSelectErrors = false;

        for (const input of textInputs) {
            const element = <HTMLInputElement>input;

            if ("facilityPk" in element.dataset && "percent" in element.dataset) {
                //The feet fields are somewhat of a special case, so handle them separately (inches will be validated the normal
                //way, because a classroom can't simply be inches in either direction)/
                if (!(element.classList.contains("feet"))) {
                    if (element.dataset.percent === "1.00" && element.value === "") {
                        element.classList.add("missing-field");
                        element.setAttribute("aria-invalid", "true");

                        Core.createErrorLabelForInput(element);

                        hasInputErrors = true;
                    } else {
                        element.classList.remove("missing-field");
                        element.removeAttribute("aria-invalid");

                        Core.removeErrorLabelForInput(element);
                    }
                } else {
                    const allFeet = document.querySelectorAll(`.feet[data-facility-pk='${facilityPK}']`);

                    let missingDimensions: boolean = false;
                    for (const feet of allFeet) {
                        const ele = <HTMLInputElement>feet;
                        if (ele.value === "" || ele.value === "0") {
                            missingDimensions = true;
                        }

                        for (const secondFeet of allFeet) {
                            const element = <HTMLInputElement>secondFeet;
                            if (missingDimensions) {
                                element.classList.add("missing-field");
                                element.setAttribute("aria-invalid", "true");

                                Core.createErrorLabelForInput(element);

                                hasInputErrors = true;
                            } else {
                                element.classList.remove("missing-field");
                                element.removeAttribute("aria-invalid");

                                Core.removeErrorLabelForInput(element);
                            }
                        }
                    }
                }
            }
        }

        for (const select of selects) {
            const element = <HTMLSelectElement>select;

            if ("facilityPk" in element.dataset && "percent" in element.dataset) {
                if (element.dataset.percent === "1.00" && (element.value === "" || element.value === "0")) {
                    element.classList.add("missing-field");
                    element.setAttribute("aria-invalid", "true");

                    Core.createErrorLabelForInput(element);

                    hasSelectErrors = true;
                } else {
                    element.classList.remove("missing-field");
                    element.removeAttribute("aria-invalid");

                    Core.removeErrorLabelForInput(element);
                }
            }
        }

        return !(hasInputErrors || hasSelectErrors);
    }

    static promiseSave(specialEducationFacilityPK: number = null, forceSave: boolean = false, onWindowUnload: boolean = false, forSEPRN: boolean = false) {
        let _this = this;

        return new Promise((resolve, reject) => {
            let shouldSave = false;
            let saveData: IIUFacilitySave[] = [];

            let facilitiesToSave: NodeListOf<HTMLElement>;
            if (specialEducationFacilityPK !== null) {
                facilitiesToSave = document.querySelectorAll(`.facilityContainer[data-facility-pk='${specialEducationFacilityPK}']`) as NodeListOf<HTMLElement>;
            } else {
                //Get all the facilities on the page
                facilitiesToSave = document.querySelectorAll('.facilityContainer') as NodeListOf<HTMLElement>;
            }
            for (let facility of facilitiesToSave) {
                let assuranceChecksData: IAssuranceCheck[] = [];

                if ('facilityPk' in facility.dataset) {
                    shouldSave = true;
                    let facilityPK = facility.dataset.facilityPk;

                    // Get Building Name 
                    let buildingName = "";
                    const buildingNameEle = <HTMLInputElement>facility.querySelector(`.building-name[data-facility-pk='${facilityPK}']`);
                    if (buildingNameEle !== null)
                        buildingName = buildingNameEle.value;

                    let buildingNameOther = "";
                    const buildingNameOtherEle = <HTMLInputElement>facility.querySelector(`.building-name-other[data-facility-pk='${facilityPK}']`);
                    if (buildingNameOtherEle !== null)
                        buildingNameOther = buildingNameOtherEle.value;

                    // Get RoomDesc
                    let roomDesc = null;
                    let room = facility.querySelector(`.room-desc[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (room !== null && room.value != '') {
                        roomDesc = room.value;
                    } else if (onWindowUnload) {
                        roomDesc = 0;
                    }

                    let operatedInLookupCodeFK = null;
                    let operatedIn = facility.querySelector(`.operated-in[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
                    let hasOther: boolean = false;
                    let hasLEA: boolean = false;

                    if (operatedIn !== null) {
                        operatedInLookupCodeFK = operatedIn.value;

                        const selectedItem = operatedIn[operatedIn.selectedIndex] as HTMLOptionElement;
                        if (selectedItem !== null)
                            if (selectedItem.dataset.lookup === "specialEducationIUOperatedInLEA")
                                hasLEA = true;
                            else if (selectedItem.dataset.lookup === "specialEducationIUOperatedInOther")
                                hasOther = true;
                    }

                    let operatedInLEA = null;
                    if (hasLEA) {
                        let operatedInLEAEle = facility.querySelector(`.operated-in-lea[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                        if (operatedInLEAEle !== null)
                            operatedInLEA = operatedInLEAEle.value;
                    }
                    
                    let operatedInOther = null;
                    if (hasOther) {
                        let operatedInOtherEle = facility.querySelector(`.operated-in-other[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                        if (operatedInOtherEle !== null)
                            operatedInOther = operatedInOtherEle.value;
                    }

                    // Get BuildingDescriptionLookupCodeFK
                    let buildingDescriptionLookupCodeFK = null;
                    let buildingDescription = facility.querySelector(`.building-description[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
                    if (buildingDescription != null && buildingDescription.value != '') {
                        buildingDescriptionLookupCodeFK = buildingDescription[buildingDescription.selectedIndex].dataset.lookupCode;
                    }

                    // Get SchoolBuildingLookupCodeFK
                    let schoolBuildingLookupCodeFK = null;
                    let schoolBuilding = facility.querySelector(`.school-building[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
                    if (schoolBuilding != null && schoolBuilding.value != '') {
                        schoolBuildingLookupCodeFK = schoolBuilding[schoolBuilding.selectedIndex].dataset.lookupCode;
                    }

                    // Get ClassroomWidth
                    let classroomWidth = null;
                    let widthFt = facility.querySelector(`.width-ft[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    let widthIn = facility.querySelector(`.width-in[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (widthFt != null && widthFt.value != '' && widthIn != null && widthIn.value != '') {
                        let width = parseInt(widthIn.value) + (parseInt(widthFt.value) * 12);
                        classroomWidth = width;
                    }

                    // Get Classroomlength
                    let classroomLength = null;
                    let lengthFt = facility.querySelector(`.length-ft[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    let lengthIn = facility.querySelector(`.length-in[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (lengthFt != null && lengthFt.value != '' && lengthIn != null && lengthIn.value != '') {
                        let length = parseInt(lengthIn.value) + (parseInt(lengthFt.value) * 12);
                        classroomLength = length;
                    }

                    // Get FileUploadFK
                    let fileUploadFK = null;
                    let file = facility.querySelector(`button.delete-row.deleteFile[data-facility-pk='${facilityPK}']`) as HTMLButtonElement;
                    if (file != null) {
                        fileUploadFK = file.dataset.fileuploadPk;
                    }

                    // Get ImplementationDate
                    let implementationDate = null;
                    let date = facility.querySelector(`.implementation-date[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                    if (date != null && date.value != '') {
                        implementationDate = date.value;
                    }

                    // Get assurance checks
                    let assuranceChecks = [];
                    let assuranceChecksForFacility = document.querySelectorAll(`.assurance-check-row[data-facility-pk='${facilityPK}']`);
                    for (let assuranceCheck of assuranceChecksForFacility) {
                        let assuranceCheckElement = <HTMLElement>assuranceCheck;
                        if ('facilityPk' in assuranceCheckElement.dataset) {

                            // Get assurance PK
                            let specialEducationFacilityAssuranceCheckPK = null;
                            let assurancePK = assuranceCheckElement.dataset.assurancePk;
                            if (assurancePK != null && assurancePK != '') {
                                specialEducationFacilityAssuranceCheckPK = assurancePK;
                            }

                            // Get PropertyFK
                            let propertyFK = null;
                            let property = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']`) as HTMLInputElement;
                            propertyFK = property.dataset.propertypk;

                            // Get LookupCodeFK (Yes/No)
                            let lookupCodeFK = null;
                            let lookupCode = assuranceCheckElement.querySelector(`.assurance-check-input[data-facility-pk='${facilityPK}']:checked`) as HTMLInputElement;
                            lookupCodeFK = lookupCode.value;

                            const facilityAssuranceCheckSaveData: IIUAssuranceCheck = {
                                SpecialEducationIUFacilityAssuranceCheckPK: parseInt(specialEducationFacilityAssuranceCheckPK),
                                SpecialEducationIUFacilityFK: parseInt(facilityPK),
                                PropertyFK: parseInt(propertyFK),
                                LookupCodeFK: parseInt(lookupCodeFK)
                            }

                            assuranceChecks.push(facilityAssuranceCheckSaveData);
                        }
                    }

                    const facilitiesSaveData: IIUFacility = {
                        SpecialEducationIUFacilityPK: parseInt(facilityPK),
                        RoomDesc: roomDesc,
                        SchoolBuildingLookupCodeFK: schoolBuildingLookupCodeFK,
                        BuildingDescriptionLookupCodeFK: buildingDescriptionLookupCodeFK,
                        ClassroomWidth: classroomWidth,
                        ClassroomLength: classroomLength,
                        FileUploadFK: fileUploadFK,
                        ImplementationDate: implementationDate,
                        PlanFK: _this.staticPlanFK,
                        OperatedInLookupCodeFK: operatedInLookupCodeFK,
                        OperatedInLEAName: operatedInLEA,
                        OperatedInOther: operatedInOther,
                        BuildingName: buildingName,
                        BuildingNameOther: buildingNameOther
                        //StatusCode

                    }

                    const thisFacilitiesSaveData: IIUFacilitySave = {
                        Facility: facilitiesSaveData,
                        AssuranceChecks: assuranceChecks
                    }

                    saveData.push(thisFacilitiesSaveData);
                }
            }

            if (shouldSave || forceSave) {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUFacilities', true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        if (specialEducationFacilityPK != null) {
                            const response = JSON.parse(xhr.responseText);
                            const accordionButton = <HTMLButtonElement>document.querySelector(`.lazyAccordionTrigger[data-specialeducationfacilityfk='${specialEducationFacilityPK}']`);
                            if (accordionButton)
                                accordionButton.dataset.validdata = response.isValid.toString().toLowerCase();

                            SpecialEducationIUFacilities.getValidFacilityCount();
                            SpecialEducationIUFacilities.clearValidationMessage();
                            SpecialEducationIUFacilities.fullPageValidation();
                            SpecialEducationIUFacilities.initializeLoadedFacility(specialEducationFacilityPK);
                            SpecialEducationIUFacilities.planPageCompleteCheck();

                            resolve('Successfully saved Facility');
                        } else {
                            resolve('Successfully saved');
                        }
                    } else {
                        reject('There was an unexpected error saving');
                    }
                };
                xhr.send(JSON.stringify({
                    'Data': saveData,
                    'PlanFK': _this.staticPlanFK,
                    'CanSaveSEPRN': forSEPRN
                }));
            } else {
                resolve('Nothing To Save');
            }
        });
    }

    static bindOperatedByChanges() {
        const operatedBys = document.getElementsByClassName("operatedBy");
        for (const operatedBy of operatedBys)
            operatedBy.addEventListener("change", (e: Event) => this.changeOperatedBy(e.target as HTMLSelectElement));
    }

    static changeOperatedBy(element: HTMLSelectElement) {
        const facilityFK = element.dataset.facilityPk;
        const selectedItem = <HTMLOptionElement>element[element.selectedIndex];
        const value = selectedItem.dataset.lookup;

        const schoolDistrictNameInput = <HTMLInputElement>document.querySelector(`.schoolDistrictName[data-facility-pk='${facilityFK}']`);
        const districtNameElement = <HTMLDivElement>document.querySelector(`.operatedBySchoolDistrictName[data-facilityfk='${facilityFK}']`);
        if (value === "specialEducationIUOperatedBySchoolDistrict") {
            districtNameElement.classList.remove("hide");
            this._staticCore.forceElementRequired(schoolDistrictNameInput);
        } else {
            districtNameElement.classList.add("hide");
            this._staticCore.forceElementOptional(schoolDistrictNameInput);
        }
    }

    //Calculates a hash value for each facility on the page and is stored in an object as a property of this class.
    static initializeFacilityHashes() {
        let that = this;

        that.facilityHashes = {};

        let facilityContainers = document.getElementsByClassName("facilityContainer") as HTMLCollectionOf<HTMLElement>;
        for (let facility of facilityContainers) {
            let facilityPK = parseInt(facility.dataset.facilityPk);
            let hash = that.calculateFacilityHash(facilityPK);
            that.facilityHashes[facilityPK] = hash;
        }
    }

    //Calculates a hash for a facility. This is used to determine whether the facility has changed for saving purposes.
    static calculateFacilityHash(facilityPK: number) {
        let that = this;
        let hash = "0";
        let value;
        let newHash;

        let allElements = document.querySelectorAll(`[data-hashable][data-facility-pk='${facilityPK}']`);

        for (let element of allElements) {
            if (element instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>element;

                if (inputElement.type === "radio") {
                    if (inputElement.checked) {
                        value = inputElement.value;
                        newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
                else {
                    if (inputElement.value === "") {
                        value = 0
                        hash = hash + value;
                    }
                    else if (inputElement.value !== "") {
                        value = inputElement.value;
                        newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                        hash = hash + newHash;
                    }
                }
            } else if (element instanceof HTMLSelectElement) {
                const selectElement = <HTMLSelectElement>element;
                if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                    value = 0;
                    hash = hash + value;
                }
                else if (selectElement.selectedIndex > 0 || selectElement.options[selectElement.selectedIndex].value !== "") {
                    value = selectElement.options[selectElement.selectedIndex].value
                    newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLTextAreaElement) {
                const textAreaElement = <HTMLTextAreaElement>element;

                if (textAreaElement.value === "") {
                    value = 0
                    hash = hash + value;
                }
                else {
                    value = textAreaElement.value;
                    newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                    hash = hash + newHash;
                }
            } else if (element instanceof HTMLAnchorElement) {
                const anchorElement = <HTMLAnchorElement>element;

                value = anchorElement.dataset.fileuploadPk;
                newHash = SpecialEducationIUFacilities._staticCore.hashCode(value);
                hash = hash + newHash;
            }
        }

        return hash;
    }

    static initializeLoadedFacility(facilityFK: number) {
        this.constructAutofillInputs();
        this.bindOperatedByChanges();
        const operatedBys = document.getElementsByClassName("operatedBy");
        for (const operatedBy of operatedBys)
            this.changeOperatedBy(operatedBy as HTMLSelectElement)

        this.initializeFacilityHashes();
        this.handleFacilitySaveDisabled(facilityFK);
        this.validateElements(facilityFK);
    }

    //The save button only becomes available for an facility if there is a change within the facility
    static handleFacilitySaveDisabled(facilityPK: number) {
        let saveButton = document.querySelector(`.facilitiesSave[data-facilitypk='${facilityPK}']`) as HTMLButtonElement;
        if (saveButton != null) {
            if (this.facilityHasChanged(facilityPK) && "canEdit" in saveButton.dataset) {
                saveButton.disabled = false;
            } else {
                saveButton.disabled = true;
            }
        }
    }

    //Determines whether a facility has changed by comparing the current hash value to a prior hash value
    static facilityHasChanged(facilityPK: number) {
        let that = this;
        let changed = false;
        if (that.facilityHashes[facilityPK] !== undefined) {
            let newHash = that.calculateFacilityHash(facilityPK);
            if (newHash !== that.facilityHashes[facilityPK]) {
                changed = true;
            }
        } else {
            //Be on the safe side and say it has changed
            changed = true;
        }

        return changed;
    }

    static initializeLoadedFacilityFields(facilityFK) {
        const inputElements = document.querySelectorAll(`.lazy-accordion-panel[data-specialeducationfacilityfk='${facilityFK}'] input`);
        const selectElements = document.querySelectorAll(`.lazy-accordion-panel[data-specialeducationfacilityfk='${facilityFK}'] select`);

        for (const elementInside of inputElements) {
            if (elementInside instanceof HTMLInputElement) {
                const inputElement = <HTMLInputElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }

        for (const elementInside of selectElements) {
            if (elementInside instanceof HTMLSelectElement) {
                const inputElement = <HTMLSelectElement>elementInside;
                if (inputElement.dataset.percent === "1.00") {
                    let label = Core.findLabelForInput(inputElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }

    static fullPageValidation() {
        const allAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const validCount = <HTMLInputElement>document.getElementById("validCount");
        const totalErrors = parseInt(validCount.value);
        const shownAccordions = document.getElementsByClassName("lazyAccordionTrigger");
        const totalErrorsNotShown = totalErrors - shownAccordions.length;
        let errorCount: number = 0;
        for (const accordion of allAccordions) {
            const accElement = <HTMLButtonElement>accordion;
            let valid = "false";
            if (accElement.dataset.validdata)
                valid = accElement.dataset.validdata;

            if (valid === "false" && !accElement.dataset.isnew) {
                accElement.classList.add("error");
                errorCount++;
            } else {
                accElement.classList.remove("error");
            }
        }

        const messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        const validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.add("show");
        const message = <HTMLDivElement>document.getElementById("validationMessage");

        messageContainer.classList.remove("success");
        messageContainer.classList.remove("warning");

        if (errorCount > 0) {
            let word = "are";
            let facility = "facilities"
            let error = "errors";
            if (errorCount === 1) {
                word = "is";
                facility = "facility";
            }

            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">There ${word} ${errorCount} ${facility} with ${error}. The ${facility} ${word} marked below.</p>`;
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else if (errorCount === 0 && totalErrorsNotShown > 0) {
            messageContainer.classList.add("warning");
            message.innerHTML = `<p class="validationErrorCountMessage">The page has been successfully saved.</p>`;

            let facilityWord = "are";
            let facilityFacility = "facilities";
            let facilityError = "issues";
            if (totalErrorsNotShown === 1) {
                facilityWord = "is";
                facilityFacility = "facility";
            }

            message.innerHTML += `<p class="validationErrorCountMessage">There ${facilityWord} ${totalErrorsNotShown} ${facilityFacility} with ${facilityError} not shown. Change your search parameters to show more.</p>`;

            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";
        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            if (message !== null) {
                message.innerHTML = "The page has been successfully saved."
                message.classList.add("show");
            }
        }
    }

    static async getValidFacilityCount() {
        const planForm = <HTMLDivElement>document.getElementById("specialEducationIUFacilitiesForm");
        const planFK = planForm.dataset.planfk;

        const response = await fetch(`/IUSpecialEducation/GetValidFacilitiesCount/${planFK}`, { credentials: 'include' })
        if (response.ok) {
            const value = await response.text();
            const field = <HTMLInputElement>document.getElementById("validCount");
            field.value = value;
        }
    }

    static clearValidationMessage() {
        const messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        messageContainerColumn.classList.remove("show");
    }

    static async planPageCompleteCheck() {
        const form = document.getElementById("specialEducationIUFacilitiesForm");
        const planFK = form.dataset.planfk;
        const pageCode = form.dataset.pagecode;

        const response = await fetch(`/IUSpecialEducation/GetPageCompleteAsync/${planFK}/${pageCode}`, { credentials: 'include' })
        if (response.ok) {
            const value = await response.json();

            const menuElement = <HTMLDivElement>document.querySelector("#leftBarspecialEducationIUFacilitiesParent .status-indicator");

            if (value.thisPage) {
                menuElement.classList.add("complete");
            } else {
                menuElement.classList.remove("complete");
            }
        }
    }

    static createSEPRN(facilityPK: number) {
        Core.showLoader();

        let xhr = new XMLHttpRequest();
        let proposedActionValue = "";
        let additionalInformationValue = "";

        let proposedActionElement = document.querySelector(`.proposedAction[data-facility-pk='${facilityPK}']`) as HTMLSelectElement;
        if (proposedActionElement != null) {
            proposedActionValue = proposedActionElement.value;
        }

        let additionalInformationElement = document.querySelector(`.additionalInformation[data-facility-pk='${facilityPK}']`) as HTMLTextAreaElement;
        if (additionalInformationElement != null) {
            additionalInformationValue = additionalInformationElement.value;
        }

        if (proposedActionElement.value != "") {
            let selected = proposedActionElement[proposedActionElement.selectedIndex] as HTMLOptionElement;

            if ((selected.dataset.lookupCode == "proposedActionDelete" || selected.dataset.lookupCode == "proposedActionChange") && additionalInformationValue == "") {
                Core.hideLoader();
                Core.createHTMLAlert('alertMessageDiv', 'Please specify a reason/explanation for this SEPRN', 'error', 3000, null);
            }
            else if (selected.dataset.lookupCode == "proposedActionNew" && !this.validateElements(facilityPK)) {
                Core.hideLoader();
            } else {
                fetch('/IUSpecialEducation/CreateSEPRN', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        IUFacilityPK: facilityPK,
                        ProposedAction: proposedActionValue,
                        AdditionalInformation: additionalInformationValue
                    })
                })
                .then(() => this.promiseSave(facilityPK, false, false, true))
                .then((response) => {
                    Core.createHTMLAlert('alertMessageDiv', 'Successfully submitted SEPRN', 'success', 3000, window.location.reload());
                    Core.hideLoader();
                })
                .catch((error) => {
                    Core.createHTMLAlert('alertMessageDiv', 'There was an unexpected issue submitting SEPRN', 'error', 3000, null);
                    Core.hideLoader();
                });
            }
        } else {
            Core.createHTMLAlert('alertMessageDiv', 'Please select a proposed action for the SEPRN', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    static initializeSEPRNWorkflow(seprnWorkflow: Workflow) {
        let seprnContainer = document.querySelector(`.seprnContainer[data-facility-pk][data-workflow-instance-pk='${seprnWorkflow.WorkflowInstancePK}']`) as HTMLElement;
        if (seprnContainer != null) {
            let specialEducationFacilityPK = parseInt(seprnContainer.dataset.facilityPk);
            //Override so that review submission stays on current page instead of going to review admin
            seprnWorkflow.ReviewURL = '';
            seprnWorkflow.BeforeSave = () => this.promiseSave(specialEducationFacilityPK, false, false, true);

            let proposedChangeAction = seprnWorkflow.ActionResponses.filter(ac => ac.ActionCode == "seprnType");
            if (proposedChangeAction.length > 0) {
                let proposedChangeActionRadio = proposedChangeAction[0] as WorkflowActionResponseRadio;
                let deletedRadio = proposedChangeActionRadio.RadioElements.filter(re => "lookupCode" in re.dataset && re.dataset.lookupCode == "proposedActionDelete") as Array<HTMLInputElement>;
                if (deletedRadio != null && deletedRadio.length > 0) {
                    if (deletedRadio[0].checked) {
                        const actionsToDisable = ['onsiteReviewSEPRN', 'ebbFlowSEPRN', 'noiseInterfereSEPRN', 'designedForInstructionSEPRN', 'readilyAccessibleSEPRN', 'squareFeet28SEPRN'];

                        for (let action of seprnWorkflow.ActionResponses.filter(a => actionsToDisable.indexOf(a.ActionCode) >= 0 && a instanceof WorkflowActionResponseRadio)) {
                            let radioAction = action as WorkflowActionResponseRadio;
                            for (let radio of radioAction.RadioElements) {
                                radio.disabled = true;
                                if ("lookupCode" in radio.dataset && radio.dataset.lookupCode == "actionNA") {
                                    radio.checked = true;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// SpecialEducationIUTraining
class SpecialEducationIUTraining {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUPersonnelCheckboxField", "specialEducationIUTrainingCheckboxField", "specialEducationIUTrainingField", "specialEducationIUPersonnelFileField"]

        let specialEducationIUTrainingSaveButton = document.getElementById("specialEducationIUTrainingSave");
        if (specialEducationIUTrainingSaveButton !== null)
            specialEducationIUTrainingSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation();
        }
        this.customInitializeRequiredFields();

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fileUploader = document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());
    }

    customDoValidation() {
        let showMessage = this.customClientSideValidation();
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(): boolean {
        let showMessage: boolean = false;
        let totalErrors: number = 0;

        const specialEducationIUTrainingSummaryFields = document.querySelectorAll(".specialEducationIUTrainingSummary[data-percent='1.00']");
        const specialEducationIUTrainingPersonnelNeedsAssessmentFields = document.querySelectorAll(".specialEducationIUTrainingPersonnelNeedsAssessment:checked");
        const specialEducationIUTrainingPopulationAssessedFields = document.querySelectorAll(".specialEducationIUTrainingPopulationAssessed:checked");
        const specialEducationIUTrainingProcedureFields = document.querySelectorAll(".specialEducationIUTrainingProcedure:checked");
        const specialEducationIUTrainingNeedsField = <HTMLTextAreaElement>document.querySelector(".specialEducationIUTrainingNeeds");
        const files = document.querySelectorAll(".specialEducationIUPersonnelFileUpload");
        const specialEducationIUTrainingProcedureOtherField = <HTMLInputElement>document.querySelector(".specialEducationIUTrainingProcedureOther");
        const specialEducationIUTechnicalPersonnelOtherTextField = <HTMLInputElement>document.querySelector("#specialEducationIUTechnicalPersonnelOtherText");


        const specialEducationIUTrainingPersonnelNeedsAssessmentTitle = <HTMLSpanElement>document.getElementById("specialEducationIUTrainingPersonnelNeedsAssessmentTitle");
        const specialEducationIUTrainingPopulationAssessedTitle = <HTMLSpanElement>document.getElementById("specialEducationIUTrainingPopulationAssessedTitle");
        const specialEducationIUTrainingProcedureTitle = <HTMLSpanElement>document.getElementById("specialEducationIUTrainingProcedureTitle");
        const specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData = <HTMLInputElement>document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData");

        if (specialEducationIUTrainingPersonnelNeedsAssessmentFields.length === 0) {
            specialEducationIUTrainingPersonnelNeedsAssessmentTitle.classList.add("missing-field");
            specialEducationIUTrainingPersonnelNeedsAssessmentTitle.setAttribute("aria-invalid", "true");
            showMessage = true;
            totalErrors++;
        }

        if (specialEducationIUTrainingPopulationAssessedFields.length === 0) {
            specialEducationIUTrainingPopulationAssessedTitle.classList.add("missing-field");
            specialEducationIUTrainingPopulationAssessedTitle.setAttribute("aria-invalid", "true");
            showMessage = true;
            totalErrors++;
        }

        if (specialEducationIUTrainingNeedsField.value === "") {
            specialEducationIUTrainingNeedsField.classList.add("missing-field");
            specialEducationIUTrainingNeedsField.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(specialEducationIUTrainingNeedsField);
            showMessage = true;
            totalErrors++;
        }

        if (files.length === 0) {
            specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData.classList.add("missing-field");
            specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData);
            showMessage = true;
            totalErrors++;
        }

        for (const specialEducationIUTrainingSummaryField of specialEducationIUTrainingSummaryFields) {
            const field = <HTMLTextAreaElement>specialEducationIUTrainingSummaryField;

            if (field.value === "") {
                field.classList.add("missing-field");
                field.setAttribute("aria-invalid", "true");
                Core.createErrorLabelForInput(field);
                showMessage = true;
                totalErrors++;
            }
        }

        if (specialEducationIUTrainingProcedureOtherField.checked) {
            if (specialEducationIUTechnicalPersonnelOtherTextField.value === "") {
                specialEducationIUTechnicalPersonnelOtherTextField.classList.add("missing-field");
                specialEducationIUTechnicalPersonnelOtherTextField.setAttribute("aria-invalid", "true");

                specialEducationIUTrainingProcedureTitle.classList.add("missing-field");
                specialEducationIUTrainingProcedureTitle.setAttribute("aria-invalid", "true");
                showMessage = true;
                totalErrors++;
            }
        }
        else if (specialEducationIUTrainingProcedureFields.length === 0 && !specialEducationIUTrainingProcedureOtherField.checked) {
            specialEducationIUTrainingProcedureTitle.innerHTML += " <span class='required-label'>*</span>";
            specialEducationIUTrainingProcedureTitle.classList.add("isRequired");
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }

        return showMessage;
    }

    customInitializeRequiredFields() {
        const specialEducationIUTrainingPersonnelNeedsAssessmentTitle = <HTMLSpanElement>document.getElementById("specialEducationIUTrainingPersonnelNeedsAssessmentTitle");
        const specialEducationIUTrainingPopulationAssessedTitle = <HTMLSpanElement>document.getElementById("specialEducationIUTrainingPopulationAssessedTitle");
        const specialEducationIUTrainingProcedureTitle = <HTMLSpanElement>document.getElementById("specialEducationIUTrainingProcedureTitle");
        const specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectDataLabel = <HTMLLabelElement>document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectDataLabelId");

        specialEducationIUTrainingPersonnelNeedsAssessmentTitle.innerHTML += " <span class='required-label'>*</span>";
        specialEducationIUTrainingPersonnelNeedsAssessmentTitle.classList.add("isRequired");

        specialEducationIUTrainingPopulationAssessedTitle.innerHTML += " <span class='required-label'>*</span>";
        specialEducationIUTrainingPopulationAssessedTitle.classList.add("isRequired");

        specialEducationIUTrainingProcedureTitle.innerHTML += " <span class='required-label'>*</span>";
        specialEducationIUTrainingProcedureTitle.classList.add("isRequired");

        const classes = ["specialEducationIUTrainingSummary", "specialEducationIUPersonnelFileField", "specialEducationIUTrainingNeeds"];
        this._core.initializeRequiredFields(classes);

    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const allSaveElements = [];
        const allFileElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUTrainingForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const textInputs = document.getElementsByClassName("specialEducationIUTrainingField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const checkboxes = document.querySelectorAll(".specialEducationIUPersonnelCheckboxField, .specialEducationIUTrainingCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        const files = document.getElementsByClassName("specialEducationIUPersonnelFileUpload");
        for (let ele of files) {
            let element = <HTMLElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk);
            let fileUploadPK = element.dataset.fileuploadpk;
            let propertyPK = parseInt(element.dataset.propertypk);
            let saveItem = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK,
                PlanPropertyPlanPropertyFilePK: 0
            };
            allFileElements.push(saveItem);
        }

        const saveData = {
            "SaveElements": allSaveElements,
            "FileElements": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTraining', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`#specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList`)

                        let currentFileList = fileList.querySelectorAll(".uploadFileList");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("specialEducationIUPersonnelFileUpload");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.addAttributeToElement("iuPersonnelDeleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.specialEducationIUPersonnelFileUpload[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById("uploadedFiles");

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.hide();
    }
}

// SpecialEducationIUPersonnel
class SpecialEducationIUPersonnel {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUPersonnelField", "specialEducationIUPersonnelCheckboxField"];

        let specialEducationIUPersonnelSaveButton = document.getElementById("specialEducationIUPersonnelSave");
        if (specialEducationIUPersonnelSaveButton !== null)
            specialEducationIUPersonnelSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fileUploader = document.getElementById("specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUPersonnelForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUPersonnelField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("specialEducationIUPersonnelCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("specialEducationIUPersonnelFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUPersonnel', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        let formFile = <HTMLInputElement>document.querySelector(`#specialEducationIUTechnicalPersonnelUploadaSampleoftheInstrumentToolUsedtoCollectData`);
                        formFile.value = "";

                        let fileList = document.querySelector(`.uploadFileList`)

                        let currentFileList = fileList.querySelectorAll(".uploadFileList");

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`#uploadedFiles`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let propertyRelationTypePK = e.dataset.propertyrelationtypepk;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.dataset.propertypk = propertyPK;
                        fileA.classList.add("specialEducationIUPersonnelFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.addAttributeToElement("iuPersonnelDeleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    let element = document.querySelector(`.specialEducationIUPersonnelFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    let parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById("uploadedFiles");

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();
            } else {
                let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("iuPersonnelDeleteFileModal", null);
        modal.hide();
    }
}

// SpecialEducationIUPDEObjectives
class SpecialEducationIUPDEObjectives {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUPDEObjectivesCheckboxField"];

        let specialEducationIUPDEObjectivesSaveButton = document.getElementById("specialEducationIUPDEObjectivesSave");
        if (specialEducationIUPDEObjectivesSaveButton !== null)
            specialEducationIUPDEObjectivesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidation(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUPDEObjectivesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let checkboxes = document.getElementsByClassName("specialEducationIUPDEObjectivesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUPDEObjectives', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Nothing to save.", 'warning', 3000, null);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    doValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        const required = [
            "specialEducationIUTechnicalPDEObjectivesTraining",
            "specialEducationIUTechnicalPDEObjectivesFederalGrants",
        ];

        const others = [
            "specialEducationIUTechnicalPDEObjectivesDevelop",
            "specialEducationIUTechnicalPDEObjectivesLocalSchoolDistricts",
            "specialEducationIUTechnicalPDEObjectivesLocalCommunity",
            "specialEducationIUTechnicalPDEObjectivesCoordinateDataSystem",
            "specialEducationIUTechnicalPDEObjectivesDevelopingTraining",
            "specialEducationIUTechnicalPDEObjectivesAssistLEA"
        ];

        for (const prop of required) {
            const checkbox = <HTMLInputElement>document.getElementById(prop);

            if (!checkbox.checked)
                totalErrors++;
        }

        let otherCount = 0;
        for (const prop of others) {
            const checkbox = <HTMLInputElement>document.getElementById(prop);

            if (checkbox.checked)
                otherCount++;
        }

        if (otherCount < 3)
            totalErrors++;

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors > 0) {
            message.innerHTML = "<p class='validationErrorCountMessage'>Intermediate Units MUST satisfy the objectives participation requirements.</p>";
            showMessage = true;
        }

        return showMessage;
    }
}

// SpecialEducationIUDevelopment
class SpecialEducationIUDevelopment {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUDevelopmentField", "specialEducationIUDevelopmentRadioField"];

        let specialEducationIUDevelopmentSaveButton = document.getElementById("specialEducationIUDevelopmentSave");
        if (specialEducationIUDevelopmentSaveButton !== null)
            specialEducationIUDevelopmentSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.setupRadioElements();

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.doValidationCustom(this.validationClasses);
        }
        this.initializeRequiredFieldsCustom(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let addIndicatorTrainingGoalButtons = document.getElementsByClassName("addIndicatorTrainingGoal");
        for (let addIndicatorTrainingGoalButton of addIndicatorTrainingGoalButtons)
            addIndicatorTrainingGoalButton.addEventListener("click", (e: Event) => this.addIndicatorTrainingGoal(e));

        let radioChanges = document.getElementsByClassName("specialEducationIUDevelopmentRadioField");
        for (let radioButton of radioChanges)
            radioButton.addEventListener("change", (e: Event) => this.changeRadioElement(<HTMLInputElement>e.target));

        let iuSpecialEducationTechnicalAssistanceRecordDeleteCancelButton = document.getElementById("iuSpecialEducationTechnicalAssistanceRecordDeleteCancel");
        if (iuSpecialEducationTechnicalAssistanceRecordDeleteCancelButton !== null)
            iuSpecialEducationTechnicalAssistanceRecordDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationTechnicalAssistanceRecordDeleteConfirmButton = document.getElementById("iuSpecialEducationTechnicalAssistanceRecordDeleteConfirm");
        if (iuSpecialEducationTechnicalAssistanceRecordDeleteConfirmButton !== null)
            iuSpecialEducationTechnicalAssistanceRecordDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        this.bindDeleteIndicatorGoal();

        const specialEducationIUDevelopmentExportToExcel = document.getElementById("specialEducationIUDevelopmentExportToExcel");
        if (specialEducationIUDevelopmentExportToExcel !== null)
            specialEducationIUDevelopmentExportToExcel.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUDevelopmentForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUDevelopmentField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let radios = document.getElementsByClassName("specialEducationIUDevelopmentRadioField");
        for (let ele of radios) {
            let planPropertyPK = 0;
            planPK = parseInt(formElement.dataset.planfk);

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.checked) {
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: null,
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUDevelopment', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    async addIndicatorTrainingGoal(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;

        let propertyCodeSection = buttonElement.dataset.propertycodesection;

        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("specialEducationIUDevelopmentForm");
        numberOfRows = 1;

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll(".personnelDevelopmentPlanRowItem");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            newRow++;

            let response = await fetch(`/IUSpecialEducation/AddPersonnelDevelopmentIndicatorRow/${planFK}/${newRow}/${propertyCodeSection}`, { credentials: 'include' })
            if (response.ok) {

                const output = await response.text();

                let newDiv = <HTMLDivElement>document.createElement("div");
                newDiv.innerHTML = output;
                newDiv.dataset.row = newRow.toString();
                newDiv.dataset.propertycodesection = propertyCodeSection;
                newDiv.classList.add("personnelDevelopmentPlanRowItem");
                newDiv.classList.add("margin-top-xl");

                let planRow = document.querySelector(`.personnelDevelopmentContainer[data-propertycodesection='${propertyCodeSection}']`);

                planRow.append(newDiv);

                that.bindDeleteIndicatorGoal();
            }

            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", "Record added!", 'success', 2000, null);
        } else {
            Core.createHTMLAlert("alertMessageDiv", 'There was an issue adding a row.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    setupRadioElements() {
        let radioChanges = document.getElementsByClassName("specialEducationIUDevelopmentRadioField");
        for (let radioButton of radioChanges)
            this.changeRadioElement(<HTMLInputElement>radioButton);
    }

    changeRadioElement(radioElement: HTMLInputElement) {
        let propertyCodeSection = radioElement.dataset.propertycodesection;

        let personnelDevelopmentPlanRowElement = <HTMLDivElement>document.querySelector(`.personnelDevelopmentPlanRow[data-propertycodesection='${propertyCodeSection}']`);

        if (radioElement.checked) {
            if (radioElement.dataset.lookuplabel.toLowerCase() === "yes") {
                if (personnelDevelopmentPlanRowElement !== null) {
                    personnelDevelopmentPlanRowElement.classList.remove("hide");

                    let allElementsToRequire = document.querySelectorAll(`.personnelDevelopmentPlanRow[data-propertycodesection='${propertyCodeSection}'] .specialEducationIUDevelopmentPersonnelSPPField`);
                    for (let ele of allElementsToRequire) {
                        let htmlEle = <HTMLElement>ele;
                        this._core.forceElementRequired(htmlEle);
                    }
                }
            }
            else {
                if (personnelDevelopmentPlanRowElement !== null) {
                    personnelDevelopmentPlanRowElement.classList.add("hide");

                    let allElementsToNotRequire = document.querySelectorAll(`.personnelDevelopmentPlanRow[data-propertycodesection='${propertyCodeSection}'] .specialEducationIUDevelopmentPersonnelSPPField`);
                    for (let ele of allElementsToNotRequire) {
                        let htmlEle = <HTMLElement>ele;
                        this._core.forceElementOptional(htmlEle);
                    }
                }
            }
        }
    }

    bindDeleteIndicatorGoal() {
        let indicatorDeleteGoalButtons = document.getElementsByClassName("deleteIndicatorGoal");
        for (let indicatorDeleteGoalButton of indicatorDeleteGoalButtons)
            indicatorDeleteGoalButton.addEventListener("click", (e: Event) => this.deleteIndicatorGoal(e))
    }

    deleteIndicatorGoal(e: Event) {
        let button = <HTMLButtonElement>e.target;
        let propertyCodeSection = button.dataset.propertycodesection;
        let row = button.dataset.row;

        let modal: Modal = new Modal("deleteTechnicalAssistanceRecordModal", null);
        modal.addAttributeToElement("deleteTechnicalAssistanceRecordModal", "#iuSpecialEducationTechnicalAssistanceRecordDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteTechnicalAssistanceRecordModal", "#iuSpecialEducationTechnicalAssistanceRecordDeleteConfirm", "propertycodesection", propertyCodeSection);
        modal.show();
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteTechnicalAssistanceRecordModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let propertyCodeSection = buttonElement.dataset.propertycodesection;

        let allElements = document.querySelectorAll(`.specialEducationIUDevelopmentPersonnelSPPField[data-row='${row}'][data-propertycodesection='${propertyCodeSection}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`.personnelDevelopmentPlanRowItem[data-row='${row}'][data-propertycodesection='${propertyCodeSection}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteTechnicalAssistanceRecordModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The record has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    initializeRequiredFieldsCustom(allClasses: string[]) {

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                //check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                for (let otherElement of otherElements) {
                    let otherHtmlElement = <HTMLElement>otherElement;
                    if (otherHtmlElement.hasAttribute("aria-required") && otherHtmlElement.getAttribute("aria-required") === "true") {
                        alreadyExists = true;
                    }
                }

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }

    doValidationCustom(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.clientSideValidationCustom(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    clientSideValidationCustom(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        let allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let alreadyExists = false;
            let htmlElement = <HTMLElement>element;
            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {

                ////check to see if there are other fields the same as this one on this page (i.e. fields that have the same propertypk)
                //var otherElements = document.querySelectorAll(`[data-propertypk='${htmlElement.dataset.propertypk}']`);
                //for (let otherElement of otherElements) {
                //    let otherHtmlElement = <HTMLElement>otherElement;
                //    if (otherHtmlElement.classList.contains("missing-field")) {
                //        alreadyExists = true;
                //    } else {
                //        if (otherHtmlElement instanceof HTMLInputElement) {
                //            if (otherHtmlElement.hasAttribute("type") && otherHtmlElement.getAttribute("type") === "radio") {
                //                //Check to see if a prior group of radio buttons has already been validated
                //                let OtherInputElement = <HTMLInputElement>otherHtmlElement;

                //                if (OtherInputElement.hasAttribute("name")) {
                //                    let radioGroupName = OtherInputElement.getAttribute("name");

                //                    let radios = document.getElementsByName(radioGroupName);
                //                    let radioIsChecked: boolean = false;
                //                    let isAlreadyValidated: boolean = false;

                //                    for (var i = 0, length = radios.length; i < length; i++) {
                //                        let radioElement = <HTMLInputElement>radios[i];
                //                        if (radioElement.checked) {
                //                            radioIsChecked = true;
                //                        }

                //                        if (radioElement.classList.contains("missing-field")) {
                //                            isAlreadyValidated = true;
                //                        }
                //                    }

                //                    if (isAlreadyValidated || radioIsChecked) {
                //                        alreadyExists = true;
                //                    }
                //                }
                //            } else {
                //                let OtherInputElement = <HTMLInputElement>otherHtmlElement;
                //                if (OtherInputElement.value !== "") {
                //                    alreadyExists = true;
                //                }
                //            }
                //        } else if (otherHtmlElement instanceof HTMLSelectElement) {
                //            let otherSelectElement = <HTMLSelectElement>otherHtmlElement;
                //            if (otherSelectElement.selectedIndex >= 0 && otherSelectElement.options[otherSelectElement.selectedIndex].value !== "") {
                //                alreadyExists = true;
                //            }
                //        } else if (otherHtmlElement instanceof HTMLTextAreaElement) {
                //            let otherTextAreaElement = <HTMLTextAreaElement>otherHtmlElement;
                //            if (otherTextAreaElement.value !== "") {
                //                alreadyExists = true;
                //            }
                //        } else if ("multiselectvalidate" in otherHtmlElement.dataset && otherHtmlElement.dataset.multiselectvalidate === "true") {
                //            //See if any options have been checked in multiselect
                //            let multiselectCheckboxes = otherHtmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                //            for (let selectBox of multiselectCheckboxes) {
                //                if (selectBox.checked) {
                //                    alreadyExists = true;
                //                    break;
                //                }
                //            }
                //        }
                //    }
                //}

                if (!element.classList.contains("missing-field")) {
                    if (element instanceof HTMLInputElement) {
                        let inputElement = <HTMLInputElement>element;

                        //Only validate once for radio buttons
                        if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "radio" && !inputElement.checked) {
                            if (inputElement.hasAttribute("name")) {
                                let radioName = inputElement.getAttribute("name");

                                let radioButtons = document.getElementsByName(radioName);
                                let alreadyValidated: boolean = false;
                                let isChecked: boolean = false;

                                for (var i = 0, length = radioButtons.length; i < length; i++) {
                                    let radioElement = <HTMLInputElement>radioButtons[i];
                                    if (radioElement.classList.contains("missing-field")) {
                                        alreadyValidated = true;
                                    }

                                    if (radioElement.checked) {
                                        isChecked = true;
                                    }
                                }

                                if (!alreadyValidated && !isChecked) {
                                    inputElement.classList.add("missing-field");
                                    inputElement.setAttribute("aria-invalid", "true");
                                    Core.createErrorLabelForInput(inputElement);
                                    showMessage = true;
                                    totalErrors++;
                                }
                            }
                        } else if (inputElement.hasAttribute("type") && inputElement.getAttribute("type") === "file") {
                            if ("hasuploaded" in inputElement.dataset && inputElement.dataset.hasuploaded != "true") {
                                inputElement.classList.add("missing-field");
                                inputElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(inputElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else if (inputElement.value === "") {
                            inputElement.classList.add("missing-field");
                            inputElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(inputElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLSelectElement) {
                        let selectElement = <HTMLSelectElement>element;
                        if (selectElement.selectedIndex < 0 || selectElement.options[selectElement.selectedIndex].value === "") {
                            selectElement.classList.add("missing-field");
                            selectElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(selectElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if (element instanceof HTMLTextAreaElement) {
                        let textAreaElement = <HTMLTextAreaElement>element;
                        if (textAreaElement.value === "") {
                            textAreaElement.classList.add("missing-field");
                            textAreaElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(textAreaElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else if ("multiselectvalidate" in htmlElement.dataset && htmlElement.dataset.multiselectvalidate === "true") {
                        let multiselectCheckboxes = htmlElement.getElementsByTagName("input") as HTMLCollectionOf<HTMLInputElement>;
                        let hasSelection = false;

                        for (let selectBox of multiselectCheckboxes) {
                            if (selectBox.checked) {
                                hasSelection = true;
                                break;
                            }
                        }

                        if (!hasSelection) {
                            htmlElement.classList.add("missing-field");
                            htmlElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(htmlElement);
                            showMessage = true;
                            totalErrors++;
                        }

                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        const allWarnings = document.getElementsByClassName("fte-warning-row");
        if (allWarnings.length > 0) {
            showMessage = true;
            totalErrors += allWarnings.length;
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUDevelopmentForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUDevelopmentExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUPersonnelDevelopmentPlan.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}

// SpecialEducationIUStaffVacancies
class SpecialEducationIUStaffVacancies {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUStaffVacanciesField", "specialEducationIUStaffVacanciesSelectField", "specialEducationIUStaffVacanciesCheckboxField"];

        this.checkNoVacancies();
        this.setupOtherDropdowns();

        let specialEducationIUStaffVacanciesSaveButton = document.getElementById("specialEducationIUStaffVacanciesSave");
        if (specialEducationIUStaffVacanciesSaveButton !== null)
            specialEducationIUStaffVacanciesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);

        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);
        
        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden; 
        }

        let iuSpecialEducationStaffVacanciesAddRowButton = document.getElementById("iuSpecialEducationStaffVacanciesAddRow");
        if (iuSpecialEducationStaffVacanciesAddRowButton !== null)
            iuSpecialEducationStaffVacanciesAddRowButton.addEventListener("click", (e: Event) => this.addRow());

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        this.bindDeleteStaffVacanciesRows();
        this.bindOtherDropdown();

        const specialEducationIUStaffVacanciesExportToExcelButton = document.getElementById("specialEducationIUStaffVacanciesExportToExcel");
        if (specialEducationIUStaffVacanciesExportToExcelButton !== null) {
            specialEducationIUStaffVacanciesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        const specialEducationIUTechnicalStaffVacanciesNoVacancies = document.getElementById("specialEducationIUTechnicalStaffVacanciesNoVacancies");
        if (specialEducationIUTechnicalStaffVacanciesNoVacancies !== null)
            specialEducationIUTechnicalStaffVacanciesNoVacancies.addEventListener("change", (e: Event) => this.checkNoVacancies());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUStaffVacanciesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUStaffVacanciesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("specialEducationIUStaffVacanciesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUStaffVacanciesSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUStaffVacancies', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUStaffVacanciesSelectField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }

    showDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }

    bindDeleteStaffVacanciesRows() {
        let allDeletes = document.getElementsByClassName("deleteStaffRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUStaffVacanciesSelectField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.dropdownChange(e.target));
    }

    dropdownChange(e) {
        let selectElement = <HTMLSelectElement>e;
        let row = selectElement.dataset.row;
        let otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUStaffVacanciesField.coreservices-select-other[data-row='${row}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        } else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }

    async addRow() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("specialEducationIUStaffVacanciesForm");
        let valueElement = <HTMLInputElement>document.getElementById("iuSpecialEducatioStaffVacanciesAddRowNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll("#specialEducationIUStaffVacancies table tbody tr");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/AddStaffVacanciesRow/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.innerHTML = output;
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#specialEducationIUStaffVacancies table tbody");

                    table.append(newTR);

                    that.bindDeleteStaffVacanciesRows();
                    that.bindOtherDropdown();
                }
            }

            valueElement.value = "";
            let plural = "s";
            if (numberOfRows === 1) plural = "";
            const message = `Row${plural} added!`
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            valueElement.value = "";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        const buttonElement = <HTMLButtonElement>e.target;
        const planProps = [];
        const row = buttonElement.dataset.row;
        const dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        const allElements = document.querySelectorAll(`.specialEducationIUStaffField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const ele of allElements) {
            const element = <HTMLElement>ele;
            const planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#iuSupportStaffAndServicesProfile table tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    customInitializeRequiredFields(allClasses: string[]) {

        const specialEducationIUStaffVacanciesPositionDropdowns = document.getElementsByClassName("specialEducationIUStaffVacanciesPositionDropdown");
        for (const specialEducationIUStaffVacanciesPositionDropdown of specialEducationIUStaffVacanciesPositionDropdowns) {
            const select = <HTMLSelectElement>specialEducationIUStaffVacanciesPositionDropdown;

            if (select.selectedIndex > 0) {
                const row = select.dataset.row;

                if ((select[select.selectedIndex] as HTMLOptionElement).dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                    const otherRowElement = <HTMLInputElement>document.querySelector(`.specialEducationIUStaffItemOther[data-row='${row}'`);

                    if (otherRowElement.value === "") {
                        otherRowElement.setAttribute("aria-required", "true");
                        otherRowElement.setAttribute("data-forcerequired", "true")
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    customDoValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission = <HTMLInputElement>document.getElementById("specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission");
        if (specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission === null || specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission.value === "") {
            showMessage = true;
            totalErrors++;
            specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission.classList.add("missing-field");
            specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission.setAttribute("aria-invalid", "true");
            Core.createErrorLabelForInput(specialEducationIUTechnicalStaffVacanciesProfessionalStaffVacanciesasofthedateofsubmission);
        }

        const specialEducationIUTechnicalStaffVacanciesNoVacancies = <HTMLInputElement>document.getElementById("specialEducationIUTechnicalStaffVacanciesNoVacancies");
        if (specialEducationIUTechnicalStaffVacanciesNoVacancies !== null && specialEducationIUTechnicalStaffVacanciesNoVacancies.checked) {

        }
        else {
            const allRows = document.querySelectorAll(".vacanciesTable tr[data-row]");

            for (const thisRow of allRows) {
                const trRow = <HTMLTableRowElement>thisRow;
                const row = trRow.dataset.row;

                const position = <HTMLSelectElement>document.querySelector(`.specialEducationIUStaffVacanciesPositionDropdown[data-row='${row}']`);
                if (position.selectedIndex > 0) {
                    if (position[position.selectedIndex].dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                        const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUStaffItemOther[data-row="${row}"]`);

                        if (otherElement === null || otherElement.value === "") {
                            showMessage = true;
                            totalErrors++;
                            otherElement.classList.add("missing-field");
                            otherElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(otherElement);
                        }
                    }

                    const elementaryCheckbox = <HTMLInputElement>document.querySelector(`.elementary[data-row='${row}`);
                    const secondaryCheckbox = <HTMLInputElement>document.querySelector(`.secondary[data-row='${row}`);

                    const elementaryFTE = <HTMLInputElement>document.querySelector(`.elementaryFTE[data-row='${row}`);
                    const secondaryFTE = <HTMLInputElement>document.querySelector(`.secondaryFTE[data-row='${row}`);

                    if (elementaryCheckbox !== null && elementaryCheckbox.checked) {
                        if (elementaryFTE === null || elementaryFTE.value === "") {
                            showMessage = true;
                            totalErrors++;
                            elementaryFTE.classList.add("missing-field");
                            elementaryFTE.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(elementaryFTE);
                        }
                    }

                    if (secondaryCheckbox !== null && secondaryCheckbox.checked) {
                        if (secondaryFTE === null || secondaryFTE.value === "") {
                            showMessage = true;
                            totalErrors++;
                            secondaryFTE.classList.add("missing-field");
                            secondaryFTE.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(secondaryFTE);
                        }
                    }                    
                } else {
                    const elementaryCheckbox = <HTMLInputElement>document.querySelector(`.elementary[data-row='${row}`);
                    const secondaryCheckbox = <HTMLInputElement>document.querySelector(`.secondary[data-row='${row}`);

                    if ((elementaryCheckbox !== null && elementaryCheckbox.checked) || (secondaryCheckbox !== null && secondaryCheckbox.checked)) {
                        showMessage = true;
                        totalErrors++;
                        position.classList.add("missing-field");
                        position.setAttribute("aria-invalid", "true");
                        Core.createErrorLabelForInput(position);
                    }
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUStaffVacanciesForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUStaffVacanciesExportToExcel`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUStaffVacancies.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    checkNoVacancies() {
        const iuSpecialEducationStaffVacanciesAddRow = <HTMLButtonElement>document.getElementById("iuSpecialEducationStaffVacanciesAddRow");
        const iuSpecialEducatioStaffVacanciesAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducatioStaffVacanciesAddRowNumber");
        const allFields = document.getElementsByClassName("specialEducationIUStaffField");
        const deletes = document.getElementsByClassName("deleteStaffRow");

        const specialEducationIUTechnicalStaffVacanciesNoVacancies = <HTMLInputElement>document.getElementById("specialEducationIUTechnicalStaffVacanciesNoVacancies");
        if (specialEducationIUTechnicalStaffVacanciesNoVacancies !== null)
            if (specialEducationIUTechnicalStaffVacanciesNoVacancies.checked) {
                iuSpecialEducationStaffVacanciesAddRow.disabled = true;
                iuSpecialEducatioStaffVacanciesAddRowNumber.readOnly = true;
                for (const field of allFields) {
                    this._core.forceElementOptional(field as HTMLElement);

                    if (field instanceof HTMLInputElement) {
                        (field as HTMLInputElement).readOnly = true;
                        (field as HTMLInputElement).disabled = true;
                    } else if (field instanceof HTMLSelectElement)
                        (field as HTMLSelectElement).disabled = true;
                }

                for (const button of deletes)
                    (button as HTMLButtonElement).disabled = true;
            } else {
                iuSpecialEducationStaffVacanciesAddRow.disabled = false;
                iuSpecialEducatioStaffVacanciesAddRowNumber.readOnly = false;
                for (const field of allFields) {
                    if (!(field as HTMLElement).classList.contains("notRequired"))
                        this._core.forceElementRequired(field as HTMLElement);

                    if (field instanceof HTMLInputElement) {
                        (field as HTMLInputElement).readOnly = false;
                        (field as HTMLInputElement).disabled = false;
                    } else if (field instanceof HTMLSelectElement)
                        (field as HTMLSelectElement).disabled = false;
                }

                for (const button of deletes)
                    (button as HTMLButtonElement).disabled = false;
            }
    }   
}

// SpecialEducationIUDifficultFill
class SpecialEducationIUDifficultFill {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUDifficultFillField", "specialEducationIUDifficultFillSelectField"];

        this.checkNoVacancies();

        let specialEducationIUDifficultFillSaveButton = document.getElementById("specialEducationIUDifficultFillSave");
        if (specialEducationIUDifficultFillSaveButton !== null)
            specialEducationIUDifficultFillSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        this.customInitializeRequiredFields(this.validationClasses);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let iuSpecialEducationDifficultToFillAddRowButton = document.getElementById("iuSpecialEducationDifficultFillAddRow");
        if (iuSpecialEducationDifficultToFillAddRowButton !== null)
            iuSpecialEducationDifficultToFillAddRowButton.addEventListener("click", (e: Event) => this.addRow());

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        this.bindDeleteStaffVacanciesRows();
        this.bindOtherDropdown();
        this.setupOtherDropdowns();

        const specialEducationIUStaffVacanciesExportToExcelButton = document.getElementById("specialEducationIUDifficultFillExportToExcel");
        if (specialEducationIUStaffVacanciesExportToExcelButton !== null) {
            specialEducationIUStaffVacanciesExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        const specialEducationIUTechnicalDifficultFillNoVacancies = document.getElementById("specialEducationIUTechnicalDifficultFillNoVacancies");
        if (specialEducationIUTechnicalDifficultFillNoVacancies !== null)
            specialEducationIUTechnicalDifficultFillNoVacancies.addEventListener("change", (e: Event) => this.checkNoVacancies());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUDifficultFillForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUDifficultFillField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let selectInputs = document.getElementsByClassName("specialEducationIUDifficultFillSelectField");
        for (let ele of selectInputs) {
            let planPropertyPK = 0;
            let element = <HTMLSelectElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }
            if ((element.value !== "" && element.value !== "0") || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: null,
                    LookupCodeFK: parseInt(element.value),
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };
                allSaveElements.push(saveItem);
            }
        }

        let checkInputs = document.getElementsByClassName("specialEducationIUDifficultFillCheckboxField");
        for (let ele of checkInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = "0";
            if (element.dataset.row !== null)
                rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);

            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
            }

            let value = "off";
            if (element.checked)
                value = "on";

            const saveItem: IPlanProperty = {
                PlanPropertyPK: planPropertyPK,
                PlanFK: planPK,
                PropertyFK: propertyPK,
                TextValue: value,
                LookupCodeFK: null,
                RowNbr: parseInt(rowNumber),
                IsDeletedInd: false
            };
            allSaveElements.push(saveItem);
        }


        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUDifficultFill', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }

    setupOtherDropdowns() {
        let selectElements = document.getElementsByClassName("specialEducationIUDifficultFillSelectWithOtherField");
        for (let selectElement of selectElements)
            this.dropdownChange(selectElement);
    }

    showDeleteRow(e: Event) {
        let deleteButton = <HTMLButtonElement>e.target;
        let row = deleteButton.dataset.row;

        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.show();
    }

    bindDeleteStaffVacanciesRows() {
        let allDeletes = document.getElementsByClassName("deleteStaffRow");
        for (let del of allDeletes)
            del.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    bindOtherDropdown() {
        let selectElements = document.getElementsByClassName("specialEducationIUDifficultFillSelectWithOtherField");
        for (let selectElement of selectElements)
            selectElement.addEventListener("change", (e: Event) => this.dropdownChange(e.target));
    }

    dropdownChange(e) {
        let selectElement = <HTMLSelectElement>e;
        let row = selectElement.dataset.row;
        let otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUDifficultFillField.coreservices-select-other[data-row='${row}']`);

        if (selectElement.options[selectElement.selectedIndex].text.toLowerCase().indexOf("other") >= 0) {
            otherElement.classList.remove("hide");
            selectElement.classList.add("with-other");
        } else {
            otherElement.classList.add("hide");
            selectElement.classList.remove("with-other");
        }
    }

    async addRow() {
        Core.showLoader();
        let numberOfRows = 0;
        let formElement = <HTMLDivElement>document.getElementById("specialEducationIUDifficultFillForm");
        let valueElement = <HTMLInputElement>document.getElementById("iuSpecialEducationDifficultFillAddRowNumber");
        if (valueElement !== null) {
            numberOfRows = parseInt(valueElement.value);
        }

        if (!isNaN(numberOfRows) && numberOfRows > 0) {
            let planFK = formElement.dataset.planfk;
            let that = this;
            let newRow = 0;

            let allRows = document.querySelectorAll("#specialEducationIUDifficultFill table tbody tr");
            for (let row of allRows) {
                let rowEle = <HTMLTableRowElement>row;
                let thisRow = parseInt(rowEle.dataset.row);

                if (thisRow > newRow)
                    newRow = thisRow;
            }

            for (let i = 0; i < numberOfRows; i++) {
                newRow++;

                let response = await fetch(`/IUSpecialEducation/AddDifficultFillRow/${planFK}/${newRow}`, { credentials: 'include' })
                if (response.ok) {

                    const output = await response.text();

                    let newTR = <HTMLTableRowElement>document.createElement("tr");
                    newTR.innerHTML = output;
                    newTR.dataset.row = newRow.toString();

                    let table = document.querySelector("#specialEducationIUDifficultFill table tbody");

                    table.append(newTR);

                    that.bindDeleteStaffVacanciesRows();
                    that.bindOtherDropdown();
                }
            }

            valueElement.value = "";
            let plural = "s";
            if (numberOfRows === 1) plural = "";
            const message = `Row${plural} added!`
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            valueElement.value = "";
            Core.createHTMLAlert("alertMessageDiv", 'An invalid number was entered for number of rows to add. Please correct the number and try again.', 'error', 3000, null);
            Core.hideLoader();
        }
    }

    deleteRowCancel() {
        let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;

        let allElements = document.querySelectorAll(`.specialEducationIUDifficultFill[data-row='${row}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`#specialEducationIUDifficultFill table tbody tr[data-row='${row}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    customDoValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const specialEducationIUTechnicalDifficultFillNoVacancies = <HTMLInputElement>document.getElementById("specialEducationIUTechnicalDifficultFillNoVacancies");
        if (specialEducationIUTechnicalDifficultFillNoVacancies !== null && specialEducationIUTechnicalDifficultFillNoVacancies.checked) {
            //if the checkbox is checked, everything is good.
        } else {
            const allRows = document.querySelectorAll(`#difficultToFillPositionList tr`);

            for (const tabRow of allRows) {
                const rowEle = <HTMLTableRowElement>tabRow;
                const row = rowEle.dataset.row;

                const positionSelect = <HTMLSelectElement>document.querySelector(`.position[data-row='${row}']`);
                const elementarySelect = <HTMLSelectElement>document.querySelector(`.elementary[data-row='${row}']`);

                let hasPosition: boolean = false;
                let hasElementary: boolean = false;
                if (positionSelect !== null && positionSelect.selectedIndex > 0) {
                    hasPosition = true;

                    const selectedValue = <HTMLOptionElement>positionSelect[positionSelect.selectedIndex];
                    if (selectedValue !== null && selectedValue.dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                        const otherField = <HTMLInputElement>document.querySelector(`.specialEducationIUDifficultFillOtherField[data-row='${row}']`);

                        if (otherField === null || otherField.value === "") {
                            otherField.classList.add("missing-field");
                            otherField.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(otherField);
                            showMessage = true;
                            totalErrors++;
                        }
                    }

                }
                if (elementarySelect !== null && elementarySelect.selectedIndex > 0) {
                    hasElementary = true;
                }

                if (hasPosition && !hasElementary) {
                    elementarySelect.classList.add("missing-field");
                    elementarySelect.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(elementarySelect);
                    showMessage = true;
                    totalErrors++;
                } else  if (!hasPosition && hasElementary) {
                    positionSelect.classList.add("missing-field");
                    positionSelect.setAttribute("aria-invalid", "true");
                    Core.createErrorLabelForInput(positionSelect);
                    showMessage = true;
                    totalErrors++;
                }
            }
        }

        let message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        if (!accordionElement.classList.contains("open")) {
                            accordionElement.click();
                        }
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    customInitializeRequiredFields(allClasses: string[], refresh: boolean = false, allowDuplicates = false) {
        const specialEducationIUDifficultFillPositionDropdowns = document.getElementsByClassName("specialEducationIUDifficultFillPositionDropdown");
        for (const specialEducationIUDifficultFillPositionDropdown of specialEducationIUDifficultFillPositionDropdowns) {
            const select = <HTMLSelectElement>specialEducationIUDifficultFillPositionDropdown;

            if (select.selectedIndex > 0) {
                const row = select.dataset.row;

                const allRowSelectElements = document.querySelectorAll(`.specialEducationIUDifficultFillElementaryDropdown[data-row='${row}'`);

                for (const selectEle of allRowSelectElements) {
                    const ele = <HTMLSelectElement>selectEle;

                    if (ele.selectedIndex <= 0) {
                        ele.setAttribute("aria-required", "true");
                        ele.dataset.forcerequired = "true";
                        ele.dataset.isValid = "false";
                    }
                }

                if ((select[select.selectedIndex] as HTMLOptionElement).dataset.lookupcode === "specialEducationIUCoreServicesOther") {
                    const otherRowElement = <HTMLInputElement>document.querySelector(`.specialEducationIUDifficultFillOtherField[data-row='${row}'`);

                    if (otherRowElement.value === "") {
                        otherRowElement.setAttribute("aria-required", "true");
                        otherRowElement.setAttribute("data-forcerequired", "true")
                    }
                }
            }
        }

        this._core.initializeRequiredFields(this.validationClasses);
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUDifficultFillForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUDifficultFillExportToExcel`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `SpecialEducationIUDifficultFill.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    checkNoVacancies() {
        const iuSpecialEducationDifficultFillAddRow = <HTMLButtonElement>document.getElementById("iuSpecialEducationDifficultFillAddRow");
        const iuSpecialEducatioDifficultFillAddRowNumber = <HTMLInputElement>document.getElementById("iuSpecialEducationDifficultFillAddRowNumber");
        const allFields = document.getElementsByClassName("specialEducationIUDifficultFill");
        const deletes = document.getElementsByClassName("deleteStaffRow");

        const specialEducationIUTechnicalDifficultFillNoVacancies = <HTMLInputElement>document.getElementById("specialEducationIUTechnicalDifficultFillNoVacancies");
        if (specialEducationIUTechnicalDifficultFillNoVacancies !== null)
            if (specialEducationIUTechnicalDifficultFillNoVacancies.checked) {
                iuSpecialEducationDifficultFillAddRow.disabled = true;
                iuSpecialEducatioDifficultFillAddRowNumber.readOnly = true;
                for (const field of allFields) {
                    this._core.forceElementOptional(field as HTMLElement);

                    if (field instanceof HTMLSelectElement)
                        (field as HTMLSelectElement).disabled = true;
                }

                for (const button of deletes)
                    (button as HTMLButtonElement).disabled = true;
            } else {
                iuSpecialEducationDifficultFillAddRow.disabled = false;
                iuSpecialEducatioDifficultFillAddRowNumber.readOnly = false;
                for (const field of allFields) {
                    this._core.forceElementRequired(field as HTMLElement);

                    if (field instanceof HTMLSelectElement)
                        (field as HTMLSelectElement).disabled = false;
                }

                for (const button of deletes)
                    (button as HTMLButtonElement).disabled = false;
            }
    }
}

// SpecialEducationIUTACContact
class SpecialEducationIUTACContact {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUTACContactField"];

        let specialEducationIUTACContactSaveButton = document.getElementById("specialEducationIUTACContactSave");
        if (specialEducationIUTACContactSaveButton !== null)
            specialEducationIUTACContactSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUTACContactForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUTACContactField");

        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTACContact', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allSaveElements));
        }
    }
}

// SpecialEducationIUTACStaff
class SpecialEducationIUTACStaff {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUTACStaffField"];

        let specialEducationIUTACStaffSaveButton = document.getElementById("specialEducationIUTACStaffSave");
        if (specialEducationIUTACStaffSaveButton !== null)
            specialEducationIUTACStaffSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this.checkForNoServices();
        this.bindAddButtons();
        this.bindDeleteButtons();
        this.bindFTECalculations();
        this.checkFTETotalsForSections();

        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffFTE");
        for (const fte of allFTEs)
            this.calculateFTE(fte as HTMLInputElement);

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        let iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const specialEducationIUTACStaffCheckboxFields = document.getElementsByClassName("specialEducationIUTACStaffCheckboxField");
        for (const specialEducationIUTACStaffCheckboxField of specialEducationIUTACStaffCheckboxFields)
            specialEducationIUTACStaffCheckboxField.addEventListener("change", (e: Event) => this.checkForNoServices());

        const tacStaffExportToExcelButton = document.getElementById("specialEducationTACStaffExportToExcel");
        if (tacStaffExportToExcelButton !== null) {
            tacStaffExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUTACStaffForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const allData = [];

        const allAccordions = document.querySelectorAll(".Accordion-trigger.tacAccordion");
        for (const accordion of allAccordions) {
            const acc = <HTMLButtonElement>accordion;
            const allInnerElements = [];

            const lookupCodeFK = acc.dataset.lookupcodefk;
            const dataPointPlanPropertyFK = acc.dataset.datapointplanpropertyfk;
            const propertyFK = acc.dataset.propertyfk;

            const textElementsInside = document.querySelectorAll(`.specialEducationIUTACStaffField[data-lookupcodefk='${lookupCodeFK}'`);
            for (const elementInside of textElementsInside) {
                let planPropertyPK = 0;
                const element = <HTMLInputElement>elementInside;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allInnerElements.push(saveItem);
                }
            }

            const totalTextElementsInside = document.querySelectorAll(`.specialEducationIUTACStaffFTETotal[data-lookupcodefk='${lookupCodeFK}'`);
            for (const elementInside of totalTextElementsInside) {
                let planPropertyPK = 0;
                const element = <HTMLInputElement>elementInside;
                const rowNumber = "0";
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allInnerElements.push(saveItem);
                }
            }          

            const checkboxes = document.querySelectorAll(`.specialEducationIUTACStaffCheckboxField[data-lookupcodefk='${lookupCodeFK}']`);
            for (let ele of checkboxes) {
                let planPropertyPK = 0;
                const element = <HTMLInputElement>ele;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.checked || hadValue) {
                    let val = "";
                    if (element.checked)
                        val = "on";
                    else
                        val = "off";

                    let saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: val,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allInnerElements.push(saveItem);
                }
            }

            allData.push(
                {
                    "DataPointPlanPropertyFK": dataPointPlanPropertyFK,
                    "LookupCodeFK": lookupCodeFK,
                    "PropertyFK": propertyFK,
                    "Elements": allInnerElements
                });
        }

        const saveData = {
            "PlanFK": planPK,
            "SaveData": allData
        };

        
        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTACStaff', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allData.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(saveData));
        }
    }

    bindAddButtons() {
        let specialEducationTACAddInitiativeAddButtons = document.getElementsByClassName("addInitiativeRow");
        for (let specialEducationTACAddInitiativeAddButton of specialEducationTACAddInitiativeAddButtons)
            specialEducationTACAddInitiativeAddButton.addEventListener("click", (e: Event) => this.addInitiativeRow(e));
    }

    bindDeleteButtons() {
        let specialEducationTACAddInitiativeDeleteButtons = document.getElementsByClassName("deleteInitiativeRow");
        for (let specialEducationTACAddInitiativeDeleteButton of specialEducationTACAddInitiativeDeleteButtons)
            specialEducationTACAddInitiativeDeleteButton.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    showDeleteRow(e: Event) {
        const deleteButton = <HTMLButtonElement>e.target;
        const row = deleteButton.dataset.row;
        const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
        const lookupCode = deleteButton.dataset.lookupcode;
        const lookupCodeFK = deleteButton.dataset.lookupcodefk;

        const modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcode", lookupCode);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcodefk", lookupCodeFK);
        modal.show();
    }

    async addInitiativeRow(e) {
        Core.showLoader();
        const addButton = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = addButton.dataset.datapointplanpropertypk;
        const lookupCode = addButton.dataset.lookupcode;
        const lookupCodeFK = addButton.dataset.lookupcodefk;
        const formElement = <HTMLDivElement>document.getElementById("specialEducationIUTACStaffForm");

        const planFK = formElement.dataset.planfk;
        let newRow = 0;

        const allRows = document.querySelectorAll(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody tr`);
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        let response = await fetch(`/IUSpecialEducation/AddTACInitiativeRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}/${lookupCode}/${lookupCodeFK}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            const newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.dataset.row = newRow.toString();
            newTR.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
            newTR.dataset.lookupcode = lookupCode;
                                                 
            const table = document.querySelector(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody`);

            table.append(newTR);

            this.bindDeleteButtons();
            this.bindFTECalculations();

            const message = `Row added!`;
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            const message = `There was an issue adding the row. Please try again.`;
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'error', 2000, null);
        }        
    }

    deleteRowCancel() {
        const modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let allElements = document.querySelectorAll(`.specialEducationIUTACStaffField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`.initiativeRows[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] table tbody tr[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindFTECalculations() {
        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffFTE");
        for (let fte of allFTEs)
            fte.addEventListener("input", (e: Event) => this.calculateFTE(e.target as HTMLInputElement));
    }

    calculateFTE(fteField: HTMLInputElement) {
        const dataPointPlanPropertyPK = fteField.dataset.datapointplanpropertypk;

        let totalFTE: number = 0;
        const theseFTEs = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let fte of theseFTEs) {
            const fteElement = <HTMLInputElement>fte;
            const thisValue = parseFloat(fteElement.value);

            if(!isNaN(thisValue))
                totalFTE += thisValue;
        }

        const totalFTEElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffFTETotal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`)
        totalFTEElement.value = totalFTE.toString();
    }

    checkFTETotalsForSections() {
        const allRows = document.getElementsByClassName("initiativeRows");

        //1. Compare with Component 2.
        for (const row of allRows) {
            const ele = <HTMLDivElement>row;
            const dataPointPlanPropertyPK = ele.dataset.datapointplanpropertypk;
            const lookupCode = ele.dataset.lookupcode;

            const thisFTETotalElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffFTETotal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const thisSectionComponent2TotalElement = <HTMLInputElement>document.querySelector(`#component2${lookupCode}`);
            const thisFTETotal = parseFloat(thisFTETotalElement.value);
            const thisSectionComponent2Total = parseFloat(thisSectionComponent2TotalElement.value);

            if (!isNaN(thisSectionComponent2Total) && !isNaN(thisFTETotal)) {
                if (thisFTETotal !== thisSectionComponent2Total) {
                    const errorMessage = document.querySelector(`#category2Match`);

                    errorMessage.classList.remove("hide");
                }
            }
        }

        //2. Special cases for TaC
        const highIncidenceRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeAcademics']`);
        let highIncidenceTotal = 0;
        let highIncidenceValid = false;
        let showHighIncidenceError = true;
        for (const highIncidenceRow of highIncidenceRows) {
               
            const fteEle = <HTMLInputElement>highIncidenceRow;

            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    highIncidenceTotal += fteEleValue;

                if (fteEleValue >= 0.2)
                    highIncidenceValid = true;
            }
            
            if (highIncidenceTotal > 1.0 && highIncidenceValid) 
                showHighIncidenceError = false;
        }

        const lowIncidenceRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeBehaviorInteragencyCoordination']`);
        let lowIncidenceTotal = 0;
        let lowIncidenceValid = false;
        let showLowIncidenceError = true;
        for (const lowIncidenceRow of lowIncidenceRows) {

            const fteEle = <HTMLInputElement>lowIncidenceRow;

            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    lowIncidenceTotal += fteEleValue;

                if (fteEleValue >= 0.2)
                    lowIncidenceValid = true;
            }

            if (lowIncidenceTotal >= 1.0 && lowIncidenceValid)
                showLowIncidenceError = true;
        }


        const attractPrepareRetainRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeInclusivePracticesLeastRestrictiveEnvironmentLRE']`);
        let attractPrepareRetainTotal = 0;
        let attractPrepareRetainValid = false;
        let showAttractPrepareRetainError = true;
        for (const attractPrepareRetainRow of attractPrepareRetainRows) {

            const fteEle = <HTMLInputElement>attractPrepareRetainRow;

            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    attractPrepareRetainTotal += fteEleValue;

                if (fteEleValue >= 0.5)
                    attractPrepareRetainValid = true;
            }

            if (attractPrepareRetainTotal >= 1.0 && attractPrepareRetainValid)
                showAttractPrepareRetainError = true;
        }
        
        const postSchoolOutcomesRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeAutism']`);
        let postSchoolOutcomesTotal = 0;
        let postSchoolOutcomesValid = false;
        let showPostSchoolOutscomesError = true;
        for (const postSchoolOutcomesRow of postSchoolOutcomesRows) {

            const fteEle = <HTMLInputElement>postSchoolOutcomesRow;

            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    postSchoolOutcomesTotal += fteEleValue;

                if (fteEleValue >= 0.2)
                    postSchoolOutcomesValid = true;
            }

            if (postSchoolOutcomesTotal >= 1.0 && postSchoolOutcomesValid)
                showPostSchoolOutscomesError = true;
        }


        const collaborativePartnershipsRows = document.querySelectorAll(`.specialEducationIUTACStaffFTE[data-lookupcode='specialEducationTACInitiativeAssistiveTechnology']`);
        let collaborativePartnershipsTotal = 0;
        let collaborativePartnershipsValid = false;
        let showCollaborativePartnershipError = true;
        for (const collaborativePartnershipsRow of collaborativePartnershipsRows) {

            const fteEle = <HTMLInputElement>collaborativePartnershipsRow;

            if (fteEle.value !== "") {
                const fteEleValue = parseFloat(fteEle.value);
                if (!isNaN(fteEleValue))
                    collaborativePartnershipsTotal += fteEleValue;

                if (fteEleValue >= 0.2)
                    collaborativePartnershipsValid = true;
            }

            if (collaborativePartnershipsTotal >= 1.0 && collaborativePartnershipsValid)
                showCollaborativePartnershipError = true;
        }

        if (!showHighIncidenceError) {
            const specialErrorElement = <HTMLDivElement>document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeAcademics']`)
            specialErrorElement.classList.remove("hide");
        }

        if (!showLowIncidenceError) {
            const specialErrorElement = <HTMLDivElement>document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeBehaviorInteragencyCoordination']`)
            specialErrorElement.classList.remove("hide");
        }

        if (!showAttractPrepareRetainError) {
            const specialErrorElement = <HTMLDivElement>document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeInclusivePracticesLeastRestrictiveEnvironmentLRE']`)
            specialErrorElement.classList.remove("hide");
        }

        if (!showPostSchoolOutscomesError) {
            const specialErrorElement = <HTMLDivElement>document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeAutism']`)
            specialErrorElement.classList.remove("hide");
        }

        if (!showCollaborativePartnershipError) {
            const specialErrorElement = <HTMLDivElement>document.querySelector(`.initiativeRowsSpecialError[data-lookupcode='specialEducationTACInitiativeAssistiveTechnology']`)
            specialErrorElement.classList.remove("hide");
        }
    }

    customInitializeRequiredFields(allClasses: string[]) {
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        const classesToValidate = formattedAllClasses.join(",");
        const allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }

    customDoValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const allAccordions = document.querySelectorAll(".Accordion-trigger.tacAccordion");
        for (const accordion of allAccordions) {
            const button = <HTMLInputElement>accordion;
            const lookupCodeFK = button.dataset.lookupcodefk;

            const noServices = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffCheckboxField[data-lookupcodefk='${lookupCodeFK}']`);
            if (noServices.checked) {
                //Validation is done!
            } else {
                const rows = document.querySelectorAll(`.specialEducationIUTACStaffField.name[data-lookupcodefk='${lookupCodeFK}']`);
                const allRows = [];
                for (const r of rows) {
                    const rowEle = <HTMLInputElement>r;
                    const row = rowEle.dataset.row;
                    allRows.push(row);
                }

                if (allRows.length > 0) {
                    let hasAtLeastOneRow: boolean = false;
                    for (const row of allRows) {
                        let hasName: boolean = false;
                        let hasFTE: boolean = false;
                        let hasFund: boolean = false;

                        const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffField.name[data-lookupcodefk='${lookupCodeFK}'][data-row='${row}']`);
                        const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffField.fte[data-lookupcodefk='${lookupCodeFK}'][data-row='${row}']`);
                        const fundElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffField.fund[data-lookupcodefk='${lookupCodeFK}'][data-row='${row}']`);

                        if (nameElement === null || nameElement.value === "") {                           
                            if ((fteElement !== null && fteElement.value !== "") || (fundElement !== null && fundElement.value !== "")) {
                                nameElement.classList.add("missing-field");
                                nameElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(nameElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            hasName = true;
                        }

                        if (fteElement === null || fteElement.value === "") {
                            if ((nameElement !== null && nameElement.value !== "") || (fundElement !== null && fundElement.value !== "")) {
                                fteElement.classList.add("missing-field");
                                fteElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fteElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            hasFTE = true;
                        }


                        if (fundElement === null || fundElement.value === "") {
                            if ((nameElement !== null && nameElement.value !== "") || (fteElement !== null && fteElement.value !== "")) {
                                fundElement.classList.add("missing-field");
                                fundElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(fundElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            hasFund = true;
                        }

                        if (hasName && hasFTE && hasFund)
                            hasAtLeastOneRow = true;
                    }
                }
            }
        }

        const message = <HTMLDivElement>document.getElementById("validationMessage");
        const allWarnings = document.getElementsByClassName("initiative-error");
        if (allWarnings.length > 0) {
            for (const warning of allWarnings) {
                if (!((warning as HTMLElement).classList.contains("hide"))) {
                    showMessage = true;
                    totalErrors += 1;
                }
            }
        }

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    checkForNoServices() {
        const specialEducationIUTACStaffCheckboxFields = document.getElementsByClassName("specialEducationIUTACStaffCheckboxField");
        for (const specialEducationIUTACStaffCheckboxField of specialEducationIUTACStaffCheckboxFields)
        {
            const element = <HTMLInputElement>specialEducationIUTACStaffCheckboxField;
            const lookupCodeFK = element.dataset.lookupcodefk;

            const allFields = document.querySelectorAll(`.specialEducationIUTACStaffField[data-lookupcodefk='${lookupCodeFK}']`);
            for (const field of allFields) {
                const ele = <HTMLInputElement>field;

                if (element.checked) {
                    this._core.forceElementOptional(ele);
                    ele.readOnly = true;
                } else {
                    this._core.forceElementRequired(ele);
                    ele.readOnly = false;
                }
            }

            const button = <HTMLButtonElement>document.querySelector(`.addInitiativeRow[data-lookupcodefk='${lookupCodeFK}']`);
            if (button !== null)
                if (element.checked)
                    button.disabled = true;
                else
                    button.disabled = false;

        }
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUTACStaffForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUTACStaffIDEABExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `TaCStaffRoster-IDEA-B-Funds.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }
}

// SpecialEducationIUTACStaffNonIDEA
class SpecialEducationIUTACStaffNonIDEA {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.fundsSelect();
        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffNonIDEABFTE");
        for (let fte of allFTEs)
            this.calculateFTE(fte as HTMLInputElement);
        this.validationClasses = ["specialEducationIUTACStaffNonIDEAField", "specialEducationIUTACStaffNonIDEASelectField"];

        const specialEducationIUTACStaffNonIDEASaveButton = document.getElementById("specialEducationIUTACStaffNonIDEASave");
        if (specialEducationIUTACStaffNonIDEASaveButton !== null)
            specialEducationIUTACStaffNonIDEASaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        const fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this.customDoValidation(this.validationClasses);
        }
        this.customInitializeRequiredFields(this.validationClasses);

        const hidden = this._core.createHash(this.validationClasses);
        const hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        const specialEducationTACStaffNonIDEAInitiativeAdd = document.getElementById("specialEducationTACStaffNonIDEAInitiativeAdd");
        if (specialEducationTACStaffNonIDEAInitiativeAdd !== null)
            specialEducationTACStaffNonIDEAInitiativeAdd.addEventListener("click", (e: Event) => this.nonIdeaInitiativeAdd(e));

        this.bindFundsSelectChange();
        this.bindAddRowButtons();
        this.bindFTECalculations();

        const iuSpecialEducationCoreServicesRowDeleteCancelButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteCancel");
        if (iuSpecialEducationCoreServicesRowDeleteCancelButton !== null)
            iuSpecialEducationCoreServicesRowDeleteCancelButton.addEventListener("click", (e: Event) => this.deleteRowCancel());

        const iuSpecialEducationCoreServicesRowDeleteConfirmButton = document.getElementById("iuSpecialEducationCoreServicesRowDeleteConfirm");
        if (iuSpecialEducationCoreServicesRowDeleteConfirmButton !== null)
            iuSpecialEducationCoreServicesRowDeleteConfirmButton.addEventListener("click", (e: Event) => this.deleteRowConfirm(e));

        const specialEducationIUTACStaffNonIDEABAddAnInitiative = document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiative");
        if (specialEducationIUTACStaffNonIDEABAddAnInitiative)
            specialEducationIUTACStaffNonIDEABAddAnInitiative.addEventListener("change", (e: Event) => this.checkForOther());

        const specialEducationTACNonIdeaBInitiativeDeleteConfirm = document.getElementById("specialEducationTACNonIdeaBInitiativeDeleteConfirm");
        if(specialEducationTACNonIdeaBInitiativeDeleteConfirm !== null)
            specialEducationTACNonIdeaBInitiativeDeleteConfirm.addEventListener("click", (e: Event) => this.deleteInitiativeConfirm(e));

        const specialEducationTACNonIdeaBInitiativeDeleteCancel = document.getElementById("specialEducationTACNonIdeaBInitiativeDeleteCancel");
        if (specialEducationTACNonIdeaBInitiativeDeleteCancel !== null)
            specialEducationTACNonIdeaBInitiativeDeleteCancel.addEventListener("click", (e: Event) => this.deleteInitiativeCancel());


        const tacStaffExportToExcelButton = document.getElementById("specialEducationTACStaffNonIDEABExportToExcel");
        if (tacStaffExportToExcelButton !== null) {
            tacStaffExportToExcelButton.addEventListener("click", () => {
                Core.showLoader();
                this.exportToExcel()
                    .then((response) => {
                        Core.hideLoader();
                    })
                    .catch((error) => {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", error, 'error', 3000, null);
                    });
            });
        }

        this.bindDeleteInitiatives();
    }

    createHash() {
        const hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        const core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        const formElement = document.getElementById("specialEducationIUTACStaffNonIDEAForm");
        const planPK = parseInt(formElement.dataset.planfk);

        const allData = [];

        const accordions = document.querySelectorAll(`.Accordion-trigger.tacAccordion`);
        for (const accord of accordions) {
            const element = <HTMLButtonElement>accord;
            const dataPointPlanPropertyPK = element.dataset.datapointplanpropertyfk;
            const lookupCodeFK = element.dataset.lookupcodefk;
            const allSaveElements = [];

            const textInputs = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of textInputs) {
                let planPropertyPK = 0;
                const element = <HTMLInputElement>ele;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "" || hadValue) {
                    const saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: element.value,
                        LookupCodeFK: null,
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }

            const selects = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEASelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            for (const ele of selects) {
                let planPropertyPK = 0;
                const element = <HTMLSelectElement>ele;
                const rowNumber = element.dataset.row;
                const propertyPK = parseInt(element.dataset.propertypk);
                let hadValue = false;
                if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                    planPropertyPK = parseInt(element.dataset.planpropertypk);
                    hadValue = true;
                }

                if (element.value !== "0" || hadValue) {
                    const saveItem: IPlanProperty = {
                        PlanPropertyPK: planPropertyPK,
                        PlanFK: planPK,
                        PropertyFK: propertyPK,
                        TextValue: "",
                        LookupCodeFK: parseInt(element.value),
                        RowNbr: parseInt(rowNumber),
                        IsDeletedInd: false
                    };

                    allSaveElements.push(saveItem);
                }
            }

            if (allSaveElements.length > 0) {
                const thisData = {
                    "PlanFK": planPK,
                    "DataPointPlanPropertyPK": dataPointPlanPropertyPK,
                    "Elements": allSaveElements
                };

                allData.push(thisData);
            }
        }
        
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUTACStaffNonIDEA', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        xhr.send(JSON.stringify(allData));
    }

    async nonIdeaInitiativeAdd(e: Event) {
        Core.showLoader();
        const formElement = <HTMLDivElement>document.getElementById("specialEducationIUTACStaffNonIDEAForm");

        const planFK = formElement.dataset.planfk;

        let initiativeValue = 0;
        let initiativeOtherValue = "";
        const initiativeElement = <HTMLSelectElement>document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiative");
        if (initiativeElement !== null) 
            initiativeValue = parseInt(initiativeElement.value);

        if (initiativeValue === 9999) {
            const specialEducationIUTACStaffNonIDEABAddAnInitiativeOther = <HTMLInputElement>document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiativeOther");
            if (specialEducationIUTACStaffNonIDEABAddAnInitiativeOther)
                initiativeOtherValue = specialEducationIUTACStaffNonIDEABAddAnInitiativeOther.value;
        }
       
        let alreadyExists = false;
        const existingAccordion = document.querySelector(`.Accordion-trigger[data-lookupcodefk='${initiativeValue}']`);
        if (existingAccordion !== null)
            alreadyExists = true;

        if (alreadyExists && initiativeValue !== 9999) {
            Core.createHTMLAlert("alertMessageDiv", "This initiative already exists. Make edits to the existing initiative or pick another one.", 'warning', 2000, null);
            Core.hideLoader();
        } else {
            const data = {
                "InitiativeLookupCodeFK": initiativeValue,
                "InitiativeOtherValue": initiativeOtherValue,
                "PlanFK": planFK,
            };

            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }

            const response = await fetch("/IUSpecialEducation/AddNonIdeaTaCInitiative", config);
            if (response.ok) {

                const initiativeContainer = <HTMLDivElement>document.getElementById("initiativesContainer");
                const output = await response.text();

                let newDiv = <HTMLTableRowElement>document.createElement("div");
                newDiv.innerHTML = output;
                initiativeContainer.append(newDiv);

                let idControl = newDiv.querySelector(".Accordion h2 button");
                let id = idControl.id;
                new CustomAccordion(id);

                this.bindAddRowButtons();
                this.bindFTECalculations();
                this.bindDeleteInitiatives();

                initiativeElement.selectedIndex = 0;

                const message = `Initiative added!`
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "There was an issue adding the initiative, please try again.", 'error', 2000, null);
            }
        }        
    }

    async nonIdeaInitiativeRowAdd(e: Event) {
        Core.showLoader();
        const addButton = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = addButton.dataset.datapointplanpropertypk;
        const lookupCode = addButton.dataset.lookupcode;
        const lookupCodeFK = addButton.dataset.lookupcodefk;
        const formElement = <HTMLDivElement>document.getElementById("specialEducationIUTACStaffNonIDEAForm");

        const planFK = formElement.dataset.planfk;
        let newRow = 0;

        const allRows = document.querySelectorAll(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody tr`);
        for (let row of allRows) {
            let rowEle = <HTMLTableRowElement>row;
            let thisRow = parseInt(rowEle.dataset.row);

            if (thisRow > newRow)
                newRow = thisRow;
        }

        newRow++;

        let response = await fetch(`/IUSpecialEducation/AddNonIdeaTACInitiativeRow/${planFK}/${newRow}/${dataPointPlanPropertyPK}/${lookupCode}/${lookupCodeFK}`, { credentials: 'include' })
        if (response.ok) {

            const output = await response.text();

            const newTR = <HTMLTableRowElement>document.createElement("tr");
            newTR.innerHTML = output;
            newTR.dataset.row = newRow.toString();
            newTR.dataset.datapointplanpropertypk = dataPointPlanPropertyPK;
            newTR.dataset.lookupcode = lookupCode;

            const table = document.querySelector(`.initiativeRows[data-lookupcodefk='${lookupCodeFK}'] table tbody`);

            table.append(newTR);

            this.bindDeleteButtons();
            this.bindFundsSelectChange();
            this.bindFTECalculations();

            const message = `Row added!`;
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'success', 2000, null);
        } else {
            const message = `There was an issue adding the row. Please try again.`;
            Core.hideLoader();
            Core.createHTMLAlert("alertMessageDiv", message, 'error', 2000, null);
        }
    }

    bindAddButtons() {
        const specialEducationTACAddInitiativeAddButtons = document.getElementsByClassName("addInitiativeRow");
        for (const specialEducationTACAddInitiativeAddButton of specialEducationTACAddInitiativeAddButtons)
            specialEducationTACAddInitiativeAddButton.addEventListener("click", (e: Event) => this.nonIdeaInitiativeAdd(e));
    }

    bindAddRowButtons() {
        const specialEducationTACAddInitiativeAddRowButtons = document.getElementsByClassName("addInitiativeRow");
        for (const specialEducationTACAddInitiativeAddRowButton of specialEducationTACAddInitiativeAddRowButtons)
            specialEducationTACAddInitiativeAddRowButton.addEventListener("click", (e: Event) => this.nonIdeaInitiativeRowAdd(e));
    }

    bindFundsSelectChange() {
        const allSelects = document.getElementsByClassName("coreservices-select");
        for (const select of allSelects)
            select.addEventListener("change", (e: Event) => this.fundsSelect());
    }

    fundsSelect() {
        const allSelects = document.getElementsByClassName("coreservices-select");
        for (const select of allSelects) {
            const element = <HTMLSelectElement>select;
            const selected = <HTMLOptionElement>element[element.selectedIndex];
            const row = element.dataset.row;
            const dataPointPlanPropertyPK = element.dataset.datapointplanpropertypk;

            const otherElement = <HTMLInputElement>document.querySelector(`.other[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
            if (selected.dataset.lookupcode === "specialEducationIUNonIDEABFundsO") {
                if (otherElement !== null) {
                    element.classList.add("with-other");
                    otherElement.classList.remove("hide");
                    this._core.forceElementRequired(otherElement);
                }
            } else {
                if (otherElement !== null) {
                    element.classList.remove("with-other");
                    otherElement.classList.add("hide");
                    this._core.forceElementOptional(otherElement);
                }
            }
        }
    }

    deleteRowCancel() {
        const modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.hide();
    }

    deleteRowConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planProps = [];
        let row = buttonElement.dataset.row;
        let dataPointPlanPropertyPK = buttonElement.dataset.datapointplanpropertypk;

        let allElements = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let ele of allElements) {
            let element = <HTMLElement>ele;
            let planProp = element.dataset.planpropertypk;

            if (planProp && parseInt(planProp) > 0) {
                planProps.push(planProp);
            }
        }

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteRowData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                Core.hideLoader();

                let rowElement = document.querySelector(`.initiativeRows[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'] table tbody tr[data-row='${row}'][data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
                rowElement.remove();

                let modal: Modal = new Modal("deleteCoreServicesRowModal", null);
                modal.hide();
                Core.createHTMLAlert("alertMessageDiv", "The row data has been removed.", 'success', 3000, null);
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planProps));
    }

    bindFTECalculations() {
        const allFTEs = document.getElementsByClassName("specialEducationIUTACStaffNonIDEABFTE");
        for (let fte of allFTEs)
            fte.addEventListener("input", (e: Event) => this.calculateFTE(e.target as HTMLInputElement));
    }

    calculateFTE(fteField: HTMLInputElement) {
        const dataPointPlanPropertyPK = fteField.dataset.datapointplanpropertypk;

        let totalFTE: number = 0;
        const theseFTEs = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEABFTE[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (let fte of theseFTEs) {
            const fteElement = <HTMLInputElement>fte;
            const thisValue = parseFloat(fteElement.value);

            if (!isNaN(thisValue))
                totalFTE += thisValue;
        }

        const totalFTEElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffFTETotal[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`)
        totalFTEElement.value = totalFTE.toString();
    }

    bindDeleteButtons() {
        let specialEducationTACAddInitiativeDeleteButtons = document.getElementsByClassName("deleteInitiativeRow");
        for (let specialEducationTACAddInitiativeDeleteButton of specialEducationTACAddInitiativeDeleteButtons)
            specialEducationTACAddInitiativeDeleteButton.addEventListener("click", (e: Event) => this.showDeleteRow(e));
    }

    showDeleteRow(e: Event) {
        const deleteButton = <HTMLButtonElement>e.target;
        const row = deleteButton.dataset.row;
        const dataPointPlanPropertyPK = deleteButton.dataset.datapointplanpropertypk;
        const lookupCode = deleteButton.dataset.lookupcode;
        const lookupCodeFK = deleteButton.dataset.lookupcodefk;

        const modal: Modal = new Modal("deleteCoreServicesRowModal", null);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "row", row);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcode", lookupCode);
        modal.addAttributeToElement("deleteCoreServicesRowModal", "#iuSpecialEducationCoreServicesRowDeleteConfirm", "lookupcodefk", lookupCodeFK);
        modal.show();
    }

    checkForOther() {
        const specialEducationIUTACStaffNonIDEABAddAnInitiative = <HTMLSelectElement>document.getElementById("specialEducationIUTACStaffNonIDEABAddAnInitiative");
        if (specialEducationIUTACStaffNonIDEABAddAnInitiative !== null) {
            const otherInitiative = <HTMLDivElement>document.getElementById("otherInitiative");
            if (specialEducationIUTACStaffNonIDEABAddAnInitiative.value === "9999") {
                otherInitiative.classList.remove("hide");

            } else {
                otherInitiative.classList.add("hide");
            }
        }
    }

    customInitializeRequiredFields(allClasses: string[]) {
        const formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        const classesToValidate = formattedAllClasses.join(",");
        const allElements = document.querySelectorAll(classesToValidate);

        for (let element of allElements) {
            let htmlElement = <HTMLElement>element;

            if ("percent" in htmlElement.dataset && htmlElement.dataset.percent !== "" && htmlElement.dataset.percent === "1.00") {
                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");
                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }

            //Manual override when data-forcerequired=true
            if ("forcerequired" in htmlElement.dataset && htmlElement.dataset.forcerequired === "true") {

                htmlElement.dataset.percent = "1.00";

                if (!(htmlElement.hasAttribute("aria-required") && htmlElement.getAttribute("aria-required") === "true")) {
                    htmlElement.setAttribute("aria-required", "true");

                    let label = Core.findLabelForInput(htmlElement);

                    if (label !== null && !label.classList.contains("isRequired")) {
                        label.innerHTML = label.innerHTML + " <span class='required-label'>*</span>";
                        label.classList.add("isRequired");
                    }
                }
            }
        }
    }

    customDoValidation(allClasses: string[], showMessageOverride?: boolean) {
        let showMessage = showMessageOverride === undefined ? this.customClientSideValidation(allClasses) : showMessageOverride;
        let messageContainerColumn = <HTMLElement>document.getElementById("validationColumn");
        let messageContainer = <HTMLElement>document.getElementById("validationMessageContainer");
        messageContainerColumn.classList.add("show");
        let validationIcon = <HTMLElement>document.getElementById("validationMessageIcon");

        setTimeout(function () {
            messageContainer.focus();
        }, 500);

        if (showMessage) {

            let message = <HTMLElement>document.getElementById("validationMessage");
            messageContainer.classList.add("warning");
            message.classList.add("show");
            validationIcon.innerHTML = "<i class='fas fa-exclamation-triangle'></i>";

        } else {
            messageContainer.classList.add("success");
            validationIcon.innerHTML = "<i class='fas fa-check-circle'></i>";

            let successMessage = <HTMLElement>document.getElementById("saveSuccess");

            if (successMessage !== null) {
                successMessage.innerHTML = "The page has been successfully saved."
            }
        }
    }

    customClientSideValidation(allClasses: string[]): boolean {
        let showMessage: boolean = false;
        let totalErrors = 0;

        let formattedAllClasses = [];
        allClasses.forEach(function (part, index) {
            formattedAllClasses[index] = "." + allClasses[index];
        });

        let classesToValidate = formattedAllClasses.join(",");

        //Remove all validation messages
        [].forEach.call(document.querySelectorAll('.missing-field-label, .validationErrorCountMessage'), function (e) {
            e.parentNode.removeChild(e);
        });

        //Remove missing field class
        [].forEach.call(document.querySelectorAll('.missing-field'), function (e) {
            e.classList.remove("missing-field");
        });

        //Remove hasBeenValidated class
        [].forEach.call(document.querySelectorAll('.hasBeenValidated'), function (e) {
            e.classList.remove("hasBeenValidated");
        });

        const allAccordions = document.querySelectorAll(".Accordion-trigger.tacAccordion");
        for (const accordion of allAccordions) {
            const button = <HTMLInputElement>accordion;
            const lookupCodeFK = button.dataset.lookupcodefk;
            const dataPointPlanPropertyPK = button.dataset.datapointplanpropertyfk;

            const rows = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
            const allRows = [];
            for (const r of rows) {
                const rowEle = <HTMLInputElement>r;
                const row = rowEle.dataset.row;
                allRows.push(row);
            }

            if (allRows.length > 0) {
                let hasAtLeastOneRow: boolean = false;
                for (const row of allRows) {
                    let hasName: boolean = false;
                    let hasFTE: boolean = false;
                    let hasFund: boolean = false;
                    let hasOther: boolean = false;

                    const nameElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffNonIDEAField.name[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const fteElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffNonIDEAField.fte[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                    const fundElement = <HTMLSelectElement>document.querySelector(`.specialEducationIUTACStaffNonIDEASelectField.fund[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);

                    if (nameElement.value.trim() === "") {
                        if ((fteElement !== null && fteElement.value !== "") || (fundElement !== null && fundElement.value !== "0")) {
                            nameElement.classList.add("missing-field");
                            nameElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(nameElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else {
                        hasName = true;
                    }

                    if (fteElement.value.trim() === "") {
                        if ((nameElement !== null && nameElement.value.trim() !== "") || (fundElement !== null && fundElement.value.trim() !== "0")) {
                            fteElement.classList.add("missing-field");
                            fteElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fteElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else {
                        hasFTE = true;
                    }


                    if (fundElement.value.trim() === "0") {
                        if ((nameElement !== null && nameElement.value.trim() !== "") || (fteElement !== null && fteElement.value.trim() !== "")) {
                            fundElement.classList.add("missing-field");
                            fundElement.setAttribute("aria-invalid", "true");
                            Core.createErrorLabelForInput(fundElement);
                            showMessage = true;
                            totalErrors++;
                        }
                    } else {
                        hasFund = true;

                        const selectedElement = <HTMLOptionElement>fundElement[fundElement.selectedIndex];
                        if (selectedElement.dataset.lookupcode === "specialEducationIUNonIDEABFundsO") {
                            const otherElement = <HTMLInputElement>document.querySelector(`.specialEducationIUTACStaffNonIDEAField.other[data-datapointplanpropertypk='${dataPointPlanPropertyPK}'][data-row='${row}']`);
                            if (otherElement !== null && otherElement.value !== "") {
                                hasOther = true;
                            } else {
                                otherElement.classList.add("missing-field");
                                otherElement.setAttribute("aria-invalid", "true");
                                Core.createErrorLabelForInput(otherElement);
                                showMessage = true;
                                totalErrors++;
                            }
                        } else {
                            //This needs to be true here since it won't exist for this (which makes it valid). 
                            hasOther = true;
                        }
                    }

                    if (hasName && hasFTE && hasFund && hasOther)
                        hasAtLeastOneRow = true;
                }
            }
        }

        const message = <HTMLDivElement>document.getElementById("validationMessage");

        if (totalErrors === 1) {
            message.innerHTML = "<p class='validationErrorCountMessage'>There is " + totalErrors + " issue to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to issue</a>";
        } else {
            message.innerHTML = "<p class='validationErrorCountMessage'>There are " + totalErrors + " issues to fix on this page</p><a id='goToFirstError' href='javascript:void(0)'>Go to first issue</a>";
        }
        let goToError = document.getElementById("goToFirstError");

        if (goToError !== null) {

            let that = this;

            let firstFocusableEl = <HTMLElement>document.querySelector(".missing-field");

            if (firstFocusableEl !== null) {
                goToError.addEventListener("click", function () {
                    let accordion = Core.findClosest(firstFocusableEl, ".Accordion-panel");
                    if (accordion) {
                        let id = accordion.getAttribute("aria-labelledby");

                        let accordionElement = <HTMLButtonElement>document.getElementById(id);
                        accordionElement.click();
                    }

                    if (firstFocusableEl.classList.contains("mce")) {
                        tinymce.execCommand('mceFocus', false, firstFocusableEl.id);
                    } else {
                        firstFocusableEl.focus();
                    }
                });
            } else {
                goToError.parentNode.removeChild(goToError);
            }
        }
        return showMessage;
    }

    exportToExcel() {
        return new Promise<void>((resolve, reject) => {
            let planForm = document.getElementById("specialEducationIUTACStaffNonIDEAForm");
            let planFK = parseInt(planForm.dataset.planfk);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', `/ExportExcel/SpecialEducationIUTACStaffNonIDEABExcelExport`, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (xhr.status === 200) {
                    let blob = this.response;
                    let filename = `TaCStaffRoster-NonIDEA-B-Funds.xlsx`;
                    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        window.navigator.msSaveBlob(blob, filename);
                    }
                    else {
                        var a = document.createElement("a");
                        var blobUrl = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
                        document.body.appendChild(a);
                        a.style.display = "none";
                        a.href = blobUrl;
                        a.download = filename;
                        a.click();
                    }
                    resolve();
                }
                else {
                    reject("There was an issue during export to Excel. Please try again later.");
                }
            };
            xhr.send(`planFK=${planFK}`);
        });
    }

    bindDeleteInitiatives() {
        const deleteButtons = document.getElementsByClassName("deleteInitiativeRow");
        for (const deleteButton of deleteButtons)
            deleteButton.addEventListener("click", (e: Event) => this.showDeleteInitiative(e));
    }

    showDeleteInitiative(e: Event) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const lookupCodeFK = button.dataset.lookupcodefk;
        const lookupCode = button.dataset.lookupcode;

        const modal: Modal = new Modal("deleteInitiativeModal", null);
        modal.addAttributeToElement("deleteInitiativeModal", "#specialEducationTACNonIdeaBInitiativeDeleteConfirm", "datapointplanpropertypk", dataPointPlanPropertyPK);
        modal.addAttributeToElement("deleteInitiativeModal", "#specialEducationTACNonIdeaBInitiativeDeleteConfirm", "lookupcode", lookupCode);
        modal.addAttributeToElement("deleteInitiativeModal", "#specialEducationTACNonIdeaBInitiativeDeleteConfirm", "lookupcodefk", lookupCodeFK);
        modal.show();
    }

    async deleteInitiativeConfirm(e) {
        const button = <HTMLButtonElement>e.target;
        const dataPointPlanPropertyPK = button.dataset.datapointplanpropertypk;
        const lookupCodeFK = button.dataset.lookupcodefk;
        const lookupCode = button.dataset.lookupcode;
        const form = <HTMLElement>document.getElementById("specialEducationIUTACStaffNonIDEAForm");
        const planFK = form.dataset.planfk;

        const planProps = [];

        const inputs = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEAField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const pp of inputs) {
            const ele = <HTMLElement>pp;

            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                planProps.push(ele.dataset.planpropertypk);
        }

        const selects = document.querySelectorAll(`.specialEducationIUTACStaffNonIDEASelectField[data-datapointplanpropertypk='${dataPointPlanPropertyPK}']`);
        for (const pp of selects) {
            const ele = <HTMLElement>pp;

            if (ele.dataset.planpropertypk && ele.dataset.planpropertypk !== "" && ele.dataset.planpropertypk !== "0")
                planProps.push(ele.dataset.planpropertypk);
        }

        planProps.push(dataPointPlanPropertyPK);

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(planProps)
        };

        let response = await fetch(`/IUSpecialEducation/DeleteRowDataWithRelated`, settings)
        if (response.ok) {
            const output = await response.json();

            if (output.success) {
                const accordionHeader = <HTMLButtonElement>document.querySelector(`.Accordion-trigger.tacAccordion[data-datapointplanpropertyfk='${dataPointPlanPropertyPK}']`);
                const accordion = <HTMLDivElement>document.querySelector(`.Accordion-panel.tacAccordion[data-datapointplanpropertyfk='${dataPointPlanPropertyPK}']`);

                accordionHeader.parentElement.remove();
                accordion.remove();

                const modal: Modal = new Modal("deleteInitiativeModal", null);
                modal.hide();

                Core.createHTMLAlert("alertMessageDiv", "The initiative has been removed.", 'success', 3000, null);
            } else {
                const modal: Modal = new Modal("deleteInitiativeModal", null);
                modal.hide();

                Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the initiative, please try again.", 'error', 3000, null);
            }
        } else {
            const modal: Modal = new Modal("deleteInitiativeModal", null);
            modal.hide();

            Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the initiative, please try again.", 'error', 3000, null);
        }
    }

    deleteInitiativeCancel() {
        const modal: Modal = new Modal("deleteInitiativeModal", null);
        modal.hide();
    }
}

// SpecialEducationIUSignatures
class SpecialEducationIUSignatures {
    validationClasses: string[];

    private _core: Core;
    constructor() {
        this._core = new Core();

        this.validationClasses = ["specialEducationIUSignaturesField", "specialEducationIUSignaturesCheckboxField", "specialEducationIUSignaturesFileField"];

        let specialEducationIUSignaturesSaveButton = document.getElementById("specialEducationIUSignaturesSave");
        if (specialEducationIUSignaturesSaveButton !== null)
            specialEducationIUSignaturesSaveButton.addEventListener("click", (e: Event) => this.save("save"));

        this._core.leftnav(this);
        let fromSaveElement = <HTMLInputElement>document.getElementById("fromSave");
        if (fromSaveElement !== null && fromSaveElement.value === "true") {
            this._core.doValidation(this.validationClasses);
        }
        this._core.initializeRequiredFields(this.validationClasses);

        let hidden = this._core.createHash(this.validationClasses);
        let hiddenInput = <HTMLInputElement>document.getElementById("hashValue");
        if (hiddenInput !== null) {
            hiddenInput.value = hidden;
        }

        let fileUploader = document.getElementById("specialEducationIUSignaturesUploadofSchoolBoardMinutesorAffirmationStatement");
        if (fileUploader !== null)
            fileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        let transferFileUploader = document.getElementById("specialEducationIUSignaturesTransferOfEntityLetters");
        if (transferFileUploader !== null)
            transferFileUploader.addEventListener("change", (e: Event) => this.uploadFile(<HTMLInputElement>e.target));

        let deleteFileButtons = document.getElementsByClassName("deleteFile");
        for (let deleteFileButton of deleteFileButtons)
            deleteFileButton.addEventListener("click", (e: Event) => this.showDeleteFile(e));

        let deleteFileConfirmButton = document.getElementById("deleteFileConfirm");
        if (deleteFileConfirmButton !== null)
            deleteFileConfirmButton.addEventListener("click", (e: Event) => this.deleteFileConfirm(e));

        let deleteFileCancelButton = document.getElementById("deleteFileCancel");
        if (deleteFileCancelButton !== null)
            deleteFileCancelButton.addEventListener("click", (e: Event) => this.deleteFileCancel());
    }

    createHash() {
        let hash = this._core.createHash(this.validationClasses);
        return hash;
    }

    getCore() {
        return this._core;
    }

    save(referrer) {
        let allSaveElements = [];
        let core = this._core;
        if (referrer !== "save" && this._core.checkSave(this) === false) {
            window.location.href = referrer;
            return false;
        }
        Core.showLoader();
        let refreshPage = "";

        if (referrer === "continue") {
            refreshPage = document.getElementById("continueButton").getAttribute("data-redirect-url");
        }
        if (referrer === "back") {
            refreshPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        }
        else {
            refreshPage = referrer;
        }
        let formElement = document.getElementById("specialEducationIUSignaturesForm");
        let planPK = parseInt(formElement.dataset.planfk);

        let textInputs = document.getElementsByClassName("specialEducationIUSignaturesField");
        for (let ele of textInputs) {
            let planPropertyPK = 0;
            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.value !== "" || hadValue) {
                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: element.value,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let checkboxes = document.getElementsByClassName("specialEducationIUSignaturesCheckboxField");
        for (let ele of checkboxes) {
            let planPropertyPK = 0;

            let element = <HTMLInputElement>ele;
            let rowNumber = element.dataset.row;
            let propertyPK = parseInt(element.dataset.propertypk);
            let hadValue = false;
            if (element.dataset.planpropertypk !== "" && element.dataset.planpropertypk !== "0") {
                planPropertyPK = parseInt(element.dataset.planpropertypk);
                hadValue = true;
            }

            if (element.checked || hadValue) {
                let val = "";
                if (element.checked)
                    val = "on";
                else
                    val = "off";

                let saveItem: IPlanProperty = {
                    PlanPropertyPK: planPropertyPK,
                    PlanFK: planPK,
                    PropertyFK: propertyPK,
                    TextValue: val,
                    LookupCodeFK: null,
                    RowNbr: parseInt(rowNumber),
                    IsDeletedInd: false
                };

                allSaveElements.push(saveItem);
            }
        }

        let allFileElements: IPlanPropertyFile[] = [];
        let files = document.getElementsByClassName("specialEducationIUSignaturesFileUploadField");
        for (let ele of files) {
            let element = <HTMLInputElement>ele;
            let sequenceNbr = parseInt(element.dataset.sequencenbr);
            let planPropertyFilePK = parseInt(element.dataset.planpropertyfilepk)
            let fileUploadPK = parseInt(element.dataset.fileuploadpk);
            let propertyPK = parseInt(element.dataset.propertypk);

            let saveItem: IPlanPropertyFile = {
                PlanPropertyFilePK: planPropertyFilePK,
                SequenceNbr: sequenceNbr,
                FileUploadPK: fileUploadPK,
                PropertyPK: propertyPK,
                PlanFK: planPK
            };

            allFileElements.push(saveItem);
        }

        var allData = {
            "ElementData": allSaveElements,
            "FileData": allFileElements,
            "PlanFK": planPK
        };

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/SaveSpecialEducationIUSignatures', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (referrer === "save") {
                if (xhr.status === 200) {
                    Core.hideLoader();
                    core.pageReload(true, planPK);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
            else {
                if (xhr.status === 200) {
                    if (refreshPage && refreshPage !== "")
                        window.location.href = refreshPage;
                    else
                        Core.hideLoader();
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
                }
            }
        };
        if (allSaveElements.length === 0 && allFileElements.length === 0) {
            if (referrer === "save") {
                Core.hideLoader();
                core.doValidation(this.validationClasses);
            }
            else {
                if (refreshPage && refreshPage !== "")
                    window.location.href = refreshPage;
                else
                    Core.hideLoader();
            }
        }
        else {
            xhr.send(JSON.stringify(allData));
        }
    }

    uploadFile(e: HTMLInputElement) {
        let core = this._core;
        Core.showLoader();
        let formName = e.dataset.formname;

        let uploadSampleForm = <HTMLFormElement>document.getElementById(formName);
        let formData = new FormData(uploadSampleForm);
        const file = e.dataset.file;

        let propertyPK = e.dataset.propertypk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/UploadFileCustom', true);
        xhr.onload = function () {
            if (xhr.status == 200) {
                if (xhr.responseText !== null) {
                    let res = JSON.parse(xhr.responseText);
                    if (res.success === true) {
                        Core.hideLoader();

                        Core.createHTMLAlert("alertMessageDiv", "The file was uploaded successfully. Make sure to save your changes!", 'success', 3000, null);

                        e.value = "";

                        let fileList = document.querySelector(`.uploadFileList[data-file='${file}'`)

                        let currentFileList = fileList.querySelectorAll(`.uploadFileList[data-file='${file}']`);

                        let sequence = -1;
                        for (let file of currentFileList) {
                            let fileEle = <HTMLDivElement>file;

                            let thisSequence = parseInt(fileEle.dataset.sequencenbr);
                            if (thisSequence > sequence) {
                                sequence = thisSequence;
                            }
                        }

                        let uploadedContainer = document.querySelector(`.uploadedFiles[data-file='${file}']`);
                        uploadedContainer.classList.remove("hide");

                        sequence++;

                        let fileWrapper = <HTMLDivElement>document.createElement("div");
                        fileWrapper.classList.add("medium-6");
                        fileWrapper.classList.add("columns");
                        fileWrapper.classList.add("end");
                        let newFile = <HTMLDivElement>document.createElement("div");
                        newFile.classList.add("uploaded-file-container");
                        let fileA = <HTMLAnchorElement>document.createElement("a");
                        fileA.dataset.fileuploadpk = res.payload.fileUploadPK;
                        fileA.dataset.planpropertyfilepk = "0";
                        fileA.dataset.propertypk = propertyPK;
                        fileA.dataset.sequencenbr = sequence.toString();
                        fileA.classList.add("specialEducationIUSignaturesFileUploadField");
                        fileA.href = "javascript:void(0);";
                        fileA.text = res.payload.filename;
                        newFile.appendChild(fileA);
                        fileWrapper.appendChild(newFile);

                        fileList.appendChild(fileWrapper);
                    } else {
                        Core.hideLoader();
                        Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
                    }
                }
            } else {
                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "The file upload failed. Please try again.", 'error', 3000, null);
            }
        };
        xhr.send(formData);
    }

    showDeleteFile(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let modal: Modal = new Modal("signaturesDeleteFileModal", null);
        modal.addAttributeToElement("signaturesDeleteFileModal", "#deleteFileConfirm", "planpropertyfilepk", planPropertyFilePK);
        modal.show();
    }

    deleteFileConfirm(e: Event) {
        let buttonElement = <HTMLButtonElement>e.target;
        let planPropertyFilePK = buttonElement.dataset.planpropertyfilepk;

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/IUSpecialEducation/DeleteFileData', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText.toLowerCase().indexOf("true") >= 0) {
                    const element = document.querySelector(`.specialEducationIUSignaturesFileUploadField[data-planpropertyfilepk='${planPropertyFilePK}']`);
                    const parent = element.parentElement.parentElement;
                    parent.remove();

                    const moreFiles = document.querySelectorAll(`.uploadFileList .uploadFileColumn`);
                    if (moreFiles.length === 0) {
                        const uploadsElement = <HTMLDivElement>document.getElementById("uploadedFiles");

                        uploadsElement.classList.add("hide");
                    }

                    Core.createHTMLAlert("alertMessageDiv", "File successfully removed.", 'success', 3000, null);
                } else {
                    Core.createHTMLAlert("alertMessageDiv", "There was an issue removing the file. Please try again.", 'error', 3000, null);
                }

                Core.hideLoader();

                const modal: Modal = new Modal("signaturesDeleteFileModal", null);
                modal.hide();
            } else {
                const modal: Modal = new Modal("signaturesDeleteFileModal", null);
                modal.hide();

                Core.hideLoader();
                Core.createHTMLAlert("alertMessageDiv", "Request failed.  Returned status of '" + xhr.status, 'error', 3000, null);
            }
        };
        xhr.send(JSON.stringify(planPropertyFilePK));
    }

    deleteFileCancel() {
        let modal: Modal = new Modal("signaturesDeleteFileModal", null);
        modal.hide();
    }
}

// SpecialEducationIUSummary
class SpecialEducationIUSummary {
    private _core: Core;
    constructor() {
        this._core = new Core();
        let backButton = document.getElementById("backButton");
        if (backButton !== null)
            backButton.addEventListener("click", (e: Event) => this.back(e));

        let submitButton = document.getElementById("submitPlanButton");
        if (submitButton !== null)
            submitButton.addEventListener("click", (e: Event) => this.submit(e));

        this._core.leftnav(this);
    }

    back(e: Event) {
        let newPage = document.getElementById("backButton").getAttribute("data-redirect-url");
        window.location.href = newPage;
    }

    getCore() {
        return this._core;
    }

    submit(e: Event) {
        let element = <HTMLButtonElement>e.target;
        if (!element.classList.contains("disabled")) {
            let planFK = 0;
            planFK = parseInt(element.dataset.planfk);
            let core = this._core;
            Core.showLoader();
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/IUSpecialEducation/SubmitPlan', true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200 && JSON.parse(xhr.responseText) && (JSON.parse(xhr.responseText)).success === true) {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "This report has been submitted for review!", 'success', 3000, window.location.reload());

                    setTimeout(function () { window.location.href = '/Reports/StateRequiredReports' }, 3000);
                }
                else {
                    Core.hideLoader();
                    Core.createHTMLAlert("alertMessageDiv", "An unexpected error occurred while submitting report. Please try again later.", 'error', 3000, null);
                }
            };
            xhr.send("planFK=" + planFK);
        }
    }
}

class SpecialEducationIUProgramProfilesLazyAccordion extends LazyAccordion {

    async loadContent(accordion: HTMLElement, contentElement: HTMLElement) {
        const icon = <HTMLSpanElement>accordion.querySelector(".lazy-accordion-loaded-icon");
        icon.classList.replace("empty", "loading");
        this.show(accordion, contentElement);

        const educationProgramFTEFK = accordion.dataset.educationprogramftefk;
        const action = accordion.dataset.action;
        const controller = accordion.dataset.controller;
        const url = `/${controller}/${action}/${educationProgramFTEFK}`;

        //load
        const response = await fetch(`${url}`, { credentials: 'include' })
        if (response.ok) {
            contentElement.innerHTML = await response.text();

            icon.classList.replace("loading", "loaded");

            SpecialEducationIUProgramProfiles.initializeLoadedFTE(parseInt(educationProgramFTEFK));
            if (accordion.dataset.validdata === "false") {
                SpecialEducationIUProgramProfiles.fteValidation(parseInt(educationProgramFTEFK));
            }

            SpecialEducationIUProgramProfiles.checkOperatedByIdentifyClassroom(parseInt(educationProgramFTEFK));
        }
    }
}

class SpecialEducationIUFacilitiesLazyAccordion extends LazyAccordion {

    async loadContent(accordion: HTMLElement, contentElement: HTMLElement) {
        const icon = <HTMLSpanElement>accordion.querySelector(".lazy-accordion-loaded-icon");
        icon.classList.replace("empty", "loading");
        this.show(accordion, contentElement);

        const facilityFK = accordion.dataset.specialeducationfacilityfk;
        const action = accordion.dataset.action;
        const controller = accordion.dataset.controller;
        const url = `/${controller}/${action}/${facilityFK}`;

        //load
        const response = await fetch(`${url}`, { credentials: 'include' })
        if (response.ok) {
            contentElement.innerHTML = await response.text();

            icon.classList.replace("loading", "loaded");

            SpecialEducationIUFacilities.initializeLoadedFacility(parseInt(facilityFK));
            SpecialEducationIUFacilities.initializeLoadedFacilityFields(facilityFK);

            if (accordion.dataset.validdata === "false") {
                SpecialEducationIUFacilities.validateElements(facilityFK);
            }

            const tabContainer = <HTMLElement>document.querySelector(`.seprnContainer[data-facility-pk='${facilityFK}'] .row .review-container .review-container-actions .tab-container`)
            if (tabContainer)
                new Tab(tabContainer);

            const seprnContainer = <HTMLButtonElement>document.querySelector(`.seprnContainer[data-facility-pk='${facilityFK}']`);
            if (seprnContainer !== null) {
                const workflowPK = seprnContainer.dataset.workflowInstancePk;
                if (workflowPK && parseInt(workflowPK) > 0)
                    new Workflow(parseInt(workflowPK));
            }

            SpecialEducationIUFacilities.checkForRequiredFieldsFromOperatedInStatic(parseInt(facilityFK));
            SpecialEducationIUFacilities.checkForBuildingOtherStatic(parseInt(facilityFK));
        }
    }
}
